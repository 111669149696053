import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button } from 'reactstrap';

import IconsChairsideEducation from '../../img/IconsChairside_Education.svg'
import IconsWaitingRoomChannel from '../../img/IconsWaiting_Room_Channel.svg'
import IconOperatoryRoomChannel from '../../img/IconOperatoryRoomChannel.svg'
import IconsWebsiteVideos from '../../img/IconsWebsite_Videos.svg'
import error_worning_small from '../../img/error_worning_small.svg'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import './style.scss';
import { history } from '..';
import WaitingRoomChannels from '../WaitingRoomChannels/WaitingRoomChannels';
import OperatoryChannels from '../OperatoryChannels/OperatoryChannels';
import { Link, Route, Switch } from 'react-router-dom';
import { SetupgradeDowngrade } from '../../store/actions/taskAction';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        isSigninClick:true,
        isSignin:false,
        value:  0
    }
    this.onSigninClick = this.onSigninClick.bind(this);
  }
  
  onSigninClick(){
    this.setState({isSigninClick:false})
  }
  onSignin(){
    this.setState({isSignin:true})
  }

  async componentDidMount(){
    await this.props.dispatch(SetupgradeDowngrade(false));
  }

  redirectPage(isPlan, redirectUrl, planName){
    let plugins = this.props.avelablePlugin
    let selectedplan = plugins?.find(p => p.code === isPlan);
    if(selectedplan){
      history.push(redirectUrl);
    }else{
      history.push({
        pathname: '/upgrade-plan',
        selectplan: planName
      });
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: this.props.match.url });
  };

  render (){
    const {t} = this.props
    const isCorporate = this.props.practiceInfo?.is_corporate;
    return(
      <>
      { isCorporate ?
        <div className="main__screen channels__page  d-flex flex-column h-100   main-bg">
          <SubHeader className="" title={`${t('homepage.title', {framework:'React'})}${isCorporate ? " | Corporation" : ""}`}/>
          <Tabs value={this.props.location.pathname} 
            onChange={this.handleChange} 
            indicatorColor='transparent'
            textColor='primary'
            className='footer-tabs custome-tabs-with-link'
            aria-label='full width tabs example' >
            <Tab label="Waiting room" component={Link} className="custome-link-tab" to={`${this.props.match.url}`} value={`${this.props.match.url}`} {...a11yProps(0)} />
            <Tab label="Operatory" component={Link} className="custome-link-tab" to={`${this.props.match.url}/operatory-channels`} value={`${this.props.match.url}/operatory-channels`} {...a11yProps(1)} />
          </Tabs>
          <div style={{marginTop:'-40px'}}>
          <Switch>
            {/* <Route exact path={`${this.props.match.url}/waiting-room-channels`}>
              <WaitingRoomChannels />
            </Route> */}
            <Route exact path={`${this.props.match.url}/operatory-channels`}>
              <OperatoryChannels />
            </Route>
            <Route exact path={`${this.props.match.url}`}>
              <WaitingRoomChannels />
            </Route>
        </Switch>
            {/* <TabPanel value={this.state.value} index={0} >
              <WaitingRoomChannels />
            </TabPanel>
            <TabPanel value={this.state.value} index={1} >
              <OperatoryChannels />
            </TabPanel> */}
          </div>
        </div>
        :
      <div className="main__screen channels__page  d-flex flex-column h-100 align-items-center justify-content-center main-bg">
          <SubHeader className="mb-auto" title={`${t('homepage.title', {framework:'React'})}${isCorporate ? " | Corporation" : ""}`}/>
              <div className="main__inner__screen mb-auto d-flex" style={{marginTop:'-120px'}}>
                {this.props.practiceInfo?.is_internal_user != 1 &&
                    <div className="channels__item mx-20" onClick={(e)=> this.redirectPage("chairside" ,'/chairside-education', 'Chairside Education')}>
                      <div className="channels__box  d-flex flex-column align-items-steach justify-content-center">
                          <div className="channels__icon">
                              <img src={IconsChairsideEducation} alt="" />
                          </div>
                      </div>
                      <div className="channels__name my-3 text-center">
                            <p className="m-0">{t('homepage.chairsideeducation', {framework:'React'})}</p>
                      </div>
                    </div>
                }
                <div className="channels__item mx-20" onClick={()=> this.redirectPage("waiting_room" ,'/waiting-room-channels','Waiting Room TV')}>
                  <div className="channels__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="channels__icon">
                          <img src={IconsWaitingRoomChannel} alt="" />
                      </div>
                  </div>
                  <div className="channels__name my-3 text-center">
                        <p className="m-0">WAITING ROOM CHANNEL</p>
                  </div>
                </div>
                <div className="channels__item mx-20"  onClick={()=> this.redirectPage("operatory_room" ,'/operatory-channels','Operatory Room TV')}>
                  <div className="channels__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="channels__icon">
                          <img src={IconOperatoryRoomChannel} alt="" />
                      </div>
                  </div>
                  <div className="channels__name my-3 text-center">
                        <p className="m-0">OPERATORY CHANNEL</p>
                  </div>
                </div>
             
                {this.props.practiceInfo.is_internal_user != 1 &&
                  <div className="channels__item mx-20" onClick={()=> this.redirectPage("website_video" ,'/select-category-website-videos','Website Videos')}>
                      <div className="channels__box  d-flex flex-column align-items-center justify-content-center align-item-strach">
                          <div className="channels__icon">
                              <img src={IconsWebsiteVideos} alt="" />
                          </div>
                      </div>
                      <div className="channels__name my-3 text-center" >
                            <p className="m-0">WEBSITE VIDEOS</p>
                      </div>
                </div>}
              </div>
      </div>
      }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  avelablePlugin:state.task.avelablePlugin
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
