import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/authorized_1.png'
import Image2 from './../../../img/help/authorized_2.png'

const ProfileAuthorization =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <>
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Profile & Authorization</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc" style={{}}>
                                        <h5>What is the difference between owners, authorized users, and regular profiles?</h5>
                                        <div className="help_detail">
                                              <p>
                                                The owner profile is the initial profile that was created when using Thalamus for the first time. This owner has admin privileges, meaning they are able to access important features such as billing information and can manage other profiles on the Thalamus account.</p>

                                                <p>Admin privileges can be shared with other key members of the practice, such as managers, and specific authorized users.</p>
                                                
                                                <p>Regular profiles can view and access the same content, but are not able to access certain pages that may contain sensitive information, such as the edit billing page.
                                              </p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image1} className="w-100" alt="" style={{ marginTop:"-7px"}}/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"-10px"}}>
                                        <h5>Managing/changing authorized users</h5>
                                        <div className="help_detail">
                                              <p>Authorized users are able to change, add, or remove profiles and authorized users in the settings (the second icon from the left on the heading tab on the top right of the page). </p>

                                                <p>Once you are in the settings, clicking the “Authorized users” page will allow you to edit, add, or remove users.</p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image2} className="w-100" alt="" style={{ marginTop:"-14px"}}/>
                                  </div>
                            </div>
                      </div>
                  </>
            
    ) 

}

export default ProfileAuthorization;
