import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchChairsideMedia, getOperatoryMediaChannelList, getWebsiteRoomMediaChannelList } from '../../../../store/actions/taskAction';
import styles from './style.module.scss';
import ChannelList from '../ChannelList'
import Loading from '../../../../components/Loading';

const MyListsWaitingRoomChannels = ({ sortedBy, sortedType, isOperatoryRoom, isChairside }) => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);

    useEffect(() => {

      if(isChairside){
        dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
        return
      }

      if(isOperatoryRoom){
        dispatch(
          getOperatoryMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }else{
        dispatch(
          getWebsiteRoomMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }
    }, []);

  return (
    <>
    {state.loading ? <div className={`goolge_tv__loader`}><Loading /></div> :
    <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
        <ChannelList title={isChairside ? 'New Videos' : 'New channels'} isOperatoryRoom={isOperatoryRoom} noSlider={true}isChairside={isChairside} channelsData={isChairside ?  state.chairSideMediaState.slice(0, 10) : isOperatoryRoom ? state.operatoryMediaChannels.slice(0, 10) : state.waitingRoomMediaChannels.slice(0, 10)}/>
    </div>
    }
    </>
  )
}

export default MyListsWaitingRoomChannels
