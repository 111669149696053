import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/profile_1.png'
import Image2 from './../../../img/help/profile_2.png'

const MyProfile =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <> 
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>My Profile</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc">
                                        <h5>Editing your profile</h5>
                                        <div className="help_detail">
                                             <p>After clicking the profile icon on the top right of the page, hover over the popup and click on the profile button. Here you will be able to update your profile photo, your ‘about’ bio, as well as update login information.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" >
                                      <img src ={Image1}  className="w-100" alt="" />
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"11px",}}>
                                        <h5>How do I change my passcode?</h5>
                                        <div className="help_detail">
                                          <p>After clicking the profile icon on the top right of the page, hover over the popup and click on the profile button. Clicking on the security tab, will allow you to change your personal phone number and passcode.</p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image2}  className="w-100" alt=""  style={{marginTop:"-44px",}}/>
                                  </div>
                            </div>
    
                      </div>
                  </>
            
    ) 

}

export default MyProfile;
