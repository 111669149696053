import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import VerifyIdentityBox from '../../components/VerifyIdentityBox'
import './style.scss';
import {  GetpracticeProfile  , GetPracticeMoreInfo , UpdatepracticeInfoRequest} from "../../store/actions/taskAction";
import HttpUtility, {HELPERURI} from '../../utilities/HttpUtility';

import { Ownership  , operatories , days , tv_count, distributor} from '../../data/comman';


class PracticeInfoIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  

  async componentDidMount(){
    if(this.props.practiceUserLoginInfo?.is_manage_location !== 1){
      await this.props.dispatch(GetpracticeProfile());
      await this.onupdate()
    }else{
      await this.onupdatechildPractice()
    }
  }

    async onEditPracticeInfo(field , value){
      let practiceProfileInfo = this.props.practiceProfileInfo
      practiceProfileInfo[field] = value
      await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))
    }


    async onupdatechildPractice(){
      
      if(this.props.practiceUserLoginInfo.practice_profile_info){
        if(this.props.practiceUserLoginInfo?.practice_profile_info?.country_id){
          var selectedcountry =  this.props.country.find(b => b.id === this.props.practiceUserLoginInfo?.practice_profile_info?.country_id)
          await  this.onEditPracticeInfo( 'selectedcountry' , selectedcountry)
          await  this.getState(selectedcountry.id);
        }
      }
  
      if(this.props.practiceUserLoginInfo.practice_profile_info){
          var ownership  = Ownership.find(b => b.id === this.props.practiceUserLoginInfo?.practice_profile_info?.ownership)
          this.onEditPracticeInfo( 'ownership' , ownership)
      }
  
      var specializations = []
      if(this.props.practiceUserLoginInfo?.practice_profile_info?.specializations){
          this.props.practiceUserLoginInfo?.practice_profile_info?.specializations.map((ids)=>
          specializations.push(this.props.specializations.find(b => b.id === ids)),
          );
            this.onEditPracticeInfo( 'specializations' , specializations)
      }
  
      if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.software){
        var selectedsoftware  = this.props.practice_softwares.find(b => b.name === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info.software)
        this.onEditPracticeInfo( 'selectedsoftware' , selectedsoftware)
      }
  
      if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.operatory_count){
        var selectedoperatories  = operatories.find(b => b.value === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.operatory_count)
        this.onEditPracticeInfo( 'selectedoperatories' , selectedoperatories)
      }
  
      if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.source){
        var selectedsource  = this.props.lead_source_types.find(b => b.name === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.source)
        this.onEditPracticeInfo( 'selectedsource' , selectedsource)
      }
  
      if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.practice_profile_info?.customer_type){
        var selectedpracticetype  = this.props.practice_customer_types.find(b => b.id === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.customer_type)
          this.onEditPracticeInfo( 'selectedpracticetype' , selectedpracticetype)
      }
  
      if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.tv_count){
        var selectedtv_count  = tv_count.find(b => b.value === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.tv_count)
        this.onEditPracticeInfo( 'selectedtv_count' , selectedtv_count)
      }
    }

    async onupdate(){
      
      if(this.props.practiceProfileInfo){
        if(this.props.practiceProfileInfo.country_id){
          var selectedcountry =  this.props.country.find(b => b.id === this.props.practiceProfileInfo.country_id)
          await  this.onEditPracticeInfo( 'selectedcountry' , selectedcountry)
          await  this.getState(selectedcountry.id);
        }
      }

      if(this.props.practiceProfileInfo){
          var ownership  = Ownership.find(b => b.id === this.props.practiceProfileInfo.ownership)
          this.onEditPracticeInfo( 'ownership' , ownership)
      }

      var specializations = []
      if(this.props.practiceProfileInfo?.specializations){
          this.props.practiceProfileInfo?.specializations.map((ids)=>
          specializations.push(this.props?.specializations.find(b => b.id === ids)),
          );
            this.onEditPracticeInfo( 'specializations' , specializations)
      }

      if(this.props.practiceProfileInfo?.additional_info?.software){
        var selectedsoftware  = this.props.practice_softwares.find(b => b.name === this.props.practiceProfileInfo.additional_info.software)
        this.onEditPracticeInfo( 'selectedsoftware' , selectedsoftware)
      }

      if(this.props.practiceProfileInfo.additional_info.operatory_count){
        var selectedoperatories  = operatories.find(b => b.value == this.props.practiceProfileInfo.additional_info.operatory_count)
        this.onEditPracticeInfo( 'selectedoperatories' , selectedoperatories)
      }

      if(this.props.practiceProfileInfo.additional_info.source){
        var selectedsource  = this.props.lead_source_types.find(b => b.name === this.props.practiceProfileInfo.additional_info.source)
        this.onEditPracticeInfo( 'selectedsource' , selectedsource)
      }

      if(this.props.practiceProfileInfo.additional_info.customer_type){
        var selectedpracticetype  = this.props.practice_customer_types.find(b => b.id === this.props.practiceProfileInfo.additional_info.customer_type)
          this.onEditPracticeInfo( 'selectedpracticetype' , selectedpracticetype)
      }

      if(this.props.practiceProfileInfo.additional_info.tv_count){
        var selectedtv_count  = tv_count.find(b => b.value === this.props.practiceProfileInfo.additional_info.tv_count)
        this.onEditPracticeInfo( 'selectedtv_count' , selectedtv_count)
      }

      if(this.props.practiceProfileInfo.additional_info.practice_setting && this.props.practiceProfileInfo.additional_info.practice_setting.system_language){
        var systemlanguage  = this.props.system_language.find(b => b.id === this.props.practiceProfileInfo.additional_info.practice_setting.system_language)
        this.onEditPracticeInfo( 'systemlanguage' , systemlanguage)
      }

      if(this.props.practiceProfileInfo.additional_info.practice_setting && this.props.practiceProfileInfo.additional_info.practice_setting.media_language){
        var medialanguage  = this.props.media_language.find(b => b.id === this.props.practiceProfileInfo.additional_info.practice_setting.media_language)
        this.onEditPracticeInfo( 'medialanguage' , medialanguage)
      }

      if(this.props.practiceProfileInfo.additional_info.distributor_name){
        var selecteddistributor  = distributor.find(b => b.label === this.props.practiceProfileInfo.additional_info.distributor_name)
          this.onEditPracticeInfo( 'selecteddistributor' , selecteddistributor)
      }

    }

    async getState(countryId){
      await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
          if(response.status === 200){
              var countryState = response.data;
              this.onEditPracticeInfo( 'countryState' , countryState)
              if(this.props.practiceProfileInfo.state_id){
                var selectedstate = countryState.find(b => b.id === this.props.practiceProfileInfo.state_id)
              }
              this.onEditPracticeInfo( 'selectedstate' , selectedstate)
          }
      });
    }

  render (){
    return(
      <div className="practiceInfo__main__screen billing__page  d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Practice Info"/>
          <VerifyIdentityBox pageUrl={`/practice-info-edit`}/>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceProfileInfo: state.task.practiceProfileInfo,
  country: state.task.country,
  specializations: state.task.specializations,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types:state.task.practice_customer_types,
  media_language:state.task.media_language,
  system_language:state.task.system_language,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(PracticeInfoIndex);