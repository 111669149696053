/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import WaitingRoomTimelineMyVideosCategories from './WaitingRoomTimelineMyVideosCategories';
import WaitingRoomTimelineMyVideosSelectedVideo from './WaitingRoomTimelineMyVideosSelectedVideo';
import WaitingRoomTimelineVideoUpload from './WaitingRoomTimelineVideoUpload';


const WaitingRoomTimelineMyVideos = ({ myVideos, timeLineList, onPublish }) => {
	const [selectedVideos, setSelectedVideos] = useState(myVideos);
	const [uploadVideo, setUploadVideo] = useState(false);
	const [categories, setCategories] = useState([]);
	const [isVideos, setIsVideos] = useState(false);
	const state = useSelector((state) => state.task);
	
	useEffect(() => {
		const categoryArray = [];
		const videos = [];
		myVideos.forEach((element) => {
			categoryArray.push({
				category_id: element.categories_id,
				category_name: element.categories_name,
				selected: false,
				videosLength: element.video.length,
			});
			element.video.forEach((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				console.log(video,'video')
				videos.push({
					...video,
					category_name: element.categories_name,
					category_id: element.categories_id,
					selected: false,
					isAdded
				});
			});
		});

		if(videos.length == 0)
			setIsVideos(false);
		else
			setIsVideos(true);

		setSelectedVideos(videos);
		// setCategories(categories)
	}, [ timeLineList]);
	
	
	const setVideos = (videos) => {
		setSelectedVideos(videos);
	};

	const showuploadVideo = () => {
		setUploadVideo(true)
	}

	const vieMyViedeo = () => {
		setUploadVideo(false)
		setIsVideos(true);

	}

	const dropDownTitle= "Category";
	const addBreakLine= "<br/>";

	return (
		<>
			<div className="waitiing-room-col-sec">
				{ !uploadVideo ?
					<>
						<div className="waiting-room-left-sec">
							<WaitingRoomTimelineMyVideosCategories
								videos={myVideos}
								setVideos={setVideos}
								onUploadVideoClick={showuploadVideo}
								timeLineList={timeLineList}
								onPublish={onPublish}
							/>
						</div>
						<div className="waiting-rom-right-sec">	
							{!isVideos ?
								<div className="d-flex waiting_room_my_video_blank flex-column h-100 align-items-center justify-content-center">
									<h1>You haven't uploaded any videos yet.</h1>
									<p>Select "Upload my videos" on the left to uploada video, or <Button type="button" className="p-0" color="link" onClick={showuploadVideo}>click here.</Button></p>
								</div>		
							:		
							<WaitingRoomTimelineMyVideosSelectedVideo
								videos={selectedVideos}
								setVideos={setVideos}
								timeLineList={timeLineList}
							/>
							}
						</div>
					</>
				: 
					<WaitingRoomTimelineVideoUpload categories={categories} onVideoClick={vieMyViedeo} dropDownTitle={dropDownTitle} addBreakLine={addBreakLine}/>
				}
			</div>
		</>
	);
};

export default WaitingRoomTimelineMyVideos;
