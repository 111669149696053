import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import LoadingButton from '../../components/LoadingButton'
import {
    deleteWebsiteVideoLibraryPlaylist,
    getPlaylistsWebsiteVideoLibrary,
    getWebsiteVideoLibraryPlaylist,
    postSaveTimelineWebsiteVideoLibrary,
    postWebsiteVideoLibraryPlaylist,
    publishWebsiteMedia,
} from '../../store/actions/taskAction';
import './videolist.scss';
import { history } from '../../pages';
import Skeleton from "@material-ui/lab/Skeleton";

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';
const DELETEIMAGE = 'assets/img/deleteImage/reddelete.png';
const REDRIGHT = 'assets/img/deleteImage/redright.png';

var arr = [];
var arrTwo = [];
const VideoListThree = ({ list, submitVideoLists, settotalVideos, setselectedVideos, selectedvideos, totalvideos, setButtonDisable, buttonDisable }) => {
    //console.log("List in VideoListThree : ", list)
    const state = useSelector((state) => state.task);
    const dispatch = useDispatch();
    const [practiceInfo, setPracticeInfo] = useState(state.practiceInfo);
    const [websiteVideosList, setWebsiteVideosList] = useState(list);
    const [allVideosCount, setAllvideosCount] = useState(0);
    const [allCategoryList, setAllCategoryList] = useState([]);
    const [allVideosIndex, setAllVideosIndex] = useState(true);
    const [allPlaylists, setAllPlaylists] = useState(state.playlists);
    const [stateUpdate, setStateUpdate] = useState(0);
    const [deleteIconShow, setDeleteIconShow] = useState({
        show: false,
        index: -1,
    });
    const [checkPlaylist, setCheckPlaylist] = useState(false);
    const [deletePlaylistMessage, setDeletePlaylistMessage] = useState({
        show: false,
        index: -1,
    });
    const [savePlaylistMessage, setSavePlayListMessage] = useState({
        message: 'save playlist',
        color: 'black',
        bgColor: 'primary',
    });
    const [saveWebsiteMessage, setSaveWebsiteMessage] = useState({
        message: 'save to website',
        color: 'black',
        bgColor: 'primary',
    });
    const [savePublishMessage, setPublishMessage] = useState({
		message: 'Publish',
		bgColor: 'primary',
	});

    useEffect(() => {
        setWebsiteVideosList(list);
    }, [list]);

    useEffect(() => {
        setAllPlaylists(state.playlists);
        console.log("play list",state.playlists)
    }, [state.playlists]);

    useEffect(() => {
        setDeletePlaylistMessage({
            index: -1,
            show: false,
        });
    }, [state.playlists]);

    useEffect(() => {
        let allVideos = [];
        let selectedVideos = [];
        allCategoryList.forEach((element) => {
            if (element.selected == true) {
                element.selected = false;
            }
        });
        allCategoryList.forEach((category) => {
            category.videos.forEach((video) => {
                var videoIndex = allVideos.findIndex(x => x.website_media_id == video.website_media_id);
                if(videoIndex > -1){
                    allVideos[videoIndex] = {
                        ...video,
                        categoryName: `${allVideos[videoIndex].categoryName}, ${category.title}`,
                        selected: video.website_media.is_predefined_media === 1,
                    };
                }else{
                    allVideos.push({
                        ...video,
                        categoryName: `${category.title}`,
                        selected: video.website_media.is_predefined_media === 1,
                    });
                    if (video.website_media.is_predefined_media === 1) {
                        selectedVideos.push({
                            ...video,
                            selected: true,
                        });
                    }
                }
            });
        });
        settotalVideos(allVideos);
        setselectedVideos(selectedVideos);
        setAllVideosIndex(true)
    },[state.isResetDefaultChairside])

    useEffect(() => {
        let categoryArray = [];
        var playListArray = [];
        const map = new Map();
        for (const item of state.websiteMediaState) {
            const value = state.websiteMediaState.filter(
                (word) => word.media_category_id === item.media_category_id
            );
            if (!map.has(item.category.id)) {
                map.set(item.category.id, true);
                categoryArray.push({
                    id: item.category.id,
                    webSiteVideoId: item.id,
                    title: item.category.title,
                    media_category_id: item.category.id,
                    selected: false,
                    selectedPlayList: false,
                    videosLength: value.length,
                    videos: value,
                });
            }
        }
        let allVideos = [];
        let selectedVideos = [];
        categoryArray.forEach((category) => {
            category.videos.forEach((video) => {
                var videoIndex = allVideos.findIndex(x => x.website_media_id == video.website_media_id);
                if(videoIndex > -1){
                    allVideos[videoIndex] = {
                        ...video,
                        categoryName: `${allVideos[videoIndex].categoryName}, ${category.title}`,
                        selected: video.website_media.is_predefined_media === 1,
                    };
                    // if (video.website_media.is_predefined_media === 1) {
                    //     selectedVideos.push({
                    //         ...video,
                    //         selected: true,
                    //     });
                    // }
                }else{
                    allVideos.push({
                        ...video,
                        categoryName: `${category.title}`,
                        selected: video.website_media.is_predefined_media === 1,
                    });
                    if (video.website_media.is_predefined_media === 1) {
                        selectedVideos.push({
                            ...video,
                            selected: true,
                        });
                    }
                }
                    
            });
        });
        allPlaylists && allPlaylists.length > 0 && 
            allPlaylists.forEach((playlist) => {
                playListArray.push({
                    ...playlist,
                    selected: false,
                    videos: playlist.playlist_media.media,
                });
            });

        setAllPlaylists(playListArray);
        settotalVideos(allVideos);
        setselectedVideos(selectedVideos);
        setAllVideosIndex(true)
        setAllCategoryList(categoryArray);
        // console.log(categoryArray,'categoryArray')
        setAllvideosCount(allVideos.length); //
        dispatch(getPlaylistsWebsiteVideoLibrary(state.practiceUserLoginInfo));
    }, [state.websiteMediaState]);

    //ON LIST ITEM CLICK
    const onCategoryClick = (item, type) => {
        if(allVideosIndex || checkPlaylist) arrTwo = []; arr = [];
        setStateUpdate(stateUpdate + 1);
        setAllVideosIndex(false);
        setButtonDisable(false);
        if (type === 'playList') {
            setCheckPlaylist(true);
            // dispatch( getWebsiteVideoLibraryPlaylist(state.practiceUserLoginInfo, item.id));
            console.log("type==> ", type)
            allCategoryList.forEach((element) => {
                element.selected = false;
                element.selectedPlayList = false;
                element.videos.forEach((video) => {
                    video.selectedPlayList = false;
                });
            });

            allPlaylists.forEach((element) => {
                if (element.id == item.id) {
                    element.selectedPlayList = !item.selectedPlayList;//checkPlaylist;
                }
                if (element.selectedPlayList === true) {
                    allCategoryList.forEach((video) => {
                        video.videos.forEach((datum) => {
                            element.playlist_media.media.forEach((data) => {
                                // console.log("isAdd", data.id === datum.media_category_id,  data.id, datum.id, item);
                                if (data.id === datum.id) {
                                    // arr.push({ ...datum, selectedPlayList: true });
                                    // console.log("isAdd", isAdd);
                                    var isAdd = arr.findIndex(x => x.id == datum.id) ;
                                    if(isAdd == -1){
                                        arr.push({...datum,...item, selectedPlayList: false });
                                    }else{
                                        arr.splice(isAdd, 1);
                                    }
                                }
                            });
                        });
                    });
                } else {
                    allCategoryList.forEach((video) => {
                        video.videos.forEach((datum) => {
                            element.playlist_media.media.forEach((data) => {
                                if (data.id === datum.media_category_id) {
                                    // arr.pop({ ...datum, selectedPlayList: true });
                                    var isAdd = arr.findIndex(x => x.id == datum.id);
                                    if(isAdd > -1){
                                        arr.splice(isAdd, 1);
                                    }
                                }
                            });
                        });
                    });
                }
            });
            settotalVideos(arr);
            // setselectedVideos(arr);
            // console.log('Item in Playlist: ', item, 'arr==>',arr);
        } else {
            // play list
            setCheckPlaylist(false);
            allPlaylists.forEach((element) => {
                element.selectedPlayList = false;
            });
            setAllPlaylists(allPlaylists);
            // play list

            allCategoryList.forEach((element) => {
                if (element.id == item.id) {
                    element.selected = !item.selected;
                    element.selectedPlayList = false;
                }
            });
            // Sending all videos of categories
            if (item.selected == true) {
                //item.selected = false;
                allCategoryList.forEach((element) => {
                    if (element.title === item.title) {
                        console.log("category name:==>",element);
                        list.forEach((datum, index) => {
                            if (element.id === datum.media_category_id ) {
                                var isAdd = arrTwo.findIndex(x => x.id == datum.id);
                                var isMultipleCat = arrTwo.findIndex(x => x.website_media_id == datum.website_media_id);
                                var categoryName = isMultipleCat > -1 ? arrTwo[isMultipleCat].categoryName : [];
                                // (isMultipleCat > -1 ? `${arrTwo[isMultipleCat].categoryName}, ` : "") + datum.category.title;
                                categoryName.push(datum.category.title);
                                if(isMultipleCat == -1){
                                    if(isAdd == -1){
                                        arrTwo.push({ ...item, ...datum, categoryName: categoryName, selected:  datum.website_media.is_predefined_media == 1 ? true :  false  });
                                    }else{
                                        arrTwo.splice(isAdd, 1);
                                    }
                                }else if(isAdd == -1){
                                    arrTwo[isMultipleCat].categoryName = categoryName;
                                }
                            }
                            // if (element.id === datum.media_category_id && datum.website_media.is_free == 1) {
                            //     arr.push({ ...item, ...datum, selected: true });
                            // }
                        });
                    }
                });
            } else {
                allCategoryList.forEach((element) => {
                    if (element.title === item.title) {
                        list.forEach((datum) => {
                            if (element.id === datum.media_category_id) {
                                var isAdd = arrTwo.findIndex(x => x.id == datum.id);
                                var isMultipleCat = arrTwo.findIndex(x => x.website_media_id == datum.website_media_id);
                                var categoryName =  arrTwo[isMultipleCat].categoryName;
                                var isCatAvaIndex = categoryName.findIndex(x => x == item.title)
                                if(isAdd > -1 && isCatAvaIndex == -1){
                                    arrTwo.splice(isAdd, 1);
                                }else if(isCatAvaIndex > -1){
                                    categoryName.splice(isCatAvaIndex,1); // remove only cat
                                    arrTwo[isMultipleCat].categoryName = categoryName;
                                    if(categoryName.length == 0){
                                        arrTwo.splice(isMultipleCat, 1);
                                    }
                                }
                                // arrTwo.pop({ ...item, ...datum, selected: false });
                            }
                            // if (element.id === datum.media_category_id && datum.website_media.is_free == 1) {
                            //     arr.pop({ ...item, ...datum, selected: true });
                            // }
                        });
                    }
                });
            }
             settotalVideos(arrTwo);
            // setselectedVideos(arr);
        }
    };
    // Show Playlist
    const showPlayLists = allPlaylists && allPlaylists.length > 0 ? allPlaylists.map((item, index) => {
                const TYPE = 'playList';
                const active = item.selectedPlayList == true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
                return (
                    <React.Fragment key={index}>
                        <div
                            className='playlistHeader d-flex'
                        // onMouseEnter={() => handleMouseEnter(index)}
                        /* onMouseLeave={() => setDeleteIconShow(false)} */
                        >
                            {' '}
                            <img
                                onClick={() => onCategoryClick(item, TYPE)}
                                src={`${active}`}
                                alt=''
                                className='videolist__submain__header__checkbox mr-4'
                            />
                            <span className='playListText d-flex justify-content-between w-100 pr-3'>
                                {deletePlaylistMessage.show === true && deletePlaylistMessage.index === index ? (
                                    <>
                                        <span className="text--danger">{`Remove ${item.playlist_name} ?`}</span>
                                        <span className='d-flex justify-content-end'>
                                            <img
                                                src={`${REDRIGHT}`}
                                                alt=''
                                                onClick={() => onHandleDeleteClick(item.id)}
                                                style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                            &nbsp;&nbsp;
                                            <img
                                                src={`${CLOSEBOXIMAGE}`}
                                                alt=''
                                                onClick={() => handleCloseClick()}
                                                style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span onMouseEnter={() => handleMouseEnter(index)}>
                                            {item.playlist_name}{' '}
                                            {`(${item.playlist_media && item.playlist_media.media && item.playlist_media.media.length})`}
                                        </span>
                                    </>
                                )}
                            </span>
                            {deleteIconShow.show === true && deleteIconShow.index === index ? (
                                <span className="d-flex ml-auto pr-3" onClick={() => onHandlePlaylistDeleteClick(index)}>
                                    <img
                                        // onClick={() => onCategoryClick(item, TYPE)}
                                        src={`${DELETEIMAGE}`}
                                        alt=''
                                        style={{
                                            color: 'red',
                                            textAlign: 'right',
                                            // marginLeft: '100px',
                                            height: '20px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                        <br />
                    </React.Fragment>
                );
            })
            : '';

    // To show confirm & cancel delete buttons
    const onHandlePlaylistDeleteClick = (index) => {
        allPlaylists.forEach((element) => {
            element.selected = false;
        });

        setDeletePlaylistMessage({
            index: index,
            show: true,
        });
        setDeleteIconShow({
            index: index,
            show: false,
        });
    };
    //On Mouse Enter
    const handleMouseEnter = (index) => {
        if (
            deletePlaylistMessage.show === true &&
            deletePlaylistMessage.index === index
        ) {
            setDeletePlaylistMessage({
                index: index,
                show: false,
            });
        }
        setDeleteIconShow({
            show: true,
            index: index,
        });
    };
    // When clicking on right icon to remove playlist
    const onHandleDeleteClick = (id) => {
        dispatch(deleteWebsiteVideoLibraryPlaylist({playListId: id}));
        setStateUpdate(stateUpdate + 1);
        setButtonDisable(true);
       
        // setTimeout(() => {
        //     dispatch(getPlaylistsWebsiteVideoLibrary(state.practiceUserLoginInfo));
        // }, 700);
        let arr = [];
        let allVideos = [];
        let selectedVideos = [];
        
        allCategoryList.forEach((category) => {
            category.videos.forEach((video) => {
                var videoIndex = allVideos.findIndex(x => x.website_media_id == video.website_media_id);
                if(videoIndex > -1){
                    allVideos[videoIndex] = {
                        ...video,
                        categoryName: `${allVideos[videoIndex].categoryName}, ${category.title}`,
                        selected: video.website_media.is_predefined_media === 1,
                    };
                }else{
                    allVideos.push({
                        ...video,
                        categoryName: category.title,
                        selected: video.website_media.is_predefined_media === 1,
                    });
                    if (video.website_media.is_predefined_media === 1) {
                        selectedVideos.push({
                            ...video,
                            selected: true,
                        });
                    }
                }
            });
        });
        // allCategoryList.forEach((element) => {
        //     element.videos.forEach((video) => {
        //         allVideos.push({
        //             ...video,
        //             selected: false,
        //         });
        //     });

        //     list.forEach((datum, index) => {
        //         if (
        //             element.id == datum.media_category_id &&
        //             datum.website_media.is_free == 1
        //         ) {
        //             arr.push({ ...datum, selected: false });
        //         }
        //     });
        // });

        if(checkPlaylist){
            setAllVideosIndex(true);
            settotalVideos(allVideos);
        } 
     
        // setselectedVideos(arr);
        // submitVideoLists([]);
        // if (state.practiceInfo.is_paid_account === 0) {
        //     setselectedVideos(arr.splice(0, 25));
        // } else if (state.practiceInfo.is_paid_account === 1) {
        //     setselectedVideos(arr.splice(0, 100));
        // }
    };
    // On Click Cancel Button in Delete
    const handleCloseClick = () => {
        setDeletePlaylistMessage({
            index: 0,
            show: false,
        });
    };

    // Show All Videos
    const changeAllVideos = () => {

        // play list
        setCheckPlaylist(false);
        allPlaylists.forEach((element) => {
            element.selectedPlayList = false;
        });
        setAllPlaylists(allPlaylists);
        // play list
        
        setStateUpdate(stateUpdate + 1);
        setAllVideosIndex(true);
        allCategoryList.forEach((element) => {
            if (element.selected == true) {
                element.selected = false;
            }
        });

        let allVideos = [];
        let selectedVideos = [];

        allCategoryList.forEach((category) => {
            category.videos.forEach((video) => {
                var videoIndex = allVideos.findIndex(x => x.website_media_id == video.website_media_id);
                if(videoIndex > -1){
                    allVideos[videoIndex] = {
                        ...video,
                        categoryName: `${allVideos[videoIndex].categoryName}, ${category.title}`,
                        selected: video.website_media.is_predefined_media === 1,
                    };
                }else{
                    allVideos.push({
                        ...video,
                        categoryName: category.title,
                        selected: video.website_media.is_predefined_media === 1,
                    });
                    if (video.website_media.is_predefined_media === 1) {
                        selectedVideos.push({
                            ...video,
                            selected: true,
                        });
                    }
                }
            });
        });

        settotalVideos(allVideos);
        setselectedVideos(selectedVideos);
    };

    // Create New Playlist
    const createNewPlayList = async () => {
        var isPaid = practiceInfo.is_paid_account == 0;
        var isTrialExpire = practiceInfo.days_left == 0;
        const idArray = [];
        if (isPaid && isTrialExpire) return; // trail add free video
        totalvideos.forEach((video) => {
            if(video.selected){
                idArray.push({
                    id: video.id,
                });
            }
        });
        if(idArray.length > 0){
            await  dispatch( postWebsiteVideoLibraryPlaylist(idArray));
            await  setSavePlayListMessage({
                message: 'Playlist Saved',
                color: 'black',
                bgColor: 'green',
            });
 

            await  dispatch(getPlaylistsWebsiteVideoLibrary(state.practiceUserLoginInfo));
            await  setSavePlayListMessage({
                message: 'save playlist',
                color: 'black',
                bgColor: 'primary',
            });
        }
    };
    // Create New Timeline
    const createNewTimeline = () => {
        const idArray = [];
        selectedvideos.forEach((video) => {
            console.log(video,'video')
            if ( video.selected) {
                
                idArray.push( video.website_media_id);
            }
        });
        dispatch(
            postSaveTimelineWebsiteVideoLibrary(state.practiceUserLoginInfo, idArray)
        );
        setSaveWebsiteMessage({
            message: 'Changes Saved',
            color: 'black',
            bgColor: 'green',
        });
        // setTimeout(() => {
        //     dispatch(getPlaylistsWebsiteVideoLibrary(state.practiceUserLoginInfo));
        // }, 700);

        setTimeout(() => {
            setSaveWebsiteMessage({
                message: 'save to website',
                bgColor: 'primary',
            });
        }, 3000);
    };

    const onPublishClick = () => {
        dispatch(publishWebsiteMedia());
        setPublishMessage({
            message: 'Published',
            bgColor: 'green',
        });
        setTimeout(() => {
            setPublishMessage({
                message: 'Publish',
                bgColor: 'primary',
            });
        }, 3000);
    }

    // Show all videos active or not
    const allVideoListActive = allVideosIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;

    return (
        <>
            <div  className='website_videos_video_selection flex-column d-flex transparent__form--white mr-20' style={{  border: state.practiceInfo.is_paid_account == 0 && state.practiceInfo.days_left == 0 && state.practiceInfo.status !== 800 ? '2px solid red' : 'none', }}>
                <div className='videolist__main__lists'>
                    <div className='videolist__main__header'>
                        <p className='videolist__main__header__header-title'>
                            {state.practiceInfo.is_paid_account == 0 && state.practiceInfo.days_left == 0 && state.practiceInfo.status !== 800
                                ? 'Videos selection disabled'
                                : 'Video selection'}
                        </p>
                    </div>
                    <p>
                        {' '}
                        {state.practiceInfo.is_paid_account == 0 && state.practiceInfo.days_left == 0 && state.practiceInfo.status !== 800 
                            ? 'Your trial period has expired, and your website video player has now access to 20 pre-selected videos only. By subscribing, it is possible to customize a selection of up to 100 videos from our library, as well as your website video player layout and color scheme.'
                            : 'Select up to 50 to be viewed by your patients via the website player, available to any of your authorized domains.'}
                    </p>
                    <div className='videolist__submain__header'>
                        <img
                            src={allVideoListActive}
                            alt=''
                            onClick={() => changeAllVideos()}
                            className='videolist__submain__header__checkbox mr-4'
                        />
                        <span className='videolist__submain__header__title'>
                            Show All Videos {`(${allVideosCount})`}
                        </span>
                    </div>
                    <hr className='videolist__main__lists_hr' />
                    <div className="videolist__main__lists__scroll_section">
                    {allCategoryList && allCategoryList.length > 0 ? allCategoryList.map((item, index) => {
                            const TYPE = 'lists';
                            const active = item.selected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
                            return (
                                <React.Fragment key={index}>
                                    <div className='videolist__main__lists__videoselection d-flex'>
                                        <img src={active} alt='' onClick={() => onCategoryClick(item, TYPE)} className='videolist__submain__header__checkbox mr-4' />
                                        <span className='videolist__submain__header__title text-nowrap'>
                                            {item.title} {`(${item.videosLength})`}
                                        </span>
                                    </div>
                                </React.Fragment>
                            );
                        })
                        :
                        <React.Fragment >
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                            <div className='videolist__main__lists__videoselection d-flex'>
                                <span className='videolist__submain__header__title text-nowrap'>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    width={350}
                                    height={22}
                                />
                                </span>
                            </div>
                        </React.Fragment> 
                        }
                    
                        {allCategoryList && allCategoryList.length > 0 ?
                            <>
                                <hr className='videolist__main__lists_hr' />
                            </>
                            : null}
                    
                    {showPlayLists}
                    </div>
                </div>
                <div className='videolist__main__button mt-auto'>
                    {selectedvideos.length > 100 ? (
                        <Button className='w-100' color="red">
                            SELECT NO MORE THAN 100 VIDEOS.
                        </Button>
                    ) : state.practiceInfo.is_paid_account == 0 && state.practiceInfo.days_left == 0 && state.practiceInfo.status !== 800 ? (
                        <Button color="primary" onClick={() =>  history.push("/Subscribe")} className='w-100'>
                            SUBSCRIBE NOW
                        </Button>
                    ) : (
                        <>
                            <div className="d-flex mb-20">
                                <LoadingButton type="button" color={saveWebsiteMessage.bgColor} loading={state.buttonLoading2}   className="col mr-3 px-2" disabled={buttonDisable || state.buttonLoading2 ? true : ''} label={saveWebsiteMessage.message}  onClick={buttonDisable ? null : () => createNewTimeline()}  />
                                <LoadingButton type="button" color={savePlaylistMessage.bgColor} loading={state.buttonLoading3}   className="col ml-3 px-2" disabled={buttonDisable || state.buttonLoading3 ? true : ''} label={savePlaylistMessage.message}  onClick={buttonDisable ? null : () => createNewPlayList()}  />
                            </div>
                            <LoadingButton type="button" color={savePublishMessage.bgColor} loading={state.buttonLoading}  className="w-100" disabled={state.buttonLoading} label={savePublishMessage.message} onClick={() => onPublishClick()}  />
                        </>
                    )}
                     
                </div>
            </div>
        </>
    );
};
export default VideoListThree;