import moment from "moment";

export let initialArray = [
  {
    id: 1,
    day_week: 1,
    name: "Sunday",
    checkCli: false,
    AftercheckCli: false,
    working: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
    after: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
  },
  {
    id: 2,
    day_week: 2,
    name: "Monday",
    checkCli: false,
    AftercheckCli: false,
    working: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
    after: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
  },
  {
    id: 3,
    day_week: 3,
    name: "Tuesday",
    checkCli: false,
    AftercheckCli: false,
    working: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
    after: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
  },
  {
    id: 4,
    day_week: 4,
    name: "Wednesday",
    checkCli: false,
    AftercheckCli: false,
    working: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
    after: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
  },
  {
    id: 5,
    day_week: 5,
    name: "Thursday",
    checkCli: false,
    AftercheckCli: false,
    working: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
    after: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
  },
  {
    id: 6,
    day_week: 6,
    name: "Friday",
    checkCli: false,
    AftercheckCli: false,
    working: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
    after: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
  },
  {
    id: 7,
    day_week: 7,
    name: "Saturday",
    checkCli: false,
    AftercheckCli: false,
    working: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
    after: {
      fromHour: "00",
      fromMinute: "00",
      fromTimeFormat: "00",
      toHour: "00",
      toMinute: "00",
      toTimeFormat: "00",
    },
  },
];

export const formatApiResponse = (data) => {
  data?.map((item) => {
    initialArray = initialArray?.map((obj) => {
      if (item.day_week === obj.day_week) {
        obj.checkCli = Boolean(item.working_hours_status);
        obj.AftercheckCli = Boolean(item.after_hours_status);
        obj.working.fromHour = moment(item.working_hours_from, "hh:mm").format(
          "hh"
        );
        obj.working.fromMinute = moment(
          item.working_hours_from,
          "hh:mm"
        ).format("mm");
        obj.working.fromTimeFormat = moment(
          item.working_hours_from,
          "hh:mm"
        ).format("a");
        obj.working.toHour = moment(item.working_hours_to, "hh:mm").format(
          "hh"
        );
        obj.working.toMinute = moment(item.working_hours_to, "hh:mm").format(
          "mm"
        );
        obj.working.toTimeFormat = moment(
          item.working_hours_to,
          "hh:mm"
        ).format("a");

        obj.after.fromHour = moment(item.after_hours_from, "hh:mm").format(
          "hh"
        );
        obj.after.fromMinute = moment(item.after_hours_from, "hh:mm").format(
          "mm"
        );
        obj.after.fromTimeFormat = moment(
          item.after_hours_from,
          "hh:mm"
        ).format("a");
        obj.after.toHour = moment(item.after_hours_to, "hh:mm").format("hh");
        obj.after.toMinute = moment(item.after_hours_to, "hh:mm").format("mm");
        obj.after.toTimeFormat = moment(item.after_hours_to, "hh:mm").format(
          "a"
        );
      }
      return obj;
    });
  });

  return initialArray;
};
