import React from 'react';

import { Button , Label , FormGroup, Form, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../../pages/index';
// import ReactCodeInput from 'react-verification-code-input';
import ReactCodeInput from 'react-code-input';
import PhoneInput from 'react-phone-input-2'
import LoadingButton from '../../../components/LoadingButton'

import { connect } from "react-redux";
import { compose } from 'redux';

import PhoneVerify from '../../../components/phoneVerify';

import {UpdatePracticeUserProfileRequest , PracticeUserUpdatePasscode , setUpdatePasscodeRequest , cleardata , GetPasscodeRecovery} from "../../../store/actions/taskAction";

class Review extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        hidden: true,
        hiddennew:true,
        isGetCode:false,
        isGetVerifyCode:false,
        practiceInfo:this.props.practiceInfo,
        isVerify:false,
        practiceUserUpdatePasscodeInfo:props.practiceUserUpdatePasscodeInfo,
        minutes: 5,
        seconds: 0,
        time:0,
        isRecovry:false,
        isSave:false,
        error:''
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.toggleShowNew = this.toggleShowNew.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  toggleShowNew(){
    this.setState({ hiddennew: !this.state.hiddennew });  
  }

    nextClick(){
        this.props.nextStep();
    }

    prevClick(){
        this.props.prevStep();
    }
        
    // handleClick(){
    //     if(this.props.practiceInfo.need_owner_setup === 0){
    //         history.push('/profilesetup');
    //     }else{
    //         history.push('/profilesetup');
    //     }
    // } 

    
    onClickUpdagtePasscode(values){
        var  params = {
            passcode: values.newpasscode,
            old_passcode:values.oldpasscode,
        }
        this.props.dispatch(PracticeUserUpdatePasscode(params));
    }
    
  async componentDidMount(){
    this.props.dispatch(cleardata());
    this.props.dispatch(setUpdatePasscodeRequest());
  }

  async onEditPracticeInfo(field , value){

    let practiceUserProfileInfo = this.props.practiceUserProfileInfo
    practiceUserProfileInfo[field] = value
    await this.props.dispatch(UpdatePracticeUserProfileRequest(practiceUserProfileInfo))

    await this.isValid()
  }

  isValid(){
    this.setState(this.validateForm)
  }

  validateForm(){
    const { newpasscode , oldpasscode  } = this.props.practiceUserProfileInfo;
    // if ( (!/[0-9]/.test(newpasscode)) || (!/[0-9]/.test(oldpasscode))) {
    //   this.setState({error: 'Please Input Number Only'})
    // }
    console.log(newpasscode,'newpasscode',oldpasscode)
    if(  newpasscode && oldpasscode && newpasscode.length == 4 && oldpasscode.length == 4 ){
      if ( (!isNaN(newpasscode)) && (!isNaN(oldpasscode))) {
        this.setState({ isSave: true , error: '' });
      }else{
        this.setState({error: 'Please Input Number Only'})
      }
     
    }else{
      this.setState({ isSave: false });
    }
  }


  resend(){
    this.myInterval = setInterval(() => {
        const { seconds, minutes } = this.state

        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1
          }))
        }else{
          this.setState({ isGetCode: true })
          this.setState({ isRecovry: false })
        }
          if(this.seconds === 0){
            clearInterval(this.myInterval)
          }
        
      }, 1000)
  }

  onRememberPasscode(){
    this.props.dispatch(GetPasscodeRecovery(this.props.practiceUserProfileInfo.id));
    this.setState({isRecovry:true , seconds:59})
    this.resend()
  }
    

  render (){
    const {t , values , practiceUserProfileInfo } = this.props
    return(
        <div className="about__screen transparent__form--white  md d-flex flex-column align-items-stretch mb-auto">
           {practiceUserProfileInfo.selectedProfileType.id === 0 ?
           <div className="d-flex flex-column text-center my-auto unsecure-profile">
            <h4 className="mb-4">Your profile is set as “Unsecure”</h4>
            <p>Your profile can currently be accessed by anyone signed in to Thalamus using your practice access code. To make your profile “Secure”, please select the “About” tab above and make the change on the “Profile type” dropdown. Once done, visit the “Security” tab once more to register your mobile number and set your own profile passcode.</p>
           </div>
           : 
          <Form className="h-100 d-flex flex-column">
            <FormGroup className="mb-20">
                <h4 className="m-0 mb-4">Mobile number</h4>
                <p className="m-0 pt-1">Keeping your mobile phone number updated helps securing your privacy.</p>
            </FormGroup>

            <PhoneVerify isEditProfile={true} values={practiceUserProfileInfo}/>
            
            <FormGroup className="my-2 py-3 form-group">
                <div className="border--bottom"></div>
            </FormGroup>
            <fieldset >
                <FormGroup className=" mt-4 pt-1">
                    <h4  className="m-0 mb-4">Update passcode</h4>
                </FormGroup>
                <FormGroup className={`mb-3 mt-5 ${this.props.error || this.state.error ?  'notverifyed' : ''}`}>
                    <div className="d-flex justify-content-between">
                        <label className="fs-14"><span className="text--primary">OLD</span> passcode</label><Button onClick={this.toggleShow} color="link primary" className="p-0 text-uppercase">{this.state.hidden ? 'SHOW' : 'Hide'}</Button>
                    </div>
                    <ReactCodeInput autoFocus={false} type={this.state.hidden ? 'password' : 'number'} onChange={(options) => this.onEditPracticeInfo('oldpasscode',options)}   fields={4} className="mb-2"/>
                    
                </FormGroup>
                <FormGroup  className={`mb-4 ${this.props.error || this.state.error ?  'notverifyed' : ''}`}>
                    <div className="d-flex justify-content-between">
                        <label className="fs-14"><span className="text--primary">NEW</span> passcode</label><Button onClick={this.toggleShowNew} color="link primary" className="p-0 text-uppercase">{this.state.hiddennew ? 'SHOW' : 'Hide'}</Button>
                    </div>
                    <ReactCodeInput autoFocus={false}  type={this.state.hiddennew ? 'password' : 'number'} onChange={(options) => this.onEditPracticeInfo('newpasscode',options)}   fields={4} className="mb-2"/>
                </FormGroup>
                <FormGroup>
                  {this.state.error && <span className='text--danger'>{this.state.error}</span>}
                </FormGroup>
                {this.state.isRecovry ?
                    <p className="m-0">Your passcode has been sent to you via SMS.</p>
                    :
                    <span>Can’t remember your passcode? <Button type="button" color="link primary" onClick={() => this.onRememberPasscode()} className="p-0">Click here.</Button></span>
                }
            </fieldset>
            <div className="">
                <Button type="button" size="xxl" disabled={!this.state.isSave} onClick={() => this.onClickUpdagtePasscode(practiceUserProfileInfo)} className={`${this.props.practiceUserUpdatePasscodeInfo === true ? 'verifyed' : ''} mt-5`} color="primary">
                   {!this.props.practiceUserUpdatePasscodeInfo ?
                    <>Save changes</>
                    :
                    <>Changes saved</>
                    }
                </Button>
            </div>
            </Form>
            }        
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingOwnerVerifyPhone:state.task.loadingOwnerVerifyPhone,
  practiceInfo: state.task.practiceInfo,
  phoneVerifyed:state.task.phoneVerifyed,
  phoneVerificationInfo:state.task.phoneVerificationInfo,
  practiceUserProfileInfo:state.task.practiceUserProfileInfo,
  practiceUserUpdatePasscodeInfo:state.task.practiceUserUpdatePasscodeInfo,
  error:state.task.error,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Review);
