import React, { useState } from 'react'
import { history } from '../../pages/index';
import Skeleton from "@material-ui/lab/Skeleton";



const ImageLoad = ({className , src, alt, onClick}) => {
    const [imageLoaded, setImageLoaded] = useState(false);


    const isImageLoaded = (val) =>{
        setImageLoaded(val)
    }

  return (
      <>
      {!imageLoaded &&
        <div style={{position:'absolute',top:'0',left:'0'}}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={200}
                height={102}
            />
        </div>
      }
        <img src={src} alt={alt} className={`${className} ${imageLoaded ? 'd-block' : 'd-none'}`} onClick={()=>onClick} onLoad={() => isImageLoaded(true)} />
      </>
  )
}

export default ImageLoad
