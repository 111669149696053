import React from 'react';

import { withTranslation } from 'react-i18next';
import { Button ,  FormGroup , Spinner} from 'reactstrap';

import { connect } from "react-redux";
import { compose } from 'redux';
import ReactCrop from 'react-image-crop';

import { GetDefaultProfilePicture , CreateNewProfile , clearloading} from "../../../store/actions/taskAction";
import Profile_icon from '../../../img/Profile_icon.svg'

import FileUpload from '../../../components/FileUpload'
import LoadingButton from '../../../components/LoadingButton'
import 'react-image-crop/lib/ReactCrop.scss';
import moment from 'moment';
import './style.scss';


class AddProfilePicture extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
        defaultProfilePictures:props.defaultProfilePictures,
        newProfileInfo:{
            ...props.values,
            file:null
        },
        isVerify:false,
        isFile:false,
        src: null,
        blobFile:'',
        selectedimg:'',
        isImageLoad: false,
        crop: {
            aspect: 1 / 1,
            unit: '%',
            x: 0,
            y: 0,
            width: 30,
            height: 30,
        },
        loaded:props.progress,
        completed:props.isCompleted,
        file:null,
        imgUpload:'',
        croppedImageUrl:'',
        errors:{
            fileSize:'',
            fileType:'',
        }
     
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.createUserProfile = this.createUserProfile.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
    this.selectImage = this.selectImage.bind(this);
    
  }

    nextClick(){
        this.props.nextStep();
    }
    
    prevClick(){
        this.props.prevStep();
    }

    async componentDidMount(){
        await  this.props.dispatch(GetDefaultProfilePicture());

    }

    onChange(e) {     
        this.props.dispatch(clearloading());   
        if (this.validateFile(e.target.files[0])) {
            if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
                reader.addEventListener(
                    'load',
                    () =>
                    this.setState({
                        src: reader.result,
                    }),
                    false
                )
                reader.readAsDataURL(e.target.files[0])
            }
            this.setState({
                newProfileInfo:{
                    ...this.state.newProfileInfo,
                    file:e.target.files[0], 
                },
                isFile:true , 
                completed:false, 
                loaded:0 , 
                errors:{filesize:'', fileType:''}
            })
        }else{
            this.setState({
                newProfileInfo:{
                    ...this.state.newProfileInfo,
                    file:e.target.files[0], 
                },
                isFile:false , 
                completed:false, 
                loaded:0 , 
            })
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
        // this.imageRef.crossOrigin = 'anonymous';
        this.setState({ isImageLoad: true ,  completed:false,  loaded:0 });
        this.props.dispatch(clearloading());
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    }

    onCropChange = crop => {
        this.setState({ crop  })
    }

    cancelUpload(){
        this.setState({ 
            newProfileInfo:{
                ...this.state.newProfileInfo,
                file:null, 
            },
            src:null
        })
    }

   async createUserProfile(e){
        e.preventDefault() 
        if(this.state.blobFile){
            this.setState({ isUpload: true }, async () => {
                e.preventDefault() 
                        const formData = new FormData();
                    formData.append(
                        'profile_pic',this.state.blobFile,
                    )
                    formData.append(
                        'first_name',this.state.newProfileInfo.first_name,
                    )
                    formData.append(
                        'last_name',this.state.newProfileInfo.last_name || '',
                    )
                    formData.append(
                        'type',1,
                    )
                    formData.append(
                        'role',this.state.newProfileInfo.selectedRole.id,
                    )
                    formData.append(
                        'is_secure',this.state.newProfileInfo.selectedProfileType.id,
                    )
                    if(this.state.newProfileInfo.selectedProfileType.id === 1){
                        if(this.props.phoneVerificationInfo?.phone){
                            formData.append('phone',this.props.phoneVerificationInfo?.phone?.replace(/\s+/g, ''))
                            formData.append('otp_media', 'sms')
                        }
                        if(this.props.phoneVerificationInfo?.email){
                            formData.append('email',this.props.phoneVerificationInfo?.email?.replace(/\s+/g, ''))
                            formData.append('otp_media', 'email')
                        }
                        formData.append('request_secrete', this.props.phoneVerificationInfo.request_secrete)
                        formData.append('otp', this.props.phoneVerificationInfo.verificationCode)
                        formData.append('passcode',this.state.newProfileInfo.passcode)
                    }
                
                    await this.props.dispatch(CreateNewProfile(formData));
                    await this.props.dispatch(clearloading());
            })
        }
    }

    selectImage(url){
        this.setState({ 
            newProfileInfo:{
                ...this.state.newProfileInfo,
                file:url+"?time="+moment().unix(), 
            },
            src:url+"?time="+moment().unix()
        })
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });
        }
    }

    

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        const ctx = canvas.getContext('2d');
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        );
    
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                console.log("blob", blob)
                this.setState({blobFile:blob}) // Set blob image inside the state here 
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    validateFile(file){
        var isError = false;
        var errors = this.state.errors;

        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        
        if (validTypes.indexOf(file.type) === -1) {
            isError = true;
            errors = {
                fileType: 'Format not accepted',
            }
            
        }else if(file.size > 10485760){
            isError = true;
            errors = {
                fileSize: 'Max 10Mb',
            }
        }
        if(isError){ 
            this.setState({errors , file:file , loaded:5});
            return;
        }else{
            return true;
        }
    }


    
  render (){
    const defaultProfilePictures = this.props.defaultProfilePictures
    const {t , loading , values , error , loadingCreateProfile} = this.props
    const { crop, src , croppedImageUrl , loaded} = this.state;
    // const topic = this.props.WaitingRoomSlideStockImages.find(x=>x.id == 1)

    return(
        <div className="add_profile_picture__screen  md d-flex align-items-stretch mb-auto">
            <form onSubmit={this.createUserProfile}>
                <div className="transparent__form--white ">
                    <FormGroup className="mb-5">
                        <h4>Select an image for your profile, or upload your own</h4>
                    </FormGroup>
                    <div className="profile_picture_img_box d-flex justify-content-center">
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                imageStyle={{height: '100%', width: '100%'}}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                crossorigin='anonymous'
                            />
                        )}
                    </div>
                    <div className="mt-4">
                        <FileUpload note={'Upload a file to see it displayed here'} onChange={this.onChange} cancelUpload={this.cancelUpload} completed={this.props.isCompleted} loaded={this.props.progress} file={this.state.newProfileInfo.file}  errors={this.state.errors}/>
                    </div>
                    <div className="mt-auto d-flex ">
                        <LoadingButton type="submit" error={this.props.error} label2={'Create user profile'} label={'Create user profile'} loading={loadingCreateProfile} disabled={!this.state.isImageLoad || loadingCreateProfile} size="lg" className={`mt-5 mr-3 col`} color="primary"/>
                        <Button type="button"  className="mt-5 ml-3 col" onClick={ this.prevClick } outline color="default white">{t('btn.back')}</Button>
                    </div>
                </div>
            </form>
            <div className="d-flex flex-wrap row-cols-4 new_profile__section mx-auto pl-5 align-content-start">
                {defaultProfilePictures && defaultProfilePictures.map((images , index) => {
                    return  (<div key={index} onClick={() => this.selectImage(images.url)}   className="new_profile__img  mx-20 mb-5">
                                <div className={`new_profile__box d-flex flex-column justify-content-end`}>
                                    <div className="new_profile__picture">
                                        <button className="btn-link btn p-0 h-100 w-100"  type="button"><img  className="mx-auto my-auto" alt={images.name} src={ images.url}/></button>
                                    </div>
                                </div>
                            </div>)
                })}
                
            </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    loading: state.task.loading,
    defaultProfilePictures: state.task.defaultProfilePictures,
    progress:state.task.progress,
    error:state.task.error,
    loadingCreateProfile:state.task.loadingCreateProfile,
    phoneVerificationInfo:state.task.phoneVerificationInfo,
    WaitingRoomSlideStockImages:state.task.WaitingRoomSlideStockImages
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AddProfilePicture);
