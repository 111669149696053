import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/Language.png'
import Image2 from './../../../img/help/Language_2.png'

const Languages =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <> 
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Languages</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc">
                                        <h5>Changing the language</h5>
                                        <div className="help_detail">
                                              <p>To update or change the language, simply click the “language” icon (shaped like a globe) located on the software header.

                                               
                                                </p>
                                                <p> Here you will be able to change the “system language” (for the Thalamus interface and menus) and “media language” (for the audio used in videos and stock content present in Thalamus). </p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image1} className="w-100" alt=""  />
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"-35px",  }}>
                                        <h5>Will there be more languages?</h5>
                                        <div className="help_detail">
                                           <p>We are expanding languages rapidly, and if there is one you would like us to add, let us know!</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-232px", }}>
                                      <img src ={Image2} className="w-100" alt="" />
                                  </div>
                            </div>
    
                      </div>
                  </>
            
    ) 

}

export default Languages;
