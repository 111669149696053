/** @format */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import WaitingRoomCategoriesList from '.';
import { useSelector, useDispatch } from 'react-redux';
import WaitingRoomVideoSelected from './WaitingRoomVideoSelected';

const WaitingRoomTimelineEducation = ({ educationTimeline, timeLineList, setModal, onPublish}) => {
	const [selectedVideos, setSelectedVideos] = useState([]);
	// const state = useSelector((state) => state.task);
    useEffect(() => {
		const categoryArray = [];
		const videos = [];
		educationTimeline.map((element) => {
			categoryArray.push({
				category_id: element.categories_id,
				category_name: element.categories_name,
				selected: false,
				videosLength: element.video.length,
			});
			element.video.map((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				console.log(video,'timeLineList')
				videos.push({
					...video,
					category_id: element.categories_id,
					category_name: element.categories_name,
					selected: false,
					isAdded,
				});
			});
		});
		setSelectedVideos(videos);
	}, [timeLineList]);
	
	const setVideos = (videos) => {
		setSelectedVideos(videos);
	};

	return (
		<>
			<div className="waitiing-room-col-sec">
				<div className="waiting-room-left-sec">
					<WaitingRoomCategoriesList
							specialization={educationTimeline}
							setVideos={setVideos}
							timeLineList={timeLineList}
							setModal={setModal}
							onPublish={onPublish}
						/>
				</div>
				<div className="waiting-rom-right-sec">
					
						<WaitingRoomVideoSelected
							videos={selectedVideos}
							setVideos={setVideos}
						/>
				</div>	
			</div>
		</>
	);
};

export default WaitingRoomTimelineEducation;
