import React from 'react';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import BrandLogo from '../../img/logo.svg'
import InvoiceIcon from '../../img/InvoiceIcon.svg'
import IconLocationPin from '../../img/IconLocationPin.svg'
import IconEmail from '../../img/IconEmail.svg'
import IconPhone from '../../img/IconPhone.svg'
import { InvoiceViewList, cleardata, UpdateInvoiceEmail, getInvoiceEmails } from "../../store/actions/taskAction";
import { DateDDMMMYYY } from '../../utilities/HttpUtility';
import moment from 'moment';
import './style.scss';
import { Button } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var selectedPracticeId
var isCorporate = false;
class HomeIndex extends React.Component {
  constructor(props) {
    super(props);
    var practiceName = "";
    console.log("invoice view===>", props);
    if(props && props.match && props.match.params && props.match.params.practiceId && props.isCorporate == 1){
      selectedPracticeId = props.match.params.practiceId;
      isCorporate = true;
      var practice = props.designateCard.data.find((dCard) => dCard.id ==  selectedPracticeId );
      if(practice){
        practiceName = practice.name;
      }
    }
    this.state = {
      email: "",
      statementEmail: "",
      statementEmailAllow: 0,
      numPages: null,
      practiceName,
    }
  }

  async componentDidMount() {
    this.props.dispatch(InvoiceViewList(this.props.history.location.state.invoice));
    this.props.dispatch(getInvoiceEmails());
    // this.props.dispatch(cleardata());
    let emailDetail = this.props.getInvoiceEmails && this.props.getInvoiceEmails.data
    this.setState({ email: emailDetail.email, statementEmail: emailDetail.statement_email, statementEmailAllow: emailDetail.need_statement_on_email })
  }
  updateEmailDetail() {
    let body = {
      statement_email: this.state.statementEmail,
      need_statement_on_email: this.state.statementEmailAllow
    }
    this.props.dispatch(UpdateInvoiceEmail(body));
  }

  onPdfLoad({ numPages }){
    if(numPages) this.setState({ numPages });
  }

  render() {
    const invoice_View = this.props.invoice_View || {}
    const setup_fee =  this.props.invoice_View && this.props.invoice_View.items.find(x => x.type === "setup_fee");
    const subscription =  this.props.invoice_View && this.props.invoice_View.items.find(x => x.type === "subscription");
    const device =  this.props.invoice_View && this.props.invoice_View.items.find(x => x.type === "device");
    const domain =  this.props.invoice_View && this.props.invoice_View.items.find(x => x.type === "domain");
    const hardware =  this.props.invoice_View && this.props.invoice_View.items.find(x => x.type === "hardware");
    const tax =  this.props.invoice_View && this.props.invoice_View.items.find(x => x.type === "tax");
    const partial_charge_deduction =  this.props.invoice_View && this.props.invoice_View.items.find(x => x.type === "partial_charge_deduction");
    var ext_details = null;
    if(hardware && hardware.ext_details){
      ext_details = JSON.parse(hardware.ext_details);
    }
    console.log(ext_details,'setup_fee')
    console.log('Invoice View Data is ', invoice_View);
    return (
      <div className="main__screen invoice__page  d-flex flex-column h-100 align-items-center justify-content-center" >
        <SubHeader className="mb-auto" title={isCorporate ? this.state.practiceName : "Invoices"} />
        <div className="d-flex w-100 mb-auto">
          <div className="invoice__inner mb-auto mx-auto invoice-view">
            <div className="invoice-box " style={{ height: 850 }}> {/*h-1008*/}
            {invoice_View.invoice_data &&
              <Document
                  renderMode="svg"
                  className="practice-invoice"
                  // loading={<div>Loading....</div>} 
                  file={`data:application/pdf;base64,${invoice_View.invoice_data}`} 
                  onLoadSuccess={this.onPdfLoad.bind(this)} >
                    {this.state.numPages && [...Array(this.state.numPages).keys()].map((page, index) => 
                        <Page key={index} pageNumber={page+1} width="820"/>
                    )}
              </Document>
            }
            </div>
          </div>
          <div className="invoice__suggestion d-flex flex-column align-items-flex-start justify-content-center">
            <h4>Receive monthly statements on</h4>
            <div className="form-group mt-4 mb-0">
              <div className="custom_check">
                <input type="checkbox" name="receive_statements_reg" id="receive_statements_reg" checked />
                <label for="receive_statements_reg">Registered email</label>
              </div>
              <input value={this.state.email} disabled type="email" className="form-control" placeholder="Enter email here" />
            </div>
            <div className="divider"></div>
            <div className="form-group">
              <div className="custom_check">
                <input type="checkbox" onChange={() => { this.setState({ statementEmailAllow: this.state.statementEmailAllow === 0 ? 1 : 0 }) }} checked={this.state.statementEmailAllow === 0 ? false : true} name="receive_statements_other" id="receive_statements_other" />
                <label for="receive_statements_other">Another email</label>
              </div>
              <input value={this.state.statementEmail} onChange={(e) => { this.setState({ statementEmail: e.target.value }) }} type="email" className="form-control" placeholder="Enter email here" />
            </div>
            <Button size="md" color="primary" onClick={() => { this.updateEmailDetail() }}>Save Changes</Button>
          </div>
        </div>
      </div>
    )
  }

}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  invoice_View: state.task.invoiceView,
  getInvoiceEmails: state.task.getInvoiceEmails,
  invoiceMailUpdated: state.task.invoiceMailUpdated,
  isCorporate: state.task.isCorporate,
  designateCard: state.task.designateCard,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(HomeIndex);
