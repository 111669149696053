import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import moment from 'moment';
import ReactCodeInput from 'react-code-input';
import { Button, Label, FormGroup, Form, Col, Row } from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import DatePicker from "react-datepicker";
import { InputField } from '../../components/InputField';
import SubHeader from '../../components/Header/subHeader'
import DropDown from '../../components/DropDown'
import LoadingButton from '../../components/LoadingButton'
import { DateFormat, isRequired, isValidEmail } from '../../data/comman'
import { history } from '../index';
import PhoneVerify from '../../components/phoneVerify';
import Loading from '../../components/Loading'

import { setToken , setSystemLanguage } from '../../helper/LocalStorage';

import { GetPracticeMoreInfo , clearerror , GetUserProfile , GetAuthorizedUser ,GetpracticeSecurityQuestion, Getgender, UpdateAuthorizedUser, UpdateAuthorizedUserRequest, GetPhoneVerification, VerifyOtp, UpdateUserProfileAboutInfo, UpdateUserProfile, DeleteUserProfile, cleardata, getPracticeSecuritySuccess } from "../../store/actions/taskAction";
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
var formIsValid = true
class OwnersProfileSetupIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
       selectedYear: null,
      selectedMonth: null,
      selectedDay: null,
      isVerify: false,
      isGetCode: false,
      hidden: true,
      formValid: false,
      emailValid: false,
      isProfileCreated: false,
      isEnableVerify: false,
      getownersId: props.getownersId,
      istoggleDelete: false,
      i_agree: false,
      isDeleteProfile: false,
      userPasscode: '',
      practiceUsersList: this.props.practiceUsersList,
      isError: {
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        selectedGender: null,
        email: '',
        email_alt: '',
        phone: '',
        passcode: '',
        isPhoneverify: '',
        security_question: {
          question_1: '',
          question_2: '',
          question_3: '',
          answer_1: '',
          answer_2: '',
          answer_3: '',
        }
      },
      isLoading:false
    }

    this.handleClick = this.handleClick.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.onclickDelete = this.onclickDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

  }

  getUserType(id) {
    switch (id) {
      case 0:
        return ["guest", "Guest"];
      case 1:
        return ["dentist", "Normal User"];
      case 2:
        return ['authorized_user', "Authorized User"];
      case 3:
        return ['manager', "Manager"];
      case 4:
        return ['owner', "Owner"];
      default:
        return [' ', " "];
    }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleClick() {
    this.setState({ isVerify: true })
  }

  onChangeValue(field, value) {
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo
    practiceUserAccountInfo[field] = value
    this.props.dispatch(UpdateAuthorizedUserRequest(practiceUserAccountInfo))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.practiceUsersList !== this.props.practiceUsersList && this.props.practiceUsersList.length > 0) {
      // var owner = this.props.practiceUsersList ? this.props.practiceUsersList.find(x=>x.type === 4) : null
      // if(owner){
      //   this.props.dispatch(GetAuthorizedUser(owner.id));
      // }
      this.loadOwnerProfile()
    }

    if (prevProps.practiceUserAccountInfo !== this.props.practiceUserAccountInfo && this.props.practiceUserAccountInfo.is_verified_mobile == 1) {
      this.validateForm()
    }
  }

  async componentDidMount() {
    this.setState({isLoading:true})
    await this.props.dispatch(GetPracticeMoreInfo());
    await this.props.dispatch(GetAuthorizedUser(this.props.location.state.users.id))
    await this.loadOwnerProfile()
    this.setState({isLoading:false})
  }

  async loadOwnerProfile() {
    if (this.props.practiceInfo.need_owner_setup === 1) {
      var owner = this.props.practiceUsersList ? this.props.practiceUsersList.find(x => x.type === 4) : null
      if (owner) {
        await this.props.dispatch(GetAuthorizedUser(owner.id));
      }
    }

    await this.onChangeValue('isLoading', false)
    await this.onChangeValue('isUpdateSuccess', false)

    var practiceUserAccountInfo = this.props.practiceUserAccountInfo

    const initialDOB = practiceUserAccountInfo.dob;
    if (initialDOB) {

      const [year, month, day] = initialDOB.split('-');

      this.setState({
        selectedYear: year,
        selectedMonth: month,
        selectedDay: day,
      });
    }


    // await this.props.dispatch(GetpracticeSecurityQuestion());
    // await this.props.dispatch(Getgender());

    var gender;
    var question_1;
    var question_2;
    var question_3;

    // if (practiceUserAccountInfo && practiceUserAccountInfo.gender) {
      // gender = await this.props.getGender.find(x => x.id === practiceUserAccountInfo?.gender)
      // this.onChangeValue('gender', gender)
    // }

    // if (practiceUserAccountInfo && this.props.practiceSecurityQuestion.length > 0 && practiceUserAccountInfo.security_question) {

    //   question_1 = await this.props.practiceSecurityQuestion.find(x => x.name === practiceUserAccountInfo.security_question.question_1)
    //   await this.onChangeValue('question_1', question_1)

    //   question_2 = await this.props.practiceSecurityQuestion.find(x => x.name === practiceUserAccountInfo.security_question.question_2)
    //   await this.onChangeValue('question_2', question_2)

    //   question_3 = await this.props.practiceSecurityQuestion.find(x => x.name === practiceUserAccountInfo.security_question.question_3)
    //   await this.onChangeValue('question_3', question_3)
    // }
  }

  async onEditAuthorizedUserInfo(field, value) {
    this.onChangeValue('isUpdateSuccess', false)
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo
    const { selectedDay, selectedMonth, selectedYear } = this.state;

    if (field === "dob") {
      if (selectedDay && selectedMonth && selectedYear) {
        const dobValue = moment(`${selectedYear}-${selectedMonth}-${selectedDay}`, 'YYYY-MM-DD').format(DateFormat);
        practiceUserAccountInfo[field] = dobValue;
      } else {
        practiceUserAccountInfo[field] = null; // Mark dob as incomplete
      }
    } else if (field === "phone") {
      this.setState({ isGetCode: true })
      practiceUserAccountInfo[field] = value.replace(/\s+/g, '')
    } else if (field === "question_1" || field === "question_2" || field === "question_3") {
      practiceUserAccountInfo[field] = value;
      var selectedIndex = this.props.practiceSecurityQuestion.findIndex(x => x.id == value.id);
      if (selectedIndex > -1) {
        var practiceSecurityQuestion = this.props.practiceSecurityQuestion;
        practiceSecurityQuestion.splice(selectedIndex, 1);
        this.props.dispatch(getPracticeSecuritySuccess(practiceSecurityQuestion));
      }
    } else {
      practiceUserAccountInfo[field] = value
      console.log('check the practiceUserAccountInfo-- >', practiceUserAccountInfo)
    }
    await this.props.dispatch(UpdateAuthorizedUserRequest(practiceUserAccountInfo))
    await this.isValid(field, value)
  }



  isValid(name, value) {
    let isError = this.state.isError;
    let emailValid = this.state.emailValid;

    switch (name) {
      case "full_name":
        isError.first_name = value ? value.length < 4 ? "Atleast 4 characaters required" : "" : "Please enter nane";
        break;
      case "dob":
        isError.dob =
          value ? "" : "Please select Date of Birth";
        break;
      // case "gender":
      //   console.log(value,'value');
      //   if(isRequired(value)){
      //     isError.gender = "Please select geneder";
      //   }else{
      //     isError.gender = "";
      //   }
      //   break;
      case "email":
        if(isRequired(value)){
          isError.email = "Please enter email address";
        }else if(isValidEmail(value)){
          isError.email = "Invaild email address";
        }else{
          isError.email = "";
        }
        break;
      case "email_alt":
        if(value && value.trim() != ""){
          if(isValidEmail(value)){
            isError.email_alt = "Please enter valid email";
          }else{
            isError.email_alt = "";
          }
        }else{
          isError.email_alt = ""; 
        }
        break;
      default:
        break;
    }
    this.setState({ isError }, this.validateForm)


  }


  validateForm() {

    const { passcode } = this.props.practiceUserAccountInfo;

    if (this.props.practiceUserAccountInfo.verificationCode && this.props.practiceUserAccountInfo.verificationCode.length == 4) {
      this.setState({ isEnableVerify: true });
    } else {
      this.setState({ isEnableVerify: false });
    }

    if (this.props.practiceUserAccountInfo.is_secure === 1) {
      if (passcode && passcode.length == 4 && this.state.i_agree) {
        this.setState({ isDeleteProfile: true });
      } else {
        this.setState({ isDeleteProfile: false });
      }
    } else {
      if (this.state.i_agree) {
        this.setState({ isDeleteProfile: true });
      }
    }
    
  }

  onSubmitValidateForm(){
    let { practiceUserAccountInfo } = this.props;
    let { isError } = this.state;
    const { selectedYear, selectedMonth, selectedDay } = this.state;

    var error = false;
    
    if(isRequired(practiceUserAccountInfo.full_name)){
      isError.first_name = "Please enter name";
      error = true;
    }else if(practiceUserAccountInfo.full_name.length < 4){
      isError.first_name = "At least 4 characaters required";
      error = true;
    }else{
      isError.first_name = "";
    }
    if (isRequired(selectedDay) || isRequired(selectedMonth) || isRequired(selectedYear)) {
      console.log(selectedYear, selectedMonth, selectedDay,'selectedYear, selectedMonth, selectedDay')

      isError.dob = "Please select Date of Birth";
      error = true;
    }

    var validate = this.getFormattedDate();
    if(validate === 'Invalid date'){
      isError.dob = "Please enter the valid Date of birth.";
      error = true;
    }

    if(isRequired(practiceUserAccountInfo.email)){
      isError.email = "Please enter email address";
      error = true;
    }else if(isValidEmail(practiceUserAccountInfo.email)){
      isError.email = "Invaild email address";
      error = true;
    }else{
      isError.email = "";
    }

    if(practiceUserAccountInfo.email_alt && practiceUserAccountInfo.email_alt.trim() != ""){
      if(isValidEmail(practiceUserAccountInfo.email_alt)){
        isError.email_alt = "Please enter valid email";
        error = true;
      }else{
        isError.email_alt = "";
      }
    }else{
      isError.email_alt = "";
    }

    if (this.props.practiceUserAccountInfo.passcode && this.props.practiceUserAccountInfo.passcode.length == 4) {
        isError.passcode = ""
    } else {
        isError.passcode = "Passcode Requried "
        error = true
    }

    // if(this.props.practiceUserAccountInfo.phone == null){
    //   isError.isPhoneverify = "Required";// practiceUserAccountInfo.phone ? "Please enter valid phone number" :
    //   error = true;
    // }else if(this.props.practiceUserAccountInfo.phone.length < 11){
    //   isError.isPhoneverify = "Please enter valid phone number";
    //   error = true;
    // }else if( practiceUserAccountInfo.is_verified_mobile == 0){
    //   isError.isPhoneverify = "Please Verify Phone Number";
    //   error = true;
    // }else{
    //   isError.isPhoneverify = "";
    // }

    if( practiceUserAccountInfo.is_verified_mobile == 0 && practiceUserAccountInfo.is_verified_email == 0){
      isError.isPhoneverify = "Please Verify";
      error = true;
    }
    else{
      isError.isPhoneverify = "";
    }
    
    this.setState({ isError });

    return error
  }

  UpdateUserProfile(isProfileCreated) {

    this.onChangeValue('isLoading', true)
    this.onChangeValue('isUpdateSuccess', false)

    var nameArray = this.props.practiceUserAccountInfo["full_name"].trim().split(" ");
    var first_name = "";
    var last_name = "";
    if(nameArray.length > 0 ){
      first_name = nameArray[0];
      if(nameArray.length > 1){
        last_name = nameArray.slice(1, nameArray.length).join(" ")
      }
    }

    let params = {
      id: this.props.practiceUserAccountInfo.id,
      first_name,
      last_name,
      dob: this.getFormattedDate(),
      gender: this.props.practiceUserAccountInfo.gender ? this.props.practiceUserAccountInfo.gender.id : 0,
      email: this.props.practiceUserAccountInfo.email,
      email_alt: this.props.practiceUserAccountInfo.email_alt,
      // phone: this.props.practiceUserAccountInfo.phone.length > 11 ? this.props.practiceUserAccountInfo.phone : null,
      isProfileCreated,
      request_secrete: this.props.phoneVerificationInfo.request_secrete,
      otp:this.props.phoneVerificationInfo.verificationCode,
      otp_media:this.props.phoneVerificationInfo.email ? 'email' : 'sms'
    }

    if( this.props.practiceUserAccountInfo?.phone?.length > 11 ){
      params['phone'] = this.props.practiceUserAccountInfo.phone
    }
    
    params['passcode'] = this.props.practiceUserAccountInfo.passcode

    Object.keys(params).length !== 0 &&
      Object.keys(params).map((key, index) => {
        this.isValid(key, params[key])
      })
    if (!this.onSubmitValidateForm()) {
      this.props.dispatch(UpdateAuthorizedUser(params));
    } else {
      this.onChangeValue('isLoading', false)
    }
  }

  onclickDelete() {
    this.setState({ istoggleDelete: !this.state.istoggleDelete })
  }

  handleDelete(event) {
    this.setState({ i_agree: !this.state.i_agree });
    this.isValid()
  }

  deleteUserProfile(passcode) {
    this.props.dispatch(DeleteUserProfile(passcode));
  }

  onBackToprofiles() {
    history.push('/profileselection');
  }

  onContinue() {
    this.props.history.push({
      pathname: '/profileselection',
    })
  }

  getYearsArray = () => {
    const currentYear = moment().year();
    const currentDate = moment();
    const eighteenYearsAgo = currentDate.subtract(18, 'years').year();
    const isAfterBirthday = currentDate.isAfter(moment(eighteenYearsAgo, 'YYYY').add(1, 'day'));

    const years = [];
    let startYear = eighteenYearsAgo;

    if (!isAfterBirthday) {
      // If the birthday hasn't occurred yet this year, adjust the start year.
      startYear -= 1;
    }

    for (let year = startYear; year >= eighteenYearsAgo - 100; year--) {
      years.push({ value: year, label: year.toString() });
    }

    return years;
  };

  getMonthsArray = () => {
    const months = moment.months().map((month, index) => ({
      value: (index + 1).toString(),
      label: month,
    }));
    return months;
  };

  getDaysArray = () => {
    const days = [];
    for (let day = 1; day <= 31; day++) {
      days.push({ value: day.toString(), label: day.toString() });
    }
    return days;
  };

  handleYearChange = (selectedOption) => {
    this.setState({ selectedYear: selectedOption.value });
    // this.onEditAuthorizedUserInfo("dob", null, this.state.selectedDay, this.state.selectedMonth, selectedOption.value);

  };

  handleMonthChange = (selectedOption) => {
    this.setState({ selectedMonth: selectedOption.value, selectedDay: null });
    // this.onEditAuthorizedUserInfo("dob", null, this.state.selectedDay, selectedOption.value, this.state.selectedYear);

  };

  handleDayChange = (selectedOption) => {
    this.setState({ selectedDay: selectedOption.value });
    // this.onEditAuthorizedUserInfo("dob", null, selectedOption.value, this.state.selectedMonth, this.state.selectedYear);

  };

  getFormattedDate = () => {
    const { selectedYear, selectedMonth, selectedDay } = this.state;
    if (!selectedYear || !selectedMonth || !selectedDay) return '';

    const date = moment(`${selectedYear}-${selectedMonth}-${selectedDay}`, 'YYYY-MM-DD');
    return date.format('YYYY-MM-DD');
  };

  render() {
    const { t, practiceSecurityQuestion, getGender, loadingDeleteUserProfile, practiceUserAccountInfo } = this.props
    const { isError } = this.state

    var isDeleteActive = this.state.isDeleteProfile;

    var userType = this.getUserType(practiceUserAccountInfo.type)
    const { selectedYear, selectedMonth, selectedDay } = this.state;

    return (
      <div className="owners_profile_creation__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
        {this.state.isLoading &&
         <Loading />
        }
          <>
            <SubHeader className="mb-auto" title={`${userType[1]}'s Profile`} />
            <div className="owners_profile_creation__inner__screen mb-auto">
              {!this.state.istoggleDelete ?
                <div className="mh-755 mw-820 transparent__form--white d-flex flex-column align-items-stretch mb-auto">
                  <Form className="mt-2 h-100 d-flex flex-column">
                    <Row>
                      <Col md={6} className="padd-r-20 setpasscode_screen">
                        <FormGroup className="mb-5">
                          <h4 className="m-0">About</h4>
                        </FormGroup>
                        <FormGroup>
                          <InputField type="text" label="Name" name="first_name" placeholder="First name" isError={isError.first_name} value={`${practiceUserAccountInfo.full_name || ""}`} onChange={(e) => this.onEditAuthorizedUserInfo('full_name', e.target.value)} className="mb-3" />
                        </FormGroup>
                        <FormGroup>
                          <div className="d-flex justify-content-between">
                            <Label for="Ownership">Date of birth</Label>
                            {isError.dob && <span className='text--danger'>{isError.dob}</span>}
                          </div>
                          <div className={`w-auto practice__hours_box`}>
                            <div className="d-flex w-100 gap-10">
                                <FormGroup className={`align-items-center m-0 w-100`}>
                                    <DropDown 
                                        className='react-select-container select-style-3 w-101 p-0'
                                        classNamePrefix="react-select"
                                        options={this.getMonthsArray()}
                                        isSearchable={false}
                                        placeholder={'MM'}
                                        value={selectedMonth ? { value: selectedMonth, label: moment.months(Number(selectedMonth) - 1) } : null}
                                        onChange={this.handleMonthChange}
                                    />
                                    {console.log(this.state.selectedMonth,'selectedMonth')}
                                </FormGroup>
                                <FormGroup style={{maxWidth:'100px'}} className={`align-items-center m-0  w-100`}>
                                    <DropDown 
                                        className='react-select-container w-101 p-0'
                                        classNamePrefix="react-select"
                                        options={this.getDaysArray()}
                                        isSearchable={false}
                                        placeholder={'DD'}
                                        onChange={this.handleDayChange}
                                        value={selectedDay ? { value: selectedDay, label: selectedDay } : null}
                                        />
                                </FormGroup>
                                <FormGroup className={`align-items-center m-0  w-100`}>
                                  <DropDown
                                      className='react-select-container w-101  p-0'
                                      classNamePrefix="react-select"
                                      options={this.getYearsArray()}
                                      isSearchable={false}
                                      placeholder={'YYYY'}
                                      value={selectedYear ? { value: selectedYear, label: selectedYear } : null}
                                      onChange={this.handleYearChange}
                                  />
                                </FormGroup>
                            </div>
                        </div>
                        </FormGroup>
                       
                        <FormGroup>
                          <InputField type="text" label={t('form.email')} name="email" isError={isError.email} onChange={(e) => this.onEditAuthorizedUserInfo('email', e.target.value)} value={practiceUserAccountInfo.email || ''} />
                        </FormGroup>
                        <FormGroup>
                          <InputField type="text" label="Secondary email (Optional)" isError={isError.email_alt} name="email_alt" value={practiceUserAccountInfo.email_alt || ''} onChange={(e) => this.onEditAuthorizedUserInfo('email_alt', e.target.value)} />
                        </FormGroup>

                        <PhoneVerify isError={isError.isPhoneverify} validateForm={() => this.validateForm()} values={practiceUserAccountInfo} isClear={true}/>

                      </Col>
                      <Col md={6} className="padd-l-20">
                     
                        {/* {this.props.practiceInfo.need_owner_setup === 1 && <> */}
                          <FormGroup className="mt-5">
                            <h4 className="m-0 mb-4">Set your Passcode</h4>
                            <p className="m-0">To help us protect your privacy, please create a <br />4-digit passcode for your owner's profile.</p>
                          </FormGroup>
                          <FormGroup className={`mt-5 ${isError.passcode ? 'notverifyed' : ''}`}>
                            <div className="d-flex justify-content-between">
                              <label>Set passcode</label>
                              {isError.passcode && <span className='text--danger'>{isError.passcode}</span>}
                              <Button onClick={this.toggleShow} color="link primary" className="p-0 text-uppercase">{this.state.hidden ? 'SHOW' : 'Hide'}</Button>
                            </div>
                            <ReactCodeInput type={this.state.hidden ? 'password' : 'number'} onChange={(options) => this.onEditAuthorizedUserInfo('passcode', options)} className={`mb-2`} fields={4} />
                          </FormGroup>
                        {/* </>} */}
                      </Col>
                    </Row>
                    <div className="mt-5 owners_profile_creation__inner__screen__footer__button">
                    <LoadingButton type="button" size="md" disabled={practiceUserAccountInfo.isLoading} loading={practiceUserAccountInfo.isLoading} label={practiceUserAccountInfo.isUpdateSuccess ? 'Changes saved' : 'save changes'} onClick={() => this.UpdateUserProfile(true)} className={`${practiceUserAccountInfo.isUpdateSuccess ? 'btn-green' : ''}`} color="primary" />
                      <Button type="button" size="md" onClick={history.goBack} className=" ml-3 " outline color="default white" disabled={this.props.practiceInfo.need_owner_setup === 1 ? true : false}>{t('btn.back')}</Button>
                      {/* {practiceUserAccountInfo.type !== 4 &&
                        <Button type="button" size="lg" onClick={() => this.onclickDelete()} className=" ml-3  owners_profile_creation__inner__screen__button__deleteButton" outline color="default white">Delete profile</Button>
                      } */}
                    </div>
                  </Form>
                </div>
                :
                <div className="transparent__form--white delete__screen about__screen bg_red md h-100 d-flex flex-column align-items-stretch">
                  <Form className="h-100 d-flex flex-column">
                    <div className="d-flex flex-column text-center h-100">
                      {!this.props.practiceUserdeleteInfo ?
                        <>
                          <div className="d-flex flex-column text-center my-auto">
                            <FormGroup className="mb-5 pb-5 border--bottom heading">
                              <h4 className="m-0 mt-1">Attention!</h4>
                            </FormGroup>
                            <FormGroup className="mb-20 removal-section">
                              <h4 className="mb-20 mt-1">Authorized User’s profile removal</h4>
                              <p>You are about to delete this admin profile. Once done, the regular profile associated with this one will no longer be able to access restricted areas.</p>
                            </FormGroup>
                            <FormGroup className="mb-5 confirm-delete">
                              <div className="custom-control white custom-redio  ml-2">
                                <input type="checkbox" onChange={this.handleDelete} defaultChecked={this.state.i_agree} className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Yes, I want to delete this admin profile.</label>
                              </div>
                            </FormGroup>
                            {this.props.practiceUserAccountInfo.is_secure === 1 ?
                              <FormGroup className={`my-auto ${this.props.error ? 'notverifyed' : ''} enter-passcode`}>
                                <div className="d-flex justify-content-between">
                                  <label>Enter your passcode</label><Button onClick={this.toggleShow} color="link white" className="p-0">SHOW</Button>
                                </div>
                                <ReactCodeInput type={this.state.hidden ? 'password' : 'number'} onChange={(options) => this.onEditAuthorizedUserInfo('passcode', options)} fields={4} className={`bg_white mb-2`} />
                              </FormGroup>
                              : null}
                          </div>
                          <div className="mt-auto d-flex justify-content-between">
                            <Button type="button" size="sm" onClick={() => this.onclickDelete()} className="col" outline color="default white">Cancel</Button>
                            {this.props.practiceUserAccountInfo.is_secure === 1 ?
                              <LoadingButton type="button" size="sm" label={'Delete profile'} loading={loadingDeleteUserProfile} disabled={!isDeleteActive || loadingDeleteUserProfile} onClick={() => this.deleteUserProfile(this.state.practiceUserAccountInfo.passcode)} className={this.props.SuccessProfileRemove === true ? 'verifyed' : ''} color="default" />
                              :
                              <LoadingButton type="button" size="sm" label={'Delete profile'} loading={loadingDeleteUserProfile} disabled={!isDeleteActive || loadingDeleteUserProfile} onClick={() => this.deleteUserProfile(null)} className={this.props.SuccessProfileRemove === true ? 'verifyed' : ''} color="default" />
                            }
                          </div>
                        </>
                        :
                        <>
                          <div className="d-flex flex-column text-center  h-100">
                            <FormGroup className="my-auto">
                              <h4 className="mb-5 mt-1">Profile deleted!</h4>
                              <p>The profile {`${this.props.practiceUserAccountInfo.first_name || ''} ${this.props.practiceUserAccountInfo.last_name || ''}`} has been deleted, and is no longer accessible. To go back to the profile selection screen, please click the button bellow.</p>
                            </FormGroup>
                          </div>
                          <div className="mt-auto">
                            <Button type="button" onClick={() => this.onBackToprofiles()} className="col w-100" outline color="default white">Back to profile selection</Button>
                          </div>
                        </>
                      }
                    </div>
                  </Form>
                </div>
              }
            </div>
          </>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  practiceUsersList: state.task.practiceUsersList,
  loadingOwnerVerifyPhone: state.task.loadingOwnerVerifyPhone,
  practiceUserAccountInfo: state.task.practiceUserAccountInfo,
  phoneVerificationInfo: state.task.phoneVerificationInfo,
  practiceSecurityQuestion: state.task.practiceSecurityQuestion,
  getGender: state.task.getGender,
  loading: state.task.loading,
  isProfileCreated: state.task.isProfileCreated,
  practiceUserdeleteInfo: state.task.practiceUserdeleteInfo,
  loadingDeleteUserProfile: state.task.loadingDeleteUserProfile,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  media_language:state.task.media_language
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(OwnersProfileSetupIndex); 