import React, { useRef, useState, useEffect } from 'react';
import { setToken , setSystemLanguage } from '../../helper/LocalStorage';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import common_fr from "../../translations/fr/common.json";
import common_en from "../../translations/en/common.json";
import i18n from "i18next";
import { GetPracticeMoreInfo , clearerror , GetUserProfile , GetAuthorizedUser} from "../../store/actions/taskAction";

import './style.scss';
import { Progress } from 'reactstrap';


const PreviewModal = ({ url, html, isOpen, toggle}) => {
  const states = useSelector(state => state.task);
  const [openOverly, setShowOverly] = useState(false);
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();

  const history = useHistory();




  useEffect(() => {
    latLongAddress()
  }, []);

const latLongAddress = async () => {
  var token = states.practiceUserLoginInfo.token_info.token_type+' '+ states.practiceUserLoginInfo.token_info.access_token
  setToken(`${token}`);
   dispatch(GetPracticeMoreInfo());
  
  setSystemLanguage(states.practiceUserLoginInfo.account_setting.system_language)
   dispatch(GetUserProfile())
  dispatch(GetAuthorizedUser(states.practiceUserLoginInfo.id))

  setTimeout(() => { 
    setCounter(100)
  }, 3000)

  setTimeout(() => { 
    if(states.practiceInfo.is_corporate == 1){
      history.push("/corporate");
    }else{
      history.push('/channels');
    }
  }, 4000)

}

 return (
    <div className="signin__main__screen loading_screen  d-flex flex-column h-100 align-items-center justify-content-center pt-0">
      <h1 className='mb-5'>Loading Your Thalamus Experience...</h1>
      {console.log(counter)}
      <Progress
          value={counter}
        />
    </div>
  );
};

export default PreviewModal;


// class WelcomeIndex extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       count:0
//     }
//   }
//   async componentDidMount(){
    
//     var token = this.props.practiceUserLoginInfo.token_info.token_type+' '+ this.props.practiceUserLoginInfo.token_info.access_token
//     setToken(`${token}`);
//     await this.props.dispatch(GetPracticeMoreInfo());
    
//     setSystemLanguage(this.props.practiceUserLoginInfo.account_setting.system_language)
//     await this.props.dispatch(GetUserProfile())
//     await this.props.dispatch(GetAuthorizedUser(this.props.practiceUserLoginInfo.id))
    

//     // var lng =  this.props.media_language && this.props.media_language.find(x=>x.id === this.props.practiceUserAccountInfo.account_setting.system_language)
//     // lng = lng.name.slice(0, 2).toLowerCase()
//     // console.log(lng)
//     //  i18n.changeLanguage(lng)

//     // await setTimeout(() => { 
//     //   history.push('/channels');
//     // }, 2000)

//     await setTimeout(() => { 
//       this.setState({count:this.state.count + 100})
//     }, 2000)

//     await history.push('/channels');
    
//   }

//   render (){
//     const {practiceUserLoginInfo , loading} = this.props

//     return(
//       <div className="signin__main__screen loading_screen  d-flex flex-column h-100 align-items-center justify-content-center pt-0">
//         <h1 className='mb-5'>Loading Your Thalamus Experience...</h1>
//         <Progress
//             value={this.state.count}
//           />
//           {/* <SubHeader className="mb-auto" title="Profile Sign in"/> */}
//             {/* <div className="welcome__box transparent__form--white  md d-flex flex-column align-items-stretch mb-auto">
//                 <div className="h-100 d-flex flex-column align-items-center text-center">
//                    <div className="my-auto"><h1>Welcome <br/> {`${practiceUserLoginInfo.first_name || ''} ${practiceUserLoginInfo.last_name || ''}`}</h1></div>
//                 </div>
//             </div> */}
//       </div>
//     ) 
//   }
// }
// const mapStateToProps = (state) => ({
//     loading: state.task.loading,
//     error: state.task.error,
//     practiceUserLoginInfo:state.task.practiceUserLoginInfo,
//     practiceUserAccountInfo:state.task.practiceUserAccountInfo,
//     media_language:state.task.media_language
// });
// export default compose(
//   withTranslation('common'),
//   connect(mapStateToProps )
// )(WelcomeIndex);
