import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import Card from '../../components/CreditCards'
import CardNotPaid from './CardNotPaid'
import './style.scss';

const HomeIndex = (props) => {
  return(
    <div className="billing__main__screen billing__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Billing"/>
        {props.practiceInfo.is_paid_account === 1 ?
          <Card />
        :
          <CardNotPaid />
        }

        { props.error &&
              Object.keys(props.error.errors).map((sub)=>{
                console.log(sub)
                return <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{props.error.errors[sub][0]}</p> </div>
              })
              
          }
    </div>
  ) 
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  getBillingCardInfo:state.task.getBillingCardInfo,
  practiceInfo:state.task.practiceInfo

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
