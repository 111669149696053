import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../index';
import {  getAccessCode   , resetAccessCode , resetAccessCodeRequest } from "../../store/actions/taskAction";
import LoadingButton from '../../components/LoadingButton'
import Loading from '../../components/Loading'

import './style.scss';

class AccessCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isContinue:false,
      isGenerate:false
    }
  }

  async componentDidMount(){
    await this.props.dispatch(getAccessCode());
   }
 
  onGenerateCode(){
    this.onChange('isContinue' , true)
  }

  onCancle(){
    this.onChange('isContinue' , false)
  }

 async onBackToSignin(){
    // this.props.dispatch(cleardata());
    await this.onChange('isdisabled' , false)
    await this.onChange('isContinue' , false)
    await this.onChange('isGenerated' , false)
  }

  async onResetAccessCode(){
    await this.onChange('isLoading' , true)
    await this.onChange('isGenerated' , true)
    await this.props.dispatch(resetAccessCode(this.props.practiceUserLoginInfo));
  }

  async onChange(field , value){
    let resetAccessCode = this.props.resetAccessCode
    resetAccessCode[field] = value
    await this.props.dispatch(resetAccessCodeRequest(resetAccessCode))
  }

  render (){
    const {t , getAccessCode } = this.props

    return(
        <div className="accesscode__main__screen  d-flex flex-column h-100 align-items-center ">
          {getAccessCode && getAccessCode.access_code ?
            <>
            <SubHeader className="mb-auto" title="Access Code"/>
            <div className="accesscode__inner__screen mb-auto">
                <div className="d-flex flex-column text-center accesscode__inner__text current_access_code text-center">
                    <p>Access code in use</p>
                    <h1 className="mb-5">{getAccessCode && getAccessCode.access_code}</h1>
                    <p className="message">This is your current access code, used to sign in to a Thalamus instance in any of your available devices.</p>
                    <LoadingButton type="button" size="md" label={`Generate a new code`} className={`mx-auto mt-5`} onClick={()=>history.push("/access-code-verify-user")} color="primary" />
                </div>
            </div>
            </>
            :
            <Loading />
          }
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  getAccessCode:state.task.getAccessCode,
  resetAccessCode:state.task.resetAccessCode,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AccessCode);
