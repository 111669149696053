import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/operatory_1.png'
import Image2 from './../../../img/help/operatory_2.png'
import Image3 from './../../../img/help/operatory_3.png'
import Image4 from './../../../img/help/operatory_4.png'

const OperatoryRoom =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <>
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Operatory</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"5px",marginLeft:"2px" }}>
                                        <h5>Navigation the Operatory Room Channel</h5>
                                        <div className="help_detail">
                                                <p>Clicking the Operatory Channel button shows all available channels. Channels created by Thalamus appear in a dark/transparent background, while channels made by the practice appear in white The key differences between operatory room and waiting room channels are as follows:
</p>
                                                <p>On the left hand side, expanding the help menu allows the user to sort and filter channels shown. New channels can be added by clicking the “New Channel” button.</p>
                                               
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-6px"  }}>
                                      <img src ={Image1} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"-12px"}}>
                                        <h5>Managing Operatory channels</h5>
                                        <div className="help_detail">
                                          <p>Changing the name of a channel: Clicking the icon in the right hand corner of a channel allows you to edit the name of the channel.
</p>
                                          <p>Editing Channel Content: Clicking the yellow “manage channels” button from the Operatory Room channels home page will direct you to the channel creator screen. Here you have access to all of the customization features for the channel, such as header (practice name) and footer (custom messages, newsfeed, time, and weather) adjustments, and timeline content modifications. 
</p>
<p>Operatory channels created by Thalamus cannot be changed in any way.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-25px" }}>
                                      <img src ={Image2} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"2px"  }}>
                                        <h5>Creating Operatory channels</h5>
                                        <div className="help_detail">
                                            <p>Selecting ‘New Channel’ from the menu screen opens the channel creator screen. Several options for customization are made available at this screen. 
</p>
<p>Operatory channel’s name: The name of the channel
</p>
<p>Header: The name of the Practice at the top of the channel, but can be edited to show any text.
</p>
<p>Timeline: Several pieces of content including education, entertainment, my videos, and my slides
</p>
<p>Footer: Text and widgets available on the bottom of the channel. Can include custom messages, newsfeed, time, and weather
</p>
<p>Images: Selecting ‘images’ allows you to select a thumbnail and overview image for the new channel.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-43px"  }}>
                                      <img src ={Image3} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"-26px",}}>
                                        <h5>Creating Operatory Room channels - Timeline</h5>
                                        <div className="help_detail">
                                          <p>There are 2 tabs on the Operatory Room timeline page. 

</p>
<ul>
      <li><span className="underline">Entertainment:</span>  Several video topics/categories are available. Drag and drop your desired video to add to the timeline. </li>
      <li><span className="underline">My Videos:</span> Videos sourced by the user can be found here. Videos can only be sourced online, from websites such as Youtube. Pressing ‘upload videos’ will take you to the upload box</li>
</ul>
<p>To create a channel, simply drag and drop the videos to the timeline in the order that is desired.
</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-54px",}}>
                                      <img src ={Image4} className="w-100" alt=""/>
                                  </div>
                            </div>
    
                      </div>
                  </>
            
    ) 

}

export default OperatoryRoom;
