import React, { useEffect, useState } from 'react'
import { history } from '../..'
import { formatSecond, formatSecondForGoogleTv } from '../../../data/comman'
import ImageLoad from './imageLoad'
import './videoBox.scss'
const VideoBox = ({videoDetail,isOperatoryRoom,isChairside,isPlaylists, toggleisOpen, videoDetail : {thumb, title, total_timeline} }) => {
  var [itemDuration, setDuration] = useState(0);
  if(isChairside && !isPlaylists){
    var {thumb, } = videoDetail?.chairside_media
    var {title, } = videoDetail?.chairside_media?.media_language
    var {duration } = videoDetail?.chairside_media?.media_language?.media

  }
  useEffect(() => {
    if(isChairside && !isPlaylists){
      setDuration(formatSecondForGoogleTv(duration))
    }else{

      videoDetail && videoDetail?.timeline.map(x => {
        itemDuration += x.media_data.duration;
      });

      setDuration(formatSecondForGoogleTv(itemDuration))

    }
  }, []);

  return (
    <div className='videoImageBox' onClick={() => history.push({pathname: isChairside || isPlaylists ? '/google-tv-chairside-video-detail' : '/google-tv-video-detail', state: { channel: videoDetail ,itemDuration:itemDuration, isOperatoryRoom: isOperatoryRoom,isPlaylists: isPlaylists, isChairside:isChairside}, toggleisOpen })}>
      <ImageLoad src={thumb} width='356px' height='200px' alt=''/>
      <div className='videoImageBoxInfo'>
        <h2 className='videoTImageBoxTitle text-center '>{title}</h2>
        <div className='d-flex justify-content-center'>
          {!isChairside && <h2 className='mt-2 p-0'>{isPlaylists ? videoDetail?.timeline_count : total_timeline} Items<span className='px-4'>|</span></h2>}<h2 className='mt-2 p-0'>{itemDuration}</h2>
        </div>
      </div>
    </div>
  )
}

export default VideoBox
