import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react'
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import {
    PlayArrow,
    // VolumeUp,
    // Fullscreen,
    ClosedCaption,
    // FullscreenExit,
    Maximize,
    Close,
    Pause,
    SkipNext,
    SkipPrevious,
    VolumeDown,
    VolumeUp
    // VolumeOff,
  } from "@material-ui/icons";
  import {
    Grid,
    Typography,
    Slider,
    Tooltip,
    IconButton
  } from "@material-ui/core";
import { Button, FormGroup, Input } from 'reactstrap';

const useStyles = makeStyles({
    ///player settings
    playerWrapper: {
      width: "100%",
      position: "relative",
    },
    controlsSwapper: {
      position: "absolute",
      // top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex:'1'
      // background: "rgba(0,0,0,0.6)",
      // display: "flex",
      // flexDirection: "column",
      // justifyContent: "space-between",
      // zIndex: 1,
    },
    controlsTop:{
      position: "absolute",
      top:"-22px",
      left:"0",
      right:"0",
      fontSize:'15px',
      fontWeight:'300'
    },
    topTitle:{
      color:"#fff",
      textTransform:"uppercase"

    },
    controlIcons: {
      color: "#777",
      fontSize: 50,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#fff",
        transform: "scale(1)",
      },
    },

    bottomIcons: {
      color: "#fff",
      fontSize:'30px',
      padding:'0 10px',
      "&:hover": {
        color: "#fff",
      },
    },
    volumeSlider: {
      width: 100,
      height:'70px!important',
      position:'absolute',
      left: '50%',
      bottom: '30px',
      marginLeft: '-10px'
    },
    processSlider: {
      width: 250,
    },
    tabsClass: {
      background:'linear-gradient(#473c39, #3c3533)',
      color:'#8d8885',
      //backgroundColor: "#443b37",
      textAlign: "center",
      height: "40px",
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      // paddingTop:'7px',
    },
    active: {
      background:'linear-gradient(#b3afae, #b1aead)',
      color:'#494948'
    },
    verticalSlider:{
      height:'175px'
    }
})

const PrettoSlider = withStyles({
    root: {
      height: 3,
      color:"#fff"
    },
    thumb: {
      height: 16,
      width: 16,
      backgroundColor: "#fff",
      // border: "2px solid currentColor",
      marginTop: -6.5,
      marginLeft: -8,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 3,
      borderRadius: 2,
      backgroundColor:'#fff'

    },
    rail: {
      height: 3,
      borderRadius: 2,
      backgroundColor:'rgba(255,255,255,0.4)',
      boxShadow: '0 1px 2px 0 rgba(0,0,0,0.3)'

    },
  })(Slider);

  const VolumeSlider = withStyles({
    root: {
      width: 3,
    },
    thumb: {

      backgroundColor: "#fff",
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      borderRadius: 2,
      backgroundColor:'rgba(255,255,255,0.4)',
      width:3
    },
    rail: {
      width:3,
      borderRadius: 2,
      backgroundColor:'#fff',

    },
  })(Slider);

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    //const valu1 = secondsToHms(value);

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value}
        style={{ fontSize: "12px" }}
      >
        {children}
      </Tooltip>
    );
  }

  function VolumeSliderComponent(props){
    const {children,open,value} = props;
    return (
       <span> {children}</span>
    );
  }

const CustomVideoPlayer = () => {

    const [state,setState] = React.useState({
        playing: true,
        muted: false,
        volume: 0.5,
        played: 0,
        seeking: false,
        toggleScreen: false,
        pip: false,
        subtitle: true,
        closeVideo: false,
        duration:"2",
    })

    const playerRef = React.useRef()
    var count = 0

    const controlRef = React.useRef()

    const classes = useStyles()
    const states = useSelector((state) => state.task);
    const handlePlayPause = () => {
		  setState({ ...state, playing: !state.playing });
    };
    
    const handleSeekChange = (e, newValue) => {
		  setState({ ...state, played: parseFloat(newValue / 100) });
	  };

    const handleProgress = (changeState) => {
		  const {played} = changeState
      setState({...state,played:played})
  };
  
  const handleDuration = (rplayer) => {
    const player = rplayer.getInternalPlayer()
    const duration = rplayer.getDuration()
    const durationInMin = parseFloat((duration/60).toFixed(1));
    setState({...state,duration:durationInMin})
  }
//"https://www.youtube.com/embed/LXb3EKWsInQ" /
    return (
        <div style={{position:"relative"}}>
              <ReactPlayer url={states.manageOperatoryChannel.timeline[0].media_data.provider_url} height={"800px"} width={"1100px"}
                onContextMenu={(e)=>e.preventDefault()}
                controls={false}
                playing={state.playing}
                // ref={playerRef}
                onProgress={handleProgress}
                onPause={()=>setState({...state.played,playing:false})}
                onPlay={()=>setState({...state.played,playing:true})}
                onReady={(reactplayer) => handleDuration(reactplayer)}
                config={{ youtube: { playerVars: { disablekb: 1 } } }}

                />


          <div style={{position:"absolute", bottom:0,left:20,display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}} ref={controlRef}>
          <IconButton onClick={handlePlayPause} style={{fontSize:'50px' , padding:'0 0px'}}  className={classes.bottomIcons} >
              {state.playing === false ? (
                <PlayArrow fontSize="inherit" />
              ) : (
                <Pause fontSize="inherit" />
              )}
            </IconButton>
                     <Button color="link" style={{ color: "#fff", marginRight: 16 }}
                     // onClick={handleChangeDisplayFormat}
                     >
                       <Typography style={{fontSize: '15px'}}>{state.duration} min</Typography>
                   </Button>
                     <div style={{flex:1}}>
                   <PrettoSlider
                     min={0}
                     max={100}
                     value={state.played * 100}
                     ValueLabelComponent={(props) => (
                       <ValueLabelComponent {...props} value={500} />
                     )}

                 //onChange={handleSeekChange}
            //   onMouseDown={handleSeekMouseDown}
                // onChangeCommitted={handleSeekMouseUp}
             />
                   </div>

               </div>
               </div>
    )
}

export default CustomVideoPlayer



// <div className=""
                        /*onMouseEnter={() => hoverHandler('enter')}*/
 //                   style={{position: 'relative'}}>
//               <IconButton style={{marginLeft: 16 }}
            //    onClick={handleMute}
 //              className={classes.bottomIcons}  >
 //                       {state.volume > 0.5  ? <VolumeUp fontSize="inherit"/> : <VolumeDown fontSize="inherit"/>}
 //             </IconButton>
            // <Button color="link"
              // onClick={()=> hoverHandler('enter')}
       //         className="py-0"   style={{ color: "#fff", marginLeft: 10 }}>
        {/* /       {state.marginLeftuted === false  ? <VolumeUp fontSize="inherit"/> : <VolumeDown fontSize="inherit"/>} */}
          {/* </Button> */}
      //         { true &&
       //          <VolumeSlider
      //             min={0}
    //               max={100}
     //              value={15 * 100}
                   // onChange={handleVolumeChange}
                   // onChangeCommitted={handleVolumeSeekDown}
                   // className={classes.volumeSlider}
    //               orientation='vertical'
   //                // onMouseLeave={() => hoverHandler('leave')}
   //                ValueLabelComponent={(props) => (
   //                  <VolumeSliderComponent {...props} value={15} />
   //                )}
  //               />
//               }
//             </div> */}