import React from 'react';

import { Button , Label , FormGroup, Form } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../../pages/index';
import { connect } from "react-redux";
import { compose } from 'redux';
import { InputField } from '../../../components/InputField';
import DropDown from '../../../components/DropDown'
import './style.scss';

const profile_type = [
  { name: 'secure', id: 1},
  { name: 'unsecure', id: 0},
];
class Review extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      practiceInfo:props.practiceInfo,
    };
    this.nextClick = this.nextClick.bind(this);
    // this.handleClick = this.handleClick.bind(this);
  }

    nextClick(){
        this.props.nextStep();
    }

  render (){
    const {t , practice_user_roles , values , isError} = this.props
    return(
        <div className="about__screen transparent__form--white  md d-flex flex-column align-items-stretch mb-auto">
          <Form className="h-100 d-flex flex-column">
            <FormGroup className="mb-20 header" >
                <h4 className="m-0 mt-1">Identification</h4>
            </FormGroup>
            <FormGroup className="align-items-center mb-20" >
                <InputField type="text" defaultValue={values.first_name || ''} isError={isError.first_name} onChange={(options) => this.props.handleChange(options)} className="mb-3" label="Name" placeholder="First" name="first_name" />
                <InputField type="text" defaultValue={values.last_name || ''} onChange={(options) => this.props.handleChange(options)}  placeholder="Last" name="last_name" />
            </FormGroup>
          
            <FormGroup>
                <div className="d-flex justify-content-between">
                    <Label>Role</Label>
                    {isError.selectedRole && (
                      <span className="text--danger">{isError.selectedRole}</span>
                    )}
                </div>
                <DropDown 
                    className='react-select-container'
                    classNamePrefix="react-select"
                    options={practice_user_roles}
                    getOptionLabel={ x => `${x.name}`}
                    getOptionValue={ x => `${x.id} ${x.name}`}
                    onChange={(options) => this.props.handleChange("selectedRole", options)}
                    isSearchable={false}
                    placeholder={'Select an option'}
                    defaultValue={values.selectedRole }
                />
                </FormGroup>
            <FormGroup className="my-5 py-3 border-bottom" >
                <div className="border--bottom"></div>
            </FormGroup>
            <FormGroup className="mb-20 type-header">
                <h4 className="m-0 mb-4">Profile type</h4>
                <p className="m-0">Selecting “Secure” will require you to provide a mobile no / email. and have it verified via a 4-digit security code we’ll send you. Once done, you’ll be able to set your passcode.</p>
            </FormGroup>
            <FormGroup>
                <div className="d-flex justify-content-between">
                    <Label>Profile type</Label>
                    {isError.selectedProfileType && (
                      <span className="text--danger">{isError.selectedProfileType}</span>
                    )}
                </div>
                <DropDown 
                    className='react-select-container'
                    classNamePrefix="react-select"
                    options={profile_type}
                    getOptionLabel={ x => `${x.name}`}
                    getOptionValue={ x => `${x.id} ${x.name}`}
                    onChange={(options) => this.props.handleChange("selectedProfileType", options)}
                    isSearchable={false}
                    placeholder={''}
                    defaultValue={values.selectedProfileType }
                />
                </FormGroup>
              <div className="mt-auto">
                    <Button type="button" size="xxl" onClick={ this.nextClick }  className="" color="primary">{t('btn.next')}</Button>
              </div>
            </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  practice_user_roles:state.task.practice_user_roles
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Review);
