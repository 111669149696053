import React, { useState, useEffect } from "react";
import SubHeader from '../../components/Header/subHeader';
import { Button, Label, FormGroup, Form, Col, Row, } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import UploadImage from '../UploadImage';
import imgUrl from '../../img/abstract-painting.png'
import {
    BrowserRouter as Router,
    Link,
    useParams,
    Route // for later
  } from 'react-router-dom'
import {GetMediaCategoryImages, WaitingRoomTimelineSlidesStockImages, cleardata} from '../../store/actions/taskAction';
import "./styles.scss";
import SelectedImagesList from './SelectedImagesList'
import { ISWAITINGROOMCHANNELIMAGE } from "../../data/comman";
import { history } from "../../pages";

const UploadCetegoryImages = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const [selectedImage, setSelectedImage] = useState({});
    const [selectedCetegory, setSelectedCetegory] = useState({});
    const [step, setStep] = useState(1);
    const [params, setParams] = useState({
        type: 'thumb',
        thumb_alt_text: '',
        channelId: props.isImage == ISWAITINGROOMCHANNELIMAGE ? state.managewaitingRoomMediaChannel.id : state.manageOperatoryChannel.id
    });

	useEffect(() => {
            dispatch(WaitingRoomTimelineSlidesStockImages(props.isOperatoryRoom));
	}, []);


    // on stock image select
	const onImageSelect = (image) => {
		setSelectedImage(image)
        setParams({
            ...params,
            thumb_alt_text:image.title
        })
        console.log(image)
	}
    const onSelectCat = (items) => {
        console.log(items,'items')
        setSelectedCetegory(items)
    }

    const onBack = () =>{
        setSelectedCetegory({})
        setSelectedImage({})
    }

    const onCancel = () =>{
       history.goBack()
    }
    
    const onChangeStape = (data) =>{
        setStep(data)
        setParams({
            ...params,
            type: data == 1 ? 'thumb' : 'feature_image',
        })
    }

    return (
        <div className="upload_cetegory__images__screen mt-5">
            <div className="d-flex">
                <div className="upload_cetegory__images__uploadBox">
                    <div className="tabs mx-auto">
                        <ul className="d-flex justify-content-between">
                            <li onClick={()=>{ onChangeStape(1); }} className={`col text-center ${step === 1 ? 'activetab' : ''}`}>
                                <span></span>
                                <label className="">Thumbnail</label>
                            </li>
                            <li onClick={()=>{ onChangeStape(2); }} className={`col text-center ${step === 2 ? 'activetab' : ''}`}>
                                <span></span>
                                <label className="">Overview</label>
                            </li>
                        </ul>
                    </div>
                    <UploadImage closeModal={()=> onCancel()} key={selectedImage.id} isChannelImageChange={props.isChannelImageChange} isThalamusChannel={props.isThalamusChannel} title={step=== 1 ? 'Thumbnail image preview' : 'Overview image preview' }  params={params} isImage={props.isImage}  selectedImage={selectedImage } uriImage={selectedImage.image_url} uploadedImage={props.uploadedImage} isOperatoryRoom={props.isOperatoryRoom} selectedState={props.state} isBackButton={props.isBackButton} hideCancelButton={props.hideCancelButton}/>
                </div>
                {/* {!props.isThalamusChannel && <> */}
                    {selectedCetegory.id ?
                        <div className="upload_cetegory__images__selected_cetegory_image">
                            <SelectedImagesList onBack={onBack} step={step} selectedImage={selectedImage} stockImages={selectedCetegory} onImageSelect={onImageSelect}/>
                        </div>
                        :
                        <div className="upload_cetegory__images__cat_lists">
                            <div className="row row-cols-2 m-0">
                                {state.WaitingRoomSlideStockImages && state.WaitingRoomSlideStockImages.map(items => {
                                    return(
                                        <div className="col px-20 mb-40" onClick={() => onSelectCat(items)}>
                                            <div className="cat_list_box">
                                                <img style={{ objectFit: 'cover' }} alt={items.title} src={imgUrl} />
                                                <div className="cat_title">{items.title}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    } 
                    {/* </> */}
                {/* // } */}
            </div>
        </div>
    );
};

export default UploadCetegoryImages;
