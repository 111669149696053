import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import Loading from '../../../components/Loading'

import About from './About'
import Security from './Security'
import AddProfilePicture from './AddProfilePicture'
import moment from 'moment';
import {DateFormat, isRequired} from '../../../data/comman'
import {clearerror, clearloading} from "../../../store/actions/taskAction";

class NewProfileForm extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      newProfileInfo:{
        first_name:'',
      },
      step: 1,
      activeClass:'',
      stepOneNext: false,
      isPasscode:false,
      isGetVerifyCode:false,
      isEnableVerify:false,
      isBack:false,
      isError: {
        first_name:'',
        last_name:'',
        selectedRole:'',
        phone:'',
        isPhoneverify:''
      }
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);

  }

  componentDidMount(){
      this.props.dispatch(clearloading());
      this.props.dispatch(clearerror());

  }

  //next step
    nextStep(){
      const { step } = this.state;
      if(!this.onSubmitValidateForm()){
        if(this.state.newProfileInfo.selectedProfileType.id === 0){
          const { step } = this.state;
          this.setState({
              step: step + 2,
          })
        }else{
          this.setState({
            step: step + 1,
          })
        }
      }
  }

  // prev step
  prevStep(){
      const { step } = this.state;
      if(this.state.newProfileInfo.selectedProfileType.id === 0){
        const { step } = this.state;
        this.setState({
            step: step - 2,
            isBack:true
        })
      }else{
        this.setState({
          step: step - 1,
          isBack:true
        })
      }
  }
  
  handleChange(field, options ) {
    var name = "";
    var value = "";

    if(field === "dob" ){
        name = field;
        value =   moment(options).format(DateFormat);
    }else if(field === "question_1" || field === "question_2" || field === "question_3" ){
      name = field;
      value =  options;
    }else if(field === "selectedRole" ){
      name = field;
      value =  options;
    }else if(field === "selectedProfileType"){
      name = field;
      value =  options;
    }else if(field === "passcode"){
      name = field;
      value =  options;
    }else if(field === "verificationCode"){
      name = field;
      value =  options;
    }else{
      name = field.target.name;
      value = field.target.value;
    }

    this.setState({
      newProfileInfo:{
          ...this.state.newProfileInfo,
          [name]: value,
      }
    },()=> {this.isValid(name, value)});
  };

  
  handlePhoneChange(value, country, e, formattedValue ){
    var fieldValue = "";
  
    fieldValue = value;

    fieldValue = fieldValue.replace(country.dialCode,'');    // 345
    fieldValue = '+'+country.dialCode+'-'+ fieldValue;
    let isError = { ...this.state.isError };

    this.setState({
      isError,
      isGetVerifyCode:true,
      newProfileInfo:{
            ...this.state.newProfileInfo,
            phone: fieldValue
        }
    },()=> {this.isValid('phone', fieldValue)})
  }

  isValid(name, value){
    let isError = this.state.isError ;
    console.log(this.state.newProfileInfo,'newProfileInfo')
    switch (name) {
      case "first_name":
          isError.first_name = '' ? "Atleast 4 characaters required" : "";
          break;
      case "first_name":
          isError.first_name = value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "last_name":
          isError.last_name =  
            value.length <= 0 ? "required" : "";
          break;
      case "selectedRole":
          isError.selectedRole =  
          value.length <= 0 ? "required" : "";
          break;
      case "phone":
          isError.phone =
              value.length < 3 ? "required" : "";
          break;
      default:
          break;
    }
    this.setState(this.validateForm)
   
  }

  validateForm(){
    let isError = this.state.isError ;
    const { step  } = this.state;
    const { first_name , last_name , selectedRole , selectedProfileType , verificationCode , phone , passcode} = this.state.newProfileInfo;
    

    if(verificationCode){
      this.setState({ isEnableVerify: true });
    }else{
      this.setState({ isEnableVerify: false });
    }
    if( step === 1 && first_name !== '' || isError.first_name === '' &&  last_name !== '' && selectedRole !== undefined &&  selectedProfileType !== undefined ){
            
          this.setState({ stepOneNext: true });

      }else if(step === 2 && verificationCode !== '' && phone !== '' && isError.phone  === '' ) {
        console.log('is back to ')
          if(passcode && passcode.length == 4){
            this.setState({ stepTwoNext: true });
          }else{
            this.setState({ stepTwoNext: false });

          }
          this.setState({ isPasscode: true });
          
      }else{

        this.setState({ stepOneNext: false , stepTwoNext:false , isPasscode:false});

      }
  }

  onSubmitValidateForm(){
    let isError = this.state.isError ;
    let { practiceUserAccountInfo } = this.props;

    var error = false;
    const { step  } = this.state;
    const { first_name , last_name , selectedRole , selectedProfileType , verificationCode , phone , passcode} = this.state.newProfileInfo;

    if(step === 1){
      if (isRequired(first_name)) {
        isError.first_name = "Please enter name";
        error = true;
      } else if (first_name.trim().length < 4) {
        isError.first_name = "At least 4 characaters required";
        error = true;
      }else {
          isError.first_name = "";
      }

      if (isRequired(selectedRole)) {
        isError.selectedRole = "Please select role";
        error = true;
      } else {
          isError.selectedRole = "";
      }

      if (isRequired(selectedProfileType)) {
        isError.selectedProfileType = "Please select profile type";
        error = true;
      } else {
          isError.selectedProfileType = "";
      }
    }

    if(step === 2){
      if (
        practiceUserAccountInfo.is_verified_mobile == 0 &&
        practiceUserAccountInfo.is_verified_email == 0
      ) {
        isError.isPhoneverify = "Please Verify";
        error = true;
      } else {
        isError.isPhoneverify = "";
      }

      if (isRequired(passcode) ||  passcode.trim().length < 4 ) {
        isError.passcode = "Please enter passcode";
        error = true;
      }

      if ( passcode.trim().length == 4  ) {
        if (!/[0-9]/.test(passcode)) {
          isError.passcode = "Please Enter Digits Only.";
          error = true;
        } else {
          isError.passcode = "";
        }

      } 
    }


    this.setState({ isError });

    return error;
  }

  renderStep(step , index){
    const { first_name , last_name , selectedRole , selectedProfileType , verificationCode , phone , passcode} = this.state.newProfileInfo;
    const values =  { first_name , last_name , selectedRole , selectedProfileType  , verificationCode , phone , passcode}
    switch(step){
        case 1:
            return  <About
                      nextStep= { this.nextStep } 
                      isNext={this.state.stepOneNext}
                      handleChange = {this.handleChange}
                      isError={this.state.isError }
                      values={values}
                    />
        case 2:
            return  <Security 
                      nextStep= { this.nextStep }
                      prevStep = {this.prevStep }
                      isNexttwo={this.state.stepTwoNext}
                      isError={this.state.isError }
                      handleChange = {this.handleChange}
                      isPasscode={this.state.isPasscode}
                      isEnableVerify={this.state.isEnableVerify}
                      isGetVerifyCode={this.state.isGetVerifyCode}
                      handlePhoneChange={this.handlePhoneChange}
                      values={values}
                      isBack={this.state.isBack}
                    />
        case 3:
            return  <AddProfilePicture 
                      nextStep= { this.nextStep }
                      prevStep = {this.prevStep }
                      handleChange = {this.handleChange}
                      isNext={this.state.stepOneNext}
                      values={values}
                    />
        default :
          return 'test1'
    }
  }

  render (){
    
    const { step } = this.state;
    const { loading } = this.props

    if (loading) {
      return  <Loading />
    }

    return(
      <>
        <div className="d-flex flex-column text-center initial_setup__inner__text text-center mt-5">
          <p className='mb-0'>{step == 1 ? 'About' : ''}{step == 2 ? 'Security' : ''}{step == 3 ? 'Profile Picture' : ''}</p>
        </div>
        <div className="custom__multi__step__form ">
            <ul className="custom__multi__step__form--progressbar">
              <li className={`custom__multi__step__form--step ${step == 1 ? 'active' : ''}`}>
                <span></span>
                {/* <label className="custom__multi__step__form--label">About</label> */}
              </li>
              <li className={`custom__multi__step__form--step ${step == 2 ? 'active' : ''}`}>
                <span></span>
                {/* <label className="custom__multi__step__form--label">Security</label> */}
              </li>
              <li className={`custom__multi__step__form--step ${step == 3 ? 'active' : ''}`}>
                <span></span>
                {/* <label className="custom__multi__step__form--label">Profile Picture</label> */}
              </li>
            </ul>
        </div>
        { this.renderStep(step) }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceUserAccountInfo: state.task.practiceUserAccountInfo,


});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(NewProfileForm);
