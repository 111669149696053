import React from 'react';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button } from 'reactstrap';
import { history } from '../../pages/index';

import VpnKey from '../../img/ic_vpn_key.svg'
import CreditCard from '../../img/ic_credit_card.svg'
import Guests from '../../img/Guests.svg'
import Info from '../../img/ic_info.svg'
import PinDrop from '../../img/ic_pin_drop.svg'
import EmailPreferences from '../../img/Email_Preferences_icon.svg'
import CCFontSize from '../../img/CC_FontSize_icon.svg'
import Addons from '../../img/ic_addons.svg'

import './style.scss';
import { SetupgradeDowngrade } from '../../store/actions/taskAction';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onClickFreeMonth() {
    if (this.props.practiceInfo.is_paid_account !== 0) {
      history.push('/getfreemonth');
    } else {
      history.push('/free-month-referral');
    }

  }

  async componentDidMount(){
    await this.props.dispatch(SetupgradeDowngrade(false));
  }


  onDefaultProfileClick(){
    history.push("active-profiles", { setDefaultProfile: true })
  }

  onCliclReferralList() {
    history.push('/referral-list');
  }
  onAuthorizedUserClick() {
    history.push('/authorizeduser');
  }

  locationListing(){
    if (this.props.practiceInfo.is_paid_account !== 0) {
      history.push("/location-user-verification")
    }else{
      history.push("/limited-access")
    }
    
  }
  render() {
    const {t } = this.props
    return (
      <div className="settings__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Settings"/>
            <div className="settings__inner__screen mb-auto d-flex flex-wrap row-cols-4">
                <div className="settings__item mx-20" onClick={()=>history.push("/access-code")}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={VpnKey} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center">
                        <p className="m-0">{t('setting.access_code', {framework:'React'})}</p>
                  </div>
                </div>
                <div className="settings__item mx-20" onClick={()=>history.push("/billing-admin-profile")}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={CreditCard} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center">
                        <p className="m-0">{t('setting.billing', {framework:'React'})}</p>
                  </div>
                </div>
                <div className="settings__item mx-20" onClick={()=>history.push("/authorized")}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={Guests} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center">
                        <p className="m-0">{t('setting.authorized_users', {framework:'React'})}</p>
                  </div>
                </div>
                <div className="settings__item mx-20" onClick={()=>history.push("/practice-info")}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={Info} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center">
                        <p className="m-0">PRACTICE INFO</p>
                  </div>
                </div>

                <div className="settings__item mx-20"  onClick={()=> this.locationListing()}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={PinDrop} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center">
                        <p className="m-0">ADD & MANAGE LOCATIONS</p>
                  </div>
                </div>
                <div className="settings__item mx-20" onClick={()=>history.push("/email-preferences")}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={EmailPreferences} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center">
                        <p className="m-0">Email Preferences</p>
                  </div>
                </div>
                <div className="settings__item mx-20" onClick={()=>history.push("/closed-captions")}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={CCFontSize} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center" >
                        <p className="m-0">Captions font size</p>
                  </div>
                </div>
                <div className="settings__item mx-20" onClick={()=>history.push("/addons")}>
                  <div className="settings__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="settings__icon">
                          <img src={Addons} alt="" />
                      </div>
                  </div>
                  <div className="settings__name my-3 text-center">
                        <p className="m-0">ADDONS</p>
                  </div>
                </div>
            </div>

        <div className="d-flex mb-auto w-100 align-items-center justify-content-center ">
          <Button className="mt-4 mx-3" size="md" onClick={() => { this.onDefaultProfileClick()}} outline color="default white">Set default profile</Button>
          <Button className="mt-4 mx-3" size="md" onClick={() => {history.push('/videosettings')}} outline color="default white">Set Video Quality</Button>
          <div className="d-none">
          <Button className="mt-4 mx-3" size="md" onClick={() => this.onClickFreeMonth()} outline color="default white">Get a free month</Button>
          {this.props.practiceInfo.is_paid_account !== 0 &&
            <Button className="mt-4 mx-3" size="md" onClick={() => this.onCliclReferralList()} outline color="default white">See my referrals</Button>
          }
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(Index);
