import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import DropDown from '../../components/DropDown'
import { Button , Spinner , FormGroup , Label , Row ,Col} from 'reactstrap';
import { InputField } from '../../components/InputField';
import LocationSVG from '../../img/ic_pin_drop.svg'
import { history } from '../../pages/index';
const FilterByShow = [
  { value: 0, label: 'Oldest first' },
  { value: 1, label: 'Newest first' },
]
class HomeIndex extends React.Component {

  onClickAssign(){
    history.push('/assign-manager');
  };

  render (){


    return(
        <>
       <div className="managecard__inner__panel transparent__form--white d-flex flex-column">
        <div className="managecard__inner mb-auto d-flex flex-column">
                <div className="managecard__inner__header">
                  <div className="row">
                    <div className="col-7">
                      <h4>Select a location to view more details</h4>
                    </div>
                    <div className="col-5  justify-content-end d-flex left-right-arrow align-items-center">
                    <div className="location-img">
                          <img src={LocationSVG} alt="location" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex search-section">
                    <FormGroup className="mr-20 search-keyword">
                      <InputField type="text" label={'Search manager by name'} name="searchbyname"  />
                    </FormGroup>
                    <FormGroup className="mr-20 search-keyword">
                      <InputField type="text" label={'Search manager by city'} name="searchcity"  />
                    </FormGroup>
                  </div>
                </div>
                <div className="managecard__inner__body flex-grow-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="150">MANAGER</th>
                        <th width="240">Practice managed</th>
                        <th width="410">LOCATION</th>
                        <th width="130">Assign date</th>
                        <th width="175"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>      
                         <td >Mark Dever</td>
                                    <td >Smith Dental</td>
                                    <td >
                                    67 Mowat Ave. Suite 305 - Toronto, ON  |  M6K 3E3
                                    </td>
                                    <td>24 Aug 2019</td>
                                    <td ><Button size="md" color="primary" >Manage</Button></td>
                                  </tr>
                                  <tr>
                                    <td>Denni Moe</td>
                                    <td >Smith Dental - Scarborough</td>
                                    <td>
                                    1911 Eglinton Ave E - Scarborough, ON  |  M1L 4P4
                                    </td>
                                    <td>24 Aug 2019</td>
                                    
                                    <td><Button size="md" color="primary" >Manage</Button></td>
                                  </tr>
                                  <tr>
                                    <td>Drice Fortin</td>
                                  
                                    <td >Smith Dental - Scarborough</td>
                                    <td>
                                    1911 Eglinton Ave E - Scarborough, ON  |  M1L 4P4
                                    </td>
                                    <td>17 Sep 2019</td>
                                    
                                    <td><Button size="md" color="red" >Unassign</Button></td>
                                  </tr>
                                  <tr>
                                  <td>Denni Moe</td>
                                    <td >Smith Dental - Scarborough</td>
                                    <td>
                                    1911 Eglinton Ave E - Scarborough, ON  |  M1L 4P4
                                    </td>
                                    <td>Multiple</td>
                                    <td><Button size="md" color="red" >Unassign</Button></td>
                                  </tr>
                                  <tr>
                                    <td>Denni Moe</td>
                                    <td >Smith Dental - Scarborough</td>
                                    <td>
                                    1911 Eglinton Ave E - Scarborough, ON  |  M1L 4P4
                                    </td>
                                    <td>24 Aug 2019</td>
                                    
                                    <td><Button size="md" color="red" >Unassign</Button></td>
                                  </tr>
                      
                    </tbody>
                  </table>
                  <div className="add_new_location">
                  <div className="button-section text-center">
                    <Button size="md mw-100 w-auto px-5" color="primary" onClick={() => this.onClickAssign()}>Assign a manager</Button>
                  </div>
                </div>
                </div>

                <div className="notification__inner__footer d-flex justify-content-center align-items-center">
                  <ul>
                      <li><Button type="button"  className={`p-3`} size="md" color="link">1</Button></li>
                  </ul>
                </div>

            </div>
        </div>
        </>
    ) 
  }
}

export default compose(
  withTranslation('common')
)(HomeIndex);
