/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from '../../components/Header/subHeader';

import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { OPERATORYENTERTEMENT, OPERATORYMYVIDEO } from "../../data/comman";
import { getOperatoryVideos, postwaitingRoomPublishTimeline , getTimeline , postTimeline, getOperatoryChannel} from '../../store/actions/taskAction';
import OperatoryRoomEnterainment from "../../components/OperatoryRoom/OperatoryRoomEnterainment";
import OperatoryRoomMyVideos from "../../components/OperatoryRoom/OperatoryRoomMyVideos";
import TimeLineDropBox from '../../components/TimeLineDropBox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uuid from "react-uuid";
import { TIMELINEMEDIATYPE, TIMELINESOCIALMEDIATYPE, TIMELINESLIDETYPE, TIMELINETRIVIATYPE } from '../../data/comman';
import PreviewModal  from '../../components/PreviewModal'
import {WAITINGROOM_CHANNEL_PREVIEW_URL, SOCKETURL, OPERATORY_CHANNEL_PREVIEW_URL} from '../../utilities/HttpUtility'
import Loading from '../../components/Loading'
import './styles.scss';
import { getToken } from '../../helper/LocalStorage';
var accessToken = ''

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3} className="opratory-timeline-sec-row">
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		//   backgroundColor: theme.palette.background.paper,
		width: 'auto',
	},
}));
var socket
const WaitingRoomTimeline = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);

	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	const [timeLineList, setTimeLineList] = useState([]);
	const [modal, setModal] = useState(false);

	const [isLoading, setisLoading] = useState(false);

	// const script = document.createElement("script");
	// script.src = "https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.0.0/socket.io.js";//standard-all/ckeditor.js
	// script.async = true;
	//script.onload = () => scriptLoaded();
	// document.body.appendChild(script);
	// const scriptLoaded=()=>{
		//  socket = window.io.connect(`${SOCKETURL}`, { transports: ['websocket', 'polling', 'flashsocket'] });
		// let socket = io();
		// console.log(socket,'socketsocket');
	// }
	// useEffect(() => {
	// 	// console.log(state.waitingRoomPublishTimeline,'state.waitingRoomPublishTimeline')
	// 	if(state.waitingRoomPublishTimeline != null){
	// 		// console.log(window,'window')
	// 		socket = window.io.connect(`${SOCKETURL}`, { transports: ['websocket', 'polling', 'flashsocket'] });
	// 		// console.log(socket,'socketsocket');
	// 		socket.emit('publish_confirmation', {
	// 			channel_id: state.manageOperatoryChannel.id
	// 		})
	// }
	// },[state.waitingRoomPublishTimeline])

	const onPublish = () =>{
		dispatch(postwaitingRoomPublishTimeline(state.manageOperatoryChannel.id, true))

		dispatch(getOperatoryChannel(state.manageOperatoryChannel.id))
	}

	useEffect(() => {
		
		getdata();
	
	}, []);


	const getdata  = async () => {
		 setisLoading(true);
		await	dispatch(getOperatoryVideos({ mediaType: OPERATORYENTERTEMENT }));
		await    dispatch(getOperatoryVideos({ mediaType: OPERATORYMYVIDEO }));
		await    dispatch(getTimeline(state.manageOperatoryChannel.id, true));
			accessToken = getToken();
		 setisLoading(false);
	}

	useEffect(() => {
		var timeline = [];
		if(state.channelTimeLine){
			state.channelTimeLine.map((tl)=>{
				if(tl.media_data)
					timeline.push({
						media_id: tl.media_type_id == TIMELINESOCIALMEDIATYPE ? tl.media_data.provider_id : ( tl.media_type_id == TIMELINESLIDETYPE || tl.media_type_id == TIMELINETRIVIATYPE ?  tl.media_data.id : tl.media_data.operatory_media_id),
						uId: uuid(),
						thumb: tl.media_type_id == TIMELINEMEDIATYPE ? (tl.media_data.operatory_media ? tl.media_data.operatory_media.thumb : '') : tl.media_data.thumb,
						duration: tl.media_type_id == TIMELINEMEDIATYPE ? tl.media_data.media.duration : tl.media_data.duration,
						title: tl.media_data.title,
						media_type_id: tl.media_type_id
					})
			})
			setTimeLineList([...timeline]);
		}
	}, [state.channelTimeLine])
	

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const onDragEnd = (result) => {
		// console.log("result", result)
		const { source, destination } = result;
		// dropped outside the list
		if (!result.destination) {
			timeLineList.splice(result.source.index, 1);
			setTimeLineList([...timeLineList]);
			// return;
			postTimeLineToServer(timeLineList);
		}else if(result.source && result.source.droppableId == "droppable_education"){
			var video_info = result.draggableId.split('_');
			var videoCatIndex = state.educationTimeline.findIndex(x => x.categories_id == video_info[0])
			var videoIndex =  state.educationTimeline[videoCatIndex].video.findIndex(x => x.media_id ==  video_info[1])
			var items = state.educationTimeline[result.source.index]
			var video = state.educationTimeline[videoCatIndex].video[videoIndex]
			var commonTimeLine = {
                thumb: video.thumb,
                title: video.title,
                duration: video.duration || 0,
                media_id: +video_info[1],
                media_type_id: TIMELINEMEDIATYPE,
                // sort_order:
                uId: uuid()
			}

			var timeLineListTemp = timeLineList;
			timeLineListTemp.splice(result.destination.index, 0, commonTimeLine);
			timeLineListTemp[result.destination.index] = commonTimeLine
			setTimeLineList([...timeLineListTemp]);
			postTimeLineToServer(timeLineListTemp);
	
		}else if(result.source && result.source.droppableId == "droppable"){
			const items = reorder(
				timeLineList,
				result.source.index,
				result.destination.index
			);
			setTimeLineList(items);
			postTimeLineToServer(items);
		}
	  }	

	  const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
	};
	  
	const postTimeLineToServer = (mainTimelineList) => {
        var param = {};
        var timelineList = []
        mainTimelineList.map((timeline, index) => {
            timelineList.push({
                media_id: timeline.media_id,
                media_type_id: timeline.media_type_id,
                sort_order: index
            });
            
        });
        param["timeline"] = timelineList;
        dispatch(postTimeline(state.manageOperatoryChannel.id, param, true))
    }

	return (
		<div className='main__screen channels__page  d-flex flex-column waitingroom_my_slides h-100 align-items-center'>
			{isLoading ? ( <Loading /> ) : (
				<>
					<SubHeader className='' title='Operatory Timeline' />
					<div className="d-flex flex-column  w-100 opratory-timeline-sec flex-grow-1">
						<DragDropContext onDragEnd={onDragEnd} >
							{/* <Droppable droppableId="delete" className={`${classes.root} footer-header `}>
							{(provided, snapshot) => (<>
								 <div
									ref={provided.innerRef}
									{...provided.droppableProps}
									> */}
										<div>
											<Tabs
												value={value}
												onChange={handleChange}
												indicatorColor='transparent'
												textColor='primary'
												className='footer-tabs'
												aria-label='full width tabs example'>
												<Tab label='Entertainment' {...a11yProps(0)} />
												<Tab label='My videos' {...a11yProps(1)} />
											</Tabs>
										</div>

										
										<TabPanel value={value} index={0} dir={theme.direction}>
											<OperatoryRoomEnterainment
												entertainmentTimeline={state.operatoryRoomEnterainmentVideos} timeLineList={timeLineList} setModal={setModal} onPublish={onPublish}
											/>
										</TabPanel>
										<TabPanel value={value} index={1} dir={theme.direction}>
											<OperatoryRoomMyVideos myVideos={state.operatoryRoomMyVideos} timeLineList={timeLineList} onPublish={onPublish} />
										</TabPanel>
									
									
										
									{/*	{provided.placeholder}
									 </div>
								</>)} 
							</Droppable> */}
							<div className='mt-auto'>
								<Droppable droppableId="droppable" direction="horizontal">
									{(provided, snapshot) => (
											<div className="dropbox__main__screen">
												<TimeLineDropBox timeLineList={timeLineList} setTimeLineList={setTimeLineList} provided={provided} snapshot={snapshot} isOperatoryRoom={true} channelId={state.manageOperatoryChannel.id}/>
												{/* {provided.placeholder} */}
											</div>
									)}
								</Droppable>
							</div>
						</DragDropContext>
						
					</div>
				</>
			)}

			<PreviewModal url={`${OPERATORY_CHANNEL_PREVIEW_URL}${state.manageOperatoryChannel.id}&preview=1&accessToken=${accessToken}`} isOpen={modal} toggle={()=>setModal(!modal)}/>

		</div>
	);
};

export default WaitingRoomTimeline;
