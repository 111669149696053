import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import NumberFormat from 'react-number-format';
import { getCartImageByType , DateDDMMMYYY, YYYY, getCardNameByNo, YY} from '../../data/comman';

import CVVIcon from '../../img/Back_CVV.svg'
import CVVIconHover from '../../img/cvv-hover.png'
import StripeIcon from '../../img/stripe-icon.png'
import { Button , Input } from 'reactstrap';
import { resetSuccess , clearerror, showError} from "../../store/actions/taskAction";
import { getCardImageByNo } from '../../data/comman';
import {AddBillingCard , UpdateBillingCard } from "../../store/actions/taskAction";
import moment from 'moment';
import LoadingButton from '../LoadingButton'

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdate:false,
      isReplace:false,
      primerCreditCard: {},
      creditCardNumber: "",
      cardHolderName: "",
      viewCardHoldename: "",
      expireDate: "",
      editExpireDate: "",
      code: "",
      formValid:false,
      isFirstTimeCardAddedd:true,
      vardImage:'',
      isError:{
        creditCardNumber: "",
        cardHolderName: "",
        editExpireDate: "",
        code:''
      }
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.onUpdateCard = this.onUpdateCard.bind(this);
  }

  async componentDidMount(){
    console.log(this.props.primerCreditCard,'primerCreditCard')
    if(this.props.primerCreditCard.brand){
      this.setState({isFirstTimeCardAddedd:false})
    }
    await this.props.dispatch(clearerror())
    await this.props.dispatch(resetSuccess())
  }

  onClickUpdateCard(){
    this.props.dispatch(clearerror())
    this.setState({isUpdate:!this.state.isUpdate , editExpireDate:''})
    this.props.getPrimeryCardInfo()

  }

  onShowUpdatedCard(){
     this.props.dispatch(resetSuccess())
     this.setState({isUpdate:!this.state.isUpdate})
  }

  onShowNewCard(){
    this.props.dispatch(resetSuccess())
    this.props.getPrimeryCardInfo()
    this.setState({isReplace:!this.state.isReplace , cardHolderName:'',creditCardNumber:'',editExpireDate:'',code:''})
 }

  onReplaceCard(){
    this.setState({isReplace:!this.state.isReplace, editExpireDate:''})
    this.props.dispatch(clearerror())

  }

  
  async onUpdateCard(){
    var isError = false;
    var error = {errors: []};
    var { editExpireDate } = this.state;

    var currentYear = moment().format(YY)
    var currentMonth = moment().format('M')
    if(editExpireDate.toString().trim() == ""){
      isError = true;
      error['errors']['card.exp_month'] = ["Please enter expiry date"];
    }else if(editExpireDate.toString().trim().length < 4){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if(parseInt(this.state.editExpireDate.slice(0,2)) > 12 || parseInt(this.state.editExpireDate.slice(0,2)) < 1){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(editExpireDate.toString().trim().slice(2,6)) > parseInt(currentYear) + 20) || parseInt(editExpireDate.toString().trim().slice(2,6)) < parseInt(currentYear) ){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(this.state.editExpireDate.slice(0,2)) < currentMonth) && (parseInt(editExpireDate.slice(2,6))) <= currentYear){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }

    if(isError){
      this.props.dispatch(showError(error));
      return
    }

    await this.props.dispatch(UpdateBillingCard(this.state.editExpireDate , this.props.primerCreditCard, this.props.isCorporate));

    await setTimeout(() => { 
       this.props.getPrimeryCardInfo()
    }, 1500)
    
    this.setState({isUpdate:!this.state.isUpdate });
  }

  isValidate(){
    const { cardHolderName, creditCardNumber, editExpireDate, code } = this.state;
    var error = {errors: []};
    var isError = false;


    

    var currentYear = moment().format(YY)
    var currentMonth = moment().format('M')
    
    
    if(code.toString().trim() == ""){
      isError = true;
      error['errors']['card.cvv'] = ["Please enter cvv"];
    }else if(code.toString().trim().length < 3 ){
      isError = true;
      error['errors']['card.cvv'] = ["Invalid cvv"]; 
    }else if(getCardNameByNo(creditCardNumber) == "AMEX" && code.toString().trim().length < 4 ){
      isError = true;
      error['errors']['card.cvv'] = ["Invalid cvv"];
    }

    if(editExpireDate.toString().trim() == ""){
      isError = true;
      error['errors']['card.exp_month'] = ["Please enter expiry date"];
    }else if(editExpireDate.toString().trim().length < 4){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if(parseInt(this.state.editExpireDate.slice(0,2)) > 12 || parseInt(this.state.editExpireDate.slice(0,2)) < 1){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(editExpireDate.toString().trim().slice(2,6)) > parseInt(currentYear) + 20) || parseInt(editExpireDate.toString().trim().slice(2,6)) < parseInt(currentYear) ){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(this.state.editExpireDate.slice(0,2)) < currentMonth) && (parseInt(editExpireDate.slice(2,6))) <= currentYear){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }

    
    if(cardHolderName.toString().trim() == ""){
      isError = true;
      error['errors']['card.name'] = ["Please enter name"];
    }

    if(creditCardNumber.toString().trim() == ""){
      isError = true;
      error['errors']['card.number'] = ["Please enter card number"];
    }

    this.props.dispatch(showError(error));
    return isError
  }
  
 async onAddCard(){
 
  if(this.isValidate()) return;
  
  let params={
      "card": {
          "name": this.state.cardHolderName,
          "number": this.state.creditCardNumber,
          "expiry_month":  parseInt(this.state.editExpireDate.slice(0,2)), 
          "expiry_year": parseInt(this.state.editExpireDate.slice(2,6)), 
          "cvv": this.state.code,
          "is_primary":1
        }
    }
    // var practiceId = this.props.isCorporate ? this.props.primerCreditCard.practice_id : null;
    var practiceId = this.props && this.props.match && this.props.match.params && this.props.match.params.practiceId ? this.props.match.params.practiceId : "";
    await this.props.dispatch(AddBillingCard(params, practiceId));

  }
  
  changeHandler(event, filed){
    var filedName = "", filedValue = "";
    var vardImage = this.state.vardImage;
    if(filed){
        filedName = filed;
        filedValue = event.value;
        if(filedName === "creditCardNumber"){
            vardImage = getCardImageByNo(filedValue);
        }
    }else{
        var { name, value } = event.target;
        filedName = name;
        filedValue = value;
    }

    this.props.dispatch(showError({ errors: [] }));
    this.setState({
        [filedName]: filedValue,
        vardImage,
        error: {} 
    },()=> {
        this.isValid(filedName , filedValue);
    });
  }

  isValid(name , value){
    let isError = this.state.isError ;

    switch (name) {
      case "creditCardNumber":
          isError.creditCardNumber =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "cardHolderName":
          isError.cardHolderName =  
          value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "editExpireDate":
          isError.editExpireDate =  
          value.length <= 0 ? "required" : "";
          break;
      case "code":
        isError.code =  
        value.length <= 0 ? "required" : "";
        break;
      default:
          break;
    }

    this.setState(this.validateForm)
  }
  
  validateForm(){
    console.log(this.state.editExpireDate,'date')
    this.setState({formValid: this.state.isError.creditCardNumber === ''  && this.state.isError.cardHolderName === ''  && this.state.isError.editExpireDate ===''});
  }


  render (){
    const primerCreditCard = this.props.primerCreditCard || null;
    const expireDate = this.props.expireDate
    const { isSuccess , loadingAddCardInfo , loadingUpdate , error} = this.props
    var imgsrc = !this.state.isReplace &&  primerCreditCard?.brand  ? getCartImageByType(primerCreditCard?.brand) : this.state.vardImage,
    isError = this.state.isError
    return(
        <div className="billingcard__inner__panel transparent__form--white d-flex flex-column"> {/* for screen 173,174,175 add class bg-danger */}
          <img src={StripeIcon} className="stripe-logo" alt=""/>
          <div className="billingcard__inner__panel__header d-flex flex-column">
            <h4>Credit card</h4>
            {this.state.isUpdate ?
            <p>Update your primary credit card information.</p>
            :
            <>
              {this.state.isReplace && primerCreditCard?.brand ? 
                <p>Replace your primary credit card by filling in the required fields with your new credit card’s information.</p>
              :      
              <p>This is your primary credit card. It is used to pay for any Thalamus related fees, and can be updated at any moment.</p>
              }
            </>
            }
            
            <div className="card-type mt-auto">
              {!this.state.isReplace && primerCreditCard?.brand?
                <>
                  <img src={getCartImageByType(primerCreditCard?.brand)} className="" alt="" />
                  <p>{primerCreditCard?.brand}</p>
                </>
              : null }
              </div>
          </div>
          {/* {loadingBillingCardInfo ?
            <div className=" flex-grow-1 flex-column d-flex  align-items-center justify-content-center">
              <Spinner color="primary" />
            </div>
          : */}
          <>
          {isSuccess ?
            <>
            {this.state.isReplace ?
              <>
              <div className="billingcard__inner__panel__body cc-confirmation">
                <p>Primary credit card successfully {this.state.isFirstTimeCardAddedd ? 'added.' : 'replaced.'} </p>
              </div>
              <div className="billingcard__inner__panel__footer mt-auto">
                <div className=""><Button className="w-100" onClick={() => this.onShowNewCard()}  color="primary">Show new credit card</Button></div>
              </div>
              </>
            :  
              <>
              <div className="billingcard__inner__panel__body cc-confirmation">
                <p>Primary credit card successfully updated.</p>
              </div>
              <div className="billingcard__inner__panel__footer mt-auto">
                <div className=""><Button className="w-100" onClick={() => this.onShowUpdatedCard()}  color="primary">Show updated credit card</Button></div>
              </div>
              </>
          } 
            </>
          :
          <>
          {console.log(primerCreditCard,'primerCreditCard', this.props.primerCreditCard)}
            {primerCreditCard  !== null || this.state.isReplace ?
            <>
            <div className={`${primerCreditCard?.brand}-bg billingcard__inner__panel__body ${this.state.isReplace ? 'isReplace' : ''}  ${primerCreditCard?.declined_at && !this.state.isReplace ? 'declined' : ''}`}> {/* mastercard-bg , visacard-bg , amexcard-bg , discovercard-bg*/}
              <div className="row">
                <div className="col-12">
                  {console.log(primerCreditCard,'primerCreditCard')}
                  <div className={`${error && error.errors && error.errors['card.number'] ? 'inputerror' : ''} form-group  icon-card `}>
                      <div className={`d-flex justify-content-between `}>
                          <label>Credit card number</label>
                          {error && error.errors && error.errors['card.number'] && <span className='text--danger'>{error.errors['card.number'][0]}</span>}
                      </div>
                      {this.state.isReplace || !primerCreditCard?.brand?
                      <NumberFormat className="form-control" format="#### #### #### ####" name="creditCardNumber" value={this.state.creditCardNumber} onValueChange={(value)=>this.changeHandler(value, "creditCardNumber")}/>
                      :
                      <Input type="text" disabled value={`xxxx xxxx xxxx ${primerCreditCard?.last4}`}/>
                      }
                      {imgsrc ? <img src={imgsrc}  className="icon-img" alt="" /> : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className={`form-group ${error && error.errors && error.errors['card.name'] ? 'inputerror' : ''}`}>
                    <div className={`d-flex justify-content-between `}>
                      <label>Name on card</label>
                      {error && error.errors && error.errors['card.name'] && <span className='text--danger'>{error.errors['card.name'][0]}</span>}
                    </div>
                    {!this.state.isReplace && primerCreditCard?.brand ? 
                        <Input type="text" name="cardHolderName" value={primerCreditCard?.name} disabled/>
                      :
                        <Input type="text" name="cardHolderName" onChange={this.changeHandler} value={this.state.cardHolderName} />
                    }
                  </div>
                </div>
                <div className="col-6">
                  <div className={`${(error && error.errors && error.errors['exp_month'] || error && error.errors && error.errors['exp_year'] || error && error.errors && error.errors['card.exp_month']) ? 'inputerror' : ''} form-group mb-0`}>
                    <label>Expiry date</label>
                    {this.state.isReplace || this.state.isUpdate ||  !primerCreditCard?.brand ?
                      <NumberFormat  className="form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="editExpireDate" onValueChange={(value)=>this.changeHandler(value, "editExpireDate")} value={this.state.editExpireDate}/>
                    :
                      <NumberFormat  className="form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="expireDate" disabled value={expireDate}/>
                    }
                    {error && error.errors && error.errors['card.exp_month'] && <span className='text--danger'>{error.errors['card.exp_month'][0]}</span>}
                  </div>
                </div>
                <div className="col-6">
                  <div className={`form-group  mb-0 cvv-img ${error && error.errors && error.errors['card.cvv' ]? 'inputerror' : ""}`}>
                    <label>Security code <img src={CVVIcon} alt="" /> </label>
                    <NumberFormat  className="form-control"  format={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "####" : "###" } disabled={!this.state.isReplace} placeholder={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "XXXX" : "XXX"} name="code" value={this.state.code} onValueChange={(value)=>this.changeHandler(value, "code")} type="password"/>
                    {error && error.errors && error.errors['card.cvv'] && <span className='text--danger'>{error.errors['card.cvv'][0]}</span>}
                    <div className="what-cvv">? <span><img src={CVVIconHover} alt=""/> <p>The 3 or 4 numbers on the back of your Visa, MasterCard, or AMEX card.</p></span></div>
                  </div>
                </div>
                {primerCreditCard?.declined_at && !this.state.isReplace &&
                <div className="card__declined text-center d-flex justify-content-center align-items-center">
                  Declined payment  | { moment(primerCreditCard?.declined_at).format(DateDDMMMYYY) }
                </div>
                }
              </div>
            </div>
            <div className="billingcard__inner__panel__footer mt-auto">
              {this.state.isUpdate ?
                <div className="row">
                  <div className="col-6">
                    {/* <Button disabled={!this.state.formValid} size="md" onClick={this.onUpdateCard}  color="primary">Update card</Button> */}
                    <LoadingButton type="button" label={`Update card`} loading={loadingUpdate} disabled={loadingUpdate} size="md" onClick={this.onUpdateCard} className={``} color="primary"/>
                  </div>
                  <div className="col-6"><Button size="md" onClick={() => this.onClickUpdateCard()} outline color="default white">Cancel</Button></div>
                </div>
              :
                <>
                {this.state.isReplace || !primerCreditCard.brand ?
                <div className="row">
                  <div className="col-6">
                    {/* <Button size="md" disabled={!this.state.formValid} onClick={() => this.onAddCard()} color="primary">Add Card</Button> */}
                    <LoadingButton type="button" error={this.props.error} label={'Add Card'} loading={loadingAddCardInfo} disabled={loadingAddCardInfo} size="xxl" onClick={() => this.onAddCard()} color="primary"/>
                  </div>
                  <div className="col-6"><Button size="md" onClick={() => this.onReplaceCard()} outline color="default white">Cancel</Button></div>
                </div>
                :
                <div className="row">
                  <div className="col-6"><Button size="md" onClick={() => this.onReplaceCard()} color="primary">Replace card</Button></div>
                  <div className="col-6"><Button size="md" onClick={() => this.onClickUpdateCard()}  outline color="default white">Update card</Button></div>
                </div>
                }
                </>
              }
            </div>
            </>
            :
            <>
                <div className="billingcard__inner__panel__body secondary-cc-add p-5">
                    <p>Add a primary credit card <br/>to see it displayed here.</p>
                </div>
                <div className="billingcard__inner__panel__footer mt-auto">
                  <Button className="w-100" onClick={() => this.onReplaceCard()}  color="primary">Add card</Button>
                </div>
              </>
            }
          </>
          } 
          </> 
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingAddCardInfo:state.task.loadingAddCardInfo,
  loadingBillingCardInfo:state.task.loadingBillingCardInfo,
  loadingUpdate:state.task.loadingUpdate,
  isUpdateSuccess:state.task.isUpdateSuccess,
  isSuccess:state.task.isSuccess,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo,
  getBillingCardInfo:state.task.getBillingCardInfo,
  error:state.task.error,
  isCorporate: state.task.isCorporate,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
