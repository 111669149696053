import React, { useState, useEffect } from "react";
import SubHeader from '../../components/Header/subHeader';
import { useDispatch, useSelector } from "react-redux";
import UploadCetegoryImages from "../../components/UploadCetegoryImages";
import { ISOPERATORYROOMCHANNELIMAGE } from "../../data/comman";

const WaitingRoomImages = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);


    return (
        <div className='Operatory__main__screen d-flex flex-column h-100 align-items-center'>
            <SubHeader className='' title={'New Operatory Room Channel'} />
            <UploadCetegoryImages isOperatoryRoom={true} isImage={ISOPERATORYROOMCHANNELIMAGE} isChannelImageChange={true} isBackButton={true} hideCancelButton={false}/>
        </div>
    ); 
};

export default WaitingRoomImages;
