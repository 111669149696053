import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import './style.scss';
import { Button} from 'reactstrap';

const HomeIndex = (props) => {
  return(
    <div className="main__screen language__page language_system d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Language"/>
        <div className="language__inner mb-auto">
          <div className="language__inner__header">
            <h4>System language</h4>
            <p>You have selected Spanish for you System Language. This will affect only Thalamus’ interface language. To also set the audio language in all Thalamus videos to Spanish, please select the option to change both System and Media Language.</p>
          </div>
          <div className="language__inner__body system_body">
            <p>Set “Spanish” as</p>
          <div className="row">
            <div className="col-12 mb-3">
              <Button size="md" color="primary">System and media language</Button>
            </div>
            <div className="col-6"><Button size="md" color="primary">System language</Button></div>
            <div className="col-6"><Button size="md" color="danger">Cancel</Button></div>
          </div>
          </div>
        </div>
    </div>
  ) 
}
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
