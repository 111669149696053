import React, { Fragment } from 'react'
import {
  Router,
  Route as DefaultRoute,
  Switch
} from 'react-router-dom'

import { withTranslation } from 'react-i18next';
import { connect ,useSelector, useDispatch } from "react-redux";
import { compose } from 'redux';
import { Button } from 'reactstrap';
import error_worning_small from '../img/error_worning_small.svg'
import PurchaseHardware from './PurchaseHardware';
import { createBrowserHistory } from "history";
import ProtectedRoute from '../components/ProtectedRoute';
import Header from '../components/Header';
import HeaderOnlyLogo from '../components/HeaderOnlyLogo';
import Help from './Help'
import Inactive from './Account_Inactive';
import AccountSuspended from './Account_Inactive/account_suspended';
import AccountCancelled from './Account_Inactive/account_cancelled';

import LimitedAccess from './Account_Inactive/limited_access';
import PaymentInfo from './PaymentRequest';
import CreditCardDeclined from '../components/CreditCardDeclined/CreditCards';
import OneTimePayment from '../components/CreditCardDeclined/CreditCards/OneTimePayment';
import Authorizing from './tos';
import TosDeclines from './tos/tos_not_accepted'
import Trial from './trial'
import Login from './login';
import LogOut from './logout';
import ForgotAccessCode from './forgot_access_code'
import InitialSetup from './InitialSetup'
// import OwnerProfileSetup from './ownersProfileCreation'
// import OwnerProfilePicture from './ownersProfileCreation/ownerProfilePicture'
import ProfileSelection from './profileSelection'
import NewProfile from './NewProfile'
import EditProfiles from './EditProfiles'
import Signin from './Signin'
import Welcome from './Signin/welcome'
import welcomeToThalamus from './Signin/welcomeToThalamus';
import ProfileCreated from './NewProfile/ProfileCreated'
import Channels from './HomeScreen'
import CorporateHome from './CorporateHomeScreen'
import ManageLocation from './manageLocation'
import SubscriptionPayment from './SubscriptionPayment';
import CorporatePayment from './CorporateBilling/CorporateBillingCard';
import CorporateInvoices from './CorporateInvoices'
import CorporateInvoiceView from './CorporateInvoices/CorporateInvoiceView'
import DesignateCreditCard from './DesignateCreditCard'
import PaymentMethodSetup from './PaymentCardSetup'
import ViewAccountProfiles from './ViewAccountProfiles'
import ActiveProfiles from './ActiveProfiles'
import Settings from './Settings'
import GetFreeMonth from './Settings/getFreeMonth'
import Subscribe from './Subscribe'
import SubscribeIntro from './Subscribe/intro'
import SubscriptionCompleted from './Subscribe/subscriptionCompleted'
import Addons from './Addons'
import AddonsList from './Addons/addonsList'
import UpgradePlan from './upgradePlan'
import EditAuthorizedprofile from './EditOwnerProfile'
import profileCreated from './EditOwnerProfile/profileCreated'
import AddAuthorizedUser from './AuthorizedUser/addAuthorizedUser';
import Nointernet from './PageLoadingError';
import Error from './Error'
// import LoadingPage from './LoadingPage'
// import DeleteAuthorizedUser from './EditOwnerProfile/deleteAuthorizedUser'

import ReferralList from './ReferralList'
import ReferralListEmpty from './ReferralList/empty'
import FreeMonthReferral from './ReferralList/freemonthreferral'
import Authorized from './AuthorizedUser'
import AuthorizedUser from './AuthorizedUser/authorizedUser'
import AuthorizedDevice from './AuthorizedDevice'

import NotificationMessages from './NotificationMessages'

import Language from './Language'
import VideoSettings from './Settings/videoQuelity'
import SystemLanguage from './Language/SystemLanguage'

import ClosedCaptions from './ClosedCaptions'
import EmailPreferences from './EmailPreferences'
import BillingAdminProfile from './BillingAdminProfile'
import BillingProfileVerificationCode from './BillingAdminProfile/BillingProfileVerificationCode'
import BillingCard from './BillingAdminProfile/BillingCard'
import RestrictedAccess from './RestrictedAccess'

import Invoices from './Invoices'
import InvoicesVerify from './Invoices/verification'
import InvoicesEmpty from './Invoices/empty'
import InvoiceView from './Invoices/InvoiceView'
import ManageDomains from './ManageDomains'
// import authorized from './ManageDomains/authorized'
import ManageDevices from './ManageDevice'

import AccessCode from './accessCode';
import GenerateAccessCode from './accessCode/generateAccessCode';
import AccessCodeVerifyUser from './accessCode/accessCodeVerifyUser';

import PracticeInfoVerify from './PracticeInfo'
import PracticeInfo from './PracticeInfo/PracticeInfoEdit'
import BasicInformation from './PracticeInfo/PracticeInfoEdit/PracticeInfo'
import Additionalinformation from './PracticeInfo/PracticeInfoEdit/additionalInformation'
// import PracticeHours from './PracticeInfo/PracticeInfoEdit/PracticeHours'
// import PracticeHours from './PracticeInfo/hours'
// import PracticeLocation from './PracticeInfo/location'
// import PracticeManagers from './PracticeInfo/managers'

import AdminProfileMobile from './AdminProfileMobile'
// import AdminProfileMobileCode from './AdminProfileMobile/MobileCode'
import AdminProfileVerification from './AdminProfileMobile/AdminProfileVerification'
import AdminProfileVerificationCode from './AdminProfileMobile/AdminProfileVerificationCode'

import WebsiteVideoLibrary from './websiteVideos/WebsiteVideoLibrary';

import WaitingRoom from './waiting_room/Index';
// import profilesetup from './ownersProfileCreation'
import ChairsideEducation from './chairsideEducation/ChairsideEduction';
import EditChairsideEducationCC from './chairsideEducation/EditCaptions'
import RenderHTML from './HTMLs';
import SelectCategory from './selectCategories';
import GetHTMLCode from './Code';
import CreateNewWaitingRoomChannel from './New_Waiting_Room/CreateNewWaitingRoomChannel';
import WaitingRoomFooter from './WaitingRoomFooter/WaitingRoomFooter';
import WaitingRoomHeader from './WaitingRoomHeader/WaitingRoomHeader';
import NewWaitingRoomAdded from './NewWaitingRoomAdded/NewWaitingRoomAdded';
import WaitingRoomTimeline from './WaitingRoomTimeline/WaitingRoomTimeline';
import WaitingRoomSchedule from './WaitingRoomSchedule/WaitingRoomSchedule';
import WaitingRoomImages from './WaitingRoomImages';

import WaitingRoomChannels from './WaitingRoomChannels/WaitingRoomChannels';
import RequestVideo from './RequestVideo/RequestVideo';
import ManageChannel from './ManageChannel/ManageChannel';

import LocationListing from './LocationListing'
import LocationVarify from './LocationListing/verification'
// import AddLocation from './LocationListing/Add_Location'
import WaitingRoomTimelineSlides from '../components/WaitingRoomTimelineSlides';
import AlertMessage from '../components/AlertMessage'
import Timeline from './OperatoryRoom/TImeline';
import Slide from './Slide';
import Managers from './LocationListing/managers'

// start by dixit on 28-07-2021
import OperatoryChannels from './OperatoryChannels/OperatoryChannels';
import CreateNewOperatoryChannel from './New_Operatory/CreateNewOperatoryChannel';
import OperatoryFooter from './OperatoryFooter/OperatoryFooter';
import OperatoryHeader from './OperatoryHeader/OperatoryHeader';
import OperatorySchedule from './OperatorySchedule/OperatorySchedule';
import OperatoryRoomImages from './OperatoryRoomImages';
import ChannelDetail from './ChannelDetail';


import emailVideo from './emailVideo'
import sharedVideoHistory from './emailVideo/sharedVideoHistory'


import CreateThalamusChannel from '../pages/CreateThalamusChannel'
import setPasscode from '../pages/Signin/setPasscode'

import contactUs from '../pages/Help/contact'

import embedCode from '../pages/embedCode'

import purchaseCompleted from '../pages/PurchaseHardware/purchaseCompleted'
import goolgeTvTestDrive from '../pages/GoogleTvTestDrive/HomeScreen'
import googleTvWaitingRoomScreen from './GoogleTvTestDrive/WaitingRoomScreen'
import googleTvOperatoryRoomScreen from './GoogleTvTestDrive/OperatoryRoomScreen'
import googleTvVideoDetail from '../pages/GoogleTvTestDrive/VideoDetail'
import googleTvChairsideVideoDetail from '../pages/GoogleTvTestDrive/ChairsideEducation/videoDetail'
import googleTvChairsideEducation from '../pages/GoogleTvTestDrive/ChairsideEducation'
import googleTvProfile from '../pages/GoogleTvTestDrive/Profile'
import googleTvSettings from '../pages/GoogleTvTestDrive/Settings'
import googleTvSpecialization from '../pages/GoogleTvTestDrive/ChairsideEducation/SpecializationVideoList'

import 'react-phone-input-2/lib/material.css'
import LoginForm from './login/loginForm';

import ManageChairsidePlaylist from './chairsideEducation/ChairsidePlaylist/ManageChairsidePlaylist';
import EditChairsidePlayList from './chairsideEducation/ChairsidePlaylist/EditChairsidePlayList';
export const history = createBrowserHistory({ forceRefresh: false });

const DefaultLayout = ({ children }) => (
  <Fragment>
    <Header />
    {children}
  </Fragment>)

const HeaderStyle2 = ({ children }) => (
  <Fragment>
    <HeaderOnlyLogo />
    {children}
  </Fragment>
)

// const HomePageLayout = ({ children }) => <Fragment>{children}</Fragment>


const Route = ({
  component: Component,
  layout: Layout = DefaultLayout,
  ...rest
}) => {
  return (
    <DefaultRoute
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}


function Main(props) {
  const states = useSelector(state => state.task);

  return (
    <>
      <Router path="/" history={history}>
        <Switch>
          <Route exact path="/user-login" component={LoginForm} layout={HeaderStyle2}/>
          <Route exact path="/" component={Login} layout={HeaderStyle2} />
          <Route exact path="/logout" component={LogOut} />
          <Route exact path="/inactive" component={Inactive} />
          <Route exact path="/accountSuspended" component={AccountSuspended} />
          <Route exact path="/accountCancelled" component={AccountCancelled} />
          <Route exact path="/limited-access" component={LimitedAccess} />
          <Route exact path="/paymentInfo" component={PaymentInfo} />
          <Route exact path="/payment-info" component={CreditCardDeclined} />
          <Route exact path="/one-time-payment" component={OneTimePayment} />
          <Route exact path="/tos" component={Authorizing} layout={HeaderStyle2} />
          <Route exact path="/tosdeclines" component={TosDeclines} layout={HeaderStyle2} />
          <Route exact path="/trial" component={Trial} layout={HeaderStyle2} />
          <Route exact path="/welcome-to-thalamus" component={welcomeToThalamus} layout={HeaderStyle2} />
          <Route exact path="/forgot-access-code" component={ForgotAccessCode} layout={HeaderStyle2} />
          <Route exact path="/initialsetup" component={InitialSetup} layout={HeaderStyle2}/>
          {/* <Route exact path="/ownerprofilesetup" component={OwnerProfileSetup} /> */}
          {/* <Route exact path="/ownerprofilepicture" component={OwnerProfilePicture} /> */}
          <Route exact path="/profileselection" component={ProfileSelection} />
          <Route exact path="/newprofile" component={NewProfile} />
          {/* <Route exact path="/loadingpage" component={LoadingPage} /> */}
          <ProtectedRoute exact path="/access-code-verify-user" component={AccessCodeVerifyUser} />
          <Route exact path="/access-code" component={AccessCode} />
          <ProtectedRoute exact path="/generate-access-code" component={GenerateAccessCode} />
          <Route exact path="/website-player-customization" component={RenderHTML} />
          <Route exact path="/get-html-code" component={GetHTMLCode} />
          <Route exact path="/select-category-website-videos" component={SelectCategory} />
          <Route exact path="/manage-devices" component={ManageDevices} />
          <Route exact path="/authorized-device" component={AuthorizedDevice} />
          <Route exact path="/add-new-waiting-room-channel" component={CreateNewWaitingRoomChannel} />
          <Route exact path="/manage-waiting-room-channel" component={ManageChannel} />
          <Route exact path="/waiting-room-channel-header" component={WaitingRoomHeader} />
          <Route exact path="/waiting-room-channel-footer" component={WaitingRoomFooter} />
          <Route exact path="/waiting-room-channel-added" component={NewWaitingRoomAdded} />
          <Route exact path="/waiting-room-channel-timeline" component={WaitingRoomTimeline} />
          <Route exact path="/waiting-room-channel-schedule" component={WaitingRoomSchedule} />
          <Route exact path="/waiting-room-channel-images" component={WaitingRoomImages} />
          <Route exact path="/channel-detail" component={ChannelDetail} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/waiting-room-channels" component={WaitingRoomChannels} />
          <Route exact path="/video-request" component={RequestVideo} />

          <Route exact path="/waiting-room-slide-edit" component={WaitingRoomTimelineSlides} />
          <Route exact path="/no-internet" component={Nointernet} />
          <Route exact path="/editprofile" component={EditProfiles} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/welcome" component={Welcome} layout={HeaderStyle2}/>
          <Route exact path="/profilecreated" component={ProfileCreated} />
          <Route  path="/channels" component={Channels} />
          <ProtectedRoute exact path="/view-account-profiles" component={ViewAccountProfiles} />
          <ProtectedRoute exact path="/active-profiles" component={ActiveProfiles} />
          <Route exact path="/settings" component={Settings} />
          <ProtectedRoute exact path="/getfreemonth" component={GetFreeMonth} />
          <Route exact path="/subscribe" component={SubscribeIntro} />
          <Route exact path="/subscribe-form" component={Subscribe} />
          <Route exact path="/subscription-completed" component={SubscriptionCompleted} />
          <Route exact path="/language" component={Language} />
          <Route exact path="/videosettings" component={VideoSettings} />
          <Route exact path="/notification-messages" component={NotificationMessages} />
          <Route exact path="/referral-list" component={ReferralList} />
          <ProtectedRoute exact path="/billing-admin-profile" component={BillingAdminProfile} />
          <ProtectedRoute exact path="/billing-card" component={BillingCard} />
          <Route exact path="/restricted-access" component={RestrictedAccess} />
          <ProtectedRoute exact path="/addons" component={Addons} />
          <ProtectedRoute exact path="/addons-list" component={AddonsList} />
          <ProtectedRoute exact path="/authorized" component={Authorized} />
          <ProtectedRoute exact path="/authorized-user" component={AuthorizedUser} />
          <Route exact path="/edit-authorized-profile" component={EditAuthorizedprofile} layout={states.practiceInfo && states.practiceInfo.need_owner_setup === 1 ? HeaderStyle2 :DefaultLayout}/>
          <Route exact path="/profile-created" component={profileCreated} />
          <ProtectedRoute exact path="/add-authorized-user" component={AddAuthorizedUser} />
          {/* <ProtectedRoute exact path="/delete-authorized-user" component={DeleteAuthorizedUser} /> */}

          <Route exact path='/website-videos' component={WebsiteVideoLibrary} />
          <Route exact path="/waiting-room" component={WaitingRoom} />
          {/* <Route exact path="/profilesetup" component={profilesetup} /> */}
          <Route exact path='/chairside-education' component={ChairsideEducation} />
          <Route exact path='/chairside-education-edit-close-caption' component={EditChairsideEducationCC} />
          
          <Route exact path='/upgrade-plan' component={UpgradePlan} />
          {/* <Route exact path='/error' component={Error} /> */}
          {/* <Route exact path="/authorized-device" component={AuthorizedDevice} /> */}


          <Route exact path="/referral-empty" component={ReferralListEmpty} />
          <Route exact path="/free-month-referral" component={FreeMonthReferral} />




          {/* <Route exact path="/language-system" component={SystemLanguage} /> */}

          <Route exact path="/closed-captions" component={ClosedCaptions} />
          <ProtectedRoute exact path="/email-preferences" component={EmailPreferences} />

          {/* <Route exact path="/billing-profile-verification-code" component={BillingProfileVerificationCode} /> */}

          <Route exact path="/invoices" component={Invoices} />
          <Route exact path="/invoiceverify" component={InvoicesVerify} />
          <Route exact path="/invoices-empty" component={InvoicesEmpty} />
          <Route exact path="/invoice-view" component={InvoiceView} />
          <Route exact path="/manage-domains" component={ManageDomains} />
          {/* <Route exact path="/manage-domains-authorized" component={authorized} /> */}
          <Route exact path="/practice-info-edit" component={PracticeInfo} />
          <Route exact path='/assign-manager' component={Managers} />
          <ProtectedRoute exact path="/basic-info-edit" component={BasicInformation} />
          <ProtectedRoute exact path="/additional-info-edit" component={Additionalinformation} />
          {/* <ProtectedRoute exact path="/practice-hours-edit" component={PracticeHours} /> */}

          <ProtectedRoute exact path="/practice-info" component={PracticeInfoVerify} />

          {/* <Route exact path="/practice-location" component={PracticeLocation} /> */}
          {/* <Route exact path="/practice-managers" component={PracticeManagers} /> */}

          <Route exact path="/admin-profile-update" component={AdminProfileMobile} />
          {/* <Route exact path="/admin-profile-mobile-code" component={AdminProfileMobileCode} /> */}
          <Route exact path="/admin-profile-verification" component={AdminProfileVerification} />
          <Route exact path="/admin-profile-verification-code" component={AdminProfileVerificationCode} />

          <ProtectedRoute exact path="/locations" component={LocationListing} />
          <Route exact path="/location-user-verification" component={LocationVarify} />
          {/* <ProtectedRoute exact path="/add-location" component={AddLocation} /> */}
          <Route exact path="/error" component={Error} />

          <Route exact path="/corporate" component={CorporateHome} />
          <Route exact path="/add-manage-location" component={ManageLocation} />
          <Route exact path="/subscription-payment" component={SubscriptionPayment} />
          <Route exact path="/:practiceId/payment-method" component={CorporatePayment} />
          <Route exact path="/:practiceId/invoices" component={Invoices} />
          <Route exact path="/:practiceId/invoice-view" component={InvoiceView} />

          <Route exact path="/corporate-invoices" component={CorporateInvoices} />
          <Route exact path="/corporate-invoicesview" component={CorporateInvoiceView} />
          <Route exact path="/designate-credit-card" component={DesignateCreditCard} />
          <Route exact path="/payment-card-setup" component={PaymentMethodSetup} />
          <Route exact path="/purchase-hardware" component={PurchaseHardware} />

          {/* opertory room */}
          <Route exact path="/operatory-room-timeline" component={Timeline} />

          <Route exact path="/slide" component={Slide} />
          {/*Added by Dixit Solanki ( work releted operatory chanel) */}
          <Route exact path="/operatory-channels" component={OperatoryChannels} />
          <Route exact path="/add-new-operatory-channel" component={CreateNewOperatoryChannel} />
          <Route exact path="/operatory-channel-header" component={OperatoryHeader} />
          <Route exact path="/operatory-channel-footer" component={OperatoryFooter} />
          <Route exact path="/operatory-channel-schedule" component={OperatorySchedule} />
          {/*Added by Dixit Solanki ( work releted operatory chanel) */}
          <Route exact path="/operatory-room-channel-images" component={OperatoryRoomImages} />

          <Route exact path="/create-thalamus-channel" component={CreateThalamusChannel} />

          <Route exact path="/setpasscode" component={setPasscode} />

          <Route exact path="/contact-us" component={contactUs} />


          <Route exact path="/chairside-create-playlist" component={ManageChairsidePlaylist} />
          <Route exact path="/chairside-edit-playlist" component={EditChairsidePlayList} />

          <Route exact path="/embed-code" component={embedCode} />

          <Route exact path="/purchase-completed" component={purchaseCompleted} />


          <Route exact path="/email-video" component={emailVideo} />

          <Route exact path="/email-video-history" component={sharedVideoHistory} />
          
          <Route exact path="/google-tv-test-drive" component={goolgeTvTestDrive} />
          <Route exact path="/google-tv-waiting-room" component={googleTvWaitingRoomScreen} />
          <Route exact path="/google-tv-operatory-room" component={googleTvOperatoryRoomScreen} />

          <Route exact path="/google-tv-video-detail" component={googleTvVideoDetail} />
          <Route exact path="/google-tv-chairside-video-detail" component={googleTvChairsideVideoDetail} />
          <Route exact path="/google-tv-chairside-room" component={googleTvChairsideEducation} />
          <Route exact path="/google-tv-profile" component={googleTvProfile} />
          <Route exact path="/google-tv-setting" component={googleTvSettings} />
          <Route exact path="/google-tv-chairside-specialization" component={googleTvSpecialization} />

        </Switch>



      </Router>
      
      <AlertMessage/>
    </>
  );
}

export default Main;

// const mapStateToProps = (state) => ({
//   error: state.task.error,
//   practiceInfo: state.task.practiceInfo,

// });
// export default compose(
//   withTranslation('common'),
//   connect(mapStateToProps)
// )(Main);