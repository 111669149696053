import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col, Form, Input, Button , FormGroup , Label} from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DropDown from '../../components/DropDown'
import LoadingButton from '../../components/LoadingButton'
import './styles.scss';
import { getFooterTime, waitingRoomLiveWeather, postFooterWeather, getFooterWeather , getwaitingRoomFooterWeather } from '../../store/actions/taskAction';
const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      // bottom: 48,
      // left:195,
      // margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const temperatureUnit = [
		{ value: 'c', label: 'Celsius' },
		{ value: 'f', label: 'Fahrenheit' },
	]
	
  
const OperatoryWeather = () => {
    const [state, setState] = useState({
        checkedB: false,
    });
    const [ location, setLocation ] = useState('');
    const [ unit, setUnit ] = useState('');
    const dispatch = useDispatch();
    const states = useSelector((state) => state.task);
    const [ weatherLocation, setLiveWeatherData ] = useState(states.weatherLocation)
    const [ newLocation, setNewLocation ] = useState(states.weatherLocation);

    useEffect(() => {
        dispatch( getwaitingRoomFooterWeather(states.manageOperatoryChannel.id, true));
    }, [])

    useEffect(() => {
      if(states.weatherLocation){
        setLocation(states.weatherLocation)
        setUnit(states.weatherLocation.unit)
        setState({ 'checkedB': states.weatherLocation.auto_set_location == 1 ? true : false});
      }
    }, [states.weatherLocation]);

    const bgImg = 'assets/img/bg.png';

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const locationChangeHandler = (event) => {
      setLocation(event.target.value);
      setMessage({
        message: "Save Changes",
        bgColor: "primary",
      });
    }

    const [message, setMessage] = useState({
        message: "Save Changes",
        bgColor: "primary",
    });
    
    const temperatureUnitChangeHandler = (event) =>{
      setUnit(event.value);
      setMessage({
        message: "Save Changes",
        bgColor: "primary",
      });
    }

    const weatherFormSubmitHandler = (event) => {
      let params ={
        auto_set_location:state.checkedB ? '1' : '0',
        location:location.location,
        unit:unit
      }
      console.log(params,'params')
      dispatch(postFooterWeather(states.manageOperatoryChannel.id, params, true))

      setMessage({
        message: "Changes saved",
        bgColor: "green",
      });
    
    }
    return (
    <div className="d-flex  waiting_room_common_section waiting_room_common_section_weather">
            <div className="transparent__form--white d-flex flex-column leftVBox mr-20 waiting_room_common_section_time_zone">
                <h4>Weather</h4>
                <>
                  <FormGroup className="d-flex align-items-center">
                    <p className="m-0">Set location automatically  </p>
                    <FormControlLabel className="toggle-button ml-4 mr-auto" control={<IOSSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />} />
                  </FormGroup>
                  <FormGroup className={` ${state.checkedB ? 'disable' : ' '}`}>
                    <Label>Location</Label>
                    <Input type="text"   value={location.location}  placeholder="Location" disabled={state.checkedB ? 'disabled' : ''} onChange={locationChangeHandler}/>
                  </FormGroup>
                  <FormGroup className={``}>
                    <div className="d-flex justify-content-between">
                      <Label>Temperature unit</Label>
                    </div>
                    <DropDown 
                      className='react-select-container'
                      classNamePrefix="react-select"
                      options={temperatureUnit}
                      getOptionLabel={ x => `${x.label}`}
                      getOptionValue={ x => `${x.value} ${x.label}`}
                      isDisabled={state.checkedB ? true : false}
                      onChange={temperatureUnitChangeHandler}
                      isSearchable={false}
                      placeholder={''}
                      // value={unit}
                    />
                  </FormGroup>
                  <LoadingButton type="button"  label={message.message} loading={states.buttonLoading} disabled={states.buttonLoading} size="xxl" onClick={weatherFormSubmitHandler} className={`mt-auto w-100`}  color={message.bgColor}/>
                </>
            </div>
            <div className="transparent__form--white rightBox ml-20 waiting_room_common_section_img">
                <img className="h-100" src={bgImg} alt=""/>
                {/* <div class="">
                    <label>NEWS</label>
                    <div class="">
                        Welcome to Smith Dental!
                    </div>
                    <div class="">
                      <p class="card-text" style={{ color:"black" }}>With supporting text below as a natural lead-in to additional content With supporting text below as a natural lead-in to additional.</p>
                    </div>
                    <div className="">
                    <p style={{ backgroundColor:"#c02323" }}>{ states.liveFooterTime.time ? states.liveFooterTime.time : '' }</p>
                        <img className="Page6__main__timeweather__image" src={states.liveWeatherData && states.liveWeatherData.current ? states.liveWeatherData.current.weather_icons[0] : ''} alt="Weather Icon" />
                        <p style={{ backgroundColor:"black" }}>{ states.liveWeatherData && states.liveWeatherData.current ? states.liveWeatherData.current.temperature : ''}{newLocation.unit == "c" ? <>&#8451;</> : <>&#8457;</> }</p>
                    </div>
                </div> */}
            </div>
    </div>
    )
}
export default OperatoryWeather;