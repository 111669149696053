import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import VerifyIdentityBox from '../../components/VerifyIdentityBox'
import './style.scss';

const Index = (props) => {
  return(
    <div className="authorized_section__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Authorized Users"/>
        <VerifyIdentityBox pageUrl={`/authorized-user`}/>
    </div>
  ) 
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);
