import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import {fetchChairsideMediaCategoryList, setSelectedChairSideMediaSpecializationCategory } from "../../store/actions/taskAction";
import { List, ListItem, ListItemText } from "@material-ui/core";

export function TabPanel(props) {
  const { children, value, index,  children: subItems, ...other } = props;

 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  
  /* root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  }, */
   active :{
    color:'#ffa819'
  },
  tooltip:{
    fontSize: '50px'
  },
  
  

}));



const HelpSidebar = ({value, setValue, tabList,setIdComponent}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // Below two are For Collapse Toggle
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
	// 	dispatch(fetchChairsideMediaCategoryList(states.practiceUserLoginInfo));
	// }, []);

  const showlabel = (name) =>{
    return <div className="tabname">{name}</div>
  }

  const showIcon = (icon) =>{
    return <div className="navIcons">{icon}</div>
  }

  const [selectedTab, setSelectedTab] = useState('Initial Setup');
  
  return (
    <>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        <List disablePadding dense className="help_menu">
        {tabList.map(({ tab, index, name, icon,  children: subItems, ...other}) => {
         
          return(
            <>
              <Tab icon={showIcon(icon)} label={showlabel(name)} {...a11yProps(index)}  className = {`heading`}  />     
              {Array.isArray(subItems) ? (
                <ul disablePadding className="help_submenu">
                  {subItems.map((subItems, index) => {
                   return(
                      <li key={subItems.name} {...a11yProps(index)} button className={`${selectedTab === subItems.name && "active-color"}`}>
                      <ListItemText className={`sidebar-item-text`} onClick={()=>{setIdComponent(subItems.url);setSelectedTab(subItems.name)}}>
                        {subItems.name}
                      </ListItemText>
                    </li>
                    )
                  
                   })}
                </ul>
              ) : null}
                </>
              ) 
        })}
         </List>
      </Tabs>
      
    </>

    
      
    
  );

}

export default HelpSidebar;