import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChairsidePlayer from '../../../components/ChairsidePlaylist/FullscreenPlayer'
import styles from './style.module.scss';
import EditeImageModal from '../../../components/EditImages/EditImageModel';
import imgurl from '../../../img/down_arrow.svg'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import './style.scss'
import {
    Close,
  } from "@material-ui/icons";
import { setChairsidePlaylistVideoActiveIndex } from '../../../store/actions/taskAction';
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const PreviewPlayListModal = ({timelineDetail, isOpen, toggle, handleCC, isGoogleTvTestDrive}) => {
	const state = useSelector((state) => state.task);
    const [video, setVideo] = useState({});
    const [isPipOn, setisPipOn] = useState(false);
    const [isPlaylistShow, setshowPlaylist] = useState(false);
    const [videoIndex, setVideoIndex] = useState(0);
    const [editorImg, setEditorImg] = useState({});
	  const [isEditor, setIsEditor] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let videoData = timelineDetail[videoIndex]?.media_data_multi.find(x=> x.media_language_id  == state.practiceUserAccountInfo.account_setting.media_language)
        setVideo(videoData)
        dispatch(setChairsidePlaylistVideoActiveIndex(0)) 

    }, []);
    
    useEffect(() => {
        let videoData = timelineDetail[videoIndex]?.media_data_multi.find(x=> x.media_language_id  == state.practiceUserAccountInfo.account_setting.media_language)
        setVideo(videoData)
    }, [videoIndex]);


    useEffect(() => {
      setVideoIndex(state.chairsideVideoActiveIndex)
    }, [state.chairsideVideoActiveIndex]);


      const setNextVideo = () =>{
        console.log('error')
        if(timelineDetail.length - 1 > videoIndex){
          setVideoIndex(videoIndex+1)
        }else{
          setVideoIndex(0)
        }
      }

      const setPreVideo = () =>{
        if(videoIndex > 1 ){
          setVideoIndex(videoIndex-1)
        }else{
          setVideoIndex(0)
        }
      }

      const openImgEditor = (img) => {
        if (img) {
          setEditorImg(img)
          setIsEditor(true)
        }
        }

        const togglePip = (isPipOn) => {
          console.log(isPipOn,'ispip');
          setisPipOn(isPipOn);
        }

        const showPlaylist = () =>{
          setshowPlaylist(!isPlaylistShow)
        }

        const onSetVideoIndex=(index)=>{
          console.log(index,'index')
          setVideoIndex(index)
        }

    
  return (<>
      {isOpen ? (<div size="xxl" className={`${isPipOn ? 'bg-none' : ' '} playlist_modal ${styles.playlist_modal} h-100 d-flex flex-column align-items-strach justify-content-center `} isOpen={isOpen} toggle={toggle}>
        {!isEditor ? 
          <>
          {video.id &&
          <div className={`${isPipOn ? 'd-none' : 'd-block'} video-show sdf  mt-5`}>
                  <ChairsidePlayer
                    video={video}
                    title={video}
                    className="w-100 h-100"
                    onClickClose={toggle}
                    setPreVideo={setPreVideo}  
                    setVideo={setVideo} 
                    setNextVideo={setNextVideo}
                    openImgEditor={openImgEditor}
                    togglePip={togglePip}
                    isChairsidePlaylist={true}
                    setVideoIndex={onSetVideoIndex}
                    videoIndex={videoIndex}
                    isGoogleTvTestDrive={isGoogleTvTestDrive}
                  />
          </div>
          }
          </>
          :
            <EditeImageModal
              open={true}
              closeEditor={() => { setIsEditor(false) }}
              // setOpen={setOpen}
              screenShotImage={editorImg}
            />
          }
		  </div>):(' ')}
    </> )
}

export default PreviewPlayListModal
