/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Card, CardBody, Button } from 'reactstrap';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LoadingButton from '../../components/LoadingButton'
const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomTimelineEntertainmentCategories = ({ specialization, setVideos, timeLineList, setModal, onPublish }) => {
	const state = useSelector((state) => state.task);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [allVideosIndex, setAllVideosIndex] = useState(true);
	const [categoryId, setCategoryId] = useState(null);

	const [isOpen, setIsOpen] = useState(false);
	const [allVideosCount, setAllVideosCount] = useState(0);
	const [videosList, setVideosList] = useState([]);
	const [count, setCount] = useState(0);
	const toggle = () => setIsOpen(!isOpen);
	const [subCat, setSubCat] = useState(false);
	const [subCategory, setSubCategory] = useState([]);
	const [filteredCategory, setFilteredCategory] = useState([]);
	
	useEffect(() => {
		const categoryArray = [];
		const videos = [];
		var totalVideos = 0;
		state.entertainmentTimeline.map((element) => {
			categoryArray.push({
				category_id: element.categories_id,
				category_name: element.categories_name,
				selected: false,
				videosLength: element.video.length,
				videos: element.video,
			});
			element.video.map((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				videos.push({
					categories_id: element.categories_id,
					categories_name: element.categories_name,
					...video,
					selected: false,
					isAdded
				});
			});
			totalVideos += element.video.length;
		});
		setAllVideosCount(totalVideos);
		setVideosList(videos);
		setVideos(videos);
		setCategories(categoryArray);
	}, [state.entertainmentTimeline ]);

	// OnCategoryClick
	const onCategoryClick = (item) => {
		setAllVideosIndex(false);
		setCount(count + 1);
		const videosArray = [];
		categories.forEach((element) => {
			if (element.category_id === item.category_id) {
				element.selected = !item.selected;
			}
		});
		categories.forEach((element) => {
			if (element.selected === true) {
				element.videos.forEach((video) => {
					var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
					videosArray.push({
						category_name: element.category_name,
						category_id: element.categories_id,
						...video,
						selected: true,
						isAdded
					});
				});
			}
			//  else {
			// 	element?.mainCategoryVideos?.map((video) => {
			// 		var isAdd = videosArray.findIndex(x => x.id === video.id);

			// 		videosArray.splice(isAdd, 1);
			// 	})
			// }
			// if (element.category_id === item.category_id) {
			// 	element.mainCategorySelected = !item.mainCategorySelected;
			// 	if(element.mainCategorySelected){ 
			// 		element.subCategories.map((cat, subIndex) => {
			// 			element.subCategories[subIndex].selected = true
			// 			cat.video.map((video) =>{
			// 				var isAdded = timeLineList.filter(x => x.media_id == video.media_id);
			// 				arr.push({ 
			// 					...video, 
			// 					category_name: cat.categories_name,
			// 					selected: true,
			// 					isAdded
			// 				});
			// 			})
			// 		});
			// 	}else{
			// 		element.subCategories.map(x => x.selected = false);
			// 	}
			// }else{
			// 	element.subCategories.map((cat, subIndex) => {
			// 		if(cat.selected){
			// 			cat.video.map((video) =>{
			// 				var isAdded = timeLineList.filter(x => x.media_id == video.media_id);
			// 				arr.push({
			// 					 ...video,
			// 					 category_name: cat.categories_name,
			// 					 selected: true,
			// 					 isAdded,
			// 				});
			// 			})
			// 		}
			// 	})
			// }
		});

		// select all
		var isAllSelectCat = categories.filter((x) => x.selected);
		setAllVideosIndex(categories.length == isAllSelectCat.length);
		setVideos(videosArray);
		
	};
	// OnSubCategoryClick
	const onSubCategoryClick = (subCategory, index) => {
		setSubCat(!subCat);
		setAllVideosIndex(false);
		subCategory.selected = !subCategory.selected;
		const arr = [];
		//setCount(count + 1);
		categories.forEach((element) => {
			element.subCategories.map((subCat) =>{
				if (subCat.selected === true) {
					subCat.video.forEach((video) => {
						var isAdded = timeLineList.filter(x => x.media_id == video.media_id);
						arr.push({ 
							...video, 
							category_name: subCat.categories_name,
							selected: true,
							isAdded
						});
					});
				}
			})
		})

		// select sub cat
		var subCategoryisAllSelectCat = categories[index].subCategories.filter((x) => x.selected);
		categories[index].mainCategorySelected = subCategoryisAllSelectCat.length == categories[index].subCategories.length;

		// select all
		var isAllSelectCat = categories.filter((x) => x.mainCategorySelected);
		setAllVideosIndex(categories.length == isAllSelectCat.length);

		setSubCategory(subCategory);
		setVideos(arr);
	};
	// Open Sub Categories Collapse
	const openSubCategories = (item) => {
		if(item.category_id === categoryId){
			setIsOpen(!isOpen);
		}else{
			setIsOpen(true);
		}
		setCategoryId(item.category_id);
		
		setCount(count + 1);
		categories.forEach((element) => {
			if (element.category_id === item.category_id) {
				element.down = !item.down;
			}
		});
	};
	// On Selecting Show All Entertainment Videos
	const changeAllVideos = () => {
		setAllVideosIndex(true);
		setCount(count + 1);
		categories.forEach((element) => {
			element.selected = false;
		});
		const videosArray = [];
		categories.forEach((element) => {
			element.videos.forEach((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				videosArray.push({
					...video,
					category_name: element.category_name,
					category_id: element.categories_id,
					selected: true,
					isAdded,
				});
			});
		});
		setVideos(videosArray);


		// setAllVideosIndex(true);
		// setCount(count + 1);
		// const videosArray = [];
		// specialization.forEach((element) => {
		// 	element.video.forEach((video) => {});
		// 	element.sub_categories.forEach((datum) => {
		// 		datum.video.forEach((subCategoryVideo) => {
		// 			//console.log("subCategoryVideo in WaitingRoomTimelineEntertainment : ", subCategoryVideo)
		// 			var isAdded = timeLineList.filter(x => x.media_id == subCategoryVideo.media_id);
		// 			videosArray.push({
		// 				...subCategoryVideo,
		// 				category_name: datum.categories_name,
		// 				selected: false,
		// 				isAdded,
		// 			});
		// 		});
		// 	});
		// });
		// setVideos(videosArray);
		// subCategory.selected = false;
	};
	const allVideoListActive = allVideosIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
	return (
		<>
			<div className='videolist__main__screen'>
				<div className='videolist__main__lists'>
					<div className='videolist__main__header'>
						<span className='videolist__main__header__header-title d-block mb-3'>Topics</span>
					</div>
					<div className='videolist__submain__header'>
						<img
							src={allVideoListActive}
							alt=''
							onClick={() => changeAllVideos()}
							className='videolist__submain__header__checkbox'
						/>
						&nbsp; &nbsp; &nbsp;
						<span className='videolist__submain__header__title'>
							Show all Entertainment videos {`(${allVideosCount})`}
						</span>
					</div>
					<hr className='videolist__main__lists_hr' />
					{categories.length > 0
					? categories.map((item, index) => {
							const active =
								item.selected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
							return (
								<React.Fragment key={index}>
									<div className='videolist__main__lists__videoselection'>
										<img
											src={active}
											alt=''
											onClick={() => onCategoryClick(item)}
											className='videolist__submain__header__checkbox'
										/>
										&nbsp; &nbsp; &nbsp;
										<span className='videolist__submain__header__title'>
											{item.category_name}
											{`(${item.videosLength})`}
										</span>
									</div>
									{/* <br />  */}
								</React.Fragment>
							);
					  })
					: null}
					{/* {categories.length > 0
						? categories.map((item, mainCatIndex) => {
								const active =
									item.mainCategorySelected === true
										? CLOSEBOXIMAGE
										: WHITEBOXIMAGE;
								const activeItem =
									item.down === true ? (
										<KeyboardArrowUpIcon fontSize='large' />
									) : (
										<KeyboardArrowDownIcon fontSize='large' />
									);
								return (
									<React.Fragment key={mainCatIndex}>
										<div className='videolist__main__lists__videoselection'>
											<img
												src={active}
												alt=''
												onClick={() => onCategoryClick(item)}
												className='videolist__submain__header__checkbox'
											/>
											&nbsp; &nbsp; &nbsp;
											<span
												className='videolist__submain__header__title'
												onClick={() => openSubCategories(item)}>
												{item.category_name}
												{`(${item.mainCategoryVideosLength})`}{' '}
												{/* {`(${item.subCategories.length})`}{' '} */}
												{/* <span>{activeItem}</span>
											</span>
										</div>
										{/* {isOpen && categoryId === item.category_id ? (
											<>
												<Collapse
													isOpen={isOpen}
													style={{ marginLeft: '25px' }}>
													{item.subCategories.map((subCategory, index) => {
														const secondActive =
															subCategory.selected === true
																? CLOSEBOXIMAGE
																: WHITEBOXIMAGE;

														return (
															<React.Fragment key={index + 1}>
																<div className='videolist__main__lists__videoselection'>
																	<img
																		src={secondActive}
																		alt=''
																		onClick={() =>
																			onSubCategoryClick(subCategory, mainCatIndex)
																		}
																		className='videolist__submain__header__checkbox'
																	/>
																	&nbsp; &nbsp; &nbsp;
																	<span className='videolist__submain__header__title'>
																		{subCategory.categories_name}{' '}
																		{`(${subCategory.video.length})`}{' '}
																	</span>
																</div>
															</React.Fragment>
														);
													})}
												</Collapse>
											</>
										) : null} */}
									{/* </React.Fragment> */}
								{/* ); */}
						  {/* })
						: null}  */}
				</div>
				<div className='d-flex videolist__main__button'>
					<LoadingButton type="button"  label={"Publish timeline"} className="mr-3" loading={state.buttonLoading3} disabled={state.buttonLoading3} size="md" onClick={() => onPublish()}  color={'primary'}/>
					<Button type="button" className='ml-3' size="md" onClick={()=>setModal(true)} color='white' >
						Preview
					</Button>
				</div>
			</div>
		</>
	);
};

export default WaitingRoomTimelineEntertainmentCategories;
