import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import { Ownership  , operatories , days , tv_count} from '../../../data/comman';
import HttpUtility, {HELPERURI} from '../../../utilities/HttpUtility';
import Loading from '../../../components/Loading'
import {  AddNewLoactionRequest, GetpracticeProfile , GetSubscribePriceOption, showError } from "../../../store/actions/taskAction";

import Stape1 from './PracticeInfo'
import Stape2 from './Subscribe'
import Stape3 from './Review'

import './style.scss';


class Index extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      step: 1,
      stepOneNext: false,
      stepTwoNext: false,
      selectedhardware:{
        value: 0, label: '0' 
      },
      selecteddomain:{
        count: 0, value: 0
      },
      selecteddevices:{
        count: 0, value: 0
      },
      selected_with_warranty:false,
      promo_code:'',
      hardwares:[],
      selectedPlan:{},
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.gotoBasicInfo = this.gotoBasicInfo.bind(this);
    this.gotoAdditionalInfo = this.gotoAdditionalInfo.bind(this);
    this.gotoPracticeHours = this.gotoPracticeHours.bind(this)
    this.updateHardwareQty = this.updateHardwareQty.bind(this)
    this.updateDomainQty = this.updateDomainQty.bind(this)
    this.updateDevicesQty = this.updateDevicesQty.bind(this)
    this.updateSelectedWithWarranty = this.updateSelectedWithWarranty.bind(this)
    this.updatePromoCode = this.updatePromoCode.bind(this)
    this.updatehardware = this.updatehardware.bind(this)
    this.updateSelectedPlan = this.updateSelectedPlan.bind(this)
  }

  //next step
    nextStep(){
      const { step } = this.state;
      this.setState({
          step: step + 1,
      })
  }

  // prev step
  prevStep(){
      const { step } = this.state;
      if(step == 3 || step == 2){
        this.setState({stepTwoNext:true})
      }
      this.setState({
          step: step - 1,
      })
      this.props.dispatch(showError({ errors: [] }));

  }

  gotoBasicInfo(){
    this.setState({
      step: 1,
    })
  }

  gotoAdditionalInfo(){
    this.setState({
      step: 2,
    })
  }

  gotoPracticeHours(){
    this.setState({
      step: 3,
    })
  }

  updateHardwareQty(val){
    this.setState({selectedhardware:val})
  }

  updateSelectedPlan(val){
    this.setState({selectedPlan:val})
  }

  updateDomainQty(val){
    this.setState({selecteddomain:val})
  }

  updateDevicesQty(val){
    this.setState({selecteddevices:val})
  }

  updateSelectedWithWarranty(val){
    this.setState({selected_with_warranty:val})
  }

  updatePromoCode(val){
    this.setState({promo_code:val})
  }

  updatehardware(val){
    this.setState({hardwares:val})
  }
  async componentDidMount(){
    this.props.dispatch(AddNewLoactionRequest());
  }



  renderStep(step , index){

    switch(step){
      
        case 1:
            return  (<Stape1 
                      nextStep= { this.nextStep }
                      isNext={this.state.stepOneNext}
                      handleChange = {this.handleChange}
                      handlePhoneChange={this.handlePhoneChange}
                      isNewLocation={this.props.isNewLocation}
                      // isUpgradePlan={this.props.isUpgradePlan}
                      isNexttwo={this.state.stepTwoNext}
                    />)
                    
        case 2:
            return  <Stape2 
                      nextStep= { this.nextStep }
                      prevStep = {this.prevStep }
                      updateHardwareQty = {this.updateHardwareQty}
                      selectedhardware={this.state.selectedhardware}
                      updateDomainQty={this.updateDomainQty}
                      selecteddomain={this.state.selecteddomain}
                      updateDevicesQty={this.updateDevicesQty}
                      selecteddevices={this.state.selecteddevices}
                      updateSelectedWithWarranty={this.updateSelectedWithWarranty}
                      selected_with_warranty={this.state.selected_with_warranty}
                      updatehardware={this.updatehardware}
                      hardwares={this.state.hardwares}
                      updatePromoCode={this.updatePromoCode}
                      promo_code={this.state.promo_code}
                      isNexttwo={this.state.stepTwoNext}
                      handleChange = {this.handleChange}
                      isNewLocation={this.props.isNewLocation}
                      updateSelectedPlan={this.updateSelectedPlan}
                      selectedPlan={this.state.selectedPlan}
                      // isUpgradePlan={this.props.isUpgradePlan}
                    />
        case 3:
            return <Stape3 
                      nextStep= { this.nextStep }
                      selectedhardware={this.state.selectedhardware}
                      prevStep = {this.prevStep }
                      isNext={this.state.stepThreeNext}
                      isNewLocation={this.props.isNewLocation}
                      selectedPlan={this.state.selectedPlan}
                      // isUpgradePlan={this.props.isUpgradePlan}
                    />
        default :
          return <Stape1/>
    }
  }

  render (){
    const { step } = this.state;
    var ext_text = ""
    if((this.props.practiceInfo?.is_paid_account == 1)){
      ext_text = "Change "
    }
    return(
      <>
        <div style={{marginTop:'-46px'}} className="d-flex flex-column text-center initial_setup__inner__text text-center">
          
          <p className='mb-0'>{step == 1 ? 'Practice Information' : ''}{step == 2 ? ext_text+ 'Subscription' : ''}{step == 3 ? 'Review' : ''}</p>
        </div>
        <div className="custom__multi__step__form ">
            <ul className="custom__multi__step__form--progressbar">
              <li className={`custom__multi__step__form--step ${step == 1 ? 'active' : ''}`}>
                <span></span>
              </li>
              <li className={`custom__multi__step__form--step ${step == 2 ? 'active' : ''}`}>
                <span></span>
              </li>
              <li className={`custom__multi__step__form--step ${step == 3 ? 'active' : ''}`}>
                <span></span>
              </li>
            </ul>
        </div>
        {  this.renderStep(step)  }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  getSubscribePriceOption:state.task.getSubscribePriceOption,
  practiceInfo:state.task.practiceInfo,
  isUpgradePlan:state.task.isUpgradePlan
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);