import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment';
import { Button } from 'reactstrap';
import { CLOSE , ACTIVEFORMAT} from '../../utilities/HttpUtility';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { GetTos , AcceptTos  } from "../../store/actions/taskAction";
import Loading from '../../components/Loading'
import { history } from '../../pages/index';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { NavLink} from 'reactstrap';


class TosIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAgree:false,
      complete:false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleEmailCopy = this.handleEmailCopy.bind(this);
    this.isAgree = this.isAgree.bind(this);
  }
  
  handleClick(){
    if(this.props.practiceInfo.can_tos_complain === 0){
      this.props.history.push({
        pathname: '/tosdeclines',
      })
    }else{
      this.props.history.push({
        pathname: CLOSE,
      })
    }
  } 
  
  handleEmailCopy(){
    this.setState({
      complete: !this.state.complete
    });
  }

  handleAgreeClick(){

    if(this.state.complete){
      this.props.dispatch(AcceptTos(1));
    }else{
      this.props.dispatch(AcceptTos(0))
    }
    
    if(this.props.practiceInfo.is_paid_account === 0 && this.props.practiceInfo.status != 800 && this.props.practiceInfo.status != 900){

        history.push('/trial');

    }else if(this.props.practiceInfo.need_profile_setup === 1){

        history.push('/initialsetup');

    }else if(this.props.practiceInfo.need_owner_setup === 1){

        this.props.history.push('/edit-authorized-profile');
        
    }else{
        history.push('/profileselection');
        // this.props.history.push('/profilesetup');
    }
  }

  async componentDidMount(){
    this.props.dispatch(GetTos());
  }

  isAgree(){
    console.log('asdasdasd')
    if (!this.state.isAgree) {
      console.log('asdasdasd')
      this.setState({isAgree:true})
    }
  }

  
  render (){
    const {t , letestTos , error , loading } = this.props

    return(
      <div className="tos__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
        <div className="tos__inner__screen d-flex flex-column align-items-stretch">
          {loading ?
           <Loading />
           :
          <div className="d-flex flex-column h-100">
              <div className="mb-5">
                <h1>{t('tos.title', {framework:'React'})}</h1>
                <strong>{t('tos.updatedOn', {framework:'React'})} { moment(letestTos.updated_at).format(ACTIVEFORMAT)}</strong>
              </div>
              <div className="tos__inner__text">
                  <PerfectScrollbar  onYReachEnd={(e) => this.isAgree()}>
                    <div className="pr-5">
                      {<div dangerouslySetInnerHTML={{__html: letestTos.content}} />}
                    </div>
                  </PerfectScrollbar>
              </div>
              <div className="d-flex pt-5">
                <Button className="mr-20" size="md" disabled={!this.state.isAgree} onClick={() => this.handleAgreeClick()} color="default">{t('tos.btnAgree', {framework:'React'})}</Button>
                {this.props.practiceInfo.can_tos_complain === 0 ?
                 <Button className="mr-20" size="md" onClick={() => this.handleClick()}  outline color="default">{t('tos.btnClose', {framework:'React'})}</Button>
                :
                  <NavLink  className="mr-20 btn btn-outline-default btn-md" href={CLOSE}>{t('tos.btnClose', {framework:'React'})}</NavLink>
                }
                <div className="custom-control custom-redio d-flex align-items-center ml-3">
                  <input type="checkbox" onChange={this.handleEmailCopy} checked={this.state.complete} className="custom-control-input" id="emailcopy" />
                  <label className="custom-control-label" htmlFor="emailcopy">{t('tos.emailMe', {framework:'React'})}</label>
                </div>
              </div>
            </div>
            }
        </div>
        
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  letestTos: state.task.letestTos,
  loading: state.task.loading,
  error: state.task.error,
  
  practiceInfo: state.task.practiceInfo,
});


// export default withTranslation('common')(TosIndex);
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(TosIndex);