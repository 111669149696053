import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { UpdateAccountSetting , GetPracticeMoreInfo , cleardata , AccountSettingRequest , UpdateSettingRequest, GetAuthorizedUser, fetchChairsideMedia} from "../../store/actions/taskAction";
import LoadingButton from '../../components/LoadingButton'
import { clearRecentPlaylists} from '../../helper/LocalStorage';

import './style.scss';
import { Button , FormGroup , Label , Input ,Spinner} from 'reactstrap';


import i18n from "i18next";
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
     
    this.state = {
      practiceUserLoginInfo:props.practiceUserLoginInfo,
      isSetlanguage:false,
      isCheckMediaLang:'',
      isCheckSystemLang:'',
      isChecked:false,
      setLangaugeName:''
    };
    this.onSetLanguage = this.onSetLanguage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMedia = this.handleChangeMedia.bind(this);
  }

  onSetLanguage(){
    this.setState({isSetlanguage:true})
  }

  async onUpdateAccountSetting(field , value){
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo
    practiceUserAccountInfo.account_setting[field] = value
    await this.props.dispatch(AccountSettingRequest(practiceUserAccountInfo))
  }

  async onEditLanguage(field , value){
    let practiceUserAccountSetting = this.props.practiceUserAccountSetting
    practiceUserAccountSetting[field] = value
    await this.props.dispatch(UpdateSettingRequest(practiceUserAccountSetting))
  }


  onCancel(){
    // var isCheckSystemLang =  this.props.practiceUserAccountInfo.account_setting.system_language
    // var isCheckMediaLang =  this.props.practiceUserAccountInfo.account_setting.media_language
    
    this.setState({isSetlanguage:false,  isChecked:false})
    this.props.dispatch(cleardata());

     this.onEditLanguage('isSystemLangCompleted' , false)
     this.onEditLanguage('isCompleted' , false)
  }

 async onUpdateSystemLanguage(){
   await this.onEditLanguage('isSystemLoading' , true)
    let params ={
      system_language:this.props.practiceUserAccountInfo.account_setting.system_language,
    }
   await this.props.dispatch(UpdateAccountSetting(params))

   var lng =  this.props.media_language && this.props.media_language.find(x=>x.id === this.props.practiceUserAccountInfo.account_setting.system_language)
  //  lng = lng.name.slice(0, 2).toLowerCase()
  //  i18n.changeLanguage(lng)
  }

  
  async  onUpdateBothLanguage(){
    await this.onEditLanguage('isLoading' , true)
    let params ={
      system_language:this.props.practiceUserAccountInfo.account_setting.system_language,
      media_language:this.props.practiceUserAccountInfo.account_setting.media_language,
      caption_language: this.props.practiceUserAccountInfo.account_setting.caption_language || this.props.practiceUserAccountInfo.account_setting.system_language
    }


   await this.props.dispatch(UpdateAccountSetting(params))
   await this.props.dispatch(fetchChairsideMedia(this.props.practiceUserLoginInfo))

    setTimeout(() => { 
      this.onEditLanguage('isCompleted' , false) 
    }, 2000)
  }

  async componentDidMount(){
   await this.props.dispatch(GetPracticeMoreInfo());
   await this.props.dispatch(GetAuthorizedUser(this.props.practiceUserAccountInfo.id))
   await this.props.dispatch(cleardata());
   await this.onEditLanguage('isLoading' , false)
    // var isCheckSystemLang =  this.props.practiceUserAccountInfo.account_setting.system_language
    // var isCheckMediaLang =  this.props.practiceUserAccountInfo.account_setting.media_language
    // this.setState({isCheckSystemLang , isCheckMediaLang})

  }


  handleChange(id , name) {
    this.onUpdateAccountSetting('system_language' , id)
    this.setState({  isChecked:true , setLangaugeName:name});
  };

  handleChangeMedia(id, isSubtitle) {
    if(isSubtitle){
      this.onUpdateAccountSetting('caption_language' , id)
    }else{
      this.onUpdateAccountSetting('media_language' , id)
      this.setState({ isChecked:true});
    }
  };

  render (){
    const { t,media_language ,system_language, loadingMoreinfo , practiceUserAccountSetting} = this.props
    const subtitlelanguage = media_language
    const filteredItems = [...subtitlelanguage]
    filteredItems.splice(1, 2); 

    const { isSetlanguage , isChecked , setLangaugeName} = this.state
      return(
        <div className="language__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
            <SubHeader className="mb-auto" title={t('languagepage.title')}/>
            {!isSetlanguage ?
            <div className="language__list d-flex flex-column transparent__form--white mb-auto">
              <div className="d-flex flex-grow-1 row">
                {loadingMoreinfo ?
                  <div className="w-100 d-flex  align-items-center justify-content-center">
                    <Spinner color="primary" />
                  </div>
                :
                <>
                  <div className="col-4">
                    <div className="language__inner__header">
                      <h4>System language</h4>
                      <p>Thalamus interface language, used by the system to communicate with its users.</p>
                    </div>
                    <div className="language__inner__body mt-2">
                      <ul className="custom_radio_select">
                        {system_language && system_language.map((language, index) => {
                          return <li key={index}>
                                  <FormGroup check className="p-0">
                                      <Input type="radio" checked={this.props.practiceUserAccountInfo.account_setting && this.props.practiceUserAccountInfo.account_setting.system_language === language.id} onChange={(e) => this.handleChange(language.id , language.name)}  name="system_language" id={`system_language_en_${index}`} />{' '}
                                      <Label for={`system_language_en_${index}`}>{language.name}</Label>
                                  </FormGroup>
                                </li>
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-4" style={{transform:" translateX(10px)"}}>
                  <div className="language__inner__header">
                    <h4>Content Language</h4>
                    <p>Audio language used in videos and other stock content present in Thalamus.</p>
                  </div>
                  <div className="language__inner__body  mt-2">
                    <ul className="custom_radio_select">
                      {media_language && media_language.map((language, index) => {
                        return <li key={index}>
                                <FormGroup check className="p-0">
                                    <Input type="radio"  checked={this.props.practiceUserAccountInfo.account_setting && this.props.practiceUserAccountInfo.account_setting.media_language === language.id} onChange={(e) => this.handleChangeMedia(language.id)}  name="media_language" id={`media_language_${index}`} />{' '}
                                    <Label for={`media_language_${index}`}>{language.name}</Label>
                                </FormGroup>
                              </li>
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-4" style={{transform:" translateX(10px)"}}>
                  <div className="language__inner__header">
                    <h4>Subtitle Language</h4>
                    <p>Subtitle language used in videos and other stock content present in Thalamus.</p>
                  </div>
                  <div className="language__inner__body  mt-2">
                    <ul className="custom_radio_select">
                      {filteredItems && filteredItems.map((language, index) => {
                        return <li key={index}>
                                <FormGroup check className="p-0">
                                    <Input type="radio"  checked={this.props.practiceUserAccountInfo.account_setting && (this.props.practiceUserAccountInfo.account_setting.caption_language ||  this.props.practiceUserAccountInfo.account_setting.media_language) === language.id} onChange={(e) => this.handleChangeMedia(language.id, true)}  name="subtitle_language" id={`subtitle_language_${index}`} />{' '}
                                    <Label for={`subtitle_language_${index}`}>{language.name === 'English (US)' ? 'English' : language.name}</Label>
                                </FormGroup>
                              </li>
                      })}
                    </ul>
                  </div>
                </div>
                </>
                }
              </div>
              <div className="d-flex">
                {/* <Button size="md" color="primary" disabled={!isChecked} onClick={() => this.onSetLanguage()}  >Set language(s)</Button> */}
                <LoadingButton type="button" size="md" label={`${practiceUserAccountSetting.isCompleted ? 'Changes saved' : 'Set language(s)'}`} loading={practiceUserAccountSetting.isLoading} disabled={practiceUserAccountSetting.isLoading} className={`${practiceUserAccountSetting.isCompleted ? 'verifyed' : ''}  system-language`} onClick={() => this.onUpdateBothLanguage()} color="primary" />
              </div>
            </div>
            :
            <div className="language__system  transparent__form--white d-flex flex-column mb-auto">
                <div className="language__inner__header">
                  <h4>{t('languagepage.subtitle')}</h4>
                  <p>{t('languagepage.detail.part1')} {setLangaugeName} {t('languagepage.detail.part2')}</p>
                </div>
                <div className="language__inner__body mt-auto">
                  <p>Set “{setLangaugeName}” as</p>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <LoadingButton type="button" size="" label={`${practiceUserAccountSetting.isCompleted ? 'Changes saved' : 'System and media language'}`} loading={practiceUserAccountSetting.isLoading} disabled={practiceUserAccountSetting.isLoading} className={`${practiceUserAccountSetting.isCompleted ? 'verifyed' : ''} w-100 system-language`} onClick={() => this.onUpdateBothLanguage()} color="primary" />
                    </div>
                    <div className="col-6">
                      <LoadingButton type="button" size="md" label={`${practiceUserAccountSetting.isSystemLangCompleted ? 'Changes saved' : 'System language'}`}  loading={practiceUserAccountSetting.isSystemLoading} disabled={practiceUserAccountSetting.isSystemLoading}   className={`${practiceUserAccountSetting.isSystemLangCompleted ? 'verifyed' : ''} px-2`} onClick={() => this.onUpdateSystemLanguage()} color="primary" />
                    </div>
                    <div className="col-6"><Button size="md" onClick={() => this.onCancel()} className="px-2" color="danger">{t('btn.cancel')}</Button></div>
                  </div>
                </div>
            </div>
            }
        </div>
      ) 
  }
}
const mapStateToProps = (state) => ({
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  practiceUserAccountSetting:state.task.practiceUserAccountSetting,
  media_language:state.task.media_language,
  system_language:state.task.system_language,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo,
  loadingMoreinfo:state.task.loadingMoreinfo,
  loading:state.task.loading,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);