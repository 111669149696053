import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from '../../components/Header/subHeader';
import './style.scss';
import { Row, Col } from 'reactstrap';
import VideoListThree from '../../components/showAllvideos/VideoListThree';
import { fetchWebsiteMedia } from '../../store/actions/taskAction';
import VideoSelectedThree from '../../components/videoSelected/VideoSelectedThree';
import Loading from '../../components/Loading'
const WebsiteVideoLibrary = () => {
    const state = useSelector((state) => state.task);
    const dispatch = useDispatch();
    const [videoSelectItem, setVideoSelectItem] = useState(state.websiteMediaState);
    const [count, setCount] = useState(0);
    const [totalVideos, setTotalVideos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(true);

    useEffect(() => {
        dispatch(fetchWebsiteMedia());
        // dispatch(PracticeLogin(state.practiceInfo));
    }, [])
    

    useEffect(() => {
        setVideoSelectItem(state.websiteMediaState)
    }, [state.websiteMediaState])

    //SUBMIT VIDEO LISTS
    const submitVideoLists = (items) => {
        setCount(count + 1);
        setVideoSelectItem(items);
    };

    // Set Total Videos 
    const settotalVideos = (totalVideos) => {
        setCount(count + 1);
        //console.log("totalVideos in settotalVideos : ", totalVideos)
        setTotalVideos(totalVideos)
    }
    
    // Set Selected Total Videos 
    const setselectedVideos = (selectedVideos) => {
        setCount(count + 1);
        //console.log("selectedVideos in setselectedVideos : ", selectedVideos)
        setSelectedVideos(selectedVideos)
    }

    return (
        <div className='website_video_library__main__screen  d-flex flex-column h-100 align-items-center'>
            <SubHeader className='' title='Website Video Library' />
            {/* {state.loading && <Loading /> } */}
            <div className='website_video_library__main__inner__screen mt-5 d-flex mb-5'>
                    <div className="website_videos_leftside">
                        <VideoListThree
                            list={videoSelectItem}
                            submitVideoLists={submitVideoLists}
                            settotalVideos={settotalVideos}
                            totalvideos={totalVideos}
                            selectedvideos={selectedVideos}
                            setselectedVideos={setselectedVideos}
                            setButtonDisable={setButtonDisable}
                            buttonDisable={buttonDisable}
                        />
                    </div>
                    <div className="website_videos_rightside w-100">
                        <VideoSelectedThree
                            selectedvideos={selectedVideos}
                            totalvideos={totalVideos}
                            settotalVideos={settotalVideos}
                            setselectedVideos={setselectedVideos}
                            setButtonDisable={setButtonDisable}
                        />
                    </div>
                
            </div>
        </div>
    )
}

export default WebsiteVideoLibrary