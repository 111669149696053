/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from '../../components/Header/subHeader';
import './styles.scss';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Spinner } from 'reactstrap';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
	fetchWaitingRoomChannelTrivia,
	newWaitingRoomTimelineEducation,
	newWaitingRoomTimelineEntertainment,
	newWaitingRoomTimelineMyVideos,
	waitingRoomChannelSlide,
	getSocailMedia,
	getTimeline,
	postTimeline,
	postwaitingRoomPublishTimeline,
} from '../../store/actions/taskAction';
import WaitingRoomTimelineSlides from '../WaitingRoomTimelineSlides/WaitingRoomTimelineSlides';
import WaitingRoomTimelineTrivia from '../../components/WaitingRoomTimelineTrivia/WaitingRoomTimelineTrivia';
import WaitingRoomTimelineEducation from '../../components/WaitingRoomTimelineEducation/WaitingRoomTimelineEducation';
import WaitingRoomTimelineEntertainment from '../../components/WaitingRoomTimelineEntertainment/WaitingRoomTimelineEntertainment';
import WaitingRoomTimelineMyVideos from '../../components/WaitingRoomTimelineMyVideos/WaitingRoomTimelineMyVideos';
import WaitingRoomSocialMedia from '../WaitingRoomSocialMedia';
import TimeLineDropBox from '../../components/TimeLineDropBox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uuid from "react-uuid";
import { TIMELINEMEDIATYPE, TIMELINESOCIALMEDIATYPE, TIMELINESLIDETYPE, TIMELINETRIVIATYPE } from '../../data/comman';
import PreviewModal  from '../../components/PreviewModal'
import {WAITINGROOM_CHANNEL_PREVIEW_URL, SOCKETURL} from '../../utilities/HttpUtility'
import Loading from '../../components/Loading'
import { getToken } from '../../helper/LocalStorage';
var accessToken = ''

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		//   backgroundColor: theme.palette.background.paper,
		width: 'auto',
	},
}));
var socket
const WaitingRoomTimeline = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);

	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	const [timeLineList, setTimeLineList] = useState([]);
	const [isHideBar, setIsHideBar] = useState(false);
	const [modal, setModal] = useState(false);

	// const script = document.createElement("script");
	// script.src = "https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.0.0/socket.io.js";//standard-all/ckeditor.js
	// // script.async = true;
	// //script.onload = () => scriptLoaded();
	// document.body.appendChild(script);
	// const scriptLoaded=()=>{
	// 	 socket = window.io.connect(`${SOCKETURL}`, { transports: ['websocket', 'polling', 'flashsocket'] });
	// 	// let socket = io();
	// 	// console.log(socket,'socketsocket');
	// }
	// useEffect(() => {
	// 	console.log(state.waitingRoomPublishTimeline,'state.waitingRoomPublishTimeline')
	// 	if(state.waitingRoomPublishTimeline != null){
	// 		// console.log(window,'window')
	// 		socket = window.io.connect(`${SOCKETURL}`, { transports: ['websocket', 'polling', 'flashsocket'] });
	// 		console.log(socket,'socketsocket');
	// 		socket.emit('publish_confirmation', {
	// 			channel_id: state.managewaitingRoomMediaChannel.id
	// 		})
	// }
	
	// },[state.waitingRoomPublishTimeline])

	const onPublish = () =>{
		dispatch(postwaitingRoomPublishTimeline(state.managewaitingRoomMediaChannel.id))
	}

	const hidebar = (value) => {
		setIsHideBar(value)
	}

	useEffect(() => {
		dispatch(newWaitingRoomTimelineEducation());
		dispatch(fetchWaitingRoomChannelTrivia());
		dispatch(newWaitingRoomTimelineEntertainment());
		dispatch(newWaitingRoomTimelineMyVideos());
		dispatch(waitingRoomChannelSlide())
		// dispatch(wistaLogin(state.practiceUserLoginInfo))
		dispatch(getSocailMedia());
		dispatch(getTimeline(state.managewaitingRoomMediaChannel.id));
		setIsHideBar(false)
		accessToken = getToken();
	}, []);

	useEffect(() => {
		setIsHideBar(false)
	}, [value]);

	useEffect(() => {
		var timeline = [];
		state.channelTimeLine.map((tl)=>{
			if(tl.media_data)
				timeline.push({
					media_id: tl.media_type_id == TIMELINESOCIALMEDIATYPE ? tl.media_data.provider_id : ( tl.media_type_id == TIMELINESLIDETYPE || tl.media_type_id == TIMELINETRIVIATYPE ?  tl.media_data.id : tl.media_data.waiting_room_media_id),
					uId: uuid(),
					thumb:  tl.media_type_id == TIMELINESLIDETYPE ? tl.media_data.image: (tl.media_type_id == TIMELINEMEDIATYPE ? (tl.media_data.waiting_room_media ? tl.media_data.waiting_room_media.thumb : '' ) : tl.media_data.thumb),
					duration: tl.media_type_id == TIMELINEMEDIATYPE ? tl.media_data.media.duration : tl.media_data.duration,
					title: tl.media_data.title,
					media_type_id: tl.media_type_id
				})
		})
		setTimeLineList([...timeline]);
	}, [state.channelTimeLine])


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};


	const onDragEnd = (result) => {
		const draggleableItem = document.getElementsByClassName("aftertimeline")
		draggleableItem[result.source.index].classList.remove("overlayClass")
		const { source, destination } = result;
		console.log(timeLineList,'timeLineList')
		// dropped outside the list
		if (!result.destination || destination.droppableId === "delete") {
			timeLineList.splice(result.source.index, 1);
			setTimeLineList([...timeLineList]);
			// return;
			postTimeLineToServer(timeLineList);
		}else if(result.source && result.source.droppableId == "droppable_education"){
			var video_info = result.draggableId.split('_');
			var videoCatIndex = state.educationTimeline.findIndex(x => x.categories_id == video_info[0])
			var videoIndex =  state.educationTimeline[videoCatIndex].video.findIndex(x => x.media_id ==  video_info[1])
			var items = state.educationTimeline[result.source.index]
			var video = state.educationTimeline[videoCatIndex].video[videoIndex]
			var commonTimeLine = {
                thumb: video.thumb,
                title: video.title,
                duration: video.duration || 0,
                media_id: +video_info[1],
                media_type_id: TIMELINEMEDIATYPE,
                // sort_order:
                uId: uuid()
			}

			var timeLineListTemp = timeLineList;
			timeLineListTemp.splice(result.destination.index, 0, commonTimeLine);
			timeLineListTemp[result.destination.index] = commonTimeLine
			setTimeLineList([...timeLineListTemp]);
			postTimeLineToServer(timeLineListTemp);

		}else if(result.source && result.source.droppableId == "droppable"){
			const items = reorder(
				timeLineList,
				result.source.index,
				result.destination.index
			);
			setTimeLineList(items);
			postTimeLineToServer(items);
		}
	  }

	  const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
	};

	const postTimeLineToServer = (mainTimelineList) => {
        var param = {};
        var timelineList = []
        mainTimelineList.map((timeline, index) => {
            timelineList.push({
                media_id: timeline.media_id,
                media_type_id: timeline.media_type_id,
                sort_order: index
            });

        });
        param["timeline"] = timelineList;
        dispatch(postTimeline(state.managewaitingRoomMediaChannel.id, param))
    }

	const onDragStart = (result) => {
		const draggleableItem = document.getElementsByClassName("aftertimeline")
		draggleableItem[result.source.index].classList.add("overlayClass")
	}

	return (
		<div className='main__screen channels__page waitingroom_my_slides  d-flex flex-column h-100 align-items-center'>
			{state.loading ? ( <Loading /> ) : (
				<>
					<SubHeader className='' title='Waiting Room Timeline' selectedTab={isHideBar} changeValue={setIsHideBar}/>
					<div className="d-flex flex-column  w-100 flex-grow-1">
						<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} >
							<Droppable droppableId="delete" className={`${classes.root} footer-header `} direction="horizontal">
							{(provided, snapshot) => (<>
								 <div
									ref={provided.innerRef}
									{...provided.droppableProps}
									>

										<div>
											<Tabs
												value={value}
												onChange={handleChange}
												indicatorColor='transparent'
												textColor='primary'
												className='footer-tabs'
												aria-label='full width tabs example'>
												<Tab label='EDUCATION' {...a11yProps(0)} />
												<Tab label='ENTERTAINMENT' {...a11yProps(1)} />
												<Tab label='MY VIDEOS' {...a11yProps(2)} style={{display: state.practiceInfo.is_internal_user == 1 ? 'none' : ''}}/> 
												<Tab label={state.practiceInfo.is_internal_user == 1 ? 'SLIDES' : 'MY SLIDES'} {...a11yProps(3)} />
												<Tab label='SOCIAL MEDIA' {...a11yProps(4)} style={{display: state.practiceInfo.is_internal_user == 1 ? 'none' : ''}} />
												<Tab label='TRIVIA' {...a11yProps(5)} />
											</Tabs>
										</div>

										<TabPanel value={value} index={0} dir={theme.direction}>
												<WaitingRoomTimelineEducation
													 educationTimeline={state.educationTimeline} timeLineList={timeLineList} setModal={setModal} onPublish={onPublish}
												/>
										</TabPanel>
										<TabPanel value={value} index={1} dir={theme.direction}>
											<WaitingRoomTimelineEntertainment
												 entertainmentTimeline={state.entertainmentTimeline} timeLineList={timeLineList} setModal={setModal} onPublish={onPublish}
											/>
										</TabPanel>
										<TabPanel value={value} index={2} dir={theme.direction}>
											<WaitingRoomTimelineMyVideos  myVideos={state.myVideosTimeline} timeLineList={timeLineList} onPublish={onPublish} />
										</TabPanel>
										<TabPanel value={value} index={3} dir={theme.direction} >
											<WaitingRoomTimelineSlides waitingRoomChannelSlides={state.waitingRoomChannelSlides} isHideBar={hidebar} isHideBarTrue={isHideBar} timeLineList={timeLineList} onPublish={onPublish}/>
										</TabPanel>
										<TabPanel value={value} index={4} dir={theme.direction} >
											<WaitingRoomSocialMedia  timeLineList={timeLineList}  onPublish={onPublish}/>
										</TabPanel>
										<TabPanel value={value} index={5} dir={theme.direction} >
											<WaitingRoomTimelineTrivia
												 waitingRoomsChannelTrivia={state.waitingRoomsChannelTrivia} timeLineList={timeLineList} setModal={setModal} onPublish={onPublish}
											/>
										</TabPanel>
										{provided.placeholder}
									 </div>
								</>)}
							</Droppable>
							{console.log(isHideBar,'isHideBar')}
							{!isHideBar &&
								<div className='mt-auto'>
									<Droppable droppableId="droppable" direction="horizontal">
										{(provided, snapshot) => (
												<div className="dropbox__main__screen">
													<TimeLineDropBox timeLineList={timeLineList} setTimeLineList={setTimeLineList} provided={provided} snapshot={snapshot} channelId={state.managewaitingRoomMediaChannel.id}/>
													{/* {provided.placeholder} */}
												</div>
										)}
									</Droppable>
								</div>
							}
						</DragDropContext>

					</div>
				</>
			)}

			<PreviewModal url={`${WAITINGROOM_CHANNEL_PREVIEW_URL}${state.managewaitingRoomMediaChannel.id}&preview=1&accessToken=${accessToken}`} isOpen={modal} toggle={()=>setModal(!modal)}/>

		</div>
	);
};

export default WaitingRoomTimeline;
