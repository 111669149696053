import React from 'react'
import { useSelector } from 'react-redux';
import styles from './style.module.scss';


const getUserType = (id) =>{
  switch(id){
      case 0: 
          return  ["guest", "Guest"];
      case 1:
          return  ["dentist", "Normal User"];
      case 2:
          return  ['authorized_user', "Authorized User"]; 
      case 3:
          return    ['manager', "Manager"]; 
      case 4:
          return    ['owner', "Owner"]; 
      default:
        return    ['guest', "Guest"];
  }
}

const ProfileImgBox = () => {
  const state = useSelector(state => state.task);
  const userType = getUserType(state.practiceUserLoginInfo.type )

  return (
    <div className={`${styles.goolge_tv_profile__img_box} d-flex flex-column h-100`}>
        { state.practiceUserLoginInfo && state.practiceUserLoginInfo.profile_pic &&
          <img className="mx-auto my-auto" alt={state.practiceUserLoginInfo.first_name} src={ state.practiceUserLoginInfo.profile_pic}/>
        }
        <div className={`${styles.goolge_tv_profile__img_box__footer} ${userType[0] === 'owner' || userType[0] === 'manager' || userType[0] === 'authorized_user'? styles.goolge_tv_profile__img_box__footer__admin : null }  d-flex align-items-center justify-content-center`} >
          {userType[1]}
        </div>
    </div>
  )
}

export default ProfileImgBox
