import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';


import Primary from './Primary'
import Secondary from './Secondary'


class NewProfileForm extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      step: 1,
    };
    this.stepOne = this.stepOne.bind(this);
    this.stepTwo = this.stepTwo.bind(this);
  }

  stepOne(){
    this.setState({
      step:1,
    })
  }
  
  stepTwo(){
    this.setState({
      step:2,
    })
  }

  
  renderStep(step , index){
    switch(step){
        case 1:
            return  <Primary />
        case 2:
            return  <Secondary  />
        default :
          return  null
    }
  }

  render (){
    const { step } = this.state;
    return(
      <>
        <div className="tabs">
            <ul className="d-flex justify-content-between">
              <li onClick={ this.stepOne } className={`col text-center ${step === 1 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Primary</label>
              </li>
              <li onClick={ this.stepTwo } className={`col text-center ${step === 2 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Secondary</label>
              </li>
            </ul>
        </div>
        <div className="mb-auto pb-5">
          { this.renderStep(step) }
        </div>
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(NewProfileForm);
