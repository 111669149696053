import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import NumberFormat from 'react-number-format';
import { InputField } from '../../../components/InputField';
import moment from 'moment';
import { Button , FormGroup , Label} from 'reactstrap';
import {GetUserVerification , VerifyOtp , ProfileVerify, VerifyOtpRequest ,cleardata ,clearerror, UpdateUserProfileAboutInfo , GetUserVerificationRequest } from "../../../store/actions/taskAction";
import LoadingButton from '../../../components/LoadingButton'
import DatePicker from "react-datepicker";
import { DateFormat } from '../../../data/comman'
import PhoneInput from 'react-phone-input-2'
import ReactCodeInput from 'react-code-input';
import { history } from '../../../pages/index';
import PhoneVerify from '../../../components/phoneVerify';

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formValid:false,
      isNext:false,
      isGetVerifyCode:false,
      minutes: 5,
      seconds: 60,
      time:0,
      email:'',
      isVerify:false,
      isEnableVerify:false,
      isGetCode:false,
      loadingbtn:false,
      practiceUserAccountInfo:{
        question_1:'',
        question_2:'',
        question_3:'',
        answer_1:'',
        answer_2:'',
        answer_3:'',
        selecteddob:'',
        type:'email',
        phone:'',
      },
      
      isError:{
        question_1:'',
        question_2:'',
        question_3:'',
        answer_1:'',
        answer_2:'',
        answer_3:'',
        email:'',
        selecteddob:'',
        phone:'',
      }
    };
    this.handleNext = this.handleNext.bind(this);
    this.onclickBack = this.onclickBack.bind(this);
    
  }

  async componentDidMount(){
    await this.onChangeValue('isVerify' , false)
    await this.props.dispatch(cleardata());
    await this.props.dispatch(clearerror());
    this.setState({ isGetVerifyCode: false });
  }
  
  handlePhoneChange(value, country, e, formattedValue ){
    var fieldValue = "";
  
    fieldValue = value;

    fieldValue = fieldValue.replace(country.dialCode,'');    // 345
    fieldValue = '+'+country.dialCode+'-'+ fieldValue;
    let isError = { ...this.state.isError };

    this.setState({
      isError,
      isGetVerifyCode:true,
      
      practiceUserAccountInfo: {
        ...this.state.practiceUserAccountInfo,
        phone: fieldValue,
      }
    },()=> {this.isValid('phone', fieldValue)})
  }

  handleEmailChange(field, options) {
    var name = "";
    var value = "";

    if(field === "email"){
      name = field;
      value =  options;
    }else {
      name = field.target.name;
      value = field.target.value;
    }

    this.setState({
        [name]: value,
    }, () => { this.isValid(name, value) });
  };
  
  handleChange(field, options) {
    var name = "";
    var value = "";

    if (field === "selecteddob") {
      name = field;
      value = moment(options).format(DateFormat);
    }else if(field === "verificationCode"){
      name = field;
      value =  options;
    }else {
      name = field.target.name;
      value = field.target.value;
    }

    this.setState({
      practiceUserAccountInfo: {
        ...this.state.practiceUserAccountInfo,
        [name]: value,
      }
    }, () => { this.isValid(name, value) });
  };


  isValid(name, value) {
    let isError = this.state.isError;
    let emailValid = this.state.emailValid;
    console.log(this.state.practiceUserAccountInfo)
    switch (name) {
      // case "answer_1":
      //   isError.answer_1 =
      //     value.length <= 0 ? "required" : "";
      //   break;
      // case "answer_2":
      //   isError.answer_2 =
      //     value.length <= 0 ? "required" : "";
      //   break;
      // case "answer_3":
      //   isError.answer_3 =
      //     value.length <= 0 ? "required" : "";
      //   break;
      case "selecteddob":
        isError.selecteddob =
          value.length <= 0 ? "required" : "";
        break;
      case "phone":
        isError.selecteddob =
          value.length <= 0 ? "required" : "";
        break;
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        isError.email = emailValid ? "" : "Wrong format";
        break;
      default:
        break; 
    }
    this.setState({ isError }, this.validateForm)
  }


  validateForm() {

    const {  verificationCode} = this.state.practiceUserAccountInfo;


    if(this.state.isError.email === ""){
      this.setState({ isGetVerifyCode: true });
    }else{
      this.setState({ isGetVerifyCode: false });
    }

    if(verificationCode && verificationCode.length == 4){
      this.setState({ isEnableVerify: true });
    }else{
      this.setState({ isEnableVerify: false });
    }
    
    if (
        // this.state.isError.answer_1 === '' && 
        // this.state.isError.answer_2 === '' && 
        // this.state.isError.answer_3 === '' && 
        // this.state.practiceUserAccountInfo.answer_1 !== '' && 
        // this.state.practiceUserAccountInfo.answer_2 !== '' && 
        // this.state.practiceUserAccountInfo.answer_3 !== '' &&
        this.state.practiceUserAccountInfo.phone !== '' &&
        this.state.practiceUserAccountInfo.selecteddob !== '') {
        
      this.setState({
        formValid: true
      });

    }else{
      this.setState({
        formValid: false
      });
    }
  }

async  handleNext() {
  this.setState({ isGetVerifyCode: false, loadingbtn:true });

  let params={
    "type": "phone ",
    // "answer_1": this.state.practiceUserAccountInfo.answer_1,
    // "answer_2": this.state.practiceUserAccountInfo.answer_2,
    // "answer_3": this.state.practiceUserAccountInfo.answer_3,
    "dob": this.state.practiceUserAccountInfo.selecteddob,
    "phone":this.state.practiceUserAccountInfo.phone
  }
    await  this.props.dispatch(ProfileVerify(params));
    await  this.setState({ isNext: this.props.userProfileVerifyInfo });
    await this.setState({ loadingbtn: false});

  }

  onclickBack(){
    if(!this.state.isNext){
      history.goBack()
    }else{
      this.props.dispatch(clearerror());
      this.setState({ isNext: false , formValid:false,
        practiceUserAccountInfo: {
          ...this.state.practiceUserAccountInfo,
          phone: '',
        }
      });
      
    }
  }

  async onChangeValue(field , value){
    let userVerificationCode = this.props.userVerificationCode
    userVerificationCode[field] = value
    await this.props.dispatch(GetUserVerificationRequest(userVerificationCode))
  }
  
async sendVerifyCode(resend){
    if(!resend){
      await this.onChangeValue('isVerify' , false)
      await this.onChangeValue('isLoading' , true);
    }else{
        clearInterval(this.myInterval)
       await this.setState({seconds:60 , isGetCode: true})
       await this.resend()
    }
    let params={
      "type": "email",
      "email": this.state.email
    }
    this.props.dispatch(GetUserVerification(params));
  }

  onClickVerifyOtp(data){
    this.onVerifyOtp('isLoading' , true);

      var params = {
        request_secrete: this.props.userVerificationCode.request_secrete,
        otp: data.verificationCode,
        email: this.state.email,
        media:'email'
      }
    this.props.dispatch(VerifyOtp(params));
  }

  async onVerifyOtp(field , value){
    let phoneVerificationInfo = this.props.phoneVerificationInfo
    
    phoneVerificationInfo[field] = value

    await this.props.dispatch(VerifyOtpRequest(phoneVerificationInfo))
}
  
  async handleRegister(){
    await this.setState({ loadingbtn: true});

    let params ={
      email:this.state.email,
      id:this.props.practiceUserAccountInfo.id,
      request_secrete:this.props.userVerificationCode.request_secrete,
      gender: this.props.practiceUserLoginInfo.gender,
      otp:this.state.practiceUserAccountInfo.verificationCode,
      phone:this.state.practiceUserAccountInfo.phone
    }
   await this.props.dispatch(UpdateUserProfileAboutInfo(params));
    await this.setState({ loadingbtn: false});

  }

  resend(){
    this.myInterval = setInterval(() => {
        const { seconds } = this.state

        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1
          }))
        }else{
          this.setState({ isGetCode: false })
        }
        if(this.seconds === 0){
          clearInterval(this.myInterval)
        }
      }, 1000)
  }

  render (){
    const {practiceUserAccountInfo  , phoneVerificationInfo , userVerificationCode} = this.props
    return(
        <div className="update_admin_profile__inner d-flex  flex-column transparent__form--white mb-auto">
          {this.state.isNext ?
            <>
            <div className="update_admin_profile__inner__header">
              <h4>Email address verification</h4>
              <p>Add your new email address and verify it with the 4 digit verification code we’ll send you.</p>
            </div>
            <div className="my-auto">
              {/* <PhoneVerify media="email"/> */}
              {userVerificationCode.isVerify?
                  <div>
                      <FormGroup className={`${this.props.phoneVerifyed === false ?  'notverifyed' : ''} mt-2`}>
                          <label>Enter the verification code</label>
                          <ReactCodeInput width="100%" type="number"  onChange={(options) => {this.handleChange('verificationCode',options);   this.props.dispatch(clearerror());}}  fieldWidth={65}  fieldHeight={80} fields={4} className={`mb-3`}/>
                          <LoadingButton  type="button" loading={phoneVerificationInfo.isLoading} label={phoneVerificationInfo.phoneVerifyed === false ? <>Incorrect verification code</> : <> {phoneVerificationInfo.phoneVerifyed === true ? <>Email Address verified</>  : <>Verify Email Address</> } </>} disabled={!this.state.isEnableVerify || phoneVerificationInfo.isLoading} size="xl" className={`${phoneVerificationInfo.phoneVerifyed === true ? 'verifyed' : ''} ${phoneVerificationInfo.phoneVerifyed === false ?  'notverifyed' : ''} mt-3 mb-3 w-100`}  
                              onClick={() => this.onClickVerifyOtp(this.state.practiceUserAccountInfo)}  color="primary" />
                          {!this.props.phoneVerifyed === true &&
                              <span className="text-nowrap">Didn’t receive your code? <Button disabled={this.state.isGetCode} onClick={() => this.sendVerifyCode(true)}  color="link primary" className="p-0">{!this.state.isGetCode ? 'Click here to resend.' : `(${this.state.seconds})` + 's'}</Button></span>
                          }
                      </FormGroup>
                  </div>
              :
                  <FormGroup>
                      <InputField type="text" label={'Email address'} name="email"  onChange={(options) => this.handleEmailChange(options)} value={this.state.email} className="mb-3" />
                      {/* <Button type="button" disabled={!this.state.isGetVerifyCode} onClick={() => this.sendVerifyCode()} size="xl" className="mt-5 w-100" color="primary">Get a code</Button> */}
                      <LoadingButton type="button" disabled={ !this.state.isGetVerifyCode || userVerificationCode.isLoading} loading={userVerificationCode.isLoading} label={`Get a code`} onClick={() => this.sendVerifyCode()} size="xl" className="mt-5 w-100" color="primary"/>{/* !phoneVerificationInfo.isGetVerifyCode || */}
                  </FormGroup>
              }
            </div>
            </>
          :
          <>
          <div className="update_admin_profile__inner__header">
            <h4>Verify your identity</h4>
            {/* <p>Fill in the answers and proceed to register a new email address.</p> */}
          </div>
          <div className="update_admin_profile__inner__body">
            {/* <FormGroup>
              <InputField type="text" label={practiceUserAccountInfo.security_question.question_1} name="answer_1"  onChange={(options) => this.handleChange(options)} value={this.state.practiceUserAccountInfo.answer_1} className="mb-3" />
            </FormGroup>
            <FormGroup>
              <InputField type="text" label={practiceUserAccountInfo.security_question.question_2} name="answer_2"  onChange={(options) => this.handleChange(options)} value={this.state.practiceUserAccountInfo.answer_2} className="mb-3" />
            </FormGroup>
            <FormGroup>
              <InputField type="text" label={practiceUserAccountInfo.security_question.question_3} name="answer_3"  onChange={(options) => this.handleChange(options)} value={this.state.practiceUserAccountInfo.answer_3} className="mb-3" />
            </FormGroup> */}
            <FormGroup>
                <Label for="Practicephone">Enter your phone number</Label>
                <PhoneInput defaultMask={'-...........'} alwaysDefaultMask={true} countryCodeEditable={false} inputProps={{ name: 'phone', required: true, }} country={(this.props.practiceProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => { this.handlePhoneChange(value, country, e, formattedValue) }} value={(this.state.phone)} />
            </FormGroup>
            <FormGroup>
              <div className="d-flex justify-content-between"><Label for="Ownership">Date of birth</Label></div>
              <DatePicker className="form-control custom-datepicker form-control mb-2"
                selected={this.state.practiceUserAccountInfo.selecteddob ? moment(this.state.practiceUserAccountInfo.selecteddob).toDate() : null}
                onChange={(date) => this.handleChange("selecteddob", date)}
                dateFormat="MMMM d, yyyy"
                maxDate={moment().subtract(18, 'years')._d}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
              />
              </FormGroup>
          </div>
          </>
          }
          <div className="update_admin_profile__inner__footer mt-auto">
            <div className="row">
              <div className="col-6">
                <LoadingButton size="md" loading={this.state.loadingbtn} disabled={ (this.state.isNext ? !phoneVerificationInfo.phoneVerifyed : !this.state.formValid) || this.state.loadingbtn } className="px-1" label={this.state.isNext ? 'Register email' : 'Next' } onClick={()=>{this.state.isNext ? this.handleRegister() : this.handleNext() }}  color="primary" />
              </div>
              <div className="col-6"><Button size="md" onClick={this.onclickBack} outline color="default white">Back</Button></div>
            </div>
          </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  userVerificationCode:state.task.userVerificationCode,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  loadingOwnerVerifyPhone:state.task.loadingOwnerVerifyPhone,
  phoneVerifyed:state.task.phoneVerifyed,
  userProfileVerifyInfo:state.task.userProfileVerifyInfo,
  isUpdateProfileCompleted:state.task.isUpdateProfileCompleted,
  isVerify:state.task.isVerify,
  phoneVerificationInfo:state.task.phoneVerificationInfo,
  practiceProfileInfo:state.task.practiceProfileInfo

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
