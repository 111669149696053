/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button , FormGroup , Label} from 'reactstrap';
import './styles.scss';
import {
	getFooterTime,
	getFooterWeather,
	waitingRoomDisplayOptions,
	postUpdateWaitingRoomDisplayOptions
} from '../../store/actions/taskAction';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import LoadingButton from '../../components/LoadingButton'
import news from '../../img/Newshape.png'
import news2 from '../../img/Newshape1.png'

const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#52d869',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: '#52d869',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});
const WaitingRoomDisplayOptions = () => {
	const bgImg = 'assets/img/bg.png';
	const dispatch = useDispatch();
	const states = useSelector((state) => state.task);
	useEffect(() => {
		dispatch(waitingRoomDisplayOptions(states.manageOperatoryChannel.id, true))
	}, []);

	const [state, setState] = React.useState({
		checkedB:  false,
		checkedC: false,
	});

	useEffect(() => {
		if(states.displayOptions){
		  	setState({ 
			  		'checkedB': states.displayOptions.display_custome_message_and_newsfeed == "1" ? true : false,
				  	'checkedC': states.displayOptions.display_time_weather == "1" ? true : false
			});
		}
	  }, [states.displayOptions]);
	
	const [message, setMessage] = useState({
		message: 'Save Changes',
		bgColor: 'primary',
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
		setMessage({
			message: 'Save Changes',
			bgColor: 'primary',
		});
	};

	const submitHandler = (event) => {
		event.preventDefault();
		
		let params ={
			"display_custome_message_and_newsfeed":state.checkedB ? "1"  : "0",
			"display_time_weather":state.checkedC ? "1" : "0"
		}
		dispatch(postUpdateWaitingRoomDisplayOptions(states.manageOperatoryChannel.id, params, true))
		setMessage({
			message: 'Changes Saved',
			bgColor: 'green',
		});
	};
	const [newLocation, setNewLocation] = useState(states.weatherLocation);
	return (
		<div className="d-flex waiting_room_common_section waiting_room_common_section_weather waiting_room_common_section_display_option">
			<div className="transparent__form--white d-flex flex-column leftVBox mr-20 waiting_room_common_section_time_zone">
					<h4>Display options</h4>
					<p> The channel’s footer can be displayed entirely, or just partially. Toogle the switches on and off to customize it. </p>
					<div>
						<FormGroup className="d-flex align-items-center time-zone-check-box">
							<label>Custom message & Newsfeed</label>
							<FormControlLabel
								control={
									<IOSSwitch
										checked={state.checkedB}
										onChange={handleChange}
										name='checkedB'
									/>
								}
							/>
						</FormGroup>
						<FormGroup className="d-flex align-items-center time-zone-check-box">
							<label>Time & Weather</label> &nbsp;&nbsp;&nbsp;
							<FormControlLabel
								control={
									<IOSSwitch
										checked={state.checkedC}
										onChange={handleChange}
										name='checkedC'
									/>
								}
							/>
						</FormGroup>
					</div>
					<LoadingButton type="button"  label={message.message} loading={states.buttonLoading} disabled={states.buttonLoading} size="xxl" onClick={submitHandler} className={`mt-auto w-100`}  color={message.bgColor}/>
			</div>
			<div className="transparent__form--white rightBox ml-20 waiting_room_common_section_img">
				{/* <img src={bgImg} className="h-100" alt=""></img> */}
				<div class='waitingRoom__main__screen__footer_option'>
					<div className='d-flex footer_message '>
						{state.checkedB ? (
							<>
								<div className="mt-auto  waitingRoom__main__screen--slide--footer mr-3" id="f1">
									<img src={news}/>
								</div>
							</>
						) : null}
						{state.checkedC ? (
							<div className='d-flex  flex-column waitingRoom__main__screen__footer_option--time_weather  ml-auto'>
								<img src={news2}/>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WaitingRoomDisplayOptions;
