import React from 'react'

const TextTabContent = () => {
    return (
        <div style={{ marginLeft:"395px" }}>
            <h5>TextTabContent</h5>
        </div>
    )
}

export default TextTabContent
