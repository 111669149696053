import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button  } from 'reactstrap';
import { history } from '../index';

import './style.scss';

class ProfileCreated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(){
  } 

  onSigninClick(users){
      
  }

  async componentDidMount(){
  }

  render (){
    return(
        <div className="restricted_access__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
            <SubHeader className="mb-auto" title='Profiles' />
            <div className="restricted_access__inner__screen mb-auto">
                <div className="d-flex flex-column text-center align-items-center restricted_access__inner__text text-center">
                    <h1 className="mb-5">Restricted access</h1>
                    <p>A few sections in the Thalamus app, such as this one, contain<br/> sensitive information that can only be viewed and managed<br/> by the practice owner, manager, or authorized user.</p>
                    <Button type="button" size="md" color="primary" onClick={history.goBack}>Back</Button>
                </div>
            </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(ProfileCreated);
