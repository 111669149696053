import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button } from 'reactstrap';
import Profile_icon from '../../img/play_white.svg';
import { formatSecond, MYSLIDESTYPE, TIMELINESLIDETYPE } from '../../data/comman';
// import he from 'he';
import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '../LoadingButton';
import MenuIcon from '../../img/menu.png';
import { removeWaitingRoomTimelineMySlide } from '../../store/actions/taskAction';
import slideBG from '../../img/bg.png';
import moment from 'moment';
const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';


const WaitingRoomSlideList = ({ slides, onEditSlide,  onNewSlideClick , timeLineList}) => {

    const state = useSelector((state) => state.task);
    const [showSlide, setShowSlide] = useState({
		slide: [],
		showSlide: false,
	});
	const [count, setCount] = useState(0);
    const [playerSlide, setPlayerSlide] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedSlide, setSelectedSlide] = useState({});
    const [removeMenu, setRemoveMenu] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(state.buttonLoading == false){
            setMenuOpen(false);
            setRemoveMenu(false);
            setSelectedSlide({});
        }
    }, [state.waitingRoomChannelSlides])

    const changeSlideSelected = (slide) => {
        setCount(count + 1);
		slides.forEach((element) => {
			if (element.id === slide.id) {
				element.selected = !slide.selected;
			}
		});
		var arr = [];
		slides.forEach((element) => {
			if (element.selected === true) {
				arr.push(element);
			}
		});
    }

    const playSelectedSlide = (slide) => {
		setPlayerSlide(slide);
		setShowSlide({
			slide: slide,
			showSlide: true,
		});
	};
	const onClickClose = (close) => {
		setShowSlide({
			slide: {},
			showSlide: false,
		});
    };
    
    const openMenu = (slide) => {
        setMenuOpen(true)
        setSelectedSlide(slide);
    }

    const cancelButton = (slide) => {
        setMenuOpen(false);
        setRemoveMenu(false);
        setSelectedSlide({});
    }

    const removeSlide = (slide) => {
        setRemoveMenu(true)
    }

    const submitRemoveVideo = (slide) => {
        dispatch(removeWaitingRoomTimelineMySlide(slide));
    }
    return (
        <div className='videoSelected__main__centerCol'>
            <div className="video_list">
                {console.log(slides,'slides')}
            {slides.length > 0 ? slides.map((slide, index) => {
                    const selectedImage = slide.selected === true ? RIGHTBOXIMAGE : WHITEBOXIMAGE;
                    return (
                        <div draggable={true} onDragStart={(event) => { event.dataTransfer.setData("timeline", JSON.stringify({ type: TIMELINESLIDETYPE, timeLine: slide })) }}
                          
                            key={index}
                            className={`videoSelected__main__columnFour videoSelected_box ${slide.isAdded.length > 0 ? 'border-4 border-warning' : ''}`}>
                            <div
                                className='videoSelected__main__card'
                                onClick={() => changeSlideSelected(slide)}>
                                {slide.isAdded.length > 0 && <h3  className='videoSelected__main__card__selectedImage' >{slide.isAdded.length}</h3>}
                                {/* <img
                                    className='videoSelected__main__card__selectedImage'
                                    src={`${selectedImage}`}
                                    alt=''
                                /> */}
                                <div style={{ height: '100%', width: '100%' }} >
                                    {/* <img
                                        src={slide.thumb}
                                        alt={slide.file_name}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                        }}
                                    /> */}
                                    {slide.image == null ? 
                                        <img src={slideBG} style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                        }}/>
                                    :
                                        <img src={`${slide.image}?time=${moment().unix()}`} style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                        }}/>
                                    }
                                </div>
                                <div
                                    className='page10__main__div2__gridbox__iconDiv toggle_icon' onClick={() => openMenu(slide)}>
                                        <i className="bar-icon"/>
                                </div>
                                <div className='videoSelected__main__card__subTitle'>
                                    {/* <div className='videoSelected__main__card__video_icon' onClick={() => playSelectedSlide(slide)}>
                                        <img
                                            src={Profile_icon}
                                            alt='play_video'
                                            
                                        />
                                    </div> */}
                                    <p className='videoSelected__main__card__title'>
                                        {slide.title} 
                                    </p>
                                    <p className='videoSelected__main__card__smalltitile'>
                                        {slide.category_name} | {formatSecond(slide.duration)}
                                    </p>
                                </div>
                                {menuOpen && slide.id === selectedSlide.id && (
                                    <div className="myVideoOptions toogle-menu"> 
                                        <div>
                                            <div>
                                                <h6>
                                                    SLIDE OPTIONS
                                                </h6>
                                                <span className="toggle-btn"  onClick={() => cancelButton(slide)}>
                                                    <i className="bar-icon"/>
                                                </span>
                                                
                                                <div className="menu-list">
                                                    <span onClick={()=> onEditSlide(slide) }>Edit</span>
                                                </div>
                                                <div className="menu-list">
                                                    <span onClick={() => {removeSlide(slide); setMenuOpen(false);} }>
                                                        Remove
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {removeMenu && slide.id === selectedSlide.id && (
                                    <div className="myVideoOptions bg_red toogle-menu"> 
                                        <div>
                                            <h6>
                                                REMOVE
                                            </h6>
                                            <span className="toggle-btn"  onClick={() => cancelButton(slide)}>
                                                    <i className="bar-icon"/>
                                            </span>
                                            <div className="remove_message">
                                                <span style={{ fontSize:"15px" }}>Remove slide permanently from your library?</span>
                                                <Row >
                                                    <Col lg='6'>
                                                        <LoadingButton type="button" label="REMOVE" loading={state.buttonLoading} size="md" onClick={()=>submitRemoveVideo(slide)} className={``} color="default"/>
                                                    </Col>
                                                    <Col lg='6'>
                                                        <Button
                                                            style={{
                                                                backgroundColor: 'transparent',
                                                                border: '1px solid white',
                                                            }}
                                                            onClick={()=> cancelButton(slide)}
                                                            >
                                                            CANCEL
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }): 
                <>
                    <div className="waiting-rom-right-sec">	
                        <div className="no-slide-text">
                                <h2>You haven’t created any slides yet.</h2>
                                <p>Select “Create Slide” on the left to create a new slide, or <Button className="p-0" color="link" onClick={onNewSlideClick}>click here.</Button></p>
                        </div>
                    </div>
                </>
                }
                {/* {showSlide.showSlide === true && (
                    <Player
                        video={playerSlide}
                        title={playerSlide}
                        style={{ marginTop: '-200px' }}
                        subtitle='https://iandevlin.github.io/mdn/video-player-with-captions/subtitles/vtt/sintel-en.vtt'
                        onClickClose={onClickClose}
                    />
                )} */}
            </div>
              
        </div>
    )
}

export default WaitingRoomSlideList;
