import React, { useEffect, useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import SubHeader from '../../components/Header/subHeader'
import LoadingButton from '../../components/LoadingButton'
import { getSystemVideoQuality, getWaitingVideoQuality, setSystemVideoQuality , setWaitingRoomVideoQuality} from '../../helper/LocalStorage';
import { VIDEO_QUALITY_AUTO, VIDEO_QUALITY_4K, VIDEO_QUALITY_FHD, VIDEO_QUALITY_HD, VIDEO_QUALITY_540, WAITION_ROOM_VIDEO_QUALITY_AUTO, WAITION_ROOM_VIDEO_QUALITY_4K, WAITION_ROOM_VIDEO_QUALITY_FHD, WAITION_ROOM_VIDEO_QUALITY_HD, WAITION_ROOM_VIDEO_QUALITY_540 } from "../../utilities/HttpUtility";



const VideoQuelity = () => {

	const [video_quality, setVideoQuality] = useState(0);
	const [w_roomvideo_quality, setWaitingVideoQuality] = useState(null);
	const [isLoading, setLoading] = useState(false);

    useEffect(()=>{
        var system_video_quality = getSystemVideoQuality();
        var waiting_room_video_quality = getWaitingVideoQuality();
        setVideoQuality(system_video_quality)
        setWaitingVideoQuality(waiting_room_video_quality)
	},[])

    const handleChange = (id) => {
        setVideoQuality(id)
    };

    const waitingRoomhandleChange = (id) => {
        setWaitingVideoQuality(id)
    };
    
    const onUpdateVideoQuality = async () =>{
        setLoading(true)
        setSystemVideoQuality(video_quality)
        setWaitingRoomVideoQuality(w_roomvideo_quality)

        setTimeout(function() {
                   setLoading(false)
        }, 1000);
    }

  return (
    <div className="language__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
    <SubHeader className="mb-auto" title={'Video Quality'}/>
        <div className="language__list w-auto d-flex flex-column transparent__form--white mb-auto">
            <div className="d-flex flex-grow-1 row">
                <div className="col">
                    <div className="language__inner__header pr-5 mr-5">
                      <h4 className='mb-5'>Chairside</h4>
                    </div>
                    <div className="language__inner__body mt-2 pr-5 mr-5">
                      <ul className="custom_radio_select ">
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio"  checked={video_quality == VIDEO_QUALITY_AUTO ? true : false} name="system_language" onChange={(e) => handleChange(VIDEO_QUALITY_AUTO)} id={`video_quality__${VIDEO_QUALITY_AUTO}`} />{' '}
                                    <Label for={`video_quality__${VIDEO_QUALITY_AUTO}`}>{'Auto'}</Label>
                                </FormGroup>
                            </li>
                            {/* <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={video_quality == VIDEO_QUALITY_4K ? true : false} name="system_language" onChange={(e) => handleChange(VIDEO_QUALITY_4K)} id={`video_quality__${VIDEO_QUALITY_4K}`} />{' '}
                                    <Label for={`video_quality__${VIDEO_QUALITY_4K}`}>{'4k - 2160p'}</Label>
                                </FormGroup>
                            </li> */}
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={video_quality == VIDEO_QUALITY_FHD ? true : false} name="system_language" onChange={(e) => handleChange(VIDEO_QUALITY_FHD)} id={`video_quality__${VIDEO_QUALITY_FHD}`} />{' '}
                                    <Label for={`video_quality__${VIDEO_QUALITY_FHD}`}>{'FHD - 1080p'}</Label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={video_quality == VIDEO_QUALITY_HD ? true : false} name="system_language" onChange={(e) => handleChange(VIDEO_QUALITY_HD)} id={`video_quality__${VIDEO_QUALITY_HD}`} />{' '}
                                    <Label for={`video_quality__${VIDEO_QUALITY_HD}`}>{'HD - 720p'}</Label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={video_quality == VIDEO_QUALITY_540 ? true : false} name="system_language" onChange={(e) => handleChange(VIDEO_QUALITY_540)} id={`video_quality__${VIDEO_QUALITY_540}`} />{' '}
                                    <Label for={`video_quality__${VIDEO_QUALITY_540}`}>{'540p'}</Label>
                                </FormGroup>
                            </li>
                      </ul>
                    </div>
                </div>
                <div className="col">
                    <div className="language__inner__header pl-5 ml-5">
                      <h4 className='mb-5'>Waiting Room</h4>
                    </div>
                    <div className="language__inner__body mt-2 pl-5 ml-5">
                      <ul className="custom_radio_select">
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio"  checked={w_roomvideo_quality == WAITION_ROOM_VIDEO_QUALITY_AUTO ? true : false} name="w_video_qualite" onChange={(e) => waitingRoomhandleChange(WAITION_ROOM_VIDEO_QUALITY_AUTO)} id={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_AUTO}`} />{' '}
                                    <Label for={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_AUTO}`}>{'Auto'}</Label>
                                </FormGroup>
                            </li>
                            {/* <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={w_roomvideo_quality == WAITION_ROOM_VIDEO_QUALITY_4K ? true : false} name="w_video_qualite" onChange={(e) => waitingRoomhandleChange(WAITION_ROOM_VIDEO_QUALITY_4K)} id={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_4K}`} />{' '}
                                    <Label for={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_4K}`}>{'4k - 2160p'}</Label>
                                </FormGroup>
                            </li> */}
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={w_roomvideo_quality == WAITION_ROOM_VIDEO_QUALITY_FHD ? true : false} name="w_video_qualite" onChange={(e) => waitingRoomhandleChange(WAITION_ROOM_VIDEO_QUALITY_FHD)} id={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_FHD}`} />{' '}
                                    <Label for={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_FHD}`}>{'FHD - 1080p'}</Label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={w_roomvideo_quality == WAITION_ROOM_VIDEO_QUALITY_HD ? true : false} name="w_video_qualite" onChange={(e) => waitingRoomhandleChange(WAITION_ROOM_VIDEO_QUALITY_HD)} id={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_HD}`} />{' '}
                                    <Label for={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_HD}`}>{'HD - 720p'}</Label>
                                </FormGroup>
                            </li>
                            <li>
                                <FormGroup check className="p-0">
                                    <Input type="radio" checked={w_roomvideo_quality == WAITION_ROOM_VIDEO_QUALITY_540 ? true : false} name="w_video_qualite" onChange={(e) => waitingRoomhandleChange(WAITION_ROOM_VIDEO_QUALITY_540)} id={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_540}`} />{' '}
                                    <Label for={`w_video_quality__${WAITION_ROOM_VIDEO_QUALITY_540}`}>{'540p'}</Label>
                                </FormGroup>
                            </li>
                      </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <LoadingButton type="button" size="md" onClick={() => onUpdateVideoQuality()} disabled={isLoading} label={'Set Videos Quality'} loading={isLoading}   color="primary" />
            </div>
        </div>
    </div>
  )
}

export default VideoQuelity
