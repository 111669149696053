import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './sidebar.scss'
import { history } from "../../..";
import { setGoogleTvTestDriveCatIsOpen, setGoogleTvTestDriveMenuItem } from "../../../../store/actions/taskAction";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
   active :{
    color:'#ffa819'
  },
  tooltip:{
    fontSize: '50px'
  },
}));



const Sidebar = ({value, setValue, tabList, items, isChairside, isOperatoryRoom }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const states = useSelector(state => state.task);
  const [categoriesList, setCategoriesList] = useState(states.chairSideCategoryList);
  // Below two are For Collapse Toggle
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setGoogleTvTestDriveMenuItem(newValue));
    let params ={
        isOpen: false,
        catTitle:'',
        videosList:[]
    }
    dispatch(setGoogleTvTestDriveCatIsOpen(params))
    if(isChairside){
      if(newValue == 6){
        history.push('/google-tv-profile')
      }
      if(newValue == 7){
        history.push('/google-tv-setting')
      }
    }else{
      if(newValue == 6){
        history.push('/google-tv-profile')
      }
      if(newValue == 7){
        history.push('/google-tv-setting')
      }
    }
     
    
  };
  const showlabel = (name) =>{
    return <div className="tabname">{name}</div>
  }

  const showIcon = (icon) =>{
    return <div className="navIcons">{icon}</div>
  }
  
  return (
    <>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        {tabList.map((tab,index) => {
          const activeIndex = value === index ?  classes.active  :'' 
          return(
              <Tab key={index} icon={showIcon(tab.icon)} textColor='#FFF' label={showlabel(tab.name)} {...a11yProps(index)}  className = {`${activeIndex}menu-button`}  />     
          )
        })}
      </Tabs>
      
    </>
  );

}

export default Sidebar;