import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import NumberFormat from 'react-number-format';
import { getCartImageByType , DateDDMMMYYY, YY, getCardNameByNo} from '../../../data/comman';

import CVVIcon from '../../../img/cvv.png'
import CVVIconHover from '../../../img/cvv-hover.png'
import StripeIcon from '../../../img/stripe-icon.png'
import { Button , Input } from 'reactstrap';
import { getCardImageByNo } from '../../../data/comman';
import {AddBillingCard , UpdateBillingCard , changeCardStatus , resetSuccess , clearerror , PayPracticePayment, showError, GetBillingCard} from "../../../store/actions/taskAction";
import LoadingButton from '../../LoadingButton'
import { history } from '../../../pages/index';
import moment from 'moment';
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdate:false,
      isNewAdded:false,
      isRemove:false,
      isRemoveConfirm:false,
      vardImage:'',
      secondaryCreditCard: {},
      creditCardNumber: "",
      cardHolderName: "",
      viewCardHoldename: "",
      expireDate: "",
      editExpireDate: "",
      code: "",
      formValid:false,
      isError:{
        creditCardNumber: "",
        cardHolderName: "",
        editExpireDate: "",
        code:''
      }
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.onUpdateCard = this.onUpdateCard.bind(this);
    this.onRemoveCard = this.onRemoveCard.bind(this);
    this.onReplaceCard = this.onReplaceCard.bind(this);
    this.onConfirmRemove = this.onConfirmRemove.bind(this);
    
    
  }


  async componentDidMount(){
    await this.props.dispatch(clearerror())
    await this.props.dispatch(resetSuccess())
  }

  onClickUpdateCard(){
    this.props.dispatch(clearerror())
    this.setState({isUpdate:!this.state.isUpdate , editExpireDate:''})
  }

  onShowUpdatedCard(){
     this.props.dispatch(resetSuccess())
     this.setState({isUpdate:!this.state.isUpdate})
  }

  onShowNewCard(){
    this.props.dispatch(resetSuccess())
    this.props.getSecondaryCardInfo()
    this.setState({isReplace:!this.state.isReplace , cardHolderName:'',creditCardNumber:'',editExpireDate:'',code:''})
  }

  onReplaceCard(){
    this.setState({isNewAdded:!this.state.isNewAdded})
  }

  onClickRemoveCard(){
    this.setState({isRemove:!this.state.isRemove})
  }

  onRemoveCard(){
    let params ={
      "is_active":0,
    }
    this.props.dispatch(changeCardStatus(params, this.props.secondaryCreditCard.id))
  }

  onConfirmRemove(){
    this.setState({isRemoveConfirm:!this.state.isRemoveConfirm})
  }

  onClickBack(){
    this.props.dispatch(resetSuccess())
    this.props.getSecondaryCardInfo()
    this.setState({isRemoveConfirm:false,isRemove:false})
  }
  
  async onUpdateCard(){

    
  var isError = false;
  var error = {errors: []};
  var { editExpireDate } = this.state;

  var currentYear = moment().format(YY)
  var currentMonth = moment().format('M')
  if(editExpireDate.toString().trim() == ""){
    isError = true;
    error['errors']['card.exp_month'] = ["Please enter expiry date"];
  }else if(editExpireDate.toString().trim().length < 4){
    isError = true;
    error['errors']['card.exp_month'] = ["Invalid expiry date"];
  }else if(parseInt(this.state.editExpireDate.slice(0,2)) > 12 || parseInt(this.state.editExpireDate.slice(0,2)) < 1){
    isError = true;
    error['errors']['card.exp_month'] = ["Invalid expiry date"];
  }else if((parseInt(editExpireDate.toString().trim().slice(2,6)) > parseInt(currentYear) + 20) || parseInt(editExpireDate.toString().trim().slice(2,6)) < parseInt(currentYear) ){
    isError = true;
    error['errors']['card.exp_month'] = ["Invalid expiry date"];
  }else if((parseInt(this.state.editExpireDate.slice(0,2)) < currentMonth) && (parseInt(editExpireDate.slice(2,6))) <= currentYear){
    isError = true;
    error['errors']['card.exp_month'] = ["Invalid expiry date"];
  }

  if(isError){
    this.props.dispatch(showError(error));
    return
  }


    await this.props.dispatch(UpdateBillingCard(this.state.editExpireDate , this.props.secondaryCreditCard));

     let params={
      pay_info:{
        "pay_once": 0,
        "card_id": this.props.secondaryCreditCard.id
      }
     }
     await this.props.dispatch(PayPracticePayment(params));
  }

  isValidate(){
    const { cardHolderName, creditCardNumber, editExpireDate, code } = this.state;
    var error = {errors: []};
    var isError = false;

    if(cardHolderName.toString().trim() == ""){
      isError = true;
      error['errors']['card.name'] = ["Please enter name"];
    }

    if(creditCardNumber.toString().trim() == ""){
      isError = true;
      error['errors']['card.number'] = ["Please enter card number"];
    }

    var currentYear = moment().format(YY)
    var currentMonth = moment().format('M')
    if(editExpireDate.toString().trim() == ""){
      isError = true;
      error['errors']['card.exp_month'] = ["Please enter expiry date"];
    }else if(editExpireDate.toString().trim().length < 4){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if(parseInt(this.state.editExpireDate.slice(0,2)) > 12 || parseInt(this.state.editExpireDate.slice(0,2)) < 1){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(editExpireDate.toString().trim().slice(2,6)) > parseInt(currentYear) + 20) || parseInt(editExpireDate.toString().trim().slice(2,6)) < parseInt(currentYear) ){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(this.state.editExpireDate.slice(0,2)) < currentMonth) && (parseInt(editExpireDate.slice(2,6))) <= currentYear){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }
    
    if(code.toString().trim() == ""){
      isError = true;
      error['errors']['card.cvv'] = ["Please enter cvv"];
    }else if(code.toString().trim().length < 3 ){
      isError = true;
      error['errors']['card.cvv'] = ["Invalid cvv"]; 
    }else if(getCardNameByNo(creditCardNumber) == "AMEX" && code.toString().trim().length < 4 ){
      isError = true;
      error['errors']['card.cvv'] = ["Invalid cvv"];
    }

    this.props.dispatch(showError(error));
    return isError
  }

  
 async onAddCard(){

  if(this.isValidate()) return;

  let params={
      "card": {
          "name": this.state.cardHolderName,
          "number": this.state.creditCardNumber,
          "expiry_month":  parseInt(this.state.editExpireDate.slice(0,2)), 
          "expiry_year": parseInt(this.state.editExpireDate.slice(2,6)), 
          "cvv": this.state.code,
          "is_primary":0
        }
    }
    await this.props.dispatch(AddBillingCard(params));
    await this.props.dispatch(GetBillingCard());
    await this.props.getSecondaryCardInfo()
   let param={
    pay_info:{
      "pay_once": 0,
      "card_id": this.props.secondaryCreditCard && this.props.secondaryCreditCard.id
    }
   }
   await this.props.dispatch(PayPracticePayment(param));
  }
  
  changeHandler(event, filed){
    var filedName = "", filedValue = "";
    var vardImage = this.state.vardImage;
    if(filed){
        filedName = filed;
        filedValue = event.value;
        if(filedName === "creditCardNumber"){
            vardImage = getCardImageByNo(filedValue);
            console.log(vardImage,'vardImage')
        }
    }else{
        var { name, value } = event.target;
        filedName = name;
        filedValue = value;
    }
    this.setState({
        [filedName]: filedValue,
        vardImage,
        error: {} 
    },()=> {
        this.isValid(filedName , filedValue);
    });
  }

  isValid(name , value){
    let isError = this.state.isError ;

    switch (name) {
      case "creditCardNumber":
          isError.creditCardNumber =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "cardHolderName":
          isError.cardHolderName =  
          value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "editExpireDate":
          isError.editExpireDate =  
          value.length <= 0 ? "required" : "";
          break;
      case "code":
        isError.code =  
        value.length <= 0 ? "required" : "";
        break;
      default:
          break;
    }

    this.setState(this.validateForm)
  }
  
  validateForm(){
    this.setState({formValid: this.state.isError.creditCardNumber === ''   && this.state.isError.cardHolderName === ''   && this.state.isError.editExpireDate ==='' && this.state.editExpireDate !== '' &&  this.state.isError.code === '' && this.state.code !== ''});
  }


  render (){
    const secondaryCreditCard = this.props.secondaryCreditCard || {};
    const secondaryexpireDate = this.props.secondaryexpireDate
    const { isSuccess , loadingAddCardInfo , isSuccessRemove , loadingUpdate , loadingcardstatus , error, isUpdateSuccess} = this.props
    var imgsrc;
    if(secondaryCreditCard){
       imgsrc = !this.props.isReplace && secondaryCreditCard.brand ? getCartImageByType(secondaryCreditCard.brand) : this.state.vardImage
    }else{
       imgsrc =  this.state.vardImage
    }
    return(
        <div className={` transparent__form--white billingcard__inner__panel d-flex flex-column`} > {/* for screen 173,174,175 add class bg-danger */}
          <img src={StripeIcon} className="stripe-logo" alt=""/>
          <div className="billingcard__inner__panel__header">
            <h4>Credit card</h4>
            {secondaryCreditCard === null ?
              <p>Secondary credit cards are charged if a primary card is declined. Adding a Visa, MasterCard, Amex or Discover as a secondary card will replace any previously stored secondary credit card.</p>
            :
            <>
              {this.props.isUpdate ?
                <p>Update your secondary credit card information<br/>
                and pay outstanding balance.</p>
                :
                <>
                  {this.props.isReplace ? 
                    <p>Secondary credit cards are used to pay for all Thalamus fees. Adding a Visa, MasterCard, Amex, or Discover as a primary card will replace any previously stored primary credit card.</p>
                  :      
                  <p>This is your secondary credit card. It is used to pay for any Thalamus related fees, and can be updated at any moment.</p>
                  }
                </>
                }
            </>
            }
            {secondaryCreditCard.brand &&
              <div className="card-type">
              {!this.props.isReplace?
                <>
                  <img src={getCartImageByType(secondaryCreditCard.brand)} className="" alt="" />
                  <p>{secondaryCreditCard.brand}</p>
                </>
              : null }
              </div>
            }
          </div>
          <>
          {/* {isSuccess ?
            <>
            {this.props.isReplace ?
              <>
              <div className="billingcard__inner__panel__body cc-confirmation">
                <p>Secondary credit card successfully added. </p>
              </div>
              <div className="billingcard__inner__panel__footer mt-auto">
                <div className=""><Button className="w-100" onClick={() => this.onShowNewCard()}  color="primary">Show new credit card</Button></div>
              </div>
              </>
            :  
              <>
              <div className="billingcard__inner__panel__body cc-confirmation">
                <p>Primary credit card successfully updated.</p>
              </div>
              <div className="billingcard__inner__panel__footer mt-auto">
                <div className=""><Button className="w-100" onClick={() => this.onShowUpdatedCard()}  color="primary">Show updated credit card</Button></div>
              </div>
              </>
            } 
            </>
          : */}
            <>
              <>
                <>
                  <div className={`${secondaryCreditCard ? secondaryCreditCard.brand:''}-bg billingcard__inner__panel__body ${secondaryCreditCard  && secondaryCreditCard.declined_at && !this.props.isReplace ? 'declined' : ''}`}> {/* mastercard-bg , visacard-bg , amexcard-bg , discovercard-bg*/}
                    <div className="row">
                      <div className="col-12">
                      <div className={`${error && error.errors && error.errors['card.number'] ? 'inputerror' : ''} form-group  icon-card `}>
                            <div className={`d-flex justify-content-between `}>
                                <label>Credit card number</label>
                                {error && error.errors && error.errors['card.number'] && <span className='text--danger'>{error.errors['card.number'][0]}</span>}
                            </div>
                            {this.props.isReplace || this.props.isUpdate && !secondaryCreditCard.brand ?
                              <NumberFormat className="form-control" format="#### #### #### ####" name="creditCardNumber" value={this.state.creditCardNumber} onValueChange={(value)=>this.changeHandler(value, "creditCardNumber")}/>
                            :
                              <Input type="text" disabled value={`xxxx xxxx xxxx ${secondaryCreditCard.last4}`}/>
                            }
                            {imgsrc ? <img src={imgsrc}  className="icon-img" alt="" /> : null}
                        </div>
                      </div>
                      <div className="col-12">
                      <div className={`form-group ${error && error.errors && error.errors['card.name'] ? 'inputerror' : ''}`}>
                          <div className={`d-flex justify-content-between `}>
                            <label>Name on card</label>
                            {error && error.errors && error.errors['card.name'] && <span className='text--danger'>{error.errors['card.name'][0]}</span>}
                          </div>
                          { !this.props.isReplace && secondaryCreditCard.brand ? 
                              <Input type="text" name="cardHolderName" value={secondaryCreditCard.name} disabled/>
                            :
                              <Input type="text" name="cardHolderName" onChange={this.changeHandler} value={this.state.cardHolderName} />
                          }
                        </div>
                      </div>
                      <div className="col-6">
                      <div className={`${error && error.errors && error.errors['card.exp_month'] || error && error.errors && error.errors['card.exp_month'] ? 'inputerror' : ''} form-group mb-0`}>
                          <label>Expiry date</label>
                          {this.props.isReplace || this.props.isUpdate && !secondaryCreditCard.brand ?
                            <NumberFormat  className="form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="editExpireDate" onValueChange={(value)=>this.changeHandler(value, "editExpireDate")} value={this.state.editExpireDate}/>
                          :
                            <NumberFormat  className="form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="expireDate" disabled value={secondaryexpireDate}/>
                          }
                          {error && error.errors && error.errors['card.exp_month'] && <span className='text--danger'>{error.errors['card.exp_month'][0]}</span>}

                        </div>
                      </div>
                      <div className="col-6">
                      <div className={`form-group  mb-0 cvv-img ${error && error.errors && error.errors['card.cvv' ]? 'inputerror' : ""}`}>
                          <label>Security code <img src={CVVIcon} alt="" /> </label>
                          <NumberFormat  className="form-control"  format={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "####" : "###"} placeholder="" name="code" onValueChange={(value)=>this.changeHandler(value, "code")} type="password"/>
                          {error && error.errors && error.errors['card.cvv'] && <span className='text--danger'>{error.errors['card.cvv'][0]}</span>}
                          <div className="what-cvv">? <span><img src={CVVIconHover} alt=""/> <p>The 3 or 4 numbers on the back of your Visa, MasterCard, or AMEX card.</p></span></div>
                        </div>
                      </div>
                      {secondaryCreditCard && secondaryCreditCard.declined_at && !this.props.isReplace &&
                        <div className="card__declined text-center d-flex justify-content-center align-items-center">
                          Declined payment  | { moment(secondaryCreditCard.declined_at).format(DateDDMMMYYY) }
                        </div>
                      }
                    </div>
                  </div>
                  <div className="billingcard__inner__panel__footer mt-auto">
                    {this.props.isUpdate  &&
                      <>
                        {isSuccess ?
                          <div className="d-flex justify-content-between">
                            <LoadingButton type="button" label={`Card updated & Payment received. Thank you!`}  className="w-100" loading={loadingUpdate} /*disabled={!this.state.formValid}*/  onClick={this.onShowUpdatedCard} color="green"/>
                          </div>
                          :
                          <div className="d-flex justify-content-betweeb">
                              <LoadingButton type="button" label={`Update card and pay now  |  $${this.props.getPracticePaymentinfo.outstanding_balance}`}  className="mr-3" loading={loadingUpdate} /*disabled={!this.state.formValid || loadingUpdate}*/ size="xxl" onClick={this.onUpdateCard} color="primary"/>
                              <Button size="xs" onClick={() => history.goBack()}  className="ml-3 px-5" outline color="default white">Back</Button>
                          </div>
                        }
                      </>
                    }
                      <>
                      {this.props.isReplace  &&
                         <>
                         {isSuccess ?
                           <div className="d-flex justify-content-betweeb">
                               <LoadingButton type="button" label={`Card added & Payment received. Thank you!`}  className="w-100" loading={loadingUpdate} /*disabled={!this.state.formValid}*/  onClick={this.onShowUpdatedCard} color="green"/>
                           </div>
                         :
                           <div className="d-flex justify-content-betweeb">
                               <LoadingButton type="button" className="mr-3" error={this.props.error} label={`Add card and pay now  |  $${this.props.getPracticePaymentinfo.outstanding_balance}`} loading={loadingAddCardInfo} /*disabled={!this.state.formValid || loadingAddCardInfo}*/ size="xxl" onClick={() => this.onAddCard()} color="primary"/>
                               <Button size="xs" onClick={() => history.goBack()} className="ml-3 px-5" outline color="default white">Back</Button>
                           </div>
                         }
                         </>
                      }
                      </>
                  </div>
                </>
              </>

            </>
          {/* }  */}
          </> 
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingAddCardInfo:state.task.loadingAddCardInfo,
  loadingBillingCardInfo:state.task.loadingBillingCardInfo,
  loadingUpdate:state.task.loadingUpdate,
  loadingcardstatus:state.task.loadingcardstatus,
  isUpdateSuccess:state.task.isUpdateSuccess,
  isSuccess:state.task.isSuccess,
  isSuccessRemove:state.task.isSuccessRemove,
  error:state.task.error,
  getPracticePaymentinfo:state.task.getPracticePaymentinfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
