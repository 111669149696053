import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import CaptionMDImg from '../../img/CaptionMDImg.jpg'
import {getDefaultCaptionFontSize , UpdateAccountSetting , AccountSettingRequest , UpdateSettingRequest} from "../../store/actions/taskAction";
import _ from 'lodash';

import './style.scss';
import { Button} from 'reactstrap';
import LoadingButton from '../../components/LoadingButton'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSave:false,
    };
    this.toggleChange = this.toggleChange.bind(this);
    this.onSave = this.onSave.bind(this);

  }
  

  async  componentDidMount(){
    await this.props.dispatch(getDefaultCaptionFontSize())  
    this.onUpdateAccountSetting('isSave' , false)
  
  }

  toggleChange(id , event){
    this.onEditAccountSetting('isSave' , true)
    this.onUpdateAccountSetting('cc_font_size' , id)
  }

  onSave(){
    let params ={
      cc_font_size:this.props.practiceUserAccountInfo.account_setting.cc_font_size
    }
    this.onEditAccountSetting('isLoading' , true)
    this.props.dispatch(UpdateAccountSetting(params))    
  }

  async onUpdateAccountSetting(field , value){
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo
    practiceUserAccountInfo.account_setting[field] = value
    await this.props.dispatch(AccountSettingRequest(practiceUserAccountInfo))
  }

  async onEditAccountSetting(field , value){
    let practiceUserAccountSetting = this.props.practiceUserAccountSetting
    practiceUserAccountSetting[field] = value
    await this.props.dispatch(UpdateSettingRequest(practiceUserAccountSetting))
  }

  render (){
    const {defaultCaptionSize , practiceUserAccountInfo , practiceUserAccountSetting} = this.props
    const result = defaultCaptionSize && defaultCaptionSize.filter(x => console.log(x,'xxxxxxx'));

    return(
      <div className="main__screen caption__page  d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Captions Font Size"/>
          <div className="caption__inner mb-auto">
            <div className="d-flex">
              <div className="col-4">
                <div className="caption__inner__card d-flex flex-column">
                  <div className="caption__inner__card__header">
                    <h4>Closed Caption font size</h4>
                    <p>Select which closed caption font size works best for you and your patients. Please take into consideration the distance patients will be sitting away from your monitor or TV.</p>
                  </div>
                  <div className="caption__inner__card__body">
                    <ul>
                      {defaultCaptionSize &&_.size(defaultCaptionSize)>0 && defaultCaptionSize.map((caption_size ,index)=>{
                          return <li>
                                  <input type="radio" onChange={(event)=>this.toggleChange(caption_size.id  , event)}  checked={practiceUserAccountInfo.account_setting.cc_font_size === caption_size.id} name="caption-size" id={`caption-${index}`} />
                                  <label for={`caption-${index}`}>
                                    <div className="caption-square"></div>
                                    <p>{caption_size&&caption_size.key_name?caption_size.key_name:0} size</p>
                                    <div className="caption-switch"></div>
                                  </label>
                                </li>
                      })}
                    </ul>
                  </div>
                  <div className="caption__inner__card__footer mt-auto">
                    <LoadingButton type="button" size="md" label={`Save Captions font size`} loading={practiceUserAccountSetting.isLoading} disabled={!practiceUserAccountSetting.isSave || practiceUserAccountSetting.isLoading}  className={``} onClick={(e) => this.onSave()} color="primary" />
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="caption__inner__preview">
                  <div className="caption__inner__preview__box">
                    <img src={CaptionMDImg} alt="" />
                    <div className={`caption__inner__preview__foot`}>
                      {_.size(defaultCaptionSize)>0 &&
                      <span className={`__${this.props.practiceUserAccountInfo.account_setting.cc_font_size} `}>{defaultCaptionSize && defaultCaptionSize.filter(x => x.id == this.props.practiceUserAccountInfo.account_setting.cc_font_size)[0].key_name} CC looks like this.</span>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  defaultCaptionSize:state.task.defaultCaptionSize,
  practiceUserAccountInfo:state.task.practiceUserLoginInfo,
  practiceUserProfileInfo:state.task.practiceUserProfileInfo,
  practiceUserAccountSetting:state.task.practiceUserAccountSetting
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);