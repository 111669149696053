import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import NewProfileForm from './Form'
import { GetPracticeMoreInfo} from "../../store/actions/taskAction";



import './style.scss';

class NewProfileIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isStart:true
    }
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(){
    this.setState({isStart:false})
  } 

  async componentDidMount(){
    await this.props.dispatch(GetPracticeMoreInfo());
  }

  render (){
    return(
      <div className="new_profile__main__screen  d-flex flex-column h-100 align-items-center ">
            <SubHeader className=""/>
            <NewProfileForm />
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(NewProfileIndex);
