import React from 'react';

import { Button , Label , FormGroup, Form , Col , Row} from 'reactstrap';
import DropDown from '../../../components/DropDown'
import { InputField } from '../../../components/InputField';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { noOfoperatories,days , tv_count,distributor} from '../../../data/comman';
import LoadingButton from '../../../components/LoadingButton'
import SubHeader from  '../../../components/Header/subHeader'
import {UpdatepracticeInfoRequest , UpdatepracticeInfo} from "../../../store/actions/taskAction";
import NewSubHeader from '../../../components/Header/NewSubHeader';

class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isSave:true,
      isError:{
        location_manager:'',
        selectedsoftware:'',
        selectedsource:'',
        selectedpracticetype:'',
        sales_executive:'',
        distributor_name:'',
        selectedtv_count:'',
        systemlanguage:'',
        medialanguage:'',
      }
    };
    this.SaveAdditionalInfo = this.SaveAdditionalInfo.bind(this);

  }

  componentDidMount(){
    this.onEditPracticeInfo('isLoading' , false);
  }

  async onEditPracticeInfo(field , value){
    let practiceProfileInfo = this.props.practiceProfileInfo
    practiceProfileInfo[field] = value
    await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))
  }

  async handleChange(field , value){
    let practiceProfileInfo = this.props.practiceProfileInfo
    if(field === 'location_manager' || field === 'sales_executive' ){
      practiceProfileInfo.additional_info[field] = value
    }
    else{
      practiceProfileInfo[field] = value
    }
    
    await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))
    await this.isValid(field , value)
  }

  isValid(field , value){
    let isError = this.state.isError;

    switch (field) {
      case "location_manager":
        isError.location_manager =
          value.length < 4 ? "Atleast 4 characaters requi red" : "";
        break;
      case "selectedsoftware":
        isError.selectedsoftware =
        value.name < 0 ? "required" : "";
        break;
      case "selectedsource":
        isError.phone =
          value.name < 0 ? "required" : "";
        break;
      case "selectedpracticetype":
        isError.selectedpracticetype =
        value.name  < 0 ? "required" : "";
        console.log(value , 'value')
        break;
      case "sales_executive":
        isError.sales_executive =
        value < 0 ? "required" : "";
        break;
      case "distributor_name":
        isError.distributor_name =
        value < 0? "required" : "";
        break;
      case "selectedtv_count":
        isError.selectedtv_count =
        value.value < 0 ? "required" : "";
        break;
      case "systemlanguage":
        isError.systemlanguage =
        value.value < 0 ? "required" : "";
        break;
      case "medialanguage":
        isError.medialanguage =
        value.value < 0 ? "required" : "";
        break;
        
      default:
        break; 
    }
    this.setState({ isError })

    const {location_manager , selectedsoftware , selectedsource , selectedpracticetype , sales_executive , selectedtv_count , distributor_name, systemlanguage , medialanguage} = this.state.isError

    if (location_manager === '' &&
      selectedsoftware === '' &&
      selectedsource === '' && 
      selectedpracticetype === '' &&
      sales_executive === '' && 
      selectedtv_count === ''&& distributor_name === '' &&  systemlanguage === '' , medialanguage ==='') {
          
        this.setState({
          isSave: true
        });

      }else{
        this.setState({
          isSave: false
        });
      }

      console.log(isError ,  'isError')
  }

  async SaveAdditionalInfo(){
    this.onEditPracticeInfo('isLoading' , true)

    const {practiceProfileInfo} = this.props
    var specializations = []
    if(practiceProfileInfo?.specializations){
      practiceProfileInfo?.specializations?.map((ids)=>
        specializations.push(ids?.id)
      );
    }


    let params={
      id:practiceProfileInfo?.id,
      name:practiceProfileInfo?.name,
      country_id:practiceProfileInfo?.selectedcountry?.id,
      phone:practiceProfileInfo?.phone,
      zip:practiceProfileInfo?.zip,
      additional_info:{
        location_manager:practiceProfileInfo?.additional_info?.location_manager,
        software:practiceProfileInfo?.selectedsoftware?.name,
        source:practiceProfileInfo?.selectedsource?.name,
        sales_executive:practiceProfileInfo?.additional_info?.sales_executive,
        distributor_name:practiceProfileInfo?.selecteddistributor ? practiceProfileInfo?.selecteddistributor?.name : "",
        customer_type:practiceProfileInfo?.selectedpracticetype?.id,
        operatory_count:practiceProfileInfo?.selectedoperatories?.value,
        tv_count: practiceProfileInfo?.selectedtv_count?.value
      },
    }
    // if(practiceProfileInfo.medialanguage && practiceProfileInfo.medialanguage.id){
    //   params['practice_setting'] = {
    //     media_language: practiceProfileInfo.medialanguage.id,
    //   }
    // }
    // if(practiceProfileInfo.systemlanguage && practiceProfileInfo.systemlanguage.id){
    //   params['practice_setting'] = {
    //     system_language: practiceProfileInfo.systemlanguage.id
    //   }
    // }
    await  this.props.dispatch(UpdatepracticeInfo(params));
  }

  
  render (){
    const {t, media_language , system_language , practiceProfileInfo} = this.props
    return(
      <div className="practiceInfo__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
        {/* <SubHeader className="mb-auto" title="Practice Info"/> */}
        <NewSubHeader className="mb-auto" title="Update Additional Info" />
        <div className="transparent__form--white additional_information d-flex flex-column align-items-stretch mb-auto" style={{ minHeight: 'auto' }}>
          <Form className="mt-2 h-100 d-flex flex-column">
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-5">
                    <h4 className="m-0">{t('title.AdditionalPracticeInfo')}</h4>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="add-pracinfo">
                <Col md={6}>
                  {/* <FormGroup>
                    <InputField type="text" label={t('form.locationManager')} name="location_manager" value={practiceProfileInfo.additional_info.location_manager} onChange={(e) => this.handleChange('location_manager', e.target.value)} />
                  </FormGroup> */}
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="practiceManagementSoftware">{t('form.practiceManagementSoftware')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.name} ${x.name}`}
                        options={this.props.practice_softwares}
                        onChange={(val)=> this.handleChange('selectedsoftware', val) }
                        isSearchable={true}
                        placeholder={''}
                        value={practiceProfileInfo.selectedsoftware}
                    />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                        <Label for="noOfoperatories">{t('form.noOfoperatories')}</Label>
                      </div>
                      <DropDown 
                          className='react-select-container'
                          classNamePrefix="react-select"
                          options={noOfoperatories}
                          getOptionLabel={ x => `${x.label}`}
                          getOptionValue={ x => `${x.value} ${x.label}`}
                          onChange={(val)=> this.handleChange('selectedoperatories', val) }
                          isSearchable={false}
                          placeholder={''}
                          value={practiceProfileInfo.selectedoperatories}
                      />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                        <Label for="source">{t('form.source')}</Label>
                      </div>
                      <DropDown 
                          className='react-select-container'
                          classNamePrefix="react-select"
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          options={this.props.lead_source_types}
                          onChange={(val)=> this.handleChange('selectedsource', val) }
                          isSearchable={false}
                          placeholder={''}
                          value={practiceProfileInfo.selectedsource}
                      />
                  </FormGroup>
                  {/* 
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="customerType">{t('form.distributor')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        options={distributor}
                        onChange={(val)=> this.handleChange('distributor', val) }
                        isSearchable={true}
                        placeholder={''}
                        defaultValue={practiceProfileInfo.selecteddistributor || ''}
                    />
                    {console.log(practiceProfileInfo,'practiceProfileInfo')}
                    {/* <InputField type="text" label={t('form.distributor')} name="distributor" defaultValue={values.distributor}  onChange={(e) => this.props.handleChange('distributor', e)} /> * /}
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.salesRepresentative')} value={practiceProfileInfo.additional_info.sales_executive} name="sales_executive" onChange={(e) => this.handleChange('sales_executive', e.target.value)}/>
                  </FormGroup> */}
                  {/* <FormGroup>
                    <InputField type="text" label={t('form.distributor')} value={practiceProfileInfo.additional_info.distributor_name} name="distributor_name" onChange={(e) => this.handleChange('distributor_name', e.target.value)} />
                  </FormGroup> */}
                  
                </Col>
                <Col md={6} className="additional_information_right_side">
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="customerType">{t('form.NoOfTVs')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        options={tv_count}
                        getOptionLabel={ x => `${x.label}`}
                        getOptionValue={ x => `${x.value} ${x.value}`}
                        onChange={(val)=> this.handleChange('selectedtv_count', val) }
                        isSearchable={false}
                        placeholder={''}
                        value={practiceProfileInfo.selectedtv_count}
                    />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                      <Label for="customerType">{t('form.customerType')}</Label>
                      </div>
                      <DropDown 
                          className='react-select-container'
                          classNamePrefix="react-select"
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          options={this.props.practice_customer_types}
                          onChange={(val)=> this.handleChange('selectedpracticetype', val) }
                          isSearchable={false}
                          placeholder={''}
                          value={practiceProfileInfo.selectedpracticetype || ''}
                      />
                  </FormGroup>
                  {/* <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="customerType">{t('form.systemlanguage')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        options={system_language}
                        onChange={(val)=> this.handleChange('systemlanguage', val) }
                        isSearchable={false}
                        placeholder={''}
                        value={practiceProfileInfo.systemlanguage}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="customerType">{t('form.medialanguage')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        options={media_language}
                        onChange={(val)=> this.handleChange('medialanguage', val) }
                        isSearchable={false}
                        placeholder={''}
                        value={practiceProfileInfo.medialanguage}
                    />
                  </FormGroup> */}
                </Col>
              </Row>
              <div className="mt-auto text-center">
                <LoadingButton type="button" size="md" error={this.state.IsError}  label="Save" loading={practiceProfileInfo.isLoading} disabled={!this.state.isSave || practiceProfileInfo.isLoading}   onClick={() => this.SaveAdditionalInfo()} className={`mt-5 mr-3`} color="primary"/>
                {/* <Button type="button" size="md" onClick={() => this.props.onBack()} className="mt-5 mr-3" color="primary">{t('btn.back')}</Button> */}
              </div>
            </Form>
        </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  media_language:state.task.media_language,
  system_language:state.task.system_language,
  practice_softwares: state.task.practice_softwares,
  lead_source_types: state.task.lead_source_types,
  practice_customer_types:state.task.practice_customer_types,
  practiceProfileInfo: state.task.practiceProfileInfo,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AdditionalInfo);