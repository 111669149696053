import Home from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import AddIcon from '@material-ui/icons/Add';
import InitialSetup from '../Help/HelpPage/InitialSetup';
import SignIn from '../Help/HelpPage/SignIn';
import Corporate from '../Help/HelpPage/Corporate';
import Updates from '../Help/HelpPage/Update';
import ProfileAuthorization from '../Help/HelpPage/ProfileAuthorized';
import Devices from '../Help/HelpPage/Devices';
import Languages from '../Help/HelpPage/Language';
import MyProfile from '../Help/HelpPage/MyProfile';
import ChairsideEducation from '../Help/HelpPage/ChairsideEducation';
import WaitingRoom from '../Help/HelpPage/WaitingRoom';
import SetupIcon from '../../../src/img/help/Search.svg';
import Refresh from '../../../src/img/help/refresh.svg';
import User from '../../../src/img/help/user.svg';
import Topbar from '../../../src/img/help/topbar.svg';
import Education from '../../../src/img/help/chairside-education.svg';
import WaitingRoomIcon from '../../../src/img/help/waiting-room.svg';
import Operatory from '../../../src/img/help/operatory.svg';
import WebsiteVideos from '../../../src/img/help/website-videos.svg';
import OperatoryRoom from './HelpPage/Operatory';
import WebsiteVideosRoom from './HelpPage/WebsiteVideos';

export const tabList = [
  {
    name : 'Setup & Sign in',
    icon : <img src={SetupIcon} />,
    children: [
      {
        "name": "Initial Setup",
        "url": <InitialSetup />
      },
      {
        "name": "Sign In",
        "url": <SignIn />
      },
      {
        "name": "Corporate",
        "url": <Corporate />
      }
    ]
  },
  {
    name : 'Updates',
    icon :  <img src={Refresh} />,
    children: [
      {
        "name": "Updates",
        "url": <Updates/>
      }
    ]
  },
  {
    name : 'Profiles & Authorized Users',
    icon :  <img src={User} />,
    children: [
      {
        "name": "Profiles & Authorized Users",
        "url": <ProfileAuthorization />
      }
    ]
  },
  {
    name : 'Top Bar',
    icon :   <img src={Topbar} />,
    children: [
      {
        "name": "Devices",
        "url": <Devices/>
      },
      {
        "name": "Language",
        "url": <Languages/>
      },
      {
        "name": "My profile",
        "url": <MyProfile />
      }
    ]
  },
  {
    name : 'Chairside Education',
    icon :   <img src={Education} />,
    children: [
      {
        "name": "Chairside Education",
        "url": <ChairsideEducation />
        // "url": <WaitingRoom/>
      }
    ]
  },
  {
    name : 'Waiting Room',
    icon :  <img src={WaitingRoomIcon} />,
    children: [
      {
        "name": "Waiting Room",
        "url": <WaitingRoom/>
      }
    ]
  },
  {
    name : 'Operatory Room',
    icon : <img src={Operatory} />,
    children: [
      {
        "name": "Operatory Room",
        "url": <OperatoryRoom/>
      }
    ]
  },
  {
    name : 'Website Videos',
    icon :   <img src={WebsiteVideos} />,
    children: [
      {
        "name": "Website Videos",
        "url": <WebsiteVideosRoom/>
      }
    ]
  }
]



export const HelptabItems = [
  {
    name : 'Setup & Sign in',
    component : <InitialSetup />,
    children: [
        {
            name:'Initial Setup',
            component : <InitialSetup />
        },
        {
            name:'Sign In',
            component : <SignIn />
        },
        {
            component : <InitialSetup />
        }
      ]
  },
  {
    name : 'reports',
    component : <SignIn/>
  },
  {
    name : 'Corporate',
    component :<Corporate />
  },
  {
    name : 'Updates',
    component :<Updates/>
  },
  {
    name : 'Profiles & Authorized Users',
    component :<ProfileAuthorization />
  },
  {
    name : 'Devices',
    component :<Devices/>
  },
  {
    name : 'Language',
    component :<Languages/>
  },
  {
    name : 'My Profile',
    component :<MyProfile />
  }
]