import React, { useState } from 'react'
import { history } from '../../pages/index';
import Skeleton from "@material-ui/lab/Skeleton";



const ImageLoad = ({data , src, alt, isChairsidePlayList}) => {
    const [imageLoaded, setImageLoaded] = useState(false);


    const isImageLoaded = (val) =>{
        setImageLoaded(val)
    }

  return (
      <>
      {!imageLoaded &&
        <div style={{position:'absolute',top:'0',left:'0'}}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={333}
                height={266}
            />
        </div>
      }
        <img src={src} alt={alt} className={`${imageLoaded ? 'd-block' : 'd-none'}`} onLoad={() => isImageLoaded(true)} onClick={() => history.push({pathname: '/channel-detail', state: { channel: data, isChairsidePlayList:isChairsidePlayList }})}/>
      </>
  )
}

export default ImageLoad
