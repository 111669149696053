/** @format */

import React, { Component, useState, useEffect } from 'react';
import { Row, Col, Button, Progress, Label } from 'reactstrap';
import FileUpload from '../FileUpload/uploadMultipleFiles';
import LoadingButton from '../LoadingButton';
import DropDown from '../DropDown';
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { InputField } from '../InputField';
import HttpUtility, { WAITINGROOMMEDIA } from '../../utilities/HttpUtility';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import uuid from "react-uuid";
import { uploadWaitingRoomTimelineMyVideos, newWaitingRoomTimelineEntertainment, newWaitingRoomTimelineMyVideos, uploadOperatoryRoomTimelineMyVideos, getOperatoryVideos } from '../../store/actions/taskAction';
import { YOUTUBESERVICEPROVIDER, VIDEOSERVICEPROVIDER, OPERATORYMYVIDEO } from '../../data/comman';
const validTypes = ['video/mp4', 'video/mov', 'video/wmv', 'video/avi', 'video/quicktime', 'video/x-ms-wmv'];
const maxFileSize = 10 * 1024 * 1024 * 1024;
var VideoUPloadUrl = WAITINGROOMMEDIA;

var uploadedVideo = 0;
class WaitingRoomTimelineVideoUpload extends Component {

    constructor(props) {
        super(props)
        this.state = {
            files: [],
            selectedFiles: [],
            selectedCategory: null,
            myVideosTimeline: [],
            drag: false,
            isLoading: false,
            isUpload: false,
            url: "",
            isAdd: true,
            urlError: '',
            isAddLoading: false,
            practiceUserLoginInfo: props.practiceUserLoginInfo,
            buttonLoading: false,
            showModal: false,
        }
        this.onChange = this.onChange.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onUploadMyVideo = this.onUploadMyVideo.bind(this);
        this.uploadVideoCallback = this.uploadVideoCallback.bind(this);
    }
    // const [selectedVideos, setSelectedVideos] = useState(myVideos);
    // const [files, setFiles] = useState([]);
    // const [selectedFiles, setSelectedFiles] = useState([]);
    // const [selectedCategory, setSelectedCategory] = useState(null);
    // const [uploadVideoIndex, setUploadVideoIndex] = useState(0);
    // const [count, setCount] =  useState(0);
    // const myVideosTimeline = useSelector((state) => state.task.myVideosTimeline)
    // const dispatch = useDispatch();
    // var VideoUPloadUrl = WAITINGROOMMEDIA;

    componentDidMount() {

    }


    shouldComponentUpdate(nextProps, nextState){
        if(this.state.buttonLoading != nextProps.buttonLoading)
            this.setState({ buttonLoading: nextProps.buttonLoading });
            
        return true;
    }
    // React.useEffect(() => {

    //     console.log('No threshold reached.', files, selectedFiles, uploadVideoIndex);
    //       // upload video
    //    selectedFiles.map((file)=>{
    //         let videoformData = new FormData();
    //         if(file.error == ""){
    //             videoformData.append(`file[${uploadVideoIndex}]`, file);
    //             uploadFiles(videoformData, uploadVideoIndex);
    //             setUploadVideoIndex(uploadVideoIndex + 1)
    //         }
    //     })

    //   }, [files]);

    onChange(e) {
        e.preventDefault();
        var _selectedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        var fileList = [];

        Array.from(_selectedFiles).map((file) => {
            var error = "";
            if (validTypes.indexOf(file.type) === -1) {
                error = "Format not accepted";
            } else if (file.size >= maxFileSize) {
                error = "Max file 50MB"
            }
            fileList.push({
                id: uuid(),
                name: file.name,
                error,
                completed: error ? true : false,
                loaded: 0,
                fileData: file
            });
        })

        if (this.state.files.length > 0) {
            this.setState({ files: [...this.state.files, ...fileList] }, () => {
                this.uploadFileToServer(fileList);
            });
            console.log("old", this.state.files);
        } else {
            this.setState({ files: [...fileList] }, () => {
                this.uploadFileToServer(fileList);
            });
            console.log("new", this.state.files);
        }
    }

    uploadFileToServer(fileList) {
        // var index = this.state.uploadVideoIndex;
        fileList.map((file) => {
            let videoformData = new FormData();
            if (file.error == "") {
                videoformData.append(`file`, file.fileData);
                this.uploadFiles(videoformData, file);
            }
        })
    }

    onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        console.log("drop file", files)
    }

    cancelUpload = (file) => {
        var files = this.state.files;
        var index = this.findVideoId(file);
        if (files[index] && files[index].source) {
            this.state.files[index].source.cancel()
        }
        files.splice(index, 1);
        this.setState({ files }, () => {
            this.validate()
        });
        // setCount(count + 1)
    }

    vieMyViedeo = () => {
        if (this.state.files.length > 0 && !this.state.showModal) {
            this.setState({ showModal: true });
        } else {
            this.props.onVideoClick();
        }
    }

    onUploadProgress(progressEvent, file) {
        var progressFile = this.state.files;
        var index = this.findVideoId(file);
        if (progressFile[index]) {
            progressFile[index].loaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({ files: progressFile, isUpload: false })
            // setCount(count + 1)
        }
    }

    fileSource = (source, file) => {
        var index = this.findVideoId(file)
        var files = this.state.files;
        if (files[index])
            files[index].source = source;
        this.setState({ files });
    }

    uploadFiles(videoformData, uploadFile) {
        HttpUtility.uploadFileWithProgress(VideoUPloadUrl, videoformData, this.onUploadProgress, this.fileSource, this.state.files, uploadFile).then((response) => {
            // setCount(count + 1)
            if (response.status == 200) {
                var files = this.state.files;
                var index = this.findVideoId(response.uploadfile)
                files[index].completed = true;
                files[index].uploadedFile = response.data;
                this.setState({ files }, () => {
                    this.validate();
                });
            }
            else {

            }
        }).catch(e => {
        })
    }

    onCategorySelect(option) {
        this.setState({ selectedCategory: option }, () => {
            this.validate()
        })
    }

    onUrlChange(url) {
        if (url == "") {
            this.setState({ isAdd: true, url, urlError: '' });
        } else {
            this.setState({ isAdd: false, url, urlError: '' });
        }
    }

    onAddClick() {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = this.state.url.match(regExp);
        if (match && match[2].length == 11) {
            var provider_id = match[2];
            this.setState({ isAddLoading: true }, () => {
                if (provider_id) {
                    HttpUtility.checkYoutubVideo(provider_id).then((response) => {
                        if (response.status == 200) {
                            var yVideoData = response.data;
                            var files = this.state.files;
                            var uploadedFile = response.data;
                            uploadedFile['hashed_id'] = provider_id;
                            files.push({
                                id: uuid(),
                                name: yVideoData.title,
                                error: "",
                                completed: true,
                                loaded: 100,
                                type: "youtube",
                                uploadedFile,
                            });
                            this.setState({ files, url: '', isAddLoading: false, isAdd: true }, () => {
                                this.validate();
                            });
                        } else if(response.status == 401){
                            this.setState({ urlError: "You can't use this URL as embedding is restricted by the video owner.", isAddLoading: false, isAdd: true });
                        }
                        else {
                            this.setState({ urlError: "Enter valid url", isAddLoading: false, isAdd: true });
                        }
                    }).catch((e) => { this.setState({ urlError: "You can't use this URL as embedding is restricted by the video owner.", isAddLoading: false, isAdd: true }); })
                }
            })
        } else {
            this.setState({ urlError: "Enter valid url" })
        }
    }

    onUploadMyVideo() {
        this.setState({ buttonLoading: true, showModal: false }, () => {
            var filesList = this.state.files;
            var param = {};
            var data = []
            filesList.forEach((video) => {
				data.push({
                    video: {
                        provider_id: video.uploadedFile.hashed_id,
                        file_name: video?.name?.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''),
                        service_provider_id: video.type ? YOUTUBESERVICEPROVIDER : VIDEOSERVICEPROVIDER
                    },
                    about: {
                        title: video?.name?.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''),
                        description: '',
                    },
                    media_category: [
                        {
                            id: this.state.selectedCategory.categories_id
                        }
                    ]
				});
			});

            // filesList.map((file) => {
                // if (this.props.type == "operatory") {
                    // param = {
                    //     media_language_id: this.state.practiceUserLoginInfo.account_setting.media_language,
                    //     data: [{
                    //         video: {
                    //             provider_id: file.uploadedFile.hashed_id,
                    //             file_name: file.name,
                    //             service_provider_id: file.type ? YOUTUBESERVICEPROVIDER : VIDEOSERVICEPROVIDER
                    //         },
                    //         about: {
                    //             title: file.name,
                    //             description: '',
                    //         },
                    //         media_category: [
                    //             {
                    //                 id: this.state.selectedCategory.categories_id
                    //             }
                    //         ]
                    //     }]
                    // }

                    // console.log("param ===>", param, file, videos);
                    // console.log("this.props.type", this.props.type);

                    // this.props.dispatch(uploadOperatoryRoomTimelineMyVideos(param, this.uploadVideoCallback));
                // }
                // else {
                //     param = {
                //         media_language_id: this.state.practiceUserLoginInfo.account_setting.media_language,
                //         video: {
                //             provider_id: file.uploadedFile.hashed_id,
                //             file_name: file.name,
                //             service_provider_id: file.type ? YOUTUBESERVICEPROVIDER : VIDEOSERVICEPROVIDER
                //         },
                //         about: {
                //             title: file.name,
                //             description: '',
                //         },
                //         media_category: [
                //             {
                //                 id: this.state.selectedCategory.categories_id
                //             }
                //         ]
                //     }
                //     // this.props.dispatch(uploadWaitingRoomTimelineMyVideos(param, this.uploadVideoCallback));
                // }
            // })
            param={
                media_language_id: this.state.practiceUserLoginInfo.account_setting.media_language,
                data
            }

            console.log(param,'param')
             if (this.props.type == "operatory") {
                this.props.dispatch(uploadOperatoryRoomTimelineMyVideos(param, this.uploadVideoCallback));
            }else{
                this.props.dispatch(uploadWaitingRoomTimelineMyVideos(param, this.uploadVideoCallback));
            }

        })
    }

    validate() { // youtube => provider_id:2
        var completedFile = this.state.files.findIndex(x => x.completed == false);
        if (this.state.selectedCategory != null && completedFile == -1 && this.state.files.length > 0)
            this.setState({ isUpload: true, showModal: false });
        else
            this.setState({ isUpload: false, showModal: false });
    }

    findVideoId(file) {
        return this.state.files.findIndex(x => x.id == file.id)
    }

    uploadVideoCallback() {
        uploadedVideo++;
        if (this.state.files.length == uploadedVideo) {
            if (this.props.type === "operatory") {
                console.log("this.props.type", this.props.type);
                this.props.dispatch(getOperatoryVideos({ mediaType: OPERATORYMYVIDEO }));
                this.setState({ buttonLoading: false });
                this.props.onVideoClick();
            }
            else {
                console.log("this.props.type", this.props.type);
                this.props.dispatch(newWaitingRoomTimelineMyVideos(this.state.practiceUserLoginInfo));
                this.setState({ buttonLoading: false });
                this.props.onVideoClick();
            }
        }else{
            this.setState({ buttonLoading: false });
            this.props.onVideoClick();
        }
    }

    render() {
        return (
            <>
                <Row className="upload-video-sec">
                    <div className="transparent__form--white">
                        <Label className="heading-label">Upload your videos</Label>
                        <div className="fileupload-desc">
                            <Label>To place different videos in different categories, please upload first all videos from one group{this.props.addBreakLine&&<br/>} of videos from the same category, click upload, thwn reapeat the process for another category.</Label>
                        </div>
                        <div className="file_upload__section">
                            <div className="d-flex justify-content-between">


                            </div>
                            <div className="d-flex justify-content-between file-upload-input">
                                <div>
                                    <Label>{this.props.dropDownTitle?this.props.dropDownTitle:'New file upload'}</Label>
                                    <DropDown 
                                        className='react-select-container mxh-200'
                                        classNamePrefix="react-select"
                                        options={this.props.type === "operatory" ? this.props.operatoryRoomMyVideos : this.props.myVideosTimeline}
                                        isSearchable={false}
                                        getOptionLabel={x => `${x.categories_name}`}
                                        getOptionValue={x => `${x.categories_id} ${x.categories_name}`}
                                        placeholder={''}
                                        value={this.state.selectedCategory}
                                        onChange={(option) => { this.onCategorySelect(option) }}
                                    />
                                </div>
                                <div className="d-flex file-upload-input-form">
                                    <div className="file_upload_file_type w-100">Link a video from YouTube</div>
                                    <div style={{width:'80%',paddingRight:'20px'}}>
                                        <InputField type="text" placeholder={'Copy and past the video URL'} className={this.state.urlError ? 'invalid-field' : ''} name="url" value={this.state.url} onChange={(e) => this.onUrlChange(e.target.value)} />
                                        {this.state.urlError && <span className="error--message fs-11 mw-100 m-0">{this.state.urlError}</span>}
                                    </div>
                                    <LoadingButton type="button" label={`ADD`} loading={this.state.isAddLoading} disabled={this.state.isAdd || this.state.isAddLoading} size="md" onClick={() => this.onAddClick()} color="primary" />
                                </div>
                            </div>
                            <div className="text-right subtitle-label">
                                <p>.mp4, .mov, .wmv, .avi   |   Max 1Gb</p>
                            </div>

                            <FileUpload className="fileupload-sec" note={'Uploading another image will replace the current one.'} files={this.state.files} onChange={this.onChange} onDrop={this.onChange} cancelUpload={this.cancelUpload} />
                        </div>
                        <LoadingButton type="button" label={`Upload`} loading={this.state.buttonLoading} disabled={!this.state.isUpload || this.state.buttonLoading} size="md" onClick={this.onUploadMyVideo} color="primary" className="file-upload-btn" />
                        <Button
                            className='videolist__main__button__warning file-upload-btn'
                            color='white'
                            onClick={() => this.vieMyViedeo()}>
                            View My Video
                        </Button>
                    </div>
                    {this.state.showModal && <div className="custom__error d-flex align-items-center justify-content-center">The videos were not added to the library yet</div>}
                </Row>
            </>
        );
    }
};

const mapStateToProps = (state) => ({
    myVideosTimeline: state.task.myVideosTimeline,
    operatoryRoomMyVideos: state.task.operatoryRoomMyVideos,
    practiceUserLoginInfo: state.task.practiceUserLoginInfo,
    buttonLoading: state.task.buttonLoading,
});

export default compose(withTranslation('common'), connect(mapStateToProps))(WaitingRoomTimelineVideoUpload)