import React from 'react';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css";
import { Button , Spinner} from 'reactstrap';

import SubHeader from  '../../components/Header/subHeader'
import LoadingButton from '../../components/LoadingButton'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import {  GetpracticeUser , DeleteMultipalUserProfiles , resetSuccess, saveDefaultProfile} from "../../store/actions/taskAction";

import Profile_icon from '../../img/Profile_icon_new.svg'
import ic_add_to_queue from '../../img/ic_add_to_queue.svg'
import add_new_user_icon from "../../img/add_new_user_icon.svg";

import { history } from '../../pages/index';

var isDefaultProfileSelection = false;
class Index extends React.Component {
  constructor(props) {
    super(props)
    isDefaultProfileSelection = this.props.history.location?.state?.setDefaultProfile ? true : false;
    this.state={
      activeItem: isDefaultProfileSelection ? this.props.practiceInfo?.autologin_user_id : null,
      defaultUserId: isDefaultProfileSelection ? this.props.practiceInfo?.autologin_user_id : null,
      isRemoveProfile:false,
      isRemoveVerify:false,
      SelectedUser:'',
      isError:{
        userType:null
      },
      isSaving: false
    }
    this.toggleAcive = this.toggleAcive.bind(this);
  }

  onNewProfileClick(){
    history.push('/newprofile');
  }
  
  toggleAcive(users, index) {
    // let newSelectedValues = this.state.activeItem;
    // newSelectedValues.push(users.id);
    // this.setState({ 
    //   activeItem:newSelectedValues
    // })
    this.props.dispatch(resetSuccess());
    this.setState({
      activeItem:users.id,
      isRemoveVerify:false
    },()=> {this.isValid(users , index)})
  }

  isValid(users, index){
    let isError = this.state.isError ;

    switch (users.type) {
      case 4:
          isError.userType = "The Owner, Manager, and Authorized User can’t be removed.";
          break;
      case 3:
          isError.userType = "The Owner, Manager, and Authorized User can’t be removed.";
          break;
      case 2:
          isError.userType = "The Owner, Manager, and Authorized User can’t be removed.";
          break;
      case 0:
        isError.userType = "Guest User can’t be removed.";
        break;
      default:
        isError.userType = null;
    }
    this.setState(this.validateForm(users))
  }

  validateForm(users){
    let isError = this.state.isError ;
    if(isError.userType  === null){
      this.setState({
        isError:{
          userType:''
        },
        SelectedUser:users,
        isRemoveProfile:true
      })
    }else{
      this.setState({
        isError,
        isRemoveProfile:false,
        isRemoveVerify:false
      })
    }
  }

async  removeProfile(){
  await  this.props.dispatch(DeleteMultipalUserProfiles(this.state.activeItem));
  await  this.setState({activeItem: null})
  await  setTimeout(() => {
        this.setState({isRemoveVerify:false , isRemoveProfile:false})
    }, 2000);
  }

  isRemoveVerify(){
    this.setState({isRemoveVerify:true})
  }

  onSelectProfile(users){
    this.props.dispatch(resetSuccess());
    this.setState({ activeItem: (this.state.defaultUserId == users.id && this.state.activeItem != null) ? null : users.id, defaultUserId: users.id, isError: { userType : "" } })
  }

  saveChanges(){
    // save default profile changes
    this.setState({ isError:{
      userType: ''
      },
      isSaving: true
    }, async () => {
      var params = {
        status: this.state.activeItem ? 1 : 0,
        user_id: this.state.defaultUserId
      }
      await this.props.dispatch(saveDefaultProfile(params));
      setTimeout(()=>{
        this.setState({ isSaving: false });
      }, 1000)
    });
  }

  async componentDidMount(){
    
    if(this.props.practiceUsersList.length === 0 || this.props.practiceUsersList === false){
      await this.props.dispatch(GetpracticeUser());
    }
    this.props.dispatch(resetSuccess());
  }

  getUserType(id){
    switch(id){
        case 0: 
            return  ["guest", "Guest"];
        case 1:
            return  ["dentist", "Normal User"];
        case 2:
            return  ['authorized_user', "Authorized User"]; 
        case 3:
          return    ['manager', "Manager"]; 
        case 4:
          return    ['owner', "Owner"]; 
        default:
          return    ['guest', "Guest"];
    }
  }

  render (){
    
    const {loading , error , loadingRemoveProfiles, loadingDefault } = this.props
    const practiceUsersList = isDefaultProfileSelection ? this.props.practiceUsersList.filter(x => x.is_secure == 0) : this.props.practiceUsersList;
    const isError = this.state.isError

    // if (loading) {
    //   return <div className="active_profiles__main__screen d-flex flex-column h-100 align-items-center justify-content-center"> 
    //             <Loading />
    //           </div> 
    // }
    
    return(
      <div className="active_profiles__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Profiles"/>
          <div className="d-flex flex-column active_profiles__inner__text w-100 mb-auto">
            <h1 className="mb-5 text-center">Active profiles</h1>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-wrap row-cols-5 justify-content-center active_profiles__section offset-md-2 col-8 p-0">
                {/*.slice(0, 20)*/}
                {this.props.practiceUserLoginInfo && !isDefaultProfileSelection && (this.props.practiceUserLoginInfo.type === 4 || this.props.practiceUserLoginInfo.type === 2) ? 
                  <div className="user__profile text-center mb-5 order"  onClick={() => this.onNewProfileClick()}>
                    <div className="user__profile__box guest d-flex flex-column justify-content-end ">
                    <div className="user__profile__picture">
                      <img className="mx-auto my-auto h-auto" alt="" src={add_new_user_icon}/>
                    </div>
                    </div>
                    <div className="user__profile__footer mb-4  bg-none"></div>
                    <div className="user__profile__name">NEW PROFILE</div>
                  </div>
                :null}
                {practiceUsersList && practiceUsersList.map((users , index) => {
                  var userType = this.getUserType(users.type )
                  console.log(users,'ssss')
                  return <div key={index}  onClick={e => isDefaultProfileSelection ? this.onSelectProfile(users) : this.toggleAcive(users)} className={`user__profile  mb-5 text-center ${userType[0]} `}>
                            <div className={`user__profile__box d-flex flex-column justify-content-end ${userType[0]} ${isError.userType ? 'error' : '' } ${this.state.activeItem === users.id ? 'selected' : 'user__profile__new'} ${userType[0]}`}>
                              <div className="user__profile__picture">
                              { users.profile_pic ?  
                                <img className="mx-auto my-auto" alt={users.first_name} src={ users.profile_pic}/>
                                :
                                <img className="mx-auto my-auto" alt={users.first_name} src={Profile_icon}/>
                              }
                              </div>
                              
                            </div>
                            <div className={`user__profile__footer mb-4 ${userType[0]}`}>
                                {userType[1]}
                              </div>
                            <div className="user__profile__name">{`${users.first_name || ''} ${users.last_name || ''}`}</div>
                            {/* <div className="sm custom-checkbox custom-control ">
                                <input type="radio" name={`theme`} checked={this.state.activeItem === users.id} className="custom-control-input" id={`user${users.id}`} />
                                <label className="custom-control-label p-0" htmlFor={`user${users.id}`}></label>
                            </div> */}
                          </div>
                })}
                
              </div>
              <div className="active_profiles__total col-2 p-0 ml-auto mb-5 pb-5 d-flex flex-column align-items-flex-start justify-content-center">
                <p className="m-0"><span className="fs-50 text--primary">
                  {practiceUsersList && practiceUsersList.length}
                </span>  Total profiles</p>
                <div className="border--bottom mt-5 mb-5"></div>
                {isError.userType && ( <span className="text--danger mb-4 restriction-message">{isError.userType}</span> )}
                {this.state.isRemoveVerify ?
                <>
                  <p className="remove-message">You’re about to remove {`${this.state.SelectedUser.first_name || ''} ${this.state.SelectedUser.last_name || ''}`}. Would you like to proceed?</p>
                  <LoadingButton type="button" label={this.props.SuccessProfileRemove ? 'Profiles removed' : 'Yes, Remove profile(s)'} loading={loadingRemoveProfiles} disabled={!this.state.isRemoveProfile || loadingRemoveProfiles} size="md" onClick={() => this.removeProfile()} className={this.props.SuccessProfileRemove === true ? 'verifyed' : ''} color="red"/>
                </>
                :
                isDefaultProfileSelection ?
                  <LoadingButton type="button" label={this.state.isSaving === true ? "Changes Saved" : "Save changes"} loading={loadingDefault} disabled={loadingDefault} size="md" onClick={() => this.saveChanges()} className={(this.state.isSaving === true && !loadingDefault) ? 'verifyed' : ''} color="primary"/>
                : 
                <Button type="button" onClick={() => this.isRemoveVerify()}   disabled={!this.state.isRemoveProfile } size="md" color="red">
                  Remove profile(s)
                </Button>
                }
              </div>
            </div>
          </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({

  loading: state.task.loading,
  loadingRemoveProfiles:state.task.loadingRemoveProfiles,
  error: state.task.error,
  loadingDefault: state.task.loadingDefault,
  practiceUsersList:state.task.practiceUsersList,
  practice_user_type:state.task.practice_user_type,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  SuccessProfileRemove:state.task.SuccessProfileRemove,
  practiceInfo: state.task.practiceInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);
