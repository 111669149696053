import React, { forwardRef, useCallback, useEffect, useRef } from "react";
import { Grid, Typography, Slider, Tooltip } from "@material-ui/core";
import { Button } from "reactstrap";
import LoadingButton from "../../components/LoadingButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { useSelector, useDispatch } from "react-redux";
import {
  PlayArrow,
  ClosedCaption,
  Maximize,
  Close,
  Pause,
  SkipNext,
  SkipPrevious,
} from "@material-ui/icons";
import { ReactComponent as Fullscreen } from "../../img/FullscreenIcon.svg";
import { ReactComponent as FullscreenExit } from "../../img/ExitfullscreenIcon.svg";
import { ReactComponent as VolumeUp } from "../../img/SpeakerhighIcon.svg";
import { ReactComponent as VolumeDown } from "../../img/SpeakerlowIcon.svg";
import {
  ShowCloseCaptionChairsideEducation,
  HideCloseCaptionChairsideEducation,
  updateChairsidePlaylist,
} from "../../store/actions/taskAction";

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
  },
  ccBtn:{
    // width:'48px',
    // height:'31px',
    // fontSize:'23px',
    // padding: 0,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // lineHeight: '28px',
    // fontWeight: 'normal'
  },
  ccBtnActive:{
    backgroundColor:'#fff',
    color: '#424242 !important'
  },
  controlsSwapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "1",
    width:'100%',
    opacity:0,
    "&:hover": {
      opacity:1,
    },

  },
  controlsTop: {
    position: "absolute",
    top: "-22px",
    left: "0",
    right: "0",
    fontSize: "15px",
    fontWeight: "300",
  },
  topTitle: {
    color: "#fff",
    textTransform: "uppercase",
  },
  controlIcons: {
    color: "#777",
    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#fff",
    fontSize: "30px",
    padding: "0 10px",
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 100,
    height: "70px!important",
    position: "absolute",
    left: "50%",
    bottom: "30px",
    marginLeft: "-10px",
  },
  processSlider: {
    width: 250,
  },
  tabsClass: {
    background: "linear-gradient(#473c39, #3c3533)",
    color: "#8d8885",
    textAlign: "center",
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  active: {
    background: "linear-gradient(#b3afae, #b1aead)",
    color: "#494948",
  },
  verticalSlider: {
    height: "175px",
  },
});
function ValueLabelComponent(props) {
  const { children, open, value } = props;


  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={{ fontSize: "12px" }}
    >
      {children}
    </Tooltip>
  );
}

function VolumeSliderComponent(props) {
  const { children, open, value } = props;
  return (
    <span> {children}</span>
  );
}

const PrettoSlider = withStyles({
  root: {
    height: 3,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    marginTop: -6.5,
    marginLeft: -8,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 3,
    borderRadius: 2,
    backgroundColor: "#009FDF",
  },
  rail: {
    height: 3,
    borderRadius: 2,
    backgroundColor: "rgba(255,255,255,0.4)",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.3)",
  },
})(Slider);

const VolumeSlider = withStyles({
  root: {
    width: 3,
  },
  thumb: {
    backgroundColor: "#fff",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    borderRadius: 2,
    backgroundColor: "rgba(255,255,255,0.4)",
    width: 3,
  },
  rail: {
    width: 3,
    borderRadius: 2,
    backgroundColor: "#fff",
  },
})(Slider);

export default forwardRef(
  (
    {
      title,
      handlePlayPause,
      playing,
      muted,
      handleMute,
      subtitle,
      handleVolumeChange,
      handleVolumeSeekDown,
      volume,
      toggleFullScreen,
      played,
      handleSeekChange,
      handleSeekMouseDown,
      handleSeekMouseUp,
      elapsedTime,
      totalDuration,
      handleChangeDisplayFormat,
      onScreenShot,
      handleTogglePip,
      handleClickClose,
      handleClickSubtitle,
      handleVideoSectionSelectedIndex,
      cs_video_section_types,
      seekToSection,
      video,
      isWaitingRoom,
      setNextVideo,
      setPreVideo
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showVolume, setShowVolume] = React.useState(false);
    const dispatch = useDispatch();
    const playPauseRef = useRef(null);
    const handleTabClick = (newValue) => {
      seekToSection(newValue);
    };
    const states = useSelector((state) => state.task);

    //Show CC
    const showCC =(video)=>{
      handleClickSubtitle()
      let params={
        cc_status: 1
      }
      dispatch(updateChairsidePlaylist(states.chairsidePlayVideo?.playlist?.id, params));
    }

    //Hide CC
    const hideCC =(video)=>{
      handleClickSubtitle()
      let params={
        cc_status: 0
      }
        dispatch(updateChairsidePlaylist(states.chairsidePlayVideo?.playlist?.id, params));
    }

    const handleUserKeyPress = useCallback((event) => {
      const { key, keyCode } = event;
      // console.log("keyCode", keyCode);
      if (keyCode == 32) {
        // handlePlayPause();
        if (playPauseRef.current) playPauseRef.current.click();
      }
    }, []);

    useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress);
      return () => {
        window.removeEventListener("keydown", console.log("test"));
      };
    }, [handleUserKeyPress]);

    // Hover on Volume
    const hoverHandler = (type) => {
      setShowVolume(!showVolume);
    };

    return (
      <>
        <div className={classes.controlsSwapper} ref={ref}>
          {/* BOTTOM CONTROLS */}
          <Grid
            container
            direaction="row"
            alignItems="center"
            className="control_section"
            // justifyContent="space-between"
            style={{
              padding: "1.5rem 5rem",
            }}
          >

            <Grid
              item
              container
              justifyContent="space-between"
              className="d-flex align-items-center"
            > 
              <IconButton
                style={{ padding: "0 5px" }}
                className={classes.bottomIcons}
                onClick={setPreVideo}
              >
                <SkipPrevious fontSize="inherit" />
              </IconButton>

              <IconButton
                ref={playPauseRef}
                onClick={handlePlayPause}
                style={{ fontSize: "50px", padding: "0 0px" }}
                className={classes.bottomIcons}
              >
                {playing === false ? (
                  <PlayArrow fontSize="inherit" />
                ) : (
                  <Pause fontSize="inherit" />
                )}
              </IconButton>

              <IconButton
                style={{ padding: "0 5px" }}
                className={classes.bottomIcons}
                onClick={setNextVideo}
              >
                <SkipNext fontSize="inherit" />
              </IconButton>
              <Button
                color="link"
                style={{ color: "#fff", marginRight: 16 }}
                onClick={handleChangeDisplayFormat}
              >
                <Typography style={{ fontSize: "15px" }}>
                  {elapsedTime}
                </Typography>
              </Button>
              <div className="d-flex align-items-center player_processbar" style={{minWidth:'670px'}}>
              <PrettoSlider
                min={0}
                max={100}
                value={played * 100}
                ValueLabelComponent={(props) => (
                  <ValueLabelComponent {...props} value={elapsedTime} />
                )}
                onChange={handleSeekChange}
                onMouseDown={handleSeekMouseDown}
                onChangeCommitted={handleSeekMouseUp}
              />
              </div>
              <Button color="link" style={{ color: "#fff", marginLeft: 16 }}>
                <Typography style={{ fontSize: "15px" }}>
                  {totalDuration}
                </Typography>
              </Button>
                <div className="text-center">
                    <LoadingButton type="button" label="CC"  onClick={()=>{states.chairsidePlaylist.cc_status == 0 ? showCC(video) : hideCC(video) }} color="default white cc-btn" className={`${classes.ccBtn} ${subtitle ? 'active' :  '' } sm`} outline/>
                </div>
              <div
                className=""
                /*onMouseEnter={() => hoverHandler('enter')}*/ style={{
                  position: "relative",
                }}
              >
                <Button
                  color="link"
                  onClick={() => hoverHandler("enter")}
                  className="py-0"
                  style={{ color: "#fff", marginLeft: 10 }}
                >
                  {muted === false ? (
                    <VolumeUp fontSize="inherit" />
                  ) : (
                    <VolumeDown fontSize="inherit" />
                  )}
                </Button>
                {showVolume && (
                  <VolumeSlider
                    min={0}
                    max={100}
                    value={volume * 100}
                    onChange={handleVolumeChange}
                    onChangeCommitted={handleVolumeSeekDown}
                    className={`zoom_button_on_smallscreen ${classes.volumeSlider}`}
                    orientation="vertical"
                    // onMouseLeave={() => hoverHandler('leave')}
                    ValueLabelComponent={(props) => (
                      <VolumeSliderComponent {...props} value={volume} />
                    )}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
);
