import React from 'react';
import './style.scss';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import CreditCardDeclined from '../../components/CreditCardDeclined'
import Loading from '../../components/Loading'

class PaymentInfo extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
    };
  }

  async componentDidMount(){
   
  }

  render (){
    const {t , loadingBillingCardInfo} = this.props
    return(
      <div className="credit_card_declined__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
         <SubHeader className="mb-auto" title="Payment Options"/>
          <div className="credit_card_declined__inner__screen mb-auto">
              <div className="d-flex flex-column text-center  text-center">
                  <CreditCardDeclined/>
              </div>
          </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingBillingCardInfo:state.task.loadingBillingCardInfo,

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(PaymentInfo);

