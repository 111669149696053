import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import StripeIcon from '../../../img/stripe-icon.png'
import { Button } from 'reactstrap';
import { history } from '../../../pages/index';

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    };
  }

  onSubscribe(){
    history.push('/Subscribe');
  }
 

  render (){
    return(
        <div className={` transparent__form--white billingcard__inner__panel d-flex flex-column`} > {/* for screen 173,174,175 add class bg-danger */}
          <img src={StripeIcon} className="stripe-logo" alt=""/>
          <div className="billingcard__inner__panel__header d-flex flex-column">
            <h4>Credit card</h4>
            <p>Secondary credit cards are charged if a primary card is declined. Adding a Visa, MasterCard, Amex or Discover as a secondary card will replace any previously stored secondary credit card.</p>
          </div>
          <div className="billingcard__inner__panel__body secondary-cc-add">
            <p>Once subscribed, the credit card<br/> information is displayed here.</p>
          </div>
          <div className="billingcard__inner__panel__footer mt-auto">
            <div className=""><Button className="w-100" onClick={() => this.onSubscribe()}  color="primary">Subscribe to view your billing information</Button></div>
          </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
