import React from 'react';
import { Button ,   Spinner} from 'reactstrap';

class Loading extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
      }
  render (){
    const { loading, disabled, error , value , type , onClick , onMouseLeave , onMouseEnter, size , className , color , label , label2 , outline, children} = this.props;
    return(
        <Button type={type} disabled={disabled} onClick={onClick} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} size={size} outline={outline} className={className} color={color}>
            {loading ? 
              <Spinner className="" size="md" color="dark" /> 
              : 
              <>
                {label}
                {children}
              </> 
            }
            
        </Button>
    ) 
  }
}
export default Loading;
