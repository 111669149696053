import React, { useEffect } from 'react'
import './style.scss';
import styles from './style.module.scss';
import TopBanner from './TopBanner';
import WaitingRoom from './WaitingRoom';
import OperatoryRoom from './OperatoryRoom';
import { useDispatch } from 'react-redux';
import { setGoogleTvTestDriveMenuItem } from '../../../store/actions/taskAction';




const HomeScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGoogleTvTestDriveMenuItem(1));
  }, []); 

  return (
    <div className={`${styles.goolge_tv_test_drive__main__screen} d-flex flex-column h-100 goolge_tv_test_drive__main__screen`}>
        <TopBanner />
        <div className={`d-flex flex-grow-1`}>
            <WaitingRoom />
            <OperatoryRoom />
        </div>
    </div>
  )
}

export default HomeScreen
