import React, { useRef, useState, useEffect } from 'react';
import { setToken , setSystemLanguage } from '../../helper/LocalStorage';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import common_fr from "../../translations/fr/common.json";
import common_en from "../../translations/en/common.json";
import i18n from "i18next";
import { GetPracticeMoreInfo , clearerror , GetUserProfile , GetAuthorizedUser} from "../../store/actions/taskAction";

import './style.scss';
import { Button, Progress } from 'reactstrap';


const PreviewModal = ({ url, html, isOpen, toggle}) => {
  const states = useSelector(state => state.task);
  const [openOverly, setShowOverly] = useState(false);
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();

  const history = useHistory();




  useEffect(() => {
    // latLongAddress()
  }, []);

  const onHomeClick=() =>{
    if(states.practiceInfo.is_corporate == 1){
      history.push('/corporate')
    }else{
      history.push('/channels')
    }
  }
  

 return (
    <div className="signin__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="" title="Purchase Hardware  |  Corporation"/>

      <div className="welcome__box purchaseCompleted_box transparent__form--white  md d-flex flex-column align-items-stretch mb-auto">
            <div className="h-100 d-flex flex-column align-items-center text-center">
                <div className="purchaseCompleted_box_info">
                    <h4 className='mb-4'>Hardware purchase completed</h4>
                    <p>Your order(s) have been placed. Please allow a<br/> few days for the order to be processed and<br/> shipped by the manufacturer.</p>
                </div>
                <Button type='button' onClick={()=> onHomeClick()} color='primary' className='w-100 mt-auto'>Back to home page</Button>
            </div>
        </div>
    </div>
  );
};

export default PreviewModal;
