import React, { useEffect, useState, useRef }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './styles.scss';
import { Row, Col, Form, Input, Button } from 'reactstrap';
import  WaitingRoomSlideCategory from '../../components/WaitingRoomTimelineSlides/WaitingRoomSlideCategory';
import WaitingRoomSlideList from '../../components/WaitingRoomTimelineSlides/WaitingRoomSlideList';
import WaitingRoomTimelineSlideCategory from '../../components/NewWaitingRoomTimelineSlide/WaitingRoomTimelineSlideCategory';
import AddWaitingRoomTimelineSlides from '../../components/NewWaitingRoomTimelineSlide/AddWaitingRoomTimelineSlides';
import { NONEANIM , TIMELINESLIDETYPE} from '../../data/comman';

const WaitingRoomTimelineSlides = ({waitingRoomChannelSlides ,isHideBar, timeLineList, onPublish, isHideBarTrue }) => {
    const childRef = useRef();
    const [selectedSlide, setSelectedSlide] = useState([]);
    const [slideCount, setSlideCount] = useState(0);
    const [newSlideSlide, setNewSlideSlide] = useState(false);
    const [slideTrasitions, onSelectTrasition] = useState(NONEANIM);
    const [onSelectSingleSlide, setOnSelectSingleSlide] = useState({});
    const state = useSelector((state) => state.task);
    
    const setSlides = (slides) => {
		setSelectedSlide(slides);
    };
    
    const onNewSlideClick = () => {
        setOnSelectSingleSlide({});
        setNewSlideSlide(!newSlideSlide);
        onSelectTrasition(NONEANIM)
        isHideBar(true)
    }

    
    const getSlideContent = () => {
        return childRef.current.getSlideContent();
    }

    const onEditSlide = (slide) => {
        setNewSlideSlide(true);
        setOnSelectSingleSlide(slide);
        onSelectTrasition(slide.settings.transition);
        isHideBar(true)
    }

    useEffect(() => {
        const arr = [];
        const slides = [];
        const slideArray = [];
        var totalSlide = 0;
        console.log(selectedSlide,'asdasd')
        selectedSlide.forEach((slide) => {
            var isAdded = timeLineList.filter(x => x.media_id == slide.id && x.media_type_id == TIMELINESLIDETYPE);
            slideArray.push({
                ...slide,
                isAdded,
            });
        });
        setSelectedSlide(slideArray);
    }, [timeLineList]);
    useEffect(() => {
        setNewSlideSlide(isHideBarTrue);
    }, [isHideBarTrue]);

    useEffect(() => {
        const arr = [];
        const slideArray = [];
        state.waitingRoomChannelSlides.forEach((element) => {
            arr.push({
                id: element.id,
                title: element.title,
                selected: false,
                slideLength: element.get_slide.length,
                slides: element.get_slide,
            });

            element.get_slide.forEach((slide) => {
                var isAdded = timeLineList.filter(x => x.media_id == slide.id && x.media_type_id == TIMELINESLIDETYPE);
				slideArray.push({
					...slide,
					category_name: element.title,
                    selected: false,
                    isAdded,
				});
			});
        });
        setSelectedSlide(slideArray);
    }, []);

    useEffect(()=>{
        isHideBar(false)
    }, [])

    return (
        <>
            {newSlideSlide ? (
            <div className="d-flex slide-editing">
                <div className="slide_leftsidebar">
                    <WaitingRoomTimelineSlideCategory
                        onNewSlideClick={onNewSlideClick} 
                        getSlideContent={getSlideContent}
                        slideTrasitions={slideTrasitions}
                        isHideBar={isHideBar}
                        onSelectSingleSlide={onSelectSingleSlide}/>
                        
                </div>
                <div className="slide_rightsidebar">
                    <AddWaitingRoomTimelineSlides
                        onSelectTrasition={onSelectTrasition}
                        slideTrasitions={slideTrasitions}
                        onSelectSingleSlide={onSelectSingleSlide}
                        ref={childRef}/>
                </div>
            </div>
        ) : (<div className="waitiing-room-col-sec">
				<div className="waiting-room-left-sec">
					<WaitingRoomSlideCategory 
                        setSelectedSlide={setSlides} 
                        timeLineList={timeLineList}
                        onNewSlideClick={onNewSlideClick} 
                        isHideBar={isHideBar}
                        onPublish={onPublish}
                    />
				</div>
				<div className="slide_rightsidebar waiting-rom-right-sec">
                    <WaitingRoomSlideList  
                        slides={selectedSlide}
                        timeLineList={timeLineList}
                        onEditSlide={onEditSlide} 
                        onNewSlideClick={onNewSlideClick}/>
				</div>
			</div>)}
        </>
    )
}

export default WaitingRoomTimelineSlides
