import React, { useState, useEffect } from "react";
import SubHeader from '../../components/Header/subHeader';
import { useDispatch, useSelector } from "react-redux";
import UploadCetegoryImages from "../../components/UploadCetegoryImages";

const WaitingRoomImages = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);


    return (
        <div className='waiting_room_channel__main__screen d-flex flex-column h-100 align-items-center'>
            <SubHeader className='' title={'New Waiting Room Channel'} />
            <UploadCetegoryImages isImage={1} isChannelImageChange={true} isBackButton={true} hideCancelButton={false}/>
        </div>
    );
};

export default WaitingRoomImages;
