import axios from "axios";
import { authHeader } from "./AuthHeader";
import { baseUrl } from "./BaseUrl";
export const SC = {
  getOperatoryScheduleCall,
  getWaitingRoomScheduleCall
};

function getOperatoryScheduleCall(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return axios
    .get(baseUrl + url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}
function getWaitingRoomScheduleCall(url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return axios
    .get(baseUrl + url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}
