import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button  , FormGroup, Form } from 'reactstrap';
import ReactCodeInput from 'react-code-input';

import BillingProfileImg from '../../img/BillingProfileAvtar.png'
import {GetUserProfileVerify, GetUserIdentify, cleardata} from "../../store/actions/taskAction";
import { history } from '../../pages/index';
import LoadingButton from '../../components/LoadingButton'

import './style.scss';
import PhoneVerify from '../phoneVerify';

class WelcomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      isVerify:false,
      isVerifyPhone:false,
      verificationCode:'',
      otp_media:'',
      isBtnEnabel:false,
      error:'',
      minutes: 5,
      seconds: 0,
      time: 0,
      isGetCode: false
    }
    this.toggleShow = this.toggleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.verifyedPhoneOrEmail = this.verifyedPhoneOrEmail.bind(this);
    this.onClickVerifyOtp = this.onClickVerifyOtp.bind(this);
  }
  
  handleChange(field, options ) {

    if (!/[0-9]/.test(options)) {

      this.setState({error: 'Please Input Number Only'})

    }else{
      this.setState({
        verificationCode:{
            [field]: options,
        }
      },()=> {this.isValid(options)});
    }
    
  };

  isValid(options){
    if(options.length == 4){
      if (!isNaN(options)) {
        this.setState({isBtnEnabel:true, error:''})
      }
    }else{
      this.setState({isBtnEnabel:false})
    }
  }


  getUserType(id){
    switch(id){
        case 0: 
            return  ["guest", "Guest"];
        case 1:
            return  ["dentist", "Normal User"];
        case 2:
            return  ['authorized_user', "Authorized User"]; 
        case 3:
            return    ['manager', "Manager"]; 
        case 4:
            return    ['owner', "Owner"]; 
        default:
          return    ['guest', "Guest"];
    }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  async onClickGetUserIdentify(otp_media){
    if(otp_media == 'sms' ? this.props.practiceUserAccountInfo?.is_verified_mobile == 1 : this.props.practiceUserAccountInfo?.is_verified_email == 1){
      clearInterval(this.myInterval)
      await this.setState({isVerify:true, otp_media:otp_media, seconds: 59})
      await this.resend()
      await this.props.dispatch(GetUserIdentify(otp_media ));
    }else{
        this.setState({isVerifyPhone:true, otp_media:otp_media})
    }

  }

  async  verifyedPhoneOrEmail(){
    clearInterval(this.myInterval)
    await this.setState({isVerify:true, seconds: 59, isVerifyPhone:false})
    await this.resend()
    await this.props.dispatch(GetUserIdentify(this.state.otp_media ));
  }
  
  onClickVerifyOtp(e){
    e.preventDefault();
    this.props.dispatch(GetUserProfileVerify(this.props.userIdentifyInfo , this.state.verificationCode , this.props.pageUrl));
  }

 async componentDidMount(){

    if(this.props.practiceInfo.is_super_admin === 1){
      history.replace('/settings');
      history.push(this.props.pageUrl)
    }
    this.props.dispatch(cleardata());
  }

  resend() {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
          isGetCode: false
        }))
      } else {
        this.setState({ isGetCode: true })
      }
      if (this.seconds === 0) {
        clearInterval(this.myInterval)
      }

    }, 1000)
  }

  async onResendCode(otp_media) {
    clearInterval(this.myInterval)
    this.props.dispatch(GetUserIdentify(otp_media ));
    this.setState({otp_media:otp_media})
    await this.setState({seconds: 59 })
    await this.resend()
  }


  render (){
      const {practiceUserLoginInfo } = this.props

    if(practiceUserLoginInfo){
     var userType = this.getUserType(practiceUserLoginInfo.type )
    }

    return(
      <div className="verify_identity_box transparent__form--white mb-auto">
        {this.state.isVerifyPhone ?
        <div className="h-100 d-flex flex-column">
          <div className=" text-center mb-5 verify-heading">
              <h4>Verify your Phone / Email</h4>
          </div>
          <PhoneVerify isFromAuthorized={true} otp_media={this.state.otp_media} verifyedPhoneOrEmail={this.verifyedPhoneOrEmail} values={this.props.practiceUserAccountInfo} buttonLabel="Get a code" isClear={true}/>
          <Button type="button" onClick={() => this.setState({isVerifyPhone:false})} className="w-100 mt-auto" color="primary">Back</Button>
        </div>
        :
        <div className="h-100 d-flex flex-column">
          <div className=" text-center mb-5 verify-heading">
              <h4>Verify your identity</h4>
              <p className="mb-0">A verification code sent via SMS text, email, or phone call is required in order to proceed.</p>
          </div>
          <div className={`user__profile  mx-auto mb-5 ${userType[0]}`}>
            <div className={`user__profile__box d-flex flex-column justify-content-end mb-3 ${userType[0]}`}>
              <div className="user__profile__picture">
              { practiceUserLoginInfo.profile_pic ?  
                <img className="mx-auto my-auto" alt={practiceUserLoginInfo.first_name} src={ practiceUserLoginInfo.profile_pic}/>
                :
                <img className="mx-auto my-auto" alt={practiceUserLoginInfo.first_name} src={BillingProfileImg}/>
              }
              </div>
              <div className={`user__profile__footer py-2  text-center ${userType[0]}`}>
                {userType[1]}
              </div>
            </div>
            <div className="user__profile__name text-center">{`${practiceUserLoginInfo.first_name || ''}  ${practiceUserLoginInfo.last_name || ''}`}</div>
          </div>
          <div className="mt-auto send-code">
          {!this.state.isVerify ?
            <>
              <p className=" text-center">Send the verification code via</p>
              <div className="row">
                <div className="col-6"><Button type="button" onClick={() => this.onClickGetUserIdentify('sms')} size="md" color="primary">Mobile SMS</Button></div>
                <div className="col-6"><Button type="button" onClick={() => this.onClickGetUserIdentify('email')} size="md" color="primary">Email</Button></div>
                {/* <div className="col-12 w-100 mt-4"><Button className="w-100" onClick={() => this.onClickGetUserIdentify('phone')}  color="primary">Phone call</Button></div> */}
              </div>
            </>
            :
            <Form>
                  <FormGroup className={`${this.props.userProfileVerifyInfo === false ?  'notverifyed' : ''} entercode mx-auto`}>
                    <div className="d-flex justify-content-between">
                        <label>Enter the verification code </label><Button onClick={this.toggleShow} color="link primary" className="p-0 text-uppercase">{this.state.hidden ? 'SHOW' : 'Hide'}</Button>
                    </div>
                    <ReactCodeInput  type={this.state.hidden ? 'password' : 'number'} onChange={(options) => this.handleChange('verificationCode',options)}   fields={4} className="mb-2"/>
                    {this.state.error && <span className='text--danger'>{this.state.error}</span>}

                </FormGroup>
                <FormGroup className="row flex-column align-items-center justify-content-center">
                  {/* onClick={() => this.onClickGetUserIdentify(this.state.otp_media)} */}
                    <span>Didn’t receive your code? <Button type="button" style={{height:'auto'}} disabled={!this.state.isGetCode} onClick={() => this.onResendCode(this.state.otp_media)}   color="link primary" className="p-0 ml-2">Click here to resend. {this.state.seconds !== 0 && `(${this.state.seconds})` + 's'}</Button></span>
                    <span className="text-nowrap">Need to update your email or mobile no.?<Button type="button"  onClick={(e)=> history.push("/admin-profile-update")} color="link primary" className="p-0 ml-2"> Click here.</Button></span>
                </FormGroup>
                <FormGroup className="m-0">
                  <LoadingButton type="submit" size="xl" disabled={!this.state.isBtnEnabel || this.props.buttonLoading} loading={this.props.buttonLoading} className={`mx-auto d-block ${this.props.userProfileVerifyInfo === true ? 'verifyed' : ''} ${this.props.userProfileVerifyInfo === false ?  'notverifyed' : ''} w-100`}  
                      onClick={(e) => this.onClickVerifyOtp(e)}  color="primary">
                      {
                          this.props.userProfileVerifyInfo === false ?
                          <>Incorrect verification code</>
                      :
                      <>
                      {this.props.userProfileVerifyInfo === true ?
                          <>Phone number verified</>
                      :
                          <>Next</>
                      }
                      </>
                      }
                  </LoadingButton>
                </FormGroup>
            </Form>
          }
          </div>
        </div>
      }
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    loading: state.task.loading,
    error: state.task.error,
    practiceUserLoginInfo:state.task.practiceUserLoginInfo,
    practiceUserAccountInfo:state.task.practiceUserAccountInfo,
    userIdentifyInfo:state.task.userIdentifyInfo,
    userProfileVerifyInfo:state.task.userProfileVerifyInfo,
    practiceInfo:state.task.practiceInfo,
    buttonLoading:state.task.buttonLoading
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(WelcomeIndex);
