/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Card, CardBody, Button } from 'reactstrap';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LoadingButton from '../../../components/LoadingButton'
const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomTimelineEntertainmentCategories = ({ specialization, setVideos, timeLineList, setModal, onPublish }) => {
	const state = useSelector((state) => state.task);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [allVideosIndex, setAllVideosIndex] = useState(true);
	const [categoryId, setCategoryId] = useState(null);

	const [isOpen, setIsOpen] = useState(false);
	const [allVideosCount, setAllVideosCount] = useState(0);
	const [count, setCount] = useState(0);
	const toggle = () => setIsOpen(!isOpen);
	const [subCat, setSubCat] = useState(false);
	const [subCategory, setSubCategory] = useState([]);
	const [allVideos, setAllVideos] = useState([]);
	const [filteredCategory, setFilteredCategory] = useState([]);
	
	useEffect(() => {
		var categoryArray = [];
		var subCategoryArray = [];
		var totalCount = 0;
		var subCategoriesVideosCount = 0;
		specialization.forEach((category) => {
			categoryArray.push({
				category_id: category.categories_id,
				category_name: category.categories_name,
				mainCategorySelected: false,
				mainCategoryVideos: category.video,
				mainCategoryVideosLength: category.video.length,
				down: false,
				subCategories: category.sub_categories,
			});
			category.sub_categories.forEach((subCategory) => {
				subCategoryArray.push({
					subCategories: category.sub_categories,
					subCategoriesSelected: false,
					subCategoryVideos: subCategory.video,
					subCategoryVideosCount: subCategory.video.length,
				});
				subCategoriesVideosCount += subCategory.video.length;
			});
			totalCount += category.video.length + subCategoriesVideosCount;
		});
		setCategories(categoryArray);
		setSubCategories(subCategoryArray);
		setAllVideosCount(totalCount);
	}, [specialization]);

	// OnCategoryClick
	const onCategoryClick = (item) => {
		setAllVideosIndex(false);
		setCount(count + 1);
		const videosArray = [];
		categories.forEach((element) => {
			if (element.category_id === item.category_id) {
				element.mainCategorySelected = !item.mainCategorySelected;
			}
		});
		categories.forEach((element) => {
			console.log(element,'element')
			if (element.mainCategorySelected === true) {
				element.mainCategoryVideos.forEach((video) => {
					var isAdded = timeLineList.filter(x => x.media_id == video.operatory_media_id);
					videosArray.push({
						...video,
						category_name: element.category_name,
						category_id: element.categories_id,
						mainCategorySelected: true,
						isAdded
					});
				});
			}
		});

		// select all
		var isAllSelectCat = categories.filter((x) => x.mainCategorySelected);
		setAllVideosIndex(categories.length == isAllSelectCat.length);
		setVideos(videosArray);
	};
	// OnSubCategoryClick
	const onSubCategoryClick = (subCategory, index) => {
		setSubCat(!subCat);
		setAllVideosIndex(false);
		subCategory.selected = !subCategory.selected;
		const arr = [];
		//setCount(count + 1);
		categories.forEach((element) => {
			element.subCategories.map((subCat) => {
				if (subCat.selected === true) {
					subCat.video.forEach((video) => {
						var isAdded = timeLineList.filter(x => x.media_id === video.operatory_media_id);
						arr.push({
							...video,
							category_name: subCat.categories_name,
							selected: true,
							isAdded
						});
					});
				}
			})
		})

		// select sub cat
		var subCategoryisAllSelectCat = categories[index].subCategories.filter((x) => x.selected);
		categories[index].mainCategorySelected = subCategoryisAllSelectCat.length === categories[index].subCategories.length;

		// select all
		var isAllSelectCat = categories.filter((x) => x.mainCategorySelected);
		setAllVideosIndex(categories.length === isAllSelectCat.length);

		setSubCategory(subCategory);
		setVideos(arr);
	};
	// Open Sub Categories Collapse
	const openSubCategories = (item) => {
		if (item.category_id === categoryId) {
			setIsOpen(!isOpen);
		} else {
			setIsOpen(true);
		}
		setCategoryId(item.category_id);

		setCount(count + 1);
		categories.forEach((element) => {
			if (element.category_id === item.category_id) {
				element.down = !item.down;
			}
		});
	};
	// On Selecting Show All Entertainment Videos
	const changeAllVideos = () => {
		setAllVideosIndex(true);
		setCount(count + 1);
		const videosArray = [];
		// console.log("specialization -- > ", specialization)
		specialization.forEach((element) => {
			// element.video.forEach((video) => {});
			// element.sub_categories.forEach((datum) => {
			element.video.forEach((subCategoryVideo) => {
				//console.log("subCategoryVideo in WaitingRoomTimelineEntertainment : ", subCategoryVideo)
				var isAdded = timeLineList.filter(x => x.media_id === subCategoryVideo.operatory_media_id);
				videosArray.push({
					...subCategoryVideo,
					category_name: element.categories_name,
					selected: false,
					isAdded,
				});
			});
			// });
		});
		setVideos(videosArray);
		subCategory.selected = false;
	};
	const allVideoListActive = allVideosIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
	return (
		<>
			<div className='videolist__main__screen opratory-left-sec h-100 d-flex flex-column'>
				<div className='videolist__main__lists'>
					<div className='videolist__main__header'>
						<span className='videolist__main__header__header-title d-block mb-4'>Topics</span>
					</div>
					<div className='videolist__submain__header'>
						<img
							src={allVideoListActive}
							alt=''
							onClick={() => changeAllVideos()}
							className='videolist__submain__header__checkbox'
						/>
						&nbsp; &nbsp; &nbsp;
						<span className='videolist__submain__header__title'>
							Show all Entertainment videos {`(${allVideosCount})`}
						</span>
					</div>
					<hr className='videolist__main__lists_hr' />
					{categories.length > 0
						? categories.map((item, mainCatIndex) => {
							console.log(item,'item')
							const active =
								item.mainCategorySelected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
							const activeItem =
								item.down === true ? (
									<KeyboardArrowUpIcon fontSize='large' />
								) : (
										<KeyboardArrowDownIcon fontSize='large' />
									);
							// console.log("categories video count", item)
							return (
								<React.Fragment key={mainCatIndex}>
									<div className='videolist__main__lists__videoselection'>
										<img
											src={active}
											alt=''
											onClick={() => onCategoryClick(item)}
											className='videolist__submain__header__checkbox'
										/>
											&nbsp; &nbsp; &nbsp;
											<span
											className='videolist__submain__header__title'
											onClick={() => { }}>
											{item.category_name}
											{`(${item.mainCategoryVideosLength})`}{' '}
											{/* <span>{activeItem}</span> */}
										</span>
									</div>
									{/* {isOpen && categoryId === item.category_id ? (
											<>
												<Collapse
													isOpen={isOpen}
													style={{ marginLeft: '25px' }}>
													{item.subCategories.map((subCategory, index) => {
														const secondActive =
															subCategory.selected === true
																? CLOSEBOXIMAGE
																: WHITEBOXIMAGE;

														return (
															<React.Fragment key={index + 1}>
																<div className='videolist__main__lists__videoselection'>
																	<img
																		src={secondActive}
																		alt=''
																		onClick={() =>
																			onSubCategoryClick(subCategory, mainCatIndex)
																		}
																		className='videolist__submain__header__checkbox'
																	/>
																	&nbsp; &nbsp; &nbsp;
																	<span className='videolist__submain__header__title'>
																		{subCategory.categories_name}{' '}
																		{`(${subCategory.video.length})`}{' '}
																	</span>
																</div>
															</React.Fragment>
														);
													})}
												</Collapse>
											</> 
										) : null} */}
								</React.Fragment>
							);
						})
						: null}
				</div>
				<div className='d-flex justify-content-between mt-auto videolist__main__button'>
					<LoadingButton type="button" label={"Publish timeline"} className="mr-3" loading={state.buttonLoading3} disabled={state.buttonLoading3} size="md" onClick={() => onPublish()} color={'primary'} />
					<Button type="button" className='ml-3' size="md" onClick={() => setModal(true)} color='white' >
						Preview
					</Button>
				</div>
			</div>
		</>
	);
};

export default WaitingRoomTimelineEntertainmentCategories;
