import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import SwiperCore, { Navigation } from 'swiper';
import { Row, Col } from 'reactstrap';
import './layout.css';
import 'swiper/swiper-bundle.css';
import LayoutThemeImageSkeleton from '../Skeletons/LayoutTheme'
import Skeleton from "@material-ui/lab/Skeleton";

SwiperCore.use([Navigation]);

const LayoutScreen = ({ layouts, submitLayouts, activeLayoutId, profile }) => {
	const state = useSelector((state) => state.task);
	let layoutsTwo = [];
	let firstLayoutObject = {
		layout: layouts.slice(0, 4),
	};
	let secondLayoutObject = {
		layout: layouts.slice(4, 8),
	};
	// let thiredLayoutObject = {
	// 	layout: layouts.slice(8, 12),
	// };
	layoutsTwo.push(firstLayoutObject, secondLayoutObject);

	const [activeIndex, setActiveIndex] = useState(activeLayoutId);
	useEffect(() => {
		// submitLayouts(layoutsTwo[0].layout[0]);
	}, []);

	useEffect(() => {
		setActiveIndex(activeLayoutId)
	}, [activeLayoutId])

	const changeButton = (value) => {
		setActiveIndex(value.id);
		submitLayouts(value.id);
	};
	return (
		<div className='layout-container website_videos_layout_selection_layout_slider'>
			<div>
				<h4 className="mb-4" style={{ color: 'white' }}>Layout</h4>
				<p className="mb-2" style={{ color: 'white' }}>
					Select a layout for your website video player, available to any of
					your authorized domains.
				</p>
			</div>
			{state.websiteMediaLayouts && state.websiteMediaLayouts.length > 0 ?
				<Swiper id='swiper-layout' spaceBetween={50} slidesPerView={1} navigation>
					{layoutsTwo.map((layout, index) => {
						return (
							<SwiperSlide key={`slide-${index}`} >
								<Row className='layout-screen'>
									{layout.layout.map((value, index) => {
										const active = activeIndex == value.id ? 'active' : '';
										const activeImage = active
											? 'assets/img/colorsImg/closeicon.png'
											: 'assets/img/colorsImg/whitebox.png';

										const subscription = state.practiceInfo.is_paid_account == 0   && value.is_paid != 0 ? ['subscription-layout-note', 'SUBSCRIPTION REQUIRED'] : '';
										return (
											<Col
												xs='6'
												key={index}
												className={`${subscription[0]}`}
												style={{ cursor: 'pointer' }}>
												<p className="mb-2 text-uppercase" style={{ color: 'white' }}>{value.name}</p>
												<div
													onClick={() => changeButton(value)}
													className={`layout-back-image ${active}`}>

													<img
														className='layout-front-image'
														src={activeImage}
														alt=''
													/>
													
													{/* <img src={`${value.layout_thumb}`} alt='' /> */}

													<LayoutThemeImageSkeleton src={value.layout_thumb} />
												</div>
												<span className='subscription-layout-note'>
													{subscription[1]}
												</span>
											</Col>
										);
									})}
								</Row>
								
							</SwiperSlide>
						);
					})}
				</Swiper>
			:
			<Swiper id='swiper-layout' spaceBetween={50} slidesPerView={1} navigation>
				<SwiperSlide key={`slide-1`} >
					<Row className='layout-screen'>
						<Col xs='6' style={{ cursor: 'pointer' }}>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={100}
								height={20}
								style={{marginBottom:'5px'}}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={165}
								height={97}
								style={{marginBottom:'7px'}}
							/>
						</Col>
						
						<Col xs='6' style={{ cursor: 'pointer' }}>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={100}
								height={20}
								style={{marginBottom:'5px'}}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={165}
								height={97}
								style={{marginBottom:'7px'}}
							/>
						</Col>
						<Col xs='6' style={{ cursor: 'pointer' }}>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={100}
								height={20}
								style={{marginBottom:'5px'}}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={165}
								height={97}
								style={{marginBottom:'7px'}}
							/>
						</Col>
						<Col xs='6' style={{ cursor: 'pointer' }}>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={100}
								height={20}
								style={{marginBottom:'5px'}}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={165}
								height={97}
								style={{marginBottom:'7px'}}
							/>
						</Col>
					</Row>
				</SwiperSlide>
				
			</Swiper>
			}
			
		</div>
	);
};
export default LayoutScreen;