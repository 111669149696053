/** @format */

import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col, Button } from 'reactstrap';
import WaitingRoomTriviaCategory from './WaitingRoomTriviaCategory';
import WaitingRoomTriviaList from './WaitingRoomTriviaList';
import { TRIVIATYPE, TIMELINETRIVIATYPE } from '../../data/comman';

const WaitingRoomTimelineTrivia = ({waitingRoomsChannelTrivia, timeLineList, setModal, onPublish }) => {
  
	const [selectedTrivias, setSelectedTrivias] = useState([]);

	const state = useSelector((state) => state.task);
	const [triviaCount, setTriviaCount] = useState(0);
	const [trivias, setTrivias] = useState([]);

    const setTrivia = (trivias) => {
		setSelectedTrivias(trivias);
	};

	useEffect(() => {
        const arr = [];
        const trivias = [];
        const triviaArray = [];
        var totalTrivia = 0;
        // state.waitingRoomsChannelTrivia.forEach((element) => {
        //     arr.push({
        //         id: element.id,
        //         title: element.title,
        //         selected: false,
        //         triviaLength: element.trivias.length,
        //         trivias: element.trivias,
        //     });

        //     selectedTrivias.forEach((trivia) => {
        //         var isAdded = timeLineList.filter(x => x.media_id == trivia.id && x.media_type_id == TIMELINETRIVIATYPE);
		// 		triviaArray.push({
		// 			...trivia,
		// 			category_name: element.title,
        //             selected: false,
        //             isAdded,
		// 		});
		// 	});

        //     totalTrivia += element.trivias.length;
        // });

		selectedTrivias.forEach((trivia) => {
			var isAdded = timeLineList.filter(x => x.media_id == trivia.id && x.media_type_id == TIMELINETRIVIATYPE);
			triviaArray.push({
				...trivia,
				selected: false,
				isAdded,
			});
		});

        setSelectedTrivias(triviaArray);
    }, [timeLineList]);

	useEffect(() => {
        const arr = [];
        const trivias = [];
        const triviaArray = [];
        var totalTrivia = 0;
        state.waitingRoomsChannelTrivia.forEach((element) => {
            arr.push({
                id: element.id,
                title: element.title,
                selected: false,
                triviaLength: element.trivias.length,
                trivias: element.trivias,
            });

            element.trivias.forEach((trivia) => {
                var isAdded = timeLineList.filter(x => x.media_id == trivia.id && x.media_type_id == TIMELINETRIVIATYPE);
				triviaArray.push({
					...trivia,
					category_name: element.title,
                    selected: false,
                    isAdded,
				});
			});

            totalTrivia += element.trivias.length;
        });
        // setTriviaCount(totalTrivia);
        setSelectedTrivias(triviaArray);
        // setTrivias(arr)
    }, []);


	return (
		<>
			<div className="waitiing-room-col-sec">
				<div className="waiting-room-left-sec">
					<WaitingRoomTriviaCategory
                        setSelectedTrivia={setTrivia} 
						timeLineList={timeLineList}
						setModal={setModal}
						onPublish={onPublish}
						/>
				</div>
				<div className="waiting-rom-right-sec waiting-room-triavia">	
                    <WaitingRoomTriviaList
                        trivias={selectedTrivias}
                        setSelectedTrivia={setTrivia} />
				</div>
			</div>
		</>
		// <div className='page7__main row'>
		// 	<div className='page7__main__div1'>
		// 		<h4>Select specialization(s)</h4>
		// 		<br />

		// 		<div className='page7__main__div1__inputfildDiv'>
		// 			<input type='checkbox' id='1' />
		// 			<label for='1'>Show All trivia</label>
		// 		</div>

		// 		<hr />
		// 		{waitingRoomsChannelTrivia.length > 0
		// 			? waitingRoomsChannelTrivia.map((channelTrivia, index) => {
		// 					return (
		// 						<div className='page7__main__div1__inputfildDiv'>
		// 							<input type='checkbox' id='1' />
		// 							<label for='1'>{channelTrivia.title}</label>
		// 						</div>
		// 					);
		// 			  })
		// 			: null}

		// 		<div className='page7__main__div1__buttonDiv'>
		// 			<Row style={{ marginTop: '-37px' }}>
		// 				<Col lg='6'>
		// 					<Button>SAVE TIMELINE</Button>
		// 				</Col>

		// 				<Col lg='6'>
		// 					<Button>PREVIEW</Button>
		// 				</Col>
		// 			</Row>
		// 		</div>

		// 		<br />
		// 	</div>
		// 	<div className='page7__main__div2'>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?1'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?2'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?3'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?4'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?5'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?6'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?7'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?8'></img>
		// 		</div>
		// 		<div className='page7__main__div2__gridbox'>
		// 			<img src='https://picsum.photos/200?9'></img>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default WaitingRoomTimelineTrivia;
