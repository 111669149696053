import React from 'react';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import IconsArrowLeft from '../../img/arrow_left.svg'
import { InvoiceList, InvoiceViewList, cleardata, getInvoiceEmails, UpdateInvoiceEmail } from "../../store/actions/taskAction";
import './style.scss';
import { Button } from 'reactstrap';
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      per_page: 15,
      totelPage: 0,
      expanded: false,
      showMoreArrow: false,
      selectedShowby: 1,
      curYear: 2021,
      minYear: 2019,
      maxYear: 2023,
      email: "",
      statementEmail: "",
      statementEmailAllow: 0
    }
    this.handlechangePage = this.handlechangePage.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handlechangePage(e, index) {
    index = index + 1

    this.setState({ currentPage: index }, () => {
      let params = {
        page: this.state.currentPage,
      }
      this.props.dispatch(InvoiceList(params))
    });
  }

  handleChange(field, options) {
    var name = "";
    var value = "";

    if (field === "showby") {
      name = field;
      value = options.value;
    } else {
      name = field.target.name;
      value = field.target.value;
    }

    this.setState({
      selectedShowby: {
        [name]: value,
      }
    });
    console.log(value, 'value')
    let params = {
      latest: value
    }
    this.props.dispatch(InvoiceList(params))
  }
  async componentDidMount() {
    let startDate = this.state.curYear + "-01-01"
    let endDate = this.state.curYear + "-12-31"
    let params = {
      start_date: startDate,
      end_date: endDate
    }
    this.props.dispatch(InvoiceList(params));
    this.props.dispatch(getInvoiceEmails());
    this.props.dispatch(cleardata());
    let emailDetail = this.props.getInvoiceEmails.data
    this.setState({ email: emailDetail.email, statementEmail: emailDetail.statement_email, statementEmailAllow: emailDetail.need_statement_on_email })
    // this.setState({ totelPage: this.props.invoiceList.last_page })
  }
  onInvoiceViewClick(invoice) {
    history.push({
      pathname: '/corporate-invoicesview',
      state: { invoice }
    });
    // this.props.dispatch(InvoiceViewList(invoice, null));
  }
  changeYear(type) {
    let curYear = this.state.curYear
    let newYear = type === "prev" ? curYear - 1 : curYear + 1
    if (newYear >= this.state.minYear && newYear <= this.state.maxYear) {
      this.setState({ curYear: newYear })
      let startDate = newYear + "-01-01"
      let endDate = newYear + "-12-31"
      let params = {
        start_date: startDate,
        end_date: endDate
      }
      this.props.dispatch(InvoiceList(params));
    }
  }
  updateEmailDetail() {
    let body = {
      statement_email: this.state.statementEmail,
      need_statement_on_email: this.state.statementEmailAllow
    }
    this.props.dispatch(UpdateInvoiceEmail(body));
  }
  render() {
    const { invoiceList } = this.props
    return (
      <div className="main__screen corporate-invoice__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Invoices  |  Smith Dental - Mile End" />
        <div className="d-flex w-100 mb-auto">
          <div className="corporate-invoice__inner mb-auto mx-auto">
            <div className="corporate-invoice__inner__header">
              <div className="row">
                <div className="col-7">
                  <h4>Invoice history</h4>
                </div>
                <div className="col-5 text-right">
                  <div className="year-change">
                    <div className="year-change-arrow">
                      <a onClick={() => { this.changeYear("prev") }}><img src={IconsArrowLeft} className="arrow-left" /></a>
                      <a onClick={() => { this.changeYear("next") }}><img src={IconsArrowLeft} className="arrow-right" /></a>
                    </div>
                    <h3>{this.state.curYear}</h3>
                  </div>
                </div>
              </div>
            </div>
            {!!invoiceList && !!invoiceList.data && invoiceList.data.length > 0 ?
              <div className="corporate-invoice__inner__body">
                {/* {invoiceList ? <div className="no-invoice-box"><div className="no-invoice"><h2> You haven't been issued an invoice yet. </h2>
                <p>Once subscribed, your first issued invoice will show up on a list.</p></div></div> : */}
                <table className="table" >
                  <thead>
                    <tr>
                      <th width="174">Billed to</th>
                      <th width="190">Invoice No.</th>
                      <th width="153">Issue date</th>
                      <th width="109">Amount</th>
                      <th width="173"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceList && invoiceList.data.map((invoice, index) => {

                      return <tr key={index} >
                        <td  width="174">{invoice.billed_name}</td>
                        <td  width="190">{invoice.total}</td>
                        <td width="153">{invoice.invoice_date}</td>
                        <td width="109">${invoice.total}</td>
                        <td width="173"><Button size="md" color="primary" onClick={() => this.onInvoiceViewClick(invoice)}>View</Button></td>
                      </tr>
                    })}
                  </tbody>
                </table>

              </div> :
              <>
                <div className="corporate-invoice__inner__body">
                  <div className="corporate-invoice__empty flex-column d-flex align-items-center justify-content-center">
                    <h4>You haven't been issued an invoice yet.</h4>
                    <p>Once subscribed, your first issued invoice will show up on a list.</p>
                  </div>
                </div>
              </>}

            <div className="corporate-invoice__inner__footer d-flex justify-content-center align-items-center">
              <ul>
                {[...Array(this.state.totelPage)].map((page, i) =>
                  <li key={i}><Button type="button" className={`p-3 ${i + 1 === this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                )}
              </ul>
            </div>
          </div>
          <div className="corporate-invoice__suggestion d-flex flex-column align-items-flex-start justify-content-center">
            <h4>Receive monthly statements on</h4>
            <div className="form-group mt-4">
              <div className="custom_check">
                <input type="checkbox" name="receive_statements_reg" id="receive_statements_reg" checked />
                <label for="receive_statements_reg">Registered email</label>
              </div>
              <input value={this.state.email} disabled type="email" className="form-control" placeholder="Enter email here" />
            </div>
            <div className="divider"></div>
            <div className="form-group">
              <div className="custom_check">
                <input type="checkbox" onChange={() => { this.setState({ statementEmailAllow: this.state.statementEmailAllow === 0 ? 1 : 0 }) }} checked={this.state.statementEmailAllow === 0 ? false : true} name="receive_statements_other" id="receive_statements_other" />
                <label for="receive_statements_other">Another email</label>
              </div>
              <input value={this.state.statementEmail} onChange={(e) => { this.setState({ statementEmail: e.target.value }) }} type="email" className="form-control" placeholder="Enter email here" />
            </div>
            <Button size="md" color="primary" onClick={() => { this.updateEmailDetail() }}>Save Changes</Button>
          </div>
        </div>
      </div>
    )
  }

}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  invoiceList: state.task.invoiceList,
  getInvoiceEmails: state.task.getInvoiceEmails,
  invoiceMailUpdated: state.task.invoiceMailUpdated
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(HomeIndex);
