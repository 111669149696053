import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { clearStorage } from '../../helper/LocalStorage';

import Loading from '../../components/Loading'

import {PracticeUserLogout } from "../../store/actions/taskAction";


class SignInIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
       
    }
  }

 componentDidMount(){
    this.props.dispatch(PracticeUserLogout(this.props.practiceUserLoginInfo))
  }

  render (){
    const {loading, error } = this.props
    
    // if (loading) {
    //   return <div className="signin__main__screen d-flex flex-column h-100 align-items-center justify-content-center"> 
    //           <Loading />
    //         </div>
    // }
    
    return(
      <div className="signin__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
          
          {error &&
            <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{error.message}</p> </div>
          }
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  error:state.task.error,
  loading: state.task.loading,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(SignInIndex);
