/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getCustomMessage,
	deleteWaitingRoomCustomMessage,
	putChannelwiseCustomMessages,
} from '../../store/actions/taskAction';
import { Button } from 'reactstrap';
import Card from './Card';
import update from 'immutability-helper';
import './styles.scss';
const DragAndDropThree = () => {
	const state = useSelector((state) => state.task);
	const dispatch = useDispatch();
	
	const [cards, setCards] = useState(state.customMessages);

	const [id, setId] = useState(null);
	const [count, setCount] = useState(0);
	const [showRemoveDeleteButton, setShowCancelDeleteButton] = useState(false);
	const [changeStyle, setChangeStyle] = useState({
		backgroundColor: 'rgba(216, 216, 216, 0.2)',
		display: 'block',
	});
	const [customMessages, setCustomMessages] = useState(state.customMessages);
	const [ index, setIndex ] = useState(null)
	const [ updateKey, setUpdateKey ] = useState(null);
	const [ isDisabled, setDisabled ] = useState(false);


	useEffect(() => {
		dispatch(getCustomMessage(state.managewaitingRoomMediaChannel.id));
	}, []);

	
	useEffect(() => {
		setCards(state.customMessages)
	}, [state.customMessages]);

	const changeDisabled = () =>{
		setDisabled(!isDisabled)
	}

	// Show Remove Cancel Button
	const showCancelRemoveButtons = (key, event) => {
		setId(key);
		setShowCancelDeleteButton(true);
		setChangeStyle({
			backgroundColor: '#e7282e',
			display: 'none',
		});
	};
	// When someone clicks on cancel button
	const clickedCancelButton = (event) => {
		setShowCancelDeleteButton(false);
		setChangeStyle({
			backgroundColor: 'rgba(216, 216, 216, 0.2)',
			display: 'block',
		});
	};
	// When someone clicks on remove button
	const clickedRemoveButton = (key, event) => {
		dispatch(
			deleteWaitingRoomCustomMessage(state.practiceUserLoginInfo, {
				message_id: key,
			})
		);
		setCount(count + 1);
		setTimeout(() => {
			dispatch(getCustomMessage(state.managewaitingRoomMediaChannel.id));
		}, 600);

		setChangeStyle({
			backgroundColor: 'rgba(216, 216, 216, 0.2)',
			display: 'block',
		});

		setShowCancelDeleteButton(false);
	};

	const moveCard = (dragIndex, hoverIndex) => {
		setIndex(dragIndex)
		console.log('dragIndex : ', dragIndex);
		const dragCard = cards[`${dragIndex}`];
		const id = dragCard.id
		setUpdateKey(id)
		console.log('dragCard : ', dragCard);
		setCards(
			update(cards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, dragCard],
				],
			})
		);
		// let params ={
		// 	rearrange: [
		// 		{
		// 			"order":`${index}`,
		// 			"id":updateKey,
		// 		},
		// 	],
			
		// }
		console.log(cards,'params')
		// dispatch(putChannelwiseCustomMessages(state.managewaitingRoomMediaChannel.id, params))
	};
	return (
		<>
			<div className='' >
				<h4>Messages</h4>
				<p> Drag your message by the dotted area to rearrange the display order.</p>
			</div>
			<div className='w-100 messages_inner_box_section'>
					{cards.length && cards.length > 0
						? cards.map(function (key, index) {
								return (
									<>
										<div className="w-100 d-flex">
										<span className="index_number">{index + 1}.</span>
											<Card key={key.key} index={index} isDisabled={isDisabled} changeDisabled={changeDisabled} id={key.id} text={key.title} moveCard={moveCard} card_order={key} />
										</div>
									</>
								);
						})
					: ''}
			</div>
		</>
	);
};

export default DragAndDropThree;
