import React, { useState } from 'react';

import { Button , NavLink  ,Dropdown,  DropdownToggle, DropdownMenu , UncontrolledDropdown, Modal, UncontrolledTooltip } from 'reactstrap';
import {/*useTranslation */  withTranslation} from "react-i18next";
import { connect } from "react-redux";
import { compose } from 'redux';
import UploadImage from '../../components/UploadImage'
import {PracticeUserLogout , GetNotification, extendTrail, setGoogleTvTestDriveOnorOff} from "../../store/actions/taskAction";

import {ReactComponent as UserIcon} from '../../img/user_icon.svg';
import {ReactComponent as HelpIcon} from '../../img/help_icon.svg';
import {ReactComponent as GlobalIcon} from '../../img/global_icon.svg';
import {ReactComponent as DevicesIcon} from '../../img/devices_icon.svg';
import {ReactComponent as SettingIcon} from '../../img/setting_icon.svg';
import {ReactComponent as CloseIcon} from '../../img/close_icon.svg';
import {ReactComponent as MinimizeIcon} from '../../img/minimize_icon.svg';
import {ReactComponent as Fullscreen} from '../../img/FullscreenIcon.svg';
import {ReactComponent as FullscreenExit} from '../../img/ExitfullscreenIcon.svg';

import {ReactComponent as NewMessages} from '../../img/new_messages.svg';
import {ReactComponent as Logout} from '../../img/logout.svg';
import {ReactComponent as ExitIcon} from '../../img/exit_icon.svg';

import Thalamus_logomark from '../../img/Thalamus_logomark_B-W.svg'
import Profile_icon from '../../img/Profile_icon.svg'
import Profile_icon_black from '../../img/Profile_icon_black.svg'
import Icon_Sign_out from '../../img/Icon_Sign_out.svg'

import Icon_Sign_out_black from '../../img/Icon_Sign_out_black.svg'
import ic_people from '../../img/ic_people.svg'
import edit_profile_icon from '../../img/edit-profile-icon.svg'
import { history } from '../../pages/index';

import './style.scss';
import logo from '../../img/logo.svg'; 
import LoadingButton from '../../components/LoadingButton';

import {CLOSE , MINIMIZE , LOGOUT, FULLSCREEN, EXITFULLSCREEN} from '../../utilities/HttpUtility'
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOpen: false,
      notificationdropdownOpen:false,
      editimgmodal:false,
      src: null,
      blobFile:'',
      isfullscreen:true,
      crop: {
        x: 50,
        y: 0,
        width: 366,
        height: 366,
        minWidth:366,
        minHeight:366
      },
      file:null,
      imgUpload:'',
      croppedImageUrl:'',
      errors:{
          fileSize:'',
          fileType:'',
      }
    }
    this.toggle = this.toggle.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.toggleEditImageModal = this.toggleEditImageModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onSignOut( ){
    // history.push('/logout');
    this.props.dispatch(PracticeUserLogout())
    this.setState({
      dropdownOpen: false
  });
    // this.props.dispatch(PracticeUserLogout(this.props.practiceUserLoginInfo))
  }

  handleProfileClick(){
    history.push('/editprofile');
  } 

  onViewAccountProfiles(){
    history.push('/view-account-profiles');
  }

  onClickSettings(){
    history.push('/settings');
  }

    onClickSubscribe(){
    history.push('/Subscribe');
  }
  onClickDevice(){
    history.push('/authorized-device');
  }

  getUserType(id){
    switch(id){
        case 0: 
            return  ["guest", "Guest"];
        case 1:
            return  ["dentist", "Normal User"];
        case 2:
            return  ['authorized_user', "Authorized User"]; 
        case 3:
            return    ['manager', "Manager"]; 
        case 4:
            return    ['owner', "Owner"]; 
        default:
          return    ['guest', "Guest"];
    }
  }

  getUserRole(role_id){
    const role = this.props.practice_user_roles.filter((x) => {return x.id === role_id; });
    return role
  }

  toggle(event) {
    this.setState({
        dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleNotification(event) {
    this.setState({
      notificationdropdownOpen: !this.state.notificationdropdownOpen
    });
    this.getNewNotification()
  }

  

  toggleEditImageModal() {
    this.setState({
        editimgmodal: !this.state.editimgmodal
    });
  }
  togglefullscreen(){
    console.log(this.state.isfullscreen,'isfullscreen')
    this.setState({
      isfullscreen: !this.state.isfullscreen
  });
  }

  closeModal(){
    this.setState({
      editimgmodal:false
    });  
  }

  onClose(){
    history.push(CLOSE);
  }

  onMinimize(){
    history.push(MINIMIZE);
  }

  async onClickViewAllMessage(){
   this.getNotification()
    // await history.push('/notification-messages');
  }

  onClickLanguages(){
    history.push('/language');
  }

  async getNewNotification(){
    var params={
      only_new:1
    }
   await this.props.dispatch(GetNotification(params))  
  }

  async getNotification(){
    var params={
      latest:1
    }
   await this.props.dispatch(GetNotification(params))  
  }

  onClickLogout(){
    history.push(LOGOUT);
  }

  onClickExtendTrial(){// isHeader
    this.props.dispatch(extendTrail(true, history))
  }

  async setGoogletv(){
   await this.props.dispatch(setGoogleTvTestDriveOnorOff(false))
   await history.push('/channels')   
  }

  render(){
     // const [t, i18n] = useTranslation('common');
    // const [dropdownOpen, setDropdownOpen] = useState(false);

    // const toggle = () => setDropdownOpen(prevState => !prevState);

    const practiceInfo = this.props.practiceInfo;
    const practiceProfileInfo = this.props.practiceProfileInfo
    const practiceUserLoginInfo = this.props.practiceUserLoginInfo

    if(practiceUserLoginInfo){
      var userType = this.getUserType(practiceUserLoginInfo.type );
      var userRole = this.getUserRole(practiceUserLoginInfo?.role);

    }
    const {t , getOnlyNewnotificationlist ,resetAccessCode , practiceUserAccountInfo} = this.props


    return (
      <div className="position-fixed header white--transparent d-flex align-items-center justify-content-between w-100">
          <div className="col-4 d-flex align-items-center">
            <img alt="" src={logo}/>"
            {/* practiceInfo.days_left == 0 && practiceInfo.extend_trial_request_status == null? */}
            {practiceInfo && practiceInfo.days_left == 0 && practiceInfo.is_paid_account != 1 && practiceInfo.extend_trial_request_status == null && practiceUserLoginInfo && practiceUserLoginInfo.token_info && !this.props.googleTvTestDrive && <LoadingButton type="button" label="EXTEND TRIAL" size="md" className={`ml-3`} color="primary" loading={this.props.extendbuttonLoading} disabled={this.props.extendbuttonLoading} onClick={()=>{ this.onClickExtendTrial() }}/>}
          </div>
          <div className="practice_name col-4 text-center mx-auto d-flex justify-content-center align-items-center">
            {practiceProfileInfo && practiceProfileInfo.name}
          </div>
          <div className="header__right__buttons col-4 px-0 d-flex align-items-center justify-content-end">
          {practiceInfo &&  practiceInfo.is_internal_user == 1 &&
            <>
              <NavLink id="logout_tooltip"  href={LOGOUT}><Logout/></NavLink>
              <UncontrolledTooltip placement="bottom"  target="logout_tooltip" className="headerMenuToolTip">
                Logout
              </UncontrolledTooltip>
            </>
          }
            { practiceInfo && practiceInfo.is_internal_user != 1 &&
            <>
              {practiceInfo && practiceInfo.is_paid_account !== 1 && !this.props.googleTvTestDrive ?
              <>
                {practiceInfo.days_left !== 0 && practiceUserLoginInfo && practiceUserLoginInfo.token_info ?
                      <div className="d-flex align-items-center trial_note expired_trial">
                        <h4 className="m-0">TRIAL</h4><span>|</span><span className="trial_days_txt px-3"><span className="trail_days px-3">{practiceInfo.days_left}</span> DAYS LEFT</span>
                        { practiceUserLoginInfo.type != 0 && practiceUserLoginInfo.type != 1  &&
                          <Button disabled={resetAccessCode && resetAccessCode.isdisabled} className="mx-3 py-2"  onClick={() => this.onClickSubscribe()} color="white">{t('btn.subscribe')}</Button>
                        }
                      </div>
                    :
                    <div className="d-flex align-items-center trial_note">
                       { practiceUserLoginInfo && practiceUserLoginInfo.token_info  &&
                        <>
                        <span className="trial_days_txt pr-3">FOR FULL ACCESS, PLEASE</span>
                        <Button disabled={resetAccessCode && resetAccessCode.isdisabled} className="mx-3 py-2"  onClick={() => this.onClickSubscribe()} color="white">{t('btn.subscribe')}</Button>
                        </>
                      }
                    </div>
                }
              </>
              :null}
                {/* <button className="btn btn-link white" onClick={() => i18n.changeLanguage('fr')}>fr</button>
                <button className="btn btn-link white" onClick={() => i18n.changeLanguage('en')}>en</button> */}
              
            
                {/* <Button color="link"><UserIcon/></Button> */}
                {practiceUserLoginInfo && practiceUserLoginInfo.token_info && 
                  <>
                  <Dropdown isOpen={this.state.notificationdropdownOpen} toggle={this.toggleNotification}>
                    {/* <DropdownToggle id="new_message"  disabled={resetAccessCode && resetAccessCode.isdisabled} className="menu__btn notification_btn" color="link">
                      <NewMessages/>
                        <div className="notificaiton-icon">2</div>
                      <span></span>
                    </DropdownToggle>
                    <UncontrolledTooltip placement="bottom" target="new_message">
                      Notification messages
                    </UncontrolledTooltip> */}
                    <DropdownMenu right className={`user__notification `}>
                      <div className="d-flex h-100">
                        <div className="logomark">
                            <img src={Thalamus_logomark} alt=""/>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="user__notification__lists">
                            {getOnlyNewnotificationlist && getOnlyNewnotificationlist.length && getOnlyNewnotificationlist.data.slice(0, 2).map((list, index) => {
                              return <div className="user__notification__list border--bottom">
                                <div className="user__notification__title d-flex justify-content-between">
                                  <h6 className="mb-0">{list.title}</h6><span>{list.publish_date}</span>
                                </div>
                                <p className="mb-0">{list.content}</p>
                              </div>
                            })}
                          </div>
                          <div className="user__notification__footer mt-auto text-right mb-3">
                            <Button onClick={() => this.onClickViewAllMessage()} color="link primary">View all messages</Button>
                          </div>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                  {this.props.isCorporate != 1 &&
                  <>
                    {this.props.googleTvTestDrive &&  
                      <Button outline id="google_tv_test_drive_tooltip" color="default white" className='exit_test_drive_btn d-flex align-items-center mr-3' disabled={resetAccessCode && resetAccessCode.isdisabled} onClick={() =>  this.setGoogletv()}><ExitIcon width={15} className='mr-3'/>Exit Test TV App</Button>
                    }
                     {!this.props.googleTvTestDrive &&  
                      <>
                      <Button id="setting_tooltip" color="link" disabled={resetAccessCode && resetAccessCode.isdisabled} onClick={() => this.onClickSettings()}><SettingIcon/></Button>
                      <UncontrolledTooltip placement="bottom"  target="setting_tooltip" className="headerMenuToolTip">
                        Settings
                      </UncontrolledTooltip>
                      <Button color="link" id="devices" disabled={resetAccessCode && resetAccessCode.isdisabled} onClick={() => this.onClickDevice()}><DevicesIcon/></Button>
                      <UncontrolledTooltip placement="bottom" target="devices" className="headerMenuToolTip">
                        Devices
                      </UncontrolledTooltip>
                      <Button color="link" id="language" disabled={resetAccessCode && resetAccessCode.isdisabled} onClick={() => this.onClickLanguages()}><GlobalIcon/></Button>
                      <UncontrolledTooltip placement="bottom" target="language" className="headerMenuToolTip">
                        Language
                      </UncontrolledTooltip>
                      <Button color="link" id="helpe" onClick={()=>history.push("/help")} disabled={resetAccessCode && resetAccessCode.isdisabled}><HelpIcon/></Button>
                      <UncontrolledTooltip placement="bottom" target="helpe" className="headerMenuToolTip">
                        Help
                      </UncontrolledTooltip>
                      </>
                    }
                  </>
                  }
                  {!this.props.googleTvTestDrive &&  
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle id="profile" disabled={resetAccessCode && resetAccessCode.isdisabled} className="menu__btn" color="link">
                      <UserIcon/>
                    </DropdownToggle>
                    <UncontrolledTooltip placement="bottom" target="profile">
                      Profile
                    </UncontrolledTooltip>
                    <DropdownMenu right className={`${userType[0] === 'owner' || userType[0] === 'manager' || userType[0] === 'authorized_user'? 'admin' : null } user__profile`}>
                      <div className={`${userType[0]} d-flex align-items-center  flex-column h-100`}>
                        <div className="d-flex align-items-center flex-column w-100">
                          <div className="p-4 w-100 text-center user__profile--header">
                            <div className="">
                              <h4 className="user__profile--name">{`${practiceUserLoginInfo.first_name || ''} ${practiceUserLoginInfo.last_name || ''}`}</h4>
                            </div>
                            <div className="">
                              <p className="user__profile--type">{practiceUserLoginInfo.type === 1 ? userRole[0]?.name : userType[1]}</p>
                            </div>
                          </div>
                          <div className="user__profile--img d-flex  flex-column">
                            <div className="edit--btn">
                              <Button onClick={this.toggleEditImageModal} className="p-0" color="link"><img src={edit_profile_icon} alt="" /></Button>
                            </div>
                            { practiceUserLoginInfo && practiceUserLoginInfo.profile_pic ?  
                              <img className="mx-auto my-auto" alt={practiceUserLoginInfo.first_name} src={ practiceUserLoginInfo.profile_pic}/>
                              :
                              <img className="default_icon m-auto" alt={practiceUserLoginInfo.first_name} src={Profile_icon}/>
                            }
                          </div>
                        </div>
                        {practiceUserLoginInfo && practiceUserLoginInfo.is_manage_location !== 1 &&
                        <div className="user__profile--footer ">
                        { 
                          userType[0] === 'owner' || userType[0] === 'manager' || userType[0] === 'authorized_user'?
                          <>
                            <div className=" d-flex justify-content-between" style={{paddingBottom:"4px"}}>
                              <Button className="" onClick={() => this.handleProfileClick()} color="link" style={{padding:"1px"}}><img className="icon mr-4" src={Profile_icon} alt="" /><span>Profile</span></Button>
                              <Button color="link"  onClick={() => this.onSignOut()} style={{padding:"1px"}}><img className="mr-4" src={Icon_Sign_out} alt="" />Sign Out</Button>
                            </div>
                            {this.props.isCorporate != 1 &&
                              <Button className="" onClick={() => this.onViewAccountProfiles()} color="link" style={{padding:"15px 10px"}}><img className="mr-4"  src={ic_people} alt="" /> View Account Profiles</Button>
                            }
                          </>
                        :
                          <div className=" d-flex justify-content-between">
                            <Button className="" onClick={() => this.handleProfileClick()} color="link"><img className="icon mr-4" src={Profile_icon_black} alt="" /><span>Profile</span></Button>
                            <Button color="link"  onClick={() => this.onSignOut()} ><img className="mr-4" src={Icon_Sign_out_black} alt="" />Sign Out</Button>
                          </div>
                        }
                            
                        </div>
                        }
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                  }
                  </>
                }
                {!this.props.googleTvTestDrive &&  
                <>
                <NavLink id="logout_tooltip" className='px-4' href={LOGOUT}><Logout/></NavLink>
                <UncontrolledTooltip placement="bottom"  target="logout_tooltip" className="headerMenuToolTip">
                  Logout
                </UncontrolledTooltip>
                </>
                }
              </>
            }
            <NavLink onClick={()=>this.togglefullscreen()} href={!this.state.isfullscreen ? FULLSCREEN : EXITFULLSCREEN}>{!this.state.isfullscreen ? <Fullscreen/> : <FullscreenExit/> }</NavLink>
            <NavLink href={MINIMIZE}><MinimizeIcon/></NavLink>
            <NavLink href={CLOSE}><CloseIcon/></NavLink>
            <NavLink  href={LOGOUT}></NavLink>
            {/* <Button color="link" onClick={() => this.onMinimize()} className="py-0 px-2 mt-0"><MinimizeIcon/></Button>
            <Button color="link" onClick={() => this.onClose()} className="py-0 px-2 mt-0"><CloseIcon/></Button> */}
            <Modal isOpen={this.state.editimgmodal} centered={true} toggle={this.toggleEditImageModal} >
                <UploadImage isPopup={true} closeModal={this.closeModal}/>
            </Modal>            
          </div>
      </div>
    );
  }

}
const mapStateToProps = (state) => {
  return {
    practiceInfo: state.task.practiceInfo,
    practiceProfileInfo:state.task.practiceProfileInfo,
    practiceUserLoginInfo: state.task.practiceUserLoginInfo,
    practiceUserLogoutInfo:state.task.practiceUserLogoutInfo,
    getOnlyNewnotificationlist:state.task.getOnlyNewnotificationlist,
    UpdateUserProfileImage:state.task.UpdateUserProfileImage,
    resetAccessCode:state.task.resetAccessCode,
    practiceUserAccountInfo:state.task.practiceUserAccountInfo,
    extendbuttonLoading: state.task.extendbuttonLoading,
    isCorporate:state.task.isCorporate,
    googleTvTestDrive:state.task.googleTvTestDrive,
    practice_user_roles:state.task.practice_user_roles
  }
};


export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Header);