import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from "reactstrap";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import { Divider } from '@material-ui/core';
// import SortIcon from '@material-ui/icons/Sort';
import WaitingRoomChannels from './WaitingRoomChannels';
import { getWebsiteRoomMediaChannelList,createNewWaitingRoomChannel , getwaitingRoomThemeList} from '../../store/actions/taskAction';
import { history } from '..';
import uuid from "react-uuid";
import {ReactComponent as SortIcon} from '../../img/sort_icon.svg';
import {ReactComponent as PersonalVideoIcon} from '../../img/PersonalVideoIcon.svg';
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer({ label }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const states = useSelector((state) => state.task);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [ sortedBy, setSortedBy ] = useState('newest');
  const [ sortedType, setSortedType ] = useState('all');
  const [ count, setCount ] = useState(0);

  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    setSortedBy('newest');
    setSortedType('all');
	}, []);
  
  const changeHandler = (sortedBy) => {
    console.log("sortedBy in Temporary Drawer : ", sortedBy)
    dispatch(
			getWebsiteRoomMediaChannelList(
				states.practiceUserLoginInfo,
				sortedBy,
        sortedType
			)
		);
    setSortedBy(sortedBy);
    setCount(count+1)
  }

  const changeHandlerTwo = (sortedType) => {
    dispatch(
			getWebsiteRoomMediaChannelList(
				states.practiceUserLoginInfo,
        sortedBy,
				sortedType,
			)
		);
    setSortedType(sortedType);
    setCount(count+1)
  }

  const newChannel = () => {
    let params ={
      "channel":uuid(),
      "title":uuid(),
      // "practice_name":states.practiceInfo.name,
      "header_status": "1",
      "footer_status":"1",
      "theme_id":1,
      "enableButton":false,
      "is_shared":0
    }
    dispatch(createNewWaitingRoomChannel(params))
    dispatch(getwaitingRoomThemeList())
	};
  

  const list = (anchor) => (
    <div  className={` ${clsx(classes.list, {  [classes.fullList]: anchor === 'top' || anchor === 'bottom', })} waiting-roomsidebar`}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {states.practiceInfo.is_internal_user !== 1 &&
       <>
        <div className="lookingvideo">
        <h6 style={{ color:"white" }}>Looking for a video we don't have yet?</h6>
        <Button  onClick={() => history.push({
          pathname: '/video-request',
          state: { page: '/waiting-room-channels' }
        }

        )} color="link primary">Click here to make a request.</Button>
        </div>
        { states.practiceInfo.is_corporate == 0 && 
          <>
            <List>
                <ListItem button key={'Channels'}>
                  <ListItemIcon>{<PersonalVideoIcon style={{ color:"white" }}/>}</ListItemIcon>
                  <ListItemText className="subtitle" primary={'Channels'} style={{ color:"white" }}/>
                </ListItem>
                
                <ul style={{ padding:"30px", color:"white" }}>
                      <li style={{ listStyle: "disc" }} className={sortedType == 'all' ? 'activelink' : '' } onClick={()=>changeHandlerTwo('all')}>All</li>
                      <li style={{ listStyle: "disc" }} className={sortedType == 'my_list' ? 'activelink' : '' } onClick={()=>changeHandlerTwo('my_list')}>My list</li>
                      <li style={{ listStyle: "disc" }} className={sortedType == 'my_channel' ? 'activelink' : '' } onClick={()=>changeHandlerTwo('my_channel')}>My channels</li>
                      <li style={{ listStyle: "disc" }} className={sortedType == 'shared_channel' ? 'activelink' : '' }  onClick={()=>changeHandlerTwo('shared_channel')}>Shared channels</li>
                      <li style={{ listStyle: "disc" }}  className={sortedType == 'after_hours' ? 'activelink' : '' } onClick={()=>changeHandlerTwo('after_hours')}>After Hours channels</li>
                </ul>
              
            </List>
            <Divider />
            </>
        }
        </>
      }
        <List>
            <ListItem button key={'Sorted By'}>
                <ListItemIcon>{<SortIcon style={{ color:"white" }}/>}</ListItemIcon>
                <ListItemText className="subtitle" primary={'Sorted By'} style={{ color:"white" }}/>
            </ListItem>
            <ul style={{ padding:"30px", color:"white" }}>
                <li style={{ listStyle: "disc" }}   className={sortedBy == 'newest' ? 'activelink' : '' } onClick={()=>changeHandler('newest')}>Newest</li>
                <li style={{ listStyle: "disc" }}   className={sortedBy == 'oldest' ? 'activelink' : '' } onClick={()=>changeHandler('oldest')}>Oldest</li>
                <li style={{ listStyle: "disc" }}   className={sortedBy == 'most_watched' ? 'activelink' : '' } onClick={()=>changeHandler('most_watched')}>Most watched</li>
                <li style={{ listStyle: "disc" }}   className={sortedBy == 'alphabetical' ? 'activelink' : '' } onClick={()=>changeHandler('alphabetical')}>Alphabetical</li>
            </ul>
            
        </List>
       
        
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button type="button" className="drawer_btn" style={{ background:"black", color:"white" }} onClick={toggleDrawer(anchor, true)}>{label} <ChevronRightIcon /></Button>
          <Drawer anchor={anchor} open={state[anchor]} BackdropProps={{ invisible: true }} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}