import React from 'react';

import './style.scss';
import { Button } from 'reactstrap';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import InitialSetup from './Form'
import { GetpracticeProfile, GetPracticeMoreInfo } from "../../store/actions/taskAction";
import Loading from '../../components/Loading'
import { Ownership } from '../../data/comman';
import HttpUtility, { HELPERURI } from '../../utilities/HttpUtility';

class InitialSetupIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isStart: true,
      loadingButton:false,
      practiceProfileInfo:{
        
      },
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ isStart: false })
  }

  async componentDidMount() {
    await this.setState({ loadingButton: true })
    // await this.props.dispatch(GetPracticeMoreInfo());
    await this.props.dispatch(GetpracticeProfile());
    await this.onload()
    await this.setState({ loadingButton: false })

  }


 async onload(){
    if(await this.props.practiceProfileInfo?.country_id){
      var country = this.props.country.find(b => b.id === this.props.practiceProfileInfo.country_id)
      if(!!country.id){
        await this.getState(country.id);
      }
    }
    var selectedownership = null;
    if(await this.props.practiceProfileInfo.ownership != null && this.props.practiceProfileInfo.ownership != undefined){
      selectedownership  = Ownership.find(b => b.id === this.props.practiceProfileInfo.ownership)
    }

    var specializations = []
    if(await this.props.practiceProfileInfo.specializations){
        this.props.practiceProfileInfo.specializations.map((ids)=>{
          specializations.push(this.props.specializations.find(b => b.id == ids))
        });
    }

    await this.setState({
      practiceProfileInfo: {
            ...this.state.practiceProfileInfo,
            country,
            selectedownership,
            specializations,
        }
    },);

    
  }

  async  getState(countryId){
    HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
          this.setState({selectedstate:'' , stepOneNext:false})
            var countryState = response.data;
            var selectedstate = null;
            if(this.props.practiceProfileInfo.state_id){
              selectedstate = countryState.find(b => b.id === this.props.practiceProfileInfo.state_id)
            }
            this.setState({
              practiceProfileInfo: {
                    ...this.state.practiceProfileInfo,
                    countryState,
                    selectedstate,
                },
            },);
        }
    });
  }
  

  render() {
    const { practiceInfo, error, t,practiceProfileLoading, loading, practiceProfileInfo, media_language, country, specializations, lead_source_types, practice_softwares, practice_customer_types } = this.props

    return (
     
      <div className="initial_setup__main__screen  d-flex flex-column h-100 align-items-center ">
         {(this.state.loadingButton) ? 
          <Loading />
        :
        <>
          <div className="d-flex flex-column text-center initial_setup__inner__text text-center mt-5">
            <h1 className="mt-0">Before using Thalamus…</h1>
            <p className='mb-0'>Please take a minute to go through the initial setup process.</p>
          </div>
          {/* {this.state.practiceProfileInfo?.selectedstate && */}
            <InitialSetup practiceProfileInfo={this.state.practiceProfileInfo} media_language={media_language} country={country} specializations={specializations} practice_customer_types={practice_customer_types} practice_softwares={practice_softwares} lead_source_types={lead_source_types} />
          {/* } */}
          </>
        }
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  country: state.task.country,
  specializations: state.task.specializations,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types: state.task.practice_customer_types,
  media_language: state.task.media_language,
  loading: state.task.loading,
  practiceProfileLoading: state.task.practiceProfileLoading,
  error: state.task.error,
  practiceProfileInfo: state.task.practiceProfileInfo,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(InitialSetupIndex);