import React from 'react';

import { Button , Label , FormGroup, Form , Col , Row} from 'reactstrap';
import DropDown from '../../../components/DropDown'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import {noOfoperatories, days , distributor, tv_count} from '../../../data/comman';
import LoadingButton from '../../../components/LoadingButton'
import { GetpracticeProfile, UpdatePracticeProfile  } from "../../../store/actions/taskAction";
import { history } from '../../../pages/index';


class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      practiceProfileInfo:props.practiceProfileInfo,
      errors:{}
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);

    this.submitdata = this.submitdata.bind(this);
  }

  nextClick(){
    this.props.nextStep();
  }

  prevClick(){
    this.props.prevStep();
  }

  async componentDidMount(){
    
  }

  async submitdata(id ,data){

    var errors = {};
    var isError = false;

      if (data.selectedpracticetype == undefined) {
        errors['selectedpracticetype'] = "Please select type.";
        isError = true;
      }

    
    if (isError) {
      this.setState({errors:errors});
      return false
    }else{
      this.setState({errors:errors});
    }
    await this.props.dispatch(UpdatePracticeProfile(id ,data));

    if(this.props.practiceInfo.need_owner_setup === 1){
      history.push('/edit-authorized-profile');
    }else{
      history.push('/profileselection');
    }
    
    if(this.props?.error?.status != 400){
     await this.props.dispatch(GetpracticeProfile());
    }

  }

  render (){
    const {t , values  ,loading, media_language, system_language, buttonLoading} = this.props
    return(
        <div className="mw-820 transparent__form--white d-flex flex-column align-items-stretch mb-auto">
          <Form className="mt-2 h-100 d-flex flex-column">
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-5 form-heading">
                    <h4 className="m-0">{t('title.AdditionalPracticeInfo')}</h4>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="add-pracinfo">
                <Col md={6} style={{paddingRight:"20px"}}>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="practiceManagementSoftware">{t('form.practiceManagementSoftware')}</Label>
                    </div>
                    {this.state.errors && this.state.errors['selectedsoftwares'] && <span className='text--danger'>{this.state.errors['selectedsoftwares']}</span>}
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        options={this.props.practice_softwares}
                        onChange={(val)=> this.props.handleChange('softwares', val, "dropdown") }
                        isSearchable={true}
                        placeholder={''}
                        value={values.selectedsoftwares}
                    />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                        <Label for="noOfoperatories">{t('form.noOfoperatories')}</Label>
                        {this.state.errors && this.state.errors['selectedoperatories'] && <span className='text--danger'>{this.state.errors['selectedoperatories']}</span>}

                      </div>
                      <DropDown 
                          className='react-select-container'
                          classNamePrefix="react-select"
                          options={noOfoperatories}
                          getOptionLabel={ x => `${x.label}`}
                          getOptionValue={ x => `${x.value} ${x.value}`}
                          onChange={(val)=> this.props.handleChange('selectedoperatories', val, "dropdown") }
                          isSearchable={true}
                          placeholder={''}
                          value={values.selectedoperatories}
                      />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                        <Label for="source">{t('form.source')}</Label>
                        {this.state.errors && this.state.errors['selectedsource'] && <span className='text--danger'>{this.state.errors['selectedsource']}</span>}

                      </div>
                      <DropDown 
                          className='react-select-container'
                          classNamePrefix="react-select"
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          options={this.props.lead_source_types}
                          onChange={(val)=> this.props.handleChange('source', val, "dropdown") }
                          isSearchable={true}
                          placeholder={''}
                          defaultValue={values.selectedsource}
                      />
                  </FormGroup>
                  
                </Col>
                <Col md={6} style={{paddingLeft:"20px"}}>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="customerType">{t('form.NoOfTVs')}</Label>
                      {this.state.errors && this.state.errors['nooftvs'] && <span className='text--danger'>{this.state.errors['nooftvs']}</span>}
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        options={tv_count}
                        getOptionLabel={ x => `${x.label}`}
                        getOptionValue={ x => `${x.value} ${x.value}`}
                        onChange={(val)=> this.props.handleChange('nooftvs', val, "dropdown") }
                        isSearchable={true}
                        placeholder={''}
                        value={values.nooftvs}
                    />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                        <Label for="customerType">{t('form.customerType')}</Label>
                        {this.state.errors && this.state.errors['selectedpracticetype'] && <span className='text--danger'>{this.state.errors['selectedpracticetype']}</span>}

                      </div>
                      <DropDown 
                          className='react-select-container'
                          classNamePrefix="react-select"
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          options={this.props.practice_customer_types}
                          onChange={(val)=> this.props.handleChange('practicetype', val, "dropdown") }
                          isSearchable={true}
                          placeholder={''}
                          defaultValue={values.selectedpracticetype}
                      />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mt-5 text-center">
                <Button type="button" size="md" onClick={ this.prevClick }  className="margin-r-20" outline color="default white">{t('btn.back')}</Button>
                <LoadingButton type="button" error={this.props.error} label={'Submit'} loading={buttonLoading} disabled={buttonLoading} size="md" onClick={() => this.submitdata(this.props.practiceProfileInfo.id ,values)} className={`margin-l-20`} color="primary"/>
              </div>
            </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  error: state.task.error,
  practiceInfo: state.task.practiceInfo,
  media_language:state.task.media_language,
  system_language:state.task.system_language,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types:state.task.practice_customer_types,
  practice_user_type:state.task.practice_user_type,
  loading: state.task.loading,
  buttonLoading: state.task.buttonLoading,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AdditionalInfo);