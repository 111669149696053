import React, { useEffect, useState } from "react";
import SubHeader from '../../components/Header/subHeader';
import DropDown from '../../components/DropDown'
import LoadingButton from '../../components/LoadingButton'

import { Button, Col, FormGroup, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getShareVideoLink} from "../../store/actions/taskAction";
import CopyToClipboard from "react-copy-to-clipboard";
import DatePicker from "react-datepicker";
import {ReactComponent as LastPageIcon} from '../../img/ic_last_page_arrow.svg';
import {ReactComponent as FirstPageIcon} from '../../img/ic_first_page_arrow.svg';
import {ReactComponent as PrePageIcon} from '../../img/ic_left_arrow.svg';
import {ReactComponent as NextPageIcon} from '../../img/ic_right_arrow.svg';



import './styles.scss';
import { history } from "..";
import moment from "moment";
import { ACTIVEFORMAT, DateDDMMMYYY, DateFormat, shareVideoType , shareVideoViewOption} from "../../data/comman";


  const SharedVideoHistory = (props) => {
    const dispatch = useDispatch();
    const states = useSelector((state) => state.task);
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page, setPerpage] = useState(8);
    const [last_page, setLastpage] = useState('');
    const [errors, setError] = React.useState("");
    const [buttonLoading, setBtnLoading] = useState(false);
    const [shareData, setShareData] = React.useState({
        shareDate:'',
        sharePatientName:'',
        shareVideoType: { "value": "", "label": "All" } , //{ "value": "email", "label": "Email" },
        shareVideoView: { "value": "", "label": "All" } // { value: 'viewed', label: 'Viewed' },
    });
    
    const [shareBtn, setShareBtn] = useState({
		message: 'Search',
		bgColor: 'primary',
	});

    useEffect(() => {
        let params = {
            page: currentPage,
            per_page: per_page
          }

        dispatch(getShareVideoLink(states.shareVideoId, params));
    }, []);

    useEffect(() => {
       console.log('test')
       setLastpage(states.shareVideoList?.last_page)
    }, [states.shareVideoList?.data?.length > 0]);


    

    const  handlechangePage= async (e, index)=> {
        var i = index + 1
        console.log(i)

        let params = {
            page: i,
            per_page: per_page
        }
        setCurrentPage(i);
        await dispatch(getShareVideoLink(states.shareVideoId, params));
      }

      const lastPage= async()=>{
        let params = {
            page: last_page,
            per_page: per_page
        }
        setCurrentPage(last_page);
        await dispatch(getShareVideoLink(states.shareVideoId, params));
      }

      const firstPage= async()=>{
        let params = {
            page: 1,
            per_page: per_page
        }
        setCurrentPage(1);
        await dispatch(getShareVideoLink(states.shareVideoId, params));
      }

      const prePage= async()=>{

        var i = currentPage - 1

        let params = {
            page: i,
            per_page: per_page
        }
        setCurrentPage(i);
        await dispatch(getShareVideoLink(states.shareVideoId, params));
      }

      const nextPage= async()=>{
        var i = currentPage + 1

        let params = {
            page: i,
            per_page: per_page
        }
        setCurrentPage(i);
        await dispatch(getShareVideoLink(states.shareVideoId, params));
      }

      const onChangeOption = (field, val)=> {
        console.log(field, '' , val)
        setShareData({
            ...shareData,
            [field]: val
        })
      }
   
      const searchSharedVideo = async () => {
        let params = {
            page: 1,
            per_page: per_page,
            search: shareData.sharePatientName || "",
            send_by: shareData.shareVideoType?.value || "",
            seen_type: shareData.shareVideoView?.value || "",
            share_date: shareData.shareDate ? moment(shareData.shareDate).format(DateFormat) : ""
        }
        setCurrentPage(1);
        setBtnLoading(true);
        await dispatch(getShareVideoLink(states.shareVideoId, params));
        setBtnLoading(false);
      }

    return (
        <div className="main__screen  emailVideo__main__screen  d-flex flex-column ">
          <SubHeader className="" title="Shared Video History"/>
            <div className='emailVideo__inner_screen'>     
                <div className='d-flex justify-content-center '>
                    <div className='emailVideo__inner__body d-flex flex-column'>

                        <div className="d-flex align-items-center emailVideo__filter_box">
                            <div className="emailVideo__filter_section w-100 mx-5">
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-4 mt-4">
                                            <Input type='text' name="sharePatientName" className="searchBox" onChange={(e) => onChangeOption('sharePatientName', e.target.value)} placeholder="Search by patient name or video name"></Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-4 mt-4">
                                            <DatePicker className="form-control custom-datepicker form-control mb-2"
                                                selected={shareData.shareDate ? moment(shareData.shareDate).toDate() : null}
                                                onChange={(date) => onChangeOption('shareDate', date)}
                                                dateFormat="MMMM d, yyyy"
                                                maxDate={moment()._d}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                yearDropdownItemNumber={500}
                                                scrollableYearDropdown
                                                placeholderText="Search by share date"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-4 mt-4">
                                            <DropDown
                                                className='react-select-container'
                                                classNamePrefix="react-select"
                                                options={shareVideoType}
                                                // getOptionLabel={x => `${x.name}`}
                                                // getOptionValue={x => `${x.id} ${x.name}`}
                                                onChange={(options) => onChangeOption('shareVideoType', options)}
                                                isSearchable={false}
                                                // placeholder={'Select an option'}
                                                value={shareData.shareVideoType}
                                                                    //   menuIsOpen={true}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-4 mt-4">
                                            <DropDown
                                                className='react-select-container'
                                                classNamePrefix="react-select"
                                                options={shareVideoViewOption}
                                                // getOptionLabel={x => `${x.name}`}
                                                // getOptionValue={x => `${x.id} ${x.name}`}
                                                onChange={(options) => onChangeOption('shareVideoView', options)}
                                                isSearchable={false}
                                                // placeholder={'Select an option'}
                                                value={shareData.shareVideoView}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div> 
                            <LoadingButton type="button" color={shareBtn.bgColor} label={shareBtn.message} size="sm" disabled={buttonLoading} loading={buttonLoading} onClick={() => searchSharedVideo()}/>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{width:'153px'}}>Patient name</th>
                                    <th style={{width:'267px'}}>Video name</th>
                                    <th style={{width:'150px'}}>Share date</th>
                                    <th style={{width:'250px'}}>Details</th>
                                    <th style={{width:'180px'}}>First Viewed</th>
                                    <th style={{width:'129px'}}>IP</th>
                                </tr>
                            </thead>
                            <tbody>
                            {states.shareVideoList && states.shareVideoList.data && states.shareVideoList.data.map((list, index) => {
                                 return (<tr>
                                            <td style={{width:'153px'}}>{list.patient_name}</td>
                                            <td style={{width:'267px'}}>{list.media_language.title}</td>
                                            <td style={{width:'150px'}}>{moment(list.created_at).format(ACTIVEFORMAT)}</td>
                                            <td style={{width:'250px'}} className="emailSHowOnHover"   >{
                                            list.send_by == 'email' ? 
                                                <>
                                                <span id={`logout_tooltip${index}`}>
                                                    {list.email}
                                                </span>
                                                    {
                                                        list?.linked_email && list?.linked_email.map((list)=>{
                                                            return ', '+ list
                                                        })
                                                    }
                                                </>
                                                : 
                                                list.phone
                                            }</td>
                                            <td style={{width:'180px'}}>{list.first_view_at ? moment(list.first_view_at).format(ACTIVEFORMAT) : '*'}</td>
                                            <td style={{width:'129px'}}>*{list.ip || ''}</td>
                                        </tr>
                                 )
                            })}
                            </tbody>
                        </table>

                        {states.shareVideoList && states.shareVideoList?.last_page > 1 &&
                            <div className=" mt-auto pt-5 paginationCustome">
                                <ul className="d-flex justify-content-center align-items-center">
                                    <li><Button color="link" className="px-2" onClick={()=> firstPage()}><FirstPageIcon /></Button></li>
                                    <li><Button color="link" className="px-2" onClick={()=> prePage()}><PrePageIcon /></Button></li>
                                        {[...Array(states.shareVideoList && states.shareVideoList.last_page)].map((page, i) =>
                                        <li key={i} className="m-0"><Button type="button" className={`p-3 ${i + 1 === currentPage ? 'activepage' : null}`} onClick={e => handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                                        )}
                                    <li><Button color="link" className="px-2" onClick={()=> nextPage()}><NextPageIcon /></Button></li>
                                    <li><Button color="link" className="px-2" onClick={()=> lastPage()}><LastPageIcon /></Button></li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default SharedVideoHistory
