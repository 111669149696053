import React from 'react'
import styled from 'styled-components'

export const PinturaMainSection = styled.div`
    background-size: contain;
    position: fixed;
    left:0;
    right:0;
    bottom: 0;
    top:0;
    z-index: 99999;
    background-color: #000;
    background-image: url(${(props)=>props.imgUrl}); 

    &::before{
        content:"";
        background-color: rgba(0,0,0,0.8);
        position: absolute;
        left:0;
        right: 0;
        bottom: 0;
        top:0;
        z-index:-1;
    } 
`;

export const PinturabackgroundImg = styled.img`
    z-index: -2;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    filter: blur(6px);

`

export const CopyrightMessage = styled.div`
  height: 68px;
  background-color: rgba(0,0,0,0.16);
  padding: 12px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  p{
    color: rgba(255,255,255,0.6);
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0;
  }
`