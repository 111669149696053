import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import "./style.scss";

const WHITEBOXIMAGE = "assets/img/colorsImg/whitebox.png";
const CLOSEBOXIMAGE = "assets/img/colorsImg/closeicon.png";

const SelectionTab = ({ label, list,submitVideoLists }) => {
  
  const [allVideosIndex, setAllVideosIndex] = useState(list.selected);
  const [allVideosCount, setAllvideosCount] = useState(0);
  const [playList, setPlayList] = useState([]);
  const [stateUpdate, setStateUpdate] = useState(0);
  const allVideoList = allVideosIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
console.log(setPlayList)
  //COUNT ALL VIDEOS
  useEffect(() => {
    var arr = [];
    list.videoList.forEach((element) => {
      element.videos.forEach((video) => {
        arr.push(video);
      });
    });
  setAllvideosCount(arr.length);
    changeAllVideos();
  }, []);
  
  
  //ON LIST ITEM CLICK
  const onButtonClick = (item, type) => {
    setAllVideosIndex(false);
    setStateUpdate(stateUpdate + 1);
    var arr = [];

    if (type === "playList") {
      list.videoList.forEach((element) => {
        element.selected = false;
      });

      playList.forEach((element) => {
        if (element.specializations === item.specializations) {
          element.selected = !item.selected;
        }
      });

      playList.forEach((element) => {
        if (element.selected === true) {
          element.videos.forEach((video) => {
            arr.push(video);
          });
        }
      });
      submitVideoLists(arr);
    } else {
      playList.forEach((element) => {
        element.selected = false;
      });

      list.videoList.forEach((element) => {
        if (element.specializations === item.specializations) {
          element.selected = !item.selected;
        }
      });

      list.videoList.forEach((element) => {
        if (element.selected === true) {
          element.videos.forEach((video) => {
            arr.push(video);
          });
        }
      });

      submitVideoLists(arr);
    }
  };

  const changeAllVideos = () => {
    setAllVideosIndex(true);
    list.videoList.forEach((element) => {
      element.selected = false;
    });
    playList.forEach((element) => {
      element.selected = false;
    });
    var arr = [];
    list.videoList.forEach((element) => {
      element.videos.forEach((video) => {
        arr.push(video);
      });
    });

    submitVideoLists(arr);
  };


  return (
    <Container>
      <div className="selectiontab__main__screen">
        <div className="selectiontab__list__screen">
          <div>
            <p className="selectiontab__headerTitle">{label}</p>
          </div>
          <div>
            <div>
              <img  onClick={() => changeAllVideos()} src={`${allVideoList}`} alt=""/>
              &nbsp;&nbsp;&nbsp;
              <span className="title">{list.allVideos}{`(${allVideosCount})`}</span>
            </div>
            <hr />
          </div>
          {list.videoList.map((item, index) => {
            const TYPE = "lists";
            const active =
              item.selected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
            const title =
              item.specializations.charAt(0).toUpperCase() +
              item.specializations.slice(1);

            return (
              <React.Fragment key={index}>
                <div>
                  <img
                    src={`${active}`}
                    onClick={() => onButtonClick(item, TYPE)}
                    alt=""
                  />
                  &nbsp;&nbsp;&nbsp;
                  <span className="title">
                    {title} {`(${item.videos.length})`}
                  </span>
                </div>
                <br />
              </React.Fragment>
            );
          })}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button className="selectiontab__button__screen" color="warning"  >
            save timeline
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button className="selectiontab__button__screen" color="white"  >
            preview
          </Button>
        </div>
      </div>
    </Container>
  );
};
export default SelectionTab;
