export const GET_PRACTICEINFO = "GET_PRACTICEINFO";
export const GET_TOS = "GET_TOS";

export const CLEAR_DATA = "CLEAR_DATA";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const CLEAR_LOADING = "CLEAR_LOADING";



export const GET_PRACTICEINFO_REQUEST = "GET_PRACTICEINFO_REQUEST";
export const GET_PRACTICEINFO_SUCCESS = "GET_PRACTICEINFO_SUCCESS";
export const GET_PRACTICEINFO_FAIL = "GET_PRACTICEINFO_FAIL";
export const UPDATE_PRACTICEINFO_REQUEST= "UPDATE_PRACTICEINFO_REQUEST";

export const SEND_PRACTICE_RECOVERY_CODE_REQUEST = "SEND_PRACTICE_RECOVERY_CODE_REQUEST";
export const SEND_PRACTICE_RECOVERY_CODE_SUCCESS = "SEND_PRACTICE_RECOVERY_CODE_SUCCESS";
export const SEND_PRACTICE_RECOVERY_CODE_FAIL = "SEND_PRACTICE_RECOVERY_CODE_FAIL";
export const SEND_PRACTICE_RECOVERY_CODE_FAIL_ERROR = "SEND_PRACTICE_RECOVERY_CODE_FAIL_ERROR";

export const GET_PRACTICEMOREINFO_REQUEST = "GET_PRACTICEMOREINFO_REQUEST";
export const GET_PRACTICEMOREINFO_SUCCESS = "GET_PRACTICEMOREINFO_SUCCESS";
export const GET_PRACTICEMOREINFO_FAIL = "GET_PRACTICEMOREINFO_FAIL";

export const GET_PRACTICESECURITYQUE_REQUEST = "GET_PRACTICESECURITYQUE_REQUEST";
export const GET_PRACTICESECURITYQUE_SUCCESS = "GET_PRACTICESECURITYQUE_SUCCESS";
export const GET_PRACTICESECURITYQUE_FAIL = "GET_PRACTICESECURITYQUE_FAIL";

export const GET_PRACTICE_PROFILE_REQUEST = "GET_PRACTICE_PROFILE_REQUEST";
export const GET_PRACTICE_PROFILE_SUCCESS = "GET_PRACTICE_PROFILE_SUCCESS";
export const GET_PRACTICE_PROFILE_FAIL = "GET_PRACTICE_PROFILE_FAIL";

export const GET_TOS_REQUEST = "GET_TOS_REQUEST";
export const GET_TOS_SUCCESS = "GET_TOS_SUCCESS";
export const GET_TOS_FAIL = "GET_TOS_FAIL";

export const POST_ACCEPT_TOS_REQUEST = "POST_ACCEPT_TOS_REQUEST";
export const POST_ACCEPT_TOS_SUCCESS = "POST_ACCEPT_TOS_SUCCESS";
export const POST_ACCEPT_TOS_FAIL = "POST_ACCEPT_TOS_FAIL";

export const GET_GENDER_REQUEST = "GET_GENDER_REQUEST";
export const GET_GENDER_SUCCESS = "GET_GENDER_SUCCESS";
export const GET_GENDER_FAIL = "GET_GENDER_FAIL";


export const GET_PRACTICE_USER_REQUEST = "GET_PRACTICE_USER_REQUEST";
export const GET_PRACTICE_USER_SUCCESS = "GET_PRACTICE_USER_SUCCESS";
export const GET_PRACTICE_USER_FAIL = "GET_PRACTICE_USER_FAIL";

export const GET_AUTHORIZED_ALL_USERS_REQUEST = "GET_AUTHORIZED_ALL_USERS_REQUEST";
export const GET_AUTHORIZED_ALL_USERS_SUCCESS = "GET_AUTHORIZED_ALL_USERS_SUCCESS";
export const GET_AUTHORIZED_ALL_USERS_FAIL = "GET_AUTHORIZED_ALL_USERS_FAIL";

export const GET_AUTHORIZED_USER_REQUEST = "GET_AUTHORIZED_USER_REQUEST";
export const GET_AUTHORIZED_USER_SUCCESS = "GET_AUTHORIZED_USER_SUCCESS";
export const GET_AUTHORIZED_USER_FAIL = "GET_AUTHORIZED_USER_FAIL";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const CANCLE_INVITE_AUTHORIZED_USER_REQUEST = "CANCLE_INVITE_AUTHORIZED_USER_REQUEST";
export const CANCLE_INVITE_AUTHORIZED_USER_SUCCESS = "CANCLE_INVITE_AUTHORIZED_USER_SUCCESS";
export const CANCLE_INVITE_AUTHORIZED_USER_FAIL = "CANCLE_INVITE_AUTHORIZED_USER_FAIL";

export const SEND_EMBED_CODE_TO_EMAIL_REQUEST = "SEND_EMBED_CODE_TO_EMAIL_REQUEST";
export const SEND_EMBED_CODE_TO_EMAIL_SUCCESS = "SEND_EMBED_CODE_TO_EMAIL_SUCCESS";
export const SEND_EMBED_CODE_TO_EMAIL_FAIL = "SEND_EMBED_CODE_TO_EMAIL_FAIL";

export const GET_PRACTICE_OWNER_REQUEST = "GET_PRACTICE_OWNER_REQUEST";
export const GET_PRACTICE_OWNER_SUCCESS = "GET_PRACTICE_OWNER_SUCCESS";
export const GET_PRACTICE_OWNER_FAIL = "GET_PRACTICE_OWNER_FAIL";

export const GET_PRACTICE_OWNER_INFO_REQUEST = "GET_PRACTICE_OWNER_INFO_REQUEST";
export const GET_PRACTICE_OWNER_INFO_SUCCESS = "GET_PRACTICE_OWNER_INFO_SUCCESS";
export const GET_PRACTICE_OWNER_INFO_FAIL = "GET_PRACTICE_OWNER_INFO_FAIL";


export const UPDATE_PRACTICE_OWNER_REQUEST = "UPDATE_PRACTICE_OWNER_REQUEST";
export const UPDATE_PRACTICE_OWNER_SUCCESS = "UPDATE_PRACTICE_OWNER_SUCCESS";
export const UPDATE_PRACTICE_OWNER_FAIL = "UPDATE_PRACTICE_OWNER_FAIL";

export const GET_PHONE_VERIFICATION_REQUEST = "GET_PHONE_VERIFICATION_REQUEST";
export const GET_PHONE_VERIFICATION_SUCCESS = "GET_PHONE_VERIFICATION_SUCCESS";
export const GET_PHONE_VERIFICATION_FAIL = "GET_PHONE_VERIFICATION_FAIL";

export const GET_USER_VERIFICATION_REQUEST = "GET_USER_VERIFICATION_REQUEST";
export const GET_USER_VERIFICATION_SUCCESS = "GET_USER_VERIFICATION_SUCCESS";
export const GET_USER_VERIFICATION_FAIL = "GET_USER_VERIFICATION_FAIL";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";


export const GET_DEFAULT_PROFILE_PIC_REQUEST = "GET_DEFAULT_PROFILE_PIC_REQUEST";
export const GET_DEFAULT_PROFILE_PIC_SUCCESS = "GET_DEFAULT_PROFILE_PIC_SUCCESS";
export const GET_DEFAULT_PROFILE_PIC_FAIL = "GET_DEFAULT_PROFILE_PIC_FAIL";


export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_FAIL = "SIGNOUT_FAIL";

export const UPDATE_PRACTICE_PROFILE_REQUEST = "UPDATE_PRACTICE_PROFILE_REQUEST";
export const UPDATE_PRACTICE_PROFILE_SUCCESS = "UPDATE_PRACTICE_PROFILE_SUCCESS";
export const UPDATE_PRACTICE_PROFILE_FAIL = "UPDATE_PRACTICE_PROFILE_FAIL";


export const UPDATE_NEW_LOCATION_PRACTICE_REQUEST = "UPDATE_NEW_LOCATION_PRACTICE_REQUEST";

export const UPDATE_PRACTICE_REQUEST = "UPDATE_PRACTICE_REQUEST";
export const UPDATE_PRACTICE_SUCCESS = "UPDATE_PRACTICE_SUCCESS";
export const UPDATE_PRACTICE_FAIL = "UPDATE_PRACTICE_FAIL";

export const ADD_NEW_LOCATION_PRACTICE_REQUEST = "ADD_NEW_LOCATION_PRACTICE_REQUEST";



export const UPDATE_OWNER_PROFILE_REQUEST = "UPDATE_OWNER_PROFILE_REQUEST";
export const UPDATE_OWNER_PROFILE_SUCCESS = "UPDATE_OWNER_PROFILE_SUCCESS";
export const UPDATE_OWNER_PROFILE_FAIL = "UPDATE_OWNER_PROFILE_FAIL";


export const GET_PRACTICE_USER_LOGIN_REQUEST = "GET_PRACTICE_USER_LOGIN_REQUEST";
export const GET_PRACTICE_USER_LOGIN_SUCCESS = "GET_PRACTICE_USER_LOGIN_SUCCESS";
export const GET_PRACTICE_USER_LOGIN_FAIL = "GET_PRACTICE_USER_LOGIN_FAIL";

export const GET_USER_PROFILE_INFO_REQUEST = "GET_USER_PROFILE_INFO_REQUEST";
export const GET_USER_PROFILE_INFO_SUCCESS = "GET_USER_PROFILE_INFO_SUCCESS";
export const GET_USER_PROFILE_INFO_FAIL = "GET_USER_PROFILE_INFO_FAIL";

export const GET_PRACTICE_USER_LOGOUT_REQUEST = "GET_PRACTICE_USER_LOGOUT_REQUEST";
export const GET_PRACTICE_USER_LOGOUT_SUCCESS = "GET_PRACTICE_USER_LOGOUT_SUCCESS";
export const GET_PRACTICE_USER_LOGOUT_FAIL = "GET_PRACTICE_USER_LOGOUT_FAIL";

export const GET_BILLING_CARD_INFO_REQUEST = "GET_BILLING_CARD_INFO_REQUEST";
export const GET_BILLING_CARD_INFO_SUCCESS = "GET_BILLING_CARD_INFO_SUCCESS";
export const GET_BILLING_CARD_INFO_FAIL = "GET_BILLING_CARD_INFO_FAIL";

export const UPDATE_BILLING_CARD_INFO_REQUEST = "UPDATE_BILLING_CARD_INFO_REQUEST";
export const UPDATE_BILLING_CARD_INFO_SUCCESS = "UPDATE_BILLING_CARD_INFO_SUCCESS";
export const UPDATE_BILLING_CARD_INFO_FAIL = "UPDATE_BILLING_CARD_INFO_FAIL";

export const ADD_BILLING_CARD_INFO_REQUEST = "ADD_BILLING_CARD_INFO_REQUEST";
export const ADD_BILLING_CARD_INFO_SUCCESS = "ADD_BILLING_CARD_INFO_SUCCESS";
export const ADD_BILLING_CARD_INFO_FAIL = "ADD_BILLING_CARD_INFO_FAIL";

export const CHANGE_BILLING_CARD_STATUS_REQUEST = "CHANGE_BILLING_CARD_STATUS_REQUEST";
export const CHANGE_BILLING_CARD_STATUS_SUCCESS = "CHANGE_BILLING_CARD_STATUS_SUCCESS";
export const CHANGE_BILLING_CARD_STATUS_FAIL = "CHANGE_BILLING_CARD_STATUS_FAIL";

export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SUCCESS_UPLOAD_FILE = 'SUCCESS_UPLOAD_FILE';
export const FAILURE_UPLOAD_FILE = 'FAILURE_UPLOAD_FILE';

export const UPDATE_PASSCODE_REQUEST = "UPDATE_PASSCODE_REQUEST";
export const UPDATE_PASSCODE_SUCCESS = "UPDATE_PASSCODE_SUCCESS";
export const UPDATE_PASSCODE_FAIL = "UPDATE_PASSCODE_FAIL";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

export const INVITE_USER_TO_CTEATE_PROFILE_REQUEST = "INVITE_USER_TO_CTEATE_PROFILE_REQUEST";
export const INVITE_USER_TO_CTEATE_PROFILE_SUCCESS = "INVITE_USER_TO_CTEATE_PROFILE_SUCCESS";
export const INVITE_USER_TO_CTEATE_PROFILE_FAIL = "INVITE_USER_TO_CTEATE_PROFILE_FAIL";

export const REINVITE_USER_TO_CTEATE_PROFILE_REQUEST = "REINVITE_USER_TO_CTEATE_PROFILE_REQUEST";
export const REINVITE_USER_TO_CTEATE_PROFILE_SUCCESS = "ReINVITE_USER_TO_CTEATE_PROFILE_SUCCESS";
export const REINVITE_USER_TO_CTEATE_PROFILE_FAIL = "REINVITE_USER_TO_CTEATE_PROFILE_FAIL";

export const UPDATE_USER_PROFILE_IMAGE_REQUEST = "UPDATE_USER_PROFILE_IMAGE_REQUEST";
export const UPDATE_USER_PROFILE_IMAGE_SUCCESS = "UPDATE_USER_PROFILE_IMAGE_SUCCESS";
export const UPDATE_USER_PROFILE_IMAGE_FAIL = "UPDATE_USER_PROFILE_IMAGE_FAIL";

export const UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST = "UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST";
export const UPDATE_USER_PROFILE_ABOUT_INFO_SUCCESS = "UPDATE_USER_PROFILE_ABOUT_INFO_SUCCESS";
export const UPDATE_USER_PROFILE_ABOUT_INFO_FAIL = "UPDATE_USER_PROFILE_ABOUT_INFO_FAIL";

export const DELETE_USER_PROFILE_REQUEST = "DELETE_USER_PROFILE_REQUEST";
export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS";
export const DELETE_USER_PROFILE_FAIL = "DELETE_USER_PROFILE_FAIL";

export const DELETE_MULTY_USER_PROFILE_REQUEST = "DELETE_MULTY_USER_PROFILE_REQUEST";
export const DELETE_MULTY_USER_PROFILE_SUCCESS = "DELETE_MULTY_USER_PROFILE_SUCCESS";
export const DELETE_MULTY_USER_PROFILE_FAIL = "DELETE_MULTY_USER_PROFILE_FAIL";

export const GET_USER_IDENTITY_REQUEST = "GET_USER_IDENTITY_REQUEST";
export const GET_USER_IDENTITY_SUCCESS = "GET_USER_IDENTITY_SUCCESS";
export const GET_USER_IDENTITY_FAIL = "GET_USER_IDENTITY_FAIL";

export const GET_USER_PROFILE_VERIFY_REQUEST = "GET_USER_PROFILE_VERIFY_REQUEST";
export const GET_USER_PROFILE_VERIFY_SUCCESS = "GET_USER_PROFILE_VERIFY_SUCCESS";
export const GET_USER_PROFILE_VERIFY_FAIL = "GET_USER_PROFILE_VERIFY_FAIL";


export const RESET_REFER_FREE_MONTH_REQUEST = "RESET_REFER_FREE_MONTH_REQUEST";
export const POST_REFER_FREE_MONTH_REQUEST = "POST_REFER_FREE_MONTH_REQUEST";
export const POST_REFER_FREE_MONTH_SUCCESS = "POST_REFER_FREE_MONTH_SUCCESS";
export const POST_REFER_FREE_MONTH_FAIL = "POST_REFER_FREE_MONTH_FAIL";

export const GET_REFERRAL_LIST_REQUEST = "GET_REFERRAL_LIST_REQUEST";
export const GET_REFERRAL_LIST_SUCCESS = "GET_REFERRAL_LIST_SUCCESS";
export const GET_REFERRAL_LIST_FAIL = "GET_REFERRAL_LIST_FAIL";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

export const FILTER_BY_KEYWORD = "FILTER_BY_KEYWORD";
export const FILTER_BY_LETEST = "FILTER_BY_LETEST";
export const FILTER_BY_SHOW_ONLY_NEW = "FILTER_BY_SHOW_ONLY_NEW";

export const UPDATE_SYSTEM_LANGUAGE_REQUEST = "UPDATE_SYSTEM_LANGUAGE_REQUEST";
export const UPDATE_SYSTEM_LANGUAGE_SUCCESS = "UPDATE_SYSTEM_LANGUAGE_SUCCESS";
export const UPDATE_SYSTEM_LANGUAGE_FAIL = "UPDATE_SYSTEM_LANGUAGE_FAIL";

export const PASSCODE_RECOVERY_REQUEST = "PASSCODE_RECOVERY_REQUEST";
export const PASSCODE_RECOVERY_SUCCESS = "PASSCODE_RECOVERY_SUCCESS";
export const PASSCODE_RECOVERY_FAIL = "PASSCODE_RECOVERY_FAIL";

export const GET_DOMAIN_LIST_REQUEST = "GET_DOMAIN_LIST_REQUEST";
export const GET_DOMAIN_LIST_SUCCESS = "GET_DOMAIN_LIST_SUCCESS";
export const GET_DOMAIN_LIST_FAIL = "GET_DOMAIN_LIST_FAIL";

export const GET_SUBSCRIBE_INFO_REQUEST = "GET_SUBSCRIBE_INFO_REQUEST";
export const GET_SUBSCRIBE_INFO_SUCCESS = "GET_SUBSCRIBE_INFO_SUCCESS";
export const GET_SUBSCRIBE_INFO_FAIL = "GET_SUBSCRIBE_INFO_FAIL";

export const GET_SUBSCRIBE_PRICE_OPTION_REQUEST = "GET_SUBSCRIBE_PRICE_OPTION_REQUEST";
export const GET_SUBSCRIBE_PRICE_OPTION_SUCCESS = "GET_SUBSCRIBE_PRICE_OPTION_SUCCESS";
export const GET_SUBSCRIBE_PRICE_OPTION_FAIL = "GET_SUBSCRIBE_PRICE_OPTION_FAIL";

export const GET_SUBSCRIBE_PRICE_CALC_REQUEST = "GET_SUBSCRIBE_PRICE_CALC_REQUEST";
export const GET_SUBSCRIBE_PRICE_CALC_SUCCESS = "GET_SUBSCRIBE_PRICE_CALC_SUCCESS";
export const GET_SUBSCRIBE_PRICE_CALC_FAIL = "GET_SUBSCRIBE_PRICE_CALC_FAIL";

export const POST_SUBSCRIBE_REQUEST = "POST_SUBSCRIBE_REQUEST";
export const POST_SUBSCRIBE_SUCCESS = "POST_SUBSCRIBE_SUCCESS";
export const POST_SUBSCRIBE_FAIL = "POST_SUBSCRIBE_FAIL";

export const POST_NEW_LOCATION_REQUEST = "POST_NEW_LOCATION_REQUEST";
export const POST_NEW_LOCATION_SUCCESS = "POST_NEW_LOCATION_SUCCESS";
export const POST_NEW_LOCATION_FAIL = "POST_NEW_LOCATION_FAIL";

export const POST_LOGIN_PRACTICE_LOCATION_REQUEST = "POST_LOGIN_PRACTICE_LOCATION_REQUEST";
export const POST_LOGIN_PRACTICE_LOCATION_SUCCESS = "POST_LOGIN_PRACTICE_LOCATION_SUCCESS";
export const POST_LOGIN_PRACTICE_LOCATION_FAIL = "POST_LOGIN_PRACTICE_LOCATION_FAIL";

export const POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUEST = "POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUEST";
export const POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUESTLOADING = "POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUESTLOADING";
export const POST_SUBSCRIBE_ADDITIONAL_DOMAIN_SUCCESS = "POST_SUBSCRIBE_ADDITIONAL_DOMAIN_SUCCESS";
export const POST_SUBSCRIBE_ADDITIONAL_DOMAIN_FAIL = "POST_SUBSCRIBE_ADDITIONAL_DOMAIN_FAIL";

export const CREATE_DOMAIN_REQUEST = "CREATE_DOMAIN_REQUEST";
export const CREATE_DOMAIN_SUCCESS = "CREATE_DOMAIN_SUCCESS";
export const CREATE_DOMAIN_FAIL = "CREATE_DOMAIN_FAIL";

export const REMOVE_DOMAIN_REQUEST = "REMOVE_DOMAIN_REQUEST";
export const REMOVE_DOMAIN_SUCCESS = "REMOVE_DOMAIN_SUCCESS";
export const REMOVE_DOMAIN_FAIL = "REMOVE_DOMAIN_FAIL";

export const UPDATE_DOMAIN_REQUEST = "UPDATE_DOMAIN_REQUEST";
export const UPDATE_DOMAIN_SUCCESS = "UPDATE_DOMAIN_SUCCESS";
export const UPDATE_DOMAIN_FAIL = "UPDATE_DOMAIN_FAIL";

export const WEBSITE_MEDIA_REQUEST = "WEBSITE_MEDIA_REQUEST";
export const WEBSITE_MEDIA_SUCCESS = "WEBSITE_MEDIA_SUCCESS";
export const WEBSITE_MEDIA_FAILURE = "WEBSITE_MEDIA_FAILURE";

export const GET_EMAIL_PREFERENCES_REQUEST = "GET_EMAIL_PREFERENCES_REQUEST";
export const GET_EMAIL_PREFERENCES_SUCCESS = "GET_EMAIL_PREFERENCES_SUCCESS";
export const GET_EMAIL_PREFERENCES_FAILURE = "GET_EMAIL_PREFERENCES_FAILURE";

export const GET_EMAIL_PREFERENCES_LIST_REQUEST = "GET_EMAIL_PREFERENCES_LIST_REQUEST";
export const GET_EMAIL_PREFERENCES_LIST_SUCCESS = "GET_EMAIL_PREFERENCES_LIST_SUCCESS";
export const GET_EMAIL_PREFERENCES_LIST_FAILURE = "GET_EMAIL_PREFERENCES_LIST_FAILURE";

export const POST_EMAIL_PREFERENCES_REQUEST = "POST_EMAIL_PREFERENCES_REQUEST";
export const POST_EMAIL_PREFERENCES_SUCCESS = "POST_EMAIL_PREFERENCES_SUCCESS";
export const POST_EMAIL_PREFERENCES_FAILURE = "POST_EMAIL_PREFERENCES_FAILURE";

export const GET_DEFAULT_CAPTION_SIZE_REQUEST = "GET_DEFAULT_CAPTION_SIZE_REQUEST";
export const GET_DEFAULT_CAPTION_SIZE_SUCCESS = "GET_DEFAULT_CAPTION_SIZE_SUCCESS";
export const GET_DEFAULT_CAPTION_SIZE_FAIL = "GET_DEFAULT_CAPTION_SIZE_FAIL";

export const GET_ACCESS_CODE_REQUEST = "GET_ACCESS_CODE_REQUEST";
export const GET_ACCESS_CODE_SUCCESS = "GET_ACCESS_CODE_SUCCESS";
export const GET_ACCESS_CODE_FAIL = "GET_ACCESS_CODE_FAIL";

export const RESET_ACCESS_CODE_REQUEST = "RESET_ACCESS_CODE_REQUEST";
export const RESET_ACCESS_CODE_SUCCESS = "RESET_ACCESS_CODE_SUCCESS";
export const RESET_ACCESS_CODE_FAIL = "RESET_ACCESS_CODE_FAIL";

export const GET_PRACTICE_REQUEST = "GET_PRACTICE_REQUEST";
export const GET_PRACTICE_SUCCESS = "GET_PRACTICE_SUCCESS";
export const GET_PRACTICE_FAIL = "GET_PRACTICE_FAIL";

export const UPDATE_AUTHORIZED_USER_REQUEST = "UPDATE_AUTHORIZED_USER_REQUEST";
export const UPDATE_AUTHORIZED_USER_SUCCESS = "UPDATE_AUTHORIZED_USER_SUCCESS";
export const UPDATE_AUTHORIZED_USER_FAIL = "UPDATE_AUTHORIZED_USER_FAIL";

// chairside-media API
export const GET_CHAIRSIDE_MEDIA_REQUEST = "GET_CHAIRSIDE_MEDIA_REQUEST";
export const GET_CHAIRSIDE_MEDIA_SUCCESS = "GET_CHAIRSIDE_MEDIA_SUCCESS";
export const GET_CHAIRSIDE_MEDIA_FAILURE = "GET_CHAIRSIDE_MEDIA_FAILURE";

//################ DD-11
export const GET_SHARE_VIDEO_LINK_REQUEST = "GET_SHARE_VIDEO_LINK_REQUEST";
export const GET_SHARE_VIDEO_LINK_SUCCESS = "GET_SHARE_VIDEO_LINK_SUCCESS";
export const GET_SHARE_VIDEO_LINK_FAILURE = "GET_SHARE_VIDEO_LINK_FAILURE";

export const SHARE_VIDEO_LINK_REQUEST = "SHARE_VIDEO_LINK_REQUEST";
export const SHARE_VIDEO_LINK_SUCCESS = "SHARE_VIDEO_LINK_SUCCESS";
export const SHARE_VIDEO_LINK_FAILURE = "SHARE_VIDEO_LINK_FAILURE";

export const SHARE_VIDEO_LINK_CLEAR_REQUEST = "SHARE_VIDEO_LINK_CLEAR_REQUEST";
export const SHARE_VIDEO_LINK_ID_REQUEST = "SHARE_VIDEO_LINK_ID_REQUEST";



//################ DD-11

// chairside-media CC API
export const GET_CHAIRSIDE_MEDIA_CC_REQUEST = "GET_CHAIRSIDE_MEDIA_CC_REQUEST";
export const GET_CHAIRSIDE_MEDIA_CC_SUCCESS = "GET_CHAIRSIDE_MEDIA_CC_SUCCESS";
export const GET_CHAIRSIDE_MEDIA_CC_FAILURE = "GET_CHAIRSIDE_MEDIA_CC_FAILURE";

// chairside-media CC API
export const UPDATE_CHAIRSIDE_MEDIA_CC_REQUEST = "UPDATE_CHAIRSIDE_MEDIA_CC_REQUEST";
export const UPDATE_CHAIRSIDE_MEDIA_CC_SUCCESS = "UPDATE_CHAIRSIDE_MEDIA_CC_SUCCESS";
export const UPDATE_CHAIRSIDE_MEDIA_CC_FAILURE = "UPDATE_CHAIRSIDE_MEDIA_CC_FAILURE";

// DELET My List Chairside Education
export const DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST = "DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST";
export const DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS = "DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS";
export const DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE = "DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE";

// POST My List Chairside Education
export const POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST = "POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST";
export const POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS = "POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS";
export const POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE = "POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE";

// chairside-media CC API
export const GET_CHAIRSIDE_MEDIA_CC_LIST_REQUEST = "GET_CHAIRSIDE_MEDIA_CC_LIST_REQUEST";
export const GET_CHAIRSIDE_MEDIA_CC_LIST_SUCCESS = "GET_CHAIRSIDE_MEDIA_CC_LIST_SUCCESS";
export const GET_CHAIRSIDE_MEDIA_CC_LIST_FAILURE = "GET_CHAIRSIDE_MEDIA_CC_LIST_FAILURE";

// POST CC Status Chairside Education
export const POST_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST = "POST_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST";
export const POST_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS = "POST_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS";
export const POST_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE = "POST_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE";

// DELETE CC Status Chairside Education
export const DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST = "DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST";
export const DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS = "DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS";
export const DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE = "DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE";

// chairside-media/category-list API
export const GET_CHAIRSIDE_CATEGORY_LIST_REQUEST = "GET_CHAIRSIDE_CATEGORY_LIST_REQUEST";
export const GET_CHAIRSIDE_CATEGORY_LIST_SUCCESS = "GET_CHAIRSIDE_CATEGORY_LIST_SUCCESS";
export const GET_CHAIRSIDE_CATEGORY_LIST_FAILURE = "GET_CHAIRSIDE_CATEGORY_LIST_FAILURE";

// chairside-media/?chairSideMediaId=/language/&languageId= API
export const GET_CHAIRSIDE_MEDIA_LANGUAGE_REQUEST = "GET_CHAIRSIDE_MEDIA_LANGUAGE_REQUEST";
export const GET_CHAIRSIDE_MEDIA_LANGUAGE_SUCCESS = "GET_CHAIRSIDE_MEDIA_LANGUAGE_SUCCESS";
export const GET_CHAIRSIDE_MEDIA_LANGUAGE_FAILURE = "GET_CHAIRSIDE_MEDIA_LANGUAGE_FAILURE";

// chairside-media/mylist API Start
export const POST_CHAIRSIDE_MEDIA_MYLIST_REQUEST = "POST_CHAIRSIDE_MEDIA_MYLIST_REQUEST";
export const POST_CHAIRSIDE_MEDIA_MYLIST_SUCCESS = "POST_CHAIRSIDE_MEDIA_MYLIST_SUCCESS";
export const POST_CHAIRSIDE_MEDIA_MYLIST_FAILURE = "POST_CHAIRSIDE_MEDIA_MYLIST_FAILURE";

// chairside-media/ video section
export const GET_VIDEO_SECTION_REQUEST = "GET_VIDEO_SECTION_REQUEST";
export const GET_VIDEO_SECTION_SUCCESS = "GET_VIDEO_SECTION_SUCCESS";
export const GET_VIDEO_SECTION_FAILURE = "GET_VIDEO_SECTION_FAILURE";

// website-media/config
export const WEBSITE_MEDIA_CONFIG_REQUEST = "WEBSITE_MEDIA_CONFIG_REQUEST";
export const WEBSITE_MEDIA_CONFIG_SUCCESS = "WEBSITE_MEDIA_CONFIG_SUCCESS";
export const WEBSITE_MEDIA_CONFIG_FAILURE = "WEBSITE_MEDIA_CONFIG_FAILURE";

// website-media/layouts/html
export const WEBSITE_MEDIA_LAYOUT_REQUEST = "WEBSITE_MEDIA_LAYOUT_REQUEST";
export const WEBSITE_MEDIA_LAYOUT_SUCCESS = "WEBSITE_MEDIA_LAYOUT_SUCCESS";
export const WEBSITE_MEDIA_LAYOUT_FAILURE = "WEBSITE_MEDIA_LAYOUT_FAILURE";

// website-media/html-code
export const WEBSITE_MEDIA_HTML_CODE_REQUEST = "WEBSITE_MEDIA_HTML_CODE_REQUEST";
export const WEBSITE_MEDIA_HTML_CODE_SUCCESS = "WEBSITE_MEDIA_HTML_CODE_SUCCESS";
export const WEBSITE_MEDIA_HTML_CODE_FAILURE = "WEBSITE_MEDIA_HTML_CODE_FAILURE";

// website-media/playlist
export const WEBSITE_MEDIA_GET_PLAYLIST_DATA_REQUEST = "WEBSITE_MEDIA_GET_PLAYLIST_DATA_REQUEST";
export const WEBSITE_MEDIA_GET_PLAYLIST_DATA_SUCCESS = "WEBSITE_MEDIA_GET_PLAYLIST_DATA_SUCCESS";
export const WEBSITE_MEDIA_GET_PLAYLIST_DATA_FAILURE = "WEBSITE_MEDIA_GET_PLAYLIST_DATA_FAILURE";

// website-media/playlist POST
export const WEBSITE_MEDIA_PLAYLIST_POST_REQUEST = "WEBSITE_MEDIA_PLAYLIST_POST_REQUEST";
export const WEBSITE_MEDIA_PLAYLIST_POST_SUCCESS = "WEBSITE_MEDIA_PLAYLIST_POST_SUCCESS";
export const WEBSITE_MEDIA_PLAYLIST_POST_FAILURE = "WEBSITE_MEDIA_PLAYLIST_POST_FAILURE";

// website-media/config POST
export const WEBSITE_MEDIA_CONFIG_POST_REQUEST = "WEBSITE_MEDIA_CONFIG_POST_REQUEST";
export const WEBSITE_MEDIA_CONFIG_POST_SUCCESS = "WEBSITE_MEDIA_CONFIG_POST_SUCCESS";
export const WEBSITE_MEDIA_CONFIG_POST_FAILURE = "WEBSITE_MEDIA_CONFIG_POST_FAILURE";

// website-media/playlist DELETE
export const WEBSITE_MEDIA_DELETE_PLAYLIST_REQUEST = "WEBSITE_MEDIA_DELETE_PLAYLIST_REQUEST";
export const WEBSITE_MEDIA_DELETE_PLAYLIST_SUCCESS = "WEBSITE_MEDIA_DELETE_PLAYLIST_SUCCESS";
export const WEBSITE_MEDIA_DELETE_PLAYLIST_FAILURE = "WEBSITE_MEDIA_DELETE_PLAYLIST_FAILURE";

// invoice API
export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILURE = "GET_INVOICE_FAILURE";

// invoice-view API
export const GET_INVOICEVIEW_REQUEST = "GET_INVOICEVIEW_REQUEST";
export const GET_INVOICEVIEW_SUCCESS = "GET_INVOICEVIEW_SUCCESS";
export const GET_INVOICEVIEW_FAILURE = "GET_INVOICEVIEW_FAILURE";


// authorized-device API
export const GET_AUTHORIZED_DEVICE_REQUEST = "GET_AUTHORIZED_DEVICE_REQUEST";
export const GET_AUTHORIZED_DEVICE_SUCCESS = "GET_AUTHORIZED_DEVICE_SUCCESS";
export const GET_AUTHORIZED_DEVICE_FAILURE = "GET_AUTHORIZED_DEVICE_FAILURE";

// unauthorized-device API
export const POST_UNAUTHORIZED_DEVICE_REQUEST = "POST_UNAUTHORIZED_DEVICE_REQUEST";
export const POST_UNAUTHORIZED_DEVICE_SUCCESS = "POST_UNAUTHORIZED_DEVICE_SUCCESS";
export const POST_UNAUTHORIZED_DEVICE_FAIL = "POST_UNAUTHORIZED_DEVICE_FAIL";

// remove-devices API
export const REMOVE_AUTHORIZED_DEVICE_REQUEST = "REMOVE_AUTHORIZED_DEVICE_REQUEST";
export const REMOVE_AUTHORIZED_DEVICE_SUCCESS = "REMOVE_AUTHORIZED_DEVICE_SUCCESS";
export const REMOVE_AUTHORIZED_DEVICE_FAILURE = "REMOVE_AUTHORIZED_DEVICE_FAILURE";

// subscribe API
export const GET_SUBSCRIBE_REQUEST = "GET_SUBSCRIBE_REQUEST";
export const GET_SUBSCRIBE_SUCCESS = "GET_SUBSCRIBE_SUCCESS";
export const GET_SUBSCRIBE_FAILURE = "GET_SUBSCRIBE_FAILURE";

// Get Option API
export const GET_OPTION_REQUEST = "GET_OPTION_REQUEST";
export const GET_OPTION_SUCCESS = "GET_OPTION_SUCCESS";
export const GET_OPTION_FAILURE = "GET_OPTION_FAILURE";

//subscribe device
export const POST_SUBSCRIBE_DEVICE_REQUEST = "POST_SUBSCRIBE_DEVICE_REQUEST";
export const POST_SUBSCRIBE_DEVICE_SUCCESS = "POST_SUBSCRIBE_DEVICE_SUCCESS";
export const POST_SUBSCRIBE_DEVICE_FAILURE = "POST_SUBSCRIBE_DEVICE_FAILURE";

// helper/timezone
export const GET_HELPER_TIMEZONE_REQUEST = 'GET_HELPER_TIMEZONE_REQUEST';
export const GET_HELPER_TIMEZONE_SUCCESS = 'GET_HELPER_TIMEZONE_SUCCESS';
export const GET_HELPER_TIMEZONE_FAILURE = 'GET_HELPER_TIMEZONE_FAILURE';

// waiting-room-media/channel/2/footer/custom-messages
export const GET_FOOTER_CUSTOM_MESSAGES_REQUEST = "GET_FOOTER_CUSTOM_MESSAGES_REQUEST"
export const GET_FOOTER_CUSTOM_MESSAGES_SUCCESS = "GET_FOOTER_CUSTOM_MESSAGES_SUCCESS"
export const GET_FOOTER_CUSTOM_MESSAGES_FAILURE = "GET_FOOTER_CUSTOM_MESSAGES_FAILURE"

// waiting-room-media/channel/trivia
export const GET_WAITING_ROOM_CHANNEL_TRIVIA_REQUEST = "GET_WAITING_ROOM_CHANNEL_TRIVIA_REQUEST";
export const GET_WAITING_ROOM_CHANNEL_TRIVIA_SUCCESS = "GET_WAITING_ROOM_CHANNEL_TRIVIA_SUCCESS";
export const GET_WAITING_ROOM_CHANNEL_TRIVIA_FAILURE = "GET_WAITING_ROOM_CHANNEL_TRIVIA_FAILURE";

// waiting-room-media/channel/2/live-weather-data
export const GET_WAITINGROOM_LIVE_WEATHER_DATA_REQUEST = "GET_WAITINGROOM_LIVE_WEATHER_DATA_REQUEST";
export const GET_WAITINGROOM_LIVE_WEATHER_DATA_SUCCESS = "GET_WAITINGROOM_LIVE_WEATHER_DATA_SUCCESS";
export const GET_WAITINGROOM_LIVE_WEATHER_DATA_FAILURE = "GET_WAITINGROOM_LIVE_WEATHER_DATA_FAILURE";

// waiting-room-media/channel/2/footer/time
export const GET_WAITINGROOM_FOOTER_TIME_REQUEST = "GET_WAITINGROOM_FOOTER_TIME_REQUEST";
export const GET_WAITINGROOM_FOOTER_TIME_SUCCESS = "GET_WAITINGROOM_FOOTER_TIME_SUCCESS";
export const GET_WAITINGROOM_FOOTER_TIME_FAILURE = "GET_WAITINGROOM_FOOTER_TIME_FAILURE";

// waiting-room-media/channel/header/{channelId}
export const POST_WAITINGROOM_MEDIA_HEADER_REQUEST = "POST_WAITINGROOM_MEDIA_HEADER_REQUEST";
export const POST_WAITINGROOM_MEDIA_HEADER_SUCCESS = "POST_WAITINGROOM_MEDIA_HEADER_SUCCESS";
export const POST_WAITINGROOM_MEDIA_HEADER_FAILURE = "POST_WAITINGROOM_MEDIA_HEADER_FAILURE";

// POST Footer Time in Waiting Room Channel
export const POST_WAITINGROOM_MEDIA_FOOTER_TIME_REQUEST = "POST_WAITINGROOM_MEDIA_FOOTER_TIME_REQUEST";
export const POST_WAITINGROOM_MEDIA_FOOTER_TIME_SUCCESS = "POST_WAITINGROOM_MEDIA_FOOTER_TIME_SUCCESS";
export const POST_WAITINGROOM_MEDIA_FOOTER_TIME_FAILURE = "POST_WAITINGROOM_MEDIA_FOOTER_TIME_FAILURE";

// waiting-room-media/channel
export const GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_REQUEST = "GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_REQUEST";
export const GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_SUCCESS = "GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_SUCCESS";
export const GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_FAILURE = "GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_FAILURE";

// waiting-room-media/channel/id
export const GET_WAITINGROOM_MEDIA_CHANNEL_REQUEST = "GET_WAITINGROOM_MEDIA_CHANNEL_REQUEST";
export const GET_WAITINGROOM_MEDIA_CHANNEL_SUCCESS = "GET_WAITINGROOM_MEDIA_CHANNEL_SUCCESS";
export const GET_WAITINGROOM_MEDIA_CHANNEL_FAILURE = "GET_WAITINGROOM_MEDIA_CHANNEL_FAILURE";

export const RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST = "RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST";
export const RESTORE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS = "RESTORE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS";
export const RESTORE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE = "RESTORE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE";

export const DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST = "DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST";
export const DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS = "DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS";
export const DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE = "DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE";

// POST Footer Weather in Waiting Room
export const POST_WAITINGROOM_MEDIA_WEATHER_REQUEST = "POST_WAITINGROOM_MEDIA_WEATHER_REQUEST";
export const POST_WAITINGROOM_MEDIA_WEATHER_SUCCESS = "POST_WAITINGROOM_MEDIA_WEATHER_SUCCESS";
export const POST_WAITINGROOM_MEDIA_WEATHER_FAILURE = "POST_WAITINGROOM_MEDIA_WEATHER_FAILURE";

// POST Footer Custom Message in Waiting room
export const POST_CHANNELWISE_CUSTOM_MESSAGES_REQUEST = "POST_CHANNELWISE_CUSTOM_MESSAGES_REQUEST";
export const POST_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS = "POST_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS";
export const POST_CHANNELWISE_CUSTOM_MESSAGES_FAILURE = "POST_CHANNELWISE_CUSTOM_MESSAGES_FAILURE";

// GET Waiting Room Weather in Waiting Room
export const GET_WAITINGROOM_WEATHER_REQUEST = "GET_WAITINGROOM_WEATHER_REQUEST";
export const GET_WAITINGROOM_WEATHER_SUCCESS = "GET_WAITINGROOM_WEATHER_SUCCESS";
export const GET_WAITINGROOM_WEATHER_FAILURE = "GET_WAITINGROOM_WEATHER_FAILURE";

// DELETE Footer Custom Message in Waiting Room
export const DELETE_WAITINGROOM_CUSTOM_MESSAGES_REQUEST = "DELETE_WAITINGROOM_CUSTOM_MESSAGES_REQUEST";
export const DELETE_WAITINGROOM_CUSTOM_MESSAGES_SUCCESS = "DELETE_WAITINGROOM_CUSTOM_MESSAGES_SUCCESS";
export const DELETE_WAITINGROOM_CUSTOM_MESSAGES_FAILURE = "DELETE_WAITINGROOM_CUSTOM_MESSAGES_FAILURE";


// GET Location Listing
export const GET_LOCATION_LISTING_REQUEST = "GET_LOCATION_LISTING_REQUEST";
export const GET_LOCATION_LISTING_SUCCESS = "GET_LOCATION_LISTING_SUCCESS";
export const GET_UNASSIGN_LOCATION_LISTING_SUCCESS = "GET_UNASSIGN_LOCATION_LISTING_SUCCESS";
export const GET_LOCATION_LISTING_FAILURE = "GET_LOCATION_LISTING_FAILURE";
// GET Location Manager Listing
export const GET_LOCATION_MANAGER_LISTING_REQUEST = "GET_LOCATION_MANAGER_LISTING_REQUEST";
export const GET_LOCATION_MANAGER_LISTING_SUCCESS = "GET_LOCATION_MANAGER_LISTING_SUCCESS";
export const GET_LOCATION_MANAGER_LISTING_FAILURE = "GET_LOCATION_MANAGER_LISTING_FAILURE";


// GET website-media/layouts
export const GET_WEBSITE_MEDIA_LAYOUTS_REQUEST = "GET_WEBSITE_MEDIA_LAYOUTS_REQUEST";
export const GET_WEBSITE_MEDIA_LAYOUTS_SUCCESS = "GET_WEBSITE_MEDIA_LAYOUTS_SUCCESS";
export const GET_WEBSITE_MEDIA_LAYOUTS_FAILURE = "GET_WEBSITE_MEDIA_LAYOUTS_FAILURE";

// GET website-media/colours
export const GET_WEBSITE_MEDIA_COLOURS_REQUEST = "GET_WEBSITE_MEDIA_COLOURS_REQUEST";
export const GET_WEBSITE_MEDIA_COLOURS_SUCCESS = "GET_WEBSITE_MEDIA_COLOURS_SUCCESS";
export const GET_WEBSITE_MEDIA_COLOURS_FAILURE = "GET_WEBSITE_MEDIA_COLOURS_FAILURE";

// GET /helper/newsfeed
export const GET_HELPER_NEWSFEED_REQUEST = "GET_HELPER_NEWSFEED_REQUEST";
export const GET_HELPER_NEWSFEED_SUCCESS = "GET_HELPER_NEWSFEED_SUCCESS";
export const GET_HELPER_NEWSFEED_FAILURE = "GET_HELPER_NEWSFEED_FAILURE";

// post newsfeed
export const POST_NEWSFEED_REQUEST = "POST_NEWSFEED_REQUEST";
export const POST_NEWSFEED_SUCCESS = "POST_NEWSFEED_SUCCESS";
export const POST_NEWSFEED_FAILURE = "POST_NEWSFEED_FAILURE";

// waiting-room-media/channel/2
export const GET_WAITINGROOM_DISPLAY_OPTIONS_REQUEST = "GET_WAITINGROOM_DISPLAY_OPTIONS_REQUEST";
export const GET_WAITINGROOM_DISPLAY_OPTIONS_SUCCESS = "GET_WAITINGROOM_DISPLAY_OPTIONS_SUCCESS";
export const GET_WAITINGROOM_DISPLAY_OPTIONS_FAILURE = "GET_WAITINGROOM_DISPLAY_OPTIONS_FAILURE";

// waiting-room-media/channel/get-theme
export const GET_WAITINGROOM_THEME_LIST_REQUEST = "GET_WAITINGROOM_THEME_LIST_REQUEST";
export const GET_WAITINGROOM_THEME_LIST_SUCCESS = "GET_WAITINGROOM_THEME_LIST_SUCCESS";
export const GET_WAITINGROOM_THEME_LIST_FAILURE = "GET_WAITINGROOM_THEME_LIST_FAILURE";

// REMOVE Waiting Room Channel
export const DELETE_WAITINGROOM_MEDIA_CHANNEL_REQUEST = "DELETE_WAITINGROOM_MEDIA_CHANNEL_REQUEST";
export const DELETE_WAITINGROOM_MEDIA_CHANNEL_SUCCESS = "DELETE_WAITINGROOM_MEDIA_CHANNEL_SUCCESS";
export const DELETE_WAITINGROOM_MEDIA_CHANNEL_FAILURE = "DELETE_WAITINGROOM_MEDIA_CHANNEL_FAILURE";

// PUT Waiting Room Channel Rename
export const UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_REQUEST = "UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_REQUEST";
export const UPDATE_WAITINGROOM_MEDIA_CHANNEL_LOADING_REQUEST = "UPDATE_WAITINGROOM_MEDIA_CHANNEL_LOADING_REQUEST";
export const UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_SUCCESS = "UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_SUCCESS";
export const UPDATE_WAITINGROOM_MEDIA_CHANNEL_CC_STATUS_SUCCESS = "UPDATE_WAITINGROOM_MEDIA_CHANNEL_CC_STATUS_SUCCESS";
export const UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_FAILURE = "UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_FAILURE";

// Mylist
export const MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_REQUEST = "MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_REQUEST";
export const MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_SUCCESS = "MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_SUCCESS";
export const MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_FAILURE = "MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_FAILURE";

// CREATE Waiting Room Channel
export const CREATE_WAITING_ROOM_CHANNEL_REQUEST = "CREATE_WAITING_ROOM_CHANNEL_REQUEST";
export const CREATE_WAITING_ROOM_CHANNEL_SUCCESS = "CREATE_WAITING_ROOM_CHANNEL_SUCCESS";
export const CREATE_WAITING_ROOM_CHANNEL_FAILURE = "CREATE_WAITING_ROOM_CHANNEL_FAILURE";

// GET UPDATE Waiting Room Channel
export const UPDATE_WAITING_ROOM_CHANNEL_REQUEST = "UPDATE_WAITING_ROOM_CHANNEL_REQUEST";
export const UPDATE_WAITING_ROOM_CHANNEL_SUCCESS = "UPDATE_WAITING_ROOM_CHANNEL_SUCCESS";
export const UPDATE_WAITING_ROOM_CHANNEL_FAILURE = "UPDATE_WAITING_ROOM_CHANNEL_FAILURE";


// POST UPDATE Waiting Room Channel
export const POST_UPDATE_WAITING_ROOM_CHANNEL_REQUEST = "POST_UPDATE_WAITING_ROOM_CHANNEL_REQUEST";
export const POST_UPDATE_WAITING_ROOM_CHANNEL_SUCCESS = "POST_UPDATE_WAITING_ROOM_CHANNEL_SUCCESS";
export const POST_UPDATE_WAITING_ROOM_CHANNEL_FAILURE = "POST_UPDATE_WAITING_ROOM_CHANNEL_FAILURE";

// PUBLISH Waiting Room TIME line
export const PUBLISH_WAITINGROOM_TIMELINE_REQUEST = "PUBLISH_WAITINGROOM_TIMELINE_REQUEST";
export const PUBLISH_WAITINGROOM_TIMELINE_SUCCESS = "PUBLISH_WAITINGROOM_TIMELINE_SUCCESS";
export const PUBLISH_WAITINGROOM_TIMELINE_FAILURE = "PUBLISH_WAITINGROOM_TIMELINE_FAILURE";
export const PUBLISH_WAITINGROOM_TIMELINE_NULL = "PUBLISH_WAITINGROOM_TIMELINE_NULL";

// ADD Location
export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILURE = "ADD_LOCATION_FAILURE";


// SEND MANAGER INVITE
export const SEND_MANAGER_INVITE_REQUEST = "SEND_MANAGER_INVITE_REQUEST";
export const SEND_MANAGER_INVITE_SUCCESS = "SEND_MANAGER_INVITE_SUCCESS";
export const SEND_MANAGER_INVITE_FAILURE = "SEND_MANAGER_INVITE_FAILURE";
// PUT Channel wise Custom Messages in Waiting Room Footer
export const PUT_CHANNELWISE_CUSTOM_MESSAGES_REQUEST = "PUT_CHANNELWISE_CUSTOM_MESSAGES_REQUEST";
export const PUT_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS = "PUT_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS";
export const PUT_CHANNELWISE_CUSTOM_MESSAGES_FAILURE = "PUT_CHANNELWISE_CUSTOM_MESSAGES_FAILURE";

// Waiting Room TImeline Education Tab
export const NEW_WAITING_ROOM_TIMELINE_EDUCATION_REQUEST = "NEW_WAITING_ROOM_TIMELINE_EDUCATION_REQUEST";
export const NEW_WAITING_ROOM_TIMELINE_EDUCATION_SUCCESS = "NEW_WAITING_ROOM_TIMELINE_EDUCATION_SUCCESS";
export const NEW_WAITING_ROOM_TIMELINE_EDUCATION_FAILURE = "NEW_WAITING_ROOM_TIMELINE_EDUCATION_FAILURE";

// Waiting Room TImeline Entertainment Tab
export const NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_REQUEST = "NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_REQUEST";
export const NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_SUCCESS = "NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_SUCCESS";
export const NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_FAILURE = "NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_FAILURE";

// POST Waiting Room Footer Display Options
export const POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST = "POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST";
export const POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_SUCCESS = "POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_SUCCESS";
export const POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_FAILURE = "POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_FAILURE";


// UNASSIGN MANAGER

export const UNASSIGN_MANAGER_REQUEST = "UNASSIGN_MANAGER_REQUEST";
export const UNASSIGN_MANAGER_SUCCESS = "UNASSIGN_MANAGER_SUCCESS";
export const UNASSIGN_MANAGER_FAILURE = "UNASSIGN_MANAGER_FAILURE";
// Waiting Room TImeline My Videos Tab
export const NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST = "NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE = "NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE";

// Remove My Videos in Waiting Room Timeline
export const REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST = "REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE = "REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE";

// Slides Stock Library in Waiting Room TImeline
export const GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_REQUEST = "GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_REQUEST";
export const GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_SUCCESS = "GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_SUCCESS";
export const GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_FAILURE = "GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_FAILURE";

// Slides Stock Library Search in Waiting Room Timeline
export const GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_REQUEST = "GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_REQUEST";
export const GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_SUCCESS = "GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_SUCCEsS";
export const GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_FAILURE = "GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_FAILURE";

// Categories for uploading my videos tab in Waiting Room TImeline
export const GET_MYVIDEOS_CATEGORY_LIST_REQUEST = "GET_MYVIDEOS_CATEGORY_LIST_REQUEST";
export const GET_MYVIDEOS_CATEGORY_LIST_SUCCESS = "GET_MYVIDEOS_CATEGORY_LIST_SUCCESS";
export const GET_MYVIDEOS_CATEGORY_LIST_FAILURE = "GET_MYVIDEOS_CATEGORY_LIST_FAILURE";

// Upload My Videos to Wistia in Waiting Room Timeline
export const UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_REQUEST = "UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_REQUEST";
export const UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_SUCCESS = "UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_SUCCESS";
export const UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_FAILURE = "UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_FAILURE";

// Request Practice video in waiting room channels
export const POST_REQUEST_PRACTICE_VIDEO_REQUEST = "POST_REQUEST_PRACTICE_VIDEO_REQUEST";
export const POST_REQUEST_PRACTICE_VIDEO_SUCCESS = "POST_REQUEST_PRACTICE_VIDEO_SUCCESS";
export const POST_REQUEST_PRACTICE_VIDEO_FAILURE = "POST_REQUEST_PRACTICE_VIDEO_FAILURE";

// Request Email Details For Invoice
export const GET_INVOICE_EMAIL_REQUEST = "GET_INVOICE_EMAIL_REQUEST";
export const GET_INVOICE_EMAIL_SUCCESS = "GET_INVOICE_EMAIL_SUCCESS";
export const GET_INVOICE_EMAIL_FAILURE = "GET_INVOICE_EMAIL_FAILURE";

// Update Email Details For Invoice
export const POST_INVOICE_EMAIL_REQUEST = "POST_INVOICE_EMAIL_REQUEST";
export const POST_INVOICE_EMAIL_SUCCESS = "POST_INVOICE_EMAIL_SUCCESS";
export const POST_INVOICE_EMAIL_FAILURE = "POST_INVOICE_EMAIL_FAILURE";
// Waiting Room Channel Slide
export const WAITING_ROOM_CHANNEL_SLIDE_REQUEST = "WAITING_ROOM_CHANNEL_SLIDE_REQUEST";
export const WAITING_ROOM_CHANNEL_SLIDE_SUCCESS = "WAITING_ROOM_CHANNEL_SLIDE_SUCCESS";
export const WAITING_ROOM_CHANNEL_SLIDE_FAILURE = "WAITING_ROOM_CHANNEL_SLIDE_FAILURE";

// GET Website Media Player Configuration Config
export const GET_WEBSITE_MEDIA_CONFIG_REQUEST = "GET_WEBSITE_MEDIA_CONFIG_REQUEST";
export const GET_WEBSITE_MEDIA_CONFIG_SUCCESS = "GET_WEBSITE_MEDIA_CONFIG_SUCCESS";
export const GET_WEBSITE_MEDIA_CONFIG_FAILURE = "GET_WEBSITE_MEDIA_CONFIG_FAILURE";

// POST Website Media Player Configuration Config
export const POST_WEBSITE_MEDIA_CONFIG_REQUEST = "POST_WEBSITE_MEDIA_CONFIG_REQUEST";
export const POST_WEBSITE_MEDIA_CONFIG_SUCCESS = "POST_WEBSITE_MEDIA_CONFIG_SUCCESS";
export const POST_WEBSITE_MEDIA_CONFIG_FAILURE = "POST_WEBSITE_MEDIA_CONFIG_FAILURE";

// website-media/html-code
export const GET_WEBSITE_MEDIA_HTML_CODE_REQUEST = "GET_WEBSITE_MEDIA_HTML_CODE_REQUEST";
export const GET_WEBSITE_MEDIA_HTML_CODE_SUCCESS = "GET_WEBSITE_MEDIA_HTML_CODE_SUCCESS";
export const GET_WEBSITE_MEDIA_HTML_CODE_FAILURE = "GET_WEBSITE_MEDIA_HTML_CODE_FAILURE";


// website-media/publish-timeline
export const PUBLISH_WEBSITE_MEDIA_REQUEST = "PUBLISH_WEBSITE_MEDIA_REQUEST";
export const PUBLISH_WEBSITE_MEDIA_SUCCESS = "PUBLISH_WEBSITE_MEDIA_SUCCESS";
export const PUBLISH_WEBSITE_MEDIA_FAILURE = "PUBLISH_WEBSITE_MEDIA_FAILURE";

// Waiting Room TImeline My Videos Tab UPLOAD
export const UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST = "UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE = "UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE";

// update waiting room video
export const UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST = "UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE = "UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE";

// fetch waiting room My Slide
export const GET_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST = "GET_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST";
export const GET_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS = "GET_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS";
export const GET_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE = "GET_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE";

// fetch waiting room timeline slide template (html)
export const GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_SUCCESS = "GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_SUCCESS";
export const GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_FAILURE = "GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_FAILURE";

//fetch waiting room timeline slide stock images
export const GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_REQUEST = "GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_REQUEST";
export const GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_SUCCESS = "GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_SUCCESS";
export const GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_FAILURE = "GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_FAILURE";

// set waiting room timeline slide in redux store
export const SET_WAITING_ROOM_TIMELINE_NEW_SLIDE = "SET_WAITING_ROOM_TIMELINE_NEW_SLIDE";
// export const SET_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS = "SET_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS";
// export const SET_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE = "SET_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE";


// create waiting room timeline slide
export const CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_REQUEST = "CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_REQUEST";
export const CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS = "CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS";
export const CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE = "CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE";

// remove slides
export const REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST = "REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST";
export const REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS = "REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS";
export const REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE = "REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE";

//social media add
export const ADD_SOCIAL_MEDIA_REQUEST = "ADD_SOCIAL_MEDIA_REQUEST";
export const ADD_SOCIAL_MEDIA_SUCCESS = "ADD_SOCIAL_MEDIA_SUCCESS";
export const ADD_SOCIAL_MEDIA_FAILURE = "ADD_SOCIAL_MEDIA_FAILURE";

// social media get
export const GET_SOCIAL_REQUEST = "GET_SOCIAL_REQUEST";
export const GET_SOCIAL_SUCCESS = "GET_SOCIAL_SUCCESS";
export const GET_SOCIAL_FAILURE = "GET_SOCIAL_FAILURE";

// remove social media
export const REMOVE_SOCIAL_REQUEST = "REMOVE_SOCIAL_REQUEST";
export const REMOVE_SOCIAL_SUCCESS = "REMOVE_SOCIAL_SUCCESS";
export const REMOVE_SOCIAL_FAILURE = "REMOVE_SOCIAL_FAILURE";

// post timeline
export const POST_TIMELINE_REQUEST = "POST_TIMELINE_REQUEST";
export const POST_TIMELINE_SUCCESS = "POST_TIMELINE_SUCCESS";
export const POST_TIMELINE_FAILURE = "POST_TIMELINE_FAILURE";

//get timelins
export const GET_TIMELINE_REQUEST = "GET_TIMELINE_REQUEST";
export const GET_TIMELINE_SUCCESS = "GET_TIMELINE_SUCCESS";
export const GET_TIMELINE_FAILURE = "GET_TIMELINE_FAILURE";

// Added by Dixit Solanki ( work releted operatory chanel)

// Create operatory Channel
export const CREATE_OPERATORY_CHANNEL_REQUEST = "CREATE_OPERATORY_CHANNEL_REQUEST";
export const CREATE_OPERATORY_CHANNEL_SUCCESS = "CREATE_OPERATORY_CHANNEL_SUCCESS";
export const CREATE_OPERATORY_CHANNEL_FAILURE = "CREATE_OPERATORY_CHANNEL_FAILURE";


// get operatory channel list
export const GET_OPERATORY_MEDIA_CHANNEL_LIST_REQUEST = "GET_OPERATORY_MEDIA_CHANNEL_LIST_REQUEST";
export const GET_OPERATORY_MEDIA_CHANNEL_LIST_SUCCESS = "GET_OPERATORY_MEDIA_CHANNEL_LIST_SUCCESS";
export const GET_OPERATORY_MEDIA_CHANNEL_LIST_FAILURE = "GET_OPERATORY_MEDIA_CHANNEL_LIST_FAILURE";

// delete operatory Channel
export const DELETE_OPERATORY_CHANNEL_REQUEST = "DELETE_OPERATORY_CHANNEL_REQUEST";
export const DELETE_OPERATORY_CHANNEL_SUCCESS = "DELETE_OPERATORY_CHANNEL_SUCCESS";
export const DELETE_OPERATORY_CHANNEL_FAILURE = "DELETE_OPERATORY_CHANNEL_SUCCESS";

// duplicate operatory Channel
export const DUPLICATE_OPERATORY_CHANNEL_REQUEST = "DUPLICATE_OPERATORY_CHANNEL_REQUEST";
export const DUPLICATE_OPERATORY_CHANNEL_SUCCESS = "DUPLICATE_OPERATORY_CHANNEL_SUCCESS";
export const DUPLICATE_OPERATORY_CHANNEL_FAILURE = "DUPLICATE_OPERATORY_CHANNEL_FAILURE";

//  get operatory info Channel
export const GET_OPERATORY_CHANNEL_REQUEST = "GET_OPERATORY_CHANNEL_REQUEST";
export const GET_OPERATORY_CHANNEL_SUCCESS = "GET_OPERATORY_CHANNEL_SUCCESS";
export const GET_OPERATORY_CHANNEL_FAILURE = "GET_OPERATORY_CHANNEL_FAILURE";

//  restore operatory info Channel
export const RESTORE_OPERATORY_CHANNEL_REQUEST = "RESTORE_OPERATORY_CHANNEL_REQUEST";
export const RESTORE_OPERATORY_CHANNEL_SUCCESS = "RESTORE_OPERATORY_CHANNEL_SUCCESS";
export const RESTORE_OPERATORY_CHANNEL_FAILURE = "RESTORE_OPERATORY_CHANNEL_FAILURE";

// update  operatory Channel Rename
export const UPDATE_OPERATORY_CHANNEL_NAME_REQUEST = "UPDATE_OPERATORY_CHANNEL_NAME_REQUEST";
export const UPDATE_OPERATORYGROOM_MEDIA_CHANNEL_LOADING_REQUEST = "UPDATE_OPERATORYGROOM_MEDIA_CHANNEL_LOADING_REQUEST";
export const UPDATE_OPERATORY_CHANNEL_NAME_SUCCESS = "UPDATE_OPERATORY_CHANNEL_NAME_SUCCESS";
export const UPDATE_OPEROTORY_MEDIA_CHANNEL_CC_STATUS_SUCCESS = "UPDATE_OPEROTORY_MEDIA_CHANNEL_CC_STATUS_SUCCESS";
export const UPDATE_OPERATORY_CHANNEL_NAME_FAILURE = "UPDATE_OPERATORY_CHANNEL_NAME_FAILURE";


// get Theme operatory Channel
export const GET_OPERATORY_THEME_LIST_REQUEST = "GET_OPERATORY_THEME_LIST_REQUEST";
export const GET_OPERATORY_THEME_LIST_SUCCESS = "GET_OPERATORY_THEME_LIST_SUCCESS";
export const GET_OPERATORY_THEME_LIST_FAILURE = "GET_OPERATORY_THEME_LIST_FAILURE";

// get options operatory Channel
export const GET_OPERATORY_DISPLAY_OPTIONS_REQUEST = "GET_OPERATORY_DISPLAY_OPTIONS_REQUEST";
export const GET_OPERATORY_DISPLAY_OPTIONS_SUCCESS = "GET_OPERATORY_DISPLAY_OPTIONS_SUCCESS";
export const GET_OPERATORY_DISPLAY_OPTIONS_FAILURE = "GET_OPERATORY_DISPLAY_OPTIONS_FAILURE";

// get whether operatory Channel
export const GET_OPERATORY_LIVE_WEATHER_DATA_REQUEST = "GET_OPERATORY_LIVE_WEATHER_DATA_REQUEST";
export const GET_OPERATORY_LIVE_WEATHER_DATA_SUCCESS = "GET_OPERATORY_LIVE_WEATHER_DATA_SUCCESS";
export const GET_OPERATORY_LIVE_WEATHER_DATA_FAILURE = "GET_OPERATORY_LIVE_WEATHER_DATA_FAILURE";

// update options operatory DISPLAY_OPTIONS
export const POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_REQUEST = "POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_REQUEST";
export const POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_SUCCESS = "POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_SUCCESS";
export const POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_FAILURE = "POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_FAILURE";

// update options operatory Channel
export const UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST = "UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE = "UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE";

// remove options operatory my video
export const REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST = "REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE = "REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE";


// end by Dixit Solanki ( work releted operatory chanel)

//Operatory room
// get videos
export const GET_OPERATORY_VIDEO_REQUEST = "GET_OPERATORY_VIDEO_REQUEST";
export const GET_OPERATORY_VIDEO_SUCCESS = "GET_OPERATORY_VIDEO_SUCCESS";
export const GET_OPERATORY_VIDEO_FAILURE = "GET_OPERATORY_VIDEO_FAILURE";


export const DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST = "DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST";
export const DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS = "DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS";
export const DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE = "DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE";

// GET Playlist in Website Video Library
export const GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_REQUEST = "GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_REQUEST";
export const GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_SUCCESS = "GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_SUCCESS";
export const GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_FAILURE = "GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_FAILURE";

// GET Playlist in Website Video Library
export const GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST = "GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST";
export const GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS = "GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS";
export const GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE = "GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE";

// POST Playlist in Website Video Library
export const POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST = "POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST";
export const POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS = "POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS";
export const POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE = "POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE";

// POST Timeline in Website Video Library
export const POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_REQUEST = "POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_REQUEST";
export const POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_SUCCESS = "POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_SUCCESS";
export const POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_FAILURE = "POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_FAILURE";

export const ADD_CATEGORY_SPECIALIZATION = "ADD_CATEGORY_SPECIALIZATION";
export const CHAIRSIDE_RESET_DEFAULT = "CHAIRSIDE_RESET_DEFAULT";
export const SET_SELECTED_SPECIALIZATION_CATEGORY = "SET_SELECTED_SPECIALIZATION_CATEGORY";

// Chairside Videos Editor APIs
export const SEND_SCREENSHOT_REQUEST = "SEND_SCREENSHOT_REQUEST";
export const SEND_SCREENSHOT__RESET_REQUEST = "SEND_SCREENSHOT__RESET_REQUEST";
export const SEND_SCREENSHOT_SUCCESS = "SEND_SCREENSHOT_SUCCESS";
export const SEND_SCREENSHOT_FAILURE = "SEND_SCREENSHOT_FAILURE";

export const GET_CUSTOM_MESSAGES_REQUEST = "GET_CUSTOM_MESSAGES_REQUEST";
export const GET_CUSTOM_MESSAGES_SUCCESS = "GET_CUSTOM_MESSAGES_SUCCESS";
export const GET_CUSTOM_MESSAGES_FAILURE = "GET_CUSTOM_MESSAGES_FAILURE";

export const POST_CUSTOM_MESSAGES_REQUEST = "POST_CUSTOM_MESSAGES_REQUEST";
export const POST_CUSTOM_MESSAGES_SUCCESS = "POST_CUSTOM_MESSAGES_SUCCESS";
export const POST_CUSTOM_MESSAGES_FAILURE = "POST_CUSTOM_MESSAGES_FAILURE";

export const RESET_CUSTOM_MESSAGES_REQUEST = "RESET_CUSTOM_MESSAGES_REQUEST";
export const RESET_CUSTOM_MESSAGES_SUCCESS = "RESET_CUSTOM_MESSAGES_SUCCESS";
export const RESET_CUSTOM_MESSAGES_FAILURE = "RESET_CUSTOM_MESSAGES_FAILURE";

// search schreenshot
export const GET_SCREENSHOT_LIST_REQUEST = "GET_SCREENSHOT_LIST_REQUEST";
export const GET_SCREENSHOT_LIST_SUCCESS = "GET_SCREENSHOT_LIST_SUCCESS";
export const GET_SCREENSHOT_LIST_FAILURE = "GET_SCREENSHOT_LIST_FAILURE";

// POST Operatory Schedule
export const CREATE_OPERATORY_SCHEDULE_REQUEST = "CREATE_OPERATORY_SCHEDULE_REQUEST";
export const CREATE_OPERATORY_SCHEDULE_SUCCESS = "CREATE_OPERATORY_SCHEDULE_SUCCESS";
export const CREATE_OPERATORY_SCHEDULE_FAILURE = "CREATE_OPERATORY_SCHEDULE_FAILURE";

// POST Waiting Room Schedule
export const CREATE_WAITING_ROOM_SCHEDULE_REQUEST = "CREATE_WAITING_ROOM_SCHEDULE_REQUEST";
export const CREATE_WAITING_ROOM_SCHEDULE_SUCCESS = "CREATE_WAITING_ROOM_SCHEDULE_SUCCESS";
export const CREATE_WAITING_ROOM_SCHEDULE_FAILURE = "CREATE_WAITING_ROOM_SCHEDULE_FAILURE";

// get custom background
export const GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_REQUEST = "GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_REQUEST";
export const GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_SUCCESS = "GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_SUCCESS";
export const GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_FAILURE = "GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_FAILURE";

// GET Waiting Room Schedule
export const GET_WAITING_ROOM_SCHEDULE_REQUEST = "GET_WAITING_ROOM_SCHEDULE_REQUEST";
export const GET_WAITING_ROOM_SCHEDULE_SUCCESS = "GET_WAITING_ROOM_SCHEDULE_SUCCESS";
export const GET_WAITING_ROOM_SCHEDULE_FAILURE = "GET_WAITING_ROOM_SCHEDULE_FAILURE";

// GET Waiting Room Schedule
export const GET_WAITINGROOM_FOOTER_WEATHER_DATA_REQUEST = "GET_WAITINGROOM_FOOTER_WEATHER_DATA_REQUEST";
export const GET_WAITINGROOM_FOOTER_WEATHER_DATA_SUCCESS = "GET_WAITINGROOM_FOOTER_WEATHER_DATA_SUCCESS";
export const GET_WAITINGROOM_FOOTER_WEATHER_DATA_FAILURE = "GET_WAITINGROOM_FOOTER_WEATHER_DATA_FAILURE";


// upload operatory Room videos
export const UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST = "UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE = "UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE";



// GET operatory Room videos
export const NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST = "NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST";
export const NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS = "NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS";
export const NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE = "NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE";

export const GET_PRACTICE_PAYMENT_INFO_REQUEST = "GET_PRACTICE_PAYMENT_INFO_REQUEST";
export const GET_PRACTICE_PAYMENT_INFO_SUCCESS = "GET_PRACTICE_PAYMENT_INFO_SUCCESS";
export const GET_PRACTICE_PAYMENT_INFO_FAIL = "GET_PRACTICE_PAYMENT_INFO_FAIL";

export const POST_PRACTICE_PAYMENT_INFO_REQUEST = "POST_PRACTICE_PAYMENT_INFO_REQUEST";
export const POST_PRACTICE_PAYMENT_INFO_SUCCESS = "POST_PRACTICE_PAYMENT_INFO_SUCCESS";
export const POST_PRACTICE_PAYMENT_INFO_FAIL = "POST_PRACTICE_PAYMENT_INFO_FAIL";

export const POST_DUPLICATES_WITH_MEDIA_CATEGORIES = "POST_DUPLICATES_WITH_MEDIA_CATEGORIES"

export const GET_MEDIA_CATEGORY_IMAGES_REQUEST = "GET_MEDIA_CATEGORY_IMAGES_REQUEST";
export const GET_MEDIA_CATEGORY_IMAGES_SUCCESS = "GET_MEDIA_CATEGORY_IMAGES_SUCCESS";
export const GET_MEDIA_CATEGORY_IMAGES_FAIL = "GET_MEDIA_CATEGORY_IMAGES_FAIL";


export const POST_TOS_COMPLAINT_REQUEST = "POST_TOS_COMPLAINT_REQUEST";
export const POST_TOS_COMPLAINT_SUCCESS = "POST_TOS_COMPLAINT_SUCCESS";
export const POST_TOS_COMPLAINT_FAIL = "POST_TOS_COMPLAINT_FAIL";

export const GET_CHAIRSIDE_MEDIA__RECENT_PLAY_REQUEST = "GET_CHAIRSIDE_MEDIA__RECENT_PLAY_REQUEST";
export const GET_CHAIRSIDE_MEDIA__RECENT_PLAY_SUCCESS = "GET_CHAIRSIDE_MEDIA__RECENT_PLAY_SUCCESS";
export const GET_CHAIRSIDE_MEDIA__RECENT_PLAY_FAILURE = "GET_CHAIRSIDE_MEDIA__RECENT_PLAY_FAILURE";

export const POST_PRACTICE_CONTACTUS_REQUEST = "POST_PRACTICE_CONTACTUS_REQUEST";
export const POST_PRACTICE_CONTACTUS_SUCCESS = "POST_PRACTICE_CONTACTUS_SUCCESS";
export const POST_PRACTICE_CONTACTUS_FAILURE = "POST_PRACTICE_CONTACTUS_FAILURE";

export const RESET_DEFAULT_WEBSITE_VIDEO_REQUEST = "RESET_DEFAULT_WEBSITE_VIDEO_REQUEST";
export const RESET_DEFAULT_WEBSITE_VIDEO_SUCCESS = "RESET_DEFAULT_WEBSITE_VIDEO_SUCCESS";
export const RESET_DEFAULT_WEBSITE_VIDEO_FAILURE = "RESET_DEFAULT_WEBSITE_VIDEO_FAILURE";

export const EXTEND_TRIAL_REQUEST = "EXTEND_TRIAL_REQUEST";
export const EXTEND_TRIAL_SUCCESS = "EXTEND_TRIAL_SUCCESS";
export const EXTEND_TRIAL_FAILURE = "EXTEND_TRIAL_FAILURE";

export const GET_DESIGNATE_CARD_REQUEST = "GET_DESIGNATE_CARD_REQUEST";
export const GET_DESIGNATE_CARD_SUCCESS = "GET_DESIGNATE_CARD_SUCCESS";
export const GET_DESIGNATE_CARD_FAILURE = "GET_DESIGNATE_CARD_FAILURE";

export const GET_DESIGNATE_CARD_MULTIPLE_REQUEST = "GET_DESIGNATE_CARD_MULTIPLE_REQUEST";
export const GET_DESIGNATE_CARD_MULTIPLE_SUCCESS = "GET_DESIGNATE_CARD_MULTIPLE_SUCCESS";
export const GET_DESIGNATE_CARD_MULTIPLE_FAILURE = "GET_DESIGNATE_CARD_MULTIPLE_FAILURE";

export const POST_CORPORATE_MULTIPLE_CARD_REQUEST = "POST_CORPORATE_MULTIPLE_CARD_REQUEST";
export const POST_CORPORATE_MULTIPLE_CARD_SUCCESS = "POST_CORPORATE_MULTIPLE_CARD_SUCCESS";
export const POST_CORPORATE_MULTIPLE_CARD_FAILURE = "POST_CORPORATE_MULTIPLE_CARD_FAILURE";

export const SELECTED_CARD = "SELECTED_CARD";
export const UPDATE_SELECTED_CARD = "UPDATE_SELECTED_CARD";
export const CHECK_PRACTIC_IS_CORPORATE = "CHECK_PRACTIC_IS_CORPORATE";

export const GET_CORPORAT_HARDWARE_CALC_REQUEST = "GET_CORPORAT_HARDWARE_CALC_REQUEST";
export const GET_CORPORAT_HARDWARE_CALC_SUCCESS = "GET_CORPORAT_HARDWARE_CALC_SUCCESS";
export const GET_CORPORAT_HARDWARE_CALC_FAILURE = "GET_CORPORAT_HARDWARE_CALC_FAILURE";

export const GET_CORPORAT_HARDWARE_LIST_REQUEST = "GET_CORPORAT_HARDWARE_LIST_REQUEST";
export const GET_CORPORAT_HARDWARE_LIST_SUCCESS = "GET_CORPORAT_HARDWARE_LIST_SUCCESS";
export const GET_CORPORAT_HARDWARE_LIST_FAILURE = "GET_CORPORAT_HARDWARE_LIST_FAILURE";

export const GET_CORPORAT_PRACTICES_LIST_REQUEST = "GET_CORPORAT_PRACTICES_LIST_REQUEST";
export const GET_CORPORAT_PRACTICES_LIST_SUCCESS = "GET_CORPORAT_PRACTICES_LIST_SUCCESS";
export const GET_CORPORAT_PRACTICES_LIST_FAILURE = "GET_CORPORAT_PRACTICES_LIST_FAILURE";

export const PURCHASE_CORPORAT_HARDWARE_REQUEST = "PURCHASE_CORPORAT_HARDWARE_REQUEST";
export const PURCHASE_CORPORAT_HARDWARE_SUCCESS = "PURCHASE_CORPORAT_HARDWARE_SUCCESS";
export const PURCHASE_CORPORAT_HARDWARE_FAILURE = "PURCHASE_CORPORAT_HARDWARE_FAILURE";

export const SHOW_ERROR = "SHOW_ERROR";

export const UPDATE_HARDWARE_TOTAL = "UPDATE_HARDWARE_TOTAL";

export const SAME_AS_MAIN_PRACTICE_REQUEST = "SAME_AS_MAIN_PRACTICE_REQUEST";
export const SAME_AS_MAIN_PRACTICE_SUCCESS = "SAME_AS_MAIN_PRACTICE_SUCCESS";
export const SAME_AS_MAIN_PRACTICE_FAIL = "SAME_AS_MAIN_PRACTICE_FAIL";


export const SET_GOOGLE_TV_TEST_DRIVE_ON_REQUEST = "SET_GOOGLE_TV_TEST_DRIVE_ON_REQUEST";
export const SET_GOOGLE_TV_TEST_DRIVE_MENU_ITEM = "SET_GOOGLE_TV_TEST_DRIVE_MENU_ITEM";
export const SET_GOOGLE_TV_TEST_DRIVE_CAT_IS_OPEN = "SET_GOOGLE_TV_TEST_DRIVE_CAT_IS_OPEN";

export const GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_REQUEST = "GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_REQUEST";
export const GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_SUCCESS = "GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_SUCCESS";
export const GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_FAILURE = "GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_FAILURE";

export const CREATE_CHAIRSIDE_PLAYLIST_REQUEST = "CREATE_CHAIRSIDE_PLAYLIST_REQUEST";
export const CREATE_CHAIRSIDE_PLAYLIST_SUCCESS = "CREATE_CHAIRSIDE_PLAYLIST_SUCCESS";
export const CREATE_CHAIRSIDE_PLAYLIST_FAILURE = "CREATE_CHAIRSIDE_PLAYLIST_FAILURE";

export const GET_CHAIRSIDE_PLAYLIST_REQUEST = "GET_CHAIRSIDE_PLAYLIST_REQUEST";
export const GET_CHAIRSIDE_PLAYLIST_SUCCESS = "GET_CHAIRSIDE_PLAYLIST_SUCCESS";
export const GET_CHAIRSIDE_PLAYLIST_FAILURE = "GET_CHAIRSIDE_PLAYLIST_FAILURE";

export const GET_SINGLE_CHAIRSIDE_PLAYLIST_REQUEST = "GET_SINGLE_CHAIRSIDE_PLAYLIST_REQUEST";
export const GET_SINGLE_CHAIRSIDE_PLAYLIST_SUCCESS = "GET_SINGLE_CHAIRSIDE_PLAYLIST_SUCCESS";
export const GET_SINGLE_CHAIRSIDE_PLAYLIST_FAILURE = "GET_SINGLE_CHAIRSIDE_PLAYLIST_FAILURE";

export const UPDATE_CHAIRSIDE_PLAYLIST_REQUEST = "UPDATE_CHAIRSIDE_PLAYLIST_REQUEST";
export const UPDATE_CHAIRSIDE_PLAYLIST_SUCCESS = "UPDATE_CHAIRSIDE_PLAYLIST_SUCCESS";
export const UPDATE_CHAIRSIDE_PLAYLIST_FAILURE = "UPDATE_CHAIRSIDE_PLAYLIST_FAILURE";

export const GET_CHAIRSIDE_PLAY_VIDEO_REQUEST = "GET_CHAIRSIDE_PLAY_VIDEO_REQUEST";
export const GET_CHAIRSIDE_PLAY_VIDEO_SUCCESS = "GET_CHAIRSIDE_PLAY_VIDEO_SUCCESS";
export const GET_CHAIRSIDE_PLAY_VIDEO_FAILURE = "GET_CHAIRSIDE_PLAY_VIDEO_FAILURE";


export const PUBLISH_CHAIRSIDE_PLAYLIST_REQUEST = "PUBLISH_CHAIRSIDE_PLAYLIST_REQUEST";
export const PUBLISH_CHAIRSIDE_PLAYLIST_SUCCESS = "PUBLISH_CHAIRSIDE_PLAYLIST_SUCCESS";
export const PUBLISH_CHAIRSIDE_PLAYLIST_FAILURE = "PUBLISH_CHAIRSIDE_PLAYLIST_FAILURE";
export const PUBLISH_CHAIRSIDE_PLAYLIST_RESET_MESSAGE = "PUBLISH_CHAIRSIDE_PLAYLIST_RESET_MESSAGE";

export const REMOVE_CHAIRSIDE_PLAYLIST_REQUEST = "REMOVE_CHAIRSIDE_PLAYLIST_REQUEST";
export const REMOVE_CHAIRSIDE_PLAYLIST_SUCCESS = "REMOVE_CHAIRSIDE_PLAYLIST_SUCCESS";
export const REMOVE_CHAIRSIDE_PLAYLIST_FAILURE = "REMOVE_CHAIRSIDE_PLAYLIST_FAILURE";

export const DUPLICATE_CHAIRSIDE_PLAYLIST_REQUEST = "DUPLICATE_CHAIRSIDE_PLAYLIST_REQUEST";
export const DUPLICATE_CHAIRSIDE_PLAYLIST_SUCCESS = "DUPLICATE_CHAIRSIDE_PLAYLIST_SUCCESS";
export const DUPLICATE_CHAIRSIDE_PLAYLIST_FAILURE = "DUPLICATE_CHAIRSIDE_PLAYLIST_FAILURE";


export const SET_CHAIRSIDE_PLAYLIST_MENU_ACTIVE = "SET_CHAIRSIDE_PLAYLIST_MENU_ACTIVE";
export const SET_CHAIRSIDE_PLAYLIST_VIDEO_INDEX = "SET_CHAIRSIDE_PLAYLIST_VIDEO_INDEX";


export const SET_UPGRADE_DOWNGRADE = "SET_UPGRADE_DOWNGRADE";

// default profile selection
export const DEFAULT_PROFILE_REQUEST = "DEFAULT_PROFILE_REQUEST";
export const DEFAULT_PROFILE_SUCCESS = "DEFAULT_PROFILE_SUCCESS";
export const DEFAULT_PROFILE_FAILURE = "DEFAULT_PROFILE_FAILURE";
