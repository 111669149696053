import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchChairsideMedia, getOperatoryMediaChannelList, getWebsiteRoomMediaChannelList } from '../../../../store/actions/taskAction';
import styles from './style.module.scss';
import ChannelList from '../ChannelList'
import Loading from '../../../../components/Loading';

const NewWaitingRoomChannels = ({ sortedBy, sortedType , isOperatoryRoom, isChairside}) => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const [myList, setMyList] = useState([]);

    useEffect(() => {
      if(isChairside){
        dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
        return
      }

      if(isOperatoryRoom){
        dispatch(
          getOperatoryMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }else{
        dispatch(
          getWebsiteRoomMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }
    }, []);

    
    useEffect(()=>{
      var myList = [];

      if(isChairside){
        state.chairSideMediaState.map((video)=>{
            if(video.chairside_media && video.chairside_media.in_mylist == 1){
              myList.push(video)
            }
        })

        setMyList(myList);

        return
      }

    
      if(isOperatoryRoom){
        state.operatoryMediaChannels.map((video)=>{
          if(video.my_lists && video.my_lists.id){
              myList.push(video)
          }
        })
      }else{
        state.waitingRoomMediaChannels.map((video)=>{
          if(video.my_list && video.my_list.id){
              myList.push(video)
          }
        })
      }
     
      setMyList(myList);


  }, [state.waitingRoomMediaChannels, state.operatoryMediaChannels,state.chairSideMediaState])

  return (
    <>
    {state.loading ? <div className={`goolge_tv__loader`}><Loading /></div> :
    <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
        <ChannelList title="My List" isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} noSlider={true} channelsData={myList}/>
    </div>
    }
    </>
  )
}

export default NewWaitingRoomChannels
