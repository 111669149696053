import './style.scss';
import logo from '../../img/logo.svg'; 
import {useTranslation} from "react-i18next";
import {ReactComponent as CloseIcon} from '../../img/close_icon.svg';
import {ReactComponent as MinimizeIcon} from '../../img/minimize_icon.svg';
import { NavLink } from 'reactstrap';
import { CLOSE, MINIMIZE } from '../../utilities/HttpUtility';

function HeaderOnlyLogo() {
  const [t, i18n] = useTranslation('common');
  return (
    <div className="position-fixed header__only--logo  d-flex align-item-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <img alt="" src={logo}/>
        </div>
        <div className="ml-auto header__right__buttons col-4 px-0 d-flex align-items-center justify-content-end">
          {/* <button className="btn btn-link" onClick={() => i18n.changeLanguage('fr')}>fr</button>
          <button className="btn btn-link" onClick={() => i18n.changeLanguage('en')}>en</button> */}
          <NavLink href={MINIMIZE}><MinimizeIcon/></NavLink>
            <NavLink href={CLOSE}><CloseIcon/></NavLink>
        </div>
    </div>
  );
}

export default HeaderOnlyLogo;
