/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Spinner, Input , FormGroup , Label} from 'reactstrap';
import DropDown from '../../components/DropDown'
import Loading from '../../components/Loading'
import LoadingButton from '../../components/LoadingButton'
import {
	fetchWebsiteMediaHtml,
	getWebsiteMediaLayouts,
	getWebsiteMediaColours,
	websiteMediaConfig,
	postWebsiteMediaConfig,
	GetPracticeMoreInfo,
	publishWebsiteMedia
} from '../../store/actions/taskAction';
import SubHeader from '../../components/Header/subHeader';
import './styles.scss';
import { history } from '..';
import 'swiper/swiper-bundle.css';
import ColoursSlider from '../../components/coloursSlider/ColoursSlider';
import LayoutScreen from '../../components/layoutScreen/LayoutScreen';
import * as consts from '../../../src/utilities/HttpUtility';
import WebsitePlayerColorSlider from '../../components/Skeletons/websitePlayerColorSlider'
import Skeleton from "@material-ui/lab/Skeleton";

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const RenderHTML = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	const [videoAudioLanguage, setVideoAudioLanguage] = useState();
	const [closedCaptionLanguage, setClosedCaptionLanguage] = useState({
		id:0,
		name:'No closed captions'
	});
	const [layoutId, setLayoutId] = useState(1);
	const [coloursId, setColoursId] = useState(1);
	const [langId, setlangId] = useState(1);
	const [ccId, setccId] = useState(1);
	const [isSave, setSaveBtnEnable] = useState(false);
	const [profile, setProfile] = useState(state.practiceInfo)
	const [medialanguage, setMediaLabguage] = useState(state.media_language)
	
	const [saveMessage, setSaveMessage] = useState({
		message: 'SAVE CHANGES',
		color: 'black',
		bgColor: 'primary',
	});

	const [html, setHtml] = useState(state.websiteMediaHtml);
	const [restoreDefault, setRestoreDefault] = useState(false);

	useEffect(() => {
		dispatch(getWebsiteMediaLayouts());
		dispatch(getWebsiteMediaColours());
		dispatch(websiteMediaConfig());
		dispatch(GetPracticeMoreInfo());
	}, []);

	useEffect(() => {
		if(Object.keys(state.websiteMediaConfigs).length > 0){
			// let params = {
			// 	layout_id: state.websiteMediaConfigs.theme_id,
			// 	colour_id: state.websiteMediaConfigs.color_id,
			// 	practice_id:state.practiceProfileInfo.id
			// }
			// dispatch( fetchWebsiteMediaHtml(params) );
			setLayoutId(state.websiteMediaConfigs.theme_id)
			setColoursId(state.websiteMediaConfigs.color_id);
			console.log( state.websiteMediaConfigs ,' state.websiteMediaConfigs')
		}
		
		
	}, [ state.websiteMediaConfigs]);

	// useEffect(() => {
	// 	// setColoursId(state.websiteMediaConfigs.color_id);
	// 	// loadIframe()
	// }, [state.websiteMediaConfigs.color_id , state.websiteMediaConfigs.theme_id]);

	// useEffect(() => {
	// 	// setLayoutId(state.websiteMediaConfigs.theme_id);
	// }, [state.websiteMediaConfigs.theme_id]);

	useEffect(() => {
		if(state.media_language.length > 0){
			var medialanguage = [...state.media_language];
			medialanguage.unshift({
				id:0,
				name:'No closed captions'
			})
			setMediaLabguage(medialanguage)
			console.log(medialanguage,'medialanguage')
			if(state.websiteMediaConfigs.media_audio_language_id && state.media_language){
				if(state.websiteMediaConfigs.media_cc_language_id){
					setClosedCaptionLanguage(medialanguage.find(x => x.id == state.websiteMediaConfigs.media_cc_language_id));
				}
				setVideoAudioLanguage(state.media_language.find(x => x.id == state.websiteMediaConfigs.media_audio_language_id));
				if(videoAudioLanguage && closedCaptionLanguage){
					setlangId( videoAudioLanguage.id)
					setccId(closedCaptionLanguage.id)
				}
				console.log(videoAudioLanguage,'videoAudioLanguage')
			}
		}
	}, [state.websiteMediaConfigs,  state.media_language]);


	// const loadIframe = async () => {
	// 	let token = localStorage.getItem("access_token")
	// 	populateIframe(consts.APIBASEURL + 'website-media/layouts/html?layout_id=' + layoutId + '&colour_id=' + coloursId, [['Authorization', token]]);
	// }
	// const populateIframe = (url, headers) => {
	// 	var xhr = new XMLHttpRequest();
	// 	function handler() {
	// 		if (this.readyState === this.DONE) {
	// 			if (this.status === 200) {
	// 				// this.response is a Blob, because we set responseType above
	// 				let srclink = URL.createObjectURL(this.response);
	// 				let prv = document.querySelector('#prv');
	// 				if (!!prv) {
	// 					prv.setAttribute("src", srclink)
	// 				}
	// 			} else {
	// 				console.error('XHR failed', this);
	// 			}
	// 		}
	// 	}
	// 	xhr.open('GET', url);
	// 	xhr.onreadystatechange = handler;
	// 	xhr.responseType = 'blob';
	// 	headers.forEach(function (header) {
	// 		xhr.setRequestHeader(header[0], header[1]);
	// 	});
	// 	xhr.send();
	// }
	const changeStyle = (event, colour) => {
		setColoursId(colour.id);
	};
	// Submit Layout
	const submitLayouts = (layoutId) => {
		// setRestoreDefault(true)
		setLayoutId(layoutId);
	};
	// Submit Colour
	const setColour = (colourId) => {
		setRestoreDefault(true);
		setColoursId(colourId);
	};

	const videoAudioLanguageChangeHandler = (value) => {
		setVideoAudioLanguage(value);
		setlangId( value.id)
	
	};
	const closedCaptionChangeHandler = (value) => {
		setClosedCaptionLanguage(value);
		setccId(value.id)
	};
	// Submit Handler
	const submitHandler = () => {
		// loadIframe()
		// dispatch(
		// 	fetchWebsiteMediaHtml(state.practiceUserLoginInfo, {
		// 		layout_id: layoutId,
		// 		colour_id: coloursId,
		// 	})
		// );
	};
	const saveChangesHandler = () => {
		console.log(closedCaptionLanguage,'closedCaptionLanguage')
		let params ={
			player_settings: {
				theme_id: layoutId,
				color_id: coloursId,
				media_audio_language_id: videoAudioLanguage.id,
				media_cc_language_id: closedCaptionLanguage.id,
			},
		}
		dispatch( postWebsiteMediaConfig(params) );

		// setTimeout(() => {
		// 	// dispatch(websiteMediaConfig());
		// 	let params ={
		// 		layout_id: layoutId,
		// 		colour_id: coloursId,
		// 	}
		// 	dispatch( fetchWebsiteMediaHtml(params) );
		// }, 700);

		setSaveMessage({
			message: 'Changes Saved',
			color: 'black',
			bgColor: 'green',
		});

		setTimeout(() => {
			setSaveMessage({
				message: 'SAVE CHANGES',
				color: 'black',
				bgColor: 'primary',
			});
		}, 2000);
	};

	// useEffect(() => {
	// 	let scriptUrl = state.websiteMediaHtml.slice(85, 168);
	// 	setHtml(scriptUrl);
	// }, [state.websiteMediaHtml]);

	// Restore to default
	const restoreDefaultSettings = () => {
		let params ={
			player_settings: {
				theme_id: 1,
				color_id: 1,
				media_audio_language_id: 1,
				media_cc_language_id: 1,
			},
		}
		dispatch( postWebsiteMediaConfig(params) );

		let param ={
			layout_id: 1,
			colour_id: 1,
			practice_id:state.practiceProfileInfo.id
		}
		dispatch(
			fetchWebsiteMediaHtml(param)
		);
		// setTimeout(() => {
		// 	dispatch(websiteMediaConfig());
		// 	// loadIframe()
		// }, 500);
	}

	const [savePublishMessage, setPublishMessage] = useState({
		message: 'Publish Player',
		bgColor: 'primary',
	});

	const onPublishClick = () => {
        dispatch(publishWebsiteMedia());
        setPublishMessage({
            message: 'Published',
            bgColor: 'green',
        });
        setTimeout(() => {
            setPublishMessage({
                message: 'Publish Player',
                bgColor: 'primary',
            });
        }, 3000);
    }

	const src = `${consts.APIBASEURL}website-media/layouts/html?layout_id=${layoutId}&colour_id=${coloursId}&practice_id=${state.practiceProfileInfo.id}&media_audio_language_id=${langId}&media_cc_language_id=${ccId}`
	return (
		<div className='website_videos__main__screen  d-flex flex-column h-100 align-items-center'>
			<SubHeader className='' title='Website Player Customization' />
				<>
					<div className='selectiontabmain mt-5'>
						<div className='website_videos_layout_selection__main__screen d-flex'>
							<div className='website_videos_layout_selection__list__screen mr-20'>
								<div style={{minHeight:'430px'}} className='website_videos_layout_selection__list__box transparent__form--white  d-flex flex-column mb-20'>
									{/* {state.websiteMediaLayouts &&
										state.websiteMediaLayouts.length > 0 ? ( */}
										<LayoutScreen
											layouts={state.websiteMediaLayouts}
											submitLayouts={submitLayouts}
											activeLayoutId={layoutId}
											profile={profile}
										/>
									{/* ) : (
										''
									)} */}
									
									
								</div>
								<div className='website_videos_layout_selection__list__box transparent__form--white  d-flex flex-column mb-20'>
									<p className='website_videos_layout_selection__headerTitle'>Language</p>
									<p style={{ marginBottom: '27px' }}>
										Select a language for video audio and subtitles.
									</p>
									<FormGroup>
										<div className="d-flex justify-content-between">
											<Label>Video audio language</Label>
										</div>
										<DropDown 
											className='react-select-container'
											classNamePrefix="react-select"
											options={state.media_language}
											getOptionLabel={ x => `${x.name}`}
											getOptionValue={ x => `${x.id} ${x.name}`}
											onChange={videoAudioLanguageChangeHandler}
											isSearchable={false}
											placeholder={''}
											value={videoAudioLanguage}
										/>
									</FormGroup>
									<FormGroup className="mb-4">
										<div className="d-flex justify-content-between">
											<Label>Video closed caption language</Label>
										</div>
										<DropDown 
											className='react-select-container'
											classNamePrefix="react-select"
											options={medialanguage}
											getOptionLabel={ x => `${x.name}`}
											getOptionValue={ x => `${x.id} ${x.name}`}
											onChange={closedCaptionChangeHandler}
											isSearchable={false}
											placeholder={''}
											value={closedCaptionLanguage}
										/>
									</FormGroup>
									
									
								</div>
								<div className='' style={{padding:'0 4rem'}}>
									{state.practiceInfo.is_paid_account == 0 && state.practiceInfo.days_left == 0 && state.practiceInfo.status !== 800 ?
										<div className='footer_btn mt-auto' >
											<Button type="button" color="primary" onClick={()=>history.push("/Subscribe")} className="col mb-4 subscribe-customise"> SUBSCRIBE TO CUSTOMIZE </Button>
											{/* <Button color="white" className="col mb-20" onClick={() => history.push('/get-html-code')}> GET HTML CODE </Button> */}
										</div>
										:
										<>
										<div className='footer_btn d-flex justify-content-between mt-auto' >
											<LoadingButton type="button" color={saveMessage.bgColor} loading={state.buttonLoading2}   className="col mb-4" disabled={state.buttonLoading2} label={saveMessage.message} onClick={saveChangesHandler}  />
											{/* <Button color="white" className="col mb-20 ml-3" onClick={() => history.push('/get-html-code')}> GET HTML CODE </Button> */}
										</div>
										<LoadingButton type="button" color={savePublishMessage.bgColor} loading={state.buttonLoading}  className="w-100" disabled={state.buttonLoading} label={savePublishMessage.message} onClick={() => onPublishClick()}  />
										</>
									}
								</div>
							</div>
						
							<div className='selectiontabmain__right_tab '>
							{state.practiceInfo.is_paid_account === 1 ? (
								<Button type="button" color="link" className="ml-auto"
									style={{ color: restoreDefault ? 'yellow' : 'yellow', marginTop:'-30px' , display:'block', paddingRight:'0'}}
									// disabled={restoreDefault === false ? 'disabled' : ''}
									onClick={restoreDefault === true ? () => restoreDefaultSettings() : ''}
								>
									RESTORE TO DEFAULT
								</Button>
							) : null}

							<div>
							<iframe 
								title="html" 
								// key={state.websiteMediaConfigs.media_audio_language_id}
								style={{
									width: '1160px',
									height: '656px',
									borderRadius: '5px 5px 5px',
									border: '1px solid white',
									background: 'white',
								}}
								src={src}>
							</iframe>
								{/* <iframe
									id="prv"
									title="html"
									style={{
										// width: '1160px',
										// height: '656px',
										// borderRadius: '5px 5px 5px',
										// border: '1px solid white',
										// background: 'white',
									}}
								/> */}
							</div>
							<div className='selectiontabmain__right_tab__color_scheme'>
								<p>Colour scheme</p>
							</div>
							<div className='selectiontabmain__right_tab__color_box'>
								{(state.websiteMediaColours &&
									state.websiteMediaColours.length > 0) ||
									state.websiteMediaColours.length > 4 ? (
									<ColoursSlider
										colours={state.websiteMediaColours}
										setColour={setColour}
										setSaveBtnEnable={setSaveBtnEnable}
										activeColourId={coloursId}
										profile={profile}
									/>
								) :  (
									<div className="website_videos_layout_selection__color_slider d-flex" style={{ marginBottom: '13px', marginLeft:'-20px',marginRight:'-20px' }}>
										<div className='' style={{padding:'0 20px'}}>
											<Skeleton
												animation="wave"
												variant="rectangular"
												width={200}
												height={102}
											/>
										</div>
										<div className='' style={{padding:'0 20px'}}>
											<Skeleton
												animation="wave"
												variant="rectangular"
												width={200}
												height={102}
											/>
										</div>
										<div className='' style={{padding:'0 20px'}}>
											<Skeleton
												animation="wave"
												variant="rectangular"
												width={200}
												height={102}
											/>
										</div>
										<div className='' style={{padding:'0 20px'}}>
											<Skeleton
												animation="wave"
												variant="rectangular"
												width={200}
												height={102}
											/>
										</div>
										<div className='' style={{padding:'0 20px'}}>
											<Skeleton
												animation="wave"
												variant="rectangular"
												width={200}
												height={102}
											/>
										</div>
								
									
									</div>
								) }
							</div>
						</div>
						</div>
					</div>
				</>
		</div>
	);
};

export default RenderHTML;