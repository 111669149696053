import React from 'react'
import { history } from '..';
import SubHeader from '../../components/Header/subHeader';
import './styles.scss';
const NewWaitingRoomAdded = () => {
    return (
        <div className="main__screen channels__page  d-flex flex-column h-100 align-items-center justify-content-center">
            <SubHeader className="mb-auto" title="New Waiting Room Channel"/>
            <div className="pagethree__main">
                <div className="pagethree__main__parent-div row">
                    <div className="col-md-12 pagethree__main__parent-div__div1">
                        <div>
                        <h4 style={{ marginBottom:"20px", color:"white" }}>Channel added Successfully</h4>
                        <p>"Basic Dental Care" has been scheduled to be published for all Thalamus users on "10 Sep 2020". Any information and content pertaining to this channel can be uploaded at any moment.</p>
                            </div>
                </div>
                    <div className="col-md-12 pagethree__main__parent-div__div2">
                        <button type="button" name="" onClick={ () => history.push('/channels') } className=" pagethree__main__parent-div__div2__button btn btn-primary">BACK TO WAITING ROOM CHANNELS</button>
                </div>
                </div>
            
                
            </div>
        </div>
    )
}

export default NewWaitingRoomAdded
