/** @format */

import React, { useRef, useState, useEffect, useCallback } from "react";
import ReactPlayer from "react-player/file";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "@material-ui/core";
import screenfull from "screenfull";
import VideoPlayer from "./VideoPlayer";
import { formatSecond, TIMEHHMMSS } from "../../data/comman";
import moment from "moment";
import {
  GET_CHAIRSIDE_MEDIA,
  WAITINGROOM_MEDIA,
} from "../../utilities/HttpUtility";
import { Button } from "reactstrap";
import { history } from "../../pages/index";
import Style from "style-it";
import WaterMarkLogo from "../../img/logo.svg";
import './style.scss';
import VideoSliderList from "./VideoSliderList";
import { getSystemVideoQuality } from "../../helper/LocalStorage";

const useStyles = makeStyles({
  playerWrapper: {
    width: "1170px",
    minHeight:'658px',
    maxHeight:'658px',
    position: "relative",
    margin: "0 auto",
  },
  controlsSwapper: {
    // position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // background: 'rgba(0,0,0,0.6)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
  },
  controlIcons: {
    color: "#777",
    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 100,
  },
  processSlider: {
    width: 250,
  },
});
const format = (seconds) => {
  if (isNaN(seconds)) {
    return "00:00";
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")} :${ss}`;
  }
  return `${mm}:${ss}`;
};

var count = 0;
const Player = ({  video, onClickClose, openImgEditor, togglePip,isWaitingRoom,setNextVideo, setPreVideo}) => {
  const classes = useStyles();
  const states = useSelector((state) => state.task);
  const [state, setState] = useState({
    playing: true,
    muted: true,
    volume: 0.5,
    played: 0,
    seeking: false,
    toggleScreen: false,
    pip: false,
		subtitle: states.chairsidePlaylist?.cc_status == 1 ? true : false,
    closeVideo: false,
  });

  const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
  const [screenshots, setScreenshots] = useState([]);
  const [open, setOpen] = useState(false);
  const [ssImage, setssImage] = useState();
  const {
    playing,
    muted,
    volume,
    played,
    seeking,
    toggleScreen,
    pip,
    subtitle,
  } = state;
  const cs_video_section_types = states.cs_video_section_types;
  const [handleVideoSectionSelectedIndex, setHandleVideoSectionSelectedIndex] =
    useState(-1);
  const [isReady, setIsReady] = useState(false);
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const canvasRef = useRef(null);
  const [openOverly, setShowOverly] = useState(false);

  useEffect(() => {
    screenfull.on("change", () => {
      if (!screenfull.isFullscreen) {
        setState({ ...state, toggleScreen: false });
      }
    });
  }, []);


  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const handleVolumeChange = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };
  const handleVolumeSeekDown = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const toggleFullScreen = () => {
    setState({ ...state, toggleScreen: !state.toggleScreen });
    screenfull.toggle(playerContainerRef.current);
  };

  const handleProgress = (changeState) => {
    var currentTime = playerRef.current.getCurrentTime();
    const duration = playerRef.current.getDuration();
    var totalPer = (currentTime / duration) * 100;
    if (
      states.practiceInfo.is_paid_account == 0 &&
      states.practiceInfo.days_left == 0
    ) {
      if (totalPer > 33) {
        setShowOverly(true);
      }
    }

    if (count > 3) {
      count = 0;
    }

    if (controlsRef.current.style.visibility === "visible") {
      count += 1;
    }

    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }
  };

  const setVideoQuality =()=>{
		setIsReady(true)
		const internalPlayer = playerRef.current?.getInternalPlayer('hls');
		if (internalPlayer) {
			internalPlayer.currentLevel = 3
		}
	}

  const handleSeekChange = (e, newValue) => {
    setState({ ...state, played: parseFloat(newValue / 100) });
  };
  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };
  const handleSeekMouseUp = (e, newValue) => {
    playerRef.current.seekTo(newValue / 100);
  };
  const handleChangeDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === "normal" ? "remainning" : "normal"
    );
  };
  const handleMouseMove = () => {
    controlsRef.current.style.visibility = "visible";
    count = 0;
  };
  const handleTogglePip = () => {
    if (isReady) setState({ ...state, pip: !state.pip });
  };
  const handleClickClose = () => {
    setState({ closeVideo: !state.closeVideo });
    onClickClose(state.closeVideo);
  };
  const handleClickSubtitle = () => {
    setState({ ...state, subtitle: !state.subtitle });
  };



  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : "00:00";

  const elapsedTime =
    timeDisplayFormat === "normal"
      ? format(currentTime)
      : `-${format(duration - currentTime)}`;
  const totalDuration = format(duration);


  const actionHandlers = [
    [
      "play",
      () => {
        /* ... */
      },
    ],
    [
      "pause",
      () => {
        /* ... */
      },
    ],
    [
      "previoustrack",
      () => {
        /* ... */
      },
    ],
    [
      "nexttrack",
      () => {
        /* ... */
      },
    ],
    [
      "stop",
      () => {
        /* ... */
      },
    ],
    [
      "seekbackward",
      (details) => {
        /* ... */
      },
    ],
    [
      "seekforward",
      (details) => {
        /* ... */
      },
    ],
    [
      "seekto",
      (details) => {
        /* ... */
      },
    ],
  ];

  const getFontSize = (size) => {
    switch (size) {
      case 1:
        return ["24px"];
      case 2:
        return ["30px"];
      case 3:
        return ["46px"];
      default:
        return ["24px"];
    }
  };

  // const setNextVideo = () =>{

  // }

  const cc_font_size =
    states.practiceUserLoginInfo.account_setting &&
    states.practiceUserLoginInfo.account_setting.cc_font_size;
  const ccLanguage = states.practiceUserAccountInfo?.account_setting?.caption_language || video?.media_language_id;
  return (
    <Container maxWidth="xl" style={{padding:'0'}}>
      <Style>
        {`
            ::cue{
                font-size:${getFontSize(cc_font_size)};
                visibility:${subtitle ? 'visible' : 'hidden'};
                background-color:${subtitle ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0)'} ;
            }
	    `}
      </Style>
      <div
        ref={playerContainerRef}
        onMouseMove={handleMouseMove}
        className={`${classes.playerWrapper} ${
          toggleScreen ? "fullscreenvideo" : "normalscreen"
        } ${handleVideoSectionSelectedIndex == -1 ? "height-82" : ""}`}
      >
        <ReactPlayer
          key={video.id}
          ref={playerRef}
          url={video?.media?.hls_stream}
          pip={pip}
          muted={muted}
          height="100%"
          width={"100%"}
          maxheight={'658px'}
          playing={playing && !openOverly}
          volume={volume}
          onEnded={setNextVideo}
          stopOnUnmount={false}
          onProgress={handleProgress}
          onError={setNextVideo}
          onReady={() => {
            setVideoQuality();
          }}
          className="chairsidePlayer"
          onEnablePIP={(e) => {
            if (togglePip && isReady) {
              togglePip(true);
              setState({ ...state, pip: true });
            }
          }}
          onDisablePIP={(e) => {
            if (togglePip && isReady) {
              togglePip(false);
              setState({ ...state, pip: false });
            }
          }}
          config={{
						file: {
							attributes: {
								crossOrigin: 'true',
							},
							tracks: 	[
										{ kind: 'subtitles',
											src: `${GET_CHAIRSIDE_MEDIA}/${video?.chairside_media_id}/cc/download/${ccLanguage}.vtt?user_id=${states.practiceUserProfileInfo.id}`,
											srcLang: video?.language?.sort_name,
											default: true
										},
									] 

						},
					}}
        />

        {actionHandlers.map((item) =>
          navigator.mediaSession.setActionHandler(item[0], item[1])
        )}

        <VideoPlayer
          ref={controlsRef}
          handlePlayPause={handlePlayPause}
          playing={playing && !openOverly}
          muted={muted}
          handleMute={handleMute}
          handleVolumeChange={handleVolumeChange}
          handleVolumeSeekDown={handleVolumeSeekDown}
          volume={volume}
          toggleFullScreen={toggleFullScreen}
          played={played}
          handleSeekChange={handleSeekChange}
          handleSeekMouseDown={handleSeekMouseDown}
          handleSeekMouseUp={handleSeekMouseUp}
          elapsedTime={elapsedTime}
          totalDuration={totalDuration}
          handleChangeDisplayFormat={handleChangeDisplayFormat}
          toggleScreen={toggleScreen}
          handleTogglePip={handleTogglePip}
          title={'Title'}
          handleClickClose={handleClickClose}
          handleClickSubtitle={handleClickSubtitle}
          handleVideoSectionSelectedIndex={handleVideoSectionSelectedIndex}
          cs_video_section_types={cs_video_section_types}
          video={video}
          isWaitingRoom={isWaitingRoom}
          setNextVideo={setNextVideo}
          setPreVideo={setPreVideo}
          subtitle={subtitle}
        />
      
      </div>
    </Container>
  );
};

export default Player;
