import React from 'react';

import { Button , Label , FormGroup, Form , Col , Row  } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import {  connect } from 'react-redux'
import { Ownership  , operatories} from '../../../data/comman';
import { compose } from 'redux';
import HttpUtility, {HELPERURI} from '../../../utilities/HttpUtility';
import moment from 'moment';
import { UpdatepracticeInfo , UpdatepracticeInfoRequest  } from "../../../store/actions/taskAction";
import { history } from '../../../pages/index';
class Review extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        practiceProfileInfo:{
            ...props.practiceProfileInfo,
            selectedownership:'',
            selectedstate:'',
        },
    };
    this.gotoBasicInfo = this.gotoBasicInfo.bind(this);
    this.gotoPracticeHours = this.gotoPracticeHours.bind(this);
    this.gotoAdditionalInfo = this.gotoAdditionalInfo.bind(this);
  }

  gotoBasicInfo(){
    this.props.gotoBasicInfo();
  }

  gotoPracticeHours(){
      this.props.gotoPracticeHours();
  }
  
  gotoAdditionalInfo(){
    this.props.gotoAdditionalInfo();
  }

 async  componentDidMount(){
  }


  
  render (){
    const practiceProfileInfo  = this.props.practiceProfileInfo
    return(
        <div className="review__screen d-flex flex-column h-100 align-items-center justify-content-center"> {/* practice_info */}
          <div className="transparent__form--white" style={{ maxWidth: 820 }}>
            {practiceProfileInfo &&
            <Form className="h-100 d-flex flex-column">
              <Row>
                <Col md={6}>
                    <FormGroup className="mb-20 d-flex align-items-center">
                        <h4 className="m-0 mt-1 fw400">Basic information</h4>
                        <Button type="button"  onClick={()=>history.push("/basic-info-edit")}  color="link primary" className="py-0 mt-1">Edit</Button>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice name</Label>
                        <Col sm={12}>
                            <span className="text--gray">{practiceProfileInfo?.name}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Ownership</Label>
                        <Col sm={12}>
                            <span className="text--gray">{practiceProfileInfo?.ownership?.name}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice phone number</Label>
                        <Col sm={12}>
                            <span className="text--gray">{practiceProfileInfo?.phone}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice address</Label>
                        <Col sm={12}>
                            <span className="text--gray">
                                {practiceProfileInfo?.address_line_1} , {practiceProfileInfo?.address_line_2} <br/>
                                {practiceProfileInfo?.city}/{practiceProfileInfo?.selectedstate && practiceProfileInfo?.selectedstate && practiceProfileInfo?.selectedstate?.name}, {practiceProfileInfo?.zip} <br/>
                                {practiceProfileInfo?.selectedcountry && practiceProfileInfo?.selectedcountry?.name}
                            </span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice email</Label>
                        <Col sm={12}>
                            <span className="text--gray">{practiceProfileInfo.email}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-0">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Specialization</Label>
                        <Col sm={12}>
                            <span className="text--gray overflow-scroll">
                                {practiceProfileInfo?.specializations?.length >= 1 && practiceProfileInfo?.specializations?.map((items, index)=>{
                                    return <div key={index}>{items && items.name}</div>
                                })}
                            </span>
                        </Col>
                    </FormGroup>
                </Col>
                <Col md={6} className="pr-5">
                    <FormGroup className="mb-20 d-flex">
                        <h4 className="m-0 mt-1 fw400">Additional information</h4>
                        <Button type="button"  onClick={()=>history.push("/additional-info-edit")} color="link primary" className="py-0 mt-1">Edit</Button>
                    </FormGroup>
                    <Row>
                        <Col sm={7} className="">
                            {/* <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Location manager</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.additional_info.location_manager}</span>
                                </Col>  
                            </FormGroup> */}
                            <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice software</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.selectedsoftware ? practiceProfileInfo.selectedsoftware.name : ""}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Number of operatories</Label>
                                <Col sm={12}>
                                    {console.log(practiceProfileInfo,'practiceProfileInfo')}
                                    <span className="text--gray">{practiceProfileInfo.selectedoperatories && practiceProfileInfo.selectedoperatories.value}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Referral source</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.selectedsource && practiceProfileInfo.selectedsource.name}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice type</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.selectedpracticetype && practiceProfileInfo.selectedpracticetype.name}</span>
                                </Col>
                            </FormGroup>
                            {/* <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Sales representative</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.additional_info.sales_executive}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-0">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Distributor</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.additional_info.distributor_name}</span>
                                </Col>
                            </FormGroup> */}
                             <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="" sm={12} >TVs in the practice</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.selectedtv_count && practiceProfileInfo.selectedtv_count.value}</span>
                                </Col>
                            </FormGroup>
                        </Col>
                        {/* <Col className="px-4">
                            <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="" sm={12} >System language</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.systemlanguage && practiceProfileInfo.systemlanguage.name}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="" sm={12} >Media Language</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.medialanguage && practiceProfileInfo.medialanguage.name}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20">
                                <Label htmlFor="practicename" className="" sm={12} >TVs in the practice</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{practiceProfileInfo.selectedtv_count.value}</span>
                                </Col>
                            </FormGroup>
                        </Col> */}
                    </Row>
                </Col>
                {/* <Col md={3} className="ml-auto practice_hours">
                    <FormGroup className="mb-20 d-flex practice_hours_header">
                        <h4 className="m-0 mt-1 fw400">Practice hours</h4>
                        <Button type="button" onClick={()=>history.push("/practice-hours-edit")} color="link primary" className="py-0 mt-1">Edit</Button>
                    </FormGroup>
                    {practiceProfileInfo && 
                        <div className="d-flex flex-column justify-content-between practice_week">
                        <FormGroup row  className="align-items-center mb-3">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Sunday</Label>
                            <Col sm={6} className="p-0 ml-auto">
                                <span className="text--gray">{practiceProfileInfo.additional_info.schedule[6].status === 0 ? 'Closed' : `${moment(practiceProfileInfo.additional_info.schedule[6].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(practiceProfileInfo.additional_info.schedule[6].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Monday</Label>
                            <Col sm={6} className="p-0 ml-auto">
                                <span className="text--gray">{practiceProfileInfo.additional_info.schedule[0].status === 0 ? 'Closed' : `${moment(practiceProfileInfo.additional_info.schedule[0].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(practiceProfileInfo.additional_info.schedule[0].end_time,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Tuesday</Label>
                            <Col sm={6} className="p-0 ml-auto">
                                <span className="text--gray">{practiceProfileInfo.additional_info.schedule[1].status === 0 ? 'Closed' : `${moment(practiceProfileInfo.additional_info.schedule[1].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(practiceProfileInfo.additional_info.schedule[1].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Wednesday</Label>
                            <Col sm={6} className="p-0 ml-auto">
                                <span className="text--gray">{practiceProfileInfo.additional_info.schedule[2].status === 0 ? 'Closed' : `${moment(practiceProfileInfo.additional_info.schedule[2].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(practiceProfileInfo.additional_info.schedule[2].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Thursday</Label>
                            <Col sm={6} className="p-0 ml-auto">
                                <span className="text--gray">{practiceProfileInfo.additional_info.schedule[3].status === 0 ? 'Closed' : `${moment(practiceProfileInfo.additional_info.schedule[3].start_time,["HH:mmH"]).format("hh:mm A")} to ${moment(practiceProfileInfo.additional_info.schedule[3].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Friday</Label>
                            <Col sm={6} className="p-0 ml-auto">
                                <span className="text--gray">{practiceProfileInfo.additional_info.schedule[4].status === 0 ? 'Closed' : `${moment(practiceProfileInfo.additional_info.schedule[4].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(practiceProfileInfo.additional_info.schedule[4].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Saturday</Label>
                            <Col sm={6} className="p-0 ml-auto">
                                <span className="text--gray">{practiceProfileInfo.additional_info.schedule[5].status === 0 ? 'Closed' : `${moment(practiceProfileInfo.additional_info.schedule[5].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(practiceProfileInfo.additional_info.schedule[5].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                    </div>
                    }
                </Col> */}
              </Row>
            </Form>
            }
          </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    country: state.task.country,
    specializations: state.task.specializations,
    practiceProfileInfo: state.task.practiceProfileInfo,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Review);