import React from 'react';

import { Button, Label, FormGroup, Form, CustomInput, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../../pages/index';

import { connect } from "react-redux";
import { compose } from 'redux';
import { InputField} from '../../../components/InputField';
import LoadingButton from '../../../components/LoadingButton'
import DropDown from '../../../components/DropDown'
import { UpdateUserProfileAboutInfo, DeleteUserProfile } from "../../../store/actions/taskAction";
import ReactCodeInput from 'react-code-input';
import {UpdatePracticeUserProfileRequest, cleardata} from "../../../store/actions/taskAction";
import './style.scss';

const profile_type = [
  { name: 'secure', id: 1 },
  { name: 'unsecure', id: 0 },
];
class Review extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      roleDisabled: true,
      istoggleDelete: false,
      practice_roles: [],
      hidden: true,
      userPasscode: false,
      i_agree: false,
      isSave:false,
      isDeleteActive:false,
      loadingbtn1:false,
      isError: {
        first_name:'',
        last_name:'',
        selectedRole:'',
      }
    };
    this.nextClick = this.nextClick.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.onclickDelete = this.onclickDelete.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  nextClick() {
    this.props.nextStep();
  }

  async componentDidMount() {
    if (this.props.practiceUserProfileInfo.type !== 4 && this.props.practiceUserProfileInfo.type !== 2) {
      this.setState({
        roleDisabled: false
      });
    }
  }

  async  updateUserProfile() {
    this.setState({
      loadingbtn1: true
    });
    var params = {
      first_name: this.props.practiceUserProfileInfo.first_name,
      last_name: this.props.practiceUserProfileInfo.last_name,
      is_secure: this.props.practiceUserProfileInfo.selectedProfileType.id,
      role: this.props.practiceUserProfileInfo.selectedRole.id,
      id:this.props.practiceUserProfileInfo.id
  }
  await this.props.dispatch(UpdateUserProfileAboutInfo(params));

    this.setState({
      loadingbtn1: false
    });
  }

  async deleteUserProfile(passcode) {
    await  this.props.dispatch(DeleteUserProfile(passcode));
   
  }

  onclickDelete() {
    this.setState({ istoggleDelete: !this.state.istoggleDelete })
  }

  onBackToprofiles() {
    this.props.dispatch(cleardata());
    history.push('/profileselection');
  }




  handleDelete(event) {
    this.setState({ i_agree: !this.state.i_agree });
    this.isValid()
  }

  async onEditPracticeInfo(field , value){
    let practiceUserProfileInfo = this.props.practiceUserProfileInfo
    practiceUserProfileInfo[field] = value
    await this.props.dispatch(UpdatePracticeUserProfileRequest(practiceUserProfileInfo))

    await this.isValid(field , value)
  }

  isValid(name, value){
    let isError = this.state.isError ;
    
    switch (name) {
      case "first_name":
          isError.first_name =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "last_name":
          isError.last_name =  
            value.length <= 0 ? "required" : "";
          break;
      case "selectedRole":
          isError.selectedRole =  
          value.length <= 0 ? "required" : "";
          break;
      default:
          break;
    }
    this.setState(isError , this.validateForm)
    
  }

  validateForm(){
    let isError = this.state.isError ;
    const { first_name , last_name , selectedRole , selectedProfileType , passcode } = this.props.practiceUserProfileInfo;
      
    if( first_name !== '' && isError.first_name === '' &&  last_name !== '' && selectedRole !== undefined &&  selectedProfileType !== undefined ){
        this.setState({ isSave: true });
    }else{
      this.setState({ isSave: false });
    }

    if(this.props.practiceUserProfileInfo.is_secure === 1){
      if(passcode && passcode.length == 4 &&  this.state.i_agree){
        this.setState({ isDeleteActive: true });
      }else{
        this.setState({ isDeleteActive: false });
      }
    }else{
      if( this.state.i_agree){
        this.setState({ isDeleteActive: true });
      }
    }
  }

  render() {
    const { t, practice_user_type, practice_user_roles, practiceUserProfileInfo, loadingDeleteUserProfile } = this.props
    const {isError} = this.state
    var isDeleteActive = this.state.isDeleteActive;
    // if(this.props.practiceUserProfileInfo.is_secure === 1){
    //   if (this.state.isDeleteActive && this.state.i_agree && practiceUserProfileInfo.passcode.length === 4) {
    //     this.setState({ isDeleteProfile: false });
    //   } else {
    //     this.setState({ isDeleteProfile: true });
    //   }
    // }else{
    //   if (this.state.i_agree === true) {
    //     isDeleteActive = false;
    //   } else {
    //     isDeleteActive = true;
    //   }
    // }
    
    return (
      <div className={`${this.state.istoggleDelete ? 'bg_red' : ' '} about__screen transparent__form--white  md d-flex flex-column align-items-stretch mb-auto`}>
        <Form className="h-100 d-flex flex-column">
          {!this.state.istoggleDelete ?
            <>
              <FormGroup className="mb-20 header" style={{marginBottom:"32px !important"}}>
                <h4 className="m-0 mt-1">Identification</h4>
              </FormGroup>
              <FormGroup className="align-items-center mb-20" style={{marginBottom:"13px !important"}}>
                <InputField disabled={this.state.roleDisabled} type="text" isError={isError.first_name} defaultValue={practiceUserProfileInfo.first_name || ''} onChange={(e)=>this.onEditPracticeInfo( 'first_name' , e.target.value)} className="mb-3" label="Name" placeholder="First" name="first_name" />
                <InputField disabled={this.state.roleDisabled} type="text" defaultValue={practiceUserProfileInfo.last_name || ''} onChange={(e)=>this.onEditPracticeInfo( 'last_name' , e.target.value)}  placeholder="Last" name="last_name" />
              </FormGroup>
              <FormGroup>
                <div className="d-flex justify-content-between">
                  <Label>Role</Label>
                </div>
                {this.props.practiceUserProfileInfo.type === 4 || this.props.practiceUserProfileInfo.type === 2 ?
                  <DropDown
                    className='react-select-container'
                    classNamePrefix="react-select"
                    options={practice_user_type}
                    getOptionLabel={x => `${x.name}`}
                    getOptionValue={x => `${x.id} ${x.name}`}
                    // onChange={(options) => this.props.handleChange("selectedRole", options)}
                    onChange={(val)=> this.onEditPracticeInfo('selectedRole', val) }
                    isSearchable={false}
                    placeholder={'Select an option'}
                    value={practiceUserProfileInfo.selectedRole}
                    isDisabled={this.state.roleDisabled}
                    // menuIsOpen={true}
                  />
                  :
                  <DropDown
                    className='react-select-container'
                    classNamePrefix="react-select"
                    options={practice_user_roles}
                    getOptionLabel={x => `${x.name}`}
                    getOptionValue={x => `${x.id} ${x.name}`}
                    // onChange={(options) => this.props.handleChange("selectedRole", options)}
                    onChange={(val)=> this.onEditPracticeInfo('selectedRole', val) }
                    isSearchable={false}
                    placeholder={'Select an option'}
                    value={practiceUserProfileInfo.selectedRole}
                    isDisabled={this.state.roleDisabled}
                    // menuIsOpen={true}
                  />
                }

              </FormGroup>
              <FormGroup className="my-5 py-3 border-bottom" style={{marginBottom:"16px !important"}}>
                <div className="border--bottom"></div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex justify-content-between">
                  <Label>Profile type</Label>
                </div>
                <DropDown
                  className='react-select-container'
                  classNamePrefix="react-select"
                  options={profile_type}
                  getOptionLabel={x => `${x.name}`}
                  getOptionValue={x => `${x.id} ${x.name}`}
                  // onChange={(options) => this.props.handleChange("selectedProfileType", options)}
                  onChange={(val)=> this.onEditPracticeInfo('selectedProfileType', val) }
                  isSearchable={false}
                  placeholder={''}
                  value={practiceUserProfileInfo.selectedProfileType}
                  isDisabled={true}
                  // menuIsOpen={true}
                />
              </FormGroup>
              <div className="mt-auto d-flex justify-content-between">
                {this.props.practiceUserProfileInfo.type !== 4 && this.props.practiceUserProfileInfo.type !== 2 ?
                  <>
                    <LoadingButton type="button" size="sm" disabled={!this.state.isSave || this.state.loadingbtn1} loading={this.state.loadingbtn1} label={`Save changes`} onClick={() => this.updateUserProfile()} className="col" color="primary"/>
                    <Button type="button" size="sm" onClick={() => this.onclickDelete()} className="col" color="red">Delete profile</Button>
                  </>
                  :
                  <LoadingButton type="button" size="xxl" disabled={!this.props.isNext || this.state.loadingbtn1} loading={this.state.loadingbtn1} label={`Save changes`} onClick={() => this.updateUserProfile()} color="primary"/>
                  // <Button type="button" size="xxl" onClick={() => this.updateUserProfile()} disabled={!this.props.isNext} className="" color="primary">Save changes</Button>
                }
              </div>
            </>
            :
            <div className="d-flex flex-column text-center h-100">
              {!this.props.practiceUserdeleteInfo ?
                <>
                  <div className="d-flex flex-column text-center my-auto delete-profile">
                    <FormGroup className="mb-5 pb-5 border--bottom delete-heading">
                      <h4 className="m-0 mt-1">Attention!</h4>
                    </FormGroup>
                    <FormGroup className="mb-20">
                      <h4 className="mb-20 mt-1 profile-remove">Profile removal!</h4>
                      <p className="remmoval-meesage">You are about to delete this profile. Once done, you won’t be able to access it anymore, nor recover any saved files within.</p>
                    </FormGroup>
                    <FormGroup className="mb-5 delete-checksign">
                      <div className="custom-control white custom-redio">
                        <input type="checkbox" onChange={this.handleDelete} defaultChecked={this.state.i_agree} className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Yes, I want to delete my profile.</label>
                      </div>
                    </FormGroup>
                    {this.props.practiceUserProfileInfo.is_secure === 1 ?
                      <FormGroup className={`my-auto ${this.props.error ? 'notverifyed' : ''}`}>
                        <div className="d-flex justify-content-between passcode">
                          <label>Enter your passcode</label><Button onClick={this.toggleShow} color="link white" className="p-0">SHOW</Button>
                        </div>
                        <ReactCodeInput type={this.state.hidden ? 'password' : 'number'} onChange={(val)=>this.onEditPracticeInfo( 'passcode' , val)} fields={4} className={`bg_white mb-2`} />
                      </FormGroup>
                      : null}
                  </div>
                  <div className="mt-auto d-flex justify-content-between">
                    <Button type="button" size="sm" onClick={() => this.onclickDelete()} className="col" outline color="default white">Cancel</Button>
                    {this.props.practiceUserProfileInfo.is_secure === 1 ?
                      <LoadingButton type="button" size="sm" error={loadingDeleteUserProfile} label={'Delete profile'} loading={loadingDeleteUserProfile} disabled={!isDeleteActive || loadingDeleteUserProfile} onClick={() => this.deleteUserProfile(this.props.practiceUserProfileInfo.passcode)} className={this.props.SuccessProfileRemove === true ? 'verifyed' : ''} color="default"/>
                      :
                      <LoadingButton type="button" size="sm" error={loadingDeleteUserProfile} label={'Delete profile'} loading={loadingDeleteUserProfile} disabled={!isDeleteActive || loadingDeleteUserProfile} onClick={() => this.deleteUserProfile(null)} className={this.props.SuccessProfileRemove === true ? 'verifyed' : ''} color="default"/>
                    }
                  </div>
                </>
                :
                <>
                  <div className="d-flex flex-column text-center  h-100 profile-deleted">
                    <FormGroup className="my-auto">
                      <h4 className="mb-5 mt-1">Profile deleted</h4>
                      <p>The profile {`${this.props.practiceUserProfileInfo.first_name} ${this.props.practiceUserProfileInfo.last_name}`} has been deleted, and is no longer accessible. To go back to the profile selection screen, please click the button bellow.</p>
                    </FormGroup>
                  </div>
                  <div className="mt-auto">
                    <Button type="button" onClick={() => this.onBackToprofiles()} className="col w-100" outline color="default white">Back to profile selection</Button>
                  </div>
                </>
              }
            </div>
          }
        </Form>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  practice_user_roles: state.task.practice_user_roles,
  practice_user_type: state.task.practice_user_type,
  practiceUserProfileInfo: state.task.practiceUserProfileInfo,
  practiceUserdeleteInfo: state.task.practiceUserdeleteInfo,
  loadingDeleteUserProfile: state.task.loadingDeleteUserProfile,
  loadingbtn1:state.task.loadingbtn1
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(Review);
