import React from 'react';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Button , Progress , Label } from 'reactstrap';
import close_small from '../../img/Close_small.svg'
import noun_drag_and_drop from '../../img/noun_drag_and_drop.svg'
import IconSuccessful_loading from '../../img/charm_tick.svg'
import {ReactComponent as CloseIcon} from '../../img/IconCloseCircle.svg';
import {ReactComponent as ErrorIcon} from '../../img/clarity_error-standard-solid.svg';
import {ReactComponent as FileTypeIcon} from '../../img/file_type_icon.svg';

import './style.scss';

class FileUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isStart:true,
    }
      this.onChange = React.createRef();
  }

  async componentDidMount(){

  }

    clearInput()
    {
        const file = document.getElementById("uploadFiles");
        file.value = [];
    }

  render (){
    return(
        <div className="file_upload__section">
            <div className="d-flex justify-content-between">
                <Label>New file upload</Label>
                <div className="file_upload_file_type">.jpeg, .png, .heic   |   Max 10Mb</div>
            </div>
            <div className="file_upload__box d-flex flex-column">
            {
                this.props.file ?
                <div className='p-4'>
                <div className="file_upload__item mb-3  d-flex align-items-center justify-content-between">
                    <div className='mr-3'><FileTypeIcon /></div>
                    <div className='d-flex flex-column w-100'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <span className="file_upload__item--name">{this.props.file.name}</span>
                            {!(this.props.errors.fileType || this.props.errors.fileSize) && this.props.loaded !== 100 &&
                                <span className='file_upload__item--name'>{this.props.loaded}%</span>
                            }
                            { this.props.errors.fileType && <div className='d-flex align-items-center'><span className="error--label fs-11 "><ErrorIcon className='mr-2'/> {this.props.errors.fileType}</span></div>}
                            { this.props.errors.fileSize && <div className='d-flex align-items-center'><span className="error--label fs-11 "><ErrorIcon className='mr-2' /> {this.props.errors.fileSize}</span></div>}
                        </div>
                        {!(this.props.errors.fileType || this.props.errors.fileSize)  && this.props.loaded < 100 && !this.props.completed  &&
                            <div className="d-flex align-items-center w-100 mt-2">
                                <Progress className={`file_upload__progress  mb-0 ${this.props.errors.fileType || this.props.errors.fileSize ? 'text-danger' : ''} ${this.props.completed ? 'completed' : null }`} value={`${this.props.loaded}`}>
                                                
                                </Progress>
                            </div>
                        }
                    </div>
                     {this.props.loaded === 100 && 
                        <div className="ml-3">
                            <img src={IconSuccessful_loading} alt="" />
                        </div>
                    }
                    <div className='file_upload__close_btn'>
                        <Button className="clear__icon" color="link p-0 m-0" onClick={() => { this.clearInput();this.props.cancelUpload()}}>
                            <CloseIcon/>
                        </Button>
                    </div>
                   
                  
                </div>
                </div>
                : null
            }
              {/* <div className="col ">
                        { this.props.errors.fileType && <span className="error--label fs-11 ">{this.props.errors.fileType}</span>}
                        { this.props.errors.fileSize && <span className="error--label fs-11 ">{this.props.errors.fileSize}</span>}
                    </div> */}
                <input  onClick={e => (e.target.value = null)} type="file" id="uploadFiles" ref={ref=> this.onChange = ref}  accept="image/png, image/gif, image/jpeg"  
                    style={{position: 'absolute' , top: '0' , bottom: '0' ,right: '0' ,left: '0' ,opacity: '0'}}   onChange={(e)=>{this.props.onChange(e)}} />
                {/* <div className="file_upload__note mt-auto text-center my-4">{this.props.note}</div> */}
                <div className="file_upload__drug__file__section align-self-end py-4 text-center d-flex justify-content-center align-items-center mt-auto">
                    Drag & drop your files, or <label className="btn-link m-0 pl-2 primary" htmlFor="uploadFiles">click to upload.</label> <img className="ml-4" src={noun_drag_and_drop} alt="Drag and Drop"/>
                </div>
            </div>
        </div>
    ) 
  }
}

export default compose(
  withTranslation('common'),
)(FileUpload);
