import React, { useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./colorsScheme.scss";
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const ColorScheme = ({ colors, submitColors }) => {
  const [activeIndex, setActiveIndex] = useState(colors[0].colorName);

  useEffect(() => {
    submitColors(colors[0]);
  }, []);

  const changeButton = (color) => {
    setActiveIndex(color.colorName);
    submitColors(color);
  };

  return (
    <>
      <Swiper spaceBetween={50} slidesPerView={5} navigation>
        {colors.map((color, index) => {

          const colorName = color
            ? color.colorName.toUpperCase()
            : "BLACK - DEFAULT";

          const backColor = color ? color.color : "#202020";
          const active = activeIndex === color.colorName ? "active" : "";
          const activeImage = active ? "assets/img/colorsImg/closeicon.png" : "assets/img/colorsImg/whitebox.png";
          const subscription = color.subscription === false ? ['subscription','SUBSCRIPTION REQUIRED'] :"";

          return (
            <SwiperSlide key={index}>
              <div
                className={`color_scheme ${active} ${subscription[0]}`}
                style={{
                  background: `linear-gradient(${backColor[0]},${backColor[1]} )`,
                }}
                onClick={() => changeButton(color)}
                
              >
                <img className="color_scheme__checkbox_image" src={`${activeImage}`} alt="" />
                <div className="color_scheme__scheme-button">{colorName}</div>
                <div className={`color_scheme__subscription-button  `} >{subscription[1]}</div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default ColorScheme;
