import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import DropDown from '../../components/DropDown'
import LinkIcon from '../../img/IconLink.svg'
import { InputField } from '../../components/InputField';
import LoadingButton from '../../components/LoadingButton'
import { history } from '../../pages/index';
import { Button , Label , Spinner} from 'reactstrap';
import {GetDomainList ,RemoveDomainRequest, RemoveDomain,UpdateDomain,CreateDomainRequest, GetSubscribeInfo , SubscribeAdditionalDomainRequest , SubscribeAdditionalDomain , resetSuccess , CreateDomain ,UpdateDomainSuccess, UpdateDomainRequest} from "../../store/actions/taskAction";
import './style.scss';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdditionalClick:false,
      isEdit:false,
      isAddNew:false,
      isAdd:false,
      isRemoveDomain:[],
      totelPage:0,
      currentPage:1,
      per_page:5,
      addtionalDomainDropDown: [],
      selectedAdditionalDomain: false,
      newDomain:null,
      isError: {
        url:''
      }
    }
    this.onClickAdditionalDomain = this.onClickAdditionalDomain.bind(this);
    this.handlechangePage = this.handlechangePage.bind(this);
    this.AddAdditionalDomain = this.AddAdditionalDomain.bind(this);
    this.onAddNewDomain = this.onAddNewDomain.bind(this);
  }

  async componentDidMount(){
    let params ={
      per_page:this.state.per_page
    }

    await this.props.dispatch(GetDomainList(params));
    // await this.props.dispatch(GetSubscribeInfo());
    // await this.props.dispatch(GetSubscribePriceOption());
    await this.props.dispatch(resetSuccess());
   
    var currencySymbol =  this.props.getSubscribePriceOption.currency.symbol
    var addtionalDomainDropDown = [];

    Object.keys(this.props.getSubscribePriceOption.domain).map((sub)=>{
      var subScriptinKey = this.props.getSubscribePriceOption.domain[sub];
      var price = subScriptinKey.price;
      var count = subScriptinKey.count;
      addtionalDomainDropDown.push({
          ...subScriptinKey,
          label: `${count} Domains |  ${currencySymbol}${price}`,
          value: count
      })
  });


  this.setState({totelPage: await this.props.getDomainList.last_page , addtionalDomainDropDown })

  }

  handlechangePage(e, index) {
    index = index + 1
   
    this.setState({currentPage: index},() => {
      let params = {
          page: this.state.currentPage,
          per_page:this.state.per_page
      }
      this.props.dispatch(GetDomainList(params))    
    });
  }

  onCancle(){
    this.setState({isAddNew:false})
  }

  onClickAdditionalDomain(){
    this.onChangeValue('isLoading' , false)
    this.props.dispatch(resetSuccess());
    this.setState({isAdditionalClick:!this.state.isAdditionalClick})
  }

  async AddAdditionalDomain(){
    await  this.onChangeValue('isLoading' , true)
    let params = {
      "additional_domains_count":this.state.selectedAdditionalDomain.count
    }
    
    await  this.props.dispatch(SubscribeAdditionalDomain(params))    
    await  setTimeout(() => { 
      this.props.dispatch(resetSuccess());
      this.setState({isAdditionalClick:!this.state.isAdditionalClick})
    }, 1000)
  }

  async onChangeValue(field , value){
    let subscribeAdditionalDomain = this.props.subscribeAdditionalDomain
    subscribeAdditionalDomain[field] = value
    await this.props.dispatch(SubscribeAdditionalDomainRequest(subscribeAdditionalDomain))
  }

  async onCreateNewDomain(field , value){
    let createDomaininfo = this.props.createDomaininfo
    createDomaininfo[field] = value
    await this.props.dispatch(CreateDomainRequest(createDomaininfo))
  }

  onEditDomain(index, field , value){
    let domain = this.props.getDomainList
    domain.data[index][field] = value
    this.props.dispatch(UpdateDomainRequest(domain))
  }

  update(index, list){
    this.onEditDomain(index ,'isLoader' , true)
    let params={
      "practice_id": this.props.practiceUserLoginInfo.practice_id,
      "domain": list.domain,
    }
    this.props.dispatch(UpdateDomain(params , list.id, index))
  }

  async removeDomain(index, list){
    await this.onEditDomain(index ,'isLoader' , true)
    await this.onEditDomain(index ,'isSuccessRemove' , false)
    await this.props.dispatch(RemoveDomain(list.id , index)) 
  }

  onUpdateCancle(index){
    this.onEditDomain(index ,'isEdit' , false)
    this.onEditDomain(index ,'isLoader' , false)
    this.onEditDomain(index ,'isRemove' , false)
    this.onCreateNewDomain('isLoader' , false)
  }
  
  handleChange(event) {
    let newDomain ;
    newDomain = event.target.value;
    this.setState({ newDomain },()=> { this.isValid() });
  }

 async addNewDomain(){
    await this.onCreateNewDomain('isLoader' , true)
    let params={
      "practice_id": this.props.practiceUserLoginInfo.practice_id,
      "domain": this.state.newDomain,
    }
    
    await this.props.dispatch(CreateDomain(params))

    
    await this.props.dispatch(resetSuccess());
    let data = {
      page: 1,
      per_page: 5,
    };
    await this.props.dispatch(GetDomainList(data));

    await  setTimeout(() => { 
      this.props.dispatch(resetSuccess());
      this.setState({
        isRemoveDomain:!this.state.isRemoveDomain,
        isAddNew:false
      })
    }, 2000)

  }

  onAddNewDomain(){
    this.setState({isAddNew:true });
     this.onCreateNewDomain('isLoader' , false)
  }

  onClickSubscribe(){
    history.push('/Subscribe');
  }

  isValid(){
    const { newDomain} = this.state;
    var valid = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})/.test(newDomain);
    var domainValidate = new RegExp(/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/)
    // if (domainValidate.test(newDomain))
   
    if(newDomain !== '' && domainValidate.test(newDomain)){
      this.setState({ isAdd: true });
    }else{
      this.setState({ isAdd: false });  
    }
  }
  
  render (){
  const { getSubscribeinfo , isSuccessAdditional , isSuccessAddedDomain , getDomainList , subscribeAdditionalDomain , isLoading} = this.props
  let data = getDomainList ? getDomainList.data : []
  return(
      <div className="domain__main__screen   d-flex flex-column h-100 align-items-center">
          <SubHeader className="mb-5 pb-5" title="Manage Domains"/>
          <div className="d-flex w-100 mb-auto pb-5">
            <div className="d-flex flex-column domain__inner mb-auto mx-auto ">
              <div className="domain__inner__header">
                <div className="row">
                  <div className="col-8">
                    <h4>Authorized domains</h4>
                    <p>Authorize more domains to gain access to our stock images, website videos, and patient education library on different websites.</p>
                  </div>
                  <div className="col-4 text-right">
                    <div className="link-icon">
                      <img src={LinkIcon} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              
              {isLoading ?
                <div className="domain__inner__body flex-grow-1 flex-column d-flex  align-items-center justify-content-center">
                  <Spinner color="primary" />
                </div>
              :
              <div className={"domain__inner__body pb-0 "}>
                <table className="table">
                {getDomainList.total && getDomainList.total !== 0 ?
                  <thead>
                    <tr>
                      <th>URL</th>
                      <th width="40%"></th>
                    </tr>
                  </thead>
                  : null }
                  <tbody>
                    {getDomainList.data && data.map((list, index) => {
                      return <tr key={index}>
                              <td>
                                  <>
                                    {list.isEdit ?
                                      <InputField type="text" name="updatedomain"  placeholder="Type your domain URL" value={list.domain}  onChange={(e)=>this.onEditDomain(index , 'domain' , e.target.value)} />
                                      :
                                    <> 
                                      {list.isRemove ?
                                        <p className="m-0">By removing this domain authorization, all Thalamus media in this URL will become unavailable.</p>
                                      :
                                      <>
                                        {list.status === 2 ?
                                          <p className="m-0"><span className="text--danger">{list.domain} was declined.</span>  Please click “Edit” to request authorization for a different URL.</p>
                                          :
                                        <>
                                          {list.domain}
                                        </>
                                        }
                                      </>
                                      }
                                    </>
                                    }
                                  </>
                              </td>
                              <td  className="button-td">
                                <div className="d-flex">
                                  {list.isRemove ?
                                  <>
                                   {list.isSuccessRemove ?
                                    <Button size="xl" color="red" onClick={this.onCancle.bind(this)}>Domain authorization removed</Button>
                                   :
                                    <LoadingButton size="md mr-4" loading={list.isLoader} disabled={list.isLoader} label={'Remove'} onClick={()=>{this.removeDomain(index , list)}}  color="primary" />
                                   }
                                  </>
                                  :
                                    <LoadingButton size="md mr-4" 
                                    loading={list.isLoader} label={list.isEdit ? 'save' : 'Edit' } onClick={()=>{list.isEdit ? this.update(index , list) : this.onEditDomain(index , 'isEdit' , true)}} outline color="default white"/>
                                  } 
                                  {!list.isSuccessRemove &&
                                    <Button size="md" onClick={()=>{list.isEdit || list.isRemove ? this.onUpdateCancle(index) : this.onEditDomain(index , 'isRemove' , true)}}  color="danger" >{list.isEdit || list.isRemove ? 'Cancel' : 'Remove' } </Button>
                                  }
                                  </div>
                                {console.log(list,'list')}
                              </td>
                            </tr>                          
                    })}
                    {this.state.isAddNew &&
                    <tr>
                      <td>
                        <InputField type="text" name="newdpmain"  placeholder="Type your domain URL"  onChange={this.handleChange.bind(this)} />
                      </td>
                      <td className="button-td">
                        <>
                          {isSuccessAddedDomain ?
                            <>
                              <Button size="xl" color="green" onClick={this.onCancle.bind(this)} className="domains-added">Domains added</Button>
                            </>
                            :
                            <>
                              <div className="d-flex">
                                <LoadingButton size="sm" className="mr-4" label="Add Domain"  loading={this.props.createDomaininfo.isLoader}  disabled={!this.state.isAdd || this.props.createDomaininfo.isLoader}  onClick={()=>this.addNewDomain()} color="primary" />
                                <Button size="md"  color="danger" onClick={this.onCancle.bind(this)} 
                                >Cancel</Button>
                              </div>
                            </>
                          }
                        </>
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
              }
              {!this.state.isAddNew && (data && data.length < getSubscribeinfo.available_domain) &&
                  <div className={"button-section mt-4 mb-5  add-doamin-btn d-flex align-items-center justify-content-center" }>
                    <Button onClick={()=>this.onAddNewDomain()} size="md" color="primary">Add domain</Button>
                  </div>
                }
              <div className="domain__inner__footer mt-auto">
                {this.state.isAdditionalClick &&
                  <div className="">
                    <Label className="choose-device">Choose how many additional domains to authorize</Label>
                    <div className="d-flex align-items-center justify-content-between">
                      <DropDown 
                          className='react-select-container mxh-200'
                          classNamePrefix="react-select"
                          options={this.state.addtionalDomainDropDown}
                          getOptionLabel={ x => `${x.label}`}
                          getOptionValue={ x => `${x.value} ${x.value}`}
                          isSearchable={false}
                          placeholder={''}
                          value={this.state.selectedAdditionalDomain}
                          onChange={(option)=>{ this.setState({ selectedAdditionalDomain: option })}}
                          // menuIsOpen={true}
                      />
                      <div className="button-section col-6 d-flex justify-content-end">
                        {isSuccessAdditional ?
                          <Button size="xl" color="green"  onClick={ this.onClickAdditionalDomain}>{this.state.selectedAdditionalDomain.count} Domains added</Button>
                        :
                        <>
                          <LoadingButton size="sm" className="mr-4" label="Add" loading={this.props.buttonLoading}  disabled={!this.state.selectedAdditionalDomain || this.props.buttonLoading}  onClick={ this.AddAdditionalDomain} color="primary" />
                          <Button size="sm" color="danger"  onClick={ this.onClickAdditionalDomain}>Cancel</Button>
                        </>
                        }
                      </div>
                    </div>
                </div>
                }

                {this.props.practiceInfo.is_paid_account === 1 ?
                <>
                  {!this.state.isAdditionalClick &&
                  <div className=" text-center">
                    <Button size="" className="col-6" onClick={ this.onClickAdditionalDomain} color="primary">Authorize additional domains</Button>
                  </div>
                  }
                </>
                :
                <div className="button-section text-center subscribe-btn">
                  <Button size="" className="col-6" onClick={ this.onClickSubscribe} color="primary">Subscribe to authorize more domains</Button>
                </div>
                }
              </div>
              {getDomainList && getDomainList.last_page > 1 &&
                <div className="domain__inner__footer mt-5 d-flex justify-content-center align-items-center">
                  <ul>
                    {[...Array(getDomainList && getDomainList.last_page)].map((page, i) =>
                      <li key={i}><Button type="button"  className={`px-3 py-0 ${i+1 === this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                    )}
                  </ul>
                </div>
              }
            </div>
            <div className="domain__suggestion d-flex flex-column align-items-flex-start justify-content-center">
              <h4><span>{getDomainList && getDomainList.total}</span> Authorized domains</h4>
              <h4><span>{getSubscribeinfo && getSubscribeinfo.available_domain}</span> Total domains</h4>
              <div className="divider"></div>
                <h4 className="total-price"><span>${getSubscribeinfo.additional_domains && getSubscribeinfo.additional_domains.base_price}</span> (Included)</h4>
            </div>
          </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  getDomainList:state.task.getDomainList,
  practiceInfo:state.task.practiceInfo,
  getSubscribeinfo:state.task.getSubscribeinfo,
  getSubscribePriceOption:state.task.getSubscribePriceOption,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  isSuccessAdditional:state.task.isSuccess,
  isSuccessAddedDomain:state.task.isSuccessAddedDomain,
  subscribeAdditionalDomain:state.task.subscribeAdditionalDomain,
  createDomaininfo:state.task.createDomaininfo,
  isEdit:state.task.isEdit,
  isLoading:state.task.isLoading,
  buttonLoading:state.task.buttonLoading
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);
