import React from 'react';

import './style.scss';
import ic_report_problem from '../../img/ic_report_problem.svg'
import { Button } from 'reactstrap';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { history } from '../index';

class AccountInactiveIndex extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        is_screll: false,
        DeviceCode:null,
        tosinfo:{}
    };
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(){
    history.push('/paymentInfo');
  } 

  async componentDidMount(){
   
  }

  render (){
    const {t} = this.props
    return(
      <div className="inactive__main__screen error__default__screen d-flex flex-column h-100 align-items-center justify-content-center">
         <SubHeader className="mb-auto" title="Account Cancelled"/>
        <div className="inactive__inner__screen mb-auto">
            <div className="d-flex flex-column text-center inactive__inner__text text-center">
                <img className="mx-auto mb-5" alt="" src={ic_report_problem}/>
                <h1>Account is cancelled</h1>
                <p>Kindly contact Thalamus Team to reactive your account.</p>
            </div>
        </div>
      </div>
    ) 
  }
}
export default withTranslation('common')(AccountInactiveIndex);
