import React, { Component } from 'react';
import { Label } from 'reactstrap';

const NoItemView = (props) =>
    <>
    {props.isShow &&
        <div className="pl-5 no-item-lable">
            <Label htmlFor="practicename" className="pr-lg-0 pl-5">{props.message}</Label>
        </div>
    }
    </>

export default NoItemView;