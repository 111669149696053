import React, { useEffect, useState } from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button, Input } from 'reactstrap';
import { formatSecond } from '../../data/comman';
import LoadingButton from '../../components/LoadingButton';
import './style.scss';
import { history } from '..';
import { updateWaitingRoomChannelName, updateOperatoryChannelName, myListWaitingOperatoryRoomChannel, waitingRoomDisplayOptions, updateChairsidePlaylist, getTimeline, getSingleChairsidePlaylist, getChairsidePlayVideo } from '../../store/actions/taskAction';
import IconPlas from '../../img/IconPlas.svg';
import editPen from '../../img/editPen.svg'
import Icon_favourites from '../../img/Icon_favourites.svg' 
import HttpUtility, { WAITINGROOM_CHANNEL_PREVIEW_URL, OPERATORY_CHANNEL_PREVIEW_URL, OPERATORY_CHANNEL,WAITINGROOM_CHANNEL, CHAIRSIDE_PLAYLIST_PLAY_URL } from '../../utilities/HttpUtility';
import PreviewModal from '../../components/PreviewModal';
import Player from '../chairsideEducation/EditCaptions/Player';
import PreviewPlayListModal from '../chairsideEducation/ChairsidePlaylist/PreviewPlayListModal'
import Loading from '../../components/Loading';
import { getToken } from '../../helper/LocalStorage';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
var timelineDetail=[]
var accessToken = ''

class HomeIndex extends React.Component {
  constructor(props) {
    super(props);
    var isOperatoryRoom = props.location.state.isOperatoryRoom || false;
    var isChairsidePlayList = props.location.state.isChairsidePlayList || false;
    var channel = props.location.state.channel;
    if(isOperatoryRoom)
      channel['my_list'] = channel.my_lists;
    this.state = {
        isSigninClick:true,
        isSignin:false,
        duration: 0,
        channel,
        isOperatoryRoom,
        isChairsidePlayList,
        showButton: false,
        modal: false,
        description: props.location.state.channel.description,
        displayButton: false,
        editButtonLoader: false,
    }
    this.onSigninClick = this.onSigninClick.bind(this);
  }
  


  onSigninClick(){
    this.setState({isSigninClick:false})
  }
  onSignin(){
    this.setState({isSignin:true})
  }

  async componentDidMount(){
     accessToken = getToken();

    var duration = 0;
    var caption_language;

    this.state.channel.timeline.map(x => {
      duration += x.media_data.duration;
    });
    this.setState({ duration: formatSecond(duration), description: this.state.channel&&this.state.channel.description });

    if(this.state.isOperatoryRoom){
      this.getChannelDetail();
    }
    if(this.state.isChairsidePlayList){
      await this.getvideoListData()
    }

    if(this.props.media_language){
      caption_language = this.props.media_language.find(x => x.id == this.props.practiceUserAccountInfo?.account_setting?.caption_language);
      this.setState({ caption_language: caption_language });
    }
  }

  async getvideoListData(){
    await this.props.dispatch(getChairsidePlayVideo(this.state.channel.id))
  }

  componentDidUpdate(){
    var selectedChannel;
    if(this.state.isOperatoryRoom){
      selectedChannel = this.props.operatoryMediaChannels.find(x => x.id == this.state.channel.id);
    }else if(this.state.isChairsidePlayList){
      selectedChannel = this.props.chairsidePlaylistsData.find(x => x.id == this.state.channel.id);
    }
    else{
      selectedChannel = this.props.waitingRoomMediaChannels.find(x => x.id == this.state.channel.id);
      console.log("🚀 ~ file: index.js ~ line 71 ~ HomeIndex ~ componentDidUpdate ~ selectedChannel", selectedChannel)
    }
    if(selectedChannel.cc_status != this.state.channel.cc_status || selectedChannel.my_list != this.state.channel.my_list){
      this.setState({ channel: selectedChannel });
    }
   

  }

  getChannelDetail(){
    this.props.dispatch(waitingRoomDisplayOptions(this.state.channel.id, this.state.isOperatoryRoom));
  }
  
  hoverHandler(type) {
		if (type === 'enter') {
			this.setState({ showButton: true });
		} else {
			this.setState({ showButton: false });
		}
	};

  handleCC(){
    let id = this.state.channel.id;
    let params={
			cc_status: this.state.channel.cc_status == 0 ? "1" : "0"
    }
    
    // if(this.props.practiceInfo.is_internal_user !== 1){
      if(this.state.isOperatoryRoom){
        this.props.dispatch(updateOperatoryChannelName(id, params) );
      }
      else if(this.state.isChairsidePlayList){
        this.props.dispatch(updateChairsidePlaylist(id, params) );
      }
      else{
        this.props.dispatch(updateWaitingRoomChannelName(id, params) );
      }
    // }
   
  }

  onMyList(isMyList){
    let params={
			my_list: isMyList
    }
    this.props.dispatch(myListWaitingOperatoryRoomChannel(this.state.channel.id, params, this.state.isOperatoryRoom) );
  }

  render (){
    const {t} = this.props;
    // const [nextEnable, setNextEnable] = useState('-disable');

    // const onSliderChange = (e) => {
    //   const activeNextSlide = e.activeIndex === 0 ? '-disable' : '-enable';
    //   setNextEnable(activeNextSlide);
    // };
    const changeDiscription = (value) => {
      this.setState({description: value})
  }
    
  const displayInputAndButton = (value) => {
      this.setState({displayButton: value})
  }
  
  const discriptionSave = (value) => {
    let params={
      description: this.state.description 
    }
    this.setState({editButtonLoader: true});
    
      try {
        HttpUtility.userPostRequest(`${this.state.isOperatoryRoom ? OPERATORY_CHANNEL:WAITINGROOM_CHANNEL}/${this.state.channel.id}`, params).then(
          (response) => {
            if (response.status === 200) {
              this.setState({displayButton: value})
              this.getChannelDetail();
              this.setState({editButtonLoader: false})
          }
          });
      } catch (error) {
        this.setState({displayButton: value})
        this.setState({editButtonLoader: false})
      }
  } 
  // console.log('video url --> ',this.props.location.state)


  var videourl=''
  //&& this.state.channel.created_type&&this.state.channel.created_type !== "thalamus"
  if(this.props.practiceInfo.is_internal_user !== 1 ){
    if(this.state.isChairsidePlayList){
      videourl=`${CHAIRSIDE_PLAYLIST_PLAY_URL}/${this.state.channel.id}/play`
    }else{
      videourl=`${this.state.isOperatoryRoom ? OPERATORY_CHANNEL_PREVIEW_URL:WAITINGROOM_CHANNEL_PREVIEW_URL}${this.state.channel.id}&cc_status=${this.state.channel.cc_status}&media_lang=${this.props.practiceUserAccountInfo.account_setting.media_language}&caption_language=${this.state.caption_language?.sort_name}&is_expired=${this.props.practiceInfo.is_paid_account == 0  && this.props.practiceInfo.days_left == 0 ? 1 : 0}&accessToken=${accessToken}`
    }
  }else{
      videourl=`${this.state.isOperatoryRoom ? OPERATORY_CHANNEL_PREVIEW_URL:WAITINGROOM_CHANNEL_PREVIEW_URL}${this.state.channel.id}&practice_name=${this.props.practiceProfileInfo?.name}&media_lang=${this.props.practiceUserAccountInfo.account_setting ? this.props.practiceUserAccountInfo.account_setting.media_language : 1}&cc_status=${this.state.channel.cc_status}&accessToken=${accessToken}`
  }

  if(this.state.isOperatoryRoom){
    timelineDetail = this.props.displayOptions?.timeline
  }else{
    timelineDetail = this.props.chairsidePlayVideo?.content
  }
  console.log('video url --> ',this.state.channel)
    return(
      <>
      {this.props.loading &&  <Loading />}
        <div className="channel_detail__page  d-flex flex-column h-100  main-bg" style={{backgroundImage:`url(${this.state.channel.feature_image || this.state.channel?.timeline[0]?.media_data?.feature_image}) `}}>
            <SubHeader className="mb-auto" title={this.state.isChairsidePlayList ? 'Playlist Details' : "Channel Details"}/>
            <div className="channel-detail mb-auto" >
                <div>
                    <h2>{this.state.channel.title}</h2>
                    <span className="duration">Duration: {this.state.duration}  |  {this.state.channel.timeline.length} Items</span>
                    {!this.state.displayButton && !this.state.isChairsidePlayList && <p className="detail">{this.state.description&&this.state.description !== '' ?this.state.description:'Click on Edit to Add description.'}</p>}
                    {this.state.displayButton && 
                      <Input type="textarea" class="editBoxCss" value={this.state.description} onChange={(e) => { changeDiscription(e.target.value); } }/> 
                    }
                    <div className="channel-detail-btn d-flex align-items-center addMarginTop">
                            <Button color="primary" size="md" onClick={()=> {this.setState({ modal: true })} }>Play {this.state.isChairsidePlayList? 'Playlist' : 'channel'}</Button>
                            {!this.state.isOperatoryRoom && 
                              <div className="text-center" style={{marginTop:'8px'}}>
                                <LoadingButton type="button" label="CC" loading={this.props.buttonLoading} disabled={this.props.buttonLoading} onClick={()=>{ this.handleCC() }} color="default white cc-btn" className={`${this.state.channel.cc_status == 0  ? '' : ' activeCC'} `} outline/>
                                <div className='cc_status_btn_text'>{this.state.channel.cc_status == 0 ? 'off' : 'on'}</div>
                              </div>
                            }
                            {this.props.practiceInfo.is_internal_user != 1 && !this.state.isChairsidePlayList ?
                              this.state.showButton === false ? (
                                <LoadingButton
                                  color='white'
                                  outline
                                  type="button"
                                  className="p-0 ml-4"
                                  loading={this.props.buttonLoading3}
                                  onMouseEnter={() => this.hoverHandler('enter')}
                                  onMouseLeave={() => this.hoverHandler('leave')}  style={{height:'30px' , width:'30px'}}>
                                  <img src={this.state.channel.my_list ? Icon_favourites : IconPlas } alt=""/>
                                </LoadingButton>
                              ) : 
                              this.state.channel.my_list ? (
                                <LoadingButton
                                  color='primary'
                                  type="button"
                                  size="md"
                                  className="ml-4"
                                  loading={this.props.buttonLoading3}
                                  onMouseLeave={() => this.hoverHandler('leave')}
                                  onClick={() => this.onMyList(0)}>
                                  REMOVE FROM MY LIST
                                </LoadingButton>
                              ) : (
                                  <LoadingButton
                                    color='white'
                                    type="button"
                                    size="md"
                                    className="ml-4"
                                    loading={this.props.buttonLoading3}
                                    onMouseLeave={() => this.hoverHandler('leave')}
                                    onClick={() => this.onMyList(1)}>
                                    ADD TO MY LIST
                                  </LoadingButton>
                              )
                            :
                          null}
                          {this.props.practiceInfo.is_internal_user === 0 && this.state.channel.created_type&&this.state.channel.created_type !== "thalamus" && !this.state.isChairsidePlayList &&
                          <>
                           {/* {!this.state.displayButton &&  */}
                                <LoadingButton type="button" loading={false}  onClick={()=>{ this.state.displayButton ? discriptionSave(false) : displayInputAndButton(true) }} color={`${this.state.displayButton ? 'primary edit-active' : 'white'} edit-btn`} className={` ml-4`} >
                                  Edit
                                </LoadingButton>
                            {/* } */}
                            {/* {this.state.displayButton && 
                              <LoadingButton type="button" loading={false}  onClick={()=>{ discriptionSave(false) }} color="white edit-btn" className={`ml-4 makeEditButtonActive`} >
                                <img src={editPen} alt="oops"/>
                              </LoadingButton>
                            } */}
                          </>
                          }

                          {this.props.practiceInfo.is_internal_user === 1 && this.state.channel.created_type&&this.state.channel.created_type === "thalamus" &&
                          <>
                            {/* {!this.state.displayButton &&  */}
                                <LoadingButton type="button" loading={false}  onClick={()=>{ this.state.displayButton ?  discriptionSave(false) : displayInputAndButton(true) }} color={`${this.state.displayButton ? 'primary edit-active' : 'white'} edit-btn`} className={` ml-4`} >
                                  Edit 
                                </LoadingButton>
                            {/* } */}
                            {/* {this.state.displayButton && 
                              <LoadingButton type="button" loading={false}  onClick={()=>{ discriptionSave(false) }} color="white edit-btn" className={`ml-4 makeEditButtonActive`} >
                                <img src={editPen} alt="oops"/>
                              </LoadingButton>
                            } */}
                          </>
                          }
                    </div> 
                </div>
            </div>
            {console.log(this.state.isChairsidePlayList,'this.state.isChairsidePlayList')}
            {(this.state.isChairsidePlayList || this.state.isOperatoryRoom) ?
            <Swiper
            spaceBetween={0}
            slidesPerView={'auto'}
            navigation
            className="channel_detail_swiper"                   
            //onSwiper={(swiper) => console.log(swiper)}
             >
              {timelineDetail?.length > 0 && timelineDetail.map((timeLineVideo) =>
                <SwiperSlide>
                  <div className="channel_detail_item">
                      <img src={this.state.isChairsidePlayList? timeLineVideo.media_data?.chairside_media?.thumb : timeLineVideo.media_data.thumb} alt={this.state.isChairsidePlayList? timeLineVideo?.media_data?.title : timeLineVideo.media_data.name} />
                      <label>{this.state.isChairsidePlayList? timeLineVideo?.media_data?.title : timeLineVideo.media_data.name}</label>
                    </div>
                  </SwiperSlide>
                )}

          </Swiper>
          :
          ''
          }
            
        </div>
        {console.log(this.state.modal,'toggle')}
        {this.state.modal &&
          <>
          { this.state.isChairsidePlayList ?
          <PreviewPlayListModal timelineDetail={timelineDetail} isOpen={this.state.modal} handleCC={this.handleCC} toggle={()=> this.setState({modal: !this.state.modal}) } />
          :
          <PreviewModal url={videourl} isOpen={this.state.modal} isChairsidePlayList={this.state.isChairsidePlayList} toggle={()=> this.setState({modal: !this.state.modal}) }/>
          }
          </>
        }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  buttonLoading: state.task.buttonLoading,
  buttonLoading3: state.task.buttonLoading3,
  buttonLoading1:state.task.buttonLoading1,
  loading:state.task.loading,
  waitingRoomMediaChannels: state.task.waitingRoomMediaChannels,
  chairsidePlaylist: state.task.chairsidePlaylist,
  chairsidePlaylistsData: state.task.chairsidePlaylistsData,
  chairsidePlayVideo: state.task.chairsidePlayVideo,
  operatoryMediaChannels: state.task.operatoryMediaChannels,
  displayOptions: state.task.displayOptions,
  practiceInfo: state.task.practiceInfo,
  practiceProfileInfo: state.task.practiceProfileInfo,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo,
  media_language:state.task.media_language
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
