import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import ReactCodeInput from "react-code-input";
import { Button, Label, FormGroup, Form, Col, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import { InputField } from "../../components/InputField";
import SubHeader from "../../components/Header/subHeader";
import DropDown from "../../components/DropDown";
import LoadingButton from "../../components/LoadingButton";
import _ from "lodash";
import { DateFormat, isRequired, isValidEmail } from "../../data/comman";
import { history } from "../index";
import PhoneVerify from "../../components/phoneVerify";
import {
  DeleteAuthorizedUser,
  GetpracticeSecurityQuestion,
  Getgender,
  GetAuthorizedUser,
  UpdateAuthorizedUser,
  UpdateAuthorizedUserRequest,
  GetPhoneVerification,
  VerifyOtp,
  UpdateUserProfileAboutInfo,
  UpdateUserProfile,
  DeleteUserProfile,
  cleardata,
  getPracticeSecuritySuccess,
  DeleteUserProfileRequest,
  resetSuccess,
  VerifyOtpRequest,
} from "../../store/actions/taskAction";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
var formIsValid = true;
var isEditUser;
class OwnersProfileSetupIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: null,
      selectedMonth: null,
      selectedDay: null,
      step: 0,
      isVerify: false,
      isGetCode: false,
      hidden: true,
      formValid: false,
      emailValid: false,
      isProfileCreated: false,
      isEnableVerify: false,
      getownersId: props.getownersId,
      istoggleDelete: false,
      i_agree: false,
      isDeleteProfile: false,
      userPasscode: "",
      practiceUsersList: this.props.practiceUsersList,
      isError: {
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        selectedGender: null,
        email: "",
        email_alt: "",
        phone: "",
        passcode: "",
        isPhoneverify: "",
        security_question: {
          question_1: "",
          question_2: "",
          question_3: "",
          answer_1: "",
          answer_2: "",
          answer_3: "",
        },
      },
    };
    if (props && props.location && props.location.isEditUser) {
      isEditUser = props.location.isEditUser;
    }
    this.handleClick = this.handleClick.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.onclickDelete = this.onclickDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getUserType(id) {
    switch (id) {
      case 0:
        return ["guest", "Guest"];
      case 1:
        return ["dentist", "Normal User"];
      case 2:
        return ["authorized_user", "Authorized User"];
      case 3:
        return ["manager", "Manager"];
      case 4:
        return ["owner", "Owner"];
      default:
        return [" ", " "];
    }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleClick() {
    this.setState({ isVerify: true });
  }

  onChangeValue(field, value) {
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo;
    practiceUserAccountInfo[field] = value;
    this.props.dispatch(UpdateAuthorizedUserRequest(practiceUserAccountInfo));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.practiceUsersList !== this.props.practiceUsersList &&
      this.props.practiceUsersList.length > 0
    ) {
      // var owner = this.props.practiceUsersList ? this.props.practiceUsersList.find(x=>x.type === 4) : null
      // if(owner){
      //   this.props.dispatch(GetAuthorizedUser(owner.id));
      // }
      this.loadOwnerProfile();
    }

    if (
      prevProps.practiceUserAccountInfo !==
        this.props.practiceUserAccountInfo &&
      this.props.practiceUserAccountInfo.is_verified_mobile == 1
    ) {
      this.validateForm();
    }
  }

  async componentDidMount() {
    this.loadOwnerProfile();
  }

  async loadOwnerProfile() {
    if (this.props.practiceInfo.need_owner_setup === 1) {
      var owner = this.props.practiceUsersList
        ? this.props.practiceUsersList.find((x) => x.type === 4)
        : null;
      if (owner) {
        await this.props.dispatch(GetAuthorizedUser(owner.id));
      }
    }

    await this.onChangeValue("isLoading", false);
    await this.onChangeValue("isUpdateSuccess", false);

    var practiceUserAccountInfo = this.props.practiceUserAccountInfo;

    const initialDOB = practiceUserAccountInfo.dob;
    if (initialDOB) {

      const [year, month, day] = initialDOB.split('-');

      this.setState({
        selectedYear: year,
        selectedMonth: month,
        selectedDay: day,
      });
    }

    let phoneVerificationInfo = {};

    phoneVerificationInfo["isVerify"] = false;
    phoneVerificationInfo["isGetVerifyCode"] = true;
    phoneVerificationInfo["phoneVerifyed"] = false;
    this.props.dispatch(VerifyOtpRequest(phoneVerificationInfo));
    if(practiceUserAccountInfo.is_verified_mobile == 0){
      practiceUserAccountInfo["is_verified_mobile"] = 0;
    }
  }

  async onEditAuthorizedUserInfo(field, value) {
    this.onChangeValue("isUpdateSuccess", false);
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo;
    const { selectedDay, selectedMonth, selectedYear } = this.state;

    if (field == "full_name") {
      practiceUserAccountInfo[field] = value;
    } else if (field === "dob") {
      
      if (selectedDay && selectedMonth && selectedYear) {
        const dobValue = moment(`${selectedYear}-${selectedMonth}-${selectedDay}`, 'YYYY-MM-DD').format(DateFormat);
        practiceUserAccountInfo[field] = dobValue;
      } else {
        practiceUserAccountInfo[field] = null; // Mark dob as incomplete
      }

      // value = moment(value).format(DateFormat);
      // practiceUserAccountInfo[field] = value;
    } else if (field === "phone") {
      this.setState({ isGetCode: true });
      practiceUserAccountInfo[field] = value.replace(/\s+/g, "");
    } else if (field === "email") {
      var phoneVerificationInfo = this.props.phoneVerificationInfo;
      phoneVerificationInfo["isVerify"] = false;
      practiceUserAccountInfo["is_verified_email"] = 0;
      this.props.dispatch(VerifyOtpRequest(phoneVerificationInfo));
      practiceUserAccountInfo[field] = value;
    } else if (
      field === "question_1" ||
      field === "question_2" ||
      field === "question_3"
    ) {
      practiceUserAccountInfo[field] = value;
      var selectedIndex = this.props.practiceSecurityQuestion.findIndex(
        (x) => x.id == value.id
      );
      if (selectedIndex > -1) {
        var practiceSecurityQuestion = this.props.practiceSecurityQuestion;
        practiceSecurityQuestion.splice(selectedIndex, 1);
        this.props.dispatch(
          getPracticeSecuritySuccess(practiceSecurityQuestion)
        );
      }
    } else {
      practiceUserAccountInfo[field] = value;
      // console.log('check the practiceUserAccountInfo-- >', practiceUserAccountInfo)
    }
    await this.props.dispatch(
      UpdateAuthorizedUserRequest(practiceUserAccountInfo)
    );
    await this.isValidField(field, value);
  }

  isValidField(name, value) {
    let isError = this.state.isError;
    let emailValid = this.state.emailValid;

    switch (name) {
      case "full_name":
        isError.first_name = value
          ? value.length < 4
            ? "Atleast 4 characaters required"
            : ""
          : "Please enter nane";
        break;
      case "dob":
        isError.dob = value ? "" : "Please select Date of Birth";
        break
      case "email":
        if (isRequired(value)) {
          isError.email = "Please enter email address";
        } else if (isValidEmail(value)) {
          isError.email = "Invaild email address";
        } else {
          isError.email = "";
        }
        break;
      case "email_alt":
        if (value && value.trim() != "") {
          if (isValidEmail(value)) {
            isError.email_alt = "Please enter valid email";
          } else {
            isError.email_alt = "";
          }
        } else {
          isError.email_alt = "";
        }
        break;
      default:
        break;
    }
    this.setState({ isError }, this.validateForm);
  }

  validateForm() {
    let isError = this.state.isError;

    const { passcode } = this.props.practiceUserAccountInfo;

    if (
      this.props.practiceUserAccountInfo.verificationCode &&
      this.props.practiceUserAccountInfo.verificationCode.length == 4
    ) {
      this.setState({ isEnableVerify: true });
    } else {
      this.setState({ isEnableVerify: false });
    }

    if (this.props.practiceUserAccountInfo.is_secure === 1) {
      if (passcode && passcode.length == 4 && this.state.i_agree) {
        this.setState({ isDeleteProfile: true });
      } else {
        this.setState({ isDeleteProfile: false });
      }
    } else {
      if (this.state.i_agree) {
        this.setState({ isDeleteProfile: true });
      }
    }
    
    this.setState({ formValid: formIsValid });
    // console.log(formIsValid,'formIsValid','>> ',this.state.isError)
  }

  async UpdateUserProfile(isProfileCreated) {
    this.onChangeValue("isLoading", true);
    this.onChangeValue("isUpdateSuccess", false);
    // console.log('check the UpdateUserProfile', this.props.practiceUserAccountInfo)
    if (
      _.isUndefined(this.props.practiceUserAccountInfo.email) &&
      this.props.practiceUserAccountInfo.email === ""
    ) {
      return;
    }

    var nameArray = this.props.practiceUserAccountInfo["full_name"]
      .trim()
      .split(" ");
    var first_name = "";
    var last_name = "";
    if (nameArray.length > 0) {
      first_name = nameArray[0];
      if (nameArray.length > 1) {
        last_name = nameArray.slice(1, nameArray.length).join(" ");
      }
    }

    let params = {
      id: this.props.practiceUserAccountInfo.id,
      first_name,
      last_name,
      dob: this.getFormattedDate(),
      gender: this.props.practiceUserAccountInfo.gender
        ? this.props.practiceUserAccountInfo.gender.id
        : 0,
      email: this.props.practiceUserAccountInfo.email,
      email_alt: this.props.practiceUserAccountInfo.email_alt,
      // phone: this.props.practiceUserAccountInfo.phone.length > 11 ? this.props.practiceUserAccountInfo.phone : null,
      isProfileCreated,
      request_secrete: this.props.phoneVerificationInfo.request_secrete,
      otp: this.props.phoneVerificationInfo.verificationCode,
      otp_media: this.props.phoneVerificationInfo.email ? "email" : "sms",
    };

    if (this.props.practiceUserAccountInfo?.phone?.length > 11) {
      params["phone"] = this.props.practiceUserAccountInfo.phone;
    }

    if (this.props.practiceInfo.need_owner_setup === 1) {
      params["passcode"] = this.props.practiceUserAccountInfo.passcode;
    }

    Object.keys(params).length !== 0 &&
      Object.keys(params).map((key, index) => {
        this.isValidField(key, params[key]);
      });
    if (
      this.state.formValid && !this.onSubmitValidateForm() &&
      this.state.isError.email === "" &&
      this.state.isError.first_name === "" &&
      this.state.isError.phone === ""
    ) {
      await this.props.dispatch(UpdateAuthorizedUser(params));
    } else {
      this.onChangeValue("isLoading", false);
    }
  }

  onclickDelete() {
    this.props.dispatch(resetSuccess());
    this.setState({ istoggleDelete: !this.state.istoggleDelete });
  }

  handleDelete(event) {
    this.setState({ i_agree: !this.state.i_agree });
    this.isValidField();
  }

  deleteUserProfile(params) {
    this.props.dispatch(DeleteAuthorizedUser(params));
  }

  async onBackToprofiles() {
    await history.push("/authorized-user");
  }

  onContinue() {
    this.props.history.push({
      pathname: "/profileselection",
    });
  }

  onSubmitValidateForm() {
    let { practiceUserAccountInfo } = this.props;
    let { isError } = this.state;
    const { selectedYear, selectedMonth, selectedDay } = this.state;

    var error = false;

    if (isRequired(practiceUserAccountInfo.full_name)) {
      isError.first_name = "Please enter name";
      error = true;
    } else if (practiceUserAccountInfo.full_name.length < 4) {
      isError.first_name = "At least 4 characaters required";
      error = true;
    } else {
      isError.first_name = "";
    }

    // if (selectedDay && selectedMonth && selectedYear) {
    //   const dobValue = moment(`${selectedYear}-${selectedMonth}-${selectedDay}`, 'YYYY-MM-DD').format(DateFormat);
    //   practiceUserAccountInfo[field] = dobValue;
    // } else {
    //   practiceUserAccountInfo[field] = null; // Mark dob as incomplete
    // }

    if (isRequired(selectedDay) || isRequired(selectedMonth) || isRequired(selectedYear)) {
      isError.dob = "Please select Date of Birth";
      error = true;
    }
    var validate = this.getFormattedDate();
    console.log(validate,'validate')
    if(validate === 'Invalid date'){
      isError.dob = "Please enter the valid Date of birth.";
      error = true;
    }

    // if (isRequired(practiceUserAccountInfo?.gender?.name)) {
    //   isError.gender = "Please select geneder";
    //   error = true;
    // } else {
    //   isError.gender = "";
    // }

    if (isRequired(practiceUserAccountInfo.email.trim())) {
      isError.email = "Please enter email address";
      error = true;
    } else if (isValidEmail(practiceUserAccountInfo.email.trim())) {
      isError.email = "Invaild email address";
      error = true;
    } else {
      isError.email = "";
    }

    if (
      practiceUserAccountInfo.email_alt &&
      practiceUserAccountInfo.email_alt.trim() != ""
    ) {
      if (isValidEmail(practiceUserAccountInfo.email_alt)) {
        isError.email_alt = "Please enter valid email";
        error = true;
      } else {
        isError.email_alt = "";
      }
    } else {
      isError.email_alt = "";
    }


    if (
      practiceUserAccountInfo.is_verified_mobile == 0 &&
      practiceUserAccountInfo.is_verified_email == 0
    ) {
      isError.isPhoneverify = "Please Verify";
      error = true;
    } else {
      isError.isPhoneverify = "";
    }

    this.setState({ isError });

    return error;
  }

  nextPreStep(isPref) {
    var { step } = this.state;
    var { practiceUserAccountInfo } = this.props;
    if (isPref) {
      this.setState((prevState) => ({ step: prevState.step - 1 }));
    } else if (step == 0 && !this.onSubmitValidateForm()) {
      //
      // if(this.state.formIsValid){
      this.setState((prevState) => ({ step: prevState.step + 1 }));
      // }
    } else if (step == 1) {
      let { isError } = this.state;
      var formIsValid = false;
      if (this.state.step == 1) {
        if (
          this.props.practiceUserAccountInfo.is_verified_mobile === 1 ||
          this.props.practiceUserAccountInfo.is_verified_email === 1
        ) {
          isError.isPhoneverify = "";
        }
       
        if (
          practiceUserAccountInfo.passcode &&
          practiceUserAccountInfo.passcode.length == 4 &&
          !formIsValid
        ) {
          isError.isPhoneverify = "";
          if (!/[0-9]/.test(practiceUserAccountInfo.passcode)) {
            isError.passcode = "Please Enter Digits Only.";
          } else {
            this.UpdateUserProfile(true);
            isError.passcode = "";
          }

        } else if (
          practiceUserAccountInfo.passcode == undefined ||
          practiceUserAccountInfo.passcode.length < 4
        ) {
          isError.passcode = "Please enter passcode";
        }
      }
      this.setState({ isError });
    }
  }

  renderStep() {
    var { step } = this.state;
    switch (step) {
      case 0:
        return this.basicInfo();
      case 1:
        return this.passcode();
    }
  }

  getYearsArray = () => {
    const currentYear = moment().year();
    const currentDate = moment();
    const eighteenYearsAgo = currentDate.subtract(18, 'years').year();
    const isAfterBirthday = currentDate.isAfter(moment(eighteenYearsAgo, 'YYYY').add(1, 'day'));

    const years = [];
    let startYear = eighteenYearsAgo;

    if (!isAfterBirthday) {
      // If the birthday hasn't occurred yet this year, adjust the start year.
      startYear -= 1;
    }

    for (let year = startYear; year >= eighteenYearsAgo - 100; year--) {
      years.push({ value: year, label: year.toString() });
    }

    return years;
  };

  getMonthsArray = () => {
    const months = moment.months().map((month, index) => ({
      value: (index + 1).toString(),
      label: month,
    }));
    return months;
  };

  getDaysArray = () => {
    const days = [];
    for (let day = 1; day <= 31; day++) {
      days.push({ value: day.toString(), label: day.toString() });
    }
    return days;
  };

  handleYearChange = (selectedOption) => {
    this.setState({ selectedYear: selectedOption.value });
    // this.onEditAuthorizedUserInfo("dob", null, this.state.selectedDay, this.state.selectedMonth, selectedOption.value);

  };

  handleMonthChange = (selectedOption) => {
    this.setState({ selectedMonth: selectedOption.value, selectedDay: null });
    // this.onEditAuthorizedUserInfo("dob", null, this.state.selectedDay, selectedOption.value, this.state.selectedYear);

  };

  handleDayChange = (selectedOption) => {
    this.setState({ selectedDay: selectedOption.value });
    // this.onEditAuthorizedUserInfo("dob", null, selectedOption.value, this.state.selectedMonth, this.state.selectedYear);

  };

  getFormattedDate = () => {
    const { selectedYear, selectedMonth, selectedDay } = this.state;
    if (!selectedYear || !selectedMonth || !selectedDay) return '';

    const date = moment(`${selectedYear}-${selectedMonth}-${selectedDay}`, 'YYYY-MM-DD');
    return date.format('YYYY-MM-DD');
  };


  basicInfo() {
    const { t, getGender, practiceUserAccountInfo } = this.props;
    const { isError } = this.state;
    const { selectedYear, selectedMonth, selectedDay } = this.state;

    return (
      <>
        <div className="transparent__form--white">
          <Row className="justify-content-center d-flex">
            <Col md={6} className="px-5">
              <FormGroup className="mb-4 pb-1 mx-auto">
                <h4 className="m-0">Profile Information</h4>
              </FormGroup>
            </Col>
            <Col md={6} className="px-5"></Col>
          </Row>
          <Row className="justify-content-center d-flex">
            <Col md={6} className="px-5">
              <div className="mx-auto h-100">
                <FormGroup>
                  <InputField
                    type="text"
                    label="Name"
                    name="first_name"
                    placeholder="First name"
                    isError={isError.first_name}
                    value={`${practiceUserAccountInfo.full_name || ""}`}
                    onChange={(e) =>
                      this.onEditAuthorizedUserInfo("full_name", e.target.value)
                    }
                    className="mb-3"
                  />
                  {/* <InputField type="text" name="last_name" placeholder="Last name" isError={isError.last_name} value={practiceUserAccountInfo.last_name || ''} onChange={(e) => this.onEditAuthorizedUserInfo('last_name', e.target.value)} /> */}
                </FormGroup>
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <Label for="Ownership">Date of birth</Label>
                    {isError.dob && (
                      <span className="text--danger">{isError.dob}</span>
                    )}
                  </div>
                  <div className={`w-auto practice__hours_box`}>
                      {/* { this.state.error && this.state.error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{this.state.error[index]}</div>} */}
                      <div className="d-flex w-100 gap-10">
                          <FormGroup className={`align-items-center m-0 w-100`}>
                              <DropDown 
                                  className='react-select-container select-style-3 w-101 p-0'
                                  classNamePrefix="react-select"
                                  options={this.getMonthsArray()}
                                  isSearchable={false}
                                  placeholder={'MM'}
                                  value={selectedMonth ? { value: selectedMonth, label: moment.months(Number(selectedMonth) - 1) } : null}
                                  onChange={this.handleMonthChange}
                              />
                              {console.log(this.state.selectedMonth,'selectedMonth')}
                          </FormGroup>
                          <FormGroup style={{maxWidth:'100px'}} className={`align-items-center m-0  w-100`}>
                              <DropDown 
                                  className='react-select-container w-101 p-0'
                                  classNamePrefix="react-select"
                                  options={this.getDaysArray()}
                                  isSearchable={false}
                                  placeholder={'DD'}
                                  onChange={this.handleDayChange}
                                  value={selectedDay ? { value: selectedDay, label: selectedDay } : null}
                                  />
                          </FormGroup>
                          <FormGroup className={`align-items-center m-0  w-100`}>
                            <DropDown
                                className='react-select-container w-101  p-0'
                                classNamePrefix="react-select"
                                options={this.getYearsArray()}
                                isSearchable={false}
                                placeholder={'YYYY'}
                                value={selectedYear ? { value: selectedYear, label: selectedYear } : null}
                                onChange={this.handleYearChange}
                            />
                          </FormGroup>
                      </div>
                  </div>
                  {/* <DatePicker
                    className="form-control custom-datepicker form-control mb-2"
                    selected={
                      practiceUserAccountInfo.dob
                        ? moment(practiceUserAccountInfo.dob).toDate()
                        : null
                    }
                    onChange={(date) =>
                      date ? this.onEditAuthorizedUserInfo("dob", date) : null
                    }
                    dateFormat="MMMM d, yyyy"
                    maxDate={moment().subtract(18, "years")._d}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={500}
                    scrollableYearDropdown
                    placeholderText="Click the icon to select a date"
                  /> */}
                </FormGroup>
                <FormGroup>
                  <InputField
                    type="text"
                    label={t("form.email")}
                    name="email"
                    isError={isError.email}
                    onChange={(e) =>
                      this.onEditAuthorizedUserInfo("email", e.target.value)
                    }
                    value={practiceUserAccountInfo.email || ""}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <InputField
                    type="text"
                    label="Secondary email (Optional)"
                    isError={isError.email_alt}
                    name="email_alt"
                    value={practiceUserAccountInfo.email_alt || ""}
                    onChange={(e) =>
                      this.onEditAuthorizedUserInfo("email_alt", e.target.value)
                    }
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md={6} className="px-5">
              <PhoneVerify
                isError={isError.isPhoneverify}
                onSubmitValidateForm={() => this.onSubmitValidateForm()}
                validateForm={() => this.validateForm()}
                values={practiceUserAccountInfo}
                buttonLabel="send a code"
                isClear={true}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }

  passcode() {
    const { isError } = this.state;
    return (
      <>
        <Col
          md={12}
          className="transparent__form--white"
          style={{
            padding: "3rem 8rem",
            width: "100%",
            maxWidth: "529px",
            margin: "0 auto",
          }}
        >
          {this.props.practiceInfo.need_owner_setup === 1 && (
            <>
              <FormGroup className="mt-5">
                <h4 className="m-0 mb-4">Set your Passcode</h4>
                <p className="m-0">
                  To help us protect your privacy, please create a <br />
                  4-digit passcode for your profile.
                </p>
              </FormGroup>
              <FormGroup
                className={`mt-5 mb-5 ${isError.passcode ? "notverifyed" : ""}`}
              >
                <div className="d-flex justify-content-between">
                  <label className="mb-3">Set passcode</label>
                  {isError.passcode && (
                    <span className="text--danger">{isError.passcode}</span>
                  )}
                  <Button
                    onClick={this.toggleShow}
                    color="link primary"
                    className="p-0 text-uppercase"
                  >
                    {this.state.hidden ? "SHOW" : "Hide"}
                  </Button>
                </div>
                <ReactCodeInput
                  type={this.state.hidden ? "password" : "number"}
                  onChange={(options) =>
                    this.onEditAuthorizedUserInfo("passcode", options)
                  }
                  className={`mb-2`}
                  fields={4}
                />
              </FormGroup>
            </>
          )}
        </Col>
      </>
    );
  }

  render() {
    const {
      t,
      loadingDeleteUserProfile,
      practiceUserAccountInfo,
      practiceInfo,
    } = this.props;
    const { isError } = this.state;

    var isDeleteActive = this.state.isDeleteProfile;
    var userType = this.getUserType(practiceUserAccountInfo.type);
    return (
      <div className="owners_profile_creation__main__screen  d-flex flex-column h-100 align-items-center">
        {practiceUserAccountInfo && (
          <>
            {practiceInfo && practiceInfo.need_owner_setup === 0 && (
              <SubHeader className="" />
            )}
            {!this.state.istoggleDelete && (
              <>
                <div className="d-flex flex-column text-center initial_setup__inner__text text-center mt-5">
                  <h1 className="mt-0">{`${isEditUser ? "Update" : "Create"} ${
                    userType[1]
                  }'s Profile`}</h1>
                  <p className="mb-0">
                    {this.state.step == 0
                      ? "Fill in your profile information."
                      : "Set up security passcode."}
                  </p>
                </div>
                {!isEditUser && (
                  <div className="custom__multi__step__form mt-3">
                    <ul className="custom__multi__step__form--progressbar">
                      <li
                        className={`custom__multi__step__form--step ${
                          this.state.step == 0 ? "active" : ""
                        }`}
                      >
                        <span></span>
                      </li>
                      <li
                        className={`custom__multi__step__form--step ${
                          this.state.step == 1 ? "active" : ""
                        }`}
                      >
                        <span></span>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            )}
            {!this.state.istoggleDelete ? (
              <div
                className={`owners_profile_creation__inner__screen ${
                  isEditUser && "mt-5"
                }`}
              >
                <div className="mw-820 d-flex flex-column align-items-stretch">
                  <Form className="mt-2 h-100 d-flex flex-column">
                    {this.renderStep()}
                    {/* </Row> */}
                  </Form>
                </div>
              </div>
            ) : (
              <div className="bg_red about__screen transparent__form--white  md d-flex flex-column align-items-stretch">
                <Form className="h-100 d-flex flex-column">
                  <div className="d-flex flex-column text-center h-100">
                    {!this.props.practiceUserdeleteInfo ? (
                      <>
                        <div className="d-flex flex-column text-center my-auto">
                          <FormGroup className="mb-5 pb-5 border--bottom heading">
                            <h4 className="m-0 mt-1">Attention!</h4>
                          </FormGroup>
                          <FormGroup className="mb-20 removal-section">
                            <h4 className="mb-20 mt-1">
                              Authorized User’s profile removal
                            </h4>
                            <p>
                              You are about to delete this admin profile. Once
                              done, the regular profile associated with this one
                              will no longer be able to access restricted areas.
                            </p>
                          </FormGroup>
                          <FormGroup className="mb-5 confirm-delete">
                            <div className="custom-control white custom-redio  ml-2">
                              <input
                                type="checkbox"
                                onChange={this.handleDelete}
                                defaultChecked={this.state.i_agree}
                                className="custom-control-input"
                                id="customCheck1"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck1"
                              >
                                Yes, I want to delete this admin profile.
                              </label>
                            </div>
                          </FormGroup>
                          {this.props.practiceUserAccountInfo.is_secure ===
                          1 ? (
                            <FormGroup
                              className={`my-auto ${
                                this.props.error ? "notverifyed" : ""
                              } enter-passcode`}
                            >
                              <div className="d-flex justify-content-between">
                                <label>Enter your passcode</label>
                                <Button
                                  onClick={this.toggleShow}
                                  color="link white"
                                  className="p-0"
                                >
                                  SHOW
                                </Button>
                              </div>
                              <ReactCodeInput
                                type={this.state.hidden ? "password" : "number"}
                                onChange={(options) =>
                                  this.onEditAuthorizedUserInfo(
                                    "passcode",
                                    options
                                  )
                                }
                                fields={4}
                                className={`bg_white mb-2 mw-100`}
                              />
                            </FormGroup>
                          ) : null}
                        </div>
                        <div className="mt-auto d-flex justify-content-between">
                          <Button
                            type="button"
                            onClick={() => this.onclickDelete()}
                            className="col"
                            outline
                            color="default white mr-3"
                          >
                            Cancel
                          </Button>
                          {/* {this.props.practiceUserAccountInfo.is_secure === 1 ? */}
                          <LoadingButton
                            type="button"
                            label={"Delete profile"}
                            loading={loadingDeleteUserProfile}
                            disabled={
                              !isDeleteActive || loadingDeleteUserProfile
                            }
                            onClick={() =>
                              this.deleteUserProfile(
                                this.props.practiceUserAccountInfo
                              )
                            }
                            className={`${
                              this.props.SuccessProfileRemove === true
                                ? "verifyed"
                                : ""
                            } col ml-3`}
                            color="default"
                          />
                          {/* : */}
                          {/* <LoadingButton type="button" size="sm" label={'Delete profile'} loading={loadingDeleteUserProfile} disabled={!isDeleteActive || loadingDeleteUserProfile} onClick={() => this.deleteUserProfile(this.props.practiceUserAccountInfo)} className={this.props.SuccessProfileRemove === true ? 'verifyed' : ''} color="default" /> */}
                          {/* } */}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex flex-column text-center  h-100">
                          <FormGroup className="my-auto">
                            <h4 className="mb-5 mt-1">
                              Authorized User’s Profile deleted
                            </h4>
                            <p style={{ padding: "0 17px" }}>
                              The admin profile “
                              {`${
                                this.props.practiceUserAccountInfo.first_name ||
                                ""
                              } ${
                                this.props.practiceUserAccountInfo.last_name ||
                                ""
                              }`}
                              ” has been deleted, and the regular profile once
                              associated with it is now unable to change
                              sensitive information for this practice.{" "}
                            </p>
                          </FormGroup>
                        </div>
                        <div className="mt-auto">
                          <Button
                            type="button"
                            onClick={() => this.onBackToprofiles()}
                            className="col w-100"
                            outline
                            color="default white"
                          >
                            Back to authorized users
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            )}
            {!this.state.istoggleDelete && (
              <div className="mt-5 owners_profile_creation__inner__screen__footer__button d-flex justify-content-center w-100">
                {this.state.step != 0 && (
                  <Button
                    type="button"
                    size="md"
                    onClick={() => this.nextPreStep(true)}
                    className=" mx-3 "
                    outline
                    color="default white"
                  >
                    {t("btn.back")}
                  </Button>
                )}{" "}
                {this.props.practiceInfo.need_owner_setup === 1 ? (
                  <LoadingButton
                    type="button"
                    size="md"
                    disabled={practiceUserAccountInfo.isLoading}
                    loading={practiceUserAccountInfo.isLoading}
                    label={this.state.step != 1 ? "next" : "Create profile"}
                    onClick={() => {
                      this.nextPreStep();
                    }}
                    className={`px-1 mx-3`}
                    color="primary"
                  />
                ) : (
                  <LoadingButton
                    type="button"
                    size="md"
                    disabled={practiceUserAccountInfo.isLoading}
                    loading={practiceUserAccountInfo.isLoading}
                    label={
                      practiceUserAccountInfo.isUpdateSuccess
                        ? "Changes saved"
                        : "save changes"
                    }
                    onClick={() => this.UpdateUserProfile()}
                    className={`${
                      practiceUserAccountInfo.isUpdateSuccess ? "btn-green" : ""
                    } mx-3`}
                    color="primary"
                  />
                )}
                
                {practiceUserAccountInfo.type !== 4 &&
                  this.props.practiceInfo.need_owner_setup === 0 &&
                  practiceUserAccountInfo.is_secure === 1 &&
                  this.props.practiceUserLoginInfo.id !==
                    practiceUserAccountInfo.id && (
                    <Button
                      type="button"
                      size="md"
                      onClick={() => this.onclickDelete()}
                      className="mx-3"
                      outline
                      color="default white"
                    >
                      Delete profile
                    </Button>
                  )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  practiceUsersList: state.task.practiceUsersList,
  loadingOwnerVerifyPhone: state.task.loadingOwnerVerifyPhone,
  practiceUserAccountInfo: state.task.practiceUserAccountInfo,
  phoneVerificationInfo: state.task.phoneVerificationInfo,
  practiceSecurityQuestion: state.task.practiceSecurityQuestion,
  getGender: state.task.getGender,
  loading: state.task.loading,
  isProfileCreated: state.task.isProfileCreated,
  practiceUserdeleteInfo: state.task.practiceUserdeleteInfo,
  loadingDeleteUserProfile: state.task.loadingDeleteUserProfile,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps)
)(OwnersProfileSetupIndex);
