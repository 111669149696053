import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchChairsideMedia, getOperatoryMediaChannelList, getWebsiteRoomMediaChannelList } from '../../../../store/actions/taskAction';
import styles from './style.module.scss';
import ChannelList from '../ChannelList'
import Loading from '../../../../components/Loading';

const MyChannels = ({ sortedBy, sortedType, isOperatoryRoom, isChairside }) => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const [myChannels, setMyChannels] = useState([]);

    useEffect(() => {

      if(isChairside){
        dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
        return
      }

      if(isOperatoryRoom){
        dispatch(
          getOperatoryMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }else{
        dispatch(
          getWebsiteRoomMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }
    }, []);

    useEffect(()=>{
        var myChannels = []
        if(isOperatoryRoom){
            let videolist = state.operatoryMediaChannels.find(x => x.created_type !== "thalamus");
            if(videolist != null &&  videolist != undefined){
                myChannels.push(videolist)
            }
            setMyChannels(myChannels)
        }else{
            let videolist = state.waitingRoomMediaChannels.find(x => x.created_type !== "thalamus");
            if(videolist != null &&  videolist != undefined){
                myChannels.push(videolist)
            }
            setMyChannels(myChannels)
        }
  
    }, [state.waitingRoomMediaChannels, state.operatoryMediaChannels])

  return (
    <>
      {state.loading ? <div className={`goolge_tv__loader`}><Loading /></div> :
      <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
          <ChannelList title="My channels" isOperatoryRoom={isOperatoryRoom} noSlider={true} channelsData={myChannels}/>
      </div>
      }
    </>
  )
}

export default MyChannels
