import React from 'react';
import { Button , Label , FormGroup, Form , Col , Row} from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Ownership } from '../../../data/comman';
import DropDown from '../../../components/DropDown'
import { InputField } from '../../../components/InputField';
import HttpUtility, {HELPERURI} from '../../../utilities/HttpUtility';
import { UpdatepracticeInfo , UpdatepracticeInfoRequest  } from "../../../store/actions/taskAction";
import SubHeader from  '../../../components/Header/subHeader'
import NewSubHeader from  '../../../components/Header/NewSubHeader'
import LoadingButton from '../../../components/LoadingButton'


class PracticeInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSave:false,
      isError:{
        name:'',
        email:'',
        ownership:'',
        phone:'',
        specializations:'',
        selectedcountry:'',
        address_line_1:'',
        address_line_2:'',
        city:'',
        selectedstate:'',
        zip:'',
      },
    };
    this.SavePracticeInfo = this.SavePracticeInfo.bind(this);
  }

  async componentDidMount(){
    // this.onupdate()
  }

 async getState(countryId){
  await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
            var countryState = response.data;
            this.onEditPracticeInfo( 'countryState' , countryState)
            this.onEditPracticeInfo( 'selectedstate' , [])
            if(this.props.practiceProfileInfo.state_id){
              var selectedstate = countryState.find(b => b.id === this.props.practiceProfileInfo.state_id)
            }
            this.onEditPracticeInfo( 'selectedstate' , selectedstate)
        }
    });
  }

 async onEditPracticeInfo(field , value){
    let practiceProfileInfo = this.props.practiceProfileInfo
    if(field === "selectedcountry"){
      this.setState({ countryState: [] });
      this.getState(value.id);
      practiceProfileInfo[field] = value
    }else  if(field === 'phone'){
      practiceProfileInfo[field] = value.replace(/\s+/g, '')
    }else  if(field === 'phone_alt'){
      practiceProfileInfo[field] = value.replace(/\s+/g, '')
    }
    else{
      practiceProfileInfo[field] = value
    }
   
    await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))

    await this.isValid(field , value)
  }

  isValid(field , value){
    let isError = this.state.isError;
    let emailValid = this.state.emailValid;

    switch (field) {
      case "name":
        isError.name =
          value.length < 4 ? "Atleast 4 characaters required" : "";
        break;
      case "ownership":
        isError.ownership =
        value && value > 0 ? "required" : "";
        break;
      case "phone":
        isError.phone =
          value && value > 7 ? "required" : "";
        break;
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        isError.email = emailValid ? "" : "Wrong format";
        break;
      case "specializations":
        isError.specializations =
        value.length <= 0 ? "required" : "";
        console.log(value,'value')
        break;
      case "selectedcountry":
        isError.selectedcountry =
        value.length && value.length <= 0 ? "required" : "";
        break;
      case "address_line_1":
        isError.address_line_1 =
        value < 0 ? "required" : "";
        break;
      case "address_line_2":
        isError.address_line_2 =
        value < 0 ? "required" : "";
        break;
      case "city":
        isError.city =
        value < 0 ? "required" : "";
        break;
      case "selectedstate":
        isError.selectedstate =
        value ? "required" : "";
        break;
      case "zip":
        isError.zip =
        value < 0 ? "required" : "";
        break;
      default:
        break; 
    }
    this.setState({ isError })

    const {name , email , ownership , phone , specializations , selectedcountry , address_line_1 , address_line_2 , city , selectedstate , zip} = this.state.isError
    var {practiceProfileInfo} =  this.props
    if (name === "" && practiceProfileInfo.name !== '' && email === '' && practiceProfileInfo.email !== '' && ownership === "" && phone === "" && practiceProfileInfo.phone !== '' && specializations === "" && selectedcountry === ''
        && address_line_1 === "" && address_line_2 === '' && city === '' && selectedstate === '' && practiceProfileInfo.selectedstate && zip === '') {
          
        this.setState({
          isSave: true
        });

      }else{
        this.setState({
          isSave: false
        });
      }
  }

 async SavePracticeInfo(){
      this.onEditPracticeInfo('isLoading' , true)
  
    const {practiceProfileInfo} = this.props
    var specializations = []
    if(practiceProfileInfo.specializations){
      practiceProfileInfo.specializations.map((ids)=>
        specializations.push(ids.id)
      );
    }


    let params={
      id:practiceProfileInfo.id,
      name:practiceProfileInfo.name,
      ownership:practiceProfileInfo.ownership.id,
      phone:practiceProfileInfo.phone.replace(/\s+/g, ''),
      phone_alt: practiceProfileInfo?.phone_alt?.length > 5 ? practiceProfileInfo.phone_alt.replace(/\s+/g, '') : '',
      email:practiceProfileInfo.email,
      country_id:practiceProfileInfo.selectedcountry.id,
      specializations:specializations,

      address_line_1:practiceProfileInfo.address_line_1,
      address_line_2:practiceProfileInfo.address_line_2,
      city:practiceProfileInfo.city,
      state_id:practiceProfileInfo.selectedstate.id,
      zip:practiceProfileInfo.zip,

    }
    await  this.props.dispatch(UpdatepracticeInfo(params));
  }



  render (){
    const {t , country , specializations } = this.props
    const {practiceProfileInfo} = this.props
    return(
      <div className="practiceInfo__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
      {/* <SubHeader className="mb-auto" title="Practice Info"/> */}
      <NewSubHeader className="mb-auto" title="Update Basic Info"/> 
      {/* <div className="practice_name text-center header--sub">
          <h1 className="subtitle p-5">Update Basic Info</h1>
      </div> */}
        <div className="mw-820 transparent__form--white basic_information d-flex flex-column align-items-stretch mb-auto" style={{ marginTop: 0, minHeight: 'auto' }}>
          <Form className="mt-2 h-100 d-flex flex-column">
              <Row>
                <Col md={6} className="padd-r-20">
                  <FormGroup className="mb-5">
                    <h4 className="m-0">{t('title.practiceInformation')}</h4>
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.practiceName')} name="name" value={practiceProfileInfo.name} onChange={(e)=>this.onEditPracticeInfo( 'name' , e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Practicephone">{t('form.practicePhone')}</Label>
                    </div>
                    <PhoneInput defaultMask={'-...........'} alwaysDefaultMask={true} countryCodeEditable={false} value={practiceProfileInfo.phone}  inputProps={{ name: 'phone',required: true, }}  country={(this.props.practiceProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => { this.onEditPracticeInfo('phone' , formattedValue)}}  />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Secondarypracticephone">{t('form.SecondaryPracticePhone')}</Label>
                    </div>
                    <PhoneInput defaultMask={'-...........'} alwaysDefaultMask={true} countryCodeEditable={false} value={practiceProfileInfo.phone_alt}  inputProps={{ name: 'phone_alt' }}  country={(this.props.practiceProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => {  this.onEditPracticeInfo('phone_alt' , formattedValue)}}  />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.practiceEmail')} name="Practiceemail" value={practiceProfileInfo.email}  onChange={(e)=>this.onEditPracticeInfo( 'email' , e.target.value)}  />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Ownership">{t('form.ownership')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        options={Ownership}
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        onChange={(val)=> this.onEditPracticeInfo('ownership', val) }
                        isSearchable={false}
                        placeholder={''}
                        value={practiceProfileInfo.ownership }
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Specialization">{t('form.specialization')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container react-select__multi'
                        classNamePrefix="react-select"
                        options={specializations}
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        onChange={(val)=> this.onEditPracticeInfo('specializations', val) }
                        isMulti={true}
                        isSearchable={true}
                        placeholder={''}
                        value={practiceProfileInfo.specializations}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} className="padd-l-20 basic_information_right_side">
                  <FormGroup className="mb-5">
                    <h4 className="m-0">{t('title.address')}</h4>
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="country">{t('form.country')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        options={country}
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        onChange={(val)=> this.onEditPracticeInfo('selectedcountry', val) }
                        isSearchable={true}
                        placeholder={''}
                        value={practiceProfileInfo.selectedcountry}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.streetandnumber')} name="Streetandnumber" value={practiceProfileInfo.address_line_1}  onChange={(e) => this.onEditPracticeInfo('address_line_1', e.target.value)}  />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.apartment')} name="Apartment" value={practiceProfileInfo.address_line_2} onChange={(e) => this.onEditPracticeInfo('address_line_2', e.target.value)}  />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.city')} name="city" value={practiceProfileInfo.city}   onChange={(e) => this.onEditPracticeInfo('city', e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="state">{t('form.state')}</Label>
                    </div>
                    <DropDown 
                          className='react-select-container react-select__multi'
                          classNamePrefix="react-select"
                          options={practiceProfileInfo.countryState}
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          onChange={(val)=> this.onEditPracticeInfo('selectedstate', val) }
                          isSearchable={true}
                          placeholder={''}
                          value={practiceProfileInfo.selectedstate}
                      />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.postalcode')} name="Postalcode" value={practiceProfileInfo.zip}  onChange={(e) => this.onEditPracticeInfo('zip', e.target.value)}  />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mt-auto text-center">
                <LoadingButton type="button" size="md" error={this.state.IsError}  label="Save" loading={practiceProfileInfo.isLoading} disabled={practiceProfileInfo.isLoading}   onClick={() => this.SavePracticeInfo()} className={`mt-5 mr-3`} color="primary"/>
                {/* <Button type="button" onClick={() => this.props.onBack()}  size="md"  className="mt-5 mr-3" color="primary">{t('btn.back')}</Button> */}
              </div>
          </Form>
        </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  country: state.task.country,
  specializations: state.task.specializations,
  practiceProfileInfo: state.task.practiceProfileInfo,

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(PracticeInfo);