import React from "react";
import SubHeader from "../../components/Header/subHeader";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button } from "reactstrap";

import CorporateTVIcon from "../../img/corporate-tv.svg";
import CorporateHomeIcon from "../../img/home_icon.svg";
import CorporateCardIcon from "../../img/ic_credit_card.svg";
import CorporateIpadIcon from "../../img/ic_devices.svg";

import "./style.scss";
import { history } from "..";
import error_worning_small from "../../img/error_worning_small.svg";

class HomeIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSigninClick: true,
      isSignin: false,
    };
    this.onSigninClick = this.onSigninClick.bind(this);
  }

  onAddManageLocation() {
    history.push("/add-manage-location");
  }

  onSubscriptionPayment() {
    history.push("/subscription-payment");
  }

  onSigninClick() {
    this.setState({ isSigninClick: false });
  }
  onSignin() {
    this.setState({ isSignin: true });
  }

  locationListing(){
    if (this.props.practiceInfo.is_paid_account !== 0) {
      history.push("/add-manage-location")
    }else{
      history.push("/limited-access")
    }
    
  }

  async componentDidMount() {}

  render() {
    const { t } = this.props;

    return (
      <div className="main__screen corporate__screen  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Corporation" />
        <div className="corporate__page__practice-heading text-center">
          <h4>A quick way to manage your practices</h4>
          <p>
            Easy and fast access to all your practices, allowing management over
            practice information, channels availability, and hardware
            acquisition.
          </p>
        </div>
        <div className="main__inner__screen mb-auto d-flex">
          <div
            className="corporate__item mx-20"
            onClick={() =>  this.locationListing()}
          >
            <div className="corporate__box  d-flex flex-column align-items-center justify-content-center">
              <div className="corporate__icon">
                <img
                  src={CorporateHomeIcon}
                  alt="corporate-home"
                  style={{ width: 100 }}
                />
              </div>
            </div>
            <div className="corporate__name my-3 text-center">
              <p className="m-0">Add &amp; manage locations</p>
            </div>
          </div>

          <div
            className="corporate__item mx-20"
            onClick={() => history.push("/channels")}
          >
            <div className="corporate__box  d-flex flex-column align-items-center justify-content-center">
              <div className="corporate__icon">
                <img src={CorporateTVIcon} alt="" />
              </div>
            </div>
            <div
              className="corporate__name my-3 text-center"
              onClick={() => history.push("/channels")}
            >
              <p className="m-0">View &amp; Share Channels</p>
            </div>
          </div>

          <div
            className="corporate__item mx-20"
            onClick={() => history.push("/purchase-hardware")}
          >
            <div className="corporate__box  d-flex flex-column align-items-center justify-content-center">
              <div className="corporate__icon">
                <img src={CorporateIpadIcon} alt="corporate-Ipad" />
              </div>
            </div>
            <div
              className="corporate__name my-3 text-center"
              onClick={() => history.push("/purchase-hardware")}
            >
              <p className="m-0">Purchase hardware</p>
            </div>
          </div>

          <div
            className="corporate__item mx-20"
            onClick={() => history.push("/subscription-payment")}
          >
            <div className="corporate__box  d-flex flex-column align-items-center justify-content-center">
              <div className="corporate__icon">
                <img src={CorporateCardIcon} alt="coroporate-card" />
              </div>
            </div>
            <div
              className="corporate__name my-3 text-center"
              onClick={() => history.push("/subscription-payment")}
            >
              <p className="m-0">Subscription payment</p>
            </div>
          </div>
        </div>
        {this.props.practiceInfo &&
        this.props.practiceInfo.credit_card_declined === 1 ? (
          <div className="custom__error d-flex align-items-center justify-content-center">
            <img className="mr-20 ml-20" alt="" src={error_worning_small} />
            <p className="m-0">{t("errors.accountInactive")}</p>
            <Button
              className="ml-5"
              outline
              size="md"
              onClick={() => history.push("/paymentInfo")}
              color="default white"
            >
              {t("btn.payNow")}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  practiceProfileInfo: state.task.practiceProfileInfo
});
export default compose(
  withTranslation("common"),
  connect(mapStateToProps)
)(HomeIndex);
