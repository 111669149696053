/** @format */

import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner , Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from '../../components/Header/subHeader';
import Sidebar, { TabPanel } from '../../components/navSideBar/Sidebar';
import VideoSlider from '../../components/videoSlider/VideoSlider';
import {
	tabList,
	tabItems,
} from './staticFile';
import { fetchChairsideMediaCategoryList , setSelectedChairSideMediaSpecializationCategory} from '../../store/actions/taskAction';
import './style.scss';
import { combineReducers } from 'redux';
import { history } from '..';

import SidebarTwo from './SidebarTwo';

const ChairsideEducation = () => {
	const [value, setValue] = useState(1);
	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	const [categoriesList, setCategoriesList] = useState(
		state.chairSideCategoryList
	);
	const [videosList, setVideosList] = useState(state.chairSideMediaState);

	const [isOpen, setIsOpen] = useState(false);
	const [isActiveSidebar, setActiveSidebar] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const submitColors = (color) => {
		//console.log(color);
	};

	useEffect(() => {
		dispatch(fetchChairsideMediaCategoryList(state.practiceUserLoginInfo));
		// if(state.isChairsideMenuActiveIndex){
		// 	setValue(6);
		//   }
	}, []);

	useEffect(() => {
		setCategoriesList(state.chairSideCategoryList)
	}, [state.chairSideCategoryList]);

	const handleClick = (id) => {
		dispatch(setSelectedChairSideMediaSpecializationCategory(id));
	}

	const onHover = (value) =>{
		setActiveSidebar(value)
	}

	return (
		<div className='chairside_education__main__screen flex-column h-100 align-items-center'>
			{/* {state.loading ? (
				<Spinner color='primary' style={{ marginLeft: "950px", marginTop: "400px" }} />
			) : ( */}
				<>
					<SubHeader className='' title={state.isChairsideMenuActiveIndex== 5 ? 'Playlists' : 'Chairside Education' } />
					<div className='chairside_education__inner__screen  d-flex'>
						<div className={`${isActiveSidebar ? 'activeNav' : '' } navSidebar `}>
							<div className="d-flex flex-column navSidebar__inner h-100 justify-content-between" onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)}>
								{/* <p className="lookingvideo">Looking for a video we don't have an yet? <span>click here to make a request.</span></p> */}
								<div className="lookingvideo px-3">
									<h6 style={{ color:"white" }}>Looking for a video we don't have yet?</h6>
									<Button className="p-0" onClick={() => history.push({
										pathname: '/video-request',
										state: { page: '/chairside-education' }
									}

									)} color="link primary">Click here to make a request.</Button>
								</div>
								<div className="navSidebar__tabItem" >
									<Sidebar
										value={state.isChairsideMenuActiveIndex}
										setValue={setValue}
										tabList={tabList}
										onClick={toggle}
									/>
									{state.isChairsideMenuActiveIndex === 6 ?
									<ul className="sidebartwo">
										{categoriesList.map((item,index) => {
											return (
												<li key={index} id={item.id} className={`${state.selectedSpecializatinCatID == item.id ? 'activecetegory' : ''}`}  onClick={()=>handleClick(item.id)}> {item.title}</li>
											)
										})}
									</ul>
									: ''}
								</div>
							{state.practiceInfo.is_paid_account == 0  && state.practiceInfo.days_left == 0 && state.practiceUserLoginInfo.type != 0 ?
								<div className="sidebar__btn">
									<Button type="button" className="w-100" color="primary">Subscribe for full access</Button>
								</div>
								: ''
							}
							</div>
						</div>
						<div className={`${state.isChairsideMenuActiveIndex == 1 ? 'home' : ''} tabcontent` }>
							{tabItems.map((item, index) => (
								<TabPanel
									value={state.isChairsideMenuActiveIndex}
									index={index}
									key={index}
									>
									{item.component}
								</TabPanel>
							))}
						</div>
					</div>
				</>
			{/* )} */}
		</div>
	);
};
export default ChairsideEducation;
