import React from 'react'
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { history } from '../..';
import styles from './style.module.scss';



const OperatoryRoom = () => {

  const states = useSelector(state => state.task);

  const redirectPage = (isPlan, redirectUrl, planName)=>{
    let plugins = states.avelablePlugin
    let selectedplan = plugins?.find(p => p.code === isPlan);
    if(selectedplan){
      history.push(redirectUrl);
    }else{
      history.push({
        pathname: '/upgrade-plan',
        selectplan: planName
      });
    }
  }
  
  return (
    <div className={`${styles.videoRoomBox} ${styles.videoRoomBox__operatory_room} w-100 d-flex flex-column justify-content-center `}>
        <div className={`${styles.videoRoomBox__innerBox} mx-auto text-center mt-auto`}>
        <h2 className='mb-5'>This TV is located in the</h2>
        <h1 className='mb-5'>Operatory Room</h1>
        <p className='mb-5'>A mix of educational, entertainment, and dental related content specifically for TVs in operatories.</p>

        <h2 className='mb-5'>OPERATORY CHANNEL<br/>
        & CHAIRSIDE EDUCATION</h2>

        <p>*This selection can later be changed.</p>
        </div>

        <div className='mt-auto text-center mb-5'>
          <Button size='lg' color="default white" onClick={()=> redirectPage("operatory_room" ,'/google-tv-operatory-room', 'Operatory Room TV') } outline className="mb-4">Select</Button>
        </div>
    </div>
  )
}

export default OperatoryRoom
