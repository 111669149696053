import React, { useState } from "react";
import clsx from 'clsx';
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse, Button } from 'reactstrap';
import { tabList } from "../staticFile";
import { fetchChairsideMedia, setSelectedChairSideMediaSpecializationCategory } from "../../../store/actions/taskAction";

import "./style.scss"
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    
    left: true,
    
  });
  const states = useSelector(state => state.task);
  const [categoriesList, setCategoriesList] = useState(states.chairSideCategoryList);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div className="second-sidebar">
            <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      
      className="second-sidebar-menu"
    >
      <p className="lookingvideo">Looking for a video we don't have an yet? <span>click here to make a request.</span></p>
      <List>
        {tabList.map((text, index) => (
          <ListItem button key={index}>
            <ListItemIcon>{text.icon}</ListItemIcon>
            <ListItemText primary={text.name} />
          </ListItem>
        ))}
      </List>
      <Collapse isOpen={true}>
          <ul className="sidebartwo" style={{marginLeft:"90px"}}>
              {categoriesList.map((item,index) => {    
                  return (                    
                      <li  id={item.id} key={index}  onClick={()=>handleClick(item.id)}> {item.title}</li>
                  )
              })}
          </ul>
      </Collapse>
      
      <div className="sidebar__btn">
          <Button size="md" color="primary">Subscribe for full access</Button>
      </div>
      
    </div>
    </div>
  );

  const handleClick = (id) => {
    // const params = states.practiceUserLoginInfo;
    // params['category_id[]'] = [id];
    dispatch(setSelectedChairSideMediaSpecializationCategory(id));
  }

  return (
    <div >
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor} >
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} className="sidebardrawer">
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}