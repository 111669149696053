import React from 'react';

import { Button , Label , FormGroup, Form , Col , Row, } from 'reactstrap';
import DropDown from '../../../components/DropDown'

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import moment from 'moment';

const hours = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
]
const PracticeOpenClosed = [
    { value: 0, label: 'Closed' },
    { value: 1, label: 'Open' },
]
const CLOSESTATUS = 0;

const PracticeAmPm = [
    { value: 0, label: 'AM' },
    { value: 1, label: 'PM' },
]

const Days = ["Monday", "Tuesday", "Wed.", "Thursday", "Friday", "Saturday", "Sunday"];


class PracticeHours extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        practiceProfileInfo: {
            practiceProfileInfo:this.props.practiceProfileInfo,
            // additional_info:{
            //     schedule: [
            //         { status: 1 },
            //         { status: 1 },
            //         { status: 1 },
            //         { status: 1 },
            //         { status: 1 },
            //         { status: 0 },
            //         { status: 0 }
            //     ]
            // }
        },
      minutes: [],
      index:0,
      hrsValue: 0 ,
      isError: {
        fullName:'',
        practiceName:'',
        phone:'',
        email:'',
        message:'',
      },
      isSave: true,
    };
    this.IncrementItem = this.IncrementItem.bind(this);
    this.DecrementItem = this.DecrementItem.bind(this);
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
  }

  IncrementItem(){
    var currentValeu = this.state.hrsValue;
    currentValeu = currentValeu +  1
    this.setState({hrsValue:currentValeu})
  }

  DecrementItem(){
    var currentValeu = this.state.hrsValue;
    currentValeu = currentValeu -  1
    this.setState({hrsValue:currentValeu})
  }

  nextClick(){
    this.props.nextStep();
  }

  prevClick(){
    this.props.prevStep();
  }
  
  async componentDidMount(){
    this.getMinutes()
  }
    
    getMinutes(){
        var minutes = [];
        for(var i = 0; i < 60; i++){
            minutes.push({ value: ('0' + i).slice(-2), label: ('0' + i).slice(-2) });
            i = i + 14;
        }
        this.setState({ minutes });
    }

    getStatus(status){
        if(status === 0){
            return PracticeOpenClosed[0]
        }else{
            return PracticeOpenClosed[1]
        }
    }

    getHours(time){
        var HourArray = this.getHMA(time);
        return hours.find(x => x.label === HourArray[0]);
    }

    getMinute(time){
        var MinuteArray = this.getHMA(time);
        return this.state.minutes.find(x => x.label === MinuteArray[1]);
    }

    getAMPM(time){
        var AmPmArray = this.getHMA(time);
        return PracticeAmPm.find(x => x.label === AmPmArray[2]);
    }

    getHMA(time){
        var hour = moment(time, "HH:mm").format('hh');
        var minute = moment(time, "HH:mm").format('mm');
        var ampm = moment(time, "HH:mm").format('A');
        return [ hour, minute, ampm ];
 }

  handleChange(field, option, item, index){
    var practiceProfileInfo =  this.props.values
    var schedule = practiceProfileInfo.additional_info.schedule[index];
    var time;
    var start_time;
    var hour;
    var end_time;
    switch(field){
        case "status":
                schedule.status = option.value;
            break;
        case "sHour":
                time = this.getHMA(item.start_time)
                start_time = moment(`${option.value}:${time[1]} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.start_time = start_time;
            break;
        case "sMinute":
                time = this.getHMA(item.start_time);
                start_time = moment(`${time[0]}:${option.value} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.start_time = start_time;
            break;
        case "sA":
                time = this.getHMA(item.start_time);
                hour = time[0]
                if(option.label === "AM" && hour > 12){
                    hour = hour - 12
                }
                start_time = moment(`${hour}:${time[1]} ${option.label}`, "hh:mm A").format("HH:mm");
                schedule.start_time = start_time;
            break;
        case "eHour":
                time = this.getHMA(item.end_time)
                end_time = moment(`${option.value}:${time[1]} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.end_time = end_time;
            break;
        case "eMinute":
                time = this.getHMA(item.end_time);
                end_time = moment(`${time[0]}:${option.value} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.end_time = end_time;
            break;
        case "eA":
                time = this.getHMA(item.end_time);
                hour = time[0]
                if(option.label === "AM" && hour > 12){
                    hour = hour - 12
                }
                end_time = moment(`${hour}:${time[1]} ${option.label}`, "hh:mm A").format("HH:mm");
                schedule.end_time = end_time;
            break;      
            default:
                break;
    }
    this.setState({ practiceProfileInfo },()=>{
        var practiSchedule = this.props.values.additional_info.schedule;
        var timeFormat = "HH:mm";
        var error = {};
        practiSchedule.map((sc, index)=>{
            console.log(sc,'sc')
            if(!moment(sc.end_time, timeFormat).isAfter(moment(sc.start_time, timeFormat))){
                error[index] = "Invalid time selected";
            }
        });
        var isSave = false;
        if(Object.keys(error).length > 0)
            isSave = false;
        else
            isSave = true;
        this.setState({ error, isSave });
    });   
}

  render (){
    const {t , values } = this.props
    var status5 = this.getStatus( values.additional_info.schedule[5].status);
    var statusValue5 = status5.value;
    var status6 = this.getStatus( values.additional_info.schedule[6].status);
    var statusValue6 = status6.value;
    return(
        <div className="practice__hours_section transparent__form--white lg d-flex flex-column align-items-stretch mb-auto">
          <Form className="h-100 d-flex flex-column">
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-4 form-heading">
                    <h4 className="m-0 mt-1">{t('title.practiceHours')}</h4>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="border--right pr-5">
                {values.additional_info.schedule.slice(0, 5).map((items, index)=>{
                     var status = this.getStatus(items.status);
                     var statusValue = status.value;
                    return  <div  key={index}  className="w-auto mt-3">
                                <div className="d-flex justify-content-between ">
                                <Label className="m-0" for="">{Days[index]}</Label>
                                </div>
                                <div className="d-flex align-items-end pb-1 mt-2">
                                    <FormGroup className="align-items-center m-0 pr-2">
                                        <Label className="p-0 mb-2 " >Open / Closed</Label>
                                        <DropDown 
                                            className='react-select-container select-style-3 w-104 p-0'
                                            classNamePrefix="react-select"
                                            options={PracticeOpenClosed}
                                            isSearchable={false}
                                            placeholder={''}
                                            value={status}
                                            onChange={(option)=> this.handleChange("status", option, items, index)}
                                        />
                                    </FormGroup>
                                    <FormGroup className={`align-items-center m-0 px-3 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                        <Label className="p-0 mb-3 w-100" >From</Label>
                                    </FormGroup>
                                    <div className={`w-auto practice__hours_box`}>
                                        { this.state.error && this.state.error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{this.state.error[index]}</div>}
                                        <div className="d-flex">
                                            <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                                <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                <DropDown 
                                                    className='react-select-container select-style-3 w-101 p-0'
                                                    classNamePrefix="react-select"
                                                    isDisabled={statusValue === CLOSESTATUS}
                                                    options={hours}
                                                    isSearchable={false}
                                                    placeholder={''}
                                                    value={this.getHours(items.start_time)}
                                                    onChange={(option)=> this.handleChange("sHour", option, items, index)}
                                                />
                                            </FormGroup>
                                            <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                                <Label className="p-0 mb-2 w-100" >Min</Label>
                                                <DropDown 
                                                    className='react-select-container w-101 p-0'
                                                    classNamePrefix="react-select"
                                                    isDisabled={statusValue === CLOSESTATUS}
                                                    options={this.state.minutes}
                                                    isSearchable={false}
                                                    placeholder={''}
                                                    value={this.getMinute(items.start_time)}
                                                    onChange={(option)=> this.handleChange("sMinute", option, items, index)}
                                                />
                                            </FormGroup>
                                            <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                        <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                        <DropDown 
                                            className='react-select-container w-101  p-0'
                                            classNamePrefix="react-select"
                                            isDisabled={statusValue === CLOSESTATUS}
                                            options={PracticeAmPm}
                                            isSearchable={false}
                                            placeholder={''}
                                            value={this.getAMPM(items.start_time)}
                                            onChange={(option)=> this.handleChange("sA", option, items, index)}
                                        />
                                    </FormGroup>
                                        </div>
                                    </div>
                                    <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                        <Label className="p-0 mb-3 w-100" >To</Label>
                                    </FormGroup>
                                    <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                        <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                        <DropDown 
                                            className='react-select-container w-101  p-0'
                                            classNamePrefix="react-select"
                                            isDisabled={statusValue === CLOSESTATUS}
                                            options={hours}
                                            isSearchable={false}
                                            placeholder={''}
                                            value={this.getHours(items.end_time)}
                                            onChange={(option)=> this.handleChange("eHour", option, items, index)}
                                        />
                                    </FormGroup>
                                    <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                        <Label className="p-0 mb-2 w-100" >Min</Label>
                                        <DropDown 
                                            className='react-select-container w-101  p-0'
                                            classNamePrefix="react-select"
                                            isDisabled={statusValue === CLOSESTATUS}
                                            options={this.state.minutes}
                                            isSearchable={false}
                                            placeholder={''}
                                            value={this.getMinute(items.end_time)}
                                            onChange={(option)=> this.handleChange("eMinute", option, items, index)}
                                        />
                                    </FormGroup>
                                    <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue === CLOSESTATUS ? "disable__time" : ''}`}>
                                        <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                        <DropDown 
                                            className='react-select-container w-101 w-100 p-0'
                                            classNamePrefix="react-select"
                                            isDisabled={statusValue === CLOSESTATUS}
                                            options={PracticeAmPm}
                                            isSearchable={false}
                                            placeholder={''}
                                            value={this.getAMPM(items.end_time)}
                                            onChange={(option)=> this.handleChange("eA", option, items, index)}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                })}
                </Col>
                <Col md={6} className="pl-5">
                <div  className="w-auto mt-3">
                    <div className="d-flex justify-content-between ">
                        <Label className="m-0" for="practiceManagementSoftware">Saterday</Label>
                    </div>
                    <div className="d-flex align-items-end pb-1 mt-2">
                        <FormGroup className={`align-items-center m-0 pr-2 `}>
                            <Label className="p-0 mb-2 " >Open / Closed</Label>
                            <DropDown 
                                className='react-select-container select-style-3 w-104 p-0'
                                classNamePrefix="react-select"
                                options={PracticeOpenClosed}
                                isSearchable={false}
                                placeholder={''}
                                value={status5}
                                onChange={(option)=> this.handleChange("status", option, values.additional_info.schedule[5], 5)}
                            />
                        </FormGroup>
                        <FormGroup className={`align-items-center m-0 px-3 ${statusValue5 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-3 w-100" >From</Label>
                        </FormGroup>
                        <div className={`w-auto practice__hours_box`}>
                            { this.state.error && this.state.error[6] && <div className="text-danger w-100 error fs-12 px-7_5">{this.state.error[6]}</div>}
                            <div className="d-flex">
                                <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue5 === CLOSESTATUS ? "disable__time" : ''}`}>
                                    <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                    <DropDown 
                                        className='react-select-container select-style-3 w-101 p-0'
                                        classNamePrefix="react-select"
                                        isDisabled={statusValue5 === CLOSESTATUS}
                                        options={hours}
                                        isSearchable={false}
                                        placeholder={''}
                                        value={this.getHours(values.additional_info.schedule[5].start_time)}
                                        onChange={(option)=> this.handleChange("sHour", option, values.additional_info.schedule[5], 5)}
                                    />
                                </FormGroup>
                                <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue5 === CLOSESTATUS ? "disable__time" : ''}`}>
                                    <Label className="p-0 mb-2 w-100" >Min</Label>
                                    <DropDown 
                                        className='react-select-container w-101 p-0'
                                        classNamePrefix="react-select"
                                        isDisabled={statusValue5 === CLOSESTATUS}
                                        options={this.state.minutes}
                                        isSearchable={false}
                                        placeholder={''}
                                        value={this.getMinute(values.additional_info.schedule[5].start_time)}
                                        onChange={(option)=> this.handleChange("sMinute", option, values.additional_info.schedule[5], 5)}
                                    />
                                </FormGroup>
                                <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue5 === CLOSESTATUS ? "disable__time" : ''}`}>
                                    <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                    <DropDown 
                                        className='react-select-container w-101  p-0'
                                        classNamePrefix="react-select"
                                        isDisabled={statusValue5 === CLOSESTATUS}
                                        options={PracticeAmPm}
                                        isSearchable={false}
                                        placeholder={''}
                                        value={this.getAMPM(values.additional_info.schedule[5].start_time)}
                                        onChange={(option)=> this.handleChange("sA", option, values.additional_info.schedule[5], 5)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue5 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-3 w-100" >To</Label>
                        </FormGroup>
                        <FormGroup className={`${statusValue5 === CLOSESTATUS ? "disable__time" : ''} align-items-center m-0 px-7_5`}>
                            <Label className="p-0 mb-2 w-100" >Hr.</Label>
                            <DropDown 
                                className='react-select-container w-101  p-0'
                                classNamePrefix="react-select"
                                isDisabled={statusValue5 === CLOSESTATUS}
                                options={hours}
                                isSearchable={false}
                                placeholder={''}
                                value={this.getHours(values.additional_info.schedule[5].end_time)}
                                onChange={(option)=> this.handleChange("eHour", option, values.additional_info.schedule[5], 5)}
                            />
                        </FormGroup>
                        <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue5 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-2 w-100" >Min</Label>
                            <DropDown 
                                className='react-select-container w-101  p-0'
                                classNamePrefix="react-select"
                                isDisabled={statusValue5 === CLOSESTATUS}
                                options={this.state.minutes}
                                isSearchable={false}
                                placeholder={''}
                                value={this.getMinute(values.additional_info.schedule[5].end_time)}
                                onChange={(option)=> this.handleChange("eMinute", option, values.additional_info.schedule[5], 5)}
                            />
                        </FormGroup>
                        <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue5 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                            <DropDown 
                                className='react-select-container w-101 w-100 p-0'
                                classNamePrefix="react-select"
                                isDisabled={statusValue5 === CLOSESTATUS}
                                options={PracticeAmPm}
                                isSearchable={false}
                                placeholder={''}
                                value={this.getAMPM(values.additional_info.schedule[5].end_time)}
                                onChange={(option)=> this.handleChange("eA", option, values.additional_info.schedule[5], 5)}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div  className="w-auto mt-3">
                    <div className="d-flex justify-content-between ">
                        <Label className="m-0" for="practiceManagementSoftware">Sunday</Label>
                    </div>
                    <div className="d-flex align-items-end pb-1 mt-2">
                        <FormGroup className={`align-items-center m-0 pr-2 `}>
                            <Label className="p-0 mb-2 " >Open / Closed</Label>
                            <DropDown 
                                className='react-select-container select-style-3 w-104 p-0'
                                classNamePrefix="react-select"
                                options={PracticeOpenClosed}
                                isSearchable={false}
                                placeholder={''}
                                value={status6}
                                onChange={(option)=> this.handleChange("status", option, values.additional_info.schedule[6], 6)}
                            />
                        </FormGroup>
                        <FormGroup className={`align-items-center m-0 px-3 ${statusValue6 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-3 w-100" >From</Label>
                        </FormGroup>

                        <div className={`w-auto practice__hours_box`}>
                            { this.state.error && this.state.error[6] && <div className="text-danger w-100 error fs-12 px-7_5">{this.state.error[6]}</div>}
                            <div className="d-flex">
                                <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue6 === CLOSESTATUS ? "disable__time" : ''}`}>
                                    <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                    <DropDown 
                                        className='react-select-container select-style-3 w-101 p-0'
                                        classNamePrefix="react-select"
                                        isDisabled={statusValue6 === CLOSESTATUS}
                                        options={hours}
                                        isSearchable={false}
                                        placeholder={''}
                                        value={this.getHours(values.additional_info.schedule[6].start_time)}
                                        onChange={(option)=> this.handleChange("sHour", option, values.additional_info.schedule[6], 6)}
                                    />
                                </FormGroup>
                                <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue6 === CLOSESTATUS ? "disable__time" : ''}`}>
                                    <Label className="p-0 mb-2 w-100" >Min</Label>
                                    <DropDown 
                                        className='react-select-container w-101 p-0'
                                        classNamePrefix="react-select"
                                        isDisabled={statusValue6 === CLOSESTATUS}
                                        options={this.state.minutes}
                                        isSearchable={false}
                                        placeholder={''}
                                        value={this.getMinute(values.additional_info.schedule[6].start_time)}
                                        onChange={(option)=> this.handleChange("sMinute", option, values.additional_info.schedule[6], 6)}
                                    />
                                </FormGroup>
                                <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue6 === CLOSESTATUS ? "disable__time" : ''}`}>
                                <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                <DropDown 
                                    className='react-select-container w-101  p-0'
                                    classNamePrefix="react-select"
                                    isDisabled={statusValue6 === CLOSESTATUS}
                                    options={PracticeAmPm}
                                    isSearchable={false}
                                    placeholder={''}
                                    value={this.getAMPM(values.additional_info.schedule[6].start_time)}
                                    onChange={(option)=> this.handleChange("sA", option, values.additional_info.schedule[6], 6)}
                                />
                            </FormGroup>
                            </div>
                        </div>
                        <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue6 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-3 w-100" >To</Label>
                        </FormGroup>
                        <FormGroup className={`${statusValue6 === CLOSESTATUS ? "disable__time" : ''} align-items-center m-0 px-7_5`}>
                            <Label className="p-0 mb-2 w-100" >Hr.</Label>
                            <DropDown 
                                className='react-select-container w-101  p-0'
                                classNamePrefix="react-select"
                                isDisabled={statusValue5 === CLOSESTATUS}
                                options={hours}
                                isSearchable={false}
                                placeholder={''}
                                value={this.getHours(values.additional_info.schedule[6].end_time)}
                                onChange={(option)=> this.handleChange("eHour", option, values.additional_info.schedule[6], 6)}
                            />
                        </FormGroup>
                        <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue6 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-2 w-100" >Min</Label>
                            <DropDown 
                                className='react-select-container w-101  p-0'
                                classNamePrefix="react-select"
                                isDisabled={statusValue6 === CLOSESTATUS}
                                options={this.state.minutes}
                                isSearchable={false}
                                placeholder={''}
                                value={this.getMinute(values.additional_info.schedule[6].end_time)}
                                onChange={(option)=> this.handleChange("eMinute", option, values.additional_info.schedule[6], 6)}
                            />
                        </FormGroup>
                        <FormGroup className={`align-items-center m-0 px-7_5 ${statusValue6 === CLOSESTATUS ? "disable__time" : ''}`}>
                            <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                            <DropDown 
                                className='react-select-container w-101 w-100 p-0'
                                classNamePrefix="react-select"
                                isDisabled={statusValue6 === CLOSESTATUS}
                                options={PracticeAmPm}
                                isSearchable={false}
                                placeholder={''}
                                value={this.getAMPM(values.additional_info.schedule[6].end_time)}
                                onChange={(option)=> this.handleChange("eA", option, values.additional_info.schedule[6], 6)}
                            />
                        </FormGroup>
                    </div>
                </div>
                </Col>
              </Row>
              <div className="mt-auto">
                <Button type="button" size="md" onClick={ this.nextClick } disabled={!this.state.isSave} className="mt-5 mr-3" color="primary">{t('btn.next')}</Button>
                <Button type="button" size="md" onClick={ this.prevClick } /*disabled={!this.state.isSave}*/ className="mt-5 ml-3" outline color="default white">{t('btn.back')}</Button>
              </div>
            </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceProfileInfo: state.task.practiceProfileInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(PracticeHours);