import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import Devices from './../../../img/help/devices.png'
import Setting from './../../../img/help/setting.png'
import Download from './../../../img/help/download.png'
import Trial from './../../../img/help/Trial.png'

import GoogelTvDevices from './../../../img/help/googleTV.svg'
import microsoft from './../../../img/help/Group709.png'
import androidIcon from './../../../img/help/android_icon.png'
import IphoneIcon from './../../../img/help/Group711.png'

import './../style.scss';
import { Button } from 'reactstrap';

const InitialSetup =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <>
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Initial Setup</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc">
                                        <h5>Downloading Thalamus</h5>
                                        <div className="help_detail">
                                              <p>Thalamus is available on PC, Google TV, and Android and iOS tablets.
                                                </p>
                                                <p> To install Thalamus, follow the link from your device or tablet.</p>
                                                <p>iOS, Android </p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{}}>
                                    <div >
                                      <a className='mb-5 d-flex mx-4' target="_blank" href="https://play.google.com/store/apps/details?id=com.snw.thalamusmedical" rel="noreferrer"><img src ={GoogelTvDevices}  alt="Googel Tv"/></a>

                                      <a className='mb-5 d-flex mx-4'  rel="noreferrer"><img src ={androidIcon}  alt="Android"/></a>

                                    </div>

                                    <div>
                                          <a className='mb-5 d-flex mx-4' target="_blank" href="https://dental.thalamusmedia.com/get-started#thalamus-app" rel="noreferrer"><img src ={microsoft}  alt="Microsoft"/></a>

                                          <a className='mb-5 d-flex mx-4' target="_blank" href="https://apps.apple.com/us/app/thalamus-medical-media/id1580517776" rel="noreferrer"><img src ={IphoneIcon}  alt="Iphone"/></a>
                                    </div>
                                    
                                  </div>
                                 
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"25px"}} >
                                        <h5>Setting up your Google TV</h5>
                                        <div className="help_detail">
                                              <p>Google TV is compatible with any TV that has an HDMI port. </p>
                                                <p> For step by step instructions for setting up your Google TV, please see Google’s <a target="_blank" rel="noreferrer" href="https://support.google.com/chromecast/answer/10109990?hl=en-CA">support page.</a></p>
                                                <p>Video instructions can be found <a  target="_blank" rel="noreferrer" href="https://youtu.be/wvsZD0X73RQ">here.</a></p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Setting}  alt="" style={{marginRight:"auto"}}/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"36px"}}>
                                        <h5>Downloading Thalamus on your tv</h5>
                                        <div className="help_detail">
                                              <p>Before downloading the Thalamus app, you must have completed setting up your Google TV. Once the setup is complete:</p>
                                                <ul>
                                                    <li>Select Search on the home screen.</li>
                                                    <li>Type Thalamus on the popup keyboard.</li>
                                                    <li>A page will appear with the app.</li>
                                                    <li>Select Install.</li>
                                                </ul>
                                              <p>If you need further assistance installing apps on your Google TV, please see Google’s <a target="_blank" rel="noreferrer" href="https://support.google.com/googletv/answer/10050570?hl=en">support page.</a> </p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Download}  alt="" style={{ marginTop:"14px"}}/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc"  style={{marginTop:"38px"}}>
                                        <h5>How long is the free trial?</h5>
                                        <div className="help_detail">
                                              <p>We offer a 14 day free trial to new Thalamus customers. The free trial includes the same features as the paid version, but will automatically disable all features when the trial is over. 
                                                </p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Trial}  alt="" style={{marginTop:"-21px"}}/>
                                  </div>
                            </div>
                      </div>
                  </>
            
    ) 

}

export default InitialSetup;
