import React from 'react';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import BrandLogo from '../../img/logo.svg'
import InvoiceIcon from '../../img/InvoiceIcon.svg'
import IconLocationPin from '../../img/IconLocationPin.svg'
import IconEmail from '../../img/IconEmail.svg'
import IconPhone from '../../img/IconPhone.svg'
import { InvoiceViewList, cleardata, UpdateInvoiceEmail, getInvoiceEmails } from "../../store/actions/taskAction";
import { DateDDMMMYYY } from '../../utilities/HttpUtility';
import moment from 'moment';
import './style.scss';
import { Button } from 'reactstrap';
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      statementEmail: "",
      statementEmailAllow: 0
    }
  }

  async componentDidMount() {
    this.props.dispatch(InvoiceViewList(this.props.history.location.state.invoice));
    this.props.dispatch(getInvoiceEmails());
    // this.props.dispatch(cleardata());
    let emailDetail = this.props.getInvoiceEmails.data
    this.setState({ email: emailDetail.email, statementEmail: emailDetail.statement_email, statementEmailAllow: emailDetail.need_statement_on_email })
  }
  updateEmailDetail() {
    let body = {
      statement_email: this.state.statementEmail,
      need_statement_on_email: this.state.statementEmailAllow
    }
    this.props.dispatch(UpdateInvoiceEmail(body));
  }
  render() {
    const invoice_View = this.props.invoice_View || {}
    console.log('Invoice View Data is ', invoice_View);
    return (
      <div className="main__screen corporate-invoice__page  d-flex flex-column h-100 align-items-center justify-content-center" >
        <SubHeader className="mb-auto" title="Smith Dental - Mile End" />
        <div className="d-flex w-100 mb-auto">
          <div className="corporate-invoice__inner mb-auto mx-auto invoice-view">
            <div className="invoice-box">
              <div className="invoice-left">
                <div className="invoice-head">
                  <img src={BrandLogo} className="invoice-logo" />
                  <p className="invoice-date">{moment(invoice_View.invoice_date).format(DateDDMMMYYY)}</p>
                  <div className="invoice-number">
                    <img src={InvoiceIcon} />
                    <h4>Invoice</h4>
                    <p>#{invoice_View.reference_no}</p>
                  </div>
                </div>
                <div className="invoice-foot">
                  <ul>
                    <li>
                      <img src={IconEmail} />
                      <p>info@thalamus.com</p>
                    </li>
                    <li>
                      <img src={IconPhone} />
                      <p>1 (888) 575-7932</p>
                    </li>
                    <li>
                      <img src={IconLocationPin} />
                      <p>305 - 67 Mowat Ave.<br /> Toronto, ON, M6K 3E3</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="invoice-content">
                <div className="content-head">
                  <div className="invoice-to">Billed to</div>
                  <div className="invoice-party">
                    <h3>{invoice_View && invoice_View.billed_name}</h3>
                    <address>
                      {!!invoice_View && invoice_View.address}
                    </address>
                  </div>
                </div>
                <div className="content-body">
                  <div className="product-item-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice_View && invoice_View.items && invoice_View.items.map((invoice, index) => {

                          return <tr>
                            <td>{invoice.item_name}</td>
                            <td>{invoice.price}</td>
                            <td>{invoice.qty}</td>
                            <td>{invoice.total}</td>
                          </tr>
                        })}
                        {/* <tr>
                          <td>Monthly subscription</td>
                          <td>$199.99</td>
                          <td>1</td>
                          <td>$199.99</td>
                        </tr>
                        <tr>
                          <td>Additional devices +5</td>
                          <td>$99.99</td>
                          <td>1</td>
                          <td>$99.99</td>
                        </tr>
                        <tr>
                          <td>Additional domain +2</td>
                          <td>$24.99</td>
                          <td>2</td>
                          <td>$49.98</td>
                        </tr>
                        <tr>
                          <td>Promo code</td>
                          <td>-15%</td>
                          <td>n/a</td>
                          <td>-15%</td>
                        </tr>
                        <tr>
                          <td>HST</td>
                          <td>13%</td>
                          <td>n/a</td>
                          <td>13%</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                  <div className="product-detail-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Other information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company Registration No.:</td>
                          <td>123456</td>
                        </tr>
                        <tr>
                          <td>Sales Tax Registration No.:</td>
                          <td>1234567892</td>
                        </tr>
                        <tr>
                          <td>Contract/PO:</td>
                          <td>1234567881</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="product-total-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th colSpan="2">Grand Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>USD</td>
                          <td>$336.14</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="corporate-invoice__suggestion d-flex flex-column align-items-flex-start justify-content-center">
            <h4>Receive monthly statements on</h4>
            <div className="form-group mt-4">
              <div className="custom_check">
                <input type="checkbox" name="receive_statements_reg" id="receive_statements_reg" checked />
                <label for="receive_statements_reg">Registered email</label>
              </div>
              <input value={this.state.email} disabled type="email" className="form-control" placeholder="Enter email here" />
            </div>
            <div className="divider"></div>
            <div className="form-group">
              <div className="custom_check">
                <input type="checkbox" onChange={() => { this.setState({ statementEmailAllow: this.state.statementEmailAllow === 0 ? 1 : 0 }) }} checked={this.state.statementEmailAllow === 0 ? false : true} name="receive_statements_other" id="receive_statements_other" />
                <label for="receive_statements_other">Another email</label>
              </div>
              <input value={this.state.statementEmail} onChange={(e) => { this.setState({ statementEmail: e.target.value }) }} type="email" className="form-control" placeholder="Enter email here" />
            </div>
            <Button size="md" color="primary" onClick={() => { this.updateEmailDetail() }}>Save Changes</Button>
          </div>
        </div>
      </div>
    )
  }

}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  invoice_View: state.task.invoiceView,
  getInvoiceEmails: state.task.getInvoiceEmails,
  invoiceMailUpdated: state.task.invoiceMailUpdated
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(HomeIndex);
