import React from 'react';
import './style.scss';

class Loading extends React.Component {
  
  render (){
    return(
      <div className="loader d-flex flex-column h-100 align-items-center justify-content-center">
        <div className="spinner-border text-primary my-auto" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) 
  }
}
export default Loading;
