import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import './style.scss';
import {Collapse, Button} from 'reactstrap';

// class HomeIndex extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//         isSigninClick:true,
//         isSignin:false
//     }
//     this.onSigninClick = this.onSigninClick.bind(this);
//   }
  
//   onSigninClick(){
//     this.setState({isSigninClick:false})
//   }
//   onSignin(){
//     this.setState({isSignin:true})
//   }

//   async componentDidMount(){

//   }
// }

const HomeIndex = (props) => {
    return(
      <div className="main__screen referral__page referral__subscribe d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Free Month Referral"/>
            <div className="referral__inner referral__subscribe__inner d-flex flex-column  mb-auto">
                <div className="referral__inner__header">
                  <h4>Refer a dentist and both of you are eligible for a FREE month of service!</h4>
                  <p>Enter the information of the dentist you would like to refer.</p>
                </div>
                <div className="referral__inner__body flex-grow-1">
                  <div className="referral__empty flex-column  h-100 d-flex align-items-center justify-content-center">
                    <h4>Available to subscribed accounts only.</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vehicula ipsum ac nisi dignissim, at vulputate diam auctor. Aenean at ultrices quam. Mauris ut suscipit arcu. Phasellus tempus est ac nunc convallis facilisis. Nunc rutrum gravida mi, at ultricies arcu maximus sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div className="referral__empty__btn">
                      <Button size="md" color="primary">Subscribe for full access to Thalamus</Button>
                    </div>
                  </div>
                </div>
            </div>
      </div>
    ) 
  }
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
