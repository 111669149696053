/** @format */

import React from "react";
import { Button, Label, FormGroup, Input } from "reactstrap";
import PropTypes from "prop-types";
import ReactCodeInput from "react-code-input";
import PhoneInput from "react-phone-input-2";
import LoadingButton from "../LoadingButton";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { InputField } from "../../components/InputField";
import {
  GetPhoneVerification,
  VerifyOtp,
  VerifyOtpRequest,
  UpdateAuthorizedUser,
  cleardata,
} from "../../store/actions/taskAction";
import { isRequired, isValidEmail } from "../../data/comman";
import {ReactComponent as TickCorcle} from '../../img/teenyicons_tick-circle-solid.svg';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnableVerify: false,
      minutes: 5,
      seconds: 0,
      isGetCode: false,
      verifyOption: "sms",
    };

    this.resetdata = this.resetdata.bind(this);
    this.onChnageField = this.onChnageField.bind(this);
  }

  async onClickVerifyOtp(otp) {
    this.onVerifyOtp("phoneVerifyed", "");
    this.onVerifyOtp("isLoading", true);
    var params = {
      request_secrete: this.props.phoneVerificationInfo.request_secrete,
      otp: otp,
    };

    if (this.state.verifyOption && this.state.verifyOption === "email") {
      params["email"] = this.props.practiceUserAccountInfo.email;
      params["media"] = "email";
    } else {
      params["phone"] = this.props.practiceUserAccountInfo.phone;
      params["media"] = "sms";
    }

    await this.props.dispatch(VerifyOtp(params));

    let updatePhone = {
      id: this.props.practiceUserAccountInfo.id,
      request_secrete: this.props.phoneVerificationInfo.request_secrete,
      otp: otp,
      gender: this.props.practiceUserAccountInfo?.gender?.id || 0,
      otp_media: this.props.phoneVerificationInfo.email ? "email" : "sms",
    };

    if (this.state.verifyOption && this.state.verifyOption === "email") {
      updatePhone["email"] = this.props.practiceUserAccountInfo.email;
    } else {
      updatePhone["phone"] = this.props.practiceUserAccountInfo.phone;
    }

    if (this.props.isNewProfile) {
      console.log("geasasasdsadd new");
    }

    if (this.props.isEditProfile || this.props.isFromAuthorized) {
      await this.props.dispatch(UpdateAuthorizedUser(updatePhone));

      if (
        this.props.phoneVerificationInfo.phoneVerifyed &&
        !this.props.isEditProfile
      ) {
        await this.props.verifyedPhoneOrEmail();
      }
    }

    if (
      this.props.phoneVerificationInfo.phoneVerifyed === true &&
      this.props.onSubmitValidateForm
    ) {
      this.props.onSubmitValidateForm();
    }

    this.resetdata();
  }

  async componentDidMount() {
    if (!this.props.isClear) {
      await this.props.dispatch(cleardata());
    }
    let phoneVerificationInfo = this.props.phoneVerificationInfo;
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo;

    if (
      practiceUserAccountInfo.is_verified_email == 1 &&
      !this.props.isFromAuthorized &&
      !this.props.isEditProfile
    ) {
      this.setState({ verifyOption: "email" });
    }

    if (this.props.isFromAuthorized) {
      this.setState({ verifyOption: this.props.otp_media });
    }
    this.setState({ isEnableVerify: false });
    if (this.props.isNewProfile && !this.props.isBack) {
      this.props.dispatch(cleardata());
      this.onVerifyOtp("email", "");
      this.onVerifyOtp("phone", "");
    }
  }

  async resetdata() {
    let phoneVerificationInfo = {};
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo;

    phoneVerificationInfo["isVerify"] = false;
    phoneVerificationInfo["isGetVerifyCode"] = true;
    phoneVerificationInfo["phoneVerifyed"] = false;
  }

  changeNumber() {
    this.onVerifyOtp("isVerify", false);
    // this.onVerifyOtp('phone' , '');

    let phoneVerificationInfo = {};
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo;

    phoneVerificationInfo["isVerify"] = false;
    phoneVerificationInfo["isGetVerifyCode"] = true;
    phoneVerificationInfo["phoneVerifyed"] = false;

    this.props.dispatch(VerifyOtpRequest(phoneVerificationInfo));
    if (this.state.verifyOption && this.state.verifyOption === "email") {
      practiceUserAccountInfo["is_verified_email"] = 0;
    } 
    if (this.state.verifyOption && this.state.verifyOption === "sms") {
      practiceUserAccountInfo["is_verified_mobile"] = 0;
    }
  }

  async onVerifyOtp(field, value) {
    this.setState({ isError: "" });
    let phoneVerificationInfo = this.props.phoneVerificationInfo;
    let practiceUserAccountInfo = this.props.practiceUserAccountInfo;

    if (field === "phone") {
      if (value.length >= 12) {
        phoneVerificationInfo["isGetVerifyCode"] = true;
      } else {
        phoneVerificationInfo["isGetVerifyCode"] = false;
      }

      phoneVerificationInfo["phoneVerifyed"] = false;
      practiceUserAccountInfo[field] = value.replace(/\s+/g, "");
      practiceUserAccountInfo["is_verified_mobile"] = 0;
    } else if (field === "email") {
      console.log(field, "field");
      phoneVerificationInfo["isGetVerifyCode"] = true;
      phoneVerificationInfo["phoneVerifyed"] = false;
      practiceUserAccountInfo[field] = value;
      practiceUserAccountInfo["is_verified_email"] = 0;
    } else if (field === "verificationCode") {
      phoneVerificationInfo["phoneVerifyed"] = "";
      // practiceUserAccountInfo["is_verified_email"] = 0;
      // practiceUserAccountInfo["is_verified_mobile"] = 0;
      phoneVerificationInfo[field] = value;
    } else {
      phoneVerificationInfo[field] = value;
    }

    await this.props.dispatch(VerifyOtpRequest(phoneVerificationInfo));
    await this.isValid();
  }

  async isValid() {
    if (this.props.validateForm) {
      this.props.validateForm();
    }
    if (
      this.props.phoneVerificationInfo.verificationCode &&
      this.props.phoneVerificationInfo.verificationCode.length == 4
    ) {
      this.setState({ isEnableVerify: true });
    } else {
      this.setState({ isEnableVerify: false });
    }
  }

  async onClickVerifyPhone(resend) {
    if (!resend) {
      this.changeNumber();
    }
    if (
      this.state.verifyOption !== "email" &&
      ((this.props.practiceUserAccountInfo &&
        this.props.practiceUserAccountInfo.phone == null) ||
        this.props.practiceUserAccountInfo.phone.length < 11)
    ) {
      if (this.props.practiceUserAccountInfo.phone == null) {
        this.setState({ isError: "Please enter phone number" });
      } else if (this.props.practiceUserAccountInfo.phone.length < 11) {
        this.setState({ isError: "Please enter valid phone number" });
      }
      return;
    }

    if (this.state.verifyOption == "email") {
      let error = false;
      if (isRequired(this.props.practiceUserAccountInfo.email)) {
        this.setState({ isError: "Please enter email address" });
        error = true;
      } else if (isValidEmail(this.props.practiceUserAccountInfo.email)) {
        error = true;
        this.setState({ isError: "Invaild email address" });
      }

      if (error) {
        return;
      }
    }

    if (!resend) {
      await this.onVerifyOtp("isLoading", true);
    }

    this.onVerifyOtp("verificationCode", "");
    let params = {};
    if (this.state.verifyOption === "email") {
      params["email"] = this.props.practiceUserAccountInfo.email;
    } else {
      params["phone"] = this.props.practiceUserAccountInfo.phone;
    }
    await this.props.dispatch(GetPhoneVerification(params));
    clearInterval(this.myInterval);
    await this.setState({ seconds: 59 });
    await this.resend();
  }

  resend() {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
          isGetCode: false,
        }));
      } else {
        this.setState({ isGetCode: true });
      }
      if (this.seconds === 0) {
        clearInterval(this.myInterval);
      }
    }, 1000);
  }

  onChnageField(value) {
    this.setState({ verifyOption: value, isError: "" });
    // this.props.dispatch(cleardata());
  }

  render() {
    const {
      values,
      practiceUserAccountInfo,
      phoneVerificationInfo,
      isEditProfile,
    } = this.props;
    return (
      <React.Fragment>
        {phoneVerificationInfo.isVerify ? (
          <FormGroup
            className={`${
              phoneVerificationInfo.phoneVerifyed === false ? "notverifyed" : ""
            } mw-350 w-100 mx-auto d-flex flex-column justify-content-around`}
          >
            <h5
              className=""
              style={{ fontSize: "1.6rem", marginBottom: "24px" }}
            >
              Verification code
            </h5>
            {this.props.isError && (
              <div className="text--danger">
                {this.state.verifyOption && this.state.verifyOption === "email"
                  ? this.props.isError + " Email"
                  : this.props.isError + " Phone number"}
              </div>
            )}
            <ReactCodeInput
              width="100%"
              value={phoneVerificationInfo.verificationCode}
              type="number"
              onChange={(options) =>
                this.onVerifyOtp("verificationCode", options)
              }
              fieldWidth={65}
              fieldHeight={80}
              fields={4}
              className={`mb-0`}
            />
            {!phoneVerificationInfo.phoneVerifyed && (
              <span className="my-4 py-2 d-block">
                Didn't receive code?{" "}
                <Button
                  onClick={() => this.onClickVerifyPhone(true)}
                  disabled={!this.state.isGetCode}
                  color="link primary"
                  className="h-auto p-0"
                >
                  Resend in {`(${this.state.seconds})` + "s"}
                </Button>
              </span>
            )}
            <LoadingButton
              type="button"
              loading={phoneVerificationInfo.isLoading}
              label={
                phoneVerificationInfo.phoneVerifyed === false ? (
                  <>Incorrect verification code</>
                ) : (
                  <>
                    {" "}
                    {phoneVerificationInfo.phoneVerifyed === true ? (
                      <>
                        {this.state.verifyOption == "email"
                          ? "Email"
                          : "Phone number"}{" "}
                        verified
                      </>
                    ) : (
                      <>
                        Verify{" "}
                        {/* {this.state.verifyOption == "email"
                            ? "email"
                            : "phone number"}{" "} */}
                      </>
                    )}{" "}
                  </>
                )
              }
              disabled={
                !this.state.isEnableVerify ||
                phoneVerificationInfo.isLoading ||
                phoneVerificationInfo.phoneVerifyed
              }
              size="xl"
              className={`${
                phoneVerificationInfo.phoneVerifyed === true ? "verifyed" : ""
              } ${
                phoneVerificationInfo.phoneVerifyed === false
                  ? "notverifyed"
                  : ""
              } w-100`}
              onClick={() =>
                this.onClickVerifyOtp(phoneVerificationInfo.verificationCode)
              }
              color="primary"
            />
            <LoadingButton
              type="button"
              label={
                this.state.verifyOption !== "email"
                  ? "CHANGE PHONE NUMBER"
                  : "CHANGE EMAIL"
              }
              size="xl"
              className={`mt-4 w-100`}
              onClick={() => this.changeNumber()}
              color="primary"
            />
          </FormGroup>
        ) : (
          <div className="mw-350 mx-auto w-100">
            <FormGroup className="mb-2">
              <label className="m-0">Phone Number or Email Verification</label>
            </FormGroup>
            <div className="d-flex my-4 py-3 align-items-center">
              <div className="custom-checkbox custom-control mr-5 d-flex align-itmems-center">
                <input
                  type="radio"
                  name={`plan_list`}
                  disabled={phoneVerificationInfo.isLoading}
                  checked={this.state.verifyOption === "sms" ? true : false}
                  onChange={(e) => this.onChnageField("sms")}
                  className="custom-control-input"
                  id={`plan_${1}`}
                />
                <label
                  className="custom-control-label pl-5"
                  htmlFor={`plan_${1}`}
                >
                  Phone number
                </label>
              </div>
              <div className=" custom-checkbox custom-control d-flex align-itmems-center">
                <input
                  type="radio"
                  name={`plan_list`}
                  disabled={phoneVerificationInfo.isLoading}
                  checked={this.state.verifyOption === "email" ? true : false}
                  onChange={(e) => this.onChnageField("email")}
                  className="custom-control-input"
                  id={`plan_${2}`}
                />
                <label
                  className="custom-control-label pl-5"
                  htmlFor={`plan_${2}`}
                >
                  Email
                </label>
              </div>
            </div>
            <FormGroup className="mt-4 mobile-veryfied">
              {this.state.verifyOption &&
              this.state.verifyOption === "email" ? (
                <div
                  className={`mb-3 ${
                    practiceUserAccountInfo.is_verified_email === 1 &&
                    "verifyed"
                  }`}
                >
                  
                  <InputField
                    type="text"
                    name="email"
                    value={practiceUserAccountInfo.email || ""}
                    onChange={(e) => this.onVerifyOtp("email", e.target.value)}
                    className=""
                  />
                  
                    {practiceUserAccountInfo.is_verified_email === 1 && (
                      <div className="d-flex justify-content-between mt-4 ">
                      <span className="text-success fs-20 font-weight-bold">Email Verified Successfully!</span>
                      </div>
                    )}
                  
                </div>
              ) : (
                <div
                  className={`mb-3 ${
                    practiceUserAccountInfo?.phone &&
                    practiceUserAccountInfo.is_verified_mobile === 1 &&
                    "verifyed"
                  }`}
                >
                  <PhoneInput
                    defaultMask={"-..........."}
                    alwaysDefaultMask={true}
                    countryCodeEditable={false}
                    inputProps={{ name: "phone", required: true }}
                    country={
                      this.props.practiceProfileInfo?.country?.code?.toLowerCase() ||
                      "ca"
                    }
                    onChange={(value, country, e, formattedValue) => {
                      this.onVerifyOtp("phone", formattedValue);
                    }}
                    value={practiceUserAccountInfo.phone || ""}
                  />
                  {this.state.verifyOption !== "email" && (
                    <>
                      {practiceUserAccountInfo.is_verified_mobile === 1 &&
                        practiceUserAccountInfo?.phone && (
                          <div className="d-flex justify-content-between mt-4">
                            <span className="text-success fs-20 font-weight-bold">
                              Phone number Verified Successfully!
                            </span>
                          </div>
                        )}
                    </>
                  )}
                </div>
              )}
              <>
                {this.props.isError == "" && this.state.isError && (
                  <span className="text--danger">{this.state.isError}</span>
                )}
                {this.props.isError && practiceUserAccountInfo.is_verified_mobile == 0 &&
        practiceUserAccountInfo.is_verified_email == 0 && (
                  <span className="text--danger">
                    {this.state.verifyOption &&
                    this.state.verifyOption === "email"
                      ? this.props.isError + " Email"
                      : this.props.isError + " Phone number"}
                  </span>
                )}
                {this.props.error && this.props.error.phone && (
                  <span className="text--danger">
                    {this.props.error.phone[0]}
                  </span>
                )}
              </>

              <div
                className={`${
                  practiceUserAccountInfo.is_verified_mobile === 1 &&
                  this.state.verifyOption === "sms"
                    ? "d-none"
                    : practiceUserAccountInfo.is_verified_email === 1 &&
                      this.state.verifyOption === "email"
                    ? "d-none"
                    : ""
                }`}
              >
                <LoadingButton
                  type="button"
                  disabled={phoneVerificationInfo.isLoading}
                  loading={phoneVerificationInfo.isLoading}
                  label={this.props.buttonLabel || `Get code/Verify`}
                  onClick={() => this.onClickVerifyPhone()}
                  size="xl"
                  className="mt-2 w-100"
                  color="primary"
                />
              </div>
            </FormGroup>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Index.defaultProps = {
  type: "text",
  className: "",
  placeholder: "",
};
Index.propTypes = {
  name: PropTypes.string.isRequired,
  // type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "text",
    "number",
    "password",
    "email",
    "textarea",
    "time",
  ]),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loadingOwnerVerifyPhone: state.task.loadingOwnerVerifyPhone,
  phoneVerifyed: state.task.phoneVerifyed,
  practiceUserAccountInfo: state.task.practiceUserAccountInfo,
  phoneVerificationInfo: state.task.phoneVerificationInfo,
  error: state.task.error,
  practiceInfo: state.task.practiceInfo,
  practiceProfileInfo: state.task.practiceProfileInfo,
});
export default compose(
  withTranslation("common"),
  connect(mapStateToProps)
)(Index);
