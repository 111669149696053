import React, { forwardRef } from "react";
import {
  Grid,
  Typography,
  Button,
  Slider,
  Tooltip,
  
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  PlayArrow,
  VolumeUp,
  Fullscreen,
  ClosedCaption,
  FullscreenExit,
  Maximize,
  Close,
  Pause,
  SkipNext,
  SkipPrevious,
  VolumeOff,
} from "@material-ui/icons";

const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
  },
  controlsSwapper: {
    // position: "absolute",
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // background: "rgba(0,0,0,0.6)",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // zIndex: 1,
    // height:'287px'
  },
  controlIcons: {
    color: "#fff",
    fontSize: 20,
    "&:hover": {
      color: "#fff",
    },
  },

  bottomIcons: {
    color: "#fff",
    fontSize: 30,
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 100,
    
  },
  processSlider: {
    width: 250,
  },
  tabsClass: {
    background:'linear-gradient(#473c39, #3c3533)',
    color:'#8d8885',
    //backgroundColor: "#443b37",
    textAlign: "center",
    height: "30px",
    paddingTop:'7px'
  },
  active: {
    background:'linear-gradient(#b3afae, #b1aead)',
    color:'#494948'
  },
  verticalSlider:{
    height:'175px'
  }
});
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  //const valu1 = secondsToHms(value);

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={{ fontSize: "12px" }}
    >
      {children}
    </Tooltip>
  );
}

function VolumeSliderComponent(props){
  const {children,open,value} = props;
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={{ fontSize: "12px" }}
    >
      {children}
    </Tooltip>
  );
}

const PrettoSlider = withStyles({
  root: {
    height: 3,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    // border: "2px solid currentColor",
    marginTop: -7,
    // marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 3,
    borderRadius: 3,
  },
  rail: {
    height: 3,
    borderRadius: 3,
  },
})(Slider);



export default forwardRef(
  (
    {
      title,
      handlePlayPause,
      playing,
      muted,
      handleMute,
      handleVolumeChange,
      handleVolumeSeekDown,
      volume,
      toggleFullScreen,
      played,
      handleSeekChange,
      handleSeekMouseDown,
      handleSeekMouseUp,
      elapsedTime,
      totalDuration,
      handleChangeDisplayFormat,
      onScreenShot,
      toggleScreen,
      handleTogglePip,
      handleClickClose,
      handleClickSubtitle
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const [value, setValue] = React.useState(0);



    const handleTabClick = (newValue) => {
      setValue(newValue);
    };

    return (
      <div className={classes.controlsSwapper} ref={ref}>
        {/* TOP CONTROLS */}

        {/* MIDDLE CONTROLS */}
        <div className="d-flex align-items-center">
            <Typography>{elapsedTime}</Typography>
          <IconButton
            onClick={handlePlayPause}
            className={classes.controlIcons}
            aria-label="reqind"
          >
            {playing === false ? (
              <PlayArrow fontSize="inherit" />
            ) : (
              <Pause fontSize="inherit" />
            )}
          </IconButton>
          <PrettoSlider
              min={0}
              max={100}
              value={played * 100}
              ValueLabelComponent={(props) => (
                <ValueLabelComponent {...props} value={elapsedTime} />
              )}
              onChange={handleSeekChange}
              onMouseDown={handleSeekMouseDown}
              onChangeCommitted={handleSeekMouseUp}
            />
            <IconButton onClick={handleMute} className={classes.bottomIcons}  >
                    {muted === false ? <VolumeUp /> : <VolumeOff />}
            </IconButton>
            <Typography className="">{totalDuration}</Typography>
        </div>
       
        {/* BOTTOM CONTROLS */}

        <Grid
          container
          direaction="row"
          alignItems="center"
          // justify="space-between"
          style={{ padding: 16 }}
        >
         
          <Grid item lg={2}>
            {/* <IconButton className={classes.bottomIcons}>
              <SkipPrevious fontSize="inherit" />
            </IconButton> */}

            {/* <IconButton
              onClick={handlePlayPause}
              className={classes.bottomIcons}
            >
              {playing === false ? (
                <PlayArrow fontSize="inherit" />
              ) : (
                <Pause fontSize="inherit" />
              )}
            </IconButton> */}

            {/* <IconButton className={classes.bottomIcons}>
              <SkipNext fontSize="inherit" />
            </IconButton> */}
          </Grid>
          <Grid item lg={1}>
            {/* <Button
              variant="text"
              style={{ color: "#fff", marginLeft: 16 }}
              onClick={handleChangeDisplayFormat}
            >
              <Typography>{elapsedTime}</Typography>
            </Button> */}
          </Grid>
          <Grid item lg={3}>
            {/* <PrettoSlider
              min={0}
              max={100}
              value={played * 100}
              ValueLabelComponent={(props) => (
                <ValueLabelComponent {...props} value={elapsedTime} />
              )}
              onChange={handleSeekChange}
              onMouseDown={handleSeekMouseDown}
              onChangeCommitted={handleSeekMouseUp}
            /> */}
          </Grid>
          <Grid item lg={1}>
            {/* <Button variant="text" style={{ color: "#fff", marginLeft: 16 }}>
              <Typography>{totalDuration}</Typography>
            </Button> */}
          </Grid>
          <Grid item lg={3}>
            {/* <IconButton className={classes.bottomIcons} onClick={handleClickSubtitle}>
              <ClosedCaption fontSize="inherit" />
            </IconButton> */}
            {/* <IconButton onClick={handleMute} className={classes.bottomIcons}  >
                       {muted === false ? <VolumeUp /> : <VolumeOff />}
            </IconButton> */}
            {/* <div className={classes.volumeSlider}>
                <Slider
                 orientation='vertical'
                defaultValue={50}
                 aria-labelledby="vertical-slider"
                /> 
            </div> */}
              {/* <Slider
              min={0}
              max={100}
              value={volume * 100}
              onChange={handleVolumeChange}
              onChangeCommitted={handleVolumeSeekDown}
              className={classes.volumeSlider}
              ValueLabelComponent={(props) => (
                <VolumeSliderComponent {...props} value={elapsedTime} />
              )}
            /> */}
            {/* <IconButton
              onClick={toggleFullScreen}
              className={classes.bottomIcons}
            >
              {toggleScreen === false ? <Fullscreen /> : <FullscreenExit />}
            </IconButton> */}
          </Grid>
          {/* <Grid item lg={2}>
            <Button onClick={onScreenShot} style={{ backgroundColor: "white" }}>
              Take screenshot
            </Button>
          </Grid> */}
        </Grid>
      </div>
    );
  }
);
