import React, { useState } from "react";
import "./dropbox.scss";
import { Col, Row } from "reactstrap";
import DragDropSlider from "./DragDropSlider";

const DropBox = ({ lists }) => {
  const [list, setList] = useState([]);
 

  const onDragStart = (ev, id) => {
    console.log("dragstart:", id);
    ev.dataTransfer.setData("id", id);
  };
  console.log(list);

 
  const active =
    list.length > 0 ? (
      <>
      <div style={{textAlign:"end"}}>
        <p>Total : 7m 08s</p>
        </div>
      <div className="dropbox__main__screen"> 
      
         {list.length>6 ? <DragDropSlider list={list} />
       :
        <Row>
         {list.map((video, index) => {
           return (
             <Col
               lg={2}
               key={index}
               onDragStart={(e) => onDragStart(e, video.id)}
               draggable={true}
             >
               <video height="120px" controls>
                 <source src={video.video}></source>
               </video>
             </Col>
           );
         })}
       </Row>  
     }
       </div> 
        
      </>
    ) : (
      <>
      <div className="dropbox__main__screen">
        <Row>
          <Col lg={11}>
            <div className="dropbox__main__label">
              <p>
                This is your Timeline | Drag and drop your content selection
                here
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <p>
                You can also drag any video within the timeline to rearrange the
                sequence in which they are played.
              </p>
            </div>
          </Col>
          <Col lg={1}>
            <div className="dropbox__main__dropimage">
              <img
                src="assets/img/dragdropicon/dragdrop.png"
                alt=""
                height="50px"
                width="50px"
              />
            </div>
          </Col>
        </Row>
        </div>
      </>
    );

  const onDragOver = (ev) => {
    ev.preventDefault();
  };

  const onDrop = (ev, cat) => {
    console.log(cat);
    let id = ev.dataTransfer.getData("id");
    console.log(id);
    let tasks = lists.filter((task) => {
      if (task.id === id) {
        task.category = cat;
      }
      return task;
    });
    console.log(tasks)
       
   const result =  tasks.filter(task => task.category  === "complete");
   console.log(result);
    setList(result)
    
  };

  return (
    <div>
      <div
        className=""
        draggable
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => onDrop(e, "complete")}
      >
          {active} 
        </div>
    </div>
  );
};
export default DropBox;
