import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button , NavLink } from 'reactstrap';

import { history } from '../index';
import {  getAccessCode  , GetPracticeMoreInfo , resetAccessCode , resetAccessCodeRequest , cleardata} from "../../store/actions/taskAction";
import LoadingButton from '../../components/LoadingButton'
import { SIGNIN} from '../../utilities/HttpUtility'

import './style.scss';

class AccessCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isContinue:false,
      isGenerate:false
    }
  }

  async componentDidMount(){
    await this.props.dispatch(getAccessCode());
   }
 
  onGenerateCode(){
    this.onChange('isContinue' , true)
  }



 async onBackToSignin(){
    // this.props.dispatch(cleardata());
    await this.onChange('isdisabled' , false)
    await this.onChange('isContinue' , false)
    await this.onChange('isGenerated' , false)
    await history.push("/profileselection")
  }

  async onResetAccessCode(){
    await this.onChange('isLoading' , true)
    await this.onChange('isGenerated' , true)
    await this.props.dispatch(resetAccessCode(this.props.practiceUserLoginInfo));
  }

  async onChange(field , value){
    let resetAccessCode = this.props.resetAccessCode
    resetAccessCode[field] = value
    await this.props.dispatch(resetAccessCodeRequest(resetAccessCode))
  }

  render (){
    const {t , getAccessCode , resetAccessCode} = this.props

    return(
        <div className="accesscode__main__screen  d-flex flex-column h-100 align-items-center ">
            <SubHeader className="mb-auto" title="Access Code"/>
            <div className="accesscode__inner__screen mb-auto">
                
                <>
                  {resetAccessCode.isGenerated ?
                    <div className="d-flex flex-column accesscode__inner__screen2 text-center accesscode__inner__text text-center">
                        <div className="access-code-box  green-border">
                          <p className="heading">Your new access code is</p>
                          <h1 className="mb-5">{resetAccessCode && resetAccessCode.access_code}</h1>
                          <p className="message">Take note of your new code now. Your instances will be terminated soon, and you’ll need the new access code to be able to log in to Thalamus in all of your devices again.</p>
                          <div className="access-code-footer">
                            {/* <Button  onClick={() => this.onBackToSignin()}  color="primary" className="w-100"></Button> */}
                            <NavLink className="btn btn-primary mt-2" href={SIGNIN}>Back to sign in page</NavLink>
                          </div>
                        </div>
                    </div>
                  :
                    <div className="d-flex flex-column text-center accesscode__inner__screen2 accesscode__inner__text text-center">
                        <div className="access-code-box">
                          <p className="heading">Access code in use</p>
                          <h1 className="mb-5">{getAccessCode && getAccessCode.access_code}</h1>
                          <p className="message">By generating a new access code, all your Thalamus instances will be terminated, and you’ll have to sign in once more in each device. Access codes should be changed in case you suspect that the security of your code has been compromised. Would you like to proceed?</p>
                          <div className="access-code-footer">
                            <LoadingButton type="button" size="sm" loading={resetAccessCode.isLoading} label={`Yes, generate it`} className={`mr-3`} onClick={() => this.onResetAccessCode()} color="primary" />
                            <Button size="sm" color="danger" className="ml-3" onClick={(e)=> history.push("/access-code")}>Cancel</Button>
                          </div>
                        </div>
                    </div>
                  }
                </>
            </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  getAccessCode:state.task.getAccessCode,
  resetAccessCode:state.task.resetAccessCode,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AccessCode);
