import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import ProfileImgBox from './ProfileImgBox'
import { Button } from 'reactstrap';
import {ReactComponent as ArrowLeft} from '../../../img/arrow_left.svg';
import { history } from '../..';
import { setGoogleTvTestDriveMenuItem } from '../../../store/actions/taskAction';


const getUserType = (id) =>{
  switch(id){
      case 0: 
          return  ["guest", "Guest"];
      case 1:
          return  ["dentist", "Normal User"];
      case 2:
          return  ['authorized_user', "Authorized User"]; 
      case 3:
          return    ['manager', "Manager"]; 
      case 4:
          return    ['owner', "Owner"]; 
      default:
        return    ['guest', "Guest"];
  }
}


const Profile = () => {
  const state = useSelector(state => state.task);
  const userType = getUserType(state.practiceUserLoginInfo.type )
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGoogleTvTestDriveMenuItem(1));

  }, []); 
  
  return (
    <div className={`${styles.goolge_tv_setting__main__screen} d-flex flex-column  justify-content-center h-100`}>
        <Button  type="button"  onClick={() => history.goBack()} style={{fontSize:'2rem'}} color="link" className="py-1 m-5 pl-0 d-flex align-items-center mb-auto"><ArrowLeft className="mr-4"/><span>Back</span></Button>
        <div className={`${styles.goolge_tv_setting__inner__screen}  py-5 my-5`}>
            <div className='px-5'>
              <h2 className='mb-5'>Settings</h2>
            </div>
            <div className='d-flex justify-content-between'>
              <div className={`${styles.goolge_tv_setting__img__section} px-5`}>
                  <ProfileImgBox />
              </div>
              <div className={`${styles.goolge_tv_setting__details} px-5 w-100`}>
                
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex align-items-center justify-content-between`}>
                    <label className=''>System language</label><div className=''>English</div>
                </div>
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Media language</label><div className=''>English (US)</div>
                </div>
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Closed captions font size</label><div className=''>Medium</div>
                </div>
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Clear cache</label><div className=''></div>
                </div>
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Theme header</label><div className=''>Show</div>
                </div>
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Theme footer</label><div className=''>Time & Weather</div>
                </div>
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex align-items-center justify-content-between`}>
                    <label className=''>Screen Saver</label><div className=''></div>
                </div>
                <div className={`${styles.goolge_tv_setting__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Sign out</label><div className=''></div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Profile
