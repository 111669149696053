import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Button, Spinner } from 'reactstrap';
import PreviewModal from '../../../components/PreviewModal';
import { formatSecond, GOOGLE_TV_TEST_DRIVE_RECENTPLAY } from '../../../data/comman';
import { OPERATORY_CHANNEL_PREVIEW_URL, WAITINGROOM_CHANNEL_PREVIEW_URL } from '../../../utilities/HttpUtility';
import styles from './style.module.scss';
import './videoDetail.scss';
import {ReactComponent as IcPlay} from '../images/ic_play.svg';
import {ReactComponent as IcCC} from '../images/ic_cc.svg';
import {ReactComponent as IcMyList} from '../images/ic_my_list.svg';
import {ReactComponent as IcMyListAdded} from '../images/ic_my_list_selected.svg';
import {ReactComponent as IcCCSelected} from '../images/ic_cc_selected.svg';
import {ReactComponent as IcClose} from '../images/ic_close.svg';
import {ReactComponent as ArrowLeft} from '../../../img/arrow_left.svg';

import { deleteFromMyListChairsideEducation, getChairsidePlayVideo, HideCloseCaptionChairsideEducation, postAddToMyListChairsideEducation, ShowCloseCaptionChairsideEducation, updateChairsidePlaylist } from '../../../store/actions/taskAction';
import Player from './Player';
import Loading from '../../../components/Loading';
import { history } from '../..';
import PreviewPlayListModal from '../components/PreviewPlayListModal';
import SwiperCore, { Navigation, Pagination, Scrollbar,Mousewheel,A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination, Scrollbar,Mousewheel, A11y]);

const VideoDetail = (props) => {
  const states = useSelector((state) => state.task);
  const dispatch = useDispatch();

  const [channelDetail, setChannelDetail] = useState({});
  const [isOperatoryRoom, setisOperatoryRoom] = useState(false);
  const [isOpenmodal, setisOpenmodal] = useState(false);
  const [isInMyList, setToMyList] = useState(false);
  const [modal, setState] = useState(false);
  const [allChannels, setAllChannels] = useState(states.waitingRoomMediaChannels);
  var [duration, setDuration] = useState(0);
var timelineDetail = []

  useEffect(() => {
    if(props.location?.state?.isPlaylists){
      gerChairsidePlaylist()
      
    }else{
      gerChairsideVideo()
    }
      console.log(props,'props.location?.state?.channel')
  }, []);

  useEffect(() => {
    if(!props.location?.state?.isPlaylists){
    var selectedChannel;
      selectedChannel = states.chairSideMediaState.find(x => x.chairside_media_id == props.location?.state?.channel?.chairside_media_id);

      if(selectedChannel?.cc_status != channelDetail?.cc_status || selectedChannel?.in_mylist != channelDetail?.in_mylist){
        selectedChannel = {...selectedChannel?.chairside_media, ...selectedChannel?.chairside_media.media_language}
        setChannelDetail(selectedChannel);
      }
    }

  }, [states.chairSideMediaState]);

  useEffect(() => {
    if(props.location?.state?.isPlaylists){
    var selectedChannel;
      selectedChannel = states.chairsidePlaylistsData.find(x => x.id == props.location?.state?.channel?.id);


      if(selectedChannel?.cc_status != channelDetail?.cc_status){
        setChannelDetail(selectedChannel);
      }
    }

  }, [states.chairsidePlaylist]);

  

  const gerChairsideVideo = async () =>{

    var {duration } = props.location?.state?.channel?.chairside_media?.media_language?.media
    var VideoDetail = {...props.location?.state?.channel?.chairside_media, ...props.location?.state?.channel?.chairside_media?.media_language}
    setChannelDetail(VideoDetail)

    if(props.location?.state?.channel?.chairside_media?.in_mylist == 1){
        setToMyList(true)
    }

    setDuration(formatSecond(duration))

  }

  const gerChairsidePlaylist = async () =>{

    // var duration = props.location?.state?.itemDuration

    props.location?.state?.channel && props.location?.state?.channel?.timeline.map(x => {
      duration += x.media_data.duration;
    });

    var VideoDetail = {...props.location?.state?.channel}
    setChannelDetail(VideoDetail)

    if(props.location?.state?.channel?.chairside_media?.in_mylist == 1){
        setToMyList(true)
    }

    await dispatch(getChairsidePlayVideo(props.location?.state?.channel?.id))

    setDuration(formatSecond(duration))

  }

  	// Remove from My List
	const removeFromMyList = () => {
		dispatch(deleteFromMyListChairsideEducation(channelDetail.chairside_media_id));
	}


    const addToList = async () =>{
        let params ={
            media_id:channelDetail.chairside_media_id
        }
        dispatch(
            postAddToMyListChairsideEducation(params)
        );
    }

   const onPlayChannel = (channel) =>{
    var recentPlayLocal = localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY);
    
    let ChannelId = channel.chairside_media_id
		if(recentPlayLocal != null){
			var recentPlay = JSON.parse(localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY));
			var index = recentPlay.findIndex(x => x == ChannelId);
			if(index > -1){
				recentPlay.splice(index, 1);
			}
			recentPlay.unshift(ChannelId);
			localStorage.setItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY, JSON.stringify(recentPlay));
		}else{
			var recentPlay = [];
			recentPlay.unshift(ChannelId)
			localStorage.setItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY, JSON.stringify(recentPlay));
		}
    
    setisOpenmodal(true)
   }

   const onClickClose = (close) => {
    setisOpenmodal(false)
	};

    //Show CC
    const showCC =(video)=>{
      console.log(props.location?.state?.isPlaylists,'props.location?.state?.isPlaylists')  
      if(props.location?.state?.isPlaylists){
          let id = channelDetail.id;
          let params={
            cc_status: channelDetail.cc_status == 0 ? "1" : "0"
          }
          dispatch(updateChairsidePlaylist(id, params) );

        }else{
          let params ={
            media_id:channelDetail.chairside_media_id
          }
		      dispatch(ShowCloseCaptionChairsideEducation(params));
        }
       
    }

    //Hide CC
    const hideCC =(video)=>{
      if(props.location?.state?.isPlaylists){
        let id = channelDetail.id;
        let params={
          cc_status: channelDetail.cc_status == 0 ? "1" : "0"
        }
        dispatch(updateChairsidePlaylist(id, params) );

      }else{
        dispatch(HideCloseCaptionChairsideEducation(channelDetail.chairside_media_id));
      }
    }

    timelineDetail = states.chairsidePlayVideo?.content


  return (
    <>
    {(states.buttonLoading3) || (states.buttonLoading4) ? <div className={`goolge_tv__loader`}><Loading /></div>  : ''}
    <div className={`${styles.goolge_tv_video_detail__main__screen} d-flex flex-column justify-content-center h-100 goolge_tv_video_detail__main__screen`}  style={{backgroundImage:`url(${channelDetail.feature_image}) `}}>
      <Button  type="button"  onClick={() => history.goBack()} style={{fontSize:'2rem'}} color="link" className="py-1 m-5 pl-0 d-flex align-items-center mb-auto"><ArrowLeft className="mr-4"/><span>Back</span></Button>
      <div className={`${styles.goolge_tv_video_detail__inner__screen} mb-auto`}>
        <div className={`${styles.goolge_tv_video_detail__text}`}>
          <h1>{channelDetail.title}</h1>
          <div className={`${styles.goolge_tv_video_detail__text__duration}`}>Duration: <span className='duration__text'>{duration}</span> {props.location?.state?.isPlaylists ? ' | ' + props.location?.state?.channel?.timeline_count + ' items' : '' }</div>
          {!props.location?.state?.isPlaylists &&
            <div className={`${styles.goolge_tv_video_detail__text__cetegory} mb-5`}>Specialization: {props.location?.state?.channel?.category?.title}</div>
          }
          <div className={`${styles.goolge_tv_video_detail__text__description} mb-5`}> <div dangerouslySetInnerHTML={{__html:  channelDetail.description}}></div></div>

          <div className={`${styles.goolge_tv_video_detail__btn_group} d-flex `}>
            <div className='text-center'>
              <div className={`channel_btn d-flex align-items-center justify-content-center mr-4 mb-1`} onClick={()=> {props.location?.state?.isPlaylists ? setState(true) : onPlayChannel(channelDetail)} }>
                  <IcPlay />
              </div>
              Play
            </div>
            {!props.location?.state?.isPlaylists &&
            <div className='text-center'>
              <div className={`channel_btn d-flex align-items-center justify-content-center mr-4 btn_mylist`} onClick={() => {channelDetail?.in_mylist == 0 ? addToList() : removeFromMyList() } }>
                { channelDetail.in_mylist == 1 ?  <><IcMyListAdded className='my_list_added' /><IcClose className='close_btn'/></> : <IcMyList />}
              </div> 
              My List
            </div>
            }
            <div className='text-center'>
              <div className={`channel_btn d-flex align-items-center justify-content-center cc_btn_google_tv  `} onClick={()=>{ channelDetail.cc_status == 0 ? showCC() : hideCC() }}>
                {channelDetail?.cc_status == 0 ? <IcCC /> :  <IcCCSelected/>}{console.log(channelDetail,'channelDetail')}
              </div>
              Captions
            </div>
          </div>
        </div>
      </div>
        {props.location?.state?.isPlaylists &&
          <div className='channel_detail_swiper_main'>
            <h4>List of videos ({timelineDetail?.length})</h4>
            <Swiper
              spaceBetween={0}
              slidesPerView={'auto'}
              keyboard={{ enabled: true }}
              // direction="horizontal"
              mousewheel
              className="channel_detail_swiper"                   
              //onSwiper={(swiper) => console.log(swiper)}
                >
                {console.log(timelineDetail,'timelineDetail')}
                {timelineDetail?.length && timelineDetail?.length > 0 && timelineDetail.map((timeLineVideo) =>
                  <SwiperSlide>
                    <div className="channel_detail_item">
                        <img src={timeLineVideo.media_data?.chairside_media?.thumb } alt={timeLineVideo?.media_data?.title} />
                        <label>{timeLineVideo?.media_data?.title }</label>
                      </div>
                    </SwiperSlide>
                  )}

            </Swiper>
          </div>
        }
      {isOpenmodal &&
      <div size="xxl" className={`${styles.goolge_tv_video_Player_Screen} ${isOpenmodal ? 'bg-block' : 'bg-none'} h-100 `} isOpen={isOpenmodal} >
      <Player
        video={channelDetail}
        title={channelDetail}
        onClickClose={onClickClose}
        className="w-100 h-100"
        // togglePip={togglePip}
        // openImgEditor={openImgEditor}
      />
      </div>
      }
      {modal&&
        <PreviewPlayListModal timelineDetail={timelineDetail} isGoogleTvTestDrive={true} isOpen={modal}  toggle={()=> setState(!modal) } />
      }
    </div>
    </>
  )
}

export default VideoDetail
