import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import NewProfileForm from './Form'
import { GetPracticeMoreInfo , clearerror , GetUserProfile, getPracticeUserAccountInfo, GetAuthorizedUser} from "../../store/actions/taskAction";

import './style.scss';

class index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isStart:true
    }
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(){
    this.setState({isStart:false})
  } 

  async componentDidMount(){
    await this.props.dispatch(GetUserProfile())
    this.props.dispatch(clearerror());
    await  this.props.dispatch(GetAuthorizedUser(this.props.practiceUserLoginInfo?.id));
    await this.props.dispatch(GetPracticeMoreInfo());
  }
  
  
  render (){
    return(
      <div className="edit_profile__main__screen  d-flex flex-column h-100 align-items-center">
              <SubHeader title={`${this.props.practiceUserLoginInfo.first_name || ''} ${this.props.practiceUserLoginInfo.last_name || ''} ${"'s profile"}`}/>
              {this.props.practiceUserProfileInfo &&
                <NewProfileForm practice_user_roles ={this.props.practice_user_roles} />
              }
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingMoreinfo: state.task.loadingMoreinfo,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
  practiceUserProfileInfo: state.task.practiceUserProfileInfo,
  error:state.task.error,
  practice_user_roles:state.task.practice_user_roles,
  practice_user_type:state.task.practice_user_type,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(index);
