import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import ChannelList from '../ChannelList'
import { Spinner } from 'reactstrap';
import { fetchChairsideMedia, getOperatoryMediaChannelList, getWebsiteRoomMediaChannelList } from '../../../../store/actions/taskAction';

const Search = ({isOperatoryRoom,isChairside}) => {
    const state = useSelector(state => state.task);
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState(null);
    const [searchTextDispatch, setSearchTextDispatch] = useState(null);
    const [videosList, setVideosList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);

    const changeHandler = (event) => {
        setSearchText(event.target.value);
    }

    useEffect(() => {
        if(isChairside){
          dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
          return
        }

        if(isOperatoryRoom){
          dispatch(
            getOperatoryMediaChannelList(
              state.practiceUserLoginInfo,
              state.practiceInfo.is_corporate == 1
            )
          );
        }else{
          dispatch(
            getWebsiteRoomMediaChannelList(
              state.practiceUserLoginInfo,
              state.practiceInfo.is_corporate == 1
            )
          );
        }
      }, []);

    useEffect(() => {
        if (searchText !== null && searchText.trim().length > 0) {
            const timerId = setTimeout(() => {
                setLoading(true);
                setSearchTextDispatch(searchText);
            }, 700)
            return () => {
                clearTimeout(timerId);
                setLoading(false);

            }
        }else{
            setVideosList([]);

        }
    }, [searchText]);

    useEffect(() => {
        const param = state.practiceUserLoginInfo;
        param['search'] = searchTextDispatch;
        filterChairSideMediaState();
    }, [searchTextDispatch]);

    const filterChairSideMediaState = () => {
        let ChannlList =[]
        
        if(isChairside){
          ChannlList = state.chairSideMediaState

          let searchedVideos = [];
          if (searchText !== null && searchText.trim().length > 0 ) {
              ChannlList.map((video, index) => {
                  if ((video?.chairside_media?.media_language?.title.toLowerCase().indexOf(searchText.toLowerCase().trim())) >= 0 ) {
                      searchedVideos.push(video);
                  }
              });

              if(searchedVideos.length === 0){
                  setSearchStatus(true)
              }else{
                  setSearchStatus(false)

              }
          }
          setVideosList(searchedVideos);
          setLoading(false);
          return
        }

        if(isOperatoryRoom){
            ChannlList = state.operatoryMediaChannels
        }else{
            ChannlList = state.waitingRoomMediaChannels
        }
       
        let searchedVideos = [];
        if (searchText !== null && searchText.trim().length > 0 ) {
            ChannlList.map((video, index) => {
                if ((video.title.toLowerCase().indexOf(searchText.toLowerCase().trim())) >= 0 ) {
                    searchedVideos.push(video);
                }
            });

            if(searchedVideos.length === 0){
                setSearchStatus(true)
            }else{
                setSearchStatus(false)

            }
        }
        setVideosList(searchedVideos);
        setLoading(false);
    }


  return (
    <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
        <input style={{ marginLeft: "15px" }} type="text" className={`${styles.goolge_tv_waiting_room__search_box} mb-5`} value={searchText} onChange={changeHandler} placeholder="Search by title." />
        {loading && 
            <Spinner color='primary' />
        }
        {searchStatus ? <h2 className='ml-4'>No result found for the {searchText}</h2> : ''}
        <ChannelList title={!searchStatus ? isChairside ? 'Results in Chairside Education' : isOperatoryRoom? 'Results in Operatory Room Channels' : 'Results in Waiting Room Channels' : null } isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} noSlider={true} channelsData={videosList}/>
    </div>
  )
}

export default Search
