import React from 'react'
import { useSelector } from 'react-redux';
import styles from './style.module.scss';

import settings_thumb from '../images/settings_thumb.png'
const getUserType = (id) =>{
  switch(id){
      case 0: 
          return  ["guest", "Guest"];
      case 1:
          return  ["dentist", "Normal User"];
      case 2:
          return  ['authorized_user', "Authorized User"]; 
      case 3:
          return    ['manager', "Manager"]; 
      case 4:
          return    ['owner', "Owner"]; 
      default:
        return    ['guest', "Guest"];
  }
}

const ProfileImgBox = () => {
  const state = useSelector(state => state.task);
  const userType = getUserType(state.practiceUserLoginInfo.type )

  return (
    <div className={`${styles.goolge_tv_setting__img_box} d-flex flex-column h-100`}>
          <img className="w-100" alt={state.practiceUserLoginInfo.first_name} src={settings_thumb}/>
    </div>
  )
}

export default ProfileImgBox
