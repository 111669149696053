import React from 'react';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import IconsArrowLeft from '../../img/arrow_left.svg'
import { InvoiceList, InvoiceViewList, cleardata, getInvoiceEmails, UpdateInvoiceEmail } from "../../store/actions/taskAction";
import './style.scss';
import { Button } from 'reactstrap';
import moment from 'moment';
import { YYYY, isValidEmail } from '../../data/comman';
import Loading from '../../components/Loading';

var selectedPracticeId
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    var practiceName = "";
    if(props && props.match && props.match.params && props.match.params.practiceId && props.isCorporate == 1){
      selectedPracticeId = props.match.params.practiceId;
      var practice = props.designateCard.data.find((dCard) => dCard.id ==  selectedPracticeId );
      if(practice){
        practiceName = practice.name;
      }
    }

    this.state = {
      currentPage: 1,
      per_page: 6,//15,
      totelPage: 0,
      expanded: false,
      showMoreArrow: false,
      selectedShowby: 1,
      curYear: moment().format(YYYY),
      minYear: moment(props.practiceInfo.created_at).format(YYYY),
      maxYear: moment().format(YYYY),
      email: "",
      statementEmail: "",
      statementEmailAllow: 0,
      emailError: "",
      practiceName,
    }
    this.handlechangePage = this.handlechangePage.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }
  handlechangePage(e, index) {
    index = index + 1

    this.setState({ currentPage: index }, () => {
      let startDate = this.state.curYear + "-01-01"
      let endDate = this.state.curYear + "-12-31"
      
      let params = {
        page: this.state.currentPage,
        per_page: this.state.per_page,
        start_date: startDate,
        end_date: endDate,
      }
       // selectedCard
      selectedPracticeId = null;
      if(this.props && this.props.match && this.props.match.params && this.props.match.params.practiceId && this.props.isCorporate == 1){
        selectedPracticeId = this.props.match.params.practiceId;
      }
      this.props.dispatch(InvoiceList(params, selectedPracticeId))
    });
  }

  // handleChange(field, options) {
  //   var name = "";
  //   var value = "";

  //   if (field === "showby") {
  //     name = field;
  //     value = options.value;
  //   } else {
  //     name = field.target.name;
  //     value = field.target.value;
  //   }

  //   this.setState({
  //     selectedShowby: {
  //       [name]: value,
  //     }
  //   });
  //   console.log(value, 'value')
  //   let params = {
  //     latest: value
  //   }
  //   var selectedPracticeId = null;
  //   if(this.props && this.props.match && this.props.match.params && this.props.match.params.practiceId && this.props.isCorporate == 1){
  //     selectedPracticeId = this.props.match.params.practiceId
  //   }
  //   this.props.dispatch(InvoiceList(params, selectedPracticeId))
  // }
  async componentDidMount() {
    let startDate = this.state.curYear + "-01-01"
    let endDate = this.state.curYear + "-12-31"
    let params = {
      start_date: startDate,
      end_date: endDate,
      page: this.state.currentPage,
      per_page: this.state.per_page,
    }
    // selectedCard
    // var selectedPracticeId = null;
    if(this.props && this.props.match && this.props.match.params && this.props.match.params.practiceId && this.props.isCorporate == 1){
      selectedPracticeId = this.props.match.params.practiceId
    }

   await this.props.dispatch(InvoiceList(params, selectedPracticeId));
   await this.props.dispatch(getInvoiceEmails(selectedPracticeId));
   await this.props.dispatch(cleardata());
    let emailDetail = this.props.getInvoiceEmails && this.props.getInvoiceEmails.data
    this.setState({ email: emailDetail.email, statementEmail: emailDetail.statement_email, statementEmailAllow: emailDetail.need_statement_on_email })
    // this.setState({ totelPage: this.props.invoiceList.last_page })
    console.log("this.props", this.props);
  }
  onInvoiceViewClick(invoice) {
    history.push({
      pathname: `/${selectedPracticeId}/invoice-view`,
      state: { invoice }
    });
    // this.props.dispatch(InvoiceViewList(invoice, null));
  }
  changeYear(type) {
    let curYear = this.state.curYear
    let newYear = type === "prev" ? curYear - 1 : curYear + 1
    if (newYear >= this.state.minYear && newYear <= this.state.maxYear) {
      this.setState({ curYear: newYear, currentPage: 1 }, () => {
        let startDate = newYear + "-01-01"
        let endDate = newYear + "-12-31"
        let params = {
          start_date: startDate,
          end_date: endDate,
          per_page: this.state.per_page,
          page: this.state.currentPage,
        }
      
        // selectedCard
        // var selectedPracticeId = null;
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.practiceId && this.props.isCorporate == 1){
          selectedPracticeId = this.props.match.params.practiceId
        }
        this.props.dispatch(InvoiceList(params, selectedPracticeId));
      });
    }
  }
  updateEmailDetail() {
    let body = {
      statement_email: this.state.statementEmail,
      need_statement_on_email: this.state.statementEmailAllow
    }
    if(this.state.statementEmailAllow){
      // selectedCard
      if(this.state.statementEmail == null || this.state.statementEmail == ""){
        this.setState({ emailError: "Please enter email address."});
      }else if(!isValidEmail(this.state.statementEmail)){
        // var selectedPracticeId = null;
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.practiceId && this.props.isCorporate == 1){
          selectedPracticeId = this.props.match.params.practiceId
        }
        this.props.dispatch(UpdateInvoiceEmail(body, selectedPracticeId));
      }else{
        this.setState({ emailError: "Please enter valid email address."});
      }
    }
  }
  render() {
    const { invoiceList } = this.props
    return (
      <div className="main__screen invoice__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title={`Invoices ${this.props.isCorporate == 1 ? '| ' + (this.state.practiceName) :  ''}`} />
        {this.props.loading && <Loading />}
        <div className="d-flex w-100 mb-auto">
          <div className="invoice__inner mb-auto mx-auto">
            <div className="invoice__inner__header">
              <div className="row">
                <div className="col-7">
                  <h4>Invoice history</h4>
                </div>
                <div className="col-5 text-right">
                  <div className="year-change">
                    <div className="year-change-arrow">
                      <Button type="button" color="link" onClick={() => { this.changeYear("prev") }}><img src={IconsArrowLeft} className="arrow-left"  alt="" /></Button>
                      <Button type="button" color="link" onClick={() => { this.changeYear("next") }}><img src={IconsArrowLeft} className="arrow-right"  alt="" /></Button>
                    </div>
                    <h3>{this.state.curYear}</h3>
                  </div>
                </div>
              </div>
            </div>
            {!!invoiceList && !!invoiceList.data && invoiceList.data.length > 0 ?
              <div className="invoice__inner__body">
                {/* {invoiceList ? <div className="no-invoice-box"><div className="no-invoice"><h2> You haven't been issued an invoice yet. </h2>
                <p>Once subscribed, your first issued invoice will show up on a list.</p></div></div> : */}
                <table className="table" >
                  <thead>
                    <tr>
                      <th>Billed to</th>
                      <th style={{width:'220px'}}>Invoice No.</th>
                      <th>Issue date</th>
                      <th style={{width:'120px'}}>Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceList && invoiceList.data.map((invoice, index) => {
                      console.log(invoice,'invoice')
                      return <tr key={index} >
                        <td>{invoice.billed_name}</td>
                        <td style={{width:'220px'}}>{invoice.reference_no}</td>
                        <td>{invoice.invoice_date}</td>
                        <td style={{width:'100px'}}>${invoice.total}</td>
                        <td><Button size="md" color="primary" onClick={() => this.onInvoiceViewClick(invoice)}>View</Button></td>
                      </tr>
                    })}
                  </tbody>
                </table>

              </div> :
              <> {!this.props.loading &&
                <div className="invoice__inner__body">
                  <div className="invoice__empty flex-column d-flex align-items-center justify-content-center">
                    <h4>You haven't been issued an invoice yet.</h4>
                    <p>Once subscribed, your first issued invoice will show up on a list.</p>
                  </div>
                </div>}
              </>}
            {this.props.invoiceList && this.props.invoiceList.last_page > 1 &&
              <div className="invoice__inner__footer d-flex justify-content-center align-items-center">
                <ul>
                  {[...Array(this.props.invoiceList.last_page)].map((page, i) =>
                    <li key={i}><Button type="button" style={{ color: i + 1 == this.state.currentPage ? '#FF9F00' : "#fff" }} className={`p-3 ${i + 1 == this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                  )}
                </ul>
              </div>
            }
          </div>
          <div className="invoice__suggestion d-flex flex-column align-items-flex-start justify-content-center">
            <h4>Receive monthly statements on</h4>
            <div className="form-group mt-4 mb-0">
              <div className="custom_check">
                <input type="checkbox" name="receive_statements_reg" id="receive_statements_reg" checked />
                <label for="receive_statements_reg">Registered email</label>
              </div>
              <input value={this.state.email} disabled type="email" className="form-control" placeholder="Enter email here" />
            </div>
            <div className="divider"></div>
            <div className="form-group">
              <div className="custom_check">
                <input type="checkbox" onChange={() => { this.setState({ statementEmailAllow: this.state.statementEmailAllow === 0 ? 1 : 0 }) }} checked={this.state.statementEmailAllow === 0 ? false : true} name="receive_statements_other" id="receive_statements_other" />
                <label for="receive_statements_other">Another email</label>
              </div>
              <input value={this.state.statementEmail} onChange={(e) => { this.setState({ statementEmail: e.target.value, emailError: "" }) }} type="email" className="form-control" placeholder="Enter email here" />
              {this.state.emailError && <span className='text--danger'>{this.state.emailError}</span>}
            </div>
            {this.state.statementEmailAllow !== 0 &&
              <Button size="md" color="primary" onClick={() => { this.updateEmailDetail() }}>Save Changes</Button>
            }
          </div>
        </div>
      </div>
    )
  }

}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  invoiceList: state.task.invoiceList,
  getInvoiceEmails: state.task.getInvoiceEmails,
  invoiceMailUpdated: state.task.invoiceMailUpdated,
  practiceInfo: state.task.practiceProfileInfo,
  selectedCard: state.task.selectedCard,
  isCorporate: state.task.isCorporate,
  designateCard: state.task.designateCard,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(HomeIndex);
