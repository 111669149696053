import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Spinner, Button, Modal } from 'reactstrap';
import AppBar from '@material-ui/core/AppBar';
import WaitingRoomTimelineSlidePreset from './WaitingRoomTimelineSlidePreset';
import WaitingRoomTimelineSlideImage from './WaitingRoomTimelineSlideImage';
import { Dropdown } from 'react-bootstrap';
import WaitingRoomSlideBackground from './WaitingRoomTimelineSlideBackground';
import {WaitingRoomTimelineSlidesTemplates,WaitingRoomTimelineSlidesStockImages, WaitingRoomTimelineSlidesBackgroundImages} from '../../store/actions/taskAction';
import { fontSize, BLANKSLIDE, editableImage1, editableImage, FADELEFT, FADERIGHT, FADETOP, FADEBOTTOM, NONEANIM, BLANKSLIDEBG, SLIDEZOOMFACTOR, MOVEABLETEXT, MOVABLEIMAGE, DEFAULTTEXT, TEXTCOMPONENT, IMAGECOMPONENT, SLIDEDEFAULTIMAGE } from '../../data/comman';
import WaitingRoomTimelineSlidePresetsList from './WaitingRoomTimelineSlidePresetsList';
import UploadImage from '../UploadImage';
import CustomSlide from './CustomSlide';
import './styles.scss';
import InlineSVG from 'react-inlinesvg';
import { Slider } from "@material-ui/core";
import _ from 'lodash';
import { PhotoshopPicker } from 'react-color';

var zoomFactor = 10 * (SLIDEZOOMFACTOR - 0.2);
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        className="btn btn-default dropdown-arrow"
        style={{background: 'none' }}
        ref={ref}
        onMouseDown={(e) => {
            e.preventDefault();
            // onClick(e);
        }}
        onClick={(e)=>  onClick(e)}
    >
      {children}
      {/* &#x25bc; */}
    </div>
));

const customFontSize = ( fontSize) => {
	var fs = fontSize * zoomFactor;
	// window.CKEDITOR.fire('saveSnapshot');
	// var styles = new window.CKEDITOR.style({
	//     element     : 'span',
	//     styles      : { 'font-size' : '#(size)' },
	//     overrides   : [ { element : 'font', attributes : { 'size' : null } } ]
	// }, {size: `${fs}px`});

	// styles.apply(window.CKEDITOR.document );
	// window.CKEDITOR.fire('saveSnapshot');


	document.execCommand("fontSize", false, fs);
	if(window.getSelection().anchorNode &&  window.getSelection() && window.getSelection().toString().length > 0){
		var fontElements = window.getSelection().anchorNode.parentNode;
		fontElements.removeAttribute("size");
		fontElements.style.fontSize = `${fs}px`;
	}
	// var spanString = $('<span/>', {
		//     'text': document.getSelection()
		// }).css('font-size', size + unit).prop('outerHTML');
	
	// document.execCommand('insertHTML', false, spanString);

	// var sel = window.getSelection(); // Gets selection
	// if (sel.rangeCount) {
	// 	var e = document.createElement('span');
	// 	e.style.fontSize = `${fs}px;`; 
	// 	e.innerHTML = sel.toString();
	// 	var range = sel.getRangeAt(0);
	// 	range.deleteContents();
	// 	range.insertNode(e);
	// }
} 

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
        width: 'auto'
	},
}));

const AddWaitingRoomTimelineSlides = forwardRef(function AddWaitingRoomTimelineSlides(props, ref){
// export default const AddWaitingRoomTimelineSlides =  forwardRef((props, ref) => {
	const childRef = useRef();
    const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);
	const [boldLatter,setBoldLatter] = useState('false');
	const [italicLatter,setItalicLatter] = useState('false');
	const [underlineLatter,setUnderlineLatter] = useState('false');
	const [selectedFontStyle, setSelectedFontStyle] = useState(null);
	const [selectedImageId, setSelectedImageId] = useState(null);
	const [selectedTextId, setSelectedTextId] = useState(null);

	const dispatch = useDispatch();
	const [isSlidePreset, setIsSlidePreset] = useState(true);
	const [isRemoveSlidePreset, setIsRemoveSlidePreset] = useState(props.onSelectSingleSlide.id ? true : false);
	const state = useSelector((state) => state.task.WaitingRoomSlideTemplate);
	const stockImages = useSelector((state) => state.task.WaitingRoomSlideStockImages);
	const backgroundImages = useSelector((state) => state.task.WaitingRoomSlideBackgroundImages);

	const [selectedSlide, setSelectedSlide] = useState(BLANKSLIDE.content); // blank slide
	// slide preset sidebar
	const [slideSidebarIsOpen, setSlideSidebarIsOpen] = useState(false);
	const toggleSlideSidebar = () => { setSlideSidebarIsOpen(!slideSidebarIsOpen)};
	// stock image sidebar
	const [stockImageSidebarIsOpen, setStockImageSidebarIsOpen] = useState(false);
	const toggleStockImageSidebar = () => { setStockImageSidebarIsOpen(!stockImageSidebarIsOpen) };
	// background images
	const [backgroundSidebarIsOpen, setBackgroundSidebarIsOpen] = useState(false);
	const toggleBackgroundSidebar = () => { setBackgroundSidebarIsOpen(!backgroundSidebarIsOpen) };
	
	//image upload modal
	const [imageUploadModal, setImageUploadModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState({});
	const [bgOpacity, setBgOpacity] = useState(100);
	const [styleOptions, setStyleOptions] = useState({
		bold: false,
		italic: false,
		underline: false,
		lineHeight: 0,
		selectedFontSize: null
	})

	const [target, setTarget] = useState([]);
	const [pickerColor, setPickerColor] = useState("#ff0000");
	const [colorPicker, setColorPicker] = useState(false);

    const handleChange = (event, newValue, id, component) => {
		setValue(newValue);
		if(component == IMAGECOMPONENT){
			setSelectedTextId(null);
			setSelectedImageId(id);
			// remove active class
			const div = document.querySelectorAll('.moveable-container');
			[].forEach.call(div, function(el) {
				el.classList.remove('slide_image_active');
			})
			// add class
			var element = document.querySelector(`#moveable_image_${id}`);
			if(element) element.classList.add('slide_image_active');
			setTarget([].slice.call(document.querySelectorAll(`#moveable_image_${id}`)));
		}else if(component == TEXTCOMPONENT){
			setSelectedTextId(id);
			setSelectedImageId(null);
			// remove active class
			const div = document.querySelectorAll('.moveable-container');
			[].forEach.call(div, function(el) {
				el.classList.remove('slide_image_active');
			})
			// add class
			var element = document.querySelector(`#moveable_${id}`);
			if(element) element.classList.add('slide_image_active');
			setTarget([].slice.call(document.querySelectorAll(`#moveable_${id}`)));
			// setTarget([]);
		}

	};

	useImperativeHandle(
        ref,
        () => ({
            getSlideContent() {
				return childRef.current.getSlideContent()
			}
			// reIntEvents() {
			// 	return childRef.current.reIntEvents()
			// }
        }),
    )

	useEffect(() => {
		if(props.onSelectSingleSlide.id){
			setSelectedSlide(props.onSelectSingleSlide.content);
		}
		dispatch(WaitingRoomTimelineSlidesTemplates());
		dispatch(WaitingRoomTimelineSlidesStockImages());
		dispatch(WaitingRoomTimelineSlidesBackgroundImages());
	}, []);

	// on select slide presets
	const onSlidePresetSelect = (slide) => {
		setSelectedImageId(null);
		if(slide.id == 0){ // is_blank slide
			clearSlide();
			setIsSlidePreset(false);
			setIsRemoveSlidePreset(false);
			setSelectedSlide("");
			setTimeout(()=>{
				setSelectedSlide(BLANKSLIDE.content);
			},100);
			
		}
		else{
			clearSlide();
			setSelectedSlide(slide.content);
			setIsSlidePreset(true);
			setIsRemoveSlidePreset(true);
		}
		toggleSlideSidebar();
		setTarget([]);
	}
	
	// on stock image select
	const onImageSelect = (image) => {
        toggleStockImageSidebar();
        setImageUploadModal(true);
		setSelectedImage(image);
	}

	const clearSlide = () => {
		try{
			var moveableContainers = document.querySelectorAll(".moveable-container");
			[].forEach.call(moveableContainers, function(moveableContainer) {
				if(moveableContainer)
					moveableContainer.remove();
			});
		}catch(e){
			console.log("catch movabel", e);
		}
	}

	const onBackgroundImagesSelect = (bgImage) => {
		toggleBackgroundSidebar();
		var isExist = !!document.getElementById(BLANKSLIDEBG);
		if(isExist){
			var editor = document.getElementById(BLANKSLIDEBG);
			editor.style.backgroundRepeat = 'no-repeat';
			editor.style.backgroundPosition = "center";
			editor.style.backgroundSize = 'cover';
			editor.style.backgroundImage = `url(${bgImage.image_url})`; 
		}
	}

	const changeBackgroundOpacity = (event, newValue) => {
		var isExist = !!document.getElementById(BLANKSLIDEBG);
		if(isExist){
			setBgOpacity(newValue);
			var editor = document.getElementById(BLANKSLIDEBG);
			editor.style.opacity = newValue/100;
		}
	} 

	const onRemoveBackground = () =>{
		var isExist = !!document.getElementById(BLANKSLIDEBG);
		if(isExist){
			var editor = document.getElementById(BLANKSLIDEBG);
			editor.style.backgroundImage = '';
			editor.style.background = '';
			editor.style.backgroundColor = 'white';
		}
	}
	
	const togleUploadImage = () => {
        setImageUploadModal(!imageUploadModal)
	}
	
	const closeModal = () => {
        setImageUploadModal(false);
	}
	
	const imageUploadSuccess = (response) => {
        var imageResponse = response.data;
        if(imageResponse && imageResponse.status == 200){
			setImageUploadModal(false);
			if(selectedImageId) {
				var selectImage = document.getElementById(selectedImageId);
				selectImage.src = imageResponse.data.image;
				selectImage.parentNode.classList.add("imageLoading");
			// else if(isSlidePreset)
			// 	document.getElementById(editableImage1).src = imageResponse.data.image;
			selectImage.onload =  (ele) =>  {
				selectImage.parentNode.classList.remove("imageLoading");
			};
		} else{
				// document.getElementById(editableImage).src = imageResponse.data.image;
				onAddNewComponent(IMAGECOMPONENT, imageResponse.data.image)
			}
			// setSelectedImageId(null);
        }else{

        }
	}

	const setColor = (color) => {
		if(color){
			document.execCommand('styleWithCSS', false, true);
			document.execCommand('foreColor', false, color);
		} else {
			document.execCommand('styleWithCSS', false, true);
			document.execCommand('removeFormat', false, "foreColor");
		}
	}

	const removeSlidePreset = () => {
		if(isRemoveSlidePreset){ 
			setSelectedSlide(""); 
			setIsRemoveSlidePreset(false);
			setIsSlidePreset(true);
		}
	}

	const setSelectedStyle = (style) => {
		try{
			var _node = window.getSelection().anchorNode.parentNode;
			var isBold = _node.style.bold ? true : (_node.style.fontWeight == "bold" ? true : (_node.tagName.toLowerCase() == "b" ? true : getComputedStyle(_node).fontWeight > 400))
			var isItalic = _node.style.italic ? true : (_node.style.fontStyle == "italic" ? true : (_node.tagName.toLowerCase() == "i" ? true : getComputedStyle(_node).fontStyle == "italic"));
			var isUnderLine = _node.style.underline ? true : ( _node.style.textDecorationLine == "underline" ? true : (_node.tagName.toLowerCase() == "u" ? true : getComputedStyle(_node).textDecorationLine == "underline"));
			var lineHeight = 0;
			// line height
			var parentNode = document.getElementById(`moveable_${_node.id}`);
			if(parentNode)
				lineHeight = getComputedStyle(parentNode).lineHeight;

			var sel = window.getSelection(); // Gets selection
			if (sel.rangeCount && sel.toString().length > 0) {
				var isParentAv = _node.tagName.toLowerCase() == "span"
				var e = isParentAv ? _node : document.createElement('span');
				// document.designMode = "on";
				switch(style){
					case "b":
						// if(isBold){
						// 	e.style.fontWeight = 'normal'; 
						// }else{
						// 	e.style.fontWeight = 'bold'; 
						// }
						document.execCommand( "bold");
						setStyleOptions({
							...styleOptions,
							bold: !isBold,
							lineHeight,
						})
						break;
					case "i":
						// if(isItalic){
						// 	e.style.fontStyle = "normal";
						// }else{
						// 	e.style.fontStyle = "italic";
						// }
						document.execCommand('italic');
						setStyleOptions({
							...styleOptions,
							italic: !isItalic,
							lineHeight,
						})
						break;
					case "u":
						// if(isUnderLine){
						// 	e.style.textDecorationLine = "none";
						// }else{
						// 	e.style.textDecorationLine = "underline";
						// }
						document.execCommand('underline');
						setStyleOptions({
							...styleOptions,
							underline: !isUnderLine,
							lineHeight,
						})
						break;
				}
				// if(!isParentAv){
				// 	e.innerHTML = sel.toString();
				// 	var range = sel.getRangeAt(0);
				// 	range.deleteContents(); // Deletes selected text…
				// 	range.insertNode(e); // … and inserts the new element at its place
				// 	// range.selectNodeContents(e);
				// 	// console.log("window.getSelection()==><",window.getSelection())
				// 	let referenceNode = document.getElementsByTagName(_node.tagName).item(0);
				// 	range.selectNode(referenceNode);
				// }
			}	
		}catch(e){
			console.log("set selected e1",e);
		}
	}
	
	const onAddNewComponent = (component, selectedImage) => {
		var isExist = selectedSlide != "";
		if(isExist){
			var container = document.getElementById("mainEditor");
			if(component == TEXTCOMPONENT){
				var numText = document.querySelectorAll('.moveable-text-container').length;
				var newId = ++numText;
				if(newId > 10) return;
				// check element exist or not
				var checkElement =  document.getElementById(`moveable_editable_text${newId}`);
				if(checkElement){
					for(var i = 1; i <= 10; i++){
						var ce =  document.getElementById(`moveable_editable_text${i}`);
						if(ce){} else{
							newId = i;
							break;
						}
					}
				}
				var moveableTextItem = `<div id="moveable_editable_text${newId}" class="moveable-container moveable-text-container" style="display: block; position: absolute !important; transform: translate3d(${70 + 50 * newId}px, 50px, 0px); width: 800px; z-index: 999; font-size: 60px;">
					<button class="draggable moveable-button"  id="draggable_button${newId}">+</button> 
					<p ondrop="return false" contenteditable="true" data-enable-grammarly="false" id="editable_text${newId}" class="blank_title">${DEFAULTTEXT}</p>
				</div>`;
				container.insertAdjacentHTML('afterbegin',  moveableTextItem);
				setTarget([].slice.call(document.querySelectorAll(`#moveable_editable_text${newId}`)));
				// setTarget([]);
			}else{
				var numImage = document.querySelectorAll('.moveable-image-container').length;
				var newId = ++numImage;
				if(newId > 10) return;
				// check element exist or not
				var checkElement =  document.getElementById(`moveable_image_editable_image${newId}`);
				if(checkElement){
					for(var i = 1; i <= 10; i++){
						var ce =  document.getElementById(`moveable_image_editable_image${i}`);
						if(ce){} else{
							newId = i;
							break;
						}
					}
				}//style="transform: translate3d(${1220 - 50 * newId}px, 50px, 0px)"
				var moveableImageItem = `<div id="moveable_image_editable_image${newId}" class="moveable-container moveable-image-container" style="height: 254px; width: 400px; position: absolute; z-index: 1000;">
					<img draggable="false" id="editable_image${newId}" style="width: 100%;" class="blank_image editable_image" src="${ selectedImage ? selectedImage : SLIDEDEFAULTIMAGE }"/>
				</div>`
				container.insertAdjacentHTML('afterbegin', moveableImageItem);
				handleChange(null, 2, `editable_image${newId}`, IMAGECOMPONENT);
				// handleChange(null, 2, `moveable_image_editable_image${newId}`);
				// setTarget([].slice.call(document.querySelectorAll(`#moveable_image_editable_image${newId}`)));
			}
			childRef.current.reIntEvents();
		}
	}
	
	const onDeleteComponent = (component) => {
		var isExist = selectedSlide != "";
		if(isExist){
			if(component == TEXTCOMPONENT && selectedTextId){
				var elem = document.getElementById(`moveable_${selectedTextId}`);
				elem.parentNode.removeChild(elem);
				setSelectedTextId(null);
				setTarget([]);
			}else if(component == IMAGECOMPONENT && selectedImageId){
				var elem = document.getElementById(`moveable_image_${selectedImageId}`);
				elem.parentNode.removeChild(elem);
				setSelectedImageId(null);
				setTarget([]);
			}
		}
	}

	const setLineHeight = (selectedLineHeight) => {
		try{
			if(window.getSelection().anchorNode &&  window.getSelection() && selectedTextId){
				// var selectedId = window.getSelection().anchorNode.parentNode.id;
				var parentNode = document.getElementById(`moveable_${selectedTextId}`);
				if(parentNode)
					parentNode.style.lineHeight = selectedLineHeight;
				setStyleOptions({
					...styleOptions,
					lineHeight: selectedLineHeight,
				})
			}
		}catch(e){
            console.log("set selected e123",e);
        }
	}

	const showColorPicker = (e) => {
		e.preventDefault();
		setColorPicker(true); 
		try{
			var _node = window.getSelection().anchorNode.parentNode;
			var style = getComputedStyle(_node);
			if(style.color){
				setPickerColor(style.color);
			}
		}catch(e){}
	}

    return (
        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
            {state?.length <= 0 ? (
               <Spinner color='primary' />
            ) : (
                <>
					<div className={`${classes.root} footer-header `}>
						<AppBar
							position='static'
							color='default'
							>
							<Tabs
								value={value}
								onChange={handleChange}
								indicatorColor='none'
								textColor='primary'
								className='footer-tabs'
								aria-label='full width tabs example'>
								<Tab label='SLIDE PRESETS' {...a11yProps(0)} />
								<Tab label='TEXT' {...a11yProps(1)} />
								<Tab label='IMAGE' {...a11yProps(2)} />
								{!isSlidePreset && <Tab label='BACKGROUND' {...a11yProps(3)} disabled={isSlidePreset}/>}
								<Tab label='TRANSITION' {...a11yProps(4)} />
							</Tabs>
						</AppBar>

						<TabPanel value={value} index={0} dir={theme.direction}>
							{/* <WaitingRoomTimelineSlidePreset templates={state} /> */}
							<div className='videolist__main__screen' style={{ padding: '10px' }}>
								<Button color='default' style={{ background: 'none', border: 'none' }} onClick={toggleSlideSidebar}>Select Slide Preset
								<InlineSVG style={{marginLeft:"10px"}} src='<svg width="19px" height="16px" viewBox="0 0 19 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<title>Shape</title>
									<g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g id="New-channel,-My-slides,-32%-Opacity" transform="translate(-1304.000000, -361.000000)" fill="#FFFFFF" fill-rule="nonzero">
											<g id="Text-format" transform="translate(715.000000, 348.000000)">
												<g id="Select-from-files" transform="translate(399.000000, 11.000000)">
													<path d="M191.055556,2 L195.963889,2 C196.201389,2 196.438889,2.07741935 196.65,2.23225806 L197.784722,3.16129032 C197.890278,3.23870968 197.995833,3.29032258 198.127778,3.29032258 L207.944444,3.29032258 C208.525,3.29032258 209,3.75483871 209,4.32258065 L209,16.9677419 C209,17.5354839 208.525,18 207.944444,18 L191.055556,18 C190.475,18 190,17.5354839 190,16.9677419 L190,3.03225806 C190,2.46451613 190.475,2 191.055556,2 Z M192.770833,6.64516129 C192.401389,6.64516129 192.111111,6.92903226 192.111111,7.29032258 L192.111111,7.67741935 C192.111111,7.83225806 192.216667,7.93548387 192.375,7.93548387 L206.625,7.93548387 C206.783333,7.93548387 206.888889,7.83225806 206.888889,7.67741935 L206.888889,7.29032258 C206.888889,6.92903226 206.598611,6.64516129 206.229167,6.64516129 L192.770833,6.64516129 Z" id="Shape"></path>
												</g>
											</g>
										</g>
									</g>
								</svg>'/>
								</Button>
								<Button color='default' style={{ background: 'none', border: 'none', color: isRemoveSlidePreset ? 'white': 'rgba(255,255,255, 0.3)'}} onClick={removeSlidePreset}>Remove slide preset</Button>
							</div><br />
						</TabPanel>
						<TabPanel value={value} index={1} dir={theme.direction} className="slide-editing-option">
							<div className='videolist__main__screen d-flex' style={{ padding: '10px' }}>
							<div className="undo-redo-btn">
								<Button color='default' style={{ background: 'none', border: 'none' }} onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('Undo',true, null)}>
									<InlineSVG  src='<svg width="17px" height="9px" viewBox="0 0 17 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
										<title>Undo</title>
										<g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
											<g id="New-channel,-My-slides,-32%-Opacity" transform="translate(-730.000000, -364.000000)" fill="#FFFFFF" fill-rule="nonzero">
												<g id="Text-format" transform="translate(715.000000, 348.000000)">
													<path d="M23.2414585,18.1303251 C21.8063079,18.1311864 20.410807,18.6013285 19.2677086,19.469075 L21.1979169,21.3975125 C21.3630312,21.5623492 21.4122918,21.8105615 21.3226397,22.0259596 C21.2329875,22.2413577 21.0221629,22.3813183 20.7888544,22.380325 L15.8039587,22.380325 C15.5913897,22.3822146 15.386877,22.2990932 15.2358984,22.1494449 C15.0849199,21.9997966 15,21.7960274 15,21.58345 L15,16.5985543 C14.9997273,16.3655683 15.1399516,16.1554023 15.3551874,16.0662055 C15.5704232,15.9770087 15.8182035,16.02638 15.9828129,16.1912626 L17.7536462,17.9620959 C20.3354985,15.8591759 23.8884858,15.4073705 26.9144816,16.7971846 C29.9404774,18.1869987 31.912872,21.176565 32,24.5053249 L29.875,24.5053249 C29.7315635,20.9450855 26.8045858,18.1321864 23.2414585,18.1303251 Z" id="Undo"></path>
												</g>
											</g>
										</g>
									</svg>'/>
								</Button>
								<Button color='default' style={{ background: 'none', border: 'none' }} onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('Redo',true, null)}>
									<InlineSVG  src='<svg width="17px" height="9px" viewBox="0 0 17 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
										<title>Forward</title>
										<g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" fill-opacity="0.3">
											<g id="New-channel,-My-slides,-32%-Opacity" transform="translate(-762.000000, -364.000000)" fill="#FFFFFF" fill-rule="nonzero">
												<g id="Text-format" transform="translate(715.000000, 348.000000)">
													<path d="M55.2414585,18.1303251 C53.8063079,18.1311864 52.410807,18.6013285 51.2677086,19.469075 L53.1979169,21.3975125 C53.3630312,21.5623492 53.4122918,21.8105615 53.3226397,22.0259596 C53.2329875,22.2413577 53.0221629,22.3813183 52.7888544,22.380325 L47.8039587,22.380325 C47.5913897,22.3822146 47.386877,22.2990932 47.2358984,22.1494449 C47.0849199,21.9997966 47,21.7960274 47,21.58345 L47,16.5985543 C46.9997273,16.3655683 47.1399516,16.1554023 47.3551874,16.0662055 C47.5704232,15.9770087 47.8182035,16.02638 47.9828129,16.1912626 L49.7536462,17.9620959 C52.3354985,15.8591759 55.8884858,15.4073705 58.9144816,16.7971846 C61.9404774,18.1869987 63.912872,21.176565 64,24.5053249 L61.875,24.5053249 C61.7315635,20.9450855 58.8045858,18.1321864 55.2414585,18.1303251 Z" id="Forward" transform="translate(55.500000, 20.252662) scale(-1, 1) translate(-55.500000, -20.252662) "></path>
												</g>
											</g>
										</g>
									</svg>'/>
								</Button>
							</div>
							{/* <Dropdown style={{minWidth:"154px"}}>
									<Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic">{selectedFontStyle ? selectedFontStyle : <b>Bold</b>}</Dropdown.Toggle>

									<Dropdown.Menu>
										
											<Dropdown.Item  onClick={(e) => {setSelectedFontStyle(<b>Bold</b>)}}><Button color='default' style={{ background: 'none', border: 'none'}}  className="bold-font" onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('Bold',false, null)}>Bold</Button></Dropdown.Item>
											<Dropdown.Item  onClick={(e) => {setSelectedFontStyle(<em>Italic</em>)}}><Button color='default' style={{ background: 'none', border: 'none' }} className="italic-font" onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('italic',false, null)}>Italic</Button></Dropdown.Item>
											<Dropdown.Item  onClick={(e) => {setSelectedFontStyle(<u>Underline</u>)}}><Button color='default' style={{ background: 'none', border: 'none' }} className="underline-font" onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('underline',false, null)}>Underline</Button></Dropdown.Item>

									</Dropdown.Menu>
								</Dropdown> */}



								<div className="alignment-block">
									<Button color='default' style={{ background: 'none', border: 'none', textAlign: 'center' }}  className={`${styleOptions.bold && "toggle_active"} bold-font`} onMouseDown={(e) => {e.preventDefault()}} onClick={(e) => {{/*document.execCommand('Bold',false, null)*/} e.preventDefault(); setSelectedStyle("b")}}>B</Button>
									<Button color='default' style={{ background: 'none', border: 'none', textAlign: 'center' }} className={`${styleOptions.italic && "toggle_active"} italic-font`} onMouseDown={(e) => {e.preventDefault()}} onClick={() => { {/*document.execCommand('italic',false, null);*/} setSelectedStyle("i")}}>I</Button>
									<Button color='default' style={{ background: 'none', border: 'none', textAlign: 'center' }} className={`${styleOptions.underline && "toggle_active"} underline-font`} onMouseDown={(e) => {e.preventDefault()}} onClick={() => { {/*document.execCommand('underline',false, null);*/} setSelectedStyle("u")}}>U</Button>
								</div>

								<Dropdown>
										<Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic">{styleOptions.selectedFontSize ? parseInt(styleOptions.selectedFontSize) : 'Size'}</Dropdown.Toggle>

										<Dropdown.Menu style={{maxHeight: 330, overflow: 'auto'}}>
											{ _.range(8, 49).map((value) =>
												<Dropdown.Item onClick={(e) => { e.preventDefault(); customFontSize(value); setStyleOptions({...styleOptions, value})}}><i class="fas fa-user"></i> {value}</Dropdown.Item>
											)}
										</Dropdown.Menu>
								</Dropdown>
								<Dropdown style={{ minWidth:'81px' }} className="color-dropdown" onMouseDown={(e) => { e.preventDefault() }}>
										<Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic">
											A
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{/* <Dropdown.Item className="" onClick={(e) => { e.preventDefault(); setColor('') }}>Reset</Dropdown.Item> */}
											<Dropdown.Item className="black" onClick={(e) => { e.preventDefault(); setColor('rgba(0, 0, 0, 1)') }}></Dropdown.Item>
											<Dropdown.Item className="yellow" onClick={(e) => { e.preventDefault(); setColor('rgba(255, 159, 0, 1)') }}></Dropdown.Item>
											<Dropdown.Item className="red" onClick={(e) => { e.preventDefault(); setColor('rgba(255, 95, 66, 1)') }}></Dropdown.Item>
											<Dropdown.Item className="blue" onClick={(e) => { e.preventDefault(); setColor('rgba(74, 121, 255, 1)') }}></Dropdown.Item>
											<Dropdown.Item className="green" onClick={(e) => { e.preventDefault(); setColor('rgba(117, 117, 117, 1)') }}></Dropdown.Item>
											<Dropdown.Item className="purple" onClick={(e) => { e.preventDefault(); setColor('rgba(173, 128, 214, 1)') }}></Dropdown.Item>
											<Dropdown.Item className="white" onClick={(e) => { e.preventDefault(); setColor('rgba(255, 255, 255, 1)') }}></Dropdown.Item>
											<Dropdown.Item className="custom" onClick={(e) => { showColorPicker(e); }}>Custom</Dropdown.Item>
										</Dropdown.Menu>
								</Dropdown>
								
								<div className="alignment-block">
									<Button color='default' style={{ background: 'none', border: 'none' }} onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand("justifyLeft", false, null)}>
										<InlineSVG src='<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="enable-background:new 0 0 100 100;" xml:space="preserve"><title>95all</title><path d="M88.4,12H11.5C9,12,7,14,7,16.5s2,4.5,4.5,4.5h77.1c2.5,0,4.5-2,4.5-4.5S91.1,12,88.4,12z"/><path d="M11.5,43.3h50.3c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5H11.5c-2.5,0-4.5,2-4.5,4.5S8.8,43.3,11.5,43.3z"/><path d="M88.4,56.7H11.5c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5h77.1c2.5,0,4.5-2,4.5-4.5S91.1,56.7,88.4,56.7z"/><path d="M61.6,79.1H11.5c-2.5,0-4.5,2-4.5,4.5C7,86,9,88,11.5,88h50.3c2.5,0,4.5-2,4.5-4.5C66.3,81.1,64.2,79.1,61.6,79.1z"/></svg>' />
									</Button>
									<Button color='default' style={{ background: 'none', border: 'none'}}  onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand("justifyCenter", false, null)}>
										<InlineSVG src='<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="enable-background:new 0 0 100 100;" xml:space="preserve"><path d="M90.4,10H9.5c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7h81.1c2.6,0,4.7-2.1,4.7-4.7S93.2,10,90.4,10z"/><path d="M76.3,42.9c2.6,0,4.7-2.1,4.7-4.7c0-2.6-2.1-4.7-4.7-4.7H23.6c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7H76.3z"/><path d="M90.4,57.1H9.5c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7h81.1c2.6,0,4.7-2.1,4.7-4.7S93.2,57.1,90.4,57.1z"/><path d="M76.3,80.6H23.6c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7h52.9c2.6,0,4.7-2.1,4.7-4.7C81.2,82.7,79.1,80.6,76.3,80.6z"/></svg>' />
									</Button>
									<Button color='default' style={{ background: 'none', border: 'none'}} onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand("justifyRight", false, null)}>
										<InlineSVG src='<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" style="enable-background:new 0 0 100 100;" xml:space="preserve"><title>95all</title><path d="M88.4,12H11.5C9,12,7,14,7,16.5s2,4.5,4.5,4.5h77.1c2.5,0,4.5-2,4.5-4.5S91.1,12,88.4,12z"/><path d="M11.5,43.3h50.3c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5H11.5c-2.5,0-4.5,2-4.5,4.5S8.8,43.3,11.5,43.3z"/><path d="M88.4,56.7H11.5c-2.5,0-4.5,2-4.5,4.5s2,4.5,4.5,4.5h77.1c2.5,0,4.5-2,4.5-4.5S91.1,56.7,88.4,56.7z"/><path d="M61.6,79.1H11.5c-2.5,0-4.5,2-4.5,4.5C7,86,9,88,11.5,88h50.3c2.5,0,4.5-2,4.5-4.5C66.3,81.1,64.2,79.1,61.6,79.1z"/></svg>' />
									</Button>
								</div>

								<Dropdown style={{ minWidth:'81px' }}  onMouseDown={(e) => { e.preventDefault()}}>
									<Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic">
										<InlineSVG src='<svg width="28" height="21" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9.1875 7.14583H14.2917L7.14583 0L0 7.14583H5.10417V27.5625H0L7.14583 34.7083L14.2917 27.5625H9.1875V7.14583ZM17.3542 3.0625V7.14583H41.8542V3.0625H17.3542ZM17.3542 31.6458H41.8542V27.5625H17.3542V31.6458ZM17.3542 19.3958H41.8542V15.3125H17.3542V19.3958Z" fill="white"/>
										</svg>' />
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item className={`${styleOptions.lineHeight == 1 ? 'active' : ""}`} onClick={(e) => { e.preventDefault(); setLineHeight(1) }}><i class="fas fa-user"></i>Single</Dropdown.Item>
										<Dropdown.Item className={`${styleOptions.lineHeight == 1.15 ? 'active' : ""}`} onClick={(e) => { e.preventDefault(); setLineHeight(1.15) }}><i class="fas fa-user"></i>1.15</Dropdown.Item>
										<Dropdown.Item className={`${styleOptions.lineHeight == 1.5 ? 'active' : ""}`} onClick={(e) => { e.preventDefault(); setLineHeight(1.5) }}><i class="fas fa-user"></i>1.5</Dropdown.Item>
										<Dropdown.Item className={`${styleOptions.lineHeight == 2 ? 'active' : ""}`} onClick={(e) => { e.preventDefault(); setLineHeight(2) }}><i class="fas fa-user"></i>Double</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

								<div className="alignment-block">
									<Button color='default' style={{ background: 'none', border: 'none', textAlign: 'center' }} onMouseDown={(e) => {e.preventDefault()}} onClick={() => { onAddNewComponent(TEXTCOMPONENT) }}>Add Text</Button>
								</div>
								<div className="alignment-block">
									<Button color='default' style={{ background: 'none', border: 'none', textAlign: 'center' }} onMouseDown={(e) => {e.preventDefault()}} onClick={() => { onDeleteComponent(TEXTCOMPONENT) }}>Delete Text</Button>
								</div>
								
								{/* <Button color='default' style={{ background: 'none', border: 'none'}}  className="bold-font" onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('Bold',false, null)}>Bold</Button>
								<Button color='default' style={{ background: 'none', border: 'none' }} className="italic-font" onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('italic',false, null)}>Italic</Button>
								<Button color='default' style={{ background: 'none', border: 'none' }} className="underline-font" onMouseDown={(e) => {e.preventDefault()}} onClick={() => document.execCommand('underline',false, null)}>Underline</Button> */}
							
							</div>
						</TabPanel>
						<TabPanel value={value} index={2} dir={theme.direction} className="slide-editing-option">
							<div className='videolist__main__screen d-flex' style={{ padding: '10px' }}>
								<Button color='default' style={{ background: 'none', border: 'none' }} onClick={() => { setImageUploadModal(true); setSelectedImage({});}}>Select image from my files
								<InlineSVG  style={{marginLeft:"10px"}} src='<svg width="19px" height="16px" viewBox="0 0 19 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<title>Shape</title>
									<g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g id="New-channel,-My-slides,-32%-Opacity" transform="translate(-1304.000000, -361.000000)" fill="#FFFFFF" fill-rule="nonzero">
											<g id="Text-format" transform="translate(715.000000, 348.000000)">
												<g id="Select-from-files" transform="translate(399.000000, 11.000000)">
													<path d="M191.055556,2 L195.963889,2 C196.201389,2 196.438889,2.07741935 196.65,2.23225806 L197.784722,3.16129032 C197.890278,3.23870968 197.995833,3.29032258 198.127778,3.29032258 L207.944444,3.29032258 C208.525,3.29032258 209,3.75483871 209,4.32258065 L209,16.9677419 C209,17.5354839 208.525,18 207.944444,18 L191.055556,18 C190.475,18 190,17.5354839 190,16.9677419 L190,3.03225806 C190,2.46451613 190.475,2 191.055556,2 Z M192.770833,6.64516129 C192.401389,6.64516129 192.111111,6.92903226 192.111111,7.29032258 L192.111111,7.67741935 C192.111111,7.83225806 192.216667,7.93548387 192.375,7.93548387 L206.625,7.93548387 C206.783333,7.93548387 206.888889,7.83225806 206.888889,7.67741935 L206.888889,7.29032258 C206.888889,6.92903226 206.598611,6.64516129 206.229167,6.64516129 L192.770833,6.64516129 Z" id="Shape"></path>
												</g>
											</g>
										</g>
									</g>
								</svg>'/>
								</Button>
								<Button color='default' style={{ background: 'none', border: 'none' }} onClick={() => { setStockImageSidebarIsOpen(true) }}>Select image from stock library
								<InlineSVG  style={{marginLeft:"10px"}} src='<svg width="17px" height="16px" viewBox="0 0 17 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<title>Shape</title>
									<g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g id="New-channel,-My-slides,-32%-Opacity" transform="translate(-1567.000000, -361.000000)" fill="#FFFFFF" fill-rule="nonzero">
											<g id="Text-format" transform="translate(715.000000, 348.000000)">
												<g id="Select-from-stock" transform="translate(632.000000, 11.000000)">
													<path d="M220.392308,2 C220.174359,2.02162162 220,2.21621622 220,2.43243243 L220,17.5675676 C220,17.7837838 220.217949,18 220.435897,18 L236.564103,18 C236.782051,18 237,17.7837838 237,17.5675676 L237,2.43243243 C237,2.19459459 236.782051,2 236.564103,2 C236.564103,2 220.392308,2 220.392308,2 Z M220.871795,2.86486486 L236.128205,2.86486486 L236.128205,13.8648649 L220.871795,13.8648649 L220.871795,2.86486486 Z M230.2,4.4 C230.374359,4.4 230.526923,4.46486486 230.592308,4.61621622 L230.592308,4.61621622 L235.169231,11.7513514 C235.34359,12.0108108 235.125641,12.4216216 234.798718,12.4216216 L234.798718,12.4216216 L222.157692,12.4216216 C221.852564,12.4216216 221.612821,12.0108108 221.787179,11.7513514 L221.787179,11.7513514 L225.274359,6.12972973 C225.361538,6 225.492308,5.91351351 225.623077,5.91351351 C225.775641,5.91351351 225.928205,5.97837838 226.015385,6.08648649 L226.015385,6.08648649 L227.584615,8.16216216 L229.873077,4.59459459 C229.938462,4.48648649 230.069231,4.4 230.2,4.4 Z M234,4 C234.552285,4 235,4.44771525 235,5 C235,5.55228475 234.552285,6 234,6 C233.447715,6 233,5.55228475 233,5 C233,4.44771525 233.447715,4 234,4 Z" id="Shape"></path>
												</g>
											</g>
										</g>
									</g>
								</svg>'/>
								</Button>
								<div className="alignment-block">
									<Button color='default' style={{ background: 'none', border: 'none', textAlign: 'center' }} onMouseDown={(e) => {e.preventDefault()}} onClick={() => { onAddNewComponent("image") }}>Add Image</Button>
								</div>
								<div className="alignment-block">
									<Button color='default' style={{ background: 'none', border: 'none', textAlign: 'center' }} onMouseDown={(e) => {e.preventDefault()}} onClick={() => { onDeleteComponent("image") }}>Delete Image</Button>
								</div>
							</div>
						</TabPanel>
						{!isSlidePreset && <TabPanel value={value} index={3} dir={theme.direction} className="slide-editing-option">
							<div className='videolist__main__screen d-flex' style={{ padding: '20px' }}>
								<Button color='default' style={{ background: 'none', border: 'none' }} onClick={toggleBackgroundSidebar}>Select background from library
								<InlineSVG src='<svg width="19px" height="16px" viewBox="0 0 19 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
									<title>Shape</title>
									<g id="Channels" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
										<g id="New-channel,-My-slides,-32%-Opacity" transform="translate(-1304.000000, -361.000000)" fill="#FFFFFF" fill-rule="nonzero">
											<g id="Text-format" transform="translate(715.000000, 348.000000)">
												<g id="Select-from-files" transform="translate(399.000000, 11.000000)">
													<path d="M191.055556,2 L195.963889,2 C196.201389,2 196.438889,2.07741935 196.65,2.23225806 L197.784722,3.16129032 C197.890278,3.23870968 197.995833,3.29032258 198.127778,3.29032258 L207.944444,3.29032258 C208.525,3.29032258 209,3.75483871 209,4.32258065 L209,16.9677419 C209,17.5354839 208.525,18 207.944444,18 L191.055556,18 C190.475,18 190,17.5354839 190,16.9677419 L190,3.03225806 C190,2.46451613 190.475,2 191.055556,2 Z M192.770833,6.64516129 C192.401389,6.64516129 192.111111,6.92903226 192.111111,7.29032258 L192.111111,7.67741935 C192.111111,7.83225806 192.216667,7.93548387 192.375,7.93548387 L206.625,7.93548387 C206.783333,7.93548387 206.888889,7.83225806 206.888889,7.67741935 L206.888889,7.29032258 C206.888889,6.92903226 206.598611,6.64516129 206.229167,6.64516129 L192.770833,6.64516129 Z" id="Shape"></path>
												</g>
											</g>
										</g>
									</g>
								</svg>'/>
								</Button>
								<Button color='default' style={{ background: 'none', border: 'none' }} onClick={onRemoveBackground}>Remove background selection</Button>
								<div className="d-flex align-items-center border--right">
									{/* <lable className="d-block text-center">{bgOpacity}%</lable> */}
									<lable style={{ paddingLeft: '20px', fontSize: 13 }}>Brightness</lable>
									<Slider
										min={0}
										max={100}
										value={bgOpacity}
										style={{width: 60, margin: 20}}
										onChange={changeBackgroundOpacity}
									/>
								</div>
							</div>
						</TabPanel>}
						<TabPanel value={value} index={isSlidePreset ? 3 : 4} dir={theme.direction} className="slide-editing-option">
							<div className='videolist__main__screen transition_tab' style={{ padding: '20px' }}>
								<Button className={`${props.slideTrasitions == FADELEFT ? 'transition_active' : ''}`} color='link' style={{  border: 'none' }} onClick={()=>{ props.onSelectTrasition(props.slideTrasitions == FADELEFT ? NONEANIM : FADELEFT ) }}>Fade left</Button>
								<Button className={`${props.slideTrasitions == FADERIGHT ? 'transition_active' : ''}`} color='link' style={{  border: 'none' }} onClick={()=>{ props.onSelectTrasition(props.slideTrasitions == FADERIGHT ? NONEANIM : FADERIGHT ) }}>Fade right</Button>
								<Button className={`${props.slideTrasitions == FADETOP ? 'transition_active' : ''}`} color='link' style={{  border: 'none' }} onClick={()=>{ props.onSelectTrasition(props.slideTrasitions == FADETOP ? NONEANIM : FADETOP ) }}>Fade top</Button>
								<Button className={`${props.slideTrasitions == FADEBOTTOM ? 'transition_active' : ''}`} color='link' style={{  border: 'none' }} onClick={()=>{ props.onSelectTrasition(props.slideTrasitions == FADEBOTTOM ? NONEANIM : FADEBOTTOM ) }}>Fade bottom</Button>
							</div>
						</TabPanel>
						
						{/* custom slide */}
						<CustomSlide selectedSlide={selectedSlide} changeTab={handleChange} setIsSlidePreset={setIsSlidePreset} setIsRemoveSlidePreset={setIsRemoveSlidePreset} ref={childRef} setBgOpacity={setBgOpacity} setStyleOptions={setStyleOptions} styleOptions={styleOptions} onAddNewComponent={onAddNewComponent} target={target} setTarget={setTarget}/> 
						{/* slide preset sidebar */} 
						<WaitingRoomTimelineSlidePresetsList templates={state} toggle={toggleSlideSidebar} isOpen={slideSidebarIsOpen} onSlideSelect={onSlidePresetSelect} type="template" />
						<WaitingRoomTimelineSlidePresetsList stockImages={stockImages} toggle={toggleStockImageSidebar} isOpen={stockImageSidebarIsOpen} onImageSelect={onImageSelect}  type="stockImages"/>
						<WaitingRoomTimelineSlidePresetsList backgroundImages={backgroundImages} toggle={toggleBackgroundSidebar} isOpen={backgroundSidebarIsOpen} onBackgroundImagesSelect={onBackgroundImagesSelect} type="bgImages"/>
						{colorPicker &&
							<div style={{ position: 'absolute', top: '50%', right: '50%', zIndex: 999999 }} onMouseDown={(e) => e.preventDefault()}>
								<PhotoshopPicker 
									color={pickerColor} 
									onChangeComplete={(color, event)=>{ event.preventDefault(); setPickerColor(color); } } 
									onAccept={() => { setColor(`rgba(${pickerColor.rgb.r}, ${pickerColor.rgb.g}, ${pickerColor.rgb.b}, ${pickerColor.rgb.a})`); setColorPicker(false); }}
									onCancel={() => {setColorPicker(false)}}
								/>
							</div>
						}
					</div>
                </>
			)}
			<Modal isOpen={imageUploadModal} centered={true} toggle={() => togleUploadImage()} >
                <UploadImage closeModal={() => closeModal()} isSlide={true} slideImage={ imageUploadSuccess } uriImage={selectedImage.image_url} uploadButtonLabel="Add image" />
            </Modal>
        </div>
    )
})

export default AddWaitingRoomTimelineSlides;