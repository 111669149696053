import React, { useState } from 'react';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import LoadingButton from '../../components/LoadingButton'
import { history } from '../index';
import DropDown from '../../components/DropDown'
import Device from '../../img/devices_icon.svg'; 
import { getDesignateCard, onSelecteCard } from "../../store/actions/taskAction";
import './style.scss';
import { Button , Spinner , FormGroup , Label , Row ,Col} from 'reactstrap';
import { InputField } from '../../components/InputField';
import LocationSVG from '../../img/ic_pin_drop.svg'
import Visa from '../../img/stripe/visa.svg';
import AmericanExpress from '../../img/stripe/amex1.svg';
import Discover from '../../img/stripe/discover1.svg';
import MasterCard from '../../img/stripe/mastercard1.svg';
import Loading from '../../components/Loading';
import NoItemView from '../../components/NoItemFound/NoItemView';


class SubscriptionPaymnet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      perPage: 5,
      name: "",
      last4: "",
    }
  }

  async onManageClick(selectedCard) {
    history.push({
      pathname: `${selectedCard.id}/payment-method`,
      param: { selectedCard: selectedCard }
    });
    this.props.dispatch(onSelecteCard(selectedCard)); 
    // this.props.dispatch(InvoiceViewList(invoice, null));
  }
  onDesignateClick() {
    history.push({
      pathname: '/designate-credit-card'
    });
    // this.props.dispatch(InvoiceViewList(invoice, null));
  }

  componentDidMount() {
    this.getDesignateCards();
  }

  onChangeHandler(filed, value){
    this.setState({
      currentPage: 1,
      [filed]: value,
    }, () =>{
      this.getDesignateCards();
    })
  }

  getDesignateCards(){
    var params = {
      per_page: this.state.perPage,
      page: this.state.currentPage,
    }
    if(this.state.name.toString().trim() != ""){
      params['practice_name'] = this.state.name;
    }
    if(this.state.last4.toString().trim() != ""){
      params['last4'] = this.state.last4;
    }
    this.props.dispatch(getDesignateCard(params));
  }

  goPage(page){
    this.setState({ currentPage: page }, () => {
      this.getDesignateCards()
    })
  }

  getCard(_brand){
    var brand;
    switch(_brand.toLowerCase()){
      case "visa":
        brand = Visa;
        break;
      case "mastercard":
        brand = AmericanExpress;
        break;
      case "amex":
        brand = Discover;
        break;
      case "discover":
        brand = MasterCard;
        break;
      default:
        brand = Visa;
        break;
    }
    return brand;
  }

  render() {
     return (
<div className="subscriptionpay__main__screen manage__page  d-flex flex-column h-100 align-items-center justify-content-center">
      <SubHeader className="mb-auto" title="Subscription Payment  |  Corporation"/>
        {this.props.loading && this.props.designateCard.data == undefined && <Loading />}
        <div className="d-flex w-100 mb-auto pb-5">
          <div className="d-flex flex-column mb-auto mx-auto managing-box">
          <div className="subscriptioncard__inner__panel transparent__form--white d-flex flex-column">
        <div className="subscriptioncard__inner mb-auto d-flex flex-column">
                <div className="subscriptioncard__inner__header">
                  <div className="row">
                    <div className="col-7">
                      <h4>Credit cards in use</h4>
                    </div>
                    <div className="col-5  justify-content-end d-flex left-right-arrow align-items-center">
                      <div className="location-img">
                          <img src={LocationSVG} alt="location" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex search-section">
                    <FormGroup className="mr-20 search-keyword">
                      <InputField type="text" label={'Search practice by name'} name="searchbyname"  onChange={(e) => this.onChangeHandler("name", e.target.value) }/>
                    </FormGroup>
                    <FormGroup className="mr-20 search-keyword">
                      <InputField type="text" label={`Search by credit card's last 4 digits`} name="searchbydigit"  onChange={(e) => this.onChangeHandler("last4", e.target.value) }/>
                    </FormGroup>
                  </div>
                </div>

                <div className="subscriptioncard__inner__body flex-grow-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="327">PRACTICE NAME</th>
                        <th width="347">Primary credit card</th>
                        <th width="344">Secondary credit card</th>
                        <th width="175"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.designateCard && this.props.designateCard.data && this.props.designateCard.data.map((x, index)=> {
                        var primaryCard = x.payment_cards.find(card => card.is_primary == 1);
                        var secondryCard = x.payment_cards.find(card => card.is_primary == 0);
                          return(<tr key={index}>
                            <td>{x.name}</td>
                            {primaryCard ? <td><img src={this.getCard(primaryCard.brand)} alt="card" />  XXXX XXXX XXXX {primaryCard.last4}</td> : <td>n/a</td>}
                            {secondryCard ? <td><img src={this.getCard(secondryCard.brand)} alt="card" />  XXXX XXXX XXXX {secondryCard.last4}</td> : <td>n/a</td>}
                            <td ><Button size="md" color="primary" onClick={() => this.onManageClick(x)}>Manage</Button></td>
                          </tr>)
                       })
                      }
                    </tbody>
                  </table>
                  {!this.props.loading && (this.props.designateCard && this.props.designateCard.data && this.props.designateCard.data.length == 0) &&
                    <NoItemView isShow={true} message="No credit card found" />
                  }
                  <div className="add_new_location">
                  <div className="button-section text-center">
                    <Button size="md mw-100 w-auto px-2" color="primary" onClick={() => this.onDesignateClick()}>Designate a credit card to multiple locations</Button>
                  </div>
                </div>
                </div>

                <div className="notification__inner__footer d-flex justify-content-center align-items-center">
                  {this.props.designateCard.total > 0 &&
                    <ul>
                      {this.props.designateCard.total && new Array(Math.ceil(this.props.designateCard.total / this.props.designateCard.per_page)).fill(0).map((x, index) =>
                        <li key={index}><Button type="button" className={`p-3 ${index + 1 == this.state.currentPage && 'activepage'}`} size="md" color="link" onClick={() => this.goPage(index + 1)}>{index + 1}</Button></li>
                      )}
                    </ul>
                  }
                </div>

            </div>
        </div>
          </div>
         
        </div>
      </div>

    )
  }

}

const mapStateToProps = (state) => ({
  designateCard: state.task.designateCard,
  loading: state.task.loading,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(SubscriptionPaymnet);