import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import { Ownership  , operatories} from '../../../data/comman';
import HttpUtility, {HELPERURI} from '../../../utilities/HttpUtility';

import Loading from '../../../components/Loading'


import Stape1 from './PracticeInfo'
import Stape2 from './AdditionalInfo'
import Stape3 from './PracticeHours'
import Stape4 from './Review'

import './style.scss';


class InitialSetup extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      practiceProfileInfo:{
        ...props.practiceProfileInfo,
          salesRepresentative:'', 
          practicetype:'',
          distributor:'',
          systemlanguage:'',
          medialanguage:'',
          nooftvs:'',
          source:'',
          locationManager:'',
          additional_info:{ 
            location_manager:'',
            software: [],
            source: '',
            schedule:props.practiceProfileInfo?.additional_info?.schedule,
            distributor_name:'',
            operatory_count:''
          }
      },
      allcountry: props.country,
      lead_source_types:props.lead_source_types,
      practice_softwares:props.practice_softwares,
      practice_customer_types:props.practice_customer_types,
      selectedcountry:'',
      step: 1,
      activeClass:'',
      stepOneNext: false,
      stepTwoNext: false,
      isError:{
        name:'',
      }
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.gotoBasicInfo = this.gotoBasicInfo.bind(this);
    this.gotoAdditionalInfo = this.gotoAdditionalInfo.bind(this);
    this.gotoPracticeHours = this.gotoPracticeHours.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  //next step
    nextStep(){
      const { step } = this.state;
      this.setState({
          step: step + 1,
      })
  }

  // prev step
  prevStep(){
      const { step } = this.state;
      this.setState({
          step: step - 1,
      })
  }

  gotoBasicInfo(){
    this.setState({
      step: 1,
    })
  }

  gotoAdditionalInfo(){
    this.setState({
      step: 2,
    })
  }

  gotoPracticeHours(){
    this.setState({
      step: 3,
    })
  }

  handlePhoneChange(value, country, e, formattedValue ){
    var fieldValue = "";
    var fieldname = e

  console.log(fieldValue,country,formattedValue, e, 'formattedValuecountrycountrycountry')
    fieldValue = value;

    fieldValue = fieldValue.replace(country.dialCode,'');    // 345
    fieldValue = '+'+country.dialCode+'-'+ fieldValue;
    let isError = { ...this.state.isError };

    this.setState({
      isError,
      isGetCode:true,
      practiceProfileInfo:{
            ...this.state.practiceProfileInfo,
            [fieldname]: fieldValue
        }
    },()=> {this.isValid(fieldname, fieldValue)})
  }

  handleChange(input, event, type, isMultiple= false){
    let value;

    if(type){
        var dropDownName =  'selected' + input;
        if(isMultiple){
            value = [];
            event.map((item)=>{
               return value.push(item);
            })
            this.setState({ [dropDownName]: value  })
        }else{
            if(dropDownName === "selectedcountry"){
                value = event;
                this.setState({ countryState: [] });
                this.getState(value.id);
            }else{
              value = event;
            }
        }
       this.setState({ [dropDownName]: event  })
    }else{
        value = event.target.value;
    }
    
    this.setState({
      practiceProfileInfo: {
            ...this.state.practiceProfileInfo,
            [input]: value
        }
    },()=> {this.isValid()});
  }

  async isValid(){

    let isError = this.state.isError ;
    const { step , practiceProfileInfo } = this.state;
    
    console.log(practiceProfileInfo ,'practiceProfileInfopracticeProfileInfopracticeProfileInfopracticeProfileInfo')

      const { name , ownership , phone  , email , specializations , city , address_line_1 , address_line_2 , zip , state  , 
              source  ,softwares, operatories ,selectedoperatories , practicetype , locationManager , salesRepresentative  , distributor , systemlanguage , nooftvs , medialanguage , selectedstate , selectedownership} = this.state.practiceProfileInfo;

      if( step === 1  && name !== '' && ownership !== ''  
          && phone !=="" && email !=='' && specializations && specializations.length !== 0
          && city !== '' && address_line_1 !== '' 
          && address_line_2 !== '' && zip !== '' && state !== '' && selectedstate && selectedownership){
            
          this.setState({ stepOneNext: true });

      }else if(step === 2 && locationManager !== '' &&  source !== undefined && softwares !== undefined && selectedoperatories !== undefined 
              && practicetype !== undefined  && systemlanguage!== '' && nooftvs!== '' && medialanguage!== '') {
          
                this.setState({ stepTwoNext: true });
          
      }else{

        this.setState({ stepOneNext: false , stepTwoNext:false});

      }

    this.setState({isError})
  }

  async componentDidMount(){
    if(await this.state.practiceProfileInfo?.country_id){
      var country = this.props.country.find(b => b.id === this.state.practiceProfileInfo.country_id)
      if(!!country){
       await this.getState(country.id);
      }
    }
    var selectedownership = null;
    if(this.state.practiceProfileInfo.ownership != null && this.state.practiceProfileInfo.ownership != undefined){
      selectedownership  = Ownership.find(b => b.id === this.state.practiceProfileInfo.ownership)
    }


    var specializations = []
    if(this.state.practiceProfileInfo.specializations){
        this.state.practiceProfileInfo.specializations.map((ids)=>{
          specializations.push(this.props.specializations.find(b => b.id == ids))
        });
    }

    this.setState({
      practiceProfileInfo: {
            ...this.state.practiceProfileInfo,
            country,
            selectedownership,
            specializations,
        }
    }, ()=>{
      this.isValid();
    });
  }

async  getState(countryId){
    HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
          this.setState({selectedstate:'' , stepOneNext:false})
            var countryState = response.data;
            var selectedstate = null;
            if(this.state.practiceProfileInfo.state_id){
              selectedstate = countryState.find(b => b.id === this.state.practiceProfileInfo.state_id)
            }
            this.setState({
              practiceProfileInfo: {
                    ...this.state.practiceProfileInfo,
                    countryState,
                    selectedstate,
                },
                stepOneNext: false 
            },() => {
              this.isValid();
            });
        }
    });
  }

  renderStep(step , index){

      const { 
              name, ownership ,phone ,email, 
              specializations , billing_type, 
              city,address_line_1,address_line_2,
              zip,available_domain,available_device,
              subscription_expiry,country ,state  ,softwares,salesRepresentative,distributor,systemlanguage,medialanguage,nooftvs,selectedcountry,
              selectedstate,selectedownership,countryState,operatories,selectedoperatories,source,locationManager,practicetype,phone_alt,
              additional_info:{ 
                location_manager,
                software ,
                schedule,
                distributor_name,
                operatory_count
              }

      } = this.state.practiceProfileInfo;

      const lead_source_types = this.state.lead_source_types
      const practice_softwares = this.state.practice_softwares
      const practice_customer_types = this.state.practice_customer_types
      
      var {  selectedsource , selectedsoftwares ,  selectedpracticetype      } = this.state;

      const values =  {  name, ownership ,phone ,email, billing_type, city,address_line_1,address_line_2,zip,available_domain,available_device,subscription_expiry,
                        country ,state , countryState , specializations, selectedcountry, selectedstate, selectedownership  , lead_source_types , practice_softwares  
                        , selectedsource , practice_customer_types , selectedsoftwares  , softwares , selectedpracticetype , practicetype,
                        distributor , operatories,selectedoperatories, salesRepresentative ,systemlanguage,medialanguage,nooftvs,source,locationManager,phone_alt,
                        additional_info:{ 
                            location_manager,
                            software,
                            schedule,
                            distributor_name,
                            operatory_count
                        }
                      };
    // console.log(values,'specializations values' ,  this.state.practiceProfileInfo , ' this.state.practiceProfileInfo')
    switch(step){
      
        case 1:
           
            return  (<Stape1 
                      nextStep= { this.nextStep }
                      isNext={this.state.stepOneNext}
                      handleChange = {this.handleChange}
                      handlePhoneChange={this.handlePhoneChange}
                      values={values}
                    />)
                    
        case 2:
            return  <Stape2 
                      nextStep= { this.nextStep }
                      prevStep = {this.prevStep }
                      isNexttwo={this.state.stepTwoNext}
                      handleChange = {this.handleChange}
                      values={values}
                      practiceProfileInfo={this.props.practiceProfileInfo}

                    />
        // case 3:
        //     return <Stape3 
        //               nextStep= { this.nextStep }
        //               prevStep = {this.prevStep }
        //               isNext={this.state.stepThreeNext}
        //               values={values}
        //             />
        // case 4:
        //     return <Stape4 
        //               nextStep= { this.nextStep }
        //               prevStep = {this.prevStep }
        //               gotoBasicInfo = {this.gotoBasicInfo}
        //               gotoAdditionalInfo = {this.gotoAdditionalInfo}
        //               gotoPracticeHours ={this.gotoPracticeHours}
        //               values={values}
        //               practiceProfileInfo={this.props.practiceProfileInfo}
        //           />
        default :
          return <Stape1/>
    }
  }

  render (){
    const { step } = this.state;
    const { loading } = this.props
    
    // if (loading) {
    //   return  <Loading />
    // }

    return(
      <>
        {/* <div className="custom__multi__step__form ">
            <ul className="custom__multi__step__form--progressbar">
              <li className={`custom__multi__step__form--step ${step == 1 ? 'active' : ''}`}>
                <span></span>
              </li>
              <li className={`custom__multi__step__form--step ${step == 2 ? 'active' : ''}`}>
                <span></span>
              </li>
            </ul>
        </div> */}

        { this.state.practiceProfileInfo ? 
          <div className='mt-5'>
            {this.renderStep(step)}
          </div>
          :
          null
        }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({

  country: state.task.country,
  specializations: state.task.specializations,
  practiceInfo: state.task.practiceInfo,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types: state.task.practice_customer_types,
  media_language: state.task.media_language,
  loading: state.task.loading,
  error: state.task.error,
  practiceProfileInfo: state.task.practiceProfileInfo,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(InitialSetup);