/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { Button } from 'reactstrap';
import LoadingButton from '../../components/LoadingButton'
import close from '../../img/Close_small_white.svg'
import './styles.scss';
import {
	getCustomMessage,
	deleteWaitingRoomCustomMessage,
	putChannelwiseCustomMessages,
} from '../../store/actions/taskAction';
const Card = forwardRef(function Card( { text, isDragging, connectDragSource, connectDropTarget , card_order, changeDisabled , isDisabled}, ref) {

	const state = useSelector((state) => state.task);
	const dispatch = useDispatch();
	const [showHoverRemoveButton,setShowHoverRemoveButton] = React.useState(false)
	const elementRef = useRef(null);
	const [id, setId] = useState(null);
	const [count, setCount] = useState(0);
	const [showRemoveDeleteButton, setShowCancelDeleteButton] = useState(false);
	const [messageColor,setMessageColor] = React.useState(false)
	const [showRemoveButton, setShowRemoveButton] = useState(false);
	const [changeStyle, setChangeStyle] = useState({
		backgroundColor: 'rgba(216, 216, 216, 0.2)',
		display: 'block',
	});
	connectDragSource(elementRef);
	connectDropTarget(elementRef);
	const opacity = isDragging ? 0 : 1;
	useImperativeHandle(ref, () => ({
		getNode: () => elementRef.current,
	}));

	// Show Remove Cancel Button
	const showCancelRemoveButtons = (key, event) => {
		setId(key);
		setShowRemoveButton(false);
		setShowCancelDeleteButton(true);
		setChangeStyle({
			backgroundColor: '#e7282e',
			display: 'none',
		});
	};

	// Show Remove Button
	const showRemoveButtons = (key, event) => {
		setId(key);
		// setShowRemoveButton(true);
		setShowHoverRemoveButton(false)
		setShowCancelDeleteButton(true)
		setMessageColor(true)
		changeDisabled()
	};
	// When someone clicks on cancel button
	const clickedCancelButton = (event) => {
		changeDisabled()
		setShowCancelDeleteButton(false);
		setChangeStyle({
			backgroundColor: 'rgba(216, 216, 216, 0.2)',
			display: 'block',
		});
		setMessageColor(false)
	};
	// When someone clicks on remove button
	const clickedRemoveButton = (key, event) => {

		let params = {
			message_id: key,
		}
		dispatch(
			deleteWaitingRoomCustomMessage(state.managewaitingRoomMediaChannel.id, params)
		);
		changeDisabled()

		// setMessageColor(false)
		// setCount(count + 1);

		// setShowCancelDeleteButton(false);
	};

	React.useEffect(()=>{
		if(!state.buttonLoading){
			setShowHoverRemoveButton(false)
			setMessageColor(false)
			setShowRemoveButton(false)
			setShowCancelDeleteButton(false)
		}

	},[state.buttonLoading])

	return (
		<div ref={elementRef}  className="d-flex  drag__items align-items-center">
			<div className={`drag drag__items--box d-flex align-items-center ${messageColor ? 'bg_red' : ''}`}>{text}</div>

			{/* {!showRemoveButton && !showRemoveDeleteButton &&
				<Button type="button" color="link " onClick={() => showRemoveButtons(card_order.id)} className='btn-close p-0 mr-4' >
					<img src={close} alt="" />
				</Button>
			} */}
			{!showHoverRemoveButton && !showRemoveDeleteButton && <Button type="button" color="link " disabled={isDisabled || state.buttonLoading} onMouseOver={()=>setShowHoverRemoveButton(true)} onMouseLeave={()=>setShowHoverRemoveButton(false)} className='btn-close p-0 mr-4' >
					<img src={close} alt="" />
				</Button>
				}
			{
				showHoverRemoveButton && <Button type="button" size={'sm'} color="red" onMouseLeave={()=>setShowHoverRemoveButton(false)} onClick={() => showRemoveButtons(card_order.id)} id={card_order.id} className='remove_btn'>
				Remove
			</Button>
			}
			{showRemoveButton && !showRemoveDeleteButton &&  card_order.id === id ? (
				<Button type="button" size={'sm'} color="red" onClick={() => showCancelRemoveButtons(card_order.id)} id={card_order.id} className='remove_btn'>
					Remove
				</Button>
			) : ('')}

			{showRemoveDeleteButton && card_order.id === id ? (
				<>
					<Button type="button" size={'sm'} onClick={clickedCancelButton}  className='cancel_btn mr-20' color="white">
						Cancel
					</Button>{' '}
					<LoadingButton type="button" size={'sm'} color="red" label={'Remove'} disabled={state.buttonLoading} loading={state.buttonLoading} onClick={() => clickedRemoveButton(card_order.id)} id={card_order.id} className='remove_btn' />
				</>
			) : (
				''
			)}
		</div>
	);
});
export default DropTarget(
	'card',
	{
		hover(props, monitor, component) {
			if (!component) {
				return null;
			}
			// node = HTML Div element from imperative API
			const node = component.getNode();
			if (!node) {
				return null;
			}
			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = node.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			// Time to actually perform the action
			props.moveCard(dragIndex, hoverIndex);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			monitor.getItem().index = hoverIndex;
		},
	},
	(connect) => ({
		connectDropTarget: connect.dropTarget(),
	})
)(
	DragSource(
		'card',
		{
			beginDrag: (props) => ({
				id: props.id,
				index: props.index,
			}),
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging(),
		})
	)(Card)
);
