import React from 'react'
import styles from './style.module.scss';

const TopBanner = () => {
  return (
    <div className={`${styles.topBanner} d-flex p-4 w-100 text-center justify-content-center align-items-center`}>
      This is an emulation of the Thalamus Google TV app.
    </div>
  )
}

export default TopBanner
