import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchChairsideMedia, deleteFromMyListChairsideEducation, postAddToMyListChairsideEducation, ShowCloseCaptionChairsideEducation, HideCloseCaptionChairsideEducation, setShareVideoId, shareVideoLinkClearData } from "../../../store/actions/taskAction";
import { Modal, Button, Spinner } from "reactstrap";
import Player from '../../../components/videoPlayer/player';
import Profile_icon from '../../../img/play_white.svg'
import Check_Sign from '../../../img/check-sign.png'
import downArrow from '../../../img/down-arrow.svg'
import IconPlas from '../../../img/IconPlas.svg'
import Icon_favourites from '../../../img/Icon_favourites.svg'
import { history } from '../../../pages/index';
import { formatSecond , RECENTPLAY } from "../../../data/comman";
import LoadingButton from '../../../components/LoadingButton';
import EditeImageModal from '../../../components/EditImages/EditImageModel';
import VideoSliderImageSkeleton from "../../../components/Skeletons/videoSliderImageSkeleton"
import Video_share_icon from '../../../img/Video_share_icon.svg'

import '../style.scss';
const Search = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.task);
    const [showResults, setShowResults] = useState(-1);
    const [searchText, setSearchText] = useState(null);
    const [searchTextDispatch, setSearchTextDispatch] = useState(null);
    const [videosList, setVideosList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [editorImg, setEditorImg] = useState({});
    const [isEditor, setIsEditor] = useState(false);
    const [showVideo, setShowVideo] = useState({
        video: {},
        showVideo: false,
    });
    const [modal, setModal] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);


    const changeHandler = (event) => {
        setSearchText(event.target.value);
        // console.log("Searched Video", event.target.value);

    }
    const onClickClose = (close) => {
        setShowVideo({
            video: {},
            showVideo: false,
        });
    };

    const openImgEditor = (img) => {
		if (img) {
			setEditorImg(img)
			setIsEditor(true)
			onClickClose()
		}
    }

    const openVideoPlayer = (video) => {
        setShowVideo({
            video: video,
            showVideo: true,
        });

        var recentPlayLocal = localStorage.getItem(RECENTPLAY);
		if(recentPlayLocal != null){
			var recentPlay = JSON.parse(localStorage.getItem(RECENTPLAY));
			var index = recentPlay.findIndex(x => x == video.chairside_media_id);
			if(index > -1){
				recentPlay.splice(index, 1);
			}
			recentPlay.unshift(video.chairside_media_id);
			localStorage.setItem(RECENTPLAY, JSON.stringify(recentPlay));
		}else{
			var recentPlay = [];
			recentPlay.unshift(video.chairside_media_id)
			localStorage.setItem(RECENTPLAY, JSON.stringify(recentPlay));
		}
    };

    // Hover on Plus icon
    const hoverHandler = (video, type) => {
        if (type === 'enter') {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };
    // Remove from My List
    const removeFromMyList = (video) => {
        dispatch(deleteFromMyListChairsideEducation(video.chairside_media_id));
        setTimeout(() => {
            dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
        }, 600);
    }

    const togglePip = (isPipOn) => {
		setShowVideo({
			...showVideo,
			showVideo: !isPipOn,
		});
	}

    // Add to my list
    const addToMyList = (video) => {
        let params = {
            media_id: video.chairside_media_id
        }
        dispatch(
            postAddToMyListChairsideEducation(params)
        );

        setTimeout(() => {
            dispatch(fetchChairsideMedia(state.practiceUserLoginInfo));
        }, 600);
    };
    const toggle = () => setModal(!modal);

    useEffect(() => {
        // dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
        if (searchText !== null && searchText.trim().length > 0) {
            const timerId = setTimeout(() => {
                setLoading(true);
                setSearchTextDispatch(searchText);
            }, 700)
            return () => {
                clearTimeout(timerId);
                setLoading(false);

            }
        }
    }, [searchText]);

    useEffect(() => {
        // dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
        const param = state.practiceUserLoginInfo;
        param['search'] = searchTextDispatch;
        // dispatch(fetchChairsideMedia(param));
        // setLoading(true);
        filterChairSideMediaState();
    }, [searchTextDispatch, state.chairSideMediaState]);

    const filterChairSideMediaState = () => {
       
        // console.log("filter");
        let searchedVideos = [];
        if (searchText !== null && searchText?.trim().length > 0 && state?.chairSideMediaState.length > 0) {
            state.chairSideMediaState.map((video, index) => {
                if ((video?.chairside_media?.media_language?.title?.toLowerCase().indexOf(searchText?.toLowerCase().trim())) >= 0 || (video?.category?.title?.toLowerCase().indexOf(searchText?.toLowerCase())) >= 0) {
                    searchedVideos.push(video);
                }
            });

            if(searchedVideos.length === 0){
                setSearchStatus(true)
            }
        }
        setVideosList(searchedVideos);
        setLoading(false);
    }

    //Show CC
    const showCC = (video) => {
        let params = {
            media_id: video.chairside_media_id
        }
        dispatch(ShowCloseCaptionChairsideEducation(params));
        }

    //Hide CC
    const hideCC = (video) => {
        dispatch(HideCloseCaptionChairsideEducation(video.chairside_media_id));
    }
    var n;

    const onShareVideoClick = async (video) =>{
		dispatch(setShareVideoId(video.chairside_media_id));
        dispatch(shareVideoLinkClearData());
		history.push('/email-video')
	}
   
    return (
        <>
         {!isEditor ? 
        <div className="search-page" style={{ marginTop: "4px" }}>
            <input style={{ marginLeft: "15px" }} type="text" className="search_main" value={searchText} onChange={changeHandler} placeholder="Search by title or specialization" />
            {loading ?
                <Spinner color='primary' />
                : (<div>
                    {videosList.length > 0 && !loading ?
                        <>
                            <br /><h3 style={{ color: "white", marginTop: "20px", marginLeft: "15px", marginBottom: "11px" }}>Results</h3>
                            <div className="myListCatelogue search-video-page">
                                {videosList.map((video, index) => {
                                    return (
                                        <div key={index} className={`${showResults == index ? 'showResultsActive' : ''} mylist-box`} onClick={() => setShowResults(index)}>
                                            <div className="card" >
                                                {/* <img src={video.chairside_media.thumb} alt={video.chairside_media.media_language.thumb_alt_text} /> */}
                                                <VideoSliderImageSkeleton src={video.chairside_media.thumb} alt={video.chairside_media.media_language.thumb_alt_text} />
                                                <div className="subTitle">
                                                    <div className="video_icon" onClick={() => openVideoPlayer(video)}>
                                                        <img src={Profile_icon} alt="play_video" onClick={() => openVideoPlayer(video)} />
                                                    </div>
                                                    <p className="smalltitile">{video.chairside_media.media_language.title} | {formatSecond(video.chairside_media.media_language.media.duration)}</p>
                                                    <p className="title">{video.category.title}</p>
                                                    {video.chairside_media.in_mylist === 1 &&
                                                        <div className="check-sign">
                                                            <img src={Check_Sign} alt="check-sign" />
                                                        </div>
                                                    }
                                                    <div className="video_downarrow"><img src={downArrow} alt="" /></div>
                                                </div>
                                                <p className="category_title">
                                                    <div
                                                    dangerouslySetInnerHTML={{
                                                    __html : video?.chairside_media?.media_language?.title?.replace('ß', "<span className\"match\">ß</span>")
                                                    }} />
                                                </p>
                                            </div>
                                            {showResults == index ?
                                                <div className="dropdown-chevron" style={{ backgroundImage: `url(${video.chairside_media.thumb})` }}>
                                                    <div className="hover_description">
                                                        <div className="hover_description__div">
                                                         

                                                            <p className="hover_description__title">
                                                                <div
                                                                dangerouslySetInnerHTML={{
                                                                __html : video?.chairside_media?.media_language?.title.replace('ß', "<span className\"match\">ß</span>")
                                                                }} />
                                                            </p>
                                                            
                                                            <p className="hover_description__duration">Duration : {formatSecond(video.chairside_media.media_language.media.duration)}<br />
                                                    Specialization : {video.category.title}</p>
                                                            {<div className="hover_description__description" dangerouslySetInnerHTML={{ __html: video.chairside_media.media_language.description }} />}
                                                        </div>
                                                        {/* <img className="hover_description__image" src={ showDetail.image }/> */}
                                                    </div>
                                                    <div className="dropdown-button d-flex">
                                                        <Button color="primary" size="md" onClick={() => openVideoPlayer(video)}>Play video</Button>
                                                        <Button type="button" size="md" onClick={() => { history.push({ pathname: '/chairside-education-edit-close-caption', state: { video: video } }) }} color="white" >View & Edit captions</Button>
                                                        <div className="text-center">
                                                            <LoadingButton type="button" label="CC" loading={video.chairside_media.isCCLoading} disabled={video.chairside_media.isCCLoading} onClick={() => { video.chairside_media.cc_status == 0 ? showCC(video) : hideCC(video) }} color="default white cc-btn" className={`${video.chairside_media.cc_status == 0 ? ' ' : 'active'} `} outline />
                                                            <div className='cc_status_btn_text'>{video.chairside_media.cc_status == 0 ? 'off' : 'on'}</div>
                                                        </div>
                                                        <Button className="check-sign"><img src={Check_Sign} alt="check-sign" /></Button>

                                                        {showButton === false ? (
                                                            <LoadingButton
                                                                color={`${state.buttonLoading3 ? 'default white px-3 d-flex align-items-center' : ' white' }`}
                                                                outline
                                                                type="button"
                                                                className="p-0"
                                                                disabled={state.buttonLoading3}
                                                                loading={state.buttonLoading3}
                                                                onMouseEnter={() => hoverHandler(video, 'enter')}
                                                                onMouseLeave={() => hoverHandler(video, 'leave')} style={{ height: '30px', width: '30px' }}>
                                                                <img src={video.chairside_media.in_mylist === 1 ? Icon_favourites : IconPlas} alt="" />
                                                            </LoadingButton>
                                                        ) : video.chairside_media.in_mylist === 1 ? (
                                                            <LoadingButton
                                                                color='primary'
                                                                type="button"
                                                                size="md"
                                                                loading={state.buttonLoading3}
                                                                onMouseLeave={() => hoverHandler(video, 'leave')}
                                                                onClick={() => removeFromMyList(video)}>
                                                                REMOVE FROM MY LIST
                                                            </LoadingButton>
                                                        ) : (
                                                                    <LoadingButton
                                                                        color='white'
                                                                        type="button"
                                                                        size="md"
                                                                        loading={state.buttonLoading3}
                                                                        onMouseLeave={() => hoverHandler(video, 'leave')}
                                                                        onClick={() => addToMyList(video)}>
                                                                        ADD TO MY LIST
                                                                    </LoadingButton>
                                                                )}
                                                        {state.practiceInfo.days_left != 0 && 
                                                            <Button color="link" className='p-0' onClick={() => onShareVideoClick(showVideo.video)}><img src={Video_share_icon} alt="Video share"/></Button>
                                                        }
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        : searchStatus ? <h2 className='ml-4 mt-5'>No result found for the {searchText}</h2> : ''
                    }
                </div>)
            }
            {/* <ShowVideos title = 'Search Results' videos ={videosList}/> */}

            {showVideo.video.id  ? (
                <div size="xxl" className={`${showVideo.showVideo ? 'bg-block' : 'bg-none'} h-100 d-flex flex-column align-items-strach justify-content-center modal-video`} isOpen={showVideo.video} toggle={toggle}>
                    <div className={`${showVideo.showVideo ? 'd-block' : 'd-none'} video-show sdf  mt-5`}> 
                        <Player
                            video={showVideo.video}
                            title={showVideo.video}
                            className="w-100 h-100"
                            onClickClose={onClickClose}
                            openImgEditor={openImgEditor}
                            togglePip={togglePip}
                        />
                    </div>
                </div>
            ) : (
                    ''
                )}
        </div>
         :
         <EditeImageModal
             open={true}
             closeEditor={() => { setIsEditor(false) }}
             // setOpen={setOpen}
             screenShotImage={editorImg}
         />
        }
        </>
    )
}

export default Search
