import React from 'react';
import './style.scss';

import { Button } from 'reactstrap';
import {ReactComponent as HomeIcon} from '../../img/home_icon.svg';
import {ReactComponent as ArrowLeft} from '../../img/arrow_left.svg';
import {/*useTranslation */  withTranslation} from "react-i18next";
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import { LoginPracticeLocation, setGoogleTvTestDriveOnorOff } from "../../store/actions/taskAction";
import { setToken  } from '../../helper/LocalStorage';

class SubHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      googleTvTestDrive:false
    }
    this.setGoogletv = this.setGoogletv.bind(this);
  }
  handleClick(){
    if(history.location.pathname == "/website-player-customization")
      history.replace("select-category-website-videos");
    else if(history.location.pathname == "/select-category-website-videos")
      history.replace("channels");
    else if(history.location.pathname == "/channels/waiting-room-channels")
    history.replace("/corporate");
    else if(history.location.pathname == "/channels/operatory-channels")
    history.replace("/corporate");
    else if(history.location.fromtrial)
    history.push({
      pathname: '/profileselection',
      fromtrial:true
    });
      else
      history.goBack();
  } 

  onHomeClick(){
    if(this.props.practiceInfo.is_corporate == 1){
      history.push('/corporate')
    }else{
      history.push('/channels')
    }
  }
  
 async onBackToMainPractice(){
    let params = {
      back_to_practice: 1,
    }
    if(this.props.isCorporate == 1){
      params['is_corporate'] = 1;
    }
    await this.props.dispatch(LoginPracticeLocation(params))
    // var token = this.props.practiceUserLoginInfo.token_info.token_type + ' ' + this.props.practiceUserLoginInfo.token_info.access_token
    // await setToken(`${token}`);
    // await history.push('/channels');
  }

  onClickInvoices(){
    history.push({ pathname: '/invoices', state: { practiceName: this.props.practiceProfileInfo?.name } });
  }

 async setGoogletv(){

   await this.setState({
    googleTvTestDrive: !this.state.googleTvTestDrive
  });

  await this.props.dispatch(setGoogleTvTestDriveOnorOff(this.state.googleTvTestDrive))
  await history.push("/google-tv-test-drive")
  }
  
  render (){
    const {practiceUserLoginInfo, loginPracticeLocationinfo , resetAccessCode, selectedTab, changeValue} = this.props
    // const {t , practiceUserLoginInfo } = this.props
    return(
      <div className={`d-flex align-items-center header--sub justify-content-between w-100 mt-4 ${this.props.className} `}>
          <div className="col d-flex align-items-center">
          
          {practiceUserLoginInfo && practiceUserLoginInfo.token_info && 
          <>
            <Button disabled={resetAccessCode && resetAccessCode.isdisabled} className={`${history.location.pathname !== '/welcome' ? 'border-right' : '' } d-flex align-items-center py-1 pr-20  pl-0 mr-20`} onClick={() => this.onHomeClick()}  color="link">
              <HomeIcon/>
            </Button>

            {history.location.pathname !== '/welcome' && !selectedTab &&
              <Button disabled={resetAccessCode && resetAccessCode.isdisabled} type="button"  onClick={() => this.handleClick()}  color="link" className="py-1 pl-0 d-flex align-items-center"><ArrowLeft className="mr-4"/><span>Back</span></Button>
            }
            
            {selectedTab &&  
              <Button disabled={resetAccessCode && resetAccessCode.isdisabled} type="button" onClick={() => changeValue(false)}  color="link" className="py-1 pl-0 d-flex align-items-center"><ArrowLeft className="mr-4"/><span>Back</span></Button>
            }
          </>
          }
           {history.location.pathname == '/signin' &&  
              <Button disabled={resetAccessCode && resetAccessCode.isdisabled} type="button"  onClick={() => this.handleClick()}  color="link" className="py-1 pl-0 d-flex align-items-center"><ArrowLeft className="mr-4"/><span>Back</span></Button>
            }
          </div>
          <div className="col practice_name text-center subtitle">
              {this.props.title}
          </div>
          <div className="col d-flex align-items-center justify-content-end">
          {practiceUserLoginInfo && practiceUserLoginInfo.token_info && this.props.practiceProfileInfo?.plan?.nick_name != "50 Website videos" &&
            <Button disabled={resetAccessCode && resetAccessCode.isdisabled}  color="link" className="py-1 pl-0 d-flex align-items-center invoice-btn" style={{paddingRight:"0"}} onClick={()=> this.setGoogletv()}><span>Google TV Test Drive</span></Button>
          }
          {practiceUserLoginInfo && practiceUserLoginInfo.is_manage_location === 1 &&
            <Button  color="primary" className="mx-4" onClick={() => this.onBackToMainPractice()} >Back to main practice</Button>
          }
          {history.location.pathname == '/billing-card' &&  
           <Button  color="link" className="py-1 pl-0 d-flex align-items-center invoice-btn" onClick={() => this.onClickInvoices()}  style={{paddingRight:"0"}}><span>Invoice</span></Button>
          }
          {history.location.pathname == ((this.props.practiceId ? `/${this.props.practiceId}` : "") + "/payment-method") &&  
           <Button disabled={resetAccessCode && resetAccessCode.isdisabled} color="link" className="py-1 pl-0 d-flex align-items-center invoice-btn" style={{paddingRight:"0"}} onClick={()=>history.push({pathname: "./invoices", param: { practiceId: this.props.practiceId ,  practiceName: this.props.title}})}><span>Invoice</span></Button>
          }
          {history.location.pathname == '/channel-detail'  ?  
           <Button disabled={resetAccessCode && resetAccessCode.isdisabled} color="link" className="py-1 pl-0 d-flex align-items-center" style={{paddingRight:"0"}} onClick={()=>history.push("/help")}><span>Help</span></Button>
          :
          <>
          {practiceUserLoginInfo && practiceUserLoginInfo.token_info &&
            <Button disabled={resetAccessCode && resetAccessCode.isdisabled}  color="link" className="py-1 pl-0 d-flex align-items-center" style={{paddingRight:"0"}} onClick={()=>history.push("/help")}><span>Help</span></Button>
           }
           </>
           }
           
           
            </div>
      </div>
    ) 
  }
}
// export default SubHeader;
const mapStateToProps = (state) => {
  return {
    practiceInfo: state.task.practiceInfo,
    practiceProfileInfo: state.task.practiceProfileInfo,
    practiceUserLoginInfo: state.task.practiceUserLoginInfo,
    practiceUserLogoutInfo:state.task.practiceUserLogoutInfo,
    loginPracticeLocationinfo:state.task.loginPracticeLocationinfo,
    resetAccessCode:state.task.resetAccessCode,
    isCorporate:state.task.isCorporate

  }
};

export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(SubHeader);
