import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';

import { Button , Progress , Spinner } from 'reactstrap';
import close_small from '../../img/Close_small.svg'
import noun_drag_and_drop from '../../img/noun_drag_and_drop.svg'
import IconSuccessful_loading from '../../img/charm_tick.svg'
import {ReactComponent as CloseIcon} from '../../img/IconCloseCircle.svg';
import {ReactComponent as ErrorIcon} from '../../img/clarity_error-standard-solid.svg';
import {ReactComponent as FileTypeIcon} from '../../img/file_type_icon.svg';
import {ReactComponent as FileTypeYoutubeIcon} from '../../img/file_type_youtube_icon.svg';

import './style.scss';

const FileUpload = ({note, files, cancelUpload, onChange, onDrop}) => {
    // console.log("FileUpload",files)
    const [darg, setDrag] = useState(false);
    return (
            <div className="file_upload__box d-flex flex-column" style={{ backgroundColor: darg ? 'pink' : '' }}  onDrop={onDrop} 
            onDragOver={(e) => {e.preventDefault(); /*setDrag(true)*/}}
            // onDragEnter={this.dragEnter}
            onDragLeave={() => setDrag(false)}>
            <div className="upload_file_list p-4">
            {
                files && files.map((file, index) => 
                    <div key={index} className="file_upload__item mb-3  d-flex align-items-center justify-content-between">
                        <div className='mr-3'>{file.type === "youtube" ? <FileTypeYoutubeIcon /> : <FileTypeIcon />}</div>
                        {console.log(file,'filefilefile')}
                        <div className='d-flex flex-column w-100'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span className="file_upload__item--name">{file.name}</span>
                                {!file.error  && file.loaded < 100 && !file.completed  &&
                                    <span className='file_upload__item--name'>{file.loaded}%</span>
                                }
                                { file.error && <div className='d-flex align-items-center'><span className="error--label fs-11 "><ErrorIcon className='mr-2'/>{ file.error }</span></div>}

                            </div>
                            {!file.error  && file.loaded < 100 && !file.completed  &&
                            <div className="d-flex align-items-center w-100 mt-2">
                                <Progress className={`file_upload__progress ${file.error ? 'text-danger' : ''} ${file.completed ? 'completed' : null }`} value={`${file.loaded}`}>
                                    
                                </Progress>
                               
                                {/* {!file.error && file.loaded < 100 && !file.completed &&
                                    <div className="ml-3">
                                        <Spinner color='primary' /> 
                                    </div>
                                } */}
                                </div>
                            }
                        </div>
                        {file.loaded === 100 && 
                            <div className="ml-3">
                                <img src={IconSuccessful_loading} alt="" />
                            </div>
                        }
                        <div className='file_upload__close_btn'>
                            <Button className="clear__icon" color="link p-0 m-0" onClick={()=>{cancelUpload(file, index)}}>
                                <CloseIcon/>
                            </Button>
                        </div>
                    </div>
                )
            }
            </div>
                <input multiple onClick={e => (e.target.value = null)} type="file" id="uploadFile" accept="video/.mp4,video/*,video/.mov,video/.wmv,video/.avi"
                    style={{position: 'absolute' , top: '0' , bottom: '0' ,right: '0' ,left: '0' ,opacity: '0'}}   onChange={onChange} />
                {/* <div className="file_upload__note mt-auto text-center my-4">{note}</div> */}
                <div className="file_upload__drug__file__section align-self-end py-4 text-center d-flex justify-content-center align-items-center mt-auto">
                    Drag & drop your files, or <label className="btn-link m-0 pl-2 primary" htmlFor="uploadFile">click to upload.</label> <img className="ml-4" src={noun_drag_and_drop} alt="Drag and Drop"/>
                </div>
            </div>
    ) 
}

export default FileUpload;
