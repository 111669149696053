import React, { useState, useEffect } from "react";

import TimePicker from "./TimePicker/TimePicker";
import { Row, Col, Form, Input, Button } from "reactstrap";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { postOperatorySchedule } from "../../store/actions/taskAction";
import { SC } from "../../helper/ServerCall";
import { getOperatorySchedule } from "../../helper/endPoint";
import moment from "moment";
import { initialArray, formatApiResponse } from "./responseFormatter";
import { Fragment } from "react";
import { useHistory } from 'react-router-dom';
const OperatorySchedule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [time, setTimer] = useState({
    day_week: 0,
    from_working_hour_type: "",
    from_working_hour: "00",
    from_working_hour_minute: "00",
    from_working_hour_type: "00",
    to_working_hour: "00",
    to_working_hour_minute: "00",
    to_working_hour_type: "",
    working_hours_status: true,

    from_after_hour_type: "",
    from_after_hour: "00",
    from_after_hour_minute: "00",
    from_after_hour_type: "",
    to_after_hour: "00",
    to_after_hour_minute: "00",
    to_after_hour_type: "",
    after_hours_status: true,
  });
  const [schedule, setSchedule] = useState([]);
  const [weekname, setWeekName] = useState("");
  const [Data, setData] = useState([]);

  const [weeks, setWeek] = useState(initialArray);
  // Week Status
  const [weekstatus, setWeekStatus] = useState(true);
  React.useEffect(() => {
    if (weekname !== "") {
      handleSchedule(weekname);
    }
  }, [time]);

  const handleChange = (type, day_week, event) => {
    setWeek(
      weeks.map((item) => {
        if (item.day_week === day_week) {
          if (type === "switch") {
            item[event.target.name] = event.target.checked;
          } else if (type === "working") {
            item.working[event.target.name] = event.target.value;
          } else if (type === "after") {
            item.after[event.target.name] = event.target.value;
          }
        }
        return item;
      })
    );

    // setWeekName(day_week);
    // setTimer({
    //   ...time,
    //   [event.target.name]: event.target.checked,
    //   day_week: day_week,
    // });
  };
  const handleAfterChange = (day_week, event) => {
    weeks.find((element) => {
      if (element.id === event.target.id) {
        element.AftercheckCli = !element.AftercheckCli;
      }
    });
    setWeekName(day_week);
    setTimer({
      ...time,
      [event.target.name]: event.target.checked,
      day_week: day_week,
    });
  };
  const TimeChangeHandler = (day_week, event) => {
    console.log(event.target.value);
    setWeekName(day_week);
    event.preventDefault();
    // setWeek(formatApiResponse(arr));
    setTimer({
      ...time,
      [event.target.name]: event.target.value,
      day_week: day_week,
    });
  };

  const handleSchedule = (weekname) => {
    return setSchedule({ ...schedule, [weekname]: time });
  };
  const handleSubmitSchedule = (event) => {
    console.log("call");
    event.preventDefault();
    var arr_schedule = [];
    weeks.map((item) => {
      arr_schedule.push({
        day_week: item.day_week,
        after_hours_to: moment(
          `${item.after.toHour}:${item.after.toMinute} ${item.after.toTimeFormat}`,
          "h:mm a"
        ).format("HH:mm"),
        after_hours_from: moment(
          `${item.after.fromHour}:${item.after.fromMinute} ${item.after.fromTimeFormat}`,
          "h:mm a"
        ).format("HH:mm"),
        working_hours_to: moment(
          `${item.working.toHour}:${item.working.toMinute} ${item.working.toTimeFormat}`,
          "h:mm a"
        ).format("HH:mm"),
        after_hours_status: +item.AftercheckCli,
        working_hours_from: moment(
          `${item.working.fromHour}:${item.working.fromMinute} ${item.working.fromTimeFormat}`,
          "h:mm a"
        ).format("HH:mm"),
        working_hours_status: +item.checkCli,
      });
    });


    let data = {
      channel_id: props.location.state.channel_id,
      media_type: 1,
      schedule: arr_schedule,
    };
    console.log(data);
    dispatch(postOperatorySchedule(data));
    history.push('/add-new-operatory-channel')
  };
  useEffect(() => {
    getData();
  }, []);
  //get schedule data
  const getData = () => {
    SC.getOperatoryScheduleCall(getOperatorySchedule).then((res) => {
      if (res.status === 200 && res.data) {
        let { schedule } = res.data?.data;
        setWeek(formatApiResponse(schedule));
        setData(schedule);
      }
    });
  };
  return (
    <div className="main__screen channels__page  d-flex flex-column h-100 align-items-center justify-content-center">
      <div className="Page9__main">
        <div className="Page9__main__baseDiv">
          <div className="Page9__main__baseDiv__headingDiv">
            <h4>Schedule</h4>
            <p>
              So, it can be done. But customizations like this are usually very
              brittle even if you add in the necessary cross-browser properties
              are usually very brittle even if you add in the necessary
              cross-browser properties
            </p>
          </div>
          <Form onSubmit={handleSubmitSchedule}>
            <div className="schedule_section">
              <div className="Page9__main__baseDiv__timeTab">
                <div className="Page9__main__baseDiv__timeTab__twoTabDiv">
                  {weeks.length &&
                    weeks.map((week, index) => {
                      return (
                        <Fragment key={index}>
                          <TimePicker
                            id={week.id}
                            Data={Data?.filter((f) => f.day_week === index + 1)}
                            after_id={week.id}
                            name={`week-${index}`}
                            check={week.checkCli}
                            after_check={week.AftercheckCli}
                            TimeChangeHandler={TimeChangeHandler}
                            handleChange={handleChange}
                            handleAfterChange={handleAfterChange}
                            weekstatus={weekstatus}
                            day_week={week.day_week}
                            day={week.name}
                            working={week.working}
                            after={week.after}
                          />
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="Page9__main__baseDiv__buttonDiv">
              <Button className="Page9__main__baseDiv__buttonDiv__button1">
                SAVE CHANGES
              </Button>

              <button className="Page9__main__baseDiv__buttonDiv__button2" >
                BACK
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OperatorySchedule;
