import React from 'react';

import './style.scss';
import { Button , Label ,Input, FormGroup , NavLink, Spinner} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import BillingProfileImg from '../../img/BillingProfileAvtar.png'
import LoadingButton from '../../components/LoadingButton'

import {sendRecoverCode , clearerror , resetSuccess} from "../../store/actions/taskAction";
import { SIGNIN } from '../../utilities/HttpUtility';

class emailSreen extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      currentPage: 1,
      isValid:true,
      email:null,
      emailValid:false,
      formValid:false,
      seconds: 59,
      isGetCode:false,
      successSend:false,
      totelPage: 0,
      isError:{
        email:''
      },
      selectedPractice: {},
    };
    this.handlechangePage = this.handlechangePage.bind(this);
  }


  async componentDidMount() {
    await this.props.dispatch(clearerror());
    clearInterval(this.myInterval)
    this.props.dispatch(resetSuccess());
    this.setState({ formValid:false, selectedPractice:{}})

  }


  handlechangePage(e, index) {
    index = index + 1

    this.setState({ currentPage: index }, () => {
      let params = {
        page: this.state.currentPage,
      }
      this.props.dispatch(sendRecoverCode(params));
    });
  }

  handleClick(){
    this.props.handleClick()
  }

  // onBack(){
  //     this.setState({isValid:true})
  // }

  handleChange(event, options){
    const { name, value } = event.target;
  
    this.setState({
      [name]: value
    },()=> {this.isValid( name, value)})
  }

  isValid(name, value){
    let isError = this.state.isError ;
    let emailValid = this.state.emailValid;

    switch (name) {
      case "email":
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          isError.email = emailValid ? '' : "Wrong format" ;
          break;
      default:
          break;
    }
    this.setState({emailValid: emailValid}, this.validateForm)
  }
  
  validateForm() {
    console.log(this.state.email)
    this.setState({formValid: this.state.emailValid });
  }

  onSend(practiceId){
    // this.setState({isValid:false})
    if(this.state.selectedPractice.id) return;
    
    else{
      var selectedPractice = {};
      selectedPractice.id = practiceId;
      this.setState({ selectedPractice })
    } 
    let params = {
      type:'email',
      // phone:null,
      email:this.state.email
    }
    if(practiceId)  
      params['practice_id'] = practiceId;

    this.props.dispatch(sendRecoverCode(params));
    if(practiceId) { 
      this.setState({seconds:60})
      this.resend()
    }
  }

  async onReSend(){
    clearInterval(this.myInterval)
    console.log(this.state.selectedPractice,'selectedPractice')
    // this.props.dispatch(resetSuccess());
    // this.setState({ formValid:false, selectedPractice:{}})

    let params = {
      type:'email',
      // phone:null,
      email:this.state.email
    }
      params['practice_id'] = this.state.selectedPractice.id;

    this.props.dispatch(sendRecoverCode(params));
    this.setState({seconds:60})
    this.resend()
  }

  onBackToSignin(){
    history.push('/profileselection');
  }
  onBackToForgotAccessCode(){
    this.props.handleClick('email');
  }
  
  onBack(){
    this.props.dispatch(clearerror());
  }

  resend(){
    this.myInterval = setInterval(() => {
        const { seconds } = this.state

        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1,
            isGetCode: false
          }))
        }else{
          this.setState({ isGetCode: true })
        }
          if(this.seconds === 0){
            clearInterval(this.myInterval)
          }
        
      }, 1000)
  }


  getUserType(id){
    switch(id){
        case 0: 
            return  ["guest", "Guest"];
        case 1:
            return  ["dentist", "Normal User"];
        case 2:
            return  ['authorized_user', "Authorized User"]; 
        case 3:
            return    ['manager', "Manager"]; 
        case 4:
            return    ['owner', "Owner"]; 
        default:
          return    ['guest', "Guest"];
    }
  }

  render (){
    const { isError, selectedPractice } = this.state;
    const {t , loadingRecoveryCode , sendRecoverCode , error, practiceSelected} = this.props
    if(sendRecoverCode){
      var userType = this.getUserType(sendRecoverCode.type )
     }
     console.log("practiceSelected: ",practiceSelected)
    return(
      <>
       {error && !error.message ?
          <>
          {!this.props.successSend ?
          <div className="d-flex flex-column  forgot_access_code__inner__enter_info">
            <h4 className="mb-5">{t('forgotAccessCode.email.title1')}</h4>
            <FormGroup className="mb-5">
                <div className="d-flex justify-content-between">
                  <Label>{t('form.email')}</Label>
                {isError.email && (<span className="text--danger">{isError.email}</span>)}
                </div>
                <Input type="email" className={isError.email ? "invalid-field" : ""} onChange={(event)=>this.handleChange(event)}  name="email" />
            </FormGroup>
            <div className="d-flex  justify-content-between pt-3">
                <LoadingButton type="button"  label2={t('btn.send')} label={t('btn.send')} loading={loadingRecoveryCode} disabled={!this.state.formValid || loadingRecoveryCode} size="sm" onClick={() => this.onSend()}  className={`mr-3`} color="primary"/>
                <Button className="ml-3" size="sm" onClick={() => this.handleClick()} color="danger">{t('btn.cancel')}</Button>
            </div>
            <div style={{marginTop:10,textAlign:" center"}} className="pt-3">
                <Button className="" size="sm" outline onClick={() => this.handleClick()} color="default white">{t('btn.back')}</Button>
              </div>
          
        </div>
          :
          (practiceSelected ? 
            <>
            <div className="practice_list">
              <div className="row">
                  <div className="col-7 mb-5">
                      <h4>Select one of the practice to procced.</h4>
                  </div>
              </div>
              <table className="table" >
                  {/* <thead>
                    <tr>
                      <th>Practice Name</th>
                      <th></th>
                    </tr>
                  </thead> */}
                  <tbody>
                  {sendRecoverCode.length>0&&sendRecoverCode.map((p)=>{
                    return <tr>
                    <td>{p.name}</td>
                    <td style={{width:"20%"}}><Button size="md" color="primary" onClick={()=> this.onSend(p.id) }>Select {selectedPractice.id === p.id && <Spinner className="" size="md" color="dark" /> }</Button></td>
                    
                  </tr>
                  })}

                  </tbody>
                </table>
                <div className="invoice__inner__footer d-flex justify-content-center align-items-center">
                  <ul>
                    {[...Array(this.state.totelPage)].map((page, i) =>
                      <li key={i}><Button type="button" className={`p-3 ${i + 1 === this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                    )}
                  </ul>
                  {/* <Button type="button"  onClick={() => this.onBackToForgotAccessCode()} size="xl" className="mt-2 " color="primary">Back</Button> */}
                  <div style={{marginTop:10,textAlign:" center"}} className="pt-3">
                {/* <Button className="" size="sm" outline onClick={() => this.onBackToForgotAccessCode()} color="default white">{t('btn.back')}</Button> */}
              </div>
                </div>
                {/* {sendRecoverCode.practice.map((p)=>
                  <div className="d-flex">
                      <h4 style={{cursor: 'pointer'}} onClick={()=> this.onSend(p.id) }>{p.name}</h4>
                      {selectedPractice.id == p.id && <Spinner className="" size="md" color="dark" /> }
                  </div>)
                } */}
              </div>
              <Button className="mx-auto mt-4" size="md" outline onClick={() => {this.props.dispatch(resetSuccess())}} color="default white">{t('btn.back')}</Button>

            </>
            :sendRecoverCode.profile_pic?
            <div className="d-flex flex-column align-items-center justify-content-center">
             <div className={`user__profile  mx-auto mb-5 ${userType[0]}`} style={{order:0}}>
               <p className="text-center mb-3">{sendRecoverCode.practice_name}</p>
               <div className={`user__profile__box d-flex flex-column justify-content-end mb-3 ${userType[0]}`}>
                 <div className="user__profile__picture">
                 { sendRecoverCode.profile_pic ?  
                   <img className="mx-auto my-auto" alt={sendRecoverCode.first_name} src={ sendRecoverCode.profile_pic}/>
                   :
                   <img className="mx-auto my-auto" alt={sendRecoverCode.first_name} src={BillingProfileImg}/>
                 }
                 </div>
                 <div className={`user__profile__footer py-2  text-center ${userType[0]}`}>
                   {userType[1]}
                 </div>
               </div>
               <div className="user__profile__name text-center">{`${sendRecoverCode.first_name||''} ${sendRecoverCode.last_name||''}`}</div>
             </div>
           <h1>Access code sent</h1>
           <p className="fs-20">{`${sendRecoverCode.first_name} ${sendRecoverCode.last_name|| ''}`} will receive an email containing the access code to your practice.</p>
           <FormGroup className="d-flex flex-column align-items-center justify-content-center">
             <Label><span>No email received? <Button type="button" style={{lineHeight:'1',height:'auto'}} onClick={() => this.onReSend()} disabled={!this.state.isGetCode}  color="link primary" className="p-0 ml-2">Click here to resend  { `(${this.state.seconds})` + 's'}</Button></span></Label>
             {/* <Button type="button"  onClick={() => this.onBackToSignin()} size="xl" className="mt-2 " color="primary">Back to sign in</Button> */}
             <NavLink className="btn btn-primary mt-2" href={SIGNIN}>Back to sign in page</NavLink>
           </FormGroup>
         </div>
         :
         <Spinner className="" size="md" color="white" /> 
          )
          }
          </>
        :
        <div className="d-flex flex-column  mw-680 text-center">
            <h1 className="mb-4">{t('forgotAccessCode.email.title2')}</h1>
            <p className="fs-20 mb-5">{t('forgotAccessCode.email.detail.part1')} {this.state.email} {t('forgotAccessCode.email.detail.part2')}</p>
            <Button className="mx-auto mt-4" size="md" onClick={() => this.props.dispatch(clearerror())}  outline  color="default white">{t('btn.back')}</Button>
        </div>
        }
      </>
    ) 
  }
}
// export default emailSreen;

const mapStateToProps = (state) => ({
  error:state.task.error,
  loadingRecoveryCode:state.task.loadingRecoveryCode,
  successSend:state.task.successSend,
  sendRecoverCode:state.task.sendRecoverCode,
  practiceSelected: state.task.practiceSelected,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(emailSreen);
