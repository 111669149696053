import React from 'react';
import { Row, Col } from 'reactstrap';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const SlidePresetsTabContent = () => {
    return (
        <div style={{ marginLeft:"395px" }}>
            <Row>
                <Col lg='2'>
                    <UndoIcon />
                    <RedoIcon />
                </Col>
                <Col lg='4'>
                    <span>Select a slide preset</span><ListAltIcon fontSize='15px' />
                </Col>
                <Col lg='4'>
                    <span>Remove slide preset</span><DeleteOutlineIcon fontSize='15px' />
                </Col>
                <Col lg='2'>

                </Col>
            </Row>
        </div>
    )
}

export default SlidePresetsTabContent
