import React, { useEffect, useState } from 'react'
import SubHeader from '../../../components/Header/subHeader'
import ChairsidePlayer from '../../../components/ChairsidePlaylist/ChairsidePlayer'
import Sidebar from '../../../components/ChairsidePlaylist/Sidebar'
import { getChairsidePlaylistMediaData, getTimeline } from '../../../store/actions/taskAction'
import { useDispatch, useSelector } from 'react-redux'
import VideoSliderList from '../../../components/ChairsidePlaylist/VideoSliderList'
import Skeleton from "@material-ui/lab/Skeleton";

import imgUrl from '../../../img/bg.png'

const ManageChairsidePlaylist = (props) => {
	const state = useSelector((state) => state.task);
	const dispatch = useDispatch();
  const [video, setVideo] = useState({});
  const [videoIndex, setVideoIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const isAddChannel = props.location.state.isFromMenageChannel;
  useEffect(() => {
        dispatch(getTimeline(state.chairsidePlaylist.id, false, true));
	}, []);

  useEffect(() => {
    setVideoData()
}, [state.channelTimeLine.length > 0]);


  
  useEffect(() => {
    setVideoData()
  }, [videoIndex]);


  const setVideoData = async () =>{
    setLoading(true);
    let videoData = state.channelTimeLine[videoIndex]?.media_data_multi.find(x=> x.media_language_id  == state.practiceUserAccountInfo.account_setting.media_language)
    setVideo(videoData);
    console.log(videoIndex,'videovideovideo')
    setLoading(false);
  }

  const setNextVideo =  () =>{
    if(state.channelTimeLine.length - 1 > videoIndex){
      let index = videoIndex + 1
      setVideoIndex(index);
    }else{
      setVideoIndex(0)
    }
  }

  const setPreVideo = () =>{
    if(videoIndex > 1 ){
      setVideoIndex(videoIndex-1)
    }else{
      setVideoIndex(0)
    }
  }
  

  return (
    <div className='chairside_education_playlist__main__screen   d-flex flex-column h-100 align-items-center '>
        <SubHeader className='' title='New Playlist' />
        <div className='d-flex w-100 chairside_education_playlist__inner_screen mt-5'>
                <Sidebar isAddChannel={isAddChannel} />
                <div className='d-flex flex-column chairside_education_playlist__videoSection'>
                  {/* {console.log(video?.id,'ididididi')} */}
                {video? 
                  <ChairsidePlayer video={video}  setVideo={setVideo} setPreVideo={setPreVideo} setNextVideo={setNextVideo}/>
                :
                  <img style={{width:'1170px', height:'658px',objectFit:'cover'}} src={imgUrl} alt=''/>
                }
                <VideoSliderList />
                </div>
        </div>
    </div>
  )
}

export default ManageChairsidePlaylist
