import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import StripeIcon from '../../../img/stripe-icon.png'
import { Button } from 'reactstrap';
import { history } from '../../index';

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    };
  }

  onSubscribe(){
    history.push('/Subscribe');
  }

  render (){
    return(
      <div className={` transparent__form--white corporate-billingcard__inner__panel d-flex flex-column`} > {/* for screen 173,174,175 add class bg-danger */}
        <img src={StripeIcon} className="stripe-logo" alt=""/>
        <div className="corporate-billingcard__inner__panel__header d-flex flex-column">
          <h4>Credit card</h4>
          <p>This is your primary credit card. It is used to pay for any Thalamus related fees, and can be updated at any moment.</p>
        </div>
        <div className="corporate-billingcard__inner__panel__body secondary-cc-add">
          <p>Once subscribed, the credit card<br/> information is displayed here.</p>
        </div>
        <div className="corporate-billingcard__inner__panel__footer mt-auto">
          <div className=""><Button className="w-100" onClick={() => this.onSubscribe()}  color="primary">Subscribe to view your billing information</Button></div>
        </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
