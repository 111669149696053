import React from 'react';
import './style.scss';
import { Button , Label , FormGroup, Form , Col , Row , NavLink} from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import HttpUtility, {TOSCOMPLAINT , CLOSE} from '../../utilities/HttpUtility';
import { InputField } from '../../components/InputField';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { PostTosComplaint } from "../../store/actions/taskAction";

class TosAccepted extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      formValid: false,
        is_sent:false,
        isValid:false,
        emailValid: false,
        field:{
          fullName:'',
          practiceName:'',
          phone:'',
          email:'',
          message:'',
        },
        errors:'',
        isError: {
          fullName:'',
          practiceName:'',
          phone:'',
          email:'',
          message:'',
        }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  
  handlePhoneChange(value, country, e, formattedValue ){
    var fieldName = "";
    var fieldValue = "";
    fieldName = e.target.name;
    fieldValue = value;
    fieldValue = '+'+country.dialCode+'-'+fieldValue;

    this.setState({
        field:{
            ...this.state.field,
            [fieldName]: fieldValue
        }
    },()=> {this.isValid()})
    
  }

  handleChange(e, options) {
    const { name, value } = e.target;

    this.setState({
        field:{
            ...this.state.field,
            [name]: value
        }
    },()=> {this.isValid( name, value)})
    
  }

  isValid( name, value){

    let isError = this.state.isError ;
    let emailValid = this.state.emailValid;

    switch (name) {
      case "fullName":
          isError.fullName =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "email":
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          isError.email = emailValid
              ? ""
              : "Wrong format";
          break;
      case "practiceName":
          isError.practiceName =  
            value.length <= 0 ? "required" : "";
          break;
      case "message":
          isError.message =  
          value.length  < 5 ? "the reason must be at least 5 characters" : "";
          break;
      default:
          break;
    }
    this.setState({emailValid: emailValid}, this.validateForm)
  }

  validateForm() {
    this.setState({formValid: this.state.emailValid && this.state.isError.fullName === ''  && this.state.isError.practiceName === ''  && this.state.isError.message ===''});
  }

  handleClose(){
    this.props.history.push({
      pathname: CLOSE,
    })
  } 

  handleBack(){
    this.setState({is_sent:false })
    this.props.history.push({
      pathname: '/tos',
    })
  } 

  sendComplaint(){
    let param = {
      email_me:0,
      name: this.state.field.fullName,
      practice_name: this.state.field.practiceName,
      practice_email: this.state.field.email,
      reason: this.state.field.message,
      phone: this.state.field.phone,
    }

    this.props.dispatch(PostTosComplaint(param));
    
    // HttpUtility.postRequest(TOSCOMPLAINT, param).then((response)=>{
    //     if(response.status === 200){
    //       this.setState({ is_sent:true })
    //     }else{
    //         var errors = response.data
    //         this.setState({ errors  })
    //     }
    // });
  }
  
  render (){
    const { isError , errors} = this.state;
    const {t, tosComplaint} = this.props
   
    return(
      <div className="tos__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
        {tosComplaint && tosComplaint.is_sent ? 
          <div className="tos__success__message">
              <h1>{t('tosdeclines.sentDetail.title')}</h1>
              <div className="d-flex justify-content-center">
                <Button size="lg" type="button" className="mt-5 mx-3" onClick={() => this.handleBack()} color="primary">{t('btn.backtoTos')}</Button>
                {/* <Button outline size="lg" type="button" className="mt-5 mx-3" onClick={() => this.handleClose()} color="default white">{t('btn.closeThalamus')}</Button> */}
                <NavLink  className="mt-5 mx-3 btn btn-outline-default white btn-lg" href={CLOSE}>{t('btn.closeThalamus')}</NavLink>
              </div>
          </div>
        :
        <div className="tos__not_accepted__form transparent__form--white d-flex flex-column align-items-stretch">
          <div className="d-flex flex-column h-100">
            <h4>{t('tosdeclines.title')}</h4>
            <p className="tos-na-content">{t('tosdeclines.details')}</p>
            <Form className="mt-2">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <InputField type="text" label={t('form.fullName')} name="fullName"  isError={isError.fullName} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="phone">{t('form.phone')}</Label>
                    <div className="d-flex">
                      <PhoneInput inputProps={{
                        name: 'phone',
                        required: true,
                      }}   country={'ca'}   className={isError.practiceName.length > 0 ? "invalid-field" : ""} countryCodeEditable={false}  onChange={(value, country, e, formattedValue) => { this.handlePhoneChange(value, country, e, formattedValue)}}  />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row >
                <Col md={6}>
                  <FormGroup>
                    <InputField type="text" label={t('form.practiceName')} name="practiceName"  isError={isError.practiceName} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <InputField type="email" label={t('form.email')} name="email"  isError={isError.email} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row >
                <Col md={12}>
                  <FormGroup>
                    <InputField type="textarea" label={t('form.message')} name="message" placeholder="Type here"   isError={isError.message} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Button type="button" size="md" onClick={()=>{this.sendComplaint()}} disabled={!this.state.formValid} className="mt-5" color="primary">{t('form.btnSend')}</Button>
            </Form>
          </div>
        </div>
        }
      </div>
    ) 
  }
}
// export default withTranslation('common')(TosAccepted);
const mapStateToProps = (state) => ({
  error: state.task.error,
  tosComplaint:state.task.tosComplaint
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(TosAccepted);