import React from 'react';
import Select , { components }from 'react-select';
import {ReactComponent as ArrowDown} from '../../img/arrow_down.svg';

import './style.scss';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDown />
        </components.DropdownIndicator>
    );
};

class AppDropDown extends React.Component{
    render(){
        return(
            <Select 
                {...this.props}
                components={{  DropdownIndicator }} 
            />
        );
    }
}

export default AppDropDown;
