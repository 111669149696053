import React from 'react';
import SubHeader from '../../components/Header/subHeader'
import DropDown from '../../components/DropDown'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import { Button } from 'reactstrap';
import LoadingButton from '../../components/LoadingButton'
import { GetOwnerLocations } from '../../store/actions/taskAction';
class AddManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        "first_name": "",
        "last_name": "",
        "email": "",
        "location": []
      }
    }
  }
  componentDidMount(){
    let paramsUnassign = {
      // view_list: "unassigned",
      // per_page: this.state.per_page,
      // page: this.state.currentPage
      without_manager: 1,
      need_all: 1,
    }
    this.props.dispatch(GetOwnerLocations(paramsUnassign, true))
  }
  updateData(elem, val) {
    let data = this.state.data
    if(elem === "location"){
      val = val.map((lc)=>{return lc = lc.id})
    } 
    data[elem] = val
    this.setState({data:data})
  }
  render() {
    return (
      <>
      <div className="email__inner assign_manager d-flex flex-column mb-auto mx-auto ">
        <div className="email__inner__header assign_manager_header">
          <h4>Assign a manager</h4>
          <p>Please provide the following information of the person you’d like to assign as a manager. The recipient will receive and email containing a link afterwards. Ask him/ her to follow through with the link and fill in the remaining information.</p>
        </div>
        <div className="email__inner__body assign_manager_body h-100">
          <div className="form-group">
            <label>Manager's first name</label>
            <input type="text" className="form-control" onChange={(e) => { this.updateData("first_name", e.target.value) }} placeholder="Noah" />
          </div>
          <div className="form-group">
            <label>Manager's last name</label>
            <input type="text" className="form-control" onChange={(e) => { this.updateData("last_name", e.target.value) }} placeholder="Jetson" />
          </div>
          <div className="form-group">
            <label>Manager's email address</label>
            <input type="text" className="form-control" onChange={(e) => { this.updateData("email", e.target.value) }} placeholder="noahj@smithdental.com" />
          </div>
          <div className="form-group">
            <label>Select the location(s) the recipient will be managing</label>
            <DropDown
              className='react-select-container react-select__multi'
              classNamePrefix="react-select"
              options={this.props.unassignedLocations}
              getOptionLabel={x => `${x.name}`}
              getOptionValue={x => `${x.id}`}
              onChange={(val) => { this.updateData("location", val) }}
              isMulti={true}
              isSearchable={true}
              placeholder={''}
            // value={this.state.location}
            />
          </div>
        </div>
        <div className="email__inner__footer assign_manager_footer">
          <div className="button-section text-center d-flex">
            <LoadingButton type="button" size="md mr-4" label='Send Invitation' loading={this.props.buttonLoading2} disabled={this.props.buttonLoading2} onClick={() => { this.props.managerInvite(this.state.data) }} color="primary"/>
            <Button size="md" outline color="default white" onClick={()=>{this.props.back()}}>Back</Button>
          </div>
        </div>
      </div>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  buttonLoading2: state.task.buttonLoading2,
  unassignedLocations: state.task.unassignedLocations,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(AddManager);
