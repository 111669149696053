import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import './style.scss';
import BG from '../../img/bg.png';
import uuid from "react-uuid";
import { formatSecond, MYSLIDESTYPE, TRIVIATYPE, EDUCATIONTYPE, ENTERAINMENTTYPE, MYVIDEOSTYPE, TIMELINEMEDIATYPE, TIMELINESLIDETYPE, SOCILAMEDIATYPE, TIMELINESOCIALMEDIATYPE, TIMELINETRIVIATYPE, FBPROVIDER, TWITTERPROVIDER } from '../../data/comman';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { postTimeline } from '../../store/actions/taskAction';
import FBLogo from '../../img/fbtimeline.svg';
import arrowLeft from '../../img/arrowLeft.svg';
import arrowRight from '../../img/arrowRight.svg';

import twiterLogo from '../../img/tw.svg';
import { Swiper, SwiperSlide } from 'swiper/react';


const TimeLineDropBox = ({ timeLineList, setTimeLineList, provided, snapshot, channelId, isOperatoryRoom, isChairsidePlayList}) => {
    useState([]);
    const dispatch = useDispatch();
    const onDragOver = (event) => {
		event.preventDefault();
    }
    console.log(timeLineList,'timeLineList')
    // useEffect(() => {
    //     console.log("timeLineList", timeLineList)
    // },[timeLineList]);

    const getMediaId = (timelineContent) => {
        switch(timelineContent.type){
            case TIMELINETRIVIATYPE:
            case TIMELINESLIDETYPE:{
                return timelineContent.timeLine.id;
            }
            case TIMELINESOCIALMEDIATYPE:{
                if(timelineContent.timeLine.media_id == FBPROVIDER)
                    return FBPROVIDER;
                else
                    return TWITTERPROVIDER;
            }
            default:
                return isOperatoryRoom ? timelineContent.timeLine.operatory_media_id : isChairsidePlayList ? timelineContent.timeLine.chairside_media_id : timelineContent.timeLine.waiting_room_media_id//timelineContent.timeLine.media_id
        }
    }

	const onDrop = (event) => {
		event.preventDefault();
        let transferData = event.dataTransfer.getData("timeline");
        if(transferData){
            let timelineContent = JSON.parse(transferData)
            var selectedMediaId = getMediaId(timelineContent);
            console.log(timelineContent,'commonTimeLine')

            // var isAlreadyExisit = timeLineList.findIndex(x => x.media_id == selectedMediaId) > -1;
            // if(isAlreadyExisit) return;//
            var commonTimeLine = {
                thumb: timelineContent.type == TIMELINESLIDETYPE ? timelineContent.timeLine.image : isChairsidePlayList ? timelineContent?.timeLine?.chairside_media?.thumb  :  timelineContent.timeLine.thumb,
                title: timelineContent.timeLine.title,
                duration: isChairsidePlayList ? timelineContent.timeLine.media.duration : timelineContent.timeLine.duration || 0,
                media_id: selectedMediaId,
                media_type_id: timelineContent.type,
                // sort_order:
                uId: uuid()
            }
            timeLineData(commonTimeLine)
        }
    }

    const timeLineData = (selectedTimeLine) => {
        var newTimeLine = timeLineList;
        newTimeLine.push(selectedTimeLine)
        setTimeLineList([...newTimeLine]);
        postTimeLineToServer();
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        // padding: timeLineList.length * 2,
        margin: `0 ${timeLineList.length}px 0 0`,

        // change background colour if dragging
       // background: isDragging ? 'lightgreen' : 'grey',

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = isDraggingOver => ({
        // background: isDraggingOver ? 'lightblue' : 'lightgrey',
        display: 'flex',
        padding: timeLineList.length,
        overflow: 'auto',
    });

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        const items = reorder(
            timeLineList,
            result.source.index,
            result.destination.index
        );

        setTimeLineList(items);
        // console.log(items, result);
        postTimeLineToServer();
      }

    const postTimeLineToServer = () => {
        var param = {};
        var timelineList = []
        timeLineList.map((timeline, index) => {
            console.log(timeline,'timeline')
            timelineList.push({
                media_id: timeline.media_id,
                media_type_id: timeline.media_type_id,
                sort_order: index
            });

        });
        param["timeline"] = timelineList;

        dispatch(postTimeline(channelId, param, isOperatoryRoom, isChairsidePlayList))
    }

    const formatSecondMi = (miliSecond) => {
        if (isNaN(miliSecond)) {
            return '00:00';
        }

        const date = new Date(miliSecond * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2, '0');

        if (hh) {
            return `${hh}h ${mm.toString().padStart(2, '0')}m ${ss}s`;
        }
        return `${mm}m ${ss}s`;
    }

    var timelineDuration = 0;
    // timeLineList.map((timeline) => {
    //     timelineDuration = parseInt(timelineDuration) + parseInt(timeline.duration)
    // })

    const scrollPrv =()=>{
        var container = document.getElementById('timeline_container')
        // document.getElementById('timeline_container').scrollLeft += 250;
        var scrollAmount = 0;
        var slideTimer = setInterval(function(){
            container.scrollLeft -= 30;
            scrollAmount += 30;
            if(scrollAmount >= 240){
                window.clearInterval(slideTimer);
            }
        }, 25);
    }
    const scrollNext =()=>{
        var container = document.getElementById('timeline_container')
        // document.getElementById('timeline_container').scrollLeft += 250;
        var scrollAmount = 0;
        var slideTimer = setInterval(function(){
            container.scrollLeft += 30;
            scrollAmount += 30;
            if(scrollAmount >= 240){
                window.clearInterval(slideTimer);
            }
        }, 25);
    }
    // console.log("timeLineList dropbox",timeLineList)
    return (
        <>
            <div className="d-flex w-100 scroolable-sec"
                onDragOver={(e) => onDragOver(e)}
                onDrop={(e) => onDrop(e)}>
                    <Button type="button" onClick={scrollPrv} color="link" className="btn_prv">
                        <img style={{height:'20px', width:'20px' , maxWidth:'unset'}} src={arrowLeft} alt="Prv" />
                    </Button>
                    <Button type="button" onClick={scrollNext} color="link" className="btn_next">
                        <img style={{height:'20px', width:'20px' , maxWidth:'unset'}} src={arrowRight} alt="next" />
                    </Button>
                   
                        <Col lg={11}>
                        {!timeLineList.length > 0 &&
                            <>
                                <div className="dropbox__main__label">
                                    <p>This is your Timeline | Drag and drop your content selection  here</p>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <p>
                                        You can also drag any video within the timeline to rearrange the
                                        sequence in which they are played.
                                    </p>
                                </div>
                            </>
                        }
                        </Col>
                    
                <Col lg={1}>
                    {timelineDuration > 0 && <div className="timeline_total_time">Total | {formatSecondMi(timelineDuration)}</div>}
                    <div className="dropbox__main__dropimage">
                        <img src="assets/img/dragdropicon/dragdrop.png" alt="" height="50px "width="50px"/>
                    </div>
                </Col>
                <Col lg='12' id="timeline_container" className="timeline_container d-flex">
                    {/* <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => ( */}
                                <div className="dragable-list"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}>
                                        {timeLineList.map((timeline, index) => {
                                            console.log(timeline,'timeline')
                                            return(<div id="dragable-list" className="dragable-item" key={timeline.uId} >
                                                            <Draggable direction="horizontal" draggableId={`timeline_${timeline.media_id}_${timeline.uId}`} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                        draggable={true}
                                                                        className="timeline_item">
                                                                        <div className="aftertimeline">
                                                                        </div>
                                                                        {timeline.media_type_id == TIMELINESOCIALMEDIATYPE ?
                                                                            <div style={{height: 150, width: 180, display: 'flex', justifyContent: 'center'}}>
                                                                                <img
                                                                                    src={timeline.media_id == FBPROVIDER ? FBLogo : twiterLogo}
                                                                                    className="img_view"
                                                                                    style={{height: 120, width: 120, margin: 'auto', objectFit: 'contain'}}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        :
                                                                            <div className="timeline_item_view">
                                                                                <img
                                                                                    src={timeline.thumb ? timeline.thumb : BG}
                                                                                    className="img_view"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        }
                                                                        { timeline.media_type_id == TIMELINETRIVIATYPE ?
                                                                            <div className="videoSelected__main__card__subTitle drop_video_box" >
                                                                                <h1 style={{color: '#a2711f', fontWeight: 800}} className="duration">TRIVIA</h1>
                                                                                <p className='videoSelected__main__card__title'>{timeline.title}</p>
                                                                            </div>
                                                                        :
                                                                        timeline.media_type_id != TIMELINESOCIALMEDIATYPE &&
                                                                            <div className='videoSelected__main__card__subTitle drop_video_box'>
                                                                                <h1 className="duration">
                                                                                    {formatSecond(timeline.duration)}
                                                                                </h1>
                                                                                <p className='videoSelected__main__card__title'>
                                                                                    {timeline.title}
                                                                                </p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Draggable>

                                            </div>
                                        )})}
                                    {provided.placeholder}
                                </div>
                            {/* )}
                        </Droppable>
                    </DragDropContext> */}
                </Col>
            </div>
        </>
    )
}

export default TimeLineDropBox;