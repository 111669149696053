/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button ,FormGroup } from 'reactstrap';
import FacebookLogin from 'react-facebook-login';
import { FBCLIENTKEY, TWITTERCONSUMERKEY, TWITTERCONSUMERSECRET, FBPROVIDER, TWITTERPROVIDER, SOCILAMEDIATYPE, TIMELINESOCIALMEDIATYPE } from '../../data/comman';
import HttpUtility, { FBBASEURL, APIBASEURL } from '../../utilities/HttpUtility';
import Dropdown from '../../components/DropDown';
import { addSocialMedia, removeSocailMedia } from '../../store/actions/taskAction';
import TwitterLogin from "react-twitter-login";// 'react-twitter-auth';//'react-twitter-auth';//
// import TwitterLogin from 'react-twitter-auth';//'react-twitter-auth';//
import FBLogo from '../../img/fbtimeline.svg';
import TwitterLogo from '../../img/tw.svg';
import Dragimg from '../../img/noun_drag_and_drop.svg';
import uuid from "react-uuid";
import { InputField } from '../../components/InputField';
import LoadingButton from '../../components/LoadingButton';

const WaitingRoomSocialMedia = ({onPublish}) => {

	const states = useSelector((state) => state.task);
	const [twitterUser, setTwitterUser] = useState(null)
	const [FBUser, setFBUser] = useState(null);
	const [drdTimeLinePage, setDrdTimeLinePage] = useState([{ label: "Timeline", value: 1 }]);
	const [selectedDrdTimeLinePage, setSelectedDrdTimeLinePage] = useState(null);
	const [twitterUserName, setTwitterUserName] = useState("");
	const dispatch = useDispatch();
	
	useEffect(() => { 
		console.log("state.socailMedia ===>", states.socailMedia);
		if(states.socailMedia.length > 0){ 
			const fbLogin = states.socailMedia.find(x => x.provider_id == FBPROVIDER);
			const twitterLogin = states.socailMedia.find(x => x.provider_id == TWITTERPROVIDER);
			setFBUser({...fbLogin});
			setTwitterUser(twitterLogin)
			if(twitterLogin) setTwitterUserName("");
			if(fbLogin){
				//  var userId = fbLogin.userID;
				// var accessToken = fbLogin.secret.access_code;
				// var url = `${FBBASEURL}me/accounts?fields=name,access_token&access_token=${accessToken}`;
				// getFBPages(url, fbLogin);
			}else{
				setFBUser(null);
			}
		}
		else{
			setFBUser(null);
			setTwitterUser(null);
			setTwitterUserName("");
		}
	}, []);

	useEffect(() => { 
		console.log("state.socailMedia ===>", states.socailMedia);
		if(states.socailMedia.length > 0){ 
			const fbLogin = states.socailMedia.find(x => x.provider_id == FBPROVIDER);
			const twitterLogin = states.socailMedia.find(x => x.provider_id == TWITTERPROVIDER);
			setFBUser({...fbLogin});
			setTwitterUser(twitterLogin)
			if(twitterLogin) setTwitterUserName("");
			if(fbLogin){
				//  var userId = fbLogin.userID;
				// var accessToken = fbLogin.secret.access_code;
				// var url = `${FBBASEURL}me/accounts?fields=name,access_token&access_token=${accessToken}`;
				// console.log("url", url);
				// getFBPages(url, fbLogin);
			}else{
				setFBUser(null);
			}
		}
		else{
			setFBUser(null);
			setTwitterUser(null);
			setTwitterUserName("");
		}
	}, [states.socailMedia]);

	const componentClicked = () => {} 

	const onDropdownChange = (option) => {
		setSelectedDrdTimeLinePage(option);
		if(states.socailMedia){
			var fbTimeUser = states.socailMedia.find(x => x.provider_id == FBPROVIDER);
			var params = {
				secret: {
					access_code: `${fbTimeUser.secret.access_code}`,
				},
				post_display_type: option.value == 1 ? 1 : 2,
				profile_name: fbTimeUser.profile_name ? fbTimeUser.profile_name : "",
				provider: FBPROVIDER,
				image: fbTimeUser.image ? fbTimeUser.image : "",
				user_id: fbTimeUser.user_id,
				updateUser: true
			};
			if(option.value != 1)
				params['page_id'] = option.value
			
			dispatch(addSocialMedia(params));
		}
	}

	const postTwiiterData = () => {
		var params = {
			secret: {
				"screen_name": twitterUserName
			},
			provider: TWITTERPROVIDER
		}
		dispatch(addSocialMedia(params));
	}

	const responseFacebook = (response) => {
		// var userId = response.userID;
		console.log("response", response);
		var accessToken = response.accessToken;
		if(accessToken){
			setFBUser(response);
			// var url = `${FBBASEURL}${userId}/accounts?fields=name,access_token&access_token=${accessToken}`;
			var url = `${FBBASEURL}me/accounts?fields=name,access_token&access_token=${accessToken}`;
			getFBPages(url);

			var params = {
				secret: {
					access_code: `${response.accessToken}`,
				},
				post_display_type: 1,
				profile_name: response.name,
				provider: FBPROVIDER,
				image: response.picture && response.picture.data ? response.picture.data.url : ""
			};
			
			dispatch(addSocialMedia(params));
		}
	}

	const getFBPages = (url, fbLogin) => {
		HttpUtility.getFBUserPages(url).then((response)=>{
			if(response.status == 200){
				var pages = response.data.data;
				var drdOptions = [{ label: "Timeline", value: 1 }];
				pages.map((page)=>{
					drdOptions.push({ label: page.name, value: page.id });
					if(fbLogin && fbLogin.page_id == page.id && fbLogin.post_display_type != 1){
						setSelectedDrdTimeLinePage({ label: page.name, value: page.id });
					}
					else{
						setSelectedDrdTimeLinePage({...drdOptions[0]});
					}
				})
				setDrdTimeLinePage(drdOptions)
			}
		}).catch((e)=>{
			console.log("error", e);
			setFBUser(null);
		})
	}

	const removeSocialLogin = (providerId) => {
		dispatch(removeSocailMedia(providerId));
	}

	return (
		<div>
			 <div className="social-media-sec">
				 <div className="w-100 social-sec">
					<h3>Social media</h3>
					<p className="social-sec-desc">To get feeds from your social media posts, please sign in to your social media accounts.</p>
					<Row>
						<Col lg='4' xs="4" className="border-right-light">
							<div className="login-steps">
								<h3>Facebook account</h3>
									<div className="step-number">
										<h4>Step 1</h4>
										<p>Click on "Login with Facebook" </p>
									</div>
									<div className="step-number">
										<h4>Step 2</h4>
										<p>Select Timeline/ Page to link</p>
									</div>
									<div className="step-number">
										<h4>Step 3</h4>
										<p>Drag the Facebook Icon to Timeline </p>
									</div>
								</div>
							{FBUser == null  ?
									<div className="text-left">
										<FacebookLogin
											appId={FBCLIENTKEY}
											autoLoad={false}
											fields="name,picture"
											scope="user_posts"
											onClick={componentClicked}
											callback={responseFacebook}
											isDisabled={false} />
									</div>
								:
									<div className="row text-left align-items-center drag-items" >
										<div className="col-md-4" draggable={true} onDragStart={(event) => { event.dataTransfer.setData("timeline", JSON.stringify({ type: TIMELINESOCIALMEDIATYPE, 
										timeLine: { media_id: FBPROVIDER,
											uId: uuid(),
											thumb: null,
											duration: 0,
											title: ""
										} })) 
										}}>
											<img className="mb-4 fb-logo" style={{height: 85, width: 85}} src={FBLogo}/>
										</div>
										<div className="col-md-8">
											<div className="drag-social-sec">
												<p>Drag and drop the Facebook icon into the timeline</p>
												<img src={Dragimg} />
											</div>
										</div>
										<div className="col-md-12">
											<h4 className="mb-5 profile-name" style={{color: 'white'}}>{FBUser.profile_name}</h4>
										</div>
										<div className="col-md-6">
											<Dropdown 
												className='react-select-container'
												classNamePrefix="react-select"
												options={drdTimeLinePage}
												onChange={(options)=> onDropdownChange(options)}
												isSearchable={false}
												placeholder={'Select an option'}
												value={selectedDrdTimeLinePage}
											/>
										</div>
										<div className="col-md-6">
											<LoadingButton type="button" label="Remove" className="w-100" loading={states.fbRemoveLoading} disabled={states.fbRemoveLoading}  onClick={() => removeSocialLogin(FBPROVIDER)} color="primary"/>
										</div>
									</div>
								}
					
						</Col>
						<Col lg='4' xs="4"> 
							<div className="login-steps">
							<h3>Twitter account</h3>
								<div className="step-number">
									<h4>Step 1</h4>
									<p>Enter Twitter Username </p>
								</div>
								<div className="step-number">
									<h4>Step 2</h4>
									<p>Drag Twitter Icon to Timeline</p>
								</div>
							</div>
							
							{twitterUser ? 
								<div className="row text-left drag-items" >
									<div className="col-md-4" draggable={true} onDragStart={(event) => { event.dataTransfer.setData("timeline", JSON.stringify({ type: TIMELINESOCIALMEDIATYPE, 
									timeLine: { media_id: TWITTERPROVIDER,
										uId: uuid(),
										thumb: null,
										duration: 0,
										title: ""
									} })) 
									}}>
										<img className="twitter-logo" src={TwitterLogo}/>
									</div>
									<div className="col-md-8">
										<div className="drag-social-sec">
											<p>Drag and drop the Twitter icon into the timeline</p>
											<img src={Dragimg} />
										</div>
									</div>
									<div className="col-md-12">
										<h4 className="mb-5 profile-name" style={{color: 'white'}}>{twitterUser.secret.screen_name}</h4>
									</div>
									<div className="col-md-6">
										<LoadingButton type="button" label="Remove" className="w-100" loading={states.twitterRemoveLoading} disabled={states.twitterRemoveLoading}  onClick={() => removeSocialLogin(TWITTERPROVIDER)} color="primary"/>
									</div>
								</div>
							:
							<form className="h-100 d-flex flex-column" style={{marginBottom:"0", marginTop:"123px"}}>
								<FormGroup className="align-items-center mb-20" >
									<div>
										<label>Username</label>
									</div>
									<InputField type="text" value={twitterUserName} className="mb-3"  placeholder="" name="Username" onChange={(e)=> setTwitterUserName(e.target.value)}/>
								</FormGroup>
								<LoadingButton type="button" label="Link Twitter account" className="w-100" loading={states.twitterLinkLoading} disabled={twitterUserName == "" || states.twitterLinkLoading}  onClick={ postTwiiterData} color="primary"/>
							</form>}
							{/* <div className="text-left">
								<TwitterLogin
									authCallback={authHandler}
									consumerKey={TWITTERCONSUMERKEY}
									consumerSecret={TWITTERCONSUMERSECRET}
									/>
									{/* <TwitterLogin
										loginUrl={`${APIBASEURL}api/v1/auth/twitter`}
										onFailure={(error) => {console.log("twitter error", error)}}
										onSuccess={(response)=>{console.log("twiiter login", response)}}
										requestTokenUrl={`{${APIBASEURL}}waiting-room-channel-timeline`}
										/> 
							</div> */}
						</Col>
						<Col lg='3 mt-auto' xs="3">
							<LoadingButton type="button"  label={"Publish timeline"} className="" loading={states.buttonLoading3} disabled={states.buttonLoading3} size="md" onClick={() => onPublish()}  color={'primary'}/>
						</Col>
					</Row>
					{/* <div className="social-media-footer">
						<Button
							color='warning'
							//onClick={() => createNewPlayList()}
							className='videolist__main__button__warning ml-0'>
							save timeline
						</Button>
						<Button
							className='videolist__main__button__warning'
							color='white'
							style={{
								// backgroundColor: `${savePlaylistMessage.bgColor}`,
								// color: `${savePlaylistMessage.color}`,
							}}
							// onClick={() => createNewPlayList()}
						>
							Preview
						</Button>
					</div> */}
				 </div>
			 </div>
		</div>
	);
};

export default WaitingRoomSocialMedia;


// 1.timeline, 2.pages [dropdown] 
//dd > onchange >  post access_token, page
