import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/devices_1.png'
import Image2 from './../../../img/help/devices_2.png'
import Image3 from './../../../img/help/devices_3.png'

const Devices =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <>
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Devices</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc">
                                        <h5>How many devices can I authorize?</h5>
                                        <div className="help_detail">
                                              <p>As part of your subscription, we allow authorization for up to 10 devices across our    supported platforms, including the provided Google TV.</p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image1} className="w-100" alt="" style={{ marginTop:"-10px"}}/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{ marginTop:"25px"}}>
                                        <h5>Removing authorized devices</h5>
                                        <div className="help_detail">
                                           <p>Authorized devices can only be removed if you have Admin privileges. From the ‘authorized devices’ page, simply click ‘manage devices’ and you will be able to remove unwanted devices by clicking ‘unauthorize’.</p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image2} className="w-100" alt="" style={{marginTop:"15px"}}/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc">
                                        <h5>How much do additional device authorizations cost?</h5>
                                        <div className="help_detail">
                                          <p>Additional device authorizations can be purchased in bundles of 5. </p>
                                          <p>$99.99 /month for the first extra 5 devices (11-15)</p>
                                          <p>$49.99 /month for each pack of 5 purchased after the first extra 5 (15-20, 21-25)</p>
                                          <p>For more than 20 extra authorized devices in total, please contact customer support.</p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image3} className="w-100" alt="" style={{marginTop:"30px"}}/>
                                  </div>
                            </div>
                      </div>
                  </>
            
    ) 

}

export default Devices;
