import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import NumberFormat from 'react-number-format';
import { getCartImageByType , DateDDMMMYYY, getCardNameByNo, YY} from '../../../data/comman';

import CVVIcon from '../../../img/Back_CVV.svg'
import CVVIconHover from '../../../img/cvv-hover.png'
import StripeIcon from '../../../img/stripe-icon.png'
import { Button , Input } from 'reactstrap';
import { resetSuccess , clearerror, showError} from "../../../store/actions/taskAction";
import { getCardImageByNo } from '../../../data/comman';
import {AddBillingCard , UpdateBillingCard , PayPracticePayment } from "../../../store/actions/taskAction";
import moment from 'moment';
import LoadingButton from '../../LoadingButton'
import { history } from '../../../pages/index';
import SubHeader from  '../../../components/Header/subHeader'

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdate:false,
      isReplace:false,
      primerCreditCard: {},
      creditCardNumber: "",
      cardHolderName: "",
      viewCardHoldename: "",
      expireDate: "",
      editExpireDate: "",
      code: "",
      formValid:false,
      vardImage:'',
      isError:{
        creditCardNumber: "",
        cardHolderName: "",
        editExpireDate: "",
        code:''
      }
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.onShowUpdatedCard = this.onShowUpdatedCard.bind(this);
  }

  async componentDidMount(){
    
    await this.props.dispatch(clearerror())
    await this.props.dispatch(resetSuccess())
    
  }

  onClickUpdateCard(){
     this.props.dispatch(clearerror())
    this.setState({isUpdate:!this.state.isUpdate , editExpireDate:''})
  }

  onShowUpdatedCard(){
     this.props.dispatch(resetSuccess())
  }

  isValidate(){
    const { cardHolderName, creditCardNumber, editExpireDate, code } = this.state;
    var error = {errors: []};
    var isError = false;

    if(cardHolderName.toString().trim() == ""){
      isError = true;
      error['errors']['card.name'] = ["Please enter name"];
    }

    if(creditCardNumber.toString().trim() == ""){
      isError = true;
      error['errors']['card.number'] = ["Please enter card number"];
    }

    var currentYear = moment().format(YY)
    var currentMonth = moment().format('M')
    if(editExpireDate.toString().trim() == ""){
      isError = true;
      error['errors']['card.exp_month'] = ["Please enter expiry date"];
    }else if(editExpireDate.toString().trim().length < 4){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if(parseInt(this.state.editExpireDate.slice(0,2)) > 12 || parseInt(this.state.editExpireDate.slice(0,2)) < 1){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(editExpireDate.toString().trim().slice(2,6)) > parseInt(currentYear) + 20) || parseInt(editExpireDate.toString().trim().slice(2,6)) < parseInt(currentYear) ){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }else if((parseInt(this.state.editExpireDate.slice(0,2)) < currentMonth) && (parseInt(editExpireDate.slice(2,6))) <= currentYear){
      isError = true;
      error['errors']['card.exp_month'] = ["Invalid expiry date"];
    }
    
    if(code.toString().trim() == ""){
      isError = true;
      error['errors']['card.cvv'] = ["Please enter cvv"];
    }else if(code.toString().trim().length < 3 ){
      isError = true;
      error['errors']['card.cvv'] = ["Invalid cvv"]; 
    }else if(getCardNameByNo(creditCardNumber) == "AMEX" && code.toString().trim().length < 4 ){
      isError = true;
      error['errors']['card.cvv'] = ["Invalid cvv"];
    }

    this.props.dispatch(showError(error));
    return isError
  }

  
 async onAddCard(){
     
    if(this.isValidate()) return;

  let params={
        pay_info:{
        "type": "card",
        "card": {
            "name": this.state.cardHolderName,
            "number": this.state.creditCardNumber,
            "expiry_month":  parseInt(this.state.editExpireDate.slice(0,2)), 
            "expiry_year": parseInt(this.state.editExpireDate.slice(2,6)), 
            "cvv": this.state.code,
        },
            "pay_once": 1,
            "card_id": 0
        }
    }


   await this.props.dispatch(PayPracticePayment(params));
  }
  
  changeHandler(event, filed){
    var filedName = "", filedValue = "";
    var vardImage = this.state.vardImage;
    if(filed){
        filedName = filed;
        filedValue = event.value;
        if(filedName === "creditCardNumber"){
            vardImage = getCardImageByNo(filedValue);
        }
    }else{
        var { name, value } = event.target;
        filedName = name;
        filedValue = value;
    }
    this.setState({
        [filedName]: filedValue,
        vardImage,
        error: {} 
    },()=> {
        this.isValid(filedName , filedValue);
    });
  }

  isValid(name , value){
    let isError = this.state.isError ;

    switch (name) {
      case "creditCardNumber":
          isError.creditCardNumber =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "cardHolderName":
          isError.cardHolderName =  
          value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "editExpireDate":
          isError.editExpireDate =  
          value.length <= 0 ? "required" : "";
          break;
      case "code":
        isError.code =  
        value.length <= 0 ? "required" : "";
        break;
      default:
          break;
    }

    this.setState(this.validateForm)
  }
  
  validateForm(){
    console.log(this.state.editExpireDate,'date')
    this.setState({formValid: this.state.isError.creditCardNumber === '' && this.state.creditCardNumber !== ''  && this.state.isError.cardHolderName === '' && this.state.cardHolderName !== ''  && this.state.isError.editExpireDate ==='' && this.state.editExpireDate !== '' &&  this.state.isError.code === '' && this.state.code !== ''});
  }


  render (){
    const primerCreditCard = this.props.primerCreditCard || {};
    const expireDate = this.props.expireDate
    const { isSuccess , loadingAddCardInfo , loadingUpdate ,buttonLoading4, error, isUpdateSuccess} = this.props
    var imgsrc =  this.state.vardImage,
    isError = this.state.isError
    return(
        <div className="billing__main__screen billing__page  d-flex flex-column h-100 align-items-center">
            <SubHeader className="mb-5" title="Payment Options"/>
            <div className={`${isUpdateSuccess ? 'succesfullyUpdate' : ''} billingcard__inner__panel  transparent__form--white d-flex flex-column`}> {/* for screen 173,174,175 add class bg-danger */}
                <img src={StripeIcon} className="stripe-logo" alt=""/>
                <div className="billingcard__inner__panel__header d-flex flex-column">
                    <h4>One time payment credit card</h4>
                    <p>A Visa, MasterCard, Amex, or Discover credit card used for this one time payment only. The card information will not be saved to our system after the payment is completed.</p>
                </div>
            <>
            <>
                <div className={`${primerCreditCard.brand}-bg billingcard__inner__panel__body   ${this.props.isReplace ? 'isReplace' : ''}  ${primerCreditCard.declined_at && !this.props.isReplace ? 'declined' : ''}`}> {/* mastercard-bg , visacard-bg , amexcard-bg , discovercard-bg*/}
                <div className="row">
                    <div className="col-12">
                        <div className={`${error && error.errors && error.errors['card.number'] ? 'inputerror' : ''} form-group  icon-card `}>
                            <div className={`d-flex justify-content-between `}>
                                <label>Credit card number</label>
                                {error && error.errors && error.errors['card.number'] && <span className='text--danger'>{error.errors['card.number'][0]}</span>}
                            </div>
                            <NumberFormat className="form-control" format="#### #### #### ####" name="creditCardNumber" value={this.state.creditCardNumber} onValueChange={(value)=>this.changeHandler(value, "creditCardNumber")}/>
                            {imgsrc ? <img src={imgsrc}  className="icon-img" alt="" /> : null}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={`form-group ${error && error.errors && error.errors['card.name'] ? 'inputerror' : ''}`}>
                            <div className={`d-flex justify-content-between `}>
                                <label>Name on card</label>
                                {error && error.errors && error.errors['card.name'] && <span className='text--danger'>{error.errors['card.name'][0]}</span>}
                            </div>
                            <Input type="text" name="cardHolderName" onChange={this.changeHandler} value={this.state.cardHolderName} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={`${error && error.errors && error.errors['card.exp_month'] || error && error.errors && error.errors['card.exp_month'] ? 'inputerror' : '' || error && error.errors && error.errors['pay_info.card.expiry_year'] ? 'inputerror' : ''} form-group mb-0`}>
                            <label>Expiry date</label>
                            <NumberFormat  className="form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="editExpireDate" onValueChange={(value)=>this.changeHandler(value, "editExpireDate")} value={this.state.editExpireDate}/>
                            {error && error.errors && error.errors['card.exp_month'] && <span className='text--danger'>{error.errors['card.exp_month'][0]}</span>}
                        </div>
                    </div>
                    <div className="col-6">
                    <div className={`form-group  mb-0 cvv-img ${error && error.errors && error.errors['card.cvv' ]? 'inputerror' : ""}`}>
                            <label>Security code <img src={CVVIcon} alt="" /> </label>
                            <NumberFormat  className="form-control"  format={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "####" : "###"} placeholder="" name="code" onValueChange={(value)=>this.changeHandler(value, "code")} type="password"/>
                            {error && error.errors && error.errors['card.cvv'] && <span className='text--danger'>{error.errors['card.cvv'][0]}</span>}
                            <div className="what-cvv">? <span><img src={CVVIconHover} alt=""/> <p>The 3 or 4 numbers on the back of your Visa, MasterCard, or AMEX card.</p></span></div>
                        </div>
                    </div>
                    {primerCreditCard.declined_at && !this.props.isReplace &&
                    <div className="card__declined text-center d-flex justify-content-center align-items-center">
                        Declined payment  | { moment(primerCreditCard.declined_at).format(DateDDMMMYYY) }
                    </div>
                    }
                </div>
                </div>
                <div className="billingcard__inner__panel__footer mt-auto">
                    {isSuccess ?
                        <div className="d-flex justify-content-betweeb">
                            <LoadingButton type="button" label={`Card added & Payment received. Thank you!`}  className="w-100" loading={loadingUpdate} /*disabled={!this.state.formValid}*/  onClick={this.onShowUpdatedCard} color="green"/>
                        </div>
                    :
                        <div className="d-flex justify-content-betweeb">
                            <LoadingButton type="button" className="mr-3" error={this.props.error} label={`Pay outstanding balance  |  $${this.props.getPracticePaymentinfo.outstanding_balance}`} loading={buttonLoading4} disabled={buttonLoading4} size="xxl" onClick={() => this.onAddCard()} color="primary"/>
                            <Button size="xs" onClick={() => history.goBack()} className="ml-3 px-5" outline color="default white">Back</Button>
                        </div>
                    }
                </div>
            </>
            </> 
            </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingAddCardInfo:state.task.loadingAddCardInfo,
  loadingBillingCardInfo:state.task.loadingBillingCardInfo,
  loadingUpdate:state.task.loadingUpdate,
  isUpdateSuccess:state.task.isUpdateSuccess,
  isSuccess:state.task.isSuccess,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo,
  getBillingCardInfo:state.task.getBillingCardInfo,
  error:state.task.error,
  getPracticePaymentinfo:state.task.getPracticePaymentinfo,
  buttonLoading4:state.task.buttonLoading4
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
