import React from 'react';
import SubHeader from '../../components/Header/subHeader';

import { history } from '..';
import './styles.scss';

const PLAYER_ICON = "/assets/img/categorySelect/player_icon.png";
const WEBSITE_LIBRARY = "/assets/img/categorySelect/video_library.png"
const EMBED_CODE = "/assets/img/categorySelect/embed-code-icon.svg"


function SelectCategory() {
    return (
        <div className="main__screen channels__page selectcatrgory__body  d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Website Videos"/>
            {/* <div className="selectcategory__main">
                <h4 className="selectcategory__main__headerTitle">Tailor your audience experience</h4>   
                <h5 className="selectcategory__main__line">Customize your video player, as well as the content it streams, to give your patients an experience that's unique to your practice.</h5>
            </div> */}
            <div className="selectcategory__main__inner__screen mb-auto d-flex">
                <div className="channels__item mx-20" onClick={(e)=> history.push("/website-player-customization")}>
                  <div className="channels__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="channels__icon selectcategory_content">
                          <img src={PLAYER_ICON} className="selectcategory_image" alt="" />
                      </div>
                  </div>
                  <div className="channels__name my-3 text-center">
                        <p className="m-0">WEBSITE PLAYER CUSTOMIZATION</p>
                  </div>
                  <p>Customize your video player, as well as the content it streams, to give your patients an experience that's unique to your practice.</p>
                </div>
                <div className="channels__item mx-20" onClick={(e)=> history.push("/website-videos")}>
                  <div className="channels__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="channels__icon selectcategory_content">
                          <img src={WEBSITE_LIBRARY} className="selectcategory_image" alt="" />
                      </div>
                  </div>
                  <div className="channels__name my-3 text-center">
                        <p className="m-0">WEBSITE VIDEO LIBRARY</p>
                  </div>
                  <p>Choose from up to 50 Thalamus videos to play on your website.</p>
                </div>
                <div className="channels__item mx-20" onClick={(e)=> history.push("/embed-code")}>
                  <div className="channels__box  d-flex flex-column align-items-center justify-content-center">
                      <div className="channels__icon selectcategory_content">
                          <img src={EMBED_CODE} className="selectcategory_image" alt="" />
                      </div>
                  </div>
                  <div className="channels__name my-3 text-center">
                        <p className="m-0">Embed code</p>
                  </div>
                  <p>Access the HTML code to embed individual website videos or the entire website player.</p>
                </div>
            </div>
        </div>    
    )
}
export default SelectCategory
