export const handleDuplicates = (duplicates) => {
    const obj = {}
    duplicates.map((item)=>{
        if(obj[item.chairside_media_id]){
            obj[item.chairside_media_id].push(item.category.title)
        }else{
            obj[item.chairside_media_id] = [item.category.title]
        }
    })
    console.log("OBJJJJJ",obj)
    return obj
}