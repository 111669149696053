/** @format */

import React, { useState } from 'react';
import Profile_icon from '../../img/play_white.svg';
import { Row, Col, Container, Label } from 'reactstrap';
import Player from '../../components/videoPlayer/player';
import { EDUCATIONTYPE, formatSecond, TIMELINEMEDIATYPE } from '../../data/comman';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditeImageModal from '../../components/EditImages/EditImageModel';
import styled from 'styled-components';

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomVideoSelected = ({ videos, setVideos}) => {
	const [showVideo, setShowVideo] = useState({
		video: [],
		showVideo: false,
	});
	const [count, setCount] = useState(0);
	const [playerVideo, setPlayerVideo] = useState(null);
	const [editorImg, setEditorImg] = useState({});
	const [isEditor, setIsEditor] = useState(false);
	const [modal, setModal] = useState(false);

	const playSelectedVideo = (video) => {
		// setPlayerVideo(video);
		setShowVideo({
			video: video,
			showVideo: true,
		});
	};
	const onClickClose = (close) => {
		setShowVideo({
			video: {},
			showVideo: false,
		});
	};
	const toggle = () => setModal(!modal);


    const openImgEditor = (img) => {
		if (img) {
			setEditorImg(img)
			setIsEditor(true)
			onClickClose()
		}
    }

	const togglePip = (isPipOn) => {
		setShowVideo({
			...showVideo,
			showVideo: !isPipOn,
		});
	}

	const changeVideoSelected = (video) => {
		setCount(count + 1);
	};

	const Item = styled.div`
		display: flex;
		user-select: none;
		padding: 0.5rem;
		margin: 0 0 0.5rem 0;
		align-items: flex-start;
		align-content: flex-start;
		line-height: 1.5;
		border-radius: 3px;
		background: #fff;
		// border: 1px ${props => (props.isDragging ? 'dashed #4099ff' : 'solid #ddd')};
		border: 1px dashed #4099ff;
	`;

	const format = (seconds) => {
		if (isNaN(seconds)) {
			return '00:00';
		}
		const date = new Date(seconds * 1000);
		const hh = date.getUTCHours();
		const mm = date.getUTCMinutes();
		const ss = date.getUTCSeconds().toString().padStart(2, '0');

		if (hh) {
			return `${hh}:${mm.toString().padStart(2, '0')} :${ss}`;
		}
		return `${mm}:${ss}`;
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        // padding: test1.length * 2,
        // margin: `0 0 ${test.length}px 0`,

        background: isDragging ? 'lightgreen' : '',

        ...draggableStyle
    });
	return (
		<>
			<div className='videoSelected__main__centerCol '>
				<Droppable droppableId="droppable_education" direction="horizontal" isDropDisabled={true}>
					{(provided, snapshot) => (
						<div className={'w-100'}
							ref={provided.innerRef}
							isDraggingOver={snapshot.isDraggingOver}

							// style={getListStyle(snapshot.isDraggingOver)}
							>
							<div className="video_list">

								{videos.length > 0 ? videos.map((video, index) => {
									const selectedImage = video.selected === true ? RIGHTBOXIMAGE : WHITEBOXIMAGE;
									return (
										// <Droppable droppableId="droppable" direction="horizontal">
										// 		{(provided, snapshot) => (
										// 			<>
													<div /*ref={provided.innerRef}*/  key={index} className={`videoSelected__main__columnFour videoSelected_box ${video.isAdded.length > 0 ? 'border-4 border-warning' : ''}`}>
															<div >
															{/* <Draggable
																// key={video.waiting_room_media_id}
																draggableId={`${video.waiting_room_media_id}`}
																index={index}>
																{(provided, snapshot) => ( */}
																	<div
																	// ref={provided.innerRef}
																	// {...provided.draggableProps}
																	// {...provided.dragHandleProps}
																	draggable={true} onDragStart={(event) => { event.dataTransfer.setData("timeline", JSON.stringify({ type: TIMELINEMEDIATYPE, timeLine: video })) }}
																		className={`videoSelected__main__card `}
																		onClick={() => changeVideoSelected(video)}>
																		{/* <img
																			className='videoSelected__main__card__selectedImage'
																			src={`${selectedImage}`}
																			alt=''
																		/> */}
																		{video.isAdded.length > 0 && <h3 className='videoSelected__main__card__selectedImage' >{video.isAdded.length}</h3>}
																		<div style={{ height: '100%', width: '100%' }}>
																			<img
																				src={video.thumb}
																				alt={video.file_name}
																				style={{
																					height: '100%',
																					width: '100%',
																					objectFit: 'cover',
																				}}
																			/>
																		</div>
																		<div className='videoSelected__main__card__subTitle'>
																			<div className='videoSelected__main__card__video_icon' onClick={() => playSelectedVideo(video)}>
																				<img
																					src={Profile_icon}
																					alt='play_video'
																					
																				/>
																			</div>
																			<p className='videoSelected__main__card__title'>
																				{video.title} | {formatSecond(video.duration)}
																			</p>
																			<p className='videoSelected__main__card__smalltitile'>
																				{video.category_name}
																			</p>
																		</div>
																	</div>
																{/* )} */}
															{/* </Draggable> */}
															</div>
													</div>
													// {/* {provided.placeholder} */}
									// 				</>
									// 			)}
									// 	</Droppable>
									)
								}):null}
								{console.log(showVideo,'showVideo')}
							</div>
							{provided.placeholder}
						</div>
					)}
				</Droppable>

				{showVideo.video.service_provider_id === 1 ? (
					<div size="xxl" className={`${showVideo.showVideo ? 'bg-block' : 'bg-none'} h-100 d-flex flex-column align-items-strach justify-content-center modal-video`} isOpen={showVideo.video} toggle={toggle}>
						<div className={`${showVideo.showVideo ? 'd-block' : 'd-none'} video-show sdf  mt-5`}>

							<Player
								video={showVideo.video}
								title={showVideo.video}
								className="w-100 h-100"
								onClickClose={onClickClose}
								openImgEditor={openImgEditor}
								togglePip={togglePip}
								isWaitingRoom={true}
							/>

						</div>
					</div>
				) : ('')}
			</div>
		</>
	);
};

export default WaitingRoomVideoSelected;
