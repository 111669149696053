import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchChairsideMedia, fetchChairsideMediaCategoryList, getOperatoryMediaChannelList, getWebsiteRoomMediaChannelList, setGoogleTvTestDriveCatIsOpen, setSelectedChairSideMediaSpecializationCategory } from '../../../../store/actions/taskAction';
import styles from './style.module.scss';
import SpecializationsBox from '../SpecializationsBox'
import ChannelList from '../ChannelList';
import { history } from '../../../../pages/index';

const Specializations = () => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const [categoriesList, setCategoriesList] = useState(
		state.chairSideCategoryList
	);
    const [videosList, setVideosList] = useState([]);
    const [catId, setCatId] = useState();
    const [catTitle, setCatTitle] = useState();
    const [isOpen, setisOpen] = useState(false);

    const toggleisOpen = async ()=>{
        // setisOpen(!isOpen)
        let params ={
            catTitle:'',
            videosList:[]
        }
        dispatch(setGoogleTvTestDriveCatIsOpen(params))
    }
    useEffect(() => {
        
		dispatch(fetchChairsideMediaCategoryList(state.practiceUserLoginInfo));
	}, []);

    useEffect(() => {
		setCategoriesList(state.chairSideCategoryList)
	}, [state.chairSideCategoryList]);

    const handleClick = (id, title) => {
        setCatId(id);
        setCatTitle(title);

        let params ={
            catTitle:catTitle,
            videosList:videosList
        }
        dispatch(setGoogleTvTestDriveCatIsOpen(params))
	}

    useEffect(() => {
        const videos = [];
        if (state?.chairSideMediaState.length && catId > 0 && catId!== undefined)
        {
            state.chairSideMediaState.map(video => {
                if (video.category.id === catId)
                    {
                        videos.push(video);
                    }
            });
            let params ={
                catTitle:catTitle,
                videosList:videos
            }
            dispatch(setGoogleTvTestDriveCatIsOpen(params))
        }
        setVideosList(videos);
  
      

    }, [catId ]);

  return (
    <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
        {console.log(state.catIsOpen,'state.catIsOpen', state.catList)}
        {state.catList.length == 0 ?
            <>
                <div className='px-20 mt-2 mb-2 d-flex align-items-center'>
                    <h2 className={`${styles.videoSliderSectionTitle} mb-0`}>Specializations</h2>
                </div>
                <div className='d-flex flex-wrap'>
                    {categoriesList.map((item,index) => {
                        return (
                            <SpecializationsBox key={index} item={item} handleClick={handleClick}/>
                        )
                    })}
                </div>
            </>
        :
            <ChannelList title={state.catTitle} isCat={true} toggleisOpen={toggleisOpen} isChairside={true} noSlider={true} channelsData={state.catList}/>
        }
        </div>
  )
}

export default Specializations
