/** @format */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col, Form, Input, Button , FormGroup , Label} from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DropDown from '../../components/DropDown'
import './styles.scss';
import {
	fetchHelperTimeline,
	getFooterTime,
	getFooterWeather,
	postWeatherTime,
	waitingRoomLiveWeather,
} from '../../store/actions/taskAction';
import time_img from '../../img/time_img.png';
import { getMinutes } from '../../data/comman';
import {hours} from '../../data/comman'
import moment from 'moment';
import LoadingButton from '../../components/LoadingButton'

    
const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		// bottom: 48,
		// left: 195,
		// margin: theme.spacing(1),
		margin:0,
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#52d869',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: '#52d869',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});


const OperatoryTime = () => {
	const [state, setState] = useState({
		checkedB: false,
		checkedC: false,
		hours
	});

	const dispatch = useDispatch();
	const states = useSelector((state) => state.task);
	console.log('states.liveFooterTime : ', states.liveFooterTime);
	const [selectedHelperTimezone, setSelectedHelperTimezone] = useState(null);
	const [selectedHours, setSelectedHours] = useState(null);
	const [selectedMinute, setSelectedMinute] = useState(null);
	const [selectedAmPm, setAmPm] = useState(null);
	const [time, setTime] = useState({
		hours: '',
		minutes: '',
		abbreviation: '',
		militaryTime: '1',
	});
	const [message, setMessage] = useState({
		message: 'Save Changes',
		bgColor: 'primary',
	});
	const [newLocation, setNewLocation] = useState(states.weatherLocation);
	useEffect(() => {
		dispatch(fetchHelperTimeline());
		dispatch(getFooterTime(states.manageOperatoryChannel.id, true));
		// dispatch(getFooterWeather(states.managewaitingRoomMediaChannel.id));
		// let params ={
		// 	auto_set_location: newLocation.auto_set_location,
		// 	location: newLocation.location,
		// 	unit: newLocation.unit,
		// }
	}, []);

	useEffect(() => {
		setSelectedHelperTimezone(states.helperTimezone.find(x => x.id == states.liveFooterTime.time_zone_id));
		setSelectedHours(getHours(states.liveFooterTime.time));
		setSelectedMinute(getMinute(states.liveFooterTime.time))
		setAmPm(getAMPM(states.liveFooterTime.time))
	}, [states.liveFooterTime]);

	useEffect(() => {
		setSelectedHours(getHours(states.liveFooterTime.time));
		setState({ ...state, hours: hours.slice(0, state.checkedC ? 24 : 12) });
	}, [state.checkedC]);
	

	const bgImg = 'assets/img/bg.png';

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
		setSelectedHours(getHours(states.liveFooterTime.time));
		setMessage({
			message: 'Save Changes',
			bgColor: 'primary',
		});
	};

	const timeZoneChangeHandler = (value) => {
		setSelectedHelperTimezone(value);
		setMessage({
			message: 'Save Changes',
			bgColor: 'primary',
		});
	};
	const hoursChangeHandler = (value) => {
		setSelectedHours(value)
		setMessage({
			message: 'Save Changes',
			bgColor: 'primary',
		});
	};
	const minuteChangeHandler = (value) => {
		setSelectedMinute(value)
		setMessage({
			message: 'Save Changes',
			bgColor: 'primary',
		});
	};

	const amPmChangeHandler = (value) => {
		setAmPm(value)

		setMessage({
			message: 'Save Changes',
			bgColor: 'primary',
		});
	};

	const PracticeAmPm = [
		{ value: 0, label: 'AM' },
		{ value: 1, label: 'PM' },
	]
	
	const getHours = (time) =>{
		var HourArray = getHMA(time);
		return hours.find(x => x.value === HourArray[0]);
	}

	const getHMA = (time) => {
		var hour = moment(time, "HH:mm").format(state.checkedC? 'HH' : 'hh');
		var minute = moment(time, "HH:mm").format('mm');
		var ampm = moment(time, "HH:mm").format('A');
		return [ hour, minute, ampm ];
	}
	
	const getMinute = (time)=> {
		var MinuteArray = getHMA(time);
		return getMinutes().find(x => x.label === MinuteArray[1]);
	}
	
	const getAMPM = (time) =>{
		var AmPmArray = getHMA(time);
		return PracticeAmPm.find(x => x.label === AmPmArray[2]);
	}
	
	const timeChangeHandler = (event) => {
		let params ={
			auto_abjust_time_and_date: state.checkedB ? '1' : '2',
			time_zone_id: selectedHelperTimezone.id,
			time: selectedHours.value + ':' + selectedMinute.value,
			military_time: state.checkedC ? '1' : '2',
		}
		console.log(params,'selectedHelperTimezone')
		dispatch( postWeatherTime(states.manageOperatoryChannel.id,params, true) );

		setMessage({
			message: 'Changes saved',
			bgColor: 'green',
		});
	};
	return (
		<div className='d-flex operaotry_room_common_section operaotry_room_time_section'>
			<div className='transparent__form--white d-flex flex-column operatory_room_common_section_time_zone'>
				<h4 className="mb-5">Time</h4>
				<FormGroup className="d-flex align-items-center">
					<p className="m-0">Adjust time and date automatically </p>
					<FormControlLabel className='toggle-button ml-4 mr-auto' control={ <IOSSwitch checked={state.checkedB} onChange={handleChange} name='checkedB' /> }/>
				</FormGroup>
				<FormGroup className={state.checkedB ? 'disable' : ' '}>
					<div className="d-flex justify-content-between">
						<Label>Time zone</Label>
					</div>
					<DropDown 
						className='react-select-container'
						classNamePrefix="react-select"
						options={states.helperTimezone}
						getOptionLabel={ x => `${x.time_zone}`}
						getOptionValue={ x => `${x.id} ${x.time_zone}`}
						isDisabled={state.checkedB ? true : false}
						onChange={timeZoneChangeHandler}
						isSearchable={false}
						placeholder={''}
						value={selectedHelperTimezone}
					/>
				</FormGroup>
				<div className={ `d-flex justify-content-between`}>
					<FormGroup className={`align-items-center m-0  ${state.checkedB ? 'disable' : ' '}`}>
						<Label className="p-0 mb-2 w-100" >Hr.</Label>
						<DropDown 
							className='react-select-container select-style-3 w-101 p-0'
							classNamePrefix="react-select"
							isDisabled={state.checkedB ? true : false}
							getOptionLabel={ x => `${x.label}`}
							getOptionValue={ x => `${x.value} ${x.label}`}
							options={state.hours}
							isSearchable={false}
							placeholder={''}
							onChange={hoursChangeHandler}
							value={selectedHours}
							
						/>
					</FormGroup>
					<FormGroup className={`align-items-center m-0  ${state.checkedB ? 'disable' : ' '}`}>
						<Label className="p-0 mb-2 w-100" >Min</Label>
						<DropDown 
							className='react-select-container w-101 p-0'
							classNamePrefix="react-select"
							isDisabled={state.checkedB ? true : false}
							getOptionLabel={ x => `${x.label}`}
							getOptionValue={ x => `${x.value} ${x.label}`}
							options={getMinutes()}
							onChange={minuteChangeHandler}
							isSearchable={false}
							placeholder={''}
							value={selectedMinute}
						/>
					</FormGroup>
					<FormGroup className={`align-items-center m-0  ${state.checkedB || state.checkedC ? 'disable' : ' '}`}>
						<Label className="p-0 mb-2 w-100" >AM / PM</Label>
						<DropDown 
							className='react-select-container w-101  p-0'
							classNamePrefix="react-select"
							isDisabled={state.checkedB || state.checkedC ? true : false}
							options={PracticeAmPm}
							isSearchable={false}
							placeholder={''}
							onChange={amPmChangeHandler}
							value={selectedAmPm}
							// onChange={(option)=> this.handleChange("sA", option, values.additional_info.schedule[5], 5)}
						/>
					</FormGroup>
					<FormGroup className={`d-flex align-items-center m-0 px-7_5 ${state.checkedB ? 'disable' : ' '}`}>
						<Label className="p-0 mt-5 mb-0 mr-2" >24h</Label>
						<FormControlLabel className='toggle-button m-0 mt-5' control={
								<IOSSwitch
									checked={state.checkedC}
									onChange={handleChange}
									disableRipple={state.checkedB ? true : false}
									name='checkedC'
								/>
							}
						/>
					</FormGroup>
				</div>
				<div className='mt-auto'>
					<LoadingButton type="button"  label={message.message} loading={states.buttonLoading} disabled={states.buttonLoading} size="xxl" onClick={timeChangeHandler} className={`w-100`}  color={message.bgColor}/>
				</div>
			</div>

			<div className='operatory_room_common_section_img'>
				<img className='' src={time_img} alt="" />

				{/* <div class='Page7__main__div2__card'>
					<label>NEWS</label>
					<div class='Page7__main__div2__card-header'>
						Welcome to Smith Dental!
					</div>
					<div class='Page7__main__div2__card-body'>
						<p class='card-text' style={{ color: 'black' }}>
							With supporting text below as a natural lead-in to additional
							content With supporting text below as a natural lead-in to
							additional.
						</p>
					</div>
					<div className='Page7__main__timeweather'>
						<p style={{ backgroundColor: '#c02323' }}>
							{states.liveFooterTime ? states.liveFooterTime.time : ''}
						</p>
					
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default OperatoryTime;
