import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedChairSideMediaSpecializationCategory } from '../../../store/actions/taskAction'
import './specializationsBox.scss'

const SpecializationsBox = ({item : {image, title, id} , handleClick}) => {
    const state = useSelector(state => state.task);
    
  return (
    <div className='col-6 px-20 py-20 '>
        <div className='specialization_box' onClick={()=>handleClick(id, title)}>
            <div className='specialization_box__img'>
                <img src={image} alt={title} />
            </div>
            <div className='specialization_box__title d-flex align-items-center justify-content-center'>
                <h2>{title}</h2>
            </div>
        </div>
    </div>
  )
}

export default SpecializationsBox
