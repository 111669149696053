import React from 'react';
import { Button , Label , FormGroup, Form , Col , Row} from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Ownership, isValidEmail } from '../../../data/comman';
import DropDown from '../../../components/DropDown'
import { InputField } from '../../../components/InputField';
import LoadingButton from '../../../components/LoadingButton'
import { GetpracticeProfile, UpdatePracticeProfile } from '../../../store/actions/taskAction';
import { history } from '../../../pages/index';

class PracticeInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors:{}
    };
    this.nextClick = this.nextClick.bind(this);
  }

  async nextClick(){
    const {values} = this.props
    
    // if(values&&values.phone_alt !== ""){
    //   var forCountForAlt = values.phone_alt?((values.phone_alt.replace(values.phone_alt.split(/[ \-]/)[0],'')).replace(/[^\w]/gi, '')).length:0;
    //   forCountForAlt<10 && console.log("Wrong")
    // }
    
    var errors = {};
    var isError = false;
    if (values) {


      if(values&&values.phone !== ""){
        var forCount = values.phone?((values.phone.replace(values.phone.split(/[ \-]/)[0],'')).replace(/[^\w]/gi, '')).length:0;
        // forCount<10 && console.log("Wrong")
        if (forCount<4) {
          errors['phone'] = "Please enter valid phone number";
          isError = true;
        }
      }
      // console.log(values,'value')
      if (values.name == "") {
        errors['name'] = "Please enter name.";
        isError = true;
      }

      if (values.phone == "") {
        errors['phone'] = "Please enter phone number.";
        isError = true;
      }

      if (values.email == "") {
        errors['email'] = "Please enter email.";
        isError = true;
      }else if(isValidEmail(values.email)){
        errors['email'] = "Please enter valid email.";
        isError = true;
      }

      if (values.city == "") {
        errors['city'] = "Please enter city.";
        isError = true;
      }

      // if (values.selectedownership == undefined ) {
      //   errors['selectedownership'] = "Please select ownership.";
      //   isError = true;
      // }

      
      if (values.zip == '') {
        errors['zip'] = "Please enter zip code.";
        isError = true;
      }

      if (values.address_line_1 == '' || values.address_line_1 == undefined || values.address_line_1 == null) {
        errors['address_line_1'] = "Please enter address.";
        isError = true;
      }

      
      // if (values.specializations.length == 0) {
      //   errors['specializations'] = "Please select specializations.";
      //   isError = true;
      // }

      if (values.selectedstate == undefined ) {
        errors['selectedstate'] = "Please select State / Province.";
        isError = true;
      }

      if (values.selectedpracticetype == undefined) {
        errors['selectedpracticetype'] = "Please select type.";
        isError = true;
      }
    }

    if (isError) {
      this.setState({errors:errors});
      return false
    }

    // this.props.nextStep();
    await this.props.dispatch(UpdatePracticeProfile(this.props.practiceProfileInfo.id  ,values));

    if(this.props.practiceInfo.need_owner_setup === 1){
      history.push('/edit-authorized-profile');
    }else{
      history.push('/profileselection');
    }
    
    if(this.props?.error?.status != 400){
     await this.props.dispatch(GetpracticeProfile());
    }
  }

  async componentDidMount(){
    // this.setState({})
  }

  render (){
    const {t , country , specializations , loading , error , values, buttonLoading} = this.props
    console.log(values,'valuesvaluesvaluesvaluesvalues')
    return(
      
        <div className="mw-820 transparent__form--white d-flex flex-column align-items-stretch ">
          <Form className="mt-2 h-100 d-flex flex-column">
              <Row>
                <Col md={6} className="padd-r-20">
                  <FormGroup className="mb-5 form-heading">
                    <h4 className="m-0">{t('title.practiceInformation')}</h4>
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.practiceName')} name="name" className={this.state.errors && this.state.errors['name'] ? 'invalid-field' : ''} defaultValue={values.name}  isError={this.state.errors && this.state.errors['name'] } onChange={(e) => this.props.handleChange('name', e)} />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Practicephone">{t('form.practicePhone')}</Label>
					            {this.state.errors && this.state.errors['phone'] && <span className='text--danger'>{this.state.errors['phone']}</span>}
                    </div>
                    <PhoneInput defaultMask={'-...........'} alwaysDefaultMask={true} countryCodeEditable={false} inputProps={{ name: 'phone',required: true, }}  country={(this.props.practiceProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => { this.props.handlePhoneChange(value, country, 'phone', formattedValue)}} value={(values.phone)} />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Secondarypracticephone">{t('form.SecondaryPracticePhone')}</Label>
                    </div>
                    <PhoneInput defaultMask={'-...........'} alwaysDefaultMask={true} countryCodeEditable={false} inputProps={{ name: 'phone_alt' }}  country={(this.props.practiceProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => { this.props.handlePhoneChange(value, country, 'phone_alt', formattedValue)}} value={(values.phone_alt)} />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.practiceEmail')} name="Practiceemail" defaultValue={values.email} className={this.state.errors && this.state.errors['email'] ? 'invalid-field' : ''}  isError={this.state.errors && this.state.errors['email'] }  onChange={(e) => this.props.handleChange('email', e)}  />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                        <Label for="customerType">{t('form.customerType')}</Label>
                        {this.state.errors && this.state.errors['selectedpracticetype'] && <span className='text--danger'>{this.state.errors['selectedpracticetype']}</span>}

                      </div>
                      <DropDown 
                          className='react-select-container'
                          classNamePrefix="react-select"
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          options={this.props.practice_customer_types}
                          onChange={(val)=> this.props.handleChange('practicetype', val, "dropdown") }
                          isSearchable={true}
                          placeholder={''}
                          defaultValue={values.selectedpracticetype}
                      />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="practiceManagementSoftware">{t('form.practiceManagementSoftware')}</Label>
                    </div>
                    {this.state.errors && this.state.errors['selectedsoftwares'] && <span className='text--danger'>{this.state.errors['selectedsoftwares']}</span>}
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        options={this.props.practice_softwares}
                        onChange={(val)=> this.props.handleChange('softwares', val, "dropdown") }
                        isSearchable={true}
                        placeholder={''}
                        value={values.selectedsoftwares}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} className="padd-l-20">
                  <FormGroup className="mb-5 form-heading">
                    <h4 className="m-0">{t('title.address')}</h4>
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="country">{t('form.country')}</Label>
                    </div>
                    <DropDown 
                        className='react-select-container'
                        classNamePrefix="react-select"
                        options={country}
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        onChange={(val)=> this.props.handleChange('country', val, "dropdown") }
                        isSearchable={true}
                        placeholder={''}
                        value={values.country}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.streetandnumber')} name="Streetandnumber" className={this.state.errors && this.state.errors['address_line_1'] ? 'invalid-field' : ''} isError={this.state.errors && this.state.errors['address_line_1'] } defaultValue={values.address_line_1}  onChange={(e) => this.props.handleChange('address_line_1', e)}  />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.apartment')} name="Apartment" defaultValue={values.address_line_2}  onChange={(e) => this.props.handleChange('address_line_2', e)}  />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.city')} name="city" defaultValue={values.city} className={this.state.errors && this.state.errors['city'] ? 'invalid-field' : ''} isError={this.state.errors && this.state.errors['city'] }  onChange={(e) => this.props.handleChange('city', e)} />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="state">{t('form.state')}</Label>
                      {this.state.errors && this.state.errors['selectedstate'] && <span className='text--danger'>{this.state.errors['selectedstate']}</span>}
                    </div>
                    <DropDown 
                          className='react-select-container react-select__multi'
                          classNamePrefix="react-select"
                          options={values.countryState}
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          onChange={(val)=> this.props.handleChange('selectedstate', val, "dropdown") }
                          isSearchable={true}
                          placeholder={''}
                          value={values.selectedstate}
                      />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" label={t('form.postalcode')} name="Postalcode" className={this.state.errors && this.state.errors['zip'] ? 'invalid-field' : ''} defaultValue={values.zip} isError={this.state.errors && this.state.errors['zip'] }   onChange={(e) => this.props.handleChange('zip', e)}  />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mt-auto text-center">
                {/* <Button type="button" size="md" onClick={ this.nextClick } className="mt-5" color="primary">{t('btn.next')}</Button> */}
                <LoadingButton type="button" error={this.props.error} label={'Submit'} loading={buttonLoading} disabled={buttonLoading} size="md" onClick={ this.nextClick } className={`mt-5`} color="primary"/>
              </div>
            </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  country: state.task.country,
  specializations: state.task.specializations,
  practiceProfileInfo:state.task.practiceProfileInfo,
  buttonLoading: state.task.buttonLoading,
  practice_customer_types:state.task.practice_customer_types,
  practice_softwares: state.task.practice_softwares,
  practiceInfo: state.task.practiceInfo,
});


export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(PracticeInfo);
