import React, {useState, useEffect} from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { useSelector, useDispatch } from 'react-redux';
import './style.scss';
import { Button, Input } from 'reactstrap';
import Sidebar, { TabPanel } from '../../components/navSideBar/Sidebar';
import HelpSidebar from './helpsidebar'
import { HelptabItems, tabList } from './HelpStatic';
import { List } from '@material-ui/core';
import Fuse from "fuse.js";
import listOfComponent from "./component.json";
import DropDown from '../../components/DropDown'
import InitialSetup from '../Help/HelpPage/InitialSetup';
import SignIn from '../Help/HelpPage/SignIn';
import Corporate from '../Help/HelpPage/Corporate';
import Updates from '../Help/HelpPage/Update';
import ProfileAuthorization from '../Help/HelpPage/ProfileAuthorized';
import Devices from '../Help/HelpPage/Devices';
import Languages from '../Help/HelpPage/Language';
import MyProfile from '../Help/HelpPage/MyProfile';
import ChairsideEducation from '../Help/HelpPage/ChairsideEducation';
import WaitingRoom from '../Help/HelpPage/WaitingRoom';
import Operatory from '../Help/HelpPage/Operatory';
import WebsiteVideos from '../Help/HelpPage/WebsiteVideos';
// import { HelptabItems, HelptabList } from '../chairsideEducation_8821/staticFile';
import { history } from '..';
const HelpPages =()=> {

  const [value, setValue] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen)
  const [subnav, setSubnav] = useState(false) 
  const [menuOpen, setmenuOpen] = useState(false) 
  const [setedId, setSetedId] = useState(<InitialSetup />) 
  
  function setComponentForRander(component){
    setSetedId(component)
  }
  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  useEffect(()=>{
    setSearchData([])
  },[])
  const fuse = new Fuse(listOfComponent,{
    // shouldSort: true,
    // threshold: 0.1,
    // location: 0,
    // distance: 100,
    keys: ["name", "tags","content"]
  });

  const searchItem = (event) => {
    setmenuOpen(true)
    setSearchInput(event.target.value);
    setSearchData(fuse.search(event.target.value));
  };
  const selectedComponent = (event) => {
  
    if (event&&event.component === 'SignIn') {
      setSetedId(<SignIn />);
    } else if(event&&event.component === 'InitialSetup'){
      setSetedId(<InitialSetup />);
    } else if(event&&event.component === 'Corporate'){
      setSetedId(<Corporate />);
    } else if(event&&event.component === 'Updates'){
      setSetedId(<Updates />);
    } else if(event&&event.component === 'ProfileAuthorization'){
      setSetedId(<ProfileAuthorization />);
    } else if(event&&event.component === 'Devices'){
      setSetedId(<Devices />);
    } else if(event&&event.component === 'Languages'){
        setSetedId(<Languages />);
    } else if(event&&event.component === 'MyProfile'){
        setSetedId(<MyProfile />);
    } else if(event&&event.component === 'ChairsideEducation'){
        setSetedId(<ChairsideEducation />);
    } else if(event&&event.component === 'WaitingRoom'){
        setSetedId(<WaitingRoom />);
    } else if(event&&event.component === 'Operatory'){
        setSetedId(<Operatory />);
    } else if(event&&event.component === 'WebsiteVideos'){
        setSetedId(<WebsiteVideos />);
    }
    setmenuOpen(false)
  };

    return(
      <>
      <div className="help__screen help__page  d-flex flex-column h-100 main-bg">
          <SubHeader className="mb-auto" title='Help'/>
            <div className="main__inner__screen mb-auto d-flex w-100" style={{marginTop:'31px',overflowY:'scroll'}}>
                  <div className="help_sidebar">
  
                        <HelpSidebar
                          value={value}
                          setValue={setValue}
                          tabList={tabList}
                          onClick={toggle}
                          setIdComponent={setComponentForRander}
                         
                          >
                        </HelpSidebar>
                  </div>
                  <div className="help_rightsidebar">
                      <div className="search-input">
                          <Input type="text" name="Search" placeholder="Search" onChange={(e)=>searchItem(e)} value={searchInput} />

                          <DropDown 
                          className='react-select-container react-select__multi'
                          classNamePrefix="react-select"
                          options={searchData}
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.component} ${x.name}`}
                          onChange={(e)=>selectedComponent(e)}
                          noOptionsMessage={() => null}
                          // closeMenuOnSelect={true}
                          menuIsOpen={menuOpen}
                          // isSearchable={true}
                          placeholder={'Search'}
                          value={searchInput}
                      />
                      </div>  
             
                      {setedId !== '' ?
                       HelptabItems.map((item, index) => (
                        <TabPanel
                          value={value}
                          index={index}
                          key={index} >
                          {setedId}
                        </TabPanel>
                      )): null }
                      <div className="help_contact_us">
                          <p>Can’t find what you were looking for?</p>
                          <Button onClick={(e)=> history.push("/contact-us")} size="sm" color="white">Contact us</Button>
                      </div>
                  </div>
                 
            </div>

      </div>
      </>
    );

};
export default HelpPages;
