import React from 'react';
import { Button , Label , FormGroup, Form , Col , Row} from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { isRequired, isValidEmail, Ownership } from '../../../data/comman';
import DropDown from '../../../components/DropDown'
import LoadingButton from '../../../components/LoadingButton'
import { InputField } from '../../../components/InputField';
import HttpUtility, {HELPERURI} from '../../../utilities/HttpUtility';
import { UpdatepracticeInfo , UpdatepracticeInfoRequest  , AddNewLocation , GetpracticeProfile , GetSubscribePriceOption, showError, UpdateNewLocationpracticeInfoRequest, AddNewLoactionRequest} from "../../../store/actions/taskAction";

var formIsValid = true
class PracticeInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSave:false,
      countryState:[],
      isError:{
        name:'',
        email:'',
        email_alt:'',
        ownership:'',
        phone:'',
        phone_alt:'',
        specializations:'',
        selectedcountry:'',
        address_line_1:'',
        address_line_2:'',
        city:'',
        selectedstate:'',
        zip:'',
      },
    };
    this.nextClick = this.nextClick.bind(this);
    this.SavePracticeInfo = this.SavePracticeInfo.bind(this);
  }

  async  componentDidMount(){
    await this.props.dispatch(GetSubscribePriceOption());
    

       if(!this.props.isNewLocation){
        // this.isValid()
       }

       if(this.props.isNewLocation){
        let practiceProfileInfo = {}
        var ownership  = Ownership.find(b => b.id === 1)
        this.onEditPracticeInfo( 'ownership' , ownership)

        practiceProfileInfo['selectedcountry'] = this.props.oldpracticeProfileInfo.country

        await  this.props.dispatch(UpdateNewLocationpracticeInfoRequest(practiceProfileInfo))
        await  this.getState(this.props.oldpracticeProfileInfo && this.props.oldpracticeProfileInfo.country && this.props.oldpracticeProfileInfo.country.id);
       }
      
  }

  nextClick(){
    this.props.nextStep();
  }


 async onEditPracticeInfo(field , value){

    if(this.props.isNewLocation){
      let practiceProfileInfo = {}
      if(field === "selectedcountry"){
        practiceProfileInfo[field] = value
        await this.getState(value.id);
      }else{
        practiceProfileInfo[field] = value
      }
      await this.props.dispatch(UpdateNewLocationpracticeInfoRequest(practiceProfileInfo))

     }else{
      let practiceProfileInfo = this.props.practiceProfileInfo

        if(field === "selectedcountry"){
          practiceProfileInfo[field] = value
          await  this.getState(value.id);
        }else{
          practiceProfileInfo[field] = value
        }
        await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))
     }
    
     this.setState({isError:{}})
    // this.isValid(field , value)
  }

  async getState(countryId){
    await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
            var countryState = response.data;
            this.setState({countryState})
            this.onEditPracticeInfo( 'countryState' , countryState)
            if(this.props.oldpracticeProfileInfo.state_id){
              var selectedstate = countryState.find(b => b.id === this.props.oldpracticeProfileInfo.state_id)
            }
            this.onEditPracticeInfo( 'selectedstate' , selectedstate)
            // this.onEditPracticeInfo( 'selectedstate' , null)
        }
    });
  }

  isValid(field , value){
    let isError = this.state.isError;
    let emailValid = this.state.emailValid;
    let cemail
    switch (field) {
      case "name":
        isError.name =
          value.length < 4 ? "Atleast 4 characaters required" : "";
        break;
      case "ownership":
        isError.ownership =
        value && value  ? "" : "required";
        break;
      case "phone":
        isError.phone =
          value.length < 7 ? "required" : "";
        break;
      case "phone_alt":
        isError.phone_alt =
          value.length < 7 ? "required" : "";
        break;
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        isError.email = emailValid ? "" : "Wrong format";
        break;
      case "email_alt":
        cemail = value == this.props.practiceProfileInfo.email
        isError.email_alt = cemail ? "" : "Email does not match";
        break;
      case "specializations":
        isError.specializations =
        value && value.length > 0 ? "" : "required";
       
        break;
      case "selectedcountry":
        console.log(value,'asdasdasdasasdasd')
        isError.selectedcountry =
        value.id ? "" : "required";
        break;
      case "address_line_1":
        isError.address_line_1 =
        value == '' ? "required" : "";
        break;
      case "address_line_2":
        isError.address_line_2 =
        value == '' ? "required" : "";
        break;
      case "city":
        isError.city =
        value == '' ? "required" : "";
        break;
      case "selectedstate":
        console.log(value,'value')
        isError.selectedstate =
        value.id ? "" : "required";
        break;
      case "zip":
        isError.zip =
        value == '' ? "required" : "";
        break;
      default:
        break; 
    }
    this.setState({ isError },this.validateForm)
    console.log(isError,'isError')

    const {name , email , email_alt , ownership , phone , phone_alt ,specializations , selectedcountry , address_line_1 , address_line_2 , city , selectedstate , zip} = this.state.isError
    if (name === "" && email === '' && ownership === "" && phone=== "" && phone_alt === '' && specializations === "" && selectedcountry === ''
        && address_line_1 === "" && address_line_2 === '' && city === '' && selectedstate === '' && zip === '' && email_alt === ''  && this.props.practiceProfileInfo.phone_alt && this.props.practiceProfileInfo.phone_alt.length > 7) {
          
        this.setState({
          isSave: true
        });

      }else{
        this.setState({
          isSave: false
        });
      }

  }

  validateForm() {
    let isError = this.state.isError;

        if (this.props.practiceProfileInfo.email_alt == this.props.practiceProfileInfo.email) {
          formIsValid = true
          isError.email_alt = ""
        } else {
          isError.email_alt = "Email does not match"
          formIsValid = false
        }

    
    this.setState({ isSave: formIsValid });

  }

 async SavePracticeInfo(){
   
      if(this.isValidate()) return;

      const {practiceProfileInfo} = this.props
      var specializations = []
      if(practiceProfileInfo?.specializations){
        practiceProfileInfo?.specializations.map((ids)=>
          specializations.push(ids.id)
        );
      }

      if(this.props.isNewLocation){
        console.log('new')
        if(!this.isValidate()){
          this.props.nextStep();
        }
       
      }else{
        
      this.onEditPracticeInfo('isLoading' , true)
      this.onEditPracticeInfo('isCompleted' , false)

        let params={
          id:practiceProfileInfo.id,
          name:practiceProfileInfo.name,
          ownership:practiceProfileInfo.ownership.id,
          phone:practiceProfileInfo.phone.replace(/\s+/g, ''),
          phone_alt: practiceProfileInfo?.phone_alt?.length > 5 ? practiceProfileInfo.phone_alt.replace(/\s+/g, '') : '',
          email:practiceProfileInfo.email,
          country_id:practiceProfileInfo.selectedcountry.id,
          specializations:specializations,
    
          address_line_1:practiceProfileInfo.address_line_1,
          address_line_2:practiceProfileInfo.address_line_2,
          city:practiceProfileInfo.city,
          state_id:practiceProfileInfo.selectedstate.id,
          zip:practiceProfileInfo.zip,
          affiliatename_licence_code:practiceProfileInfo.affiliatename_licence_code || '',
          affiliate_name:practiceProfileInfo.affiliate_name || ''
          
        }

        await  this.props.dispatch(UpdatepracticeInfo(params));

        if(this.props.practiceProfileInfo.isCompleted === true && !this.isValidate()){
          this.nextClick()
        }
      }
   
  }

  isValidate(){
    var error =false;
    var isError = {};
    const {practiceProfileInfo } = this.props


    if(isRequired(practiceProfileInfo.name)){
      error = true;
      isError.name = ["The name field is required."];
    }

    if(isRequired(practiceProfileInfo.ownership)){
      error = true;
      isError.ownership = ["required."];
    }

    if(isRequired(practiceProfileInfo.selectedcountry)){
      error = true;
      isError.selectedcountry= ["The country  field is required."];
    }
    
    if(isRequired(practiceProfileInfo.selectedstate)){
      error = true;
      isError.selectedstate = ["required."];
    }

    if(isRequired(practiceProfileInfo.selectedstate)){
      error = true;
      isError.selectedstate = ["The state  field is required."];
    }

    if(isRequired(practiceProfileInfo.zip)){
      error = true;
      isError.zip = ["required."];
    }

    if(isRequired(practiceProfileInfo.phone)){
      error = true;
      isError.phone = ["required."];
    }

    if(practiceProfileInfo.phone && practiceProfileInfo.phone.length < 10){
      error = true;
      isError.phone = ["Invaild phone no."];
    }


    if(isRequired(practiceProfileInfo.email)){
      error = true;
      isError.email = ["required."];
    }else if(isValidEmail(practiceProfileInfo.email)){
      error = true;
      isError.email = ["Invaild email address"];
    }else{
      
    }

    if(isRequired(practiceProfileInfo.email_alt)){
      error = true;
      isError.email_alt = ["required."];
    }else if(isValidEmail(practiceProfileInfo.email_alt)){
      error = true;
      isError.email_alt = ["Invaild email address"];
    }else if(practiceProfileInfo.email !== practiceProfileInfo.email_alt){
      error = true;
      isError.email_alt = ["Email does not match"];
    }else{
     
    }

    
    if(practiceProfileInfo.specializations && practiceProfileInfo.specializations.length > 0){
    
    }else{
      error = true;
      isError.specializations = ["required."];
    }

    if(isRequired(practiceProfileInfo.address_line_1)){
      error = true;
      isError.address_line_1 = ["required."];
    }

    if(isRequired(practiceProfileInfo.city)){
      error = true;
      isError.city = ["required."];
    }


    console.log(isError,'isError')

    this.setState({ isError})
    return error
  }

  render (){
    const {t , country , specializations  ,practiceProfileInfo } = this.props

    return(
        <div className={`${(!this.props?.isUpgradePlan) ? 'mw-1270' : 'mw-849' } transparent__form--white d-flex flex-column align-items-stretch mb-auto practice_info`}>
            <Form className="mt-2 mb-20 inner_white_box flex-grow-1 d-flex flex-column">
              <Row>
                <Col  className="">
                  <FormGroup className="mb-5">
                    <h4 className="m-0">{t('title.practiceInformation')}</h4>
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" isError={this.state.isError.name} className={`${this.state.isError.name ? 'invalid-field' : ''}`} label={t('form.practiceName')} name="name" value={practiceProfileInfo.name} onChange={(e)=>this.onEditPracticeInfo( 'name' , e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Ownership">{t('form.ownership')}</Label>
                      {this.state.isError.ownership && <span className='text--danger'>{this.state.isError.ownership}</span>}
                    </div>
                    <DropDown 
                        className={`${this.state.isError.ownership ? 'invalid-field' : ''} react-select-container`}
                        classNamePrefix="react-select"
                        options={Ownership}
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        onChange={(val)=> this.onEditPracticeInfo('ownership', val) }
                        isSearchable={false}
                        placeholder={''}
                        isDisabled={this.props.isNewLocation}
                        value={practiceProfileInfo.ownership }
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Practicephone">{t('form.practicePhone')}</Label>
                      {this.state.isError.phone && <span className='text--danger'>{this.state.isError.phone}</span>}
                    </div>
                    <PhoneInput defaultMask={'-...........'}  alwaysDefaultMask={true} countryCodeEditable={false} value={practiceProfileInfo.phone}  inputProps={{ name: 'phone',required: true, className: this.state.isError.phone ? 'invalid-field  form-control' : ' form-control'}}  country={(this.props.oldpracticeProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => { this.onEditPracticeInfo('phone' , formattedValue)}}  />
                    </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Secondarypracticephone">{t('form.SecondaryPracticePhone')}</Label>
                    </div>
                    <PhoneInput defaultMask={'-...........'}  alwaysDefaultMask={true} countryCodeEditable={false}  value={practiceProfileInfo.phone_alt}  inputProps={{ name: 'phone_alt' }}  country={(this.props.oldpracticeProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => {  this.onEditPracticeInfo('phone_alt' , formattedValue)}}  />
                    </FormGroup>
                  <FormGroup>
                    <InputField type="text" isError={this.state.isError.email} className={`${this.state.isError.email ? 'invalid-field' : ''}`} label={t('form.practiceEmail')} name="Practiceemail" value={practiceProfileInfo.email}  onChange={(e)=>this.onEditPracticeInfo( 'email' , e.target.value)}  />
                  </FormGroup>
                  <FormGroup>
                    <InputField type="text" isError={this.state.isError.email_alt} className={`${this.state.isError.email_alt ? 'invalid-field' : ''}`} label={`Confirm practice email`} name="Confirmpracticeemail" value={practiceProfileInfo.email_alt}  onChange={(e)=>this.onEditPracticeInfo( 'email_alt' , e.target.value)}  />
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="Specialization">{t('form.specialization')}</Label>
                      {this.state.isError.specializations && <span className='text--danger'>{this.state.isError.specializations}</span>}
                    </div>
                    <DropDown 
                        className={`${this.state.isError.specializations ? 'invalid-field' : ''}  react-select-container react-select__multi`}
                        classNamePrefix="react-select"
                        options={specializations}
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        onChange={(val)=> this.onEditPracticeInfo('specializations', val) }
                        isMulti={true}
                        isSearchable={true}
                        placeholder={''}
                        value={practiceProfileInfo.specializations}
                    />
                  </FormGroup>
                </Col>
                <Col  className="">
                  <FormGroup className="mb-5">
                    <h4 className="m-0">{t('title.address')}</h4>
                  </FormGroup>
                    <FormGroup>
                        <InputField type="text" label={t('form.streetandnumber')} className={`${this.state.isError.address_line_1 ? 'invalid-field' : ''}`} isError={this.state.isError.address_line_1} name="Streetandnumber" value={practiceProfileInfo.address_line_1}  onChange={(e) => this.onEditPracticeInfo('address_line_1', e.target.value)}  />
                    </FormGroup>
                    <FormGroup>
                      <InputField type="text" label={t('form.apartment')} name="Apartment" value={practiceProfileInfo.address_line_2} onChange={(e) => this.onEditPracticeInfo('address_line_2', e.target.value)}  />
                    </FormGroup>
                  <FormGroup>
                    <div className="d-flex justify-content-between">
                      <Label for="country">{t('form.country')}</Label>
                      {this.state.isError.selectedcountry && <span className='text--danger'>{this.state.isError.selectedcountry}</span>}
                    </div>
                    <DropDown 
                        className={`${this.state.isError.selectedcountry ? 'invalid-field' : ''} react-select-container`}
                        classNamePrefix="react-select"
                        options={country}
                        getOptionLabel={ x => `${x.name}`}
                        getOptionValue={ x => `${x.id} ${x.name}`}
                        onChange={(val)=> this.onEditPracticeInfo('selectedcountry', val) }
                        isSearchable={true}
                        placeholder={''}
                        value={practiceProfileInfo.selectedcountry}
                        isDisabled={true}
                    />
                  </FormGroup>
                  <FormGroup>
                      <div className="d-flex justify-content-between">
                        <Label for="state">{t('form.state')}</Label>
                        {this.state.isError.selectedstate && <span className='text--danger'>{this.state.isError.selectedstate}</span>}
                      </div>
                      <DropDown 
                          className={`${this.state.isError.selectedstate ? 'invalid-field' : ''} react-select-container react-select__multi`}
                          classNamePrefix="react-select"
                          options={practiceProfileInfo.countryState}
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.name}`}
                          onChange={(val)=> this.onEditPracticeInfo('selectedstate', val) }
                          isSearchable={true}
                          placeholder={''}
                          value={practiceProfileInfo.selectedstate}
                          isDisabled={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <InputField type="text" label={t('form.city')} className={`${this.state.isError.city ? 'invalid-field' : ''}`} isError={this.state.isError.city} name="city" value={practiceProfileInfo.city}   onChange={(e) => this.onEditPracticeInfo('city', e.target.value)} />
                    </FormGroup>
                    
                    <FormGroup>
                      <InputField type="text" label={t('form.postalcode')} className={`${this.state.isError.zip ? 'invalid-field' : ''}`} isError={this.state.isError.zip} name="Postalcode" value={practiceProfileInfo.zip}  onChange={(e) => this.onEditPracticeInfo('zip', e.target.value)}  />
                    </FormGroup>
                </Col>
                {(!this.props?.isUpgradePlan)  &&
                  <Col  className="pl-5">
                      <>
                        <FormGroup className="mb-5">
                          <h4 className="m-0">Affiliate</h4>
                        </FormGroup>
                        <FormGroup>
                          <InputField type="text" label="Affiliate's name" name="name"   onChange={(e) => this.onEditPracticeInfo('affiliate_name', e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                          <InputField type="text" label="Affiliate's licence code" name="name"   onChange={(e) => this.onEditPracticeInfo('affiliatename_licence_code', e.target.value)} />
                        </FormGroup>
                        <p style={{fontSize:'12px'}}><span style={{color:'#FF4D54'}}>*</span>The above information is only required if your practice is associated with an affiliate.</p>
                      </>
                  </Col>
                }
              </Row>
            </Form>
            <div className="mt-auto practice-footer d-flex justify-content-center">
              <LoadingButton type="button" size="md" label={t('btn.next')} loading={practiceProfileInfo.isLoading} disabled={ practiceProfileInfo.isLoading} onClick={() => this.SavePracticeInfo()} className={`mt-5 mr-3`} color="primary"/>
            </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state , ownProps) => ({
  country: state.task.country,
  specializations: state.task.specializations,
  practiceInfo:state.task.practiceInfo,
  newPracticeProfileInfo:state.task.newPracticeProfileInfo,
  practiceProfileInfo: ownProps.isNewLocation ? state.task.newPracticeProfileInfo :state.task.practiceProfileInfo,
  error: state.task.error,
  oldpracticeProfileInfo: state.task.practiceProfileInfo,
  isCorporate:state.task.isCorporate,
  isUpgradePlan:state.task.isUpgradePlan
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(PracticeInfo);
