import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import VerifyIdentityBox from '../../components/VerifyIdentityBox'

import './style.scss';

class WelcomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  

  async componentDidMount(){
  }

  render (){
      const { error} = this.props

    return(
      <div className="view_account_profile__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Profiles"/>
          <VerifyIdentityBox pageUrl={`/active-profiles`}/>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    loading: state.task.loading,
    error: state.task.error,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(WelcomeIndex);
