import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import ChannelList from '../components/ChannelList'
import Loading from '../../../components/Loading';
import Header from '../Header'
import Sidebar, { TabPanel } from '../components/Sidebar';
import { tabList, tabItems } from './staticFile';
import { useLocation } from "react-router-dom"

const WaitingRoomChannels = (props) => {
    const dispatch = useDispatch();
    const states = useSelector((state) => state.task);
    const [myList, setMyList] = useState([]);
    const [recentPlay, setRecentPlay] = useState([]);
    const [videosList, setvideosList] = useState(states.chairSideMediaState);
    const [value, setValue] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [isActiveSidebar, setActiveSidebar] = useState(false);
    const sampleLocation = useLocation();
  
      const toggle = () => setIsOpen(!isOpen);
    const onHover = (value) =>{
		setActiveSidebar(value)
	}

    useEffect(() => {
        const videos = [];
        if (states?.chairSideMediaState.length && props.location.state.id > 0 && props.location.state.id!== undefined)
        {
            states.chairSideMediaState.map(video => {
                if (video.category.id === props.location.state.id)
                    {
                        videos.push(video);
                    }
            });
        }
        setvideosList(videos);

    }, [props.location.state.id ]);

    useEffect(() => {

     
      
    }, []);

  return (
    <div className={`${styles.goolge_tv_waiting_room__main__screen} d-flex flex-column h-100`}>
        <Header isOperatoryRoom={true}/>  
        <div className={`${isActiveSidebar ? 'activeNav' : '' } navGoogleTvSidebar `}>
          <div className="d-flex flex-column navSidebar__inner h-100  pt-5" onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)}>
            <h2 className={`${isActiveSidebar ? 'visible' : 'invisible' } mb-5 mt-5 pt-5 pl-5 pl-5`}>{'Chairside Education'}</h2>
            <div className="navSidebar__tabItem" >
                <Sidebar
                  value={states.googleTvTestDriveChairsideMenuItem}
                  setValue={setValue}
                  tabList={tabList}
                  onClick={toggle}
                  isChairside={true}
                />
            </div>
          </div>
        </div>
      <div className={`${states.googleTvTestDriveChairsideMenuItem == 1 ? 'home' : ''} tabcontent` }>
            <div role="tabpanel" >
                    <ChannelList title={props.location.state.title} isCat={true}  isChairside={true} noSlider={true} channelsData={videosList}/>
            </div>
      </div>
    </div>
    // <>    
    //     {state.loading ? <div className={`goolge_tv__loader`}><Loading /></div> :
    //     <div className={`${styles.goolge_tv_waiting_room__main__screen} d-flex flex-column h-100`}>
    //         <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
    //             <ChannelList title={props.location.state.title} isCat={true}  isChairside={true} noSlider={true} channelsData={videosList}/>
    //         </div>
    //     </div>
    //     }
    // </>
  )
}

export default WaitingRoomChannels
