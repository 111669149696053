import React, { useState, useEffect } from "react";
import TimePicker from "./TimePicker/TimePicker";
import SubHeader from '../../components/Header/subHeader';
import DropDown from '../../components/DropDown'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, Label, FormGroup, Form, Col, Row, } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { postWaitingRoomSchedule, getWaitingRoomSchedule, getOperatoryRoomSchedule, postOperatoryRoomSchedule } from "../../store/actions/taskAction";
import { SC } from "../../helper/ServerCall";
// import { getWaitingRoomSchedule } from "../../helper/endPoint";
import moment from "moment";
import { initialArray, formatApiResponse } from "./responseFormatter";
import { Fragment } from "react";
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { getMinutes } from '../../data/comman';
import LoadingButton from '../../components/LoadingButton'

import "./styles.scss";

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#6087fd',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#6087fd',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const hours = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
]
const PracticeOpenClosed = [
    { value: 0, label: 'Closed' },
    { value: 1, label: 'Open' },
]
const CLOSESTATUS = 0;

const PracticeAmPm = [
    { value: 0, label: 'AM' },
    { value: 1, label: 'PM' },
]

const Days = ["Monday", "Tuesday", "Wed.", "Thursday", "Friday", "Saturday", "Sunday"];

const WaitingRoomSchedule = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const [isOperatory, setIsOperatory] = useState(props.location.state.isOperatory || false);

    const history = useHistory();
    const [time, setTimer] = useState({
        day_week: 0,
        from_working_hour_type: "",
        from_working_hour: "00",
        from_working_hour_minute: "00",
        from_working_hour_type: "00",
        to_working_hour: "00",
        to_working_hour_minute: "00",
        to_working_hour_type: "",
        working_hours_status: true,

        from_after_hour_type: "",
        from_after_hour: "00",
        from_after_hour_minute: "00",
        from_after_hour_type: "",
        to_after_hour: "00",
        to_after_hour_minute: "00",
        to_after_hour_type: "",
        after_hours_status: true,
    });
    const [schedules, setSchedule] = useState([]);
    const [weekname, setWeekName] = useState("");
    const [Data, setData] = useState([]);
    const [minutes, setMinutes] = useState([]);
    const [weeks, setWeek] = useState(initialArray);
    const [error, setError] = useState({});

    const [selectedWorkingHoursFrom, setSelectedWorkingHoursFrom] = useState(null);
    const [selectedWorkingMinuteFrom, setSelectedWorkingMinuteFrom] = useState(null);
    const [selectedAmPm, setAmPm] = useState(null);

    const [selectedWorkingHoursTo, setSelectedWorkingHoursTo] = useState(null);
    const [selectedWorkingMinuteTo, setSelectedWorkingMinuteTo] = useState(null);
    const [selectedAmPmTo, setAmPmTo] = useState(null);

    const [selectedAfterHoursFrom, setSelectedAfterHoursFrom] = useState(null);
    const [selectedAfterMinuteFrom, setSelectedAfterMinuteFrom] = useState(null);
    const [selectedAmPmAfter, setAmPmAfter] = useState(null);

    const [selectedAfterHoursTo, setSelectedAfterHoursTo] = useState(null);
    const [selectedAfterMinuteTo, setSelectedAfterMinuteTo] = useState(null);
    const [selectedAmPmAfterTo, setAmPmAfterTo] = useState(null);

    const [workingStatus, setWorkingStatus] = useState(0);

    const [states, setState] = useState({
        checkCli: false,
        AftercheckCli: false,
    });

    useEffect(() => {
        if (isOperatory) {
            dispatch(getOperatoryRoomSchedule());
        } else {
            dispatch(getWaitingRoomSchedule());
        }
    }, []);

    // useEffect(() => {
    //     var arr_schedule = [];
    //     schedules.map((item ,index) => {
    //         console.log(item,'itemitemitemitemitemitemitem')

    //         setSelectedWorkingHoursFrom(getHours(item.working_hours_from[index]));
    //         setSelectedWorkingMinuteFrom(getMinute(item.working_hours_from[index]))
    //         setAmPm(getAMPM(item.working_hours_from[index]))

    //         setSelectedWorkingHoursTo(getHours(item.working_hours_to[index]));
    //         setSelectedWorkingMinuteTo(getMinute(item.working_hours_to[index]))
    //         setAmPmTo(getAMPM(item.working_hours_to[index]))

    //         setSelectedAfterHoursFrom(getHours(item.after_hours_from[index]));
    //         setSelectedAfterMinuteFrom(getMinute(item.after_hours_from[index]))
    //         setAmPmAfter(getAMPM(item.after_hours_from[index]))

    //         setSelectedAfterHoursTo(getHours(item.after_hours_to[index]));
    //         setSelectedAfterMinuteTo(getMinute(item.after_hours_to[index]))
    //         setAmPmAfterTo(getAMPM(item.after_hours_to[index]))
    //     });


    // }, [schedules]);

    useEffect(() => {
        if (state.waitingRoomSchedule) {
            setSchedule(state.waitingRoomSchedule.schedule)
        }
    }, [state.waitingRoomSchedule]);


    // Week Status
    const [weekstatus, setWeekStatus] = useState(true);

    // React.useEffect(() => {
    //     if (weekname !== "") {
    //         handleSchedule(weekname);
    //     }
    // }, [time]);

    const getStatus = (status) => {
        if (status === 0) {
            setWorkingStatus(false)
        } else {
            setWorkingStatus(true)
        }

    }

    const handleChange = (field, option, item, index) => {
        var schedule = schedules[index];
        var time;
        var working_hours_from;
        var working_hours_to;
        var hour;
        // console.log(schedule,'scheduleschedule')
        switch (field) {
            case "wswitch":
                item[option.target.name] = option.target.checked;
                setState({ ...state, [option.target.name]: option.target.checked });
                schedule.working_hours_status = option.target.checked ? 1 : 0;
                break;
            case "afswitch":
                item[option.target.name] = option.target.checked;
                setState({ ...state, [option.target.name]: option.target.checked });
                schedule.after_hours_status = option.target.checked ? 1 : 0;
                break;
            case "wHour":
                time = getHMA(item.working_hours_from)
                working_hours_from = moment(`${option.value}:${time[1]} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.working_hours_from = working_hours_from;
                setSelectedWorkingHoursFrom(getHours(working_hours_from));
                break;
            case "wMinute":
                time = getHMA(item.working_hours_from);
                working_hours_from = moment(`${time[0]}:${option.value} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.working_hours_from = working_hours_from;
                setSelectedWorkingMinuteFrom(getMinute(working_hours_from))
                break;
            case "wA":
                time = getHMA(item.working_hours_from);
                hour = time[0]
                if (option.label === "AM" && hour > 12) {
                    hour = hour - 12
                }
                working_hours_from = moment(`${hour}:${time[1]} ${option.label}`, "hh:mm A").format("HH:mm");
                schedule.working_hours_from = working_hours_from;
                setAmPm(getAMPM(working_hours_from))
                break;
            case "weHour":
                time = getHMA(item.working_hours_to)
                working_hours_to = moment(`${option.value}:${time[1]} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.working_hours_to = working_hours_to;
                setSelectedWorkingHoursTo(getHours(working_hours_to))
                break;
            case "weMinute":
                time = getHMA(item.working_hours_to);
                working_hours_to = moment(`${time[0]}:${option.value} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.working_hours_to = working_hours_to;
                setSelectedWorkingMinuteTo(getMinute(working_hours_to))
                break;
            case "weA":
                time = getHMA(item.working_hours_to);
                hour = time[0]
                if (option.label === "AM" && hour > 12) {
                    hour = hour - 12
                }
                working_hours_to = moment(`${hour}:${time[1]} ${option.label}`, "hh:mm A").format("HH:mm");
                schedule.working_hours_to = working_hours_to;
                setAmPmTo(getAMPM(working_hours_to))
                break;
            default:
                break;
        }

        // var timeFormat = "HH:mm";
        // var error = {};
        // schedules.map((sc, index)=>{
        //     console.log(sc,'sc')
        //     if(!moment(sc.working_hours_from, timeFormat).isAfter(moment(sc.working_hours_to, timeFormat))){
        //         error[index] = "Invalid time selected";
        //     }
        // });

        // setError(error)

        const newArr = [...schedules];
        newArr[index] = schedule;
        setSchedule(newArr);

    }

    const aFhourshandleChange = (field, option, item, index) => {
        var schedule = schedules[index];
        var time;
        var after_hours_from;
        var after_hours_to;
        var hour;
        // console.log(schedule,'scheduleschedule')
        switch (field) {
            case "afswitch":
                item[option.target.name] = option.target.checked;
                // setState({ ...state, [option.target.name]: option.target.checked });
                schedule.working_hours_status = option.target.checked ? 1 : 0;
                break;
            case "afHour":
                time = getHMA(item.after_hours_from)
                after_hours_from = moment(`${option.value}:${time[1]} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.after_hours_from = after_hours_from;
                setSelectedAfterHoursFrom(getHours(after_hours_from));
                break;
            case "afMinute":
                time = getHMA(item.after_hours_from);
                after_hours_from = moment(`${time[0]}:${option.value} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.after_hours_from = after_hours_from;
                setSelectedAfterMinuteFrom(getMinute(after_hours_from));
                break;
            case "afA":
                time = getHMA(item.after_hours_from);
                hour = time[0]
                if (option.label === "AM" && hour > 12) {
                    hour = hour - 12
                }
                after_hours_from = moment(`${hour}:${time[1]} ${option.label}`, "hh:mm A").format("HH:mm");
                schedule.after_hours_from = after_hours_from;
                setAmPmAfter(getAMPM(after_hours_from));
                break;
            case "afeHour":
                time = getHMA(item.after_hours_to)
                after_hours_to = moment(`${option.value}:${time[1]} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.after_hours_to = after_hours_to;
                setSelectedAfterHoursTo(getHours(after_hours_to))
                break;
            case "afeMinute":
                time = getHMA(item.after_hours_to);
                after_hours_to = moment(`${time[0]}:${option.value} ${time[2]}`, "hh:mm A").format("HH:mm");
                schedule.after_hours_to = after_hours_to;
                setSelectedAfterMinuteTo(getMinute(after_hours_to))
                break;
            case "afeA":
                time = getHMA(item.after_hours_to);
                hour = time[0]
                if (option.label === "AM" && hour > 12) {
                    hour = hour - 12
                }
                after_hours_to = moment(`${hour}:${time[1]} ${option.label}`, "hh:mm A").format("HH:mm");
                schedule.after_hours_to = after_hours_to;
                setAmPmAfterTo(getAMPM(after_hours_to))

                break;
            default:
                break;
        }

        const newArr = [...schedules];
        newArr[index] = schedule;
        setSchedule(newArr);

    }

    // const handleChange = (type, day_week, event) => {
    //     setWeek(
    //         weeks.map((item) => {
    //             if (item.day_week === day_week) {
    //                 if (type === "switch") {
    //                     item[event.target.name] = event.target.checked;
    //                 } else if (type === "working") {
    //                     item.working[event.target.name] = event.target.value;
    //                 } else if (type === "after") {
    //                     item.after[event.target.name] = event.target.value;
    //                 }
    //             }
    //             return item;
    //         })
    //     );
    // };

    const handleAfterChange = (day_week, event) => {
        weeks.find((element) => {
            if (element.id === event.target.id) {
                element.AftercheckCli = !element.AftercheckCli;
            }
        });
        setWeekName(day_week);
        setTimer({
            ...time,
            [event.target.name]: event.target.checked,
            day_week: day_week,
        });
    };
    const TimeChangeHandler = (day_week, event) => {
        console.log(event.target.value);
        setWeekName(day_week);
        event.preventDefault();
        // setWeek(formatApiResponse(arr));
        setTimer({
            ...time,
            [event.target.name]: event.target.value,
            day_week: day_week,
        });
    };

    // const handleSchedule = (weekname) => {
    //     return setSchedule({ ...schedule, [weekname]: time });
    // };
    const handleSubmitSchedule = (event) => {
        console.log("call");
        event.preventDefault();
        var arr_schedule = [];
        schedules.map((item, index) => {
            arr_schedule.push({
                day_week: item.day_week,
                after_hours_to: item.after_hours_to,
                after_hours_from: item.after_hours_from,
                working_hours_to: item.working_hours_to,
                working_hours_from: item.working_hours_from,
                after_hours_status: item.after_hours_status,
                working_hours_status: item.working_hours_status,
            });
        });

        let data = {
            channel_id: props.location.state.channel_id,
            media_type: 1,
            schedule: arr_schedule,
        };
        console.log(data);
        if (isOperatory) {
            dispatch(postOperatoryRoomSchedule(data));
        } else {
            dispatch(postWaitingRoomSchedule(data));
        }
        // history.push('/add-new-operatory-channel')
    };

    // useEffect(() => {
    //     getData();
    // }, []);
    // //get schedule data
    // const getData = () => {
    //     SC.getWaitingRoomScheduleCall(getWaitingRoomSchedule).then((res) => {
    //         if (res.status === 200 && res.data) {
    //             let { schedule } = res.data?.data;
    //             setWeek(formatApiResponse(schedule));
    //             setData(schedule);
    //         }
    //     });
    // };



    const getHours = (time) => {
        var HourArray = getHMA(time);
        return hours.find(x => x.value === HourArray[0]);
    }

    const getHMA = (time) => {
        var hour = moment(time, "HH:mm").format('hh');
        var minute = moment(time, "HH:mm").format('mm');
        var ampm = moment(time, "HH:mm").format('A');
        return [hour, minute, ampm];
    }

    const getMinute = (time) => {
        var MinuteArray = getHMA(time);
        return getMinutes().find(x => x.label === MinuteArray[1]);
    }

    const getAMPM = (time) => {
        var AmPmArray = getHMA(time);
        return PracticeAmPm.find(x => x.label === AmPmArray[2]);
    }

    return (
        <div className='waiting_room_channel__main__screen d-flex flex-column h-100 align-items-center'>
            <SubHeader className='' title={isOperatory ? 'Operatory Room Channels' : 'Waiting Room Channels'} />
            <div className="d-flex flex-column waiting_room_channel__schedule__screen  mt-5  w-100">
                <div className="transparent__form--white">
                    <Form className="h-100 d-flex flex-column">
                        <Row>
                            <Col md={6}>
                                <FormGroup className="mb-4 form-heading">
                                    <h4 className="m-0 mb-3 mt-1">Schedule</h4>
                                    <p>Select the time periods in which you’d like to have this channel displayed. Only one channel can have a programmed schedule, and adding a new schedule will replace any other that is currently in use.
                                    </p>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="custom-scroll">
                            <Row>
                                {console.log(schedules, 'schedules')}
                                <Col md={6} className="">
                                    {schedules && schedules.slice(0, 5).map((items, index) => {
                                        return <div key={index} className="mb-5">
                                            <div className="practice__hours_section d-flex">
                                                <div className="border--right pr-20"> 
                                                    <div className="">
                                                        <div className="d-flex justify-content-between working_hours">
                                                            <Label className="m-0" for="">{Days[index]}</Label>
                                                            <div className="" style={{marginRight:"-12px"}}>
                                                                <Label className="m-0" for="">Working hours</Label>
                                                                <FormControlLabel className="m-0 custom-radio-btn" control={<IOSSwitch checked={items.working_hours_status == 0 ? false : true} onChange={(e) => { handleChange("wswitch", e, items, index) }} name="checkCli" />} />
                                                            </div>
                                                        </div>
                                                        <div className={`d-flex align-items-end pb-1 mt-2 from_start `}>
                                                            <FormGroup className={`align-items-center m-0 px-3 `}>
                                                                <Label className="p-0 mb-3 " >From</Label>
                                                            </FormGroup>
                                                            <div className={`w-auto practice__hours_box`}>
                                                                {error && error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{error[index]}</div>}
                                                                <div className="d-flex">
                                                                    <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                        <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                        <DropDown
                                                                            className='react-select-container select-style-3 w-101 p-0'
                                                                            classNamePrefix="react-select"
                                                                            // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                            options={hours}
                                                                            isSearchable={false}
                                                                            placeholder={''}
                                                                            value={getHours(items.working_hours_from)}
                                                                            onChange={(option) => handleChange("wHour", option, items, index)}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                        <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                        <DropDown
                                                                            className='react-select-container w-101 p-0'
                                                                            classNamePrefix="react-select"
                                                                            // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                            options={getMinutes()}
                                                                            isSearchable={false}
                                                                            placeholder={''}
                                                                            value={getMinute(items.working_hours_from)}
                                                                            onChange={(option) => handleChange("wMinute", option, items, index)}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                        <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                        <DropDown
                                                                            className='react-select-container w-101  p-0'
                                                                            classNamePrefix="react-select"
                                                                            // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                            options={PracticeAmPm}
                                                                            isSearchable={false}
                                                                            placeholder={''}
                                                                            // value={selectedAmPm}
                                                                            value={getAMPM(items.working_hours_from)}
                                                                            onChange={(option) => handleChange("wA", option, items, index)}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={`d-flex align-items-end pb-1 mt-2 to_end`}>
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                <Label style={{ width: '41.84px' }} className="p-0 mb-3 " >To</Label>
                                                            </FormGroup>
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                <DropDown
                                                                    className='react-select-container w-101  p-0'
                                                                    classNamePrefix="react-select"
                                                                    // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                    options={hours}
                                                                    isSearchable={false}
                                                                    placeholder={''}
                                                                    value={getHours(items.working_hours_to)}
                                                                    onChange={(option) => handleChange("weHour", option, items, index)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                <DropDown
                                                                    className='react-select-container w-101  p-0'
                                                                    classNamePrefix="react-select"
                                                                    // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                    options={getMinutes()}
                                                                    isSearchable={false}
                                                                    placeholder={''}
                                                                    value={getMinute(items.working_hours_to)}
                                                                    onChange={(option) => handleChange("weMinute", option, items, index)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                <DropDown
                                                                    className='react-select-container w-101 w-100 p-0'
                                                                    classNamePrefix="react-select"
                                                                    // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                    options={PracticeAmPm}
                                                                    isSearchable={false}
                                                                    placeholder={''}
                                                                    value={getAMPM(items.working_hours_to)}
                                                                    onChange={(option) => handleChange("weA", option, items, index)}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pl-5">
                                                    <div className="">
                                                        <div className="d-flex justify-content-end ">
                                                            <div className="after--hour">
                                                                <Label className="m-0" for="">After hours</Label>
                                                                <FormControlLabel className="m-0 custom-radio-btn" control={<IOSSwitch checked={items.after_hours_status == 0 ? false : true} onChange={(e) => handleChange("afswitch", e, items, index)} name="AftercheckCli" />} />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-end pb-1 mt-2 from_start" style={{marginBottom:"14px"}}>
                                                            <FormGroup className={`align-items-center m-0 px-3 `} style={{marginLeft:"-10px"}}>
                                                                <Label className="p-0 mb-3 " style={{width:"36px"}} >From</Label>
                                                            </FormGroup>
                                                            <div className={`w-auto practice__hours_box`}>
                                                                {/* { error && error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{error[index]}</div>} */}
                                                                <div className="d-flex">
                                                                    <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                        <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                        <DropDown
                                                                            className='react-select-container select-style-3 w-101 p-0'
                                                                            classNamePrefix="react-select"
                                                                            // isDisabled={statusValue === CLOSESTATUS}
                                                                            options={hours}
                                                                            isSearchable={false}
                                                                            placeholder={''}
                                                                            value={getHours(items.after_hours_from)}
                                                                            onChange={(option) => aFhourshandleChange("afHour", option, items, index)}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                        <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                        <DropDown
                                                                            className='react-select-container w-101 p-0'
                                                                            classNamePrefix="react-select"
                                                                            // isDisabled={statusValue === CLOSESTATUS}
                                                                            options={getMinutes()}
                                                                            isSearchable={false}
                                                                            placeholder={''}
                                                                            value={getMinute(items.after_hours_from)}
                                                                            onChange={(option) => aFhourshandleChange("afMinute", option, items, index)}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                        <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                        <DropDown
                                                                            className='react-select-container w-101  p-0'
                                                                            classNamePrefix="react-select"
                                                                            // isDisabled={statusValue === CLOSESTATUS}
                                                                            options={PracticeAmPm}
                                                                            isSearchable={false}
                                                                            placeholder={''}
                                                                            value={getAMPM(items.after_hours_from)}
                                                                            onChange={(option) => aFhourshandleChange("afA", option, items, index)}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex align-items-end pb-1 mt-2 to_end">
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `} style={{marginLeft:"-10px"}}>
                                                                <Label style={{width:"36px"}} className="p-0 mb-3 " >To</Label>
                                                            </FormGroup>
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                <DropDown
                                                                    className='react-select-container w-101  p-0'
                                                                    classNamePrefix="react-select"
                                                                    // isDisabled={statusValue === CLOSESTATUS}
                                                                    options={hours}
                                                                    isSearchable={false}
                                                                    placeholder={''}
                                                                    value={getHours(items.after_hours_to)}
                                                                    onChange={(option) => aFhourshandleChange("afeHour", option, items, index)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                <DropDown
                                                                    className='react-select-container w-101  p-0'
                                                                    classNamePrefix="react-select"
                                                                    // isDisabled={statusValue === CLOSESTATUS}
                                                                    options={getMinutes()}
                                                                    isSearchable={false}
                                                                    placeholder={''}
                                                                    value={getMinute(items.after_hours_to)}
                                                                    onChange={(option) => aFhourshandleChange("afeMinute", option, items, index)}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                <DropDown
                                                                    className='react-select-container w-101 w-100 p-0'
                                                                    classNamePrefix="react-select"
                                                                    // isDisabled={statusValue === CLOSESTATUS}
                                                                    options={PracticeAmPm}
                                                                    isSearchable={false}
                                                                    placeholder={''}
                                                                    value={getAMPM(items.after_hours_to)}
                                                                    onChange={(option) => aFhourshandleChange("afeA", option, items, index)}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </Col>
                                <Col md={6} className="border--left">
                                    {schedules && schedules.length > 0 ?
                                        <>
                                            <div className="mb-5">
                                                <div className="practice__hours_section d-flex">
                                                    <div className="border--right pr-20">
                                                        <div className="">
                                                            <div className="d-flex justify-content-between working_hours">
                                                                <Label className="m-0" for="">{Days[5]}</Label>
                                                                <div className="" style={{marginRight:"-12px"}}>
                                                                    <Label className="m-0" for="">Working hours</Label>
                                                                    <FormControlLabel className="m-0 custom-radio-btn" control={<IOSSwitch checked={schedules[5].working_hours_status == 0 ? false : true} onChange={(e) => handleChange("wswitch", e, schedules[5], 5)} name="checkCli" />} />
                                                                </div>
                                                            </div>
                                                            {console.log(schedules, 'schedules')}
                                                            <div className={`d-flex align-items-end pb-1 mt-2 from_start`}>
                                                                <FormGroup className={`align-items-center m-0 px-3 `}>
                                                                    <Label className="p-0 mb-3 " >From</Label>
                                                                </FormGroup>
                                                                <div className={`w-auto practice__hours_box`}>
                                                                    {/* { error && error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{error[index]}</div>} */}
                                                                    <div className="d-flex">
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                            <DropDown
                                                                                className='react-select-container select-style-3 w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                                options={hours}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getHours(schedules[5].working_hours_from)}
                                                                                onChange={(option) => handleChange("wHour", option, schedules[5], 5)}
                                                                            />
                                                                        </FormGroup>
                                                                        {console.log(schedules, 'schedules')}
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                                options={getMinutes()}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getMinute(schedules[5].working_hours_from)}
                                                                                onChange={(option) => handleChange("wMinute", option, schedules[5], 5)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101  p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                                options={PracticeAmPm}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                // value={selectedAmPm}
                                                                                value={getAMPM(schedules[5].working_hours_from)}
                                                                                onChange={(option) => handleChange("wA", option, schedules[5], 5)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={`d-flex align-items-end pb-1 mt-2 to_end`}>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label style={{ width: '41.84px' }} className="p-0 mb-3 " >To</Label>
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                        options={hours}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getHours(schedules[5].working_hours_to)}
                                                                        onChange={(option) => handleChange("weHour", option, schedules[5], [5])}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                        options={getMinutes()}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getMinute(schedules[5].working_hours_to)}
                                                                        onChange={(option) => handleChange("weMinute", option, schedules[5], 5)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101 w-100 p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                        options={PracticeAmPm}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getAMPM(schedules[5].working_hours_to)}
                                                                        onChange={(option) => handleChange("weA", option, schedules[5], 5)}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pl-5">
                                                        <div className="">
                                                            <div className="d-flex justify-content-end ">
                                                                <div className="after_hour">
                                                                    <Label className="m-0" for="">After hours</Label>
                                                                    <FormControlLabel className="m-0 custom-radio-btn" control={<IOSSwitch checked={schedules[5].after_hours_status == 0 ? false : true} onChange={(e) => handleChange("afswitch", e, schedules[5], 5)} name="AftercheckCli" />} />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end pb-1 mt-2 from_start">
                                                                <FormGroup className={`align-items-center m-0 px-3 `}>
                                                                    <Label className="p-0 mb-3 " >From</Label>
                                                                </FormGroup>
                                                                <div className={`w-auto practice__hours_box`}>
                                                                    {/* { error && error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{error[index]}</div>} */}
                                                                    <div className="d-flex">
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                            <DropDown
                                                                                className='react-select-container select-style-3 w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={statusValue === CLOSESTATUS}
                                                                                options={hours}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getHours(schedules[5].after_hours_from)}
                                                                                onChange={(option) => aFhourshandleChange("afHour", option, schedules[5], 5)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={statusValue === CLOSESTATUS}
                                                                                options={getMinutes()}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getMinute(schedules[5].after_hours_from)}
                                                                                onChange={(option) => aFhourshandleChange("afMinute", option, schedules[5], 5)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101  p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={statusValue === CLOSESTATUS}
                                                                                options={PracticeAmPm}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getAMPM(schedules[5].after_hours_from)}
                                                                                onChange={(option) => aFhourshandleChange("afA", option, schedules[5], 5)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="d-flex align-items-end pb-1 mt-2 to_end">
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label style={{ width: '41.84px' }} className="p-0 mb-3 " >To</Label>
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={statusValue === CLOSESTATUS}
                                                                        options={hours}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getHours(schedules[5].after_hours_to)}
                                                                        onChange={(option) => aFhourshandleChange("afeHour", option, schedules[5], 5)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={statusValue === CLOSESTATUS}
                                                                        options={getMinutes()}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getMinute(schedules[5].after_hours_to)}
                                                                        onChange={(option) => aFhourshandleChange("afeMinute", option, schedules[5], 5)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101 w-100 p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={statusValue === CLOSESTATUS}
                                                                        options={PracticeAmPm}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getAMPM(schedules[5].after_hours_to)}
                                                                        onChange={(option) => aFhourshandleChange("afeA", option, schedules[5], 5)}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5">
                                                <div className="practice__hours_section d-flex">
                                                    <div className="border--right pr-20">
                                                        <div className="">
                                                            <div className="d-flex justify-content-between working_hours">
                                                                <Label className="m-0" for="">{Days[6]}</Label>
                                                                <div className="" style={{marginRight:"-12px"}}>
                                                                    <Label className="m-0" for="">Working hours</Label>
                                                                    <FormControlLabel className="m-0 custom-radio-btn" control={<IOSSwitch checked={schedules[6].working_hours_status == 0 ? false : true} onChange={(e) => handleChange("wswitch", e, schedules[6], 6)} name="checkCli" />} />
                                                                </div>
                                                            </div>
                                                            {console.log(schedules, 'schedules')}
                                                            <div className={`d-flex align-items-end pb-1 mt-2 from_start`}>
                                                                <FormGroup className={`align-items-center m-0 px-3 `}>
                                                                    <Label className="p-0 mb-3 " >From</Label>
                                                                </FormGroup>
                                                                <div className={`w-auto practice__hours_box`}>
                                                                    {/* { error && error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{error[index]}</div>} */}
                                                                    <div className="d-flex">
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                            <DropDown
                                                                                className='react-select-container select-style-3 w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                                options={hours}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getHours(schedules[6].working_hours_from)}
                                                                                onChange={(option) => handleChange("wHour", option, schedules[6], 6)}
                                                                            />
                                                                        </FormGroup>
                                                                        {console.log(schedules, 'schedules')}
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                                options={getMinutes()}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getMinute(schedules[6].working_hours_from)}
                                                                                onChange={(option) => handleChange("wMinute", option, schedules[6], 6)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101  p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                                options={PracticeAmPm}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                // value={selectedAmPm}
                                                                                value={getAMPM(schedules[6].working_hours_from)}
                                                                                onChange={(option) => handleChange("wA", option, schedules[6], 6)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={`d-flex align-items-end pb-1 mt-2 to_end`}>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label style={{ width: '41.84px' }} className="p-0 mb-3 " >To</Label>
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                        options={hours}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getHours(schedules[6].working_hours_to)}
                                                                        onChange={(option) => handleChange("weHour", option, schedules[6], 6)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                        options={getMinutes()}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getMinute(schedules[6].working_hours_to)}
                                                                        onChange={(option) => handleChange("weMinute", option, schedules[6], 6)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101 w-100 p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={items.working_hours_status == 0 ? true : false}
                                                                        options={PracticeAmPm}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getAMPM(schedules[6].working_hours_to)}
                                                                        onChange={(option) => handleChange("weA", option, schedules[6], 6)}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pl-5">
                                                        <div className="">
                                                            <div className="d-flex justify-content-end ">
                                                                <div className="after_hour">
                                                                    <Label className="m-0" for="">After hours</Label>
                                                                    <FormControlLabel className="m-0 custom-radio-btn" control={<IOSSwitch checked={schedules[6].after_hours_status == 0 ? false : true} onChange={(e) => handleChange("afswitch", e, schedules[6], 6)} name="AftercheckCli" />} />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end pb-1 mt-2 from_start">
                                                                <FormGroup className={`align-items-center m-0 px-3 `}>
                                                                    <Label className="p-0 mb-3 " >From</Label>
                                                                </FormGroup>
                                                                <div className={`w-auto practice__hours_box`}>
                                                                    {/* { error && error[index] && <div className="text-danger w-100 error fs-12 px-7_5">{error[index]}</div>} */}
                                                                    <div className="d-flex">
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                            <DropDown
                                                                                className='react-select-container select-style-3 w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={statusValue === CLOSESTATUS}
                                                                                options={hours}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getHours(schedules[6].after_hours_from)}
                                                                                onChange={(option) => aFhourshandleChange("afHour", option, schedules[6], 6)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101 p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={statusValue === CLOSESTATUS}
                                                                                options={getMinutes()}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getMinute(schedules[6].after_hours_from)}
                                                                                onChange={(option) => aFhourshandleChange("afMinute", option, schedules[6], 6)}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                            <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                            <DropDown
                                                                                className='react-select-container w-101  p-0'
                                                                                classNamePrefix="react-select"
                                                                                // isDisabled={statusValue === CLOSESTATUS}
                                                                                options={PracticeAmPm}
                                                                                isSearchable={false}
                                                                                placeholder={''}
                                                                                value={getAMPM(schedules[6].after_hours_from)}
                                                                                onChange={(option) => aFhourshandleChange("afA", option, schedules[6], 6)}
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="d-flex align-items-end pb-1 mt-2 to_end">
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label style={{ width: '41.84px' }} className="p-0 mb-3 " >To</Label>
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Hr.</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={statusValue === CLOSESTATUS}
                                                                        options={hours}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getHours(schedules[5].after_hours_to)}
                                                                        onChange={(option) => aFhourshandleChange("afeHour", option, schedules[6], 6)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >Min</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101  p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={statusValue === CLOSESTATUS}
                                                                        options={getMinutes()}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getMinute(schedules[5].after_hours_to)}
                                                                        onChange={(option) => aFhourshandleChange("afeMinute", option, schedules[6], 6)}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup className={`align-items-center m-0 px-7_5 `}>
                                                                    <Label className="p-0 mb-2 w-100" >AM / PM</Label>
                                                                    <DropDown
                                                                        className='react-select-container w-101 w-100 p-0'
                                                                        classNamePrefix="react-select"
                                                                        // isDisabled={statusValue === CLOSESTATUS}
                                                                        options={PracticeAmPm}
                                                                        isSearchable={false}
                                                                        placeholder={''}
                                                                        value={getAMPM(schedules[5].after_hours_to)}
                                                                        onChange={(option) => aFhourshandleChange("afeA", option, schedules[6], 6)}
                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ''
                                    }
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-auto">
                            <LoadingButton type="button" label="Save Changes" loading={state.buttonLoading2} disabled={state.buttonLoading2} size="md" onClick={handleSubmitSchedule} className={`mt-5 mr-3`} color="primary" />
                            {/* <Button type="button" size="md" className="mt-5 mr-3" color="primary" onClick={handleSubmitSchedule}>Save</Button> */}
                            <Button type="button" size="md" /*disabled={!this.state.isSave}*/ className="mt-5 ml-3" outline color="default white" onClick={() => { history.goBack() }}>back</Button>
                        </div>
                    </Form>

                    {/* <Form onSubmit={handleSubmitSchedule}>
                        <div className="schedule_section">
                            {weeks.length &&
                                weeks.map((week, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <TimePicker
                                                id={week.id}
                                                Data={Data?.filter((f) => f.day_week === index + 1)}
                                                after_id={week.id}
                                                name={`week-${index}`}
                                                check={week.checkCli}
                                                after_check={week.AftercheckCli}
                                                TimeChangeHandler={TimeChangeHandler}
                                                handleChange={handleChange}
                                                handleAfterChange={handleAfterChange}
                                                weekstatus={weekstatus}
                                                day_week={week.day_week}
                                                day={week.name}
                                                working={week.working}
                                                after={week.after}
                                            />
                                        </Fragment>
                                    );
                                })}
                        </div>
                    </Form> */}
                </div>
            </div>
        </div>
    );
};

export default WaitingRoomSchedule;
