import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button } from 'reactstrap';
import { TRIVIATYPE, TIMELINETRIVIATYPE } from '../../data/comman';
import LoadingButton from '../../components/LoadingButton'

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomTriviaCategory = ({ setSelectedTrivia, timeLineList, setModal, onPublish }) => {
    const [allTriviaIndex, setAllTriviaIndex] = useState(true);
    const [triviaCount, setTriviaCount] = useState(0);
    const [trivias, setTrivias] = useState([]);
    const [count, setCount] = useState(0);
    const state = useSelector((state) => state.task);

    useEffect(() => {
        const arr = [];
        const trivias = [];
        const triviaArray = [];
        var totalTrivia = 0;
        state.waitingRoomsChannelTrivia.forEach((element) => {
            arr.push({
                id: element.id,
                title: element.title,
                selected: false,
                triviaLength: element.trivias.length,
                trivias: element.trivias,
            });

            element.trivias.forEach((trivia) => {
                var isAdded = timeLineList.filter(x => x.media_id == trivia.id && x.media_type_id == TIMELINETRIVIATYPE);
				triviaArray.push({
					...trivia,
					category_name: element.title,
                    selected: false,
                    isAdded,
				});
			});

            totalTrivia += element.trivias.length;
        });
        setTriviaCount(totalTrivia);
        setSelectedTrivia(triviaArray);
        setTrivias(arr)
    }, [state.waitingRoomsChannelTrivia]);

    const allTriviaListActive = allTriviaIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;

    // On Categry Click
    const onCategoryClick = (item) => {
        setAllTriviaIndex(false);
        setCount(count + 1);
        const triviaArray = [];
        trivias.map((element) => {
            if (element.id === item.id) {
                element.selected = !item.selected;
            }
        });
        trivias.map((element) => {
            if (element.selected === true) {
                element.trivias.forEach((trivia) => {
                    var isAdded = timeLineList.filter(x => x.media_id == trivia.id && x.media_type_id == TIMELINETRIVIATYPE);
                    triviaArray.push({
                        ...trivia,
                        category_name: element.title,
                        selected: false,
                        isAdded
                    });
                });
            }
        });

        // select all
        var isAllSelectCat = trivias.filter((x) => x.selected);
        setAllTriviaIndex(trivias.length == isAllSelectCat.length);

        setSelectedTrivia(triviaArray);
    };

    const changeAllTrivias = () => {
        setAllTriviaIndex(true);
        setCount(count + 1);
        const triviaArray = [];
        trivias.forEach((element) => {
            element.selected = false;
            element.trivias.forEach((trivia) => {
                var isAdded = timeLineList.filter(x => x.media_id == trivia.id && x.media_type_id == TIMELINETRIVIATYPE);
                triviaArray.push({
                    ...trivia,
                    category_name: element.title,
                    selected: false,
                    isAdded,
                });
            });
        });
         
        setSelectedTrivia(triviaArray);
    };

    return (
        <>
            <div className='videolist__main__screen'>
                <div className='videolist__main__lists'>
                    <div className='videolist__main__header'>
                        <span className='videolist__main__header__header-title d-block mb-3'>
                            Topics
                        </span>
                    </div>
                    <div className='videolist__submain__header'>
                        <img
                            src={allTriviaListActive}
                            alt=''
                            onClick={() => changeAllTrivias()}
                            className='videolist__submain__header__checkbox'
                        />
                        &nbsp; &nbsp; &nbsp;
                        <span className='videolist__submain__header__title'>
                            Show all my trivias {`(${triviaCount})`}<br/>
                        </span>
                    </div>
                    <hr className='videolist__main__lists_hr' />
                    {trivias.length > 0
                        ? trivias.map((item, index) => {
                                const active = item.selected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
                                return (
                                    <React.Fragment key={index}>
                                        <div className='videolist__main__lists__videoselection'>
                                            <img
                                                src={active}
                                                alt=''
                                                onClick={() => onCategoryClick(item)}
                                                className='videolist__submain__header__checkbox'
                                            />
                                            &nbsp; &nbsp; &nbsp;
                                            <span className='videolist__submain__header__title'>
                                                {item.title}
                                                {`(${item.triviaLength})`}
                                            </span>
                                        </div>
                                        {/* <br />  */}
                                    </React.Fragment>
                                );
                            })
                        : null}
                </div>
                <div className='d-flex justify-content-between videolist__main__button'>
                    <LoadingButton type="button"  label={"Publish timeline"} className="mr-3" loading={state.buttonLoading3} disabled={state.buttonLoading3} size="md" onClick={() => onPublish()}  color={'primary'}/>
                    <Button  className='ml-3' size="md" color='white' onClick={() => setModal(true)} >
                        Preview
                    </Button>
                </div>
            </div>
        </>
    );
};


export default WaitingRoomTriviaCategory
