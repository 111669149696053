import React, { Component } from "react";
import { FormGroup } from "@material-ui/core";
import { Button} from 'reactstrap';
import { InputField } from "../../components/InputField";
import { PracticeLogin , SignOut } from "../../store/actions/taskAction";
import { setDeviceCode } from "../../helper/LocalStorage";
import { connect } from "react-redux";
import LoadingButton from '../../components/LoadingButton';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            password: "",
            errors: {},
            isLoading: false
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    changeHandler(field, value){
        this.setState({ 
            [field]: value,
            errors: {},
        })
    }

    onSubmit(){
        let { userName, password } = this.state;
        var errors = {};
        var isError = false;
        if(userName.trim() == ""){
            errors["userName"] = "Please enter username";
            isError = true;
        }

        if(password.trim() == ""){
            errors["password"] = "Please enter password";
            isError = true;
        }

        if(isError){
            this.setState({ errors });
            return;
        }

        if(process.env.REACT_APP_USERNAME == userName.trim() && process.env.REACT_APP_PASSWORD == password.trim()){
            this.setState({ isLoading: true }, () => {
                this.login();
            });
        }else{
            errors["invalid"] = "Invalid username and password";
            this.setState({ errors });
        }
    }

    async login(){
        var { SignOut, PracticeLogin } = this.props;

        var param = {
            device_code: process.env.REACT_APP_DEVICE_CODE,
            access_code: process.env.REACT_APP_ACCESS_CODE,
            type:"PC"
          }

          setDeviceCode(`${process.env.REACT_APP_DEVICE_CODE}`);
      
          await SignOut();
          await PracticeLogin(param);
          this.setState({ isLoading: false });
    }

    render() {
        let { userName, password, errors, isLoading } = this.state;
        return (
            <div className="app align-item-center d-flex flex-colum justify-content-center h-100" style={{backgroundColor: '#343434'}}>
                <div className="card__body  col-4 m-auto ">
                    <div className="p-5">
                        <form className="px-4">
                            <FormGroup>
                                <InputField type="text" placeholder='Username' name="" value={userName} onChange={(e) => this.changeHandler("userName", e.target.value)} />
                                {errors.userName && <span className="text-danger">{errors.userName}</span>}
                            </FormGroup>
                            <FormGroup>
                                <InputField className="mt-4" type="password" placeholder='Password' name="" value={password} onChange={(e) => this.changeHandler("password", e.target.value)}/>
                                {errors.password && <span className="text-danger">{errors.password}</span>}
                                {errors.invalid && <span className="text-danger">{errors.invalid}</span>}
                            </FormGroup>
                            <LoadingButton type="button" label="Login" loading={isLoading} disabled={isLoading} size="md" onClick={this.onSubmit} className="mt-5 form-control" color="primary"/>
                            {/* <Button className="col w-100 mt-5" type="button" color="primary" size="lg" onClick={this.onSubmit} style={{ minWidth: 350, maxWidth: 350 }}>
                                <span className="btn__text">Login</span>
                            </Button> */}
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispacthToProps = dispatch => {
  return {
    SignOut: () => dispatch(SignOut()),
    PracticeLogin: (param) => dispatch(PracticeLogin(param)),
  };
};

export default connect( null,  mapDispacthToProps )(LoginForm);