/** @format */

import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getChairsidePlaylistMediaData, postwaitingRoomPublishTimeline } from '../../../store/actions/taskAction';
import LoadingButton from '../../../components/LoadingButton'

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const ChairsideCatList = ({ specialization, setVideos, timeLineList, setModal, onPublish}) => {
	const dispatch = useDispatch();
	const [allVideosIndex, setAllVideosIndex] = useState(true);
	const [allVideosCount, setAllVideosCount] = useState(0);
	const [categories, setCategories] = useState([]);
	const [count, setCount] = useState(0);
	const [videosList, setVideosList] = useState([]);
	// const specialization = useSelector((state) => state.task.educationTimeline);
	const state = useSelector((state) => state.task);

	

	useEffect(() => {
		const categoryArray = [];
		const videos = [];
		var totalVideos = 0;
		state.chairsidePlaylistMediaData && state.chairsidePlaylistMediaData.map((element) => {
			categoryArray.push({
				category_id: element.id,
				category_name: element.title,
				selected: false,
				videosLength: element.chairside_videos.length,
				videos: element.chairside_videos,
			});
			element.chairside_videos.map((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.chairside_media_id);
				videos.push({
					categories_id: element.id,
					categories_name: element.title,
					...video,
					chairside_media:{
						...video.chairside_media,
						media_language:{
							media:{
								provider_url:video?.media?.provider_url
							}
						}
					},
					selected: false,
					isAdded
				});
			});
			totalVideos += element.chairside_videos.length;
		});
		setAllVideosCount(totalVideos);
		setVideosList(videos);
		setVideos(videos);
		setCategories(categoryArray);
	}, [specialization]);

	// On Categry Click
	const onCategoryClick = (item) => {
		setAllVideosIndex(false);
		setCount(count + 1);
		const videosArray = [];
		categories.forEach((element) => {
			console.log(element,'element')
			if (element.category_id === item.category_id) {
				element.selected = !item.selected;
			}
		});
		console.log(categories,'categories')
		categories.forEach((element) => {
			if (element.selected === true) {
				element.videos.forEach((video) => {
					var isAdded = timeLineList.filter(x => x.media_id == video.chairside_media_id);
					videosArray.push({
						category_name: element.category_name,
						category_id: element.categories_id,
						...video,
						selected: true,
						isAdded
					});
				});
			}
		});

		// select all
		var isAllSelectCat = categories.filter((x) => x.selected);
		setAllVideosIndex(categories.length == isAllSelectCat.length);

		setVideos(videosArray);
	};

	// Change All Videos
	const changeAllVideos = () => {
		setAllVideosIndex(true);
		setCount(count + 1);
		categories.forEach((element) => {
			element.selected = false;
		});
		const videosArray = [];
		categories.forEach((element) => {
			element.videos.forEach((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				videosArray.push({
					...video,
					category_name: element.category_name,
					category_id: element.categories_id,
					selected: true,
					isAdded,
				});
			});
		});
		setVideos(videosArray);
	};

	const allVideoListActive = allVideosIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
	return (
		<div className='videolist__main__screen waiting-room-chanel-sec d-flex flex-column'>
			<div className='videolist__main__lists'>
				<div className='videolist__main__heade mb-3'>
					<span className='videolist__main__header__header-title'>
						Select specialization(s)
					</span>
				</div>
				<div className='videolist__submain__header'>
					<img
						src={allVideoListActive}
						alt=''
						onClick={() => changeAllVideos()}
						className='videolist__submain__header__checkbox'
					/>
					<span className='videolist__submain__header__title ml-4'>
						Show All Videos {` (${allVideosCount})`}
					</span>
				</div>
				<hr className='videolist__main__lists_hr' />
				{categories.length > 0
					? categories.map((item, index) => {
							const active =
								item.selected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
							return (
								<React.Fragment key={index}>
									<div className='videolist__main__lists__videoselection' onClick={() => onCategoryClick(item)} style={{cursor:'pointer'}}>
										<img
											src={active}
											alt=''
											
											className='videolist__submain__header__checkbox'
										/>
										<span className='videolist__submain__header__title ml-4'>
											{item.category_name}
											{` (${item.videosLength})`}
										</span>
									</div>
									{/* <br />  */}
								</React.Fragment>
							);
					  })
					: null}
			</div>
			<div className='d-flex justify-content-between mt-auto'>
				<LoadingButton type="button"  label={state.savePlaylistmessage} className="w-100" loading={state.buttonLoading3} disabled={state.buttonLoading3}  onClick={() => onPublish()}  color={state.bgColor}/>
			</div>
		</div>
	);
};

export default ChairsideCatList;
