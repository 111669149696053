import React from 'react';

import { Button , Label , FormGroup, Form , Col , Row  , CustomInput } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../../pages/index';
import moment from 'moment';
import { UpdatePracticeProfile  , SubscribeNew , SubscribeNewRequest, showError, sameAsMainPractice, UpgradeDowngradeSubscribe, GetBillingCard} from "../../../store/actions/taskAction";
import Thalamus_Logo from '../../../img/Thalamus_Logo.svg'
import allStripePaymentIcon from '../../../img/allStripePaymentIcon.svg'
import stripe_icon from '../../../img/stripe_icon.svg'
import Que from '../../../img/QueIcon.svg'
import CVVIconHover from '../../../img/cvv-hover.png'
import {  connect } from 'react-redux'
import { InputField } from '../../../components/InputField';
import NumberFormat from 'react-number-format';
import { getCardImageByNo , getCartImageByType , count, getCardNameByNo, YY, isRequired} from '../../../data/comman';
import { compose } from 'redux';
import LoadingButton from '../../../components/LoadingButton'
import AppDropDown from '../../../components/DropDown';
import HttpUtility, { HELPERURI } from '../../../utilities/HttpUtility';
import Loading from '../../../components/Loading';

class Review extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        primaryCardSelected: null,
        creditCardNumber: "",
        cardHolderName: "",
        expireDate: "",
        editExpireDate: "",
        code: "",
        formValid:false,
        isChecked:false,
        secondaryemail:0,
        selectedcountry:[],
        selectedstate:[],
        address_line_1:'',
        address_line_2:'',
        city:'',
        zip:'',
        savedCard:false,
        usenewcreditcard:true,
        isError:{
            creditCardNumber: "",
            cardHolderName: "",
            editExpireDate: "",
            code:'',
            selectedPractices:'',
            selectedcountry:'',
            address_line_1:'',
            address_line_2:'',
            city:'',
            selectedstate:'',
            zip:'',
        }
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.subscribeNew = this.subscribeNew.bind(this);
    this.handleSecondaryEmail = this.handleSecondaryEmail.bind(this);
  }

    nextClick(){
        this.props.nextStep();
    }

    prevClick(){
        this.props.prevStep();
    }

    handleSecondaryEmail(event){
        const target = event.target;
        const value =  target.checked ? 1 : 0;
        const name = target.name;
        this.setState({
        [name]: value    
        });
        console.log(this.state.secondaryemail,'secondaryemail')
    }
    
    async onEditSubscription(field , value){
        let subscribeInfo = this.props.subscribeInfo
        subscribeInfo[field] = value
        this.props.dispatch(SubscribeNewRequest(subscribeInfo));
    }

    isValidate(){
        const { cardHolderName, creditCardNumber, editExpireDate , code , address_line_1 , city , selectedcountry , selectedstate ,zip ,primaryCardSelected} = this.state;
        var error = {errors: []};
        var isError = false;
        
    
        var currentYear = moment().format(YY)
        var currentMonth = moment().format('M')
    
        if(zip.toString().trim() == "" ){
            isError = true;
            error['errors']['address.billing.zip'] = ["The zip  field is required."];
        }

        if(isRequired(selectedstate)){
            isError = true;
            error['errors']['address.billing.state_id'] = ["The state  field is required."];
        }

        if(address_line_1.toString().trim() == ""){
            isError = true;
            error['errors']['address.billing.address_line_1'] = ["The address field is required."];
        }

        if(city.toString().trim() == ""){
            isError = true;
            error['errors']['address.billing.city'] = ["The city field is required."];
        }

        if(isRequired(selectedcountry)){
            isError = true;
            error['errors']['address.billing.country_id'] = ["The country  field is required."];
        }
        
        if(this.state.savedCard){
        if(isRequired(primaryCardSelected)){
            isError = true;
            error['errors']['pay_info.card_id'] = ["Please select a card from the list."];
        }
        }else{

            if(code.toString().trim() == ""){
            isError = true;
            error['errors']['pay_info.card.cvv'] = ["Please enter cvv"];
            }else if(code.toString().trim().length < 3 ){
            isError = true;
            error['errors']['pay_info.card.cvv'] = ["Invalid cvv"]; 
            }else if(getCardNameByNo(creditCardNumber) == "AMEX" && code.toString().trim().length < 4 ){
            isError = true;
            error['errors']['pay_info.card.cvv'] = ["Invalid cvv"];
            }

            if(cardHolderName.toString().trim() == ""){
                isError = true;
                error['errors']['pay_info.card.name'] = ["Please enter name"];
            }

            if(editExpireDate.toString().trim() == ""){
                isError = true;
                error['errors']['pay_info.card.expiry_month'] = ["Please enter expiry date"];
            }else if(editExpireDate.toString().trim().length < 4){
                isError = true;
                error['errors']['pay_info.card.expiry_month'] = ["Invalid expiry date"];
            }else if(parseInt(this.state.editExpireDate.slice(0,2)) > 12 || parseInt(this.state.editExpireDate.slice(0,2)) < 1){
                isError = true;
                error['errors']['pay_info.card.expiry_month'] = ["Invalid expiry date"];
            }else if((parseInt(editExpireDate.toString().trim().slice(2,6)) > parseInt(currentYear) + 20) || parseInt(editExpireDate.toString().trim().slice(2,6)) < parseInt(currentYear) ){
                isError = true;
                error['errors']['pay_info.card.expiry_month'] = ["Invalid expiry date"];
            }else if((parseInt(this.state.editExpireDate.slice(0,2)) < currentMonth) && (parseInt(editExpireDate.slice(2,6))) <= currentYear){
                isError = true;
                error['errors']['pay_info.card.expiry_month'] = ["Invalid expiry date"];
            }

            if(creditCardNumber.toString().trim() == ""){
                isError = true;
                error['errors']['pay_info.card.number'] = ["Please enter card number"];
            }

        }
    
        this.props.dispatch(showError(error));
        return isError
    }

    async subscribeNew(){
        console.log(this.props.getSubscribePriceCalc.total?.final_price,'this.props.getSubscribePriceCalc.total?.final_price')

        if((this.props.getSubscribePriceCalc.upgrade_downgrade_action == undefined) || ((this.props.getSubscribePriceCalc.upgrade_downgrade_action == 'downgrade_plan' || this.props.getSubscribePriceCalc.upgrade_downgrade_action == 'upgrade_plan') && this.props.getSubscribePriceCalc?.total?.final_price !== 0 )){
            if(this.isValidate()) return;
        }

        const {practiceProfileInfo} = this.props
        var specializations = []
        if(practiceProfileInfo.specializations){
            practiceProfileInfo.specializations.map((ids)=>
            specializations.push(ids.id)
            );
        }

      await  this.onEditSubscription('isLoading' , true)
      var hardwaresNew = [];
        let getSubscribePriceCalc = this.props.getSubscribePriceCalc
        let hardwares = {
        ...getSubscribePriceCalc.hardwares
        }

        if(this.props.selectedhardware.value == 0){
            hardwares = {
                ...getSubscribePriceCalc.free_hardwares
            }
        }
        hardwares = hardwares.detail;


        hardwares?.map((hardware , index)=>{
            hardwaresNew.push({
                "id": hardware.hardware_id,
                "qty": this.props.selectedhardware.value == 0 ? 0 :  hardware.qty,
                "with_warranty":hardware.with_warranty
            })
        })

      let params = {
            "plan_id":this.props.selectedPlan.id,
            "subscription_type":this.props.getSubscribePriceCalc.subscription.payment_cycle_key,
            "additional_domains_count":this.props.getSubscribePriceCalc.additional_domains.count,
            "additional_devices_count":this.props.getSubscribePriceCalc.additional_devices.count,
            "hardwares": hardwaresNew,
            "pay_info": {
                "type": "card",
                "card": {
                    "name": this.state.cardHolderName,
                    "number":  this.state.creditCardNumber,
                    "expiry_month":  parseInt(this.state.editExpireDate.slice(0,2)),
                    "expiry_year":  parseInt(this.state.editExpireDate.slice(2,6)),
                    "cvv": this.state.code
                },
            "pay_once": 0,
            "card_id": 0,
        },
        "promo_code":  this.props.getSubscribePriceCalc && this.props.getSubscribePriceCalc.promo_code_info && this.props.getSubscribePriceCalc.promo_code_info.code ? this.props.getSubscribePriceCalc.promo_code_info.code : "",
        "need_statement_on_email": 1,
        "statement_email": this.state.secondaryemail,
        }

        if(this.state.savedCard){
            params['pay_info'] = {
                    "type": "card",
                    "card": {},
                    "pay_once": 0,
                    "card_id": this.state.primaryCardSelected && this.state.primaryCardSelected.id && this.state.primaryCardSelected.id || '',
            }
        }else{
            if(getSubscribePriceCalc.upgrade_downgrade_action !== undefined){
                params['pay_info'] = {
                        "type": "card",
                        "card": {
                            "name": this.state.cardHolderName,
                            "number":  this.state.creditCardNumber,
                            "expiry_month":  parseInt(this.state.editExpireDate.slice(0,2)),
                            "expiry_year":  parseInt(this.state.editExpireDate.slice(2,6)),
                            "cvv": this.state.code
                        },
                        "pay_once": 1,
                        "card_id": 0,
                }
            }


            
        }

        if(this.props.isNewLocation){
            params['isNewLocation'] = true
            params['add_manage_location'] = true
            params['practice'] = {
                name:practiceProfileInfo.name,
                contact_name:'',
                email:practiceProfileInfo.email,
                phone:practiceProfileInfo.phone.replace(/\s+/g, ''),
                phone_alt: practiceProfileInfo.phone_alt && practiceProfileInfo.phone_alt.replace(/\s+/g, ''),
                country_id:practiceProfileInfo.selectedcountry.id,
                address_line_1:practiceProfileInfo.address_line_1,
                address_line_2:practiceProfileInfo.address_line_2,
                city:practiceProfileInfo.city,
                state_id:practiceProfileInfo.selectedstate.id,
                zip:practiceProfileInfo.zip,
                specializations:specializations,
            }
        }

        if((this.props.isUpgradePlan || this.props.practiceInfo?.is_paid_account === 1) && this.props.isNewLocation != true){
            await  this.props.dispatch(UpgradeDowngradeSubscribe(params));
        }else{
            await  this.props.dispatch(SubscribeNew(params));
        }
        
    }

    componentDidMount(){
        this.props.dispatch(GetBillingCard());
         this.setState({
            selectedcountry: this.props.practiceProfileInfo.selectedcountry,  
            selectedstate:this.props.practiceProfileInfo.selectedstate,
        })
    }

    async changeHandler(event, filed){

        this.props.dispatch(showError({ errors: [] }));

        var filedName = "", filedValue = "";
        var vardImage = this.state.vardImage;

        if(filed === "creditCardNumber"){
            filedName = filed;
            filedValue = event.value;
            vardImage = getCardImageByNo(filedValue);
      
          }else if(filed === 'selectedcountry'){
            filedName = filed;
            filedValue = event;
            this.getState(filedValue.id);
          }else if(filed === 'selectedstate'){
            filedName = filed;
            filedValue = event;
          }else if(filed === 'countryState'){
            console.log(event,'event')
            filedName = filed;
            filedValue = event;
          }else if(filed === 'editExpireDate'){
            filedName = filed;
            filedValue = event.value;
          }else if(filed === 'code'){
            filedName = filed;
            filedValue = event.value;
          }else if(filed === 'savedCard'){
            filedName = filed;
            filedValue = event.target.checked;
             console.log(filedName,'', filedValue)
          }else if(filed === 'usenewcreditcard'){
            filedName = filed;
            filedValue = event.target.checked;
          }else if(filed === 'sameasmainpractice'){
            filedName = filed;
            filedValue = event.target.checked;
            await  this.setState({[filedName]: filedValue})
      
            if(this.state.sameasmainpractice){

                if(this.props.isCorporate == 1){
                    if(this.props.mainPracticeAddress && this.props.mainPracticeAddress.country_id){

                    }else{
                        await this.props.dispatch(sameAsMainPractice())
                    }

                    if(this.props.mainPracticeAddress.country_id){
                        var selectedcountry =  this.props.country.find(b => b.id == this.props.mainPracticeAddress.country_id);
                        await this.getState(this.props.mainPracticeAddress.country_id);
                
                        var selectedstate = await this.state.countryState.find(b => b.id == this.props.mainPracticeAddress.state_id);
                        console.log(this.props.mainPracticeAddress.country_id,'selectedcountry' , selectedstate);
                    }
                    
            
                    await this.setState({
                        selectedcountry:selectedcountry,  
                        selectedstate:selectedstate,
                        city:this.props.mainPracticeAddress.city,
                        address_line_1:this.props.mainPracticeAddress.address_line_1,
                        address_line_2:this.props.mainPracticeAddress.address_line_2,
                        zip:this.props.mainPracticeAddress.zip
                    })
                }else{
                    
                    await this.setState({
                        selectedcountry: this.props.practiceProfileInfo.selectedcountry,  
                        selectedstate:this.props.practiceProfileInfo.selectedstate,
                        city:this.props.practiceProfileInfo.city,
                        address_line_1:this.props.practiceProfileInfo.address_line_1,
                        address_line_2:this.props.practiceProfileInfo.address_line_2,
                        zip:this.props.practiceProfileInfo.zip
                    })
                }
            }
          }
          else if(filed == "primaryCardSelected"){
            filedName = filed;
            filedValue = event;
            this.setState({ 
               primaryCardHolderName: filedValue.name,
               primaryCardHolderExipreDate: `${filedValue.exp_month} / ${filedValue.exp_year}`,
               [filedName]: filedValue,
               errors: {
                  ...this.state.errors,
                  [filedName]:  "",
               }
            })
          }
          else{
              var { name, value } = event.target;
              filedName = name;
              filedValue = value;
          }

        this.setState({
            [filedName]: filedValue,
            vardImage,
        },()=> {
            // this.isValid(filedName , filedValue);  
        });
    }

    isValid(name , value){
        let isError = this.state.isError ;

        switch (name) {
          case "creditCardNumber":
              isError.creditCardNumber =
                value.length > 4  ? "Atleast 4 characaters required" : "";
              break;
          case "cardHolderName":
              isError.cardHolderName =  
              value  ? "Atleast 4 characaters required" : "";
              break;
          case "editExpireDate":
              isError.editExpireDate =  
              value ? "required" : "";
              break;
          case "code":
            isError.code =  
            value ? "required" : "";
            break;
          default:
              break;
        }
    
        this.setState(this.validateForm)
    }
      
    validateForm(){

        if(this.state.creditCardNumber !== '' &&
        this.state.cardHolderName !== '' &&
          this.state.editExpireDate !== '' &&
          this.state.code !== ''){

                this.setState({formValid: true});
            }else{

                this.setState({formValid: false});

            }
        
    }

    async getState(countryId){
        this.setState({selectedstate:null})
    
        await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
            if(response.status === 200){
                var countryState = response.data;
                this.changeHandler( countryState,'countryState')
                // this.changeHandler( [], 'selectedstate')
            }
        });
    }

    changecheckboxHandler(e , field){
    this.setState({savedCard:!this.state.savedCard})
    }

    customOpetionLabel({last4, brand}) {
    return  <div className="custome__opetion d-flex align-items-center">
            <div className="card_brand__img mr-4">
            <img src={getCartImageByType(brand)} alt={brand}/>
            </div>
            <div className="select__label">{`XXXX XXXX XXXX ${last4}`}</div>
        </div>
    };
    
  
  render (){
    const {t, getSubscribePriceOption , getSubscribePriceCalc , subscribeInfo , error, country} = this.props
    var subscription = getSubscribePriceOption['subscription'];
    var currency_symbol = getSubscribePriceOption.currency.symbol
    var imgsrc = !this.state.isReplace ? this.state.vardImage : this.state.vardImage
    var qty = [];
    return(
        <div style={{paddingRight:'1rem'}} className="mw-849  transparent__form--white  d-flex flex-column align-items-stretch mb-auto">
            {!this.state.selectedstate && <Loading />  }
          <Form className="h-100 d-flex flex-column ">
            <div className="inner_white_box mb-3 py-5"  style={{marginRight: '1.4rem'}}>
                <Row>
                    <Col md={12} className="">
                        <FormGroup className="m-0 d-flex  align-items-center justify-content-between">
                            <h4 className="m-0">Review & Complete your subscription</h4>
                            <img src={Thalamus_Logo} alt=""/>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className='payment_scroll mb-20'>
            <div className="inner_white_box mb-3 review_grand_total" >
                <Row>
                    <Col md={6} className="">
                        <h4>Items</h4>
                    </Col>
                    <Col md={4} className="">
                        <h4>Quantity</h4>
                    </Col>
                    <Col md={2} className="">
                        <h4>Amount</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="">
                        <Label className="my-2">{`${getSubscribePriceCalc.subscription  && getSubscribePriceCalc.subscription.payment_cycle}`} subscription | {`${getSubscribePriceCalc.subscription  && getSubscribePriceCalc.subscription.name}`} </Label>
                    </Col>
                    <Col md={4} className="">
                        <Label className="my-2">1</Label>
                    </Col>
                    <Col md={2} className="">
                        <Label className="my-2">{`${currency_symbol}${getSubscribePriceCalc.subscription  && getSubscribePriceCalc.subscription.base_price.toFixed(2) || '0.00'}`}</Label>
                    </Col>
                </Row>
                {((getSubscribePriceCalc.upgrade_downgrade_action == undefined) || ((getSubscribePriceCalc.upgrade_downgrade_action == 'downgrade_plan' || getSubscribePriceCalc.upgrade_downgrade_action == 'upgrade_plan') && (getSubscribePriceCalc?.setup_price !== 0 && getSubscribePriceCalc?.setup_price != null) )) &&
                <Row>
                        <Col md={6} className="">
                            <Label className="my-2">Setup fee {getSubscribePriceCalc.promo_code_info && getSubscribePriceCalc?.promo_code_info?.device_chromecast === "N" ? "" : '(1x Google TV included)'}</Label>
                        </Col>
                        <Col md={4} className="">
                            <Label className="my-2">1</Label>
                        </Col>
                        <Col md={2} className="">
                            <Label className="my-2">{`${currency_symbol}${getSubscribePriceCalc && getSubscribePriceCalc.subscription && (getSubscribePriceCalc?.setup_price || 0).toFixed(2)}`}</Label>
                        </Col>
                    </Row>
                }
                {
                     getSubscribePriceCalc.free_hardwares && getSubscribePriceCalc.free_hardwares.detail.map((free_hardware , index)=>{
                        qty.push(free_hardware.qty) 
                     })
                }

                    {
                    getSubscribePriceCalc.hardwares && getSubscribePriceCalc.hardwares.detail.length > 0 ?

                    getSubscribePriceCalc.hardwares && getSubscribePriceCalc.hardwares.detail.map((hardware , index)=>{
                    return  <>
                                <Row key={index}>
                                    <Col md={6} className="">
                                        <Label className="my-2">{`${hardware.hardware && hardware.hardware.name}`}</Label>
                                    </Col>
                                    <Col md={4} className="">
                                        <Label className="my-2">{`${hardware.qty && hardware.qty || 'n/a'}`}</Label>
                                    </Col>
                                    <Col md={2} className="">
                                        <Label className="my-2">{`${currency_symbol}${hardware.price && (hardware.price * hardware.qty).toFixed(2) || '0.00'}`}</Label>
                                        </Col>
                                </Row>
                                {console.log(qty,'qty')}
                                {hardware.with_warranty == 1 &&
                                <Row >
                                    <Col md={6} className="">
                                         <Label className="my-2">{`${hardware.with_warranty == 1 && 'Warranty included '}`}</Label>
                                    </Col>
                                    <Col md={4} className="">
                                        <Label className="my-2">{`${hardware.qty && (hardware.qty + (qty.length > 0 ? qty[index]  : 0 )) || 'n/a'}`}</Label>
                                        {console.log(qty)}
                                    </Col>
                                    <Col md={2} className="">
                                        <Label className="my-2">{`${currency_symbol}${hardware.total_qty_warranty_price && (hardware.total_qty_warranty_price + (getSubscribePriceCalc?.free_hardwares?.total || 0) ).toFixed(2)}`}</Label>
                                    </Col>
                                </Row>
                                }
                            </>

                    })

                    :
                    getSubscribePriceCalc.free_hardwares && getSubscribePriceCalc.free_hardwares.detail.length > 0 &&  getSubscribePriceCalc.free_hardwares.detail.map((free_hardware , index)=>{
                        return  <>
                                {free_hardware.with_warranty == 1 &&
                                <Row >
                                    <Col md={6} className="">
                                         <Label className="my-2">{`${free_hardware.with_warranty == 1 && 'Warranty included '}`}</Label>
                                    </Col>
                                    <Col md={4} className="">
                                        <Label className="my-2">{`${free_hardware.qty && (free_hardware.qty) || 'n/a'}`}</Label>
                                    </Col>
                                    <Col md={2} className="">
                                        <Label className="my-2">{`${currency_symbol}${ getSubscribePriceCalc?.free_hardwares?.total.toFixed(2) || '0.00'}`}</Label>
                                    </Col>
                                </Row>
                                }
                            </>
                    })
                }
                 {getSubscribePriceCalc.additional_domains.count > 0 &&
                    <Row >
                        <Col md={6} className="">
                            <Label className="my-2">Domains</Label>
                        </Col>
                        <Col md={4} className="">
                            <Label className="my-2">{getSubscribePriceCalc.additional_domains.count}</Label>
                        </Col>
                        <Col md={2} className="">
                            <Label className="my-2">{`${currency_symbol}${getSubscribePriceCalc.additional_domains && getSubscribePriceCalc.additional_domains.base_price || '0.00'}`}</Label>
                        </Col>
                    </Row>
                    }
                    {getSubscribePriceCalc.additional_devices.count > 0 &&
                        <Row >
                            <Col md={6} className="">
                                <Label className="my-2">Devices</Label>
                            </Col>
                            <Col md={4} className="">
                                <Label className="my-2">{getSubscribePriceCalc.additional_devices.count}</Label>
                            </Col>
                            <Col md={2} className="">
                                <Label className="my-2">{`${currency_symbol}${getSubscribePriceCalc.additional_devices && getSubscribePriceCalc.additional_devices.base_price || '0.00'}`}</Label>
                            </Col>
                        </Row>
                    }
                <div className='pt-5 mb-5 border-bottom'></div>
                {getSubscribePriceCalc && getSubscribePriceCalc.promo_code_info.code &&
                    <Row>
                        <Col md={6} className="">
                            <Label className="my-2">Promo Code {getSubscribePriceCalc && getSubscribePriceCalc.promo_code_info.code ? `(${getSubscribePriceCalc && getSubscribePriceCalc.promo_code_info.code})` : ''}</Label>
                        </Col>
                        <Col md={4} className="">
                            {/* <Label className="my-2">n/a</Label> */}
                        </Col>
                        <Col md={2} className="">
                            {/* <Label className="my-2">{`-${currency_symbol}${getSubscribePriceCalc && getSubscribePriceCalc.promo_code_info?.setup_discount_value?.toFixed(2) || '0.00'}`}</Label> */}
                        </Col>
                    </Row>
                }
                {(getSubscribePriceCalc?.total?.discount != 0 && getSubscribePriceCalc?.total?.discount != undefined) && 
                    <Row>
                        <Col md={6} className="">
                            <Label className="my-2">Discount </Label>
                        </Col>
                        <Col md={4} className="">
                            {/* <Label className="my-2">n/a</Label> */}
                        </Col>
                        <Col md={2} className="">
                            <Label className="my-2"><strong>-{`${currency_symbol}${getSubscribePriceCalc && getSubscribePriceCalc.total.discount || '0.00'}`}</strong></Label>
                        </Col>
                    </Row>
                }
                {(getSubscribePriceCalc?.tax?.price != 0) && 
                <Row>
                    <Col md={6} className="">
                        <Label className="my-2">Tax {`(${getSubscribePriceCalc.tax && getSubscribePriceCalc.tax.tax ||'0'}%)`}</Label>
                    </Col>
                    <Col md={4} className="">
                        {/* <Label className="my-2">{`${getSubscribePriceCalc.tax && getSubscribePriceCalc.tax.tax || 'n/a'}`}</Label> */}
                    </Col>
                    <Col md={2} className="">
                        <Label className="my-2">{`${currency_symbol}${getSubscribePriceCalc.tax && getSubscribePriceCalc.tax.price.toFixed(2) || '0.00'}`}</Label>
                    </Col>
                </Row>
                }
                {(getSubscribePriceCalc?.partial_charge_deduction != 0) && 
                    <Row >
                        <Col md={6} className="">
                            <Label className="my-2">Partial Charge Deduction</Label>
                        </Col>
                        <Col md={4} className="">
                            
                        </Col>
                        <Col md={2} className="">
                            <Label className="my-2"><strong>-{`${currency_symbol}${getSubscribePriceCalc.partial_charge_deduction && getSubscribePriceCalc.partial_charge_deduction || '0.00'}`}</strong></Label>
                        </Col>
                    </Row>
                }
                {getSubscribePriceCalc?.credit && (this.props.practiceInfo?.is_paid_account === 1 &&  this.props.isNewLocation !== true) && (getSubscribePriceCalc?.credit?.total != 0) &&
                <Row >
                    <Col md={6} className="">
                        <Label className="my-2">Credit</Label>
                    </Col>
                    <Col md={4} className="">
                        
                    </Col>
                    <Col md={2} className="">
                        <Label className="my-2"><strong>-{`${currency_symbol}${getSubscribePriceCalc.credit && (getSubscribePriceCalc.credit?.total || 0).toFixed(2) }`}</strong></Label>
                    </Col>
                </Row>
                }

                {getSubscribePriceCalc?.previous_invoice_details && (this.props.practiceInfo?.is_paid_account === 1 && this.props.isNewLocation !== true) && (getSubscribePriceCalc?.previous_invoice_details && getSubscribePriceCalc?.previous_invoice_details?.total_deposited_amount != 0) &&
                <Row >
                    <Col md={6} className="">
                        <Label className="my-2">Previous invoice settlement</Label>
                    </Col>
                    <Col md={4} className="">
                        
                    </Col>
                    <Col md={2} className="">
                        <Label className="my-2"><strong>-{`${currency_symbol}${getSubscribePriceCalc.previous_invoice_details && (getSubscribePriceCalc.previous_invoice_details?.total_deposited_amount || 0).toFixed(2) }`}</strong></Label>
                    </Col>
                </Row>
                }

                <Row className="d-flex mt-5 align-items-center justify-content-between">
                    <Col md={6} className="">
                        <h4>Grand total</h4>
                    </Col>
                    <Col md={2} className="">
                        <h4>{`${currency_symbol}${getSubscribePriceCalc.total && getSubscribePriceCalc.total.final_price || '0'}`}</h4>
                    </Col>
                </Row>
                <Row className="d-flex mt-5 align-items-center justify-content-between">
                    <Col md={12} className="">
                        <p className='payment_info_text'>* You will only be partially charged for the first month. Full subscription rate will be charged on the 1st of the following month. </p>
                    </Col>
                </Row>
            </div>
            {((getSubscribePriceCalc.upgrade_downgrade_action == undefined) || (getSubscribePriceCalc?.total?.final_price !== 0 )) &&
            <div className="inner_white_box  payment-box">
                <Row>
                    <Col md={12} className="">
                        <FormGroup className="m-0 d-flex  align-items-center justify-content-between payment-heading">
                            <div className='d-flex align-items-center mb-4'>
                                <h4 className="m-0 mr-4">Payment</h4>
                                <img className='mr-4' src={allStripePaymentIcon} alt=""/>
                                <Button type="button" color="link" className="p-0"><img src={Que} alt=""/></Button>
                                <img className='ml-4' src={stripe_icon} alt=""/>
                            </div>
                            {/* <Label>Receive monthly statements on</Label> */}
                        </FormGroup>
                    </Col>
                </Row>
                {(getSubscribePriceCalc.upgrade_downgrade_action !== undefined) &&
                    <Row>
                        <Col md={6}>
                            <div className="sm custom-checkbox custom-control mb-20">
                                <input type="checkbox"   name={'savedCard'} onChange={(event)=>this.changecheckboxHandler(event,'savedCard')} checked={this.state.savedCard} className="custom-control-input" id="savedCard"/>
                                <label className="custom-control-label" htmlFor="savedCard">Select a credit card saved in your account</label>
                            </div>
                            <FormGroup className="select-location">
                            <Label>Select a credit card from your account</Label>
                            <AppDropDown 
                                className={`${error && error.errors && error.errors['pay_info.card_id'] ? 'invalid-field' : ''} react-select-container w-100`}
                                classNamePrefix="react-select"
                                options={this.props.getBillingCardInfo && this.props.getBillingCardInfo || []}
                                formatOptionLabel={this.customOpetionLabel}
                                onChange={(options) => this.changeHandler( options, "primaryCardSelected")}
                                getOptionLabel={x => `XXXX XXXX XXXX ${x.last4}`}
                                getOptionValue={x => `${x.pay_card_id}`}
                                placeholder={'Enter the last 4 digits or search on the list'}
                                isDisabled={!this.state.savedCard}
                            />
                            {error && error.errors && error.errors['pay_info.card_id'] && <span className='text--danger'>{error.errors['pay_info.card_id'][0]}</span>}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <div className="sm custom-checkbox custom-control ">
                                <input  type="checkbox" onChange={(event)=>this.changecheckboxHandler(event,'usenewcreditcard')} checked={!this.state.savedCard} name={'usenewcreditcard'}  className="custom-control-input" id="usenewcreditcard"/>
                                <label className="custom-control-label" htmlFor="usenewcreditcard">Use a new credit card</label>
                            </div>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col md={12} className=" d-flex  align-items-center justify-content-end">
                        <Col md={4} className="">
                            <FormGroup className="m-0 d-flex  align-items-center justify-content-end payment-heading">
                                <Label>Receive monthly statements on</Label>
                            </FormGroup>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="">
                        <FormGroup className={`${error && error.errors && error.errors['pay_info.card.number'] ? 'inputerror' : ''}  icon-card `}>
                            <div className={`d-flex justify-content-between `}>
                                <Label>Credit card number</Label>
                                {/* {error && error.errors && error.errors['pay_info.card.number'] && <span className='text--danger'>{error.errors['pay_info.card.number'][0]}</span>} */}
                            </div>
                            <NumberFormat disabled={this.state.savedCard} className="bg__yellow mw_auto form-control" format="#### #### #### ####" name="creditCardNumber" value={this.state.creditCardNumber} onValueChange={(value)=>this.changeHandler(value, "creditCardNumber")}/>
                            {imgsrc ? <img src={imgsrc}  className="icon-img" alt="" /> : null}
                        </FormGroup>
                    </Col>
                    <Col md={2} className="">
                        <FormGroup className={`${error && error.errors && error.errors['pay_info.card.expiry_month'] ? 'inputerror' : ''}`}>
                            <Label>Expire date</Label>
                            <NumberFormat disabled={this.state.savedCard}  className="bg__yellow mw_auto form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="editExpireDate" onValueChange={(value)=>this.changeHandler(value, "editExpireDate")} />
                        </FormGroup>
                    </Col>
                    <Col md={4} className="">
                        <CustomInput className="sm mb-2" type="checkbox" checked={true}  id="exampleCustomCheckbox" label="Registered email" />
                        <InputField className="bg__yellow mw_auto" type="text" label="" disabled name="email"  value={this.props.practiceProfileInfo.email}    onChange={(e) => this.changeHandler(e , 'email')}  />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="">
                    <FormGroup className={`${error && error.errors && error.errors['pay_info.card.name'] ? 'inputerror' : ''}`}>
                        <InputField disabled={this.state.savedCard} className="bg__yellow mw_auto" type="text" label="Name on card" name="cardHolderName" onChange={(e) => this.changeHandler( e )}  />
                    </FormGroup>
                    </Col>
                    <Col md={2} className="">
                    <FormGroup className={`${error && error.errors && error.errors['pay_info.card.cvv'] ? 'inputerror' : ''} cvv-img`}>
                        <Label>Security code</Label>
                        <NumberFormat disabled={this.state.savedCard} className="bg__yellow mw_auto form-control"  format={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "####" : "###"} placeholder={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "XXXX" : "XXX"} name="code" onValueChange={(value)=>this.changeHandler(value, "code")} type="password"/>
                        <div className="what-cvv">? <span><img src={CVVIconHover} alt=""/> <p>The 3 or 4 numbers on the back of your Visa, MasterCard, or AMEX card.</p></span></div>
                    </FormGroup>
                    </Col>
                    <Col md={4} className="">
                        <div className="sm custom-checkbox custom-control mb-2">
                            <input type="checkbox" onChange={this.handleSecondaryEmail} name={'secondaryemail'}  checked={this.state.secondaryemail} className="custom-control-input" id="secondaryemail"/>
                            <label className="custom-control-label" htmlFor="secondaryemail">Secondary email</label>
                        </div>
                        <InputField className="bg__yellow mw_auto" type="text" label="" name="" value={this.props.practiceUserAccountInfo.email_alt}  placeholder="Enter email here"  onChange={(e) => this.changeHandler(e , 'email_alt')}  />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="d-flex justify-content-between align-items-center">
                        <FormGroup className="my-4">
                            <h5 className="m-0">Billing address</h5>
                        </FormGroup>
                        <FormGroup className="my-4">
                            <div className="sm custom-checkbox custom-control ">
                                <input type="checkbox"  disabled={false} name={'sameasmainpractice'} onChange={(event)=>this.changeHandler(event,'sameasmainpractice')} checked={this.state.sameasmainpractice} className="custom-control-input" id="sameasmainpractice"/>
                                <label className="custom-control-label" htmlFor="sameasmainpractice">Same as practice</label>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="">
                    <FormGroup>
                        <div className="d-flex justify-content-between">
                        <Label for="country">{t('form.country')}</Label>
                        </div>
                        <AppDropDown
                            className={`${error && error.errors && error.errors['address.billing.country_id'] ? 'invalid-field' : ''} react-select-container bg__yellow`}
                            classNamePrefix="react-select"
                            options={country}
                            getOptionLabel={ x => `${x.name}`}
                            getOptionValue={ x => `${x.id} ${x.name}`}
                            onChange={(val)=> this.changeHandler(val, 'selectedcountry') }
                            isSearchable={true}
                            placeholder={''}
                            value={this.state.selectedcountry}
                            isDisabled={true}
                        />
                        {error && error.errors && error.errors['address.billing.country_id'] && <span className='text--danger'>{error.errors['address.billing.country_id'][0]}</span>}

                    </FormGroup>
                    </Col>
                    <Col md={6} className="">
                    <FormGroup className="">
                        <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.city')} name="city" value={this.state.city} className={`${error && error.errors && error.errors['address.billing.city'] ? 'invalid-field' : '' } bg__yellow mw_auto`} onChange={(e) => this.changeHandler(e)} />
                        {error && error.errors && error.errors['address.billing.city'] && <span className='text--danger'>{error.errors['address.billing.city'][0]}</span>}
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="">
                        <FormGroup>
                        <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.streetandnumber')} name="address_line_1" value={this.state.address_line_1} className={`${error && error.errors && error.errors['address.billing.address_line_1'] ? 'invalid-field' : ''} bg__yellow mw_auto`}  onChange={(e) => this.changeHandler(e)}  />
                        {error && error.errors && error.errors['address.billing.address_line_1'] && <span className='text--danger'>{error.errors['address.billing.address_line_1'][0]}</span>}
                        </FormGroup>
                    </Col>
                    <Col md={6} className="">
                        <FormGroup>
                        <div className="d-flex justify-content-between">
                            <Label for="state">{t('form.state')}</Label>
                        </div>
                        <AppDropDown
                            className={`${error && error.errors && error.errors['address.billing.state_id'] ? 'invalid-field' : ''} react-select-container react-select__multi bg__yellow`}
                            classNamePrefix="react-select"
                            options={this.state.countryState}
                            getOptionLabel={ x => `${x.name}`}
                            getOptionValue={ x => `${x.id} ${x.name}`}
                            onChange={(val)=> this.changeHandler(val, 'selectedstate') }
                            isSearchable={true}
                            placeholder={''}
                            value={this.state.selectedstate}
                            isDisabled={true}
                        />
                        {error && error.errors && error.errors['address.billing.state_id'] && <span className='text--danger'>{error.errors['address.billing.state_id'][0]}</span>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="">
                        <FormGroup>
                        <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.apartment')} name="address_line_2" value={this.state.address_line_2} className="bg__yellow mw_auto" onChange={(e) => this.changeHandler(e)}  />
                        </FormGroup>
                    </Col>
                    <Col md={6} className="">
                        <FormGroup>
                        <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.postalcode')} name="zip" value={this.state.zip} className={`${error && error.errors && error.errors['address.billing.zip'] ? 'invalid-field' : ''} bg__yellow mw_auto`}  onChange={(e) => this.changeHandler(e)}  />
                        {error && error.errors && error.errors['address.billing.zip'] && <span className='text--danger'>{error.errors['address.billing.zip'][0]}</span>}
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            }
            </div>
            <div style={{paddingRight: '1.4rem'}} className="d-flex  align-items-center justify-content-between review-footer-btn">
                <LoadingButton type="button" disabled={subscribeInfo.isLoading} loading={subscribeInfo.isLoading} onClick={() => this.subscribeNew() }  label="Complete & Subscribe"  size=""  className={`mr-3 col-9 complete-btn`} color="primary"/>
                <Button type="button" size="md" onClick={ this.prevClick }   className="ml-3" outline color="default white">{t('btn.back')}</Button>
            </div>
            </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state, ownProps) => ({
    error: state.task.error,
    getSubscribePriceOption:state.task.getSubscribePriceOption,
    getSubscribePriceCalc:state.task.getSubscribePriceCalc,
    practiceUserAccountInfo:state.task.practiceUserAccountInfo,
    practiceProfileInfo: ownProps.isNewLocation ? state.task.newPracticeProfileInfo :state.task.practiceProfileInfo,
    newlocationinfo:state.task.newlocationinfo,
    subscribeInfo:state.task.subscribeInfo,
    country: state.task.country,
    mainPracticeAddress: state.task.mainPracticeAddress,
    isCorporate:state.task.isCorporate,
    getBillingCardInfo:state.task.getBillingCardInfo,
    practiceInfo:state.task.practiceInfo,
    isUpgradePlan:state.task.isUpgradePlan

});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Review);