import React from 'react'
import Skeleton from "@material-ui/lab/Skeleton";

const ChannelList = () => {
  return (
    <div className='px-20'>
        <div className={`waiting_room_channel__main__lists__item d-flex`}>
            <div className='waiting_room_channel__main__lists__item__image_box'>
                <Skeleton
                    animation="wave"
                    variant="rect"
                    width={333}
                    height={266}
                />
            </div>
            <div className='col waiting_room_channel__main__lists__item__title_box p-0'>
                <ul className='d-flex flex-column h-100'>
                    <div className='d-flex align-items-center justify-content-between align-items-center waiting-header'>
                        <Skeleton
                            animation="wave"
                            variant="rect"
                            width={132}
                            height={15}
                        />
                    </div>
                    <div className='channel-play-list'>
                        <div className='py-3 border--bottom sdf'>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={162}
                                height={21}
                            />
                        </div>
                        <div className='py-3 border--bottom sdf'>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={162}
                                height={21}
                            />
                        </div>
                        <div className='py-3 border--bottom sdf'>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={162}
                                height={21}
                            />
                        </div>
                        <div className='py-3 border--bottom sdf'>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={162}
                                height={21}
                            />
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default ChannelList
