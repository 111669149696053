import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import NumberFormat from 'react-number-format';
import { getCardNameByNo, getCartImageByType } from '../../../data/comman';

import CVVIcon from '../../../img/cvv.png'
import CVVIconHover from '../../../img/cvv-hover.png'
import StripeIcon from '../../../img/stripe-icon.png'
import { Button , Input , Spinner} from 'reactstrap';
import { resetSuccess , clearerror} from "../../../store/actions/taskAction";
import { getCardImageByNo } from '../../../data/comman';
import {AddBillingCard , UpdateBillingCard , changeCardStatus} from "../../../store/actions/taskAction";
import LoadingButton from '../../../components/LoadingButton'

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdate:false,
      isReplace:false,
      isRemove:false,
      isRemoveConfirm:false,
      vardImage:'',
      secondaryCreditCard: {},
      creditCardNumber: "",
      cardHolderName: "",
      viewCardHoldename: "",
      expireDate: "",
      editExpireDate: "",
      code: "",
      formValid:false,
      isError:{
        creditCardNumber: "",
        cardHolderName: "",
        editExpireDate: "",
        code:''
      }
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.onUpdateCard = this.onUpdateCard.bind(this);
    this.onRemoveCard = this.onRemoveCard.bind(this);
    this.onReplaceCard = this.onReplaceCard.bind(this);
    this.onConfirmRemove = this.onConfirmRemove.bind(this);
    
    
  }


  async componentDidMount(){
    await this.props.dispatch(clearerror())
    await this.props.dispatch(resetSuccess())
  }

  onClickUpdateCard(){
    this.props.dispatch(clearerror())
    this.setState({isUpdate:!this.state.isUpdate , editExpireDate:''})
  }

  onShowUpdatedCard(){
     this.props.dispatch(resetSuccess())
     this.setState({isUpdate:!this.state.isUpdate})
  }

  onShowNewCard(){
    this.props.dispatch(resetSuccess())
    this.props.getSecondaryCardInfo()
    this.setState({isReplace:!this.state.isReplace , cardHolderName:'',creditCardNumber:'',editExpireDate:'',code:''})
  }

  onReplaceCard(){
    this.setState({isReplace:!this.state.isReplace})
  }

  onClickRemoveCard(){
    this.setState({isRemove:!this.state.isRemove})
  }

  onRemoveCard(){
    let params ={
      "is_active":0,
    }
    this.props.dispatch(changeCardStatus(params, this.props.secondaryCreditCard.id))
  }

  onConfirmRemove(){
    this.setState({isRemoveConfirm:!this.state.isRemoveConfirm})
  }

  onClickBack(){
    this.props.dispatch(resetSuccess())
    this.props.getSecondaryCardInfo()
    this.setState({isRemoveConfirm:false,isRemove:false})
  }
  
  onUpdateCard(){
     this.props.dispatch(UpdateBillingCard(this.state.editExpireDate , this.props.secondaryCreditCard));
  }

  
 async onAddCard(){
  let params={
      "card": {
          "name": this.state.cardHolderName,
          "number": this.state.creditCardNumber,
          "expiry_month":  parseInt(this.state.editExpireDate.slice(0,2)), 
          "expiry_year": parseInt(this.state.editExpireDate.slice(2,6)), 
          "cvv": this.state.code,
          "is_primary":0
        }
    }
   await this.props.dispatch(AddBillingCard(params));

  }
  
  changeHandler(event, filed){
    var filedName = "", filedValue = "";
    var vardImage = this.state.vardImage;
    if(filed){
        filedName = filed;
        filedValue = event.value;
        if(filedName === "creditCardNumber"){
            vardImage = getCardImageByNo(filedValue);
            console.log(vardImage,'vardImage')
        }
    }else{
        var { name, value } = event.target;
        filedName = name;
        filedValue = value;
    }
    this.setState({
        [filedName]: filedValue,
        vardImage,
        error: {} 
    },()=> {
        this.isValid(filedName , filedValue);
    });
  }

  isValid(name , value){
    let isError = this.state.isError ;

    switch (name) {
      case "creditCardNumber":
          isError.creditCardNumber =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "cardHolderName":
          isError.cardHolderName =  
          value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "editExpireDate":
          isError.editExpireDate =  
          value.length <= 0 ? "required" : "";
          break;
      case "code":
        isError.code =  
        value.length <= 0 ? "required" : "";
        break;
      default:
          break;
    }

    this.setState(this.validateForm)
  }
  
  validateForm(){
    this.setState({formValid: this.state.isError.creditCardNumber === ''  && this.state.isError.cardHolderName === ''  && this.state.isError.editExpireDate ===''});
  }


  render (){
    const secondaryCreditCard = this.props.secondaryCreditCard || null;
    const secondaryexpireDate = this.props.secondaryexpireDate
    const { isSuccess , loadingAddCardInfo , isSuccessRemove , loadingUpdate , loadingcardstatus , error} = this.props
    var imgsrc;
    if(secondaryCreditCard){
       imgsrc = !this.state.isReplace ? getCartImageByType(secondaryCreditCard.brand) : this.state.vardImage
    }else{
       imgsrc =  this.state.vardImage
    }
    return(
        <div className={`${this.state.isRemove ? 'bg_red' : ' '} transparent__form--white billingcard__inner__panel d-flex flex-column`} > {/* for screen 173,174,175 add class bg-danger */}
          <img src={StripeIcon} className="stripe-logo" alt=""/>
          <div className="billingcard__inner__panel__header">
          {this.state.isRemove ?
               <h4>Credit card removal</h4>
            :  <h4>Credit card</h4> 
            }
            
            
            {!secondaryCreditCard === null ?
            <p>Secondary credit cards are charged if a primary card is declined. Adding a Visa, MasterCard, Amex or Discover as a secondary card will replace any previously stored secondary credit card.</p>
            :
            <>
              {this.state.isRemove ?
              <p>Secondary credit cards are optional, and can be replaced or  removed at any moment at your own discretion.</p>
              :
              <p>This is your secondary credit card. If the primary credit card is declined for any reason, this card will be charged instead.</p>
              }
            </>
            }
            {secondaryCreditCard !== null &&
              <div className="card-type">
              {!this.state.isReplace?
                <>
                  <img src={getCartImageByType(secondaryCreditCard.brand)} className="" alt="" />
                  <p>{secondaryCreditCard.brand}</p>
                </>
              : null }
              </div>
            }
          </div>
          {/* {loadingBillingCardInfo ?
            <div className=" flex-grow-1 flex-column d-flex  align-items-center justify-content-center">
              <Spinner color="primary" />
            </div>
          : */}
          <>
          {isSuccess ?
            <>
            {this.state.isReplace ?
              <>
              <div className="billingcard__inner__panel__body cc-confirmation">
                <p>Secondary credit card successfully added. </p>
              </div>
              <div className="billingcard__inner__panel__footer mt-auto">
                <div className=""><Button className="w-100" onClick={() => this.onShowNewCard()}  color="primary">Show new credit card</Button></div>
              </div>
              </>
            :  
              <>
              <div className="billingcard__inner__panel__body cc-confirmation">
                <p>Primary credit card successfully updated.</p>
              </div>
              <div className="billingcard__inner__panel__footer mt-auto">
                <div className=""><Button className="w-100" onClick={() => this.onShowUpdatedCard()}  color="primary">Show updated credit card</Button></div>
              </div>
              </>
            } 
            </>
          :
            <>
            {secondaryCreditCard !== null || this.state.isReplace?
              <>
                {this.state.isRemoveConfirm ?
                <>
                  {isSuccessRemove ?
                  <>
                    <div className="billingcard__inner__panel__body remove-conform succesfully-removed  p-5">
                        <p>Secondary credit card successfully removed.</p>
                    </div>
                    <div className="billingcard__inner__panel__footer back-btn mt-auto">
                      <div className="row">
                        <div className="col-12"><Button className="w-100" onClick={() => this.onClickBack()}  color="white">Back</Button></div>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="billingcard__inner__panel__body remove-conform confirm-remove  p-5">
                        <p>Not having a secondary card added will require further actions from an authorized user in case of a declined payment on your primary credit card. Would you like to proceed?</p>
                    </div>
                    <div className="billingcard__inner__panel__footer mt-auto">
                      <div className="row">
                        <div className="col-6">
                          <LoadingButton type="button" label={`Yes, Remove card`} loading={loadingcardstatus} disabled={loadingcardstatus} size="md" onClick={this.onRemoveCard} className={``} color="default"/>
                        </div>
                        <div className="col-6"><Button size="md" onClick={() => this.onConfirmRemove()} outline color="default white">Cancel</Button></div>
                      </div>
                    </div>
                  </>
                  }
                </>
                :
                <>
                  <div className={`${secondaryCreditCard ? secondaryCreditCard.brand:''}-bg billingcard__inner__panel__body`}> {/* mastercard-bg , visacard-bg , amexcard-bg , discovercard-bg*/}
                    <div className="row">
                      <div className="col-12">
                      <div className={`${error && error.errors && error.errors['card.number'] ? 'inputerror' : ''} form-group  icon-card `}>
                            <div className={`d-flex justify-content-between `}>
                                <label>Credit card number</label>
                                {error && error.errors && error.errors['card.number'] && <span className='text--danger'>{error.errors['card.number'][0]}</span>}
                            </div>
                            {this.state.isReplace ?
                            <NumberFormat className="form-control" format="#### #### #### ####" name="creditCardNumber" value={this.state.creditCardNumber} onValueChange={(value)=>this.changeHandler(value, "creditCardNumber")}/>
                            :
                            <Input type="text" disabled value={`xxxx xxxx xxxx ${secondaryCreditCard.last4}`}/>
                            }
                            {imgsrc ? <img src={imgsrc}  className="icon-img" alt="" /> : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Name on card</label>
                          {!this.state.isReplace ? 
                              <Input type="text" name="cardHolderName" value={secondaryCreditCard.name} disabled/>
                            :
                              <Input type="text" name="cardHolderName" onChange={this.changeHandler} value={this.state.cardHolderName} />
                          }
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-0">
                          <label>Expiry date</label>
                          {this.state.isReplace || this.state.isUpdate?
                            <NumberFormat  className="form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="editExpireDate" onValueChange={(value)=>this.changeHandler(value, "editExpireDate")} value={this.state.editExpireDate}/>
                          :
                            <NumberFormat  className="form-control"  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="expireDate" disabled value={secondaryexpireDate}/>
                          }
                          
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group  mb-0 cvv-img">
                          <label>Security code <img src={CVVIcon} alt="" /> </label>
                          <NumberFormat  className="form-control"  format={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "####" : "###"} placeholder={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "XXXX" : "XXX"} name="code" onValueChange={(value)=>this.changeHandler(value, "code")}/>
                          <div className="what-cvv">? <span><img src={CVVIconHover} alt=""/> <p>The 3 or 4 numbers on the back of your Visa, MasterCard, or AMEX card.</p></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="billingcard__inner__panel__footer mt-auto">
                    {this.state.isUpdate &&
                      <div className="row">
                        <div className="col-6">
                          {/* <Button disabled={!this.state.formValid} size="md" onClick={this.onUpdateCard}  color="primary">Update card</Button> */}
                          <LoadingButton type="button" label={`Update card`} loading={loadingUpdate} disabled={!this.state.formValid || loadingUpdate} size="md" onClick={this.onUpdateCard} className={``} color="primary"/>
                        </div>
                        <div className="col-6"><Button size="md" onClick={() => this.onClickUpdateCard()} outline color="default white">Cancel</Button></div>
                      </div>
                    }
                    {this.state.isRemove &&
                      <div className="row">
                        <div className="col-6"><Button size="md" onClick={this.onConfirmRemove}  color="default">Remove card</Button></div>
                        <div className="col-6"><Button size="md" onClick={() => this.onClickRemoveCard()} outline color="default white">Cancel</Button></div>
                      </div>
                    }
                      <>
                      {this.state.isReplace &&
                        <div className="row">
                          <div className="col-6">
                            <LoadingButton type="button"  label={'Add Card'} loading={loadingAddCardInfo} disabled={!this.state.formValid || loadingAddCardInfo} size="xxl" onClick={() => this.onAddCard()} color="primary"/>
                          </div>
                          <div className="col-6"><Button size="md" onClick={() => this.onReplaceCard()} outline color="default white">Cancel</Button></div>
                        </div>
                      }

                      {!this.state.isUpdate && !this.state.isRemove && !this.state.isReplace ?
                      <div className="row">
                        <div className="col-6"><Button size="md" onClick={() => this.onReplaceCard()} color="primary">Replace card</Button></div>
                        <div className="col-3"><Button size="md" onClick={() => this.onClickUpdateCard()}  outline color="default white">Update</Button></div>
                        <div className="col-3"><Button size="md" onClick={() => this.onClickRemoveCard()}  color="red ">Remove</Button></div>
                      </div>
                      :
                      null
                      }
                      </>
                  </div>
                </>
                }
              </>
            :
            <>
              <div className="billingcard__inner__panel__body secondary-cc-add p-5">
                  <p>Add a secondary credit card <br/>to see it displayed here.</p>
              </div>
              <div className="billingcard__inner__panel__footer mt-auto">
                <Button className="w-100" onClick={() => this.onReplaceCard()}  color="primary">Add card</Button>
              </div>
            </>
            }
            </>
          } 
          </> 
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingAddCardInfo:state.task.loadingAddCardInfo,
  loadingBillingCardInfo:state.task.loadingBillingCardInfo,
  loadingUpdate:state.task.loadingUpdate,
  loadingcardstatus:state.task.loadingcardstatus,
  isUpdateSuccess:state.task.isUpdateSuccess,
  isSuccess:state.task.isSuccess,
  isSuccessRemove:state.task.isSuccessRemove,
  error:state.task.error
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
