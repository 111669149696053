import React from 'react';

import { Button , Label , FormGroup, Form , Col , Row , CustomInput , ListGroup , ListGroupItem,InputGroup,InputGroupAddon ,InputGroupText, UncontrolledTooltip,} from 'reactstrap';
import DropDown from '../../../components/DropDown'
import { InputField } from '../../../components/InputField';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { count} from '../../../data/comman';
import Que from '../../../img/QueIcon.svg'
import {ReactComponent as Clear} from '../../../img/Close_small.svg';

import { GetSubscribeInfo, SaveSubscribeCalc , SaveSubscribeCalcRequest, showError } from "../../../store/actions/taskAction";
import Loading from '../../../components/Loading';


class Subscribe extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      addtionalDomainDropDown:[ ],
      addtionalDeviceDropDown:[] , 
      selecteddomain:{
        count: 0, value: 0
      },
      selecteddevices:{
        count: 0, value: 0
      },
      hardwares:[],
      selectedhardware:{
        value: 0, label: '0' 
      },
      selected_with_warranty:false,
      promo_code:'',
      selectedPlan:{},
      selectedPlanId:1,
      selectedSubscriptionType: 'month'
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
  }

  nextClick(){
    var error = {errors: []};
    if((this.props?.isUpgradePlan || this.props.practiceInfo?.is_paid_account === 1) && !this.props?.isNewLocation){
      console.log(this.props.getSubscribeinfo?.subscription_plan?.plan_id,'=>',this.props.selectedPlan.id,'=>',this.props.getSubscribePriceCalc.subscription.plan_id)
      if((this.props.getSubscribePriceCalc.subscription.plan_id === this.props.getSubscribeinfo?.subscription_plan?.plan_id)){
          error['errors']['selectedPlan'] = ["Please select different plan"];
          this.props.dispatch(showError(error));
      }else{
        this.props.nextStep();
      }
    }else{
      this.props.nextStep();
    }
  }

  prevClick(){
    this.props.prevStep();
  }

  async onEditSaveSubscribeCalc(field , value , index){
    var getSubscribePriceCalc = this.props.getSubscribePriceCalc
   if(this.props.selectedPlan.id === this.props.getSubscribeinfo?.subscription_plan?.plan_id){
      getSubscribePriceCalc = {
        ...this.props.getSubscribePriceCalc,
        subscription: {
          ...this.props.getSubscribePriceCalc.subscription,
          ...this.props.getSubscribeinfo?.subscription_plan
        }
      }
    } 
    var freeHardware = getSubscribePriceCalc?.free_hardwares?.detail?.lenght > 0 ? getSubscribePriceCalc.free_hardwares : [];
    let hardwares = {
      ...getSubscribePriceCalc.hardwares, ...freeHardware
    }
    hardwares = hardwares.detail;
    if(field === 'subscription'){
        getSubscribePriceCalc[field].plan_id = value.id
        this.props.updateSelectedPlan(value)
    }else if(field === "selectedSubscriptionType"){
      this.setState({selectedSubscriptionType:value })
    }
    else if(field === 'additional_domains'){
      getSubscribePriceCalc[field].count = value.count
      await  this.props.updateDomainQty(value)

    }else if(field === 'additional_devices'){
      getSubscribePriceCalc[field].count = value.count

      await this.props.updateDevicesQty(value)

      
    }else if(field === 'hardwares'){
     
      let hardwareIndex = hardwares.findIndex(x=>x.id === this.props.getSubscribePriceOption.hardware[index].hardware.hardware_id)
      console.log(hardwares,'hardwares hardwares', hardwareIndex, 'hardwareIndex')

      if(hardwareIndex === -1){
        hardwares.push({
          hardware_id: this.props.getSubscribePriceOption.hardware[index].hardware.id,
          qty:value.value,
          with_warranty:this.props.selected_with_warranty ? 1 : 0,

        })
        var selectedhardware = count.find(b => b.value === value.value)
        await this.props.updateHardwareQty(selectedhardware)
      }else{
        hardwares[index].qty=value.value
        hardwares[index].with_warranty=this.props.selected_with_warranty ? 1 : 0
       

        var selectedhardware = count.find(b => b.value === value.value)
        await this.props.updateHardwareQty(selectedhardware)

      }
      getSubscribePriceCalc[field].detail['id'] = this.props.getSubscribePriceOption.hardware[index].hardware.hardware_id
      getSubscribePriceCalc[field].detail =  hardwares

      
    }else if(field === 'with_warranty'){
      await this.props.updateSelectedWithWarranty(value)
      let hardwareIndex = hardwares.findIndex(x=>x.id === this.props.getSubscribePriceOption.hardware[index].hardware.hardware_id)
      console.log(hardwares,'hardwares with_warranty',hardwareIndex,'hardwareIndex')
      
      if(hardwareIndex === -1){
        hardwares.push({
          hardware_id: this.props.getSubscribePriceOption.hardware[index].hardware.id,
          qty:this.props.selectedhardware.value,
          with_warranty:value ? 1 : 0,
        })
      }else{
        hardwares[index].with_warranty=value ? 1 : 0
      }
      getSubscribePriceCalc['hardwares'].detail =  hardwares
    }else if(field === 'promo_code'){
     
     await this.props.updatePromoCode(value)

    }
    else{
      getSubscribePriceCalc[field] = value
    }


    hardwares.map(x => {
      console.log(x,'qty', this.props.selectedhardware.value , x.free_hardware === 1 && x.qty <= 0)
      x.id = x.hardware_id;
      x.qty = (this.props.selectedhardware.value == 0 &&  x.with_warranty == 1) ? 0 : this.props.selectedhardware.value;
      return x;
    })
    // if(hardwares.lenght === 0){}
   await this.props.updatehardware(hardwares)
    let params ={
      "plan_id":getSubscribePriceCalc.subscription.plan_id,
      "subscription_type": this.state.selectedSubscriptionType,
      "additional_domains_count":this.props.selecteddomain.count,
      "additional_devices_count":this.props.selecteddevices.count,
      "hardwares": this.props.hardwares,
      "promo_code":this.props.promo_code,
    }
    if(this.props.practiceInfo.is_paid_account === 1){
      params['is_paid_account'] = 1
    }
    if(this.props.isNewLocation){
      params['add_manage_location'] = true
    }
    await this.props.dispatch(SaveSubscribeCalcRequest(getSubscribePriceCalc))
    await this.props.dispatch(SaveSubscribeCalc(params, (this.props.selectedPlan.id === this.props.getSubscribeinfo?.subscription_plan?.plan_id && this.props.practiceInfo.is_paid_account === 1 && this.props.isNewLocation !== true )))
    
  }

async componentDidMount(){

    if(!this.props.isNexttwo){
      
      await this.props.dispatch(GetSubscribeInfo());
    
      if(this.props.getSubscribePriceOption){
        var selectedPlan = this.props.getSubscribePriceOption.plans.find(b => b.id === this.props.getSubscribeinfo?.subscription_plan?.plan_id);
        this.props.updateSelectedPlan(selectedPlan);
      }
    }

    if(this.props.practiceInfo.is_paid_account === 1){

      var selectedSubscriptionType = this.props.getSubscribeinfo?.subscription_plan?.payment_cycle_key
      this.setState({ selectedSubscriptionType })

    }

    let params ={
        "plan_id": this.props.selectedPlan.id,
        "subscription_type": this.props.getSubscribeinfo?.subscription_plan?.payment_cycle_key === 'trial' ? 'month' : this.props.getSubscribeinfo?.subscription_plan?.payment_cycle_key,
        "additional_domains_count": 0,
        "additional_devices_count": 0,
        "hardwares": [
        
        ]
    }

    if(this.props.practiceInfo.is_paid_account === 1){
      params['is_paid_account'] = 1
    }

    if(this.props.isNewLocation){
      params['add_manage_location'] = true
    }

    if(!this.props.isNexttwo){
      await this.props.dispatch(SaveSubscribeCalc(params, (this.props.selectedPlan.id === this.props.getSubscribeinfo?.subscription_plan?.plan_id && this.props.practiceInfo.is_paid_account === 1 )));
    }
     var addtionalDomainDropDown = [];
     var addtionalDeviceDropDown = [];


 
    Object.keys(this.props.getSubscribePriceOption.domain).map((sub , index)=>{
       var subScriptinKey = this.props.getSubscribePriceOption.domain[sub];
       var count = subScriptinKey.count;

       if(index === 0){
          addtionalDomainDropDown.push({
              count: 0,
              value: 0
          })
       }
    
       addtionalDomainDropDown.push({
           ...subScriptinKey,
           label: `${count} Domains`,
           value: count
       })
  
    });



    Object.keys(this.props.getSubscribePriceOption.device).map((sub,index)=>{
      var subScriptinKey = this.props.getSubscribePriceOption.device[sub];
      var count = subScriptinKey.count;

      if(index === 0){
        addtionalDeviceDropDown.push({
            count: 0,
            value: 0
        })
    }

      addtionalDeviceDropDown.push({
          ...subScriptinKey,
          label: `${count} Device `,
          value: count
      })
    });


    await this.setState({ addtionalDomainDropDown , addtionalDeviceDropDown  })

  }

  render (){
    const {t, getSubscribePriceOption , getSubscribePriceCalc , practiceProfileInfo,loadingcalc} = this.props
    var subscription = getSubscribePriceOption['subscription'];
    var { plans } = getSubscribePriceOption
    var currency_symbol =  getSubscribePriceOption.currency.symbol
    return(
        <div className={`${(!this.props?.isUpgradePlan) ? 'mw-1270' : 'mw-849' } transparent__form--white d-flex flex-column align-items-stretch mb-auto`}>
          {loadingcalc &&
            <Loading />
          }
          <Form className="mt-2 d-flex flex-column">
            <div className="inner_white_box mb-3">
              <Row>
                <Col  className="">
                  <FormGroup className="mb-4 d-flex justify-content-between">
                    <h4 className="mb-5">Subscription</h4>
                  </FormGroup>
                </Col>
                {(!this.props?.isUpgradePlan)  &&
                  <Col md={5} className="">
                    <FormGroup className="mb-4 d-flex justify-content-between">
                      <h4 className="mb-5">Hardware & Addons</h4>
                    </FormGroup>
                  </Col>
                }
              </Row>
              <Row>
                <Col  className={(!this.props?.isUpgradePlan) ? 'pr-5 border-right' : "" }>
                  <Row>
                    <Col md={12} className="d-flex">
                        <div className="col-2">
                          <Label className="">Suite</Label>
                        </div>
                        <div className='w-100'>
                        {
                          plans?.map((plan, index)=>{
                            const {plugins} = plan
                            const MonthlyPrice =plan.plan_payment_cycles.find(b => b.cycle.cycle_key === this.state.selectedSubscriptionType);
                            return <FormGroup key={index} className="mb-4 d-flex justify-content-between">
                                <div className="">
                                    <div className=" custom-checkbox custom-control ">
                                        <input type="radio" name={`plan_list`} onChange={(event)=>this.onEditSaveSubscribeCalc('subscription' , plan )} checked={getSubscribePriceCalc.subscription && getSubscribePriceCalc.subscription.plan_id === plan.id} className="custom-control-input" id={`plan_${plan.id}`}/>
                                        <label className="custom-control-label" htmlFor={`plan_${plan.id}`}>
                                          <div className='suite_title'>{plan.name}</div>
                                          { plan.id !== 1 ? plugins?.map((plugin, i)=>{
                                              return <div key={i} className='suite_sub_title'>{plugin.name}</div>
                                            }):
                                            <div className='suite_sub_title'>All Thalamus Solutions</div>
                                          }
                                          
                                        </label>
                                    </div>
                                </div>
                                <div className='suite_price'>
                                    <strike>{`${currency_symbol}${MonthlyPrice?.subscription_fee_discount_less_price}`}</strike>
                                    <div>{`${currency_symbol}${MonthlyPrice?.subscription_fee.toFixed(2)}`}</div>
                                </div>
                              </FormGroup>
                          })
                        }
                        </div>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col md={12} >
                      <ListGroup flush>
                        <ListGroupItem className="px-0 py-5 align-items-center d-flex justify-content-between">
                            <div className="d-flex col-12 p-0 align-items-center">
                              <div className="col-2 p-0">
                                <Label className="">Billing Cycle</Label>
                              </div>
                              {
                                this.props.selectedPlan && this.props.selectedPlan.plan_payment_cycles && this.props.selectedPlan.plan_payment_cycles.map((sub, index)=>{
                                  // console.log(sub,'sub')
                                  return <div key={index} className={`col-2 p-0 ${(this.props?.isUpgradePlan) || (this.props.practiceInfo?.is_paid_account === 1 && (!this.props?.isNewLocation)) && (this.state.selectedSubscriptionType !== sub.cycle.cycle_key) ? 'disabled_plan' : ''}`}>
                                  <div className="custom-checkbox">
                                    <input type="checkbox" name={`${index}`} 
                                      disabled={(this.props?.isUpgradePlan || this.props.practiceInfo?.is_paid_account === 1) && (!this.props?.isNewLocation) }
                                      checked={this.state.selectedSubscriptionType === sub.cycle.cycle_key} 
                                      onChange={(event)=>this.onEditSaveSubscribeCalc('selectedSubscriptionType' , sub.cycle.cycle_key )} 
                                      className="custom-control-input"  id={`plan__${index}`}/>
                                      <label className="custom-control-label" htmlFor={`plan__${index}`}>
                                        {sub.cycle.name}
                                        {/* {subscription[sub].title} {subscription[sub].discount > 0 && <span className="discount">{`${subscription[sub].discount }% Off`}</span>} */}
                                      </label>
                                  </div>
                                </div>
                                })
                              }
                              <Button type='button'  color='help-icon' id="help_tooltip">?</Button>
                              <UncontrolledTooltip  trigger={'hover'}  placement="bottom"  target="help_tooltip" className="help_tooltip">
                                To change the Billing Cycle please contact Thalamus via email support@thalamusmedia.com
                              </UncontrolledTooltip>
                            </div>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} >
                      <ListGroup flush>
                        <ListGroupItem className="px-0 py-5 align-items-center  d-flex justify-content-between">
                          { getSubscribePriceOption.hardware.length > 0 ?
                          <Label className="m-0">Setup fee {getSubscribePriceCalc.promo_code_info && getSubscribePriceCalc?.promo_code_info?.device_chromecast === "N" ? '' : "(1x Google TV included)"}</Label>
                          :
                          <Label className="m-0">Setup fee</Label>
                          }
                          <Label className="m-0">
                            <div>{`${currency_symbol}${(getSubscribePriceCalc?.setup_price || 0).toFixed(2)}`}</div>
                          </Label>
                        </ListGroupItem>
                        {(!this.props?.isUpgradePlan)  &&
                        <ListGroupItem className="px-0 py-4 align-items-center align-items-center d-flex justify-content-between">
                          <div className="d-flex  align-items-center">
                            <Label className="mr-5 m-0 text-nowrap">Promo code</Label>
                            <div className='d-flex flex-column'>
                              {getSubscribePriceCalc.promo_code_message != '' && <span className={`${getSubscribePriceCalc && getSubscribePriceCalc.promo_code_info && getSubscribePriceCalc.promo_code_info.code && getSubscribePriceCalc.promo_code_info.code ? 'text-success' : 'text--danger'}`}>{getSubscribePriceCalc.promo_code_message}</span>}
                              <div className='promo_code_section'>
                              <InputField type="text" onChange={(e)=>this.onEditSaveSubscribeCalc( 'promo_code' , e.target.value)} value={this.props.promo_code || ''}  className="col-12" name="promocode"  />
                              {getSubscribePriceCalc.promo_code_message != '' ? <Button onClick={()=> this.onEditSaveSubscribeCalc( 'promo_code' , '')  } className='p-0 promo_code_clear_btn' type='button' color='link primary'><Clear /></Button> : '' }
                              </div>
                            </div>
                          </div>
                          <Label className="m-0">-{currency_symbol}{getSubscribePriceCalc?.total?.discount || '0.00'}</Label>
                        </ListGroupItem>
                        }
                      </ListGroup>
                    </Col>
                  </Row>
                </Col>
                {console.log(this.props?.isUpgradePlan,'this.props?.isUpgradePlan')}
                {(!this.props?.isUpgradePlan ) &&
                <Col md={5} className="pl-5">
                  {/* <FormGroup>
                    <h4 className="m-0">Hardware & Addons</h4>
                  </FormGroup> */}
                  {getSubscribePriceOption.hardware.length > 0 &&
                    <FormGroup>
                      <p>Android TV device (Optional)</p>
                    </FormGroup>
                  }
                  { getSubscribePriceOption.hardware && getSubscribePriceOption.hardware.map((hardware , index)=>{
                    return  <Row key={index}>
                              <Col md={12} >
                                <ListGroup className="mb-20">
                                  <ListGroupItem className="py-3 border-bottom-0 bg_gray align-items-center d-flex justify-content-between">
                                    <Label className="m-0 col-6 p-0">{hardware.hardware.name}</Label>
                                    <DropDown 
                                        className='react-select-container w-77 bg_white'
                                        classNamePrefix="react-select"
                                        options={count}
                                        getOptionLabel={ x => `${x.label}`}
                                        getOptionValue={ x => `${x.value} ${x.label}`}
                                        onChange={(val)=> this.onEditSaveSubscribeCalc('hardwares', val, index) }
                                        isSearchable={true}
                                        placeholder={''}
                                        value={this.props.selectedhardware}
                                    />
                                    {/* this.state.selectedhardware.value > 0 ? hardware.price : */}
                                    {/* getSubscribePriceCalc.hardwares && getSubscribePriceCalc.hardwares.total */}
                                    <Label className="m-0">{`${currency_symbol}${getSubscribePriceCalc.hardwares && (getSubscribePriceCalc?.hardwares?.detail[index]?.price *  this.props.selectedhardware.value) || '0.00'}`}</Label>
                                    <Button type="button" color="link" className="p-0"><img src={Que} alt=""/></Button>
                                  </ListGroupItem>
                                </ListGroup>
                              </Col>
                              <Col md={12} className="d-flex d-flex justify-content-between mb-5">
                                    <div className="mw-300">
                                      <div className="custom-checkbox">
                                        <input type="checkbox" name={`${hardware.hardware_id}`}   disabled={this.props.buttonLoading} checked={this.props.selected_with_warranty ? true : false} className="custom-control-input" onChange={(event)=>this.onEditSaveSubscribeCalc('with_warranty' , event.target.checked , index )}  id={`hardware__${index}`} />
                                        <label className="custom-control-label" htmlFor={`hardware__${index}`}>{`Add extended warranty (1 year total)`}</label>
                                      </div>
                                    </div>
                                    <Label className="m-0">{`${currency_symbol}${(((getSubscribePriceCalc?.hardwares?.detail[index]?.total_qty_warranty_price ? getSubscribePriceCalc?.hardwares?.detail[index]?.total_qty_warranty_price : 0 ) + (getSubscribePriceCalc?.free_hardwares?.total || 0))).toFixed(2) }`}</Label>
                                    <div className="p-1"></div>
                              </Col>
                            </Row>
                  })}
                <Row>
                  <Col md={12} >
                    <ListGroup className="mb-20">
                        <ListGroupItem className="py-3 bg_gray border-0 align-items-center d-flex justify-content-between">
                          <Label className="m-0 col-6 p-0">Additional authorized domains</Label>
                          <DropDown 
                              className='react-select-container w-77 bg_white'
                              classNamePrefix="react-select"
                              options={this.state.addtionalDomainDropDown}
                              getOptionLabel={ x => `${x.count}`}
                              getOptionValue={ x => `${x.count} ${x.count}`}
                              onChange={(val)=>this.onEditSaveSubscribeCalc('additional_domains' , val )}
                              isSearchable={true}
                              placeholder={''}
                              value={this.props.selecteddomain}
                          />
                          <Label className="m-0">{`${currency_symbol}${getSubscribePriceCalc.additional_domains && getSubscribePriceCalc.additional_domains.base_price.toFixed(2) || '0.00'}`}</Label>
                          <Button type="button" color="link" className="p-0"><img src={Que} alt=""/></Button>
                        </ListGroupItem>
                    </ListGroup>
                  </Col>
                  <Col md={12} >
                    <ListGroup className="mb-20">
                      <ListGroupItem className="py-3 bg_gray border-0 align-items-center d-flex justify-content-between">
                          <Label className="m-0 col-6 p-0">Additional authorized devices</Label>
                          {/* {console.log(this.state.addtionalDeviceDropDown,'this.state.addtionalDeviceDropDown')} */}
                          <DropDown 
                              className='react-select-container w-77 bg_white'
                              classNamePrefix="react-select"
                              options={this.state.addtionalDeviceDropDown}
                              getOptionLabel={ x => `${x.count}`}
                              getOptionValue={ x => `${x.count} ${x.count}`}
                              onChange={(val)=>this.onEditSaveSubscribeCalc('additional_devices' , val )}
                              isSearchable={true}
                              placeholder={''}
                              value={this.props.selecteddevices}
                          />
                          <Label className="m-0">{`${currency_symbol}${getSubscribePriceCalc.additional_devices && getSubscribePriceCalc.additional_devices.base_price.toFixed(2) || '0.00'}`}</Label>
                          <Button type="button" color="link" className="p-0"><img src={Que} alt=""/></Button>
                        </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
                </Col>
                }
              </Row>
            </div>
            <div className="inner_white_box mb-20 mh-335 p-5">
            <Row>
              <Col className={(!this.props?.isUpgradePlan) ? 'pr-5 border-right text-right' : "text-right" }>
                <h4 className='my-4'>Subscription Total: {`${currency_symbol}${((getSubscribePriceCalc?.subscription?.base_price || 0) +  (getSubscribePriceCalc?.setup_price || 0)).toFixed(2)}`}</h4>
              </Col>
              {(!this.props?.isUpgradePlan) &&
                <Col md={5} className="text-right">
                  <h4 className='my-4'>Hardware & Add-ons Total: {`${currency_symbol}${getSubscribePriceCalc.hardwares && (getSubscribePriceCalc.hardwares.total + getSubscribePriceCalc.additional_domains.base_price + getSubscribePriceCalc.additional_devices.base_price + (getSubscribePriceCalc?.free_hardwares?.total || 0)).toFixed(2)}`}</h4>
                </Col>
              }
            </Row>
            </div>
            <div className="mt-auto d-flex justify-content-center">
              <Button type="button" size="md" onClick={ this.nextClick }  className="mr-3" color="primary">{t('btn.next')}</Button>
              <Button type="button" size="md" onClick={ this.prevClick }  className="ml-3" outline color="default white">{t('btn.back')}</Button>
            </div>
          </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state, ownProps) => ({
  media_language:state.task.media_language,
  getSubscribePriceOption:state.task.getSubscribePriceOption,
  getSubscribePriceCalc:state.task.getSubscribePriceCalc,
  newlocationinfo:state.task.newlocationinfo,
  practiceProfileInfo: ownProps.isNewLocation ? state.task.newPracticeProfileInfo :state.task.practiceProfileInfo,
  buttonLoading:state.task.buttonLoading,
  getSubscribeinfo:state.task.getSubscribeinfo,
  practiceInfo:state.task.practiceInfo,
  loadingcalc:state.task.loadingcalc,
  isCorporate:state.task.isCorporate,
  isUpgradePlan:state.task.isUpgradePlan


});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Subscribe);