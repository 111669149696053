import React, { useState } from 'react'
import { history } from '../../pages/index';
import Skeleton from "@material-ui/lab/Skeleton";



const WebsiteVideoImageSkeleton = ({ src = '' , alt = ''}) => {
    const [imageLoaded, setImageLoaded] = useState(false);


    const isImageLoaded = (val) =>{
        setImageLoaded(val)
    }

  return (
      <>
          <Skeleton
              animation="wave"
              variant="rectangular"
              width={344}
              height={200}
          />
      </>
  )
}

export default WebsiteVideoImageSkeleton
