import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import DropDown from '../../components/DropDown'
import { InputField } from '../../components/InputField';
import {DateDDMMMYYY} from '../../utilities/HttpUtility';
import IconsArrowDown from '../../img/IconDownArrow.svg'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import {GetNotification } from "../../store/actions/taskAction";
import { Button , Spinner , FormGroup , Label , Row ,Col} from 'reactstrap';
import CollapsiblePanel from "../../components/CollapsiblePanel/CollapsiblePanel";
import moment from 'moment';

import './style.scss';
const FilterByShow = [
  { value: 0, label: 'Oldest first' },
  { value: 1, label: 'Newest first' },
]
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage:1,
      per_page:5,
      totelPage:0,
      expanded: false,
      showMoreArrow:false,
      selectedShowby:1,
      currentDate: moment(),
      month: moment().format('MMMM'),
      years: moment().format('YYYY'),
      minDate:'',
      maxDate:'',
      btnPre:true
    }
    this.handlechangePage = this.handlechangePage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.nextYear = this.nextYear.bind(this);
    this.preYear = this.preYear.bind(this);
    
  }
  
  getStatus(id){
    switch(id){
        case 0: 
            return  ["color-yellow", "Pending"];
        case 1:
            return  ["color-green", "Subscribed"];
        case 2:
            return  ['color-red', "Expired offer"]; 
        default:
          return    ['color-yellow', "Pending"];
    }
  }

  handlechangePage(e, index) {
    index = index + 1
   
    this.setState({currentPage: index},() => {
      let params = {
          page: this.state.currentPage,
          per_page:this.state.per_page
      }
      this.props.dispatch(GetNotification(params))    
    });
  }

 async handleChange(field, options){
    var name = "";
    var value = "";

    if(field === "showby"){
      name = field;
      value =  options;
    }else{
      name = field.target.name;
      value = field.target.value;
    }

    await this.setState({
      selectedShowby:value
    });
    let params ={
      latest:this.state.selectedShowby.value
    }
    await this.props.dispatch(GetNotification(params))
  }

  YEARS () {
    const years = []
    const dateStart = moment()
    const dateEnd = moment().add(10, 'y')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push(dateStart.format('YYYY'))
      dateStart.add(1, 'year')
    }
    return years
   }

  async componentDidMount(){
  
    if(this.props.getnotificationlist){
      var selectedShowby =  FilterByShow.find(b => b.value === this.state.selectedShowby)
      this.setState({ selectedShowby})
    }
    
    let params = {
        page: this.state.currentPage,
        per_page:this.state.per_page,
        latest:this.state.selectedShowby
    }
   await this.props.dispatch(GetNotification(params))    

   let moments = this.props.getnotificationlist.data.map(d => moment(d.publish_date)),
   maxDate = moment.max(moments),
   minDate = moment.min(moments)
  
   await this.setState({totelPage:this.props.getnotificationlist.last_page , minDate , maxDate})
    if(moment(this.state.currentDate).isSame(moment(this.state.maxDate), 'year')){
      await  this.setState({btnNext:false})
    }else{
      await this.setState({btnNext:true})
    }
  }

  expandedText(event, id) {
    this.setState({
      expanded: !this.state.expanded
    });       
  }

  getMoreTextDiv(text) {
    if (this.state.expanded) {
      return text;
    } else {
      return text.substring(0, 98);
    }
  }

  filterByInput(e){
    let input = e.target.value;
    let params ={
      keyword:input,
      per_page:this.state.per_page
    }
    this.props.dispatch(GetNotification(params))
 }

  async nextYear(){
    let date = this.state.currentDate;
    date.add(1, 'year');
    this.setState({
        currentDate: date,
        month: date.format('MMMM'),
        years: date.format('YYYY')
    });

    console.log(moment(this.state.currentDate).format('Y-m-d'),'this.state.currentDate')



    if(moment(this.state.currentDate).isSame(moment(this.state.maxDate), 'year')){
      await  this.setState({btnPre:true})
      await this.setState({btnNext:false})
    }else{
      await this.setState({btnPre:false})
      await this.setState({btnNext:true})
    }

    let params = {
      page: this.state.currentPage,
      per_page:this.state.per_page,
      start_date:moment(this.state.minDate).format('Y-MM-D') ,
      end_date:moment(this.state.currentDate).format('Y-MM-D') 
    }
    await this.props.dispatch(GetNotification(params)) 
 }

 
async preYear(){
      let date = this.state.currentDate;
      date.add(-1, 'year');
      this.setState({
          currentDate: date,
          month: date.format('MMMM'),
          years: date.format('YYYY')
      });

      if(moment(this.state.currentDate).isSame(moment(this.state.minDate), 'year')){
        await  this.setState({btnPre:false})
        await this.setState({btnNext:true})
      }else{
        await this.setState({btnPre:true})
        await this.setState({btnNext:false})
      }

      let params = {
        page: this.state.currentPage,
        per_page:this.state.per_page,
        start_date:moment(this.state.minDate).format('Y-MM-D') ,
        end_date:moment(this.state.currentDate).format('Y-MM-D') 
      }
      await this.props.dispatch(GetNotification(params)) 
}

  render (){
    const {t ,loadingnotification, getnotificationlist , error , collapse} = this.props
    // var Year = moment().year()
    return(
      <div className="main__screen notification__page  d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Notification Messages"/>
            <div className="notification__inner mb-auto d-flex flex-column">
                <div className="notification__inner__header">
                  <div className="row">
                    <div className="col-7">
                      <h4>Notification messages</h4>
                    </div>
                    <div className="col-5  justify-content-end d-flex left-right-arrow align-items-center">
                      <Button onClick={this.preYear} disabled={!this.state.btnPre} color="link"><img src={IconsArrowDown} alt="" /> </Button> 
                      <Button onClick={this.nextYear} disabled={!this.state.btnNext}  color="link" className="next-arrow">
                        <img src={IconsArrowDown} alt="" />
                      </Button> 
                      <h3>{this.state.years}</h3>
                    </div>
                  </div>
                  <div className="d-flex">
                    <FormGroup className="mr-20 search-keyword">
                      <InputField type="text" label={'Search by keyword'} name="searchbykeyword" onChange={e=> { this.filterByInput(e); }} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Show</Label>
                        <DropDown 
                            className='react-select-container w-100'
                            classNamePrefix="react-select"
                            options={FilterByShow}
                            getOptionLabel={ x => `${x.label}`}
                            getOptionValue={ x => `${x.value} ${x.value}`}
                            onChange={(options) => this.handleChange("showby", options)}
                            isSearchable={false}
                            value={this.state.selectedShowby }
                            placeholder={''}
                        />
                    </FormGroup>
                  </div>
                </div>
                {loadingnotification ?
                  <div className="notification__inner__body flex-grow-1 flex-column d-flex  align-items-center justify-content-center">
                    <Spinner color="primary" />
                  </div>
                :
                <div className="notification__inner__body flex-grow-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="165">Title</th>
                        <th width="660">Content</th>
                        <th width="130">Received on</th>
                      </tr>
                    </thead>
                    <tbody>
                        {getnotificationlist && getnotificationlist.data.map((list, index) => {
                          return <tr>
                                    <td width="165">{list.title}</td>
                                    <td width="660">
                                      <div className="">
                                      <CollapsiblePanel title={list.content} collapse={collapse}>
                                          {list.content}
                                      </CollapsiblePanel>
                                      </div>
                                    </td>
                                    <td width="130">{  moment(list.publish_date).format(DateDDMMMYYY)}</td>
                                  </tr>
                        })}
                      
                    </tbody>
                  </table>
                </div>
                }
                {getnotificationlist && getnotificationlist.last_page > 1 &&
                <div className="notification__inner__footer d-flex justify-content-center align-items-center">
                  <ul>
                    {[...Array(this.state.totelPage)].map((page, i) =>
                      <li key={i}><Button type="button"  className={`p-3 ${i+1 === this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                    )}
                  </ul>
                </div>
                }
            </div>
      </div>
    ) 
  }
}

const mapStateToProps = (state) => ({
  error: state.task.error,
  loadingnotification: state.task.loadingnotification,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  getnotificationlist:state.task.getnotificationlist
});


export default compose(
  withTranslation('common'),
  connect(mapStateToProps  )
)(HomeIndex);


