import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import manage_domain_icon  from '../../img/manage_domain_icon.svg'
import ic_devices  from '../../img/ic_devices.svg'
import { history } from '../../pages/index';
import {GetDomainList, GetauthorizedDevice, GetSubscribeInfo , GetSubscribePriceOption } from "../../store/actions/taskAction";
import MenuBox from '../../components/MenuBox'
import './style.scss';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      per_page: 5,
    }
  }
  componentDidMount(){
    let params ={
      per_page:this.state.per_page
    }

    //  this.props.dispatch(GetDomainList(params));
     this.props.dispatch(GetauthorizedDevice(params))    
     this.props.dispatch(GetSubscribeInfo());
     this.props.dispatch(GetSubscribePriceOption());
  }

  render (){
    const { getSubscribeinfo  , getDomainList} = this.props

    return(
      <div className="addons__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Addons"/>
          <div className="d-flex flex-column text-center addons__inner__text  w-100 mb-auto">
              <div className="mt-auto">
                <h4 className="mb-5 pb-4 text-center">Expand your access to THALAMUS</h4>
               
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex flex-wrap  justify-content-center row-cols-5 addons__section offset-md-2 col-8 p-0">
                  <MenuBox iconUrl={manage_domain_icon} menuTitle='MANAGE DOMAINS' isPlan='website_video' redirectUrl='/manage-domains' planName='Website Videos'/>
                  <MenuBox iconUrl={ic_devices} menuTitle='MANAGE DEVICES'  redirectUrl='/manage-devices' />
                </div>
                <div className="addons__total col-2 p-0 ml-auto mb-5 pb-5 d-flex flex-column align-items-start">
                  <p className="summary-title">Summary</p>
                  <p className="m-0"><span className="fs-50 text--primary">{getDomainList && getDomainList.total}</span>Authorized domains</p>
                  <p className="m-0"><span className="fs-50 text--primary">{getSubscribeinfo && getSubscribeinfo.available_domain}</span>Total domains</p>
                  <div className="border--bottom mt-4 mb-4 w-100"></div>
                  <p className="m-0"><span className="fs-50 text--primary">{this.props.authorizedDeviceList && this.props.authorizedDeviceList.total}</span>Authorized devices</p>
                  <p className="m-0"><span className="fs-50 text--primary">{getSubscribeinfo && getSubscribeinfo.available_device}</span>Total devices</p>
                  <div className="border--bottom mt-4 mb-4 w-100"></div>
                </div>
              </div>
            </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  getSubscribeinfo:state.task.getSubscribeinfo,
  getSubscribePriceOption:state.task.getSubscribePriceOption,
  getDomainList:state.task.getDomainList,
  authorizedDeviceList:state.task.authorizedDeviceList,
  avelablePlugin:state.task.avelablePlugin
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);
