import uuid from "react-uuid";
export const colors = [
    {
        colorName:"black",
        color:["#212121", "#1a1a1a"],
        subscription:true
    },
    {
        colorName:"silver",
        color:["#4b4e56", "#56585c"],
        subscription:true
    },
     {
        colorName:"dark blue",
        color:["#2c3c50", "#435a74"],
        subscription:true
    },
    {
        colorName:"brown",
        color:["#463e32", "#575143"],
         subscription:true
    },
    {
        colorName:"light blue",
        color:["#25587c","#25587c"],
        subscription:true
    },
    {
        colorName:"cyan",
        color:["#3d6668","#416a6c"],
        subscription:true
    },
    {
        colorName:"red",
        color:["#a43333","#a94040"],
        subscription:true
    },
    {
        colorName:"light",
        color:["#dddddd","#8d8d8d"],
        subscription:true
    },
    {
        colorName:"dark",
        color:["#181818","#181818"],
        subscription:true
    },
    {
        colorName:"granite",
        color:["#787878","#adadad"],
        subscription:false
    },   
    {
        colorName:"sky",
        color:["#66759a","#8493c1"],
        subscription:false
    },
    {
        colorName:"oak",
        color:["#6e513f","#906f5b"],
        subscription:false
    }      
]
export const layouts = [
    {
        layout:[
            {
                layoutNames:"default Layout",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:true
            },
            {
                layoutNames:"layout 2",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:true
            },
            {
                layoutNames:"layout 3",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:true
            },
            {
                layoutNames:"layout 4",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:true
            }
        ]
    },
    {
        layout:[
            {
                layoutNames:"layout 6",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:true
            },
            {
                layoutNames:"layout 7",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:false
            },
            {
                layoutNames:"layout 8",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:false
            },
            {
                layoutNames:"layout 9",
                layoutImages: "assets/img/layoutImg/defaultlayout.png",
                subscription:false
            }
        ]
    },
   
]

export const videolist = {

        title:'Video selection',
        description:'selected up to 100 videos to be viewed by your patients via the website player, available to any of your authorized domains. ',

        selected:true,
        subTitle:'Show all videos',
        items:493,
        videoButton :'select no more than 100 videos',
        lists:[
            {
                selected:false,
                title:'genaral density',
                items:172
            },
            {
                selected:false,
                title:'Endonatics',
                items:57
            },
            {
                selected:false,
                title:'Oral and Maxillofacial Surgery',
                items:22
            },
            {
                selected:false,
                title:'Oral Medicine and Pathology',
                items:31
            },
            {
                selected:false,
                title:'Oral and Maxillofacial Radiology',
                items:18
            },
            {
                selected:false,
                title:'Orthodontics and Dentofacial Orthopedics',
                items:68
            },
            {
                selected:false,
                title:'Pediatric Dentistry',
                items:34
            },
            {
                selected:false,
                title:'Periodontics',
                items:54
            },
            {
                selected:false,
                title:'Prosthodontics',
                items:37
            },
            {
                selected:false,
                title:'Playlist 1',
                items:100
            },
            {
                selected:false,
                title:'Playlist 2',
                items:100
            },
            
            
        ]
    
}

export const videoSelectedList ={
    lists :[
        {
            title:'paste application',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'sleep apnea',
            video: 'assets/img/videoSelectedImg/2.mp4',
            selected: false,
        },
        {
            title:'hygiene services',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'straight talk about crooked teeth',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'anatomy of teeth',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'post and core',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'how do i control',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'invisible braces',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'thumb sucking',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        {
            title:'pathology ',
            video: 'assets/img/videoSelectedImg/1.mp4',
            selected: false,
        },
        
        
    ]
}
 

export const videoselectionList ={
    title: 'Video selection',
    description:'selected up to 100 videos to be viewed by your patients via the website player, available to any of your authorized domains. ',
    allVideos :'Show all videos',
    selected : true,
    saveWebsite :'save to website',
    savePlaylist : 'save to playlist',
    videoList:[
        {
            specializations :'genaral density',
            selected:false,
            videos : [
                {
                    id   : uuid(),
                    title:'paste application',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),
                    title:'sleep apnea',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    selected: false,
                    category:"wip"
                },
            ]
        },
        {
            specializations :'Endonatics',
            selected:false,
            videos : [
                {
                    id   : uuid(),
                    title:'hygiene services',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),
                    title:'straight talk about crooked teeth',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),
                    title:'Oral & Maxillofacial Radiology',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),                    
                    title:'Orthodontics & Dentofacial Orthopedics',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),                   
                    title:'Pediatric Dentistry',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),
                    title:'Periodontics',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),
                    title:'Prosthodontics',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
                    selected: false,
                    category:"wip"
                },
            ]
        },
        {
            specializations :'Oral and Maxillofacial Surgery',
            selected:false,
            videos : [
                {
                    id   : uuid(),
                    title:'paste application',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
                    selected: false,
                    category:"wip"
                },
                {
                    id   : uuid(),
                    title:'sleep apnea',
                    video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
                    selected: false,
                    category:"wip"
                },
            ]
        },
    ]
}
