import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import IconsChairsideEducation from '../../img/IconsChairside_Education.svg'
import IconsWaitingRoomChannel from '../../img/IconsWaiting_Room_Channel.svg'
import IconWaitingRoomChannel from '../../img/IconsWaiting_Room_Channel.svg'
import IconsWebsiteVideos from '../../img/IconsWebsite_Videos.svg'

import './style.scss';
import { Table } from 'reactstrap';
import { Button} from 'reactstrap';

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        isSigninClick:true,
        isSignin:false
    }
    this.onSigninClick = this.onSigninClick.bind(this);
  }
  
  onSigninClick(){
    this.setState({isSigninClick:false})
  }
  onSignin(){
    this.setState({isSignin:true})
  }

  async componentDidMount(){

  }

  render (){
    return(
      <div className="main__screen referral__page  d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Free Month Referral"/>
            <div className="referral__inner mb-auto">
                <div className="referral__inner__header">
                  <h4>Referral list</h4>
                  <p>View all referrals that have subscribed to Thalamus following your invitation link.</p>
                  <p>Each subscription granted you both a free month.</p>
                </div>
                <div className="referral__inner__body">
                  <div className="referral__empty flex-column d-flex align-items-center justify-content-center">
                    <h4>You haven't referred a doctor yet.</h4>
                    <p>Once a referrals are made, you’ll have access to them on a list.</p>
                  </div>
                </div>
            </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
