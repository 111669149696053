import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button , Spinner } from 'reactstrap';
import { history } from '../index';
import {  GetpracticeProfile  , GetPracticeMoreInfo, GetSubscribeInfo, SetupgradeDowngrade} from "../../store/actions/taskAction";
import './style.scss';
import InlineSVG from 'react-inlinesvg'
import lock_icon from '../../img/lock_icon.svg'
import Loading from '../../components/Loading';

class ProfileCreated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isContinue:false
    }
  }

  async componentDidMount(){
    console.log(this.props.location?.selectplan,'props')
    if(this.props.practiceUserLoginInfo?.is_manage_location !== 1){
      this.props.dispatch(GetpracticeProfile());
    }
    await this.props.dispatch(GetSubscribeInfo());
    // await this.props.dispatch(GetpracticeProfile());
    // await  this.props.dispatch(GetPracticeMoreInfo());
   }
 
  onContinueClick(){
    this.setState({isContinue:true})
  }

  async onUpgradeClick(){
    await this.props.dispatch(SetupgradeDowngrade(true));
    history.push('/Subscribe')
  }

  render (){
    const {practiceInfo , error  , loading,isLoading, getSubscribeinfo ,media_language, country , specializations , lead_source_types , practice_softwares , practice_customer_types} = this.props

    return(
        <div className="upgradeplan__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
          {isLoading ?
            <Loading />
          :
          <div className="upgradeplan__inner__screen">
            <div className="d-flex flex-column text-center inactive__inner__text text-center">
                  <img className="mx-auto mb-5" alt="" src={lock_icon}/>
                  <h1>Please Subscribe</h1>
                  <p>Your {getSubscribeinfo?.subscription_plan?.name} does not include {this.props.location?.selectplan}.<br/> Please upgrade your subscription.</p>
                  <div className='d-flex justify-content-center'>
                  
                    <Button size="md" onClick={() => this.onUpgradeClick()} className="mx-3" color="primary">Upgrade</Button>
                    <Button size="md" onClick={() => history.push('/channels')} className="mx-3" color="default white" outline>Back</Button>
                  </div>
              </div>
          </div>
          }
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  country: state.task.country,
  specializations: state.task.specializations,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types:state.task.practice_customer_types,
  media_language:state.task.media_language,
  loading: state.task.loading,
  error: state.task.error,
  practiceProfileInfo: state.task.practiceProfileInfo,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  getSubscribeinfo:state.task.getSubscribeinfo,
  isLoading:state.task.isLoading
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(ProfileCreated);
