import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button } from 'reactstrap';
import Profile_icon from '../../img/play_white.svg';
import { formatSecond, TRIVIATYPE, TIMELINETRIVIATYPE } from '../../data/comman';
import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '../LoadingButton';
import triviaBG from '../../img/bg.png';
const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';
const WaitingRoomTriviaList = ({ trivias }) => {

    const state = useSelector((state) => state.task);
    const [showTrivia, setShowTrivia] = useState({
		trivia: [],
		showTrivia: false,
	});
	const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    
    // useEffect(() => {
    // })

    const changeTriviaSelected = (trivia) => {
        // setCount(count + 1);
		// trivias.forEach((element) => {
		// 	if (element.id === trivia.id) {
		// 		element.selected = !trivia.selected;
		// 	}
		// });
		// var arr = [];
		// trivias.forEach((element) => {
		// 	if (element.selected === true) {
		// 		arr.push(element);
		// 	}
		// });
    }

    const playSelectedTrivia = (trivia) => {
		// setShowTrivia({
		// 	trivia: trivia,
		// 	showTrivia: true,
		// });
	};

    return (
        <div className='videoSelected__main__centerCol'>
            <div className="video_list">
                {trivias.length > 0 ? trivias.map((trivia, index) => {
                    const selectedImage = trivia.selected === true ? RIGHTBOXIMAGE : WHITEBOXIMAGE;
                    return (
                        <div draggable={true} onDragStart={(event) => { event.dataTransfer.setData("timeline", JSON.stringify({ type: TIMELINETRIVIATYPE, timeLine: trivia })) }}
                     
                            key={index}
                            className={`videoSelected__main__columnFour videoSelected_box ${trivia.isAdded && trivia.isAdded.length > 0 ? 'border-4 border-warning' : ''}`}>
                            <div
                                className='videoSelected__main__card'
                                onClick={() => changeTriviaSelected(trivia)}>
                                {/* <img
                                    className='videoSelected__main__card__selectedImage'
                                    src={`${selectedImage}`}
                                    alt=''
                                /> */}
                                {trivia.isAdded && trivia.isAdded.length > 0 && <h3 className='videoSelected__main__card__selectedImage' >{trivia.isAdded.length}</h3>}
                                <div style={{ height: '100%', width: '100%' }} >
                                    <img
                                        src={triviaBG}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <div className="trivia_img_bg_text" style={{position: 'absolute', top:0, left: 0, right: 0, textAlign: 'center'}}>
                                        <h1>TRIVIA</h1>
                                        <h3>{trivia.category_name}</h3>
                                    </div>
                                </div>
                                <div
                                    className='page10__main__div2__gridbox__iconDiv'
                                    style={{ marginTop: '-95px', marginRight: '5px' }}>
                                    {/* <img
                                        src={MenuIcon}
                                        onClick={() => openMenu(trivia)} ></img> */}
                                </div>
                                <div className='videoSelected__main__card__subTitle'>
                                    {/* <div className='videoSelected__main__card__video_icon'>
                                        <img
                                            src={Profile_icon}
                                            alt='play_video'
                                            onClick={() => playSelectedTrivia(trivia)}
                                        />
                                    </div> */}
                                    <p className='videoSelected__main__card__title'>
                                        {trivia.title} 
                                    </p>
                                    <p className='videoSelected__main__card__smalltitile'>
                                        {trivia.category_name} | {/*formatSecond(trivia.duration)*/}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                }):null}
            </div>
        </div>
    )
}

export default WaitingRoomTriviaList;
