import React from 'react';
import { Button } from 'reactstrap';
import SubHeader from  '../../../components/Header/subHeader'
import NewSubHeader from  '../../../components/Header/NewSubHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import {  GetpracticeProfile  , GetPracticeMoreInfo , UpdatepracticeInfoRequest} from "../../../store/actions/taskAction";

import Review from './Review'
import PracticeInfo from './PracticeInfo'
import Additionalinformation from './additionalInformation'
import PracticeHours from './PracticeHours'
import { Ownership  , operatories , days , tv_count,distributor} from '../../../data/comman';
import HttpUtility, {HELPERURI} from '../../../utilities/HttpUtility';
import Loading from '../../../components/Loading';

class InitialSetupIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isStart:true,
      isActivebasicInfo:false,
      isActiveAdditionalInfo:false,
      isPracticeHours:false,
      isLoading:false,
    }
    this.handleClick = this.handleClick.bind(this);
    this.gotoBasicInfo = this.gotoBasicInfo.bind(this);
    this.gotoAdditionalInfo = this.gotoAdditionalInfo.bind(this);
    this.gotoPracticeHours = this.gotoPracticeHours.bind(this);
    this.onBack = this.onBack.bind(this);
  }
  
  handleClick(){
    this.setState({isStart:false})
  } 

  async componentDidMount(){
   
  }
  
  
  gotoBasicInfo(){
    this.setState({isActivebasicInfo:true})
  }

  gotoAdditionalInfo(){
    this.setState({isActiveAdditionalInfo:true})
  }

  gotoPracticeHours(){
    this.setState({isActivePracticeHours:true})
  }

  onBack(){
    this.setState({isActivePracticeHours:false , isActiveAdditionalInfo:false , isActivebasicInfo:false})
  }

  async onEditPracticeInfo(field , value){
    let practiceProfileInfo = this.props.practiceProfileInfo
    practiceProfileInfo[field] = value
    await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))
  }

  async onupdate(){
      
    if(this.props.practiceProfileInfo){
      if(this.props.practiceProfileInfo.country_id){
        var selectedcountry =  this.props.country.find(b => b.id === this.props.practiceProfileInfo.country_id)
        await  this.onEditPracticeInfo( 'selectedcountry' , selectedcountry)
        await  this.getState(selectedcountry.id);
      }
    }

    if(this.props.practiceProfileInfo){
        var ownership  = Ownership.find(b => b.id === this.props.practiceProfileInfo.ownership)
        this.onEditPracticeInfo( 'ownership' , ownership)
    }

    var specializations = []
    if(this.props.practiceProfileInfo.specializations){
        this.props.practiceProfileInfo.specializations.map((ids)=>
        specializations.push(this.props.specializations.find(b => b.id === ids)),
        );
          this.onEditPracticeInfo( 'specializations' , specializations)
    }

    if(this.props.practiceProfileInfo.additional_info.software){
      var selectedsoftware  = this.props.practice_softwares.find(b => b.name === this.props.practiceProfileInfo.additional_info.software)
      this.onEditPracticeInfo( 'selectedsoftware' , selectedsoftware)
    }

    if(this.props.practiceProfileInfo.additional_info.operatory_count){
      var selectedoperatories  = operatories.find(b => b.value == this.props.practiceProfileInfo.additional_info.operatory_count)
      this.onEditPracticeInfo( 'selectedoperatories' , selectedoperatories)
    }

    if(this.props.practiceProfileInfo.additional_info.source){
      var selectedsource  = this.props.lead_source_types.find(b => b.name === this.props.practiceProfileInfo.additional_info.source)
      this.onEditPracticeInfo( 'selectedsource' , selectedsource)
    }

    if(this.props.practiceProfileInfo.additional_info.customer_type){
      var selectedpracticetype  = this.props.practice_customer_types.find(b => b.id === this.props.practiceProfileInfo.additional_info.customer_type)
        this.onEditPracticeInfo( 'selectedpracticetype' , selectedpracticetype)
    }

    if(this.props.practiceProfileInfo.additional_info.tv_count){
      var selectedtv_count  = tv_count.find(b => b.value === this.props.practiceProfileInfo.additional_info.tv_count)
      this.onEditPracticeInfo( 'selectedtv_count' , selectedtv_count)
    }else{
      var selectedtv_count  = tv_count.find(b => b.value === 0)
      this.onEditPracticeInfo( 'selectedtv_count' , selectedtv_count)
    }

    if(this.props.practiceProfileInfo.additional_info.practice_setting && this.props.practiceProfileInfo.additional_info.practice_setting.system_language){
      var systemlanguage  = this.props.system_language.find(b => b.id === this.props.practiceProfileInfo.additional_info.practice_setting.system_language)
      this.onEditPracticeInfo( 'systemlanguage' , systemlanguage)
    }

    if(this.props.practiceProfileInfo.additional_info.practice_setting && this.props.practiceProfileInfo.additional_info.practice_setting.media_language){
      var medialanguage  = this.props.media_language.find(b => b.id === this.props.practiceProfileInfo.additional_info.practice_setting.media_language)
      this.onEditPracticeInfo( 'medialanguage' , medialanguage)
    }

    if(this.props.practiceProfileInfo.additional_info.distributor_name){
      var selecteddistributor  = distributor.find(b => b.label === this.props.practiceProfileInfo.additional_info.distributor_name)
        this.onEditPracticeInfo( 'selecteddistributor' , selecteddistributor)
    }

  }

  async getState(countryId){
    await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
            var countryState = response.data;
            this.onEditPracticeInfo( 'countryState' , countryState)
            if(this.props.practiceProfileInfo.state_id){
              var selectedstate = countryState.find(b => b.id === this.props.practiceProfileInfo.state_id)
            }
            this.onEditPracticeInfo( 'selectedstate' , selectedstate)
        }
    });
  }

  render (){
    return(
      <div className="practiceInfo__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
        {/* <SubHeader className="mb-auto" title="Practice Info"/> */}
        <NewSubHeader className="mb-auto" title="Practice Info"/> 
        {/* {this.state.isActivebasicInfo &&
          <PracticeInfo onBack = {this.onBack}/>
        }
        {this.state.isActiveAdditionalInfo &&
          <Additionalinformation onBack = {this.onBack}/>
        }
        {this.state.isActivePracticeHours &&
          <PracticeHours onBack = {this.onBack}/>
        } */}
        {this.state.isLoading ? <Loading /> :
          <Review  gotoBasicInfo = {this.gotoBasicInfo}  gotoAdditionalInfo = {this.gotoAdditionalInfo} gotoPracticeHours = {this.gotoPracticeHours} />
        }
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  country: state.task.country,
  specializations: state.task.specializations,
  practiceProfileInfo: state.task.practiceProfileInfo,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types:state.task.practice_customer_types,
  media_language:state.task.media_language,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(InitialSetupIndex);