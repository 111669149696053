import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Button, Spinner } from 'reactstrap';
import PreviewModal from '../../../components/PreviewModal';
import { formatSecond, GOOGLE_TV_TEST_DRIVE_RECENTPLAY } from '../../../data/comman';
import { OPERATORY_CHANNEL_PREVIEW_URL, WAITINGROOM_CHANNEL_PREVIEW_URL } from '../../../utilities/HttpUtility';
import styles from './style.module.scss';

import {ReactComponent as IcPlay} from '../images/ic_play.svg';
import {ReactComponent as IcCC} from '../images/ic_cc.svg';
import {ReactComponent as IcMyList} from '../images/ic_my_list.svg';
import {ReactComponent as IcMyListAdded} from '../images/ic_my_list_selected.svg';
import {ReactComponent as IcCCSelected} from '../images/ic_cc_selected.svg';
import {ReactComponent as IcClose} from '../images/ic_close.svg';
import {ReactComponent as ArrowLeft} from '../../../img/arrow_left.svg';

import { myListWaitingOperatoryRoomChannel, updateOperatoryChannelName, updateWaitingRoomChannelName } from '../../../store/actions/taskAction';
import Loading from '../../../components/Loading';
import { history } from '../..';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getToken } from '../../../helper/LocalStorage';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
var accessToken = ''

const VideoDetail = (props) => {
  const states = useSelector((state) => state.task);
  const dispatch = useDispatch();

  const [channelDetail, setChannelDetail] = useState({});
  const [isOperatoryRoom, setisOperatoryRoom] = useState(false);
  const [isOpenmodal, setisOpenmodal] = useState(false);
  const [isInMyList, setToMyList] = useState(false);
  const [allChannels, setAllChannels] = useState(states.waitingRoomMediaChannels);
  var [duration, setDuration] = useState(41);


  useEffect(() => {
    getSelectedChannelDetail()
    accessToken = getToken();

  }, []);

  const getSelectedChannelDetail= async () =>{

    setChannelDetail(props.location?.state?.channel)
    
    props.location?.state?.channel.timeline.map(x => {
      duration += x.media_data.duration;
    });

    if(props.location?.state?.isOperatoryRoom){
      if(props.location?.state?.channel?.my_lists?.channel_id){
        setToMyList(!isInMyList)
      }
    }else{
      if(props.location?.state?.channel?.my_list?.channel_id){
        setToMyList(!isInMyList)
      }
    }
  
    if(props.location?.state?.isOperatoryRoom){
      setisOperatoryRoom(props.location?.state?.isOperatoryRoom)
    }
    setDuration(formatSecond(duration))
  }

  
  useEffect(() => {
    var selectedChannel;
    if(isOperatoryRoom){
      selectedChannel = states.operatoryMediaChannels.find(x => x.id == props.location?.state?.channel.id);

      if(selectedChannel?.cc_status != channelDetail?.cc_status || selectedChannel?.my_lists != channelDetail?.my_lists){
        setChannelDetail(selectedChannel);
      }

    }else{
      selectedChannel = states.waitingRoomMediaChannels.find(x => x.id == props.location?.state?.channel.id);

      if(selectedChannel?.cc_status != channelDetail?.cc_status || selectedChannel?.my_list != channelDetail?.my_list){
        setChannelDetail(selectedChannel);
      }
    }

    

  }, [allChannels]);

  const addToMyList = async () =>{
    await toggleMylist()
    await addToList();
  }

  const toggleMylist = async () =>{
    setToMyList(!isInMyList);
  }

  const addToList = async () =>{
    let params={
			my_list: !isInMyList 
    }
    await dispatch(myListWaitingOperatoryRoomChannel(channelDetail.id, params, isOperatoryRoom) );
   }

   const onPlayChannel = (channel) =>{
    var recentPlayLocal = localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY);
		if(recentPlayLocal != null){
			var recentPlay = JSON.parse(localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY));
			var index = recentPlay.findIndex(x => x == channel.id);
			if(index > -1){
				recentPlay.splice(index, 1);
			}
			recentPlay.unshift(channel.id);
			localStorage.setItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY, JSON.stringify(recentPlay));
		}else{
			var recentPlay = [];
			recentPlay.unshift(channel.id)
			localStorage.setItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY, JSON.stringify(recentPlay));
		}
    
    setisOpenmodal(true)
   }

   const handleCC = () =>{
    let id = channelDetail.id;
    let params={
			cc_status: channelDetail.cc_status == 0 ? "1" : "0"
    }
    
      if(isOperatoryRoom)
        dispatch(updateOperatoryChannelName(id, params) );
      else
      dispatch(updateWaitingRoomChannelName(id, params) );
  }


  var videourl=''
  if(states.practiceInfo.is_internal_user !== 1 ){
    videourl=`${isOperatoryRoom ? OPERATORY_CHANNEL_PREVIEW_URL:WAITINGROOM_CHANNEL_PREVIEW_URL}${channelDetail.id}&cc_status=${channelDetail.cc_status}&media_lang=${states.practiceUserAccountInfo.account_setting.media_language}&is_expired=${states.practiceInfo.is_paid_account == 0  && states.practiceInfo.days_left == 0 ? 1 : 0}&accessToken=${accessToken}`
  }else{
    videourl=`${isOperatoryRoom ? OPERATORY_CHANNEL_PREVIEW_URL:WAITINGROOM_CHANNEL_PREVIEW_URL}${channelDetail.id}&practice_name=${states.practiceProfileInfo?.name}&media_lang=${states.practiceUserAccountInfo.account_setting ? states.practiceUserAccountInfo.account_setting.media_language : 1}&cc_status=${channelDetail.cc_status}&accessToken=${accessToken}`
  }

  return (
    <>
    {(states.buttonLoading3) || (states.buttonLoading2) ? <div className={`goolge_tv__loader`}><Loading /></div>  : ''}
    <div className={`${styles.goolge_tv_video_detail__main__screen} d-flex flex-column justify-content-center h-100`}  style={{backgroundImage:`url(${channelDetail.feature_image}) `}}>
      <Button  type="button"  onClick={() => history.goBack()}  style={{fontSize:'2rem'}} color="link" className="py-1 m-5 pl-0 d-flex align-items-center mb-auto"><ArrowLeft className="mr-4"/><span>Back</span></Button>
      <div className={`${styles.goolge_tv_video_detail__inner__screen} mb-auto`}>
        <div className={`${styles.goolge_tv_video_detail__text}`}>
          <h1>{channelDetail.title}</h1>{console.log(channelDetail,'channelDetail')}
          <div className={`${styles.goolge_tv_video_detail__text__duration}`}>Duration: <span className='duration__text'>{duration}</span></div>
          {/* <div className={`${styles.goolge_tv_video_detail__text__cetegory} mb-5`}>Specialization: Oral & Maxillofacial Surgery</div> */}
          <div className={`${styles.goolge_tv_video_detail__text__description} mb-5`}>{channelDetail.description}</div>

          <div className={`${styles.goolge_tv_video_detail__btn_group} d-flex `}>
            <div className='text-center'>
              <div className={`channel_btn d-flex align-items-center justify-content-center mr-4 mb-1`} onClick={()=> {onPlayChannel(channelDetail)} }>
                  <IcPlay />
              </div>
              Play
            </div>
            <div className='text-center'>
              <div className={`channel_btn d-flex align-items-center justify-content-center mr-4 btn_mylist`} onClick={() => addToMyList()}>
                { isInMyList ? <><IcMyListAdded className='my_list_added' /><IcClose className='close_btn'/></> : <IcMyList />}
              </div> 
              My List
            </div>
            {!isOperatoryRoom &&
            <div className='text-center'>
              <div className={`channel_btn d-flex align-items-center justify-content-center cc_btn_google_tv `} onClick={()=>{ handleCC(channelDetail) }}>
                { channelDetail.cc_status == 0 ? <IcCC /> :  <IcCCSelected/>}
              </div>
              Captions
            </div>
            }
          </div>
        </div>
      </div>
      {isOpenmodal &&
          <PreviewModal url={videourl} isOpen={isOpenmodal} toggle={()=> setisOpenmodal(!isOpenmodal) }/>
        }

        {isOperatoryRoom &&
          <Swiper
            spaceBetween={40}
            slidesPerView={6}
            navigation
            className={`${styles.goolge_tv_video_detail__footer_timeline_sliderBox} google_tv_videoDetail_footer_slider`}                   
             >
          { channelDetail.timeline && channelDetail.timeline.map((timeLineVideo) =>
            <SwiperSlide className={`${styles.goolge_tv_video_detail__footer_timeline_sliderBox__item}`}>
              <div  className={`${styles.goolge_tv_video_detail__footer_timeline} d-flex`}>
                <div className={`${styles.goolge_tv_video_detail__footer_timeline_items}`}>
                  <img src={timeLineVideo.media_data.thumb} alt={timeLineVideo.media_data.name} />
                  <label>{timeLineVideo.media_data.name}</label>
                </div>
              </div>
              </SwiperSlide>
             
            )}
          </Swiper>
          }
    </div>
    </>
  )
}

export default VideoDetail
