import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';


import Primary from './Primary'
import Secondary from './Secondary'
import moment from 'moment';
import {DateFormat} from '../../../data/comman'
import {GetBillingCard , UpdateBillingCard , AddBillingCard, resetSuccess} from "../../../store/actions/taskAction";

import { getCardImageByNo } from '../../../data/comman';

class NewProfileForm extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      primerCreditCard: {},
      secondaryCreditCard:null,
      step: 1,
      isActiveUpdate:true,
      vardImage: "",
      isError: {
        expireDate:'',
      }
    };
    this.stepOne = this.stepOne.bind(this);
    this.stepTwo = this.stepTwo.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onUpdateCard = this.onUpdateCard.bind(this);
    this.onAddCard = this.onAddCard.bind(this);
    this.ResetValue = this.ResetValue.bind(this);
    this.getPrimeryCardInfo =this.getPrimeryCardInfo.bind(this);
    this.getSecondaryCardInfo =this.getSecondaryCardInfo.bind(this);
  }

  stepOne(){
    this.setState({
      step:1,
    })
  }
  
  stepTwo(){
    this.setState({
      step:2,
    })
  }

  onUpdateCard(){
    this.props.dispatch(UpdateBillingCard(this.state.primerCreditCard));
  }

  onAddCard(){
    this.props.dispatch(AddBillingCard(this.state.primerCreditCard , this.props.practiceUserAccountInfo.practice_id));
  }

  ResetValue(){
    this.setState({
      primerCreditCard:{
        ...this.state.primerCreditCard,
        expireDate: '',
      }
    })
  }

  changeHandler(event, filed){
    var filedName = "", filedValue = "";
    var vardImage = this.state.vardImage;
    if(filed){
        filedName = filed;
        filedValue = event.value;
        if(filedName === "creditCardNumber"){
            vardImage = getCardImageByNo(filedValue);
        }
        if(this.state.isEdit){
            // pageProps.onEditCard(event, filedName)
        }
    }else{
        var { name, value } = event.target;
        filedName = name;
        filedValue = value;
    }
    this.setState({
        primerCreditCard:{
          ...this.state.primerCreditCard,
          [filedName]: filedValue,
        }, 
        vardImage,
        error: {} 
    },()=> {
        this.isValid();
    });
}


  isValid(name, value){
    let isError = this.state.isError ;
    console.log(this.state.primerCreditCard ,'filedValue')

    // switch (name) {
    //   case "first_name":
    //       isError.first_name =
    //           value.length < 4 ? "Atleast 4 characaters required" : "";
    //       break;
    //   case "last_name":
    //       isError.last_name =  
    //         value.length <= 0 ? "required" : "";
    //       break;
    //   case "selectedRole":
    //       isError.selectedRole =  
    //       value.length <= 0 ? "required" : "";
    //       break;
    //   default:
    //       break;
    // }
    this.setState(this.validateForm)

    
  }
  
  validateForm(){
    let isError = this.state.isError ;
    const { expireDate} = this.state.primerCreditCard;

      if(expireDate !== ''  ){
          this.setState({ isActiveUpdate: false });
      }else{
        this.setState({ isActiveUpdate: true});
      }
  }

  async getPrimeryCardInfo(){
      var primerCreditCard =  await this.props.getBillingCardInfo.find(x => x.is_primary === 1);
      var expireDate =  `${primerCreditCard.exp_month.toString().length === 1 ? `0${primerCreditCard.exp_month}` : primerCreditCard.exp_month}${primerCreditCard.exp_year}`
      await this.setState({ 
        primerCreditCard,
        expireDate
      });
  }

  async getSecondaryCardInfo(){
    var secondaryCreditCard =  await this.props.getBillingCardInfo.find(x => x.is_primary === 0);
    if(secondaryCreditCard){
      var secondaryexpireDate =  `${secondaryCreditCard.exp_month.toString().length === 1 ? `0${secondaryCreditCard.exp_month}` : secondaryCreditCard.exp_month}${secondaryCreditCard.exp_year}`
      await this.setState({ 
        secondaryCreditCard,
        secondaryexpireDate
      });
    }else{
      await this.setState({ 
        secondaryCreditCard:null,
      });
    }
    
}


  async componentDidMount(){
    await this.props.dispatch(GetBillingCard());
    var primerCreditCard = this.props.getBillingCardInfo.find(x => x.is_primary === 1);
    var secondaryCreditCard = this.props.getBillingCardInfo.find(x => x.is_primary === 0);
    if(secondaryCreditCard){
      this.getSecondaryCardInfo()
    }
    if(primerCreditCard){
      this.getPrimeryCardInfo()
    }
   
  }

  renderStep(step , index){
    switch(step){
        case 1:
            return  <Primary
                      isError={this.state.isError }
                      primerCreditCard={this.state.primerCreditCard}
                      expireDate={this.state.expireDate}
                      getPrimeryCardInfo={this.getPrimeryCardInfo}
                    />
        case 2:
            return  <Secondary 
                      isError={this.state.isError }
                      secondaryexpireDate={this.state.secondaryexpireDate}
                      secondaryCreditCard={this.state.secondaryCreditCard}
                      getSecondaryCardInfo={this.getSecondaryCardInfo}
                    />
        default :
          return  null
    }
  }

  render (){
    const { step } = this.state;
    return(
      <>
        <div className="tabs">
            <ul className="d-flex justify-content-between">
              <li onClick={ this.stepOne } className={`col text-center ${step === 1 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Primary</label>
              </li>
              <li onClick={ this.stepTwo } className={`col text-center ${step === 2 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Secondary</label>
              </li>
            </ul>
        </div>
        <div className="mb-auto pb-5">
          { this.renderStep(step) }
        </div>
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
  getBillingCardInfo:state.task.getBillingCardInfo,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(NewProfileForm);
