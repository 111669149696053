/** @format */

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import SubHeader from '../../components/Header/subHeader';
import DragAndDropThree from '../../components/SortableDropzoneForCustomMessages/OperatoryDragAndDrop';
import {
	getCustomMessage, postChannelWiseCustomMessages
} from '../../store/actions/taskAction';
import OperatoryDisplayOptions from '../OperatoryDisplayOptions/OperatoryDisplayOptions';
import OperatoryTime from '../OperatoryTime/OperatoryTime';
import OperatoryWeather from '../OperatoryWeather/OperatoryWeather';
import './styles.scss';
import WaitingOperatoryRoomNewsFeed from '../../components/WaitingOperatoryRoomNewsFeed';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'auto',
	},
}));
const OperatoryFooter = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	useEffect(() => {
		dispatch(getCustomMessage(state.manageOperatoryChannel.id, true));
	}, []);

	
	const [formData, setFormData] = useState({
		title: '',
		messages: '',
		order: null,
	});
	const [message, setMessage] = useState({
		message: 'Add Message',
		color: 'black',
		bgColor: '#eccc63',
	});

	const [showRemoveDeleteButton, setShowCancelDeleteButton] = useState(false);
	const [value, setValue] = useState(0);
	const [count, setCount] = useState(0);
	// const [customMessages, setCustomMessages] = useState(state.customMessages);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const changeHandler = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value,
		});
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	const [id, setId] = useState(null);
	const customMessagesSubmitHandler = (event) => {
		let params ={
			title: formData.title,
			messages: formData.messages,
			order: count,
		}
		dispatch( postChannelWiseCustomMessages(state.manageOperatoryChannel.id, params, true));
		setMessage({
			message: 'Message Added',
			bgColor: 'btn-green',
			color: 'black',
		});
		setFormData({
			title: '',
			messages: '',
			order: null,
		});
		setTimeout(() => {
			setMessage({
				message: 'Add Message',
				color: 'black',
				bgColor: '#eccc63',
			});
		}, 1000);
		setCount(count + 1);
		// dispatch(getCustomMessage(state.manageOperatoryChannel.id));
	};

	return (
		<div className='operatory_channel_channel_footer__main__screen  d-flex flex-column h-100 align-items-center '>
			<SubHeader className='' title='Footer' />
			<div className="operatory_channel_channel_footer__inner__screen mt-5">
				<div className={`${classes.root} footer-header operatory_channel_channel_footer__inner__text`}>
					<div className="">
						<AppBar position='static' color='default' className="d-inline-flex">
							<Tabs
								value={value}
								onChange={handleChange}
								indicatorColor='transparent'
								textColor='primary'
								className='footer-tabs'
								aria-label='full width tabs example '>
								<Tab
									label='CUSTOM MESSAGES'
									{...a11yProps(0)}
									className='footer-tab-btn'
								/>
								<Tab
									label='NEWSFEED'
									{...a11yProps(1)}
									className='footer-tab-btn'
								/>
								<Tab label='TIME' {...a11yProps(2)} className='footer-tab-btn' />
								<Tab
									label='WEATHER'
									{...a11yProps(3)}
									className='footer-tab-btn'
								/>
								<Tab
									label='DISPLAY OPTIONS'
									{...a11yProps(4)}
									className='footer-tab-btn'
								/>
							</Tabs>
						</AppBar>
					</div>
					<TabPanel value={value} index={0} dir={theme.direction}>
						<div className='d-flex operatory_channel_channel_footer__panel_body operatory_room_channel_footer__panel_body w-100'>
							<div className='transparent__form--white custom_message d-flex flex-column align-items-stretch '>
								<Form className="operatory_channel_channel_footer__panel_body__form">
									<FormGroup>
										<h4>Custom message</h4>
										<p> Please add a title and enter a messsage you'd like to
										leave for your patients. </p>
									</FormGroup>
									<FormGroup>
										<label>Title</label>
										<Input type='text' value={formData.title} onChange={changeHandler} name='title' />
									</FormGroup>
									<FormGroup className=' '>
										<label>Message</label>
										<textarea class='form-control' rows='7' name='messages' onChange={changeHandler} value={formData.messages} placeholder="Type here"></textarea>
									</FormGroup>
									<FormGroup className=''>
										<Button type="button" color="primary" className={`${message.bgColor} w-100`} onClick={customMessagesSubmitHandler}>
											{message.message}
										</Button>
									</FormGroup>
								</Form>
							</div>
							<div className='main__hr'></div>
							<div className="message_box w-100">
							{state.customMessages ? (
								<DndProvider className="" backend={HTML5Backend}>
									<DragAndDropThree />
								</DndProvider>
							) : (
								''
							)}
							</div>
						</div>
					</TabPanel>
					<TabPanel value={value} index={1} dir={theme.direction}>
						<WaitingOperatoryRoomNewsFeed isOperatiryRoom={true}/>
					</TabPanel>
					<TabPanel value={value} index={2} dir={theme.direction}>
						<OperatoryTime />
					</TabPanel>
					<TabPanel value={value} index={3} dir={theme.direction}>
						<OperatoryWeather />
					</TabPanel>
					<TabPanel value={value} index={4} dir={theme.direction}>
						<OperatoryDisplayOptions />
					</TabPanel>
				</div>
			</div>
		</div>
	);
};

export default OperatoryFooter;
