import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button , Spinner } from 'reactstrap';
import { history } from '../../pages/index';
import {  PracticeUserLogin } from "../../store/actions/taskAction";
import LoadingButton from '../../components/LoadingButton'

import './style.scss';

class ProfileCreated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(){
  } 

  onSigninClick(users){
      if(this.props.newProfileInfo.is_secure === 0){
        this.props.dispatch(PracticeUserLogin(users.id , null));
        // history.push('/profileselection');
      }else{
          history.push({
            pathname: '/signin',
            state: { users }
          });
      }
  }

  async componentDidMount(){
  }

  render (){
    const {loadingSignin } = this.props
    return(
        <div className="signin__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
            <SubHeader className="mb-auto"/>
            <div className="signin__inner__screen mb-auto">
                <div className="d-flex flex-column text-center signin__inner__text text-center">
                    <h1 className="mb-5">Profile created</h1>
                    <p>The profile “{this.props.newProfileInfo.first_name+' '+' '+this.props.newProfileInfo.last_name}” has been created<br/> and is now available in the profile selection section.</p>
                    <LoadingButton type="button"  label2={'Sign in'} label={'Sign in'} loading={loadingSignin} disabled={loadingSignin} size="md" onClick={() => this.onSigninClick(this.props.newProfileInfo)} className={`mx-auto mt-5`} color="primary"/>
                </div>
            </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    newProfileInfo:state.task.newProfileInfo,
    loadingSignin:state.task.loadingSignin
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(ProfileCreated);
