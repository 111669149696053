import React, {useState} from 'react';
// import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import Back from '../../../img/help/Chevron.svg'
import '../style.scss';
import { Button } from 'reactstrap';
import Image1 from '../../../img/help/education_1.png'
import Image2 from '../../../img/help/education_2.png'
import Image3 from '../../../img/help/education_3.png'
import Image4 from '../../../img/help/education_4.png'

const Education =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <> 
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Chairside Education</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"3px"}}>
                                        <h5>Navigating the Chairside Education page</h5>
                                        <div className="help_detail">
                                             <p>When navigating to the Chairside education page, you will see three rows of videos: New releases, recently played, and My list. </p>
                                             <p>On the left hand side is a vertical navigation bar featuring the following menus.</p>
                                             <p>Search: Selecting the search icon will allow you to quickly find a specific video. Entering a keyword such as ‘sleep’ will show you all available videos that reference sleep.</p>
                                             <p>Home: Selecting home will direct you back to the initial page on the Chairside education page.</p>
                                             <p>New Releases: The new releases screen contains the most recent videos released by Thalamus.</p>
                                             <p>
My list: My list contains all the videos that have been manually added by the user.</p>
<p>Chairside screenshots:  Screenshots saved during a video are available in the Chairside Screenshots here.</p>
<p>Specializations: Clicking a specialization will display all the videos associated with that specific specialization (ie. Endodontics). </p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image1}   className="w-100" alt="" style={{marginTop:"-8px"}}/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"104px", }}>
                                        <h5>Adding videos to your list</h5>
                                        <div className="help_detail">
                                          <p>Clicking on a video will open up a small menu with additional information.The last option, the + icon, allows you to add or remove videos to your list, for easy access.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"84px",}}>
                                      <img src ={Image2} />
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"10px"}}>
                                        <h5>Chairside Screenshots</h5>
                                        <div className="help_detail">
                                          <p>Selecting the “take a screenshot’ button while a video is playing allows you to edit the captured image and save it. At the edit screen, you are able to add text and freely draw over the image,
</p>
<p>Selecting “Text” will give you options to change the size, the font, and the colour of the text. Selecting “Drawing” activates the freeform drawing tool, as well as the ability to insert lines/circles or crop the image.
</p>
<p>On the left hand side of the screen is where you can enter the patient’s name and email, as well as write a customized note/message Once the patient’s name and email address is entered, press “email screenshot”.
</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"10px" }}>
                                      <img src ={Image3}  className="w-100" alt="" />
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"2px"}}>
                                        <h5>Editing Closed captions</h5>
                                        <div className="help_detail">
                                          <p>Clicking the “view & edit captions” button for a selected video will direct you to the “closed captions” screen. Here the user can edit and add custom captions. 
</p>
<p>Clicking on ‘Time’ (either IN or OUT) allows the user to edit the captions. ‘IN’ is the time that the caption starts, and OUT is the time the caption ends. Press “Save changes” Once changes are finished, 
</p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image4}  className="w-100" alt=""  style={{marginTop:"-12px", }}/>
                                  </div>
                            </div>
    
                      </div>
                  </>
            
    ) 

}

export default Education;
