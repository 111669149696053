import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/websitevideos.png'
import Image2 from './../../../img/help/websitevideos_2.png'
import Image3 from './../../../img/help/websitevideos_3.png'

const WebsiteVideosRoom =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <> 
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Website Videos</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"6px",}}>
                                        <h5>Website Video Interface</h5>
                                        <div className="help_detail">
                                              <p>Selecting  the “website videos” option from the home page takes you to a page with two options:
</p>
<ul>
    <li>1.) Website Player Customization</li>
    <li>2.) Website Video Library </li>
</ul>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-6px"}}>
                                      <img src ={Image1}  className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"-19px",}}>
                                        <h5>Player customization</h5>
                                        <div className="help_detail">
                                            <p>This screen allows you to customize the design and layout of the Thalamus video player. A default layout, and colour scheme will be selected automatically. 
</p>
<ul className="no-style">
    <li><span className="underline">Layout:</span> Select the design and placement of the video player
</li>
<li ><span className="underline">Language:</span> Select a language for video audio and subtitles (english and no closed captions are selected as default),
</li>
<li ><span className="underline">Colour scheme:</span> Choose default colours of video player
</li>
</ul>
<p><b>Get HTML Code:</b> Once changes have been made, this button provides you with the code needed to place the video player on your site.
</p>
<p>Quick Preview: Gives you a full view of how the web player will look like. 
</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-17px",}}>
                                      <img src ={Image2} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"-9px", }}>
                                        <h5>Website video library</h5>
                                        <div className="help_detail">
                                            <p>The website video player page allows you to select the videos that will be displayed on your website. There are 50 videos to choose from. Videos are organized by specialization. Users can check and uncheck desired videos that will appear on their site.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-29px",}}>
                                      <img src ={Image3} className="w-100" alt=""/>
                                  </div>
                            </div>
    
                      </div>

                  </>
            
    ) 

}

export default WebsiteVideosRoom;
