import React from 'react';

import { Button , Label , FormGroup, Form , Col , Row , Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../../pages/index';
import moment from 'moment';
import { UpdatePracticeProfile  } from "../../../store/actions/taskAction";

import {  connect } from 'react-redux'

import { compose } from 'redux';
import LoadingButton from '../../../components/LoadingButton'

class Review extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        practiceProfileInfo:props.practiceProfileInfo,
     
    };
    this.nextClick = this.nextClick.bind(this);
    this.gotoBasicInfo = this.gotoBasicInfo.bind(this);
    this.gotoPracticeHours = this.gotoPracticeHours.bind(this);
    this.gotoAdditionalInfo = this.gotoAdditionalInfo.bind(this);
    
    // this.handleClick = this.handleClick.bind(this);
  }

  nextClick(){
    this.props.nextStep();
  }

  gotoBasicInfo(){
    this.props.gotoBasicInfo();
  }

  gotoPracticeHours(){
      this.props.gotoPracticeHours();
  }
  gotoAdditionalInfo(){
      this.props.gotoAdditionalInfo();
  }

//   handleClick(){
//     if(this.props.practiceInfo.need_owner_setup === 1){
//         history.push('/ownerprofilesetup');
//     }else{
//         history.push('/profileselection');
//     }
//   } 

    submitdata(id ,data){
        this.props.dispatch(UpdatePracticeProfile(id ,data));
    }
  
  render (){
    const {t , values , loading , error} = this.props
      
    return(
        <div className="review__screen transparent__form--white md d-flex flex-column align-items-stretch mb-auto">
          <Form className="h-100 d-flex flex-column">
              <Row>
                <Col md={3} className="mr-3">
                    <FormGroup className="mb-20 d-flex align-items-center review-heading">
                        <h4 className="m-0 mt-1 fw400">Basic information</h4>
                        <Button type="button"  onClick={ this.gotoBasicInfo } color="link primary" className="py-0 mt-1">Edit</Button>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20 review-form-group">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice name</Label>
                        <Col sm={12}>
                            <span className="text--gray">{values.name || ''}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20 review-form-group">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Ownership</Label>
                        <Col sm={12}>
                            <span className="text--gray">{values.selectedownership.name || ''}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20 review-form-group">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice phone number</Label>
                        <Col sm={12}>
                            <span className="text--gray">{values.phone || ''}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20 review-form-group">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice address</Label>
                        <Col sm={12}>
                            <span className="text--gray">
                                {values.address_line_1 || ''} , {values.address_line_2 || ''} <br/>
                                {values.city || ''}/{values.selectedstate.name || ''}, {values.zip || ''} <br/>
                                {values.country.name}
                            </span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-20 review-form-group">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice email</Label>
                        <Col sm={12}>
                            <span className="text--gray">{values.email || ''}</span>
                        </Col>
                    </FormGroup>
                    <FormGroup row  className="align-items-center mb-0 review-form-group">
                        <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Specialization</Label>
                        <Col sm={12}>
                            <span className="text--gray">
                                {values.specializations.map((items, index)=>{
                                    return <div key={index}>{items.name}</div>
                                })}
                            </span>
                        </Col>
                    </FormGroup>
                </Col>
                <Col md={6} className="mw-519 border--right pr-5">
                    <FormGroup className="mb-20 d-flex review-heading">
                        <h4 className="m-0 mt-1 fw400">Additional information</h4>
                        <Button type="button" onClick={ this.gotoAdditionalInfo } color="link primary" className="py-0 mt-1">Edit</Button>
                    </FormGroup>
                    <Row>
                        <Col sm={7} className="review-section">
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Location manager</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.locationManager || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Practice software</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.softwares.name || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Number of operatories</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.selectedoperatories.value || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Referral source</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.source.name || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Customer type</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.practicetype.name || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Sales representative</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.salesRepresentative || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-0 review-form-group">
                                <Label htmlFor="practicename" className="font-weight-bold" sm={12} >Distributor</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.distributor.label || ''}</span>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col className="px-4 review-section">
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="" sm={12} >System language</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.systemlanguage.name || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="" sm={12} >Media Language</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.medialanguage.name || ''}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row  className="align-items-center mb-20 review-form-group">
                                <Label htmlFor="practicename" className="" sm={12} >TVs in the practice</Label>
                                <Col sm={12}>
                                    <span className="text--gray">{values.nooftvs.value || ''}</span>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={3} className="ml-auto review-section">
                    <FormGroup className="mb-20 d-flex review-heading">
                        <h4 className="m-0 mt-1 fw400">Practice hours</h4>
                        <Button type="button" onClick={ this.gotoPracticeHours } color="link primary" className="py-0 mt-1">Edit</Button>
                    </FormGroup>
                    {values.additional_info.schedule && 
                        <div className="d-flex flex-column justify-content-between">
                        <FormGroup row  className="align-items-center mb-3 review-day">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Sunday</Label>
                            <Col sm={7} className="p-0 ml-auto">
                                <span className="text--gray">{values.additional_info.schedule[6].status === 0 ? 'Closed' : `${moment(values.additional_info.schedule[6].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(values.additional_info.schedule[6].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2 review-day">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Monday</Label>
                            <Col sm={7} className="p-0 ml-auto">
                                <span className="text--gray">{values.additional_info.schedule[0].status === 0 ? 'Closed' : `${moment(values.additional_info.schedule[0].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(values.additional_info.schedule[0].end_time,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2 review-day">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Tuesday</Label>
                            <Col sm={7} className="p-0 ml-auto">
                                <span className="text--gray">{values.additional_info.schedule[1].status === 0 ? 'Closed' : `${moment(values.additional_info.schedule[1].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(values.additional_info.schedule[1].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2 review-day">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Wednesday</Label>
                            <Col sm={7} className="p-0 ml-auto">
                                <span className="text--gray">{values.additional_info.schedule[2].status === 0 ? 'Closed' : `${moment(values.additional_info.schedule[2].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(values.additional_info.schedule[2].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2 review-day">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Thursday</Label>
                            <Col sm={7} className="p-0 ml-auto">
                                <span className="text--gray">{values.additional_info.schedule[3].status === 0 ? 'Closed' : `${moment(values.additional_info.schedule[3].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(values.additional_info.schedule[3].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2 review-day">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Friday</Label>
                            <Col sm={7} className="p-0 ml-auto">
                                <span className="text--gray">{values.additional_info.schedule[4].status === 0 ? 'Closed' : `${moment(values.additional_info.schedule[4].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(values.additional_info.schedule[4].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                        <FormGroup row  className="align-items-center mb-2 review-day">
                            <Label htmlFor="practicename" className="pr-lg-0" sm={5}>Saturday</Label>
                            <Col sm={7} className="p-0 ml-auto">
                                <span className="text--gray">{values.additional_info.schedule[5].status === 0 ? 'Closed' : `${moment(values.additional_info.schedule[5].start_time,["HH:mm"]).format("hh:mm A")} to ${moment(values.additional_info.schedule[5].end_time ,["HH:mm"]).format("hh:mm A")} `}</span>
                            </Col>
                        </FormGroup>
                    </div>
                    }
                </Col>
              </Row>
              <div className="mt-auto">
                    <p className="mb-3">If everything is correct, please proceed.</p>
                    <LoadingButton type="button" error={this.props.error} label2={t('btn.registerGotoProfile')} label={t('btn.registerGotoProfile')} loading={loading} disabled={loading} size="xxl" onClick={() => this.submitdata(this.props.practiceProfileInfo.id ,values)} className={``} color="primary"/>
              </div>
            </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    loading: state.task.loading,
    error: state.task.error,
    practiceInfo: state.task.practiceInfo,
    
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Review);