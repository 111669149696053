import React, { useState } from 'react'
import VideoBox from '../VideoBox';
import styles from './style.module.scss';
// import { useHorizontalScroll } from "../Scroll";
import PlayIcon from '../../../../img/play_icon_larg.svg'
import SwiperCore, { Keyboard , Mousewheel} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.css';
import { Button, Spinner } from 'reactstrap';
import {ReactComponent as ArrowLeft} from '../../../../img/arrow_left.svg';
import { history } from '../../../../pages/index';

SwiperCore.use([Keyboard, Mousewheel]);


const Channels = ({channelsData = [], title, noSlider, isOperatoryRoom, isChairside, isRecentPlay, isMyList, isCat, toggleisOpen, isPlaylists}) => {
  // const refs =  useHorizontalScroll()
  const scrollRef = noSlider ? null : null;

  return (
      <>
          {title &&
            <div className={`px-20 mt-2 mb-2 ${isCat ? 'd-flex align-items-center' : ''}`}>
                {/* {isCat &&  
                  <Button  type="button"  onClick={() => toggleisOpen(toggleisOpen)}  style={{fontSize:'2rem'}} color="link" className="py-1 mr-4 mb-0 pl-0 d-flex align-items-center"><ArrowLeft className="mr-4"/><span>Back</span></Button>
                } */}
                <h2 className={`${styles.videoSliderSectionTitle} mb-0`}>{title}</h2>
            </div>
          }
          <div ref={scrollRef} className={`${noSlider ? styles.videoSliderSectionVscroll + ' d-flex flex-wrap' : styles.videoSliderSection}`}>
              {noSlider ?
                <>
                {channelsData.map((info, index) => {
                    return(
                    <>
                      {(isPlaylists ? info?.timeline?.length > 0 : info.id)  &&
                        (<div className='mx-20 py-20' key={index} data-hash={index} >
                          <VideoBox toggleisOpen={toggleisOpen} key={index} isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} isPlaylists={isPlaylists} videoDetail={info} />
                        </div>)
                      }
                    </>
                    )
                })}
                </>:
                <>
                {channelsData.length > 0 ?
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={'auto'}
                    keyboard={{ enabled: true }}
                    // direction="horizontal"
                    mousewheel
                     >
                  {channelsData.map((info, index) => {
                      return(
                      <SwiperSlide className={`${styles.videoSliderSection__slide} mx-20 py-20`} key={index} data-hash={index} >
                        <VideoBox isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} videoDetail={info} />
                      </SwiperSlide>
                      )
                  })}
                  </Swiper>
                :
                <>
                {(isRecentPlay || isMyList) &&
                  <div className="placeholder__box d-flex align-items-center justify-content-center flex-column">
                      <div className="text-center">
                          <img className="mb-4" src={PlayIcon} alt=""/>
                          <p style={{fontSize:'14px' , color:'#C9C9C9'}} className="mb-0 px-5">
                            {isRecentPlay && 'Recently played videos will appear here.'}
                            {isMyList && 'Press the + icon when a video is selected to add here for quick access.' }
                          </p>
                      </div>
                  </div>
                }
                </>
                }
                </>
              }
          </div>
      </>
  )
}

export default Channels
