import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input } from 'reactstrap';
import SubHeader from '../../components/Header/subHeader';
import LoadingButton from '../../components/LoadingButton'
import { updateOperatoryChannelName, updateOperatoryChannelNameRequest } from '../../store/actions/taskAction';
import './styles.scss';
import { history } from '..';
const OperatoryHeader = () => {
    const states = useSelector((state) => state.task);
    const [practice_name, setName] = useState(states.manageOperatoryChannel.practice_name);
    const bgImg = 'assets/img/bg.png'
    
    const changeHandler = (field, value) => {

        setSaveMessage({
            message: "SAVE PRACTICE NAME",
            bgColor: "primary",
        });

        setName({
            practice_name:value
        })
        
        // let manageOperatoryChannel = states.manageOperatoryChannel
        // manageOperatoryChannel[field] = value
        // manageOperatoryChannel['enableButton'] = false
        // dispatch(updateOperatoryChannelNameRequest(manageOperatoryChannel))
    }
    const [saveMessage, setSaveMessage] = useState({
        message: "SAVE PRACTICE NAME",
        bgColor: "primary",
    });

    const dispatch = useDispatch();
   

    useEffect(() => {
        setName({
            practice_name:states.manageOperatoryChannel.practice_name
        })
    },[states.manageOperatoryChannel])

    const handleSubmit = (event) => {
        let params ={
            practice_name:practice_name.practice_name
        }
        dispatch(updateOperatoryChannelName(states.manageOperatoryChannel.id , params))

        setSaveMessage({
            message: "Changes saved",
            bgColor: "green",
        });

        setTimeout(() => { 
            history.goBack()
        }, 2000)
    }
    return (
        <div className="main__screen channels__page  d-flex flex-column h-100 align-items-center justify-content-center">
            <SubHeader className="mb-auto" title="Header"/>
            <div className="waiting-room-header">
                <div className="header-left-side">
                <div className="Page4__main__div1">
                    <div className="row  Page4__main__div1__row">

                        <div className="Page4__main__div1__row__upperDiv">
                            <h1>Practice name</h1>
                            <p>Enter the practice name to be displayed</p>
                            <Form>
                                <FormGroup>
                                    <Input placeholder="" onChange={(e)=> changeHandler('practice_name' , e.target.value)} value={practice_name.practice_name === null || practice_name.practice_name ==="" ? "" : practice_name.practice_name } />
                                </FormGroup>
                                <FormGroup>
                                    {/* <Button color="primary" type="button" onClick={handleSubmit} className=""> {saveMessage.message} </Button> */}
                                    <LoadingButton type="button"  label={saveMessage.message} loading={states.buttonLoading} disabled={states.buttonLoading} size="xxl" onClick={handleSubmit} className={`mt-auto w-100`}  color={saveMessage.bgColor}/>
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                </div>
                </div>
                <div className="header-right-side">
                    <div className="Page4__main__div2">
                        <img src={bgImg} alt=""></img>
                        <label>{ states.manageOperatoryChannel.practice_name }</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OperatoryHeader;
