/** @format */

import React, { useState, useEffect, useRef  } from 'react';
import SubHeader from '../../../components/Header/subHeader';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Input, Form, Button, Spinner , FormGroup ,ListGroup ,ListGroupItem , Label} from 'reactstrap';
import TemporaryDrawer from './TemporaryDrawer';
// import './styles.scss';
import MenuIcon from '@material-ui/icons/Menu';
import IconSharingGrey from '../../../img/IconSharingGrey.svg'
import MoveItemIcon from '../../../img/MoveItemIcon.svg'
import LoadingButton from '../../../components/LoadingButton'
import {InputField} from '../../../components/InputField'
import {clearerror, getWaitingRoomMediaChannel ,getWebsiteRoomMediaChannelList, updateWaitingRoomChannelName, deleteWaitingRoomChannel,restoreWaitingRoomMediaChannel , duplicateWaitingRoomMediaChannel, createChairsidePlaylist, getChairsidePlayListData, getSingleChairsidePlaylist, updateChairsidePlaylist, removeChairsidePlaylist, duplicateChairsidePlaylist } from '../../../store/actions/taskAction';
import Loading from '../../../components/Loading'
import { formatSecond , THALAMUSCHANNEL } from '../../../data/comman';
import Skeleton from "@material-ui/lab/Skeleton";
import { useOnLoadImages } from "../../../components/useOnLoadImages";
import addPlayListPlusIcon from '../../../img/addPlayListPlusIcon.svg' 
import { history } from '../../../pages/index';

import "./playlist.scss"

import SkeletonChannelList from "../../../components/Skeletons/channelList"
import ImageSkeleton from "../../../components/Skeletons/chairsidePlaylistImg"
const WaitingRoomChannels = ({ sortedBy, sortedType }) => {
	const wrapperRef = useRef(null);
	const wrapperRefBox = useRef(null);

	const imagesLoaded = useOnLoadImages(wrapperRef);

	useOutsideAlerter(wrapperRefBox);


	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	const [channelId, setChannelId] = useState(null);
	const [channelOptions, setChannelOptions] = useState(false);
	const [renameOption, setRenameOption] = useState(false);
	const [restoreDefault, setRestoreDefault] = useState(false);
	const [removeChannel, setRemoveChannel] = useState(false);
	const [duplicate, setDuplicateChannel] = useState(false);
	const [sharechannel, setShareChannel] = useState(false);
	const [count, setCount] = useState(0);
	const [renameChannelName, setRenameChannelName] = useState('');
	const [channelRemoveId, setChannelRemoveId] = useState(null);
	const [selectedSortedType, setSelectedSortedType] = useState("all");
	const [imageLoaded, setImageLoaded] = useState([]);
	const [message, setMessage] = useState({
		message: 'Rename',
		color: 'black',
		bgColor: '#eccc63',
	});
	useEffect(() => {
		dispatch(
			getChairsidePlayListData(
				state.practiceUserLoginInfo,
				sortedBy,
				sortedType,
			)
		);
	}, [sortedBy, sortedType]);

	useEffect(() => {
		setRenameChannelName('');
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false)
		setRemoveChannel(false)
		setDuplicateChannel(false)
		setShareChannel(false)
	}, [state.chairsidePlaylistsData]);

	const handleSortedType = (type) => {
		setSelectedSortedType(type);
		dispatch(
			getWebsiteRoomMediaChannelList(
				state.practiceUserLoginInfo,
				sortedBy,
				type,
			)
		);
	}

	const openMenu = (id) => {
		if(channelId === id){
			setChannelId(null);
			setCount(0);
			setRenameOption(false);
			setChannelOptions(false);
			console.log(channelId,'channelId', id , 'id')
		}else{
			setChannelId(id);
			setCount(count + 1);
			setRenameOption(false);
			setChannelOptions(true);
			setRestoreDefault(false)
			setRemoveChannel(false)
			setDuplicateChannel(false)
			setShareChannel(false)
			console.log(channelId,'channelId', id , 'id')
		}

	};
	const renameChannel = (event) => {
		setMessage({
			message: 'Rename',
		});
		setChannelOptions(false);
		setRenameOption(true);
	};

	const restoreDefaultChannel = (event) => {
		setMessage({
			message: 'Restore',
		});
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(true)
	};

	const duplicateChannel = (event) => {
		setMessage({
			message: 'Duplicate',
		});
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false)
		setDuplicateChannel(true)
	};

	const shareChannel = (event) => {
		setMessage({
			message: 'Share',
		});
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false)
		setDuplicateChannel(false)
		setShareChannel(true)

	};

	const renameHandleChange = (event) => {
		setRenameChannelName(event.target.value);
	};

	const onCancel = () =>{
		setChannelOptions(true);
		setRenameOption(false);
		setRestoreDefault(false)
		setRemoveChannel(false)
		setDuplicateChannel(false)
		setShareChannel(false)
		setRenameChannelName('');
		dispatch(clearerror())
	}

	const renameSubmitHandler = (id) => {
		let params={
			title: renameChannelName
		}
		dispatch( updateChairsidePlaylist(id, params) );
		// setRenameChannelName('');
	};

	const shareSubmitHandler = (id) => {
		let params={
			is_shared: "1"
		}
		dispatch( updateChairsidePlaylist(id, params) );
	};

	const restoreSubmitHandler = (id) => {
		dispatch(
			restoreWaitingRoomMediaChannel(id)
		);
	};

	const duplicateSubmitHandler = (id) => {
		dispatch(
			duplicateChairsidePlaylist(id)
		);
	};

	const removeSelectedChannel = (id) => {
		setMessage({
			message: 'yes, remove it',
		});
		setChannelRemoveId(id);
		setRemoveChannel(true)
	};

	const deleteChannelHandler = (id) => {
		dispatch( removeChairsidePlaylist(id) );
	};
	const manageChannel = (id) => {
		setChannelId(id);
		dispatch(getSingleChairsidePlaylist(id, true))
	};
	const isImageLoaded = (id, index) =>{
		// imageLoaded.push(index)
		// setImageLoaded(id[index] = true)
	}

	const onCreatePlaylit=()=>{
        dispatch(createChairsidePlaylist());
    }

	const closeAll = () =>{
		setChannelId(null);
		setCount(0);
		setChannelOptions(false);
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false);
		setRemoveChannel(false);
		setDuplicateChannel(false);
		setShareChannel(false);
	}

	function useOutsideAlerter(ref) {
		useEffect(() => {
		  /**
		   * Alert if clicked on outside of element
		   */
		  function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				closeAll()
			}
		  }
		  // Bind the event listener
		  document.addEventListener("mousedown", handleClickOutside);
		  return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		  };
		}, [ref]);
	  }

	return (
			<>
			    {state.loading &&  <Loading />}
				<div className="chairside_playlist_main_screen d-flex flex-column  custom-scroll mb-auto w-100">
					<Col lg='12'>
						<div className='d-flex flex-wrap row-cols-2  mx-auto' ref={wrapperRefBox}>
						<div className='px-20'>
							<div className='chaiside_create_play_list_box d-flex flex-column align-items-center justify-content-center' style={{cursor:'pointer'}} onClick={()=> onCreatePlaylit()} >
							<img className='mb-5' src={addPlayListPlusIcon} alt="add play list"/>
							<p>Click here to create a new playlist.</p>
							</div>
						</div>
							{state.chairsidePlaylistsData && state.chairsidePlaylistsData.length > 0 ? state.chairsidePlaylistsData.map((datum, index) => {
									var thumb = datum?.timeline[0]?.media_data?.thumb_image || datum?.thumb;
									return (
										<div key={index} className='px-20'>
											<div className={`waiting_room_channel__main__lists__item d-flex ${!imagesLoaded ? '' : datum.created_type}`}>
														<div className='waiting_room_channel__main__lists__item__image_box'>
															<ImageSkeleton data={datum} src={thumb} alt='' isChairsidePlayList={true}/>
															<div className='waiting_room_channel__main__lists__item__video_count d-flex flex-column align-items-center justify-content-between py-5'>
																{datum.is_shared == 1 &&
																	<div className=" btn-link p-0"><img src={IconSharingGrey} alt="" /></div>
																}
																<div className="text-center">
																	<h2>{datum?.timeline?.length}</h2>
																	<label id='overlayLabel' for='overlay'>
																		ITEMS
																	</label>
																</div>
																<div className=" btn-link p-0"><img src={MoveItemIcon} alt="" /></div>
															</div>
														</div>
													
													<div className='col waiting_room_channel__main__lists__item__title_box p-0'>
														<ul className='d-flex flex-column h-100'>
															<div className="d-flex align-items-center justify-content-between align-items-center waiting-header">
																<h5 className="m-0">
																	{datum.title}
																</h5>
																<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id) } />
															</div>
															<div className="channel-play-list">
															{datum.timeline.slice(0,3).map( (datumTwo, index) => {return <div className="py-3 border--bottom sdf">
																	<div className="d-flex justify-content-between">
																			<div className="channel_title">{datumTwo.media_data.name}</div>
																			<span  className="duration">{formatSecond(datumTwo.media_data.duration)}</span>
																	</div>
																	</div>} )}
															</div>

															{channelId == datum.id  && renameOption && (<>
																<div className="chanal_options channel_detail_box d-flex  flex-column h-100">
																	<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
																		<h5 className="m-0"> RENAME </h5>
																		<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id) } />
																	</div>
																	<div className="rename_form mt-4 h-100 d-flex flex-column">
																	<FormGroup className={state.error && state.error.errors && state.error.errors['title'] ? 'inputerror' : ''}>
																		<InputField type="text" isError={state.error && state.error.errors && state.error.errors['title'] && state.error.errors['title'][0]} name='channel'  label={state.error && state.error.errors && state.error.errors['title'] ? "Enter new playlist's name" : 'Please enter a new name for Your playlist'} value={renameChannelName}  onChange={ renameHandleChange } />
																	</FormGroup>
																	<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																		<LoadingButton  className="col mr-3" label={message.message} loading={state.buttonLoading1} disabled={state.buttonLoading1} color="primary" onClick={() => renameSubmitHandler(datum.id) }></LoadingButton>
																		<Button  className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
																	</FormGroup>
																	</div>
																</div>
															</>)}

															{channelId == datum.id && channelOptions  && (<>
																<div className="chanal_options">
																	<div className="d-flex px-3 py-3  justify-content-between align-items-center border--bottom">
																		<h5 className="m-0"> Channel Options </h5>
																		<MenuIcon style={{ width: '2em', 	height: '2em', }}  onClick={() => openMenu(datum.id) } />
																	</div>
																	{(datum.created_type !== THALAMUSCHANNEL || state.practiceInfo.is_internal_user == 1 ) &&
																		<div className="chanal_options--item cc px-3 py-3"  onClick={ channelId == datum.id ? renameChannel : '' }>
																			Rename
																		</div>
																	}
																	{/* {(datum.created_type !== THALAMUSCHANNEL || state.practiceInfo.is_internal_user == 1 ) &&
																		<div className="chanal_options--item px-3 py-3" onClick={ channelId == datum.id ? restoreDefaultChannel : '' }>
																			Restore Default
																		</div>
																	} */}
																	{ !datum.parent_id  &&
																	<div className="chanal_options--item  px-3 py-3" onClick={ channelId == datum.id ? duplicateChannel : '' }>
																		Duplicate
																	</div>
																	}
																	{(datum.created_type !== THALAMUSCHANNEL) && !datum.parent_id && //|| state.practiceInfo.is_internal_user == 1
																		<div className="chanal_options--item px-3 py-3" onClick={ channelId == datum.id ? shareChannel : '' }>
																			Share with linked practices
																		</div>
																	}
																	{((state.practiceUserAccountInfo.id == datum.user_id && datum.created_type !== THALAMUSCHANNEL) || state.practiceInfo.is_internal_user == 1) &&
																	<div className="px-3 py-3 chanal_options--item" onClick={ channelId == datum.id ? removeSelectedChannel : '' } >
																		Remove {datum.parent_id ? 'duplicate' : ''}
																	</div>
																	}
																</div>
															</>)}


															{channelId == datum.id && restoreDefault  && (<>
																<div className="chanal_options channel_detail_box d-flex  flex-column h-100">
																	<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
																		<h5 className="m-0">RESTORE DEFAULT </h5>
																		<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id) } />
																	</div>
																	<div className="rename_form h-100 d-flex flex-column">
																	<FormGroup className="">
																		<Label>The default selection of videos and slides curated by THALAMUS will be restored to this channel’s timeline.</Label>
																	</FormGroup>
																	<FormGroup className="d-flex justify-content-between mt-auto channel-option-btn">
																		<LoadingButton  className="col mr-3" label={message.message} loading={state.buttonLoading} disabled={state.buttonLoading} color="primary" onClick={() => restoreSubmitHandler(datum.id) }></LoadingButton>
																		<Button  className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
																	</FormGroup>
																	</div>
																</div>
															</>)}

															{channelId == datum.id && duplicate  && (<>
																<div className="chanal_options channel_detail_box  d-flex  flex-column h-100">
																	<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
																		<h5 className="m-0">Duplicate</h5>
																		<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id) } />
																	</div>
																	<div className="rename_form mt-4 h-100 d-flex flex-column">
																	<FormGroup className="">
																		<Label>The default selection of videos and slides curated by THALAMUS will be restored to this channel’s timeline.</Label>
																	</FormGroup>
																	<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																		<LoadingButton  className="col mr-3" label={message.message} loading={state.buttonLoading4} disabled={state.buttonLoading4} color="primary" onClick={() => duplicateSubmitHandler(datum.id) }></LoadingButton>
																		<Button  className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
																	</FormGroup>
																	</div>
																</div>
															</>)}

															{channelId == datum.id && sharechannel  && (<>
																<div className="chanal_options channel_detail_box  d-flex  flex-column h-100">
																	<div className="d-flex chanal_options_header  justify-content-between align-items-center border--bottom">
																		<h5 className="m-0">SHARE WITH LINKED PRACTICES</h5>
																		<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id) } />
																	</div>
																	<div className="rename_form mt-4 h-100 d-flex flex-column">
																	<FormGroup className="">
																		<Label>By selecting this option, you’ll be making this  playlist available to all associated practices, allowing any user to view the video and slide selection.</Label>
																	</FormGroup>
																	<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																		<LoadingButton  className="col mr-3" label={message.message} loading={state.buttonLoading1} disabled={state.buttonLoading1} color="primary" onClick={() => shareSubmitHandler(datum.id) }></LoadingButton>
																		<Button  className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
																	</FormGroup>
																	</div>
																</div>
															</>)}



															{channelId == datum.id && removeChannel  && (<>
																<div className="chanal_options channel_detail_box remove_box d-flex  flex-column h-100 bg_red">
																	<div className="d-flex  chanal_options_header  justify-content-between align-items-center border--bottom">
																		<h5 className="m-0">REMOVE</h5>
																		<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id) } />
																	</div>
																	<div className="rename_form mt-4 h-100 d-flex flex-column">
																	<FormGroup className="">
																		<Label>You’re about to remove this playlist, and you won’t have access to this selection any longer. To recriate this playlist's timeline, another playlist will need to be created. Would you like to proceed?</Label>
																	</FormGroup>
																	<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																		<LoadingButton  className="col mr-3" label={message.message} loading={state.buttonLoading3} disabled={state.buttonLoading3} color="default"  onClick={() => deleteChannelHandler(datum.id) }></LoadingButton>
																		<Button  className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
																	</FormGroup>
																	</div>
																</div>
															</>)}

															
															
															{(datum.created_type !== THALAMUSCHANNEL || state.practiceInfo.is_internal_user == 1) &&
																<div className="mt-auto mb-4">
																	<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																		<LoadingButton color="primary" className="col mt-auto" label={"Manage Playlist"} loading={channelId === datum.id?state.buttonLoading:null} disabled={channelId === datum.id?state.buttonLoading:null} onClick={() => manageChannel(datum.id)} ></LoadingButton>
																	</FormGroup>
																</div>
															}
														</ul>

													</div>
											</div>
										</div>
									);
									}
								)
							:
							<>
								
							</>
							}
						</div>
					</Col>
				</div>
			</>
	);
};

export default WaitingRoomChannels;
