import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import SubHeader from '../../components/Header/subHeader'
import './style.scss';
import Profile_icon from '../../img/Profile_icon.svg'
import { Button } from 'reactstrap';
import {  GetAllAuthorizedUsers, ResendInviteUser } from "../../store/actions/taskAction";
import {DateDDMMMYYY} from '../../utilities/HttpUtility';
import { history } from '../index';
import {  InviteUser , CanclInviteUser , GetAuthorizedUser , UpdateAuthorizedUserRequest} from "../../store/actions/taskAction";
import LoadingButton from '../../components/LoadingButton'

import moment from 'moment';

class AuthorizedUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isResendSusses:false,
      dataType:0
    }
    this.addNewUser = this.addNewUser.bind(this);
  }

  componentDidMount(){
    let params={
      privileged:1,
      with_invitee:1,
    }
     this.props.dispatch(GetAllAuthorizedUsers(params));
  }

  getUserType(id){
    switch(id){
        case 0: 
            return  ["guest", "Guest"];
        case 1:
            return  ["dentist", "Normal User"];
        case 2:
            return  ['authorized_user', "Authorized User"]; 
        case 3:
          return    ['manager', "Manager"]; 
        case 4:
          return    ['owner', "Owner"]; 
        default:
          return    ['guest', "Guest"];
    }
  }

 async onClickEdit(id){
  
   await  this.props.dispatch(GetAuthorizedUser(id));
   await  history.push({
      pathname: '/edit-authorized-profile',
      authorizedUserId: id,
      isEditUser: true
    });
  }

  addNewUser(type){
    history.push({
      pathname: '/add-authorized-user',
      type: type
    });
  }

async  onReSend(data){
 await this.setState({isResendSusses:false, dataType:data.type})
   var params ={
      "first_name": data.first_name,
      "last_name": data.last_name,
      "email":data.email,
      "type":data.type
    }
    await this.props.dispatch(ResendInviteUser(params,data.id));
    await this.setState({isResendSusses:true})

    setTimeout(() => { 
       this.setState({isResendSusses:false})
    }, 1200)

  }

  deleteUserProfile(id) {
    this.props.dispatch(CanclInviteUser(id)); 
  }

  render() {
    const {authorizedUserList , practiceUserLoginInfo} = this.props
    
    var authorizedUserListtype =  authorizedUserList && authorizedUserList?.find(x=>x.type === 2)
    var manager =  authorizedUserList && authorizedUserList?.find(x=>x.type === 3)
    var owner =  authorizedUserList && authorizedUserList?.find(x=>x.type === 4)
    
    return (
      <div className="authorized_section__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-0" title="Authorized Users" />
          <div className="authorized_section__main__screen__about__screen transparent__form--white  md d-flex flex-column align-items-center mb-auto">
            <h4 className="mb-4 mt-0">Authorize and edit authorized users</h4>
            <p>Each main practice is allowed 3 authorized users to change account settings.</p>
            <div className="user_profile_section d-flex flex-wrap justify-content-between">
                {owner &&
                    <div className={`user__profile  mb-5 text-center owner `}>
                      <div className={`user__profile__box d-flex flex-column justify-content-center mb-3 ${this.getUserType(owner.type)[0]} `}>
                        <div className="user__profile__picture">
                          { owner.profile_pic ?  
                            <img className="mx-auto my-auto " alt={owner.first_name} src={ owner.profile_pic}/>
                            :
                            <img className="mx-auto my-auto default_img" alt={owner.first_name} src={Profile_icon}/>
                          }
                        </div>
                        <div className={`user__profile__footer py-2 owner`}>
                          {
                            this.getUserType(owner.type)[1]
                          }
                        </div>
                      </div>
                      { owner.is_invitee === 1 ? 
                      <p className="user__profile__name">PENDING</p>
                      :
                      <div className="user__profile__name">{`${owner.first_name || ''} ${owner.last_name || ''}`}</div>
                      }
                      {console.log(owner,'owner')}
                      {this.props.practiceUserProfileInfo.id === owner.id ?
                        <Button type="button" size="sm" onClick={() => this.onClickEdit(owner.id)} className="col" color="primary">Edit</Button>
                      :
                      <>
                      {owner.is_invitee === 1 ?
                        <div>
                          <Button type="button" size="sm" onClick={() => this.onReSend(owner)}  className="col mb-20" color={this.state.isResendSusses && this.state.dataType == 4 ? 'green' : 'primary'}>RESEND REQUEST</Button>
                          <Button type="button" size="sm"  className="col" onClick={() => this.deleteUserProfile(owner.id)} color="red">Cancel</Button>
                        </div>
                        :
                        <>
                        <Button type="button" size="sm" disabled onClick={() => this.onClickEdit(owner.id)} className="col" color="primary">Edit</Button>
                        </>
                      }    
                      </>                      
                      }
                      <>
                        <div className="user_profile_section__owner_detail">
                          <span>{owner.email}</span>
                          <span>{owner.email_alt}</span>
                          <span>{owner.phone}</span>
                          <span>{  moment(owner.dob).format(DateDDMMMYYY)}</span>
                        </div>
                      </>
                    </div>
                }
                {!manager ?
                  <div className="user__profile  mb-5 text-center manager mb-5 ">
                    <div className="user__profile__box manager d-flex flex-column justify-content-center mb-3" >
                      <div className="user__profile__picture">
                        <img className="mx-auto my-auto default_img" src={Profile_icon} alt="" />
                      </div>
                      <div className={`user__profile__footer py-2 manager`}>
                        Manager
                      </div>
                    </div>
                    <div className="user__profile__name">NOT ASSIGNED</div>
                    <Button type="button" size="sm"  onClick={() => this.addNewUser(3)} className="col" color="primary">add user</Button>
                  </div>
                  :
                  <div className={`user__profile  mb-5 text-center manager `}>
                      <div className={`user__profile__box d-flex flex-column justify-content-center mb-3 ${this.getUserType(manager.type)[0]} `}>
                        <div className="user__profile__picture">
                          { manager.profile_pic ?  
                            <img className="mx-auto my-auto " alt={manager.first_name} src={ manager.profile_pic}/>
                            :
                            <img className="mx-auto my-auto default_img" alt={manager.first_name} src={Profile_icon}/>
                          }
                        </div>
                        <div className={`user__profile__footer py-2 manager`}>
                          {
                            this.getUserType(manager.type)[1]
                          }
                        </div>
                      </div>
                      { manager.is_invitee === 1 ? 
                      <p className="user__profile__name">PENDING</p>
                      :
                      <div className="user__profile__name">{`${manager.first_name || ''} ${manager.last_name || ''}`}</div>
                      }
                      {practiceUserLoginInfo.id === manager.id || practiceUserLoginInfo.type === 4 &&  manager.is_invitee === 0 ?
                        <Button type="button" size="sm" onClick={() => this.onClickEdit(manager.id)} className="col" color="primary">Edit</Button>
                      :
                      <>
                      {manager.is_invitee === 1 ?
                        <div>
                          <LoadingButton type="button" size="sm" onClick={() => this.onReSend(manager)} disabled={this.props.buttonLoading4 && this.state.dataType == 3} loading={this.props.buttonLoading4 && this.state.dataType == 3}  className="col mb-20" color={this.state.isResendSusses && this.state.dataType == 3 ? 'green' : 'primary'}>RESEND REQUEST</LoadingButton>
                          <Button type="button" size="sm"  className="col" onClick={() => this.deleteUserProfile(manager.id)} color="red">Cancel</Button>
                        </div>
                        :
                        <>
                        <Button type="button" size="sm" disabled onClick={() => this.onClickEdit(manager.id)} className="col" color="primary">Edit</Button>
                        </>
                      }    
                      </>                      
                      }
                      <>
                        <div className="user_profile_section__owner_detail">
                          <span>{manager.email}</span>
                          <span>{manager.email_alt}</span>
                          <span>{manager.phone}</span>
                          <span>{manager.dob &&  moment(manager.dob).format(DateDDMMMYYY)}</span>
                        </div>
                      </>
                    </div>
                }
                {!authorizedUserListtype ?
                  <div className="user__profile  mb-5 text-center manager mb-5 ">
                    <div className="user__profile__box manager d-flex flex-column justify-content-center mb-3" >
                      <div className="user__profile__picture">
                        <img className="mx-auto my-auto default_img" src={Profile_icon} alt="" />
                      </div>
                      <div className={`user__profile__footer py-2 manager`}>
                        Authorized User
                      </div>
                    </div>
                    <div className="user__profile__name">NOT ASSIGNED</div>
                    <Button type="button" size="sm"  onClick={() => this.addNewUser(2)}   className="col" color="primary">add user</Button>
                  </div>
                  :
                    <div className={`user__profile  mb-5 text-center manager `}>
                      <div className={`user__profile__box d-flex flex-column justify-content-center mb-3 ${this.getUserType(authorizedUserListtype.type)[0]} `}>
                        <div className="user__profile__picture">
                          { authorizedUserListtype.profile_pic ?  
                            <img className="mx-auto my-auto " alt={authorizedUserListtype.first_name} src={ authorizedUserListtype.profile_pic}/>
                            :
                            <img className="mx-auto my-auto default_img" alt={authorizedUserListtype.first_name} src={Profile_icon}/>
                          }
                        </div>
                        <div className={`user__profile__footer py-2 manager`}>
                          {
                            this.getUserType(authorizedUserListtype.type)[1]
                          }
                        </div>
                      </div>
                      { authorizedUserListtype.is_invitee === 1 ? 
                      <p className="user__profile__name">PENDING</p>
                      :
                      <div className="user__profile__name">{`${authorizedUserListtype.first_name || ''} ${authorizedUserListtype.last_name || ''}`}</div>
                      }
                      {practiceUserLoginInfo.id === authorizedUserListtype.id  || practiceUserLoginInfo.type === 4 &&  authorizedUserListtype.is_invitee === 0?
                        <Button type="button" size="sm" onClick={() => this.onClickEdit(authorizedUserListtype.id)} className="col" color="primary">Edit</Button>
                      :
                      <>
                      {authorizedUserListtype.is_invitee === 1 ?
                        <div>
                          <LoadingButton type="button" size="sm" onClick={() => this.onReSend(authorizedUserListtype)} disabled={this.props.buttonLoading4 && this.state.dataType == 2} loading={this.props.buttonLoading4 && this.state.dataType == 2}  className="col mb-20" color={this.state.isResendSusses && this.state.dataType == 2 ? 'green' : 'primary'}>RESEND REQUEST</LoadingButton>
                          <Button type="button" size="sm"  className="col" onClick={() => this.deleteUserProfile(authorizedUserListtype.id)} color="red">Cancel</Button>
                        </div>
                        :
                        <>
                        <Button type="button" size="sm" disabled onClick={() => this.onClickEdit(authorizedUserListtype.id)} className="col" color="primary">Edit</Button>
                        </>
                      }    
                      </>                      
                      }
                      <>
                        <div className="user_profile_section__owner_detail">
                          <span>{authorizedUserListtype.email}</span>
                          <span>{authorizedUserListtype.email_alt}</span>
                          <span>{authorizedUserListtype.phone}</span>
                          <span>{authorizedUserListtype.dob &&  moment(authorizedUserListtype.dob).format(DateDDMMMYYY)}</span>
                        </div>
                      </>
                    </div>
                }
            </div>
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.task.error,
  authorizedUserList:state.task.authorizedUserList,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  practiceUserProfileInfo:state.task.practiceUserProfileInfo,
  isResendSusses:state.task.isResendSusses,
  buttonLoading4:state.task.buttonLoading4
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AuthorizedUser);