import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { BLANKSLIDE, XOFFSET, YOFFSET, XIMGOFFSET, YIMGOFFSET, SMOTHDRAG, MINHEIGHT, MINWIDTH, MAXHEIGHT, MAXWIDTH, SMOTHRESIZE, SMOTHRESIZERATIO, fontSize, 
    editableImage, editableImage1, editableImage2, editableImage3, editableImage4, editableImage5, editableImage6, editableImage7, editableImage8, editableImage9, editableImage10,
    editableText, editableText1, editableText2, editableText3, editableText4, editableText5, editableText6, editableText7,  editableText8,  editableText9,  editableText10,
    draggableComp, draggableImgComp, moveableImageSectionId, moveableSectionId, TEXT, editableTex1, IMAGE, BLANKSLIDEBG, SLIDEZOOMFACTOR, moveableImageSectionId1, moveableSectionId1, moveableImageSectionId2, moveableImageSectionId3, moveableImageSectionId4, moveableImageSectionId5, moveableImageSectionId6, moveableImageSectionId7, moveableImageSectionId8, moveableImageSectionId9, moveableImageSectionId10, moveableSectionId10, moveableSectionId2, moveableSectionId3, moveableSectionId4, moveableSectionId5, moveableSectionId6, moveableSectionId7, moveableSectionId8, moveableSectionId9, draggableComp1, draggableComp2, draggableComp3, draggableComp4, draggableComp5, draggableComp6, draggableComp7, draggableComp8, draggableComp9, draggableComp10, draggableImgComp2, draggableImgComp3, draggableImgComp4, draggableImgComp5, draggableImgComp6, draggableImgComp7, draggableImgComp8, draggableImgComp9, draggableImgComp10, draggableImgComp1, IMAGECOMPONENT, TEXTCOMPONENT } from '../../data/comman';
import './styles.scss';
import he from 'he';
import { Button } from 'reactstrap';
import Moveable from "react-moveable";
import MoveableHelper from "moveable-helper";

var zoomFactor = 10 * (SLIDEZOOMFACTOR - 0.2);

const CustomSlide = forwardRef(function CustomSlide({ selectedSlide, changeTab, setIsSlidePreset, setIsRemoveSlidePreset, setBgOpacity, setStyleOptions, styleOptions, target, setTarget }, ref){

    useImperativeHandle(
        ref,
        () => ({
            getSlideContent() {
                return saveSlide();
            },
            reIntEvents(){
                return initEvents()
            }
        }),
    )
    const [helper] = React.useState(() => {
        return new MoveableHelper();
    })

    // const elementGuidelines = [].slice.call(
    //     document.querySelectorAll(".moveable")
    // );

   
    const script = document.createElement("script");
    script.src = "https://cdn.ckeditor.com/4.7.0/standard-all/ckeditor.js";//standard-all/ckeditor.js
    const script1 = document.createElement("script");
    script1.src = "https://s3.us-west-2.amazonaws.com/thalamus.media.live/waiting-room-slide-preset-assets/js/custom.js";
    // script.async = true;
    script.onload = () => scriptLoaded();
    document.body.appendChild(script);
    document.body.appendChild(script1);

    /// script load
    const scriptLoaded = () => {
        try{
            // blank script load and border
            // var isBlankSLide = !!document.getElementById(moveableSectionId)
            // var moveable = document.getElementById(moveableSectionId);
            // var draggableButton = document.getElementById(draggableComp);
            // var moveable1 = document.getElementById(moveableImageSectionId);
            // var draggableButton1 = document.getElementById(draggableImgComp);

            var moveableContainers = document.querySelectorAll(".moveable-container");
            var moveableButtons = document.querySelectorAll(".moveable-button");
            
            // if(isBlankSLide){
                // moveable.style.borderWidth = 'thin';
                // draggableButton.style.display = 'block';
                // moveable1.style.borderWidth = 'thin';
                // draggableButton1.style.display = 'block';
                [].forEach.call(moveableContainers, function(moveableContainer) {
                    if(moveableContainer)
                        moveableContainer.style.borderWidth = 'thin';
                });

                [].forEach.call(moveableButtons, function(moveableButton) {
                    if(moveableButton)
                        moveableButton.style.display = 'block';
                });
                setIsSlidePreset(false);
                setIsRemoveSlidePreset(false);

                var blankslideBg = document.getElementById(BLANKSLIDEBG);
                if(blankslideBg){
                    var opacity = blankslideBg.style.opacity;
                    setBgOpacity(opacity ? +opacity * 100 : 100);
                }
            // }
        }catch(e){console.log("slide...!", e)}

        window.CKEDITOR.dtd.$editable.span = 1;
        window.CKEDITOR.config.toolbarLocation = 'top';
        window.CKEDITOR.config.extraPlugins = 'sharedspace,font,toolbar,undo,panel'//,balloontoolbar,linkballoon,image2'// sourcedialog 
        window.CKEDITOR.config.toolbar = [
            { name: 'clipboard', items: [ 'Undo', 'Redo', "Bold", "Italic", "Underline" ] },
            {name: "basicstyles", itmes: ["Bold", "Italic", "Underline"]},
            { name: 'styles', items: [ 'FontSize' ] },
            { name: 'other', items: ['customBold']}
        ];

        // window.CKEDITOR.config.fontSize_sizes = '9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px';
        
        window.CKEDITOR.config.toolbarCanCollapse = true;
        window.CKEDITOR.config.fontSize_defaultLabel = '';
        window.CKEDITOR.config.sharedSpaces = {
            top: 'topToolbar',
            // bottom: 'bottomToolbar'
        };

        window.CKEDITOR.config.allowedContent = true;
        // window.CKEDITOR.config.coreStyles_bold = {
        //     element: 'span',
        //     styles: { 'font-weight': 'bold' }
        // };

        try{
            var toolbar = document.getElementById("topToolbar");
            if(toolbar && toolbar.innerHTML)
                document.getElementById("custom_undo").innerHTML = toolbar.innerHTML;
        } catch(e){}

        // config  toolbar hide/show
       var imageExist = !!document.getElementById(editableImage);
       var imageExist1 = !!document.getElementById(editableImage1);
       var imageExist2 = !!document.getElementById(editableImage2);
       var imageExist3 = !!document.getElementById(editableImage3);
       var imageExist4 = !!document.getElementById(editableImage4);
       var imageExist5 = !!document.getElementById(editableImage5);
       var imageExist6 = !!document.getElementById(editableImage6);
       var imageExist7 = !!document.getElementById(editableImage7);
       var imageExist8 = !!document.getElementById(editableImage8);
       var imageExist9 = !!document.getElementById(editableImage9);
       var imageExist10 = !!document.getElementById(editableImage10);
       
       //editable text
       var textExist = !!document.getElementById(editableText);
       var textExist1 = !!document.getElementById(editableText1);
       var textExist2 = !!document.getElementById(editableText2);
       var textExist3 = !!document.getElementById(editableText3);
       var textExist4 = !!document.getElementById(editableText4);
       var textExist5 = !!document.getElementById(editableText5);
       var textExist6 = !!document.getElementById(editableText6);
       var textExist7 = !!document.getElementById(editableText7);
       var textExist8 = !!document.getElementById(editableText8);
       var textExist9 = !!document.getElementById(editableText9);
       var textExist10 = !!document.getElementById(editableText10);

       var draggableButton = document.getElementById(draggableComp);

       

       // editable image
       if(imageExist){
            document.getElementById(editableImage).onclick = () => {
                changeTab(null, 2, editableImage, IMAGECOMPONENT);/// selected tab image 
            }
        }

        if(imageExist1){
            document.getElementById(editableImage1).onclick = (e) => { changeTab(null, 2, editableImage1, IMAGECOMPONENT); /*selected tab image */}
        }

        if(imageExist2){
            document.getElementById(editableImage2).onclick = (e) => { changeTab(null, 2, editableImage2, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist3){
            document.getElementById(editableImage3).onclick = (e) => { changeTab(null, 2, editableImage3, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist4){
            document.getElementById(editableImage4).onclick = (e) => { changeTab(null, 2, editableImage4, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist5){
            document.getElementById(editableImage5).onclick = (e) => { changeTab(null, 2, editableImage5, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist6){
            document.getElementById(editableImage6).onclick = (e) => { changeTab(null, 2, editableImage6, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist7){
            document.getElementById(editableImage7).onclick = (e) => { changeTab(null, 2, editableImage7, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist8){
            document.getElementById(editableImage8).onclick = (e) => { changeTab(null, 2, editableImage8, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist9){
            document.getElementById(editableImage9).onclick = (e) => { changeTab(null, 2, editableImage9, IMAGECOMPONENT); /*selected tab image */}
        }
        if(imageExist10){
            document.getElementById(editableImage10).onclick = (e) => { changeTab(null, 2, editableImage10, IMAGECOMPONENT); /*selected tab image */}
        }

        // 
        if(textExist){
            // const resizers = ele.querySelectorAll('.editableText');
            var editTextbox = document.getElementById(editableText);
            editTextbox.onclick = () => {
                changeTab(null, 1, editableText, TEXTCOMPONENT);
                setSelectedStyle();
            }
        }

        if(textExist1){
            var editTextbox1 = document.getElementById(editableText1);
            editTextbox1.onclick = () => {
                changeTab(null, 1, editableText1, TEXTCOMPONENT);/// selected tab text
                setSelectedStyle();
            };
        }

        if(textExist2){
            var editTextbox2 = document.getElementById(editableText2)
            editTextbox2.onclick = () => {
                changeTab(null, 1, editableText2, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }

        if(textExist3){
            document.getElementById(editableText3).onclick = () => {
                changeTab(null, 1, editableText3, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }
        if(textExist4){
            document.getElementById(editableText4).onclick = () => {
                changeTab(null, 1, editableText4, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }
        if(textExist5){
            document.getElementById(editableText5).onclick = () => {
                changeTab(null, 1, editableText5, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }
        if(textExist6){
            document.getElementById(editableText6).onclick = () => {
                changeTab(null, 1, editableText6, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }
        if(textExist7){
            document.getElementById(editableText7).onclick = () => {
                changeTab(null, 1, editableText7, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }
        if(textExist8){
            document.getElementById(editableText8).onclick = () => {
                changeTab(null, 1, editableText8, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            }
        }
        if(textExist9){
            document.getElementById(editableText9).onclick = () => {
                changeTab(null, 1, editableText9, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }
        if(textExist10){
            document.getElementById(editableText10).onclick = () => {
                changeTab(null, 1, editableText10, TEXTCOMPONENT); /*selected tab text */
                setSelectedStyle();
            };
        }

        initEvents();
    }

    const initEvents = () => {
        var draggableButton = document.getElementById(draggableComp);
        var draggableButton1 = document.getElementById(draggableComp1);
        var draggableButton2 = document.getElementById(draggableComp2);
        var draggableButton3 = document.getElementById(draggableComp3);
        var draggableButton4 = document.getElementById(draggableComp4);
        var draggableButton5 = document.getElementById(draggableComp5);
        var draggableButton6 = document.getElementById(draggableComp6);
        var draggableButton7 = document.getElementById(draggableComp7);
        var draggableButton8 = document.getElementById(draggableComp8);
        var draggableButton9 = document.getElementById(draggableComp9);
        var draggableButton10 = document.getElementById(draggableComp10);
        
        var draggableImgButton = document.getElementById(draggableImgComp);
        var draggableImgButton1 = document.getElementById(draggableImgComp1);
        var draggableImgButton2 = document.getElementById(draggableImgComp2);
        var draggableImgButton3 = document.getElementById(draggableImgComp3);
        var draggableImgButton4 = document.getElementById(draggableImgComp4);
        var draggableImgButton5 = document.getElementById(draggableImgComp5);
        var draggableImgButton6 = document.getElementById(draggableImgComp6);
        var draggableImgButton7 = document.getElementById(draggableImgComp7);
        var draggableImgButton8 = document.getElementById(draggableImgComp8);
        var draggableImgButton9 = document.getElementById(draggableImgComp9);
        var draggableImgButton10 = document.getElementById(draggableImgComp10);

        // editable component ids (resize components)
        // images
        var moveableImgSection = document.getElementById(moveableImageSectionId);
    
        // texts
        var moveableSection = document.getElementById(moveableSectionId);
        
        // =========================blank slide drag and drag and drop===================================*//
        /// ======================== resize start ========================
        var isExistMovable = !!document.getElementById(moveableImageSectionId) || !!document.getElementById(moveableImageSectionId1) || !!document.getElementById(moveableSection) || !!document.getElementById(moveableSectionId1);
        if(isExistMovable){
            //images
            const ele = document.getElementById(moveableImageSectionId);
            const ele1 = document.getElementById(moveableImageSectionId1);
            const ele2 = document.getElementById(moveableImageSectionId2);
            const ele3 = document.getElementById(moveableImageSectionId3);
            const ele4 = document.getElementById(moveableImageSectionId4);
            const ele5 = document.getElementById(moveableImageSectionId5);
            const ele6 = document.getElementById(moveableImageSectionId6);
            const ele7 = document.getElementById(moveableImageSectionId7);
            const ele8 = document.getElementById(moveableImageSectionId8);
            const ele9 = document.getElementById(moveableImageSectionId9);
            const ele10 = document.getElementById(moveableImageSectionId10);
            
            // texts
            const eleText = document.getElementById(moveableSectionId);  
            const eleText1 = document.getElementById(moveableSectionId1);  
            const eleText2 = document.getElementById(moveableSectionId2);  
            const eleText3 = document.getElementById(moveableSectionId3);  
            const eleText4 = document.getElementById(moveableSectionId4);  
            const eleText5 = document.getElementById(moveableSectionId5);  
            const eleText6 = document.getElementById(moveableSectionId6);  
            const eleText7 = document.getElementById(moveableSectionId7);  
            const eleText8 = document.getElementById(moveableSectionId8);  
            const eleText9 = document.getElementById(moveableSectionId9);  
            const eleText10 = document.getElementById(moveableSectionId10);  

            const resizers = document.querySelectorAll('.moveable-image-container .resizer')//ele.querySelectorAll('.resizer');
            const resizersText = document.querySelectorAll('.moveable-text-container .resizer'); //eleText.querySelectorAll('.resizer');
            let isEditableComponent = null;
            let x = 0;
            let y = 0;
            let w = 0;
            let h = 0;
            let currentText = null;
            let cureeentImage = null;

            // const mouseMoveHandler = function(e) {
            //     var mainContainer = document.getElementsByClassName("main-container");
            //     const dx = e.clientX - x;
            //     const dy = e.clientY - y;
            //     var newHeight = h + dy;
            //     var newWidth = w + dx; 
            //     if( newHeight > MINHEIGHT && newWidth > MINWIDTH && newHeight < MAXHEIGHT && newWidth < MAXWIDTH){
            //         if(isEditableComponent == IMAGE) {
            //             cureeentImage.style.width = `${newWidth * SMOTHRESIZERATIO}px`;
            //             cureeentImage.style.height = `${newHeight * 1.2}px`;
            //         }else if(isEditableComponent == TEXT){
            //             currentText.style.width = `${newWidth * SMOTHRESIZERATIO}px`;
            //             currentText.style.height = `${newHeight * 1.2}px`;
            //            // document.getElementById(editableText).style.fontSize = `${((newWidth)/SMOTHRESIZE + (newHeight)/SMOTHRESIZE)/2}px`;
            //         //    currentText.querySelector('p').style.fontSize = `${((newWidth)/SMOTHRESIZE + (newHeight)/SMOTHRESIZE)/2}px`;
            //         }
            //     }
            // };

            // const mouseUpHandler = function() {
            //     document.removeEventListener('mousemove', mouseMoveHandler);
            //     document.removeEventListener('mouseup', mouseUpHandler);
            // };

            // const mouseDownHandler = function(e) {
            //     x = e.clientX;
            //     y = e.clientY;

            //     var styles;
            //     if(e.target.parentNode == ele || e.target.parentNode == ele1 || e.target.parentNode == ele2 || e.target.parentNode == ele3 || e.target.parentNode == ele4 || e.target.parentNode == ele5 ||
            //         e.target.parentNode == ele6 || e.target.parentNode == ele7 || e.target.parentNode == ele8 || e.target.parentNode == ele9 || e.target.parentNode == ele10) {
            //         styles = window.getComputedStyle(e.target.parentNode);
            //         isEditableComponent = IMAGE;
            //         cureeentImage = e.target.parentNode;
            //     }else if(e.target.parentNode == eleText || e.target.parentNode == eleText1 || e.target.parentNode == eleText2 ||e.target.parentNode == eleText3 || e.target.parentNode == eleText4 || e.target.parentNode == eleText5 ||
            //          e.target.parentNode == eleText6 || e.target.parentNode == eleText7 || e.target.parentNode == eleText8 || e.target.parentNode == eleText9 || e.target.parentNode == eleText10){
            //         styles = window.getComputedStyle(e.target.parentNode);
            //         isEditableComponent = TEXT;
            //         currentText = e.target.parentNode;
            //     }
                
            //     w = parseInt(styles.width, 10);
            //     h = parseInt(styles.height, 10);
                
            //     // Attach the listeners to `document`
            //     document.addEventListener('mousemove', mouseMoveHandler);
            //     document.addEventListener('mouseup', mouseUpHandler);
            // };

            // // Loop over them
            // [].forEach.call(resizers, function(resizer) {
            //     if(resizer)
            //         resizer.addEventListener('mousedown', mouseDownHandler);	
            // });

            // [].forEach.call(resizersText, function(resizer) {
            //     if(resizer)
            //         resizer.addEventListener('mousedown', mouseDownHandler);	
            // });
            

            //======================== resize end ========================


            // ======================== drag start ========================
            var container = document.getElementById('mainEditor');
            container.addEventListener("mousedown", dragStart, false);
            container.addEventListener("mouseup", dragEnd, false);
            container.addEventListener("mousemove", drag, false);
            var currentX;
            var currentY;
            var initialX;
            var initialY;
            var xOffset = XOFFSET;
            var yOffset = YOFFSET;
            var active = false;

            // image
            var currentImgX;
            var currentImgY;
            var initialImgX;
            var initialImgY;
            var xImgOffset = XIMGOFFSET;
            var yImgOffset = YIMGOFFSET;
            var imgActive = false

            function dragStart(e) {
                if(e.target.parentNode == ele || e.target.parentNode == ele1 || e.target.parentNode == ele2 || e.target.parentNode == ele3 || e.target.parentNode == ele4 || e.target.parentNode == ele5 ||
                    e.target.parentNode == ele6 || e.target.parentNode == ele7 || e.target.parentNode == ele8 || e.target.parentNode == ele9 || e.target.parentNode == ele10) {
                        cureeentImage = e.target.parentNode;
                }else if(e.target.parentNode == eleText || e.target.parentNode == eleText1 || e.target.parentNode == eleText2 ||e.target.parentNode == eleText3 || e.target.parentNode == eleText4 || e.target.parentNode == eleText5 ||
                    e.target.parentNode == eleText6 || e.target.parentNode == eleText7 || e.target.parentNode == eleText8 || e.target.parentNode == eleText9 || e.target.parentNode == eleText10){
                        currentText = e.target.parentNode;
                    if(target.length > 0)
                        setTarget([]);
                }

                if (e.target === draggableButton || e.target === draggableButton1 || e.target === draggableButton2 || e.target === draggableButton3 || e.target === draggableButton4 || e.target === draggableButton5 ||
                    e.target === draggableButton6 || e.target === draggableButton7 || e.target === draggableButton8 || e.target === draggableButton9 || e.target === draggableButton10) {
                    initialX = e.clientX - xOffset;
                    initialY = e.clientY - yOffset;
                    active = true;
                }else if(e.target == draggableImgButton || e.target == draggableImgButton1 || e.target == draggableImgButton2 || e.target == draggableImgButton3 || e.target == draggableImgButton4 || e.target == draggableImgButton5 ||
                    e.target == draggableImgButton6 || e.target == draggableImgButton7 || e.target == draggableImgButton8 || e.target == draggableImgButton9 || e.target == draggableImgButton10 ){  //|| e.target === resizeImgSection
                    initialImgX = e.clientX - xImgOffset;
                    initialImgY = e.clientY - yImgOffset;
                    imgActive = true;
                }
            }

            function dragEnd(e) {
                // if(e.target === draggableButton){
                //     initialX = currentX;
                //     initialY = currentY;
                // }else if(e.target === draggableImgButton ){ //|| e.target === resizeImgSection
                //     initialImgX = currentImgX;
                //     initialImgY = currentImgY;
                // }
                //text
                // currentX = null;
                // currentY = null;
                // initialX = null;
                // initialY = null;
                // xOffset = XOFFSET / 4;
                // yOffset = YOFFSET / 4;
                
                // // image
                // currentImgX = null;
                // currentImgY = null;
                // initialImgX = null;
                // initialImgY = null;
                // xImgOffset = XIMGOFFSET ;
                // yImgOffset = YIMGOFFSET;

                active = false;
                imgActive = false;
            }

            function drag(e) {
                if (active) {
                    e.preventDefault();
                    currentX = e.clientX - initialX;
                    currentY = e.clientY - initialY;
                    xOffset = currentX;
                    yOffset = currentY;
                    setTranslate(currentX * SMOTHDRAG, currentY * SMOTHDRAG, currentText);
                }
                if(imgActive){
                    e.preventDefault();
                    currentImgX = e.clientX - initialImgX;
                    currentImgY = e.clientY - initialImgY;
                    xImgOffset = currentImgX;
                    yImgOffset = currentImgY;
                    setTranslate(currentImgX * SMOTHDRAG, currentImgY  * SMOTHDRAG, cureeentImage);
                }
            }
        
            function setTranslate(xPos, yPos, el) {
                el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
            }
        }

        // ======================== drag end ========================
        // =========================blank slide drag and drag and drop end===================================*/
    }

    const saveSlide = () => {
        if(window.CKEDITOR){
            // var data = window.CKEDITOR.instances.editor1.getData();
            var element = document.getElementById('mainEditor');
            // remove border and remove buttons
            // var isBlankSLide = !!document.getElementById(moveableSectionId)
            // var moveable = document.getElementById(moveableSectionId);
            // var draggableButton = document.getElementById(draggableComp);
            
            // var moveable1 = document.getElementById(moveableImageSectionId);
            // var draggableButton1 = document.getElementById(draggableImgComp);

            var moveableContainers = document.querySelectorAll(".moveable-container");
            var moveableButtons = document.querySelectorAll(".moveable-button");
            try{
                // if(isBlankSLide){
                    // moveable.style.borderWidth = 0;
                    // draggableButton.style.display = 'none';
                    // moveable1.style.borderWidth = 0;
                    // draggableButton1.style.display = 'none'; //.remove();
                    
                    [].forEach.call(moveableContainers, function(moveableContainer) {
                        if(moveableContainer)
                            moveableContainer.style.borderWidth = 0;
                    });

                    [].forEach.call(moveableButtons, function(moveableButton) {
                        if(moveableButton)
                            moveableButton.style.display = 'none';
                    });

                    var moveableControlBox = document.getElementsByClassName("moveable-control-box")[0];
                    moveableControlBox.remove();

                    // var moveableLine = document.getElementsByClassName("moveable-line"); 
                    // if(moveableLine)
                    //     moveableLine.style.display = 'none';

                    // var moveableControll = document.getElementsByClassName("moveable-control"); 
                    // if(moveableControll)
                    //     moveableControll.style.display = 'none';

                // }
            }catch(e){
                console.log("Error=>",e)
            }
            console.log(element.innerHTML);
            return element.innerHTML;
        }
        return "";
    }

    const setSelectedStyle = () => {
        try{
            var _node = window.getSelection().anchorNode.parentNode;
            var isBold = _node.style.bold ? true : (_node.style.fontWeight == "bold" ? true : (_node.tagName.toLowerCase() == "b" ? true : getComputedStyle(_node).fontWeight > 400));
            var isItalic = _node.style.italic ? true : (_node.style.fontStyle == "italic" ? true : (_node.tagName.toLowerCase() == "i" ? true : getComputedStyle(_node).fontStyle == "italic"));
            var isUnderLine = _node.style.underline ? true : ( _node.style.textDecorationLine == "underline" ? true : (_node.tagName.toLowerCase() == "u" ? true : getComputedStyle(_node).textDecorationLine == "underline"));
            var isFontSize =  _node.style.fontSize ? _node.style.fontSize : null;
            var lineHeight = 0;
        
            var parentNode = document.getElementById(`moveable_${_node.id}`);
            if(parentNode){
                var mLineHeight = getComputedStyle(parentNode).lineHeight;
                lineHeight = mLineHeight.slice(0, -2) / 10;
            }
            
            setStyleOptions({
                ...styleOptions,
                bold: isBold,
                italic: isItalic,
                underline: isUnderLine,
                lineHeight,
                selectedFontSize: isFontSize ? isFontSize.substring(0, isFontSize.length - 2) / zoomFactor : null
            })
        }catch(e){
            console.log("set selected e12",e);
        }
    }
    
    return (
        <>
            <div style={{ height: 541, width: 960, overflow: 'hidden', position: 'relative' }}>
                <div id="topToolbar" style={{display: 'none'}}></div>  
                {/* <div
                    id="element"
                    className="element"
                    style={{
                    marginTop: 100,
                    marginLeft: 350,
                    width: 100,
                    height: 100,
                    }}>
                    <div id="moveable_image_editable_image" class="moveable-container moveable-image-container1">
                        <img draggable="false" id="editable_image" class="blank_image editable_image" src="assets/img/blank_slide_holder.jpeg"/>
                    </div>
                </div> */}
   
                <div className={`mainEditor ${target.length > 0 ? "main-editor-moveable-target" : ""}`} id="mainEditor" style={{  zoom: SLIDEZOOMFACTOR, position: 'relative' }}>
                {target.length > 0 &&
                    <Moveable
                        target={target}
                        draggable={true}
                        resizable={true}
                        rotatable={false}
                        origin={false}
                        edge={true}
                        // scalable={true}
                        onDragStart={helper.onDragStart}
                        onDrag={helper.onDrag}
                        onResizeStart={helper.onResizeStart}
                        onResize={helper.onResize}
                        onRotateStart={helper.onRotateStart}
                        onRotate={helper.onRotate}
                        // elementGuidelines={elementGuidelines}
                    />}
                    <div style={{ height: '1080px' }} dangerouslySetInnerHTML={{ __html: 
                        `${he.decode(selectedSlide)}`
                    }}/>
                    </div>
                {/* <div id="bottomToolbar"></div> */}
            </div>
            {/* <Button onClick={saveSlide}>
                Save Slide
            </Button> */}
        </>
    )
})

export default CustomSlide;
