import axios from 'axios';
import { clearStorage, getToken, getDeviceCode } from '../helper/LocalStorage';
import { history } from '../pages';
import { connect } from "react-redux";
import { unAuthorized } from '../store/actions/taskAction';

export const CLOSE = "/thalamusclose";
export const MINIMIZE = "/thalamusminimize";
export const FULLSCREEN = "/thalamusfullscreen ";
export const EXITFULLSCREEN = "/thalamusexitfullscreen ";

export const VIDEO_QUALITY_AUTO = 0;
export const VIDEO_QUALITY_4K = 5;
export const VIDEO_QUALITY_FHD = 4;
export const VIDEO_QUALITY_HD = 3;
export const VIDEO_QUALITY_540 = 2;


export const WAITION_ROOM_VIDEO_QUALITY_AUTO = 3840;
export const WAITION_ROOM_VIDEO_QUALITY_4K = 3840;
export const WAITION_ROOM_VIDEO_QUALITY_FHD = 1080;
export const WAITION_ROOM_VIDEO_QUALITY_HD = 720;
export const WAITION_ROOM_VIDEO_QUALITY_540 = 540;



export const SIGNIN = "/thalamussignin";
export const LOGOUT = "/thalamuslogout";

export const FBBASEURL = "https://graph.facebook.com/";

export const SOCKETURL = "https://51e7-103-81-119-30.ngrok.io";


export const APIBASEURL = process.env.REACT_APP_BASE_URL; //"https://api.thalamusmedia.com/";
export const WISTIA_TOKEN = 'Bearer '+process.env.REACT_APP_WISTIA_UPLOAD_TOKEN;
export const WISTIA_PROJECT_ID = process.env.REACT_APP_WISTIA_PROJECT_ID;


export const UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE = "https://upload.wistia.com/";
export const HELPERTYPE = APIBASEURL + "helper?types="

export const TOS = APIBASEURL + "tos";
export const TOSLATEST = TOS + "/latest";
export const TOSACCEPT = TOS + "/accept";
export const TOSCOMPLAINT = TOS + "/complaint";

export const PRACTICE = APIBASEURL + "practice";
export const PRACTICELOGIN = PRACTICE + "/login";
export const PRACTICE_USER_LOGIN = PRACTICE + "/user/login";
export const PRACTICE_USER_LOGOUT = PRACTICE + "/user/logout";
export const PRACTICERECOVERCODE = PRACTICE + "/recover-access-code";//"/recover-code";
export const PRACTICERECOVERCODEBYPRACTICE = PRACTICE + "/recover-access-code-by-practice";
export const PRACTICEPROFILE = PRACTICE + "/profile";
export const PRACTICEPROFILE_IDENTIFY = PRACTICE + "/user/profile/identify";
export const PRACTICE_PROFILE_VERIFY = PRACTICE + "/user/profile/verify";
export const PRACTICE_USER_PROFILE_VERIFY = PRACTICE + "/user/profile/verify/update";
export const PRACTICEUSER = PRACTICE + "/user";
export const PRACTICEUSERPROFILE = PRACTICE + "/user/profile";
export const AUTHORIZED_DEVICE = PRACTICE + "/device";
export const PRACTICE_DEVICE = PRACTICE + "/device";
export const PRACTICE_PROFILE_CHANGE_PASSWORD = PRACTICEUSER + "/profile/change-password";
export const UPDATE_PRACTICEUSER = PRACTICE + "/user";
export const CREATE_NEW_PRACTICEUSER = PRACTICE + "/user";
export const PHONE_VERIFICATION = PRACTICE + "/phone-verification";
export const EMAIL_VERIFICATION = PRACTICE + "/email-verification";

export const PRACTICESETUP = PRACTICE + "/setup";
export const VERIFYED_OTP = APIBASEURL + "verify/otp";
export const INVOICE = PRACTICE + "/invoice";
export const INVOICEVIEW = PRACTICE + "/invoice";
export const SUBSCRIBE = PRACTICE + "/subscribe";
export const CORPORATE = APIBASEURL + "corporate";
export const CORPORATE_WAITING_ROOM_CHANNEL = CORPORATE + "/channel/waiting-room";
export const CORPORATE_OPERATORY_ROOM_CHANNEL = CORPORATE + "/channel/operatory-room";
export const PRACTICE_CONTACTUS = PRACTICE + "/connect/practice-contact-us";




export const TYPE_EMAIL_PREFERENCES_lIST = APIBASEURL + "helper/email-preferences";
export const SELECTED_EMAIL_PREFERENCES = APIBASEURL + "email-preferences";
export const HARDWARELIST = APIBASEURL + "hardware"

export const GET_PRACTICE_NOTIFICATION = PRACTICE + "/notification";
export const GET_OPTION = PRACTICE + "/price/option";

export const HELPERURI = APIBASEURL + "helper/";
export const DEFAULT_PROFILE_PICTURE = APIBASEURL + "helper/profile-pic";

export const TYPE_SPECIALIZATION = "specialization";
export const TYPE_PREACTICEUSER = "practice_user_type";
export const TYPE_COUNTRY = "country";
export const TYPE_OWNERSHIP = "ownership";

export const TYPE_LEAD_SOURCE = "lead_source_types";
export const TYPE_PRACTICE_SOFTWARES = "practice_softwares";
export const TYPE_PRACTICE_CUSROMER_TYPES = "practice_customer_types";
export const TYPE_PRACTICE_USER_ROLES = "practice_user_roles";
export const TYPE_SUBSCRIPTION_TYPES = "subscription_type";
export const TYPE_MEDIA_LANGUAGE = "media_language";
export const TYPE_SYSTEM_LANGUAGE = "system_language";
export const TYPE_CC_CLOSE_CAPTION_SECTION = "cs_video_section_types";

export const TYPE_PRACTICE_SECURITYQUE = "security_question";
export const TYPE_GENDER = "gender";
export const TYPE_DEFAULT_CAPTION_FONT_SIZE = "close_caption_font_size";
export const TYPE_MEDIA_CATEGORY_IMAGE = APIBASEURL + 'helper/media-category-image';


export const WEBSITE_MEDIA = APIBASEURL + 'website-media';

export const GET_CHAIRSIDE_MEDIA = APIBASEURL + 'chairside-media';
export const GET_CHAIRSIDE_MEDIA_CATEGORY_LIST = APIBASEURL + 'chairside-media/category-list';
export const GET_CHAIRSIDE_MEDIA_LANGUAGE = APIBASEURL + "chairside-media/?chairSideMediaId=/language/&languageId="
export const POST_CHAIRSIDE_MEDIA_MYLIST = APIBASEURL + 'chairside-media/mylist';
export const WEBSITE_MEDIA_LAYOUT = APIBASEURL + 'website-media/layouts/html?layout_id=1&colour_id=2';
export const WEBSITE_MEDIA_HTML = APIBASEURL + 'website-media/html-code';
export const WEBSITE_MEDIA_GET_HTML = APIBASEURL + "website-media/layouts/html";
export const WEBSITE_MEDIA_GET_PLAYLIST_DATA = APIBASEURL + 'website-media/playlist';
export const WEBSITE_MEDIA_DELETE_PLAYLIST = APIBASEURL + 'website-media/playlist';
export const ADD_SOCIAL_MEDIA = `${PRACTICEUSER}/add-social-media`;
export const GET_SOCIAL_MEDIA = `${PRACTICEUSER}/get-social-media`;
export const SOCIAL_MEDIA = `${PRACTICEUSER}/social-media`
export const HELPER_TIMEZONE = APIBASEURL + 'helper/timezone';
export const GET_CHAIRSIDE_PLAYLIST_MEDIA = APIBASEURL + 'chairside-media/playlist/videos';
export const CREATE_CHAIRSIDE_PLAYLIST = APIBASEURL + 'chairside-media/playlist';
export const CHAIRSIDE_PLAYLIST_PLAY_URL = APIBASEURL + 'chairside-media/playlist';




/* added by milap for watingroom channel*/
export const WAITINGROOM_MEDIA = APIBASEURL + 'waiting-room-media';
export const OPERATORY_MEDIA = APIBASEURL + 'operatory-media';
export const WAITINGROOM_CHANNEL = APIBASEURL + 'waiting-room-media/channel';
export const WAITINGROOM_CHANNEL_THEME_LIST = WAITINGROOM_CHANNEL + '/get-theme';
export const POST_CHANNELWISE_CUSTOM_MESSAGES = '/footer/custom-messages';
export const GET_WAITINGROOM_CHANNEL_FOOTER_TIME = "footer/time";
export const POST_WAITINGROOM_CHANNEL_FOOTER_TIME = "footer/time";
export const GET_WAITINGROOM_CHANNEL_FOOTER_WEATHER = "footer/weather";
export const GET_WAITINGROOM_CHANNEL_LIVE_WEATHER_DATA = "v1/live-weather-data";
export const GET_WAITINGROOM_CHANNEL_SCHEDULE = WAITINGROOM_MEDIA + "/schedule";
export const GET_OPERATORY_CHANNEL_SCHEDULE = OPERATORY_MEDIA + "/schedule";
export const WAITINGROOM_CHANNEL_PREVIEW_URL = "waiting-room-slide/index.html?channel=";
export const OPERATORY_CHANNEL_PREVIEW_URL =  "waiting-room-slide/waiting-video/index.html?channel=";
/* added by milap end */

export const GET_SHARE_VIDEO_LINK = APIBASEURL + "practice/share-video";

export const GET_CHANNELWISE_CUSTOM_MESSAGES = APIBASEURL + 'waiting-room-media/channel/2/footer/custom-messages';
export const GET_WAITINGROOM_CHANNEL_TRIVIA = APIBASEURL + 'waiting-room-media/channel/trivia';

export const DateFormat = "YYYY-MM-DD";
export const ACTIVEFORMAT = "MMM DD, YYYY";
export const DateDDMMMYYY = "DD MMM YYYY";
export const POST_WAITING_ROOM_HEADER = APIBASEURL + "waiting-room-media/channel/2";
export const GET_WEBSITEROOM_MEDIA_CHANNEL = APIBASEURL + "waiting-room-media/channel?sorted_by=newest";

export const WAITINGROOM_FOOTER_WEATHER = APIBASEURL + "waiting-room-media/channel/2/footer/weather";
export const GET_WAITINGROOM_LIVE_FOOTER_TIME = APIBASEURL + "waiting-room-media/channel/2/footer/time";

// export const POST_CHANNELWISE_CUSTOM_MESSAGES = APIBASEURL + 'waiting-room-media/channel/2/footer/custom-messages';
export const DELETE_CHANNELWISE_CUSTOM_MESSAGES = APIBASEURL + 'waiting-room-media/channel/2/footer/custom-messages';
export const PUT_CHANNELWISE_CUSTOM_MESSAGES = APIBASEURL + "waiting-room-media/channel/53/footer/custom-messages"

export const GET_WAITINGROOM_FOOTER_WEATHER = APIBASEURL + "waiting-room-media/channel/2/footer/weather";
export const GET_WAITINGROOM_LIVE_WEATHER_DATA = APIBASEURL + "waiting-room-media/channel/2/live-weather-data";

export const GET_OWNER_LOCATIONS = PRACTICE + "/location"
export const GET_OWNER_LOCATIONS_MANAGER = PRACTICE + "/location/manager"
export const POST_OWNER_LOCATION = PRACTICE + "/location"

export const GET_WEBSITE_MEDIA_LAYOUTS = APIBASEURL + "website-media/layouts";
export const GET_WEBSITE_MEDIA_COLOURS = APIBASEURL + "website-media/colours";

export const GET_HELPER_NEWSFEED = APIBASEURL + "helper/newsfeed";
export const GET_WAITINGROOM_DISPLAY_OPTIONS = APIBASEURL + "waiting-room-media/channel/3";

export const DELETE_WAITINGROOM_MEDIA_CHANNEL = APIBASEURL + "waiting-room-media/channel/3";
export const UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME = APIBASEURL + "waiting-room-media/channel/3";

export const CREATE_NEW_WAITING_ROOM_CHANNEL = APIBASEURL + "waiting-room-media/channel";


export const NEW_WAITING_ROOM_TIMELINE_EDUCATION = APIBASEURL + "waiting-room-media/videos/1";
export const NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT = APIBASEURL + "waiting-room-media/videos/2";
export const NEW_WAITING_ROOM_TIMELINE_MYVIDEOS = APIBASEURL + "waiting-room-media/videos/3";
export const REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS = APIBASEURL + "waiting-room-media";

export const SLIDES_STOCK_LIBRARY_WAITINGROOM = APIBASEURL + "waiting-room-media/stock-library/category-list";
export const SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM = APIBASEURL + "waiting-room-media/stock-library/search";

export const GET_MYVIDEOS_CATEGORY_LIST = APIBASEURL + "helper?types=waiting_room_media_main_category";
export const REQUEST_PRACTICE_VIDEO = APIBASEURL + "practice/video-request";
export const WAITING_ROOM_CHANNEL_SLIDE = APIBASEURL + "waiting-room-media/channel/slide";
export const WEBSITE_MEDIA_CONFIG = APIBASEURL + "website-media/config";
export const WEBSITE_MEDIA_PUBLISH = APIBASEURL + "website-media/publish-timeline";

export const WAITINGROOMMEDIA = "https://upload.wistia.com";
export const WAITINGROOMSLIDESSTOCKIMAGE = `${APIBASEURL}waiting-room-media/stock-library/search`;
export const OPERATORYSLIDESSTOCKIMAGE = `${APIBASEURL}operatory-media/stock-library/search`;
export const WAITINGROOMSLIDESBACKGROUNDIMAGE = `${APIBASEURL}waiting-room-media/slide-background/search`; 
export const YOUTUBEVIDEO = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch`;
export const WAITING_ROOM_MEDIA = APIBASEURL + "waiting-room-media";
export const CREATEWAITINGROOMNEWSLIDE = APIBASEURL + "waiting-room-media/channel/slide";
export const SLIDE_IMAGE_UPLOAD = WAITING_ROOM_CHANNEL_SLIDE + '/image-upload';
export const WAITINGROOMSLIDESHTMLTEMPLATE = WAITING_ROOM_MEDIA + "/slide-presets";

export const POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST = APIBASEURL + "website-media/playlist";
export const GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY = APIBASEURL + "website-media/playlist";
//operatory room
export const OPERATORY_ROOM = APIBASEURL + "operatory-media";
export const OPERATORY_ROOM_VIDEO = OPERATORY_ROOM + "/videos";
// Added by Dixit Solanki ( work releted operatory chanel)
export const OPERATORY_CHANNEL = APIBASEURL + 'operatory-media/channel';
export const OPERATORY_CHANNEL_THEME_LIST = OPERATORY_CHANNEL + '/get-theme';
export const CREATE_NEW_OPERATORY_CHANNEL = APIBASEURL + "operatory-media/channel";
export const GET_OPERATORY_DISPLAY_OPTIONS = APIBASEURL + "operatory-media/channel/3";
export const OPERATORY_ROOM_MEDIA = APIBASEURL + 'operatory-media';
export const NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS = "operatory-media/3"
// Added by Dixit Solanki ( work releted operatory chanel)

// Added by Dixit Solanki ( work releted operatory Schedule)
export const SAVE_OPERATORY_SCHEDULE = APIBASEURL + "operatory-media/schedule";
export const SAVE_WAITING_ROOM_SCHEDULE = APIBASEURL + "waiting-room-media/schedule";

export default class HttpUtility {

    static checkRequest(error) {
        let response = error.response;
        if (response && response.status == 401) {//403 
            // let error = new Error("Current Session Expired. Login Again..");
            // error.response = error;
            // throw error;
            // clearStorage();
            window.location.href ="/thalamuslogout"
            // return response;// redirect login page
            // return {
            //     "status": 401,
            //     "message": "Unauthorized",
            //     "data": null,
            //     "errors": { error: ["Unauthorized"] },
            //     "error": { error: ["Unauthorized"] }
            // }
        }else if (response && response.status == 412) {
            window.location.href ="/thalamuslogout"
        } 
         else if (response && response.status >= 500) {
            // //   window.location.href = '/error'
            //     localStorage.setItem("erpage", window.location.href).then(() => {
            //         window.location = '/error'
            //     })
            return response;

        } else if (response && response.status == 404) {
            history.push('/error')
            return {
                "status": 404,
                "message": "",
                "data": null,
                "errors": {}
            }
        }else if (response && response.status == 403) {
            window.location.href ="/upgrade-plan"
        } 
         else {
            // let error = new Error(error);
            // throw error;
            return response;
        }
    }

    static parseJson(response) {
        let jsonResonse = {};
        jsonResonse.status = response.status;
        jsonResonse.data = response.data.data;
        jsonResonse.message = response.data.message;
        return jsonResonse;
    }

    static getHeaders() {
        var headers = {};
        headers['Content-Type'] = 'application/json;charset=UTF-8';
        var accessToken = getDeviceCode();
        // console.log(accessToken);
        if (accessToken !== "") {
            headers['Device-Code'] = accessToken;
        }
        return headers;
    }

    static getuserHeaders = () => {
        var userheaders = {};
        userheaders['Content-Type'] = 'application/json';
        userheaders['Accept'] = 'application/json';
        var accessToken = getToken();
        if (accessToken !== "") {
            userheaders['Authorization'] = accessToken;
        }
        return userheaders;
    }


    static queryStringBuilder(params) {
        if (typeof params === 'undefined' || typeof params !== 'object') {
            params = {};
            return params;
        }
        var query = '?'; let index = 0;
        for (var param in params) {
            index++;
            var value = params[param];
            if (index === 1) {
                query += param + '=' + value;
            } else {
                query += '&' + param + '=' + value;
            }
        }
        return query;
    }

    static getRequest(uri, param) {
        if (param) {
            uri = uri + this.queryStringBuilder(param);
        }
        var headers = this.getHeaders();
        // console.log(headers);
        return axios.get(uri, {
            headers: headers,
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static getuserRequest(uri, params) {
        if (params) {
            uri = uri + this.queryStringBuilder(params);
        }
        var headers = this.getuserHeaders();
        return axios.get(uri, {
            headers: headers,
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static userdeleteRequest(uri, params) {
        var headers = this.getuserHeaders();
        return axios.delete(uri, {
            headers: headers,
            data: params
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static userPostRequest(uri, params) {
        var uheaders = this.getuserHeaders()
        return axios.post(uri, params, {
            headers: uheaders,
        }).then(this.parseJson).catch(this.checkRequest)
    }

    static postRequest(uri, param) {
        var headers = this.getHeaders();
        return axios.post(uri, param, {
            // crossDomain: true,
            // withCredentials: true,
            headers: headers,
        }).then(this.parseJson).catch(this.checkRequest)
    }

    static postfile(uri, param, dispatch) {
        var headers = this.getHeaders();
        return axios.post(uri, param, {
            headers: headers,
        }).then(this.parseJson).catch(this.checkRequest)
    }

    static patchRequest(uri, param) {
        var headers = this.getHeaders();
        return axios.patch(uri, param, {
            headers: headers,
        }).then(this.parseJson).catch(this.checkRequest)
    }

    static patchUserRequest(uri, param) {
        var uheaders = this.getuserHeaders();
        return axios.patch(uri, param, {
            headers: uheaders,
        }).then(this.parseJson).catch(this.checkRequest)
    }


    static putRequest(uri, param) {
        var headers = this.getHeaders();
        return axios.put(uri, param, {
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static putuserRequest(uri, param) {
        var headers = this.getuserHeaders();
        return axios.put(uri, param, {
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest);
    }


    static deleteUserRequest(uri, param) {
        if (param) {
            uri = uri + this.queryStringBuilder(param);
        }
        var headers = this.getuserHeaders();
        return axios.delete(uri, {
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static deleteRequest(uri) {
        var headers = this.getHeaders();
        return axios.delete(uri, {
            headers: headers
        }).then(this.parseJson).catch(this.checkRequest);
    }

    static getHelperType(typeArray) {
        var uri = HELPERTYPE + typeArray.toString();
        return this.getRequest(uri)//.then((response)=>{ return response });
    }



    static patchuserRequest(uri, param) {
        var headers = this.getuserHeaders();
        return axios.patch(uri, param, {
            headers: headers,
        }).then(this.parseJson).catch(this.checkRequest)
    }

    static checkYoutubVideo(videoID) {
        var url = YOUTUBEVIDEO;
        var param = {
            v: videoID,
            format: 'json'
        }
        url = url + this.queryStringBuilder(param);

        return axios.get(url).then(response => response);
    }

    static getFBUserPages(url) {
        return axios.get(url);
    }

    static uploadFileWithProgress(uri, formData, onUploadProgress, fileSource, files, uploadfile) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        var accessToken = getToken();

        fileSource(source, uploadfile)
        return axios({
            method: 'post',
            params: {
                // 'access_token': '4db82d1d334cdf272c9422f30a0eb077aa06dec9eb35c0d6f07fe9eba01997f0',
                'project_id': WISTIA_PROJECT_ID
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': WISTIA_TOKEN
            },
            data: formData,
            url: uri,
            cancelToken: source.token,
            onUploadProgress: (progressEvent) => {
                onUploadProgress(progressEvent, uploadfile)
            }
        }).then((response) => {
            let jsonResonse = {};
            jsonResonse.status = response.status;
            jsonResonse.data = response.data;
            jsonResonse.uploadfile = uploadfile;
            return jsonResonse;
        });
    }

}



