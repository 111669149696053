import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchChairsideMedia, getChairsidePlayListData, getOperatoryMediaChannelList, getWebsiteRoomMediaChannelList } from '../../../../store/actions/taskAction';
import styles from './style.module.scss';
import ChannelList from '../ChannelList'
import Loading from '../../../../components/Loading';

const MyListsWaitingRoomChannels = ({ sortedBy, sortedType, isOperatoryRoom, isChairside , isPlaylists}) => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);

    useEffect(() => {

        dispatch(getChairsidePlayListData(state.practiceUserLoginInfo))

      
    }, []);

  return (
    <>
    {state.loading ? <div className={`goolge_tv__loader`}><Loading /></div> :
    <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
        <ChannelList title={'Playlists'} isOperatoryRoom={isOperatoryRoom} noSlider={true} isChairside={isChairside} isPlaylists={isPlaylists} channelsData={state.chairsidePlaylistsData && state.chairsidePlaylistsData.slice(0, 10) }/>
    </div>
    }
    </>
  )
}

export default MyListsWaitingRoomChannels
