import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import {
    Router,
    Route as DefaultRoute,
    Switch
  } from 'react-router-dom'
import Header from '../../components/Header';
import HeaderOnlyLogo from '../../components/HeaderOnlyLogo';
  
const DefaultLayout = ({ children }) => (
    <Fragment>
      <Header />
      {children}
    </Fragment>
  )
  
  const HeaderStyle2 = ({ children }) => (
    <Fragment>
      <HeaderOnlyLogo />
      {children}
    </Fragment>
  )
  
  // const HomePageLayout = ({ children }) => <Fragment>{children}</Fragment>
  
  
  const Route = ({
    component: Component,
    layout: Layout = DefaultLayout,
    ...rest
  }) => {
    return (
      <DefaultRoute
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  }
  
function ProtectedRoute(props) {
        const Component = props.component;
        const isAuthorized = props.practiceUserLoginInfo.type === 4 || props.practiceUserLoginInfo.type === 2 || props.practiceUserLoginInfo.type === 3 ? true : false

        return isAuthorized ? (
            <Route component={Component}  />
        ) : (
            <Redirect to={{ pathname: '/restricted-access' }} />
        );
    }

const mapStateToProps = (state) => ({
    practiceUserLoginInfo:state.task.practiceUserLoginInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(ProtectedRoute);