import React, { useEffect, useState } from "react";
import { Button} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import LoadingButton from '../../components/LoadingButton';


import {
  Grid,
  FormLabel,
  FormGroup,
  TextareaAutosize,
} from "@material-ui/core";
import PinturaEditor from './PinturaEditor'
import { Rnd } from "react-rnd";
import "./EditImage.scss";
import { resetDefaultsendScreenShot,getCSCustomMessage, resetCSCustomMessage, updateCSCustomMessage, sendScreenShot } from '../../store/actions/taskAction';
import { CopyrightMessage, PinturabackgroundImg, PinturaMainSection, RecommendItem } from "./styled";
import { isError } from "@sentry/utils";

class EditImageModalClass extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fileData: null,
      rotation: 0,
      fullWidth: true,
      maxWidth: "xl",
      patientName: "",
      patientEmail: "",
      customMessage: "",
      isDisabled:true,
      isSucessUpdate:false,
      isError:false,
      error:{
        name:'',
        email:'',
        message:''
      },
      textDrawing: {
        text: true,
        drawing: false,
      }
    };
  }
  handleMaxWidthChange = (event) => {
    this.setState({ maxWidth: event.target.value });
  };

  updateCustomMessage = async () => {
    let body = {
      "chairside_media_settings":
      {
        "screenshot_additonal_message": this.state.customMessage
      }
    }
    await this.props.dispatch(updateCSCustomMessage(body))
    if(!this.props.error){
      await this.setState({isDisabled:false,isSucessUpdate:true})
    }
  }

  handleFullWidthChange = (event) => {
    this.setState({ fullWidth: event.target.checked });
  };

  resetCustomMessage = async () => {
    await this.props.dispatch(resetCSCustomMessage())
    console.log(this.props.resetMessage,'this.props.resetMessage')
    this.setState({ customMessage: this.props.resetMessage })
    await this.setState({isDisabled:true,isSucessUpdate:false})
    // setCustomMessage(states.resetMessage)
  }

  async componentDidMount() {
    await this.props.dispatch(getCSCustomMessage())
    // this.setState({ customMessage: this.props.currentMessage.screenshot_additonal_message })
    this.setState({isDisabled:true,isSucessUpdate:false})
  }

  handleOnText = () => {
    // setTextDrawing({
    //   ...textDrawing,
    //   text: true,
    //   drawing: false,
    // });
  };

  handleOnDrawing = () => {
    // setTextDrawing({
    //   ...textDrawing,
    //   text: false,
    //   drawing: true,
    // });
  };
  handleChangeSquareTool = (e) => {
    // setToolsState({ ...toolsState, squareTool: true, square: e.target.value });
  };
  handleChangeCircleTool = (e) => {
    console.log(e);
    // setToolsState({ ...toolsState, circleTool: true, circle: e.target.value });
  };
  handleChangeColorTool = (e) => {
    // setToolsState({ ...toolsState, backgroundColor: e.target.value });
  };
  handleChangeBorderColor = (e) => {
    // setToolsState({ ...toolsState, circleBorderColor: e.target.value });
  };
  handleChangeTextSize = (item) => {

    // setToolsState({ ...toolsState, textSize: item });
  };
  handleChangeTextStyle = (item) => {

    // setToolsState({ ...toolsState, textStyle: item });
  }
  handleChangeRobotoStyle = (item) => {
    // setToolsState({ ...toolsState, robotStyle: item })
  }
  rotate = () => {
    // let newRotation = rotation + 90;
    // if (newRotation >= 360) {
    //   newRotation = -360;
    // }
    // setRotation(newRotation);
  };

  rotateLeft = () => {
    // let newRotation = rotation - 90;
    // if (newRotation >= 360) {
    //   newRotation = -360;
    // }
    // setRotation(newRotation);
  };

  sendEmail = async () => {
    let {  error } = this.state;

    var isError = false;

    // if (!this.state.fileData) {
    //   this.setState({
    //     error:{
    //       file:'Please press Done when finished editing the screenshot.'
    //     }
    //   })
    //   return false
    // }
    if (!this.state.patientName) {
        error.name = 'Please Enter Patient Name!';
        isError =  true
    }

    if (!this.state.patientEmail) {
        error.email = 'Please Enter Patient Email ID!';
        isError =  true
    }

    if (!this.state.customMessage) {
        error.message = 'Please Enter your message';
        isError =  true
    }

    this.setState({ error });


    if(isError){
      return false
    }

    const formData = new FormData();
    formData.append("attachment", this.state.fileData)
    formData.append("patient_name", this.state.patientName)
    formData.append("email", this.state.patientEmail)
    formData.append("message", this.state.customMessage|| ' ')
    await this.props.dispatch(sendScreenShot(formData))
    if (this.props.sendScreenshot.status === 200) {
      // alert("Email Send!")
    }
  }

  resetDefaultsendScreenShot = async () => {
    await this.props.dispatch(resetDefaultsendScreenShot())
    await this.props.closeEditor() 
  }
  
  render() {
    return (
      <React.Fragment>
        <PinturaMainSection>
          <PinturabackgroundImg src={this.props.screenShotImage ? this.props.screenShotImage.image : ""} className="pinturabackgoundImg"/>
               
        <Grid container >
          {this.props.sendScreenshot && this.props.sendScreenshot.status === 200 ? 
          <Grid item lg={3} className="ediImage__left_side__screen"  >
            <div className="screenshotSentsuccess h-100 d-flex flex-column text-center ">
              <div className="mt-5 pt-5">
                <h4 className="mb-4">Screenshot emailed</h4>
                <p>The screenshot containing all your notes was sent to your patient’s email. To view all previously emailed images, please visit the “Screenshots” section, accessible through the left navigation bar on Chairside Education.</p>
              </div>
              <Button className="mt-auto w-100" color="white" onClick={() => { this.resetDefaultsendScreenShot() }}>Back to the video player</Button>
            </div>
          </Grid>
          :
          <Grid item lg={3} className="ediImage__left_side__screen"  >
            <h5>
              Email this image with your notes
            </h5>
            <br />
            <form style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              width: "fit-content",
            }}>
              <FormGroup>
                <FormLabel className="ediImage__left_side__screen_label" >
                  Patient's name
                </FormLabel>
                <input value={this.state.patientName} onChange={(e) => { this.setState({ patientName: e.target.value , error:{}}) }} />
                {this.state.error && this.state.error?.name != '' && <span className='text--danger'>{this.state.error?.name}</span>}

              </FormGroup>
              <br />
              <FormGroup>
                <FormLabel className="ediImage__left_side__screen_label">
                  Patient's email
                </FormLabel>
                <input value={this.state.patientEmail} onChange={(e) => { this.setState({ patientEmail: e.target.value, error:{} }) }} />
                {this.state.error && this.state.error?.email != '' && <span className='text--danger'>{this.state.error?.email}</span>}

              </FormGroup>
              <br />
              <hr color="#312723" />
              <br />
              <FormGroup className="placeholder-textarea">
                <FormLabel className="ediImage__left_side__screen_label">
                  Accompanying message (Click to edit)
                </FormLabel>
                <TextareaAutosize
                  aria-label="minimum height"
                  rowsMin={3}
                  className="ediImage__left_side__screen_textArea"
                  value={this.state.customMessage}
                  placeholder={'Please type your message here'}
                  onChange={(e) => { this.setState({ customMessage: e.target.value ,isDisabled:true,isSucessUpdate:false, error:{}}) }}
                />
                {this.state.error && this.state.error?.message != '' && <span className='text--danger'>{this.state.error?.message}</span>}

                {this.props.error && this.props.error.errors && <span className='text--danger'>{this.props.error.errors['chairside_media_settings.screenshot_additonal_message']}</span>}
                {/* <span className="ediImage__custome_placeholder">Sent by your doctor at "{this.props.practiceInfo.name}"</span> */}
              </FormGroup>
              <br />
              <LoadingButton
                color={this.state.isSucessUpdate ? 'green' : 'primary'}
                type="button"
                size="large"
                loading={this.props.buttonLoading}
                disabled={this.props.buttonLoading}
                onClick={() => { this.updateCustomMessage() }}
                className="ediImage__left_side__screen_button"
              >
                {this.state.isSucessUpdate? 'Custom message in use' : 'save custom message'}
              </LoadingButton>
              <br />
              <br />
              <LoadingButton
                color="primary"
                type="button"
                size="large"
                variant="contained"
                loading={this.props.buttonLoading2}
                disabled={this.state.isDisabled || this.props.buttonLoading2}
                onClick={() => { this.resetCustomMessage() }}
                className="ediImage__left_side__screen_button"
              >
                revert to default message
              </LoadingButton>
            </form>
            <br />
            <p className="text--danger mb-3" style={{fontSize: "15px"}}>Please press 'Done' when finished editing the screenshot.</p>
            <LoadingButton
              variant="contained"
              type="button"
              color="primary"
              size="large"
              loading={this.props.buttonLoading3}
              disabled={this.state.fileData  ? false || this.props.buttonLoading3 : true}
              className="ediImage__left_side__screen_button"
              onClick={() => { this.sendEmail() }}
            >
              email screenshot
            </LoadingButton>
            <br />
            <br />
            <Button
              size="large"
              color="red"
              type="button"
              onClick={() => { this.props.closeEditor() }}
              variant="contained"
              className="ediImage__left_side__screen_closebutton"
            >
              close and discard draft
            </Button>

          </Grid>
          }
          <Grid item lg={9} className="ediImage__right_side__screen">
            <Grid item lg={12}>
              <h5>
                Click anywhere on the image to start typing or drawing, and
                use the options below to customize your input.
              </h5>
            </Grid>

            
            <Grid>
              <div className="pintura-editor">
                <div
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                    marginTop: "10px"
                  }}
                >
                  <PinturaEditor  updateFile={(file) => { this.setState({ fileData: file }) }} data={this.props.screenShotImage ? this.props.screenShotImage.image : ""} />
                  <Rnd  bounds="window" >

                  </Rnd>
                </div>

                <CopyrightMessage>
                  <p style={{ textAlign: "center" }}>
                    This image captured from copyrighted video is provided for
                    informational purpose only.
                    <br />It cannot be presented or distributed to any other
                    outside sources, being that it is a shared confidential
                    image between patient and doctor
                  </p>
                </CopyrightMessage>
              </div>
            </Grid>
          </Grid>
        </Grid>
       
        </PinturaMainSection>
       
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentMessage: state.task.currentMessage,
  resetMessage: state.task.resetMessage,
  updateCSCustomMessage: state.task.updateCSCustomMessage,
  sendScreenshot: state.task.sendScreenshot,
  buttonLoading:state.task.buttonLoading,
  buttonLoading2:state.task.buttonLoading2,
  buttonLoading3:state.task.buttonLoading3,
  practiceInfo: state.task.practiceInfo,
  error:state.task.error
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(EditImageModalClass);