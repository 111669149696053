/** @format */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ChairsideCatList from './ChairsideCatList';
import { useSelector, useDispatch } from 'react-redux';
import ChairsideVideoSelected from './ChairsideVideoSelected';

const WaitingRoomTimelineEducation = ({ educationTimeline, timeLineList, setModal, onPublish}) => {
	console.log(educationTimeline,'educationTimeline')
	const [selectedVideos, setSelectedVideos] = useState([]);
	// const state = useSelector((state) => state.task);
    useEffect(() => {
		const videos = [];
		selectedVideos.map((video) => {
			var isAdded = timeLineList.filter(x => x.media_id == video.chairside_media_id);
			videos.push({
				...video,
				isAdded,
			});
		});

		setSelectedVideos(videos);
	}, [timeLineList]);


	useEffect(() => {
		const categoryArray = [];
		const videos = [];
		educationTimeline?.map((element) => {
			// console.log(element,'element')
			categoryArray.push({
				category_id: element.id,
				category_name: element.title,
				selected: false,
				videosLength: element.chairside_videos.length,
			});
			element.chairside_videos.map((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.chairside_media_id);
				// console.log(video,'timeLineListtimeLineListtimeLineList')
				videos.push({
					...video,
					chairside_media:{
						...video.chairside_media,
						media_language:{
							media:{
								provider_url:video?.media?.provider_url
							}
						}
					},
					category_id: element.id,
					category_name: element.title,
					selected: false,
					isAdded,
				});
			});
		});
	}, []);
	
	const setVideos = (videos) => {
		setSelectedVideos(videos);
	};

	return (
		<>
			<div className="waitiing-room-col-sec">
				<div className="waiting-room-left-sec">
					<ChairsideCatList
							specialization={educationTimeline}
							setVideos={setVideos}
							timeLineList={timeLineList}
							setModal={setModal}
							onPublish={onPublish}
						/>
				</div>
				<div className="waiting-rom-right-sec">
					
						<ChairsideVideoSelected
							videos={selectedVideos}
							setVideos={setVideos}
						/>
				</div>	
			</div>
		</>
	);
};

export default WaitingRoomTimelineEducation;
