import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button , Spinner } from 'reactstrap';
import { history } from '../../pages/index';
import {  GetpracticeProfile  , GetPracticeMoreInfo , UpdatepracticeInfoRequest, GetSubscribePriceOption} from "../../store/actions/taskAction";

import { Ownership  , operatories , days , tv_count} from '../../data/comman';
import HttpUtility, {HELPERURI} from '../../utilities/HttpUtility';

import InitialSetup from './Form'
import Loading from '../../components/Loading'

import './style.scss';

class ProfileCreated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isContinue:false
    }
  }

  async componentDidMount(){
    // if(this.props.practiceUserLoginInfo?.is_manage_location !== 1){
    //   await this.props.dispatch(GetpracticeProfile());
    // }
    // await this.props.dispatch(GetSubscribePriceOption());
    // await this.props.dispatch(GetPracticeMoreInfo());
    // await this.onupdate()
  }

  async onupdate(){
      
    if(this.props.practiceProfileInfo){
      if(this.props.practiceProfileInfo?.country_id){
        var selectedcountry =  this.props.country.find(b => b.id === this.props.practiceProfileInfo?.country_id)
        await  this.onEditPracticeInfo( 'selectedcountry' , selectedcountry)
        if(!!selectedcountry){
           this.getState(selectedcountry.id);
        }
        
      }
    }

    if(this.props.practiceProfileInfo){
        var ownership  = Ownership.find(b => b.id === this.props.practiceProfileInfo?.ownership)
        this.onEditPracticeInfo( 'ownership' , ownership)
    }

    var specializations = []
    if(this.props.practiceProfileInfo?.specializations){
        this.props.practiceProfileInfo?.specializations.map((ids)=>
        specializations.push(this.props.specializations.find(b => b.id === ids)),
        );
          this.onEditPracticeInfo( 'specializations' , specializations)
    }

    if(this.props.practiceProfileInfo?.additional_info?.software){
      var selectedsoftware  = this.props.practice_softwares.find(b => b.name === this.props.practiceProfileInfo?.additional_info?.software)
      this.onEditPracticeInfo( 'selectedsoftware' , selectedsoftware)
    }

    if(this.props.practiceProfileInfo.additional_info.operatory_count){
      var selectedoperatories  = operatories.find(b => b.value === this.props.practiceProfileInfo?.additional_info?.operatory_count)
      this.onEditPracticeInfo( 'selectedoperatories' , selectedoperatories)
    }

    if(this.props.practiceProfileInfo?.additional_info.source){
      var selectedsource  = this.props.lead_source_types.find(b => b.name === this.props.practiceProfileInfo?.additional_info?.source)
      this.onEditPracticeInfo( 'selectedsource' , selectedsource)
    }

    if(this.props.practiceProfileInfo.additional_info.customer_type){
      var selectedpracticetype  = this.props.practice_customer_types.find(b => b.id === this.props.practiceProfileInfo?.additional_info?.customer_type)
        this.onEditPracticeInfo( 'selectedpracticetype' , selectedpracticetype)
    }

    if(this.props.practiceProfileInfo.additional_info.tv_count){
      var selectedtv_count  = tv_count.find(b => b.value === this.props.practiceProfileInfo?.additional_info?.tv_count)
      this.onEditPracticeInfo( 'selectedtv_count' , selectedtv_count)
    }
  }

  async getState(countryId){
    await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
            var countryState = response.data;
            this.onEditPracticeInfo( 'countryState' , countryState)
            if(this.props.practiceProfileInfo.state_id){
              var selectedstate = countryState.find(b => b.id === this.props.practiceProfileInfo?.state_id)
            }
            this.onEditPracticeInfo( 'selectedstate' , selectedstate)
        }
    });
  }

  async onEditPracticeInfo(field , value){
    let practiceProfileInfo = this.props.practiceProfileInfo
    practiceProfileInfo[field] = value
    await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))
  }
 
  onContinueClick(){
    this.setState({isContinue:true})
  }

  render (){
    const {loadingPractiveProfile , error} = this.props

    return(
        <div className="subscription__main__screen  d-flex flex-column h-100 align-items-center ">
            {loadingPractiveProfile ? 
              <Loading />
            :
                <>
                  {/* <SubHeader className="" title=""/> */}
                  <div className="subscription__inner__screen my-auto">
                      <div className="d-flex flex-column text-center subscription__inner__text text-center">
                          {this.props.practiceInfo.is_paid_account !== 1 ?
                          <>
                            <h1 className="mb-5">Thalamus subscription</h1>
                            <p>In order to continue providing your practice and patients with the best possible experience, we require you to go through a few quick steps before confirming your subscription.</p>
                            <Button size="md" className="mx-auto mt-5" onClick={()=>{ history.push({pathname: '/subscribe-form', isUpgradePlan:this.props?.isUpgradePlan || false})}} color="primary">
                              Continue
                            </Button>
                          </>
                          :
                          <>
                            <h1 className="mb-5">Change Subscription</h1>
                            <p>To change the current plan you need to go through a few steps. The downgrade plan will be reflected in the next billing cycle. The upgrade plan will have an immediate effect and pro-rata charge calculation.</p>
                            
                            <div className='d-flex justify-content-center mt-5'>
                              <Button size="md" className="mx-3" onClick={()=>{ history.push({pathname: '/subscribe-form', isUpgradePlan:this.props?.isUpgradePlan || false})}} color="primary">
                                Continue
                              </Button>
                              <Button size="md" onClick={() => history.push('/channels')} className="mx-3" color="default white" outline>Back</Button>
                            </div>
                          </>
                          }
                      </div>
                  </div>
                </>
            }
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceProfileInfo: state.task.practiceProfileInfo,
  country: state.task.country,
  specializations: state.task.specializations,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types:state.task.practice_customer_types,
  getSubscribePriceOption:state.task.getSubscribePriceOption,
  loadingMoreinfo:state.task.loadingMoreinfo,
  loading:state.task.loading,
  loadingPractiveProfile:state.task.loadingPractiveProfile,
  error: state.task.error,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
  practiceInfo:state.task.practiceInfo,
  isUpgradePlan:state.task.isUpgradePlan
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(ProfileCreated);
