/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col } from 'reactstrap';
import WaitingRoomTimelineEntertainmentCategories from './OperatoryRoomCategory';
import WaitingRoomTimelineEntertainmentVideoSelected from './OperatoryRoomTimelineEntertainmentVideoSelected';

const WaitingRoomTimelineEntertainment = ({ entertainmentTimeline, timeLineList, setModal, onPublish }) => {
	const [selectedVideos, setSelectedVideos] = useState([]);
	const state = useSelector((state) => state.task);
	const [categories, setCategories] = useState([]);
	const [subCategories, setSubCategories] = useState([]);
	const [allVideosIndex, setAllVideosIndex] = useState(true);
	const [categoryId, setCategoryId] = useState(null);

	const [isOpen, setIsOpen] = useState(false);
	const [allVideosCount, setAllVideosCount] = useState(0);
	const [count, setCount] = useState(0);
	const toggle = () => setIsOpen(!isOpen);
	const [subCat, setSubCat] = useState(false);
	const [subCategory, setSubCategory] = useState([]);
	const [allVideos, setAllVideos] = useState([]);
	const [filteredCategory, setFilteredCategory] = useState([]);
    useEffect(()=>{
		console.log(selectedVideos,'selectedVideos')
		const videos = [];
		selectedVideos.map((video) => {
			var isAdded = timeLineList.filter(x => x.media_id == video.operatory_media_id);
			videos.push({
				...video,
				isAdded,
			});
		});
        setSelectedVideos(videos)
    }, [timeLineList])

	useEffect(() => {
		var videosArray = [];
        entertainmentTimeline.forEach((element) => {
			element.video.forEach((subCategoryVideo) => {
				var isAdded = timeLineList.filter(x => x.media_id === subCategoryVideo.operatory_media_id);
				videosArray.push({
					...subCategoryVideo,
					isAdded,
				});
			});
		});
        setSelectedVideos(videosArray)
	}, []);

	const setVideos = (videos) => {
		console.log('check the video list  --> ',videos)
		setSelectedVideos(videos);
	};
	return (
		<>
			<div className="waitiing-room-col-sec">
				<div className="waiting-room-left-sec">
					<WaitingRoomTimelineEntertainmentCategories
						specialization={entertainmentTimeline}
						setVideos={setVideos}
						timeLineList={timeLineList}
						setModal={setModal}
						onPublish={onPublish}
					/>
				</div>
				<div className="waiting-rom-right-sec">
					<WaitingRoomTimelineEntertainmentVideoSelected
						videos={selectedVideos}
                        setVideos={setVideos}
					/>
				</div>	
			</div>
		</>
	);
};

export default WaitingRoomTimelineEntertainment;
