import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col , Input , Button} from "reactstrap";
import { InputField } from "../../components/InputField";
import { CreateDomain, GetDomainList, GetSubscribeInfo, getWebsiteMediaHtmlCode, resetSuccess } from "../../store/actions/taskAction";

import LoadingButton from '../../components/LoadingButton'
import { isRequired, validateDomain } from "../../data/comman";

const AuthorizeDomain = () => {
  const dispatch = useDispatch();
  const states = useSelector((state) => state.task);
  const [newDomain, setNewDomain] = useState('');
  const [isError, setisError] = useState({});

  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {

    dispatch(GetDomainList());
    dispatch(GetSubscribeInfo());
		dispatch(getWebsiteMediaHtmlCode(states.practiceUserLoginInfo));

  }, []);

  const handleChange = (e) =>{
    setNewDomain(e.target.value)
  }

  const  addNewDomain = async () =>{
    await setisLoading(true)
    if(!isValidate()){
      let params={
        "practice_id": states.practiceUserLoginInfo.practice_id,
        "domain": newDomain,
      }
      await dispatch(CreateDomain(params))

      await dispatch(resetSuccess());

      await dispatch(GetDomainList());
    } 
    setNewDomain('')
    await setisLoading(false)
  }

  

  const isValidate =  () => {
    var error =false;
    var isError = {};

    if((states.getDomainList && states.getDomainList.total) === (states.getSubscribeinfo && states.getSubscribeinfo.available_domain)){
      error = true;
      isError.newdomain = "No more domains allowed to add. Please purchase authorized domains..";
    }

    if(!validateDomain(newDomain)){
      error = true;
      isError.newdomain = "Invalid domain.";
    }

    if(isRequired(newDomain)){
      error = true;
      isError.newdomain = "The domain field is required.";
    }


    setisError(isError)
    return error
  }

  return (
    <div className='embedcode__authorize_domain__section d-flex flex-column align-items-center mb-5'>
            <div className='d-flex align-items-center flex-column'>
                <h3 className='mb-4'>Authorize Your Domain</h3>
                <p>To embed Thalamus player and videos onto your website, you need to authorize your website domain first.</p>
            </div>
            <div className='my-4 authorize_domain_list d-flex justify-content-center'>
                <label className='' style={{whiteSpace:'nowrap'}}>Current Authorized Domains ({states.getDomainList && states.getDomainList.total}/{states.getSubscribeinfo && states.getSubscribeinfo.available_domain}):</label>
                <div className="px-4  text-center">
                  {states.getDomainList.data && states.getDomainList.data.length > 0 ? states.getDomainList.data && states.getDomainList.data.map((list, index) => {
                    return <span key={index} className='domain_item mx-1 mb-2'>{list.domain}</span>
                  })
                :
                  <span className='domain_item mx-1 mb-2'>You currently have no authorized domains. Please authorize your domain below to embed the code.</span>
                }
                </div>
            </div>
            <div className='my-4 d-flex align-items-center'>
              <div>Authorize New Domain:</div><div className=" mx-20" style={{position:'relative'}}><InputField type="text"  name="newDpmain" className={`${isError.newdomain && isError.newdomain ? 'invalid-field' : ''} mx-260`} value={newDomain} onChange={(e)=> handleChange(e)} />
              {isError.newdomain && <span style={{position:'absolute',top:'-20px',right:'0',minWidth:'550px',left:'0'}} className='text--danger'>{isError.newdomain}</span>}</div>
              <LoadingButton  className="mw-168"  style={{width:'168px'}} label="Add Domain" disabled={isLoading} loading={isLoading}  onClick={()=>addNewDomain()} color="primary" />
            </div>
    </div>
  );
};

export default AuthorizeDomain;
