import React, { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useDispatch, useSelector } from 'react-redux';
import WebsitePlayerColorSlider from '../Skeletons/websitePlayerColorSlider'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const ColoursSlider = ({ colours, setColour, activeColourId, profile  , setSaveBtnEnable}) => {
	const state = useSelector((state) => state.task);
	const [nextEnable, setNextEnable] = useState('-disable');
	const [prevEnable, setprevEnable] = useState('-disable');
	const [coloursId, setColoursId] = useState(activeColourId);
	useEffect(() => {
		setColoursId(activeColourId)
	}, [activeColourId])

	const onSliderChange = (e) => {
		const activeNextSlide = e.activeIndex === 0 ? '-disable' : '-enable';
		setNextEnable(activeNextSlide);
	};

	const changeStyle = (event) => {
		setColoursId(event.target.id);
		setSaveBtnEnable(true)
	};
	const clickHandler = (colour) => {

		setColoursId(colour.id);
		setColour(colour.id);
	};
	
	return (
		<>
			<div className="website_videos_layout_selection__color_slider" style={{ marginBottom: '13px' }}>
				<Swiper
					spaceBetween={0}
					slidesPerView={5}
					navigation
					id={`video-slider${nextEnable}`}
					onSlideChange={(e) => onSliderChange(e)}>
					{colours.map((colour, index) => {
						const selectedImage =
							colour.id == coloursId ? RIGHTBOXIMAGE : WHITEBOXIMAGE;
						return (
							<SwiperSlide data-hash={index} key={colour.id}>
								<div className='color_scheme_box' key={colour.id}>
									<div className={`${colour.name} ${state.practiceInfo.is_paid_account == 0  && colour.is_paid != 0 ? 'disabled' : ''}`} onClick={ state.practiceInfo.is_paid_account == 0 && colour.is_paid != 0 ? null : () => clickHandler(colour) }>
										<img src={selectedImage} alt={colour.name} className='website_videos_layout_selection__imagetwo' />
										<WebsitePlayerColorSlider id={index} alt={colour.name} onClick={state.practiceInfo.is_paid_account == 0   && colour.is_paid != 0 ? null : changeStyle}  className={ colour.id == coloursId ? 'selection__colorscheme bordeer' : 'selection__colorscheme'}  src={colour.colour_thumb} />
										<p className="m-0 website_videos_color_name"> {colour.name} </p>
									</div>
									{state.practiceInfo.is_paid_account == 0   && colour.is_paid != 0 && 
										<p className={ `subscription-note `}>
											SUBSCRIPTION REQUIRED
										</p>
									}
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</>
	);
};

export default ColoursSlider;