import React, { useEffect, useRef, useState } from "react";
import SubHeader from '../../components/Header/subHeader';
import DropDown from '../../components/DropDown'
import AuthorizeDomain from './authorizeDomain'
import LoadingButton from '../../components/LoadingButton'

import './styles.scss';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { GetpracticeProfile, getWebsiteMediaHtmlCode , sendEmbedCodeToEmail} from "../../store/actions/taskAction";
import CopyToClipboard from "react-copy-to-clipboard";
import { history } from "..";



  const SelectCategory = () => {
    const dispatch = useDispatch();
    const states = useSelector((state) => state.task);
    const [html, setHtml] = useState(states.copyHTML);
    const [buttonLoading, setBtnLoading] = useState(false);
    const [buttonLoading2, setBtnLoading2] = useState(false);
    const [errors, setError] = React.useState("");
    const textAreaRef = useRef(null);

    const [shareCodeData, setshareCodeData] = useState({
        selectedDomain: [],
        emailList: [],
        email:'',
        linkEmails:[],
        linkEmail:''
      });

    useEffect(() => {
        dispatch(GetpracticeProfile());
        dispatch(getWebsiteMediaHtmlCode(states.practiceUserLoginInfo));

    }, []);

    useEffect(() => {
		setHtml(states.copyHTML)
	}, [states.copyHTML]);

    const [savePlaylistMessage, setSavePlayListMessage] = useState({
		message: 'COPY CODE',
		color: '#424242',
		bgColor: '#FF9F00',
	});

    
    const [shareBtn, setShareBtn] = useState({
		message: 'Share',
		bgColor: 'primary',
	});

    const [getCodeBtn, setgetCodeBtn] = useState({
		message: 'Get code',
		bgColor: 'primary',
	});

    const changeColor = () => {
 
        
		setHtml(html);


		setSavePlayListMessage({
			message: 'COPIED TO CLIPBOARD',
			bgColor: 'green',
			color: 'black',
		});

        setTimeout(() => { 
            setSavePlayListMessage({
                message: 'COPY CODE',
                color: '#424242',
                bgColor: '#FF9F00',
            });
    
          }, 3000)
	};

    const codeCopied = (e) => {
		console.log("Copied to clipboard" , e)
	}

    const handleChange = (field, val) =>{
        setError({});
        if(field === 'emailList' || field === 'linkEmails'){
            setshareCodeData({
              ...shareCodeData,
              [field]: val
            })
          }else if(field === 'selectedDomain' ){
            setshareCodeData({
                ...shareCodeData,
                [field]: val
              })
          }
          else{
            setshareCodeData({
              ...shareCodeData,
              [field]: val.target.value
            })
          }
    }

    const handleShare = async () => {

        var errors = {};
        var isError = false;

        if (shareCodeData.email === "" ) {
            errors.email = "The email field is required.";
            isError = true;
        }

        if (shareCodeData.selectedDomain.length === 0) {
            errors.selectedDomain = "Please select domain";
            isError = true;
        }

        if (isError) {
            setError(errors);
            return false
        }

        await setBtnLoading(true);
        var domains = []
        var emails = []
        shareCodeData.selectedDomain.map((list) => {domains.push(list.domain) })
        shareCodeData.emailList.map((email) => {  emails.push(email) })

        if(shareCodeData.email){
            emails.push( shareCodeData.email )
        }
          
        let params ={
            emails,
            domains,
          }

        await  dispatch(sendEmbedCodeToEmail(params))

        setShareBtn({
            message: 'Share',
            bgColor: 'green',
        })

        await setshareCodeData({
            selectedDomain: [],
            emailList: [],
            email:'',
            linkEmails:[],
            linkEmail:''
        })

        setTimeout(() => { 
            setShareBtn({
                message: 'Share',
                bgColor: 'primary',
            });
        }, 3000)

        await  setBtnLoading(false);
    }
    
    const handleGetCode = async () => {
        var errors = {};
        var isError = false;

        if (shareCodeData.linkEmail === "" ) {
            errors.linkEmail = "The email field is required..";
            isError = true;
        }

        if (isError) {
            setError(errors);
            return false
        }

        await setBtnLoading2(true);
        var emails = []
        shareCodeData.linkEmails.length > 0 && shareCodeData.linkEmails.map((email) => {  emails.push(email) })

        let params ={
            email:shareCodeData.linkEmail,
            link_emails:emails
          }

        await  dispatch(sendEmbedCodeToEmail(params))

        setgetCodeBtn({
            message: 'Get code',
            bgColor: 'green',
        })

        await setshareCodeData({
            selectedDomain: [],
            emailList: [],
            email:'',
            linkEmails:[],
            linkEmail:''
        })

        setTimeout(() => { 
            setgetCodeBtn({
                message: 'Get code',
                bgColor: 'primary',
            });
        }, 3000)

        await  setBtnLoading2(false);
    }
    return (
        <div className="main__screen  embedcode__main__screen  d-flex flex-column ">
          <SubHeader className="mb-5" title="Embed Code"/>
            <div className='embedcode__inner_screen'>

                <AuthorizeDomain/>
                
                <div className='d-flex justify-content-between pb-5'>
                    <div className='embedcode__html_code_section mr-4 mb-5'>
                        <div className=''>
                            <h4 className='mb-20'>Embed HTML code to your website</h4>
                            <p>To add Thalamus videos to your website, use the following HTML code. <strong>Please share this code with your website manager or developer.</strong></p>

                            <p><span style={{color:'#FF9F00'}}>IMPORTANT:</span> Don’t forget to clear your internet browser cache once it is all done, or you may not see any changes.</p>
                        </div>
                        <FormGroup className="">
                            <Label>Message</Label>
                            <Input type='textarea'   style={{minHeight:'120px',maxWidth:'580px'}} value={html}></Input>
                            <CopyToClipboard
                                text={html || ''}
                                onCopy={(e)=>codeCopied(e)}
                                options={{format: 'text/plain'}}
                                >
                                <Button
                                className='htmlcode__main__button mb-5'
                                style={{
                                    backgroundColor: `${savePlaylistMessage.bgColor}`,
                                    color: `${savePlaylistMessage.color}`,
                                }}
                                size='md'
                                onClick={() => changeColor()}>
                                {savePlaylistMessage.message}
                            </Button>
                            </CopyToClipboard>
                        </FormGroup>
                        <FormGroup className="mb-4 mt-4">
                            <Label>Send code to email</Label>
                            <Input type='text' className={`${errors && errors.email ? 'invalid-field' : ''}`} name="email" value={shareCodeData.email} onChange={(val)=> handleChange('email', val) }></Input>
                            {errors.email && <span className="text-danger">{errors.email}</span>}
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <Label>Also send to the following email(s)</Label>
                            <DropDown
                                className='react-select-container react-select__multi'
                                classNamePrefix="react-select"
                                options={states.practiceProfileInfo.additional_info && states.practiceProfileInfo.additional_info.linked_emails || []}
                                getOptionLabel={ x => `${x}`}
                                getOptionValue={ x => `${x} ${x}`}
                                onChange={(val)=> handleChange('emailList', val) }
                                isSearchable={false}
                                isMulti={true}
                                placeholder={''}
                                value={shareCodeData.emailList}
                            />
                            {errors.emailList && <span className="text-danger">{errors.emailList}</span>}

                        </FormGroup>
                        <FormGroup className="mb-5">
                            <Label>Domain(s)</Label>
                            <DropDown
                                className={`${errors && errors.selectedDomain ? 'invalid-field' : ''} react-select-container react-select__multi`}
                                classNamePrefix="react-select"
                                options={states.getDomainList.data && states.getDomainList.data || []}
                                getOptionLabel={ x => `${x.domain}`}
                                getOptionValue={ x => `${x.domain} ${x.domain}`}
                                onChange={(val)=> handleChange('selectedDomain', val) }
                                isSearchable={false}
                                isMulti={true}
                                placeholder={''}
                                value={shareCodeData.selectedDomain}
                            />
                            {errors.selectedDomain && <span className="text-danger">{errors.selectedDomain}</span>}

                        </FormGroup>
                        <FormGroup className="mb-5">
                            <LoadingButton type="button" color={shareBtn.bgColor} disabled={buttonLoading} loading={buttonLoading} label={shareBtn.message}  size="md" onClick={() => handleShare()} className={`mr-20`} />
                        </FormGroup>
                    </div>
                    <div className='embedcode__html_code_section ml-4 mb-5'>
                        <div className=''>
                            <h4 className='mb-20'>Add individual videos to website</h4>
                            <p>To add individual Thalamus videos to your website, enter your email below. You will receive an HTML file with HTML codes for all available website videos.</p>
                        </div>
                        <Row>
                            <Col sm={6}>
                                <FormGroup className="mb-4">
                                    <Label>Email Address</Label>
                                    <Input type='text' name="linkEmail" value={shareCodeData.linkEmail} className={`${errors.linkEmail ? 'invalid-field' : ''} w-100 mw-100`} onChange={(val)=> handleChange('linkEmail', val) }></Input>
                                    {errors.linkEmail && <span className="text-danger">{errors.linkEmail}</span>}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup className="mb-4">
                                    <Label>Also send to the following email(s)</Label>
                                    <DropDown
                                        className='react-select-container react-select__multi'
                                        classNamePrefix="react-select"
                                        options={states.practiceProfileInfo.additional_info && states.practiceProfileInfo.additional_info.linked_emails || []}
                                        getOptionLabel={ x => `${x}`}
                                        getOptionValue={ x => `${x} ${x}`}
                                        onChange={(val)=> handleChange('linkEmails', val) }
                                        isSearchable={false}
                                        isMulti={true}
                                        placeholder={''}
                                        value={shareCodeData.linkEmails}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="mt-5">
                            <LoadingButton type="button" color={getCodeBtn.bgColor} disabled={buttonLoading2} loading={buttonLoading2} label={getCodeBtn.message}  size="md" onClick={() => handleGetCode()} className={`mr-20`} />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default SelectCategory
