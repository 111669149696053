import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import React, { useEffect, useState ,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Input } from 'reactstrap';
import { history } from '..';
import SubHeader from '../../components/Header/subHeader';
import lower from '../../img/show_footer.png';
import middle from '../../img/show_Content.png';
import theme1 from '../../img/page16-screen-img.png';
import upper from '../../img/show_header.png';
import operatory__show_header_hover from '../../img/show_header.png';
import {getOperatoryChannel, getOperatoryThemeList,postwaitingRoomPublishTimeline, updateOperatoryChannelName, updateOperatoryChannelNameRequest } from '../../store/actions/taskAction';
import LoadingButton from '../../components/LoadingButton'
import PreviewModal from '../../components/PreviewModal'
import './styles.scss';
import { OPERATORY_CHANNEL_PREVIEW_URL } from '../../utilities/HttpUtility';
import Player from '../../components/videoPlayer/player'
import CustomVideoPlayer from './CustomVideoPlayer';
import { getToken } from '../../helper/LocalStorage';

var accessToken = ''

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#6087fd',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#6087fd',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },


});
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

const CreateNewOperatoryChannel = (props) => {
  const states = useSelector((state) => state.task);
  const classes = useStyles()
  const [modal, setModal] = useState(false);
  const [isAddChannel, setIsAddChannel] = useState(false);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    checkedA: true,
    checkedB: states.manageOperatoryChannel.header_status == 1 ? true : false,
    checkedC: states.manageOperatoryChannel.footer_status == 1 ? true : false,
  });

  const [isShared, setIsShared] = useState({
    practiceonly: states.manageOperatoryChannel.is_shared == 0 ? true : false,
    allpractice: states.manageOperatoryChannel.is_shared == 1 ? true : false
  });


  useEffect(() => {
    // let params ={
    //   "channel":uuid(),
    //   "title":uuid(),
    //   "practice_name":'Smith Dental',
    //   "theme_id":1,
    //   "enableButton":false
    // }
    // dispatch(createNewOperatoryChannel(params))

    dispatch(getOperatoryThemeList())
    accessToken = getToken();

    
  }, []);

  useEffect(() => {
   
    states.manageOperatoryChannel.is_shared == 1 ?
    setIsShared({ ...state, 'allpractice': true })
    :
    setIsShared({ ...state,'practiceonly': true })
    
    states.manageOperatoryChannel.footer_status == 1 ?
      setState({ ...state, 'checkedC': true })
    :
      setState({ ...state, 'checkedC': false })

    states.manageOperatoryChannel.header_status == 1 ?
      setState({ ...state, 'checkedB': true })
    :
      setState({ ...state, 'checkedB': false })
  }, [states.manageOperatoryChannel])


  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const changeHandler = (field, value) => {
    let manageOperatoryChannel = states.manageOperatoryChannel
    manageOperatoryChannel[field] = value
    manageOperatoryChannel['enableButton'] = false
    dispatch(updateOperatoryChannelNameRequest(manageOperatoryChannel))
  }

  const onSelectedChange = (event, name) => {
    setIsShared({[name]: event.target.checked });
  }

  const submitHandler = (event) => {
    let params = {
      "title": states.manageOperatoryChannel.title,
      "theme_id": states.manageOperatoryChannel.theme_id,
      "header_status": state.checkedB ? "1" : "0",
      "footer_status": state.checkedC ? "1" : "0",
      "display_custome_message_and_newsfeed": state.checkedC ? 1 : 0,
      "display_time_weather": state.checkedC ? 1 : 0,
      "enableButton": false,
      "is_shared": isShared.practiceonly ? 0 : 1
    }
    const isAddChannel = props.location.state.isFromMenageChannel ? false : true;
    setIsAddChannel(isAddChannel);
    console.log("isAdd", isAddChannel);

    dispatch(updateOperatoryChannelName(states.manageOperatoryChannel.id, params))
    // history.push('/channels')
  }

  const onPublish = () =>{
    // submitHandler()
		dispatch(postwaitingRoomPublishTimeline(states.manageOperatoryChannel.id, true))

    dispatch(getOperatoryChannel(states.manageOperatoryChannel.id))
	}
  return (
    <div className="Operatory__main__screen  d-flex flex-column h-100 align-items-center ">
      <SubHeader className="" title={`${props.location.state.isMangeChannel ? "Manage" : "New Operatory"} Channel`} />
      {states.manageOperatoryChannel &&
        <div className="d-flex Operatory__main__inner_screen mt-5" style={{overflow:'hidden'}}>
          <div className="Page6__main__left-slide d-flex flex-column Operatory__main__left-slide mr-20">

            {isAddChannel && !state.buttonLoading2 ? (
              <div className="transparent__form--white h-100  d-flex flex-column  justify-content-between" style={{ border: '1px solid green' }}>
                <div className="Page6__main__left-slide__div1__header" style={{ padding: '42px 40px 46px', minWidth: '346px' }}>
                  <h6>  Channel Added Successfully </h6>

                your chanel is now accessible via the waiting room channel section. please use new channel opiion menu, orclick "Manage Timeline" to make any changes
              </div>
                <div className="footer_btn">
                  <div className="row">
                    <div className=" col-md-12">
                      <Button type="button" onClick={() => {states.practiceInfo.is_corporate == 0 ? history.push('/operatory-channels') : history.push('/channels/operatory-channels') }} name="" id="" className="btn btn-block btn-primary">Back to Operatory Room Channels</Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (

                <div className="transparent__form--white h-100  d-flex flex-column ">
                  <div className="Page6__main__left-slide__div1__header">
                    <h6>Name and customize your new channel</h6>
                  </div>
                  <div className="d-flex justify-content-between channel-name">
                      <FormGroup className="Page6__main__left-slide__div1__body w-100">
                        <label>Operatory channel's name</label><br />
                        <Input type="text" name="title" value={states.manageOperatoryChannel.title} onChange={(e) => changeHandler('title', e.target.value)} />
                      </FormGroup>
                    </div>
                  <div className="Page6__main__left-slide__div1__body channel-body">

                    <div className="d-flex justify-content-between">
                      <FormGroup className="d-flex Operatory__show_header ">
                        <div className="Operatory__show_img p-0 d-flex flex-column align-items-center" >
                          <img className="w-100" src={state.checkedB ? operatory__show_header_hover : upper} alt=""></img>
                        </div>
                        <div className="ml-4 w-100 d-flex flex-column justify-content-between">
                          <div className="channel_detail d-flex flex-column justify-content-between h-100">
                            <div className="d-flex align-items-center Operatory_show_checkbox">
                              <label className="mr-4 mb-0 timeline-title">Show header</label>
                              <FormControlLabel className="mr-0 custom_checkbox" control={<IOSSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />} />
                            </div>
                            <div className="Operatory_show_checkbox">
                              <label>Practice name</label>
                            </div>
                            <div className="">
                              <Button size="xs" onClick={() => history.push("/operatory-channel-header")}>Edit Header</Button>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="d-flex justify-content-between">

                      <FormGroup className="d-flex Operatory__show_timeline" >
                        <div className="Operatory__show_img p-0 d-flex flex-column align-items-center" >
                          <img className="w-100" src={middle} alt=""></img>
                        </div>
                        <div className="ml-4">
                          <div className="channel_detail">
                            <div>
                              <label className="w-100 timeline-title">Content</label>
                            </div>
                            <div className="Operatory_show_checkbox">
                              <p>Choose media that will display on the channel.</p>
                            </div>
                            <div className="" >
                              <Button size="xs" onClick={() => history.push("/operatory-room-timeline")}>Edit Content</Button>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    {/* <FormGroup className="d-flex Operatory__show_footer">
                  <div className="Operatory__show_footer--img  Operatory__show_img p-0 d-flex flex-column align-items-center" onClick={() => history.push("/operatory-channel-footer")}>
                    <img className="w-100" src={state.checkedC ? operatory__show_footer_hover : lower} alt=""></img>
                  </div>
                  <div className="ml-4">
                    <div className="channel_detail">
                      <div className="d-flex align-items-center align-self-center Operatory_show_checkbox">
                        <label className="mr-4 mb-0">Show Footer </label>
                        <FormControlLabel className="m-0 custom_checkbox" control={<IOSSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />} />
                      </div>
                      <div className="">
                        <label>Custom Messages</label>
                        <label>Newsfeed</label>
                        <label>Time</label>
                        <label>Weather</label>
                      </div>
                    </div>
                  </div>
                </FormGroup> */}
                    <FormGroup className="channel-checkbox">
                      <div className="custom-checkbox show-channel-checkbox custom-control mb-4">
                        <input type="radio" name={'practiceonly'} checked={isShared.practiceonly} onChange={(e)=>onSelectedChange(e, 'practiceonly')} className="custom-control-input" id="practiceonly" />
                        <label className="custom-control-label" htmlFor="practiceonly">Show channel in this practice only</label>
                      </div>
                      <div className=" custom-checkbox custom-control ">
                        <input type="radio" name={'practiceonly'} checked={isShared.allpractice} onChange={(e)=>onSelectedChange(e, 'allpractice')} className="custom-control-input" id="allpractice" />
                        <label className="custom-control-label" htmlFor="allpractice">Share channel with all my practices</label>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="Page6__main__left-slide__div1__footer mt-3">
                    <div className="Page6__main__left-slide__div1__footer__footerRow row">
                      <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol Page6__main__left-slide__div1__footer__footerRow__button col-md-12 mb-20">
                        <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol__row row">
                          {/* <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol__row__upperColbtn col-md-6">
                            <Button type="button" name="" id=""
                              className="btn btn-block btn-white"
                              onClick={() => { history.push({ pathname: '/waiting-room-channel-schedule', state: { channel_id: states.manageOperatoryChannel.id, isOperatory: 1 } }) }} >SCHEDULE</Button>
                          </div> */}
                          <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol__row__upperColbtn col">
                            <Button type="button" name="" className="btn btn-block btn-white px-2" onClick={() => { history.push({ pathname: '/operatory-room-channel-images' }) }} id="">Thumbnail</Button>
                          </div>
                        </div>
                      </div>
                      <div className="Page6__main__left-slide__div1__footer__footerRow__lowerCol col-md-12">
                        {props.location.state.isFromMenageChannel ?
                          <>
                            <LoadingButton type="button" loading={states.buttonLoading2} label={props?.location?.state?.isFromMenageChannel ? 'Save channel' : 'ADD CHANNEL'} disabled={states.manageOperatoryChannel.enableButton || states.buttonLoading2 ? 'disabled' : ''} size="xxl" onClick={submitHandler} className={`mt-auto w-100`} color={'primary'} /> 
                            <LoadingButton type="button"  label={"Publish channel"} className="w-100  mt-4" loading={states.buttonLoading3} disabled={states.buttonLoading3}  onClick={() => onPublish()}  color={'primary'}/>
                          </>
                          :
                          <LoadingButton type="button" loading={states.buttonLoading2} label={props?.location?.state?.isFromMenageChannel ? 'Save channel' : 'ADD CHANNEL'} disabled={states.manageOperatoryChannel.enableButton || states.buttonLoading2 ? 'disabled' : ''} size="xxl" onClick={submitHandler} className={`mt-auto w-100`} color={'primary'} /> 
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
          

          {states.manageOperatoryChannel.timeline && states.manageOperatoryChannel.timeline.length > 0 ?
          <div className="Page6__main__right-side ml-20">
            {<iframe key={states.manageOperatoryChannel} className="operatory__channel__priview_iframe" style={{height:'1080px',width:'1920px', border: 0, transform: 'scale(0.6)',transformOrigin: '0 0'}} src={`${OPERATORY_CHANNEL_PREVIEW_URL}${states.manageOperatoryChannel.id}&preview=1&accessToken=${accessToken}`} title=" "></iframe>}
          </div>
          :
          <div className="Page6__main__right-side ml-20">
            <div className="Page6__main__screen-slide create_operatory_screen Operatory__main__screen--slide">
              <div className="d-flex flex-column flex-fill operatory_room_innerbox Operatory__main__screen--slide--innerBox">
                {state.checkedB &&
                  <div style={{cursor:"pointer"}}>
                     <h2 className="Operatory__main__screen--practice_name text-uppercase" >{states.manageOperatoryChannel.practice_name}</h2>
                  </div>
                }
                 {/* {states.manageOperatoryChannel.timeline?states.manageOperatoryChannel.timeline.length > 0 && <CustomVideoPlayer/>:null} */}
              </div>

            </div>
          </div>
          }
        </div>
      }
      <PreviewModal url={`${OPERATORY_CHANNEL_PREVIEW_URL}${states.manageOperatoryChannel.id}&preview=1&accessToken=${accessToken}`} isOpen={modal} toggle={() => setModal(!modal)} />

    </div>
  )
}




export default CreateNewOperatoryChannel





