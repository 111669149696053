import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import {GetreferralList } from "../../store/actions/taskAction";
import Guests from '../../img/Guests.svg'
import './style.scss';
import { Button , Spinner} from 'reactstrap';

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage:1,
      per_page:5,
      totelPage:0
    }
    this.handlechangePage = this.handlechangePage.bind(this);
  }
  
  getStatus(id){
    switch(id){
        case 0: 
            return  ["color-yellow", "Pending"];
        case 1:
            return  ["color-green", "Subscribed"];
        case 2:
            return  ['color-red', "Expired offer"]; 
        default:
          return    ['color-yellow', "Pending"];
    }
  }

  handlechangePage(e, index) {
    index = index + 1
    this.setState({currentPage: index},() => {
      let params ={
        page: this.state.currentPage,
      }
      this.props.dispatch(GetreferralList(params))    
    });
  }


  async componentDidMount(){
    let params ={
      page: this.state.currentPage,
      per_page:this.state.per_page
    }
   await this.props.dispatch(GetreferralList(params))    
    this.setState({totelPage:this.props.getreferralList.last_page})
  }

  render (){
    const {t ,loading, getreferralList , error} = this.props
    return(
      <div className={`${ getreferralList && getreferralList.data.length > 0 ? '' : 'no_referred_doctor'} referral__main__screen referral__page  d-flex flex-column h-100 align-items-center justify-content-center`}>
          <SubHeader className="mb-auto" title="Free Month Referral"/>
            <div className={`${ getreferralList && getreferralList.data.length > 0 ? '' : 'no_referred_header'} referral__inner mb-auto d-flex flex-column `}>
                <div className={`${ getreferralList && getreferralList.data.length > 0 ? '' : 'no_referred_subheader'} referral__inner__header`}>
                  <h4>Referral list</h4>
                  <p>View all referrals that have subscribed to Thalamus following your invitation link.</p>
                  <p>Each subscription granted you both a free month.</p>
                    <div className="no_referal_user">
                      <img src={Guests} alt="" />
                    </div>
                </div>
                  
                  
                    { loading ?
                    <div className="referral__inner__body flex-grow-1 p-0 flex-column d-flex  align-items-center justify-content-center ">
                      <Spinner color="primary" />
                    </div>
                    :
                    <div className="referral__inner__body flex-grow-1">
                      {getreferralList && getreferralList.data.length > 0 ?
                      <table className="table">
                        <thead>
                          <tr>
                            <th>NAME</th>
                            <th>Invitation date</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                            {getreferralList && getreferralList.data.map((user, index) => {
                              var status = this.getStatus(user.status )
                              return  <tr key={index} >
                                        <td>{user.name}</td>
                                        <td>{user.created_at}</td>
                                        <td>{user.email}</td>
                                        <td><span className={status[0]}></span>{status[1]}</td>
                                        <td><Button disabled={user.status !== 0} className="mx-3" size="md" color="primary">Resend</Button></td>
                                      </tr>
                            })}
                        </tbody>
                      </table>
                      :
                      <div className="referral__empty referral_doctor flex-column d-flex h-100 align-items-center justify-content-center">
                        <h4>You haven't referred a doctor yet.</h4>
                        <p>Once a referrals are made, you’ll have access to them on a list.</p>
                      </div>
                      }
                    </div>
                    }
                    {getreferralList && getreferralList.last_page > 1 &&
                    <div className="referral__inner__footer d-flex justify-content-center align-items-center">
                      <ul>
                        {[...Array(this.state.totelPage)].map((page, i) =>
                          <li key={i}><Button type="button"  className={`p-3 ${i+1 === this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                        )}
                      </ul>
                    </div>
                    }
            </div>
      </div>
    ) 
  }
}

const mapStateToProps = (state) => ({
  error: state.task.error,
  loading: state.task.loading,

  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  getreferralList:state.task.getreferralList
});


export default compose(
  withTranslation('common'),
  connect(mapStateToProps  )
)(HomeIndex);

