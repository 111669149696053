import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Button, Label, FormGroup, Form, Col, Row, } from 'reactstrap';

import he from 'he';

import {
    Close,
  } from "@material-ui/icons";
import './style.scss';



const PreviewModal = ({ url, html, isOpen, toggle}) => {
    const states = useSelector(state => state.task);
    const [openOverly, setShowOverly] = useState(false);
    const history = useHistory();


  

    useEffect(() => {
        if(isOpen){
            if(states.practiceInfo.is_paid_account == 0  && states.practiceInfo.days_left == 0 && !openOverly){
                setTimeout(() => {
                    setShowOverly({openOverly:isOpen});
                }, 60000);
            }
        }
	}, []);

   return (
        isOpen &&
        <div className={`${isOpen ? 'd-block' : 'd-none'} preview__modal`}>
            <div className="d-flex align-items-center mini-close" onClick={toggle} style={{ color: "white" , padding:"0 10px" , cursor:"pointer", position:'absolute', background: 'black', zIndex: 9}}>
                <p className="m-0">CLOSE</p>
                <Close fontSize="inherit" style={{ fontSize: "20px",  }}/>
            </div>
            {states.practiceInfo.is_paid_account == 0  && states.practiceInfo.days_left == 0 && openOverly ?
                <div className="video_overly d-flex flex-column h-100 align-items-center justify-content-center">
                    <h2>Please subscribe to watch the full video.</h2>
                    {!states.googleTvTestDrive &&
                    <Button type="button" className="mt-5" size="md" onClick={ ()=> history.push('/Subscribe')} color={'primary'} >
                        Subscribe
                    </Button>
                    }
                </div>
                : 
                <>
                    {url && <iframe style={{height:'100%',width:'100%', border: 0}} src={url} title="oreview"></iframe>}
                    {html &&  <div className="mainEditor" id="mainEditor" style={{ height: '100%', width: '100%' }}  dangerouslySetInnerHTML={{ __html: 
                        `${html}`
                    }} />}
                </>
            }
           
        </div>
	);
};

export default PreviewModal;
