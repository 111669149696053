import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Accesscode from './../../../img/help/sign_in_1.png'
import MultipleDevice from './../../../img/help/sign_in_2.png'
import Corporate from './../../../img/help/sign_in_3.png'
import ForgotAccesscode from './../../../img/help/sign_in_4.png'

const SignIn =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <>
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Sign In</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                            <div className="help-img" style={{marginTop:"10px"}}>
                                      <img src ={Accesscode} className="w-100" alt=""/>
                                  </div>
                                  <div className="help_desc" style={{marginTop:"10px" }}>
                                        <h5>Your access code</h5>
                                        <div className="help_detail">
                                              <p>
                                              Your access code is the key to signing in to Thalamus. Every practice receives an access code for Thalamus once the sign up process is completed. Once signed in, Thalamus will remember your device and you will only be required to sign in again if there is a software update, a change to our terms of service, or if you manually sign out. 

                                              </p>
                                        </div>
                                  </div>
                                  
                            </div>
                            <div className="help_description">
                            <div className="help-img" style={{marginTop:"-25px", }}>
                                      <img src ={MultipleDevice} className="w-100" alt=""/>
                                  </div>
                                  <div className="help_desc" style={{}}>
                                        <h5>Can I sign in to my account on multiple devices?</h5>
                                        <div className="help_detail">
                                              <p>Yes, a subscription to Thalamus allows you to login to 10 unique devices on either a PC, Google TV, iOS tablet, or Android tablet. If you require logging in to more than 10 devices, contact us.</p>
                                        </div>
                                  </div>
                                 
                            </div>
                            <div className="help_description" >
                            <div className="help-img" style={{marginTop:"-23px",}}>
                                      <img src ={Corporate} className="w-100" alt=""/>
                                  </div>
                                  <div className="help_desc" style={{marginTop:"-5px", }}>
                                        <h5>Signing into your corporate account</h5>
                                        <div className="help_detail">
                                              <p>Corporate customers can sign in by clicking the “CORPORATE SIGN IN” button, located on the lower section of the sign in page. Corporate customers will then be prompted to enter their corporate access code. </p>
                                        </div>
                                  </div>
                                 
                            </div>
                            <div className="help_description">
                            <div className="help-img">
                                      <img src ={ForgotAccesscode} className="w-100" alt=""/>
                                  </div>
                                  <div className="help_desc">
                                        <h5>What happens if I lose or forget my access code?</h5>
                                        <div className="help_detail">
                                              <p>If you lose or forget your access code, select the ‘can’t remember access code?’ option on the sign in page. You will be directed to the ‘forgot your access code?’ screen, where you will have the option to receive the access code by either by email or mobile SMS. The email address or mobile number must match the ones given by an authorized user in the practice.</p>
                                        </div>
                                  </div>
                                 
                            </div>
                      </div>
                    
                  </>
            
    ) 

}

export default SignIn;
