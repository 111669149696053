import React from 'react'
import { history } from '../../src/pages/index';
import { useSelector } from "react-redux";
const MenuBox = ({iconUrl, menuTitle, isPlan=false, redirectUrl, planName = ''}) => {

    const state = useSelector((state) => state.task);

   const redirectPage = (isPlan, redirectUrl, planName) =>{

        if(!isPlan){
          history.push(redirectUrl);
          return
        }
    
        let plugins = state.avelablePlugin
        let selectedplan = plugins?.find(p => p.code === isPlan);
        
        if(selectedplan){
          history.push(redirectUrl);
        }else{
          history.push({
            pathname: '/upgrade-plan',
            selectplan: planName
          });
        }
      }

      
  return (
    <div className="addons__item mx-20" onClick={(e)=> redirectPage(isPlan ,redirectUrl, planName)}>
        <div className="addons__box  d-flex flex-column align-items-center justify-content-center">
            <div className="addons__icon">
                <img alt="" src={iconUrl}/>
            </div>
        </div>
        <div className="addons__name my-3 text-center">
            <p className="m-0">{menuTitle}</p>
        </div>
    </div>
  )
}

export default MenuBox
