import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import DropDown from '../../components/DropDown'
import { connect } from "react-redux";
import { Button , Label , FormGroup, Form , Col , Row  , CustomInput, ListGroupItem, ListGroup } from 'reactstrap';
import Que from '../../img/QueIcon.svg'
import CVVIconHover from '../../img/cvv-hover.png'
import { InputField } from '../../components/InputField';
import { compose } from 'redux';
import './style.scss';
import InlineSVG from 'react-inlinesvg/esm';
import Review from '../InitialSetup/Form/Review';
import NumberFormat from 'react-number-format';
import Visa from '../../img/stripe/visa.svg';
import AmericanExpress from '../../img/stripe/amex1.svg';
import Discover from '../../img/stripe/discover1.svg';
import MasterCard from '../../img/stripe/mastercard1.svg';
import PoweredByStripe from '../../img/stripe/powered_by_stripe.svg';
import { getCorporatHardwareCalc , getCorporatHardwareCalcRequest, getCorporatHardwareList, getCorporatPracticesList, getDesignateCardMultiple, purchaseCorporatHardware, sameAsMainPractice, showError, updateHardwareTotal, UpdatepracticeInfoRequest } from '../../store/actions/taskAction';
import { count, getCardImageByNo, getCardNameByNo, getCartImageByType, isRequired} from '../../data/comman';
import LoadingButton from '../../components/LoadingButton'
import HttpUtility, { HELPERURI } from '../../utilities/HttpUtility';
import { history } from '../../pages/index';
  
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        primaryCardSelected: null,
        primaryCardHolderName: "",
        primaryCardHolderExipreDate: "",
        isSigninClick:true,
        isSignin:false,
        isReviewClick:false,
        creditCardNumber: "",
        cardHolderName: "",
        expireDate: "",
        editExpireDate: "",
        formValid:false,
        code: "",
        selectedhardware:[{
          value: 0, label: '0' 
        }],
        hardwares:[],
        selectedPractices:[],
        selectedcountry:[],
        selectedstate:[],
        address_line_1:'',
        address_line_2:'',
        city:'',
        zip:'',
        savedCard:true,
        usenewcreditcard:false,
        withWarranty:[],
        order_details: [],
        currentOrder:0,
        order:{},
        isError:{
          selectedPractices:'',
          creditCardNumber: "",
          cardHolderName: "",
          editExpireDate: "",
          code:'',
          selectedcountry:'',
          address_line_1:'',
          address_line_2:'',
          city:'',
          selectedstate:'',
          zip:'',
      }
    }
    this.onReviewClick = this.onReviewClick.bind(this);
    this.onEditSaveSubscribeCalc = this.onEditSaveSubscribeCalc.bind(this);
    this.onBack = this.onBack.bind(this);
  }
  
  onReviewClick(){
    let  isError = {} 
    console.log(this.state.selectedPractices)

    if(this.state.selectedPractices.length === 0 || this.state.selectedPractices[this.state.currentOrder].length === 0){
      isError.selectedPractices = "Select the delivery practice(s) before reviewing order";
      this.setState({ isError });
      return false
    }else{
      this.setState({isError})
    }
    this.setState({isReviewClick:true})
  }

  onBack(){
    this.setState({isReviewClick:false})
  }

  async componentDidMount(){

    await this.props.dispatch(getCorporatPracticesList());
    await this.props.dispatch(getDesignateCardMultiple());
    await this.props.dispatch(getCorporatHardwareList());
    // Object.keys(this.props.corporathardwarelist.hardware).map((sub)=>{
    //   var selectedhardware = count.find(b => b.value === 0)
    //   this.setState({selectedhardware })
    // });
    await this.props.dispatch(getCorporatHardwareCalcRequest());
  }

  async onEditSaveSubscribeCalc(field , value , index){
    const {currentOrder, order_details} = this.state;
    
    if(field === 'hardwares'){
      let hardwareIndex = order_details[currentOrder] && order_details[currentOrder].hardwares ?  order_details[currentOrder] &&  order_details[currentOrder].hardwares.findIndex(x=>x.id === this.props.corporathardwarelist.hardware[index].hardware.id) : -1;
      if(hardwareIndex === -1){
        var hardwaresNew = [];
         hardwaresNew.push({
            id: this.props.corporathardwarelist.hardware[index].hardware.id,
            qty:value.value,
            with_warranty:this.state.withWarranty[currentOrder] ? 1 : 0
          })
          order_details[currentOrder] = {
            practice_id : this.state.selectedPractices.length > 0 ? this.state.selectedPractices[currentOrder].map((ids)=>{return ids.id}) : [],
            hardwares: hardwaresNew,
          }
        let selectedhardware = this.state.selectedhardware
        selectedhardware[currentOrder] = count.find(b => b.value == value.value)
        await  this.setState({selectedhardware , order_details })
      }else{
        var hardwaresNew = [];
        hardwaresNew[index] = {
          ...hardwaresNew[index],
          id: this.props.corporathardwarelist.hardware[index].hardware.id,
          qty:value.value,
          with_warranty:this.state.withWarranty[currentOrder] ? 1 : 0
        }
        // hardwares[index].qty=value.value
        order_details[currentOrder] = {
          practice_id : this.state.selectedPractices.length > 0 ? this.state.selectedPractices[currentOrder].map((ids)=>{return ids.id}) : [],
          hardwares: hardwaresNew,
        }
        let selectedhardware = this.state.selectedhardware
        selectedhardware[currentOrder] = count.find(b => b.value == value.value)
        await this.setState({selectedhardware , order_details })
      }

      if(value.value == 0){
        var hardwares= []
        var withWarranty = this.state.withWarranty
        withWarranty[currentOrder] = false
        this.setState({withWarranty})
      }
      
    }else if(field === 'with_warranty'){

      let hardwareIndex = order_details[currentOrder].hardwares ? order_details[currentOrder].hardwares.findIndex(x=>x.id === this.props.corporathardwarelist.hardware[index].hardware.id) : -1
      var hardwares = [];
      if(hardwareIndex === -1){
        hardwares.push({
          id: this.props.corporathardwarelist.hardware[index].hardware.id,
          with_warranty:value ? 1 : 0,
        })
        order_details.push({
          practice_id : this.state.selectedPractices.length > 0 ? this.state.selectedPractices[currentOrder].map((ids)=>{return ids.id}) : [],
          hardwares
        })
      }else{
        order_details[currentOrder].hardwares[index].with_warranty = value ? 1 : 0
        order_details[currentOrder] = {
          ...this.state.order_details[currentOrder],
          practice_id : this.state.selectedPractices.length > 0 ? this.state.selectedPractices[currentOrder].map((ids)=>{return ids.id}) : [],
        }
      }
      var withWarranty = this.state.withWarranty
        withWarranty[currentOrder]=!this.state.withWarranty[currentOrder]
        await this.setState({withWarranty, order_details})


    }else if(field === 'selectedPractices'){
      const {selectedPractices} =  this.state
      selectedPractices[currentOrder] = value
      await this.setState({isError:'' , selectedPractices  },()=>{
        if(order_details.length >= currentOrder){
          order_details[currentOrder] = {
            ...this.state.order_details[currentOrder],
            practice_id : this.state.selectedPractices.length > 0 ? this.state.selectedPractices[currentOrder].map((ids)=>{return ids.id}) : [],
          }
        }else{
          order_details.push({
            ...this.state.order_details[currentOrder],
            practice_id : this.state.selectedPractices.length > 0 ? this.state.selectedPractices[currentOrder].map((ids)=>{return ids.id}) : [],
          })
        }
        this.setState({order_details})
      })
    
    }



    // await this.setState([...hardwares])
    
    let params ={
      order_details
    }
    console.log(this.state.selectedhardware[this.state.currentOrder],'this.state.selectedhardware[this.state.currentOrder]', this.state.selectedPractices[this.state.currentOrder])

    // if( this.state.selectedhardware[this.state.currentOrder].value != 0 && this.state.selectedPractices[this.state.currentOrder].length && this.state.selectedPractices[this.state.currentOrder].length > 0){
      await this.props.dispatch(getCorporatHardwareCalc(params));
    // }else{
      // await this.props.dispatch(updateHardwareTotal(this.state.currentOrder))
      // this.props.corporathardwarecalc.order_detail[this.state.currentOrder].order_total = 0
    // }
  }

  async addOrder(){
   var {selectedhardware} = this.state;
   let  isError = {} 

    if(this.state.selectedPractices.length === 0 || this.state.selectedPractices[this.state.currentOrder].length === 0){
      isError.selectedPractices = "Select the delivery practice(s) before reviewing order";
      this.setState({ isError });
      return false
    }else{
      this.setState({isError})
    }
    selectedhardware.push({
      value: 0, label: '0' 
    })
    var withWarranty =this.state.withWarranty
    withWarranty.push(false)

    let selectedPractices = [...this.state.selectedPractices]
    selectedPractices.push([])
    this.setState({currentOrder:this.state.currentOrder+1, selectedhardware,selectedPractices})
  }

 async changeHandler(event, filed){
    var filedName = "", filedValue = "";
    var vardImage = this.state.vardImage;

    if(filed === "creditCardNumber"){
      filedName = filed;
      filedValue = event.value;
      vardImage = getCardImageByNo(filedValue);

    }else if(filed === 'selectedcountry'){
      filedName = filed;
      filedValue = event;
      this.getState(filedValue.id);
    }else if(filed === 'selectedstate'){
      filedName = filed;
      filedValue = event;
    }else if(filed === 'countryState'){
      console.log(event,'event')
      filedName = filed;
      filedValue = event;
    }else if(filed === 'editExpireDate'){
      filedName = filed;
      filedValue = event.value;
    }else if(filed === 'code'){
      filedName = filed;
      filedValue = event.value;
    }else if(filed === 'savedCard'){
      filedName = filed;
      filedValue = event.target.checked;
       console.log(filedName,'', filedValue)
    }else if(filed === 'usenewcreditcard'){
      filedName = filed;
      filedValue = event.target.checked;
    }else if(filed === 'sameasmainpractice'){
      filedName = filed;
      filedValue = event.target.checked;
      await  this.setState({[filedName]: filedValue})

      if(this.state.sameasmainpractice){
        if(this.props.mainPracticeAddress && this.props.mainPracticeAddress.country_id){
        }else{
          await this.props.dispatch(sameAsMainPractice())
        }
        
        var selectedcountry =  this.props.country.find(b => b.id == this.props.mainPracticeAddress.country_id);
        await this.getState(this.props.mainPracticeAddress.country_id);

        var selectedstate =  this.state.countryState.find(b => b.id == this.props.mainPracticeAddress.state_id);
        console.log(this.props.mainPracticeAddress.country_id,'selectedcountry' ,selectedcountry);

        this.setState({
            selectedcountry:selectedcountry,  
            selectedstate:selectedstate,
            city:this.props.mainPracticeAddress.city,
            address_line_1:this.props.mainPracticeAddress.address_line_1,
            address_line_2:this.props.mainPracticeAddress.address_line_2,
            zip:this.props.mainPracticeAddress.zip
        })
      }else{

      }
    }
    else  if(filed == "primaryCardSelected"){
      filedName = filed;
      filedValue = event;
      this.setState({ 
         primaryCardHolderName: filedValue.name,
         primaryCardHolderExipreDate: `${filedValue.exp_month} / ${filedValue.exp_year}`,
         [filedName]: filedValue,
         errors: {
            ...this.state.errors,
            [filedName]:  "",
         }
      })
   }
    else{
        var { name, value } = event.target;
        filedName = name;
        filedValue = value;
    }

    this.props.dispatch(showError(
      {error: {
        errors: []
      }}
    ));
    
    this.setState({
        [filedName]: filedValue,
        vardImage,
    },()=> {
      // this.isValid(filedName , filedValue);  
    });
  }

  changecheckboxHandler(e , field){
    this.setState({savedCard:!this.state.savedCard})
  }

  isValid(name , value){
    let isError = {}

    switch (name) {
      case "creditCardNumber":
          isError.creditCardNumber =
            value.length > 4  ? "Atleast 4 characaters required" : "";
          break;
      case "cardHolderName":
          isError.cardHolderName =  
          value  ? "Atleast 4 characaters required" : "";
          break;
      case "editExpireDate":
          isError.editExpireDate =  
          value ? "required" : "";
          break;
      case "code":
        isError.code =  
        value ? "required" : "";
        break;
      case "selectedcountry":
        isError.selectedcountry =
        value.id ? "" : "required";
        console.log(value,'valuevaluevalue')
        break;
      case "address_line_1":
        isError.address_line_1 =
        value == '' ? "required" : "";
        break;
      case "address_line_2":
        isError.address_line_2 =
        value == '' ? "required" : "";
        break;
      case "city":
        isError.city =
        value == '' ? "required" : "";
        break;
      case "selectedstate":
        console.log(value,'value')
        isError.selectedstate =
        value.id ? "" : "required";
        break;
      case "zip":
        isError.zip =
        value == '' ? "required" : "";
        break;
      default:
          break;
    }

    this.setState({ isError },this.validateForm)
  }

  isValidate(){
    const { cardHolderName, creditCardNumber, editExpireDate , code , address_line_1 , city , selectedcountry , selectedstate ,zip ,primaryCardSelected} = this.state;
    var error = {errors:[]};
    var isError = false;

    

    if(address_line_1.toString().trim() == ""){
      isError = true;
      error['errors']['address.billing.address_line_1'] = ["The address field is required."];
    }

    if(city.toString().trim() == ""){
      isError = true;
      error['errors']['address.billing.city'] = ["The city field is required."];
    }

    if(isRequired(selectedcountry)){
      isError = true;
      error['errors']['address.billing.country_id'] = ["The country  field is required."];
    }

    if(isRequired(selectedstate)){
      isError = true;
      error['errors']['address.billing.state_id'] = ["The state  field is required."];
    }

    if(zip.toString().trim() == "" ){
      isError = true;
      error['errors']['address.billing.zip'] = ["The zip  field is required."];
    }
    
    if(this.state.savedCard){
      if(isRequired(primaryCardSelected)){
        isError = true;
        error['errors']['pay_info.card_id'] = ["The pay card field is required."];
      }
    }else{
      if(cardHolderName.toString().trim() == ""){
        isError = true;
        error['errors']['pay_info.card.name'] = ["Please enter name"];
      }
  
      if(creditCardNumber.toString().trim() == ""){
        isError = true;
        error['errors']['pay_info.card.number'] = ["The card number field is required."];
      }
  
      if(editExpireDate.toString().trim() == ""){
        isError = true;
        error['errors']['pay_info.card.expiry_year'] = ["The card expiry date field is required."];
      }
  
      if(code.toString().trim() == ""){
        isError = true;
        error['errors']['pay_info.card.cvv'] = ["The cvv field is required."];
      }
    }

    this.props.dispatch(showError(error));
    return isError
  }
  

  validateForm(){

    if(this.state.creditCardNumber !== '' || this.state.primaryCardHolderName &&
    this.state.cardHolderName !== '' || this.state.primaryCardHolderName&&
      this.state.editExpireDate !== '' || this.state.primaryCardHolderExipreDate &&
      this.state.code !== '' || this.state.selectedcountry !== '' && this.state.city && this.state.address_line_1 !== '' && this.state.selectedstate !== ''
      && this.state.zip !== ''){
            this.setState({formValid: true});
        }else{
            this.setState({formValid: false});
        }
  }



  async subscribeNew(){
    // var selectedPractices= [];
    // this.state.selectedPractices.map((ids)=>{
    //   selectedPractices.push(ids.id);
    // })

    if(this.isValidate()) return;

    var order_details = this.state.order_details
      let params = {
        order_details,
      "pay_info": {
          "type": "card",
          "card": {
              "name": this.state.cardHolderName,
              "number":  this.state.creditCardNumber,
              "expiry_month":  parseInt(this.state.editExpireDate.slice(0,2)),
              "expiry_year":  parseInt(this.state.editExpireDate.slice(2,6)),
              "cvv": this.state.code
          },
      "pay_once": 1,
      "card_id": 0,
      },
      "address":{
        "billing":{
            "address_line_1":this.state.address_line_1,
            "address_line_2":this.state.address_line_2,
            "city":this.state.city,
            "zip":this.state.zip,
            "state_id":this.state.selectedstate.id,
            "country_id":this.state.selectedcountry.id
        }
      }
      }

      let params2 = {
        order_details,
        "pay_info": {
            "type": "card",
            "card":{},
        "pay_once": 0,
        "card_id": this.state.primaryCardSelected && this.state.primaryCardSelected.id && this.state.primaryCardSelected.id || '',
        },
        "address":{
          "billing":{
              "address_line_1":this.state.address_line_1,
              "address_line_2":this.state.address_line_2,
              "city":this.state.city,
              "zip":this.state.zip,
              "state_id":this.state.selectedstate.id,
              "country_id":this.state.selectedcountry.id
          }
        }
      }


      
      if(this.state.savedCard){
        await this.props.dispatch(purchaseCorporatHardware(params2));
      }else{
        await this.props.dispatch(purchaseCorporatHardware(params));
      }
  }


    async getState(countryId){
    this.setState({selectedstate:null})

    await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
            var countryState = response.data;
            this.changeHandler( countryState,'countryState')
            // this.changeHandler( [], 'selectedstate')
        }
    });
  }

  nextOrder(){
    console.log(this.state.currentOrder +1  , this.state.order_details.length , 'sloipo' )
    if(this.state.currentOrder + 1 < this.state.order_details.length ){
      this.setState((prevState=>({ currentOrder: prevState.currentOrder + 1 , selectedhardware:[...prevState.selectedhardware] }) ));
    }
  }

  prvOrder(){
    console.log(this.state.currentOrder , this.state.order_details.length , 'sloipo' )

    if(this.state.currentOrder + 1 > 1){
      this.setState((prevState=>({ currentOrder: prevState.currentOrder - 1 , selectedhardware:[...prevState.selectedhardware] }) ));
    }
  }

  customOpetionLabel({last4, brand}) {
    return  <div className="custome__opetion d-flex align-items-center">
         <div className="card_brand__img mr-4">
            <img src={getCartImageByType(brand)} alt={brand}/>
         </div>
         <div className="select__label">{`XXXX XXXX XXXX ${last4}`}</div>
      </div>
   };

  render (){
    const {t,corporathardwarelist,corporatpracticeslist , corporathardwarecalc,country,practiceProfileInfo, error} = this.props;
    var imgsrc = !this.state.isReplace ? this.state.vardImage : this.state.vardImage
    var currency_symbol = corporathardwarelist && corporathardwarelist.currency && corporathardwarelist.currency.symbol || ''
    const { isError } = this.state

    return(
      <div className="main__screen purchase__page  d-flex flex-column h-100 align-items-center ">
          <SubHeader className="mb-4" title="Purchase Hardware  |  Corporation"/>
          <div className="mw-1270 transparent__form--white d-flex flex-column align-items-stretch">
            {!this.state.isReviewClick ?
            <>
              <div className="inner_white_box mb-3 purchase_header">
                <Row>
                    <Col md={4} className="order_number">
                        <FormGroup className="m-0 d-flex  align-items-center justify-content-between">
                            <h4 className="m-0">Order {this.state.currentOrder + 1}</h4>
                            <div className="arrow">
                              <Button color="link" onClick={()=>this.prvOrder()}><InlineSVG src='<svg xmlns="http://www.w3.org/2000/svg" width="30.705" height="17.634" viewBox="0 0 30.705 17.634">
                              <path fill="#fff" d="M14.557,26.823.892,13.158a1.687,1.687,0,0,1,0-2.386l.594-.594a1.687,1.687,0,0,1,2.384,0L15.739,22.3,27.631,10.175a1.687,1.687,0,0,1,2.384,0l.594.594a1.687,1.687,0,0,1,0,2.386L16.943,26.823A1.688,1.688,0,0,1,14.557,26.823Z" transform="translate(-0.398 -9.683)"/>
                              </svg>' /> </Button>
                              <Button color="link" onClick={()=>this.nextOrder()} className="next-click"><InlineSVG src='<svg xmlns="http://www.w3.org/2000/svg" width="30.705" height="17.634" viewBox="0 0 30.705 17.634">
                              <path fill="#fff" d="M14.557,26.823.892,13.158a1.687,1.687,0,0,1,0-2.386l.594-.594a1.687,1.687,0,0,1,2.384,0L15.739,22.3,27.631,10.175a1.687,1.687,0,0,1,2.384,0l.594.594a1.687,1.687,0,0,1,0,2.386L16.943,26.823A1.688,1.688,0,0,1,14.557,26.823Z" transform="translate(-0.398 -9.683)"/>
                              </svg>' /> </Button>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={8} className="total_summary">
                      <div className="d-flex ml-auto">
                      <div className="total_order">
                        <h4 className="m-0">Total orders   <span>{this.state.currentOrder + 1}</span> </h4>
                      </div>
                        <div className="order_amount">
                          <h4 className="m-0">Order(s) total   <span>{`${currency_symbol}${corporathardwarecalc.total && corporathardwarecalc.total.price || 0}`}</span></h4>
                        </div>
                      </div>
                    </Col>
                </Row>
              </div>
              <div  className="purchase_card d-flex">
                  <div className="purchase_detail"> 
                        <div className="hardware_Detail">
                          <div className="hardware_heading">
                              <FormGroup className="d-flex justify-content-between">
                                <h4 className="m-0">Hardware & Addons</h4>
                                <h4>Total: {`${currency_symbol}${corporathardwarecalc.order_detail && corporathardwarecalc.order_detail[this.state.currentOrder] && corporathardwarecalc.order_detail[this.state.currentOrder].order_total || 0}`}</h4>
                              </FormGroup>
                          </div> 
                          <div className="hardware_title">
                            <Label className="mb-3">Android devices (Optional)</Label>
                          </div> 
                          <div className="device_detail">
                                  {
                                  corporathardwarelist.hardware && corporathardwarelist.hardware.map((hardware , index)=>{
                                      return <><ListGroup key={index} className="device_name"> 
                                            <ListGroupItem className="py-3 border-bottom-0 bg_gray align-items-center d-flex justify-content-between">
                                                <Label className="m-0 col-6 p-0">{hardware.hardware.name}</Label>
                                                <DropDown 
                                                    className='react-select-container w-77 bg_white'
                                                    classNamePrefix="react-select"
                                                    options={count}
                                                    getOptionLabel={ x => `${x.label}`}
                                                    getOptionValue={ x => `${x.value} ${x.label}`}
                                                    onChange={(val)=> this.onEditSaveSubscribeCalc('hardwares', val, index) }
                                                    isSearchable={true}
                                                    placeholder={''}
                                                    value={this.state.selectedhardware[this.state.currentOrder]}
                                                />
                                                <Label className="m-0">{`${currency_symbol}  ${(hardware.price * this.state.selectedhardware[this.state.currentOrder].value).toFixed(2)}`}</Label>
                                                <Button type="button" color="link" className="p-0"><img src={Que} alt=""/></Button>
                                              </ListGroupItem>
                                            </ListGroup>

                                            <ListGroup className="extend_licence">
                                              <ListGroupItem className="py-3 align-items-center d-flex justify-content-between">
                                                <div className="mw-300">
                                                  <div className="custom-checkbox">
                                                    <input type="checkbox" name={`${hardware.hardware_id}`} disabled={this.state.selectedhardware[this.state.currentOrder] && this.state.selectedhardware[this.state.currentOrder].value > 0 ? false : true} checked={this.state.selectedhardware[this.state.currentOrder] && this.state.withWarranty[this.state.currentOrder]}  className="custom-control-input" onChange={(event)=>this.onEditSaveSubscribeCalc('with_warranty' , event.target.checked , index )}  id={`hardware__${index}`} />
                                                    <label className="custom-control-label" htmlFor={`hardware__${index}`}>{`Add extended warranty (1 years)`}</label>
                                                  </div>
                                                </div>
                                                <Label className="m-0">{`${currency_symbol}${ corporathardwarecalc.order_detail && corporathardwarecalc.order_detail[this.state.currentOrder] && corporathardwarecalc.order_detail[this.state.currentOrder].hardwares && corporathardwarecalc.order_detail[this.state.currentOrder].hardwares[index] && corporathardwarecalc.order_detail[this.state.currentOrder].hardwares[index].total_qty_warranty_price > 0 ? corporathardwarecalc.order_detail[this.state.currentOrder].hardwares[index] && corporathardwarecalc.order_detail[this.state.currentOrder].hardwares[index].total_qty_warranty_price && corporathardwarecalc.order_detail[this.state.currentOrder].hardwares[index].total_qty_warranty_price : hardware.extended_warranty || '0'}`}</Label>
                                                <div className="p-1"></div>
                                              </ListGroupItem>
                                            </ListGroup>
                                            </>
                                  })
                                }
                          </div>
                        </div>
                  </div>
                  <div className="practices_Detail">
                    <h4 className="mb-5">Practices</h4>
                    <p>The total cost for this order will be multiplied by the amount of practices selected.</p>
                    <div className="numberof_orders">
                        <p>Order total cost  x  Number of practices for delivery</p>
                        <span>{`${currency_symbol}${corporathardwarecalc.order_detail && corporathardwarecalc.order_detail[this.state.currentOrder] && corporathardwarecalc.order_detail[this.state.currentOrder].none_practice_order_total || 0}`}  x  {this.state.selectedPractices[this.state.currentOrder] && this.state.selectedPractices[this.state.currentOrder].length || 0}</span>
                    </div>
                    <FormGroup className="select-location">
                      <Label>Select the location(s) to send this order</Label>
                      <DropDown 
                          className={`${isError.selectedPractices ? 'invalid-field' : ''} react-select-container react-select__multi w-100`}
                          classNamePrefix="react-select"
                          options={corporatpracticeslist}
                          getOptionLabel={ x => `${x.name}`}
                          getOptionValue={ x => `${x.id} ${x.id}`}
                          onChange={(val)=> this.onEditSaveSubscribeCalc('selectedPractices', val) }
                          isSearchable={false}
                          value={this.state.selectedPractices[this.state.currentOrder] }
                          placeholder={'Enter practice(s) name or search on the list'}
                          isMulti={true}
                      />
                      {isError.selectedPractices  && <span className='text--danger' style={{fontSize:'14px'}}>{isError.selectedPractices}</span>}

                    </FormGroup>
                  </div>
              </div>
              <div className="purchase-footer">
                  <div className="d-flex">
                      <Button color="primary" disabled={this.state.selectedhardware[this.state.currentOrder] && this.state.selectedhardware[this.state.currentOrder].value > 0 ? false : true} className="review_btn" onClick={this.onReviewClick}>Review order(s) & Purchase</Button>
                      <Button color="primary" size="md" disabled={(this.state.selectedhardware[this.state.currentOrder] && this.state.selectedhardware[this.state.currentOrder].value > 0 ? false : true)} onClick={()=> this.addOrder()}>Add another order</Button>
                      <Button type='button' color="default white col" outline className="ml-auto" onClick={() => history.push('/corporate')} size="md">Cancel</Button> 
                  </div>
              </div>
            </> 
            :
              <>
              <div className="review_card"> 
                <div className="inner_white_box mb-3">
                    <h4 className="review_order">Review your order(s)</h4>
                    { corporathardwarecalc.order_detail && corporathardwarecalc.order_detail.length > 0 && corporathardwarecalc.order_detail.map((hardwares , index)=>{
                      var items = hardwares.detail
                      return <><div className="order-detail">
                        <div className="order-header py-4">
                          <Row>
                            <Col md={6} className="">
                              <h4>Order {index+1}<span className="edit-order btn btn-link p-0" onClick={this.onBack}>Edit order</span></h4>
                            </Col>
                            <Col md={2} className="">
                            </Col>
                            <Col md={2} className="">
                            </Col>
                            <Col md={2} className="">
                              <h4>{`${currency_symbol}${corporathardwarecalc.total && hardwares.order_total || 0}`}</h4>        
                            </Col>
                        </Row>
                          
                        </div>
                        <div className="order-summary">
                          <Row>
                              <Col md={6} className="">
                                  <h4>Items</h4>
                              </Col>
                              <Col md={2} className="">
                                  <h4>Qty</h4>
                              </Col>
                              <Col md={2} className="">
                                  <h4>Ext. warranty</h4>
                              </Col>
                              <Col md={2} className="">
                                  <h4>Amount</h4>
                              </Col>
                          </Row>
                        {hardwares.hardwares.detail && hardwares.hardwares.detail.length > 0 && hardwares.hardwares.detail.map((item , index)=>{
                          return <Row>
                            <Col md={6} className="">
                                <Label className="my-3">{item.hardware.name}</Label>
                            </Col>
                            <Col md={2} className="">
                                <Label className="my-3">{item.qty}</Label>
                            </Col>
                            <Col md={2} className="">
                                <Label className="my-3">{item.with_warranty == 1 ? 'Yes' : 'No'}</Label>
                            </Col>
                            <Col md={2} className="">
                                <Label className="my-3">{currency_symbol}{item.price}</Label>
                            </Col>
                        </Row>
                        })}
                        <div className="delivery-address">
                            <h4>Deliver addresses  <span className="order-instruction"> <span className='text--danger'>*</span>Each selected practice will receive all items included in “Order 1”.</span></h4>  
                              <div className="address-list">
                                {this.state.selectedPractices[index].map((practice)=>{
                                    return <div className="address">{practice.name}</div>   
                                })}
                              </div>     
                        </div>    
                        </div>
                      </div>
                      </>
                      })}
                      <div className="grand-total">
                        <Row className="d-flex mt-5 align-items-center justify-content-between shipping-charge">
                            <Col md={6} className="">
                                <Label className="my-3">Shipping</Label>
                            </Col>
                            <Col md={2} className="">
                                <Label className="my-3">{`${currency_symbol}${corporathardwarecalc.shipping_charge && corporathardwarecalc.shipping_charge.price || 0}`}</Label>
                            </Col>
                        </Row>
                        <Row className="d-flex mt-5 align-items-center justify-content-between tax-charge">
                            <Col md={6} className="">
                                <Label className="my-3">Tax</Label>
                            </Col>
                            <Col md={2} className="">
                                <Label className="my-3">{`${currency_symbol}${corporathardwarecalc.tax && corporathardwarecalc.tax.price || 0}(${corporathardwarecalc.tax && corporathardwarecalc.tax.tax})%`}</Label>
                            </Col>
                        </Row>
                        <Row className="d-flex mt-5 align-items-center justify-content-between total-summary">
                            <Col md={6} className="">
                                <h4>Grand total</h4>
                            </Col>
                            <Col md={2} className="">
                                <h4>{`${currency_symbol}${corporathardwarecalc.total && corporathardwarecalc.total.final_price || 0}`}</h4>
                            </Col>
                        </Row>        
                      </div>
                </div>
                
                <div className="inner_white_box mb-3 payment-section">
                      <div className="review_heading d-flex">
                          <h4>Payment</h4>
                          <div className="pay-icons">
                              <img src={Visa} alt="visa" />
                              <img src={AmericanExpress} alt="visa" />
                              <img src={Discover} alt="visa" />
                              <img src={MasterCard} alt="visa" />
                              <img src={Que} alt="visa" className="que-icon" />
                              <img src={PoweredByStripe} alt="visa" />
                          </div>
                      </div>
                <div className="mb-20">
                  <Row>
                      <Col md={5} className="pr-5">
                        <div className="sm custom-checkbox custom-control ">
                            <input type="checkbox"   name={'savedCard'} onChange={(event)=>this.changecheckboxHandler(event,'savedCard')} checked={this.state.savedCard} className="custom-control-input" id="savedCard"/>
                            <label className="custom-control-label" htmlFor="savedCard">Select a credit card saved in your account</label>
                        </div>
                        {console.log(this.state.savedCard,'savedCard')}
                        <FormGroup className="select-location">
                          <Label>Select a credit card from your account</Label>
                          <DropDown 
                              className={`${error && error.errors && error.errors['pay_info.card_id'] ? 'invalid-field' : ''} react-select-container w-100`}
                              classNamePrefix="react-select"
                              options={this.props.designateCardMultiple && this.props.designateCardMultiple.primaryCards || []}
                              formatOptionLabel={this.customOpetionLabel}
                              onChange={(options) => this.changeHandler( options, "primaryCardSelected")}
                              getOptionLabel={x => `XXXX XXXX XXXX ${x.last4}`}
                              getOptionValue={x => `${x.pay_card_id}`}
                              placeholder={'Enter the last 4 digits or search on the list'}
                              isDisabled={!this.state.savedCard}
                          />
                          {error && error.errors && error.errors['pay_info.card_id'] && <span className='text--danger'>{error.errors['pay_info.card_id'][0]}</span>}
                        </FormGroup>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <InputField className="bg__yellow mw_auto"  type="text" label="Name on Card" value={this.state.primaryCardHolderName}  name="cardHolderName" disabled />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <InputField className="bg__yellow mw_auto"  type="text" label={'Expiry date'} name="expirydate" value={this.state.primaryCardHolderExipreDate} disabled/>
                            {/* <NumberFormat  className="bg__yellow mw_auto form-control"  format="## / ####" placeholder="MM / YYYY" mask={['M', 'M', 'Y', 'Y','Y','Y']} name="editExpireDate"  /> */}
                          </Col> 
                        </Row>
                       </Col> 
                       <Col md={7} className="pl-5 pr-5">
                            <div className="sm custom-checkbox custom-control ">
                                <input  type="checkbox" onChange={(event)=>this.changecheckboxHandler(event,'usenewcreditcard')} checked={!this.state.savedCard} name={'usenewcreditcard'}  className="custom-control-input" id="usenewcreditcard"/>
                                <label className="custom-control-label" htmlFor="usenewcreditcard">Use a new credit card</label>
                            </div>
                            <Row>
                                  <Col md={9} className="">
                                    <FormGroup className="icon-card">
                                        <Label>Credit card number</Label>
                                        <NumberFormat disabled={this.state.savedCard} className={`${error && error.errors && error.errors['pay_info.card.number'] ? 'invalid-field' : ''} bg__yellow mw_auto form-control`} format="#### #### #### ####" name="creditCardNumber" value={this.state.creditCardNumber} onValueChange={(value)=>this.changeHandler(value, "creditCardNumber")}/>
                                        {imgsrc ? <img src={imgsrc}  className="icon-img" alt="" /> : null}
                                        {error && error.errors && error.errors['pay_info.card.number'] && <span className='text--danger'>{error.errors['pay_info.card.number'][0]}</span>}
                                    </FormGroup>
                                    {/* <FormGroup>
                                        <InputField className="bg__yellow mw_auto" type="text" label="Name on card" name="cardHolderName" />
                                    </FormGroup> */}
                                  </Col> 
                                  <Col md={3} className="">
                                    <Label>Expire date</Label>
                                    <NumberFormat disabled={this.state.savedCard}  className={`${(error && error.errors && error.errors['pay_info.card.expiry_year'] || error && error.errors && error.errors['pay_info.card.expiry_month']) ? 'invalid-field' : ''} bg__yellow mw_auto form-control`}  format="## / ##" placeholder="MM / YY" mask={['M', 'M', 'Y', 'Y']} name="editExpireDate" onValueChange={(value)=>this.changeHandler(value, "editExpireDate")} />
                                    {/* {error && error.errors && error.errors['pay_info.card.number'] && <span className='text--danger'>{error.errors['pay_info.card.number'][0]}</span>} */}
                                  </Col>
                            </Row>
                            <Row>
                              <Col md={9} className="">
                                <FormGroup>
                                    <InputField disabled={this.state.savedCard} className={`${error && error.errors && error.errors['pay_info.card.name'] ? 'invalid-field' : ''} bg__yellow mw_auto`} type="text" label="Name on card" value={this.state.cardHolderName} name="cardHolderName" onChange={(e) => this.changeHandler( e )}  />
                                    {error && error.errors && error.errors['pay_info.card.name'] && <span className='text--danger'>{error.errors['pay_info.card.name'][0]}</span>}

                                </FormGroup>
                              </Col>
                              <Col md={3} className="">
                                <FormGroup className="cvv-img">
                                    <Label>Security code</Label>
                                    <NumberFormat  disabled={this.state.savedCard} className={`${error && error.errors && error.errors['pay_info.card.cvv'] ? 'invalid-field' : ''} bg__yellow mw_auto form-control` }  format={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "####" : "###"} placeholder={getCardNameByNo(this.state.creditCardNumber) == "AMEX" ? "XXXX" : "XXX"} name="code" onValueChange={(value)=>this.changeHandler(value, "code")} type="password"/>
                                    <div className="what-cvv">? <span><img src={CVVIconHover} alt=""/> <p>The 3 or 4 numbers on the back of your Visa, MasterCard, or AMEX card.</p></span></div>
                                </FormGroup>
                              </Col>
                            </Row>
                       </Col> 
                  </Row>
                  <Row>
                  <Col md={5} className="d-flex justify-content-between pr-5">
                    <FormGroup className="my-5">
                        <h4 className="m-0">Billing address</h4>
                    </FormGroup>
                    <FormGroup className="my-5">
                        <div className="sm custom-checkbox custom-control ">
                            <input type="checkbox"  disabled={false} name={'sameasmainpractice'} onChange={(event)=>this.changeHandler(event,'sameasmainpractice')} checked={this.state.sameasmainpractice} className="custom-control-input" id="sameasmainpractice"/>
                            <label className="custom-control-label" htmlFor="sameasmainpractice">same as main practice</label>
                        </div>
                    </FormGroup>
                  </Col>
                  </Row>
                  <Row>
                      <Col md={5} className="pr-5">
                        <FormGroup>
                          <div className="d-flex justify-content-between">
                            <Label for="country">{t('form.country')}</Label>
                          </div>
                          <DropDown 
                              className={`${error && error.errors && error.errors['address.billing.country_id'] ? 'invalid-field' : ''} react-select-container bg__yellow`}
                              classNamePrefix="react-select"
                              options={country}
                              getOptionLabel={ x => `${x.name}`}
                              getOptionValue={ x => `${x.id} ${x.name}`}
                              onChange={(val)=> this.changeHandler(val, 'selectedcountry') }
                              isSearchable={true}
                              placeholder={''}
                              value={this.state.selectedcountry}
                              isDisabled={this.state.sameasmainpractice}
                          />
                          {error && error.errors && error.errors['address.billing.country_id'] && <span className='text--danger'>{error.errors['address.billing.country_id'][0]}</span>}

                        </FormGroup>
                      </Col>
                      <Col md={7} className="pl-5">
                        <FormGroup className="">
                          <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.city')} name="city" value={this.state.city} className={`${error && error.errors && error.errors['address.billing.city'] ? 'invalid-field' : '' } bg__yellow mw_auto`} onChange={(e) => this.changeHandler(e)} />
                          {error && error.errors && error.errors['address.billing.city'] && <span className='text--danger'>{error.errors['address.billing.city'][0]}</span>}
                        </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                    <Col md={5} className="pr-5">
                      <FormGroup>
                        <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.streetandnumber')} name="address_line_1" value={this.state.address_line_1} className={`${error && error.errors && error.errors['address.billing.address_line_1'] ? 'invalid-field' : ''} bg__yellow mw_auto`}  onChange={(e) => this.changeHandler(e)}  />
                        {error && error.errors && error.errors['address.billing.address_line_1'] && <span className='text--danger'>{error.errors['address.billing.address_line_1'][0]}</span>}
                      </FormGroup>
                    </Col>
                    <Col md={7} className="pl-5">
                      <FormGroup>
                        <div className="d-flex justify-content-between">
                          <Label for="state">{t('form.state')}</Label>
                        </div>
                        <DropDown 
                            className={`${error && error.errors && error.errors['address.billing.state_id'] ? 'invalid-field' : ''} react-select-container react-select__multi bg__yellow`}
                            classNamePrefix="react-select"
                            options={this.state.countryState}
                            getOptionLabel={ x => `${x.name}`}
                            getOptionValue={ x => `${x.id} ${x.name}`}
                            onChange={(val)=> this.changeHandler(val, 'selectedstate') }
                            isSearchable={true}
                            placeholder={''}
                            value={this.state.selectedstate}
                            isDisabled={this.state.sameasmainpractice}
                        />
                        {error && error.errors && error.errors['address.billing.state_id'] && <span className='text--danger'>{error.errors['address.billing.state_id'][0]}</span>}

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} className="pr-5">
                      <FormGroup>
                        <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.apartment')} name="address_line_2" value={this.state.address_line_2} className="bg__yellow mw_auto" onChange={(e) => this.changeHandler(e)}  />
                      </FormGroup>
                    </Col>
                    <Col md={7} className="pl-5">
                      <FormGroup>
                        <InputField type="text" disabled={this.state.sameasmainpractice} label={t('form.postalcode')} name="zip" value={this.state.zip} className={`${error && error.errors && error.errors['address.billing.zip'] ? 'invalid-field' : ''} bg__yellow mw_auto`}  onChange={(e) => this.changeHandler(e)}  />
                        {error && error.errors && error.errors['address.billing.zip'] && <span className='text--danger'>{error.errors['address.billing.zip'][0]}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
              </div> 
                 </div>
              </div>
              <div className="purchase-footer">
                  <div className="d-flex">
                      {/* <Button color="primary" className="review_btn" >Purchase hardware</Button> */}
                      <LoadingButton type="button" disabled={this.props.buttonLoading} loading={this.props.buttonLoading} onClick={() => this.subscribeNew() }  label="Purchase hardware"  size=""  className={`review_btn`} color="primary"/>
                      <Button color="default white col" outline size="md" onClick={this.onBack}>Back</Button>
                      <Button color="default white col" type='button' outline className="ml-auto" size="md" onClick={() => history.push('/corporate')}>Cancel</Button> 
                  </div>
              </div>
              </>
            }
          </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  corporathardwarelist: state.task.corporathardwarelist,
  corporatpracticeslist:state.task.corporatpracticeslist,
  corporathardwarecalc:state.task.corporathardwarecalc,
  country: state.task.country,
  practiceProfileInfo:state.task.practiceProfileInfo,
  buttonLoading:state.task.buttonLoading,
  designateCardMultiple: state.task.designateCardMultiple,
  error:state.task.error,
  mainPracticeAddress: state.task.mainPracticeAddress
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
