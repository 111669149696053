import React, { useState } from 'react';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import LoadingButton from '../../components/LoadingButton'
import { history } from '../../pages/index';
import DropDown from '../../components/DropDown'
import Device from '../../img/devices_icon.svg'; 
import { GetauthorizedDevice, cleardata, resetSuccess, UnauthorizedDevice,RemoveAdditionalDeviceRequest , RemoveAdditionalDevice, GetSubscribePriceOption, SubscribeAdditionalDevice , SubscribeList } from "../../store/actions/taskAction";
import './style.scss';
import { Button, Label } from 'reactstrap';

class ManageDevice extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      activeItem:[],
      data: null,
      isAdditionalClick:false,
      currentPage: 1,
      total: 0,
      per_page: 5,
      isRemove:false,
      totalDevice:0,
      totelPage:0,
      isRemoveVerify:false,
      isAddDevice:false,
      isAddNew:false,
      selectedAdditionalDevice: false,
      addtionalDeviceDropDown:[],
    }
    
    this.unauthorized_device = this.unauthorized_device.bind(this);
    this.AddAdditionalDevice = this.AddAdditionalDevice.bind(this);
    this.onClickAdditionalDevices = this.onClickAdditionalDevices.bind(this);
    this.removeAdditionalDevice = this.removeAdditionalDevice.bind(this);
    
  }

  onClickAdditionalDevices(){
    this.setState({isAdditionalClick:!this.state.isAdditionalClick})
    this.props.dispatch(resetSuccess());
  }

  async AddAdditionalDevice(){
    let params = {
      "additional_devices_count":this.state.selectedAdditionalDevice.count
    }  
    await this.props.dispatch(SubscribeAdditionalDevice(params))    
    await  setTimeout(() => { 
        this.props.dispatch(resetSuccess());
        this.setState({isAdditionalClick:false})
      }, 5000)
  }

  handlechangePage(e, index) {
    index = index + 1
   
    this.setState({currentPage: index},() => {
      let params = {
          page: this.state.currentPage,
          per_page:this.state.per_page
      }
      this.props.dispatch(GetauthorizedDevice(params))    
    });
  }

  async componentDidMount(){
    await  this.props.dispatch(resetSuccess());
    this.props.dispatch(SubscribeList());
    
    this.onChangeValue('isLoading' , false)
    this.onChangeValue('isRemove' , false)
    this.onChangeValue('isSuccessRemove' , false)

    var addtionalDeviceDropDown = [];
    var currencySymbol = this.props.getSubscribePriceOption.currency && this.props.getSubscribePriceOption.currency.symbol || ''

    Object.keys(this.props.getSubscribePriceOption.device).map((sub)=>{
      var subScriptinKey = this.props.getSubscribePriceOption.device[sub];
      var price = subScriptinKey.price;
      var count = subScriptinKey.count;
      addtionalDeviceDropDown.push({
          ...subScriptinKey,
          label: `${count} Devices |  ${currencySymbol}${price}`,
          value: count
      })
    });
    this.setState({total:this.props.authorizedDeviceList.last_page ,addtionalDeviceDropDown })
  }

  unauthorized_device(id) {
    this.props.dispatch(UnauthorizedDevice(id));
  }

 async removeAdditionalDevice(){

  this.onChangeValue('isLoading' , true)
  this.onChangeValue('isSuccessRemove' , false)

    let params = {
      "additional_devices_count":5
    }  

    await this.props.dispatch(RemoveAdditionalDevice(params))
    
    await  setTimeout(() => { 
          this.onChangeValue('isSuccessRemove' , false)
    }, 2000)
  }

  onChangeValue(field , value){
    let authorizedDeviceList = this.props.authorizedDeviceList
    authorizedDeviceList[field] = value
    this.props.dispatch(RemoveAdditionalDeviceRequest(authorizedDeviceList))
  }

  toggleRemove(){
    this.onChangeValue('isRemove' , true)
  }

  render() {
    const { authorizedDeviceList , isSuccessAdditional, getSubscribeinfo, subscribeList,  loadingsubscribedevice  , isSuccessRemove} = this.props
    let data = authorizedDeviceList ? authorizedDeviceList.data : [];
    const availabeDevice = subscribeList && subscribeList.available_device;
    return (
      <div className="manage_device__main__screen manage__page total_device  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Manage Devices" />
        <div className="d-flex w-100 mb-auto" style={{marginBottom:"148px"}}>
          <div className="manage__inner d-flex flex-column  mb-auto mx-auto ">
            <div className="manage__inner__header">
              <div className="row">
                <div className="col-8">
                  <h4>Authorized devices</h4>
                  <p>Authorize up to 20 devices to have access to our content. Simply launch Thalamus on your computer, tablet or Apple TV, then authorize the device when prompted.</p>
                </div>
                <div className="col-4 text-right">
                   <img src={Device} alt="device"  className="device_icon"/>
                </div>
              </div>
            </div>
            <div className="manage__inner__body">
              <table className="table" >
                <thead>
                  <tr>
                    <th width="241">DEVICE</th>
                    <th >HARDWARE</th>
                    <th width="186">SOFTWARE</th>
                    <th width="200"></th>
                  </tr>
                </thead>
                <tbody> 
                  <> 
                  {data && data.map((device , index) => {
                    return <tr>
                              <td>{device.name}</td>
                              <td>{device.type} | {device.ip_address}</td>
                              <td>{device.software}</td>
                              <td><Button size="md" color="red"  onClick={() => this.unauthorized_device(device.id)}>Unauthorize</Button></td>
                            </tr>
                    })}
                    {/* {[...Array(availabeDevice)].map((elementInArray, index) => ( 
                      <tr style={{textAlign:"center"}} key="index">
                          <td colSpan="4" style={{lineHeight:"40px"}}>Launch THALAMUS on your device of choice and authorize it when prompted.</td>
                      </tr>
                        ) 
                    )} ; */}
                  </>
                </tbody>
              </table>
            </div>
            <div className="manage__inner__footer mt-auto">
              {this.state.isAdditionalClick &&
                <div className="additional-device-add">
                  <Label className="choose-device">Choose how many additional devices to authorize</Label>
                  <div className="d-flex align-items-center justify-content-between">
                    <DropDown 
                        className='react-select-container mxh-200'
                        classNamePrefix="react-select"
                        options={this.state.addtionalDeviceDropDown}
                        getOptionLabel={ x => `${x.label}`}
                        getOptionValue={ x => `${x.value} ${x.value}`}
                        isSearchable={false}
                        placeholder={''}
                        value={this.state.selectedAdditionalDevice}
                        onChange={(option)=>{ this.setState({ selectedAdditionalDevice: option })}}
                    />
                    <div className="button-section col-6 d-flex justify-content-end">
                      {isSuccessAdditional ?
                        <Button size="xl" color="green"  onClick={ this.onClickAdditionalDevices}>{this.state.selectedAdditionalDevice.count} Domains added</Button>
                      :
                      <>
                        <LoadingButton size="sm" className="mr-4" label="Add"  loading={loadingsubscribedevice} disabled={!this.state.selectedAdditionalDevice || loadingsubscribedevice} onClick={ this.AddAdditionalDevice} color="primary" />
                        <Button size="sm" color="danger"  onClick={ this.onClickAdditionalDevices}>Cancel</Button>
                      </>
                      }
                    </div>
                  </div>
              </div>
              }

              {this.props.practiceInfo.is_paid_account === 1 ?
              <>
                {!this.state.isAdditionalClick && 
                  <>
                   {authorizedDeviceList && authorizedDeviceList.total <= 20 && getSubscribeinfo.available_device && getSubscribeinfo.available_device < 20?
                  <div className="text-center ">
                    <Button size="" className="col-6" onClick={ this.onClickAdditionalDevices} color="primary">Authorize additional devices</Button>
                  </div>
                  :
                  <div className="urgent_call text-center">if more licenses are required, please call <Button color="link" className="p-0 link primary">1(888) 575-7932.</Button> </div>
                  }
                  </>
                }
              </>
              :
              <div className="button-section text-center subscribe-btn">
                <Button  className="col-6" onClick={ this.onClickSubscribe} color="primary">Subscribe to authorize additional devices</Button>
              </div>
              }
            </div>
            {authorizedDeviceList && authorizedDeviceList.last_page > 1 &&
              <div className="manage__inner__footer__pagination mt-5 d-flex justify-content-center align-items-center">
                <ul>
                  {[...Array(authorizedDeviceList && authorizedDeviceList.last_page)].map((page, i) =>
                    <li key={i}><Button type="button"  className={`px-3 py-0 ${i+1 === this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                  )}
                </ul>
              </div>
            }
          </div>
          <div className={` ${authorizedDeviceList.isRemove || authorizedDeviceList.isSuccessRemove ? 'manage_remove_suggestion' : 'null'}  manage__suggestion total_device d-flex flex-column align-items-flex-start justify-content-center`}>
                <h4><span>{authorizedDeviceList && authorizedDeviceList.total}</span> Authorized domains</h4>
                <h4><span>{getSubscribeinfo.available_device && getSubscribeinfo.available_device}</span> Total devices</h4>
                <div className="divider mt-4"></div>
                <h4><span>${getSubscribeinfo.additional_devices && getSubscribeinfo.additional_devices.base_price}</span> (Included)</h4>

                {getSubscribeinfo && getSubscribeinfo.available_device > 10  && 
                  <div className="">
                      <div className="divider mt-4"></div>
                      {(authorizedDeviceList.isRemove || authorizedDeviceList.isSuccessRemove) &&
                        <p>You’re about to downgrade your plan by removing 5 additional authorized devices. All remaining authorized devices will be unauthorized, and it will be necessary to open Thalamus in each device and authorize them again. Would you like to proceed?</p>
                      }
                      <LoadingButton size="md" color="danger" disabled={authorizedDeviceList.isLoading}  className={authorizedDeviceList.isSuccessRemove ? 'btn-green' : ''} label={!authorizedDeviceList.isSuccessRemove ? authorizedDeviceList.isRemove ? 'Yes, Remove devices' : 'Remove 5 devices' : '5 Devices removed'}  loading={authorizedDeviceList.isLoading}  onClick={()=>{authorizedDeviceList.isRemove ? this.removeAdditionalDevice() : this.toggleRemove()}} />
                  </div>
              }
          </div>
        </div>
      </div>

    )
  }

}
const mapStateToProps = (state) => ({
  authorizedDeviceList:state.task.authorizedDeviceList,
  unauthorizedDevice:state.task.unauthorizedDevice,
  subscribeList:state.task.subscribeList,
  optionList:state.task.optionList,
  getSubscribeinfo:state.task.getSubscribeinfo,
  getSubscribePriceOption:state.task.getSubscribePriceOption,
  subscribeAdditionalDevice:state.task.subscribeAdditionalDevice,
  practiceInfo:state.task.practiceInfo,
  loadingsubscribedevice:state.task.loadingsubscribedevice,
  isSuccessAdditional:state.task.isSuccess,
  isSuccessRemove:state.task.isSuccessRemove

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(ManageDevice);