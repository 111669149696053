import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import Loading from '../../../components/Loading'

import About from './About'
import Security from './Security'
import AddProfilePicture from './AddProfilePicture'
import moment from 'moment';
import {DateFormat} from '../../../data/comman'
import {UpdatePracticeUserProfileRequest , GetUserProfile} from "../../../store/actions/taskAction";

const profile_type = [
  { name: 'secure', id: 1},
  { name: 'unsecure', id: 0},
];
class NewProfileForm extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      step: 1,
      activeClass:'',
      stepOneNext: false,
      isGetVerifyCode:false,
      isEnableVerify:false,
      isDeleteProfile:false,
      isError: {
        first_name:'',
        last_name:'',
        selectedRole:'',
      }
    };
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.stepOne = this.stepOne.bind(this);
    this.stepTwo = this.stepTwo.bind(this);
    this.stepThree = this.stepThree.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);

  }

  //next step
    nextStep(){
      const { step } = this.state;
      this.setState({
        step: step + 1,
      })
  }

  // prev step
  prevStep(){
      const { step } = this.state;
      this.setState({
        step: step - 1,
      })
  }

  stepOne(){
    this.setState({
      step:1,
    })
  }
  
  stepTwo(){
    this.setState({
      step:2,
    })
  }

  stepThree(){
    this.setState({
      step:3,
    })
  }

  async onEditPracticeInfo(field , value){
    let practiceUserProfileInfo = this.props.practiceUserProfileInfo
    practiceUserProfileInfo[field] = value
    await this.props.dispatch(UpdatePracticeUserProfileRequest(practiceUserProfileInfo))
  }

  async onupdate(){
    var selectedRole
    var selectedProfileType
    if(this.props.practiceUserProfileInfo.type === 4 || this.props.practiceUserProfileInfo.type === 2){
      selectedRole = this.props.practice_user_type.find(b => b.id === this.props.practiceUserProfileInfo.type)
      selectedProfileType = profile_type.find(b => b.id === this.props.practiceUserProfileInfo.is_secure)

      this.onEditPracticeInfo( 'selectedRole' , selectedRole)
      this.onEditPracticeInfo( 'selectedProfileType' , selectedProfileType)
    }else{
      selectedRole = this.props.practice_user_roles.find(b => b.id === this.props.practiceUserProfileInfo.role)
      selectedProfileType = profile_type.find(b => b.id === this.props.practiceUserProfileInfo.is_secure)

      this.onEditPracticeInfo( 'selectedRole' , selectedRole)
      this.onEditPracticeInfo( 'selectedProfileType' , selectedProfileType)
    }
  }
  
  handleChange(field, options ) {
    var name = "";
    var value = "";

    if(field === "dob" ){
        name = field;
        value =   moment(options).format(DateFormat);
    }else if(field === "question_1" || field === "question_2" || field === "question_3" ){
      name = field;
      value =  options;
    }else if(field === "selectedRole" ){
      name = field;
      value =  options;
    }else if(field === "selectedProfileType"){
      name = field;
      value =  options;
    }else if(field === "oldpasscode"){
      name = field;
      value =  options;
    }else if(field === "passcode"){
      name = field;
      value =  options;
    }else if(field === "newpasscode"){
      name = field;
      value =  options;
    }else if(field === "verificationCode"){
      name = field;
      value =  options;
    }else{
      name = field.target.name;
      value = field.target.value;
    }

    this.setState({
      practiceUserProfileInfo:{
          ...this.state.practiceUserProfileInfo,
          [name]: value,
      }
    },()=> {this.isValid(name, value)});
  };

  
  handlePhoneChange(value, country, e, formattedValue ){
    var fieldValue = "";
  
    fieldValue = value;

    fieldValue = fieldValue.replace(country.dialCode,'');    // 345
    fieldValue = '+'+country.dialCode+'-'+ fieldValue;
    let isError = { ...this.state.isError };

    this.setState({
      isError,
      isGetVerifyCode:true,
      practiceUserProfileInfo:{
            ...this.state.practiceUserProfileInfo,
            phone: fieldValue
        }
    },()=> {this.isValid('phone', fieldValue)})
  }

  isValid(name, value){
    let isError = this.state.isError ;
    
    switch (name) {
      case "first_name":
          isError.first_name =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "last_name":
          isError.last_name =  
            value.length <= 0 ? "required" : "";
          break;
      case "selectedRole":
          isError.selectedRole =  
          value.length <= 0 ? "required" : "";
          break;
      default:
          break;
    }
    this.setState(this.validateForm)

    
  }
  
  validateForm(){
    let isError = this.state.isError ;
    const { step } = this.state;
    const { first_name , last_name , selectedRole , selectedProfileType , verificationCode , phone , newpasscode , oldpasscode , passcode } = this.state.practiceUserProfileInfo;
      
      if(passcode){
        this.setState({ isDeleteProfile: true });
      }

      if(verificationCode && verificationCode.length == 4){
        this.setState({ isEnableVerify: true });
      }else{
        this.setState({ isEnableVerify: false });
      }

      if( step === 1 && first_name !== '' && isError.first_name === '' &&  last_name !== '' && selectedRole !== undefined &&  selectedProfileType !== undefined ){
            
          this.setState({ stepOneNext: true });
          console.log('test')

      }else if(step === 2 &&  newpasscode && oldpasscode && newpasscode.length == 4 && oldpasscode.length == 4) {
              
                this.setState({ stepTwoNext: true });
          
      }else{

        this.setState({ stepOneNext: false , stepTwoNext:false});

      }
  }

  async componentDidMount(){
    // await this.props.dispatch(GetUserProfile())
    await this.onupdate()
  }

  renderStep(step , index){
    // const { first_name , last_name , selectedRole , selectedProfileType , verificationCode , phone , newpasscode , oldpasscode , id , passcode} = this.state.practiceUserProfileInfo;
    // const values =  { first_name , last_name , selectedRole , selectedProfileType  , verificationCode , phone , newpasscode , oldpasscode , id , passcode}
    switch(step){
        case 1:
            return  <About
                      nextStep= { this.nextStep } 
                      isNext={this.state.stepOneNext}
                      isDeleteActive={this.state.isDeleteProfile}
                      handleChange = {this.handleChange}
                      isError={this.state.isError }
                      // values={values}
                    />
        case 2:
            return  <Security 
                      nextStep= { this.nextStep }
                      prevStep = {this.prevStep }
                      isNexttwo={this.state.stepTwoNext}
                      isEnableVerify={this.state.isEnableVerify}
                      isGetVerifyCode={this.state.isGetVerifyCode}
                      handleChange = {this.handleChange}
                      handlePhoneChange={this.handlePhoneChange}
                      // values={values}
                    />
        case 3:
            return  <AddProfilePicture 
                      nextStep= { this.nextStep }
                      prevStep = {this.prevStep }
                      handleChange = {this.handleChange}
                      isNext={this.state.stepOneNext}
                      // values={values}
                    />
        default :
          return 'test1'
    }
  }

  render (){
    
    const { step } = this.state;
    const { t } = this.props
    return(
      <>
        <div className="tabs">
            <ul className="d-flex justify-content-between">
              <li onClick={ this.stepOne } className={`col text-center ${step === 1 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">About</label>
              </li>
              <li onClick={ this.stepTwo } className={`col text-center ${step === 2 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Security</label>
              </li>
              <li onClick={ this.stepThree } className={`col text-center ${step === 3 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Photo</label>
              </li>
            </ul>
        </div>
        { this.renderStep(step) }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceUserProfileInfo: state.task.practiceUserProfileInfo,
  practice_user_roles:state.task.practice_user_roles,
  practice_user_type:state.task.practice_user_type,
  error:state.task.error,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(NewProfileForm);
