import "react-perfect-scrollbar/dist/css/styles.css";
import './App.scss';
import React from 'react';

import Main from '../src/pages';
import Nointernet from './pages/PageLoadingError'
import { ContextMenu, MenuItem, ContextMenuTrigger ,hideMenu} from "react-contextmenu";
import { NavLink } from "reactstrap";
import { CLOSE, EXITFULLSCREEN, FULLSCREEN, MINIMIZE } from "./utilities/HttpUtility";

const REFRESH_KEY = 116;
class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isDisconnected: false,
      isfullscreen:true,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  togglefullscreen(){
    console.log(this.state.isfullscreen,'isfullscreen')
    this.setState({
      isfullscreen: !this.state.isfullscreen
  });
  }

  
  handleKeyDown = (event) => {
      if(event.keyCode == REFRESH_KEY)
      event.preventDefault();
      else if((event.ctrlKey || event.metaKey) && event.which == 82){
      event.preventDefault();
    }
    // switch( event.keyCode ) {
    // case REFRESH_KEY:
    // case (81 && 91):
    // console.log("refresh...!1", event.keyCode,"===>", event.which);
    // event.preventDefault();
    // break;
    // default: 
    // console.log("refresh...!default 1", event.keyCode, event.which);
    // break;
    // }
   }

  componentDidMount() {
    // this.handleConnectionChange();
    // window.addEventListener('online', this.handleConnectionChange);
    // window.addEventListener('offline', this.handleConnectionChange);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    // window.removeEventListener('online', this.handleConnectionChange);
    // window.removeEventListener('offline', this.handleConnectionChange);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if(event.keyCode == REFRESH_KEY)
      event.preventDefault();
    else if((event.ctrlKey || event.metaKey) && event.which == 82){
      event.preventDefault();
    }
}

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('//google.com', {
            mode: 'no-cors',
            })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing)
            });
          }).catch(() => this.setState({ isDisconnected: true }) )
        }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  }

  handleContextClik (event) {
    const ele = document.getElementById("same_unique_identifier");
    hideMenu({
      target  : ele,
      id: 'same_unique_identifier'
    });
  };

  render (){
    const { isDisconnected } = this.state;
    return (
      <div className="app" style={{userSelect:'none'}}   onClick={()=>this.handleContextClik()}>
        { isDisconnected && 
          <Nointernet/>
        }
          <ContextMenuTrigger  className="h-100" id="same_unique_identifier">
          <Main/>
				</ContextMenuTrigger>
        <ContextMenu hideOnLeave={false}   id="same_unique_identifier">
          <MenuItem  						data={{item: "Create Post"}}>
            <NavLink href={MINIMIZE}>Minimize</NavLink>
          </MenuItem>
          <MenuItem  						data={{item: "Create Post"}}>
            <NavLink onClick={()=>this.togglefullscreen()} href={!this.state.isfullscreen ? FULLSCREEN : EXITFULLSCREEN}>{!this.state.isfullscreen ? 'Full screen' : 'Exit full screen' }</NavLink>
          </MenuItem>
          <MenuItem  						data={{item: "Create Post"}}>
            <NavLink href={CLOSE}>Close</NavLink>
          </MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

export default App;
