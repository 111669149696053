
import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Card from '@material-ui/core/Card';
import DropDown from '../../components/DropDown'
import Grid from '@material-ui/core/Grid';
import bg from '../../img/bg.png';
import moment from 'moment';

const useCardStyles = makeStyles({
  root: {
    maxWidth: 250,
    maxHeight: 217,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 0,
  },
});

const useGridStyles = makeStyles((theme) => ({
  root: {
    width:600,
    flexGrow: 1,
    overflow: 'scroll'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function WaitingRoomTimelineSlidePresetsList({type,isOpen,toggle,templates,onSlideSelect,stockImages,onImageSelect,backgroundImages,onBackgroundImagesSelect}) {
  const classesCard = useCardStyles();
  const classesGrid = useGridStyles();

  const [slideData, setSlideData] = useState({});
  const [slideAllData, setSlideAllData] = useState({});
  const [dropDownData, setDropDownData] = useState([]);
  const [selectedSlideCategory, setSelectedSlideCategory] = useState({value:0,label:'All'});

  useEffect(() => {
    const slidesCategory = [{value:0,label:'All'}];
    if(templates?.length){
     templates.map(cate => {
      slidesCategory.push({ value: cate.id, label: cate.title })
     })

     if( templates[0] && templates[0].slide_preset[0] && templates[0].slide_preset[0].id != 0){ // add blank slide
      templates[0].slide_preset.unshift({
        category_id: 1,
        content: "",
        id: 0,
        title: "Blank slide",
      })
    }
      // slidesCategory.push({ vale: 2, label: 'sss' });
      setDropDownData(slidesCategory);
      setSlideData(templates);
      setSlideAllData(templates);
    }
    
  }, [templates]);

  useEffect(() => {
    if (stockImages?.length)
    {
      const stockImgCat = [{value:0,label:'All Category'}];
      stockImages.map(imgCat => {
        stockImgCat.push({ value: imgCat.id, label: imgCat.title });
      });
      setDropDownData(stockImgCat);
      setSlideData(stockImages);
      setSlideAllData(stockImages);
    }
  }, [stockImages])

  useEffect(() => {
    if (backgroundImages?.length)
    {
      const bgImgCat = [{value:0,label:'All Category'}];
      backgroundImages.map(imgCat => {
        bgImgCat.push({ value: imgCat.id, label: imgCat.title });
      });
      setDropDownData(bgImgCat);
      setSlideData(backgroundImages);
      setSlideAllData(backgroundImages);
    }
  }, [backgroundImages])

  const handlechange = (options) => {
    setSelectedSlideCategory(options)
    if (options.value === 0) {
      setSlideData(slideAllData);
    } else {
      const filterdSlides = slideAllData.filter(cate => cate.id === options.value);
      setSlideData(filterdSlides);
    }
  }

  const list = (anchor) => (
    <div className={` ${classesGrid.root} ${type==="template" ?  'slide-preset-section':''}`}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <span className='videolist__main__header__header-title' style={{ color: 'white' }}>
          {type==="template"?  'Slide Presets':''}
          {type==="stockImages"?  'Thalamus library':''}
          </span>
          <p className="videolist__main__header__detail">
          {type==="template"?  'To start your slide from one of our presets, select one of the options below and add your own text. Images can be selected from our stock library or uploaded directly from your device.':''}
          </p>
        </Grid>

        <Grid item xs={12} >
          <div>
            <div className="form-group category_section">
              <label className='videolist__main__header__header-title' style={{ color: 'white' }}>Category</label>
              <DropDown
                className='react-select-container w-100'
                classNamePrefix="react-select"
                options={dropDownData}
                onChange={(selectedOption) => { handlechange(selectedOption); }}
                isSearchable={false}
                defaultvalue={dropDownData[0]}
                value={selectedSlideCategory}
                placeholder='Select Category'
              />
            </div>
          </div>
        </Grid>

        <div className="photo_section">
          <div className="template_list">
              {type === 'template' & slideData.length ? slideData?.map(template => {
              return template.slide_preset.map((slide) => {
                return (
                  <Grid key={slide.id} item onClick={() => onSlideSelect(slide)} style={{ cursor: 'pointer' }}>
                    <span className='videolist__main__header__header-title' style={{ color: 'white', textTransform: 'uppercase' }} >{slide.title} </span>
                    <Card className={classesCard.root}>
                      {slide.id != 0 && <img style={{ objectFit: 'cover' }} alt="title slide" src={slide.image+"?time="+moment().unix()} />}
                    </Card>
                  </Grid>
                )
              });
            }) : null}

          </div>
          <div style={{padding:"0 14px"}}>
          <p style={{marginBottom:"10px", color:"#ffffff"}}>Drag and drop an image in the slide.</p>
          <div className="stock-images">
            {type === "stockImages" & slideData.length ? slideData?.map(image => {
              return image.stock_library_data.map((img) => {
                return (
                  <Grid item xs={6} key={img.id} onClick={() => onImageSelect(img)} style={{ cursor: 'pointer' }}>
                    {/* <span className='videolist__main__header__header-title' style={{ color: 'white',textTransform:'uppercase' }} >{slide.title} </span> */}
                    <Card className={classesCard.root}>
                      <img style={{ objectFit: 'cover', height:175, width: 250 }} alt="title slide" src={img.thumb_url+"?time="+moment().unix()} />
                    </Card>
                  </Grid>
                )
              });
            }) : null}
          </div>

          </div>

          <div style={{padding:"0 14px"}}>
            <div className="stock-images">
                {type === "bgImages" & slideData.length ? slideData?.map(image => {
              return image.slide_background_data.map((img) => {
                return (
                  <Grid item xs={6} key={img.id} onClick={() => onBackgroundImagesSelect(img)} style={{ cursor: 'pointer' }}>
                    {/* <span className='videolist__main__header__header-title' style={{ color: 'white',textTransform:'uppercase' }} >{slide.title} </span> */}
                    <Card className={classesCard.root}>
                      <img style={{ objectFit: 'cover', height: 168, width: 250 }} alt="title slide" src={img.thumb_url} />
                    </Card>
                  </Grid>
                )
              });
            }) : null}
            </div>
          </div>

        </div>

      </Grid>
    </div>
  )

  return (
    <div>
        <React.Fragment >
          <Drawer anchor="left" open={isOpen} onClose={toggle} className="slide-preset-drawer" >
            {list("left")}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
