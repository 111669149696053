import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import SubHeader from '../../components/Header/subHeader';
import "./styles.scss";
import About from './about'
import UploadCetegoryImages from "../../components/UploadCetegoryImages";
import { createNewWaitingRoomChannel, createNewOperatoryChannel } from "../../store/actions/taskAction";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { DateFormat } from "../../data/comman";
import { useLocation, useHistory } from "react-router-dom";
import { history } from "..";
  
const CreateNewWaitingRoom = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOperatoryRoom, setIsOperatoryRoom] = useState(location.param && location.param.isOperatoryRoom);
  const [isWaitingRoom, setIsWaitingRoom] = useState(location.param && location.param.isWaitingRoom);
  const [step, setStep] = useState(isOperatoryRoom || isWaitingRoom ? 1 : 0);
  const [params, setParams] = useState({
      type: 'thumb',
      thumb_alt_text: '',
  });
  const [state, setState] = useState({
      channelName: '',
      description: '',
      selectedPublish: null,
      selectedScheduleDate: null,//new Date(),
      thumb: '',
      feature_image: '',
      selectedThumb: null,
      selectedFeatureImage: null
  });

  const [error, setError] = useState({
      channelName: '',
      description: '',
      selectedPublish: null,
      selectedScheduleDate: null,
      isError: false,
  })

  useEffect(()=>{
    if(!isOperatoryRoom && !isWaitingRoom)
        history.goBack();
  }, [])

  const resetError = () => {
    setError({
      channelName: '',
      description: '',
      selectedPublish: null,
      selectedScheduleDate: null,
      isError: false,
    })
  }

  const setStateValue = (field, value) => {
    console.log(state,'response')
    setState({
      ...state,
      [field]: value,
    })
    resetError();
  }

  const uploadedImage = (response) => {
   
    if(response.type == "thumb"){
      setStateValue("thumb", response.name);
      setStateValue("selectedThumb", response)
    }else{
      setStateValue("feature_image", response.name);
      setStateValue("selectedFeatureImage", response);
    }
  }

  const onAddChannel = () => {

    var channelName = "", description = "", selectedPublish = "", selectedScheduleDate = "", isError = false;

    if(state.channelName == ""){
      channelName = "Please enter channel name";
      isError = true;
    }

    if(state.description == ""){
      description = "Please enter description";
      isError = true;
    }

    if(state.selectedPublish == null){
      selectedPublish = "Please select publishing date";
      isError = true;
    }

    if(state.selectedScheduleDate == null && (state.selectedPublish && state.selectedPublish.value == 2)){
      selectedScheduleDate = "Please select schedule date";
      isError = true;
    }

    if(isError == true){ 
      setError({
        channelName,
        description,
        selectedPublish,
        selectedScheduleDate,
        isError: isError
      })
      return;
    }

    let params ={
      title: state.channelName,
      published_type: state.selectedPublish.value, 
      description: state.description,
      thumb: state.selectedThumb ? state.selectedThumb.name : '',
      feature_image: state.selectedFeatureImage ? state.selectedFeatureImage.name : '',
      is_internal_user: 1,
    }

    if(state.selectedPublish.value == 2)
      params['published_date'] = moment(state.selectedScheduleDate).format(DateFormat);

      console.log(params,'params')

    if(isOperatoryRoom){ // operatory room channel create
      dispatch(createNewOperatoryChannel(params))
    }else{
      dispatch(createNewWaitingRoomChannel(params))
    }
  }

  const onTimeLine = () => {

  }

  const onQuickPreview = () => {

  }

  const renderStep = (step , index)=>{
      switch(step){
          case 1:
              return  <About state={state} setState={setStateValue} onAddChannel={onAddChannel} onTimeLine={onTimeLine} onQuickPreview={onQuickPreview} error={error} isOperatoryRoom={isOperatoryRoom}/>
          case 2:
              return  <div>
                 <UploadCetegoryImages state={state} uploadedImage={uploadedImage} isThalamusChannel={true} isOperatoryRoom={isOperatoryRoom} hideCancelButton={true}/>
              </div>
          default :
            return  null
      }
  }

  const onChangeStape = (data) =>{
      setStep(data)
      setParams({
          ...params,
          type: data == 1 ? 'thumb' : 'feature_image',
      })
  }

  return (
    <>
    {step > 0 &&
      <div className="main__screen channels__page  d-flex flex-column h-100 align-items-center" >
        <SubHeader className="mb-5" title={`New ${isOperatoryRoom ? 'Operatory' : 'Waiting' } Room Channel`}/>
        <div className="tabs mx-auto">
              <ul className="d-flex justify-content-between mb-4">
                  <li onClick={()=>onChangeStape(1)} className={`col text-center ${step === 1 ? 'activetab' : ''}`}>
                      <span></span>
                      <label className="">About</label>
                  </li>
                  <li onClick={()=>onChangeStape(2)} className={`col text-center ${step === 2 ? 'activetab' : ''}`}>
                      <span></span>
                      <label className="">Images</label>
                  </li>
              </ul>
          </div>
        { 
          renderStep(step) 
        }
    </div>}
    </>
  );
}

export default CreateNewWaitingRoom
