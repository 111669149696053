import React from "react";
import SubHeader from "../../components/Header/subHeader";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import Primary from "./Primary";
import Secondary from "./Secondary";
import "./style.scss";
import LocationListing from "../LocationListing";
import { AddNewLoactionRequest } from "../../store/actions/taskAction";

class NewProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primerCreditCard: {},
      secondaryCreditCard: null,
      step: 1,
      isActiveUpdate: true,
      vardImage: "",
      isError: {
        expireDate: "",
      },
    };
  }


  async componentDidMount(){
    this.props.dispatch(AddNewLoactionRequest());
  }

  render() {
    return (
      <LocationListing isActiveAddManager={this.props.location.isActiveAddManager || false} iscorporate={true} />
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  error: state.task.error,
});

export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(NewProfileForm);