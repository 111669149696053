import React from 'react';

import { withTranslation } from 'react-i18next';
import { Button ,  FormGroup} from 'reactstrap';

import { connect } from "react-redux";
import { compose } from 'redux';
import ReactCrop from 'react-image-crop';

import { UpdateUserProfileImage , cleardata} from "../../store/actions/taskAction";
import FileUpload from '../FileUpload'
import 'react-image-crop/lib/ReactCrop.scss';
import "./style.scss";
import HttpUtility, { SLIDE_IMAGE_UPLOAD } from '../../utilities/HttpUtility';
import LoadingButton from '../LoadingButton';
class AddProfilePicture extends React.Component {
  constructor(props) {
    super(props)
    
    var file = props.uriImage ? {
        name: "stock_image"
    } : null ;
    
    
    this.state = {
        profileInfo: {
            ...props.practiceUserLoginInfo,
            file
        },
        title: props.title,
        isVerify:false,
        src: props.uriImage || (props.selectedState && props.selectedState.selectedThumb ? props.selectedState.selectedThumb.image : null) || null,
        isFile:false,
        blobFile:'',
        selectedimg:'',
        crop: {
            aspect: props.isSlide ? undefined : props.aspect,
            unit: '%',
            x: 25,
            y: 25,
            width: 50,
            height: 50,
        },
        loaded:props.progress,
        completed:props.isCompleted,
        file:null,
        imgUpload:'',
        croppedImageUrl:'',
        errors:{
            fileSize:'',
            fileType:'',
        },
        isUpload: false,
        isImageLoad: false,
    };
    this.onChange = this.onChange.bind(this);
    this.UpdateUserProfileImage = this.UpdateUserProfileImage.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
  }

    async componentDidMount(){
        this.props.dispatch(cleardata());
    }

    onChange(e) {     
        this.props.dispatch(cleardata());   
        if (this.validateFile(e.target.files[0])) {
            if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
                reader.addEventListener(
                    'load',
                    () =>
                    this.setState({
                        src: reader.result,
                    }),
                    false
                )
                reader.readAsDataURL(e.target.files[0])
            }
            this.setState({
                profileInfo:{
                    ...this.state.profileInfo,
                    file:e.target.files[0], 
                },
                isFile:true , 
                completed:false, 
                loaded:0 , 
                errors:{filesize:'', fileType:''}
            })
        }else{
            this.setState({
                profileInfo:{
                    ...this.state.profileInfo,
                    file:e.target.files[0], 
                },
                isFile:false , 
                completed:false, 
                loaded:0 , 
            })
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
        // this.imageRef.crossOrigin = 'anonymous';
        this.setState({ isImageLoad: true });
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    }

    onCropChange = crop => {
        this.setState({ crop  })
    }

    cancelUpload(){
        this.setState({ 
            profileInfo:{
                ...this.state.profileInfo,
                file:null, 
            },
            src:null,
            isUpload: false,
            isImageLoad: false,
            isFile: false
        })
    }

    onUploadProgress(progressEvent, file){
        console.log("progressEvent, file", progressEvent, file)
        // var progressFile = this.state.files;
        // var index = this.findVideoId(file);
        // if(progressFile[index]){
        //     progressFile[index].loaded = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        //     this.setState({ files: progressFile, isUpload: false })
        //     // setCount(count + 1)
        // }
    }

    fileSource = (source, file) => {
        console.log("source, file", source, file)
        // var index = this.findVideoId(file)
        // var files = this.state.files;
        // if(files[index])
        //     files[index].source = source;
        // this.setState({ files });
    }

    async UpdateUserProfileImage(e){
        e.preventDefault();
        if(this.state.blobFile){
            this.setState({ isUpload: true }, async () => {
                e.preventDefault() 
                const formData = new FormData();
                if(this.props.isSlide){
                    formData.append('image',this.state.blobFile)
                }else if(this.props.isImage > 0){
                    formData.append('image',this.state.blobFile)
                    formData.append('thumb_alt_text',this.props.params.thumb_alt_text)
                    formData.append('type',this.props.params.type)
                }else if(this.props.uploadedImage){
                    formData.append('image',this.state.blobFile)
                    formData.append('thumb_alt_text',this.props.params.thumb_alt_text)
                    formData.append('type',this.props.params.type)
                    if(this.props.isOperatoryRoom)
                        formData.append('opratory_room_channel', true);
                    else
                        formData.append('waiting_room_channel', true);
                    
                }else{
                    formData.append('profile_pic',this.state.blobFile)
                }

                await  this.props.dispatch(UpdateUserProfileImage(this.props.practiceUserLoginInfo.id ,  formData, this.props.isSlide,  this.props.isSlide ? this.props.slideImage : null, this.props.isImage, this.props.params ? this.props.params.channelId : null, this.props.uploadedImage));
                this.setState({
                    loaded:this.props.progress, 
                })
            })
        }
    }

    async makeClientCrop(crop) {
        console.log(crop,'crop')
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });
        }
    }

    

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        if(this.props&&this.props.params&&this.props.params.type&&this.props.params.type == 'thumb'){
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
          
            // New lines to be added
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = "high";
          
            ctx.drawImage(
              image,
              crop.x * scaleX,
              crop.y * scaleY,
              crop.width * scaleX,
              crop.height * scaleY,
              0,
              0,
              crop.width,
              crop.height
            );
        }else{
            const pixelRatio = window.devicePixelRatio;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width * pixelRatio * scaleX;
            canvas.height = crop.height * pixelRatio * scaleY;
            const ctx = canvas.getContext('2d');
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';
        
            ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
            );
        }
        
    
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                console.log("blob", blob)
                this.setState({blobFile:blob}) // Set blob image inside the state here 
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
      }

      validateFile(file){
        var isError = false;
        var errors = this.state.errors;

        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        
        if (validTypes.indexOf(file.type) === -1) {
            isError = true;
            errors = {
                fileType: 'Format not accepted',
            }
            
        }else if(file.size > 10485760){
            isError = true;
            errors = {
                fileSize: 'Max 10Mb',
            }
        }
        if(isError){ 
            this.setState({errors , file:file , loaded:5});
            return;
        }else{
            return true;
        }
    }

    shouldComponentUpdate(nextProps, nextState){
       

        if(nextProps.title != this.state.title && this.props.isChannelImageChange){
            this.setState({ title: nextProps.title });
            this.cancelUpload();
        }else if(nextProps.title != this.state.title && this.props.isThalamusChannel){
           this.setState({ title: nextProps.title });
           this.cancelUpload();
           if(nextProps.title == "Thumbnail image preview" && nextProps.selectedState && nextProps.selectedState.selectedThumb && nextProps.selectedState.selectedThumb.image){
               this.setState({ src: nextProps.selectedState.selectedThumb.image })
           }else if(nextProps.selectedState && nextProps.selectedState.selectedFeatureImage){
                this.setState({ src: nextProps.selectedState.selectedFeatureImage.image })
           }
        }
        return true;
    }


  render (){
    const { crop, src  } = this.state;
    return(
        <div className="add_profile_picture  md d-flex align-items-stretch justify-content-center mb-auto">
            <form onSubmit={this.UpdateUserProfileImage}>
                <div className="transparent__form--white ">
                    <FormGroup className="mb-5">
                        <h4>{ this.props.title ? this.props.title : 'Adjust the viewing area of your image before uploading'} </h4>
                    </FormGroup>
                    <div className={`${this.props.practiceUserLoginInfo.is_secure === 0 ? 'bg_white' : null } ${this.props.isPopup ? 'round' : ''} profile_picture_img_box d-flex justify-content-center`} onmousedown="event.preventDefault ? event.preventDefault() : event.returnValue = false" onmouseup="event.preventDefault ? event.preventDefault() : event.returnValue = false">

                        {src && (
                            <ReactCrop
                                src={src}
                                imageStyle={{ height: '100%', width: '100%' }}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                crossorigin='anonymous'
                            />
                        )}
                    </div>
                    <div className="mt-4" style={{marginBottom:"9px"}}>
                        <FileUpload  className="fileupload-sec" note={'Uploading another image will replace the current one.'} onChange={this.onChange} cancelUpload={this.cancelUpload} completed={this.props.isCompleted} loaded={this.props.progress} file={this.state.profileInfo.file}  errors={this.state.errors}/>
                    </div>
                    <div className="mt-auto d-flex upload-footer">
                        <LoadingButton type="submit" loading={this.props.buttonLoading} disabled={!this.state.isImageLoad || this.props.buttonLoading} className={`${this.props.UpdateUserProfileImage === true ? 'verifyed' : ''} mt-5 col`}  color="primary" >
                                {!this.props.UpdateUserProfileImage ?
                                    <>{this.props.uploadButtonLabel}</>
                                    :
                                    <> image saved</>
                                }
                        </LoadingButton>
                        {/* <LoadingButton type="button"  label={`${isAddChannel ? message.message : 'ADD PLAYLIST'}`} loading={state.buttonLoading1} disabled={state.buttonLoading1} onClick={()=> onCreatePlaylit()} size="xxl"  className={`mt-4 w-100`} color={message.bgColor} /> */}

                        {!this.props.hideCancelButton && <Button type="button"  color={this.props.isBackButton ? 'default' : 'danger'} className={this.props.isBackButton ? 'white mt-5 btn btn-outline-default' : 'mt-5'} onClick={() =>  this.props.closeModal() }>
                              {this.props.isBackButton ? 'Back' : this.props.isPopup ? 'close' : 'cancle'}
                        </Button>}
                        {this.props.UpdateUserProfileImage === true && !this.props.uriImage && this.props.closeModa &&
                         this.props.closeModal()
                        }
                    </div>
                </div>
            </form>
        </div>
    ) 
  }
}

AddProfilePicture.defaultProps = {
    uploadButtonLabel: "Save image",
    aspect: 1 / 1,
}

const mapStateToProps = (state) => ({
    loading: state.task.loading,
    progress:state.task.progress,
    UpdateUserProfileImage:state.task.UpdateUserProfileImage,
    practiceUserLoginInfo: state.task.practiceUserLoginInfo,
    buttonLoading: state.task.buttonLoading
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AddProfilePicture);
