/** @format */

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from '../../components/Header/subHeader';
import { Button, Label, FormGroup, Form, Col, Row } from 'reactstrap';
import { postRequestPracticeVideo } from '../../store/actions/taskAction';
import { InputField } from '../../components/InputField';
import { withTranslation } from 'react-i18next';
import {
	useLocation
} from 'react-router-dom'
import DropDown from '../../components/DropDown'
import LoadingButton from '../../components/LoadingButton'
import './style.scss';

const selection = [
	{ value: 'none', name: 'None' },
	{ value: 'website videos', name: 'Website Videos' },
	{ value: 'waiting room', name: 'Waiting Room' },
	{ value: 'chairside education', name: 'Chairside Education' },
]
const RequestVideo = () => {
	const location = useLocation();
	const state = useSelector((state) => state.task);
	const dispatch = useDispatch();
	const [content, setContent] = useState('');
	const [subject, setSubject] = useState('');
	const [section, setSection] = useState('');
	const [message, setMessage] = useState({
		message: 'Request Video',
		color: 'black',
		bgColor: '#eccc63',
	});

	const [allowthalamus, setAllowthalamus] = useState(false);
	const [isloading, setIsloading] = useState(false);



	const contentHandler = (event) => {
		setContent(event.target.value);
	};
	const subjectHandler = (event) => {
		setSubject(event.target.value);
	};
	const sectionHandler = (event) => {
		console.log(event, 'event')
		setSection(event.value);
	};

	const changeHandler = (event)=>{
		setAllowthalamus(!allowthalamus)
	}

	const submitHandler = async (event) => {
		await setIsloading(true);
		let params = {
			subject: subject,
			section: section,
			content: content,
			is_contact_me: allowthalamus ? 1 : 0
		}
		await dispatch(
			postRequestPracticeVideo(params,location.state.page)
		);
		 setMessage({
			message: 'Video Requested',
			color: 'black',
			bgColor: 'green',
		});
		await setContent('');
		await setSubject('');
		await setSection('');
		await setIsloading(false);

	};
	return (
		<div className="videoRequest__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
			<SubHeader className="" title="Video Request" />
			<div className="videoRequest__inner__screen d-flex flex-column align-items-stretch">
				<div className=" transparent__form--white d-flex flex-column align-items-stretch">
					<div className="d-flex flex-column h-100">
						<h4>Video request</h4>
						<p>
							{' '}
							If you are looking for a video that is not yet available in the
							Thalamus library, <br />
							please add the subject of the video and provide a few details for
							its content.{' '}
						</p>
						<Form className="mt-2">
							<Row>
								<Col md={6}>
									<FormGroup>
										<InputField type="text" label={`Subject`} value={subject} name='subject' onChange={subjectHandler} />
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup>
										<Label>Select</Label>
										<DropDown
											className='react-select-container'
											classNamePrefix="react-select"
											options={selection}
											getOptionLabel={x => `${x.name}`}
											getOptionValue={x => `${x.id} ${x.name}`}
											onChange={sectionHandler}
											isSearchable={false}
											placeholder={'Select an option'}
										/>
										{/* <label>Select </label> <br />
									<select name='section' onChange={sectionHandler}>
										<option disabled selected>
											None
										</option>
										<option value='website videos'>Website Videos</option>
										<option value='waiting room'>Waiting Room</option>
										<option value='chairside education'>
											Chairside Education
										</option>
									</select> */}
									</FormGroup>
								</Col>
							</Row>
							<Row >
								<Col md={12}>
									<FormGroup>
										<InputField type="textarea" label={`Content`} name="content" placeholder="Type here" value={content} rows='16' onChange={contentHandler} />
									</FormGroup>
								</Col>
							</Row>
							<Row >
								<Col md={12}>
									<FormGroup>
										<div className="sm custom-checkbox custom-control ">
											<input type="checkbox"   name={'allowthalamus'} onChange={(event)=>changeHandler(event)} checked={allowthalamus} className="custom-control-input" id="allowthalamus"/>
											<label className="custom-control-label" htmlFor="allowthalamus">Allow Thalamus to contact me about my video request.</label>
										</div>
									</FormGroup>
								</Col>
							</Row>
							{/* <Button type="button" onClick={submitHandler} size={`md`} color="primary" className='btn btn-warning' disabled={content !== '' && subject !== '' ? false : true} > {message.message} </Button> */}
							<LoadingButton type="button" onClick={submitHandler} size={`md`} color="primary" className='btn btn-warning' loading={isloading} disabled={(content !== '' && subject !== '' ? false : true) || isloading} label={message.message}  />

						</Form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation('common')(RequestVideo);
