import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import './sidebar.scss'
import {fetchChairsideMediaCategoryList, setChairsidePlaylistMenuActive, setSelectedChairSideMediaSpecializationCategory } from "../../store/actions/taskAction";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  
  /* root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  }, */
   active :{
    color:'#ffa819'
  },
  tooltip:{
    fontSize: '50px'
  },
  
  

}));



const Sidebar = ({value, setValue, tabList, items }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const states = useSelector(state => state.task);
  const [categoriesList, setCategoriesList] = useState(states.chairSideCategoryList);
  // Below two are For Collapse Toggle
  const handleChange = (event, newValue) => {
    dispatch(setChairsidePlaylistMenuActive(newValue));
    setValue(newValue);
  };

  // useEffect(() => {
	// 	dispatch(fetchChairsideMediaCategoryList(states.practiceUserLoginInfo));
	// }, []);

  const showlabel = (name) =>{
    return <div className="tabname">{name}</div>
  }

  const showIcon = (icon) =>{
    return <div className="navIcons">{icon}</div>
  }
  
  return (
    <>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        
        aria-label="Vertical tabs example"
      >
       
        {tabList.map((tab,index) => {
          const activeIndex = value === index ?  classes.active  :'' 
          return(
              <Tab key={index} icon={showIcon(tab.icon)} label={showlabel(tab.name)} {...a11yProps(index)}  className = {`${activeIndex}menu-button`}  />     
          )
        })}
      </Tabs>
      
    </>

    
      
    
  );

}

export default Sidebar;