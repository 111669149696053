/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Input , Modal } from 'reactstrap';
import Profile_icon from '../../img/play_white.svg';
import Dialog from "@material-ui/core/Dialog";
import Player from '../../components/videoPlayer/websiteVideoPlayer';
import { formatSecond } from '../../data/comman';
import { resetDefaultwebsiteVideo ,resetDefaultWebsiteVideoLibery , postSaveTimelineWebsiteVideoLibrary , fetchWebsiteMedia } from '../../store/actions/taskAction';
import  WebsiteVideoImageSkeleton from "../../components/Skeletons/websiteVideoImageSkeleton"
import  WebsiteVideoSkeleton from "../../components/Skeletons/websiteVideoSkeleton"

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const VideoSelectedThree = ({ selectedvideos, totalvideos, settotalVideos, setselectedVideos, setButtonDisable }) => {
    const state = useSelector((state) => state.task);
    const dispatch = useDispatch();
    const [showVideo, setShowVideo] = useState({
        video: {},
        showVideo: false,
    });
    const [count, setCount] = useState(0);
    // Play Video in Player
    const playVideo = (video) => {
        setShowVideo({
            video: video,
            showVideo: true,
        });
    };
    const changeSelectedArray = [];
    
    // Reset Button
    const resetButton = async () => {
        // setCount(count + 1);
        dispatch(resetDefaultWebsiteVideoLibery());  
        let resetArray = [];
        setCount(count + 1);
        totalvideos.forEach((element) => {
            if (element.website_media.is_predefined_media === 1) {
                resetArray.push({ ...element, selected: true });
            }
        });
        settotalVideos(totalvideos);
        // setselectedVideos(resetArray);
        dispatch(resetDefaultwebsiteVideo());
        // let resetArray = [];
        // const idArray = [];
        // state.websiteMediaState.forEach((element) => {
        //     let allVideoIndex = totalvideos.findIndex((elem) => { return elem.website_media_id === element.website_media_id })
        //     if (allVideoIndex > -1) {
        //         setCount(count - 1);
        //         resetArray.push(element);
        //         idArray.push( element.website_media_id);
        //     } else {
        //         setCount(count + 1);
        //         idArray.push( element.website_media_id);
        //         resetArray.push(element);
        //     }
        // });
        // console.log(resetArray,'resetArray')
        // // setselectedVideos(resetArray);
        // dispatch(
        //     postSaveTimelineWebsiteVideoLibrary(state.practiceUserLoginInfo, idArray)
        // );
        // dispatch(fetchWebsiteMedia());
    };

    // When clicks on close button in player
    const onClickClose = (close) => {
        setShowVideo({
            video: {},
            showVideo: false,
        });
    };

    const changeVideosSelected = (video) => {
        if(video.playlist_media) return;
        if(state.practiceInfo.is_paid_account == 0 && state.practiceInfo.days_left == 0 && state.practiceInfo.status !== 800) return;
        let videoIndex = selectedvideos.findIndex((elem) => { return elem.website_media_id === video.website_media_id })
        let allVideoIndex = totalvideos.findIndex((elem) => { return elem.website_media_id === video.website_media_id })
        setButtonDisable(false);
        let data = selectedvideos
        let allData = totalvideos
        if (videoIndex > -1) {
            setCount(count - 1);
            data.splice(videoIndex, 1)
            allData[allVideoIndex].selected = false

        } else {
            setCount(count + 1);
            video.selected = true
            allData[allVideoIndex].selected = true
            data.push(video)
        }
        settotalVideos(allData);
        setselectedVideos(data);
    };

    const togglePip = (isPipOn) => {
		setShowVideo({
			...showVideo,
			showVideo: !isPipOn,
		});
    }
    
    return (
        <>
            <div className={`videoSelected__main__screen ${state.practiceInfo.is_paid_account == 0 ? 'videoSelected__main__screen__disabled_screen' : ''} `}>
                <div className='videoSelected__main__header'>
                    {state.practiceInfo.is_paid_account === 1 ? (
                        <Button color="link">
                            <span style={selectedvideos.length > 100 ? { color: "red" } : null}>{selectedvideos.length}</span> VIDEOS SELECTED
                        </Button>
                    ) : (
                        <Button color="link">
                            <span style={selectedvideos.length > 100 ? { color: "red" } : null}>{selectedvideos.length}</span> VIDEOS SELECTED
                        </Button>
                    )}

                    {state.practiceInfo.is_paid_account == 0 && state.practiceInfo.status !== 800 ? null : (
                        <Button color="link" type="button"
                            onClick={() => resetButton()}
                            className='videoSelected__main__header__reset'>
                            RESET TO DEFAULT
                        </Button>
                    )}
                </div>
                <div className='videoSelected__main__centerCol pt-4'>
                    {selectedvideos.length > 0 ?
                    <Row className="d-flex ">
                        {state.practiceInfo.is_paid_account === 1 ? totalvideos.map((video, index) => {
                            // console.log(totalvideos,'selectedvideosselectedvideosselectedvideosselectedvideos')
                                // const selected = selectedvideos.findIndex((elem) => { return elem.id === video.id }) > -1 ? true : false
                                const selectedImage = video.selected === true ? RIGHTBOXIMAGE : WHITEBOXIMAGE;
                                return (
                                    <>
                                        <Col
                                            lg='4'
                                            key={index}
                                            className={`videoSelected__main__columnFour videoSelected_box `}>
                                            <div className={`videoSelected__main__card websitevideo ${video.selected === true ? 'active' : ''}`}>
                                                <img
                                                    className='videoSelected__main__card__selectedImage'
                                                    src={`${selectedImage}`}
                                                    alt=''
                                                    onClick={() => changeVideosSelected(video)}
                                                />
                                                <div
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        
                                                    }} /* onClick={() => changeVideoSelected(video)} */
                                                >
                                                   <WebsiteVideoImageSkeleton  src={video.website_media.thumb} alt='' />

                                                    {/* <img
                                                        src={video.website_media.thumb}
                                                        alt=''
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    /> */}
                                                </div>
                                                <div className='videoSelected__main__card__subTitle'>
                                                    <div className='videoSelected__main__card__video_icon' onClick={() => playVideo(video)}>
                                                        <img
                                                            src={Profile_icon}
                                                            alt='play_video'
                                                        />
                                                    </div>
                                                    <p className='videoSelected__main__card__title'>
                                                        {video.website_media.media_language.title.toUpperCase()} <span className="videoSelected__main__card__time">|  {formatSecond(video.website_media.media_language.media.duration)}</span>
                                                    </p>
                                                    <p className='videoSelected__main__card__smalltitile'> 
                                                    
                                                    </p>
                                                    <p className='videoSelected__main__card__smalltitile videoSelected__main__card__description'>
                                                        {video.playlist_media ? video.category.title : video.categoryName.toString()}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                );
                            })
                            : totalvideos.map((video, index) => {
                                const selected = video.selected ? true : false;
                                /* video.selected === true &&  selected ? RIGHTBOXIMAGE : WHITEBOXIMAGE;*/
                                const selectedImage = selected
                                    ? RIGHTBOXIMAGE
                                    : WHITEBOXIMAGE;
                                // const selectedImage = selected  ? 'checked' : '';
                                return (
                                    <Col
                                        lg='4'
                                        key={index}
                                        className='videoSelected__main__columnFour videoSelected_box'>
                                        <div className='videoSelected__main__card'>
                                            <img
                                                className='videoSelected__main__card__selectedImage'
                                                src={`${selectedImage}`}
                                                alt=''
                                                onClick={() => changeVideosSelected(video)}
                                            />
                                            {/* <Input type="checkbox" 
											style={{ marginLeft: "5px", marginTop: "9px" }}
											disabled={state.practiceInfo.is_paid_account == 0 ? 'disabled' : ''}
											defaultChecked={`${selectedImage}`}
											/> */}
                                            <div
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                }} /* onClick={() => changeVideoSelected(video)} */
                                            >

                                                <WebsiteVideoImageSkeleton  src={video.website_media.thumb} alt='' />

                                                {/* <img
                                                    src={video.website_media.thumb}
                                                    alt={video.website_media.media_language.title}
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                /> */}
                                            </div>
                                            <div className='videoSelected__main__card__subTitle'>
                                                <div className='videoSelected__main__card__video_icon'>
                                                    <img
                                                        src={Profile_icon}
                                                        alt='play_video'
                                                        onClick={() => playVideo(video)}
                                                    />
                                                </div>
                                                <p className='videoSelected__main__card__title'>
                                                    {video.website_media.media_language.title.toUpperCase()}
                                                    <span className='videoSelected__main__card__duration'> 
                                                    |  {formatSecond(video.website_media.media_language.media.duration)}
                                                    </span>
                                                </p>
                                                
                                                <p className='videoSelected__main__card__smalltitile'>
                                                    {video.playlist_media ? video.category.title : video.categoryName.toString()}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                         {showVideo.video.id  && (
                                <div size="xxl" className={`${showVideo.showVideo ? 'bg-block' : 'bg-none'} h-100 d-flex flex-column align-items-strach justify-content-center modal-video`}>
                                    <div className={`${showVideo.showVideo ? 'd-block' : 'd-none'} video-show`}> 
                                        
                                        <Player
                                            video={showVideo.video}
                                            title={showVideo.video}
                                            onClickClose={onClickClose}
                                            className="w-100 h-100"
                                            togglePip={togglePip}
                                        />
                                    </div>
                                </div>
                            
                        )}
                        
                    </Row>
                    :
                    <Row className="d-flex ">
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                        <Col lg='4' className={`videoSelected__main__columnFour videoSelected_box `}>
                            <div className={`videoSelected__main__card websitevideo`}>
                                <WebsiteVideoSkeleton  />
                            </div>
                        </Col>
                    </Row>
                    }
                </div>
            </div>
        </>
    );
};

export default VideoSelectedThree;