/** @format */

import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner, FormGroup , Label , Button} from 'reactstrap';
import { useSelector, useDispatch , } from 'react-redux';
import {useHistory} from "react-router-dom";
import TimeField from 'react-advanced-timefield';
import closeIcon from '../../../img/Close_small_white.svg'
import SubHeader from '../../../components/Header/subHeader';
import Sidebar, { TabPanel } from '../../../components/navSideBar/Sidebar';
import VideoSlider from '../../../components/videoSlider/VideoSlider';
import Player from './Player';
import LoadingButton from '../../../components/LoadingButton';

import {  getChairsideMediaCCList , getChairsideMediaCC , updateChairsideMediaCC} from '../../../store/actions/taskAction';
import './style.scss';
import Loading from '../../../components/Loading'
import {GET_CHAIRSIDE_MEDIA} from '../../../utilities/HttpUtility';

const ChairsideEducation = (props) => {
	const dispatch = useDispatch();
    let history = useHistory();
	const state = useSelector((state) => state.task);
    // var  isNewLocation = this.props.location.state  ? this.props.location.state.isNewLocation : false

	const [categoriesList, setCategoriesList] = useState(
		state.chairSideCategoryList
	);
	const [video, setVideo] = useState(history.location.state.video);
    const [selectedCClanguage, setSelectedCCLanguage] = useState(0);
    const [chairSideMediaCC, setVideoCC] = useState([]);
    const [chairSideMediaCCList, setchairSideMediaCCList] = useState([]);
    const [isSave, setisSave] = useState(false);
    const [tracks, settracks] = useState('');


	// useEffect(() => {
	// 	dispatch(getChairsideMediaCCList(video.chairside_media_id ));
	// }, []);

    useEffect(() => {
		dispatch(getChairsideMediaCCList(video.chairside_media_id ));
	}, [video]);


    useEffect(() => {
      var  selectedCClanguage = state.media_language.find(x => x.id == video.chairside_media.media_language.language.id)
        setSelectedCCLanguage(selectedCClanguage.id)
	}, [state.chairSideMediaState]);

    useEffect(() => {
        setchairSideMediaCCList(state.chairSideMediaCCList);
    }, [state.chairSideMediaCCList ]);

    useEffect(() => {
        // console.log(chairSideMediaCCList , selectedCClanguage,'[state.chairSideMediaCCList , selectedCClanguage')
        if(chairSideMediaCCList.length > 0 ){
            var  getccId = chairSideMediaCCList.find(x => x.language.id == selectedCClanguage)
            console.log(getccId,'getccId')
            dispatch(getChairsideMediaCC(getccId.id));
        }
      }, [chairSideMediaCCList , video]);

      useEffect(() => {
          if(state.chairSideMediaCCList.length > 0){
            setVideoCC(state.chairSideMediaCC.captions)
          }else{
              setVideoCC([])
          }
          isValid()
      }, [state.chairSideMediaCC]);

      useEffect(() => {
        var ccurl
        if(chairSideMediaCCList.length > 0){
            // chairSideMediaCCList.map((languagelist)=>{
            //     console.log(languagelist,'languagelist')
            //      ccurl = `${GET_CHAIRSIDE_MEDIA}/${video.chairside_media_id}/cc/download/${languagelist.media_language_id}.vtt?user_id=${state.practiceUserProfileInfo.id}`
            //         // tracks.push( {kind: 'subtitles', src: `https://raw.githubusercontent.com/1c7/vtt-test-file/master/vtt%20files/5.%20Position.vtt`, srcLang: 'en' , default: true} )
            //     }
            // );
            ccurl = `${GET_CHAIRSIDE_MEDIA}/${video.chairside_media_id}/cc/download/${video.chairside_media.media_language.media_language_id}.vtt?user_id=${state.practiceUserProfileInfo.id}`
            settracks(ccurl)
        }
	}, [chairSideMediaCCList]);

    const  UpdateCaption = () =>{
        var captions = []
        chairSideMediaCC.map((caption, index) =>{
            captions.push({
                start_frame: caption.start_frame,
                end_frame:caption.end_frame,
                text:caption.text
            })
          }); 
        let params ={
            chairside_media_id:video.chairside_media_id,
            media_language_id:video.chairside_media.media_language.language.id,
            captions:captions
        }
        // console.log(params,'params' , state.chairSideMediaCC, 'state.chairSideMediaCC')
        dispatch(updateChairsideMediaCC(params));
        // dispatch(getChairsideMediaCC(chairSideMediaCCList[0].id));
    }

    const OnAddNewCaptions = () => {
        let Captions = [...chairSideMediaCC];
        Captions.push({
            start_frame: "",
            end_frame: "",
            text: "",
          });
          setVideoCC(Captions)
          setisSave(false)
    }

    const  RemoveAddedCaptions = (index) => {
        let Captions = [...chairSideMediaCC];
        Captions.splice(index,1);
        setVideoCC(Captions)
        isValid()
    }

    const updateCCText = (newName, index ) => {
        let Captions = [...chairSideMediaCC];
        Captions[index].text=newName;
        setVideoCC(Captions)
        isValid()
    }

    const updateCCStartFrame = (startframe, index ) => {
        let Captions = [...chairSideMediaCC];
        Captions[index].start_frame=startframe;
        setVideoCC(Captions)
        isValid()
    }

    const updateCCEndFrame = (endframe, index ) => {
        let Captions = [...chairSideMediaCC];
        Captions[index].end_frame=endframe;
        setVideoCC(Captions)
        isValid()
    }

    const getMediaCC = () =>{
       
    }

    const isValid = ()=>{
        var isError = true;
        
        for(var index = 0; index < chairSideMediaCC.length; index++){
            var caption = chairSideMediaCC[index];
            if(caption.start_frame != "" && caption.end_frame != "" && caption.text != ""){
                isError = false;
                chairSideMediaCC[index].start_frame_error = false;
                chairSideMediaCC[index].end_frame_error = false;
               
                if(index > 0){
                    if(caption.start_frame > chairSideMediaCC[index -1].end_frame && caption.start_frame < caption.end_frame){
                        isError = false;
                        chairSideMediaCC[index].start_frame_error = false;
                        chairSideMediaCC[index].end_frame_error = false;
                        
                    }else if(caption.start_frame > caption.end_frame){
                        isError = true;
                        chairSideMediaCC[index].end_frame_error = true;
                    }else if(caption.start_frame == caption.end_frame){
                        isError = true;
                        chairSideMediaCC[index].start_frame_error = true;
                        chairSideMediaCC[index].end_frame_error = true;
                    }else{
                        isError = true;
                        chairSideMediaCC[index].start_frame_error = true;
                    }
                }
                else if(index == 0){
                    if(caption.start_frame >= caption.end_frame){
                        isError = true;
                        chairSideMediaCC[index].end_frame_error = true;
                    }else{
                        isError = false;
                    }
                }
            }
            else{
                isError = true;
                setisSave(false)
            }
            if(chairSideMediaCC.length - 1 == index){
                setisSave(!isError)
            }
        }
    }

    const renderAddCaptions = (caption, index) => {
      return    <div key={index} className="d-flex pb-2 mb-2 border--bottom align-items-center">
                    <div className="col-auto pl-0 mr-4" style={{width:"55px"}}>
                        <Button className="remove_cc_btn p-0" color="link" onClick={()=>RemoveAddedCaptions(index)}><img src={closeIcon} alt=""/></Button>
                    </div>
                    <div className="col-2 pl-0 d-flex justify-content-between" style={{maxWidth:"143px"}}>
                    <div className="col p-0">
                        <div>IN</div>
                        <div>OUT</div>
                    </div>
                    {/* {console.log(caption,'captioncaption')} */}
                    <div className="col-8 p-0 video_cc_time_field">
                        <TimeField className={`d-block w-100 border-0 ${caption.start_frame_error  ? 'text-danger' : ''}`} onChange={(e) => updateCCStartFrame(e.target.value, index)} value={caption.start_frame} showSeconds showMillis/>
                        <TimeField className={`d-block w-100 border-0 ${caption.end_frame_error  ? 'text-danger' : ''}`} onChange={(e) => updateCCEndFrame(e.target.value, index)} value={caption.end_frame} showSeconds showMillis/>
                    </div>
                    </div>
                    <div className="col pr-0">
                        <textarea className={`form-control`} onChange={(e) => updateCCText(e.target.value, index)} value={caption.text}/>
                    </div>
                </div>
    };

	return (
		<div className='chairside_education_close_caption__main__screen d-flex flex-column h-100 align-items-center '>
            {state.loading && <Loading /> }
            <SubHeader title='Closed Captions'/>
            <div className="chairside_education_close_caption__inner__screen   flex-grow-1">
                <div className="chairside_education_close_caption__inner__text d-flex h-100">
                        <div className="chairside_education_close_caption__player">
                            <h3 className="mb-5 px-4">{video.chairside_media.media_language.title}</h3>
                            <div className="chairside_education_close_caption__player__box mb-5">
                                <Player
                                    video={video}
                                    subtitle={tracks}
                                    className="w-100 h-100 video-player-preview"
                                />
                            </div>
                           
                            <FormGroup className="px-4 video-title">
                                <div className="">
                                    <h3 className="mb-5">Caption languages</h3>
                                    <p>Edit and create your own captions by typing your own words</p>
                                </div>
                            </FormGroup>
                            <FormGroup className="email_check_list px-4">
                                {
                                    state.chairSideMediaCCList.map((list, index) => {
                                        return <div className="custom_check">
                                                    <input type="checkbox" name={`${list.language.name}_${list.language.id}`} id={`${list.language.id}`}  checked={selectedCClanguage === list.language.id ? true : false}  />
                                                    <label for={`${list.language.name}_${list.language.id}`}>{list.language.name}</label>
                                                </div>
                                    })
                                }
                               
                            </FormGroup>
                        </div>        
                        <div className=" d-flex flex-column h-100 w-100 chairside_education_close_caption_right_side" style={{marginTop:"8px"}}>
                            <div className="chairside_education_close_caption__addframe">
                                {/* <span className="undo-steps"></span> */}
                                <div className="d-flex" style={{marginBottom:"7px"}}>
                                    <div className="col-auto pl-0 pr-4 mr-4" style={{width:"55px"}}><span className="p-3"></span></div>
                                    <div className="col-2 pl-0" style={{maxWidth:"143px"}}>
                                        <h4>Time</h4>
                                        <div className="fs-11">Click on the time to edit</div>
                                    </div>
                                    <div className="col pr-0 d-flex justify-content-between">
                                        <div className="">
                                            <h4>Captions</h4>
                                            <div className="fs-11">Click on captions to edit</div>
                                        </div>
                                        <div><Button color="link" className="p-0"></Button></div>
                                    </div>
                                </div>
                                <div className="chairside_education_close_caption__addframe__lists">
                                    {chairSideMediaCC && chairSideMediaCC.map((caption, index) => renderAddCaptions(caption, index) ) }
                                </div>
                                <div className="d-flex mb-4  align-items-center">
                                    <div className="col pl-0 " style={{marginTop:"18px"}}>
                                        <Button color="primary" size="md" onClick={OnAddNewCaptions}  className="col">Add frame</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-auto ml-4">
                                <LoadingButton type="button" color="primary" size="xxl" loading={state.buttonLoading} onClick={()=> UpdateCaption()} disabled={!isSave} className="mb-5">Update current selection  |  English</LoadingButton>
                            </div>
                        </div>
                </div>
            </div>
		</div>
	);
};
export default ChairsideEducation;
