import React,{ useRef,useState} from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";


import {
  Container,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
import screenfull from "screenfull";
import VideoPlayer from "./VideoPlayer";
import EditeImageModal from "../EditImages/EditImageModel";



const useStyles = makeStyles({
  playerWrapper: {
    //width:'100%',
    width: "1100px",
    position: "relative",
    right:"20%"
  },
  controlsSwapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
  },
  controlIcons: {
    color: "#777",
    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 100,
  },
  processSlider: {
    width: 250,
  },
});
const format = (seconds) =>{
  if(isNaN(seconds)){
    return '00:00';
  }

  const date = new Date(seconds*1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");  

  if(hh){
    return `${hh}:${mm.toString().padStart(2,"0")} :${ss}`;
  }
  return `${mm}:${ss}`

}

var count =0; 
const Player = ({video,onClickClose}) =>{
  console.log("Player Inside Website Media Component : ", video)
  const classes = useStyles();
  //const [anchorEl, setAnchorEl] = React.useState(null);

  const [state, setState] = useState({
    playing: false,
    muted: true,
    volume: 0.5,
    played: 0,
    seeking:false,
    toggleScreen:false,
    pip : false,
    subtitle:true,
    closeVideo:false
});


  const [timeDisplayFormat, setTimeDisplayFormat] = useState('normal');
  const [screenshots, setScreenshots] = useState([]);
  const [open, setOpen] = useState(false);
  const [ssImage,setssImage]= useState();
  const { playing, muted, volume, played, seeking,toggleScreen,pip } = state;
  console.log(seeking)

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const canvasRef= useRef(null);

  console.log(state)
  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };
  const handleMute = () => {
    setState({ ...state, muted: !state.muted });
  };
  const handleVolumeChange = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };
  const handleVolumeSeekDown = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };
  
  
  const toggleFullScreen = () => {
    /* setToggleScreen(!toggleScreen); */
    setState({...state,toggleScreen:!state.toggleScreen});
    console.log(playerContainerRef.current);
     screenfull.toggle(playerContainerRef.current);
  }


  const handleProgress = (changeState) => {

    if(count>3){
      controlsRef.current.style.visibility='hidden';
      count = 0;
    }

    if(controlsRef.current.style.visibility === 'visible'){
      count+=1;
    }


    if(!state.seeking){
      setState({...state, ...changeState});
    }
     
  }
  const handleSeekChange= (e, newValue) =>{
    setState({...state, played: parseFloat(newValue/100)});
  }
  const handleSeekMouseDown = (e) => {
     setState({...state, seeking:true})
  }
  const handleSeekMouseUp = (e, newValue) => {
        playerRef.current.seekTo(newValue/100);
  }
  const handleChangeDisplayFormat = () => {
    setTimeDisplayFormat(timeDisplayFormat==='normal'?'remainning':'normal')
  }
  const handleMouseMove = () => {
     controlsRef.current.style.visibility = "visible";
     count =0;
  }
  const handleTogglePip = () =>{
       setState({...state,pip:!state.pip});
  }
  const handleClickClose = () => {
    setState({closeVideo:!state.closeVideo});
    onClickClose(state.closeVideo);
  }
  const handleClickSubtitle = () =>{
    console.log("subtitle",state.subtitle)
    setState({...state,subtitle:!state.subtitle})
  }
  const handleModalOpen = (e,screenshot) => {
    setOpen(true);
    setssImage(screenshot);
  }
   
   const addScreenshot = () =>{
       const canvas = canvasRef.current;
       canvas.width=160;
       canvas.height=90;

       const ctx = canvas.getContext('2d');
       ctx.drawImage(playerRef.current.getInternalPlayer(),0,0,canvas.width,canvas.height);

       const imageUrl = canvas.toDataURL();
       canvas.width=0;
       canvas.height=0;

       setScreenshots([...screenshots,{time:currentTime,display:elapsedTime,image:imageUrl}])
  } 
  const downloadImage = (e) =>{
   console.log(e);
  }
  const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
  const duration = playerRef.current ? playerRef.current.getDuration() :'00:00';

  const elapsedTime = timeDisplayFormat==='normal' ?  format(currentTime) :`-${format(duration-currentTime)}`;
  const totalDuration = format(duration);



  return(
    <Container maxWidth="md" style={{ marginTop: "10px" }}>
       <div 
          ref={playerContainerRef}
          onMouseMove={handleMouseMove}
          className={classes.playerWrapper}>
        <ReactPlayer
          ref={playerRef}
          url={video}
          pip={pip}
          muted={muted}
          height="100%"
          width={"100%"}
          playing={playing}
          volume={volume}

          onProgress={handleProgress}
          config={{
            file:{
              attributes:{
                crossOrigin:"true"
              },
              tracks: state.subtitle===true?[
                {kind: 'subtitles', src: video.subtitle, srcLang: 'en', default: true},
              ] :[]
            },
            youtube:{
              playerVars:{showinfo:1}
            }
          }}
          
        />
        <VideoPlayer
           ref={controlsRef}
           handlePlayPause={handlePlayPause}
           playing={playing}
           muted={muted}
           handleMute={handleMute}
           handleVolumeChange={handleVolumeChange}
           handleVolumeSeekDown={handleVolumeSeekDown}
           volume={volume}
           toggleFullScreen={toggleFullScreen}
           played={played}
           handleSeekChange={handleSeekChange}
           handleSeekMouseDown={handleSeekMouseDown}
           handleSeekMouseUp={handleSeekMouseUp}
           elapsedTime={elapsedTime}
           totalDuration={totalDuration}
           handleChangeDisplayFormat={handleChangeDisplayFormat}
           onScreenShot={addScreenshot}
           toggleScreen={toggleScreen}
           handleTogglePip={handleTogglePip}
           title={video.title}
           handleClickClose={handleClickClose}
           handleClickSubtitle={handleClickSubtitle}
           
        />
        </div>
        {/* <EditeImageModal open={open} setOpen={setOpen}  screenShotImage={ssImage}/> */}
        <Grid container style={{marginTop:'10px'}} spacing={3}>
          {screenshots.map((screenshot,index) => {
            //console.log(screenshot)
            return(
              <Grid item key={index}>
              <Paper onClick={(e)=> handleModalOpen(e,screenshot)}   /* onClick={()=>playerRef.current.seekTo(screenshot.time)} */ >
                 <img crossOrigin='anonymous' src={screenshot.image} onClick={handleModalOpen} alt=""/>
                 <Typography>
                            Screenshot at {screenshot.display}     
                            <a href={screenshot.image} download onClick={e=>downloadImage(e)} >Download</a> </Typography>
              </Paper>
              </Grid>
            )
          }
            
          )}

        </Grid>
        <canvas ref={canvasRef} />
    </Container>
  )
}

export default Player;