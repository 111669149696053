import React, { forwardRef, useCallback, useEffect, useRef  } from "react";
import {
  Grid,
  Typography,
  Slider,
  Tooltip,
} from "@material-ui/core";
import { Button } from 'reactstrap';
import LoadingButton from '../../../components/LoadingButton';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { useSelector, useDispatch } from "react-redux";
import {
  PlayArrow,
  // VolumeUp,
  // Fullscreen,
  ClosedCaption,
  // FullscreenExit,
  Maximize,
  Close,
  Pause,
  SkipNext,
  SkipPrevious,
  // VolumeOff,
} from "@material-ui/icons";
import {ReactComponent as Fullscreen} from '../../../img/FullscreenIcon.svg';
import {ReactComponent as FullscreenExit} from '../../../img/ExitfullscreenIcon.svg';
import {ReactComponent as VolumeUp} from '../../../img/SpeakerhighIcon.svg';
import {ReactComponent as VolumeDown} from '../../../img/SpeakerlowIcon.svg';
import { ShowCloseCaptionChairsideEducation , HideCloseCaptionChairsideEducation, setShareVideoId, shareVideoLinkClearData, updateChairsidePlaylist } from '../../../store/actions/taskAction';
import { history } from "../../../pages";
import Video_share_icon from '../../../img/Video_share_icon.svg'



const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
  },
  controlsSwapper: {
    position: "absolute",
    // top: 0,
    left: 0,
    right: 0,
    bottom: '0',
    zIndex:'1',
  },
  ccBtnActive:{
    backgroundColor:'#fff',
    color: '#424242 !important'
  },
  controlsTop:{
    position: "absolute",
    top:"-22px",
    left:"0",
    right:"0",
    fontSize:'15px',
    fontWeight:'300'
    
  },
  topTitle:{
    color:"#fff",
    textTransform:"uppercase"

  },
  controlIcons: {
    color: "#777",
    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#fff",
    fontSize:'30px',
    padding:'0 10px',
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 100,
    height:'70px!important',
    position:'absolute',
    left: '50%',
    bottom: '30px',
    marginLeft: '-10px'
  },
  processSlider: {
    width: 250,
  },
  tabsClass: {
    background:'linear-gradient(#473c39, #3c3533)',
    color:'#8d8885',
    //backgroundColor: "#443b37",
    textAlign: "center",
    height: "40px",
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    // paddingTop:'7px',
  },
  active: {
    background:'linear-gradient(#b3afae, #b1aead)',
    color:'#494948'
  },
  verticalSlider:{
    height:'175px'
  }
});
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  //const valu1 = secondsToHms(value);

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={{ fontSize: "12px" }}
    >
      {children}
    </Tooltip>
  );
}

function VolumeSliderComponent(props){
  const {children,open,value} = props;
  return (
    // <Tooltip
    //   open={open}
    //   enterTouchDelay={0}
    //   placement="top"
    //   title={value}
    //   style={{ fontSize: "12px" }}
    // >
     <span> {children}</span>
    // </Tooltip>
  );
}

const PrettoSlider = withStyles({
  root: {
    height: 3,
    opacity:1
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    // border: "2px solid currentColor",
    marginTop: -6.5,
    marginLeft: -8,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 3,
    borderRadius: 2,
    backgroundColor:'#009FDF',
    opacity:1
  },
  rail: {
    height: 3,
    borderRadius: 2,
    backgroundColor:'rgba(255,255,255,0.4)',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.3)',
    opacity:1
    

  },
})(Slider);

const VolumeSlider = withStyles({
  root: {
    width: 3,
  },
  thumb: {

    backgroundColor: "#fff",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    borderRadius: 2,
    backgroundColor:'rgba(255,255,255,0.4)',
    width:3
  },
  rail: {
    width:3,
    borderRadius: 2,
    backgroundColor:'#fff',

  },
})(Slider);

export default forwardRef(({ title, handlePlayPause, playing, muted,handleMute,subtitle, handleVolumeChange, handleVolumeSeekDown, volume, toggleFullScreen, played, handleSeekChange, handleSeekMouseDown, handleSeekMouseUp, elapsedTime, totalDuration,
  handleChangeDisplayFormat, onScreenShot, toggleScreen,handleTogglePip, handleClickClose, handleClickSubtitle, handleVideoSectionSelectedIndex, cs_video_section_types, seekToSection , video , isWaitingRoom }, ref) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showVolume, setShowVolume] =  React.useState(false);
    const dispatch = useDispatch();
    const playPauseRef = useRef(null);
    const handleTabClick = (newValue) => {
      seekToSection(newValue);
    };
    const states = useSelector((state) => state.task);

    //Show CC
    const showCC =(video)=>{
      handleClickSubtitle()
     
    }

    //Hide CC
    const hideCC =(video)=>{
      handleClickSubtitle()
      
    }

    const handleUserKeyPress = useCallback(event => {
      const { key, keyCode } = event;
      // console.log("keyCode", keyCode);
      if(keyCode == 32){
        // handlePlayPause();
        if(playPauseRef.current)
          playPauseRef.current.click();
      }
    }, []);

    useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress);
      return () => {
          window.removeEventListener("keydown", console.log("test"));
      };
    }, [handleUserKeyPress]);

    // Hover on Volume
    const hoverHandler = (type) => {
      setShowVolume(!showVolume);
    };

    const ShowDivisions = () =>{
      return(
        <div className="d-flex" style={{backgroundColor: 'rgba(216,216,216,0.2)'}}>
            {handleVideoSectionSelectedIndex != -1 && cs_video_section_types.map((tab, index) => {
              const active = handleVideoSectionSelectedIndex === index ? classes.active : "";
              return (
                <Grid
                  //alignContent="center"
                  item
                  lg={3}
                  key={index}
                  onClick={() =>handleTabClick(index)}
                  className={`${classes.tabsClass} ${active}`}
                >
                  <Typography style={{fontSize: '15px'}}>{tab.name.toUpperCase()}</Typography>
                </Grid>
              );
            })}
        </div>
      )
    };

    const onShareVideoClick = async (video) =>{
      dispatch(setShareVideoId(video.chairside_media_id));
      dispatch(shareVideoLinkClearData());
      history.push('/email-video')
    }

    return (
      <>
       {/* TOP CONTROLS */}
       <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          className={classes.controlsTop}
          // style={{ padding: 16 }}
        >
          <Grid item>
            <div className={classes.topTitle}>
              {title}
            </div>
          </Grid>
          <div className="d-flex align-items-center minimize-close-section">
            <div className="d-flex align-items-center mini-close" onClick={handleTogglePip}  style={{ color: "white" , padding:"0 10px" , cursor:"pointer" }} >
              <p className="m-0">MINIMIZE</p>
              <Maximize fontSize="inherit" style={{ paddingTop: "10px" , fontSize: "25px",  }} />
            </div>
            <div className="d-flex align-items-center mini-close" onClick={handleClickClose} style={{ color: "white" , padding:"0 10px" , cursor:"pointer"}}>
              <p className="m-0">CLOSE</p>
              <Close fontSize="inherit" style={{ fontSize: "20px",  }}/>
            </div>
          </div>
        </Grid>
      <div className={classes.controlsSwapper} ref={ref}>
        {/* BOTTOM CONTROLS */}
        {toggleScreen === false ? <ShowDivisions/>:''}
        <Grid
          container
          direaction="row"
          alignItems="center"
          justify="space-between"
          style={{ padding: '13px 0px' , background: 'linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);'}}
        >
          <Button color="link" className='p-0' style={{marginLeft:'20px',marginRight:'80px'}} onClick={() => onShareVideoClick(video)}><img src={Video_share_icon} alt="Video share"/></Button>

          <Grid className="">

            <IconButton style={{padding:'0 5px'}} className={classes.bottomIcons}>
              <SkipPrevious fontSize="inherit" />
            </IconButton>

            <IconButton ref={playPauseRef} onClick={handlePlayPause} style={{fontSize:'50px' , padding:'0 0px'}}  className={classes.bottomIcons} >
              {playing === false ? (
                <PlayArrow fontSize="inherit" />
              ) : (
                <Pause fontSize="inherit" />
              )}
            </IconButton>

            <IconButton style={{padding:'0 5px'}} className={classes.bottomIcons}>
              <SkipNext fontSize="inherit" />
            </IconButton>
          </Grid>

          <Grid item lg={7} justify="space-between" className="d-flex align-items-center" >
            <Button color="link" style={{ color: "#fff", marginRight: 16 }} onClick={handleChangeDisplayFormat} >
              <Typography style={{fontSize: '15px'}}>{elapsedTime}</Typography>
            </Button>
            <PrettoSlider
              min={0}
              max={100}
              value={played * 100}
              ValueLabelComponent={(props) => (
                <ValueLabelComponent {...props} value={elapsedTime} />
              )}
              onChange={handleSeekChange}
              onMouseDown={handleSeekMouseDown}
              onChangeCommitted={handleSeekMouseUp}
            />
            <Button color="link" style={{ color: "#fff", marginLeft: 16 }}>
              <Typography style={{fontSize: '15px'}}>{totalDuration}</Typography>
            </Button>
            {/* <IconButton className={classes.bottomIcons} onClick={handleClickSubtitle}>
              <ClosedCaption fontSize="inherit" />
            </IconButton> */}
            {!isWaitingRoom &&
            <div className="text-center">
                <LoadingButton type="button" label="CC"  onClick={()=>{states.chairsidePlaylist.cc_status == 0 ? showCC(video) : hideCC(video) }} color="default white cc-btn" className={`${classes.ccBtn} ${subtitle ? classes.ccBtnActive :  '' } sm`} outline/>
            </div>
            }
            <div className=""  /*onMouseEnter={() => hoverHandler('enter')}*/   style={{position: 'relative'}}>
              {/* <IconButton style={{marginLeft: 16 }} onClick={handleMute} className={classes.bottomIcons}  >
                        {volume > 0.5  ? <VolumeUp fontSize="inherit"/> : <VolumeDown fontSize="inherit"/>}
              </IconButton> */}
              <Button color="link" onClick={()=> hoverHandler('enter')} className="py-0"   style={{ color: "#fff", marginLeft: 10 }}>
                {muted === false  ? <VolumeUp fontSize="inherit"/> : <VolumeDown fontSize="inherit"/>}
              </Button>
              {showVolume &&
                <VolumeSlider
                  min={0}
                  max={100}
                  value={volume * 100}
                  onChange={handleVolumeChange}
                  onChangeCommitted={handleVolumeSeekDown}
                  className={`zoom_button_on_smallscreen ${classes.volumeSlider}`}
                  orientation='vertical'
                  // onMouseLeave={() => hoverHandler('leave')}
                  ValueLabelComponent={(props) => (
                    <VolumeSliderComponent {...props} value={volume} />
                  )}
                />
              }
            </div>
            <IconButton  onClick={toggleFullScreen}  className={classes.bottomIcons} >
              {toggleScreen === false ? <Fullscreen fontSize="inherit" /> : <FullscreenExit fontSize="inherit" />}
            </IconButton>
          </Grid>

          <Grid item lg={2} >
            {!isWaitingRoom &&
              <Button type="button" size="md" color={'white'} onClick={onScreenShot} style={{ maxWidth:'180px' }}>
                Take screenshot
              </Button>
            }
          </Grid>
        </Grid>
        {toggleScreen === true ? <ShowDivisions/>:''}
      </div>
      </>
    );
  }
);
