import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button , Spinner } from 'reactstrap';
import { history } from '../../pages/index';
import {  GetpracticeProfile, SetupgradeDowngrade} from "../../store/actions/taskAction";
import './style.scss';

class ProfileCreated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  async componentDidMount(){
    await this.props.dispatch(SetupgradeDowngrade(false));
    if(this.props.practiceUserLoginInfo?.is_manage_location !== 1){
      this.props.dispatch(GetpracticeProfile());
    }
     console.log(this.props.location.isNewLocation,'this.props.isNewLocation',this.props)
  }

  render (){
    const {subscribeInfo , loading} = this.props

    return(
        <div className="subscription__main__screen  d-flex flex-column h-100 align-items-center ">
            <SubHeader className="" title={`${this.props.location.isNewLocation ? 'New Location Access Code' : 'Subscribing to THALAMUS'}`}/>
            <div className={`subscription__completed transparent__form--white  ${this.props.location.isNewLocation ? 'h-auto' : ''}`}>
                <div className="d-flex flex-column h-100 text-center  text-center">
                    {((this.props.location?.isUpgradePlan || this.props.practiceInfo?.is_paid_account === 1) && this.props?.location?.message == 'Success' ) &&
                      <h4 style={{fontWeight:'500'}} className="mb-5">{this.props.location.isNewLocation ? 'New location’s access code' : 'Your location’s access code'}</h4>
                    }
                    <h1 className="mb-4" style={{letterSpacing:'7px'}}>{subscribeInfo && subscribeInfo.access_code}</h1>
                    {!this.props.location.isNewLocation ?
                    <>
                    {((this.props.location.isUpgradePlan || this.props.practiceInfo?.is_paid_account !== 1) && this.props?.location?.message != 'Success' ) ?
                       <h4 className='mt-auto' style={{lineHeight:'1.3'}}>{this.props?.location?.message}</h4>
                      :
                      <p>Thank you for subscribing! You can still use the same access code you’ve been using so far to sign in to Thalamus. If you ever need a reminder, please access Settings/Access code.</p>
                    }
                    <Button onClick={()=> this.props.practiceInfo.is_corporate && this.props.practiceInfo.is_corporate === 1 ? history.push("/corporate") : history.push("/channels")} className="mx-auto mt-auto mb-0 w-100" color="primary">Back To Home Screen</Button>
                    </>
                    :
                    <>
                    <p style={{marginTop: '8px',marginBottom: '33px'}}>This is your new location’s access code, used to sign in to a Thalamus instance in any of the available devices. We have sent a copy of the code to the new practice registered email.</p>
                    <div className=' mt-auto'>
                      <p className='mb-4'>You can now assign a manager to your new location.</p>
                      <Button type='button' onClick={()=>{ history.push({pathname:'/add-manage-location', isActiveAddManager:true })}} className="mx-auto mb-0 w-100" color="primary">Assign a manager</Button>
                    </div>
                    </>
                    }
                </div>
            </div>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceProfileInfo: state.task.practiceProfileInfo,
  subscribeInfo: state.task.subscribeInfo,
  practiceInfo: state.task.practiceInfo,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(ProfileCreated);
