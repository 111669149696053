import discoverImage from '../img/discover.svg';
import MasterCardImage from '../img/mastercard.svg';
import VisaCardImage from '../img/Visacard.svg';
import amexImage from '../img/amex.svg';
import jcbCardImage from '../img/jcb.svg'

export const DateFormat = "YYYY-MM-DD";
export const ACTIVEFORMAT = "MMM DD, YYYY";
export const DateDDMMMYYY = "DD MMM YYYY";
export const YYYY = "yyyy";
export const YY = "YY";
export const YOUTUBESERVICEPROVIDER = 2;
export const VIDEOSERVICEPROVIDER = 1;
export const RECENTPLAY = "recent_playlists";
export const GOOGLE_TV_TEST_DRIVE_RECENTPLAY = "google_tv_recent_playlists";

export const TIMEHHMMSS = "hh:mm:ss";

export const FBPROVIDER = 1;
export const TWITTERPROVIDER = 3;
export const FBCLIENTKEY = "600464734543346";//"950576342526953";//"2769047893318155";
export const TWITTERCONSUMERKEY = "YJDarVH194raOvr5DCv5TSqKY";
export const TWITTERCONSUMERSECRET = "CdIWRyeQQjD5fQHbkqJNoohrRyNQU7IIJjen4289hCyf3ICnk0";
export const GOOGLEAPIKEY = "AIzaSyDfCTH2oo9Ac5K-gSB5BjREM-aLumLfaBs"

export const EDUCATIONTYPE = 1;
export const ENTERAINMENTTYPE = 2; 
export const MYVIDEOSTYPE = 3;
export const MYSLIDESTYPE = 4;
export const SOCILAMEDIATYPE = 5; 
export const TRIVIATYPE = 6;    

export const TIMELINEMEDIATYPE = 1; // media
export const TIMELINESLIDETYPE = 2; // slide
export const TIMELINETRIVIATYPE = 3; // trivia
export const TIMELINESOCIALMEDIATYPE = 4; // social media 

export const ISWAITINGROOMCHANNELIMAGE = 1;
export const THALAMUSCHANNEL = 'thalamus';
export const PRACTICECHANNEL = 'practice';
// operatory room
export const OPERATORYENTERTEMENT = 1;
export const OPERATORYMYVIDEO = 2;
export const ISOPERATORYROOMCHANNELIMAGE = 2;

export const CHAIRSIDENEWRELEASE = "newRelease";
export const CHAIRSIDERECENTPLAY = "recentPlay";
export const CHAIRSIDEMYLIST = "myList";
export const FADELEFT = "fade_left";
export const FADERIGHT = "fade_right";
export const FADETOP = "fade_top";
export const FADEBOTTOM = "fade_bottom";
export const NONEANIM = "none";

export const CANVASWIDTH = 1920;
export const CANVASHEIGHT = 1080;
export const SMOTHDRAG = 1.8;
export const SMOTHRESIZE = 8;
export const SMOTHRESIZERATIO = 0.8;
export const XOFFSET = 70;
export const YOFFSET = 50;
export const XIMGOFFSET = 1220/SMOTHDRAG;
export const YIMGOFFSET = 50;
export const MINHEIGHT = 200;
export const MINWIDTH = 200;
export const MAXHEIGHT = 1000;
export const MAXWIDTH = 1800;

export const fontSize = [{label: 8, value: 8}, {label: 9, value: 9}, {label: 10, value: 10}, {label: 11, value: 11}, {label: 12, value: 12}, {label: 13, value: 13}, {label: 14, value: 14}, {label: 15, value: 15}]
export const SLIDEDURATION = [{ label: '5', value: 5 },{ label: '10', value: 10 }, { label: '15', value: 15 }, { label: '20', value: 20 }, { label: '25', value: 25 }, { label: '30', value: 30 }]

export const editableImage = "editable_image";
export const editableImage1 = "editable_image1";
export const editableImage2 = "editable_image2";
export const editableImage3 = "editable_image3";
export const editableImage4 = "editable_image4";
export const editableImage5 = "editable_image5";
export const editableImage6 = "editable_image6";
export const editableImage7 = "editable_image7";
export const editableImage8 = "editable_image8";
export const editableImage9 = "editable_image9";
export const editableImage10 = "editable_image10";

export const editableText = "editable_text";
export const editableText1 = "editable_text1";
export const editableText2 = "editable_text2";
export const editableText3 = "editable_text3";
export const editableText4 = "editable_text4";
export const editableText5 = "editable_text5";
export const editableText6 = "editable_text6";
export const editableText7 = "editable_text7";
export const editableText8 = "editable_text8";
export const editableText9 = "editable_text9";
export const editableText10 = "editable_text10";

export const draggableComp = "draggable_button";
export const draggableComp1 = "draggable_button1";
export const draggableComp2 = "draggable_button2";
export const draggableComp3 = "draggable_button3";
export const draggableComp4 = "draggable_button4";
export const draggableComp5 = "draggable_button5";
export const draggableComp6 = "draggable_button6";
export const draggableComp7 = "draggable_button7";
export const draggableComp8 = "draggable_button8";
export const draggableComp9 = "draggable_button9";
export const draggableComp10 = "draggable_button10";
export const moveableSectionId = "moveable_editable_text";
export const moveableSectionId1 = "moveable_editable_text1";
export const moveableSectionId2 = "moveable_editable_text2";
export const moveableSectionId3 = "moveable_editable_text3";
export const moveableSectionId4 = "moveable_editable_text4";
export const moveableSectionId5 = "moveable_editable_text5";
export const moveableSectionId6 = "moveable_editable_text6";
export const moveableSectionId7 = "moveable_editable_text7";
export const moveableSectionId8 = "moveable_editable_text8";
export const moveableSectionId9 = "moveable_editable_text9";
export const moveableSectionId10 = "moveable_editable_text10";

export const draggableImgComp = "draggable_img_button";
export const draggableImgComp1 = "draggable_img_button1";
export const draggableImgComp2 = "draggable_img_button2";
export const draggableImgComp3 = "draggable_img_button3";
export const draggableImgComp4 = "draggable_img_button4";
export const draggableImgComp5 = "draggable_img_button5";
export const draggableImgComp6 = "draggable_img_button6";
export const draggableImgComp7 = "draggable_img_button7";
export const draggableImgComp8 = "draggable_img_button8";
export const draggableImgComp9 = "draggable_img_button9";
export const draggableImgComp10 = "draggable_img_button10";

export const moveableImageSectionId = "moveable_image_editable_image";
export const moveableImageSectionId1 = "moveable_image_editable_image1";
export const moveableImageSectionId2 = "moveable_image_editable_image2";
export const moveableImageSectionId3 = "moveable_image_editable_image3";
export const moveableImageSectionId4 = "moveable_image_editable_image4";
export const moveableImageSectionId5 = "moveable_image_editable_image5";
export const moveableImageSectionId6 = "moveable_image_editable_image6";
export const moveableImageSectionId7 = "moveable_image_editable_image7";
export const moveableImageSectionId8 = "moveable_image_editable_image8";
export const moveableImageSectionId9 = "moveable_image_editable_image9";
export const moveableImageSectionId10 = "moveable_image_editable_image10";

export const zoomFactor = 10;
export const IMAGE = "image";
export const TEXT = "text";
export const BLANKSLIDEBG = "blankSlideContainer";
export const SLIDEZOOMFACTOR = 0.5;
export const DEFAULTTEXT = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
export const IMAGECOMPONENT = "image";
export const TEXTCOMPONENT = "text"
export const SLIDEDEFAULTIMAGE = "https://static.thalamusmedia.com/images/waiting_room_media/stock_library/thumb_image/default.jpg";
{/* <div class="resizer resizer-r"></div>
        <div class="resizer resizer-l"></div>
        <div class="resizer resizer-t"></div>
        <div class="resizer resizer-b"></div> */}
export const MOVEABLETEXT = 
    `<div id="moveable_editable_text1" class="moveable-container moveable-text-container" style="position: absolute; width: 800px; z-index: 999; font-size: 60px;">
        <button class="draggable moveable-button"  id="draggable_button1">+</button> 
        <p ondrop="return false" contenteditable="true" data-enable-grammarly="false" id="editable_text1" class="blank_title">${DEFAULTTEXT}</p>
    </div>`;

export const MOVABLEIMAGE = 
    `<div id="moveable_image_editable_image" class="moveable-container moveable-image-container" style="height: 254px; width: 400px; position: absolute; z-index: 1000;">
        <img draggable="false" style="width: 100%;" id="editable_image" class="blank_image editable_image" src='${SLIDEDEFAULTIMAGE}'/>
    </div>`

export const BLANKSLIDE = {
    id: 0,
    content: `<div class="main-container" id="blank-slide-container" style="position: absolute; height: 1080px; width: 1920px">
                <div id="blankSlideContainer" style="position: absolute; top: 0;left: 0;right: 0;bottom: 0;height: 100%;width: 100%; "></div>
                ${MOVEABLETEXT}
            </div>`
}

export const Ownership = [
    { name: 'Individually Owned', id: 0},
    { name: 'Part of a Group', id: 1},
];

export const days = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
]

export const noOfoperatories = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15+' },
]

export const hours = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
]

export const operatories = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15+' },
]

export const tv_count = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15+' },
]

export const count = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
]

export const distributor = [
    { value: 1, label: 'ABLEDent' },
    { value: 2, label: 'Ace Dental' },
    { value: 3, label: 'Care Stack' },
    { value: 4, label: 'Cloud 4 Dentists' },
    { value: 5, label: 'Cloud 4 Ortho' },
    { value: 6, label: 'Cloud 9' },
    { value: 7, label: 'CS Softdent' },
    { value: 8, label: 'Curve Dental' },
    { value: 9, label: 'Datacon' },
    { value: 10, label: 'DentiCalc' },
    { value: 11, label: 'Denticon' },
    { value: 12, label: 'DentiMax' },
    { value: 13, label: 'Dentisoft' },
    { value: 14, label: 'Dentrix' },
    { value: 15, label: 'Dentrix Ascend' },
    { value: 16, label: 'Dentrix Enterprise' },
    { value: 17, label: 'Dovetail' },
    { value: 18, label: 'Eaglesoft' },
    { value: 19, label: 'Easy Dental' },
    { value: 20, label: 'Exact' },
    { value: 21, label: 'Fuse' },
    { value: 22, label: 'iDentalSoft' },
    { value: 23, label: 'Maxident' },
    { value: 24, label: 'MOGO Azure' },
    { value: 25, label: 'Open Dental' },
    { value: 26, label: 'Practice Web' },
    { value: 27, label: 'PracticeWorks' },
    { value: 28, label: 'Praktica' },
    { value: 29, label: 'Softdent' },
    { value: 30, label: 'Software of Excellence' },
    { value: 31, label: 'Total Dental' },
    { value: 32, label: 'None' },
    { value: 33, label: 'Other' },
]

export const shareVideoType = [
    
    { value: '', label: 'All' },
    { value: 'email', label: 'Email' },
    { value: 'sms', label: 'SMS' },
]

export const shareVideoViewOption = [
    { value: '', label: 'All' },
    { value: 'viewed', label: 'Viewed' },
    { value: 'not_viewed', label: 'Not Viewed' },
]

export const getCardImageByNo = (number) => {
    // visa
    number = "" + number;
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return VisaCardImage;
        // return "visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
     if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
        return MasterCardImage;
        // return "mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
            return amexImage;
        // return "AMEX";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return discoverImage;
        // return "discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        // return "diners";
        return MasterCardImage;

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        // return "Diners - Carte Blanche";
        return MasterCardImage;
    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return jcbCardImage;

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return VisaCardImage;

    return "";
}

export const getCardNameByNo = (number) => {
    // visa
    number = "" + number;
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return "visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
     if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
        return "mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "AMEX";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        return "diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        // return "jcb";
        return jcbCardImage;


    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "visa electron";

    return "";
}

export const getCartImageByType = (type) => {
    switch(type){
        case "Visa":
            return VisaCardImage;
        case "MasterCard":
            return MasterCardImage;
        case "American Express":
            return amexImage;
        case "Discover":
            return discoverImage;
        case "JCB":
            return jcbCardImage;
        default :
            return VisaCardImage;
    }
}

export const formatSecond = (miliSecond, isFull = false) => {
        if (isNaN(miliSecond)) {
            return '00:00';
        }

        const date = new Date(miliSecond * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2, '0');

        if (hh || isFull) {
            return `${hh || '00'}:${mm.toString().padStart(2, '0')} :${ss}`;
        }
        return `${mm}:${ss}`;
}

export const formatSecondForGoogleTv = (miliSecond, isFull = false) => {
    if (isNaN(miliSecond)) {
        return '00:00';
    }

    var date = new Date(miliSecond * 1000);
    var hh = date.getUTCHours();
    var mm = date.getUTCMinutes();
    var ss = date.getUTCSeconds().toString().padStart(2, '0');

    // if (hh || isFull) {
    //     return `${hh || '00'}:${mm.toString().padStart(2, '0')} :${ss}`;
    // }

    var formatHour = ""
    var formatMinute = ""
    var formatSeconds = ""

    if(hh > 0){
        if(hh > 1){
            formatHour = hh+" hrs "
        }else{
            formatHour = hh+" hr "
        }
    }

    if(mm > 1)
    formatMinute = mm+" mins "
    else
    formatMinute = mm+" min "

    if(ss > 1)
    formatSeconds = ss+" secs "
    if(hh > 0)
    formatSeconds = ""

    return `${formatHour} ${formatMinute} ${formatSeconds}`;
}

export const getMinutes = () =>{
    var minutes = [];
    for(var i = 0; i < 60; i++){
        minutes.push({ value: ('0' + i).slice(-2), label: ('0' + i).slice(-2) });
    }
    return minutes;
}


export const reason = [
    { value: 0, label: 'Please choose your subject' },
    { value: 1, label: 'Billing Issue' },
    { value: 2, label: 'Error' },
    { value: 3, label: 'Other' },
]

export const isRequired = (value) => {
    if(value == "" || value == undefined || value == null || value.toString().trim() == "")
        return true;
    else
        return false;
}

export const validateDomain = (domain) => {
    var domainValidate = new RegExp(/^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/)
    if (domainValidate.test(domain))
        return true;
    else
        return false;
}

export const isValidEmail = (email) => {
    // return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) == null;
    return !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)  
}

export const extendTrialStatus = {
    pending: 0,
    approve: 1,
    decline: 2,
}