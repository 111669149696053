/** @format */

import React, { useState } from 'react';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EditSlides from '../EditSlides/EditSlides';
import SlidePresetsTabContent from '../WaitingRoomTimelineSlidesTabContents/SlidePresetsTabContent';
import TextTabContent from '../WaitingRoomTimelineSlidesTabContents/TextTabContent';
import ImagesTabContent from '../WaitingRoomTimelineSlidesTabContents/ImagesTabContent';
import BackgroundTabContent from '../WaitingRoomTimelineSlidesTabContents/BackgroundTabContent';
import TransitionsTab from '../WaitingRoomTimelineSlidesTabContents/TransitionsTab';
import SubHeader from '../Header/subHeader';
import { Row, Col } from 'reactstrap';
import SlideCreation from './SlideCreation';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<Typography component={'span'}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}
const useStyles = makeStyles((theme) => ({
	root: {
		width: 'auto',
	},
}));
const WaitingRoomTimelineSlides = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [slidePresetsTab, setSlidePresetsTab] = useState(true);
	const [textTab, setTextTab] = useState(false);
	const [imagesTab, setImagesTab] = useState(false);
	const [backgroundTab, setBackgroundTab] = useState(false);
	const [transitionTab, setTransitionTab] = useState(false);

	const slidePresetsChangeHandler = () => {
		setSlidePresetsTab(!slidePresetsTab);
	};
	const textChangeHandler = () => {
		setTextTab(!textTab);
	};
	const imagesChangeHandler = () => {
		setImagesTab(!imagesTab);
	};
	const backgroundChangeHandler = () => {
		setBackgroundTab(!backgroundTab);
	};
	const transitionsChangeHandler = () => {
		setTransitionTab(!transitionTab);
	};
	return (
		<div className='main__screen channels__page  d-flex flex-column h-100 align-items-center justify-content-center'>
			<SubHeader className='mb-auto' title='Slide Creation' />
			<Row>
				<Col lg='4'>
					<SlideCreation />
				</Col>
				<Col lg='8'>
					<div className={`${classes.root} footer-header `}>
						<AppBar
							position='static'
							color='default'
							style={{ marginLeft: '0px' }}>
							<Tabs
								value={value}
								onChange={handleChange}
								indicatorColor='primary'
								textColor='primary'
								className='footer-tabs'
								aria-label='full width tabs example'>
								<Tab
									label='SLIDES PRESETS'
									{...a11yProps(0)}
									className='footer-tab-btn'
									onClick={slidePresetsChangeHandler}
								/>
								<Tab
									label='TEXT'
									{...a11yProps(1)}
									className='footer-tab-btn'
									onClick={textChangeHandler}
								/>
								<Tab
									label='IMAGES'
									{...a11yProps(2)}
									className='footer-tab-btn'
									onClick={imagesChangeHandler}
								/>
								<Tab
									label='BACKGROUND'
									{...a11yProps(3)}
									className='footer-tab-btn'
									onClick={backgroundChangeHandler}
								/>
								<Tab
									label='TRANSITIONS'
									{...a11yProps(4)}
									className='footer-tab-btn'
									onClick={transitionsChangeHandler}
								/>
							</Tabs>
						</AppBar>
						<TabPanel value={value} index={0} dir={theme.direction} style={{ marginLeft:'-419px' }}>
							{slidePresetsTab ? <SlidePresetsTabContent /> : null}
						</TabPanel>

						<TabPanel value={value} index={1} dir={theme.direction} style={{ marginLeft:'-419px' }}>
							{textTab ? <TextTabContent /> : null}
						</TabPanel>

						<TabPanel value={value} index={2} dir={theme.direction} style={{ marginLeft:'-419px' }}>
							{imagesTab ? <ImagesTabContent /> : null}
						</TabPanel>

						<TabPanel value={value} index={3} dir={theme.direction} style={{ marginLeft:'-419px' }}>
							{backgroundTab ? <BackgroundTabContent /> : null}
						</TabPanel>

						<TabPanel value={value} index={4} dir={theme.direction} style={{ marginLeft:'-419px' }}>
							{transitionTab ? <TransitionsTab /> : null}
						</TabPanel>
					</div>
					<EditSlides />
				</Col>
			</Row>
		</div>
	);
};

export default WaitingRoomTimelineSlides;
