import React from 'react';
import './style.scss';

import { Button } from 'reactstrap';
import {ReactComponent as HomeIcon} from '../../img/home_icon.svg';
import {ReactComponent as ArrowLeft} from '../../img/arrow_left.svg';
import {/*useTranslation */  withTranslation} from "react-i18next";
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import { LoginPracticeLocation } from "../../store/actions/taskAction";
import { setToken  } from '../../helper/LocalStorage';

class SubHeader extends React.Component {

  handleClick(){
    if(history.location.pathname == "/website-player-customization")
      history.replace("select-category-website-videos");
    else if(history.location.pathname == "/select-category-website-videos")
      history.replace("channels");
    else
      history.goBack();
  } 

  onHomeClick(){
    history.push('/channels')
  }
  
 async onBackToMainPractice(){
    let params = {
      back_to_practice: 1
    }
    await this.props.dispatch(LoginPracticeLocation(params))
    // var token = this.props.practiceUserLoginInfo.token_info.token_type + ' ' + this.props.practiceUserLoginInfo.token_info.access_token
    // await setToken(`${token}`);
    // await history.push('/channels');
  }

  onClickInvoices(){
    history.push('/invoices')
  }
  
  render (){
    const {practiceUserLoginInfo, loginPracticeLocationinfo , resetAccessCode, selectedTab, changeValue} = this.props
    // const {t , practiceUserLoginInfo } = this.props
    return(
      <div className={`d-flex align-items-center header--sub justify-content-between w-100 mt-4 ${this.props.className} `}>
        <div className="d-flex align-items-center justify-content-end">
            <Button disabled={resetAccessCode && resetAccessCode.isdisabled} className={`${history.location.pathname !== '/welcome' ? 'border-right' : '' } d-flex align-items-center py-1 pr-20  pl-0 mr-20`} onClick={() => this.onHomeClick()}  color="link">
              <HomeIcon/>
            </Button>
            <Button disabled={resetAccessCode && resetAccessCode.isdisabled} type="button"  onClick={() => this.handleClick()}  color="link" className="py-1 pl-0 d-flex align-items-center"><ArrowLeft className="mr-4"/><span>Back</span></Button>
        </div>
        <div className="flex-grow-1 practice_name text-center header--sub" >
          <h1 className="subtitle pr-5">{this.props.title}</h1>
        </div>
      </div>
    ) 
  }
}
// export default SubHeader;
const mapStateToProps = (state) => {
  return {
    practiceInfo: state.task.practiceInfo,
    practiceUserLoginInfo: state.task.practiceUserLoginInfo,
    practiceUserLogoutInfo:state.task.practiceUserLogoutInfo,
    loginPracticeLocationinfo:state.task.loginPracticeLocationinfo,
    resetAccessCode:state.task.resetAccessCode,
  }
};

export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(SubHeader);
