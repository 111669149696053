import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import NumberFormat from 'react-number-format';
import { getCardNameByNo, getCartImageByType } from '../../data/comman';
import CVVIcon from '../../img/cvv.png'
import { history } from '../../pages/index';
import CVVIconHover from '../../img/cvv-hover.png'
import StripeIcon from '../../img/stripe-icon.png'
import { Button , Input , Spinner} from 'reactstrap';
import { resetSuccess , clearerror} from "../../store/actions/taskAction";
import { getCardImageByNo } from '../../data/comman';
import {AddBillingCard , UpdateBillingCard , GetBillingCard} from "../../store/actions/taskAction";
import LoadingButton from '../../components/LoadingButton'
import SubHeader from  '../../components/Header/subHeader'
import './style.scss'

var selectedCard;
class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.selectedCard){
      selectedCard = this.props.location.state.selectedCard;
    }
    this.state = {
      isReplace:false,
      primerCreditCard: {},
      creditCardNumber: "",
      cardHolderName: "",
      viewCardHoldename: "",
      expireDate: "",
      editExpireDate: "",
      code: "",
      formValid:false,
      vardImage:'',
      isError:{
        creditCardNumber: "",
        cardHolderName: "",
        editExpireDate: "",
        code:''
      }
    };
  }

  async componentDidMount(){
    await this.props.dispatch(clearerror())
    await this.props.dispatch(resetSuccess())
  }

 backtoSubscription(){
   history.replace('/subscription-payment')
 }

  render (){
    const primerCreditCard = selectedCard || {};
    const expireDate = selectedCard ? `${selectedCard.exp_month} / ${selectedCard.exp_year}` : "";
    var imgsrc = !this.state.isReplace ? getCartImageByType(primerCreditCard.brand) : this.state.vardImage,
    isError = this.state.isError
    var error = {}
    return(
      <div className="paymentsetup__main__screen paymentsetup__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Subscription Payment  |  Corporation"/>
        <div className="paymentsetupcard__inner__panel transparent__form--white d-flex flex-column mb-auto"> {/* for screen 173,174,175 add class bg-danger */}
          <img src={StripeIcon} className="stripe-logo" alt=""/>
          <div className="paymentsetupcard__inner__panel__header d-flex flex-column">
            <h4>Credit card setup complete</h4>
            <p>Selected practices will now pay for all Thalamus related fees using the following MasterCard credit card.</p>
          </div>
          <>
            <div className={`${primerCreditCard.brand}-bg paymentsetupcard__inner__panel__body`}> {/* mastercard-bg , visacard-bg , amexcard-bg , discovercard-bg*/}
              <div className="row">
                <div className="col-12">
                  <div className={`${error && error.errors && error.errors['card.number'] ? 'inputerror' : ''} form-group  icon-card `}>
                      <div className={`d-flex justify-content-between `}>
                          <label>Credit card number</label>
                      </div>

                      <NumberFormat className="form-control" format="#### #### #### ####" name="creditCardNumber" placeholder={`XXXX  XXXX  XXXX  ${selectedCard ? selectedCard.last4 : ""}`} disabled/>

                      {imgsrc ? <img src={imgsrc}  className="icon-img" alt="" /> : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Name on card</label>
                        <Input type="text" name="cardHolderName" placeholder={selectedCard.name ? selectedCard.name : ""} disabled/>
                  </div>
                </div>
                <div className="col-6">
                  <div className={`${error && error.errors && error.errors['exp_month'] || error && error.errors && error.errors['exp_year'] ? 'inputerror' : ''} form-group mb-0`}>
                    <label>Expiry date</label>
                      <NumberFormat  className="form-control"  format="## / ##" placeholder={expireDate} mask={['M', 'M', 'Y', 'Y']} name="expireDate" disabled />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group  mb-0 cvv-img">
                    <label>Security code <img src={CVVIcon} alt="" /> </label>
                    <NumberFormat  className="form-control"  format={getCardNameByNo(selectedCard) == "AMEX" ? "####" : "###"} placeholder={getCardNameByNo(selectedCard) == "AMEX" ? "XXXX" : "XXX"} name="code" disabled/>
                    <div className="what-cvv">? <span><img src={CVVIconHover} alt=""/> <p>The 3 or 4 numbers on the back of your Visa, MasterCard, or AMEX card.</p></span></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="paymentsetupcard__inner__panel__footer mt-auto">
              <Button color="primary" className="w-100" onClick={() => this.backtoSubscription()}>Back to subscription payment</Button>
            </div>
         
          </> 
        </div>
    </div>

    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingAddCardInfo:state.task.loadingAddCardInfo,
  loadingBillingCardInfo:state.task.loadingBillingCardInfo,
  loadingUpdate:state.task.loadingUpdate,
  isUpdateSuccess:state.task.isUpdateSuccess,
  isSuccess:state.task.isSuccess,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo,
  getBillingCardInfo:state.task.getBillingCardInfo,
  error:state.task.error

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
