import React from "react";
import { Swiper} from "swiper";
import "swiper/swiper-bundle.css";

const DragDropSlider = ({ list }) => {
  
  
  var swiper = new Swiper(".swiper-container", {
    slidesPerView: 7,
    spaceBetween: 10,
    // init: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      "@0.00": {
        slidesPerView: 7,
        spaceBetween: 10,
      },
      "@0.75": {
        slidesPerView: 7,
        spaceBetween: 20,
      },
      "@1.00": {
        slidesPerView: 7,
        spaceBetween: 40,
      },
      "@1.50": {
        slidesPerView: 7,
        spaceBetween: 50,
      },
    },
  });
  console.log(swiper)
  return (
    <>
      <div class="swiper-container">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-wrapper">
          {list.map((item, index) => {
            return (
              <div key={index} class="swiper-slide">
                <video  height="130px">
                  <source src={item.video}></source>
                </video>
              </div>
            );
          })}
        </div>
      </div>
     
    </>
  );
};
export default DragDropSlider;
