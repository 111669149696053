import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import BillingProfileImg from '../../img/BillingProfileAvtar.png'

import './style.scss';
import { Button} from 'reactstrap';

const HomeIndex = (props) => {
  return(
    <div className="main__screen billing__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Billing"/>
        <div className="billing__inner mb-auto billing-verify-code">
          <div className="billing__inner__header">
            <h4>Verify your identity</h4>
            <p>A verification code sent via SMS text, email, or phone call is required in order to proceed.</p>
          </div>
          <div className="billing__inner__body">
            <div className="user__box">
              <div className="user__avtar">
                <img className="mx-auto my-auto" alt="" src={BillingProfileImg}/>
                <span>Owner</span>
              </div>
              <div className="user__name">CARLA SMITH</div>

            </div>
          </div>
          <div className="billing__inner__footer">
            <div class="d-flex justify-content-between">
              <label>Enter the verification code </label><button class="p-0 btn btn-link primary">SHOW</button>
            </div>
            <div className="verification-code-input">
              <input type="tel" className="form-control" />
              <input type="tel" className="form-control" />
              <input type="tel" className="form-control" />
              <input type="tel" className="form-control" />
            </div>
            <p>Didn’t receive your code? <a href="#" className="text-primary">Click here to resend.</a></p>
            <p>Need to update your email or mobile no.? <a href="#" className="text-primary">Click here.</a></p>
            <Button size="md mt-4" color="primary">Next</Button>
          </div>
        </div>
    </div>
  ) 
}
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
