import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import VerifyIdentityBox from '../../components/VerifyIdentityBox'
import { Button } from 'reactstrap';
import { history } from '../../pages/index';

import './style.scss';



const HomeIndex = (props) => {
  return(
    <div className="practiceInfo__main__screen billing__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Add & Manage Locations"/>
        {props.practiceUserLoginInfo.type === 4  ?
        <VerifyIdentityBox pageUrl={`/locations`}/>:
        <div className="initial_setup__inner__screen mb-auto">
                <div className="d-flex flex-column text-center restricted_access initial_setup__inner__text text-center">
                    <h2 className="mb-5 mt-0">{"Ristricted access (Owner Only)"}</h2>
                    <p>{"Due to the changes that can be made in all practices affiliated with yours, this section in the Thalamus app can only be viewed and managed by the practice owner. "}</p>
                    
                    <Button size="md" className="mx-auto mt-5" onClick={()=>history.push("/settings")}  color="primary">Back</Button>
                </div>
            </div>
        }
        {/* {props.error && 
          <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{props.error.message}</p> </div>
        } */}
    </div>
  ) 
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
