import React, { useState, useEffect } from 'react';
import SubHeader from '../../components/Header/subHeader';
import './styles.scss';
import upper from '../../img/show_header.png';
import waitingRoom__show_header_hover from '../../img/show_header.png';
import { Swiper, SwiperSlide } from 'swiper/react';

import middle from '../../img/show_Content.png';
import lower from '../../img/show_footer.png';
import waitingRoom__show_footer_hover from '../../img/show_footer.png';

import theme1 from '../../img/page16-screen-img.png';
import clsx from 'clsx';
import { Button, Input, FormGroup } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { createNewWaitingRoomChannel,postwaitingRoomPublishTimeline, updateWaitingRoomChannelName, updateWaitingRoomChannelNameRequest, getwaitingRoomThemeList , waitingRoomDisplayOptions } from '../../store/actions/taskAction';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { history } from '..';
import uuid from "react-uuid";
import LoadingButton from '../../components/LoadingButton'
import PreviewModal from '../../components/PreviewModal'
import { WAITINGROOM_CHANNEL_PREVIEW_URL } from '../../utilities/HttpUtility'
import 'swiper/swiper-bundle.css';
import { getToken } from '../../helper/LocalStorage';
import Lottie from "react-lottie";

import theme01_title_long001 from "./themes/theme01_title_long001.json";
import theme01_title_short001 from "./themes/theme01_title_short001.json";

import theme02_title_long001 from "./themes/theme02_title_long001.json";
import theme02_title_short001 from "./themes/theme02_title_short001.json";

import theme03_title_long001 from "./themes/theme03_title_long001.json";
import theme03_title_short001 from "./themes/theme03_title_short001.json";

import theme04_title_long001 from "./themes/theme04_title_long001.json";
import theme04_title_short001 from "./themes/theme04_title_short001.json";

import theme05_title_long001 from "./themes/theme05_title_long001.json";
import theme05_title_short001 from "./themes/theme05_title_short001.json";

import theme06_title_long001 from "./themes/theme06_title_long001.json";
import theme06_title_short001 from "./themes/theme06_title_short001.json";

import theme07_title_long001 from "./themes/theme07_title_long001.json";
import theme07_title_short001 from "./themes/theme07_title_short001.json";

import theme08_title_long001 from "./themes/theme08_title_long001.json";
import theme08_title_short001 from "./themes/theme08_title_short001.json";

var accessToken = ''

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#6087fd',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#6087fd',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});
function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

const CreateNewWaitingRoomChannel = (props) => {
  const states = useSelector((state) => state.task);

  const [nextEnable, setNextEnable] = useState('-disable');
  const [isAddChannel, setIsAddChannel] = useState(false);
  const [selectedTheme, setTheme] = useState(1);
  const [selectedfile, setThemeFile] = useState(theme01_title_short001);
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    checkedA: true,
    checkedB: states.managewaitingRoomMediaChannel.header_status == 1 ? true : false,
    checkedC: states.managewaitingRoomMediaChannel.footer_status == 1 ? true : false ,
  });

  const [isShared, setIsShared] = useState({
    practiceonly: states.managewaitingRoomMediaChannel.is_shared == 0 ? true : false,
    allpractice: states.managewaitingRoomMediaChannel.is_shared == 1 ? true : false
  });

  // var theme01 = `${imgurl}/${selectedTheme}/theme0${selectedTheme}-news-short.webp`


  useEffect(() => {
    // let params ={
    //   "channel":uuid(),
    //   "title":uuid(),
    //   "practice_name":'Smith Dental',
    //   "theme_id":1,
    //   "enableButton":false
    // }
    // dispatch(createNewWaitingRoomChannel(params))

    dispatch(getwaitingRoomThemeList())
    dispatch(waitingRoomDisplayOptions(states.managewaitingRoomMediaChannel.id))
    accessToken = getToken();

  }, []);

  useEffect(() => {
    states.managewaitingRoomMediaChannel.is_shared == 1 ?
      setIsShared({ ...state, 'allpractice': true })
      :
      setIsShared({ ...state, 'practiceonly': true })

    states.managewaitingRoomMediaChannel.footer_status == 1 ?
      setState({ ...state, 'checkedC': true })
    :
      setState({ ...state, 'checkedC': false })

    states.managewaitingRoomMediaChannel.header_status == 1 ?
      setState({ ...state, 'checkedB': true })
    :
      setState({ ...state, 'checkedB': false })
    
      setTheme(states.managewaitingRoomMediaChannel.theme_id)


  }, [states.managewaitingRoomMediaChannel])

  useEffect(() => {
    setThemeFile(setThemeFileName(selectedTheme))
  }, [states.displayOptions, selectedTheme])

  const setThemeFileName = (themeId) =>{
    switch(themeId){
      case 1: return states.displayOptions.display_time_weather == "1" ? theme01_title_short001 : theme01_title_long001;
      case 2: return states.displayOptions.display_time_weather == "1" ? theme02_title_short001 : theme02_title_long001;
      case 3: return states.displayOptions.display_time_weather == "1" ? theme03_title_short001 : theme03_title_long001;
      case 4: return states.displayOptions.display_time_weather == "1" ? theme04_title_short001 : theme04_title_long001;
      case 5: return states.displayOptions.display_time_weather == "1" ? theme05_title_short001 : theme05_title_long001;
      case 6: return states.displayOptions.display_time_weather == "1" ? theme06_title_short001 : theme06_title_long001;
      case 7: return states.displayOptions.display_time_weather == "1" ? theme07_title_short001 : theme07_title_long001;
      case 8: return states.displayOptions.display_time_weather == "1" ? theme08_title_short001 : theme08_title_long001;
      default: return  theme01_title_short001
    }
  }


  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const changeHandler = (field, value) => {
    let managewaitingRoomMediaChannel = states.managewaitingRoomMediaChannel
    managewaitingRoomMediaChannel[field] = value
    managewaitingRoomMediaChannel['enableButton'] = false
    dispatch(updateWaitingRoomChannelNameRequest(managewaitingRoomMediaChannel))
    setTheme(value)
  }

  const changeHandlerTitle = (field, value) => {
    let managewaitingRoomMediaChannel = states.managewaitingRoomMediaChannel
    managewaitingRoomMediaChannel[field] = value
    managewaitingRoomMediaChannel['enableButton'] = false
    dispatch(updateWaitingRoomChannelNameRequest(managewaitingRoomMediaChannel))
  }

  

  const onSelectedChange = (event, name) => {
    setIsShared({[name]: event.target.checked });
  }

  const submitHandler = (event) => {

    let params = {
      "title": states.managewaitingRoomMediaChannel.title,
      "theme_id": states.managewaitingRoomMediaChannel.theme_id,
      "header_status": state.checkedB ? "1" : "0",
      "footer_status": state.checkedC ? "1" : "0",
      "display_custome_message_and_newsfeed": states.displayOptions.display_custome_message_and_newsfeed,
      "display_time_weather": states.displayOptions.display_time_weather,
      "enableButton": false,
      "is_shared": isShared.practiceonly ? 0 : 1
    }

    const isAddChannel = props.location.state.isFromMenageChannel ? false : true;
    setIsAddChannel(isAddChannel);
    console.log("isAdd", isAddChannel);
    dispatch(updateWaitingRoomChannelName(states.managewaitingRoomMediaChannel.id, params, isAddChannel))
    // history.push('/channels')
  }

  const onPublish = () =>{
    // submitHandler()
		dispatch(postwaitingRoomPublishTimeline(states.managewaitingRoomMediaChannel.id))
	}

  const onSliderChange = (e) => {
		const activeNextSlide = e.activeIndex === 0 ? '-disable' : '-enable';
		setNextEnable(activeNextSlide);
	};


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: selectedfile,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="waitingRoom__main__screen  d-flex flex-column h-100 align-items-center ">
      <SubHeader className="" title="Manage Channel" />
      {states.managewaitingRoomMediaChannel &&
        <div className="d-flex waitingRoom__main__inner_screen mt-5">
          <div className="waitingRoom__main__left-slide d-flex flex-column waitingRoom__main__left-slide mr-20" >
            {isAddChannel && !state.buttonLoading2 ? (
              <div className="transparent__form--white h-100  d-flex flex-column  justify-content-between" style={{ border: '1px solid green' }}>
                <div className="Page6__main__left-slide__div1__header" style={{ padding: '42px 40px 46px', minWidth: '346px' }}>
                  <h6>  Channel Added Successfully </h6>

                  your chanel is now accessible via the waiting room channel section. please use new channel opiion menu, orclick "Manage Timeline" to make any changes
              </div>
                <div className="waitingRoom__main__left-slide__div1__footer">

                  <div className="footer_btn">
                    <div className="row">
                      <div className=" col-md-12">
                        <Button type="button" onClick={() => { states.practiceInfo.is_corporate == 1 ? history.push('/channels') :  history.push('/waiting-room-channels') }} name="" id="" className="btn btn-block btn-primary">Back to Waiting Room Channels</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
                <div className="transparent__form--white h-100  d-flex flex-column  justify-content-between">
                  <div className="waitingRoom__main__left-slide__div1__header">
                    <h6>Name and customize your new channel</h6>
                  </div>
                  <div className="waitingRoom__main__left-slide__div1__body">
                    <FormGroup className="waitingRoom__main__left-slide__div1__body__header">
                      <label>Waiting room channel's name</label><br />
                      <Input type="text" name="title" value={states.managewaitingRoomMediaChannel.title} onChange={(e) => changeHandlerTitle('title', e.target.value)} />
                    </FormGroup>
                    <FormGroup className="d-flex waitingRoom__show_header">
                      <div className="waiting__show_img p-0 d-flex flex-column align-items-center" >
                        <img className="w-100" src={state.checkedB ? waitingRoom__show_header_hover : upper} alt=""></img>
                      </div>
                      <div className="ml-4 w-100 d-flex flex-column justify-content-between">
                        <div className="channel_detail d-flex flex-column justify-content-between h-100">
                          <div className="d-flex align-items-center waiting_checkbox">
                            <label className="mr-4 mb-0  timeline-title">Show header</label>
                            <FormControlLabel className="mr-0 custom_checkbox" control={<IOSSwitch checked={state.checkedB} onChange={(e)=>handleChange(e)} name="checkedB" />} />
                          </div>
                          <div className="waiting_checkbox">
                            <label>Practice name</label>
                          </div>
                          <div className="">
                            <Button size="xs" onClick={() => history.push("/waiting-room-channel-header")}>Edit Header</Button>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="d-flex waitingRoom__show_timeline" >
                      <div className="waiting__show_img p-0 d-flex flex-column align-items-center" >
                        <img className="w-100" src={middle} alt=""></img>
                      </div>
                      <div className="ml-4 d-flex flex-column justify-content-between">
                        <div className="channel_detail d-flex flex-column justify-content-between h-100">
                          <div className="d-flex align-items-center ">
                            <label className="w-100 timeline-title">Content</label>
                          </div>
                          <div className="waiting_checkbox">
                            <p>Choose media that will display on the channel.</p>
                          </div>
                          <div className="" onClick={() => history.push("/waiting-room-channel-timeline")}>
                            <Button size="xs" >Edit Content</Button>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="d-flex waitingRoom__show_footer">
                      <div className="waitingRoom__show_footer--img  waiting__show_img p-0 d-flex flex-column align-items-center" >
                        <img className="w-100" src={state.checkedC ? waitingRoom__show_footer_hover : lower} alt=""></img>
                      </div>
                      <div className="ml-4 d-flex flex-column justify-content-between">
                        <div className="channel_detail d-flex flex-column justify-content-between h-100">
                          <div className="d-flex align-items-center waiting_checkbox">
                            <label className="mr-4 mb-0 timeline-title">Show Footer</label>
                            <FormControlLabel className="m-0 custom_checkbox" control={<IOSSwitch checked={state.checkedC} onChange={(e)=>handleChange(e)} name="checkedC" />} />
                          </div>
                          <div className="waiting_checkbox">
                            <p>Edit custom message, news sources and weather.</p>
                          </div>
                          <div className="">
                            <Button size="xs" onClick={() => history.push("/waiting-room-channel-footer")}>Edit Footer</Button>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className=" custom-checkbox custom-control mb-4 show-channel-checkbox">
                      {/* disabled={state.allpractice ? true : false} */}
                        <input type="radio" name={'practiceonly'}  checked={isShared.practiceonly} onChange={(e)=>onSelectedChange(e, 'practiceonly')} className="custom-control-input" id="practiceonly" />
                        <label className="custom-control-label" htmlFor="practiceonly">Show channel in this practice only</label>
                      </div>
                      <div className=" custom-checkbox custom-control ">
                      {/* disabled={state.practiceonly ? true : false} */}
                        <input type="radio" name={'practiceonly'}  checked={isShared.allpractice} onChange={(e)=>onSelectedChange(e, 'allpractice')} className="custom-control-input" id="allpractice" />
                        <label className="custom-control-label" htmlFor="allpractice">Share channel with all my practices</label>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="waitingRoom__main__left-slide__div1__footer">
                    <div className="footer_btn">
                      <div className="row">
                        {/* <div className=" col-md-4">
                          <Button type="button" onClick={() => { history.push({ pathname: '/waiting-room-channel-schedule', state: { channel_id: states.managewaitingRoomMediaChannel.id } }) }} name="" id="" className="btn btn-block btn-white">SCHEDULE</Button>
                        </div> */}
                        <div className="col">
                          <Button type="button" name="" id="" onClick={() => { history.push({ pathname: '/waiting-room-channel-images' }) }} className="btn btn-block btn-white px-2">Thumbnail</Button>
                        </div>
                        <div className="col">
                          <Button type="button" name="" id="" onClick={() => setModal(true)} className="btn btn-block btn-white">PREVIEW</Button>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      {props.location.state.isFromMenageChannel ?
                        <>
                          <LoadingButton type="button" loading={states.buttonLoading2} label={props.location.state.isFromMenageChannel ? 'Save channel' : 'ADD CHANNEL'} disabled={states.managewaitingRoomMediaChannel.enableButton || states.buttonLoading2 ? 'disabled' : ''} size="xxl" onClick={submitHandler} className={`mt-auto w-100`} color={'primary'} />
                          <LoadingButton type="button"  label={"Publish channel"} className="w-100 mt-4" loading={states.buttonLoading3} disabled={states.buttonLoading3}  onClick={() => onPublish()}  color={'primary'}/>
                        </>
                        : 
                        <LoadingButton type="button" loading={states.buttonLoading2} label={props.location.state.isFromMenageChannel ? 'Save channel' : 'ADD CHANNEL'} disabled={states.managewaitingRoomMediaChannel.enableButton || states.buttonLoading2 ? 'disabled' : ''} size="xxl" onClick={submitHandler} className={`mt-4 w-100`} color={'primary'} />
                       }
                    </div>
                  </div>
                </div>)}
          </div >
          <div className="waitingRoom__main__right-side ml-20">
            <div className="waitingRoom__main__screen--slide d-flex flex-column flex-fill" >
              <div className="d-flex flex-column flex-fill waitingRoom__main__screen--slide--innerBox">
                <video key={selectedTheme} autoPlay={true} preload="auto" loop={true} className="w-100 theme_bg">
                    <source src={`assets/img/Themes/${selectedTheme}/theme_0${selectedTheme}_BG.webm`}  type="video/webm" />
                </video >
                {state.checkedB &&
                  <div style={{cursor:"pointer",position:'relative'}} onClick={() => history.push("/waiting-room-channel-header")}>
                    <h2 className=" waitingRoom__main__screen--practice_name text-uppercase" >{states.managewaitingRoomMediaChannel.practice_name}</h2>
                  </div>
                }
                {state.checkedC &&
                <div className="d-flex mt-auto justify-content-end">
                  {states.displayOptions.display_custome_message_and_newsfeed == "1" &&
                    <div style={{cursor:"pointer",position:'relative'}} className=" mt-auto waitingRoom__main__screen--slide--footer" id="f1" onClick={() => history.push("/waiting-room-channel-footer")}>
                      {/* <img  role="presentation"  key={selectedTheme} src={`assets/img/Themes/${selectedTheme}/theme0${selectedTheme}-news-${states.displayOptions.display_time_weather == "1" ? 'short' : 'long'}.webp`} alt="" className="w-100"/> */}
                      <Lottie options={defaultOptions}/>
                      <div className={`theme_${selectedTheme} footer_news`}>
                        {selectedTheme !== 4 ?
                        <>live News</>
                        :
                        <>News</>
                        }
                      </div>
                      <h3 className={`theme_${selectedTheme} `}>Welcome to {states.managewaitingRoomMediaChannel.practice_name}!</h3>
                      <div className={`theme_${selectedTheme} waitingRoom__main__screen--slide--footer--text`}><p>We hope that your experience today leaves you smile :D</p></div>
                    </div>
                  }
                  
                  {states.displayOptions.display_time_weather == "1" &&
                    <div className="waitingRoom__main__screen--slide--footer--weather">
                      {/* <img src={`assets/img/sunny_bg.png`} alt=""/> */}
                      <video key={selectedTheme} autoPlay={true} preload="auto" loop={true} className="w-100 ">
                        <source src={`assets/img/sun.mp4`}  type="video/mp4" />
                      </video >
                      <span className="time">2:30 PM</span>
                      <span className="temp">78 &#8457;</span>
                    </div>
                  }
                </div>
                }
               
              </div>
            </div>

            <div className="waitingRoom__main__screen--theme__lists">
              <h3 className="my-5">Select a theme</h3>
              <div className="d-flex theme__lists justify-content-between">
                { states.waitingRoomThemeList && states.waitingRoomThemeList.length && states.waitingRoomThemeList.length > 0 &&
                <Swiper
                    spaceBetween={0}
                    slidesPerView={5}
                    navigation
                    id={`video-slider${nextEnable}`}
                    onSlideChange={(e) => onSliderChange(e)}>
                        {
                            states.waitingRoomThemeList && states.waitingRoomThemeList.length && states.waitingRoomThemeList.length > 0 ? states.waitingRoomThemeList.map((lists, index) => {
                                return (
                                    <SwiperSlide data-hash={index} key={lists.id}>
                                        <div key={index} className="theme__box">
                                            <div className="sm custom-checkbox custom-control ">
                                                <input type="radio" name={`theme`} checked={lists.id == states.managewaitingRoomMediaChannel.theme_id} onChange={(e) => changeHandler('theme_id', lists.id)} className="custom-control-input" id={`theme${lists.id}`} />
                                                <label className="custom-control-label p-0" htmlFor={`theme${lists.id}`}><img className="w-100" src={lists.image} alt="" /></label>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                                }) : ''
                        }
                </Swiper>
                }
              </div>
            </div>
          </div>
        </div>
      }

      <PreviewModal url={`${WAITINGROOM_CHANNEL_PREVIEW_URL}${states.managewaitingRoomMediaChannel.id}&preview=1&accessToken=${accessToken}`} isOpen={modal} toggle={() => setModal(!modal)} />
    </div>
  )
}

export default CreateNewWaitingRoomChannel