import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import SubHeader from '../../../components/Header/subHeader'
import Loading from '../../../components/Loading';
import TimeLineDropBox from '../../../components/TimeLineDropBox';
import { TIMELINEMEDIATYPE } from '../../../data/comman';
import { getChairsidePlaylistMediaData, getTimeline, postTimeline, publishChairsidePlaylist, publishChairsidePlaylistFailure, publishChairsidePlaylistResetMessage, updateChairsidePlaylist } from '../../../store/actions/taskAction';

import ChairsideVideoPlayList from './CharsidePlaylist'
const EditVideoList = () => {

	const state = useSelector((state) => state.task);
	const [timeLineList, setTimeLineList] = useState([]);
	const dispatch = useDispatch();

    useEffect(() => {
		dispatch(getChairsidePlaylistMediaData());
        dispatch(getTimeline(state.chairsidePlaylist.id, false, true));
	}, []);

    const onDragEnd = (result) => {
		const draggleableItem = document.getElementsByClassName("aftertimeline")
		draggleableItem[result.source.index].classList.remove("overlayClass")
		const { source, destination } = result;
		// dropped outside the list
		if (!result.destination || destination.droppableId === "delete") {
			timeLineList.splice(result.source.index, 1);
			setTimeLineList([...timeLineList]);
			// return;
			postTimeLineToServer(timeLineList);
		}else if(result.source && result.source.droppableId == "droppable_education"){
			var video_info = result.draggableId.split('_');
			var videoCatIndex = state.educationTimeline.findIndex(x => x.categories_id == video_info[0])
			var videoIndex =  state.educationTimeline[videoCatIndex].video.findIndex(x => x.media_id ==  video_info[1])
			var items = state.educationTimeline[result.source.index]
			var video = state.educationTimeline[videoCatIndex].video[videoIndex]
			var commonTimeLine = {
                thumb: video.thumb,
                title: video.title,
                duration: video.duration || 0,
                media_id: +video_info[1],
                media_type_id: TIMELINEMEDIATYPE,
                // sort_order:
                uId: uuid()
			}

			var timeLineListTemp = timeLineList;
			timeLineListTemp.splice(result.destination.index, 0, commonTimeLine);
			timeLineListTemp[result.destination.index] = commonTimeLine
			setTimeLineList([...timeLineListTemp]);
			postTimeLineToServer(timeLineListTemp);

		}else if(result.source && result.source.droppableId == "droppable"){
			const items = reorder(
				timeLineList,
				result.source.index,
				result.destination.index
			);
			setTimeLineList(items);
			postTimeLineToServer(items);
		}
	  }

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
	};

    const postTimeLineToServer = (mainTimelineList) => {
        var param = {};
        var timelineList = []
        mainTimelineList.map((timeline, index) => {
            timelineList.push({
                media_id: timeline.media_id,
                media_type_id: timeline.media_type_id,
                sort_order: index
            });

        });
        param["timeline"] = timelineList;
        dispatch(postTimeline(state.chairsidePlaylist.id, param , false , true))
    }

    const onDragStart = (result) => {
        const draggleableItem = document.getElementsByClassName("aftertimeline")
        draggleableItem[result.source.index].classList.add("overlayClass")
    }

    useEffect(() => {
		var timeline = [];
		state.channelTimeLine.map((tl)=>{
            console.log(tl,'ttltltltltltltlltltl')
			if(tl.media_data)
				timeline.push({
					media_id: tl.media_data.chairside_media_id,
					uId: uuid(),
					thumb:   tl.media_data.chairside_media?.thumb,
					duration: tl.media_data.media.duration,
					title: tl.media_data.title,
					media_type_id: tl.media_type_id
				})
		})
		setTimeLineList([...timeline]);
	}, [state.channelTimeLine])

  

    const onPublish = async () =>{
		dispatch(publishChairsidePlaylist(state.chairsidePlaylist.id))

        
	}


  return (
		<div className='main__screen channels__page waitingroom_my_slides  d-flex flex-column h-100 align-items-center'>
        {state.loading1 && <Loading />}
        <SubHeader className='' title='Edit Playlist' />
       
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} >
                <div className='w-100 chairside_education_playlist__inner_screen mt-3 mt-xl-5'>
                    <Droppable droppableId="delete" className={` footer-header `} direction="horizontal">
                        {(provided, snapshot) => (<>
                                <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                >

                                <ChairsideVideoPlayList
                                    educationTimeline={state.chairsidePlaylistMediaData} timeLineList={timeLineList} onPublish={onPublish}/>
                                {provided.placeholder}
                                </div>
                        </>)}
                    </Droppable>

                    
                </div>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                            <div className="dropbox__main__screen mt-auto">
                                <TimeLineDropBox timeLineList={timeLineList} isChairsidePlayList={true} setTimeLineList={setTimeLineList} provided={provided} snapshot={snapshot} channelId={state.chairsidePlaylist.id}/>
                            </div>
                    )}
                </Droppable>
            </DragDropContext>
        
    </div>
  )
}

export default EditVideoList
