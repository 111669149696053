import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button } from 'reactstrap';
import { MYSLIDESTYPE, TIMELINESLIDETYPE } from '../../data/comman';
import LoadingButton from '../../components/LoadingButton'
const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomSlideCategory = ({ setSelectedSlide, timeLineList, onNewSlideClick , onPublish}) => {
    const [allSlideIndex, setAllSlideIndex] = useState(true);
    const [slideCount, setSlideCount] = useState(0);
    const [slides, setSlides] = useState([]);
    const [count, setCount] = useState(0);
    const state = useSelector((state) => state.task);

    useEffect(() => {
        const arr = [];
        const slides = [];
        const slideArray = [];
        var totalSlide = 0;
        state.waitingRoomChannelSlides.forEach((element) => {
            arr.push({
                id: element.id,
                title: element.title,
                selected: false,
                slideLength: element.get_slide.length,
                slides: element.get_slide,
            });

            element.get_slide.forEach((slide) => {
                var isAdded = timeLineList.filter(x => x.media_id == slide.id && x.media_type_id == TIMELINESLIDETYPE);
				slideArray.push({
					...slide,
					category_name: element.title,
                    selected: false,
                    isAdded,
				});
			});

            totalSlide += element.get_slide.length;
        });
        setSlideCount(totalSlide);
        setSelectedSlide(slideArray);
        setSlides(arr)
    }, [state.waitingRoomChannelSlides]);

    const allSlideListActive = allSlideIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;

    // On Categry Click
    const onCategoryClick = (item) => {
        setAllSlideIndex(false);
        setCount(count + 1);
        const slideArray = [];
        slides.map((element) => {
            if (element.id === item.id) {
                element.selected = !item.selected;
            }
        });
        slides.map((element) => {
            if (element.selected === true) {
                element.slides.forEach((slide) => {
                    console.log(slide,'slide')
                    var isAdded = timeLineList.filter(x => x.media_id == slide.id && x.media_type_id == TIMELINESLIDETYPE);
                    slideArray.push({
                        ...slide,
                        cat_name: element.title,
                        selected: false,
                        isAdded,
                    });
                });
            }
        });

        // select all
        var isAllSelectCat = slides.filter((x) => x.selected);
        setAllSlideIndex(slides.length == isAllSelectCat.length);

        setSelectedSlide(slideArray);
    };

    const changeAllSlides = () => {
        setAllSlideIndex(true);
        setCount(count + 1);
        const slideArray = [];
        slides.forEach((element) => {
            element.selected = false;
            element.slides.forEach((slide) => {
                var isAdded = timeLineList.filter(x => x.media_id == slide.id && x.media_type_id == TIMELINESLIDETYPE);
                slideArray.push({
                    ...slide,
                    category_name: element.title,
                    selected: false,
                    isAdded,
                });
            });
        });
         
        setSelectedSlide(slideArray);
    };

    return (
        <>
            <div className='videolist__main__screen waitingroom_slides_category d-flex flex-column'>
                <div className='videolist__main__lists'>
                    <div className='videolist__main__header'>
                        <span className='videolist__main__header__header-title d-block mb-3'>
                            Topics
                        </span>
                    </div>
                    <div className='videolist__submain__header'>
                        <img
                            src={allSlideListActive}
                            alt=''
                            onClick={() => changeAllSlides()}
                            className='videolist__submain__header__checkbox'
                        />
                        &nbsp; &nbsp; &nbsp;
                        <span className='videolist__submain__header__title'>
                            Show all my slides {`(${slideCount})`}<br/>
                        </span>
                    </div>
                    <hr className='videolist__main__lists_hr' />
                    {slides.length > 0
                        ? slides.map((item, index) => {
                                const active = item.selected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
                                return (
                                    <React.Fragment key={index}>
                                        <div className='videolist__main__lists__videoselection'>
                                            <img
                                                src={active}
                                                alt=''
                                                onClick={() => onCategoryClick(item)}
                                                className='videolist__submain__header__checkbox'
                                            />
                                            &nbsp; &nbsp; &nbsp;
                                            <span className='videolist__submain__header__title'>
                                                {item.title}
                                                {`(${item.slideLength})`}
                                            </span>
                                        </div>
                                        {/* <br />  */}
                                    </React.Fragment>
                                );
                            })
                        : null}
                </div>
				<div className='d-flex videolist__main__button'>
                    <LoadingButton type="button"  label={"Publish timeline"} className="mr-3" loading={state.buttonLoading3} disabled={state.buttonLoading3} size="md" onClick={() => onPublish()}  color={'primary'}/>

                    <Button
                        className='ml-3'
                        color='white'
                        size="md"
                        onClick={() => onNewSlideClick()}
                    >
                        Create Slide
                    </Button>
                </div>
            </div>
        </>
    );
};


export default WaitingRoomSlideCategory
