import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchChairsideMedia, fetchChairsideMediaCategoryList, getOperatoryMediaChannelList, getWebsiteRoomMediaChannelList } from '../../../../store/actions/taskAction';
import styles from './style.module.scss';
import ChannelList from '../ChannelList'
import { GOOGLE_TV_TEST_DRIVE_RECENTPLAY } from '../../../../data/comman';
import Loading from '../../../../components/Loading';

const WaitingRoomChannels = ({ sortedBy, sortedType , isOperatoryRoom, isChairside }) => {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const [myList, setMyList] = useState([]);
    const [recentPlay, setRecentPlay] = useState([]);
    const [videosList, setvideosList] = useState(state.chairSideMediaState);
    const [allVideosList, setallVideosList] = useState(state.chairSideMediaState);
    const [categoriesList, setCategoriesList] = useState(state.chairSideCategoryList);

    var recentPlayedDb = localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY);

    useEffect(() => {

      if(isChairside){
        dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))
        dispatch(fetchChairsideMediaCategoryList(state.practiceUserLoginInfo));
        return
      }
      
      if(isOperatoryRoom){
        dispatch(
          getOperatoryMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }else{
        dispatch(
          getWebsiteRoomMediaChannelList(
            state.practiceUserLoginInfo,
            sortedBy,
            sortedType,
            state.practiceInfo.is_corporate == 1
          )
        );
      }
      
    }, []);

    useEffect(()=>{
      setCategoriesList(state.chairSideCategoryList)
    }, [state.chairSideCategoryList])

    useEffect(()=>{
      if(isChairside){
        if(recentPlayedDb != null){
          var recentPlay = JSON.parse(localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY));
          var recentPlayVideos = []
          recentPlay.map((video)=>{
              var videolist = state.chairSideMediaState.find(x => x.chairside_media_id == video);
              if(videolist != null && videolist != undefined){
                recentPlayVideos.push(videolist)
              }
          })
          setRecentPlay(recentPlayVideos)
        }
        return
      }
      
      let ChannelsList = isOperatoryRoom ? state.waitingRoomMediaChannels : state.operatoryMediaChannels
      if(recentPlayedDb != null){
          var recentPlay = JSON.parse(localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY));
          var recentPlayVideos = []
          recentPlay.map((video)=>{
              var videolist = ChannelsList.find(x => x.id == video);
              if(videolist != null && videolist != undefined){
                recentPlayVideos.push(videolist)
              }
          })
          setRecentPlay(recentPlayVideos)
      }
  }, [recentPlayedDb])

    useEffect(()=>{

      if(isChairside){
        getChairsideMedia()
        return
      }

      if(isOperatoryRoom){
        getOperatoryChannels()
      }else{
        getWaitingRoomChannels()
      }

  }, [state.waitingRoomMediaChannels, state.operatoryMediaChannels, state.chairSideMediaState])

  const getChairsideMedia =  async () =>{
    
    var myList = [];
    state.chairSideMediaState.map((video)=>{
        if(video.chairside_media && video.chairside_media.in_mylist == 1){
          myList.push(video)
        }
    })

    setMyList(myList);
    setvideosList(state.chairSideMediaState.slice(0, 10) )
    setallVideosList(state.chairSideMediaState)
    if(recentPlayedDb != null){
      var recentPlay = JSON.parse(localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY));
      var recentPlayVideos = []
      recentPlay.map((video)=>{
          var videolist = state.chairSideMediaState.find(x => x.chairside_media_id === video);
          if(videolist != null &&  videolist != undefined){
              recentPlayVideos.push(videolist)
          }
      })
      setRecentPlay(recentPlayVideos)
  }
}

  const getWaitingRoomChannels =  async () =>{
    
      var myList = [];
      state.waitingRoomMediaChannels.map((video)=>{
          if(video.my_list && video.my_list.id){
              myList.push(video)
          }
      })
      setMyList(myList);

      if(recentPlayedDb != null){
        var recentPlay = JSON.parse(localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY));
        var recentPlayVideos = []
        recentPlay.map((video)=>{
            var videolist = state.waitingRoomMediaChannels.find(x => x.id === video);
            if(videolist != null &&  videolist != undefined){
                recentPlayVideos.push(videolist)
            }
        })
        setRecentPlay(recentPlayVideos)
    }
  }

  const getOperatoryChannels = async () =>{
    var myList = [];
    state.operatoryMediaChannels.map((video)=>{
        if(video.my_lists && video.my_lists.id){
            myList.push(video)
        }
    })
    await  setMyList(myList);

    if(recentPlayedDb != null){
      var recentPlay = JSON.parse(localStorage.getItem(GOOGLE_TV_TEST_DRIVE_RECENTPLAY));
      var recentPlayVideos = []
      recentPlay.map((video)=>{
          var videolist = state.operatoryMediaChannels.find(x => x.id === video);
          if(videolist != null && videolist != undefined){
              recentPlayVideos.push(videolist)
          }
      })
      setRecentPlay(recentPlayVideos)
   }

  }

  return (
    <>    
        {state.loading ? <div className={`goolge_tv__loader`}><Loading /></div> :
        <div className={`${styles.goolge_tv_waiting_room__inner__screen} mt-5`}>
            <ChannelList title={isChairside ? 'New Videos' : 'New channels'} isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} channelsData={ isChairside ? videosList.slice(0, 10) : isOperatoryRoom ? state.operatoryMediaChannels.slice(0, 10) :state.waitingRoomMediaChannels.slice(0, 10)}/>
            <ChannelList title="Recently played" isRecentPlay={true} isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} channelsData={recentPlay}/>
            <ChannelList title="My List" isMyList={true} isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} channelsData={myList}/>


        
            {isChairside && categoriesList.map((item,index) => { 
                    var videosCat = []
                    allVideosList.map((video,i)=>{
                        if (item.id == video.media_category_id)
                        {
                            videosCat.push(video);
                        }
                    })
                  return (      
                    <ChannelList title={item.title} isChairside={isChairside} isOperatoryRoom={isOperatoryRoom} channelsData={videosCat.slice(0, 10)}/>
                  )
            })}
        </div>
        }
    </>
  )
}

export default WaitingRoomChannels
