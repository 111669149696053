import React from 'react';

import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button , Spinner } from 'reactstrap';
import { history } from '../../pages/index';
import {  GetpracticeProfile  , GetPracticeMoreInfo , UpdatepracticeInfoRequest, GetSubscribePriceOption} from "../../store/actions/taskAction";

import { Ownership  , operatories , days , tv_count} from '../../data/comman';
import HttpUtility, {HELPERURI} from '../../utilities/HttpUtility';

import InitialSetup from './Form'
import Loading from '../../components/Loading'
import AlertMessage  from '../../components/AlertMessage'
import './style.scss';

class ProfileCreated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isContinue:false
    }
  }

  async componentDidMount(){
    if(this.props.practiceUserLoginInfo?.is_manage_location !== 1){
      await this.props.dispatch(GetpracticeProfile());
      await this.onupdate()
    }else{
      await this.onupdatechildPractice()
    }
    // await this.props.dispatch(GetpracticeProfile());
    // await this.props.dispatch(GetSubscribePriceOption());
    // await this.props.dispatch(GetPracticeMoreInfo());
  }

  async onupdatechildPractice(){
      
    if(this.props.practiceUserLoginInfo.practice_profile_info){
      if(this.props.practiceUserLoginInfo?.practice_profile_info?.country_id){
        var selectedcountry =  this.props.country.find(b => b.id === this.props.practiceUserLoginInfo?.practice_profile_info?.country_id)
        await  this.onEditPracticeInfo( 'selectedcountry' , selectedcountry)
        await  this.getState(selectedcountry.id);
      }
    }

    if(this.props.practiceUserLoginInfo.practice_profile_info){
        var ownership  = Ownership.find(b => b.id === this.props.practiceUserLoginInfo?.practice_profile_info?.ownership)
        this.onEditPracticeInfo( 'ownership' , ownership)
    }

    var specializations = []
    if(this.props.practiceUserLoginInfo?.practice_profile_info?.specializations){
        this.props.practiceUserLoginInfo?.practice_profile_info?.specializations.map((ids)=>
        specializations.push(this.props.specializations.find(b => b.id === ids)),
        );
          this.onEditPracticeInfo( 'specializations' , specializations)
    }

    if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.software){
      var selectedsoftware  = this.props.practice_softwares.find(b => b.name === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info.software)
      this.onEditPracticeInfo( 'selectedsoftware' , selectedsoftware)
    }

    if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.operatory_count){
      var selectedoperatories  = operatories.find(b => b.value === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.operatory_count)
      this.onEditPracticeInfo( 'selectedoperatories' , selectedoperatories)
    }

    if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.source){
      var selectedsource  = this.props.lead_source_types.find(b => b.name === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.source)
      this.onEditPracticeInfo( 'selectedsource' , selectedsource)
    }

    if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.practice_profile_info?.customer_type){
      var selectedpracticetype  = this.props.practice_customer_types.find(b => b.id === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.customer_type)
        this.onEditPracticeInfo( 'selectedpracticetype' , selectedpracticetype)
    }

    if(this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.tv_count){
      var selectedtv_count  = tv_count.find(b => b.value === this.props.practiceUserLoginInfo?.practice_profile_info?.additional_info?.tv_count)
      this.onEditPracticeInfo( 'selectedtv_count' , selectedtv_count)
    }
  }

  async onupdate(){
      
    if(this.props.practiceProfileInfo){
      await  this.onEditPracticeInfo( 'email_alt' ,  this.props.practiceProfileInfo?.email)
      if(this.props.practiceProfileInfo?.country_id){
        var selectedcountry =  this.props.country.find(b => b.id === this.props.practiceProfileInfo?.country_id)
        await  this.onEditPracticeInfo( 'selectedcountry' , selectedcountry)
        await  this.getState(selectedcountry.id);
      }
    }

    if(this.props.practiceProfileInfo){
        var ownership  = Ownership.find(b => b.id === this.props.practiceProfileInfo?.ownership)
        this.onEditPracticeInfo( 'ownership' , ownership)
    }

    var specializations = []
    if(this.props.practiceProfileInfo?.specializations){
        this.props.practiceProfileInfo?.specializations.map((ids)=>
        specializations.push(this.props.specializations.find(b => b.id === ids)),
        );
          this.onEditPracticeInfo( 'specializations' , specializations)
    }

    if(this.props.practiceProfileInfo?.additional_info?.software){
      var selectedsoftware  = this.props.practice_softwares.find(b => b.name === this.props.practiceProfileInfo?.additional_info.software)
      this.onEditPracticeInfo( 'selectedsoftware' , selectedsoftware)
    }

    if(this.props.practiceProfileInfo?.additional_info?.operatory_count){
      var selectedoperatories  = operatories.find(b => b.value === this.props.practiceProfileInfo?.additional_info?.operatory_count)
      this.onEditPracticeInfo( 'selectedoperatories' , selectedoperatories)
    }

    if(this.props.practiceProfileInfo?.additional_info?.source){
      var selectedsource  = this.props.lead_source_types.find(b => b.name === this.props.practiceProfileInfo?.additional_info.source)
      this.onEditPracticeInfo( 'selectedsource' , selectedsource)
    }

    if(this.props.practiceProfileInfo?.additional_info?.customer_type){
      var selectedpracticetype  = this.props.practice_customer_types.find(b => b.id === this.props.practiceProfileInfo?.additional_info?.customer_type)
        this.onEditPracticeInfo( 'selectedpracticetype' , selectedpracticetype)
    }

    if(this.props.practiceProfileInfo?.additional_info?.tv_count){
      var selectedtv_count  = tv_count.find(b => b.value === this.props.practiceProfileInfo?.additional_info?.tv_count)
      this.onEditPracticeInfo( 'selectedtv_count' , selectedtv_count)
    }
  }

  async getState(countryId){
    await HttpUtility.getRequest(`${HELPERURI}${countryId}/state`).then((response) => {
        if(response.status === 200){
            var countryState = response.data;
            this.onEditPracticeInfo( 'countryState' , countryState)
            if(this.props.practiceProfileInfo.state_id){
              var selectedstate = countryState.find(b => b.id === this.props.practiceProfileInfo.state_id)
            }
            this.onEditPracticeInfo( 'selectedstate' , selectedstate)
        }
    });
  }

  async onEditPracticeInfo(field , value){
    let practiceProfileInfo = this.props.practiceProfileInfo
    practiceProfileInfo[field] = value
    await this.props.dispatch(UpdatepracticeInfoRequest(practiceProfileInfo))
  }
 
  onContinueClick(){
    this.setState({isContinue:true})
  }

  render (){
    const {practiceProfileInfo , loading , error, loadingPractiveProfile} = this.props
    var  isNewLocation = this.props.location.state  ? this.props.location.state.isNewLocation : false
    return(
        <div className="subscription__main__screen  d-flex flex-column h-100 align-items-center ">
            {loadingPractiveProfile ? 
              <Loading />
            :
              <>
                <SubHeader className=""/>
                <InitialSetup practiceProfileInfo={practiceProfileInfo} isNewLocation={isNewLocation && isNewLocation || ''}/>
              </>
            }
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceProfileInfo: state.task.practiceProfileInfo,
  country: state.task.country,
  specializations: state.task.specializations,
  lead_source_types: state.task.lead_source_types,
  practice_softwares: state.task.practice_softwares,
  practice_customer_types:state.task.practice_customer_types,
  getSubscribePriceOption:state.task.getSubscribePriceOption,
  loadingMoreinfo:state.task.loadingMoreinfo,
  loading:state.task.loading,
  error: state.task.error,
  loadingPractiveProfile: state.task.loadingPractiveProfile,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  isUpgradePlan:state.task.isUpgradePlan

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(ProfileCreated);