import React, {useState} from 'react';
// import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import Back from '../../../img/help/Chevron.svg'
import '../style.scss';
import { Button } from 'reactstrap';
import Image1 from '../../../img/help/waiting_1.png'
import Image2 from '../../../img/help/waiting_2.png'
import Image3 from '../../../img/help/waiting_3.png'
import Image4 from '../../../img/help/waiting_4.png'

// ./../../img/help/waiting_4.png'

const WaitingRoom =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <>
  
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Waiting Room</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc">
                                        <h5>Navigating the Waiting Room Channel</h5>
                                        <div className="help_detail">
                                                <p>The Waiting Room Channels home screen shows you all of the channels that are available for viewing. 
                                                </p>
                                                <p>Channels with a black/transparent background were made by the Thalamus team, while channels with a white background are ones made by the practice. 
                                                </p>
                                                <p>Each channel box displays information regarding the channel, including name of channel, length, number of videos and names of videos. 
                                                </p>
                                                <p>On the left hand side, expanding the help menu allows the user to sort and filter channels shown. 
                                                </p>
                                                <p>New channels can be added by clicking the “New Channel” button.
                                                </p>
                                                <p>Selecting an individual channel from the waiting room channel menu, directs you to a preview screen, before playing the channel.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"13px" }}>
                                      <img src ={Image1} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"13px"}}>
                                        <h5>Managing Waiting Room channels</h5>
                                        <div className="help_detail">
                                          <p>Editing the name of a channel: Clicking the icon in the right hand corner of a channel allows you to edit the name of the channel.
                                          </p>
                                          <p>Editing Channel Content: Clicking the yellow “manage channels” button from the Waiting Room channels home page will direct you to the channel creator screen. Here you have access to all of the customization features for the channel, such as header (practice name) and footer (custom messages, newsfeed, time, and weather) adjustments, and timeline content modifications. </p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"13px"}}>
                                      <img src ={Image2} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"6px",marginLeft:"1px" }}>
                                        <h5>Creating Waiting Room channels</h5>
                                        <div className="help_detail">
                                         <p>Selecting ‘New Channel’ from the menu screen opens the channel creator screen. Several options for customization are made available at this screen. 
                                    </p>
                                    <p>Name: The name of the channel
                                    </p>
                                    <p>Header: The name of the Practice at the top of the channel, but can be edited to show any text.
                                    </p>
                                    <p>Footer: Text and widgets available on the bottom of the channel. Can include custom messages, newsfeed, time, and weather
                                    </p>
                                    <p>Timeline: Several pieces of content including education, entertainment, my videos, and my slides
                                    </p>
                                    <p>A preview of the channel is shown on the right hand side. At the bottom of the screen, several preset themes are available to choose from.
                                    </p>
                                    <p>Scheduling a channel: The ‘schedule’ button allows the user to make adjustments to when the videos play in the waiting room. Only one channel can have a programmed schedule, adding a new schedule will override and replace any other schedules currently in use.
                                    </p>
                                    <p>Images: Selecting ‘images’ allows you to select a thumbnail and overview image for the new channel.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-112px"}}>
                                      <img src ={Image3}  className="w-100" alt="" />
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{marginTop:"-56px", }}>
                                        <h5>Creating Waiting Room channels - Timeline</h5>
                                        <div className="help_detail">
                                          <p>Clicking the “view & edit captions” button for a selected video will direct you to the “closed captions” screen. Here the user can edit and add custom captions. 
</p>
<p>Clicking on ‘Time’ (either IN or OUT) allows the user to edit the captions. ‘IN’ is the time that the caption starts, and OUT is the time the caption ends. Press “Save changes” Once changes are finished, 
</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-31px",}}>
                                      <img src ={Image4}  className="w-100" alt="" />
                                  </div>
                            </div>
    
                      </div>
                  </>
            
    ) 

}

export default WaitingRoom;
