import React from 'react';
import SubHeader from  '../../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button , Label , FormGroup, Form , Col , Row , Spinner} from 'reactstrap';
import { InputField } from '../../../components/InputField';
import LoadingButton from '../../../components/LoadingButton';
import {SendReferForFreeMonth , resetReferForFreeMonth ,clearerror} from "../../../store/actions/taskAction";
import { history } from '../../../pages/index';


class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        formValid: false,
        is_sent:false,
        isValid:false,
        emailValid: false,
        field:{
          fullName:'',
          email:'',
          message:'',
        },
        errors:'',
        isError: {
            fullName:'',
            email:'',
            message:'',
          }
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, options) {
    const { name, value } = e.target;

    this.setState({
        field:{
            ...this.state.field,
            [name]: value
        }
    },()=> {this.isValid( name, value)})
    
  }

  isValid( name, value){

    let isError = this.state.isError ;
    let emailValid = this.state.emailValid;
    console.log(this.state.field ,'field')

    switch (name) {
      case "fullName":
          isError.fullName =
              value.length < 4 ? "Atleast 4 characaters required" : "";
          break;
      case "email":
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          isError.email = emailValid
              ? ""
              : "Wrong format";
          break;
      case "message":
          isError.message =  
          value.length  < 5 ? "the reason must be at least 5 characters" : "";
          break;
      default:
          break;
    }
    this.setState({emailValid: emailValid}, this.validateForm)
  }

  validateForm() {
    this.setState({formValid: this.state.emailValid && this.state.isError.fullName === '' && this.state.field.message !== ''  &&   this.state.field.fullName !== '' && this.state.isError.message ===''});
  }

  sendGetFreeMonth(){
    this.props.dispatch(SendReferForFreeMonth(this.state.field))
  }

  referAnother(){
    this.props.dispatch(resetReferForFreeMonth())
  }

  onClickReferralsList(){
    history.push('/referral-list');
  }

  async componentDidMount(){
    await this.props.dispatch(clearerror());
  }

  render (){
    const { isError } = this.state;
    const {t , refersend , buttonLoading} = this.props
    return(
      <div className="settings__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Free Month Referral"/>
          {refersend ?
            <div className="refer__success__message settings__main__invitation col-6 d-flex flex-column  mb-auto align-items-center justify-content-center">
                <h1>Your invitation has been sent</h1>
                <p className="fs-20">You’ll receive an email to confirm your complimentary 1-month <br/> subscription after your referral has set up a Thalamus account. </p>
                <div className="d-flex justify-content-center w-100">
                  <Button size="lg" type="button" className="mt-5 mx-3" onClick={() => this.referAnother()} color="primary">Refer another dentist</Button>
                  <Button outline size="lg" type="button" className="mt-5 mx-3" onClick={() => this.onClickReferralsList()} color="default white">See my referrals</Button>
                </div>
            </div>
          :
            <div className="transparent__form--white settings__main__refer_form d-flex flex-column  mb-auto d-flex ">
                <h4 className="mb-4">Refer a dentist and both of you are eligible for a FREE month of service!</h4>
                <p>Enter the information of the dentist you would like to refer.</p>
                <Form className="mt-2">
                    <Row>
                        <Col md={6}>
                        <FormGroup className={`${isError.fullName ? 'inputerror' : null }`}>
                            <InputField type="text" label={t('form.fullName')} name="fullName"  isError={isError.fullName} onChange={this.handleChange} />
                        </FormGroup>
                        </Col>
                        <Col md={6}>
                        <FormGroup  className={`${isError.email ? 'inputerror' : null }`}>
                            <InputField type="email" label={t('form.email')} name="email"  isError={isError.email} onChange={this.handleChange} />
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12}>
                        <FormGroup className={`${isError.message ? 'inputerror' : null }`}>
                            <InputField type="textarea" label={t('form.message')} name="message" placeholder="Type here"   isError={isError.message} onChange={this.handleChange} />
                        </FormGroup>
                        </Col>
                    </Row>
                    <LoadingButton type="button" label2={t('form.btnSend')} label={t('form.btnSend')} loading={buttonLoading} disabled={!this.state.formValid || buttonLoading} size="md" onClick={()=>{this.sendGetFreeMonth(this.state.field)}}  className={`mt-5`} color="primary"/>
                </Form>
            </div>
          }      

          
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    error: state.task.error,
    buttonLoading:state.task.buttonLoading,
    refersend:state.task.refersend
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);
