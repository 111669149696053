import React, { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input } from 'reactstrap'
import middle from '../../img/show_Content.png';
import LoadingButton from '../../components/LoadingButton'
import { history } from '../../pages';
import { useDispatch, useSelector } from 'react-redux';
import { createChairsidePlaylist, updateChairsidePlaylist } from '../../store/actions/taskAction';
import { InputField } from '../InputField';
import { Title } from '@material-ui/icons';

const Sidebar = ({isAddChannel}) => {

    const state = useSelector((state) => state.task);
	const dispatch = useDispatch();
    const [playlistState, setState] = useState({
            title: state.chairsidePlaylist.title || '',
            practiceonly: state.chairsidePlaylist.is_shared == 0 ? true : false,
            allpractice: state.chairsidePlaylist.is_shared == 1 ? true : false
    });

    const [message, setMessage] = useState({
		message: 'Save PLAYLIST',
		bgColor: 'primary',
	});

    const [error, setError] = useState({
        title: '',
        isError: false,
    })

    const resetError = () => {
        setError({
          title: '',
          isError: false,
        })
    }
  

    useEffect(() => {
        setStateValue("title", state.chairsidePlaylist.title)

        state.chairsidePlaylist.is_shared == 1 ?
        setState({ ...playlistState, 'allpractice': true })
        :
        setState({ ...playlistState, 'practiceonly': true })

    }, [state.chairsidePlaylist]);

    const setStateValue = (field, value) => {
        setState({
          ...playlistState,
          [field]: value,
        })
        resetError();
    }
    

    const onCreatePlaylit=()=>{
        var title ='', isError = false;
        if(playlistState.title == ""){
            title = "Please enter playlist title";
            isError = true;
        }

        if(isError == true){ 
            setError({
                title,
                isError: isError
            })
            return;
        }

        let params={
			title: playlistState.title,
            "is_shared": playlistState.practiceonly ? "0" : "1"
		}

		dispatch( updateChairsidePlaylist(state.chairsidePlaylist.id, params) );
        
        setMessage({
			message: 'Changes saved',
			bgColor: 'green',
		});

        setTimeout(() => {
            setMessage({
                message: 'Save PLAYLIST',
                bgColor: 'primary',
            });
		}, 1000);
    }

    const onSelectedChange = (event, name) => {
        setState({ [name]: event.target.checked });
    }
    

  return (
    <div className='transparent__form--white chairside_education_playlist__left_sidebar'>
        <h6 className='mb-5'>Name and customize your new playlist</h6>
        <FormGroup className="mb-5">
            <InputField type="text" label="Playlist name" name="title"   isError={error.title} value={playlistState.title} onChange={(e) => { setStateValue("title", e.target.value)}} className="mb-3" />
        </FormGroup>

        <FormGroup className="d-flex mb-5" >
            <div className=" p-0 d-flex flex-column align-items-center" onClick={() => history.push("/chairside-edit-playlist")} style={{cursor:'pointer'}}>
                <img className="w-100 chairside-edit-playlist-img" src={middle} alt="" style={{minWidth:'200px',height:'121px'}}></img>
            </div>
            <div className="ml-4 d-flex flex-column justify-content-between" onClick={() => history.push("/chairside-edit-playlist")} style={{cursor:'pointer'}}>
                <div className=" d-flex flex-column " >
                    <div className="d-flex align-items-center mb-3">
                        <label className="w-100 "><strong>Edit Playlist</strong></label>
                    </div>
                    <div className="editPlaylist_info">
                    <p>Edit playlist for
                    Chairside videos.</p>
                    </div>
                </div>
            </div>
        </FormGroup>
        <FormGroup>
            <div className=" custom-checkbox custom-control mb-4 show-channel-checkbox">
            {/* disabled={playlistState.allpractice ? true : false} */}
            <input type="radio" name={'practiceonly'}  checked={playlistState.practiceonly} onChange={(e)=>onSelectedChange(e , 'practiceonly')} className="custom-control-input" id="practiceonly" />
            <label className="custom-control-label" htmlFor="practiceonly">Show channel in this practice only</label>
            </div>
            <div className=" custom-checkbox custom-control ">
            {/* disabled={playlistState.practiceonly ? true : false} */}
            <input type="radio" name={'practiceonly'}  checked={playlistState.allpractice} onChange={(e)=>onSelectedChange(e , 'allpractice')} className="custom-control-input" id="allpractice" />
            <label className="custom-control-label" htmlFor="allpractice">Share channel with all locations</label>
            </div>
        </FormGroup>
        <LoadingButton type="button"  label={`${isAddChannel ? message.message : 'ADD PLAYLIST'}`} loading={state.buttonLoading1} disabled={state.buttonLoading1} onClick={()=> onCreatePlaylit()} size="xxl"  className={`mt-4 w-100`} color={message.bgColor} />
    </div>
  )
}

export default Sidebar
