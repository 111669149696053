import React from "react";
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";

import SubHeader from "../../components/Header/subHeader";
import Loading from "../../components/Loading";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  GetpracticeUser,
  cleardata,
  PracticeUserLogin,
} from "../../store/actions/taskAction";

// import Profile_icon from "../../img/Profile_icon.svg";
import Profile_icon from "../../img/Profile_icon_new.svg";
import add_new_user_icon from "../../img/add_new_user_icon.svg";
// import ic_add_to_queue from "../../img/ic_add_to_queue.svg";

import { history } from "../../pages/index";
import { Button } from "reactstrap";

class InitialSetupIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerify: false,
      isProfileLoading: false,
      isProfileCreated: false,
      practiceUsersList: this.props.practiceUsersList,
    };

    this.handleClick = this.handleClick.bind(this);
    this.onCreateProfile = this.onCreateProfile.bind(this);
  }

  handleClick() {
    this.setState({ isVerify: true });
  }

  onCreateProfile() {
    this.setState({ isProfileCreated: true });
  }

  async componentDidMount() {
    // if(this.props.practiceUsersList.length === 0 || this.props.practiceUsersList === false){
    this.props.dispatch(GetpracticeUser());
    // var keys = {};
    // window.addEventListener("keydown",
    //     function(e){
    //         keys[e.code] = true;
    //         switch(e.code){
    //             case "ArrowUp": case "ArrowDown": case "ArrowLeft": case "ArrowRight":
    //             case "Space": e.preventDefault(); break;
    //             default: break; // do not block other keys
    //         }
    //     },
    // false);
    // window.addEventListener('keyup',
    //     function(e){
    //         keys[e.code] = false;
    //     },
    // false);
    // }
    // this.props.dispatch(cleardata());
  }

  getUserType(id) {
    switch (id) {
      case 0:
        return ["guest", "Guest"];
      case 1:
        return ["dentist", "Normal User"];
      case 2:
        return ["authorized_user", "Authorized User"];
      case 3:
        return ["manager", "Manager"];
      case 4:
        return ["owner", "Owner"];
      default:
        return ["guest", "Guest"];
    }
  }

  getUserRole(role_id) {
    const role = this.props.practice_user_roles.filter((x) => {
      return x.id === role_id;
    });
    return role;
  }

  onNewProfileClick() {
    history.push("/newprofile");
  }

  onProfileSelect(users) {
    var fromtrial = false;
    var is_super_admin = false;
    var frompaymentinfo = false;
    if (this.props.history.location.fromtrial) {
      fromtrial = this.props.history.location.fromtrial;
    }
    if (this.props.history.location.frompaymentinfo) {
      frompaymentinfo = this.props.history.location.frompaymentinfo;
    }
    if (users.is_secure === 1) {
      history.push({
        pathname: "/signin",
        state: { users },
        fromtrial: fromtrial,
        frompaymentinfo: frompaymentinfo,
      });
    } else if (users.type === 2 && users.is_secure === 0) {
      history.push({
        pathname: "/setpasscode",
        state: { users },
      });
      // this.props.dispatch(PracticeUserLogin(users.id , null, true, fromtrial));
    } else if (users.type === 3 && users.is_secure === 0) {
      history.push({
        pathname: "/setpasscode",
        state: { users },
      });

      // this.props.dispatch(PracticeUserLogin(users.id , null, true , fromtrial));
    } else if (users.type === 4 && users.is_secure === 0) {
      history.push({
        pathname: "/setpasscode",
        state: { users },
      });

      // this.props.dispatch(PracticeUserLogin(users.id , null, true, fromtrial));
    } else {
      this.setState({ isProfileLoading: true }, () => {
        if (this.props.practiceInfo.is_super_admin == 1) {
          is_super_admin = true;
        }

        this.props.dispatch(
          PracticeUserLogin(users.id, null, false, fromtrial, is_super_admin)
        );
      });
    }
  }

  render() {
    const { loading, error } = this.props;
    const isCorporate = this.props.practiceInfo.is_corporate;

    if (this.props.history.location.fromtrial) {
      var fromtrial = this.props.history.location.fromtrial;
    }
    if (this.props.history.location.frompaymentinfo) {
      var frompaymentinfo = this.props.history.location.frompaymentinfo;
    }
    var result;
    console.log(fromtrial, "fromtrial");
    var practiceUsersList = this.props.practiceUsersList;
    if (fromtrial) {
      result = practiceUsersList
        .filter((x) => x.type != 1 && x.type != 0 && x.is_secure != 0)
        .map((users, index) => {
          return users;
        });
    } else if (isCorporate == 1) {
      result = practiceUsersList
        .filter((x) => x.type == 4)
        .map((users, index) => {
          return users;
        });
    } else {
      result = practiceUsersList.map((users, index) => {
        return users;
      });
    }

    return (
      <div
        className="profile_selection__main__screen  d-flex flex-column h-100 align-items-center justify-content-center"
        style={{ userSelect: "none" }}
      >
        <SubHeader className="mb-auto" title="Profile Sign in" />
        {loading || (this.state.isProfileLoading && <Loading />)}
        <div className="d-flex flex-column text-center profile_selection__inner__text custom-scroll text-center mb-auto w-100">
          {!fromtrial ? (
            <h1 className="mb-5 mt-0">
              Hello! Please select your profile to get started.
            </h1>
          ) : (
            <h1>
              Before you can subcribe to Thalamus,
              <br />
              please login to an authorized profile.
            </h1>
          )}
          <div className="d-flex flex-wrap row-cols-4 justify-content-center user__profile__section mx-auto">
          {!fromtrial && !isCorporate && !frompaymentinfo && (
              <div
                className="user__profile mb-5 order"
                onClick={() => this.onNewProfileClick()}
              >
                <div className="user__profile__box guest d-flex flex-column justify-content-end">
                  <div className="user__profile__picture">
                    <img
                      className="mx-auto my-auto h-auto"
                      alt=""
                      src={add_new_user_icon}
                    />
                  </div>
                  <div className="user__profile__footer mb-4  bg-none"></div>
                </div>
                <div className="user__profile__name">NEW PROFILE</div>
              </div>
            )}
            {practiceUsersList &&
              result.map((users, index) => {
                console.log(users, "users list");
                var userType = this.getUserType(users.type);
                var userRole = this.getUserRole(users?.role);
                console.log(userRole, "userRole");
                return (
                  <div
                    key={index}
                    onClick={() => this.onProfileSelect(users)}
                    className={`user__profile mb-5 ${userType[0]}`}
                  >
                    <div
                      className={`user__profile__box d-flex flex-column justify-content-end  ${userType[0]}`}
                    >
                      <div className="user__profile__picture ">
                        {users.profile_pic ? (
                          <img
                            className="mx-auto my-auto"
                            alt={users.first_name}
                            src={users.profile_pic}
                          />
                        ) : (
                          <img
                            className="mx-auto my-auto"
                            alt={users.first_name}
                            src={Profile_icon}
                          />
                        )}
                      </div>
                      {users.is_secure === 1 &&
                        <div className="user__profile__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M6.99935 25.666C6.35768 25.666 5.80818 25.4374 5.35085 24.98C4.89352 24.5227 4.66524 23.9736 4.66602 23.3327V11.666C4.66602 11.0244 4.89468 10.4748 5.35202 10.0175C5.80935 9.56018 6.35846 9.33191 6.99935 9.33268H8.16602V6.99935C8.16602 5.38546 8.73496 4.00957 9.87285 2.87168C11.0107 1.73379 12.3862 1.16524 13.9994 1.16602C15.6132 1.16602 16.9891 1.73496 18.127 2.87285C19.2649 4.01074 19.8335 5.38624 19.8327 6.99935V9.33268H20.9994C21.641 9.33268 22.1905 9.56135 22.6479 10.0187C23.1052 10.476 23.3335 11.0251 23.3327 11.666V23.3327C23.3327 23.9743 23.104 24.5239 22.6467 24.9812C22.1894 25.4385 21.6402 25.6668 20.9994 25.666H6.99935ZM13.9994 19.8327C14.641 19.8327 15.1905 19.604 15.6479 19.1467C16.1052 18.6894 16.3335 18.1402 16.3327 17.4994C16.3327 16.8577 16.104 16.3082 15.6467 15.8509C15.1894 15.3935 14.6402 15.1652 13.9994 15.166C13.3577 15.166 12.8082 15.3947 12.3509 15.852C11.8935 16.3094 11.6652 16.8585 11.666 17.4994C11.666 18.141 11.8947 18.6905 12.352 19.1479C12.8094 19.6052 13.3585 19.8335 13.9994 19.8327ZM10.4994 9.33268H17.4994V6.99935C17.4994 6.02713 17.1591 5.20074 16.4785 4.52018C15.798 3.83963 14.9716 3.49935 13.9994 3.49935C13.0271 3.49935 12.2007 3.83963 11.5202 4.52018C10.8396 5.20074 10.4994 6.02713 10.4994 6.99935V9.33268Z" fill="white"/>
                            </svg>
                        </div>
                      }
                    </div>
                    <div className={`user__profile__footer mb-4  ${userType[0]}`}>
                      {users.type === 1 ? userRole[0]?.name : userType[1]}
                    </div>
                    <div className="user__profile__name">{`${
                      users.first_name || ""
                    } ${users.last_name || ""}`}</div>
                  </div>
                );
              })}
            
          </div>
          {fromtrial && (
            <Button
              className="mx-3 btn-235 mt-5 align-self-center"
              size="md"
              onClick={() => this.props.history.push("/trial")}
              outline
              color="default white"
            >
              back
            </Button>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  practiceInfo: state.task.practiceInfo,
  practiceUsersList: state.task.practiceUsersList,
  practice_user_type: state.task.practice_user_type,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
  practice_user_roles: state.task.practice_user_roles,
});
export default compose(
  withTranslation("common"),
  connect(mapStateToProps)
)(InitialSetupIndex);
