import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import logo from '../../../img/logo.svg'; 
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setGoogleTvTestDriveMenuItem } from '../../../store/actions/taskAction';
import { history } from '../..';

const HeaderGoogleTv = ({isOperatoryRoom}) => {

  const dispatch = useDispatch();
  const states = useSelector(state => state.task);
  const location = useLocation();

  
  const redirectPage = (isPlan, redirectUrl, planName)=>{
    let plugins = states.avelablePlugin
    let selectedplan = plugins?.find(p => p.code === isPlan);
    if(selectedplan){
      history.push(redirectUrl);
    }else{
      history.push({
        pathname: '/upgrade-plan',
        selectplan: planName
      });
    }
  }

  const onClickChearside= async ()=>{
   await dispatch(setGoogleTvTestDriveMenuItem(1));
   await redirectPage("chairside" , '/google-tv-chairside-room', 'Chairside Education');
  }
  

  return (
    <div className={`${styles.goolge_tv_header}  py-5 px-5`}>
      <div className={`${styles.goolge_tv_header__inner__section} d-flex justify-content-between align-items-center`}>
        <div className=''>
            <img alt="" style={{width:'360px'}} src={logo}/>
        </div>
        <div className={`${styles.goolge_tv_header__menu_links} d-flex align-items-center`}>
          {isOperatoryRoom && 
            <>
            <NavLink className={`${styles.goolge_tv_header__menu_link} p-3 mx-4`} onClick={()=> dispatch(setGoogleTvTestDriveMenuItem(1))}  activeClassName={`${styles.goolge_tv_header__menu_link__active}`} to="/google-tv-operatory-room">Operatory Channels</NavLink>
            <Button className={`${styles.goolge_tv_header__menu_link} ${location.pathname == '/google-tv-chairside-room' ? styles.goolge_tv_header__menu_link__active : ''} p-3 mx-4`} color="link" onClick={()=>  onClickChearside() } activeClassName={`${styles.goolge_tv_header__menu_link__active}`} >Chairside Education</Button>
            </>
          }
          <NavLink className={`${styles.goolge_tv_header__menu_link} p-3 mx-4`} activeClassName={`${styles.goolge_tv_header__menu_link__active}`} to="/google-tv-test-drive">TV Location</NavLink>
          {/* <NavLink className={`${styles.goolge_tv_header__menu_link} p-3 mx-4`} activeClassName={`${styles.goolge_tv_header__menu_link__active}`} to="/google-tv-profile">Profiles</NavLink> */}
        </div>
      </div>
    </div>
  )
}

export default HeaderGoogleTv
