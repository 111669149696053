import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import './style.scss';
import Card from './Form'
import { Button} from 'reactstrap';


import { history } from '../../pages/index';
import {cleardata } from "../../store/actions/taskAction";


const onclickBack = (props) => {
  history.push("/authorized")
  props.dispatch(cleardata());
};

const HomeIndex = (props) => {
  return(
    <div className="update_admin_profile__main__screen  billing__page d-flex flex-column h-100 align-items-center ">
        {/* <SubHeader className="mb-5" title="Phone Number Update"/> */}

        {/* {props.isUpdateProfileCompleted ?
         <div className="update_admin_profile__inner update_phone_number mb-auto d-flex  flex-column  border-success border">
          <div className="update_admin_profile__inner__body d-flex align-items-center justify-content-center">
            <div className="d-block w-100">
              <div className="update_admin_profile__inner__header text-center">
                <h4>Phone number successfully updated</h4>
                <p>From now on, verification codes to restricted areas access will be sent to your new number.</p>
              </div>
            </div>
          </div>
          <div className="update_admin_profile__inner__footer mt-auto">
            <Button size="md px-0" onClick={()=> onclickBack(props)}color="primary">Back to admin profile verification</Button>
          </div>
        </div>
        : */}
        <Card />
        {/* } */}
    </div>
  ) 
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  getBillingCardInfo:state.task.getBillingCardInfo,

  isUpdateProfileCompleted:state.task.isUpdateProfileCompleted

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
