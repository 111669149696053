
import React from 'react';
import { connect } from "react-redux";
import { setDeviceCode, setSystemVideoQuality, setWaitingRoomVideoQuality } from '../../helper/LocalStorage';
import {PracticeLogin , SignOut , CheckPracticeisCorporate} from "../../store/actions/taskAction";
import Loading from '../../components/Loading'

var deviceCode;
var accessCode;
var isCorporate;
var param;
var isSuperAdmin;
var super_admin_auth_secret;
class LoginIndex extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
    };
  }
  

  async componentDidMount(){
    
    const {PracticeLogin , SignOut, CheckPracticeisCorporate} = this.props;

    const query = new URLSearchParams(this.props.location.search);
     deviceCode = query.get('q')
     accessCode = query.get('access_code')
     isSuperAdmin = query.get('is_super_admin')
     isCorporate = query.get('isCorporate')
     super_admin_auth_secret = query.get('super_admin_auth_secret')
    param = {
      device_code: deviceCode,
      access_code: accessCode,
      type:"PC",
    }
    
    

    setDeviceCode(`${deviceCode}`);
    setSystemVideoQuality(3)
    setWaitingRoomVideoQuality(720)

    // await SignOut();

    await SignOut();
    
    if(isSuperAdmin == 1){
      param['is_super_admin'] = 1
      param['super_admin_auth_secret'] = super_admin_auth_secret
    }

    if(isCorporate == 1){
      console.log(isCorporate,'isCorporate')
      CheckPracticeisCorporate(isCorporate)
      param['is_corporate'] = 1
    }

    await PracticeLogin();

    

  }

  render (){
    const {loading , error } = this.props
    if (loading) {
      return  <div className="main__screen d-flex flex-column h-100 align-items-center justify-content-center"> 
                <Loading />
              </div>
    }
    if (error) {
      return    <div className="main__screen d-flex flex-column h-100 align-items-center justify-content-center"> 
                  <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{error.message}</p> </div>
                </div>
    }
    return(
      <div className="main__screen d-flex flex-column h-100 align-items-center justify-content-center">
      </div>
    ) 
  }
}
// export default LoginIndex;
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  practiceInfo: state.task.practiceInfo,
  practiceUserLoginInfo:state.task.practiceInfo,
  isCorporate:state.task.isCorporate
});

const mapDispacthToProps = dispatch => {
  return {
    SignOut: () => dispatch(SignOut()),
    PracticeLogin: () => dispatch(PracticeLogin(param)),
    CheckPracticeisCorporate:() => dispatch(CheckPracticeisCorporate(isCorporate))
  };
};

export default connect( mapStateToProps,  mapDispacthToProps )(LoginIndex);