import React, { useEffect, useState } from "react";
import SubHeader from '../../components/Header/subHeader';
import DropDown from '../../components/DropDown'
import LoadingButton from '../../components/LoadingButton'

import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getShareVideoLink, shareVideoLink, shareVideoLinkClearData} from "../../store/actions/taskAction";
import CopyToClipboard from "react-copy-to-clipboard";
import PhoneInput from 'react-phone-input-2';

import './styles.scss';
import { history } from "..";
import { isValidEmail } from "../../data/comman";


  const SelectCategory = (props) => {
    const dispatch = useDispatch();
    const states = useSelector((state) => state.task);
    const [html, setHtml] = useState('');
    const [buttonLoading, setBtnLoading] = useState(false);
    const [buttonLoading2, setBtnLoading2] = useState(false);
    const [errors, setError] = React.useState({});
    
    const [shareVideoLinkData, setshareVideoLinkData] = useState({
		firstName:'',
        lastName:'',
        emailList: '',
        email:'',
        phone:'',
        smsfirstName:'',
        smslastName:'',
        phoneValue:''
      });

    useEffect(() => {
        dispatch(shareVideoLinkClearData());
        setHtml('')
    }, []);

    useEffect(() => {
		setHtml(states.shareVideoData.token)
	}, [states.shareVideoData]);

    const [savePlaylistMessage, setSavePlayListMessage] = useState({
		message: 'Copy URL',
		color: '#424242',
		bgColor: '#FF9F00',
	});

    
    const [shareBtn, setShareBtn] = useState({
		message: 'Share',
		bgColor: 'primary',
	});

    const [share2Btn, setShare2Btn] = useState({
		message: 'Share',
		bgColor: 'primary',
	});


    const changeColor = () => {
		setHtml(html);

		setSavePlayListMessage({
			message: 'COPIED TO CLIPBOARD',
			bgColor: 'green',
			color: 'black',
		});

        setTimeout(() => { 
            setSavePlayListMessage({
                message: 'COPY CODE',
                color: '#424242',
                bgColor: '#FF9F00',
            });
    
          }, 3000)
	};

    const codeCopied = () => {
		console.log("Copied to clipboard")
	}

    const handleChange = (field, val, isPhone) =>{
        setError({});
        setshareVideoLinkData({
        ...shareVideoLinkData,
        [field]: isPhone ? val : val.target.value
        })
    }

    const handlePhoneChange = (value, country,e, formattedValue) =>{
        var fieldName = "";
        var fieldValue = "";
        fieldName = e.target.name;
        fieldValue = value;

        fieldValue = '+'+country.dialCode+'-'+fieldValue;
        var new_str = value.replace(country.dialCode, '');
        console.log(value,'value', new_str)

        setshareVideoLinkData({
            ...shareVideoLinkData,
            [fieldName]: formattedValue,
            phoneValue:new_str
        })
      }

    const handleShare = async (isSms) => {

        var errors = {};
        var isError = false;
        var emails = [];

        if(isSms){ // send by phone
            if (shareVideoLinkData.smsfirstName.replace(/\s+/g, '').length < 4 ) {
                errors.smsfirstName = "Atleast 4 characaters required";
                isError = true;
            }
            if (shareVideoLinkData.smsfirstName.replace(/\s+/g, '') === "" ) {
                errors.smsfirstName = "The patient first name field is required.";
                isError = true;
            }
          
            // if (shareVideoLinkData.smslastName.replace(/\s+/g, '') === "" ) {
            //     errors.smslastName = "The patient last name field is required.";
            //     isError = true;
            // }
            if (shareVideoLinkData.phone === "" ) {
                errors.phone = "The patient mobile/cell phone field is required.";
                isError = true;
            } 
            else if(shareVideoLinkData.phoneValue.length < 7) {
                errors.phone = "Please enter valid patient mobile/cell phone.";
                isError = true;
            }
            await setBtnLoading2(!isError);
        }
        else { // send by email
            if (shareVideoLinkData.firstName.replace(/\s+/g, '').length < 4 ) {
                errors.firstName = "Atleast 4 characaters required";
                isError = true;
            }
            if (shareVideoLinkData.firstName.replace(/\s+/g, '') === "" ) {
                errors.firstName = "The patient first name field is required.";
                isError = true;
            }
            // if (shareVideoLinkData.lastName.replace(/\s+/g, '') === "" ) {
            //     errors.lastName = "The patient last name field is required.";
            //     isError = true;
            // }
            if (shareVideoLinkData.email === "" ) {
                errors.email = "The email field is required.";
                isError = true;
            } else if(isValidEmail(shareVideoLinkData.email)){
                errors.email = "Please enter valid email address.";
                isError = true;
            }
            if(shareVideoLinkData.emailList.trim() != ""){
                if(isValidEmail(shareVideoLinkData.emailList)){
                    errors.emailList = "Please enter valid email address.";
                    isError = true;
                } else {
                    emails.push(shareVideoLinkData.emailList);
                }
            }
            await setBtnLoading(!isError);
        }       

        if (isError) {
            setError(errors);
            return false
        }else{
            setError({})
        }

        let params ={}

        if(isSms){
            params['patient_name'] = shareVideoLinkData.smsfirstName + ' ' +  shareVideoLinkData.smslastName;
            params['phone'] = shareVideoLinkData.phone.split(" ").join("");
            params['send_by'] = "sms";
        } else {
            params['patient_name'] = shareVideoLinkData.firstName + ' ' +  shareVideoLinkData.lastName;
            params['send_by'] = "email";
            params['email'] = shareVideoLinkData.email;
        }

        if(emails.length > 0){
            params['linked_email'] = emails;
        }
            
        await  dispatch(shareVideoLink(params, states.shareVideoId))

        if(isSms){
            setShare2Btn({ message: 'Shared', bgColor: 'green' });
            await setshareVideoLinkData({ ...shareVideoLinkData, phone: '+1', smsfirstName:'', smslastName:'' });
        }else {
            setShareBtn({ message: 'Shared', bgColor: 'green' });
            await setshareVideoLinkData({ ...shareVideoLinkData, emailList: '', email:'', firstName:'', lastName:'' });
        }

        if(isSms){
            await setBtnLoading2(false);
            setTimeout(() => { 
                setShare2Btn({ message: 'Share', bgColor: 'primary' });
            }, 3000)
        }
        else {
            await setBtnLoading(false);
            setTimeout(() => { 
                setShareBtn({ message: 'Share', bgColor: 'primary' });
            }, 3000)
        }
    }
   
    return (
        <div className="main__screen  emailVideo__main__screen  d-flex flex-column ">
          <SubHeader className="mb-5" title="Share Video"/>
            <div className='emailVideo__inner_screen '>       
                <div className='d-flex justify-content-center mb-5'>
                    <div className='emailVideo__html_code_section mt-5'>
                        <div className=''>
                            <h4 className='mb-20'>Share video</h4>
                            <p className="mb-0">You can share this video with your patients. Please note that the shared links will only be available for 10 days. </p>
                        </div>
                        <FormGroup className="mb-0 mt-4">
							<Button color='link'onClick={()=> history.push({pathname:'/email-video-history'})}  className="p-0 history-link">Video Share History</Button>
                        </FormGroup>
                    </div>
                </div>         
                <div className='d-flex justify-content-between emailVideo__bottom_section'>
                    <div className='emailVideo__html_code_section email_box '>
                        <div className=''>
                            <h4 className='mb-20'>Send Email</h4>
                        </div>
                        <Row>
							<Col>
                                <FormGroup className="mb-4 mt-4">
                                    <Label>Patient first name</Label>
                                    <Input type='text' name="name" className={errors.name ? 'invalid-field' : ''} value={shareVideoLinkData.firstName} onChange={(val)=> handleChange('firstName', val) }></Input>
                                    {errors.firstName && <span className="text-danger">{errors.firstName}</span>}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-4 mt-4">
                                    <Label>Patient last name</Label>
                                    <Input type='text' name="name" className={errors.name ? 'invalid-field' : ''} value={shareVideoLinkData.lastName} onChange={(val)=> handleChange('lastName', val) }></Input>
                                    {errors.lastName && <span className="text-danger">{errors.lastName}</span>}
                                </FormGroup>
                            </Col>
                        </Row>
						<Row>
							<Col>
								<FormGroup className="mb-4">
									<Label>Patient email</Label>
									<Input type='text' name="email" className={errors.email ? 'invalid-field' : ''} value={shareVideoLinkData.email} onChange={(val)=> handleChange('email', val) }></Input>
									{errors.email && <span className="text-danger">{errors.email}</span>}
								</FormGroup>
							</Col>
							<Col>
								<FormGroup className="mb-4">
									<Label>Email cc</Label>
                                    <Input type='text' name="emailList" className={errors.emailList ? 'invalid-field' : ''} value={shareVideoLinkData.emailList} onChange={(val)=> handleChange('emailList', val) }></Input>
									{errors.emailList && <span className="text-danger">{errors.emailList}</span>}
								</FormGroup>
							</Col>
						</Row>
                       
                        <FormGroup className="mb-0 mt-4">
                            <LoadingButton type="button" color={shareBtn.bgColor} disabled={buttonLoading} loading={buttonLoading} label={shareBtn.message}  size="md" onClick={() => handleShare()} className={`mr-20`} />
                            {/* <Button color='default white' onClick={()=> history.goBack()} outline size='md'>Back</Button> */}
                        </FormGroup>
                    </div>
                    <div className='emailVideo__html_code_section sms_box'>
                        <div className=''>
                            <h4 className='mb-20'>Send SMS</h4>
                        </div>
                        <Row>
							<Col>
                                <FormGroup className="mb-4 mt-4">
                                    <Label>Patient first name</Label>
                                    <Input type='text' name="name" className={errors.smsfirstName ? 'invalid-field' : ''} value={shareVideoLinkData.smsfirstName} onChange={(val)=> handleChange('smsfirstName', val) }></Input>
                                    {errors.smsfirstName && <span className="text-danger">{errors.smsfirstName}</span>}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-4 mt-4">
                                    <Label>Patient last name</Label>
                                    <Input type='text' name="name" className={errors.smslastName ? 'invalid-field' : ''} value={shareVideoLinkData.smslastName} onChange={(val)=> handleChange('smslastName', val) }></Input>
                                    {errors.smslastName && <span className="text-danger">{errors.smslastName}</span>}
                                </FormGroup>
                            </Col>
                        </Row>
						<Row>
							<Col>
								<FormGroup className="mb-4">
									<Label>Patient mobile/cell phone</Label>
                                    {/* value={shareVideoLinkData.phone}  */}
									{/* <Input type='text' value={shareVideoLinkData.phone} onChange={(val)=> handleChange('phone', val) }></Input> */}
                                    <PhoneInput defaultMask={'- ...........'} alwaysDefaultMask={true} countryCodeEditable={false} inputProps={{ name: 'phone',required: true, }}  country={(states.practiceProfileInfo?.country?.code)?.toLowerCase() || 'ca'} onChange={(value, country, e, formattedValue) => { handlePhoneChange(value,country,e, formattedValue)}} value={shareVideoLinkData.phone}/>
									{errors.phone && <span className="text-danger">{errors.phone}</span>}
								</FormGroup>
							</Col>
							<Col>
								
							</Col>
						</Row>
                       
                        <FormGroup className="mb-0 mt-4">
                            <LoadingButton type="button" color={share2Btn.bgColor} disabled={buttonLoading2} loading={buttonLoading2} label={share2Btn.message}  size="md" onClick={() => handleShare(true)} className={`mr-20`} />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default SelectCategory
