import React from 'react'

const ImagesTabContent = () => {
    return (
        <div style={{ marginLeft:"395px" }}>
            <h5>ImagesTabContent</h5>
        </div>
    )
}

export default ImagesTabContent
