import React, { useState, useEffect } from "react";
import { Collapse , Button} from "reactstrap";
import IconsArrowDown from '../../img/IconDownArrow.svg'
function CollapsiblePanel({ children, ...props }) {
  const {title, collapse } = props;
  const [isCollapse, setIsCollapse] = useState(collapse);
  const [icon, setIcon] = useState("active_arrow");

  const toggle = () => {
    setIsCollapse(!isCollapse);
    setIcon(state => {
      return state === "active_arrow"
        ? "in_active_arrow"
        : "active_arrow";
    });
  };

  const animate = collapse => {
    setIsCollapse(collapse);
    setIcon(state => {
      return state === "active_arrow"
        ? "in_active_arrow"
        : "active_arrow";
    });
  };

  useEffect(() => {
    animate(!collapse);
  }, [collapse]);

  return (
    <div className="d-flex justify-content-between w-100 align-items-top align-items-center">
      <Collapse className=""  isOpen={isCollapse}>
        {children}
      </Collapse>
      {title.length > 98 && 
      <div className="pr-3">
        <Button color="link" onClick={() => toggle()} className={`down-arrow p-0 ${icon}` } ><img src={IconsArrowDown} alt="" /></Button>
      </div>
    }
    </div>
  );
}

CollapsiblePanel.defaultProps = {
  collapse: true
};

export default CollapsiblePanel;
