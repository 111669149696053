import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import './style.scss';
import PlayIcon from '../../img/play_icon_larg.svg'
import { history } from '../../pages';

const VideoSliderList = () => {
    const states = useSelector((state) => state.task);

  return (
    <div className="chairside_education_playlist--video__lists mt-3 mt-lg-4 mt-xl-5">
        <div className="d-flex video__lists justify-content-between">
        <Swiper
            spaceBetween={24}
            slidesPerView={4}
            navigation
            // id={`video-slider${nextEnable}`}
            // onSlideChange={(e) => onSliderChange(e)}
            >
                {
                    states.channelTimeLine  && states.channelTimeLine.length > 0 ? states.channelTimeLine.map((lists, index) => {
                        return (
                            <SwiperSlide data-hash={index} key={lists.id}>
                                <div key={index} className="chairside_education_playlist__video_box">
                                    <img className="w-100" src={lists.media_data?.chairside_media?.thumb} alt="" />
                                    <h2>{lists?.media_data?.title}</h2>
                                </div>
                            </SwiperSlide>
                        )
                    }) 
                    :
                    <SwiperSlide>
                        <div className="placeholder__box mt-0 mb-0 d-flex align-items-center justify-content-center flex-column" onClick={() => history.push("/chairside-edit-playlist")}>
                            <div className="text-center">
                                <img className="mb-4" src={PlayIcon} alt=""/>
                                <p style={{fontSize:'14px' , color:'#C9C9C9'}} className="mb-0">Click “Edit Playlist” on the<br/> left to add videos here.</p>
                            </div>
                        </div>
                    </SwiperSlide>
                }
        </Swiper>
        </div>
    </div>
  )
}

export default VideoSliderList
