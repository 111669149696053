import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import React, { useState } from "react";
import { Row, Col, Form, Input, Button } from "reactstrap";
import "./styles.scss";

const twentyFourHours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
const minutes = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 21,
    height: 13,
    padding: 0,
    top: 14,
    left: 100,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 10.5,
    height: 11.5,
    marginLeft: -7,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const TimePicker = (props) => {
  const [state, setState] = useState({
    checkedB: true,
  });
  // React.useEffect(() => {}, props.weekstatus);

  return (
    <div className="Page8__main">
      {/* before time  */}
      <div className="Page8__main__picker">
        <div className="Page8__main__headingDivWrapper">
          <div className="Page8__main__headingDivWrapper__rightTxtDiv">
            {props.day}
          </div>
          <div className="Page8__main__headingDivWrapper__leftTxtDiv">
            Working hours
          </div>
          <FormControlLabel
            className="toggle-button "
            control={
              <IOSSwitch
                checked={props.check}
                onChange={(e) =>
                  props.handleChange("switch", props.day_week, e)
                }
                name={"checkCli"}
                id={props.id}
              />
            }
          />
        </div>

        <div className="Page8__main__upperDivWrapper">
          <label>From</label>
          <div className="Page8__main__upperDivWrapper__holdingDiv">
            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Hr.</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="fromHour"
                  value={props?.working?.fromHour}
                  style={{ width: "120%", minWidth: "0" }}
                  onChange={(e) =>
                    props.handleChange("working", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Hr.
                  </option>
                  {twentyFourHours.map((twentyFourHour, index) => {
                    return (
                      <option value={twentyFourHour} key={index}>
                        {twentyFourHour}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Min</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="fromMinute"
                  value={props?.working?.fromMinute}
                  style={{ width: "120%", minWidth: "0" }}
                  onChange={(e) =>
                    props.handleChange("working", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Min.
                  </option>
                  {minutes.map((minute, index) => {
                    return (
                      <option value={minute} key={index}>
                        {minute}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>AM / PM</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="fromTimeFormat"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.working?.fromTimeFormat}
                  onChange={(e) =>
                    props.handleChange("working", props.day_week, e)
                  }
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Input>
              </div>
            </div>
          </div>
        </div>

        <div className="Page8__main__lowerDivWrapper">
          <label>To</label>
          <div className="Page8__main__lowerDivWrapper__holdingDiv">
            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Hr.</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="toHour"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.working?.toHour}
                  onChange={(e) =>
                    props.handleChange("working", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Hr.
                  </option>
                  {twentyFourHours.map((twentyFourHour, index) => {
                    return (
                      <option value={twentyFourHour} key={index}>
                        {twentyFourHour}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Min</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="toMinute"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.working?.toMinute}
                  onChange={(e) =>
                    props.handleChange("working", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Min.
                  </option>
                  {minutes.map((minute, index) => {
                    return (
                      <option value={minute} key={index}>
                        {minute}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>AM / PM</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="toTimeFormat"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.working?.toTimeFormat}
                  onChange={(e) =>
                    props.handleChange("working", props.day_week, e)
                  }
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Input>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  after time  */}
      <div className="Page8__main__picker">
        <div className="Page8__main__headingDivWrapper">
          <div className="Page8__main__headingDivWrapper__rightTxtDiv">
          </div>
          <div className="Page8__main__headingDivWrapper__leftTxtDiv">
            After hours
          </div>
          <FormControlLabel
            className="toggle-button"
            control={
              <IOSSwitch
                checked={props.after_check}
                onChange={(e) =>
                  props.handleChange("switch", props.day_week, e)
                }
                name="AftercheckCli"
                id={props.id}
              />
            }
          />
        </div>

        <div className="Page8__main__upperDivWrapper">
          <label>From</label>
          <div className="Page8__main__upperDivWrapper__holdingDiv">
            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Hr.</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="fromHour"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.after?.fromHour}
                  onChange={(e) =>
                    props.handleChange("after", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Hr.
                  </option>
                  {twentyFourHours.map((twentyFourHour, index) => {
                    return (
                      <option value={twentyFourHour} key={index}>
                        {twentyFourHour}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Min</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="fromMinute"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.after?.fromMinute}
                  onChange={(e) =>
                    props.handleChange("after", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Min.
                  </option>
                  {minutes.map((minute, index) => {
                    return (
                      <option value={minute} key={index}>
                        {minute}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>AM / PM</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="fromTimeFormat"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.after?.fromTimeFormat}
                  onChange={(e) =>
                    props.handleChange("after", props.day_week, e)
                  }
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Input>
              </div>
            </div>
          </div>
        </div>

        <div className="Page8__main__lowerDivWrapper">
          <label>To</label>
          <div className="Page8__main__lowerDivWrapper__holdingDiv">
            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Hr.</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="toHour"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.after?.toHour}
                  onChange={(e) =>
                    props.handleChange("after", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Hr.
                  </option>
                  {twentyFourHours.map((twentyFourHour, index) => {
                    return (
                      <option value={twentyFourHour} key={index}>
                        {twentyFourHour}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>Min</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="toMinute"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.after?.toMinute}
                  onChange={(e) =>
                    props.handleChange("after", props.day_week, e)
                  }
                >
                  <option selected disabled>
                    Min.
                  </option>
                  {minutes.map((minute, index) => {
                    return (
                      <option value={minute} key={index}>
                        {minute}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="Page8__main__upperDivWrapper__inputDiv">
              <label>AM / PM</label>
              <div className="Page8__main__upperDivWrapper__inputDiv__input2">
                <Input
                  type="select"
                  name="toTimeFormat"
                  style={{ width: "120%", minWidth: "0" }}
                  value={props?.after?.toTimeFormat}
                  onChange={(e) =>
                    props.handleChange("after", props.day_week, e)
                  }
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TimePicker;
