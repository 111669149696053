import React, { useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Label, FormGroup} from "reactstrap";
import "./styles.scss";
import { InputField } from "../../components/InputField";
import DropDown from '../../components/DropDown';
import LoadingButton from '../../components/LoadingButton';

const publishedDrd = [{label: 'Publish immediately', value: 1}, {label: 'Schedule a publishing date', value: 2}, {label: 'Don’t publish yet', value: 0 }];

const CreateNewWaitingRoom = ({ state, setState, onAddChannel, onTimeLine, onQuickPreview, error, isOperatoryRoom }) => {
  const states = useSelector((state) => state.task);

  const [saveMessage, setSaveMessage] = useState({
        message: "ADD CHANNEL",
        color: "black",
        bgColor: "white",
    });

  const changeColor = () =>{
    setSaveMessage({
        message: "ADD CHANNEL",
        bgColor: "rgb(255, 159, 0)",
        color: "black",
    });
  } 

  return (
    <>
        <div className="transparent__form--dark">
            <h3>Name and customize the new channel</h3>
            <p> Once published, all Thalamus users will have access to this channel. </p>
            <Row >
                <Col lg='6'>
                <FormGroup className="mb-4">
                    <InputField type="text" label={`${isOperatoryRoom ? 'Operatory' : 'Waiting'} Room channel's name`} name="email" value={state.channelName} onChange={(e) => setState('channelName', e.target.value)} isError={error.channelName || (states.error ? states.error.title : '') } className="" />
                </FormGroup>
                </Col>
                <Col lg='6'>
                <FormGroup className="mb-4">
                    <fieldset disabled={true} >
                    <Label>Category</Label>
                    <DropDown
                        className='react-select-container mxh-200'
                        classNamePrefix="react-select"
                        // options={this.props.type == "operatory" ? this.props.operatoryRoomMyVideos : this.props.myVideosTimeline}
                        isSearchable={false}
                        getOptionLabel={x => `${x.categories_name}`}
                        getOptionValue={x => `${x.categories_id} ${x.categories_name}`}
                        placeholder={''}
                        // value={this.state.selectedCategory}
                        // onChange={(option) => { this.onCategorySelect(option) }}
                    />
                    </fieldset>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
            <Col lg='12'>
                <FormGroup className="mb-4">
                <InputField type="textarea" label={"Description"} name="email" value={state.description} onChange={(e) => { setState("description", e.target.value)}} isError={error.description} className="mb-3" />
                </FormGroup>
            </Col>
            </Row>
            <hr className="new_room__hr" ></hr>
            <Row>
                <Col lg='6'>
                <FormGroup className="mb-4">
                    <fieldset >
                    <Label>Channel’s publishing date</Label>
                    <span className='text--danger'>{error.selectedPublish}</span>
                    <DropDown
                        className='react-select-container mxh-200'
                        classNamePrefix="react-select"
                        options={publishedDrd}
                        isSearchable={false}
                        placeholder={''}
                        value={state.selectedPublish}
                        onChange={(option) => {setState("selectedPublish", option) }}
                    />
                    </fieldset>
                    </FormGroup>
                </Col>
                <Col lg='6'>
                <FormGroup>
                    <fieldset disabled={state.selectedPublish && state.selectedPublish.value == 2 ? false : true}>
                    <Label>Schedule a publishing date</Label>
                    <span className='text--danger'>{error.selectedScheduleDate}</span>
                    <DatePicker className="form-control custom-datepicker form-control mb-2"
                        selected={state.selectedScheduleDate}
                        onChange={(date) => setState("selectedScheduleDate", date)}
                        dateFormat="MMMM d, yyyy"
                        minDate={new Date()}
                        disabled={state.selectedPublish && state.selectedPublish.value == 2 ? false : true}
                    />
                    </fieldset >
                </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg='12'>
                    <label style={{marginTop:"0px", fontSize:"14px",marginTop:"25px", marginBottom:"10px" }}><span style={{ color:"red" }}>*</span> A timeline must be created before the channel can be added.</label>
                </Col>
            </Row>
            <Row>
                <Col lg='4'>
                    <LoadingButton type="button" label={saveMessage.message}  className="w-100" loading={states.buttonLoading} disabled={states.buttonLoading} onClick={onAddChannel}  color="primary"/>
                </Col>
                {/* <Col lg='4'>
                    <Button color="white" className="w-100">TIMELINE</Button>
                </Col> */}
                <Col lg='4'>
                    {/* <Button color="white" className="w-100">QUICK PREVIEW</Button> */}
                    {/* <LoadingButton type="button" label="QUICK PREVIEW"  className="w-100" disabled={states.buttonLoading} onClick={onAddChannel} color="white"/> */}
                </Col>
            </Row>
        </div>
    </>
  );
}

export default CreateNewWaitingRoom
