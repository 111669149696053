import React, {useState, useEffect} from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { Button, Input , FormGroup ,Label , Form} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { InputField} from '../../components/InputField';
import LoadingButton from '../../components/LoadingButton'
import {ReactComponent as UserIcon} from '../../img/help/user_icon.svg';
import {ReactComponent as EmailIcon} from '../../img/help/email.svg';
import QuestionIcon from '../../img/help/Object.png';
import {ReactComponent as PlanIcon} from '../../img/help/send_plan.svg';
import DropDown from '../../components/DropDown'
import {reason} from '../../data/comman'
import { postPracticeContactUs} from "../../store/actions/taskAction";

import './style.scss';


const ContactUs =()=> {
    const [name, setname] = useState('');
    const [email, setEmail] = useState('');
    const [reasonfield, setReason] = useState('');
    const [isSuccessSend, setSuccessSend] = useState(false);

    let states = useSelector((state) => state.task);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        contact_name: '',
        email: '',
        reasonfield: null,
        message: null
    });

    const [error, setError] = useState({
        error_name: '',
        error_email: '',
        error_message: null,
        error_reason: null,
        isError: false,
    })

    const resetError = () => {
        setError({
            error_name: '',
            error_email: '',
            error_message: null,
            error_reason: null,
            isError: false,
        })
    }

    const resetField = () => {
        setState({
            contact_name: '',
            email: '',
            reasonfield: null,
            message: ''
        })
    }

    const setStateValue = (field, value) => {
        console.log(state,'response')
        setState({
          ...state,
          [field]: value,
        })
        resetError();
      }

    const resetFieldCallback = (value) =>{
        
        if(value){
            setSuccessSend(value)
            resetField()
        }else{
            setSuccessSend(value)
        }
       
    }

  var emailValid;
    const  sendEmail = async ()=>{
        var error_name = "", error_email = "", error_message = "", error_reason = "", isError = false;
        
        if(!state.contact_name){
            error_name = 'Please enter name';
            isError = true;
        }
        if(!state.email){
            error_email = 'Please enter email';
            
            isError = true;
        }
        if(state.email){
            emailValid = state.email ? state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) : '';
            
            if(!emailValid){
                error_email =  "Wrong format";
                isError = true;
            }
        }
        if(!state.reasonfield){
            error_reason = 'Please select reason';
            isError = true;
        }
        if(!state.message){
            error_message = 'Please enter message';
            isError = true;
        }

        if(isError == true){
            setError({
                error_name,
                error_email,
                error_reason,
                error_message,
                isError: isError
            })
            return;
        }


        let params = {
            name:state.contact_name,
            email:state.email,
            message:state.message,
            reason:state.reasonfield.value
        }

        dispatch(postPracticeContactUs(params,resetFieldCallback))

       

        setTimeout(()=>{
            resetError();
        }, 2000)

    }

    return(
      <>
      <div className="contact__main__screen  d-flex flex-column  align-items-start h-100">
        <SubHeader className="" title='Contact Us'/>
        <div className="contact__inner__screen h-100 d-flex flex-column">
            {isSuccessSend ? 
            <>
                <div className="contact__content my-auto d-flex flex-column align-items-center justify-content-center ">
                    <h1 className="my-5">Thank you!</h1>
                    <p className="mb-5 text-center fs-20">We have received your message. <br/>
                    We will reach you out immediately!</p>
                    <Button onClick={() => { resetFieldCallback(false) }} className="mt-4 mb-5" size="md" color="default white" outline>Back</Button>
                </div>
            </>
            :
            <>
            <div className="contact__header d-flex align-items-center">
                <FormGroup className="m-0 border--right pr-5 mr-5">
                    <Label>Practice Name:</Label>
                    <p className="m-0">{states.practiceProfileInfo.name}</p>
                </FormGroup>
                <FormGroup className="m-0">
                    <Label>Account Number:</Label>
                    <p className="m-0">{states.practiceProfileInfo.account_code}</p>
                </FormGroup>
            </div>
            <div className="contact__content ">
                <h1>We’d like to hear from you!</h1>
                <p className="mb-5">Send us a message and we will get back to you soon.</p>
                <Form className="d-flex flex-column">
                    <FormGroup className="mb-20">
                        <span style={{display: 'inline-block'}}>
                            <div className={`d-flex justify-content-between `}>
                                <Label className="d-flex align-items-center mb-3"><UserIcon className="custome_icon mr-3"/> Contact Name:</Label>
                                {error.isError && <span className='text--danger'>{error.error_name}</span>}
                            </div>
                            <div className="inputBox">
                                <Input type="text" name="contact_name" value={state.contact_name} onChange={(e) => { setStateValue('contact_name',e.target.value); }}></Input>
                                <span></span>
                            </div>
                        </span>
                    </FormGroup>
                    <FormGroup className="mb-20">
                        <span style={{display: 'inline-block'}}>
                            <div className={`d-flex justify-content-between `}>
                                <Label className="d-flex align-items-center mb-3"><EmailIcon className="custome_icon mr-3"/>Email:</Label>
                                {error.isError && <span className='text--danger'>{error.error_email}</span>}
                            </div>
                            <div className="inputBox">
                                <Input type="text" name="email" value={state.email} onChange={(e) => { setStateValue('email',e.target.value); }}></Input>
                                <span></span>
                            </div>
                        </span>
                    </FormGroup>
                    <FormGroup className="mb-20">
                        <span style={{display: 'inline-block'}} className="col-6 p-0">
                            <div className={`d-flex justify-content-between `}>
                                <Label className="d-flex align-items-center mb-3"><img src={QuestionIcon} className="custome_icon mr-3" alt=""/>Reason:</Label>
                                {error.isError && <span className='text--danger'>{error.error_reason}</span>}
                            </div>
                            <DropDown
                                className='react-select-container w-100'
                                classNamePrefix="react-select"
                                getOptionLabel={x => `${x.label}`}
                                getOptionValue={x => `${x.value} ${x.label}`}
                                options={reason}
                                onChange={(e) => { setStateValue('reasonfield',e); }}
                                // menuIsOpen={true}
                                isSearchable={false}
                                placeholder={'Please choose your subject'}
                                value={state.reasonfield}
                            />
                        </span>
                    </FormGroup>
                    <FormGroup className="mb-20">
                    <span style={{display: 'inline-block'}} className="col-6 p-0">
                        <div className={`d-flex justify-content-between `}>
                            <Label className="d-flex align-items-center mb-3"><PlanIcon className="custome_icon mr-3"/>Message:</Label>
                            {error.isError && <span className='text--danger'>{error.error_message}</span>}
                        </div>
                        <Input type="textarea" name="message" value={state.message} onChange={(e) => { setStateValue('message',e.target.value); }}></Input>
                    </span>
                    </FormGroup>
                    <LoadingButton type="button"  disabled={states.buttonLoading} loading={states.buttonLoading} color="primary" onClick={() => { sendEmail() }} className="mx-auto mb-3" size="sm">Submit</LoadingButton>
                    <p className="text-center">* Have an urgent request, please contact us at <span className="text--primary">1 (844) 484-2526.</span></p>
                </Form>
            </div>
            </>
            }
        </div>
      </div>
      </>
    );

};
export default ContactUs;
