import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import VerifyIdentityBox from '../../components/VerifyIdentityBox'
import Card from './Form'
import './style.scss';

const HomeIndex = (props) => {
  console.log(props,'asdaaaaaa')
  return(
    <div className="billing__main__screen billing__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Billing"/>
        <VerifyIdentityBox pageUrl={`/billing-card`}/>
        {/* {props.error && 
          <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{props.error.message}</p> </div>
        } */}
    </div>
  ) 
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
