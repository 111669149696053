import React from 'react';

import './style.scss';
import { Button , Label , FormGroup , NavLink, Spinner} from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import LoadingButton from '../../components/LoadingButton'

import {sendRecoverCode , clearerror , resetSuccess} from "../../store/actions/taskAction";
import BillingProfileImg from '../../img/BillingProfileAvtar.png'
import { history } from '../../pages/index';
import { SIGNIN } from '../../utilities/HttpUtility';
import { SEND_PRACTICE_RECOVERY_CODE_FAIL } from '../../store/actions/actions';

class mobileEntry extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isSave:false,
      isValid:true,
      phone:'',
      formattedValue:'',
      seconds: 59,
      isGetCode:false,
      successSend:false,
      selectedPractice: {},
    };
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    await this.props.dispatch(clearerror());
    clearInterval(this.myInterval)
    this.props.dispatch(resetSuccess());
    this.setState({ formValid:false, selectedPractice:{}})

  }

  
  handlePhoneChange(value, country, e, formattedValue ){
    var  fieldValue = value;
    fieldValue = fieldValue.replace(country.dialCode,'');    // 345
    fieldValue = '+'+country.dialCode+'-'+ fieldValue;
    let isError = { ...this.state.isError };
    this.setState({
        isError,
        phone: fieldValue,
        formattedValue:formattedValue
    },()=> {this.isValid()})

    this.props.dispatch(clearerror());
  }

  isValid(){
    var {phone} = this.state.phone
    if( phone !== ''){
      this.setState({ isSave: true});
    }else{
      this.setState({ isSave: false });
    }
  }

  handleClick(){
    this.props.handleClick()
  }

  resend(){
    this.myInterval = setInterval(() => {
        const { seconds } = this.state

        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1,
            isGetCode: false
          }))
        }else{
          this.setState({ isGetCode: true })
        }
          if(this.seconds === 0){
            clearInterval(this.myInterval)
          }
        
      }, 1000)
  }

  onBack(){
    this.props.dispatch(clearerror());
  }

  onSend(practiceId){
    if(this.state.selectedPractice.id) return;
    else{
      var selectedPractice = {};
      selectedPractice.id = practiceId;
      this.setState({ selectedPractice })
    } 

    let params = {
      type:'sms',
      phone:this.state.phone,
      // email:null
    }
    if(practiceId)  
      params['practice_id'] = practiceId;
     
    this.props.dispatch(sendRecoverCode(params));
    if(practiceId) { 
        this.setState({seconds:60})
        this.resend()
    }
  }

 async onReSend(){
    clearInterval(this.myInterval)
    // this.props.dispatch(resetSuccess());
    // this.setState({ formValid:false, selectedPractice:{}})
    // await this.resend()

    
    let params = {
      type:'sms',
      phone:this.state.phone,
      // email:null
    }
      params['practice_id'] = this.state.selectedPractice.id;

    this.props.dispatch(sendRecoverCode(params));
    this.setState({seconds:60})
    this.resend()
  }

  onBackToSignin(){
    history.push('/profileselection'); 
  }

  getUserType(id){
    switch(id){
        case 0: 
            return  ["guest", "Guest"];
        case 1:
            return  ["dentist", "Normal User"];
        case 2:
            return  ['authorized_user', "Authorized User"]; 
        case 3:
            return    ['manager', "Manager"]; 
        case 4:
            return    ['owner', "Owner"]; 
        default:
          return    ['guest', "Guest"];
    }
  }

  render (){
    const {t , loadingRecoveryCode , error , sendRecoverCode, practiceSelected } = this.props
    const { selectedPractice } = this.state;
    if(sendRecoverCode){
      var userType = this.getUserType(sendRecoverCode.type )
     }
    return( 
      <>
        {error && !error.message ?
          <>
          {!this.props.successSend ?
          <div className="justify-content-center">
          <div className="d-flex flex-column  forgot_access_code__inner__enter_info justify-content-center">
              <h4 className="mb-5">{t('forgotAccessCode.mobile.title1')}</h4>
              <FormGroup className={`${error ?  'inputerror' : ''} mb-5`}>
                  <Label>{t('form.phone')}</Label>
                  <PhoneInput placeholder="(000) 000-0000" countryCodeEditable={false} onChange={(value, country, e, formattedValue) => { this.handlePhoneChange(value, country, e, formattedValue)}} name="phone" country={'ca'}   />
              </FormGroup>
              <div className="d-flex  justify-content-between pt-3">
                  <LoadingButton type="button"  label2={t('btn.send')} label={t('btn.send')} loading={loadingRecoveryCode}  disabled={!this.state.isSave || this.props.loadingRecoveryCode} size="sm" onClick={() => this.onSend()}  className={`mr-3`} color="primary"/>
                  <Button className="ml-3" size="sm" onClick={() => this.handleClick()}  color="danger">{t('btn.cancel')}</Button>
              </div>
          </div>
          <div style={{marginTop:10,textAlign:" center"}} className="pt-3">
                <Button className="" size="sm" outline onClick={() => this.handleClick()} color="default white">{t('btn.back')}</Button>
              </div>
          </div>
          :
          (practiceSelected ? 
            <>
              <div className="practice_list">
              <div className="row">
                  <div className="col-7 mb-5">
                      <h4>Select one of the practice to procced.</h4>
                  </div>
              </div>
              <table className="table" >
                  <tbody>
                  {sendRecoverCode.length>0&&sendRecoverCode.map((p)=>{
                    return <tr>
                    <td>{p.name}</td>
                    <td style={{width:"20%"}}><Button size="md" color="primary" onClick={()=> this.onSend(p.id) }>Select {selectedPractice.id === p.id && <Spinner className="" size="md" color="dark" /> }</Button></td>
                  </tr>
                  })}

                  </tbody>
                </table>

                {/* <div className="invoice__inner__footer d-flex justify-content-center align-items-center">
                  <ul>
                  {[...Array(this.state.totelPage)].map((page, i) =>
                    <li key={i}><Button type="button" className={`p-3 ${i + 1 === this.state.currentPage ? 'activepage' : null}`} onClick={e => this.handlechangePage(e, i)} size="md" color="link">{i + 1}</Button></li>
                    )}
                    </ul>
                  </div> */}
              </div>
                  <Button className="mx-auto mt-4" size="md" outline onClick={() => {this.props.dispatch(resetSuccess())}} color="default white">{t('btn.back')}</Button>
            </>
          :
          sendRecoverCode.profile_pic?
          <div className="d-flex flex-column align-items-center justify-content-center">
              <div className={`user__profile  mx-auto mb-5 ${userType[0]}`} style={{order:0}}>
                <p className="text-center mb-3">{sendRecoverCode.practice_name}</p>
                <div className={`user__profile__box d-flex flex-column justify-content-end mb-3 ${userType[0]}`}>
                  <div className="user__profile__picture">
                  { sendRecoverCode.profile_pic ?  
                    <img className="mx-auto my-auto" alt={sendRecoverCode.first_name} src={ sendRecoverCode.profile_pic}/>
                    :
                    <img className="mx-auto my-auto" alt={sendRecoverCode.first_name} src={BillingProfileImg}/> 
                  }
                  </div>
                  <div className={`user__profile__footer py-2  text-center ${userType[0]}`}>
                    {userType[1]}
                  </div>
                </div>
                
                <div className="user__profile__name text-center">{`${sendRecoverCode.first_name||''} ${sendRecoverCode.last_name||''}`}</div>
              </div>
            <h1>Access code sent</h1>
            <p className="fs-20">{`${sendRecoverCode.first_name} ${sendRecoverCode.last_name || ''}`} will receive an SMS containing the access code to your practice.</p>
            <FormGroup className="d-flex flex-column align-items-center justify-content-center">
              <Label><span>No email received? <Button type="button"  style={{lineHeight:'1',height:'auto'}} onClick={() => this.onReSend()} disabled={!this.state.isGetCode}  color="link primary" className="p-0 ml-2">Click here to resend  { `(${this.state.seconds})` + 's'}</Button></span></Label>
              {/* <Button type="button"  onClick={() => this.onBackToSignin()} size="xl" className="mt-2 " color="primary">Back to sign in</Button> */}
              <NavLink className="btn btn-primary mt-2" href={SIGNIN}>Back to sign in page</NavLink>
            </FormGroup>
          </div>
          :
          <Spinner className="" size="md" color="white" /> 
          )}
        </>
        :
        <div className="d-flex flex-column  mw-680 text-center">
          <h1 className="mb-4">{t('forgotAccessCode.mobile.title2')}</h1>
          <p className="fs-20 mb-5">{t('forgotAccessCode.mobile.detail.part1')} {this.state.formattedValue} {t('forgotAccessCode.mobile.detail.part2')}</p>
          <Button className="mx-auto mt-4" size="md" outline onClick={() => this.props.dispatch(clearerror())} color="default white">{t('btn.back')}</Button>
        </div>
        }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  error:state.task.error,
  loadingRecoveryCode:state.task.loadingRecoveryCode,
  successSend:state.task.successSend,
  sendRecoverCode:state.task.sendRecoverCode,
  practiceSelected: state.task.practiceSelected,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(mobileEntry);