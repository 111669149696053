import Home from '../components/SidebarMenuItems/Home';
import NewChannels from '../components/SidebarMenuItems/NewChannels';
import MyLists from '../components/SidebarMenuItems/MyLists';
import AllChannelsList from '../components/SidebarMenuItems/AllChannels';
import MyChannelsList from '../components/SidebarMenuItems/MyChannels';
import SearchScreen from '../components/SidebarMenuItems/Search';
import SpecializationsList from '../components/SidebarMenuItems/Specializations';
import Playlists from '../components/SidebarMenuItems/Playlists';


import {ReactComponent as SearchIcon} from '../images/ic_search.svg';
import {ReactComponent as HomeIcon} from '../images/ic_home.svg';
import {ReactComponent as VideoLibraryIcon} from '../images/ic_new_channels.svg';
import {ReactComponent as AddIcon} from '../images/ic_my_list.svg';
import {ReactComponent as ImageIcon} from '../images/ic_cetegory.svg';
import {ReactComponent as AllChannels} from '../images/ic_all_channel.svg';
import {ReactComponent as Mychannels} from '../images/ic_My_channels.svg';
import {ReactComponent as Settings} from '../images/ic_setting.svg';
import {ReactComponent as Videorequest} from '../images/ic_Video_request.svg';
import {ReactComponent as Specializations} from '../images/ic_specializations.svg';
import {ReactComponent as MyProfile} from '../images/ic_my_profile.svg';
import {ReactComponent as PlayListIcon} from '../../../img/playListIcon.svg';


export const tabList = [
  {
    name : 'Search',
    icon : <SearchIcon/>
  },
  {
    name : 'Home',
    icon :  <HomeIcon  />
  },
  {
    name : 'New videos',
    icon :  <VideoLibraryIcon  />
  },
  {
    name : 'My List',
    icon :  <AddIcon />
  },
  {
    name : 'Playlists',
    icon :  <PlayListIcon />
  },
  {
    name : 'Specializations',
    icon : <Specializations />
  },
  {
    name : 'My profile',
    icon : <MyProfile />
  },
  {
    name : 'Settings',
    icon : <Settings />
  },
  // {
  //   name : 'Video request',
  //   icon : <Videorequest />
  // },
]

export const tabItems = [
  {
    name : 'Search',
    component : <SearchScreen isChairside={true} />
  },
  {
    name : 'Home',
    component : <Home isChairside={true}/>
  },
  {
    name : 'New Releases',
    component :<NewChannels isChairside={true} />
  },
  {
    name : 'My List',
    component :<MyLists isChairside={true}/>
  },
  {
    name : 'Playlists',
    component :<Playlists  isPlaylists={true}/>
  },
  {
    name : 'Specializations',
    component :<SpecializationsList isChairside={true} />
  },
  {
    name : 'My profile',
    component :<MyChannelsList isChairside={true}/>
  },
]
