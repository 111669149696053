import React from 'react';

import './style.scss';
import mastercard from '../../img/mastercard.svg'
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import {GetPracticePaymentinfo } from "../../store/actions/taskAction";
import { getCartImageByType } from '../../data/comman';
import Loading from '../../components/Loading'
import { history } from '../../pages/index';

class CreditCardDeclined extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        primerCreditCard:{},
        secondaryCreditCard:{}
    };
  }

  async componentDidMount(){
    await this.props.dispatch(GetPracticePaymentinfo());
    if(this.props.getPracticePaymentinfo && this.props.getPracticePaymentinfo.cards  && this.props.getPracticePaymentinfo.cards.length > 0){
        var primerCreditCard = this.props.getPracticePaymentinfo.cards.find(x => x.is_primary === 1);
        var secondaryCreditCard = this.props.getPracticePaymentinfo.cards.find(x => x.is_primary === 0);
        if(primerCreditCard){
            this.setState({ 
                primerCreditCard,
            });
        }

        if(secondaryCreditCard){
            this.setState({ 
                secondaryCreditCard,
            });
        }

    }
  }

  render (){
    const {t , loadingBillingCardInfo} = this.props
    return( <>
        {/* {loadingBillingCardInfo ?
            <Loading/>
        : */}
            <div className="transparent__form--white d-flex flex-column align-items-stretch mb-auto">
                {loadingBillingCardInfo ?
                    <Loading/>
                :
                <>
                <h4 className="mb-20">Credit card declined</h4>
                <p>Your invoice is past due, and your credit card has been declined. <br/>For payment, please select one of the following options.</p>
                <div className="credit_card_declined__outsdanding__balance">
                    <h1>${this.props.getPracticePaymentinfo.outstanding_balance}</h1>
                    <span>Outsdanding balance</span>
                </div>
                <div className={`${this.state.primerCreditCard.declined_at ? 'invalid-field' : ''} credit_card_declined__box `}>
                    {this.state.primerCreditCard.declined_at &&<span className="error">Declined</span> }
                    <div className="d-flex justify-content-between align-items-center pb-3">
                        <div className="credit_card_declined__img">
                            <img src={getCartImageByType(this.state.primerCreditCard.brand)} className="" alt="" />
                        </div>
                        <div className="credit_card_declined__info d-flex">
                            <div className="credit_card_declined__info__name px-4 border--right">
                                {this.state.primerCreditCard.name}
                            </div>
                            <div className="credit_card_declined__info__name__number pl-4">{`XXXX XXXX XXXX ${this.state.primerCreditCard.last4}`}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between pt-3">
                        <Button type="button" onClick={()=>history.push({pathname: '/payment-info', state: { isReplace:true}})} className="mr-3" size="" color="primary col">{t('btn.replaceCard')}</Button>
                        <Button type="button" onClick={()=>history.push({pathname: '/payment-info', state: { isUpdate:true}})} className="ml-3" outline size="" color="default white col">{t('btn.updateCard')}</Button>
                    </div>
                </div>
                {this.state.secondaryCreditCard.id ?
                    <div className={`${this.state.secondaryCreditCard.declined_at ? 'invalid-field' : ''} credit_card_declined__box `}>
                        {this.state.secondaryCreditCard.declined_at && <span className="error">Declined</span> }
                        <div className="d-flex justify-content-between align-items-center pb-3">
                            <div className="credit_card_declined__img">
                                <img src={getCartImageByType(this.state.secondaryCreditCard.brand)} className="" alt="" />
                            </div>
                            <div className="credit_card_declined__info d-flex">
                                <div className="credit_card_declined__info__name px-4 border--right">
                                    {this.state.secondaryCreditCard.name}
                                </div>
                                <div className="credit_card_declined__info__name__number pl-4">{`XXXX XXXX XXXX ${this.state.secondaryCreditCard.last4}`}</div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                            <Button type="button" onClick={()=>history.push({pathname: '/payment-info', state: { isReplace:true}})} className="mr-3" size="" color="primary col">{t('btn.replaceCard')}</Button>
                            <Button type="button" onClick={()=>history.push({pathname: '/payment-info', state: { isUpdate:true}})} className="ml-3" outline size="" color="default white col">{t('btn.updateCard')}</Button>
                        </div>
                    </div>
                :
                <div className="credit_card_declined__box  py-20">
                        <Button type="button" onClick={()=>history.push({pathname: '/payment-info', state: { isReplace:true}})} className="mr-3" size="" color="primary col">Add a secondary credit card</Button>
                </div>
                }
                <div className="credit_card_declined__box mb-0 py-20">
                        <Button className="mr-3" size="" onClick={()=>history.push({pathname: '/one-time-payment', state: { isOneTime:true}})} color="primary col">{t('btn.onetimePaymentCreditCard')}</Button>
                </div>
                </>
                }
            </div>
        {/* } */}
        </>
    ) 
  }
}
// export default withTranslation('common')(CreditCardDeclined);

const mapStateToProps = (state) => ({
    practiceUserLoginInfo: state.task.practiceUserLoginInfo,
    getPracticePaymentinfo:state.task.getPracticePaymentinfo,
    practiceUserAccountInfo:state.task.practiceUserAccountInfo,
    loadingBillingCardInfo:state.task.loadingBillingCardInfo,
  });
  export default compose(
    withTranslation('common'),
    connect(mapStateToProps )
  )(CreditCardDeclined);