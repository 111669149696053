import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import IconsArrowDown from '../../img/IconDownArrow.svg'
import LoadingButton from '../../components/LoadingButton';

import './style.scss';
import { Button , UncontrolledCollapse ,Collapse} from 'reactstrap';
import {getEmailPreferencesList , getEmailPreferences , getEmailPreferencesListRequest , saveEmailPreferences  , saveEmailPreferencesRequest} from "../../store/actions/taskAction";

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen:'',
      getEmailPreferences:props.getEmailPreferences,
      isSavePrefenceLoading: false,
      buttonLabel: "SAVE PREFERENCES",
      buttonColor: "primary"
    };
    this.toggle = this.toggle.bind(this);
    this.toggleChange = this.toggleChange.bind(this);

  }

async  componentDidMount(){
  await this.props.dispatch(getEmailPreferences())
  await this.props.dispatch(getEmailPreferencesList(this.props.getEmailPreferences || []))    
  await this.selectedPreferences()
}

componentWillUnmount(){
  let lists = this.props.getEmailPreferencesList
  lists.map((x) =>{ x.isOpen = false; return x; });
  this.props.dispatch(getEmailPreferencesListRequest(lists))
}

async selectedPreferences(){
  var {getEmailPreferencesList } = this.props
  var getEmailPreferences = this.props.getEmailPreferences
  getEmailPreferencesList.map((x , index2) => {
    var index = getEmailPreferences.findIndex(x1 => x1 === x.id )
        x.children.map((x1 , childidnex1) =>  {
        var childindex = getEmailPreferences.findIndex(m => m === x1.id )
        
        if(childindex > -1 )
          getEmailPreferencesList[index2].children[childidnex1].isChecked = true
      } )
      var selectedEmailPref = x.children.filter(x => x.isChecked == true);
      if(selectedEmailPref.length > 0 && selectedEmailPref.length == x.children.length){
        getEmailPreferencesList[index2].isChecked = true;
      }
   if( index> -1 ){
      getEmailPreferencesList[index2].isChecked = true
   }
  })
  this.props.dispatch(getEmailPreferencesListRequest(getEmailPreferencesList) )
  this.props.dispatch(saveEmailPreferencesRequest(getEmailPreferences) )
}

  toggle(index) {
    let lists = this.props.getEmailPreferencesList
    lists[index].isOpen =  !lists[index].isOpen
    this.props.dispatch(getEmailPreferencesListRequest(lists))
  }

  toggleChange(index , event , isChilderen){
    let lists = this.props.getEmailPreferencesList
    let getEmailPreferences = this.state.getEmailPreferences
    let id;
    var checked = event.target.checked
    if(isChilderen){
      lists[isChilderen].children[index].isChecked = checked
      id = lists[isChilderen].children[index].id
     
      // select all
      var selectedChild = lists[isChilderen].children.filter(x => x.isChecked);
      if(selectedChild.length == lists[isChilderen].children.length){
        lists[isChilderen].isChecked = true;
        getEmailPreferences.push(lists[isChilderen].id);
      }else {
        lists[isChilderen].isChecked = false;
        var index = getEmailPreferences.indexOf(lists[isChilderen].id);
        if (index > -1) {
          getEmailPreferences.splice(index, 1);
        }
      }
    }else{
      lists[index].isChecked = checked
      id = lists[index].id
      if(lists[index].children.length > 0){
        lists[index].children.map(x=>{
          var selectedIndex = getEmailPreferences.findIndex(x1 => x1 === x.id)
          if(selectedIndex === -1 )
            getEmailPreferences.push(x.id)
          else
            getEmailPreferences.splice(selectedIndex , 1)
         return x.isChecked = checked
        })
      }
    }


    var selectedIndex = getEmailPreferences.findIndex(x1 => x1 === id)

    if(selectedIndex === -1 ){
     getEmailPreferences.push(id)
    }else{
      getEmailPreferences.splice(selectedIndex , 1)
    }
   
    this.setState({getEmailPreferences},()=>console.log(getEmailPreferences))

    this.props.dispatch(getEmailPreferencesListRequest(lists))
  }

  async onEditPracticeInfo(field , value){
    let practiceProfileInfo = this.props.practiceProfileInfo
    practiceProfileInfo[field] = value
    await this.props.dispatch(saveEmailPreferencesRequest(practiceProfileInfo))
  }

  async onSave(){
    let getEmailPreferences = this.state.getEmailPreferences
    let params={
      "is_allowed_email": getEmailPreferences
    }
    this.setState({ isSavePrefenceLoading: true }, async () => {
      await this.props.dispatch(saveEmailPreferences(params))
      this.setState({ isSavePrefenceLoading: false, isSavePrefenceLoading: false, buttonLabel: "SAVE SUCCESSFULLY", buttonColor: "green" });
      setTimeout(() => {
        this.setState({ buttonLabel: "SAVE PREFERENCES", buttonColor: "primary" });
    }, 3000);
      this.props.dispatch(saveEmailPreferencesRequest(getEmailPreferences) )
    })
  }

  render (){
    const {getEmailPreferencesList , getEmailloading} = this.props
    return(
      <div className="main__screen email__page  d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Email Preferences"/>
          <div className="email__inner mb-auto d-flex flex-column">
            <div className="email__inner__header">
              <h4>Select your notification preferences</h4>
              <p>Personalize the notification messages you’d like to receive via email.</p>
            </div>
            <div className="email__inner__body">
              {!getEmailloading ? 
              <ul className="email_check_list">
              {getEmailPreferencesList && getEmailPreferencesList.map((list, index) => {
                  return  <li key={index}>
                            <div className="custom_check">
                              <input type="checkbox" onChange={(event)=>this.toggleChange(index  , event)} checked={list.isChecked ? list.isChecked : '' }  name={list.key} id={list.key+'_'+list.id} />
                              <label  for={list.key+'_'+list.id}>{list.name}</label>
                              {list.children && list.children.length > 0 &&
                                <Button type="bitton" onClick={() => this.toggle(index)} color={`link p-0 ${list.isOpen ? 'active_arrow' : ''}`} id={list.key+'_toggler_'+list.index}  ><img className="down-arrow ml-3" src={IconsArrowDown} alt="" /></Button>
                              }
                            </div>
                            {list.children && list.children.length > 0 &&
                              <UncontrolledCollapse  toggler={list.key+'_toggler_'+list.index} >
                              <div className="custom_inner_check">
                                <ul>
                                  {list.children.map((list, index1) => {
                                    return  <li key={index1}>
                                        <div className="custom_check">
                                          <input type="checkbox" name={list.key}  onChange={(event)=>this.toggleChange(index1  , event , index)} checked={list.isChecked ? list.isChecked : '' } id={list.key+'_'+list.id} />
                                          <label for={list.key+'_'+list.id}>{list.name}</label>
                                        </div>
                                      </li>
                                    })
                                  }
                                </ul>
                              </div>
                              </UncontrolledCollapse >
                            }
                        </li>
                })}
              </ul>
              :
              <>loader</>  
            }
            </div>
            <div className="email__inner__footer mt-auto">
              <LoadingButton size="md" type="button" color={this.state.buttonColor} loading={this.state.isSavePrefenceLoading} disabled={this.state.isSavePrefenceLoading} label={this.state.buttonLabel} onClick={() => this.onSave()}  />
            </div>
          </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  getEmailPreferencesList:state.task.getEmailPreferencesList,
  getEmailPreferences:state.task.getEmailPreferences,
  getEmailloading:state.task.getEmailloading
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);
