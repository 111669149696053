import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import error_worning_small from '../../img/error_worning_small.svg'
import { Button } from 'reactstrap';
import { history } from '../../pages/index';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        };
    }
    state = { error: null, errorInfo: null };

    render() {
        const { error, userIdentifyInfo , practiceUserLoginInfo} = this.props
        return (
            <div>
                {history.location.pathname != '/profileselection' && practiceUserLoginInfo && practiceUserLoginInfo.token_info && this.props.practiceInfo && this.props.practiceInfo.is_invoice_declined === 1 ?
                    <div className="custom__error d-flex align-items-center justify-content-center"><img className="mr-20 ml-20" alt="" src={error_worning_small}/>  <p className="m-0">Your invoice is past due, and your primary credit card has been declined.</p> <Button className="ml-5" outline size="md" onClick={()=>history.push("/paymentInfo")} color="default white">pay Now</Button></div>
                : '' }
                { 
                    error && Array.isArray(error.errors) && error.errors.length === 0 && error.message ?
                     <div className="custom__error d-flex align-items-center justify-content-center">{error.message}</div>
                :
                    error && error.errors && Object.keys(error.errors).length !== 0 &&
                    Object.keys(error.errors).map((sub, index) =>{
                    return <div key={index} className="custom__error d-flex align-items-center justify-content-center">
                    <p className="m-0">
                        { 
                            error.errors[sub][0]
                        }
                    </p>
                    </div>
                    })
                }
                 {/* <div className="custom__error d-flex align-items-center justify-content-center">{error.message}</div> */}
                {
                //    userIdentifyInfo.otp && <div className="custom__error d-flex align-items-center justify-content-center">{userIdentifyInfo.otp}</div> 
                }
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    error: state.task.error,
    userIdentifyInfo: state.task.userIdentifyInfo,
    practiceInfo:state.task.practiceInfo,
    practiceUserLoginInfo:state.task.practiceUserLoginInfo
});
export default compose(
    withTranslation('common'),
    connect(mapStateToProps)
)(ErrorBoundary);