import React from 'react';

import './style.scss';
import IconsNoconnectionWhite from '../../img/IconsNoconnectionWhite.svg'

import { Button } from 'reactstrap';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import Header from '../../components/Header';

class AccountInactiveIndex extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        is_screll: false,
        DeviceCode:null,
        tosinfo:{}
    };
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(){
    window.location.reload();
  } 

  async componentDidMount(){
   
  }

  render (){
    const {t} = this.props
    return(
      <div className="app">
        <div className="inactive__main__screen error__default__screen d-flex flex-column h-100 align-items-center justify-content-center">
          <div className="inactive__inner__screen nointernet__main__screen my-auto">
              <div className="d-flex flex-column text-center no-internet-img inactive__inner__text text-center">
                  <img className="mx-auto mb-5" alt="" src={IconsNoconnectionWhite}/>
                  <h1>Your PC is not online</h1>
                  <p>To access Thalamus from your PC, an internet connection is required.<br/>
  Please verify your connection and try reloading the page.</p>
                  <Button size="sm" onClick={() => this.handleClick()} className="mx-auto" color="default">Reload</Button>
              </div>
          </div>
        </div>
      </div>
    ) 
  }
}
export default withTranslation('common')(AccountInactiveIndex);
