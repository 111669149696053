import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
// import {CKEditorContext, CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
import './styles.scss';
// import Context from '@ckeditor/ckeditor5-core/src/context';
// import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import '../../livestyle.scss';
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import UploadImage from '../../components/UploadImage';
import { cleardata } from "../../store/actions/taskAction";

var editableImage = "editable_image";
const Slide = () => {
    const [imageUploadModal, setImageUploadModal] = useState(false);
    const dispatch = useDispatch();

    const script = document.createElement("script");
    script.src = "https://cdn.ckeditor.com/4.7.0/standard-all/ckeditor.js";//standard-all/ckeditor.js
    // script.async = true;
    script.onload = () => scriptLoaded();

    document.body.appendChild(script);



    const scriptLoaded = () => {
        window.CKEDITOR.dtd.$editable.span = 1;
        window.CKEDITOR.config.toolbarLocation = 'top';
        // window.CKEDITOR.config.removePlugins = 'floatingspace,maximize,resiz';
        window.CKEDITOR.config.extraPlugins = 'sharedspace,font'//,balloontoolbar,linkballoon,image2'// sourcedialog
        // window.CKEDITOR.config.uiColor = '#424242';
        
        window.CKEDITOR.config.toolbar = [
            // { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'fontSize' ] },
            // { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
            { name: 'clipboard', items: [ 'Undo', 'Redo', "Bold", "Italic", "Underline" ] },
            {name: "basicstyles", itmes: ["Bold", "Italic", "Underline"]},
            { name: 'styles', items: [ 'FontSize' ] },
        ];

        // window.CKEDITOR.balloonToolbars = {
        //     buttons: 'Link,Unlink,Image',
        //     widgets: 'image'
        // };

        // window.CKEDITOR.config.toolbarGroups = [{ name: 'styles' }]

        // window.CKEDITOR.disableAutoInline = true;

        window.CKEDITOR.config.fontSize_sizes = '9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px';
        
        window.CKEDITOR.config.toolbarCanCollapse = true;
        window.CKEDITOR.config.fontSize_defaultLabel = '';
        window.CKEDITOR.config.sharedSpaces = {
            top: 'topToolbar',
            // bottom: 'bottomToolbar'
        };

        window.CKEDITOR.config.allowedContent = true;

        // window.CKEDITOR.ui.addButton('custom_button', {
        //     label: 'Custom Button',
        //     command: 'custom_command',
        //     toolbar: "insert",
        //     icon:"https://avatars1.githubusercontent.com/u/5501000"
        // });
        
        // window.CKEDITOR.config.floatSpacePreferRight = true;
        document.getElementById(editableImage).onclick = () => {
            // alert(" on image click...!")
            setImageUploadModal(true);
        }
    }

    const saveSlide = () => {
        if(window.CKEDITOR){
            var data = window.CKEDITOR.instances.editor1.getData();
            console.log("data==>", data);
            var element = document.getElementById('mainEditor');
            console.log(element.innerHTML);
        }
    }

    const togleUploadImage = () => {
        setImageUploadModal(!imageUploadModal)
    }

    const closeModal = () => {
        setImageUploadModal(false);
    }

    const imageUploadSuccess = (response) => {
        var imageResponse = response.data
        if(imageResponse && imageResponse.status == 200){
            document.getElementById("editable_image").src = imageResponse.data.image;
            dispatch(cleardata()); 
        }else{

        }
    }

    return(
        <>
            <Row>
                <Col lg='4'>
                    
                </Col>
                <Col lg='7'>
                    <div style={{marginTop: 120}}>
                        {/* <CKEditorContext context={ Context }> */}
                        {/*<link href="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/fonts/stylesheet.css" rel="stylesheet" />
                        <link href="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/css/happy-birthday.css" rel="stylesheet" />
                        <div class="main"><div class="happy-birthday-page">
                            <div class="happy-birthday-card"><div class="birthday-person"> 
                            <img src="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/images/48629069-portrait-of-happy-doctor.png" alt="medical-team-persion"/>
                        </div>
                        <div class="birthday-wish">
                        <CKEditor
                                // config={{
                                //     link: {
                                //         addTargetToExternalLinks: true
                                //     },
                                //     stylesSet: ['http://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/fonts/stylesheet.css', 'http://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/css/happy-birthday.css']
                                // }}
                                config={ {
                                    // plugins: [ Paragraph, Bold, Italic, Essentials ],
                                    // toolbar: [ 'bold', 'italic' ],
                                    link: {
                                        addTargetToExternalLinks: true
                                    },
                                    stylesSet: ['http://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/fonts/stylesheet.css', 
                                                'http://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/css/happy-birthday.css']
                                } }
                                editor={ InlineEditor }
                                data={`<h1 class="title" contenteditable="true"> Happy Birthday, <span contenteditable="true">Dr. Harper!</span></h1><p>May you enjoy the finest things in life. Cheers to another year!</p></div></div><div class="birthday-bg"> <img src="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/images/51325570-chocolate-cupcake-with-a-sparkler.png" alt="birthday-bg"></div></div></div></body></html>`} 
                            />
                        </CKEditorContext>
                            </div>*/}
                            <span contentEditable="true" data-enable-grammarly="false">Test data</span> {/* */}
                            <div>
                                <div id="topToolbar"></div>
                                <div class="mainEditor" id="mainEditor" style={{  height:1080, zoom: 0.6 }} dangerouslySetInnerHTML={{ __html: `
                                    <link href="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/fonts/stylesheet.css" rel="stylesheet" />
                                    <link href="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/css/happy-birthday.css" rel="stylesheet" />
                                    <div class="main">
                                        <div class="happy-birthday-page">
                                            <div class="happy-birthday-card">
                                                <div class="birthday-person" > 
                                                    <img id="editable_image" src="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/images/48629069-portrait-of-happy-doctor.png" alt="medical-team-persion"/>
                                                </div>
                                                <div class="birthday-wish">
                                                    <h1 class="title" > Happy Birthday, <span contenteditable="true" data-enable-grammarly="false">Dr. Harper!</span></h1>
                                                    <p contenteditable="true" data-enable-grammarly="false" >May you enjoy the finest things in life. Cheers to another year!</p>
                                                </div>
                                            </div>
                                            <div class="birthday-bg">
                                                <img src="https://s3.ap-south-1.amazonaws.com/thalamus.media/waiting-room-slide-preset-assets/images/51325570-chocolate-cupcake-with-a-sparkler.png" alt="birthday-bg"/>
                                            </div>
                                        </div>
                                    </div>`}} />
                                </div>
                                <div id="bottomToolbar"></div>
                            </div>
                        <Button onClick={saveSlide}>
                            Save Slide
                        </Button>
                </Col>
            </Row>
            <Modal isOpen={imageUploadModal} centered={true} toggle={togleUploadImage} >
                <UploadImage closeModal={closeModal} isSlide={true} slideImage={ imageUploadSuccess }/>
            </Modal>
        </>
    )
}

export default Slide;