import React from 'react';

import './style.scss';
import { Button, NavLink } from 'reactstrap';
import Mobile from './mobileEntry'
import Email from './emailSreen'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { clearerror, resetSuccess } from "../../store/actions/taskAction";
import { history } from '..';
import { SIGNIN } from '../../utilities/HttpUtility';

class ForgotAccessCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileScreen: false,
      showEmailScreen: false,
      showDefaultScreen: true,
    };
    this.handleClick = this.handleClick.bind(this);

  }

  async componentDidMount() {
    await this.props.dispatch(clearerror());
    await this.props.dispatch(resetSuccess());
    this.setState({ showEmailScreen: false, showDefaultScreen: true, showMobileScreen: false });

  }

  handleClick(name) {
    console.log(name, 'name')
    switch (name) {
      case "email":
        this.setState({ showEmailScreen: true, showDefaultScreen: false, showMobileScreen: false });
        break;
      case "mobile":
        this.setState({ showMobileScreen: true, showDefaultScreen: false, showEmailScreen: false });
        break;
      default:
        this.setState({ showDefaultScreen: true, showMobileScreen: false, showEmailScreen: false });
    }
  }
  onBack() {
    history.push('/thalamussignin');
  }
  render() {
    const { t, error } = this.props
    const { showMobileScreen, showEmailScreen, showDefaultScreen } = this.state;
    return (
      <div className="forgot_access_code__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
        <div className="forgot_access_code__inner__screen transparent__form--dark d-flex flex-column align-items-center  justify-content-center">
          {showMobileScreen && (
            <Mobile t={t} handleClick={this.handleClick} />
          )}
          {showEmailScreen && (
            <Email t={t} handleClick={this.handleClick} />
          )}
          {showDefaultScreen && (
            <div className="d-flex flex-column text-center forgot_access_code__inner__text">
              <h1>{t('forgotAccessCode.title')}</h1>
              <p>{t('forgotAccessCode.detail')}</p>
              <div className="d-flex  justify-content-center">
                <Button className="mx-3" size="md" onClick={() => this.handleClick('email')} color="primary">{t('btn.email')}</Button>
                <Button className="mx-3" size="md" onClick={() => this.handleClick('mobile')} color="primary">{t('btn.msms')}</Button>
              </div>
              <div style={{ marginTop: 20, }}>
                {/* <Button className="mx-3" size="md" onClick={() => this.handleClick('mobile')}>Back</Button> */}
                <NavLink href={SIGNIN}>
                  <Button className="mx-auto mt-4" size="md" outline onClick={() => this.onBack()} color="default white">{t('btn.back')}</Button>
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
// export default withTranslation('common')(ForgotAccessCode);
const mapStateToProps = (state) => ({
  error: state.task.error,
  sendRecoverCode: state.task.sendRecoverCode
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(ForgotAccessCode);