import React from 'react';
import { Button, FormGroup, Form, Spinner } from 'reactstrap';
// import ReactCodeInput from 'react-verification-code-input';
import ReactCodeInput from 'react-code-input';
import SubHeader from '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import LoadingButton from '../../components/LoadingButton'
import { history } from '../../pages/index';

import { PracticeUserLogin, GetPasscodeRecovery, clearerror } from "../../store/actions/taskAction";

import './style.scss';

class SignInIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      isSigninClick: false,
      isSignin: false,
      isRecovry: false,
      isEnableSignin: false,
      userPasscode: '',
      minutes: 5,
      seconds: 0,
      time: 0,
      signinerror: '',
      error:''
    }
    this.onSigninClick = this.onSigninClick.bind(this);
    this.onSignin = this.onSignin.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  resend() {
    this.myInterval = setInterval(() => {
      const { seconds } = this.state

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
          isGetCode: false
        }))
      } else {
        this.setState({ isGetCode: true })
      }
      if (this.seconds === 0) {
        clearInterval(this.myInterval)
      }

    }, 1000)
  }

  async componentDidMount() {
    await this.props.dispatch(clearerror());
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }


  handleChange(field, options) {
    var name = "";
    var value = "";
    
    if (field === "verificationCode") {

      if (!/[0-9]/.test(options)) {

        this.setState({error: 'Please Input Number Only'})

      }else{
        name = field;
        value = options;
      }

    } else {
      name = field.target.name;
      value = field.target.value;
    }

    this.setState({
      userPasscode: {
        [name]: value,
      }
    }, () => { this.isValid() });
  };

  isValid() {
    if (this.state.userPasscode && this.state.userPasscode.verificationCode && this.state.userPasscode.verificationCode.length == 4) {
      if (!isNaN(this.state.userPasscode.verificationCode)) {
        this.setState({error:'', isEnableSignin: true})
      }
    } else {
      this.setState({ isEnableSignin: false });
    }
  }
  onSigninClick() {
    this.setState({ isSigninClick: false })
  }

  onSignin() {
    var fromtrial = false;
    var is_super_admin = false
    var frompaymentinfo= false

    if(this.props.history.location.fromtrial){
      fromtrial = this.props.history.location.fromtrial
    }
console.log(this.props.history.location.frompaymentinfo,'this.props.history.location.frompaymentinfo')
    if(this.props.history.location.frompaymentinfo){
      frompaymentinfo = this.props.history.location.frompaymentinfo
    }


    if(this.props.practiceInfo.is_super_admin == 1){
      is_super_admin = true
    }

    this.props.dispatch(PracticeUserLogin(this.props.history.location.state.users.id, this.state.userPasscode.verificationCode, false, fromtrial, is_super_admin, frompaymentinfo));
  }

  async onRememberPasscode() {
    clearInterval(this.myInterval)
    await this.props.dispatch(GetPasscodeRecovery(this.props.history.location.state.users.id));
    await this.setState({ isRecovry: true, seconds: 59 })
    await this.resend()
  }

  // async componentWillUnmount() {
  //   this.props.signinerror=''
  // }

  handleKeypress(e) {
    console.log('lllllllllllllllllllllll')
    if (e.keyCode === 13) {
      this.btn.click();
    }
  };

  render() {

    const { loadingSignin, error } = this.props

    return (
      <div className="signin__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader fromtrial={ this.props.history.location.fromtrial} className="mb-auto" title="Profile Sign in" />
        {this.state.isSigninClick ?
          <div className="signin__inner__screen mb-auto">
            <div className="d-flex flex-column text-center signin__inner__text text-center">
              <h1 className="mb-5">Profile created</h1>
              <p>The profile “James Clark” has been created<br /> and is now available in the profile selection section.</p>
              <Button size="md" className="mx-auto mt-5" onClick={() => this.onSigninClick()} color="primary">Sign in</Button>
            </div>
          </div>
          :
          <div className="signin__box transparent__form--white  md d-flex flex-column align-items-stretch mb-auto">
            <Form className="h-100 d-flex flex-column flex-grow-1">
              <FormGroup className="mb-20">
                <h4 className="m-0 mb-4">Please enter your passcode</h4>
                <p className="m-0"> Please enter the passcode you have set to &nbsp; secure your profile.</p>
              </FormGroup>

              <FormGroup className={`my-auto ${this.props.error || this.state.error ? 'notverifyed' : ''}`} style={{ transform: "translateY(-12px)" }}>
                <div className="d-flex justify-content-between">
                  <label style={{ fontSize: "14px" }}>Enter your passcode</label><Button onClick={this.toggleShow} color="link primary" className="p-0">{this.state.hidden ? 'SHOW' : 'Hide'}</Button>
                </div>
                <ReactCodeInput onKeyPress={this.handleKeypress}  type={this.state.hidden ? 'password' : 'number'}  onChange={(options) => { this.props.dispatch(clearerror()); this.handleChange('verificationCode', options) }} fields={4} className={`mb-2 input-password`} />
                {this.state.error && <span className='text--danger'>{this.state.error}</span>}
                {this.state.isRecovry ?
                  <p className="mt-3">Your passcode has been sent to you via SMS.<br />
No code received? <Button type="button" disabled={!this.state.isGetCode} color="link primary h-auto" onClick={() => this.onRememberPasscode()} className="p-0">Click here to resend. {`(${this.state.seconds})` + 's'}</Button></p>
                  : null}
              </FormGroup>
              <div className="">
                <div className="d-flex align-items-center mb-3">
                  <label className="mr-2 mb-0">Can’t remember your passcode?</label> <Button type="button" disabled={this.state.seconds > 0?!this.state.isGetCode:false} onClick={() => { if (this.state.seconds == 0) { this.onRememberPasscode() } }} color="link primary" className="p-0">Click here.</Button>
                </div>
                <LoadingButton type="submit" ref={node => (this.btn = node)} disabled={!this.state.isEnableSignin || loadingSignin} label={`${this.props.signinerror ? 'Incorrect passcode' : 'Sign in'}`} loading={loadingSignin} size="xxl" onClick={() => this.onSignin()} className={`${this.props.signinerror ? 'notverifyed' : ''}`} color="primary" />
              </div>
            </Form>
          </div>
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  signinerror: state.task.signinerror,
  loadingSignin: state.task.loadingSignin,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
  practiceInfo: state.task.practiceInfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(SignInIndex);
