import React, { useState } from 'react'
import { history } from '../../pages/index';
import Skeleton from "@material-ui/lab/Skeleton";



const VideoswiperSlideSkeleton = ({ src = '' , alt = ''}) => {


  return (
         <>
        <div className='swiper-slide swiper-slide-active'>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={356}
                height={200}
            />
        </div>
        <div className='swiper-slide swiper-slide-active'>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={356}
                height={200}
            />
        </div>
        <div className='swiper-slide swiper-slide-active'>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={356}
                height={200}
            />
        </div>
        <div className='swiper-slide swiper-slide-active'>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={356}
                height={200}
            />
        </div>
        <div className='swiper-slide swiper-slide-active'>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={356}
                height={200}
            />
        </div>
        </>
  )
}

export default VideoswiperSlideSkeleton
