/** @format */

import React from 'react';

const EditSlides = () => {
	return (
		<div>
			<canvas></canvas>
				<iframe
					srcDoc={`<html><body><h1>Hello There!</h1></body</html>`}
					style={{
						width: '100%',
						height: '656px',
                        marginTop:'-120px',
						border: '1px solid white',
						background: 'white',
					}}
				/>
			
		</div>
	);
};

export default EditSlides;
