import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import VerifyIdentityBox from '../../components/VerifyIdentityBox'
import './style.scss';

class PracticeInfoIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render (){
    return(
      <div className="accesscode__main__screen   d-flex flex-column h-100 align-items-center justify-content-center">
          <SubHeader className="mb-auto" title="Access Code"/>
          <VerifyIdentityBox pageUrl={`/generate-access-code`}/>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(PracticeInfoIndex);