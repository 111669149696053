import React from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';

import './style.scss';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../pages/index';

class TrialIndex extends React.Component {
  
  async componentDidMount(){
    // const {practiceInfo} = this.props;
  }

  handleClick(){
    if(this.props.practiceInfo.need_profile_setup === 1){
        history.push('/initialsetup');
    }else if(this.props.practiceInfo.need_owner_setup === 1){
        history.push('/edit-authorized-profile');
    }else{
      history.push('/trial');
    }
  } 

  onClickSubscribe(){
    // history.push('/Subscribe');
    history.push({
      pathname: '/profileselection',
      fromtrial:true
    });
  }

  render (){
    const {practiceInfo , t} = this.props
    return(
      <div className="welcome__thalamus trial__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
        <div className="trial__inner__screen transparent__form--dark d-flex flex-column align-items-center  justify-content-center">
            <div className="d-flex flex-column text-center welcome__thalamus__text">
              <div>
                  <h1>{t('welcomeToThalamus.title')}</h1>
                  <p>{t('welcomeToThalamus.subTitle')}</p> 
                  <div className="d-flex  justify-content-center">
                      <Button className="mx-3 btn-235" size="md" onClick={() => this.handleClick()}  outline color="default white">{t('btn.continue')}</Button>
                  </div>
              </div>
            </div>
        </div>
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo
});


// export default connect( mapStateToProps,  mapDispacthToProps )(TrialIndex);
// export default withTranslation('common')(TrialIndex);
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(TrialIndex);