import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import BillingProfileImg from '../../img/BillingProfileAvtar.png'

import './style.scss';
import { Button} from 'reactstrap';

const HomeIndex = (props) => {
  return(
    <div className="main__screen update_admin_profile__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Access Code"/>
        { /* <div className="update_admin_profile__inner mb-auto">
          <div className="update_admin_profile__inner__header text-center">
            <h4>Verify your identity</h4>
            <p>A verification code sent via SMS text, email, or phone call is required in order to proceed.</p>
          </div>
          <div className="update_admin_profile__inner__body min-height-none">
            <div className="user__box">
              <div className="user__avtar">
                <img className="mx-auto my-auto" alt="" src={BillingProfileImg}/>
                <span>Owner</span>
              </div>
              <div className="user__name">CARLA SMITH</div>

            </div>
          </div>
          <div className="update_admin_profile__inner__footer">
            <div class="d-flex justify-content-between">
              <label>Enter the verification code </label><button class="p-0 btn btn-link primary">SHOW</button>
            </div>
            <div className="verification-code-input">
              <input type="tel" className="form-control" />
              <input type="tel" className="form-control" />
              <input type="tel" className="form-control" />
              <input type="tel" className="form-control" />
            </div>
            <p className="mx-n2 mb-1">Didn’t receive your code? <a href="#" className="text-primary">Click here to resend.</a></p>
            <p className="mx-n3 mb-1">Need to update your email or mobile no.? <a href="#" className="text-primary">Click here.</a></p>
            <Button size="md mt-4" color="primary">Next</Button>
          </div>
        </div> */}

         {/* screen no 269  */}
        <div className="update_admin_profile__inner mb-auto border-success border">
          <div className="update_admin_profile__inner__body d-flex align-items-center justify-content-center">
            <div className="d-block w-100">
              <div className="update_admin_profile__inner__header text-center">
                <h4>Access code in use</h4>
                <h2 className="my-5 fs50">458769</h2>
                <p>By generating a new access code, all your Thalamus instances will be terminated, and you’ll have to sign in once more in each device. Access codes should be changed in case you suspect that the security of your code has been compromised. Would you like to proceed?</p>
              </div>
            </div>
          </div>
          <div className="update_admin_profile__inner__footer d-flex">
            <Button size="md px-0 mr-2" color="primary">Yes, generate it</Button>
            <Button size="md px-0 ml-2" color="danger">Cancel</Button>
          </div>
        </div>
      

    </div>
  ) 
}
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
