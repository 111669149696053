import React, { useState, useEffect } from "react";
import SubHeader from '../../components/Header/subHeader';
import { Button, Label, FormGroup, Form, Col, Row, } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import UploadImage from '../../components/UploadImage';
import {ReactComponent as ArrowLeft} from '../../img/arrow_left.svg';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch 
  } from "react-router-dom";
import "./styles.scss";


export default function SelectedImagesList ({onImageSelect, stockImages, onBack, selectedImage, step}) {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const history = useHistory();
    const { catId } = useParams()
    const { url, path } = useRouteMatch()
    const topic = state.WaitingRoomSlideStockImages.find(x=>x.id == stockImages.id)

    useEffect(() => {
		console.log(catId,'catId')
	}, []);

    return (
        <div className="upload_cetegory__images__cat_lists">
            <div className="d-flex justify-content-between py-4 px-20">
                <Button  type="button"  onClick={() => onBack()}  color="link" className="py-1 pl-0 d-flex align-items-center"><ArrowLeft className="mr-4"/><span>Back</span></Button>
                <div className="">{topic.title} | {step == 1 ? 'Thumbnail' : 'Overview' }</div>
            </div>
            <div className="row row-cols-2 m-0">
                {topic.stock_library_data && topic.stock_library_data.map(items => {
                    return(
                        <div key={items.id} className="col px-20 mb-40" onClick={() => onImageSelect(items)}>
                            <div className={`cat_list_box cat_list_item ${items.id == selectedImage.id ? 'activeImg' : ''}`}>
                                <img style={{ objectFit: 'cover' }}  alt={''} src={items.thumb_url} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

