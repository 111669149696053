import { useState } from 'react';

// react-pintura
import { PinturaEditor } from 'react-pintura';

// pintura
import 'pintura/pintura.css';
import {
    // editor
    locale_en_gb,
    createDefaultImageReader,
    createDefaultImageWriter,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_finetune,
    plugin_finetune_locale_en_gb,
    plugin_finetune_defaults,
    plugin_filter,
    plugin_filter_locale_en_gb,
    plugin_filter_defaults,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,
    processImage, 
    getEditorDefaults 
} from 'pintura';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const editorDefaults = getEditorDefaults({
    utils: ['annotate'],
    imageReader: createDefaultImageReader(),
    imageWriter: {
        // createDefaultImageWriter(),
        targetSize: {
            width: 1600,
            height: 900,
            fit: 'contain',
        },
        postprocessImageData: (imageData) =>
            new Promise((resolve, reject) => {
           
            // Create a canvas element to handle the imageData
            const canvas = document.createElement('canvas');
            canvas.width = imageData.width;
            canvas.height = imageData.height;
            const ctx = canvas.getContext('2d');
            ctx.putImageData(imageData, 0, 0);

            ctx.fillStyle = '#00000040';
            ctx.fillRect(0, imageData.height - 68, imageData.width, 68);
        
            ctx.font="18px Roboto, sans-serif";
            ctx.fillStyle = "white";
            ctx.textAlign = "center";
            canvas.style.letterSpacing = "0.5px";
            ctx.fillText('This image captured from copyrighted video is provided for informational purpose only.', imageData.width/2, imageData.height - 42);
            ctx.fillText('It cannot be presented or distributed to any other outside sources, being that it is a shared confidential image between patient and doctor', imageData.width/2, imageData.height - 16);
            
            resolve(ctx.getImageData(0, 0, imageData.width, imageData.height));
        })
    },
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...markup_editor_defaults,
    locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...markup_editor_locale_en_gb,
    },
});

export default function Example(props) {
    // inline
    const [result, setResult] = useState('');

    return (
        <div className="App">
            <div className='pintura_editor_main_section' style={{ height: '80vh' }} >
                <PinturaEditor
                    {...editorDefaults}
                    src={props.data}
                    onLoad={(res) => console.log('load image', res)}
                    onProcess={({ dest }) => {
                        props.updateFile(dest); 
                        setResult(URL.createObjectURL(dest))
                    }} 
                    className="pintura-editor"
                />
            </div>
        </div>
    );
}
