import { AUTHORIZED_DEVICE, FBBASEURL } from '../../utilities/HttpUtility';
import {
	GET_PRACTICEINFO_REQUEST,
	GET_PRACTICEINFO_SUCCESS,
	GET_PRACTICEINFO_FAIL,
	UPDATE_PRACTICEINFO_REQUEST,

	SIGNOUT_REQUEST,
	SIGNOUT_SUCCESS,
	SIGNOUT_FAIL,

	GET_PRACTICEMOREINFO_REQUEST,
	GET_PRACTICEMOREINFO_SUCCESS,
	GET_PRACTICEMOREINFO_FAIL,

	GET_TOS_REQUEST,
	GET_TOS_SUCCESS,
	GET_TOS_FAIL,

	POST_ACCEPT_TOS_REQUEST,
	POST_ACCEPT_TOS_SUCCESS,
	POST_ACCEPT_TOS_FAIL,

	GET_PRACTICE_PROFILE_REQUEST,
	GET_PRACTICE_PROFILE_SUCCESS,
	GET_PRACTICE_PROFILE_FAIL,

	GET_PRACTICESECURITYQUE_REQUEST,
	GET_PRACTICESECURITYQUE_SUCCESS,
	GET_PRACTICESECURITYQUE_FAIL,

	GET_GENDER_REQUEST,
	GET_GENDER_SUCCESS,
	GET_GENDER_FAIL,

	GET_PRACTICE_USER_REQUEST,
	GET_PRACTICE_USER_SUCCESS,
	GET_PRACTICE_USER_FAIL,

	GET_AUTHORIZED_USER_REQUEST,
	GET_AUTHORIZED_USER_SUCCESS,
	GET_AUTHORIZED_USER_FAIL,

	UPDATE_AUTHORIZED_USER_REQUEST,
	UPDATE_AUTHORIZED_USER_SUCCESS,
	UPDATE_AUTHORIZED_USER_FAIL,

	GET_AUTHORIZED_ALL_USERS_REQUEST,
	GET_AUTHORIZED_ALL_USERS_SUCCESS,
	GET_AUTHORIZED_ALL_USERS_FAIL,

	INVITE_USER_TO_CTEATE_PROFILE_REQUEST,
	INVITE_USER_TO_CTEATE_PROFILE_SUCCESS,
	INVITE_USER_TO_CTEATE_PROFILE_FAIL,

	REINVITE_USER_TO_CTEATE_PROFILE_REQUEST,
	REINVITE_USER_TO_CTEATE_PROFILE_SUCCESS,
	REINVITE_USER_TO_CTEATE_PROFILE_FAIL,

	GET_USER_PROFILE_INFO_REQUEST,
	GET_USER_PROFILE_INFO_SUCCESS,
	GET_USER_PROFILE_INFO_FAIL,

	GET_PRACTICE_OWNER_REQUEST,
	GET_PRACTICE_OWNER_SUCCESS,
	GET_PRACTICE_OWNER_FAIL,

	GET_PRACTICE_OWNER_INFO_REQUEST,
	GET_PRACTICE_OWNER_INFO_SUCCESS,
	GET_PRACTICE_OWNER_INFO_FAIL,

	UPDATE_OWNER_PROFILE_REQUEST,
	UPDATE_OWNER_PROFILE_SUCCESS,
	UPDATE_OWNER_PROFILE_FAIL,

	GET_PHONE_VERIFICATION_REQUEST,
	GET_PHONE_VERIFICATION_SUCCESS,
	GET_PHONE_VERIFICATION_FAIL,
	POST_NEW_LOCATION_REQUEST,
	POST_NEW_LOCATION_SUCCESS,
	POST_NEW_LOCATION_FAIL,

	POST_LOGIN_PRACTICE_LOCATION_REQUEST,
	POST_LOGIN_PRACTICE_LOCATION_SUCCESS,
	POST_LOGIN_PRACTICE_LOCATION_FAIL,

	CHECK_PRACTIC_IS_CORPORATE,

	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUEST,
	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUESTLOADING,
	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_SUCCESS,
	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_FAIL,

	GET_USER_VERIFICATION_REQUEST,
	GET_USER_VERIFICATION_SUCCESS,
	GET_USER_VERIFICATION_FAIL,

	GET_DEFAULT_PROFILE_PIC_REQUEST,
	GET_DEFAULT_PROFILE_PIC_SUCCESS,
	GET_DEFAULT_PROFILE_PIC_FAIL,

	UPDATE_PRACTICE_PROFILE_REQUEST,
	UPDATE_PRACTICE_PROFILE_SUCCESS,
	UPDATE_PRACTICE_PROFILE_FAIL,


	UPDATE_PRACTICE_REQUEST,
	UPDATE_PRACTICE_SUCCESS,
	UPDATE_PRACTICE_FAIL,

	VERIFY_OTP_REQUEST,
	VERIFY_OTP_SUCCESS,
	VERIFY_OTP_FAIL,

	GET_PRACTICE_USER_LOGIN_REQUEST,
	GET_PRACTICE_USER_LOGIN_SUCCESS,
	GET_PRACTICE_USER_LOGIN_FAIL,

	GET_PRACTICE_USER_LOGOUT_REQUEST,
	GET_PRACTICE_USER_LOGOUT_SUCCESS,
	GET_PRACTICE_USER_LOGOUT_FAIL,

	GET_BILLING_CARD_INFO_REQUEST,
	GET_BILLING_CARD_INFO_SUCCESS,
	GET_BILLING_CARD_INFO_FAIL,


	UPDATE_BILLING_CARD_INFO_REQUEST,
	UPDATE_BILLING_CARD_INFO_SUCCESS,
	UPDATE_BILLING_CARD_INFO_FAIL,

	CHANGE_BILLING_CARD_STATUS_REQUEST,
	CHANGE_BILLING_CARD_STATUS_SUCCESS,
	CHANGE_BILLING_CARD_STATUS_FAIL,

	ADD_BILLING_CARD_INFO_REQUEST,
	ADD_BILLING_CARD_INFO_SUCCESS,
	ADD_BILLING_CARD_INFO_FAIL,


	GET_DOMAIN_LIST_REQUEST,
	GET_DOMAIN_LIST_SUCCESS,
	GET_DOMAIN_LIST_FAIL,

	CREATE_DOMAIN_REQUEST,
	CREATE_DOMAIN_SUCCESS,
	CREATE_DOMAIN_FAIL,

	UPDATE_DOMAIN_REQUEST,
	UPDATE_DOMAIN_SUCCESS,
	UPDATE_DOMAIN_FAIL,

	REMOVE_DOMAIN_REQUEST,
	REMOVE_DOMAIN_SUCCESS,
	REMOVE_DOMAIN_FAIL,

	GET_SUBSCRIBE_INFO_REQUEST,
	GET_SUBSCRIBE_INFO_SUCCESS,
	GET_SUBSCRIBE_INFO_FAIL,

	GET_SUBSCRIBE_PRICE_OPTION_REQUEST,
	GET_SUBSCRIBE_PRICE_OPTION_SUCCESS,
	GET_SUBSCRIBE_PRICE_OPTION_FAIL,

	GET_SUBSCRIBE_PRICE_CALC_REQUEST,
	GET_SUBSCRIBE_PRICE_CALC_SUCCESS,
	GET_SUBSCRIBE_PRICE_CALC_FAIL,

	POST_SUBSCRIBE_REQUEST,
	POST_SUBSCRIBE_SUCCESS,
	POST_SUBSCRIBE_FAIL,


	SET_UPLOAD_PROGRESS,
	SUCCESS_UPLOAD_FILE,
	FAILURE_UPLOAD_FILE,

	UPDATE_PASSCODE_REQUEST,
	UPDATE_PASSCODE_SUCCESS,
	UPDATE_PASSCODE_FAIL,

	UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST,
	UPDATE_USER_PROFILE_ABOUT_INFO_SUCCESS,
	UPDATE_USER_PROFILE_ABOUT_INFO_FAIL,

	UPDATE_SYSTEM_LANGUAGE_REQUEST,
	UPDATE_SYSTEM_LANGUAGE_SUCCESS,
	UPDATE_SYSTEM_LANGUAGE_FAIL,

	UPDATE_USER_PROFILE_REQUEST,
	UPDATE_USER_PROFILE_SUCCESS,
	UPDATE_USER_PROFILE_FAIL,

	UPDATE_USER_PROFILE_IMAGE_REQUEST,
	UPDATE_USER_PROFILE_IMAGE_SUCCESS,
	UPDATE_USER_PROFILE_IMAGE_FAIL,

	DELETE_USER_PROFILE_REQUEST,
	DELETE_USER_PROFILE_SUCCESS,
	DELETE_USER_PROFILE_FAIL,

	GET_USER_IDENTITY_REQUEST,
	GET_USER_IDENTITY_SUCCESS,
	GET_USER_IDENTITY_FAIL,

	GET_USER_PROFILE_VERIFY_REQUEST,
	GET_USER_PROFILE_VERIFY_SUCCESS,
	GET_USER_PROFILE_VERIFY_FAIL,


	DELETE_MULTY_USER_PROFILE_REQUEST,
	DELETE_MULTY_USER_PROFILE_SUCCESS,
	DELETE_MULTY_USER_PROFILE_FAIL,

	RESET_REFER_FREE_MONTH_REQUEST,
	POST_REFER_FREE_MONTH_REQUEST,
	POST_REFER_FREE_MONTH_SUCCESS,
	POST_REFER_FREE_MONTH_FAIL,

	GET_REFERRAL_LIST_REQUEST,
	GET_REFERRAL_LIST_SUCCESS,
	GET_REFERRAL_LIST_FAIL,

	SEND_PRACTICE_RECOVERY_CODE_REQUEST,
	SEND_PRACTICE_RECOVERY_CODE_SUCCESS,
	SEND_PRACTICE_RECOVERY_CODE_FAIL,
	SEND_PRACTICE_RECOVERY_CODE_FAIL_ERROR,

	GET_NOTIFICATION_REQUEST,
	GET_NOTIFICATION_SUCCESS,
	GET_NOTIFICATION_FAIL,

	CLEAR_DATA,
	CLEAR_ERROR,
	RESET_SUCCESS,
	CLEAR_LOADING,

	WEBSITE_MEDIA_REQUEST,
	WEBSITE_MEDIA_SUCCESS,
	WEBSITE_MEDIA_FAILURE,

	GET_CHAIRSIDE_MEDIA_REQUEST,
	GET_CHAIRSIDE_MEDIA_SUCCESS,
	GET_CHAIRSIDE_MEDIA_FAILURE,

	GET_CHAIRSIDE_CATEGORY_LIST_REQUEST,
	GET_CHAIRSIDE_CATEGORY_LIST_SUCCESS,
	GET_CHAIRSIDE_CATEGORY_LIST_FAILURE,

	GET_CHAIRSIDE_MEDIA_LANGUAGE_REQUEST,
	GET_CHAIRSIDE_MEDIA_LANGUAGE_SUCCESS,
	GET_CHAIRSIDE_MEDIA_LANGUAGE_FAILURE,

	//// search schreenshot
	GET_SCREENSHOT_LIST_REQUEST,
	GET_SCREENSHOT_LIST_SUCCESS,
	GET_SCREENSHOT_LIST_FAILURE,

	GET_VIDEO_SECTION_REQUEST,
	GET_VIDEO_SECTION_SUCCESS,
	GET_VIDEO_SECTION_FAILURE,

	FILTER_BY_KEYWORD,
	FILTER_BY_LETEST,
	FILTER_BY_SHOW_ONLY_NEW,

	WEBSITE_MEDIA_CONFIG_REQUEST,
	WEBSITE_MEDIA_CONFIG_SUCCESS,
	WEBSITE_MEDIA_CONFIG_FAILURE,

	WEBSITE_MEDIA_LAYOUT_REQUEST,
	WEBSITE_MEDIA_LAYOUT_SUCCESS,
	WEBSITE_MEDIA_LAYOUT_FAILURE,

	WEBSITE_MEDIA_HTML_CODE_REQUEST,
	WEBSITE_MEDIA_HTML_CODE_SUCCESS,
	WEBSITE_MEDIA_HTML_CODE_FAILURE,

	WEBSITE_MEDIA_GET_PLAYLIST_DATA_REQUEST,
	WEBSITE_MEDIA_GET_PLAYLIST_DATA_SUCCESS,
	WEBSITE_MEDIA_GET_PLAYLIST_DATA_FAILURE,
	WEBSITE_MEDIA_PLAYLIST_POST_REQUEST,
	WEBSITE_MEDIA_PLAYLIST_POST_SUCCESS,
	WEBSITE_MEDIA_PLAYLIST_POST_FAILURE,
	WEBSITE_MEDIA_CONFIG_POST_REQUEST,
	WEBSITE_MEDIA_CONFIG_POST_SUCCESS,
	WEBSITE_MEDIA_CONFIG_POST_FAILURE,

	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAILURE,

	GET_INVOICEVIEW_REQUEST,
	GET_INVOICEVIEW_SUCCESS,
	GET_INVOICEVIEW_FAILURE,

	GET_AUTHORIZED_DEVICE_REQUEST,
	GET_AUTHORIZED_DEVICE_SUCCESS,
	GET_AUTHORIZED_DEVICE_FAILURE,

	REMOVE_AUTHORIZED_DEVICE_REQUEST,
	REMOVE_AUTHORIZED_DEVICE_SUCCESS,
	REMOVE_AUTHORIZED_DEVICE_FAILURE,

	POST_UNAUTHORIZED_DEVICE_REQUEST,
	POST_UNAUTHORIZED_DEVICE_SUCCESS,
	POST_UNAUTHORIZED_DEVICE_FAIL,

	GET_SUBSCRIBE_REQUEST,
	GET_SUBSCRIBE_SUCCESS,
	GET_SUBSCRIBE_FAILURE,

	GET_OPTION_REQUEST,
	GET_OPTION_SUCCESS,
	GET_OPTION_FAILURE,

	GET_ACCESS_CODE_REQUEST,
	GET_ACCESS_CODE_SUCCESS,
	GET_ACCESS_CODE_FAIL,

	RESET_ACCESS_CODE_REQUEST,
	RESET_ACCESS_CODE_SUCCESS,
	RESET_ACCESS_CODE_FAIL,

	POST_SUBSCRIBE_DEVICE_REQUEST,
	POST_SUBSCRIBE_DEVICE_SUCCESS,
	POST_SUBSCRIBE_DEVICE_FAILURE,


	GET_EMAIL_PREFERENCES_REQUEST,
	GET_EMAIL_PREFERENCES_SUCCESS,
	GET_EMAIL_PREFERENCES_FAILURE,

	POST_EMAIL_PREFERENCES_REQUEST,
	POST_EMAIL_PREFERENCES_SUCCESS,
	POST_EMAIL_PREFERENCES_FAILURE,

	GET_DEFAULT_CAPTION_SIZE_REQUEST,
	GET_DEFAULT_CAPTION_SIZE_SUCCESS,
	GET_DEFAULT_CAPTION_SIZE_FAIL,

	GET_EMAIL_PREFERENCES_LIST_REQUEST,
	GET_EMAIL_PREFERENCES_LIST_SUCCESS,
	GET_EMAIL_PREFERENCES_LIST_FAILURE,

	GET_WAITING_ROOM_CHANNEL_TRIVIA_REQUEST,
	GET_WAITING_ROOM_CHANNEL_TRIVIA_SUCCESS,
	GET_WAITING_ROOM_CHANNEL_TRIVIA_FAILURE,

	GET_WAITINGROOM_LIVE_WEATHER_DATA_REQUEST,
	GET_WAITINGROOM_LIVE_WEATHER_DATA_SUCCESS,
	GET_WAITINGROOM_LIVE_WEATHER_DATA_FAILURE,

	GET_WAITINGROOM_FOOTER_TIME_REQUEST,
	GET_WAITINGROOM_FOOTER_TIME_SUCCESS,
	GET_WAITINGROOM_FOOTER_TIME_FAILURE,

	GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_REQUEST,
	GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_SUCCESS,
	GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_FAILURE,

	GET_WAITINGROOM_MEDIA_CHANNEL_REQUEST,
	GET_WAITINGROOM_MEDIA_CHANNEL_SUCCESS,
	GET_WAITINGROOM_MEDIA_CHANNEL_FAILURE,

	RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST,
	RESTORE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS,
	RESTORE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE,

	DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST,
	DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS,
	DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE,

	POST_WAITINGROOM_MEDIA_FOOTER_TIME_REQUEST,
	POST_WAITINGROOM_MEDIA_FOOTER_TIME_SUCCESS,
	POST_WAITINGROOM_MEDIA_FOOTER_TIME_FAILURE,

	POST_WAITINGROOM_MEDIA_WEATHER_REQUEST,
	POST_WAITINGROOM_MEDIA_WEATHER_SUCCESS,
	POST_WAITINGROOM_MEDIA_WEATHER_FAILURE,

	POST_CHANNELWISE_CUSTOM_MESSAGES_REQUEST,
	POST_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS,
	POST_CHANNELWISE_CUSTOM_MESSAGES_FAILURE,

	GET_FOOTER_CUSTOM_MESSAGES_REQUEST,
	GET_FOOTER_CUSTOM_MESSAGES_SUCCESS,
	GET_FOOTER_CUSTOM_MESSAGES_FAILURE,

	DELETE_WAITINGROOM_CUSTOM_MESSAGES_REQUEST,
	DELETE_WAITINGROOM_CUSTOM_MESSAGES_SUCCESS,
	DELETE_WAITINGROOM_CUSTOM_MESSAGES_FAILURE,
	GET_WAITINGROOM_WEATHER_REQUEST,
	GET_WAITINGROOM_WEATHER_SUCCESS,
	GET_WAITINGROOM_WEATHER_FAILURE,

	GET_LOCATION_LISTING_REQUEST,
	GET_LOCATION_LISTING_SUCCESS,
	GET_LOCATION_LISTING_FAILURE,

	GET_LOCATION_MANAGER_LISTING_REQUEST,
	GET_LOCATION_MANAGER_LISTING_SUCCESS,
	GET_LOCATION_MANAGER_LISTING_FAILURE,

	GET_WEBSITE_MEDIA_LAYOUTS_REQUEST,
	GET_WEBSITE_MEDIA_LAYOUTS_SUCCESS,
	GET_WEBSITE_MEDIA_LAYOUTS_FAILURE,

	GET_WEBSITE_MEDIA_COLOURS_REQUEST,
	GET_WEBSITE_MEDIA_COLOURS_SUCCESS,
	GET_WEBSITE_MEDIA_COLOURS_FAILURE,

	GET_HELPER_TIMEZONE_REQUEST,
	GET_HELPER_TIMEZONE_SUCCESS,
	GET_HELPER_TIMEZONE_FAILURE,

	GET_HELPER_NEWSFEED_REQUEST,
	GET_HELPER_NEWSFEED_SUCCESS,
	GET_HELPER_NEWSFEED_FAILURE,

	POST_NEWSFEED_REQUEST,
	POST_NEWSFEED_SUCCESS,
	POST_NEWSFEED_FAILURE,

	GET_WAITINGROOM_DISPLAY_OPTIONS_REQUEST,
	GET_WAITINGROOM_DISPLAY_OPTIONS_SUCCESS,
	GET_WAITINGROOM_DISPLAY_OPTIONS_FAILURE,

	GET_WAITINGROOM_THEME_LIST_REQUEST,
	GET_WAITINGROOM_THEME_LIST_SUCCESS,
	GET_WAITINGROOM_THEME_LIST_FAILURE,

	DELETE_WAITINGROOM_MEDIA_CHANNEL_REQUEST,
	DELETE_WAITINGROOM_MEDIA_CHANNEL_SUCCESS,
	DELETE_WAITINGROOM_MEDIA_CHANNEL_FAILURE,

	MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_REQUEST,
	MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_SUCCESS,
	MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_FAILURE,

	UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_REQUEST,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_LOADING_REQUEST,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_SUCCESS,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_CC_STATUS_SUCCESS,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_FAILURE,

	CREATE_WAITING_ROOM_CHANNEL_REQUEST,
	CREATE_WAITING_ROOM_CHANNEL_SUCCESS,
	CREATE_WAITING_ROOM_CHANNEL_FAILURE,

	UPDATE_WAITING_ROOM_CHANNEL_REQUEST,
	UPDATE_WAITING_ROOM_CHANNEL_SUCCESS,
	UPDATE_WAITING_ROOM_CHANNEL_FAILURE,

	POST_UPDATE_WAITING_ROOM_CHANNEL_REQUEST,
	POST_UPDATE_WAITING_ROOM_CHANNEL_SUCCESS,
	POST_UPDATE_WAITING_ROOM_CHANNEL_FAILURE,
	ADD_LOCATION_REQUEST,
	ADD_LOCATION_SUCCESS,
	ADD_LOCATION_FAILURE,

	SEND_MANAGER_INVITE_REQUEST,
	SEND_MANAGER_INVITE_SUCCESS,
	SEND_MANAGER_INVITE_FAILURE,
	PUT_CHANNELWISE_CUSTOM_MESSAGES_REQUEST,
	PUT_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS,
	PUT_CHANNELWISE_CUSTOM_MESSAGES_FAILURE,
	NEW_WAITING_ROOM_TIMELINE_EDUCATION_REQUEST,
	NEW_WAITING_ROOM_TIMELINE_EDUCATION_SUCCESS,
	NEW_WAITING_ROOM_TIMELINE_EDUCATION_FAILURE,
	NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_REQUEST,
	NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_SUCCESS,
	NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_FAILURE,
	POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST,
	POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_SUCCESS,
	POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_FAILURE,

	UNASSIGN_MANAGER_REQUEST,
	UNASSIGN_MANAGER_SUCCESS,
	UNASSIGN_MANAGER_FAILURE,

	NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
	UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
	REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
	GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_REQUEST,
	GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_SUCCESS,
	GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_FAILURE,
	GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_REQUEST,
	GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_SUCCESS,
	GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_FAILURE,
	GET_MYVIDEOS_CATEGORY_LIST_REQUEST,
	GET_MYVIDEOS_CATEGORY_LIST_SUCCESS,
	GET_MYVIDEOS_CATEGORY_LIST_FAILURE,
	UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_REQUEST,
	UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_SUCCESS,
	UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_FAILURE,
	POST_REQUEST_PRACTICE_VIDEO_REQUEST,
	POST_REQUEST_PRACTICE_VIDEO_SUCCESS,
	POST_REQUEST_PRACTICE_VIDEO_FAILURE,
	WAITING_ROOM_CHANNEL_SLIDE_REQUEST,
	WAITING_ROOM_CHANNEL_SLIDE_SUCCESS,
	WAITING_ROOM_CHANNEL_SLIDE_FAILURE,
	GET_WEBSITE_MEDIA_CONFIG_REQUEST,
	GET_WEBSITE_MEDIA_CONFIG_SUCCESS,
	GET_WEBSITE_MEDIA_CONFIG_FAILURE,
	POST_WEBSITE_MEDIA_CONFIG_REQUEST,
	POST_WEBSITE_MEDIA_CONFIG_SUCCESS,
	POST_WEBSITE_MEDIA_CONFIG_FAILURE,

	/* add by milep */
	PUBLISH_WEBSITE_MEDIA_REQUEST,
	PUBLISH_WEBSITE_MEDIA_SUCCESS,
	PUBLISH_WEBSITE_MEDIA_FAILURE,

	POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_REQUEST,
	POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_SUCCESS,
	POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_FAILURE,
	/* add by milep  end*/

	GET_WEBSITE_MEDIA_HTML_CODE_REQUEST,
	GET_WEBSITE_MEDIA_HTML_CODE_SUCCESS,
	GET_WEBSITE_MEDIA_HTML_CODE_FAILURE,
	REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST,
	REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS,
	REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE,


	GET_INVOICE_EMAIL_REQUEST,
	GET_INVOICE_EMAIL_SUCCESS,
	GET_INVOICE_EMAIL_FAILURE,

	POST_INVOICE_EMAIL_REQUEST,
	POST_INVOICE_EMAIL_SUCCESS,
	POST_INVOICE_EMAIL_FAILURE,

	GET_PRACTICE_REQUEST,
	GET_PRACTICE_SUCCESS,
	GET_PRACTICE_FAIL,

	UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_REQUEST,
	GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_SUCCESS,
	GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_FAILURE,
	GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_REQUEST,
	GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_SUCCESS,
	GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_FAILURE,

	SET_WAITING_ROOM_TIMELINE_NEW_SLIDE,
	CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE,
	CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_REQUEST,
	CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS,

	ADD_SOCIAL_MEDIA_REQUEST,
	ADD_SOCIAL_MEDIA_SUCCESS,
	ADD_SOCIAL_MEDIA_FAILURE,

	GET_SOCIAL_REQUEST,
	GET_SOCIAL_SUCCESS,
	GET_SOCIAL_FAILURE,

	REMOVE_SOCIAL_REQUEST,
	REMOVE_SOCIAL_SUCCESS,
	REMOVE_SOCIAL_FAILURE,

	GET_TIMELINE_REQUEST,
	GET_TIMELINE_SUCCESS,
	GET_TIMELINE_FAILURE,

	POST_TIMELINE_REQUEST,
	POST_TIMELINE_SUCCESS,
	POST_TIMELINE_FAILURE,

	GET_USER_PROFILE_REQUEST,
	GET_USER_PROFILE_SUCCESS,
	GET_USER_PROFILE_FAIL,
	// Added by Dixit Solanki ( work releted operatory chanel)

	// Create operatory Channel
	CREATE_OPERATORY_CHANNEL_REQUEST,
	CREATE_OPERATORY_CHANNEL_SUCCESS,
	CREATE_OPERATORY_CHANNEL_FAILURE,
	// get operatory channel list
	GET_OPERATORY_MEDIA_CHANNEL_LIST_REQUEST,
	GET_OPERATORY_MEDIA_CHANNEL_LIST_SUCCESS,
	GET_OPERATORY_MEDIA_CHANNEL_LIST_FAILURE,
	// delete operatory Channel
	DELETE_OPERATORY_CHANNEL_REQUEST,
	DELETE_OPERATORY_CHANNEL_SUCCESS,
	DELETE_OPERATORY_CHANNEL_FAILURE,
	// duplicate operatory Channel
	DUPLICATE_OPERATORY_CHANNEL_REQUEST,
	DUPLICATE_OPERATORY_CHANNEL_SUCCESS,
	DUPLICATE_OPERATORY_CHANNEL_FAILURE,
	//  get operatory info Channel
	GET_OPERATORY_CHANNEL_REQUEST,
	GET_OPERATORY_CHANNEL_SUCCESS,
	GET_OPERATORY_CHANNEL_FAILURE,
	//  restore operatory info Channel
	RESTORE_OPERATORY_CHANNEL_REQUEST,
	RESTORE_OPERATORY_CHANNEL_SUCCESS,
	RESTORE_OPERATORY_CHANNEL_FAILURE,
	// update  operatory Channel Rename
	UPDATE_OPERATORY_CHANNEL_NAME_REQUEST,
	UPDATE_OPERATORY_CHANNEL_NAME_SUCCESS,
	UPDATE_OPERATORY_CHANNEL_NAME_FAILURE,
	// get Theme operatory Channel
	GET_OPERATORY_THEME_LIST_REQUEST,
	GET_OPERATORY_THEME_LIST_SUCCESS,
	GET_OPERATORY_THEME_LIST_FAILURE,
	// get options operatory Channel
	GET_OPERATORY_DISPLAY_OPTIONS_REQUEST,
	GET_OPERATORY_DISPLAY_OPTIONS_SUCCESS,
	GET_OPERATORY_DISPLAY_OPTIONS_FAILURE,
	// get whether operatory Channel
	GET_OPERATORY_LIVE_WEATHER_DATA_REQUEST,
	GET_OPERATORY_LIVE_WEATHER_DATA_SUCCESS,
	GET_OPERATORY_LIVE_WEATHER_DATA_FAILURE,
	// update options operatory Channel
	POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_REQUEST,
	POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_SUCCESS,
	POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_FAILURE,

	// end by Dixit Solanki ( work releted operatory chanel)
	ADD_CUSTOM_LAYOUT_ROW,
	UPDATE_CUSTOM_LAYOUT_ROW,

	GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_REQUEST,
	GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_SUCCESS,
	GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_FAILURE,


	POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
	POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
	POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,

	DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
	DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
	DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,

	ADD_CATEGORY_SPECIALIZATION,
	CHAIRSIDE_RESET_DEFAULT,

	GET_CHAIRSIDE_MEDIA_CC_REQUEST,
	GET_CHAIRSIDE_MEDIA_CC_SUCCESS,
	GET_CHAIRSIDE_MEDIA_CC_FAILURE,

	UPDATE_CHAIRSIDE_MEDIA_CC_REQUEST,
	UPDATE_CHAIRSIDE_MEDIA_CC_SUCCESS,
	UPDATE_CHAIRSIDE_MEDIA_CC_FAILURE,

	DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST,
	DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS,
	DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE,

	POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST,
	POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS,
	POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE,

	POST_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST,
	POST_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS,
	POST_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE,

	DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST,
	DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS,
	DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE,

	GET_CHAIRSIDE_MEDIA_CC_LIST_REQUEST,
	GET_CHAIRSIDE_MEDIA_CC_LIST_SUCCESS,
	GET_CHAIRSIDE_MEDIA_CC_LIST_FAILURE,

	SET_SELECTED_SPECIALIZATION_CATEGORY,
	SEND_SCREENSHOT_REQUEST,
	SEND_SCREENSHOT__RESET_REQUEST,
	SEND_SCREENSHOT_SUCCESS,
	SEND_SCREENSHOT_FAILURE,

	GET_CUSTOM_MESSAGES_REQUEST,
	GET_CUSTOM_MESSAGES_SUCCESS,
	GET_CUSTOM_MESSAGES_FAILURE,

	POST_CUSTOM_MESSAGES_REQUEST,
	POST_CUSTOM_MESSAGES_SUCCESS,
	POST_CUSTOM_MESSAGES_FAILURE,

	RESET_CUSTOM_MESSAGES_REQUEST,
	RESET_CUSTOM_MESSAGES_SUCCESS,
	RESET_CUSTOM_MESSAGES_FAILURE,

	GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_REQUEST,
	GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_SUCCESS,
	GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_FAILURE,

	GET_OPERATORY_VIDEO_REQUEST,
	GET_OPERATORY_VIDEO_SUCCESS,
	GET_OPERATORY_VIDEO_FAILURE,
	/*add by milap waiting Room schedule*/
	/*add by milap waiting Room schedule*/
	GET_WAITING_ROOM_SCHEDULE_REQUEST,
	GET_WAITING_ROOM_SCHEDULE_SUCCESS,
	GET_WAITING_ROOM_SCHEDULE_FAILURE,
	//  Waiting Room Shedule
	CREATE_WAITING_ROOM_SCHEDULE_REQUEST,
	CREATE_WAITING_ROOM_SCHEDULE_SUCCESS,
	CREATE_WAITING_ROOM_SCHEDULE_FAILURE,
	//  Waiting Room Footer Wether Data
	GET_WAITINGROOM_FOOTER_WEATHER_DATA_REQUEST,
	GET_WAITINGROOM_FOOTER_WEATHER_DATA_SUCCESS,
	GET_WAITINGROOM_FOOTER_WEATHER_DATA_FAILURE,
	UPDATE_OPEROTORY_MEDIA_CHANNEL_CC_STATUS_SUCCESS,
	UPDATE_OPERATORYGROOM_MEDIA_CHANNEL_LOADING_REQUEST,
	//  Waiting Room Publish Timeline
	PUBLISH_WAITINGROOM_TIMELINE_REQUEST,
	PUBLISH_WAITINGROOM_TIMELINE_SUCCESS,
	PUBLISH_WAITINGROOM_TIMELINE_FAILURE,
	PUBLISH_WAITINGROOM_TIMELINE_NULL,
	UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,
	UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,

	UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	GET_PRACTICE_PAYMENT_INFO_REQUEST,
	GET_PRACTICE_PAYMENT_INFO_SUCCESS,
	GET_PRACTICE_PAYMENT_INFO_FAIL,

	POST_PRACTICE_PAYMENT_INFO_REQUEST,
	POST_PRACTICE_PAYMENT_INFO_SUCCESS,
	POST_PRACTICE_PAYMENT_INFO_FAIL,
	POST_DUPLICATES_WITH_MEDIA_CATEGORIES,

	GET_MEDIA_CATEGORY_IMAGES_REQUEST,
	GET_MEDIA_CATEGORY_IMAGES_SUCCESS,
	GET_MEDIA_CATEGORY_IMAGES_FAIL,

	POST_TOS_COMPLAINT_REQUEST,
	POST_TOS_COMPLAINT_SUCCESS,
	POST_TOS_COMPLAINT_FAIL,

	GET_CHAIRSIDE_MEDIA__RECENT_PLAY_REQUEST,
	GET_CHAIRSIDE_MEDIA__RECENT_PLAY_SUCCESS,
	GET_CHAIRSIDE_MEDIA__RECENT_PLAY_FAILURE,

	POST_PRACTICE_CONTACTUS_REQUEST,
	POST_PRACTICE_CONTACTUS_SUCCESS,
	POST_PRACTICE_CONTACTUS_FAILURE,

	RESET_DEFAULT_WEBSITE_VIDEO_REQUEST,
	RESET_DEFAULT_WEBSITE_VIDEO_SUCCESS,
	RESET_DEFAULT_WEBSITE_VIDEO_FAILURE,
	EXTEND_TRIAL_REQUEST,
	EXTEND_TRIAL_SUCCESS,
	EXTEND_TRIAL_FAILURE,
	GET_SHARE_VIDEO_LINK_REQUEST,
	GET_SHARE_VIDEO_LINK_SUCCESS,
	GET_SHARE_VIDEO_LINK_FAILURE,
	SHARE_VIDEO_LINK_REQUEST,
	SHARE_VIDEO_LINK_SUCCESS,
	SHARE_VIDEO_LINK_FAILURE,
	SHARE_VIDEO_LINK_CLEAR_REQUEST,
	SHARE_VIDEO_LINK_ID_REQUEST,

	SEND_EMBED_CODE_TO_EMAIL_REQUEST,
	SEND_EMBED_CODE_TO_EMAIL_SUCCESS,
	SEND_EMBED_CODE_TO_EMAIL_FAIL,

	GET_DESIGNATE_CARD_REQUEST,
	GET_DESIGNATE_CARD_SUCCESS,
	GET_DESIGNATE_CARD_FAILURE,
	SELECTED_CARD,
	GET_DESIGNATE_CARD_MULTIPLE_REQUEST,
	GET_DESIGNATE_CARD_MULTIPLE_SUCCESS,
	GET_DESIGNATE_CARD_MULTIPLE_FAILURE,

	GET_CORPORAT_HARDWARE_CALC_REQUEST,
	GET_CORPORAT_HARDWARE_CALC_SUCCESS,
	GET_CORPORAT_HARDWARE_CALC_FAILURE,

	GET_CORPORAT_HARDWARE_LIST_REQUEST,
	GET_CORPORAT_HARDWARE_LIST_SUCCESS,
	GET_CORPORAT_HARDWARE_LIST_FAILURE,

	GET_CORPORAT_PRACTICES_LIST_REQUEST,
	GET_CORPORAT_PRACTICES_LIST_SUCCESS,
	GET_CORPORAT_PRACTICES_LIST_FAILURE,

	PURCHASE_CORPORAT_HARDWARE_REQUEST,
	PURCHASE_CORPORAT_HARDWARE_SUCCESS,
	PURCHASE_CORPORAT_HARDWARE_FAILURE,
	POST_CORPORATE_MULTIPLE_CARD_REQUEST,
	POST_CORPORATE_MULTIPLE_CARD_SUCCESS,
	POST_CORPORATE_MULTIPLE_CARD_FAILURE,
	GET_UNASSIGN_LOCATION_LISTING_SUCCESS,
	UPDATE_SELECTED_CARD,
	SHOW_ERROR,
	UPDATE_HARDWARE_TOTAL,

	SAME_AS_MAIN_PRACTICE_REQUEST,
	SAME_AS_MAIN_PRACTICE_SUCCESS,
	SAME_AS_MAIN_PRACTICE_FAIL,
	UPDATE_NEW_LOCATION_PRACTICE_REQUEST,
	ADD_NEW_LOCATION_PRACTICE_REQUEST,
	SET_GOOGLE_TV_TEST_DRIVE_ON_REQUEST,
	SET_GOOGLE_TV_TEST_DRIVE_MENU_ITEM,
	SET_GOOGLE_TV_TEST_DRIVE_CAT_IS_OPEN,
	GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_REQUEST,
	GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_SUCCESS,
	GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_FAILURE,
	CREATE_CHAIRSIDE_PLAYLIST_REQUEST,
	CREATE_CHAIRSIDE_PLAYLIST_SUCCESS,
	CREATE_CHAIRSIDE_PLAYLIST_FAILURE,
	GET_CHAIRSIDE_PLAYLIST_FAILURE,
	GET_CHAIRSIDE_PLAYLIST_SUCCESS,
	GET_CHAIRSIDE_PLAYLIST_REQUEST,
	GET_SINGLE_CHAIRSIDE_PLAYLIST_REQUEST,
	GET_SINGLE_CHAIRSIDE_PLAYLIST_SUCCESS,
	GET_SINGLE_CHAIRSIDE_PLAYLIST_FAILURE,
	UPDATE_CHAIRSIDE_PLAYLIST_REQUEST,
	UPDATE_CHAIRSIDE_PLAYLIST_SUCCESS,
	UPDATE_CHAIRSIDE_PLAYLIST_FAILURE,
	GET_CHAIRSIDE_PLAY_VIDEO_FAILURE,
	GET_CHAIRSIDE_PLAY_VIDEO_SUCCESS,
	GET_CHAIRSIDE_PLAY_VIDEO_REQUEST,
	PUBLISH_CHAIRSIDE_PLAYLIST_REQUEST,
	PUBLISH_CHAIRSIDE_PLAYLIST_SUCCESS,
	PUBLISH_CHAIRSIDE_PLAYLIST_FAILURE,
	REMOVE_CHAIRSIDE_PLAYLIST_REQUEST,
	REMOVE_CHAIRSIDE_PLAYLIST_SUCCESS,
	REMOVE_CHAIRSIDE_PLAYLIST_FAILURE,
	DUPLICATE_CHAIRSIDE_PLAYLIST_REQUEST,
	DUPLICATE_CHAIRSIDE_PLAYLIST_SUCCESS,
	DUPLICATE_CHAIRSIDE_PLAYLIST_FAILURE,
	PUBLISH_CHAIRSIDE_PLAYLIST_RESET_MESSAGE,
	SET_CHAIRSIDE_PLAYLIST_MENU_ACTIVE,
	SET_CHAIRSIDE_PLAYLIST_VIDEO_INDEX,
	DEFAULT_PROFILE_REQUEST,
	DEFAULT_PROFILE_SUCCESS,
	DEFAULT_PROFILE_FAILURE,
	SET_UPGRADE_DOWNGRADE

} from '../actions/actions';
import { TWITTERPROVIDER, FBPROVIDER, extendTrialStatus} from '../../data/comman';

const INITIAL_STATE = {
	practiceInfo: [],
	letestTos: [],
	loading: true,
	isCompleted: false,
	error: {
		errors: []
	},
	subscribeAdditionalDevice: [],
	country: [],
	specializations: [],
	lead_source_types: [],
	practice_softwares: [],
	practice_customer_types: [],
	waitingRoomMediaChannels: [],
	practiceProfileInfo: {},
	newPracticeProfileInfo:{},
	practiceUsersList: [],
	getownersId: [],
	optionList: [],
	ownersProfileinfo: [],
	updateOwnersProfileinfo: [],
	practice_user_type: [],
	phoneVerificationInfo: {},
	defaultProfilePictures: [],
	practice_user_roles: [],
	practice_subscription_type: [],
	media_language: [],
	getDomainList: {},
	updatedpracticeUserInfo: [],
	practiceUserLoginInfo: {},
	practiceUserLogoutInfo: [],
	newProfileInfo: [],
	progress: null,
	practiceUserUpdatePasscodeInfo: [],
	UpdateUserProfileImage: false,
	practiceUserdeleteInfo: false,
	phoneVerifyed: {},
	isProfileCreated: false,
	sendRecoverCode: false,
	websiteMediaState: [],
	chairSideMediaState: [],
	chairSideCategoryList: [],
	csVideoSection: {},
	websiteMediaLanguageState: [],
	websiteMediaConfig: [],
	websiteMediaLayout: '',
	websiteMediaHtml: [],
	websiteMediaGetPlayListData: [],
	Unauthorized: [],
	subscribeList: [],
	helperTimezone: [],
	customMessages: [],
	waitingRoomsChannelTrivia: [],
	liveWeatherData: {},
	liveFooterTime: {},
	authorizedUserList: [],
	weatherLocation: {},
	websiteMediaLayouts: [],
	websiteMediaColours: [],
	helperNewsfeeds: [],
	displayOptions: {},
	waitingRoomChannel: {},
	getEmailPreferences: [],
	getEmailPreferencesList: [],
	defaultCaptionSize: [],
	practiceUserAccountSetting: [],
	practiceUserAccountInfo: {
		// selectedGender:{}
	},
	newChannel: {},
	newSlide:{},
	manageChannel: {},
	addLocationResponce: {},
	getAccessCode: {},
	resetAccessCode: {},
	myVideosTimeline: [],
	stockLibrarySearch: [],
	myVideosCategories: [],
	waitingRoomChannelSlides: [],
	websiteMediaConfigs: {},
	copyHTML: '',
	getSubscribePriceCalc: [],
	userIdentifyInfo: {
		otp: ''
	},
	getnotificationlist: {},
	newlocationinfo: {},
	subscribeInfo: {},
	getSubscribeinfo: {},
	authorizedDeviceList: {},
	getSubscribePriceOption: {},
	subscribeAdditionalDomain: {},
	createDomaininfo: {},
	userVerificationCode: {},
	getOnlyNewnotificationlist: {},
	referFreeMonthInfo: {},
	authorizedUser: {},
	educationTimeline: [],
	buttonLoading: false,
	buttonLoading2: false,
	buttonLoading3: false,
	buttonLoading4: false,
	updateError: "",
	socailMedia: [],
	channelTimeLine: [],
	managewaitingRoomMediaChannel: {},

	// operatory room
	operatoryRoomEnterainmentVideos: [],

	// operatory state
	operatoryMediaChannels: [],
	manageOperatoryChannel: {},
	playlists: [],
	isResetDefaultChairside: false,
	csVideoSectionTypes: [],
	chairSideMediaCC: {},
	chairSideMediaCCList: [],
	cs_video_section_types: [],
	searchSchreenshotList: [],
	waitingRoomSchedule:{},
	waitingRoomFooterWeather:{},
	waitingRoomPublishTimeline:null,
	getPracticePaymentinfo:{},
	duplicatesWithCategories:{},
	getMediaCategoryImages:[],
	practiceSelected: false,
	tosComplaint:{
		is_sent:false
	},
	practiceSecurityQuestion:[],
	system_language:[],
	chairSideMediarecentPlay:[],
	ownership: [],
	sendScreenshot:{},
	practicecontactus:false,
	designateCard: {},
	designateCardMultiple: {},
	isCorporate: 0,
	selectedCard: {},
	corporathardwarecalc:{},
	corporathardwarelist:{},
	corporatpracticeslist:[],
	purchaseCorporatHardware:{},
	unAssignedLocations: [],
	mainPracticeAddress:{},
	avelablePlugin:[],
	shareVideoData:{},
	shareVideoList:{},
	shareVideoId:null,
	googleTvTestDrive:false,
	googleTvTestDriveMenuItem:1,
	googleTvTestDriveChairsideMenuItem:1,
	catList:[],
	chairsidePlaylistMediaData:[],
	chairsidePlaylist:{},
	bgColor: 'primary',
	savePlaylistmessage:'Save Playlist',
	isChairsideMenuActiveIndex: 1,
	chairsideVideoActiveIndex: 0,
	loadingDefault: false,
	isUpgradePlan:false,
};

const chairsidemediaUpdate = (chairSideMediaState, chairside_media_id, change, field, secondField, secondChange) => {
	var mediaIndex = chairSideMediaState.findIndex(x => x.chairside_media_id == chairside_media_id);
	chairSideMediaState[mediaIndex].chairside_media[field] = change;
	if(secondField){
		chairSideMediaState[mediaIndex].chairside_media[secondField] = secondChange;
	}
	return chairSideMediaState;
}

const fn = (state = INITIAL_STATE, action) => {
	 console.log("task reducer"  , action.payload);

	switch (action.type) {
		//GET_PRACTICEINFO_REQUEST
		case POST_DUPLICATES_WITH_MEDIA_CATEGORIES:{
			return {
				...state,
				duplicatesWithCategories:action.payload
			}
		}
		case GET_PRACTICEINFO_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				practiceInfo: null,
				error: null,
			};
		}
		case GET_PRACTICEINFO_SUCCESS: {
			var practiceUserLoginInfo = {}
			var practiceInfo= action.practiceInfo
			var plugin = action.practiceInfo?.plan?.plugins
			if(practiceInfo.auth_info){
				practiceUserLoginInfo = practiceInfo.auth_info
			}
			return {
				...state,
				isCompleted: true,
				loading: false,
				practiceInfo: action.practiceInfo,
				avelablePlugin:plugin,
				practiceUserLoginInfo: practiceUserLoginInfo,
				error: false,
			};
		}
		case GET_PRACTICEINFO_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				practiceInfo: {},
				error: action.practiceInfo,
			};
		}

		//UPDATE_PRACTICEINFO_REQUEST
		case UPDATE_PRACTICEINFO_REQUEST: {
			return {
				...state,
				loading: true,
				practiceInfo: { ...action.practiceInfo },
				error: null,
			};
		}

		//SIGNOUT_REQUEST
		case SIGNOUT_REQUEST: {
			return {
				...state,
				error: null,
			};
		}

		case SIGNOUT_SUCCESS: {
			return {
				...INITIAL_STATE //Always return the initial state
			};
		}

		case SIGNOUT_FAIL: {
			return {
				...state,
				error: action.error,
			};
		}

		//SEND_PRACTICE_RECOVERY_CODE_REQUEST
		case SEND_PRACTICE_RECOVERY_CODE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingRecoveryCode: true,
				successSend: action.params.practice_id ? true : false,
				sendRecoverCode: action.params.practice_id ? state.sendRecoverCode : null,
				practiceSelected: action.params.practice_id ? false : true,
				error: {},
			};
		}
		case SEND_PRACTICE_RECOVERY_CODE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingRecoveryCode: false,
				sendRecoverCode: action.sendRecoverCode,
				successSend: true,
				practiceSelected: action.sendRecoverCode.practice_id ? false : true,
				error: {},
			};
		}
		case SEND_PRACTICE_RECOVERY_CODE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingRecoveryCode: false,
				sendRecoverCode: false,
				successSend: false,
				error: action.error,
			};
		}

		case SEND_PRACTICE_RECOVERY_CODE_FAIL_ERROR: {
			return {
				...state,
				error: action.error,
			};
		}

		//GET_TOS_REQUEST
		case GET_TOS_REQUEST: {
			return {
				...state,
				isCompleted: false,
				letestTos: [],
				loading: true,
				error: null,
			};
		}
		case GET_TOS_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				letestTos: action.letestTos,
				loading: false,
				error: false,
			};
		}
		case GET_TOS_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				letestTos: [],
				error: action.payload,
			};
		}

		//WEBSITE_MEDIA
		case WEBSITE_MEDIA_REQUEST:
			return {
				...state,
				loading: true  //state.websiteMediaState.length > 0 ? false : true,
			};

		case WEBSITE_MEDIA_SUCCESS:
			return {
				...state,
				loading: false,
				websiteMediaState: action.websiteMediaState,
				error: '',
			};
		case WEBSITE_MEDIA_FAILURE:
			return {
				...state,
				loading: false,
				websiteMediaState: [],
				error: action.error,
			};

		//WEBSITE_MEDIA CONFIG
		case WEBSITE_MEDIA_CONFIG_REQUEST:
			return {
				...state,
				loading: false,
			};

		case WEBSITE_MEDIA_CONFIG_SUCCESS:
			return {
				...state,
				loading: false,
				websiteMediaConfig: action.websiteMediaConfig,
				error: '',
			};
		case WEBSITE_MEDIA_CONFIG_FAILURE:
			return {
				...state,
				loading: true,
				websiteMediaConfig: [],
				error: action.payload,
			};

		// WEBSITE MEDIA LAYOUT
		case WEBSITE_MEDIA_LAYOUT_REQUEST:
			return {
				...state,
				loading: false,
			};

		case WEBSITE_MEDIA_LAYOUT_SUCCESS:
			return {
				...state,
				loading: false,
				websiteMediaLayout: action.websiteMediaLayout,
				error: '',
			};
		case WEBSITE_MEDIA_LAYOUT_FAILURE:
			return {
				...state,
				loading: true,
				websiteMediaLayout: [],
				error: action.payload,
			};

		// WEBSITE MEDIA HTML
		case WEBSITE_MEDIA_HTML_CODE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case WEBSITE_MEDIA_HTML_CODE_SUCCESS:
			return {
				...state,
				loading: false,
				websiteMediaHtml: action.websiteMediaHtml,
				error: '',
			};
		case WEBSITE_MEDIA_HTML_CODE_FAILURE:
			return {
				...state,
				loading: false,
				// websiteMediaHtml: [],
				error: action.payload,
			};

		// WEBSITE MEDIA GET PLAYLIST DATA
		case WEBSITE_MEDIA_GET_PLAYLIST_DATA_REQUEST:
			return {
				...state,
				loading: false,
			};
		case WEBSITE_MEDIA_GET_PLAYLIST_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				websiteMediaGetPlayListData: action.websiteMediaGetPlayListData,
				error: '',
			};
		case WEBSITE_MEDIA_GET_PLAYLIST_DATA_FAILURE:
			return {
				...state,
				loading: true,
				websiteMediaGetPlayListData: [],
				error: action.payload,
			};

		// CHAIRSIDE_MEDIA CONFIG
		case GET_CHAIRSIDE_MEDIA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_CHAIRSIDE_MEDIA_SUCCESS:
			return {
				...state,
				loading: false,
				chairSideMediaState: action.chairSideMediaState,
				error: '',
			};
		case GET_CHAIRSIDE_MEDIA_FAILURE:
			return {
				...state,
				loading: false,
				chairSideMediaState: [],
				error: action.payload,
			};
		// CHAIRSIDE MEDIA CATEGORY LIST
		case GET_CHAIRSIDE_CATEGORY_LIST_REQUEST:
			return {
				...state,
				// loading: true,
			};
		case GET_CHAIRSIDE_CATEGORY_LIST_SUCCESS:
			return {
				...state,
				// loading: false,
				chairSideCategoryList: action.chairSideCategoryList,
				error: '',
			};
		case GET_CHAIRSIDE_CATEGORY_LIST_FAILURE:
			return {
				...state,
				// loading: false,
				// chairSideCategoryList: [],
				error: action.payload,
			};
		// CHAIRSIDE LANGUAGE LIST
		case GET_CHAIRSIDE_MEDIA_LANGUAGE_REQUEST:
			return {
				...state,
				loading: false,
			};
		case GET_CHAIRSIDE_MEDIA_LANGUAGE_SUCCESS:
			return {
				...state,
				loading: false,
				websiteMediaLanguageState: action.websiteMediaLanguageState,
				error: '',
			};
		case GET_CHAIRSIDE_MEDIA_LANGUAGE_FAILURE:
			return {
				...state,
				loading: true,
				websiteMediaLanguageState: [],
				error: action.payload,
			};

		// get chairside video section
		case GET_VIDEO_SECTION_REQUEST:
			return {
				...state,
				csVideoSection: {},
				error: null,
			}

		case GET_VIDEO_SECTION_SUCCESS:
			return {
				...state,
				csVideoSection: action.csVideoSection,
				error: null,
			}

		case GET_VIDEO_SECTION_FAILURE:
			return {
				...state,
				csVideoSection: {},
				error: action.error
			}

		case GET_SCREENSHOT_LIST_REQUEST:
			return {
				...state,
				// searchSchreenshotList: [],
				error: null,
			}

		case GET_SCREENSHOT_LIST_SUCCESS:
			return {
				...state,
				searchSchreenshotList: action.searchSchreenshotList,
			}

		case GET_SCREENSHOT_LIST_FAILURE:
			return {
				...state,
				searchSchreenshotList: [],
				error: action.error,
			}

		// POST WEBSITE MEDIA PLAYLIST
		case WEBSITE_MEDIA_PLAYLIST_POST_REQUEST:
			return {
				...state,
				isCompleted: false,
				websiteMediaGetPlayListData: [],
				successMessage: '',
				loading: true,
				error: null,
			};

		case WEBSITE_MEDIA_PLAYLIST_POST_SUCCESS:
			return {
				...state,
				isCompleted: true,
				websiteMediaGetPlayListData: action.websiteMediaGetPlayListData,
				successMessage: action.successMessage,
				loading: false,
				error: null,
			};

		case WEBSITE_MEDIA_PLAYLIST_POST_FAILURE:
			return {
				...state,
				isCompleted: false,
				websiteMediaGetPlayListData: [],
				successMessage: '',
				loading: true,
				error: action.error,
			};
		// POST WEBSITE MEDIA CONFIG
		case WEBSITE_MEDIA_CONFIG_POST_REQUEST:
			return {
				...state,
				isCompleted: false,
				websiteMediaConfig: [],
				successMessage: '',
				loading: true,
				error: '',
			};
		case WEBSITE_MEDIA_CONFIG_POST_SUCCESS:
			return {
				...state,
				isCompleted: true,
				websiteMediaConfig: action.websiteMediaConfig,
				successMessage: action.successMessage,
				loading: false,
				error: '',
			};
		case WEBSITE_MEDIA_CONFIG_POST_FAILURE:
			return {
				...state,
				isCompleted: false,
				websiteMediaConfig: [],
				successMessage: '',
				loading: true,
				error: action.error,
			};
		//POST_ACCEPT_TOS_REQUEST
		case POST_ACCEPT_TOS_REQUEST: {
			return {
				...state,
				isCompleted: false,
				AcceptTos: [],
				loading: true,
				error: null,
			};
		}
		case POST_ACCEPT_TOS_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				AcceptTos: action.AcceptTos,
				loading: false,
				error: action.AcceptTos,
			};
		}
		case POST_ACCEPT_TOS_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				AcceptTos: [],
				error: action.AcceptTos,
			};
		}

		//GET_PRACTICEMOREINFO_REQUEST
		case GET_PRACTICEMOREINFO_REQUEST: {
			return {
				...state,
				isCompleted: false,
				country: [],
				specializations: [],
				lead_source_types: [],
				practice_softwares: [],
				practice_customer_types: [],
				practice_user_type: [],
				practice_user_roles: [],
				practice_subscription_type: [],
				media_language: [],
				cs_video_section_types: [],
				loadingMoreinfo: true,
				getGender:[],
				system_language:[]
				// error: null,
			};
		}
		case GET_PRACTICEMOREINFO_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				country: action.country,
				specializations: action.specializations,
				lead_source_types: action.lead_source_types,
				practice_softwares: action.practice_softwares,
				practice_customer_types: action.practice_customer_types,
				practice_user_type: action.practice_user_type,
				practice_user_roles: action.practice_user_roles,
				practice_subscription_type: action.practice_subscription_type,
				media_language: action.media_language,
				system_language:action.system_language,
				cs_video_section_types: action.cs_video_section_types,
				getGender:action.getGender,
				loadingMoreinfo: false,
				// error: false,
			};
		}
		case GET_PRACTICEMOREINFO_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingMoreinfo: false,
				country: [],
				specializations: [],
				lead_source_types: [],
				practice_softwares: [],
				practice_customer_types: [],
				practice_user_type: [],
				practice_user_roles: [],
				practice_subscription_type: [],
				media_language: [],
				cs_video_section_types: [],
				error: action.payload,
			};
		}

		//practiceProfileInfo
		case GET_PRACTICE_PROFILE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				// practiceProfileInfo: action.practiceProfileInfo,
				loadingPractiveProfile: true,
				error: null,
			};
		}
		case GET_PRACTICE_PROFILE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				practiceProfileInfo: action.practiceProfileInfo,
				loadingPractiveProfile: false,
				// error: false,
			};
		}
		case GET_PRACTICE_PROFILE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingPractiveProfile: false,
				practiceProfileInfo: action.practiceProfileInfo,
				error: action.payload,
			};
		}

		//practiceProfileInfo
		case GET_PRACTICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				currentPracticeInfo: action.currentPracticeInfo,
				loading: true,
				error: null,
			};
		}
		case GET_PRACTICE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				currentPracticeInfo: action.currentPracticeInfo,
				loading: false,
				error: false,
			};
		}
		case GET_PRACTICE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				currentPracticeInfo: action.currentPracticeInfo,
				error: action.payload,
			};
		}

		//UPDATE_PRACTICE_REQUEST

		case ADD_NEW_LOCATION_PRACTICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				newPracticeProfileInfo: {}
				// error: null,
			};
		}

		case UPDATE_NEW_LOCATION_PRACTICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				newPracticeProfileInfo: {...state.newPracticeProfileInfo,...action.newPracticeProfileInfo}
				// error: null,
			};
		}
		
		//UPDATE_PRACTICE_REQUEST
		case UPDATE_PRACTICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				practiceProfileInfo: { ...action.practiceProfileInfo },
				// error: null,
			};
		}

		case UPDATE_PRACTICE_SUCCESS: {
			var practiceProfileInfo = state.practiceProfileInfo;
			// practiceProfileInfo = action.practiceProfileInfo;
			practiceProfileInfo.isLoading = false;
			practiceProfileInfo.isCompleted = true;
			return {
				...state,
				isCompleted: true,
				practiceProfileInfo: { ...practiceProfileInfo },
				error: action.error,
			};
		}

		case UPDATE_PRACTICE_FAIL: {
			practiceProfileInfo = state.practiceProfileInfo;
			practiceProfileInfo.isLoading = false;
			return {
				...state,
				isCompleted: false,
				practiceProfileInfo: { ...practiceProfileInfo },
				error: action.error,
			};
		}


		//GET_PRACTICESECURITYQUE_FAIL
		case GET_PRACTICESECURITYQUE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				// practiceSecurityQuestion: null,
				error: null,
			};
		}
		case GET_PRACTICESECURITYQUE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				practiceSecurityQuestion: action.practiceSecurityQuestion,
				error: false,
			};
		}
		case GET_PRACTICESECURITYQUE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				// practiceSecurityQuestion: false,
				error: action.error,
			};
		}

		//GET_PRACTICESECURITYQUE_FAIL
		case GET_GENDER_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getGender: null,
				error: null,
			};
		}
		case GET_GENDER_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				getGender: action.getGender,
				error: false,
			};
		}
		case GET_GENDER_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getGender: false,
				error: action.getGender,
			};
		}

		//GET_PRACTICE_OWNER_REQUEST
		case GET_PRACTICE_OWNER_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getownersId: null,
				error: null,
			};
		}
		case GET_PRACTICE_OWNER_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				getownersId: action.getownersId,
				error: false,
			};
		}
		case GET_PRACTICE_OWNER_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getownersId: false,
				error: action.getownersId,
			};
		}

		//GET_PRACTICE_OWNER_INFO_REQUEST
		case GET_PRACTICE_OWNER_INFO_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingOwnerinfo: true,
				// ownersProfileinfo: null,
				error: action.error,
			};
		}
		case GET_PRACTICE_OWNER_INFO_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingOwnerinfo: false,
				ownersProfileinfo: action.ownersProfileinfo,
				error: action.error,
			};
		}
		case GET_PRACTICE_OWNER_INFO_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingOwnerinfo: false,
				ownersProfileinfo: false,
				error: action.error,
			};
		}

		//UPDATE_OWNER_PROFILE_REQUEST
		case UPDATE_OWNER_PROFILE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingUpdateowner: true,
				isProfileCreated: false,
				practiceUserAccountInfo: { ...action.practiceUserAccountInfo },
				error: null,
			};
		}
		case UPDATE_OWNER_PROFILE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingUpdateowner: false,
				isProfileCreated: true,
				practiceUserAccountInfo: { ...action.practiceUserAccountInfo },
				error: false,
			};
		}
		case UPDATE_OWNER_PROFILE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingUpdateowner: false,
				practiceUserAccountInfo: { ...action.practiceUserAccountInfo },
				isProfileCreated: false,
				error: action.error,
			};
		}

		//GET_PRACTICE_USER_REQUEST
		case GET_PRACTICE_USER_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				// practiceUsersList: action.practiceUsersList,
				error: null,
			};
		}
		case GET_PRACTICE_USER_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				practiceUsersList: action.practiceUsersList,
			};
		}
		case GET_PRACTICE_USER_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				// practiceUsersList: false,
				error: action.error,
			};
		}

		//GET_AUTHORIZED_ALL_USERS_REQUEST
		case GET_AUTHORIZED_ALL_USERS_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				// authorizedUserList: action.authorizedUserList,
				error: null,
			};
		}
		case GET_AUTHORIZED_ALL_USERS_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				authorizedUserList: action.authorizedUserList,
				error: action.error,
			};
		}
		case GET_AUTHORIZED_ALL_USERS_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				authorizedUserList: action.authorizedUserList,
				error: action.error,
			};
		}

		//INVITE_USER_TO_CTEATE_PROFILE_REQUEST
		case INVITE_USER_TO_CTEATE_PROFILE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadinginvite: true,
				// authorizedUserList: action.authorizedUserList,
				error: null,
			};
		}
		case INVITE_USER_TO_CTEATE_PROFILE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadinginvite: false,
				authorizedUserList: action.authorizedUserList,
				error: action.error,
			};
		}
		case INVITE_USER_TO_CTEATE_PROFILE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadinginvite: false,
				// authorizedUserList: action.authorizedUserList,
				error: action.error,
			};
		}

		//REINVITE_USER_TO_CTEATE_PROFILE_REQUEST
		case REINVITE_USER_TO_CTEATE_PROFILE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadinginvite: true,
				isResendSusses:false,
				buttonLoading4:true,
				// authorizedUserList: action.authorizedUserList,
				error: null,
			};
		}
		case REINVITE_USER_TO_CTEATE_PROFILE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadinginvite: false,
				isResendSusses:true,
				buttonLoading4:false,
				// authorizedUserList: action.authorizedUserList,
				error: action.error,
			};
		}
		case REINVITE_USER_TO_CTEATE_PROFILE_FAIL: {
			return {
				...state,
				isCompleted: false,
				buttonLoading4:false,
				isResendSusses:false,
				loadinginvite: false,
				// authorizedUserList: action.authorizedUserList,
				error: action.error,
			};
		}

		//GET_PHONE_VERIFICATION_REQUEST
		case GET_PHONE_VERIFICATION_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingGetVerifyCode: true,
				isVerifyOwnerPhone: false,
				// phoneVerificationInfo: {...action.phoneVerificationInfo},
				error: null,
			};
		}
		case GET_PHONE_VERIFICATION_SUCCESS: {
			let phoneVerificationInfo = state.phoneVerificationInfo;
			phoneVerificationInfo = action.phoneVerificationInfo;
			phoneVerificationInfo.isVerify = true
			phoneVerificationInfo.isLoading = false
			return {
				...state,
				isCompleted: true,
				loadingGetVerifyCode: false,
				isVerifyOwnerPhone: true,
				phoneVerificationInfo: { ...phoneVerificationInfo },
				error: action.error,
			};
		}
		case GET_PHONE_VERIFICATION_FAIL: {
			let phoneVerificationInfo = state.phoneVerificationInfo;
			phoneVerificationInfo.isLoading = false
			return {
				...state,
				isCompleted: false,
				loadingGetVerifyCode: false,
				isVerifyOwnerPhone: false,
				phoneVerificationInfo: { ...phoneVerificationInfo },
				error: action.error,
			};
		}

		//GET_USER_VERIFICATION_REQUEST
		case GET_USER_VERIFICATION_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingGetVerifyCode: true,
				isVerify: false,
				userVerificationCode: { ...action.userVerificationCode },
				error: null,
			};
		}
		case GET_USER_VERIFICATION_SUCCESS: {
			var userVerificationCode = state.userVerificationCode;
			userVerificationCode = action.userVerificationCode;
			userVerificationCode.isVerify = true
			userVerificationCode.isLoading = false
			return {
				...state,
				isCompleted: true,
				loadingGetVerifyCode: false,
				isVerify: true,
				userVerificationCode: { ...userVerificationCode },
				error: action.error,
			};
		}
		case GET_USER_VERIFICATION_FAIL: {
			var userVerificationCode = state.userVerificationCode;
			userVerificationCode.isLoading = false
			return {
				...state,
				isCompleted: false,
				loadingGetVerifyCode: false,
				isVerify: false,
				userVerificationCode: { ...userVerificationCode },
				error: action.error,
			};
		}

		//GET_DEFAULT_PROFILE_PIC_REQUEST
		case GET_DEFAULT_PROFILE_PIC_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				defaultProfilePictures: null,
				error: null,
			};
		}
		case GET_DEFAULT_PROFILE_PIC_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				defaultProfilePictures: action.defaultProfilePictures,
				error: false,
			};
		}
		case GET_DEFAULT_PROFILE_PIC_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				defaultProfilePictures: false,
				error: action.defaultProfilePictures,
			};
		}

		//UPDATE_PRACTICE_PROFILE_REQUEST
		case UPDATE_PRACTICE_PROFILE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				buttonLoading:true,
				loading: true,
				updatedpracticeUserInfo: null,
				error: null,
			};
		}
		case UPDATE_PRACTICE_PROFILE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				buttonLoading:false,
				loading: false,
				updatedpracticeUserInfo: action.updatedpracticeUserInfo,
				error: action.error,
			};
		}
		case UPDATE_PRACTICE_PROFILE_FAIL: {
			return {
				...state,
				isCompleted: false,
				buttonLoading:false,
				loading: false,
				updatedpracticeUserInfo: action.updatedpracticeUserInfo,
				error: action.error,
			};
		}

		//VERIFY_OTP_REQUEST
		case VERIFY_OTP_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				loadingOwnerVerifyPhone: true,
				phoneVerificationInfo: { ...action.phoneVerificationInfo },
				// practiceUserAccountInfo: {...action.practiceUserAccountInfo},
				error: null,
			};
		}
		case VERIFY_OTP_SUCCESS: {
			let phoneVerificationInfo = state.phoneVerificationInfo
			
			let practiceUserAccountInfo = state.practiceUserAccountInfo
			if(phoneVerificationInfo.email){
				practiceUserAccountInfo['is_verified_email'] = 1;
			}else{
				practiceUserAccountInfo['is_verified_mobile'] = 1;
			}
			phoneVerificationInfo.isVerify = false
			// phoneVerificationInfo = action.phoneVerificationInfo
			phoneVerificationInfo.phoneVerifyed = true
			phoneVerificationInfo.isLoading = false
			
			return {
				...state,
				isCompleted: true,
				loading: false,
				loadingOwnerVerifyPhone: false,
				// phoneVerifyed: true,
				phoneVerificationInfo: { ...phoneVerificationInfo },
				practiceUserAccountInfo: {...practiceUserAccountInfo},
				error: null,
			};
		}
		case VERIFY_OTP_FAIL: {
			let phoneVerificationInfo = state.phoneVerificationInfo
			phoneVerificationInfo.phoneVerifyed = false
			// phoneVerificationInfo.isVerify = false
			phoneVerificationInfo.isGetVerifyCode = true
			phoneVerificationInfo.isLoading = false
			return {
				...state,
				isCompleted: false,
				loading: false,
				loadingOwnerVerifyPhone: false,
				// phoneVerifyed: false,
				phoneVerificationInfo: { ...phoneVerificationInfo },
				// practiceUserAccountInfo: {...action.practiceUserAccountInfo},
				error: action.error,
			};
		}

		//GET_PRACTICE_USER_LOGIN_REQUEST
		case GET_PRACTICE_USER_LOGIN_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingSignin: true,
				// practiceUserLoginInfo: null,
				// error: null,
			};
		}
		case GET_PRACTICE_USER_LOGIN_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingSignin: false,
				practiceUserLoginInfo: action.practiceUserLoginInfo,
				error: action.error,
			};
		}
		case GET_PRACTICE_USER_LOGIN_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingSignin: false,
				// practiceUserLoginInfo: false,
				signinerror: action.error,
			};
		}






		//SEND_EMBED_CODE_TO_EMAIL_REQUEST
		case SEND_EMBED_CODE_TO_EMAIL_REQUEST: {
			return {
				...state,
			};
		}
		case SEND_EMBED_CODE_TO_EMAIL_SUCCESS: {
			return {
				...state,
				sendcodetoemaildata: action.sendcodetoemaildata,
				error: action.error,
			};
		}
		case SEND_EMBED_CODE_TO_EMAIL_FAIL: {
			return {
				...state,
				error: action.error,
			};
		}
		// SEND_EMBED_CODE_TO_EMAIL_REQUEST end















		//GET_PRACTICE_USER_LOGOUT_REQUEST
		case GET_PRACTICE_USER_LOGOUT_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				practiceUserLogoutInfo: null,
				error: null,
			};
		}
		case GET_PRACTICE_USER_LOGOUT_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				practiceUserLogoutInfo: action.practiceUserLogoutInfo,
				practiceUserLoginInfo: {},
				error: action.error,
			};
		}
		case GET_PRACTICE_USER_LOGOUT_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				practiceUserLogoutInfo: false,
				error: action.error,
			};
		}

		//SET_UPLOAD_PROGRESS
		case SET_UPLOAD_PROGRESS:
			return {
				...state,
				progress: action.progress,
				isCompleted: false,
				newProfileInfo: false,
				loadingCreateProfile: true,
			};

		case SUCCESS_UPLOAD_FILE:
			return {
				...state,
				newProfileInfo: action.newProfileInfo,
				isCompleted: true,
				loadingCreateProfile: false,
			};

		case FAILURE_UPLOAD_FILE:
			return {
				...state,
				status: 0,
				progress: 0,
				newProfileInfo: false,
				isCompleted: false,
				error: action.error,
				loadingCreateProfile: false,
			};

		//UpdateUserProfileImage
		case UPDATE_USER_PROFILE_IMAGE_REQUEST:
			return {
				...state,
				progress: action.progress,
				isCompleted: false,
				UpdateUserProfileImage: false,
				buttonLoading:true
			};

		case UPDATE_USER_PROFILE_IMAGE_SUCCESS:
			var practiceUserLoginInfo = { ...state.practiceUserLoginInfo, ...action.practiceUserAccountInfo };
			return {
				...state,
				UpdateUserProfileImage: true,
				practiceUserLoginInfo: practiceUserLoginInfo,
				isCompleted: true,
				buttonLoading:false
			};

		case UPDATE_USER_PROFILE_IMAGE_FAIL:
			return {
				...state,
				status: 0,
				progress: 0,
				UpdateUserProfileImage: false,
				isCompleted: false,
				error: action.error,
				buttonLoading:false
			};

		//UPDATE_USER_PROFILE_REQUEST
		case UPDATE_USER_PROFILE_REQUEST: {
			return {
				...state,
				isUpdateUserProfileSuccess: false,
				loadingUserProfile: true,
				UpdateUserProfileInfo: action.UpdateUserProfileInfo,
				error: null,
			};
		}

		case UPDATE_USER_PROFILE_SUCCESS: {
			return {
				...state,
				isUpdateUserProfileSuccess: true,
				loadingUserProfile: false,
				UpdateUserProfileInfo: action.UpdateUserProfileInfo,
				error: action.error,
				buttonLoading:false
			};
		}

		case UPDATE_USER_PROFILE_FAIL: {
			return {
				...state,
				isUpdateUserProfileSuccess: false,
				loadingUserProfile: false,
				UpdateUserProfileInfo: action.UpdateUserProfileInfo,
				error: action.error,
			};
		}

		//UPDATE_SYSTEM_LANGUAGE_REQUEST
		case UPDATE_SYSTEM_LANGUAGE_REQUEST: {
			var practiceUserAccountSetting = state.practiceUserAccountSetting;
			practiceUserAccountSetting = action.practiceUserAccountSetting;
			practiceUserAccountSetting.isSystemLangCompleted = false;
			practiceUserAccountSetting.isCompleted = false;
			return {
				...state,
				practiceUserAccountSetting: { ...practiceUserAccountSetting },
				error: null,
			};
		}

		case UPDATE_SYSTEM_LANGUAGE_SUCCESS: {
			practiceUserAccountSetting = action.practiceUserAccountSetting;
			practiceUserAccountSetting.isSystemLoading = false;
			practiceUserAccountSetting.isLoading = false;
			practiceUserAccountSetting.isSystemLangCompleted = true;
			practiceUserAccountSetting.isCompleted = true;
			return {
				...state,
				practiceUserAccountSetting: { ...practiceUserAccountSetting },
				error: action.error,
			};
		}

		case UPDATE_SYSTEM_LANGUAGE_FAIL: {
			return {
				...state,
				error: action.error,
			};
		}

		//practiceUserAccountInfo
		case GET_USER_PROFILE_INFO_REQUEST: {
			var practiceUserAccountInfo = { ...state.practiceUserLoginInfo, ...action.practiceUserAccountInfo };
			return {
				...state,
				isCompleted: false,
				loading: true,
				practiceUserAccountInfo: practiceUserAccountInfo,
				error: null,
			};
		}

		case GET_USER_PROFILE_INFO_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				practiceUserAccountInfo: practiceUserAccountInfo,
				error: action.error,
			};
		}

		case GET_USER_PROFILE_INFO_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				practiceUserAccountInfo: { ...action.practiceUserAccountInfo },
				error: action.error,
			};
		}

		//GET_BILLING_CARD_INFO_REQUEST
		case GET_BILLING_CARD_INFO_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingBillingCardInfo: true,
				// getBillingCardInfo: null,
				error: null,
			};
		}
		case GET_BILLING_CARD_INFO_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingBillingCardInfo: false,
				getBillingCardInfo: action.getBillingCardInfo,
				error: action.error,
			};
		}
		case GET_BILLING_CARD_INFO_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingBillingCardInfo: false,
				error: action.error,
			};
		}

		//GET_BILLING_CARD_INFO_REQUEST
		case CHANGE_BILLING_CARD_STATUS_REQUEST: {
			return {
				...state,
				isSuccessRemove: false,
				loadingcardstatus: true,
				cardstatus: null,
				error: null,
			};
		}
		case CHANGE_BILLING_CARD_STATUS_SUCCESS: {
			return {
				...state,
				isSuccessRemove: true,
				loadingcardstatus: false,
				cardstatus: action.cardstatus,
				error: action.error,
			};
		}
		case CHANGE_BILLING_CARD_STATUS_FAIL: {
			return {
				...state,
				isSuccessRemove: false,
				loadingcardstatus: false,
				cardstatus: false,
				error: action.error,
			};
		}

		//ADD_BILLING_CARD_INFO_REQUEST
		case ADD_BILLING_CARD_INFO_REQUEST: {
			return {
				...state,
				isSuccess: false,
				loadingAddCardInfo: true,
				addBillingCardInfo: null,
				error: null,
			};
		}
		case ADD_BILLING_CARD_INFO_SUCCESS: {
			return {
				...state,
				isSuccess: true,
				loadingAddCardInfo: false,
				addBillingCardInfo: action.addBillingCardInfo,
				error: action.error,
			};
		}
		case ADD_BILLING_CARD_INFO_FAIL: {
			return {
				...state,
				isSuccess: false,
				loadingAddCardInfo: false,
				addBillingCardInfo: false,
				error: action.error,
			};
		}

		//UPDATE_BILLING_CARD_INFO_REQUEST
		case UPDATE_BILLING_CARD_INFO_REQUEST: {
			return {
				...state,
				isUpdateSuccess: false,
				loadingUpdate: true,
				updateBillingCardInfo: null,
				error: null,
			};
		}
		case UPDATE_BILLING_CARD_INFO_SUCCESS: {
			if(state.isCorporate){
				var designateCard = state.designateCard;
				var designateCardIndex = designateCard.data.findIndex(x => x.id == action.data.id )
				if(designateCardIndex > -1){
					designateCard.data[designateCardIndex] = action.data;
				}
			}
			return {
				...state,
				isUpdateSuccess: true,
				loadingUpdate: false,
				updateBillingCardInfo: action.updateBillingCardInfo,
				error: action.error,
				designateCard: state.isCorporate ? designateCard : state.designateCard
			};
		}
		case UPDATE_BILLING_CARD_INFO_FAIL: {
			return {
				...state,
				isUpdateSuccess: false,
				loadingUpdate: false,
				updateBillingCardInfo: false,
				error: action.error,
			};
		}

		//UPDATE_PASSCODE_REQUEST
		case UPDATE_PASSCODE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				// practiceUserUpdatePasscodeInfo: null,
				error: null,
			};
		}
		case UPDATE_PASSCODE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				practiceUserUpdatePasscodeInfo: action.practiceUserUpdatePasscodeInfo,
				error: action.error,
			};
		}
		case UPDATE_PASSCODE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				practiceUserUpdatePasscodeInfo: false,
				error: action.error,
			};
		}

		//GET_NOTIFICATION_REQUEST
		case GET_NOTIFICATION_REQUEST: {
			return {
				...state,
				isCompleted: false,
				// loadingnotification: true,
				// getnotificationlist: null,
				// error: null,
			};
		}

		case GET_NOTIFICATION_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingnotification: false,
				getnotificationlist: action.getnotificationlist,
				error: action.error,
			};
		}

		case GET_NOTIFICATION_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingnotification: false,
				// getnotificationlist: action.getnotificationlist,
				error: action.error,
			};
		}

		//UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST
		case UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST: {
			return {
				...state,
				isUpdateProfileCompleted: false,
				practiceUserProfileInfo: { ...action.practiceUserProfileInfo },
				loading: true,
				error: null,
			};
		}

		case UPDATE_USER_PROFILE_ABOUT_INFO_SUCCESS: {
			// var practiceUserProfileInfo = action.practiceUserProfileInfo
			// var practiceUserProfileInfoOld = state.practiceUserProfileInfo
			// if(state.practiceUserProfileInfo.id == action.practiceUserAccountInfo.id){
			// practiceUserLoginInfo	= action.practiceUserAccountInfo
			var practiceUserLoginInfo = { ...state.practiceUserLoginInfo, ...action.practiceUserProfileInfo };
			// }
			return {
				...state,
				isUpdateProfileCompleted: true,
				loading: false,
				practiceUserLoginInfo: practiceUserLoginInfo,
				// practiceUserProfileInfo: action.practiceUserProfileInfo,
				// practiceUserProfileInfo: state.practiceUserProfileInfo,
				practiceUserAccountInfo:{...action.practiceUserProfileInfo},
				error: action.error,
			};
		}

		case UPDATE_USER_PROFILE_ABOUT_INFO_FAIL: {
			return {
				...state,
				isUpdateProfileCompleted: false,
				loading: false,
				// practiceUserAccountInfo: {...action.practiceUserAccountInfo},
				error: action.error,
			};
		}

		//GET_DOMAIN_LIST_REQUEST
		case GET_DOMAIN_LIST_REQUEST: {
			return {
				...state,
				// isLoading:true,
				isCompleted: false,
				// getDomainList: action.getDomainList,
				error: null,
			};
		}

		case GET_DOMAIN_LIST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isCompleted: true,
				getDomainList: { ...action.getDomainList },
				error: action.error,
			};
		}

		case GET_DOMAIN_LIST_FAIL: {
			return {
				...state,
				isLoading: false,
				isCompleted: false,
				getDomainList: { ...action.getDomainList },
				error: action.error,
			};
		}

		//CREATE_DOMAIN_REQUEST
		case CREATE_DOMAIN_REQUEST: {
			return {
				...state,
				isSuccessAddedDomain: false,
				createDomaininfo: { ...action.createDomaininfo },
				error: null,
			};
		}

		case CREATE_DOMAIN_SUCCESS: {
			var createDomaininfo = state.createDomaininfo;
			createDomaininfo = action.createDomaininfo;
			createDomaininfo.isLoader = false;
			return {
				...state,
				isSuccessAddedDomain: true,
				createDomaininfo: { ...createDomaininfo },
				error: action.error,
			};
		}

		case CREATE_DOMAIN_FAIL: {
			return {
				...state,
				isSuccessAddedDomain: false,
				createDomaininfo: { ...action.createDomaininfo },
				error: action.error,
			};
		}

		//UPDATE_DOMAIN_REQUEST
		case UPDATE_DOMAIN_REQUEST: {
			return {
				...state,
				getDomainList: { ...action.getDomainList },
			};
		}

		case UPDATE_DOMAIN_SUCCESS: {
			var getDomainList = state.getDomainList;
			console.log(getDomainList, 'getDomainList')
			// getDomainList = action.getDomainList;
			getDomainList.data[action.payload].isLoader = false;
			getDomainList.data[action.payload].isEdit = false;
			return {
				...state,
				getDomainList: { ...getDomainList },
			};
		}

		case UPDATE_DOMAIN_FAIL: {
			return {
				...state,
				getDomainList: { ...getDomainList },
				error: action.payload,
			};
		}

		//REMOVE_DOMAIN_REQUEST
		case REMOVE_DOMAIN_REQUEST: {
			return {
				...state,
				getDomainList: { ...action.getDomainList },
			};
		}

		case REMOVE_DOMAIN_SUCCESS: {
			var _getDomainList = state.getDomainList;
			// _getDomainList.data[action.payload] = action.getDomainList;
			_getDomainList.data[action.payload].isLoading = false;
			_getDomainList.data[action.payload].isSuccessRemove = true;
			// _getDomainList.data.splice(action.payload, 1);
			return {
				...state,
				getDomainList: { ..._getDomainList },
			};
		}

		case REMOVE_DOMAIN_FAIL: {
			return {
				...state,
				getDomainList: { ...action._getDomainList },
				error: action.error,
			};
		}

		//GET_SUBSCRIBE_INFO_SUCCESS
		case GET_SUBSCRIBE_INFO_REQUEST: {
			return {
				...state,
				isCompleted: false,
				isLoading:true,
				// getSubscribeinfo:null,
				error: null,
			};
		}

		case GET_SUBSCRIBE_INFO_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				isLoading:false,
				avelablePlugin: action.getSubscribeinfo?.subscription_plan?.plugins,
				getSubscribeinfo: action.getSubscribeinfo,
				error: action.error,
			};
		}

		case GET_SUBSCRIBE_INFO_FAIL: {
			return {
				...state,
				isCompleted: false,
				isLoading:false,
				getSubscribeinfo: action.getSubscribeinfo,
				error: action.error,
			};
		}

		//GET_SUBSCRIBE_PRICE_OPTION_REQUEST
		case GET_SUBSCRIBE_PRICE_OPTION_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingnotification: true,
				// getSubscribePriceOption: null,
				// error: null,
			};
		}

		case GET_SUBSCRIBE_PRICE_OPTION_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingnotification: false,
				getSubscribePriceOption: action.getSubscribePriceOption,
				error: action.error,
			};
		}

		case GET_SUBSCRIBE_PRICE_OPTION_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingnotification: false,
				getSubscribePriceOption: action.getSubscribePriceOption,
				error: action.error,
			};
		}

		//GET_SUBSCRIBE_PRICE_CALC_REQUEST
		case GET_SUBSCRIBE_PRICE_CALC_REQUEST: {
			return {
				...state,
				buttonLoading:true,
				loadingcalc:true,
				// getSubscribePriceCalc: { ...action.getSubscribePriceCalc },
				error: null,

			};
		}

		case GET_SUBSCRIBE_PRICE_CALC_SUCCESS: {
			var getSubscribePriceCalc = { ...action.getSubscribePriceCalc }
			console.log(action.isUpgradePlan,'action.isUpgradePlan')
			if(action.isUpgradePlan){
				var current_subscribe = {...state.getSubscribeinfo?.subscription_plan}
				// delete current_subscribe.payment_cycle_key
				getSubscribePriceCalc = {
					...getSubscribePriceCalc,
					subscription: {
					  ...getSubscribePriceCalc.subscription,
					  ...current_subscribe
					}
				  }
			}
			return {
				...state,
				buttonLoading:false,
				loadingcalc:false,
				getSubscribePriceCalc,
				error: action.error,
			};
		}

		case GET_SUBSCRIBE_PRICE_CALC_FAIL: {
			return {
				...state,
				buttonLoading:false,
				loadingcalc:false,
				getSubscribePriceCalc: { ...action.getSubscribePriceCalc },
				error: action.error,
			};
		}

		case SET_UPGRADE_DOWNGRADE: {
			return {
				...state,
				isUpgradePlan:action.isUpgradePlan,
				error: null,
			};
		}

		//POST_SUBSCRIBE_REQUEST
		case POST_SUBSCRIBE_REQUEST: {
			
			return {
				...state,
				subscribeInfo: { ...action.subscribeInfo },
				// practiceInfo:{...practiceInfo },
				error: null,

			};
		}

		case POST_SUBSCRIBE_SUCCESS: {
			var subscribeInfo = state.subscribeInfo;
			// subscribeInfo = action.subscribeInfo
			subscribeInfo.isLoading = false
			var practiceInfo = state.practiceInfo;
			practiceInfo['is_paid_account'] = 1;
			return {
				...state,
				subscribeInfo: { ...action.subscribeInfo },
				error: action.error,
			};
		}

		case POST_SUBSCRIBE_FAIL: {
			subscribeInfo = state.subscribeInfo;
			subscribeInfo.isLoading = false
			return {
				...state,
				subscribeInfo: { ...action.subscribeInfo },
				error: action.error,
			};
		}

		//POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUEST
		case POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUEST: {
			return {
				...state,
				isSuccess: false,
				loadingnotification: true,
				subscribeAdditionalDomain: { ...action.subscribeAdditionalDomain },
				error: null,
			};
		}

		case POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUESTLOADING: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			};
		}

		case POST_SUBSCRIBE_ADDITIONAL_DOMAIN_SUCCESS: {
			var subscribeAdditionalDomain = state.subscribeAdditionalDomain;
			subscribeAdditionalDomain = action.subscribeAdditionalDomain;
			subscribeAdditionalDomain.isLoading = false;
			return {
				...state,
				isSuccess: true,
				loadingnotification: false,
				buttonLoading: false,
				subscribeAdditionalDomain: { ...subscribeAdditionalDomain },
				error: action.error,
			};
		}

		case POST_SUBSCRIBE_ADDITIONAL_DOMAIN_FAIL: {
			// subscribeAdditionalDomain.isLoading = false;
			return {
				...state,
				isSuccess: false,
				loadingnotification: false,
				buttonLoading: false,
				// subscribeAdditionalDomain: { ...subscribeAdditionalDomain },
				error: action.error,
			};
		}

		// Footer Time
		case GET_WAITINGROOM_FOOTER_TIME_REQUEST: {
			return {
				...state,
				loading: true,
				liveFooterTime: {},
				error: null,
			};
		}
		case GET_WAITINGROOM_FOOTER_TIME_SUCCESS: {
			return {
				...state,
				loading: false,
				liveFooterTime: action.liveFooterTime,
				error: null,
			};
		}
		case GET_WAITINGROOM_FOOTER_TIME_FAILURE: {
			return {
				...state,
				loading: true,
				liveFooterTime: {},
				error: action.error,
			};
		}

		//GET_PRACTICE_USER_LOGOUT_REQUEST
		case DELETE_USER_PROFILE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingDeleteUserProfile: true,
				practiceUserdeleteInfo: false,
				error: null,
			};
		}

		case DELETE_USER_PROFILE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingDeleteUserProfile: false,
				practiceUserdeleteInfo: true,
				// practiceUserLoginInfo: {},
				error: action.error,
			};
		}

		case DELETE_USER_PROFILE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingDeleteUserProfile: false,
				practiceUserdeleteInfo: false,
				error: action.error,
			};
		}

		//DELETE_MULTY_USER_PROFILE_REQUEST
		case DELETE_MULTY_USER_PROFILE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loadingRemoveProfiles: true,
				practiceMultipalUserdeleteInfo: null,
				SuccessProfileRemove: false,
				error: null,
			};
		}
		case DELETE_MULTY_USER_PROFILE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loadingRemoveProfiles: false,
				practiceMultipalUserdeleteInfo: action.practiceMultipalUserdeleteInfo,
				SuccessProfileRemove: true,
				error: action.error,
			};
		}
		case DELETE_MULTY_USER_PROFILE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loadingRemoveProfiles: false,
				practiceMultipalUserdeleteInfo: false,
				SuccessProfileRemove: false,
				error: action.error,
			};
		}

		//GET_USER_IDENTITY_REQUEST
		case GET_USER_IDENTITY_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				// userIdentifyInfo: null,
				error: null,
			};
		}
		case GET_USER_IDENTITY_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				userIdentifyInfo: action.userIdentifyInfo,
			};
		}
		case GET_USER_IDENTITY_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				error: action.error,
			};
		}

		//GET_USER_PROFILE_VERIFY_REQUEST
		case GET_USER_PROFILE_VERIFY_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				buttonLoading:true,
				userProfileVerifyInfo: null,
				phoneVerificationInfo:{},
				userVerificationCode:{},
				error: null,
			};
		}
		case GET_USER_PROFILE_VERIFY_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				buttonLoading:false,
				userProfileVerifyInfo: true,
				userIdentifyInfo: {},
				error: action.error,
			};
		}
		case GET_USER_PROFILE_VERIFY_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				buttonLoading:false,
				userProfileVerifyInfo: false,
				error: action.error,
			};
		}

		//POST_REFER_FREE_MONTH_REQUEST

		case RESET_REFER_FREE_MONTH_REQUEST: {
			return {
				...state,
				isCompleted: false,
				buttonLoading: false,
				referFreeMonthInfo: {},
				refersend: false,
				error: null,
			};
		}
		case POST_REFER_FREE_MONTH_REQUEST: {
			return {
				...state,
				isCompleted: false,
				buttonLoading: true,
				referFreeMonthInfo: {},
				refersend: false,
				error: null,
			};
		}
		case POST_REFER_FREE_MONTH_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				buttonLoading: false,
				referFreeMonthInfo: action.referFreeMonthInfo,
				refersend: action.refersend,
				error: action.error,
			};
		}
		case POST_REFER_FREE_MONTH_FAIL: {
			return {
				...state,
				isCompleted: false,
				buttonLoading: false,
				referFreeMonthInfo: action.referFreeMonthInfo,
				refersend: false,
				error: action.error,
			};
		}

		//POST_REFER_FREE_MONTH_REQUEST
		case GET_REFERRAL_LIST_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getreferralList: null,
				refersend: false,
				error: null,
			};
		}
		case GET_REFERRAL_LIST_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				getreferralList: action.getreferralList,
				error: action.error,
			};
		}
		case GET_REFERRAL_LIST_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				getreferralList: action.getreferralList,
				refersend: false,
				error: action.error,
			};
		}

		case FILTER_BY_KEYWORD: {
			return {
				...state,
				isCompleted: true,
				// loadingnotification: false,
				getnotificationlist: action.getnotificationlist,
				error: action.error,
			};
		}

		case FILTER_BY_LETEST: {
			return {
				...state,
				isCompleted: true,
				loadingnotification: false,
				getnotificationlist: action.getnotificationlist,
				error: action.error,
			};
		}

		case FILTER_BY_SHOW_ONLY_NEW: {
			return {
				...state,
				isCompleted: true,
				loadingnotification: false,
				getOnlyNewnotificationlist: action.getnotificationlist,
				error: action.error,
			};
		}

		//CLEAR_DATA
		case CLEAR_DATA: {
			
			return {
				...state,
				isCompleted: false,
				loading: false,
				loadingOwnerVerifyPhone: false,
				loadingGetVerifyCode: false,
				progress: 0,
				UpdateUserProfileImage: false,
				practiceUserUpdatePasscodeInfo: false,
				userProfileVerifyInfo: null,
				phoneVerifyed: {},
				isProfileCreated: false,
				isVerifyOwnerPhone: false,
				isVerify: false,
				practiceUserdeleteInfo: false,
				isUpdateUserProfileSuccess: false,
				isUpdateProfileCompleted: false,
				phoneVerificationInfo:{},
				userVerificationCode:{},
				practiceUserdeleteInfo: false,
				error: {
					errors: []
				},
			};
		}

		case CLEAR_LOADING: {
			return {
				...state,
				loading: false,
				isCompleted: false,
				buttonLoading: false,
				buttonLoading2: false,
				buttonLoading3: false,
				buttonLoading4: false,
				progress: 0,
				UpdateUserProfileImage: false,
				isUpdateUserProfileSuccess: false,
			};
		}

		//POST_UNAUTHORIZED_DEVICE
		case POST_UNAUTHORIZED_DEVICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				Unauthorized: null,
				error: null,
			};
		}
		case POST_UNAUTHORIZED_DEVICE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				Unauthorized: action.Unauthorized,
				error: false,
			};
		}
		case POST_UNAUTHORIZED_DEVICE_FAIL: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				Unauthorized: false,
				error: action.Unauthorized,
			};
		}

		//CLEAR_ERROR
		case CLEAR_ERROR: {
			return {
				...state,
				isCompleted: false,
				error: {
					errors: []
				},
				signinerror: ''
			};
		}

		//reset successSend
		case RESET_SUCCESS: {
			return {
				...state,
				successSend: false,
				isUpdateSuccess:false,
				isSuccessRemove: false,
				isSuccessAddedDomain: false,
				SuccessProfileRemove: false,
				loadingsubscribedevice:false,
				isSuccess: false,
				practiceUserdeleteInfo: false
			};
		}

		//GET_INVOICE_REQUEST
		case GET_INVOICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				invoiceList: null,
				error: null,
			};
		}
		case GET_INVOICE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				invoiceList: action.invoiceList,
				error: false,
			};
		}
		case GET_INVOICE_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				invoiceList: false,
				error: action.invoiceList,
			};
		}

		//GET_INVOICEVIEW_REQUEST
		case GET_INVOICEVIEW_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				invoiceView: null,
				error: null,
			};
		}
		case GET_INVOICEVIEW_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				invoiceView: action.invoiceView,
				error: false,
			};
		}
		case GET_INVOICEVIEW_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				invoiceView: false,
				error: action.invoiceView,
			};
		}

		//GET_AUTHORIZED_DEVICE_REQUEST
		case GET_AUTHORIZED_DEVICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				// authorizedDeviceList: null,
				error: null,
			};
		}
		case GET_AUTHORIZED_DEVICE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				authorizedDeviceList: action.authorizedDeviceList,
				error: false,
			};
		}
		case GET_AUTHORIZED_DEVICE_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				authorizedDeviceList: false,
				error: action.authorizedDeviceList,
			};
		}

		//REMOVE_AUTHORIZED_DEVICE_REQUEST
		case REMOVE_AUTHORIZED_DEVICE_REQUEST: {
			return {
				...state,
				isSuccessRemove: false,
				loadingremovedevice: true,
				authorizedDeviceList: { ...action.authorizedDeviceList },
				error: null,

			};
		}
		case REMOVE_AUTHORIZED_DEVICE_SUCCESS: {
			var authorizedDeviceList = state.authorizedDeviceList;
			authorizedDeviceList[action.payload] = action.authorizedDeviceList;
			authorizedDeviceList.isLoading = false;
			authorizedDeviceList.isRemove = false;
			authorizedDeviceList.isSuccessRemove = true;
			return {
				...state,
				isSuccessRemove: true,
				loading: false,
				authorizedDeviceList: { ...authorizedDeviceList },
				error: null,
			};
		}
		case REMOVE_AUTHORIZED_DEVICE_FAILURE: {
			authorizedDeviceList = state.authorizedDeviceList;
			authorizedDeviceList[action.payload] = action.authorizedDeviceList;
			authorizedDeviceList.isLoading = false;
			authorizedDeviceList.isRemove = false;
			authorizedDeviceList.isSuccessRemove = false;
			return {
				...state,
				isSuccessRemove: false,
				loading: true,
				authorizedDeviceList: { ...action.authorizedDeviceList },
				error: action.error,
			};
		}

		//GET_DEVICE_SUBSCRIPTION_LIST
		case POST_SUBSCRIBE_DEVICE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				isSuccess: false,
				loadingsubscribedevice: true,
				subscribeAdditionalDevice: null,
				error: null,
			};
		}
		case POST_SUBSCRIBE_DEVICE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				isSuccess: true,
				loadingsubscribedevice: false,
				subscribeAdditionalDevice: action.subscribeAdditionalDevice,
				error: false,
			};
		}

		case POST_SUBSCRIBE_DEVICE_FAILURE: {
			return {
				...state,
				isCompleted: false,
				isSuccess: false,
				loadingsubscribedevice: false,
				subscribeAdditionalDevice: false,
				error: action.error,
			};
		}

		//GET_EMAIL_PREFERENCES_REQUEST
		case GET_EMAIL_PREFERENCES_LIST_REQUEST: {
			return {
				...state,
				error: null,
				getEmailPreferencesList: [...action.getEmailPreferencesList],
			};
		}
		case GET_EMAIL_PREFERENCES_LIST_SUCCESS: {
			return {
				...state,
				getEmailPreferencesList: action.getEmailPreferencesList,
				error: action.error,
			};
		}
		case GET_EMAIL_PREFERENCES_LIST_FAILURE: {
			return {
				...state,
				getEmailPreferencesList: action.getEmailPreferencesList,
				error: action.error,
			};
		}



		//GET_EMAIL_PREFERENCES_REQUEST
		case GET_EMAIL_PREFERENCES_REQUEST: {
			return {
				...state,
				getEmailloading: true,
				error: null,
				getEmailPreferences: [...action.getEmailPreferences],
			};
		}
		case GET_EMAIL_PREFERENCES_SUCCESS: {
			return {
				...state,
				getEmailloading: false,
				getEmailPreferences: action.getEmailPreferences,
				error: action.error,
			};
		}
		case GET_EMAIL_PREFERENCES_FAILURE: {
			return {
				...state,
				getEmailloading: false,
				getEmailPreferences: action.getEmailPreferences,
				error: action.error,
			};
		}

		//POST_EMAIL_PREFERENCES_REQUEST
		case POST_EMAIL_PREFERENCES_REQUEST: {
			var getEmailPreferences = state.getEmailPreferences;
			getEmailPreferences = action.getEmailPreferences;
			return {
				...state,
				isCompleted: false,
				getEmailPreferences: [...getEmailPreferences],
				loading: true,
				error: null,
			};
		}

		case POST_EMAIL_PREFERENCES_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				error: action.error,
			};
		}

		case POST_EMAIL_PREFERENCES_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				error: action.error,
			};
		}

		//GET_DEFAULT_CAPTION_SIZE_REQUEST
		case GET_DEFAULT_CAPTION_SIZE_REQUEST: {
			return {
				...state,
				error: null,
				defaultCaptionSize: action.defaultCaptionSize,
			};
		}
		case GET_DEFAULT_CAPTION_SIZE_SUCCESS: {
			return {
				...state,
				defaultCaptionSize: action.defaultCaptionSize,
				error: action.error,
			};
		}
		case GET_DEFAULT_CAPTION_SIZE_FAIL: {
			return {
				...state,
				defaultCaptionSize: action.defaultCaptionSize,
				error: action.error,
			};
		}

		//GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_REQUEST
		case GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_REQUEST: {
			return {
				...state,
				loading: true,
				error: null,
				isloading:true,
				// waitingRoomMediaChannels: [],
			};
		}
		case GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				isloading:false,
				waitingRoomMediaChannels: action.waitingRoomMediaChannels,
				error: null,
			};
		}
		case GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_FAILURE: {
			return {
				...state,
				loading: false,
				isloading:false,
				error: action.error,
				waitingRoomMediaChannels: [],
			};
		}
		//GET_WEBSITEROOM_MEDIA_CHANNEL_REQUEST
		case GET_WAITINGROOM_MEDIA_CHANNEL_REQUEST: {
			return {
				...state,
				// loading: true,
				buttonLoading:true,
				error: null,
				managewaitingRoomMediaChannel: action.managewaitingRoomMediaChannel,
			};
		}
		case GET_WAITINGROOM_MEDIA_CHANNEL_SUCCESS: {
			return {
				...state,
				// loading: false,
				buttonLoading:false,
				managewaitingRoomMediaChannel: action.managewaitingRoomMediaChannel,
				error: null,
			};
		}
		case GET_WAITINGROOM_MEDIA_CHANNEL_FAILURE: {
			return {
				...state,
				// loading: true,
				buttonLoading:false,
				error: action.error,
				managewaitingRoomMediaChannel: action.managewaitingRoomMediaChannel,
			};
		}

		//RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST added by milap
		case RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
				// waitingRoomMediaChannels: [],
			};
		}
		case RESTORE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				// waitingRoomMediaChannels: action.waitingRoomMediaChannels,
				error: null,
			};
		}
		case RESTORE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
				// waitingRoomMediaChannels: [],
			};
		}
		//RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST

		//DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST added by milap
		case DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			};
		}
		case DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				// waitingRoomMediaChannels: { ...action.waitingRoomMediaChannels },
				error: null,
			};
		}
		case DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}
		//RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST


		//POST Footer Weather in Waiting Room
		case POST_WAITINGROOM_MEDIA_FOOTER_TIME_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: null,
			};
		}
		case POST_WAITINGROOM_MEDIA_FOOTER_TIME_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				message: action.message,
				error: null,
			};
		}

		case POST_WAITINGROOM_MEDIA_FOOTER_TIME_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				message: null,
				error: action.error,
			};
		}
		//POST Footer Time in Waiting Room
		case POST_WAITINGROOM_MEDIA_WEATHER_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: null,
			};
		}
		case POST_WAITINGROOM_MEDIA_WEATHER_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				message: action.message,
				error: null,
			};
		}
		case POST_WAITINGROOM_MEDIA_WEATHER_FAILURE: {
			console.log(action.error,'action.error')
			return {
				...state,
				buttonLoading: false,
				message: null,
				error: action.error.data,
			};
		}

		// POST Footer Custom Messages
		case POST_CHANNELWISE_CUSTOM_MESSAGES_REQUEST: {
			return {
				...state,
				loading: true,
				message: null,
				error: null,
			};
		}
		case POST_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS: {
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		}
		case POST_CHANNELWISE_CUSTOM_MESSAGES_FAILURE: {
			return {
				...state,
				loading: true,
				message: null,
				error: action.error,
			};
		}
		// Get Footer Custom Messages
		case GET_FOOTER_CUSTOM_MESSAGES_REQUEST: {
			return {
				...state,
				loading: true,
				// customMessages: [],
				error: null,
			};
		}
		case GET_FOOTER_CUSTOM_MESSAGES_SUCCESS: {
			return {
				...state,
				loading: true,
				customMessages: action.customMessages,
				error: null,
			};
		}
		case GET_FOOTER_CUSTOM_MESSAGES_FAILURE: {
			return {
				...state,
				loading: true,
				// customMessages: [],
				error: action.error,
			};
		}
		// DELETE Waiting Room Footer Custom Messages
		case DELETE_WAITINGROOM_CUSTOM_MESSAGES_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				// message: null,
				error: null,
			};
		}
		case DELETE_WAITINGROOM_CUSTOM_MESSAGES_SUCCESS: {
			var customMessages = state.customMessages;
			var msgIndex = customMessages.findIndex(x => x.id == action.message.id);
			customMessages.splice(msgIndex, 1);
			return {
				...state,
				buttonLoading: false,
				customMessages: [...customMessages],
				error: null,
			};
		}
		case DELETE_WAITINGROOM_CUSTOM_MESSAGES_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				// message: null,
				error: action.error,
			};
		}
		// Get Weather Location in Footer in Waiting Room
		case GET_WAITINGROOM_WEATHER_REQUEST: {
			return {
				...state,
				loading: true,
				weatherLocation: {},
				error: null,
			};
		}
		case GET_WAITINGROOM_WEATHER_SUCCESS: {
			return {
				...state,
				loading: false,
				weatherLocation: action.weatherLocation,
				error: null,
			};
		}
		case GET_WAITINGROOM_WEATHER_FAILURE: {
			return {
				...state,
				loading: true,
				weatherLocation: {},
				error: action.error,
			};
		}
		// Get Live Weather as per location entered in weather location

		case GET_WAITINGROOM_LIVE_WEATHER_DATA_REQUEST: {
			return {
				...state,
				loading: true,
				// liveWeatherData: {},
				error: null,
			};
		}
		case GET_WAITINGROOM_LIVE_WEATHER_DATA_SUCCESS: {
			return {
				...state,
				loading: false,
				liveWeatherData: action.liveWeatherData,
				error: null,
			};
		}
		case GET_WAITINGROOM_LIVE_WEATHER_DATA_FAILURE: {
			return {
				...state,
				loading: false,
				// liveWeatherData: {},
				error: action.error,
			};
		}

		//GET LOCATION
		case GET_LOCATION_LISTING_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getnotificationlist: null,
				error: null,
			};
		}

		case GET_LOCATION_LISTING_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				getLocationsList: action.getLocationsList,
				error: action.error,
			};
		}

		case GET_UNASSIGN_LOCATION_LISTING_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				unassignedLocations: action.unassignedLocations,
				error: action.error,
			};
		}

		case GET_LOCATION_LISTING_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				getLocationsList: action.getLocationsList,
				error: action.error,
			};
		}

		//GET Location Managers
		case GET_LOCATION_MANAGER_LISTING_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getLocationsManagerList: null,
				error: null,
			};
		}

		case GET_LOCATION_MANAGER_LISTING_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				getLocationsManagerList: action.getLocationsManagerList,
				error: action.error,
			};
		}

		case GET_LOCATION_MANAGER_LISTING_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				getLocationsManagerList: action.getLocationsManagerList,
				error: action.error,
			};
		}

		//ADD LOCATION
		case ADD_LOCATION_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				addLocationResponce: null,
				error: null,
			};
		}

		case ADD_LOCATION_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				addLocationResponce: action.addLocationResponce,
				error: action.error,
			};
		}

		case ADD_LOCATION_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				addLocationResponce: action.addLocationResponce,
				error: action.error,
			};
		}

		// GET website-media/layouts
		case GET_WEBSITE_MEDIA_LAYOUTS_REQUEST: {
			return {
				...state,
				// websiteMediaLayouts: [],
				loading: true,
				error: null,
			};
		}
		case GET_WEBSITE_MEDIA_LAYOUTS_SUCCESS: {
			return {
				...state,
				websiteMediaLayouts: action.websiteMediaLayouts,
				loading: false,
				error: null,
			};
		}
		case GET_WEBSITE_MEDIA_LAYOUTS_FAILURE: {
			return {
				...state,
				// websiteMediaLayouts: [],
				loading: false,
				error: action.error,
			};
		}

		// GET website-media/colours
		case GET_WEBSITE_MEDIA_COLOURS_REQUEST: {
			return {
				...state,
				loading: true,
				// websiteMediaColours: [],
				error: null,
			};
		}
		case GET_WEBSITE_MEDIA_COLOURS_SUCCESS: {
			return {
				...state,
				loading: false,
				websiteMediaColours: action.websiteMediaColours,
				error: null,
			};
		}
		case GET_WEBSITE_MEDIA_COLOURS_FAILURE: {
			return {
				...state,
				loading: true,
				// websiteMediaColours: [],
				error: action.error,
			};
		}

		// GET Helper Timezones
		case GET_HELPER_TIMEZONE_REQUEST: {
			return {
				...state,
				loading: true,
				// helperTimezone: [],
				error: null,
			};
		}
		case GET_HELPER_TIMEZONE_SUCCESS: {
			return {
				...state,
				loading: false,
				helperTimezone: action.helperTimezone,
				error: null,
			};
		}
		case GET_HELPER_TIMEZONE_FAILURE: {
			return {
				...state,
				loading: true,
				// helperTimezone: [],
				error: action.error,
			};
		}

		// GET Helper Newsfeed
		case GET_HELPER_NEWSFEED_REQUEST: {
			return {
				...state,
				loading: true,
				helperNewsfeeds: [],
				error: null,
			};
		}
		case GET_HELPER_NEWSFEED_SUCCESS: {
			return {
				...state,
				loading: false,
				helperNewsfeeds: action.helperNewsfeeds,
				error: null,
			};
		}
		case GET_HELPER_NEWSFEED_FAILURE: {
			return {
				...state,
				loading: false,
				helperNewsfeeds: [],
				error: action.error,
			};
		}

		// post news feeds
		case POST_NEWSFEED_REQUEST: {
			return {
				...state,
				buttonLoading: true,
			}
		}

		case POST_NEWSFEED_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
			}
		}

		case POST_NEWSFEED_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			}
		}

		// Get Display Options in Waiting Room
		case GET_WAITINGROOM_DISPLAY_OPTIONS_REQUEST: {
			return {
				...state,
				displayOptions: {},
				error: null,
			};
		}
		case GET_WAITINGROOM_DISPLAY_OPTIONS_SUCCESS: {
			return {
				...state,
				displayOptions: action.displayOptions,
				error: null,
			};
		}
		case GET_WAITINGROOM_DISPLAY_OPTIONS_FAILURE: {
			return {
				...state,
				displayOptions: {},
				error: action.error,
			};
		}
		// Get Waiting Room theme list
		case GET_WAITINGROOM_THEME_LIST_REQUEST: {
			return {
				...state,
				loading: true,
				waitingRoomThemeList: action.waitingRoomThemeList,
				error: null,
			};
		}
		case GET_WAITINGROOM_THEME_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				waitingRoomThemeList: action.waitingRoomThemeList,
				error: null,
			};
		}
		case GET_WAITINGROOM_THEME_LIST_FAILURE: {
			return {
				...state,
				loading: true,
				error: action.error,
			};
		}
		// DELETE Waiting Room Channel
		case DELETE_WAITINGROOM_MEDIA_CHANNEL_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				// message: null,
				error: null,
			};
		}
		case DELETE_WAITINGROOM_MEDIA_CHANNEL_SUCCESS: {
			// var message = state.message;
			// let msgIndex = message.findIndex(x => x.id == action.message.id);
			// message.splice(msgIndex, 1);
			var waitingRoomMediaChannels = state.waitingRoomMediaChannels;
			var id = action.id
			var index = waitingRoomMediaChannels.findIndex(x => x.id == id);
			waitingRoomMediaChannels.splice(index, 1);
			return {
				...state,
				buttonLoading: false,
				waitingRoomMediaChannels: [...waitingRoomMediaChannels],
				error: null,
			};
		}
		case DELETE_WAITINGROOM_MEDIA_CHANNEL_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				// message: null,
				error: action.error,
			};
		}

		// my list waiting room channel
		case MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_REQUEST:{
			return{
				...state,
				buttonLoading3: true,
				error: null
			}
		}

		case MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_SUCCESS:{
			var waitingRoomMediaChannels = state.waitingRoomMediaChannels;
			var operatoryMediaChannels = state.operatoryMediaChannels;
			var isOperatoryRoom = action.isOperatoryRoom;
			var id = action.id;
			var params = action.params;
			if(isOperatoryRoom){
				var index = operatoryMediaChannels.findIndex(x => x.id == id);
				operatoryMediaChannels[index].my_list = params.my_list == 1 ? { channel_id: id } : null;
			}else{
				var index = waitingRoomMediaChannels.findIndex(x => x.id == id);
				waitingRoomMediaChannels[index].my_list = params.my_list == 1 ? { channel_id: id } : null;
			}
			return{
				...state,
				buttonLoading3: false,
				waitingRoomMediaChannels: [ ...waitingRoomMediaChannels ],
				operatoryMediaChannels: [ ...operatoryMediaChannels ],
				error: null
			}
		}

		case MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_FAILURE:{
			return{
				...state,
				buttonLoading3: false,
				error: action.error
			}
		}

		// Create New Waiting Room Channel
		case CREATE_WAITING_ROOM_CHANNEL_REQUEST: {
			return {
				...state,
				loading: true,
				loadingNewChannelCreate:true,
				managewaitingRoomMediaChannel: action.managewaitingRoomMediaChannel,
				error: null,
				buttonLoading: true,
			};
		}
		case CREATE_WAITING_ROOM_CHANNEL_SUCCESS: {
			return {
				...state,
				loading: false,
				loadingNewChannelCreate:false,
				managewaitingRoomMediaChannel: { ...action.managewaitingRoomMediaChannel },
				error: null,
				buttonLoading: false,
			};
		}
		case CREATE_WAITING_ROOM_CHANNEL_FAILURE: {
			return {
				...state,
				loading: false,
				loadingNewChannelCreate:false,
				managewaitingRoomMediaChannel: {},
				error: action.error,
				buttonLoading: false,
			};
		}
		// Update Waiting Room Channel Name
		case UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_REQUEST: {
			console.log(state, 'state request')
			return {
				...state,
				managewaitingRoomMediaChannel: state.managewaitingRoomMediaChannel,
				error: null,
			};
		}
		case UPDATE_WAITINGROOM_MEDIA_CHANNEL_LOADING_REQUEST: {
			return {
				...state,
				buttonLoading2: true,
				buttonLoading:true,
				error: null,
			};
		}
		case UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_SUCCESS: {
			var waitingRoomMediaChannels = state.waitingRoomMediaChannels;
			var managewaitingRoomMediaChannel = action.managewaitingRoomMediaChannel
			var index = waitingRoomMediaChannels.findIndex(x => x.id == managewaitingRoomMediaChannel.id);
			waitingRoomMediaChannels[index] = {...waitingRoomMediaChannels[index] , ...managewaitingRoomMediaChannel};
			return {
				...state,
				buttonLoading2: false,
				buttonLoading:false,
				managewaitingRoomMediaChannel: { ...action.managewaitingRoomMediaChannel },
				waitingRoomMediaChannels:[...waitingRoomMediaChannels],
				error: null,
			};
		}

		case UPDATE_WAITINGROOM_MEDIA_CHANNEL_CC_STATUS_SUCCESS:{
			var waitingRoomMediaChannels = state.waitingRoomMediaChannels;

			var id = action.id;
			var params = action.params;
			var index = waitingRoomMediaChannels.findIndex(x => x.id == id);
			waitingRoomMediaChannels[index].cc_status = params.cc_status;
			return {
				...state,
				buttonLoading2: false,
				buttonLoading:false,
				waitingRoomMediaChannels: [ ...waitingRoomMediaChannels ],
				error: null,
			};
		}

		case UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_FAILURE: {
			return {
				...state,
				buttonLoading2: false,
				buttonLoading: false,
				managewaitingRoomMediaChannel: state.managewaitingRoomMediaChannel,
				error: action.error,
			};
		}

		// GET UPDATE Waiting Room Channel
		case UPDATE_WAITING_ROOM_CHANNEL_REQUEST: {
			return {
				...state,
				loading: true,
				manageChannel: {},
				error: null,
			};
		}
		case UPDATE_WAITING_ROOM_CHANNEL_SUCCESS: {
			return {
				...state,
				loading: false,
				manageChannel: action.manageChannel,
				error: null,
			};
		}
		case UPDATE_WAITING_ROOM_CHANNEL_FAILURE: {
			return {
				...state,
				loading: true,
				manageChannel: {},
				error: action.error,
			};
		}
		// POST UPDATE Waiting Room Channel
		case POST_UPDATE_WAITING_ROOM_CHANNEL_REQUEST: {
			return {
				...state,
				loading: true,
				message: null,
				error: null,
			};
		}
		case POST_UPDATE_WAITING_ROOM_CHANNEL_SUCCESS: {
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		}
		case POST_UPDATE_WAITING_ROOM_CHANNEL_FAILURE: {
			return {
				...state,
				loading: true,
				message: null,
				error: action.error,
			};
		}
		// GET Waiting Room Timeline Trivia
		case GET_WAITING_ROOM_CHANNEL_TRIVIA_REQUEST: {
			return {
				...state,
				loading: true,
				waitingRoomsChannelTrivia: [],
				error: null,
			};
		}
		case GET_WAITING_ROOM_CHANNEL_TRIVIA_SUCCESS: {
			return {
				...state,
				loading: false,
				waitingRoomsChannelTrivia: action.waitingRoomsChannelTrivia,
				error: null,
			};
		}
		case GET_WAITING_ROOM_CHANNEL_TRIVIA_FAILURE: {
			return {
				...state,
				loading: true,
				waitingRoomsChannelTrivia: [],
				error: action.error,
			};
		}
		//
		//
		//
		// PUT Channel wise Custom Messages in Waiting Room
		case PUT_CHANNELWISE_CUSTOM_MESSAGES_REQUEST: {
			return {
				...state,
				loading: true,
				putCustomMessages: [],
				error: null,
			};
		}
		case PUT_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS: {
			return {
				...state,
				loading: false,
				putCustomMessages: action.putCustomMessages,
				error: null,
			};
		}
		case PUT_CHANNELWISE_CUSTOM_MESSAGES_FAILURE: {
			return {
				...state,
				loading: true,
				putCustomMessages: [],
				error: action.error,
			};
		}
		// Waiting Room TImeline Education Tab
		case NEW_WAITING_ROOM_TIMELINE_EDUCATION_REQUEST: {
			return {
				...state,
				loading: true,
				educationTimeline: [],
				error: null,
			};
		}

		case NEW_WAITING_ROOM_TIMELINE_EDUCATION_SUCCESS: {
			return {
				...state,
				loading: false,
				educationTimeline: [...action.educationTimeline],
				error: null,
			};
		}

		case NEW_WAITING_ROOM_TIMELINE_EDUCATION_FAILURE: {
			return {
				...state,
				loading: true,
				educationTimeline: [],
				error: action.error,
			};
		}
		// Get Live Weather as per location entered in weather location

		//GET_ACCESS_CODE_REQUEST
		case GET_ACCESS_CODE_REQUEST: {
			return {
				...state,
				getAccessCode: { ...action.getAccessCode },
				error: null,
			};
		}

		case GET_ACCESS_CODE_SUCCESS: {
			return {
				...state,
				getAccessCode: action.getAccessCode,
				error: action.error,
			};
		}

		case GET_ACCESS_CODE_FAIL: {
			return {
				...state,
				getAccessCode: action.getAccessCode,
				error: action.error,
			};
		}

		//RESET_ACCESS_CODE_REQUEST
		case RESET_ACCESS_CODE_REQUEST: {
			return {
				...state,
				resetAccessCode: { ...action.resetAccessCode },
				error: null,
			};
		}

		case RESET_ACCESS_CODE_SUCCESS: {
			var resetAccessCode = state.resetAccessCode;
			resetAccessCode = action.resetAccessCode;
			resetAccessCode.isGenerated = true;
			resetAccessCode.isContinue = true;
			resetAccessCode.isLoading = false;
			resetAccessCode.isdisabled = true;
			return {
				...state,
				resetAccessCode: { ...resetAccessCode },
				practiceUserAccountInfo: {},
				// practiceUserLoginInfo: {},
				error: action.error,
			};
		}

		case RESET_ACCESS_CODE_FAIL: {
			return {
				...state,
				resetAccessCode: action.resetAccessCode,
				error: action.error,
			};
		}

		// Waiting Room TImeline Entertainment Tab
		case NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_REQUEST: {
			return {
				...state,
				loading: true,
				entertainmentTimeline: [],
				error: null,
			};
		}
		case NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_SUCCESS: {
			return {
				...state,
				loading: false,
				entertainmentTimeline: action.entertainmentTimeline,
				error: null,
			};
		}

		case NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_FAILURE: {
			return {
				...state,
				loading: true,
				entertainmentTimeline: [],
				error: action.error,
			};
		}

		// POST Display Options in Waiting Room Footer
		case POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: null,
			};
		}
		case POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				message: action.message,
				error: null,
			};
		}
		case POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				message: null,
				error: action.error,
			};
		}
		// Waiting Room TImeline Me Videos Tab
		case NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST: {
			console.log("action", action);
			return {
				...state,
				// loading: action.isReload ? action.isReload : true,
				// myVideosTimeline: [],
				error: null,
			};
		}
		case NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS: {
			return {
				...state,
				loading: false,
				buttonLoading: false,
				myVideosTimeline: [...action.myVideosTimeline],
				error: null,
			};
		}
		case NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE: {
			return {
				...state,
				loading: false,
				// myVideosTimeline: [],
				error: action.error,
			};
		}

		//GET_SUBSCRIBE_LIST
		case GET_SUBSCRIBE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				subscribeList: null,
				error: null,

			};
		}
		case GET_SUBSCRIBE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				subscribeList: action.subscribeList,
				error: false,
			};
		}
		case GET_SUBSCRIBE_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				subscribeList: false,
				error: action.subscribeList,
			};
		}


		//GET_OPERATORY_VIDEO_REQUEST
		case GET_OPERATORY_VIDEO_REQUEST: {
			return {
				...state,
				loading: true,
				// operatoryRoomEnterainmentVideos: [],
				error: null,

			};
		}
		case GET_OPERATORY_VIDEO_SUCCESS: {
			return {
				...state,
				loading: false,
				[action.stateName]: action.videos,
				error: false,
			};
		}
		case GET_OPERATORY_VIDEO_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				error: action.subscribeList,
			};
		}


		// Remove Video in My Video Tab in Waiting Room Timeline
		case REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: null,
			};
		}
		case REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS: {
			var updateMyVideosTimeline = state.myVideosTimeline;
			var catIndex = updateMyVideosTimeline.findIndex(x => x.categories_id == action.video.category_id);
			var videoIndex = updateMyVideosTimeline[catIndex].video.findIndex(x => x.waiting_room_media_id == action.video.waiting_room_media_id);
			updateMyVideosTimeline[catIndex].video.splice(videoIndex, 1);
			return {
				...state,
				buttonLoading: false,
				myVideosTimeline: [...updateMyVideosTimeline],
				error: null,
			};
		}
		case REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				message: null,
				error: action.error,
			};
		}

		// Remove my slide Tab in Waiting Room Timeline
		case REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: null,
			};
		}
		case REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS: {
			var waitingRoomChannelSlides = state.waitingRoomChannelSlides;
			var catIndex = waitingRoomChannelSlides.findIndex(x => x.id == action.slide.cat_id);
			var slideIndex = waitingRoomChannelSlides[catIndex].get_slide.findIndex(x => x.id == action.slide.id);
			waitingRoomChannelSlides[catIndex].get_slide.splice(slideIndex, 1);
			return {
				...state,
				buttonLoading: false,
				waitingRoomChannelSlides: [...waitingRoomChannelSlides],
				error: null,
			};
		}
		case REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				message: null,
				error: action.error,
			};
		}

		//update waitind room medai
		case UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: action.error,
			};
		}
		case UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS: {
			var updateMyVideosTimeline = state.myVideosTimeline;
			var catIndex = updateMyVideosTimeline.findIndex(x => x.categories_id == action.data.categories[0].media_category_id);
			var videoIndex = updateMyVideosTimeline[catIndex].video.findIndex(x => x.waiting_room_media_id == action.data.id);
			updateMyVideosTimeline[catIndex].isUpdate = true;
			updateMyVideosTimeline[catIndex].video[videoIndex].title = action.data.media_language.title;
			return {
				...state,
				buttonLoading: false,
				myVideosTimeline: [...updateMyVideosTimeline],
				error: action.error,
			};
		}
		case UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}
		// Slides Stock Library List in Waiting Room Timeline
		case GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_REQUEST: {
			return {
				...state,
				loading: true,
				stockLibrary: [],
				error: null,
			};
		}
		case GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_SUCCESS: {
			return {
				...state,
				loading: false,
				stockLibrary: action.stockLibrary,
				error: null,
			};
		}
		case GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_FAILURE: {
			return {
				...state,
				loading: true,
				stockLibrary: [],
				error: action.error,
			};
		}
		// Slides Stock Library Search in Waiting Room Timeline
		case GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_REQUEST: {
			return {
				...state,
				loading: true,
				stockLibrarySearch: [],
				error: null,
			};
		}
		case GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_SUCCESS: {
			return {
				...state,
				loading: false,
				stockLibrarySearch: action.stockLibrarySearch,
				error: null,
			};
		}
		case GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_FAILURE: {
			return {
				...state,
				loading: true,
				stockLibrarySearch: [],
				error: action.error,
			};
		}
		// Categories for uploading my videos tab in Waiting Room TImeline
		case GET_MYVIDEOS_CATEGORY_LIST_REQUEST: {
			return {
				...state,
				loading: true,
				myVideosCategories: [],
				error: null,
			};
		}
		case GET_MYVIDEOS_CATEGORY_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				myVideosCategories: action.myVideosCategories,
				error: null,
			};
		}
		case GET_MYVIDEOS_CATEGORY_LIST_FAILURE: {
			return {
				...state,
				loading: true,
				myVideosCategories: [],
				error: action.error,
			};
		}
		// Upload My Videos to Wistia in Waiting Room Timeline
		case UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_REQUEST: {
			return {
				...state,
				loading: true,
				message: null,
				error: null,
			};
		}
		case UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_SUCCESS: {
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		}
		case UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_FAILURE: {
			return {
				...state,
				loading: true,
				message: null,
				error: action.error,
			};
		}
		// Request Practice video in waiting room all channels
		case POST_REQUEST_PRACTICE_VIDEO_REQUEST: {
			return {
				...state,
				loading: true,
				message: null,
				error: null
			}
		}
		case POST_REQUEST_PRACTICE_VIDEO_SUCCESS: {
			return {
				...state,
				loading: false,
				message: action.message,
				error: null
			}
		}
		case POST_REQUEST_PRACTICE_VIDEO_FAILURE: {
			return {
				...state,
				loading: true,
				message: null,
				error: action.error
			}
		}
		// Waiting Room Channel Slides
		case WAITING_ROOM_CHANNEL_SLIDE_REQUEST: {
			return {
				...state,
				loading: true,
				waitingRoomChannelSlides: [],
				error: null
			}
		}
		case WAITING_ROOM_CHANNEL_SLIDE_SUCCESS: {
			return {
				...state,
				loading: false,
				waitingRoomChannelSlides: action.waitingRoomChannelSlides,
				error: null
			}
		}
		case WAITING_ROOM_CHANNEL_SLIDE_FAILURE: {
			return {
				...state,
				loading: true,
				waitingRoomChannelSlides: [],
				error: action.error
			}
		}

		case GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_REQUEST: {
			return {
				...state,
				loading: true,
				error: null
			};
		}

		case GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_SUCCESS: {
			return {
				...state,
				loading: false,
				WaitingRoomSlideBackgroundImages: action.backgroundImages
			};
		}

		case GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};

		 }


		// GET Website Media Configuration
		case GET_WEBSITE_MEDIA_CONFIG_REQUEST: {
			return {
				...state,
				loading: true,
				// websiteMediaConfigs: {},
				error: null
			}
		}
		case GET_WEBSITE_MEDIA_CONFIG_SUCCESS: {
			return {
				...state,
				loading: false,
				websiteMediaConfigs: action.websiteMediaConfigs,
				error: null
			}
		}
		case GET_WEBSITE_MEDIA_CONFIG_FAILURE: {
			return {
				...state,
				loading: true,
				// websiteMediaConfigs: {},
				error: null
			}
		}
		// POST Website Media Configuration
		case POST_WEBSITE_MEDIA_CONFIG_REQUEST: {
			return {
				...state,
				buttonLoading2: true,
				message: null,
				error: null
			}
		}
		case POST_WEBSITE_MEDIA_CONFIG_SUCCESS: {
			return {
				...state,
				buttonLoading2: false,
				message: action.message,
				error: null
			}
		}
		case POST_WEBSITE_MEDIA_CONFIG_FAILURE: {
			return {
				...state,
				buttonLoading2: false,
				message: null,
				error: action.error
			}
		}

		// PUBLISH_WEBSITE_MEDIA
		case PUBLISH_WEBSITE_MEDIA_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: null
			}
		}
		case PUBLISH_WEBSITE_MEDIA_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				message: action.message,
				error: null
			}
		}
		case PUBLISH_WEBSITE_MEDIA_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				message: null,
				error: action.error
			}
		}

		// PUBLISH_WEBSITE_MEDIA
		case POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_REQUEST: {
			return {
				...state,
				buttonLoading2: true,
				// websiteTimeline: null,
				error: null
			}
		}
		case POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_SUCCESS: {
			var websiteMediaState = state.websiteMediaState;
			websiteMediaState.map(x => {
				var isSaved = JSON.parse(action.websiteTimeline.website_media_id).find(z => z == x.website_media_id)
				if (isSaved) {
					x.website_media.is_predefined_media = 1;
				} else {
					x.website_media.is_predefined_media = 0;
				}
			})
			return {
				...state,
				buttonLoading2: false,
				websiteTimeline: action.websiteTimeline,
				// websiteMediaState:websiteMediaState,
				error: null
			}
		}
		case POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_FAILURE: {
			return {
				...state,
				buttonLoading2: false,
				// websiteTimeline: null,
				error: action.error
			}
		}

		// Get Website Media HTML Code for Copy to clipboard
		//
		// GET_WEBSITE_MEDIA_HTML_CODE_SUCCESS
		// GET_WEBSITE_MEDIA_HTML_CODE_FAILURE
		case GET_WEBSITE_MEDIA_HTML_CODE_REQUEST: {
			return {
				...state,
				loading: true,
				// copyHTML: [],
				error: null
			}
		}
		case GET_WEBSITE_MEDIA_HTML_CODE_SUCCESS: {
			return {
				...state,
				loading: false,
				copyHTML: action.copyHTML,
				error: null
			}
		}
		case GET_WEBSITE_MEDIA_HTML_CODE_FAILURE: {
			return {
				...state,
				loading: false,
				copyHTML: '',
				error: action.error
			}
		}
		//GET INVOICE EMAIL
		case GET_INVOICE_EMAIL_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				getInvoiceEmails: null,
				error: null,

			};
		}

		case GET_INVOICE_EMAIL_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				getInvoiceEmails: action.getInvoiceEmails,
				error: action.error,
			};
		}

		case GET_INVOICE_EMAIL_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				getInvoiceEmails: action.getInvoiceEmails,
				error: action.error,
			};
		}

		//UPDATE INVOICE EMAIL
		case POST_INVOICE_EMAIL_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				invoiceMailUpdated: null,
				error: null,

			};
		}

		case POST_INVOICE_EMAIL_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				invoiceMailUpdated: action.invoiceMailUpdated,
				error: action.error,
			};
		}

		case POST_INVOICE_EMAIL_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				invoiceMailUpdated: action.invoiceMailUpdated,
				error: action.error,
			};
		}

		//UNASSIGN MANAGER
		case UNASSIGN_MANAGER_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				addLocationResponce: null,
				error: null,

			};
		}

		case UNASSIGN_MANAGER_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				unassignManager: action.unassignManager,
				error: action.error,
			};
		}

		case UNASSIGN_MANAGER_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				unassignManager: action.unassignManager,
				error: action.error,
			};
		}

		//MANAGER INVITE
		case SEND_MANAGER_INVITE_REQUEST: {
			return {
				...state,
				isCompleted: false,
				loading: true,
				buttonLoading2:true,
				addLocationResponce: null,
				error: null,

			};
		}

		case SEND_MANAGER_INVITE_SUCCESS: {
			return {
				...state,
				isCompleted: true,
				loading: false,
				buttonLoading2:false,
				managerInviteSend: action.managerInviteSend,
				error: action.error,
			};
		}

		case SEND_MANAGER_INVITE_FAILURE: {
			return {
				...state,
				isCompleted: false,
				loading: false,
				buttonLoading2:false,
				managerInviteSend: action.managerInviteSend,
				error: action.error,
			};
		}

		case CHECK_PRACTIC_IS_CORPORATE: {
			console.log(action.isCorporate,'action.isCorporate')
			return {
				...state,
				isCorporate: action.isCorporate
			};
		}

		//POST_LOGIN_PRACTICE_LOCATION_REQUEST
		case POST_LOGIN_PRACTICE_LOCATION_REQUEST: {
			return {
				...state,
				// practiceUserLoginInfo: { ...action.loginPracticeLocationinfo },
				error: null,

			};
		}

		case POST_LOGIN_PRACTICE_LOCATION_SUCCESS: {
			console.log(action.loginPracticeLocationinfo,'loginPracticeLocationinfo')
			return {
				...state,
				practiceUserLoginInfo: { ...action.loginPracticeLocationinfo },
				practiceProfileInfo: { ...action.practiceProfileInfo },
				practiceInfo: { ...action.practiceInfo },
				error: action.error,
			};
		}

		case POST_LOGIN_PRACTICE_LOCATION_FAIL: {
			return {
				...state,
				practiceUserLoginInfo: { ...action.loginPracticeLocationinfo },
				error: action.error,
			};
		}

		//POST_SUBSCRIBE_REQUEST
		case POST_NEW_LOCATION_REQUEST: {
			return {
				...state,
				newlocationinfo: { ...action.newlocationinfo },
				error: null,

			};
		}

		case POST_NEW_LOCATION_SUCCESS: {
			practiceProfileInfo = state.practiceProfileInfo;
			practiceProfileInfo.isCompleted = true;
			practiceProfileInfo.isLoading = false;
			return {
				...state,
				newlocationinfo: { ...action.newlocationinfo },
				practiceProfileInfo: { ...practiceProfileInfo },
				error: action.error,
			};
		}

		case POST_NEW_LOCATION_FAIL: {
			practiceProfileInfo = state.practiceProfileInfo;
			// practiceProfileInfo = action.practiceProfileInfo;
			practiceProfileInfo.isLoading = false;
			return {
				...state,
				newlocationinfo: { ...action.newlocationinfo },
				practiceProfileInfo: { ...practiceProfileInfo },
				error: action.error,
			};
		}

		//GET_AUTHORIZED_USER_REQUEST
		case GET_AUTHORIZED_USER_REQUEST: {
			return {
				...state,
				loading: true,
				practiceUserAccountInfo: {...action.practiceUserAccountInfo},
				error: null,
			};
		}
		case GET_AUTHORIZED_USER_SUCCESS: {
			return {
				...state,
				loading: false,
				practiceUserAccountInfo: { ...action.practiceUserAccountInfo },
				error: action.error,
			};
		}
		case GET_AUTHORIZED_USER_FAIL: {
			return {
				...state,
				loading: false,
				// practiceUserAccountInfo: action.practiceUserAccountInfo,
				error: action.error,
			};
		}

		//GET user profile
		case GET_USER_PROFILE_REQUEST: {
			return {
				...state,
				loading: true,
				practiceUserProfileInfo: action.practiceUserProfileInfo,
				error: null,
			};
		}
		case GET_USER_PROFILE_SUCCESS: {
			return {
				...state,
				loading: false,
				practiceUserProfileInfo: action.practiceUserProfileInfo,
				error: action.error,
			};
		}
		case GET_USER_PROFILE_FAIL: {
			return {
				...state,
				loading: false,
				// practiceUserAccountInfo: action.practiceUserAccountInfo,
				error: action.error,
			};
		}

		//GET_AUTHORIZED_USER_REQUEST
		case UPDATE_AUTHORIZED_USER_REQUEST: {
			return {
				...state,
				loading: true,
				practiceUserAccountInfo: { ...action.practiceUserAccountInfo },
				error: null,
			};
		}
		case UPDATE_AUTHORIZED_USER_SUCCESS: {
			var practiceUserAccountInfo = state.practiceUserAccountInfo
			var practiceUserLoginInfo = state.practiceUserLoginInfo
			if (state.practiceUserLoginInfo.id == action.practiceUserAccountInfo.id) {
				// practiceUserLoginInfo	= action.practiceUserAccountInfo
				practiceUserLoginInfo = { ...state.practiceUserLoginInfo, ...action.practiceUserAccountInfo };
			}
			// practiceUserAccountInfo = {...action.practiceUserAccountInfo ,...state.practiceUserAccountInfo};
			practiceUserAccountInfo['is_verified_mobile'] = action.practiceUserAccountInfo.is_verified_mobile
			practiceUserAccountInfo.isLoading = false;
			practiceUserAccountInfo.isUpdateSuccess = true;
			return {
				...state,
				loading: false,
				practiceUserAccountInfo: { ...practiceUserAccountInfo },
				practiceUserLoginInfo: practiceUserLoginInfo,
				error: action.error,
			};
		}
		case UPDATE_AUTHORIZED_USER_FAIL: {
			var practiceUserAccountInfo = state.practiceUserAccountInfo
			practiceUserAccountInfo.isLoading = false;
			return {
				...state,
				loading: false,
				practiceUserAccountInfo: practiceUserAccountInfo ,
				error: action.error,
			};
		}

		// Waiting Room TImeline Me Videos UPLOAD
		case UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			};
		}
		case UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				error: null,
			};
		}
		case UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}


		case GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_SUCCESS: {
			return {
				...state,
				loading: false,
				WaitingRoomSlideTemplate: action.templates
			};
		}
		case GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}

		case GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_REQUEST: {
			return {
				...state,
				loading: true,
				error: null
			};
		}
		case GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_SUCCESS: {
			return {
				...state,
				loading: false,
				WaitingRoomSlideStockImages: action.stockImages
			};
		}

		case GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}

		case SET_WAITING_ROOM_TIMELINE_NEW_SLIDE: {
			return {
				...state,
				newSlide: { ...state.newSlide, ...action.payload }
			};
		}

		case CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null
			};
		}
		case CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				//add to waiting roomslide
				newSlide: {},
			};
		}

		case CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}

		case GET_SOCIAL_REQUEST: {
			return {
				...state,
				loading: false,
				error: null,
			};
		}

		case GET_SOCIAL_SUCCESS: {
			return {
				...state,
				loading: false,
				socailMedia: action.socailMedia,
				error: null,
			};
		}

		case GET_SOCIAL_FAILURE: {
			return {
				...state,
				loading: false,
				error: null,
			};
		} 

		case REMOVE_SOCIAL_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
				twitterRemoveLoading: action.providerId == TWITTERPROVIDER,
				fbRemoveLoading: action.providerId == FBPROVIDER,
			};
		}

		case REMOVE_SOCIAL_SUCCESS: {
			var providerId = action.providerId;
			var socailMedia = state.socailMedia;
			var index = socailMedia.findIndex(x => x.provider_id == providerId);
			socailMedia.splice(index, 1);
			console.log("socailMedia socailMedia", socailMedia)
			return {
				...state,
				buttonLoading: false,
				error: null,
				socailMedia: [...socailMedia],
				twitterRemoveLoading: false,
				fbRemoveLoading: false,
			};
		}

		case REMOVE_SOCIAL_FAILURE:{
			return {
				...state,
				buttonLoading: false,
				error: action.error,
				twitterRemoveLoading: false,
				fbRemoveLoading: false,
			};
		}


		case ADD_SOCIAL_MEDIA_REQUEST: {
			return {
				...state,
				loading: false,
				twitterLinkLoading: action.providerId == TWITTERPROVIDER,
				fbLinkLoading: action.providerId == FBPROVIDER,
			}
		}

		case ADD_SOCIAL_MEDIA_SUCCESS: {
			var socailMedia = state.socailMedia
			
			if(action.params.updateUser != true)
				socailMedia.push(action.socailMedia);

			return {
				...state,
				error: null,
				socailMedia: [...socailMedia],
				loading: false,
				twitterLinkLoading: false,
				fbLinkLoading: false,
			}
		}

		case ADD_SOCIAL_MEDIA_FAILURE: {
			return {
				...state,
				error: action.error,
				loading: false,
				twitterLinkLoading: false,
				fbLinkLoading: false,
			}
		}

		case POST_TIMELINE_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			}
		}

		case POST_TIMELINE_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				error: null,
			}
		}

		case POST_TIMELINE_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: null,
			}
		}

		case GET_TIMELINE_REQUEST: {
			return {
				...state,
				loading: true,
				channelTimeLine: [],
				error: null,
			}
		}

		case GET_TIMELINE_SUCCESS: {
			return {
				...state,
				loading: false,
				channelTimeLine: action.data,
				error: null,
			}
		}

		case GET_TIMELINE_FAILURE: {
			return {
				...state,
				loading: false,
				channelTimeLine: [],
				error: null,
			}
		}
		// Added by Dixit Solanki ( work releted operatory chanel)

		// Create operatory Channel
		case CREATE_OPERATORY_CHANNEL_REQUEST: {
			return {
				...state,
				loading: true,
				loadingNewChannelCreate:true,
				manageOperatoryChannel: action.manageOperatoryChannel,
				error: null,
				buttonLoading: true,
			};
		}
		case CREATE_OPERATORY_CHANNEL_SUCCESS: {
			return {
				...state,
				loading: false,
				loadingNewChannelCreate:false,
				manageOperatoryChannel: { ...action.manageOperatoryChannel },
				error: null,
				buttonLoading: false,
			};
		}
		case CREATE_OPERATORY_CHANNEL_FAILURE: {
			return {
				...state,
				loading: true,
				loadingNewChannelCreate:false,
				manageOperatoryChannel: {},
				error: action.error,
				buttonLoading: false,
			};
		}
		case GET_OPERATORY_MEDIA_CHANNEL_LIST_REQUEST: {
			return {
				...state,
				loading: true,
				isloading:true,
				error: null,
			};
		}
		// get operatory channel list
		case GET_OPERATORY_MEDIA_CHANNEL_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				isloading:false,
				operatoryMediaChannels: action.operatoryMediaChannels,
				error: null,
			};
		}
		case GET_OPERATORY_MEDIA_CHANNEL_LIST_FAILURE: {
			return {
				...state,
				loading: false,
				isloading:false,
				error: action.error,
				operatoryMediaChannels: [],
			};
		}
		// delete operatory Channel
		case DELETE_OPERATORY_CHANNEL_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				message: null,
				error: null,
			};
		}
		case DELETE_OPERATORY_CHANNEL_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				message: action.message,
				error: null,
			};
		}
		case DELETE_OPERATORY_CHANNEL_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				message: null,
				error: action.error,
			};
		}
		// duplicate operatory Channel
		case DUPLICATE_OPERATORY_CHANNEL_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			};
		}
		case DUPLICATE_OPERATORY_CHANNEL_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				// operatoryMediaChannels: { ...action.operatoryMediaChannels },
				error: null,
			};
		}
		case DUPLICATE_OPERATORY_CHANNEL_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}
		//  get operatory info Channel
		case GET_OPERATORY_CHANNEL_REQUEST: {
			return {
				...state,
				// loading: true,
				buttonLoading: true,
				error: null,
				// manageOperatoryChannel: action.manageOperatoryChannel,
			};
		}

		case GET_OPERATORY_CHANNEL_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				manageOperatoryChannel: action.manageOperatoryChannel,
				error: null,
			};
		}
		case GET_OPERATORY_CHANNEL_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
				manageOperatoryChannel: action.manageOperatoryChannel,
			};
		}
		//  restore operatory info Channel
		case RESTORE_OPERATORY_CHANNEL_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			};
		}
		case RESTORE_OPERATORY_CHANNEL_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				error: null,
			};
		}
		case RESTORE_OPERATORY_CHANNEL_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}
		// update  operatory Channel Rename

		case UPDATE_OPERATORYGROOM_MEDIA_CHANNEL_LOADING_REQUEST: {
			return {
				...state,
				buttonLoading2: true,
				buttonLoading: true,
				error: null,
			};
		}

		case UPDATE_OPERATORY_CHANNEL_NAME_REQUEST: {
			return {
				...state,
				// buttonLoading: true,
				// buttonLoading2: true,
				error: null,
			};
		}
		case UPDATE_OPERATORY_CHANNEL_NAME_SUCCESS: {
			let operatoryMediaChannels = state.operatoryMediaChannels;
			var manageOperatoryChannel = action.manageOperatoryChannel
			let index = operatoryMediaChannels.findIndex(x => x.id == manageOperatoryChannel.id);
			operatoryMediaChannels[index] = {...operatoryMediaChannels[index] , ...manageOperatoryChannel};
			return {
				...state,
				buttonLoading: false,
				buttonLoading2: false,
				manageOperatoryChannel: { ...action.manageOperatoryChannel },
				operatoryMediaChannels:[...operatoryMediaChannels],
				error: null,
			};
		}
		case UPDATE_OPERATORY_CHANNEL_NAME_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				buttonLoading2: false,
				manageOperatoryChannel: state.manageOperatoryChannel,
				error: action.error,
			};
		}

		case UPDATE_OPEROTORY_MEDIA_CHANNEL_CC_STATUS_SUCCESS: {
			var operatoryMediaChannels = state.operatoryMediaChannels;
			var id = action.id;
			var params = action.params;
			var index = operatoryMediaChannels.findIndex(x => x.id == id);
			operatoryMediaChannels[index].cc_status = params.cc_status;
			return {
				...state,
				buttonLoading2: false,
				operatoryMediaChannels: [ ...operatoryMediaChannels ],
				error: null,
			};
		}

		// get Theme operatory Channel
		case GET_OPERATORY_THEME_LIST_REQUEST: {
			return {
				...state,
				loading: true,
				operatoryThemeList: action.operatoryThemeList,
				error: null,
			};
		}
		case GET_OPERATORY_THEME_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				operatoryThemeList: action.operatoryThemeList,
				error: null,
			};
		}
		case GET_OPERATORY_THEME_LIST_FAILURE: {
			return {
				...state,
				loading: true,
				error: action.error,
			};
		}
		// get options operatory Channel
		case GET_OPERATORY_DISPLAY_OPTIONS_REQUEST: {
			return {
				...state,
				loading: true,
				displayOptions: {},
				error: null,
			};
		}
		case GET_OPERATORY_DISPLAY_OPTIONS_SUCCESS: {
			return {
				...state,
				loading: false,
				displayOptions: action.displayOptions,
				error: null,
			};
		}
		case GET_OPERATORY_DISPLAY_OPTIONS_FAILURE: {
			return {
				...state,
				loading: true,
				displayOptions: {},
				error: action.error,
			};
		}
		// get whether operatory Channel

		case GET_OPERATORY_LIVE_WEATHER_DATA_REQUEST: {
			return {
				...state,
				loading: true,
				liveWeatherData: {},
				error: null,
			};
		}
		case GET_OPERATORY_LIVE_WEATHER_DATA_SUCCESS: {
			return {
				...state,
				loading: false,
				liveWeatherData: action.liveWeatherData,
				error: null,
			};
		}
		case GET_OPERATORY_LIVE_WEATHER_DATA_FAILURE: {
			return {
				...state,
				loading: true,
				liveWeatherData: {},
				error: action.error,
			};
		}
		// update options operatory Channel
		case POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_REQUEST: {
			return {
				...state,
				loading: true,
				message: null,
				error: null,
			};
		}
		case POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_SUCCESS: {
			return {
				...state,
				loading: false,
				message: action.message,
				error: null,
			};
		}
		case POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_FAILURE: {
			return {
				...state,
				loading: true,
				message: null,
				error: action.error,
			};
		}


		case GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_REQUEST: {
			return {
				...state,
				error: null,
			};
		}

		case GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_SUCCESS: {
			return {
				...state,
				playlists: action.playlists,
				error: null,
			};
		}

		case GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_FAILURE: {
			return {
				...state,
				error: action.error,
			};
		}

		//POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST:{
		case POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST: {
			return {
				...state,
				error: null,
				buttonLoading3: true
			};
		}

		case POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS: {
			return {
				...state,
				playListData: action.playListData,
				buttonLoading3: false,
				error: null,
			};
		}

		case POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE: {
			return {
				...state,
				buttonLoading3: false,
				error: action.error,
			};
		}

		//DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST:{
		case DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST: {
			return {
				...state,
				error: null,
			};
		}

		case DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS: {
			var playlists = state.playlists;
			var findIndex = playlists.findIndex(x => x.id == action.deletedPlayListId);
			playlists.splice(findIndex, 1);
			return {
				...state,
				removeplayListData: true,
				playlists: playlists,
				error: null,
			};
		}

		case DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE: {
			return {
				...state,
				removeplayListData: false,
				error: action.error,
			};
		}

		case ADD_CATEGORY_SPECIALIZATION: {
			return {
				...state,
				loading: false,
				specializationCategory: action.payload,
				error: false,
			};
		}

		case CHAIRSIDE_RESET_DEFAULT: {
			return {
				...state,
				isResetDefaultChairside: !state.isResetDefaultChairside,
			}
		}

		//GET_CHAIRSIDE_MEDIA_CC_LIST_REQUEST:{
		case GET_CHAIRSIDE_MEDIA_CC_LIST_REQUEST: {
			return {
				...state,
				error: null,
				chairSideMediaCCList:[],
				buttonLoading3:true
			};
		}

		case GET_CHAIRSIDE_MEDIA_CC_LIST_SUCCESS: {
			return {
				...state,
				chairSideMediaCCList: action.chairSideMediaCCList,
				buttonLoading3: false,
				error: null,
			};
		}

		case GET_CHAIRSIDE_MEDIA_CC_LIST_FAILURE: {
			return {
				...state,
				buttonLoading3:false,
				chairSideMediaCCList:[],
				error: action.error,
			};
		}

		// ############# DD-11

		case SHARE_VIDEO_LINK_CLEAR_REQUEST: {
			return {
				...state,
				shareVideoData: action.shareVideoData,
			};
		}

		case SHARE_VIDEO_LINK_ID_REQUEST: {
			return {
				...state,
				shareVideoId: action.shareVideoId,
			};
		}

		case GET_SHARE_VIDEO_LINK_REQUEST: {
			return {
				...state,
				error: null,
				buttonLoading3:true
			};
		}

		case GET_SHARE_VIDEO_LINK_SUCCESS: {
			return {
				...state,
				shareVideoList: action.shareVideoList,
				buttonLoading3: false,
				error: null,
			};
		}

		case GET_SHARE_VIDEO_LINK_FAILURE: {
			return {
				...state,
				buttonLoading3:false,
				error: action.error,
			};
		}

		case SHARE_VIDEO_LINK_REQUEST: {
			return {
				...state,
				error: null,
				buttonLoading3:true,
				shareVideoData: {},
			};
		}

		case SHARE_VIDEO_LINK_SUCCESS: {
			return {
				...state,
				shareVideoData: action.shareVideoData,
				buttonLoading3: false,
				error: null,
			};
		}

		case SHARE_VIDEO_LINK_FAILURE: {
			return {
				...state,
				buttonLoading3:false,
				error: action.error,
			};
		}
		// ############# DD-11
		

		//GET_CHAIRSIDE_MEDIA_CC_REQUEST:{
		case GET_CHAIRSIDE_MEDIA_CC_REQUEST: {
			return {
				...state,
				loading: true,
				error: null,
				buttonLoading3: true
			};
		}

		case GET_CHAIRSIDE_MEDIA_CC_SUCCESS: {

			return {
				...state,
				loading: false,
				chairSideMediaCC: action.chairSideMediaCC,
				buttonLoading3: false,
				error: null,
			};
		}

		case GET_CHAIRSIDE_MEDIA_CC_FAILURE: {
			return {
				...state,
				loading: false,
				buttonLoading3: false,
				error: action.error,
			};
		}

		//GET_CHAIRSIDE_MEDIA_CC_REQUEST:{
		case UPDATE_CHAIRSIDE_MEDIA_CC_REQUEST: {
			return {
				...state,
				error: null,
				buttonLoading: true
			};
		}

		case UPDATE_CHAIRSIDE_MEDIA_CC_SUCCESS:{
		var	chairSideMediaCC = {...state.chairSideMediaCC}
		chairSideMediaCC['isUpdateSuccess'] = true
		console.log(chairSideMediaCC,'chairSideMediaCC')
			return {
				...state,
				chairSideMediaCC: {...action.chairSideMediaCC },
				buttonLoading: false,
				error: null,
			};
		}

		case UPDATE_CHAIRSIDE_MEDIA_CC_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}

		//  get chairside media custom message
		case GET_CUSTOM_MESSAGES_REQUEST: {
			return {
				...state,
				// loading: true,
				error: null,
				currentMessage: action.currentMessage,
			};
		}

		case GET_CUSTOM_MESSAGES_SUCCESS: {
			return {
				...state,
				// loading: false,
				currentMessage: action.currentMessage,
				error: null,
			};
		}
		case GET_CUSTOM_MESSAGES_FAILURE: {
			return {
				...state,
				// loading: true,
				error: action.error,
				currentMessage: action.currentMessage,
			};
		}

		//  reset chairside media custom message
		case RESET_CUSTOM_MESSAGES_REQUEST: {
			return {
				...state,
				buttonLoading2: true,
				error: null,
				resetMessage: action.resetMessage,
			};
		}

		case RESET_CUSTOM_MESSAGES_SUCCESS: {
			return {
				...state,
				buttonLoading2: false,
				resetMessage: action.resetMessage,
				error: null,
			};
		}
		case RESET_CUSTOM_MESSAGES_FAILURE: {
			return {
				...state,
				buttonLoading2: false,
				error: action.error,
				resetMessage: action.resetMessage,
			};
		}

		//  reset chairside media custom message
		case POST_CUSTOM_MESSAGES_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
				updateCSCustomMessage: action.updateCSCustomMessage,
			};
		}

		case POST_CUSTOM_MESSAGES_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				updateCSCustomMessage: action.updateCSCustomMessage,
				error: null,
			};
		}
		case POST_CUSTOM_MESSAGES_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
				updateCSCustomMessage: action.updateCSCustomMessage,
			};
		}
		
		//  reset chairside media custom message
		
		case SEND_SCREENSHOT__RESET_REQUEST: {
			return {
				...state,
				buttonLoading2: false,
				error: null,
				sendScreenshot: {},
			};
		}

		case SEND_SCREENSHOT_REQUEST: {
			return {
				...state,
				buttonLoading3: true,
				error: null,
				sendScreenshot: {},
			};
		}

		case SEND_SCREENSHOT_SUCCESS: {
			return {
				...state,
				buttonLoading3: false,
				sendScreenshot: action.sendScreenshot,
				error: null,
			};
		}
		case SEND_SCREENSHOT_FAILURE: {
			return {
				...state,
				buttonLoading3: false,
				error: action.error,
				sendScreenshot: action.sendScreenshot,
			};
		}

		//DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST:{
		case DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST:{
			return {
				...state,
				error: null,
				buttonLoading3:true,
				chairSideMediaState: chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, true, "isMyListLoading"),
			};
		}

		case DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS:{
			return {
				...state,
				// chairSideMediaCC: action.chairSideMediaCC,
				buttonLoading3:false,
				chairSideMediaState: [...chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, 0, "in_mylist", "isMyListLoading", false)],
				error: null,
			};
		}

		case DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE:{
			return {
				...state,
				buttonLoading3:false,
				error: action.error,
				chairSideMediaState: chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, false, "isMyListLoading"),
			};
		}

		case DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST:
		case POST_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST: {
			return {
				...state,
				error: null,
				buttonLoading4:true,
				chairSideMediaState: chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, true, "isCCLoading"),
			}
		}

		case POST_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS: {
			return {
				...state,
				// chairSideMediaCC: action.chairSideMediaCC,
				buttonLoading4:false,
				chairSideMediaState: [...chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, 1, "cc_status", "isCCLoading", false)],
				error: null
			}
		}

		case DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE:
		case POST_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE: {
			return {
				...state,
				buttonLoading4:false,
				error: action.error,
				chairSideMediaState: chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, false, "isCCLoading",),
			}
		}

		case DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS:{
			return {
				...state,
				buttonLoading4:false,
				chairSideMediaState: [...chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, 0, "cc_status",  "isCCLoading", false)],
				error: null
			}
		}

		case SET_SELECTED_SPECIALIZATION_CATEGORY:
			return {
			...state,
			selectedSpecializatinCatID: action.selectedSpecializatinCatID

			}

		//POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST:{
		case POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST:{
			return {
				...state,
				error: null,
				buttonLoading3:true
			};
		}

		case POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS:{
			return {
				...state,
				// chairSideMediaCC: action.chairSideMediaCC,
				buttonLoading3:false,
				error: null,
				chairSideMediaState: [...chairsidemediaUpdate(state.chairSideMediaState, action.chairside_media_id, 1, "in_mylist")],
			};
		}

		case POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE:{
			return {
				...state,
				buttonLoading3:false,
				error: action.error,
			};
		}




		//GET_WAITING_ROOM_SCHEDULE_REQUEST:{
		case GET_WAITING_ROOM_SCHEDULE_REQUEST:{
			return {
				...state,
				error: null,
				buttonLoading3:true
			};
		}

		case GET_WAITING_ROOM_SCHEDULE_SUCCESS:{
			return {
				...state,
				waitingRoomSchedule: action.waitingRoomSchedule,
				buttonLoading3:false,
				error: null,
			};
		}

		case GET_WAITING_ROOM_SCHEDULE_FAILURE:{
			return {
				...state,
				buttonLoading3:false,
				error: action.error,
			};
		}

		//CREATE_WAITING_ROOM_SCHEDULE_REQUEST:{
		case CREATE_WAITING_ROOM_SCHEDULE_REQUEST:{
			return {
				...state,
				error: null,
				buttonLoading2:true
			};
		}

		case CREATE_WAITING_ROOM_SCHEDULE_SUCCESS:{
			return {
				...state,
				waitingRoomSchedule: action.waitingRoomSchedule,
				buttonLoading2:false,
				error: null,
			};
		}

		case CREATE_WAITING_ROOM_SCHEDULE_FAILURE:{
			return {
				...state,
				buttonLoading2:false,
				error: action.error,
			};
		}

		//CREATE_WAITING_ROOM_SCHEDULE_REQUEST:{
		case GET_WAITINGROOM_FOOTER_WEATHER_DATA_REQUEST:{
			return {
				...state,
				error: null,
				weatherLocation:{},
				buttonLoading2:true
			};
		}

		case GET_WAITINGROOM_FOOTER_WEATHER_DATA_SUCCESS:{
			return {
				...state,
				weatherLocation: action.weatherLocation,
				buttonLoading2:false,
				error: null,
			};
		}

		case GET_WAITINGROOM_FOOTER_WEATHER_DATA_FAILURE:{
			return {
				...state,
				buttonLoading2:false,
				error: action.error,
			};
		}

		//PUBLISH_WAITINGROOM_TIMELINE_REQUEST:{
		case PUBLISH_WAITINGROOM_TIMELINE_REQUEST:{
			return {
				...state,
				error: null,
				waitingRoomPublishTimeline: null,
				buttonLoading3:true
			};
		}

		case PUBLISH_WAITINGROOM_TIMELINE_SUCCESS:{
			return {
				...state,
				waitingRoomPublishTimeline: {...action.waitingRoomPublishTimeline},
				buttonLoading3:false,
				error: null,
			};
		}

		case PUBLISH_WAITINGROOM_TIMELINE_FAILURE:{
			return {
				...state,
				buttonLoading3:false,
				waitingRoomPublishTimeline: null,
				error: action.error,
			};
		}

		case PUBLISH_WAITINGROOM_TIMELINE_NULL:{
			return {
				...state,
				buttonLoading3:false,
				waitingRoomPublishTimeline: null,
				error: action.error,
			};
		}

		// Waiting Room TImeline Me Videos UPLOAD
		case UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			};
		}
		case UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				error: null,
			};
		}
		case UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}

		// OperatoryRoom TImeline My Videos Update
		case UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				error: null,
			};
		}
		case UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS: {
			var operatoryRoomMyVideos = state.operatoryRoomMyVideos;
			console.log(operatoryRoomMyVideos,'operatoryRoomMyVideosoperatoryRoomMyVideos')
			var id = action.params.operatory_media_id;
			var params = action.params;
			var catindex = operatoryRoomMyVideos.findIndex(x=>x.categories_id == params.category_id)
			var index = operatoryRoomMyVideos[catindex].video.findIndex(x => x.operatory_media_id == id);
			operatoryRoomMyVideos[catindex].video[index].title = params.about.title;
			return {
				...state,
				buttonLoading: false,
				operatoryRoomMyVideos:[...operatoryRoomMyVideos],
				error: null,
			};
		}
		case UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}

		// OperatoryRoom TImeline My Videos remove
		case REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST: {
			return {
				...state,
				buttonLoading4: true,
				error: null,
			};
		}
		case REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS: {
			var operatoryRoomMyVideos = state.operatoryRoomMyVideos;
			var catIndex = operatoryRoomMyVideos.findIndex(x => x.categories_id == action.video.category_id);
			var videoIndex = operatoryRoomMyVideos[catIndex].video.findIndex(x => x.operatory_media_id == action.video.operatory_media_id);
			operatoryRoomMyVideos[catIndex].video.splice(videoIndex, 1);
			// .splice(videoIndex, 1)
			return {
				...state,
				buttonLoading4: false,
				operatoryRoomMyVideos:[...operatoryRoomMyVideos],
				error: null,
			};
		}
		case REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE: {
			return {
				...state,
				buttonLoading4: false,
				error: action.error,
			};
		}

		// GET_PRACTICE_PAYMENT_INFO_REQUEST
		case GET_PRACTICE_PAYMENT_INFO_REQUEST: {
			return {
				...state,
				buttonLoading4: true,
				loadingBillingCardInfo:true,
				error: null,
			};
		}
		case GET_PRACTICE_PAYMENT_INFO_SUCCESS: {
			return {
				...state,
				buttonLoading4: false,
				loadingBillingCardInfo:false,
				getPracticePaymentinfo: action.getPracticePaymentinfo,
				error: null,
			};
		}
		case GET_PRACTICE_PAYMENT_INFO_FAIL: {
			return {
				...state,
				loadingBillingCardInfo:false,
				buttonLoading4: false,
				error: action.error,
			};
		}

		// POST_PRACTICE_PAYMENT_INFO_REQUEST
		case POST_PRACTICE_PAYMENT_INFO_REQUEST: {
			return {
				...state,
				buttonLoading4: true,
				isSuccess:false,
				isPayNow:false,
				isUpdateSuccess:false,
				// error: null,
			};
		}
		case POST_PRACTICE_PAYMENT_INFO_SUCCESS: {
			var practiceInfo = state.practiceInfo;
			practiceInfo['is_invoice_declined'] = 0;
			return {
				...state,
				buttonLoading4: false,
				isPayNow:true,
				isSuccess:true,
				isUpdateSuccess:true,
				practiceInfo:{...practiceInfo},
				getPracticePaymentinfo: action.getPracticePaymentinfo,
				error: null,
			};
		}
		case POST_PRACTICE_PAYMENT_INFO_FAIL: {
			var practiceInfo = state.practiceInfo;
			practiceInfo['is_invoice_declined'] = 1;
			return {
				...state,
				buttonLoading4: false,
				isPayNow:false,
				isUpdateSuccess:false,
				practiceInfo:{...practiceInfo},
				isSuccess:false,
				error: action.error,
			};
		}

		// GET_MEDIA_CATEGORY_IMAGES_SUCCESS
		case GET_MEDIA_CATEGORY_IMAGES_REQUEST: {
			return {
				...state,
				error: null,
			};
		}
		case GET_MEDIA_CATEGORY_IMAGES_SUCCESS: {
			return {
				...state,
				getMediaCategoryImages: action.getMediaCategoryImages,
				error: null,
			};
		}
		case GET_MEDIA_CATEGORY_IMAGES_FAIL: {
			return {
				...state,
				error: action.error,
			};
		}

		// POST_TOS_COMPLAINT_REQUEST
		case POST_TOS_COMPLAINT_REQUEST: {
			let tosComplaint = state.tosComplaint;
			tosComplaint['is_sent'] = false;
			return {
				...state,
				error: null,
			};
		}
		case POST_TOS_COMPLAINT_SUCCESS: {
			let practiceInfo = state.practiceInfo;
			practiceInfo['can_tos_complain'] = 1;
			let tosComplaint = state.tosComplaint;
			tosComplaint['is_sent'] = true;
			return {
				...state,
				tosComplaint: {...tosComplaint},
				error: null,
			};
		}
		case POST_TOS_COMPLAINT_FAIL: {
			let tosComplaint = state.tosComplaint;
			tosComplaint['is_sent'] = true;
			return {
				...state,
				tosComplaint: {...tosComplaint},
				error: action.error,
			};
		}

		// GET_CHAIRSIDE_MEDIA__RECENT_PLAY_REQUEST
		case GET_CHAIRSIDE_MEDIA__RECENT_PLAY_REQUEST: {
			return {
				...state,
				chairSideMediarecentPlay:[],
				error: null,
			};
		}

		case GET_CHAIRSIDE_MEDIA__RECENT_PLAY_SUCCESS: {
			return {
				...state,
				chairSideMediarecentPlay: action.chairSideMediarecentPlay,
				error: null,
			};
		}

		case GET_CHAIRSIDE_MEDIA__RECENT_PLAY_FAILURE: {
			return {
				...state,
				chairSideMediarecentPlay: action.chairSideMediarecentPlay,
				error: action.error,
			};
		}

		
		// POST_PRACTICE_CONTACTUS_REQUEST
		case POST_PRACTICE_CONTACTUS_REQUEST: {
			return {
				...state,
				practicecontactus:false,
				buttonLoading: true,
				error: null,
			};
		}

		case POST_PRACTICE_CONTACTUS_SUCCESS: {
			return {
				...state,
				practicecontactus: action.practicecontactus,
				buttonLoading: false,
				error: null,
			};
		}

		case POST_PRACTICE_CONTACTUS_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				error: action.error,
			};
		}

		// RESET_DEFAULT_WEBSITE_VIDEO_REQUEST
		case RESET_DEFAULT_WEBSITE_VIDEO_REQUEST: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case RESET_DEFAULT_WEBSITE_VIDEO_SUCCESS: {
			return {
				...state,
				websiteMediaState: action.websiteMediaState,
				loading: false,
				error: null,
			};
		}

		case RESET_DEFAULT_WEBSITE_VIDEO_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}

		// EXTEND_TRIAL_REQUEST
		case EXTEND_TRIAL_REQUEST: {
			return {
				...state,
				extendbuttonLoading: true,
				error: null,
			};
		}

		case EXTEND_TRIAL_SUCCESS: {
			var practiceInfo = state.practiceInfo;
			practiceInfo.extend_trial_request_status = extendTrialStatus.pending;
			return {
				...state,
				extendbuttonLoading: false,
				practiceInfo,
				error: null,
			};
		}

		case EXTEND_TRIAL_FAILURE: {
			return {
				...state,
				extendbuttonLoading: false,
				error: action.error,
			};
		}

		case GET_DESIGNATE_CARD_REQUEST: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case GET_DESIGNATE_CARD_SUCCESS: {
			return {
				...state,
				loading: false,
				designateCard: action.designateCard,
				error: null,
			};
		}

		case GET_DESIGNATE_CARD_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}



		case GET_CORPORAT_HARDWARE_CALC_REQUEST: {
			return {
				...state,
				loading: true,
				corporathardwarecalc: {},
				error: null,
			};
		}

		case GET_CORPORAT_HARDWARE_CALC_SUCCESS: {
			return {
				...state,
				loading: false,
				corporathardwarecalc: action.corporathardwarecalc,
				error: null,
			};
		}

		case GET_CORPORAT_HARDWARE_CALC_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}

		case GET_CORPORAT_HARDWARE_LIST_REQUEST: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case GET_CORPORAT_HARDWARE_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				corporathardwarelist: action.corporathardwarelist,
				error: null,
			};
		}

		case GET_CORPORAT_HARDWARE_LIST_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}

		case GET_CORPORAT_PRACTICES_LIST_REQUEST: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case GET_CORPORAT_PRACTICES_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				corporatpracticeslist: action.corporatpracticeslist,
				error: null,
			};
		}

		case GET_CORPORAT_PRACTICES_LIST_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}

		case PURCHASE_CORPORAT_HARDWARE_REQUEST: {
			return {
				...state,
				loading: true,
				buttonLoading:true,
				error: null,
			};
		}

		case PURCHASE_CORPORAT_HARDWARE_SUCCESS: {
			return {
				...state,
				loading: false,
				buttonLoading:false,
				purchaseCorporatHardware: action.purchaseCorporatHardware,
				error: null,
			};
		}

		case PURCHASE_CORPORAT_HARDWARE_FAILURE: {
			return {
				...state,
				buttonLoading:false,
				loading: false,
				error: action.error,
			};
		}

		case SELECTED_CARD:{
			return {
				...state,
				selectedCard: action.selectedCard
			}
		}

		case UPDATE_SELECTED_CARD:{
			
		} 

		case POST_CORPORATE_MULTIPLE_CARD_REQUEST: {
			return {
				...state,
				buttonLoading: true,
			}
		}

		case POST_CORPORATE_MULTIPLE_CARD_SUCCESS:{
			return {
				...state,
				buttonLoading: false,
			}
		}

		case POST_CORPORATE_MULTIPLE_CARD_FAILURE:{
			return {
				...state,
				buttonLoading: false,
			}
		}

		case GET_DESIGNATE_CARD_MULTIPLE_REQUEST: {
			return {
				...state,
				loading: true,
				buttonLoading: false,
				error: null,
			};
		}

		case GET_DESIGNATE_CARD_MULTIPLE_SUCCESS: {
			return {
				...state,
				loading: false,
				designateCardMultiple: action.designateCardMultiple,
				error: null,
			};
		}

		case GET_DESIGNATE_CARD_MULTIPLE_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.error,
			};
		}

		case SAME_AS_MAIN_PRACTICE_REQUEST: {
			return {
				...state,
				// loading: true,
				buttonLoading: false,
				error: null,
			};
		}

		case SAME_AS_MAIN_PRACTICE_SUCCESS: {
			return {
				...state,
				// loading: false,
				mainPracticeAddress: action.mainPracticeAddress,
				error: null,
			};
		}

		case SAME_AS_MAIN_PRACTICE_FAIL: {
			return {
				...state,
				// loading: false,
				error: action.error,
			};
		}

		case SHOW_ERROR:{
			return {
				...state,
				error: action.error,
			}
		}

		case UPDATE_HARDWARE_TOTAL:{
				// corporathardwarecalc.order_detail[this.state.currentOrder].order_total = 0
			var corporathardwarecalc =  state.corporathardwarecalc
			corporathardwarecalc.order_detail[action.payload].order_total = 0
			return {
				...state,
				corporathardwarecalc
			}
		}

		case SET_GOOGLE_TV_TEST_DRIVE_ON_REQUEST:{
			return {
				...state,
				googleTvTestDrive: action.googleTvTestDrive,
			}
		}

		case SET_GOOGLE_TV_TEST_DRIVE_MENU_ITEM:{
			return {
				...state,
				googleTvTestDriveMenuItem: action.googleTvTestDriveMenuItem,
				googleTvTestDriveChairsideMenuItem: action.googleTvTestDriveChairsideMenuItem,
			}
		}

		case SET_GOOGLE_TV_TEST_DRIVE_CAT_IS_OPEN:{
			return {
				...state,
				catList: action.catList,
				catTitle: action.catTitle

			}
		}






		case GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_REQUEST: {
			return {
				...state,
				loading1: true,
				chairsidePlaylistMediaData: [],
				error: null,
			};
		}

		case GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_SUCCESS: {
			return {
				...state,
				loading1: false,
				chairsidePlaylistMediaData: [...action.chairsidePlaylistMediaData],
				error: null,
			};
		}

		case GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_FAILURE: {
			return {
				...state,
				loading1: false,
				chairsidePlaylistMediaData: [],
				error: action.error,
			};
		}

		case CREATE_CHAIRSIDE_PLAYLIST_REQUEST: {
			return {
				...state,
				loading: true,
				chairsidePlaylist: {},
				error: null,
			};
		}

		case CREATE_CHAIRSIDE_PLAYLIST_SUCCESS: {
			return {
				...state,
				loading: false,
				chairsidePlaylist: action.chairsidePlaylist,
				error: null,
			};
		}

		case CREATE_CHAIRSIDE_PLAYLIST_FAILURE: {
			return {
				...state,
				loading: true,
				chairsidePlaylist: {},
				error: action.error,
			};
		}

		case GET_CHAIRSIDE_PLAYLIST_REQUEST: {
			return {
				...state,
				loading: true,
				// chairsidePlaylistsData: [],
				error: null,
			};
		}

		case GET_CHAIRSIDE_PLAYLIST_SUCCESS: {
			return {
				...state,
				loading: false,
				chairsidePlaylistsData: action.chairsidePlaylistsData,
				error: null,
			};
		}

		case GET_CHAIRSIDE_PLAYLIST_FAILURE: {
			return {
				...state,
				loading: true,
				// chairsidePlaylistsData: {},
				error: action.error,
			};
		}

		case GET_SINGLE_CHAIRSIDE_PLAYLIST_REQUEST: {
			return {
				...state,
				buttonLoading: true,
				chairsidePlaylist: {},
				error: null,
			};
		}

		case GET_SINGLE_CHAIRSIDE_PLAYLIST_SUCCESS: {
			return {
				...state,
				buttonLoading: false,
				chairsidePlaylist: action.chairsidePlaylist,
				error: null,
			};
		}

		case GET_SINGLE_CHAIRSIDE_PLAYLIST_FAILURE: {
			return {
				...state,
				buttonLoading: false,
				chairsidePlaylist: {},
				error: action.error,
			};
		}

		case UPDATE_CHAIRSIDE_PLAYLIST_REQUEST: {
			return {
				...state,
				buttonLoading1: true,
				buttonLoading4: true,
				error: null,
			};
		}

		case UPDATE_CHAIRSIDE_PLAYLIST_SUCCESS: {
			var chairsidePlaylistsData = state.chairsidePlaylistsData;
			var chairsidePlaylist = action.chairsidePlaylist
			var index = chairsidePlaylistsData.findIndex(x => x.id == chairsidePlaylist.id);
			chairsidePlaylistsData[index] = {...chairsidePlaylistsData[index] , ...chairsidePlaylist};
			return {
				...state,
				buttonLoading1: false,
				buttonLoading4: false,
				chairsidePlaylist: { ...action.chairsidePlaylist },
				chairsidePlaylistsData:[...chairsidePlaylistsData],
				error: null,
			};
		}

		case UPDATE_CHAIRSIDE_PLAYLIST_FAILURE: {
			return {
				...state,
				buttonLoading1: false,
				buttonLoading4: false,
				error: action.error,
			};
		}

		case GET_CHAIRSIDE_PLAY_VIDEO_REQUEST: {
			return {
				...state,
				loading: true,
				buttonLoading3:true,
				chairsidePlayVideo: {},
				error: null,
			};
		}

		case GET_CHAIRSIDE_PLAY_VIDEO_SUCCESS: {
			return {
				...state,
				loading: false,
				buttonLoading3:false,
				chairsidePlayVideo: action.chairsidePlayVideo,
				error: null,
			};
		}

		case GET_CHAIRSIDE_PLAY_VIDEO_FAILURE: {
			return {
				...state,
				loading: false,
				buttonLoading3:false,
				chairsidePlayVideo: {},
				error: action.error,
			};
		}

		case PUBLISH_CHAIRSIDE_PLAYLIST_REQUEST: {
			return {
				...state,
				loading: true,
				buttonLoading3:true,
				error: null,
			};
		}

		case PUBLISH_CHAIRSIDE_PLAYLIST_SUCCESS: {
			return {
				...state,
				loading: false,
				savePlaylistmessage:'Playlist Saved',
				bgColor: 'green',
				publistChairsidePlaylistStatus:action.publistChairsidePlaylistStatus,
				buttonLoading3:false,
				error: null,
			};
		}

		case PUBLISH_CHAIRSIDE_PLAYLIST_FAILURE: {
			return {
				...state,
				loading: false,
				savePlaylistmessage:'Save Playlist',
				bgColor: 'primary',
				buttonLoading3:false,
				error: action.error,
			};
		}

		case PUBLISH_CHAIRSIDE_PLAYLIST_RESET_MESSAGE: {
			return {
				...state,
				loading: false,
				savePlaylistmessage:'Save Playlist',
				bgColor: 'primary',
				buttonLoading3:false,
				error: action.error,
			};
		}

		case REMOVE_CHAIRSIDE_PLAYLIST_REQUEST: {
			return {
				...state,
				buttonLoading3:true,
				error: null,
			};
		}

		case REMOVE_CHAIRSIDE_PLAYLIST_SUCCESS: {
			var chairsidePlaylistsData = state.chairsidePlaylistsData;
			var id = action.id
			var index = chairsidePlaylistsData.findIndex(x => x.id == id);
			chairsidePlaylistsData.splice(index, 1);
			return {
				...state,
				buttonLoading3: false,
				chairsidePlaylistsData: [...chairsidePlaylistsData],
				error: null,
			};
		}

		case REMOVE_CHAIRSIDE_PLAYLIST_FAILURE: {
			return {
				...state,
				buttonLoading3:false,
				error: action.error,
			};
		}

		case DUPLICATE_CHAIRSIDE_PLAYLIST_REQUEST: {
			return {
				...state,
				buttonLoading4:true,
				error: null,
			};
		}

		case DUPLICATE_CHAIRSIDE_PLAYLIST_SUCCESS: {
			return {
				...state,
				buttonLoading4:false,
				error: null,
			};
		}

		case DUPLICATE_CHAIRSIDE_PLAYLIST_FAILURE: {
			return {
				...state,
				buttonLoading4:false,
				error: action.error,
			};
		}

		case SET_CHAIRSIDE_PLAYLIST_MENU_ACTIVE: {
			return {
				...state,
				isChairsideMenuActiveIndex: action.isChairsideMenuActiveIndex
			};
		}

		case SET_CHAIRSIDE_PLAYLIST_VIDEO_INDEX: {
			return {
				...state,
				chairsideVideoActiveIndex: action.chairsideVideoActiveIndex
			};
		}

		//Default Profile selection
		case DEFAULT_PROFILE_REQUEST: {
			return {
				...state,
				loadingDefault: action.loadingDefault,
				successDefault: action.successDefault,
				error: null,
			};
		}
		case DEFAULT_PROFILE_SUCCESS: {
			var practiceInfo = state.practiceInfo;
			practiceInfo['autologin_user_id'] = action.userId;
			return {
				...state,
				loadingDefault: action.loadingDefault,
				successDefault: action.successDefault,
				practiceInfo,
				error: null,
			};
		}
		case DEFAULT_PROFILE_FAILURE: {
			console.log(action.error,'action.error')
			return {
				...state,
				loadingDefault: action.loadingDefault,
				successDefault: action.successDefault,
				error: action.error.data,
			};
		}

		default:
			return state;
	}
}

export default fn;