/** @format */

import React from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

export class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		this.props.onChange(e);
	}

	render() {
		const {
			name,
			label,
			type,
			placeholder,
			value,
			isError,
			tabIndex,
			onChange,
			defaultValue,
			className,
			disabled,
		} = this.props;
		return (
			<React.Fragment>
				{label &&
				<div className={`d-flex justify-content-between `}>
					<Label htmlFor={name}>{label}</Label>
					{isError && <span className='text--danger'>{isError}</span>}
				</div>
				}
				<Input
					id={name}
					tabIndex={tabIndex}
					name={name}
					type={type}
					placeholder={placeholder}
					onChange={onChange}
					disabled={disabled}
					defaultValue={defaultValue}
					value={value}
					className={className}
				/>
			</React.Fragment>
		);
	}
}

InputField.defaultProps = {
	type: 'text',
	className: '',
	placeholder: '',
};
InputField.propTypes = {
	name: PropTypes.string.isRequired,
	// type: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
	type: PropTypes.oneOf([
		'text',
		'number',
		'password',
		'email',
		'textarea',
		'time',
	]),
	className: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
};
