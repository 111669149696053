import React from "react";
import { Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import SubHeader from "../../components/Header/subHeader";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  GetpracticeUser,
  UpdatePracticeInfoRequest,
} from "../../store/actions/taskAction";

import "./style.scss";
class profileCreated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getUserType(id) {
    switch (id) {
      case 0:
        return ["guest", "Guest"];
      case 1:
        return ["dentist", "Normal User"];
      case 2:
        return ["authorized_user", "Authorized User"];
      case 3:
        return ["manager", "Manager"];
      case 4:
        return ["owner", "Owner’s"];
      default:
        return ["guest", "Guest"];
    }
  }

  onContinue() {
    this.props.history.push({
      pathname: "/profileselection",
    });
  }

  async componentDidMount() {
    await this.props.dispatch(GetpracticeUser());
    if (this.props.practiceUserAccountInfo.type === 4) {
      let practiceInfo = this.props.practiceInfo;
      practiceInfo["need_owner_setup"] = 0;
      this.props.dispatch(UpdatePracticeInfoRequest(practiceInfo));
    }
  }

  render() {
    const { t } = this.props;
    var userType = this.getUserType(this.props.practiceUserAccountInfo.type);

    return (
      <div className="owners_profile_creation__main__screen align-items-center justify-content-center d-flex flex-column h-100 ">
        {/* <SubHeader className="mb-5" title="Owner's Profile" /> */}
        {this.props.practiceUserAccountInfo && (
          <div className="owners_profile_creation__inner__screen transparent__form--white"  style={{padding:'82px 85px'}}>
            <div className="owners_profile_creation__inner__screen">
              <div className="d-flex flex-column text-center owners_profile_creation__inner__text text-center">
                <h1 className="mb-4 mt-0 text-primary">
                  Congratulations! {userType[1]} Profile Created.
                </h1>
                <p style={{maxWidth:'660px'}} className="align-self-center fs-20">
                  Now you can log in to Thalamus with the profile you’ve just
                  created. Your passcode will be required every time you sign in
                  to your profile.
                </p>
                <div className="mt-4">
                  <Button
                    size="md"
                    className="mx-auto mt-5"
                    onClick={() => this.onContinue()}
                    color="primary"
                  >
                    {t("btn.done")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  practiceUserAccountInfo: state.task.practiceUserAccountInfo,
  practiceInfo: state.task.practiceInfo,
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps)
)(profileCreated);
