import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useSelector, useDispatch} from "react-redux";
import { getHelperNewsfeed, postNewsfeed } from '../../store/actions/taskAction';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import LoadingButton from '../LoadingButton';
import Loading from '../Loading';

const WHITEBOXIMAGE = "assets/img/colorsImg/whitebox.png";
const CLOSEBOXIMAGE = "assets/img/colorsImg/closeicon.png";
const PARTIALLYCHECK = "assets/img/colorsImg/partially.png";
  
const WaitingOperatoryRoomNewsFeed = ({ isOperatiryRoom }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
  
    const [checkedChild, setCheckedChild] = useState(false);
    const [ filteredNewsfeed, setFilteredNewsfeed ] = useState([])
    const [ count, setCount ] = useState(0);
    const [ activeIdChild, setActiveIdChild ] = useState(null);

    const [helperNewsfeeds, setHelperNewsfeeds] = useState([]);
    const [mainNewsFeeds, setMainNewsFeeds] = useState([]);

    useEffect(() => {
      var channelId = isOperatiryRoom ? state.managewaitingRoomMediaChannel.id : state.managewaitingRoomMediaChannel.id;
      dispatch(getHelperNewsfeed(channelId, isOperatiryRoom))
    }, [])

    useEffect(()=>{
      var parent = state.helperNewsfeeds.filter((datum) => datum.parent == 0);
        setHelperNewsfeeds(parent);
        console.log(state.helperNewsfeeds,'state.helperNewsfeeds')


        state.helperNewsfeeds.map((d) => {
         var parentid = d.id
         var childlist = state.helperNewsfeeds.filter(x => x.parent == parentid);
         var noofslected = childlist.filter(x => x.is_check == 1);
         if(noofslected.length > 0 && childlist.length != noofslected.length){
            d.is_check = 2;
         }
        });

        setMainNewsFeeds(state.helperNewsfeeds);
      }, [state.helperNewsfeeds]);

    // on select news feed (title click)
    const handleChange = (event) => {
      var id = event.target.id;
      var oldSelectedId = activeIdChild
      setActiveIdChild(id)
      const arr = []
      mainNewsFeeds.filter((datum) => datum.parent == id).map((d, i)=>{
        arr.push({
          ...d,
          // is_check: false,
        })
      });
      setFilteredNewsfeed(arr)
      setCheckedChild((prev)=> oldSelectedId == id ? !prev : true);
    };
    
    // image click > select/unselect
    const imageClickHandler = (id, isChecked) => {
      const arr = [];
      setActiveIdChild(id)
      // mainNewsFeeds.filter((datum)=> datum.parent === id );
      mainNewsFeeds.map((d, i) => {
        if(d.parent === id){
          arr.push({
            ...d,
            is_check: isChecked,
          })
          d['is_check'] = isChecked;
        }else if(d.id == id){
          d['is_check'] = isChecked;
        }
      })
      
      setFilteredNewsfeed([...arr]);
      setCount(count+1);
    }

    const childChangeHandler = (e, parentId) => {
      const id = e.target.id;
      filteredNewsfeed.map((element)=>{
        if(element.id == id) {
          element.is_check = !element.is_check;  
        }
      })
      mainNewsFeeds.map((d) => {
        if(d.id == id){
          d.is_check = !d.is_check;  
        }
      });

      // select alll....!
      var main = mainNewsFeeds.filter(x => x.parent == parentId);
      var selected = main.filter( x=> x.is_check == true);
      var parentIndex = mainNewsFeeds.findIndex(x => x.id == parentId);


      if(main.length == selected.length){
        mainNewsFeeds[parentIndex].is_check = true;
      }else if(selected.length > 0){
        console.log('partially');
        mainNewsFeeds[parentIndex].is_check = 2;
      }
      else{
        mainNewsFeeds[parentIndex].is_check = false;
      }
      setFilteredNewsfeed([...filteredNewsfeed]);
      setCount(count+1);
    }
    
    const saveNewsFeed = () => {
      var selected_newsfeed = [];
      mainNewsFeeds.map(x => {
        if(x.is_check === true || x.is_check === 1 ){
          selected_newsfeed.push({ newsfeed_id: x.id });
        }
      })
      
      var param = {
        selected_newsfeed : selected_newsfeed 
      }
      // if(selected_newsfeed.length == 0) return;

      var channelId = isOperatiryRoom ? state.managewaitingRoomMediaChannel.id : state.managewaitingRoomMediaChannel.id;
      dispatch(postNewsfeed(channelId, param, isOperatiryRoom));
    }

    return (
        <div className="news_feed__main waiting_room_common_section_newsfeed">
          {state.loading && <Loading />}
            <p style={{ color:"white", fontSize:"20px", marginBottom:"15px" }}>Newsfeed</p>
            <p style={{ color:"white", fontSize:"15px" }}>Select the sources for your news.</p>
            <div className="news_feed_dropdown">
            {
              helperNewsfeeds.map((newsfeed, mainIndex) => {
                if(newsfeed.parent == 0 && newsfeed.status == 1){
                  console.log(newsfeed,'newsfeed')
                  return (
                    <div key={newsfeed.id} className={checkedChild == true && (activeIdChild == newsfeed.id) ? 'ac' : ''}>
                      <img src={newsfeed.is_check === 2 ? PARTIALLYCHECK : newsfeed.is_check ? CLOSEBOXIMAGE : WHITEBOXIMAGE } alt={WHITEBOXIMAGE} style={{ width:"4%" }} id={newsfeed.id} onClick={() => imageClickHandler(newsfeed.id, !newsfeed.is_check) }/>
                      <h6 style={{ color:"white", marginTop:"-14px", marginLeft:"20px" }} key={ mainIndex } id={newsfeed.id} onClick={ handleChange }>{newsfeed.title.replace("Marktes", "Markets")} | Select All <KeyboardArrowDownIcon id={newsfeed.id}/></h6>
                      {
                        activeIdChild == newsfeed.id ? 
                          <Collapse in={checkedChild}>
                          {
                            filteredNewsfeed.map((filtered, index) => {
                              const active = filtered.is_check ?  CLOSEBOXIMAGE : WHITEBOXIMAGE 
                              return (
                                <div key={filtered.id}>
                                  <img src={`${active}`} alt={active} style={{ width:"3%" }} id={filtered.id} onClick={(e) => childChangeHandler(e, filtered.parent) } />
                                  <h6 style={{ color:"white", marginTop:"-14px", marginLeft:"20px" }} key={index} id={filtered.id} onClick={(e)=> childChangeHandler(e, filtered.parent) }>{filtered.title}</h6>
                                </div>
                              )
                            })
                          }
                          </Collapse> 
                        : ''
                      }  
                    </div>
                  )
                }
              })
            } 
            </div>
              

          <br/><br/>
          {/* <Button className="btn btn-white" style={{ width:"350px", marginLeft:'-5px' }} >Save Newsfeed Sources</Button> */}
          <LoadingButton type="button" label="Save Newsfeed Sources" loading={state.buttonLoading} disabled={state.buttonLoading} size="xxl" onClick={ saveNewsFeed } color="primary"/>
        </div>
    )
}
export default WaitingOperatoryRoomNewsFeed;