/** @format */

import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import WaitingRoomTimelineMyVideosCategories from './OperatoryRoomCategory';
import WaitingRoomTimelineMyVideosSelectedVideo from './OperatoryRoomTimelineMyVideosSelectedVideo';
import WaitingRoomTimelineVideoUpload from '../../WaitingRoomTimelineMyVideos/WaitingRoomTimelineVideoUpload';
import '../style.scss'

const WaitingRoomTimelineMyVideos = ({ myVideos, timeLineList, onPublish }) => {
	const [selectedVideos, setSelectedVideos] = useState([]);
	const [uploadVideo, setUploadVideo] = useState(false);
	const [categories, setCategories] = useState([]);
	const [isVideos, setIsVideos] = useState(false);
	const state = useSelector((state) => state.task);
	
	useEffect(()=>{
		console.log(selectedVideos,'selectedVideos')
		const videos = [];
		selectedVideos.map((video) => {
			var isAdded = timeLineList.filter(x => x.media_id == video.operatory_media_id);
			videos.push({
				...video,
				isAdded,
			});
		});
		setSelectedVideos(videos)

		if(videos.length == 0)
		setIsVideos(false);
		else
		setIsVideos(true);


			
    }, [timeLineList])

	useEffect(() => {
		const categoryArray = [];
		const videos = [];
		myVideos.forEach((element) => {
			categoryArray.push({
				category_id: element.categories_id,
				category_name: element.categories_name,
				selected: false,
				videosLength: element.video.length,
			});
			element.video.forEach((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.operatory_media_id);
				videos.push({
					...video,
					category_name: element.categories_name,
					category_id: element.categories_id,
					selected: false,
					isAdded
				});
			});
		});

		if(videos.length == 0)
			setIsVideos(false);
		else
			setIsVideos(true);


		setSelectedVideos(videos);
		// setCategories(categories)
	}, []);

	useEffect(() => {
		const categoryArray = [];
		const videos = [];
		myVideos.forEach((element) => {
			categoryArray.push({
				category_id: element.categories_id,
				category_name: element.categories_name,
				selected: false,
				videosLength: element.video.length,
			});
			element.video.forEach((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.operatory_media_id);
				videos.push({
					...video,
					category_name: element.categories_name,
					category_id: element.categories_id,
					selected: false,
					isAdded
				});
			});
		});

		if(videos.length == 0)
			setIsVideos(false);
		else
			setIsVideos(true);


		setSelectedVideos(videos);
		// setCategories(categories)
	}, [state.operatoryRoomMyVideos]);
	
	const setVideos = (videos) => {
		setSelectedVideos(videos);
	};

	const showuploadVideo = () => {
		setUploadVideo(true)
	}

	const vieMyViedeo = () => {
		setUploadVideo(false)
		setIsVideos(true);

	}

	return (
		<>
			<div className="waitiing-room-col-sec">
				
				{ !uploadVideo ?
					<>
						<div className="waiting-room-left-sec">
							<WaitingRoomTimelineMyVideosCategories
								videos={myVideos}
								setVideos={setVideos}
								onUploadVideoClick={showuploadVideo}
								timeLineList={timeLineList}
								onPublish={onPublish}
							/>
						</div>
						<div className="waiting-rom-right-sec">
							<div className="videoSelected__main__centerCol">
								{!isVideos ?
									<div className="d-flex waiting_room_my_video_blank flex-column h-100 align-items-center justify-content-center">
										<h1>You haven't uploaded any videos yet.</h1>
										<p>Select "Upload my videos" on the left to uploada video, or <Button type="button" className="p-0" color="link" onClick={showuploadVideo}>click here.</Button></p>
									</div>		
								:	
									<WaitingRoomTimelineMyVideosSelectedVideo
										videos={selectedVideos}
										setVideos={setVideos}
										timeLineList={timeLineList}
									/>
								}
							</div>
						</div>
					
					</>
				: 
					<WaitingRoomTimelineVideoUpload type = "operatory" categories={categories} onVideoClick={vieMyViedeo} />
				}
			</div>
		</>
	);
};

export default WaitingRoomTimelineMyVideos;
