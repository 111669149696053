import React from "react";
import SubHeader from "../../components/Header/subHeader";
import { InputField } from "../../components/InputField";
import { DateDDMMMYYY } from "../../utilities/HttpUtility";
import { setToken } from "../../helper/LocalStorage";
import AddManager from "./managers";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  GetOwnerLocations,
  GetOwnerLocationsManager,
  SendManagerInvite,
  UnassignManager,
  LoginPracticeLocation,
  GetSubscribePriceOption,
} from "../../store/actions/taskAction";
import { Button, Spinner, FormGroup } from "reactstrap";
import IconLocationWhite from "../../img/ic_pin_drop.svg";

import moment from "moment";
import { history } from "../../pages/index";

import "./style.scss";

class LocationListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      per_page: 5,
      totelPage: 0,
      currentPageManager: 1,
      per_pageManger: 5,
      totelPageManager: 0,
      expanded: false,
      showMoreArrow: false,
      selectedShowby: 1,
      step: 1,
      filter: "",
      locSearch: "",
      locSearchByCity: "",
      locSearchByManager: "",
      searchByMname: "",
      searchByMcity: "",
      unAssignedLocations: [],
      unAssignConfirmId: "",
      isViewAll: false,
      selectedManager: null,
      confirmId: "",
      loading: true,
      isCompleted: false,
    };
    this.handlechangePage = this.handlechangePage.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getStatus(id) {
    switch (id) {
      case 0:
        return ["color-yellow", "Pending"];
      case 1:
        return ["color-green", "Subscribed"];
      case 2:
        return ["color-red", "Expired offer"];
      default:
        return ["color-yellow", "Pending"];
    }
  }

  handlechangePage(e, index) {
    index = index + 1;

    this.setState({ currentPage: index }, async () => {
      let params = {
        page: this.state.currentPage,
        per_page: this.state.per_page,
      };
      if (this.state.filter === "unassigned") {
        params["view_list"] = "unassigned";
      }
      await this.props.dispatch(GetOwnerLocations(params));
    });
  }

  handleManagerPageChange(page) {
    this.setState({ currentPageManager: page }, () => {
      var paramsManager = {
        page: this.state.currentPageManager,
        per_page: this.state.per_pageManger,
      };
      this.props.dispatch(GetOwnerLocationsManager(paramsManager));
    });
  }

  async unAssignLocation(lid, mid) {
    let body = {
      practices_id: lid,
      practices_user_id: mid,
    };
    await this.props.dispatch(UnassignManager(body));

    if (this.props.unassignManager.status === 200) {
      await this.props.dispatch(GetOwnerLocationsManager());
      let params = {
        view_list: "all",
      };
      await this.props.dispatch(GetOwnerLocations(params));
      this.viewAll(false);
    }
  }

  handleChange(field, options) {
    var name = "";
    var value = "";

    if (field === "showby") {
      name = field;
      value = options.value;
    } else {
      name = field.target.name;
      value = field.target.value;
    }

    this.setState({
      selectedShowby: {
        [name]: value,
      },
    });
    let params = {
      latest: value,
    };
    // this.props.dispatch(GetNotification(params))
  }

  async componentDidMount() {
    console.log(this.props, "this.props.location");
    await this.props.dispatch(GetSubscribePriceOption());
    if (this.props.isActiveAddManager && this.props.isActiveAddManager) {
      this.setState({ step: 3 });
    }
    // if (this.props.practiceUserLoginInfo.role !== 0) {
    //   history.push("/settings")
    // }
    // let paramsUnassign = {
    //   // view_list: "unassigned",
    //   // per_page: this.state.per_page,
    //   // page: this.state.currentPage
    //   without_manager: 1,
    //   need_all: 1,
    // }
    // await this.props.dispatch(GetOwnerLocations(paramsUnassign, true))
    // this.setState({ unAssignedLocations: this.props.getLocationsList.data })

    let params = {
      view_list: "all",
      per_page: this.state.per_page,
      page: this.state.currentPage,
    };
    await this.props.dispatch(GetOwnerLocations(params));
    this.setState({
      totelPage: this.props.getLocationsList.last_page,
      loading: false,
    }); // unAssignedLocations: this.props.getLocationsList.data
    var paramsManager = {
      page: this.state.currentPageManager,
      per_page: this.state.per_pageManger,
    };
    await this.props.dispatch(GetOwnerLocationsManager(paramsManager));
    this.setState({
      totelPageManager: this.props.getLocationsManagerList.last_page,
      loading: false,
    });
  }

  expandedText(event, id) {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  getMoreTextDiv(text) {
    if (this.state.expanded) {
      return text;
    } else {
      return text.substring(0, 98);
    }
  }

  filterByInput(e) {
    // this.setState({ locSearch: e.target.value })
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 }, () => {
      this.updateFilter(this.state.filter);
    });
  }

  viewAll(isAll, manager) {
    if (isAll) {
      this.setState({ isViewAll: true, selectedManager: manager });
    } else {
      this.setState({ isViewAll: false, selectedManager: null });
    }
  }

  async updateFilter(ft) {
    this.setState({ step: 1, currentPage: 1, filter: ft }, async () => {
      let params = {
        page: this.state.currentPage,
        per_page: this.state.per_pageManger,
      };
      if (this.state.filter === "unassigned" && ft !== "unassigned") {
        params["view_list"] = "all";
      }
      if (this.state.filter !== "unassigned" && ft === "unassigned") {
        params["view_list"] = "unassigned";
      }
      if (ft === "unassigned") {
        params["view_list"] = "unassigned";
      }
      const { locSearch, locSearchByCity, locSearchByManager } = this.state;

      if (locSearch.trim() != "") {
        params["name"] = locSearch;
      }

      if (locSearchByCity.trim() != "") {
        params["city"] = locSearchByCity;
      }
      if (locSearchByManager.trim() != "") {
        params["manager"] = locSearchByManager;
      }

      await this.props.dispatch(GetOwnerLocations(params));
      this.setState({ filter: ft }); //locSearch: "", locSearchByCity: "", locSearchByManager: ""
    });
  }

  managerFilter(e) {
    this.setState(
      { [e.target.name]: e.target.value, currentPageManager: 1 },
      () => {
        this.getMangers();
      }
    );
  }

  getMangers() {
    var params = {
      page: this.state.currentPageManager,
      per_page: this.state.per_pageManger,
    };

    if (this.state.searchByMcity.toString().trim() != "") {
      params["city"] = this.state.searchByMcity;
    }
    if (this.state.searchByMname.toString().trim() != "") {
      params["name"] = this.state.searchByMname;
    }

    this.props.dispatch(GetOwnerLocationsManager(params));
  }

  async managerInvite(data) {
    await this.props.dispatch(SendManagerInvite(data));
    if (
      !!this.props.managerInviteSend &&
      this.props.managerInviteSend.status === 200
    ) {
      this.setState({ isCompleted: true, currentPageManager: 1 });
      var params = {
        page: 1,
        per_page: this.state.per_pageManger,
      };
      await this.props.dispatch(GetOwnerLocationsManager(params));
    }
  }

  async backtomanager() {
    this.setState({ isCompleted: false, step: 2 });
  }

  async onManage(id) {
    let params = {
      practice_id: id,
    };
    // if(this.props.isCorporate){
    //   params['is_corporate'] = 1;
    // }
    await this.props.dispatch(LoginPracticeLocation(params));
    // var token = this.props.practiceUserLoginInfo.token_info.token_type + ' ' + this.props.practiceUserLoginInfo.token_info.access_token
    // setToken(`${token}`);

    // history.push('/channels');
  }

  render() {
    const {
      t,
      loading,
      getLocationsList,
      getLocationsManagerList,
      error,
      collapse,
      isCorporate,
    } = this.props;
    let step = this.state.step;
    let locationList = !!getLocationsList ? getLocationsList.data : [];
    let managerList =
      getLocationsManagerList && getLocationsManagerList.data
        ? getLocationsManagerList.data
        : [];
    console.log(getLocationsList, "unAssignedLocations");
    if (this.state.filter !== "" && this.state.filter !== "unassigned") {
      locationList = locationList.filter((loc, i) => {
        return loc.name.split("")[0].toLowerCase() === this.state.filter;
      });
    }
    // if (this.state.locSearch !== "") {
    //   locationList = locationList.filter((loc, i) => { return loc.name.toLowerCase().includes(this.state.locSearch.toLowerCase()) })
    // }
    // if (this.state.locSearchByCity !== "") {
    //   locationList = locationList.filter((loc, i) => { return loc.city.toLowerCase().includes(this.state.locSearchByCity.toLowerCase()) });
    // }
    // if (this.state.locSearchByManager !== "") {
    //   locationList = locationList.filter((loc, i) => loc.users && loc.users[0] && loc.users[0].full_name.toLowerCase().includes(this.state.locSearchByManager.toLowerCase()) );
    // }

    // if (this.state.searchByMname !== "") {
    //   managerList = managerList.filter((data, i) => data.first_name.toLowerCase().includes(this.state.searchByMname.toLowerCase()) || data.last_name.includes(this.state.searchByMname.toLowerCase()));
    // }
    // if (this.state.searchByMcity !== "") {
    //   console.log(managerList,'managerList')
    //   managerList = managerList.filter((data, i) => data.practice[0].city.toLowerCase().includes(this.state.searchByMcity.toLowerCase()));
    // }

    return (
      <div className="main__screen notification__page add_manage_location  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader
          className="mb-4"
          title={`Add & Manage Locations ${
            isCorporate == 1 ? " | Corporation" : ""
          }`}
        />
        <div className="tabs">
          <ul className="d-flex justify-content-between">
            <li
              onClick={() => {
                this.setState({ step: 1 });
              }}
              className={`col text-center ${step === 1 ? "activetab" : ""}`}
            >
              <span></span>
              <label className="">Locations</label>
            </li>
            <li
              onClick={() => {
                this.setState({ step: 2 });
              }}
              className={`col text-center ${
                step === 2 || step === 3 ? "activetab" : ""
              }`}
            >
              <span></span>
              <label className="">Managers</label>
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 mb-auto align-items-center justify-content-between">
          
            {step === 1 && (
              <div className="d-flex flex-column  mb-auto mx-auto add_manager_section">
              <div className="notification__inner mb-auto d-flex flex-column">
                <div className="notification__inner__header add_manage_header">
                  <div className="row">
                    <div className="col-7">
                      <h4>Select a location to view more details</h4>
                    </div>
                    <div className="col-5  justify-content-end d-flex">
                      {/* <Button color="link"> {`<`} </Button> <Button color="link"> {`>`} </Button>  */}
                      {isCorporate == 1 && (
                        <>
                          <h4
                            onClick={() => {
                              this.updateFilter("");
                            }}
                            style={{
                              marginTop: "5px",
                              marginRight: "30px",
                              color:
                                this.state.filter === "" ? "#fff" : "#949090",
                            }}
                          >
                            ALL
                          </h4>
                          <h4
                            onClick={() => {
                              this.updateFilter("unassigned");
                            }}
                            style={{
                              marginTop: "5px",
                              marginRight: "94px",
                              color:
                                this.state.filter === "unassigned"
                                  ? "#fff"
                                  : "#949090",
                              fontSize: "25px",
                            }}
                          >
                            UNASSIGNED
                          </h4>
                        </>
                      )}
                      <div className="location_icon">
                        <img
                          style={{ width: "45px" }}
                          src={IconLocationWhite}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <FormGroup className="mr-20">
                      <InputField
                        value={this.state.locSearch}
                        type="text"
                        label={"Search practice by name"}
                        name="locSearch"
                        onChange={(e) => {
                          this.filterByInput(e);
                        }}
                      />
                    </FormGroup>
                    {isCorporate == 1 && (
                      <>
                        <FormGroup className="mr-20">
                          <InputField
                            value={this.state.locSearchByCity}
                            type="text"
                            label={"Search practice by city"}
                            name="locSearchByCity"
                            onChange={(e) => {
                              this.filterByInput(e);
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="mr-20">
                          <InputField
                            value={this.state.locSearchByManager}
                            type="text"
                            label={"Search practice by assigned manager"}
                            name="locSearchByManager"
                            onChange={(e) => {
                              this.filterByInput(e);
                            }}
                          />
                        </FormGroup>
                      </>
                    )}
                    {isCorporate == 0 && (
                      <>
                        <h4
                          onClick={() => {
                            this.updateFilter("");
                          }}
                          style={{
                            marginTop: "38px",
                            marginRight: "30px",
                            color:
                              this.state.filter === "" ? "#fff" : "#949090",
                          }}
                        >
                          ALL
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("unassigned");
                          }}
                          style={{
                            marginTop: "38px",
                            marginRight: "20px",
                            color:
                              this.state.filter === "unassigned"
                                ? "#fff"
                                : "#949090",
                            fontSize: "25px",
                          }}
                        >
                          UNASSIGNED
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("a");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "a" ? "#fff" : "#949090",
                          }}
                        >
                          A
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("b");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "b" ? "#fff" : "#949090",
                          }}
                        >
                          B
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("c");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "c" ? "#fff" : "#949090",
                          }}
                        >
                          C
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("d");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "d" ? "#fff" : "#949090",
                          }}
                        >
                          D
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("e");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "e" ? "#fff" : "#949090",
                          }}
                        >
                          E
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("f");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "f" ? "#fff" : "#949090",
                          }}
                        >
                          F
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("g");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "g" ? "#fff" : "#949090",
                          }}
                        >
                          G
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("h");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "h" ? "#fff" : "#949090",
                          }}
                        >
                          H
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("i");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "i" ? "#fff" : "#949090",
                          }}
                        >
                          I
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("j");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "j" ? "#fff" : "#949090",
                          }}
                        >
                          J
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("k");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "k" ? "#fff" : "#949090",
                          }}
                        >
                          K
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("l");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "l" ? "#fff" : "#949090",
                          }}
                        >
                          L
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("m");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "m" ? "#fff" : "#949090",
                          }}
                        >
                          M
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("n");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "n" ? "#fff" : "#949090",
                          }}
                        >
                          N
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("o");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "o" ? "#fff" : "#949090",
                          }}
                        >
                          O
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("p");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "p" ? "#fff" : "#949090",
                          }}
                        >
                          P
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("q");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "q" ? "#fff" : "#949090",
                          }}
                        >
                          Q
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("r");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "r" ? "#fff" : "#949090",
                          }}
                        >
                          R
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("s");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "s" ? "#fff" : "#949090",
                          }}
                        >
                          S
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("t");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "t" ? "#fff" : "#949090",
                          }}
                        >
                          T
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("u");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "u" ? "#fff" : "#949090",
                          }}
                        >
                          U
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("v");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "v" ? "#fff" : "#949090",
                          }}
                        >
                          V
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("w");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "w" ? "#fff" : "#949090",
                          }}
                        >
                          W
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("x");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "x" ? "#fff" : "#949090",
                          }}
                        >
                          X
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("y");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "y" ? "#fff" : "#949090",
                          }}
                        >
                          Y
                        </h4>
                        <h4
                          onClick={() => {
                            this.updateFilter("z");
                          }}
                          style={{
                            marginTop: "39px",
                            marginRight: "10px",
                            color:
                              this.state.filter === "z" ? "#fff" : "#949090",
                          }}
                        >
                          Z
                        </h4>
                      </>
                    )}
                  </div>
                </div>
                {this.state.loading ? (
                  <div className="notification__inner__body flex-grow-1 flex-column d-flex  align-items-center justify-content-center">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <div className="notification__inner__body add_manage_table flex-grow-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="183">PRACTICE NAME</th>
                          <th width="372">LOCATION</th>
                          <th width="130">MANAGER</th>
                          <th width="130">ADDED ON</th>
                          <th width="130"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {locationList &&
                          locationList.map((list, index) => {
                            return (
                              <tr key={index}>
                                <td width="183">{list.name}</td>
                                <td width="363">
                                  {
                                    `${
                                      list.address_line_1
                                        ? list.address_line_1
                                        : ""
                                    }` +
                                      `${
                                        list.address_line_2
                                          ? ", " + list.address_line_2
                                          : ""
                                      }` +
                                      `${list.city ? " - " + list.city : ""}` +
                                      `${
                                        list.state ? ", " + list.state.code : ""
                                      }` +
                                      `${list.zip ? " | " + list.zip : ""}`
                                    // ${list.country ? list.country.name : ""}
                                  }
                                </td>
                                <td width="130">
                                  {list.users.length > 0 ? (
                                    list.users[0].is_invitee == 1 ? (
                                      <span className="text--primary">
                                        Pending
                                      </span>
                                    ) : (
                                      list.users[0].full_name
                                    )
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      Unassigned
                                    </span>
                                  )}
                                </td>
                                <td width="130">
                                  {moment(list.created_at).format(DateDDMMMYYY)}
                                </td>
                                <td width="150">
                                  <Button
                                    type="button"
                                    color="primary"
                                    disabled={
                                      this.props.practiceUserLoginInfo
                                        .practice_id == list.id
                                        ? true
                                        : false
                                    }
                                    className={`p-3 `}
                                    onClick={(e) => this.onManage(list.id)}
                                    size="md"
                                  >
                                    Manage
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
                <div
                  style={{ marginBottom: "40px" }}
                  className="notification__inner__footer add_manage_footer d-flex justify-content-center align-items-center"
                >
                  <button
                    class="mx-3 btn btn-primary w-50 "
                    onClick={() => {
                      history.push({
                        pathname: "/subscribe-form",
                        state: { isNewLocation: true },
                      });
                    }}
                  >
                    Add new location
                  </button>
                </div>
                {getLocationsList && getLocationsList.last_page > 1 && (
                  <div className="notification__inner__footer add_manage_pagination d-flex justify-content-center align-items-center">
                    <ul>
                      {[
                        ...Array(
                          getLocationsList && getLocationsList.last_page
                        ),
                      ].map((page, i) => (
                        <li key={i}>
                          <Button
                            type="button"
                            className={`p-3 ${
                              i + 1 === this.state.currentPage
                                ? "activepage"
                                : null
                            }`}
                            onClick={(e) => this.handlechangePage(e, i)}
                            size="md"
                            color="link"
                          >
                            {i + 1}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              </div>
            )}
            {step === 2 && (
              <div className="d-flex flex-column  mb-auto mx-auto add_manager_section">
              <div className="notification__inner mb-auto d-flex flex-column">
                <div className="notification__inner__header">
                  <div className="row">
                    <div className="col-7">
                      <h4>View managers assigned to all your locations</h4>
                    </div>
                    <div className="col-5  justify-content-end d-flex">
                      {/* <Button color="link"> {`<`} </Button> <Button color="link"> {`>`} </Button>  */}
                      <img
                        style={{ width: "45px" }}
                        src={IconLocationWhite}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <FormGroup className="mr-20">
                      <InputField
                        type="text"
                        label={"Search manager by name"}
                        name="searchByMname"
                        onChange={(e) => {
                          this.managerFilter(e);
                        }}
                      />
                    </FormGroup>
                    {isCorporate == 1 && (
                      <>
                        <FormGroup className="mr-20">
                          <InputField
                            type="text"
                            label={"Search manager by city"}
                            name="searchByMcity"
                            onChange={(e) => {
                              this.managerFilter(e);
                            }}
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>
                </div>
                {loading ? (
                  <div className="notification__inner__body flex-grow-1 flex-column d-flex  align-items-center justify-content-center">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <div className="notification__inner__body flex-grow-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="180">MANAGER</th>
                          <th width="214">PRACTICE NAME</th>
                          <th width="25%">LOCATION</th>
                          <th width="154">ASSIGNED DATE</th>
                          <th width="187"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.isViewAll &&
                          managerList.map((list, index) => {
                            return (
                              <>
                                {this.state.confirmId ===
                                list.practice[0].id + "_" + list.id ? (
                                  <tr key={index}>
                                    <td colSpan="3">
                                      Unassigning {list.full_name || ""} from
                                      this location will immediately revoke
                                      user's admin profile priviledges.{" "}
                                    </td>
                                    <td width="187">
                                      <button
                                        class="mx-3 btn btn-md"
                                        style={{ backgroundColor: "snow" }}
                                        onClick={() => {
                                          this.setState({ confirmId: "" });
                                        }}
                                      >
                                        CANCEL
                                      </button>{" "}
                                    </td>
                                    <td width="187">
                                      {list.practice.length === 1 ? (
                                        <button
                                          class="mx-3 btn btn-danger btn-md"
                                          onClick={() => {
                                            this.unAssignLocation(
                                              list.practice[0].id,
                                              list.id
                                            );
                                          }}
                                        >
                                          UNASSIGN
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            this.viewAll(true, list);
                                          }}
                                          class="mx-3 btn btn-primary btn-md"
                                        >
                                          VIEW ALL
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td width="180">{list.full_name || ""}</td>
                                    <td width="214">
                                      {list.practice.length === 1
                                        ? list.practice[0].name
                                        : "Multiple"}
                                    </td>
                                    <td width="25%">
                                      {list.practice.length === 1
                                        ? `${
                                            list.practice[0].address_line_1
                                              ? list.practice[0].address_line_1
                                              : ""
                                          }` +
                                          `${
                                            list.practice[0].address_line_2
                                              ? ", " +
                                                list.practice[0].address_line_2
                                              : ""
                                          }` +
                                          `${
                                            list.practice[0].city
                                              ? " - " + list.practice[0].city
                                              : ""
                                          }` +
                                          `${
                                            list.practice[0].state
                                              ? ", " +
                                                list.practice[0].state.code
                                              : ""
                                          }` +
                                          `${
                                            list.practice[0].zip
                                              ? " | " + list.practice[0].zip
                                              : ""
                                          }`
                                        : "Multiple"}
                                    </td>
                                    <td width="154">
                                      {list.practice.length === 1 ? (
                                        list.is_invitee == 1 ? (
                                          <span className="text--primary">
                                            Pending
                                          </span>
                                        ) : (
                                          moment(list.created_at).format(
                                            DateDDMMMYYY
                                          )
                                        )
                                      ) : (
                                        "Multiple"
                                      )}
                                    </td>
                                    <td width="187">
                                      {list.practice.length === 1 ? (
                                        <button
                                          class={`mx-3 btn btn-danger btn-md ${
                                            list.is_invitee == 1 && "disabled"
                                          }`}
                                          disabled={list.is_invitee == 1}
                                          onClick={() => {
                                            this.setState({
                                              confirmId:
                                                list.practice[0].id +
                                                "_" +
                                                list.id,
                                            });
                                          }}
                                        >
                                          UNASSIGN
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            this.viewAll(true, list);
                                          }}
                                          class="mx-3 btn btn-primary btn-md"
                                        >
                                          VIEW ALL
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        {this.state.isViewAll &&
                          !!this.state.selectedManager.practice &&
                          this.state.selectedManager.practice.map((pr, i) => {
                            return (
                              <>
                                {this.state.confirmId ===
                                pr.id + "_" + this.state.selectedManager.id ? (
                                  <tr>
                                    <td colSpan="3">
                                      Unassigning{" "}
                                      {this.state.selectedManager.full_name ||
                                        ""}{" "}
                                      from this location will immediately revoke
                                      user's admin profile priviledges.
                                    </td>
                                    <td width="187">
                                      <button
                                        class="mx-3 btn btn-md"
                                        style={{ backgroundColor: "snow" }}
                                        onClick={() => {
                                          this.setState({ confirmId: "" });
                                        }}
                                      >
                                        CANCEL
                                      </button>{" "}
                                    </td>
                                    <td width="187">
                                      <button
                                        class="mx-3 btn btn-danger btn-md"
                                        onClick={() => {
                                          this.unAssignLocation(
                                            pr.id,
                                            this.state.selectedManager.id
                                          );
                                        }}
                                      >
                                        UNASSIGN
                                      </button>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td width="130">
                                      {this.state.selectedManager.full_name ||
                                        ""}
                                    </td>
                                    <td width="165">{pr.name}</td>
                                    <td width="400">
                                      {`${
                                        pr.address_line_1
                                          ? pr.address_line_1
                                          : ""
                                      }
                          ${pr.address_line_2 ? ", " + pr.address_line_2 : ""}
                          ${pr.city ? " - " + pr.city : ""}
                          ${pr.state ? ", " + pr.state.code : ""}
                          ${pr.zip ? " | " + pr.zip : ""}`}
                                    </td>
                                    <td width="130">
                                      {this.state.selectedManager.is_invitee ==
                                      1 ? (
                                        <span style={{ color: "red" }}>
                                          Pending
                                        </span>
                                      ) : (
                                        moment(
                                          this.state.selectedManager.created_at
                                        ).format(DateDDMMMYYY)
                                      )}
                                    </td>
                                    <td width="187">
                                      <button
                                        class={`mx-3 btn btn-danger btn-md ${
                                          this.state.selectedManager
                                            .is_invitee == 1 && "disabled"
                                        }`}
                                        disabled={
                                          this.state.selectedManager
                                            .is_invitee == 1
                                        }
                                        onClick={() => {
                                          this.setState({
                                            confirmId:
                                              pr.id +
                                              "_" +
                                              this.state.selectedManager.id,
                                          });
                                        }}
                                      >
                                        UNASSIGN
                                      </button>
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
                {this.state.isViewAll ? (
                  <div className="notification__inner__footer d-flex justify-content-center align-items-center">
                    <button
                      class="mx-3 btn btn-primary w-50"
                      onClick={() => {
                        this.viewAll(false);
                      }}
                    >
                      Back
                    </button>
                  </div>
                ) : (
                  <div
                    style={{ marginBottom: "40px" }}
                    className="notification__inner__footer d-flex justify-content-center align-items-center"
                  >
                    <button
                      class="mx-3 btn btn-primary w-50"
                      onClick={() => {
                        this.setState({ step: 3 });
                      }}
                    >
                      Assign Manager
                    </button>
                  </div>
                )}
                {getLocationsManagerList &&
                  getLocationsManagerList.last_page > 1 && (
                    <div className="notification__inner__footer d-flex justify-content-center align-items-center">
                      <ul>
                        {[...Array(getLocationsManagerList.last_page)].map(
                          (page, i) => (
                            <li key={i}>
                              <Button
                                type="button"
                                className={`p-3 ${
                                  i + 1 === this.state.currentPageManager
                                    ? "activepage"
                                    : null
                                }`}
                                onClick={(e) =>
                                  this.handleManagerPageChange(i + 1)
                                }
                                size="md"
                                color="link"
                              >
                                {i + 1}
                              </Button>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
              </div>
              </div>
            )}
          {step === 3 && (
            <>
              {this.state.isCompleted ? (
                <div className="email__inner assign_manager assign_manager_sucess d-flex flex-column mb-auto mx-auto ">
                  <div className="d-flex flex-column h-100 text-center  text-center h-100 justify-content-center">
                    <div className="email__inner__header assign_manager_header">
                      <h3
                        className="mb-4"
                        style={{ fontSize: "20px", color: "#fff" }}
                      >
                        Invitation sent successfully
                      </h3>
                      <p>
                        Once your manager completes the form available in the
                        link you’ve just sent, he/ she will have admin profile
                        privileges and will be able to change and update
                        sensitive information when logged in to Thalamus at his
                        assigned location.
                      </p>
                    </div>
                    <div className="email__inner__footer assign_manager_footer">
                      <div className="button-section text-center d-flex">
                        <Button
                          size="md"
                          color="primary"
                          onClick={() => {
                            this.backtomanager();
                          }}
                        >
                          Back to managers
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <AddManager
                  managerInvite={(data) => {
                    this.managerInvite(data);
                  }}
                  back={() => {
                    this.setState({ step: 2 });
                  }}
                />
              )}
            </>
          )}

          <div className="managecard__suggestion total_device d-flex flex-column align-items-flex-start justify-content-center">
            <h4>
              <span>{getLocationsList && getLocationsList.total}</span>{" "}
              Locations
            </h4>
            <div className="divider mt-4"></div>
            <p>Some locations don’t have a manager assigned to them yet.</p>
            <button
              className="btn btn-outline-default white btn-md mr-4"
              onClick={() => {
                this.updateFilter("unassigned");
              }}
            >
              Unassigned locations
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.task.error,
  loading: state.task.loading,
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
  getLocationsList: state.task.getLocationsList,
  getLocationsManagerList: state.task.getLocationsManagerList,
  managerInviteSend: state.task.managerInviteSend,
  unassignManager: state.task.unassignManager,
  loginPracticeLocationinfo: state.task.loginPracticeLocationinfo,
  isCorporate: state.task.practiceInfo.is_corporate,
  unAssignedLocations: state.task.unAssignedLocations,
});

export default compose(
  withTranslation("common"),
  connect(mapStateToProps)
)(LocationListing);
