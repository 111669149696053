import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import Profile_icon from '../../img/play_white.svg';
import Player from '../videoPlayer/operatoryVideoplayer';
import { useSelector } from 'react-redux';
import { ENTERAINMENTTYPE, formatSecond, TIMELINEMEDIATYPE } from '../../data/comman';

const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomTimelineEntertainmentVideoSelected = ({videos , setVideos}) => {
    const [showVideo, setShowVideo] = useState({
		video: {},
		showVideo: false,
	});
	const [count, setCount] = useState(0);
	const [modal, setModal] = useState(false);

    // Play Selected Video
    const playSelectedVideo = (video) => {
        setShowVideo({
			video: video,
			showVideo: true,
		});
    }
    // On Video Close
    const onClickClose = (close) => {
		setShowVideo({
			video: {},
			showVideo: false,
		});
	};
	const toggle = () => setModal(!modal);

	const togglePip = (isPipOn) => {
		setShowVideo({
			...showVideo,
			showVideo: !isPipOn,
		});
	}
    const format = (seconds) => {
		if (isNaN(seconds)) {
			return '00:00';
		}

		const date = new Date(seconds * 1000);
		const hh = date.getUTCHours();
		const mm = date.getUTCMinutes();
		const ss = date.getUTCSeconds().toString().padStart(2, '0');

		if (hh) {
			return `${hh}:${mm.toString().padStart(2, '0')} :${ss}`;
		}
		return `${mm}:${ss}`;
	};
	return (
        <>
            <div className='videoSelected__main__centerCol'>
                <div className="video_list">
					{console.log(videos,'added')}
                {videos.map((video, index) => {
						const selectedImage =
							video.selected === true ? RIGHTBOXIMAGE : WHITEBOXIMAGE;
						return (
							<div draggable={true} onDragStart={(event) => { event.dataTransfer.setData("timeline", JSON.stringify({ type: TIMELINEMEDIATYPE, timeLine: video })) }}
						
								key={index}
								className={`videoSelected__main__columnFour videoSelected_box ${video.isAdded && video.isAdded.length > 0 ? 'border-4 border-warning' : ''}`}>
								<div
									className='videoSelected__main__card'
									//onClick={() => changeVideoSelected(video)}
                                    >
									{video.isAdded && video.isAdded.length > 0 && <h3 className='videoSelected__main__card__selectedImage' >{video.isAdded.length}</h3>}
									{/* <img
										className='videoSelected__main__card__selectedImage'
										src={`${selectedImage}`}
										alt=''
									/> */}
									<div style={{ height: '100%', width: '100%' }}>
										<img
											src={video.thumb}
											alt={video.file_name}
											style={{
												height: '100%',
												width: '100%',
												objectFit: 'cover',
											}}
										/>
									</div>
									<div className='videoSelected__main__card__subTitle'>
										<div className='videoSelected__main__card__video_icon' onClick={() => playSelectedVideo(video)}>
											<img
												src={Profile_icon}
												alt='play_video'
												
											/>
										</div>
										<p className='videoSelected__main__card__title'>
											{video.title} | {formatSecond(video.duration)}
										</p>
										<p className='videoSelected__main__card__smalltitile'>
											{video.category_name}
										</p>
									</div>
								</div>
							</div>
						);
					})}
                   {showVideo.video ? (
						<div size="xxl" className={`${showVideo.showVideo ? 'bg-block  d-flex' : 'bg-none d-none'} h-100 flex-column align-items-strach justify-content-center modal-video`} isOpen={showVideo.video} toggle={toggle}>
							<div className={`${showVideo.showVideo ? 'd-block' : 'd-none'} video-show sdf  mt-5`}> 
								
								<Player
									video={showVideo.video}
									title={showVideo.video}
									className="w-100 h-100"
									onClickClose={onClickClose}
									// openImgEditor={openImgEditor}
									togglePip={togglePip}
									isWaitingRoom={true}
								/>
								
							</div>
						</div>
					) : ('')}
                </div>
            </div>
        </>
    )
}

export default WaitingRoomTimelineEntertainmentVideoSelected
