import React from 'react';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'reactstrap';

import SubHeader from  '../../components/Header/subHeader'
import Loading from '../../components/Loading'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import {  GetauthorizedDevice  , resetSuccess , GetSubscribeInfo} from "../../store/actions/taskAction";

import PC from '../../img/device_type_pc.svg'
import IPAD from '../../img/IPAD.svg'
import { history } from '../index';


class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      activeItem:[],
      isRemoveProfile:false,
      isRemoveVerify:false,
      SelectedUser:'',
      isAuthorized:true,
      isError:{
        deviceType:null
      }
    }
    
    this.toggleAcive = this.toggleAcive.bind(this);
  }

  
  toggleAcive(users, index) {
    this.setState({
      activeItem:users.id,
      isRemoveVerify:false
    })
  }


  async componentDidMount(){
  await  this.props.dispatch(GetauthorizedDevice());
  await this.props.dispatch(GetSubscribeInfo());
  await this.props.dispatch(resetSuccess());
  }

  getDeviceType(type){
    switch(type){
        case 'PC': 
            return  ["pc", PC];
        case 'IPAD':
            return  ["ipad", IPAD];
        default:
          return    ['pc', PC];
    }
  }

  onManageClick(){
    const isAuthorized = this.props.practiceUserLoginInfo.type === 4 || this.props.practiceUserLoginInfo.type === 2 || this.props.practiceUserLoginInfo.type === 3 ? true : false
    if(isAuthorized){
      history.push('/addons')
    }else{
      this.setState({isAuthorized:false})
    }
    
    
  }
  render (){
    
    const { error } = this.props
    const {authorizedDeviceList , loading , getSubscribeinfo} = this.props
    let data = authorizedDeviceList ? authorizedDeviceList.data : []
    return(
      <div className="authorized_device__main__screen  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Devices"/>
        {loading ?
          <Loading />
        :
          <div className="d-flex flex-column  authorized_device__inner__text  w-100 mb-auto">
            {data && data.length <= 4 ?
              <h1 className="mb-5 text-center device-4">Authorized devices</h1>
            :
              <h1 className="mb-5 text-center ">Authorized devices</h1>
            }
            
            <div className="d-flex align-items-center">
              <div className="d-flex flex-wrap row-cols-5 authorized_device__section offset-md-2 col-8 p-0">
                {data && data.map((device , index) => {
                  var deviceType = this.getDeviceType(device.type)
                  return <div key={index}  onClick={e => this.toggleAcive(device)}  className={`user__profile  mx-20 mb-5 text-center  ${deviceType[0]}`}>
                            <div className={`authorized_device__box d-flex flex-column justify-content-center mb-3  ${deviceType[0]}`}>
                              <div className="authorized_device__picture">
                                <img className="mx-auto my-auto" alt=""  src={deviceType[1]}/>
                              </div>
                              {console.log(device.name,'device')}
                            </div>
                            <div className="authorized_device__name">
                              {device.name}
                            </div>
                          </div>
                })}
              </div>
              <div className="authorized_device__total col-2 p-0 ml-auto mb-5 pb-5 d-flex flex-column align-items-flex-start justify-content-center">
              <h4 className="mb-5"><span className="fs-50 text--primary">
                {data && data.length}
                </span>  Authorized devices</h4>
                <h4 className="m-0 mt-3"><span className="fs-50 text--primary">
                {getSubscribeinfo && getSubscribeinfo.available_device}
                </span>  Total devices</h4>
                <div className="border--bottom mt-5 mb-5"></div>
                <Button type="button" onClick={() => this.onManageClick()}  size="md"   color={`${this.state.isAuthorized ? 'btn btn-outline-default white btn-outline-defaul btn-md' : 'red'}`}>
                  {
                    this.state.isAuthorized ? 'Manage Devices' : "You're not authorized" 
                  }
                </Button>
              </div>
            </div>
          </div>
        }

        {/* {error && 
          <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{error.message}</p> </div>
        } */}
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({

  loading: state.task.loading,
  error: state.task.error,
  authorizedDeviceList:state.task.authorizedDeviceList,
  SuccessProfileRemove:state.task.SuccessProfileRemove,
  practiceUserLoginInfo:state.task.practiceUserLoginInfo,
  getSubscribeinfo:state.task.getSubscribeinfo
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Index);