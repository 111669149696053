import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import DropDown from '../../components/DropDown'
import { InputField } from '../../components/InputField';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { history } from '../../pages/index';
import {GetNotification, getDesignateCardMultiple, postCorporateMultipleCard } from "../../store/actions/taskAction";
import { Button , Spinner , FormGroup , Label , Row ,Col} from 'reactstrap';
import StripeIcon from '../../img/stripe-icon.png'
import './style.scss';
import LoadingButton from '../../components/LoadingButton';
import { getCartImageByType } from '../../data/comman';

class HomeIndex extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         expanded: false,
         primaryCardSelected: null,
         primaryCardHolderName: "",
         primaryCardHolderExipreDate: "",
         primaryPracticeSelected: [],
         secondryCardSelected: null,
         secondaryCardHolderName: "",
         secondaryCardHolderExipreDate: "",
         secondryPracticeSelected: [],
         errors: {},
         isPrimaryUpdate: true,
         isSecondryUpdate: false,
      }
      this.handleChange = this.handleChange.bind(this);
   }

   handleChange(field, options){
      if(field == "primaryCardSelected"){
         this.setState({ 
            primaryCardHolderName: options.name,
            primaryCardHolderExipreDate: `${options.exp_month} / ${options.exp_year}`,
            [field]: options,
            errors: {
               ...this.state.errors,
               [field]:  "",
            }
         })
      } else if(field == "secondryCardSelected"){
         this.setState({
            secondaryCardHolderName:  options.name,
            secondaryCardHolderExipreDate: `${options.exp_month} / ${options.exp_year}`,
            [field]: options,
            errors: {
               ...this.state.errors,
               [field]:  "",
            }
         })
      }else{
         this.setState({ 
            [field]: options,
            errors: {
               ...this.state.errors,
               [field]:  "",
            },
          });
      }
   }

   async componentDidMount(){
      if(this.props.isCorporate){
         await this.props.dispatch(getDesignateCardMultiple());
      }
   }

   async onReplaceClick(){
      if(!this.isValid()){
         var { primaryCardSelected, primaryPracticeSelected, secondryCardSelected, secondryPracticeSelected, isPrimaryUpdate, isSecondryUpdate } = this.state;
         var params = {};

         if(isPrimaryUpdate){
            params['card_id'] = primaryCardSelected.id;
            params['practice_id'] = primaryPracticeSelected.map(x => x.id);
         }else if(isSecondryUpdate){
            params['card_id'] = secondryCardSelected.id;
            params['practice_id'] = secondryPracticeSelected.map(x => x.id);
         }
         await this.props.dispatch(postCorporateMultipleCard(params, isPrimaryUpdate ? primaryCardSelected : secondryCardSelected));
      }
   }

   isValid(){
      var errors = {};
      var isError = false
      var { primaryCardSelected, primaryPracticeSelected, isPrimaryUpdate, isSecondryUpdate, secondryCardSelected, secondryPracticeSelected } = this.state;
      if(isPrimaryUpdate){
         if(primaryCardSelected == null) {
            isError = true;
            errors['primaryCardSelected'] = "Please select card";
         }

         if(primaryPracticeSelected && primaryPracticeSelected.length == 0){
            isError = true;
            errors['primaryPracticeSelected'] = "Please select practice";
         }
      }else if(isSecondryUpdate){
         if(secondryCardSelected == null) {
            isError = true;
            errors['secondryCardSelected'] = "Please select card";
         }

         if(secondryPracticeSelected && secondryPracticeSelected.length == 0){
            isError = true;
            errors['secondryPracticeSelected'] = "Please select practice";
         }
      }else{
         errors['message'] = "Please select any of the option"
      }

      this.setState({ errors });
      return isError;
   }

   customOpetionLabel({last4, brand}) {
      return  <div className="custome__opetion d-flex align-items-center">
           <div className="card_brand__img mr-4">
              <img src={getCartImageByType(brand)} alt={brand}/>
           </div>
           <div className="select__label">{`XXXX XXXX XXXX ${last4}`}</div>
        </div>
     };

   render (){
      const { errors, isPrimaryUpdate, isSecondryUpdate } = this.state;
      return(
         <div className="main__screen designate__page  d-flex flex-column h-100 align-items-center justify-content-center">
            <SubHeader className="mb-auto" title="Subscription Payment  |  Corporation"/>
            <div className="designate__inner mb-auto d-flex flex-column">
               <img src={StripeIcon} className="stripe-logo" alt=""/>
                     <div className="d-flex">
                        <div className="primary-card-section card-section">
                           <div className="custom_check">
                              <input type="checkbox" name="primary" id="primary" checked={isPrimaryUpdate} onChange={(e) => { this.setState({ errors: {}, isPrimaryUpdate: true, isSecondryUpdate: false }) }} />
                              <label for="primary"><h4 className="ml-3">Primary credit card</h4></label>
                           </div>
                           <p>Select a credit card to act as a primary card to multiple practice locations. This action replaces all primary cards for chosen practices.</p>
                           <FormGroup>
                              <Label>Select the card to be used</Label>
                              <DropDown 
                                 className='react-select-container w-100'
                                 classNamePrefix="react-select"
                                 options={this.props.designateCardMultiple && this.props.designateCardMultiple.primaryCards || []}
                                 formatOptionLabel={this.customOpetionLabel}
                                 onChange={(options) => this.handleChange("primaryCardSelected", options)}
                                 getOptionLabel={x => `XXXX XXXX XXXX ${x.last4}`}
                                 getOptionValue={x => `${x.pay_card_id}`}
                                 placeholder={'Enter the last 4 digits or search on the list'}
                              />
                              {errors && <span className='text--danger'>{errors.primaryCardSelected}</span>}
                           </FormGroup>
                           <div className="row">
                                 <div className="col-6">
                                    <FormGroup className="disabled" disabled>
                                       <InputField type="text" label={'Name on card'} name="nameoncard" value={this.state.primaryCardHolderName} disabled/>
                                    </FormGroup>
                                 </div>
                                 <div className="col-6">
                                    <FormGroup className="disabled" disabled>
                                       <InputField type="text" label={'Expiry date'} name="expirydate" value={this.state.primaryCardHolderExipreDate} disabled/>
                                    </FormGroup>
                                 </div>
                           </div>
                           
                           <FormGroup>
                              <Label>Select the location(s) to use the selected card</Label>
                              <DropDown 
                                 className='react-select-container react-select__multi w-100'
                                 classNamePrefix="react-select"
                                 options={this.props.designateCardMultiple && this.props.designateCardMultiple.practice || []}
                                 getOptionLabel={x => `${x.name}`}
                                 getOptionValue={x => `${x.id}`}
                                 onChange={(options) => this.handleChange("primaryPracticeSelected", options)}
                                 isSearchable={true}
                                 placeholder={'Enter practice(s) name or search on the list'}
                                 isMulti={true}
                              />
                              {errors && <span className='text--danger'>{errors.primaryPracticeSelected}</span>}
                           </FormGroup>
                        </div>
                        <div className="secondary-card-section card-section">
                           <div className="custom_check">
                              <input type="checkbox" name="primary" id="secondray" checked={isSecondryUpdate}  onChange={(e) => { this.setState({ errors: {}, isPrimaryUpdate: false, isSecondryUpdate: true }) }}/>
                              <label for="secondray"><h4 className="ml-3">Secondary credit card</h4></label>
                           </div>
                           <p>Select a credit card to act as a secondary card to multiple practice locations. This action replaces all secondary cards for chosen practices.</p>
                           <FormGroup>
                              <Label>Select the card to be used</Label>
                              <DropDown 
                                 className='react-select-container w-100'
                                 classNamePrefix="react-select"
                                 options={this.props.designateCardMultiple && this.props.designateCardMultiple.secondrayCards || []}
                                 formatOptionLabel={this.customOpetionLabel}
                                 onChange={(options) => this.handleChange("secondryCardSelected", options)}
                                 getOptionLabel={x => `XXXX XXXX XXXX ${x.last4}`}
                                 getOptionValue={x => `${x.pay_card_id}`}
                                 placeholder={'Enter the last 4 digits or search on the list'}
                              />
                              {errors && <span className='text--danger'>{errors.secondryCardSelected}</span>}
                           </FormGroup>
                           <div className="row">
                                 <div className="col-6">
                                 <FormGroup className="disabled" disabled>
                                    <InputField type="text" label={'Name on card'} name="nameoncard" value={this.state.secondaryCardHolderName} disabled/>
                                 </FormGroup>
                                 </div>
                                 <div className="col-6">
                                 <FormGroup className="disabled" disabled>
                                    <InputField type="text" label={'Expiry date'} name="expirydate" value={this.state.secondaryCardHolderExipreDate} disabled/>
                                 </FormGroup>
                                 </div>
                           </div>
                           
                           <FormGroup>
                              <Label>Select the location(s) to use the selected card</Label>
                              <DropDown 
                                 className='react-select-container react-select__multi w-100'
                                 classNamePrefix="react-select"
                                 options={this.props.designateCardMultiple && this.props.designateCardMultiple.practice || []}
                                 getOptionLabel={x => `${x.name}`}
                                 getOptionValue={x => `${x.id}`}
                                 onChange={(options) =>
                                 this.handleChange("secondryPracticeSelected", options)}
                                 isSearchable={true}
                                 placeholder={'Enter practice(s) name or search on the list'}
                                 isMulti={true}
                              />
                           </FormGroup>
                           {errors && <span className='text--danger'>{errors.secondryPracticeSelected}</span>}
                        </div>
                     </div>
                     {errors && <span className='text--danger'>{errors.message}</span>}
                     <div className="mt-auto d-flex designate-footer">
                        {/* <Button color="primary" size="md" onClick={() => this.onReplaceClick()}>Replace cards</Button> */}
                        <LoadingButton type="button" label="Replace cards" loading={this.props.buttonLoading} disabled={this.props.buttonLoading} size="md" onClick={() =>  this.onReplaceClick()} color="primary"/>
                        <Button outline size="md" color="default white col" onClick={() => history.goBack()}>Back</Button> 
                     </div>
            </div>
         </div>
      ) 
   }
}
const mapStateToProps = (state) => ({
   error: state.task.error,
   loading: state.task.loading,
   buttonLoading: state.task.buttonLoading,
   practiceUserLoginInfo:state.task.practiceUserLoginInfo,
   getnotificationlist:state.task.getnotificationlist,
   isCorporate: state.task.isCorporate,
   designateCardMultiple: state.task.designateCardMultiple,
});
export default compose(
withTranslation('common'),
connect(mapStateToProps  )
)(HomeIndex);