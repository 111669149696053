import React from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import SubHeader from  '../../../components/Header/subHeader'
import { Button} from 'reactstrap';
import { history } from '../../../pages/index';
import {cleardata } from "../../../store/actions/taskAction";


import Phone from './Phone'
import Email from './Email'

class NewProfileForm extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      step: 1,
    };
    this.stepOne = this.stepOne.bind(this);
    this.stepTwo = this.stepTwo.bind(this);
  }

  stepOne(){
    this.setState({
      step:1,
    })
  }
  
  stepTwo(){
    this.setState({
      step:2,
    })
  }


  async componentDidMount(){
   
   
  }

  renderStep(step , index){
    switch(step){
        case 1:

            return  <Phone
                      isError={this.state.isError }
                      primerCreditCard={this.state.primerCreditCard}
                      expireDate={this.state.expireDate}
                      getPrimeryCardInfo={this.getPrimeryCardInfo}
                    />
        case 2:
            return  <Email 
                      isError={this.state.isError }
                      secondaryexpireDate={this.state.secondaryexpireDate}
                      secondaryCreditCard={this.state.secondaryCreditCard}
                      getSecondaryCardInfo={this.getSecondaryCardInfo}
                    />
        default :
          return  null
    }
  }

   onclickBack ()  {
    history.push("/authorized")
  };
  render (){
    const { step } = this.state;
    return(
      <>
       <SubHeader className="mb-5" title={`${this.state.step == 1 ? 'Phone Number Update' : 'Email Address Update'}`}/>
        {this.props.isUpdateProfileCompleted ?
        <div className="update_admin_profile__inner update_phone_number mb-auto d-flex  flex-column  border-success border">
          <div className="update_admin_profile__inner__body d-flex align-items-center justify-content-center">
            <div className="d-block w-100">
              {this.state.step == 1 ?
                <div className="update_admin_profile__inner__header text-center phone_number_update">
                  <h4>Phone number successfully updated</h4>
                  <p>From now on, verification codes to restricted areas access will be sent to your new number.</p>
                </div>
                :
                <div className="update_admin_profile__inner__header text-center phone_number_update">
                  <h4>Email address successfully updated</h4>
                  <p>From now on, verification codes to restricted areas access will be sent to your new email.</p>
                </div>
              }
            </div>
          </div>
          <div className="update_admin_profile__inner__footer mt-auto phone_number_up_footer">
            <Button size="md px-0" onClick={this.onclickBack} color="primary">Back to admin profile verification</Button>
          </div>
        </div>
        :
        <>
        <div className="tabs">
            <ul className="d-flex justify-content-between">
              <li onClick={ this.stepOne } className={`col text-center ${step === 1 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Mobile no.</label>
              </li>
              <li onClick={ this.stepTwo } className={`col text-center ${step === 2 ? 'activetab' : ''}`}>
                <span></span>
                <label className="">Email</label>
              </li>
            </ul>
        </div>
        <div className="mb-auto pb-5">
          { this.renderStep(step) }
        </div>
        </>
        }
      </>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceUserLoginInfo: state.task.practiceUserLoginInfo,
  getBillingCardInfo:state.task.getBillingCardInfo,
  practiceUserAccountInfo:state.task.practiceUserAccountInfo,
  isUpdateProfileCompleted:state.task.isUpdateProfileCompleted
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(NewProfileForm);
