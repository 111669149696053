/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import WaitingRoomTimelineEntertainmentCategories from './WaitingRoomTimelineEntertainmentCategories';
import WaitingRoomTimelineEntertainmentVideoSelected from './WaitingRoomTimelineEntertainmentVideoSelected';

const WaitingRoomTimelineEntertainment = ({ entertainmentTimeline, timeLineList, setModal, onPublish }) => {
	const [selectedVideos, setSelectedVideos] = useState([]);
	const state = useSelector((state) => state.task);
	const [categories, setCategories] = useState([]);

	useEffect(()=>{
		console.log(selectedVideos,'selectedVideos')
		const videos = [];
		selectedVideos.map((video) => {
			var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
			videos.push({
				...video,
				isAdded,
			});
		});
        setSelectedVideos(videos)
    }, [timeLineList])

	useEffect(() => {
		const categoryArray = [];
		const videos = [];
		var totalVideos = 0;
		state.entertainmentTimeline.map((element) => {
			categoryArray.push({
				category_id: element.categories_id,
				category_name: element.categories_name,
				selected: false,
				videosLength: element.video.length,
				videos: element.video,
			});
			element.video.map((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				videos.push({
					categories_id: element.categories_id,
					categories_name: element.categories_name,
					...video,
					selected: false,
					isAdded
				});
			});
		});
		setSelectedVideos(videos);
		setCategories(categoryArray);
	}, [ ]);
	
	
	const setVideos = (videos) => {
		setSelectedVideos(videos);
	};
	return (
		<>
			<div className="waitiing-room-col-sec">
				<div className="waiting-room-left-sec">
					<WaitingRoomTimelineEntertainmentCategories
						specialization={entertainmentTimeline}
						setVideos={setVideos}
						timeLineList={timeLineList}
						setModal={setModal}
						onPublish={onPublish}
					/>
				</div>
				<div className="waiting-rom-right-sec">
					<WaitingRoomTimelineEntertainmentVideoSelected
						videos={selectedVideos}
                        setVideos={setVideos}
					/>
				</div>	
			</div>
		</>
	);
};

export default WaitingRoomTimelineEntertainment;
