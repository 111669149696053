import React, { useState, usePrevious, useRef, useEffect } from 'react'
import styles from './style.module.scss';
import Header from '../Header'
import Sidebar, { TabPanel } from '../components/Sidebar';
import { tabList, tabItems } from './staticFile';
import { useDispatch, useSelector } from 'react-redux';
import { setGoogleTvTestDriveMenuItem } from '../../../store/actions/taskAction';
const MainScreen = () => {
  const [value, setValue] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveSidebar, setActiveSidebar] = useState(false);
  const prevCountRef = useRef();
  const states = useSelector(state => state.task);
  const dispatch = useDispatch();
	const toggle = () => setIsOpen(!isOpen);

  const onHover = (value) =>{
		setActiveSidebar(value)
	}



  return (
    <div className={`${styles.goolge_tv_waiting_room__main__screen} d-flex flex-column h-100`}>
        <Header />  
        {console.log(prevCountRef,'prevCountRef')}
        <div className={`${isActiveSidebar ? 'activeNav' : '' } navGoogleTvSidebar `}>
          <div className="d-flex flex-column navSidebar__inner h-100 justify-content-between" onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)}>
          <h2 className={`${isActiveSidebar ? 'visible' : 'invisible' } mb-5 mt-5 pt-5 pl-5 pl-5`}>{'Waiting Room'}</h2>
            <div className="navSidebar__tabItem" >
                <Sidebar
                  value={states.googleTvTestDriveMenuItem}
                  setValue={setValue}
                  tabList={tabList}
                  onClick={toggle}
                />
            </div>
          </div>
        </div>
      <div className={`${states.googleTvTestDriveMenuItem == 1 ? 'home' : ''} tabcontent` }>
        {tabItems.map((item, index) => (
          <TabPanel
            value={states.googleTvTestDriveMenuItem}
            index={index}
            key={index}
            >
            {item.component}
          </TabPanel>
        ))}
      </div>
    </div>
  )
}

export default MainScreen
