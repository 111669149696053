import React from 'react';

import { withTranslation } from 'react-i18next';
import { Button ,  FormGroup} from 'reactstrap';

import { connect } from "react-redux";
import { compose } from 'redux';
import ReactCrop from 'react-image-crop';

import { UpdateUserProfileImage , cleardata} from "../../../store/actions/taskAction";
import FileUpload from '../../../components/FileUpload'
import 'react-image-crop/lib/ReactCrop.scss';

class AddProfilePicture extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        profileInfo:props.practiceUserProfileInfo,
        isVerify:false,
        src: null,
        isFile:false,
        blobFile:'',
        selectedimg:'',
        crop: {
            aspect: 1 / 1,
            unit: '%',
            x: 25,
            y: 25,
            width: 50,
            height: 50,
        },
        loaded:props.progress,
        completed:props.isCompleted,
        file:null,
        imgUpload:'',
        croppedImageUrl:'',
        errors:{
            fileSize:'',
            fileType:'',
        }
     
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.UpdateUserProfileImage = this.UpdateUserProfileImage.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
  }

    nextClick(){
        this.props.nextStep();
    }
    
    prevClick(){
        this.props.prevStep();
    }

    async componentDidMount(){
        this.props.dispatch(cleardata());
    }

    onChange(e) {     
        this.props.dispatch(cleardata());   
        if (this.validateFile(e.target.files[0])) {
            if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
                reader.addEventListener(
                    'load',
                    () =>
                    this.setState({
                        src: reader.result,
                    }),
                    false
                )
                reader.readAsDataURL(e.target.files[0])
            }
            this.setState({
                profileInfo:{
                    ...this.state.profileInfo,
                    file:e.target.files[0], 
                },
                isFile:true , 
                completed:false, 
                loaded:0 , 
                errors:{filesize:'', fileType:''}
            })
        }else{
            this.setState({
                profileInfo:{
                    ...this.state.profileInfo,
                    file:e.target.files[0], 
                },
                isFile:false , 
                completed:false, 
                loaded:0 , 
            })
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
        this.imageRef.crossOrigin = '*';
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    }

    onCropChange = crop => {
        this.setState({ crop  })
    }

    cancelUpload(){
        this.setState({ 
            profileInfo:{
                ...this.state.profileInfo,
                file:null, 
            },
            src:null
        })
    }

    async UpdateUserProfileImage(e){
        e.preventDefault() 
        const formData = new FormData();
        formData.append(
            'profile_pic',this.state.blobFile,
        )
       
      await  this.props.dispatch(UpdateUserProfileImage(this.props.practiceUserProfileInfo.id ,  formData));
        this.setState({
            loaded:this.props.progress, 
        })
    }

    async makeClientCrop(crop) {
        console.log(crop,'crop')
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );
          this.setState({ croppedImageUrl });
        }
    }

    

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
            
          canvas.toBlob(blob => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error('Canvas is empty');
              return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            this.setState({blobFile:blob}) // Set blob image inside the state here 
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
      }

      validateFile(file){
        var isError = false;
        var errors = this.state.errors;

        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        
        if (validTypes.indexOf(file.type) === -1) {
            isError = true;
            errors = {
                fileType: 'Unsupported format',
            }
            
        }else if(file.size > 10485760){
            isError = true;
            errors = {
                fileSize: 'Max 10Mb',
            }
        }
        if(isError){ 
            this.setState({errors , file:file , loaded:5});
            return;
        }else{
            return true;
        }
    }


  render (){
    const {t , loading , values} = this.props
    const { crop, src , croppedImageUrl , loaded } = this.state;

    // if (loading) {
    //     return  <Loading />
    // }
    return(
        <div className="add_profile_picture__screen  md d-flex align-items-stretch justify-content-center mb-auto">
            <form onSubmit={this.UpdateUserProfileImage}>
                <div className="transparent__form--white ">
                    <FormGroup className="mb-5">
                        <h4>Upload and save your own profile picture</h4>
                    </FormGroup>
                    <div className={`${this.props.practiceUserProfileInfo.is_secure === 0 ? 'bg_white' : null } profile_picture_img_box d-flex justify-content-center`} onmousedown="event.preventDefault ? event.preventDefault() : event.returnValue = false" onmouseup="event.preventDefault ? event.preventDefault() : event.returnValue = false" >
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                crossorigin={null} 
                            />
                        )}
                    </div>
                    <div className="mt-4">
                        <FileUpload className="fileupload-sec" note={null} onChange={this.onChange} cancelUpload={this.cancelUpload} completed={this.props.isCompleted} loaded={this.props.progress} file={this.state.profileInfo.file}  errors={this.state.errors}/>
                    </div>
                    <div className="mt-auto d-flex"  style={{marginTop:"40px !important"}} >
                        <Button type="submit" disabled={!this.state.isFile} className={`${this.props.UpdateUserProfileImage === true ? 'verifyed' : ''} mt-5 col footer-save-button`} color="primary">
                                {!this.props.UpdateUserProfileImage ?
                                    <>Save image</>
                                    :
                                    <> image saved</>
                                }
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
    loading: state.task.loading,
    progress:state.task.progress,
    UpdateUserProfileImage:state.task.UpdateUserProfileImage,
    practiceUserProfileInfo: state.task.practiceUserProfileInfo,
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AddProfilePicture);
