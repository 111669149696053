import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button, Label, FormGroup, Form, CustomInput, Spinner } from 'reactstrap';
import { InputField} from '../../components/InputField';
import LoadingButton from '../../components/LoadingButton'
import { history } from '../index';
import {  InviteUser } from "../../store/actions/taskAction";

import './style.scss';

class AddUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addAuthorizedUserinfo:{
                first_name:'',
                email:'',
                email_confirm:'',
                type:''
            },
            emailValid: false,
            formValid: false,
            isError:{
                email:''
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

handleChange(field, options ) {
    var name = "";
    var value = "";
    
    name = field.target.name;
    value = field.target.value;

    this.setState({
        addAuthorizedUserinfo:{
            ...this.state.addAuthorizedUserinfo,
            [name]: value,
        }
    },()=> {this.isValid(name, value)});
};

isValid(name, value){
    let isError = this.state.isError;
    let emailValid = this.state.emailValid;
    var cemail;
    console.log(this.state.addAuthorizedUserinfo,'addAuthorizedUserinfo')
    switch (name) {
        case "first_name":
            isError.first_name =
                value.length < 4 ? "Atleast 4 characaters required" : "";
            break;
        case "last_name":
            isError.last_name =
                value.length < 0 ? "required" : "";
            break;
        case "email":
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            isError.email = emailValid ? "" : "Wrong format";
            break;
        case "email_confirm":
            isError.email_confirm =
                value !== this.state.addAuthorizedUserinfo.email ? "email does not match" : "";
            break;
        default:
             break;
    }
    
    this.setState(this.validateForm)
}

validateForm() {
    if( this.state.isError.email === '' 
        && this.state.isError.first_name === '' 
        && this.state.isError.email_confirm === '' 
        && this.state.addAuthorizedUserinfo.email !== '' 
        && this.state.addAuthorizedUserinfo.first_name !== ''
        && this.state.addAuthorizedUserinfo.last_name !== ''
        && this.state.addAuthorizedUserinfo.email_confirm !== ''){
        this.setState({formValid: true});
    }else{
        this.setState({formValid: false});
    }
}

onSend(){
    this.props.dispatch(InviteUser(this.state.addAuthorizedUserinfo));
}

componentDidMount(){
    this.setState({
        addAuthorizedUserinfo:{
            ...this.state.addAuthorizedUserinfo,
            type: this.props.location.type,
        }
    })
    console.log(this.props,'asssssssssssssssss')
}


render() {
  const { t , error , loadinginvite} = this.props
  const { isError } = this.state
  return(
    <div className="authorized_section__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Authorized Users"/>
        <div className="add_authorized_user transparent__form--white md d-flex flex-column align-items-stretch mb-auto">
            <Form className="h-100 d-flex flex-column">
                <FormGroup className="mb-20 adduser-form-header">
                    <h4 className="mb-4 mt-1">Authorizing a user</h4>
                    <p>Please provide the name and email of the person you’d like to give authorization over your Thalamus software. This person will receive an email containing a link where he or she can fill in with the remaining information.</p>
                </FormGroup>
                <FormGroup className="align-items-center mb-20">
                    <InputField type="text" isError={isError.first_name} onChange={(options) => this.handleChange(options)} className="mb-3" label="Name" placeholder="First" name="first_name" />
                    <InputField type="text" isError={isError.last_name}  onChange={(options) => this.handleChange(options)} placeholder="Last" name="last_name" />
                </FormGroup>
                <FormGroup>
                    <InputField type="text" isError={isError.email} o label={t('form.email')} name="email"  onChange={(options) => this.handleChange(options)}  />
                </FormGroup>
                <FormGroup>
                    <InputField type="text" isError={isError.email_confirm} label="Confirm email" name="email_confirm" onChange={(options) => this.handleChange(options)} />
                </FormGroup>
                <div className="mt-auto d-flex justify-content-between">
                    <LoadingButton type="button" className="mr-3 col" loading={loadinginvite} disabled={!this.state.formValid || loadinginvite} label='Send' onClick={() => this.onSend()}  color="primary" />
                    <Button type="button"   onClick={history.goBack} className="ml-3 col" outline color="default white">{t('btn.back')}</Button>
                </div>
            </Form>
        </div>
        {/* {error && 
          <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{error.message}</p> </div>
        } */}
    </div>
  ) 
}
}

const mapStateToProps = (state) => ({
  error: state.task.error,
  loadinginvite:state.task.loadinginvite
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AddUser);
