/** @format */

import axios from 'axios';
import { getToken, getDeviceCode, clearStorage, clearAllStorage, cleartoken, setToken } from '../../helper/LocalStorage';
import * as consts from '../../utilities/HttpUtility';

import {
	CLEAR_DATA,
	CLEAR_ERROR,
	RESET_SUCCESS,
	CLEAR_LOADING,

	GET_PRACTICEINFO_REQUEST,
	GET_PRACTICEINFO_SUCCESS,
	GET_PRACTICEINFO_FAIL,

	UPDATE_PRACTICEINFO_REQUEST,

	GET_PRACTICEMOREINFO_REQUEST,
	GET_PRACTICEMOREINFO_SUCCESS,
	GET_PRACTICEMOREINFO_FAIL,

	GET_TOS_REQUEST,
	GET_TOS_SUCCESS,
	GET_TOS_FAIL,

	POST_ACCEPT_TOS_REQUEST,
	POST_ACCEPT_TOS_SUCCESS,
	POST_ACCEPT_TOS_FAIL,

	GET_PRACTICE_PROFILE_REQUEST,
	GET_PRACTICE_PROFILE_SUCCESS,
	GET_PRACTICE_PROFILE_FAIL,

	GET_PRACTICESECURITYQUE_REQUEST,
	GET_PRACTICESECURITYQUE_SUCCESS,
	GET_PRACTICESECURITYQUE_FAIL,

	GET_GENDER_REQUEST,
	GET_GENDER_SUCCESS,
	GET_GENDER_FAIL,

	GET_PRACTICE_USER_REQUEST,
	GET_PRACTICE_USER_SUCCESS,
	GET_PRACTICE_USER_FAIL,

	GET_SUBSCRIBE_REQUEST,
	GET_SUBSCRIBE_SUCCESS,
	GET_SUBSCRIBE_FAILURE,

	GET_AUTHORIZED_USER_REQUEST,
	GET_AUTHORIZED_USER_SUCCESS,
	GET_AUTHORIZED_USER_FAIL,

	GET_AUTHORIZED_ALL_USERS_REQUEST,
	GET_AUTHORIZED_ALL_USERS_SUCCESS,
	GET_AUTHORIZED_ALL_USERS_FAIL,

	CANCLE_INVITE_AUTHORIZED_USER_REQUEST,
	CANCLE_INVITE_AUTHORIZED_USER_SUCCESS,
	CANCLE_INVITE_AUTHORIZED_USER_FAIL,

	GET_USER_PROFILE_INFO_REQUEST,
	GET_USER_PROFILE_INFO_SUCCESS,
	GET_USER_PROFILE_INFO_FAIL,


	GET_PRACTICE_OWNER_INFO_REQUEST,
	GET_PRACTICE_OWNER_INFO_SUCCESS,
	GET_PRACTICE_OWNER_INFO_FAIL,


	GET_PHONE_VERIFICATION_REQUEST,
	GET_PHONE_VERIFICATION_SUCCESS,
	GET_PHONE_VERIFICATION_FAIL,

	GET_USER_VERIFICATION_REQUEST,
	GET_USER_VERIFICATION_SUCCESS,
	GET_USER_VERIFICATION_FAIL,

	VERIFY_OTP_REQUEST,
	VERIFY_OTP_SUCCESS,
	VERIFY_OTP_FAIL,

	GET_DEFAULT_PROFILE_PIC_REQUEST,
	GET_DEFAULT_PROFILE_PIC_SUCCESS,
	GET_DEFAULT_PROFILE_PIC_FAIL,

	SIGNOUT_REQUEST,
	SIGNOUT_SUCCESS,
	SIGNOUT_FAIL,

	UPDATE_PRACTICE_PROFILE_REQUEST,
	UPDATE_PRACTICE_PROFILE_SUCCESS,
	UPDATE_PRACTICE_PROFILE_FAIL,

	UPDATE_PRACTICE_REQUEST,
	UPDATE_PRACTICE_SUCCESS,
	UPDATE_PRACTICE_FAIL,

	UPDATE_OWNER_PROFILE_REQUEST,
	UPDATE_OWNER_PROFILE_SUCCESS,
	UPDATE_OWNER_PROFILE_FAIL,

	GET_PRACTICE_USER_LOGIN_REQUEST,
	GET_PRACTICE_USER_LOGIN_SUCCESS,
	GET_PRACTICE_USER_LOGIN_FAIL,

	GET_PRACTICE_USER_LOGOUT_REQUEST,
	GET_PRACTICE_USER_LOGOUT_SUCCESS,
	GET_PRACTICE_USER_LOGOUT_FAIL,

	GET_BILLING_CARD_INFO_REQUEST,
	GET_BILLING_CARD_INFO_SUCCESS,
	GET_BILLING_CARD_INFO_FAIL,

	GET_DOMAIN_LIST_REQUEST,
	GET_DOMAIN_LIST_SUCCESS,
	GET_DOMAIN_LIST_FAIL,

	CREATE_DOMAIN_REQUEST,
	CREATE_DOMAIN_SUCCESS,
	CREATE_DOMAIN_FAIL,

	REMOVE_DOMAIN_REQUEST,
	REMOVE_DOMAIN_SUCCESS,
	REMOVE_DOMAIN_FAIL,

	UPDATE_DOMAIN_REQUEST,
	UPDATE_DOMAIN_SUCCESS,
	UPDATE_DOMAIN_FAIL,

	GET_SUBSCRIBE_INFO_REQUEST,
	GET_SUBSCRIBE_INFO_SUCCESS,
	GET_SUBSCRIBE_INFO_FAIL,


	GET_SUBSCRIBE_PRICE_OPTION_REQUEST,
	GET_SUBSCRIBE_PRICE_OPTION_SUCCESS,
	GET_SUBSCRIBE_PRICE_OPTION_FAIL,

	GET_SUBSCRIBE_PRICE_CALC_REQUEST,
	GET_SUBSCRIBE_PRICE_CALC_SUCCESS,
	GET_SUBSCRIBE_PRICE_CALC_FAIL,

	POST_SUBSCRIBE_REQUEST,
	POST_SUBSCRIBE_SUCCESS,
	POST_SUBSCRIBE_FAIL,

	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUEST,
	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUESTLOADING,
	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_SUCCESS,
	POST_SUBSCRIBE_ADDITIONAL_DOMAIN_FAIL,

	UPDATE_BILLING_CARD_INFO_REQUEST,
	UPDATE_BILLING_CARD_INFO_SUCCESS,
	UPDATE_BILLING_CARD_INFO_FAIL,

	CHANGE_BILLING_CARD_STATUS_REQUEST,
	CHANGE_BILLING_CARD_STATUS_SUCCESS,
	CHANGE_BILLING_CARD_STATUS_FAIL,

	ADD_BILLING_CARD_INFO_REQUEST,
	ADD_BILLING_CARD_INFO_SUCCESS,
	ADD_BILLING_CARD_INFO_FAIL,

	SET_UPLOAD_PROGRESS,
	SUCCESS_UPLOAD_FILE,
	FAILURE_UPLOAD_FILE,

	UPDATE_PASSCODE_REQUEST,
	UPDATE_PASSCODE_SUCCESS,
	UPDATE_PASSCODE_FAIL,

	PASSCODE_RECOVERY_REQUEST,
	PASSCODE_RECOVERY_SUCCESS,
	PASSCODE_RECOVERY_FAIL,

	UPDATE_USER_PROFILE_IMAGE_REQUEST,
	UPDATE_USER_PROFILE_IMAGE_SUCCESS,
	UPDATE_USER_PROFILE_IMAGE_FAIL,

	UPDATE_USER_PROFILE_REQUEST,
	UPDATE_USER_PROFILE_SUCCESS,
	UPDATE_USER_PROFILE_FAIL,

	INVITE_USER_TO_CTEATE_PROFILE_REQUEST,
	INVITE_USER_TO_CTEATE_PROFILE_SUCCESS,
	INVITE_USER_TO_CTEATE_PROFILE_FAIL,

	

	UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST,
	UPDATE_USER_PROFILE_ABOUT_INFO_SUCCESS,
	UPDATE_USER_PROFILE_ABOUT_INFO_FAIL,

	DELETE_USER_PROFILE_REQUEST,
	DELETE_USER_PROFILE_SUCCESS,
	DELETE_USER_PROFILE_FAIL,

	GET_USER_IDENTITY_REQUEST,
	GET_USER_IDENTITY_SUCCESS,
	GET_USER_IDENTITY_FAIL,

	GET_USER_PROFILE_VERIFY_REQUEST,
	GET_USER_PROFILE_VERIFY_SUCCESS,
	GET_USER_PROFILE_VERIFY_FAIL,

	DELETE_MULTY_USER_PROFILE_REQUEST,
	DELETE_MULTY_USER_PROFILE_SUCCESS,
	DELETE_MULTY_USER_PROFILE_FAIL,

	RESET_REFER_FREE_MONTH_REQUEST,
	POST_REFER_FREE_MONTH_REQUEST,
	POST_REFER_FREE_MONTH_SUCCESS,
	POST_REFER_FREE_MONTH_FAIL,

	GET_REFERRAL_LIST_REQUEST,
	GET_REFERRAL_LIST_SUCCESS,
	GET_REFERRAL_LIST_FAIL,

	SEND_PRACTICE_RECOVERY_CODE_REQUEST,
	SEND_PRACTICE_RECOVERY_CODE_SUCCESS,
	SEND_PRACTICE_RECOVERY_CODE_FAIL,

	GET_NOTIFICATION_REQUEST,
	GET_NOTIFICATION_SUCCESS,
	GET_NOTIFICATION_FAIL,

	UPDATE_SYSTEM_LANGUAGE_REQUEST,
	UPDATE_SYSTEM_LANGUAGE_SUCCESS,
	UPDATE_SYSTEM_LANGUAGE_FAIL,

	GET_ACCESS_CODE_REQUEST,
	GET_ACCESS_CODE_SUCCESS,
	GET_ACCESS_CODE_FAIL,

	RESET_ACCESS_CODE_REQUEST,
	RESET_ACCESS_CODE_SUCCESS,
	RESET_ACCESS_CODE_FAIL,

	WEBSITE_MEDIA_REQUEST,
	WEBSITE_MEDIA_SUCCESS,
	WEBSITE_MEDIA_FAILURE,

	GET_CHAIRSIDE_MEDIA_REQUEST,
	GET_CHAIRSIDE_MEDIA_SUCCESS,
	GET_CHAIRSIDE_MEDIA_FAILURE,
	POST_DUPLICATES_WITH_MEDIA_CATEGORIES,

	GET_CHAIRSIDE_CATEGORY_LIST_REQUEST,
	GET_CHAIRSIDE_CATEGORY_LIST_SUCCESS,
	GET_CHAIRSIDE_CATEGORY_LIST_FAILURE,

	GET_CHAIRSIDE_MEDIA_LANGUAGE_REQUEST,
	GET_CHAIRSIDE_MEDIA_LANGUAGE_SUCCESS,
	GET_CHAIRSIDE_MEDIA_LANGUAGE_FAILURE,

	POST_CHAIRSIDE_MEDIA_MYLIST_REQUEST,
	POST_CHAIRSIDE_MEDIA_MYLIST_SUCCESS,
	POST_CHAIRSIDE_MEDIA_MYLIST_FAILURE,

	GET_VIDEO_SECTION_REQUEST,
	GET_VIDEO_SECTION_SUCCESS,
	GET_VIDEO_SECTION_FAILURE,

	WEBSITE_MEDIA_CONFIG_REQUEST,
	WEBSITE_MEDIA_CONFIG_SUCCESS,
	WEBSITE_MEDIA_CONFIG_FAILURE,

	WEBSITE_MEDIA_LAYOUT_REQUEST,
	WEBSITE_MEDIA_LAYOUT_SUCCESS,
	WEBSITE_MEDIA_LAYOUT_FAILURE,

	WEBSITE_MEDIA_HTML_CODE_REQUEST,
	WEBSITE_MEDIA_HTML_CODE_SUCCESS,
	WEBSITE_MEDIA_HTML_CODE_FAILURE,

	WEBSITE_MEDIA_GET_PLAYLIST_DATA_REQUEST,
	WEBSITE_MEDIA_GET_PLAYLIST_DATA_SUCCESS,
	WEBSITE_MEDIA_GET_PLAYLIST_DATA_FAILURE,

	WEBSITE_MEDIA_PLAYLIST_POST_REQUEST,
	WEBSITE_MEDIA_PLAYLIST_POST_SUCCESS,
	WEBSITE_MEDIA_PLAYLIST_POST_FAILURE,

	WEBSITE_MEDIA_CONFIG_POST_REQUEST,
	WEBSITE_MEDIA_CONFIG_POST_SUCCESS,
	WEBSITE_MEDIA_CONFIG_POST_FAILURE,

	WEBSITE_MEDIA_DELETE_PLAYLIST_REQUEST,
	WEBSITE_MEDIA_DELETE_PLAYLIST_SUCCESS,
	WEBSITE_MEDIA_DELETE_PLAYLIST_FAILURE,
	POST_NEW_LOCATION_REQUEST,
	POST_NEW_LOCATION_SUCCESS,
	POST_NEW_LOCATION_FAIL,

	POST_LOGIN_PRACTICE_LOCATION_REQUEST,
	POST_LOGIN_PRACTICE_LOCATION_SUCCESS,
	POST_LOGIN_PRACTICE_LOCATION_FAIL,

	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAILURE,

	GET_HELPER_TIMEZONE_REQUEST,
	GET_HELPER_TIMEZONE_SUCCESS,
	GET_HELPER_TIMEZONE_FAILURE,

	GET_INVOICEVIEW_REQUEST,
	GET_INVOICEVIEW_SUCCESS,
	GET_INVOICEVIEW_FAILURE,

	GET_AUTHORIZED_DEVICE_REQUEST,
	GET_AUTHORIZED_DEVICE_SUCCESS,
	GET_AUTHORIZED_DEVICE_FAILURE,

	POST_UNAUTHORIZED_DEVICE_REQUEST,
	POST_UNAUTHORIZED_DEVICE_SUCCESS,
	POST_UNAUTHORIZED_DEVICE_FAIL,

	GET_OPTION_REQUEST,
	GET_OPTION_SUCCESS,
	GET_OPTION_FAILURE,

	POST_SUBSCRIBE_DEVICE_REQUEST,
	POST_SUBSCRIBE_DEVICE_SUCCESS,
	POST_SUBSCRIBE_DEVICE_FAILURE,

	GET_EMAIL_PREFERENCES_REQUEST,
	GET_EMAIL_PREFERENCES_SUCCESS,
	GET_EMAIL_PREFERENCES_FAILURE,

	POST_EMAIL_PREFERENCES_REQUEST,
	POST_EMAIL_PREFERENCES_SUCCESS,
	POST_EMAIL_PREFERENCES_FAILURE,

	GET_DEFAULT_CAPTION_SIZE_REQUEST,
	GET_DEFAULT_CAPTION_SIZE_SUCCESS,
	GET_DEFAULT_CAPTION_SIZE_FAIL,

	GET_EMAIL_PREFERENCES_LIST_REQUEST,
	GET_EMAIL_PREFERENCES_LIST_SUCCESS,
	GET_EMAIL_PREFERENCES_LIST_FAILURE,

	REMOVE_AUTHORIZED_DEVICE_REQUEST,
	REMOVE_AUTHORIZED_DEVICE_SUCCESS,
	REMOVE_AUTHORIZED_DEVICE_FAILURE,

	GET_WAITING_ROOM_CHANNEL_TRIVIA_REQUEST,
	GET_WAITING_ROOM_CHANNEL_TRIVIA_SUCCESS,
	GET_WAITING_ROOM_CHANNEL_TRIVIA_FAILURE,

	GET_WAITINGROOM_LIVE_WEATHER_DATA_REQUEST,
	GET_WAITINGROOM_LIVE_WEATHER_DATA_SUCCESS,
	GET_WAITINGROOM_LIVE_WEATHER_DATA_FAILURE,

	GET_WAITINGROOM_FOOTER_TIME_REQUEST,
	GET_WAITINGROOM_FOOTER_TIME_SUCCESS,
	GET_WAITINGROOM_FOOTER_TIME_FAILURE,

	GET_FOOTER_CUSTOM_MESSAGES_REQUEST,
	GET_FOOTER_CUSTOM_MESSAGES_SUCCESS,
	GET_FOOTER_CUSTOM_MESSAGES_FAILURE,

	POST_WAITINGROOM_MEDIA_HEADER_REQUEST,
	POST_WAITINGROOM_MEDIA_HEADER_SUCCESS,
	POST_WAITINGROOM_MEDIA_HEADER_FAILURE,

	GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_REQUEST,
	GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_SUCCESS,
	GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_FAILURE,

	GET_WAITINGROOM_MEDIA_CHANNEL_REQUEST,
	GET_WAITINGROOM_MEDIA_CHANNEL_SUCCESS,
	GET_WAITINGROOM_MEDIA_CHANNEL_FAILURE,

	RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST,
	RESTORE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS,
	RESTORE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE,

	DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST,
	DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS,
	DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE,

	POST_WAITINGROOM_MEDIA_WEATHER_REQUEST,
	POST_WAITINGROOM_MEDIA_WEATHER_SUCCESS,
	POST_WAITINGROOM_MEDIA_WEATHER_FAILURE,

	POST_CHANNELWISE_CUSTOM_MESSAGES_REQUEST,
	POST_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS,
	POST_CHANNELWISE_CUSTOM_MESSAGES_FAILURE,

	DELETE_WAITINGROOM_CUSTOM_MESSAGES_REQUEST,
	DELETE_WAITINGROOM_CUSTOM_MESSAGES_SUCCESS,
	DELETE_WAITINGROOM_CUSTOM_MESSAGES_FAILURE,

	POST_WAITINGROOM_MEDIA_FOOTER_TIME_REQUEST,
	POST_WAITINGROOM_MEDIA_FOOTER_TIME_SUCCESS,
	POST_WAITINGROOM_MEDIA_FOOTER_TIME_FAILURE,

	GET_WAITINGROOM_WEATHER_REQUEST,
	GET_WAITINGROOM_WEATHER_SUCCESS,
	GET_WAITINGROOM_WEATHER_FAILURE,

	GET_LOCATION_LISTING_REQUEST,
	GET_LOCATION_LISTING_SUCCESS,
	GET_LOCATION_LISTING_FAILURE,

	GET_LOCATION_MANAGER_LISTING_REQUEST,
	GET_LOCATION_MANAGER_LISTING_SUCCESS,
	GET_LOCATION_MANAGER_LISTING_FAILURE,


	GET_WEBSITE_MEDIA_LAYOUTS_REQUEST,
	GET_WEBSITE_MEDIA_LAYOUTS_SUCCESS,
	GET_WEBSITE_MEDIA_LAYOUTS_FAILURE,

	GET_WEBSITE_MEDIA_COLOURS_REQUEST,
	GET_WEBSITE_MEDIA_COLOURS_SUCCESS,
	GET_WEBSITE_MEDIA_COLOURS_FAILURE,

	GET_HELPER_NEWSFEED_REQUEST,
	GET_HELPER_NEWSFEED_SUCCESS,
	GET_HELPER_NEWSFEED_FAILURE,

	POST_NEWSFEED_REQUEST,
	POST_NEWSFEED_SUCCESS,
	POST_NEWSFEED_FAILURE,

	GET_WAITINGROOM_DISPLAY_OPTIONS_REQUEST,
	GET_WAITINGROOM_DISPLAY_OPTIONS_SUCCESS,
	GET_WAITINGROOM_DISPLAY_OPTIONS_FAILURE,

	GET_WAITINGROOM_THEME_LIST_REQUEST,
	GET_WAITINGROOM_THEME_LIST_SUCCESS,
	GET_WAITINGROOM_THEME_LIST_FAILURE,

	DELETE_WAITINGROOM_MEDIA_CHANNEL_REQUEST,
	DELETE_WAITINGROOM_MEDIA_CHANNEL_SUCCESS,
	DELETE_WAITINGROOM_MEDIA_CHANNEL_FAILURE,

	MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_REQUEST,
	MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_SUCCESS,
	MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_FAILURE,

	UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_REQUEST,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_LOADING_REQUEST,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_SUCCESS,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_FAILURE,

	CREATE_WAITING_ROOM_CHANNEL_REQUEST,
	CREATE_WAITING_ROOM_CHANNEL_SUCCESS,
	CREATE_WAITING_ROOM_CHANNEL_FAILURE,

	UPDATE_WAITING_ROOM_CHANNEL_REQUEST,
	UPDATE_WAITING_ROOM_CHANNEL_SUCCESS,
	UPDATE_WAITING_ROOM_CHANNEL_FAILURE,

	POST_UPDATE_WAITING_ROOM_CHANNEL_REQUEST,
	POST_UPDATE_WAITING_ROOM_CHANNEL_SUCCESS,
	POST_UPDATE_WAITING_ROOM_CHANNEL_FAILURE,

	ADD_LOCATION_REQUEST,
	ADD_LOCATION_SUCCESS,
	ADD_LOCATION_FAILURE,

	SEND_MANAGER_INVITE_REQUEST,
	SEND_MANAGER_INVITE_SUCCESS,
	SEND_MANAGER_INVITE_FAILURE,

	PUT_CHANNELWISE_CUSTOM_MESSAGES_REQUEST,
	PUT_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS,
	PUT_CHANNELWISE_CUSTOM_MESSAGES_FAILURE,
	NEW_WAITING_ROOM_TIMELINE_EDUCATION_REQUEST,
	NEW_WAITING_ROOM_TIMELINE_EDUCATION_SUCCESS,
	NEW_WAITING_ROOM_TIMELINE_EDUCATION_FAILURE,
	NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_REQUEST,
	NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_SUCCESS,
	NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_FAILURE,
	POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST,
	POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_SUCCESS,
	POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_FAILURE,

	UNASSIGN_MANAGER_REQUEST,
	UNASSIGN_MANAGER_SUCCESS,
	UNASSIGN_MANAGER_FAILURE,
	NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
	REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
	GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_REQUEST,
	GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_SUCCESS,
	GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_FAILURE,
	GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_REQUEST,
	GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_SUCCESS,
	GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_FAILURE,
	GET_MYVIDEOS_CATEGORY_LIST_REQUEST,
	GET_MYVIDEOS_CATEGORY_LIST_SUCCESS,
	GET_MYVIDEOS_CATEGORY_LIST_FAILURE,
	UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_REQUEST,
	UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_SUCCESS,
	UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_FAILURE,
	POST_REQUEST_PRACTICE_VIDEO_REQUEST,
	POST_REQUEST_PRACTICE_VIDEO_SUCCESS,
	POST_REQUEST_PRACTICE_VIDEO_FAILURE,

	GET_INVOICE_EMAIL_REQUEST,
	GET_INVOICE_EMAIL_SUCCESS,
	GET_INVOICE_EMAIL_FAILURE,

	POST_INVOICE_EMAIL_REQUEST,
	POST_INVOICE_EMAIL_SUCCESS,
	POST_INVOICE_EMAIL_FAILURE,

	WAITING_ROOM_CHANNEL_SLIDE_REQUEST,
	WAITING_ROOM_CHANNEL_SLIDE_SUCCESS,
	WAITING_ROOM_CHANNEL_SLIDE_FAILURE,
	GET_WEBSITE_MEDIA_CONFIG_REQUEST,
	GET_WEBSITE_MEDIA_CONFIG_SUCCESS,
	GET_WEBSITE_MEDIA_CONFIG_FAILURE,
	POST_WEBSITE_MEDIA_CONFIG_REQUEST,
	POST_WEBSITE_MEDIA_CONFIG_SUCCESS,
	POST_WEBSITE_MEDIA_CONFIG_FAILURE,
	GET_WEBSITE_MEDIA_HTML_CODE_REQUEST,
	GET_WEBSITE_MEDIA_HTML_CODE_SUCCESS,
	GET_WEBSITE_MEDIA_HTML_CODE_FAILURE,

	/* add by milep */
	PUBLISH_WEBSITE_MEDIA_REQUEST,
	PUBLISH_WEBSITE_MEDIA_SUCCESS,
	PUBLISH_WEBSITE_MEDIA_FAILURE,

	/* add by milep  end*/

	GET_PRACTICE_REQUEST,
	GET_PRACTICE_SUCCESS,
	GET_PRACTICE_FAIL,

	GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_SUCCESS,
	GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_FAILURE,
	GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_REQUEST,
	GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_SUCCESS,
	GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_FAILURE,

	UPDATE_AUTHORIZED_USER_REQUEST,
	UPDATE_AUTHORIZED_USER_SUCCESS,
	UPDATE_AUTHORIZED_USER_FAIL,

	UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	GET_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST,
	GET_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS,
	GET_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE,

	SET_WAITING_ROOM_TIMELINE_NEW_SLIDE,
	// SET_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS,
	// SET_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE,

	CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_REQUEST,
	CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS,
	CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE,

	REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST,
	REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS,
	REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE,

	ADD_SOCIAL_MEDIA_REQUEST,
	ADD_SOCIAL_MEDIA_SUCCESS,
	ADD_SOCIAL_MEDIA_FAILURE,

	GET_SOCIAL_REQUEST,
	GET_SOCIAL_SUCCESS,
	GET_SOCIAL_FAILURE,

	REMOVE_SOCIAL_REQUEST,
	REMOVE_SOCIAL_SUCCESS,
	REMOVE_SOCIAL_FAILURE,

	POST_TIMELINE_REQUEST,
	POST_TIMELINE_SUCCESS,
	POST_TIMELINE_FAILURE,
	GET_TIMELINE_REQUEST,
	GET_TIMELINE_SUCCESS,
	GET_TIMELINE_FAILURE,

	GET_USER_PROFILE_REQUEST,
	GET_USER_PROFILE_SUCCESS,
	GET_USER_PROFILE_FAIL,

	GET_OPERATORY_VIDEO_REQUEST,
	GET_OPERATORY_VIDEO_SUCCESS,
	GET_OPERATORY_VIDEO_FAILURE,

	// Added by Dixit Solanki ( work releted operatory chanel)

	// Create operatory Channel
	CREATE_OPERATORY_CHANNEL_REQUEST,
	CREATE_OPERATORY_CHANNEL_SUCCESS,
	CREATE_OPERATORY_CHANNEL_FAILURE,

	// get operatory channel list
	GET_OPERATORY_MEDIA_CHANNEL_LIST_REQUEST,
	GET_OPERATORY_MEDIA_CHANNEL_LIST_SUCCESS,
	GET_OPERATORY_MEDIA_CHANNEL_LIST_FAILURE,

	// delete operatory Channel
	DELETE_OPERATORY_CHANNEL_REQUEST,
	DELETE_OPERATORY_CHANNEL_SUCCESS,
	DELETE_OPERATORY_CHANNEL_FAILURE,

	// duplicate operatory Channel
	DUPLICATE_OPERATORY_CHANNEL_REQUEST,
	DUPLICATE_OPERATORY_CHANNEL_SUCCESS,
	DUPLICATE_OPERATORY_CHANNEL_FAILURE,

	//  get operatory info Channel
	GET_OPERATORY_CHANNEL_REQUEST,
	GET_OPERATORY_CHANNEL_SUCCESS,
	GET_OPERATORY_CHANNEL_FAILURE,

	//  restore operatory info Channel
	RESTORE_OPERATORY_CHANNEL_REQUEST,
	RESTORE_OPERATORY_CHANNEL_SUCCESS,
	RESTORE_OPERATORY_CHANNEL_FAILURE,

	// update  operatory Channel Rename
	UPDATE_OPERATORY_CHANNEL_NAME_REQUEST,
	UPDATE_OPERATORY_CHANNEL_NAME_SUCCESS,
	UPDATE_OPERATORY_CHANNEL_NAME_FAILURE,

	// get Theme operatory Channel
	GET_OPERATORY_THEME_LIST_REQUEST,
	GET_OPERATORY_THEME_LIST_SUCCESS,
	GET_OPERATORY_THEME_LIST_FAILURE,

	// get options operatory Channel
	GET_OPERATORY_DISPLAY_OPTIONS_REQUEST,
	GET_OPERATORY_DISPLAY_OPTIONS_SUCCESS,
	GET_OPERATORY_DISPLAY_OPTIONS_FAILURE,

	// get whether operatory Channel
	GET_OPERATORY_LIVE_WEATHER_DATA_REQUEST,
	GET_OPERATORY_LIVE_WEATHER_DATA_SUCCESS,
	GET_OPERATORY_LIVE_WEATHER_DATA_FAILURE,

	// update options operatory Channel
	POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_REQUEST,
	POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_SUCCESS,
	POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_FAILURE,
	// end by Dixit Solanki ( work releted operatory chanel)


	POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
	POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
	POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,

	DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
	DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
	DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,

	GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
	GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
	GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,

	POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_REQUEST,
	POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_FAILURE,
	POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_SUCCESS,

	GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_REQUEST,
	GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_SUCCESS,
	GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_FAILURE,
	ADD_CATEGORY_SPECIALIZATION,
	CHAIRSIDE_RESET_DEFAULT,


	/* add by milap */
	GET_CHAIRSIDE_MEDIA_CC_REQUEST,
	GET_CHAIRSIDE_MEDIA_CC_SUCCESS,
	GET_CHAIRSIDE_MEDIA_CC_FAILURE,

	UPDATE_CHAIRSIDE_MEDIA_CC_REQUEST,
	UPDATE_CHAIRSIDE_MEDIA_CC_SUCCESS,
	UPDATE_CHAIRSIDE_MEDIA_CC_FAILURE,

	GET_CHAIRSIDE_MEDIA_CC_LIST_REQUEST,
	GET_CHAIRSIDE_MEDIA_CC_LIST_SUCCESS,
	GET_CHAIRSIDE_MEDIA_CC_LIST_FAILURE,

	SET_SELECTED_SPECIALIZATION_CATEGORY,

	DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST,
	DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS,
	DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE,

	POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST,
	POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS,
	POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE,

	DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST,
	DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS,
	DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE,

	POST_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST,
	POST_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS,
	POST_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE,
	/* end */

	// Chair Side Video Editor
	SEND_SCREENSHOT_REQUEST,
	SEND_SCREENSHOT__RESET_REQUEST,
	SEND_SCREENSHOT_SUCCESS,
	SEND_SCREENSHOT_FAILURE,

	GET_CUSTOM_MESSAGES_REQUEST,
	GET_CUSTOM_MESSAGES_SUCCESS,
	GET_CUSTOM_MESSAGES_FAILURE,

	POST_CUSTOM_MESSAGES_REQUEST,
	POST_CUSTOM_MESSAGES_SUCCESS,
	POST_CUSTOM_MESSAGES_FAILURE,

	RESET_CUSTOM_MESSAGES_REQUEST,
	RESET_CUSTOM_MESSAGES_SUCCESS,
	RESET_CUSTOM_MESSAGES_FAILURE,

	// search schreenshot
	GET_SCREENSHOT_LIST_REQUEST,
	GET_SCREENSHOT_LIST_SUCCESS,
	GET_SCREENSHOT_LIST_FAILURE,

	//  Opeatory Shedule
	CREATE_OPERATORY_SCHEDULE_REQUEST,
	CREATE_OPERATORY_SCHEDULE_SUCCESS,
	CREATE_OPERATORY_SCHEDULE_FAILURE,

	//  Waiting Room Shedule
	CREATE_WAITING_ROOM_SCHEDULE_REQUEST,
	CREATE_WAITING_ROOM_SCHEDULE_SUCCESS,
	CREATE_WAITING_ROOM_SCHEDULE_FAILURE,

	GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_REQUEST,
	GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_SUCCESS,
	GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_FAILURE,

	/*add by milap waiting Room schedule*/
	GET_WAITING_ROOM_SCHEDULE_REQUEST,
	GET_WAITING_ROOM_SCHEDULE_SUCCESS,
	GET_WAITING_ROOM_SCHEDULE_FAILURE,

	GET_WAITINGROOM_FOOTER_WEATHER_DATA_REQUEST,
	GET_WAITINGROOM_FOOTER_WEATHER_DATA_SUCCESS,
	GET_WAITINGROOM_FOOTER_WEATHER_DATA_FAILURE,

	UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_CC_STATUS_SUCCESS,
	UPDATE_OPEROTORY_MEDIA_CHANNEL_CC_STATUS_SUCCESS,
	UPDATE_OPERATORYGROOM_MEDIA_CHANNEL_LOADING_REQUEST,

	PUBLISH_WAITINGROOM_TIMELINE_REQUEST,
	PUBLISH_WAITINGROOM_TIMELINE_SUCCESS,
	PUBLISH_WAITINGROOM_TIMELINE_FAILURE,
	PUBLISH_WAITINGROOM_TIMELINE_NULL,


	UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
	REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,

	GET_PRACTICE_PAYMENT_INFO_REQUEST,
	GET_PRACTICE_PAYMENT_INFO_SUCCESS,
	GET_PRACTICE_PAYMENT_INFO_FAIL,

	POST_PRACTICE_PAYMENT_INFO_REQUEST,
	POST_PRACTICE_PAYMENT_INFO_SUCCESS,
	POST_PRACTICE_PAYMENT_INFO_FAIL,

	GET_MEDIA_CATEGORY_IMAGES_REQUEST,
	GET_MEDIA_CATEGORY_IMAGES_SUCCESS,
	GET_MEDIA_CATEGORY_IMAGES_FAIL,

	POST_TOS_COMPLAINT_REQUEST,
	POST_TOS_COMPLAINT_SUCCESS,
	POST_TOS_COMPLAINT_FAIL,
	REINVITE_USER_TO_CTEATE_PROFILE_REQUEST,
	REINVITE_USER_TO_CTEATE_PROFILE_SUCCESS,
	REINVITE_USER_TO_CTEATE_PROFILE_FAIL,

	GET_CHAIRSIDE_MEDIA__RECENT_PLAY_REQUEST,
	GET_CHAIRSIDE_MEDIA__RECENT_PLAY_SUCCESS,
	GET_CHAIRSIDE_MEDIA__RECENT_PLAY_FAILURE,

	POST_PRACTICE_CONTACTUS_REQUEST,
	POST_PRACTICE_CONTACTUS_SUCCESS,
	POST_PRACTICE_CONTACTUS_FAILURE,

	RESET_DEFAULT_WEBSITE_VIDEO_REQUEST,
	RESET_DEFAULT_WEBSITE_VIDEO_SUCCESS,
	RESET_DEFAULT_WEBSITE_VIDEO_FAILURE,
	EXTEND_TRIAL_REQUEST,
	EXTEND_TRIAL_SUCCESS,
	EXTEND_TRIAL_FAILURE,
  
	SEND_EMBED_CODE_TO_EMAIL_REQUEST,
	SEND_EMBED_CODE_TO_EMAIL_FAIL,
	SEND_EMBED_CODE_TO_EMAIL_SUCCESS,

	GET_DESIGNATE_CARD_SUCCESS,
	GET_DESIGNATE_CARD_REQUEST,
	GET_DESIGNATE_CARD_FAILURE,

	CHECK_PRACTIC_IS_CORPORATE,
	SELECTED_CARD,
	GET_DESIGNATE_CARD_MULTIPLE_SUCCESS,
	GET_DESIGNATE_CARD_MULTIPLE_FAILURE,
	GET_DESIGNATE_CARD_MULTIPLE_REQUEST,

	GET_CORPORAT_HARDWARE_CALC_REQUEST,
	GET_CORPORAT_HARDWARE_CALC_SUCCESS,
	GET_CORPORAT_HARDWARE_CALC_FAILURE,

	GET_CORPORAT_HARDWARE_LIST_REQUEST,
	GET_CORPORAT_HARDWARE_LIST_SUCCESS,
	GET_CORPORAT_HARDWARE_LIST_FAILURE,

	GET_CORPORAT_PRACTICES_LIST_REQUEST,
	GET_CORPORAT_PRACTICES_LIST_SUCCESS,
	GET_CORPORAT_PRACTICES_LIST_FAILURE,

	PURCHASE_CORPORAT_HARDWARE_REQUEST,
	PURCHASE_CORPORAT_HARDWARE_SUCCESS,
	PURCHASE_CORPORAT_HARDWARE_FAILURE,
	POST_CORPORATE_MULTIPLE_CARD_REQUEST,
	POST_CORPORATE_MULTIPLE_CARD_SUCCESS,
	POST_CORPORATE_MULTIPLE_CARD_FAILURE,
	GET_UNASSIGN_LOCATION_LISTING_SUCCESS,
	UPDATE_SELECTED_CARD,
	SHOW_ERROR,
	UPDATE_HARDWARE_TOTAL,
	SAME_AS_MAIN_PRACTICE,
	SAME_AS_MAIN_PRACTICE_REQUEST,
	SAME_AS_MAIN_PRACTICE_SUCCESS,
	SAME_AS_MAIN_PRACTICE_FAIL,
	UPDATE_NEW_LOCATION_PRACTICE_REQUEST,
	ADD_NEW_LOCATION_PRACTICE_REQUEST,
	GET_SHARE_VIDEO_LINK_REQUEST,
	GET_SHARE_VIDEO_LINK_SUCCESS,
	GET_SHARE_VIDEO_LINK_FAILURE,
	SHARE_VIDEO_LINK_REQUEST,
	SHARE_VIDEO_LINK_SUCCESS,
	SHARE_VIDEO_LINK_FAILURE,
	SHARE_VIDEO_LINK_CLEAR_REQUEST,
	SHARE_VIDEO_LINK_ID_REQUEST,
	SET_GOOGLE_TV_TEST_DRIVE_ON_FAIL,
	SET_GOOGLE_TV_TEST_DRIVE_ON_SUCCESS,
	SET_GOOGLE_TV_TEST_DRIVE_ON_REQUEST,
	SET_GOOGLE_TV_TEST_DRIVE_MENU_ITEM,
	SET_GOOGLE_TV_TEST_DRIVE_CAT_IS_OPEN,
	GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_REQUEST,
	GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_SUCCESS,
	GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_FAILURE,
	CREATE_CHAIRSIDE_PLAYLIST_FAILURE,
	CREATE_CHAIRSIDE_PLAYLIST_SUCCESS,
	CREATE_CHAIRSIDE_PLAYLIST_REQUEST,
	GET_CHAIRSIDE_PLAYLIST_REQUEST,
	GET_CHAIRSIDE_PLAYLIST_SUCCESS,
	GET_CHAIRSIDE_PLAYLIST_FAILURE,
	GET_SINGLE_CHAIRSIDE_PLAYLIST_REQUEST,
	GET_SINGLE_CHAIRSIDE_PLAYLIST_SUCCESS,
	GET_SINGLE_CHAIRSIDE_PLAYLIST_FAILURE,
	UPDATE_CHAIRSIDE_PLAYLIST_REQUEST,
	UPDATE_CHAIRSIDE_PLAYLIST_SUCCESS,
	UPDATE_CHAIRSIDE_PLAYLIST_FAILURE,
	GET_CHAIRSIDE_PLAY_VIDEO_REQUEST,
	GET_CHAIRSIDE_PLAY_VIDEO_SUCCESS,
	GET_CHAIRSIDE_PLAY_VIDEO_FAILURE,
	PUBLISH_CHAIRSIDE_PLAYLIST_REQUEST,
	PUBLISH_CHAIRSIDE_PLAYLIST_SUCCESS,
	PUBLISH_CHAIRSIDE_PLAYLIST_FAILURE,
	REMOVE_CHAIRSIDE_PLAYLIST_REQUEST,
	REMOVE_CHAIRSIDE_PLAYLIST_SUCCESS,
	REMOVE_CHAIRSIDE_PLAYLIST_FAILURE,
	DUPLICATE_CHAIRSIDE_PLAYLIST_REQUEST,
	DUPLICATE_CHAIRSIDE_PLAYLIST_SUCCESS,
	DUPLICATE_CHAIRSIDE_PLAYLIST_FAILURE,
	PUBLISH_CHAIRSIDE_PLAYLIST_RESET_MESSAGE,
	SET_CHAIRSIDE_PLAYLIST_MENU_ACTIVE,
	SET_CHAIRSIDE_PLAYLIST_VIDEO_INDEX,
	DEFAULT_PROFILE_REQUEST,
	DEFAULT_PROFILE_SUCCESS,
	DEFAULT_PROFILE_FAILURE,
	SET_UPGRADE_DOWNGRADE,

} from './actions';

import HttpUtility, {
	PRACTICELOGIN,
	PRACTICE,
	PRACTICE_DEVICE,
	PRACTICERECOVERCODE,
	PRACTICERECOVERCODEBYPRACTICE,
	PRACTICE_USER_LOGIN,
	PRACTICE_USER_LOGOUT,
	TOSLATEST,
	TOSACCEPT,
	PRACTICEPROFILE,
	SUBSCRIBE,
	CLOSE,
	SIGNIN,
	TYPE_SPECIALIZATION,
	PRACTICEUSER,
	PRACTICEUSERPROFILE,
	AUTHORIZED_DEVICE,
	CREATE_NEW_PRACTICEUSER,
	TYPE_GENDER,
	TYPE_OWNERSHIP,
	TYPE_COUNTRY,
	TYPE_PRACTICE_SECURITYQUE,
	TYPE_LEAD_SOURCE,
	TYPE_PRACTICE_SOFTWARES,
	TYPE_PRACTICE_CUSROMER_TYPES,
	TYPE_PREACTICEUSER,
	TYPE_DEFAULT_CAPTION_FONT_SIZE,
	PHONE_VERIFICATION,
	PRACTICE_USER_PROFILE_VERIFY,
	VERIFYED_OTP,
	PRACTICE_PROFILE_VERIFY,
	DEFAULT_PROFILE_PICTURE,
	TYPE_PRACTICE_USER_ROLES,
	TYPE_SUBSCRIPTION_TYPES,
	TYPE_MEDIA_LANGUAGE,
	TYPE_SYSTEM_LANGUAGE,
	TYPE_CC_CLOSE_CAPTION_SECTION,
	PRACTICESETUP,
	PRACTICE_PROFILE_CHANGE_PASSWORD,
	UPDATE_PRACTICEUSER,
	PRACTICEPROFILE_IDENTIFY,
	GET_PRACTICE_NOTIFICATION,
	TYPE_EMAIL_PREFERENCES_lIST,
	SELECTED_EMAIL_PREFERENCES,
	WEBSITE_MEDIA,
	GET_CHAIRSIDE_MEDIA,
	GET_CHAIRSIDE_MEDIA_CATEGORY_LIST,
	GET_CHAIRSIDE_MEDIA_LANGUAGE,
	POST_CHAIRSIDE_MEDIA_MYLIST,
	WEBSITE_MEDIA_LAYOUT,
	WEBSITE_MEDIA_HTML,
	WEBSITE_MEDIA_GET_PLAYLIST_DATA,
	WEBSITE_MEDIA_DELETE_PLAYLIST,
	INVOICE,
	INVOICEVIEW,
	UNAUTHORIZED_DEVICE,
	HELPER_TIMEZONE,
	GET_CHANNELWISE_CUSTOM_MESSAGES,
	GET_WAITINGROOM_CHANNEL_TRIVIA,
	GET_WAITINGROOM_LIVE_WEATHER_DATA,
	GET_WAITINGROOM_LIVE_FOOTER_TIME,
	POST_WAITING_ROOM_HEADER,
	GET_WEBSITEROOM_MEDIA_CHANNEL,
	WAITINGROOM_FOOTER_WEATHER,
	POST_CHANNELWISE_CUSTOM_MESSAGES,
	DELETE_CHANNELWISE_CUSTOM_MESSAGES,
	GET_WAITINGROOM_FOOTER_WEATHER,
	GET_OWNER_LOCATIONS,
	GET_OWNER_LOCATIONS_MANAGER,
	GET_WEBSITE_MEDIA_LAYOUTS,
	GET_WEBSITE_MEDIA_COLOURS,
	GET_HELPER_NEWSFEED,
	GET_WAITINGROOM_DISPLAY_OPTIONS,
	DELETE_WAITINGROOM_MEDIA_CHANNEL,
	UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME,
	CREATE_NEW_WAITING_ROOM_CHANNEL,
	POST_OWNER_LOCATION,
	PUT_CHANNELWISE_CUSTOM_MESSAGES,
	NEW_WAITING_ROOM_TIMELINE_EDUCATION,
	NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT,
	HARDWARELIST,
	NEW_WAITING_ROOM_TIMELINE_MYVIDEOS,
	REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS,
	SLIDES_STOCK_LIBRARY_WAITINGROOM,
	SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM,
	GET_MYVIDEOS_CATEGORY_LIST,
	UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE,
	REQUEST_PRACTICE_VIDEO,
	WAITING_ROOM_CHANNEL_SLIDE,
	WEBSITE_MEDIA_CONFIG,
	WEBSITE_MEDIA_GET_HTML,
	WAITING_ROOM_MEDIA,
	OPERATORY_ROOM_MEDIA,
	WAITINGROOMSLIDESHTMLTEMPLATE,
	WAITINGROOMSLIDESSTOCKIMAGE,
	CREATEWAITINGROOMNEWSLIDE,
	/* added by milap for watingroom channel*/


	/* added by milap for watingroom channel*/
	WAITINGROOM_CHANNEL,
	WAITINGROOM_CHANNEL_THEME_LIST,
	GET_WAITINGROOM_CHANNEL_FOOTER_TIME,
	GET_WAITINGROOM_CHANNEL_FOOTER_WEATHER,
	GET_WAITINGROOM_CHANNEL_LIVE_WEATHER_DATA,
	GET_WAITINGROOM_CHANNEL_SCHEDULE,
	ADD_SOCIAL_MEDIA,
	GET_SOCIAL_MEDIA,
	SOCIAL_MEDIA,
	OPERATORY_ROOM_VIDEO,
	WEBSITE_MEDIA_PUBLISH,



	/* added by milap for watingroom channel end */

	/* added by dixit solanki for operatory channel*/
	OPERATORY_CHANNEL,
	CREATE_NEW_OPERATORY_CHANNEL,
	OPERATORY_CHANNEL_THEME_LIST,
	GET_OPERATORY_DISPLAY_OPTIONS,
	/* added by dixit for operatory channel end */
	SLIDE_IMAGE_UPLOAD,


	POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST,
	GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY,

	// for operatory Schedule post
	SAVE_OPERATORY_SCHEDULE,
	SAVE_WAITING_ROOM_SCHEDULE,
	WAITINGROOMSLIDESBACKGROUNDIMAGE,
	NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS,

	TYPE_MEDIA_CATEGORY_IMAGE,

	TOSCOMPLAINT,
	PRACTICE_CONTACTUS,
	CORPORATE,
	CORPORATE_WAITING_ROOM_CHANNEL,
	CORPORATE_OPERATORY_ROOM_CHANNEL
	
} from '../../utilities/HttpUtility';

import { history } from '../../pages/index';
import { param } from 'jquery';
import { FBPROVIDER, ISOPERATORYROOMCHANNELIMAGE, ISWAITINGROOMCHANNELIMAGE, GOOGLEAPIKEY, OPERATORYMYVIDEO } from '../../data/comman';
import { handleDuplicates } from '../../helper/commonHelper';

const getuserHeaders = () => {
	var userheaders = {};
	userheaders['Content-Type'] = 'application/json';
	userheaders['Accept'] = 'application/json';
	var accessToken = getToken();
	if (accessToken !== '') {
		userheaders['Authorization'] = accessToken;
	}
	return userheaders;
};

export const UpdatePracticeInfoRequest = (practiceInfo) => {
	return {
		type: UPDATE_PRACTICEINFO_REQUEST,
		practiceInfo: practiceInfo,
	};
};

export const CheckPracticeisCorporate = (isCorporate) => {
	return {
		type: CHECK_PRACTIC_IS_CORPORATE,
		isCorporate: isCorporate,
	};
};

export const PracticeLogin = (params) => {
	return async (dispatch) => {

		dispatch({ type: GET_PRACTICEINFO_REQUEST });

		try {
			await HttpUtility.postRequest(PRACTICELOGIN, params)
				.then((response) => {
					if (response.status == 200) {
						const practiceInfo = response.data;
						dispatch({
							type: GET_PRACTICEINFO_SUCCESS,
							practiceInfo: practiceInfo,
						});
						cleartoken();
						if (practiceInfo.is_internal_user != 1){
							dispatch(GetPracticeMoreInfo());
							dispatch(GetpracticeProfile());
							dispatch(GetpracticeUser());
						}
						if (practiceInfo.is_authorized_device === 0) {
							history.push(CLOSE);
						}else if (practiceInfo.is_internal_user === 1) {
							var token = practiceInfo.auth_info.token_info.token_type + ' ' + practiceInfo.auth_info.token_info.access_token
							setToken(`${token}`);
							history.push('/channels');
						}else if (practiceInfo.is_account_inactive === 1) {
							history.push('/inactive');
						}else if(practiceInfo.status === 500 ){
							history.push('/accountSuspended');
						}else if(practiceInfo.status === 600) {
							history.push('/accountCancelled');
						}
						else if (practiceInfo.has_new_tos === 1) {
							history.push('/tos');
						} else if(practiceInfo.status == 800 || practiceInfo.status == 900){
							history.push('/welcome-to-thalamus');
							// welcome to thalamus
						}else if (practiceInfo.is_paid_account === 0  && practiceInfo.status != 900) { //&& practiceInfo.status !== 800
							history.push('/trial'); // 
						}else if(practiceInfo.need_profile_setup === 1 && practiceInfo.status == 900){
							//you have recive trial continue > initialsetup

						}
						else if (practiceInfo.need_profile_setup === 1) {
							history.push('/initialsetup');
						} else if (practiceInfo.need_owner_setup === 1) {
							history.push('/edit-authorized-profile');
						}else {
							history.push('/profileselection');
						}
					} else { // if unauthorize then close
						history.push(SIGNIN);
					}
				})
				.catch((response) => {
					dispatch({ type: GET_PRACTICEINFO_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_PRACTICEINFO_FAIL, payload: error });
		}
	};
};

export const SignOut = (params) => {
	return async (dispatch) => {
		dispatch({
			type: SIGNOUT_SUCCESS,
		});
	};
};

export const GetTos = () => {
	return async (dispatch) => {
		dispatch({ type: GET_TOS_REQUEST });

		try {
			await HttpUtility.getRequest(TOSLATEST)
				.then((response) => {
					const letestTos = response.data;
					dispatch({
						type: GET_TOS_SUCCESS,
						letestTos: letestTos,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_TOS_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_TOS_FAIL, error: error });
		}
	};
};

export const AcceptTos = (email_me) => {
	return async (dispatch) => {
		var params = {
			email_me: email_me,
		};

		dispatch({ type: POST_ACCEPT_TOS_REQUEST });

		try {
			await HttpUtility.postRequest(TOSACCEPT, params)
				.then((response) => {
					const AcceptTos = response.data;
					dispatch({
						type: POST_ACCEPT_TOS_SUCCESS,
						AcceptTos: AcceptTos,
					});
				})
				.catch((response) => {
					dispatch({ type: POST_ACCEPT_TOS_FAIL, error: response });
				});
		} catch (response) {
			dispatch({ type: POST_ACCEPT_TOS_FAIL, error: response });
		}
	};
};

export const sendRecoverCode = (params) => {
	return async (dispatch) => {
		params = {
			recovery_type: params.type,
			phone: params.phone,
			email: params.email,
			practice_id: params.practice_id
		};

		dispatch({ type: SEND_PRACTICE_RECOVERY_CODE_REQUEST, params: params });

		try {
			var url = PRACTICERECOVERCODE;
			if(params.practice_id)
				url = PRACTICERECOVERCODEBYPRACTICE;

			await HttpUtility.postRequest(url, params)
				.then((response) => {
					if (response.status === 200) {
						const sendRecoverCode = response.data;
						dispatch({
							type: SEND_PRACTICE_RECOVERY_CODE_SUCCESS,
							sendRecoverCode: sendRecoverCode,
						});
					} else {
						dispatch({ type: SEND_PRACTICE_RECOVERY_CODE_FAIL, error: response.message });
					}
				})
		} catch (response) {
			dispatch({ type: SEND_PRACTICE_RECOVERY_CODE_FAIL, error: response });
		}
	};
};

export const GetPracticeMoreInfo = () => {
	return async (dispatch) => {
		dispatch({ type: GET_PRACTICEMOREINFO_REQUEST });

		// console.log('get more info')
		try {
			await HttpUtility.getHelperType([
				TYPE_SPECIALIZATION,
				TYPE_COUNTRY,
				TYPE_LEAD_SOURCE,
				TYPE_PRACTICE_SOFTWARES,
				TYPE_PRACTICE_CUSROMER_TYPES,
				TYPE_PREACTICEUSER,
				TYPE_PRACTICE_USER_ROLES,
				TYPE_SUBSCRIPTION_TYPES,
				TYPE_MEDIA_LANGUAGE,
				TYPE_SYSTEM_LANGUAGE,
				TYPE_CC_CLOSE_CAPTION_SECTION,
				TYPE_GENDER,
				TYPE_OWNERSHIP
			])
				.then((response) => {
					const country = response.data.country;
					const specializations = response.data.specialization;
					const lead_source_types = response.data.lead_source_types;
					const practice_softwares = response.data.practice_softwares;
					const practice_customer_types = response.data.practice_customer_types;
					const practice_user_type = response.data.practice_user_type;
					const practice_user_roles = response.data.practice_user_roles;
					const practice_subscription_type = response.data.subscription_type;
					const media_language = response.data.media_language;
					const system_language = response.data.system_language;
					const cs_video_section_types = response.data.cs_video_section_types;
					const getGender = response.data.gender;
					const ownership = response.data.ownership;
					dispatch({
						type: GET_PRACTICEMOREINFO_SUCCESS,
						country: country,
						specializations: specializations,
						lead_source_types: lead_source_types,
						practice_softwares: practice_softwares,
						practice_customer_types: practice_customer_types,
						practice_user_type: practice_user_type,
						practice_user_roles: practice_user_roles,
						practice_subscription_type: practice_subscription_type,
						media_language: media_language,
						cs_video_section_types: cs_video_section_types,
						getGender:getGender,
						system_language:system_language,
						ownership
					});
				})
				.catch((response) => {
					dispatch({ type: GET_PRACTICEMOREINFO_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_PRACTICEMOREINFO_FAIL, payload: error });
		}
	};
};

export const GetpracticeInfo = () => {
	return async (dispatch) => {

		dispatch({ type: GET_PRACTICE_REQUEST });

		try {
			await HttpUtility.getuserRequest(PRACTICE)
				.then((response) => {
					const currentPracticeInfo = response.data;
					dispatch({
						type: GET_PRACTICE_SUCCESS,
						currentPracticeInfo: currentPracticeInfo,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_PRACTICE_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_PRACTICE_FAIL, payload: error });
		}
	};
};

export const GetpracticeProfile = () => {
	return async (dispatch) => {

		dispatch({ type: GET_PRACTICE_PROFILE_REQUEST });

		try {
			await HttpUtility.getRequest(PRACTICEPROFILE)
				.then((response) => {
					const practiceProfileInfo = response.data;
					dispatch({
						type: GET_PRACTICE_PROFILE_SUCCESS,
						practiceProfileInfo: practiceProfileInfo,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_PRACTICE_PROFILE_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_PRACTICE_PROFILE_FAIL, payload: error });
		}
	};
};

export const UpdateNewLocationpracticeInfoRequest = (newPracticeProfileInfo) => {
	return {
		type: UPDATE_NEW_LOCATION_PRACTICE_REQUEST,
		newPracticeProfileInfo: newPracticeProfileInfo,
	};
};


export const AddNewLoactionRequest = () => {
	return {
		type: ADD_NEW_LOCATION_PRACTICE_REQUEST,
	};
};

export const UpdatepracticeInfoRequest = (practiceProfileInfo) => {
	return {
		type: UPDATE_PRACTICE_REQUEST,
		practiceProfileInfo: practiceProfileInfo,
	};
};

export const UpdatepracticeInfo = (params) => {

	return async (dispatch) => {
		try {
			await HttpUtility.patchUserRequest(PRACTICEPROFILE, params).then(
				(response) => {
					
					if (response.status === 200) {
						const practiceProfileInfo = response.data;
						dispatch({
							type: UPDATE_PRACTICE_SUCCESS,
							// practiceProfileInfo: practiceProfileInfo,
						});
					} else {
						dispatch({
							type: UPDATE_PRACTICE_FAIL,
							error: response.data,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: UPDATE_PRACTICE_FAIL, error: error });
		}
	};
};

export const GetpracticeSecurityQuestion = () => {
	return async (dispatch) => {
		dispatch({ type: GET_PRACTICESECURITYQUE_REQUEST });

		try {
			await HttpUtility.getHelperType(TYPE_PRACTICE_SECURITYQUE)
				.then((response) => {
					const practiceSecurityQuestion = response.data.security_question;
					// dispatch({
					// 	type: GET_PRACTICESECURITYQUE_SUCCESS,
					// 	practiceSecurityQuestion: practiceSecurityQuestion,
					// });
					dispatch(getPracticeSecuritySuccess(practiceSecurityQuestion))
				})
				.catch((response) => {
					dispatch({ type: GET_PRACTICESECURITYQUE_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_PRACTICESECURITYQUE_FAIL, error: error });
		}
	};
};

export const getPracticeSecuritySuccess = (practiceSecurityQuestion) => {
	return {
		type: GET_PRACTICESECURITYQUE_SUCCESS,
		practiceSecurityQuestion: practiceSecurityQuestion,
	};
};

export const Getgender = () => {
	return async (dispatch) => {
		dispatch({ type: GET_GENDER_REQUEST });

		try {
			await HttpUtility.getHelperType(TYPE_GENDER)
				.then((response) => {
					const getGender = response.data.gender;
					dispatch({
						type: GET_GENDER_SUCCESS,
						getGender: getGender,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_GENDER_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_GENDER_FAIL, error: error });
		}
	};
};

export const GetOwnersID = (params) => {
	return async (dispatch) => {
		await HttpUtility.getRequest(PRACTICEUSER, params).then((response) => {
			const getownersId = response.data[0].id;
			if (getownersId) {
				dispatch(GetOwnersProfile(getownersId));
			}
		});
	};
};

export const GetOwnersProfile = (id) => {
	return async (dispatch) => {
		dispatch({ type: GET_PRACTICE_OWNER_INFO_REQUEST });

		try {
			await HttpUtility.getRequest(`${PRACTICEUSER}/${id}`)
				.then((response) => {
					const ownersProfileinfo = response.data;
					dispatch({
						type: GET_PRACTICE_OWNER_INFO_SUCCESS,
						ownersProfileinfo: ownersProfileinfo,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_PRACTICE_OWNER_INFO_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_PRACTICE_OWNER_INFO_FAIL, payload: error });
		}
	};
};

export const GetpracticeUser = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_PRACTICE_USER_REQUEST });
		try {
			await HttpUtility.getRequest(PRACTICEUSER, params)
				.then((response) => {
					const practiceUsersList = response.data;

					if (response.status === 200) {
						// var owner =  practiceUsersList && practiceUsersList.find(x=>x.type === 4)
						// dispatch(GetAuthorizedUser(owner.id));
						dispatch({
							type: GET_PRACTICE_USER_SUCCESS,
							practiceUsersList: practiceUsersList,
						});
					} else {
						dispatch({
							type: GET_PRACTICE_USER_FAIL,
							error: practiceUsersList,
						});
					}
				})
				.catch((error) => {
					dispatch({ type: GET_PRACTICE_USER_FAIL, error: error });
				});
		} catch (error) {
			dispatch({ type: GET_PRACTICE_USER_FAIL, error: error });
		}
	};
};



export const GetAllAuthorizedUsers = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_AUTHORIZED_ALL_USERS_REQUEST });
		try {
			await HttpUtility.getRequest(PRACTICEUSER, params)
				.then((response) => {
					var authorizedUserList = response.data;
					authorizedUserList = authorizedUserList.filter((x) => x.is_active === 1 || x.is_invitee === 1);
					console.log(authorizedUserList, 'authorizedUserList')
					if (response.status === 200) {
						dispatch({
							type: GET_AUTHORIZED_ALL_USERS_SUCCESS,
							authorizedUserList: authorizedUserList,
						});
					} else {
						dispatch({ type: GET_AUTHORIZED_ALL_USERS_FAIL, error: authorizedUserList });
					}
				})
				.catch((error) => {
					dispatch({ type: GET_AUTHORIZED_ALL_USERS_FAIL, error: error });
				});
		} catch (error) {
			dispatch({ type: GET_AUTHORIZED_ALL_USERS_FAIL, error: error });
		}
	};
};

export const CanclInviteUser = (id) => {
	return async (dispatch) => {
		dispatch({ type: CANCLE_INVITE_AUTHORIZED_USER_REQUEST });
		try {
			await HttpUtility.deleteUserRequest(`${PRACTICEUSER}/${id}/cancel-invite`)
				.then((response) => {
					let authorizedUserList = response.data;
					authorizedUserList = authorizedUserList.filter(
						(x) => x.is_active === 1 || x.is_invitee === 1
					);
					if (response.status === 200) {
						dispatch({
							type: CANCLE_INVITE_AUTHORIZED_USER_SUCCESS,
							authorizedUserList: authorizedUserList,
						});
						let params = {
							privileged: 1,
							with_invitee: 1,
						};
						dispatch(GetAllAuthorizedUsers(params));
					} else {
						dispatch({
							type: CANCLE_INVITE_AUTHORIZED_USER_FAIL,
							error: authorizedUserList,
						});
					}
				})
				.catch((error) => {
					dispatch({ type: CANCLE_INVITE_AUTHORIZED_USER_FAIL, error: error });
				});
		} catch (error) {
			dispatch({ type: CANCLE_INVITE_AUTHORIZED_USER_FAIL, error: error });
		}
	};
};
 
export const InviteUser = (params) => {
	return async (dispatch) => {
		dispatch({ type: INVITE_USER_TO_CTEATE_PROFILE_REQUEST });
		try {
			await HttpUtility.userPostRequest(`${PRACTICEUSER}/invite`, params).then(
				(response) => {
					const authorizedUserList = response.data;
					if (response.status === 200) {
						dispatch({
							type: INVITE_USER_TO_CTEATE_PROFILE_SUCCESS,
						});
						history.push('/authorized-user');
					} else {
						dispatch({
							type: INVITE_USER_TO_CTEATE_PROFILE_FAIL,
							error: authorizedUserList,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: INVITE_USER_TO_CTEATE_PROFILE_FAIL, error: error });
		}
	};
};

export const ResendInviteUser = (params,id) => {
	return async (dispatch) => {
		dispatch({ type: REINVITE_USER_TO_CTEATE_PROFILE_REQUEST });
		try {
			await HttpUtility.getuserRequest(`${PRACTICEUSER}/${id}/resend-invite`).then(
				(response) => {
					const authorizedUserList = response.data;
					if (response.status === 200) {
						dispatch({
							type: REINVITE_USER_TO_CTEATE_PROFILE_SUCCESS,
						});
						history.push('/authorized-user');
					} else {
						dispatch({
							type: REINVITE_USER_TO_CTEATE_PROFILE_FAIL,
							error: authorizedUserList,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: REINVITE_USER_TO_CTEATE_PROFILE_FAIL, error: error });
		}
	};
};

export const GetPhoneVerification = (params) => {
	let url;
	return async (dispatch) => {
		dispatch({ type: GET_PHONE_VERIFICATION_REQUEST });
		if(params.email){
			url  = 	consts.EMAIL_VERIFICATION
		}else{
			url = PHONE_VERIFICATION
		}
		try {
			await HttpUtility.postRequest(url, params)
				.then((response) => {
					if(response.status == 200){
						const phoneVerificationInfo = response.data;
						dispatch({
							type: GET_PHONE_VERIFICATION_SUCCESS,
							phoneVerificationInfo: phoneVerificationInfo,
						});
					}else{
						dispatch({ type: GET_PHONE_VERIFICATION_FAIL, error: response.data.errors });
					}
				})
				.catch((response) => {
					dispatch({ type: GET_PHONE_VERIFICATION_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_PHONE_VERIFICATION_FAIL, payload: error });
		}
	};
};

export const GetUserVerificationRequest = (userVerificationCode) => {
	return {
		type: GET_USER_VERIFICATION_REQUEST,
		userVerificationCode: userVerificationCode,
	};
};

export const GetUserVerification = (params) => {
	return async (dispatch) => {
		// dispatch({ type: GET_USER_VERIFICATION_REQUEST });

		try {
			await HttpUtility.userPostRequest(PRACTICE_USER_PROFILE_VERIFY, params)
				.then((response) => {
					const userVerificationCode = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_USER_VERIFICATION_SUCCESS,
							userVerificationCode: userVerificationCode,
						});
					} else {
						dispatch({
							type: GET_USER_VERIFICATION_FAIL,
							error: userVerificationCode,
						});
					}
				})
				.catch((response) => {
					dispatch({ type: GET_USER_VERIFICATION_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_USER_VERIFICATION_FAIL, error: error });
		}
	};
};

export const VerifyOtpRequest = (phoneVerificationInfo) => {
	return {
		type: VERIFY_OTP_REQUEST,
		phoneVerificationInfo: phoneVerificationInfo,
	};
};

export const VerifyOtp = (params) => {
	return async (dispatch) => {

		// dispatch({ type: VERIFY_OTP_REQUEST });

		try {
			await HttpUtility.postRequest(`${VERIFYED_OTP}/${params.media}`, params)
				.then((response) => {
					if (response.status === 200) {
						const phoneVerificationInfo = response.data;
						dispatch({
							type: VERIFY_OTP_SUCCESS,
							phoneVerificationInfo: phoneVerificationInfo,
						});
					} else {
						dispatch({ type: VERIFY_OTP_FAIL, error: response });
					}
				})
		} catch (error) {
			dispatch({ type: VERIFY_OTP_FAIL, error: error });
		}
	};
};

export const GetUserIdentify = (otp_media) => {
	return async (dispatch) => {
		var params = {
			otp_media: otp_media,
		};

		dispatch({ type: GET_USER_IDENTITY_REQUEST });

		try {
			await HttpUtility.userPostRequest(PRACTICEPROFILE_IDENTIFY, params)
				.then((response) => {
					const userIdentifyInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_USER_IDENTITY_SUCCESS,
							userIdentifyInfo: userIdentifyInfo,
						});
					} else {
						dispatch({ type: GET_USER_IDENTITY_FAIL, error: userIdentifyInfo });
					}
				})
				.catch((response) => {
					dispatch({ type: GET_USER_IDENTITY_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_USER_IDENTITY_FAIL, error: error });
		}
	};
};

export const GetUserProfileVerify = (params, data, pageUrl) => {
	return async (dispatch) => {
		var param = {
			request_secrete: params.request_secrete,
			otp: data.verificationCode,
		};

		dispatch({ type: GET_USER_PROFILE_VERIFY_REQUEST });

		try {
			await HttpUtility.userPostRequest(
				`${VERIFYED_OTP}/${params.otp_media}`,
				param
			)
				.then((response) => {
					const userProfileVerifyInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_USER_PROFILE_VERIFY_SUCCESS,
							userProfileVerifyInfo: userProfileVerifyInfo,
						});

						history.push(pageUrl);
					} else {
						dispatch({
							type: GET_USER_PROFILE_VERIFY_FAIL,
							error: userProfileVerifyInfo,
						});
					}
				})
				.catch((response) => {
					dispatch({ type: GET_USER_PROFILE_VERIFY_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_USER_PROFILE_VERIFY_FAIL, error: error });
		}
	};
};

export const ProfileVerify = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_USER_PROFILE_VERIFY_REQUEST });

		try {
			await HttpUtility.userPostRequest(`${PRACTICE_PROFILE_VERIFY}`, params)
				.then((response) => {
					const userProfileVerifyInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_USER_PROFILE_VERIFY_SUCCESS,
							userProfileVerifyInfo: userProfileVerifyInfo,
						});
					} else {
						dispatch({
							type: GET_USER_PROFILE_VERIFY_FAIL,
							error: userProfileVerifyInfo,
						});
					}
				})
				.catch((response) => {
					dispatch({ type: GET_USER_PROFILE_VERIFY_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_USER_PROFILE_VERIFY_FAIL, error: error });
		}
	};
};

export const GetDefaultProfilePicture = () => {
	return async (dispatch) => {
		dispatch({ type: GET_DEFAULT_PROFILE_PIC_REQUEST });

		try {
			await HttpUtility.getRequest(DEFAULT_PROFILE_PICTURE).then((response) => {
				const defaultProfilePictures = response.data;
				if (response.status === 200) {
					dispatch({
						type: GET_DEFAULT_PROFILE_PIC_SUCCESS,
						defaultProfilePictures: defaultProfilePictures,
					});
				}
			});
		} catch (error) {
			dispatch({ type: GET_DEFAULT_PROFILE_PIC_FAIL, payload: error });
		}
	};
};

export const UpdatePracticeProfile = (id, data) => {
	console.log(data,'data')
	var specializations = []
	data.specializations.length !== 0 && data.specializations.map((items, index)=>{
		specializations.push(items.id)
	})
	return async (dispatch) => {
		var params = {
			id: id,
			name: data.name,
			// ownership: data.selectedownership.id,
			phone: data.phone,
			phone_alt: data.phone_alt && data.phone_alt.length > 7 ? data.phone_alt : null,
			email: data.email,
			billing_type: 0,
			country_id: data.country.id,
			state_id: data.selectedstate.id,
			city: data.city,
			address_line_1: data.address_line_1,
			address_line_2: data.address_line_2,
			zip: data.zip,
			// specializations:specializations,
			need_statement_on_email: 0,
			additional_info: {
				location_manager: data.locationManager,
				software: data.selectedsoftwares && data.selectedsoftwares.name || '',
				// operatory_count: data.selectedoperatories && data.selectedoperatories.value,
				// tv_count: data.nooftvs && data?.nooftvs?.value || 0,
				// source: data.source && data.source.name || '',
				// source_name: data.source && data.source.name || '',
				source_practice_name: data.source && data.source.name,
				customer_type: data.practicetype && data.practicetype.id,
				// sales_executive: data.salesRepresentative,
				// distributor_name: data.distributor.label,
				// linked_emails: [
				//     "demo@demo.com"
				// ],
				// practice_setting:{
				// 	media_language:data.medialanguage.id,
				// 	system_language:data.systemlanguage.id
				// },
				schedule: [
					{
						status: data.additional_info.schedule[0].status,
						start_time: data.additional_info.schedule[0].start_time,
						end_time: data.additional_info.schedule[0].end_time,
					},
					{
						status: data.additional_info.schedule[1].status,
						start_time: data.additional_info.schedule[1].start_time,
						end_time: data.additional_info.schedule[1].end_time,
					},
					{
						status: data.additional_info.schedule[2].status,
						start_time: data.additional_info.schedule[2].start_time,
						end_time: data.additional_info.schedule[2].end_time,
					},
					{
						status: data.additional_info.schedule[3].status,
						start_time: data.additional_info.schedule[3].start_time,
						end_time: data.additional_info.schedule[3].end_time,
					},
					{
						status: data.additional_info.schedule[4].status,
						start_time: data.additional_info.schedule[4].start_time,
						end_time: data.additional_info.schedule[4].end_time,
					},
					{
						status: data.additional_info.schedule[5].status,
						start_time: data.additional_info.schedule[5].start_time,
						end_time: data.additional_info.schedule[5].end_time,
					},
					{
						status: data.additional_info.schedule[6].status,
						start_time: data.additional_info.schedule[6].start_time,
						end_time: data.additional_info.schedule[6].end_time,
					},
				],
			},
			
		};
		dispatch({ type: UPDATE_PRACTICE_PROFILE_REQUEST });

		try {
			await HttpUtility.postRequest(PRACTICESETUP, params)
				.then((response) => {
					const updatedpracticeUserInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: UPDATE_PRACTICE_PROFILE_SUCCESS,
							updatedpracticeUserInfo: updatedpracticeUserInfo,
						});
						// history.push('/edit-authorized-profile');
					} else {
						dispatch({
							type: UPDATE_PRACTICE_PROFILE_FAIL,
							error: updatedpracticeUserInfo,
						});
					}
				})
				.catch((error) => {
					dispatch({ type: UPDATE_PRACTICE_PROFILE_FAIL, error: error });
				});
		} catch (error) {
			dispatch({ type: UPDATE_PRACTICE_PROFILE_FAIL, error: error });
		}
	};
};

export const UpdateOwnersProfile = (phoneVerificationInfo, ownerdata) => {
	return async (dispatch) => {
		var params = {
			first_name: ownerdata.first_name,
			last_name: ownerdata.last_name,
			dob: ownerdata.dob,
			gender: ownerdata.selectedGender.id,
			email: ownerdata.email,
			email_alt: ownerdata.email_alt,
			phone: ownerdata.phone,
			security_question: {
				question_1: ownerdata.question_1.name,
				question_2: ownerdata.question_2.name,
				question_3: ownerdata.question_3.name,
				answer_1: ownerdata.answer_1,
				answer_2: ownerdata.answer_2,
				answer_3: ownerdata.answer_3,
			},
			passcode: ownerdata.passcode,
			request_secrete: phoneVerificationInfo.request_secrete,
		};
		console.log(params, 'params')
		// dispatch({ type: UPDATE_OWNER_PROFILE_REQUEST });
		// try {
		// 	await HttpUtility.postRequest(`${PRACTICEUSER}/${ownerdata.id}`, params)
		// 		.then((response) => {
		// 			const practiceUserAccountInfo = response.data;
		// 			if (response.status === 200) {
		// 				dispatch({
		// 					type: UPDATE_OWNER_PROFILE_SUCCESS,
		// 					practiceUserAccountInfo: practiceUserAccountInfo,
		// 				});
		// 			} else {
		// 				dispatch({
		// 					type: UPDATE_OWNER_PROFILE_FAIL,
		// 					payload: practiceUserAccountInfo,
		// 				});
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			dispatch({ type: UPDATE_OWNER_PROFILE_FAIL, payload: error });
		// 		});
		// } catch (error) {
		// 	dispatch({ type: UPDATE_OWNER_PROFILE_FAIL, payload: error });
		// }
	};
};


export const GetAuthorizedUser = (id) => {
	return async (dispatch) => {
		dispatch({
			type: GET_AUTHORIZED_USER_REQUEST,
		});

		try {
			await HttpUtility.getRequest(`${PRACTICEUSER}/${id}`)
				.then((response) => {
					const practiceUserAccountInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_AUTHORIZED_USER_SUCCESS,
							practiceUserAccountInfo: practiceUserAccountInfo,
						});
					} else {
						dispatch({
							type: GET_AUTHORIZED_USER_FAIL,
							payload: practiceUserAccountInfo,
						});
					}
				})
				.catch((error) => {
					dispatch({ type: GET_AUTHORIZED_USER_FAIL, payload: error });
				});
		} catch (error) {
			dispatch({ type: GET_AUTHORIZED_USER_FAIL, payload: error });
		}
	};
}

export const GetUserProfile = (id) => {
	return async (dispatch) => {

		dispatch({ type: GET_USER_PROFILE_REQUEST, });

		try {
			await HttpUtility.getuserRequest(`${PRACTICEUSERPROFILE}`)
				.then((response) => {
					const practiceUserProfileInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_USER_PROFILE_SUCCESS,
							practiceUserProfileInfo: practiceUserProfileInfo,
						});
					} else {
						dispatch({
							type: GET_USER_PROFILE_FAIL,
							practiceUserProfileInfo: practiceUserProfileInfo,
						});
					}
				})
		} catch (error) {
			dispatch({ type: GET_USER_PROFILE_FAIL, payload: error });
		}
	};
}

export const UpdateAuthorizedUserRequest = (practiceUserAccountInfo) => {
	return {
		type: UPDATE_AUTHORIZED_USER_REQUEST,
		practiceUserAccountInfo: practiceUserAccountInfo,
	};
};

export const UpdateAuthorizedUser = (params) => {

	return async (dispatch) => {

		try {
			await HttpUtility.postRequest(`${PRACTICEUSER}/${params.id}`, params)
				.then((response) => {

					if (response.status === 200) {
						const practiceUserAccountInfo = response.data;
						dispatch({
							type: UPDATE_AUTHORIZED_USER_SUCCESS,
							practiceUserAccountInfo: practiceUserAccountInfo,
						});
						// dispatch(getPracticeUserAccountInfo(params));
						if (params.isProfileCreated) {
							history.push('/profile-created')
						}

						if (params.isPasscodeSet) {
							history.push('/welcome')
						}
					} else {
						dispatch({
							type: UPDATE_AUTHORIZED_USER_FAIL,
							error: response.data,
						});
					}
				})
		} catch (error) {
			dispatch({ type: UPDATE_AUTHORIZED_USER_FAIL, payload: error });
		}
	};
};

export const PracticeUserLogin = (id, userInfo, setpasscode=false, fromtrial, is_super_admin, frompaymentinfo) => {
	return async (dispatch) => {
		var params = {
			user_id: id,
			passcode: userInfo,
		};

		if(is_super_admin){
			params['is_super_admin'] =  1
		}
		dispatch({ type: GET_PRACTICE_USER_LOGIN_REQUEST });

		try {
			await HttpUtility.postRequest(PRACTICE_USER_LOGIN, params).then(
				(response) => {
					const practiceUserLoginInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_PRACTICE_USER_LOGIN_SUCCESS,
							practiceUserLoginInfo: practiceUserLoginInfo,
						});
						// dispatch(getPracticeUserAccountInfo(practiceUserLoginInfo));
						var token = practiceUserLoginInfo.token_info.token_type + ' ' + practiceUserLoginInfo.token_info.access_token
						setToken(`${token}`);
						if(userInfo && fromtrial){
							history.push('/Subscribe');
						}
						// else{
						// 	// history.push('/welcome');
						// }
						
						// if(setpasscode){
						// 	history.push('/setpasscode');
						// }else{
							
						// }

						if(fromtrial){
							history.push('/Subscribe');
						}else{
							if(frompaymentinfo){
								history.push('/paymentInfo');
							}else{
								history.push('/welcome');
							}
						}
						
					} else {
						dispatch({
							type: GET_PRACTICE_USER_LOGIN_FAIL,
							error: practiceUserLoginInfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_PRACTICE_USER_LOGIN_FAIL, error: error });
		}
	};
};

export const PracticeUserLogout = () => {
	return async (dispatch) => {
		dispatch({ type: GET_PRACTICE_USER_LOGOUT_REQUEST });

		try {
			await HttpUtility.getuserRequest(PRACTICE_USER_LOGOUT).then(
				(response) => {
					const practiceUserLogoutInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_PRACTICE_USER_LOGOUT_SUCCESS,
							practiceUserLogoutInfo: practiceUserLogoutInfo,
						});
						history.push('/profileselection');
					} else {
						dispatch({
							type: GET_PRACTICE_USER_LOGOUT_FAIL,
							error: practiceUserLogoutInfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_PRACTICE_USER_LOGIN_FAIL, error: error.response });
		}
	};
};

// website-media/layouts/html Action Start
export const fetchWebsiteMediaLayoutRequest = () => {
	return {
		type: WEBSITE_MEDIA_LAYOUT_REQUEST,
		loading: true,
	};
};
export const fetchWebsiteMediaLayoutSuccess = (layout) => {
	return {
		type: WEBSITE_MEDIA_LAYOUT_SUCCESS,
		websiteMediaLayout: layout,
	};
};
export const fetchWebsiteMediaLayoutFailure = (error) => {
	return {
		type: WEBSITE_MEDIA_LAYOUT_FAILURE,
		payload: error,
		loading: false,
	};
};
// website-media/layouts/html Action Creator Start
export const fetchWebsiteMediaLayout = (params) => {
	console.log('Params : ', params);
	return async (dispatch) => {
		dispatch(fetchWebsiteMediaLayoutRequest());
		try {
			axios
				.get(WEBSITE_MEDIA_LAYOUT, {
					headers: {
						'Content-Type': 'text/html;charset=utf-8',
						Accept: 'text/html',
						Authorization: 'Bearer ' + params.token_info.access_token,
					},
				})
				.then((response) => {
					console.log('Response : ', response);
					if (response.status === 200) {
						const layout = response.data;
						dispatch(fetchWebsiteMediaLayoutSuccess(layout));
					} else {
						const error = 'Error';
						dispatch(fetchWebsiteMediaLayoutFailure(error));
					}
				})
				.catch((error) => {
					const errMessage = error.message;
					dispatch(fetchWebsiteMediaLayoutFailure(errMessage));
				});
		} catch (error) {
			const errMessage = error.message;
			dispatch(fetchWebsiteMediaLayoutFailure(errMessage));
		}
	};
};
// website-media/layouts/html Action Creator End

// website-media/layouts/html Action End

// waiting-room-media/channel/2/footer/custom-messages Action Start
export const getCustomMessagesRequest = () => {
	return {
		type: GET_FOOTER_CUSTOM_MESSAGES_REQUEST,
		// customMessages: [],
		loading: true,
		error: null,
	};
};

export const getCustomMessagesSuccess = (messages) => {
	return {
		type: GET_FOOTER_CUSTOM_MESSAGES_SUCCESS,
		customMessages: messages,
		loading: false,
		error: null,
	};
};

export const getCustomMessagesFailure = (error) => {
	return {
		type: GET_FOOTER_CUSTOM_MESSAGES_FAILURE,
		// customMessages: [],
		loading: true,
		error: error,
	};
};

// waiting-room-media/channel/2/footer/custom-messages Action Creator Start
export const getCustomMessage = (id, isOperatiryRoom) => {
	return (dispatch) => {
		dispatch(getCustomMessagesRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/footer/custom-messages`;
			if(isOperatiryRoom){
				url = `${OPERATORY_CHANNEL}/${id}/footer/custom-messages`;
			}
			HttpUtility.getuserRequest(url).then((response) => {
				if (response) {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(getCustomMessagesSuccess(datum));
					} else {
						dispatch(getCustomMessagesFailure(datum));
					}
				}
			}
			)
		} catch (error) {
			dispatch(getCustomMessagesFailure(error));
		}
	};
};
// waiting-room-media/channel/2/footer/custom-messages Action Creator End

// waiting-room-media/channel/2/footer/custom-messages Action End

// Live Weather Data Action Start
export const waitingRoomLiveWeatherDataRequest = () => {
	return {
		type: GET_WAITINGROOM_LIVE_WEATHER_DATA_REQUEST,
		loading: true,
		// liveWeatherData: {},
		error: null,
	};
};
export const waitingRoomLiveWeatherDataSuccess = (data) => {
	return {
		type: GET_WAITINGROOM_LIVE_WEATHER_DATA_SUCCESS,
		loading: false,
		liveWeatherData: data,
		error: null,
	};
};
export const waitingRoomLiveWeatherDataFailure = (error) => {
	return {
		type: GET_WAITINGROOM_LIVE_WEATHER_DATA_FAILURE,
		loading: true,
		// liveWeatherData: {},
		error: error,
	};
};
// Live Weather Data Action Creator Start
export const waitingRoomLiveWeather = (id) => {
	return async (dispatch) => {
		dispatch(waitingRoomLiveWeatherDataRequest());
		try {
			await HttpUtility.getuserRequest(`${WAITINGROOM_CHANNEL}/${id}/${GET_WAITINGROOM_CHANNEL_LIVE_WEATHER_DATA}`).then((response) => {
				if (response.status === 200 && response.data != null) {
					dispatch(waitingRoomLiveWeatherDataSuccess(response.data));
				} else {
					var error = 'Some error occured';
					dispatch(waitingRoomLiveWeatherDataFailure(error));
				}
			});
		} catch (error) {
			dispatch(waitingRoomLiveWeatherDataFailure(error));
		}
	};
};
// Live Weather Data Action Creator End

// Live Weather Data Action End

// website-media/playlist Action Start
export const fetchWebsiteMediaPlaylistRequest = () => {
	return {
		type: WEBSITE_MEDIA_GET_PLAYLIST_DATA_REQUEST,
		loading: true,
	};
};
export const fetchWebsiteMediaPlaylistSuccess = (playlist) => {
	return {
		type: WEBSITE_MEDIA_GET_PLAYLIST_DATA_SUCCESS,
		websiteMediaGetPlayListData: playlist,
	};
};
export const fetchWebsiteMediaPlaylistFailure = (error) => {
	return {
		type: WEBSITE_MEDIA_GET_PLAYLIST_DATA_FAILURE,
		payload: error,
		loading: false,
	};
};

// website-media/playlist Action Creator Start
export const fetchWebsiteMediaPlayList = (params) => {
	return async (dispatch) => {
		dispatch(fetchWebsiteMediaLayoutRequest());
		try {
			HttpUtility.getuserRequest(WEBSITE_MEDIA_GET_PLAYLIST_DATA, params).then(
				(response) => {
					const playlist = response.data;
					if (response.status === 200) {
						dispatch(fetchWebsiteMediaPlaylistSuccess(playlist));
					} else {
						const messages = 'Error';
						dispatch(fetchWebsiteMediaPlaylistFailure(messages));
					}
				}
			);
		} catch (error) {
			const errMessage = error.message;
			dispatch(fetchWebsiteMediaPlaylistFailure(errMessage));
		}
	};
};
// website-media/playlist Action Creator End

// website-media/playlist Action End

// website-media/layouts Action Start
export const fetchWebsiteMediaHtmlRequest = () => {
	return {
		type: WEBSITE_MEDIA_HTML_CODE_REQUEST,
	};
};
export const fetchWebsiteMediaHtmlSuccess = (layout) => {
	return {
		type: WEBSITE_MEDIA_HTML_CODE_SUCCESS,
		copyHTML: layout,
	};
};
export const fetchWebsiteMediaHtmlFailure = (error) => {
	return {
		type: WEBSITE_MEDIA_HTML_CODE_FAILURE,
		payload: error,
	};
};
// website-media/layouts Action Creator Start
export const fetchWebsiteMediaHtml = (params) => {
	return async (dispatch) => {
		dispatch(fetchWebsiteMediaHtmlRequest());
		try {
			HttpUtility.getuserRequest(`${consts.APIBASEURL + `website-media/layouts/html?layout_id=${params.layout_id}&colour_id=${params.colour_id}`}`).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data;
					dispatch(fetchWebsiteMediaHtmlSuccess(responseMessage));
				} else {
					dispatch(fetchWebsiteMediaHtmlFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(fetchWebsiteMediaHtmlFailure(error));
		}
		// try {
		// 	axios({
		// 		method: 'GET',
		// 		url: consts.APIBASEURL + `website-media/layouts/html?layout_id=${params.layout_id}&colour_id=${params.colour_id}`,
		// 		headers: {
		// 			Authorization: 'Bearer ' + params.token_info.access_token,
		// 		},
		// 	}).then((response) => {
		// 		if (response.status === 200) {
		// 			const responseMessage = response.data;
		// 			dispatch(fetchWebsiteMediaHtmlSuccess(responseMessage));
		// 		} else {
		// 			dispatch(fetchWebsiteMediaHtmlFailure(response));
		// 		}
		// 	});
		// } catch (error) {
		// 	const errMessage = error.message;
		// 	dispatch(fetchWebsiteMediaHtmlFailure(errMessage));
		// }
	};
};
// website-media/layouts Action Creator End
// website-media/layouts Action End

// website-media/html-code Action Start

export const getWebsiteMediaHtmlCodeRequest = () => {
	return {
		type: GET_WEBSITE_MEDIA_HTML_CODE_REQUEST,
		loading: true,
		// copyHTML: [],
		error: null
	}
}

export const getWebsiteMediaHtmlCodeSuccess = (html) => {
	return {
		type: GET_WEBSITE_MEDIA_HTML_CODE_SUCCESS,
		loading: false,
		copyHTML: html,
		error: null
	}
}
export const getWebsiteMediaHtmlCodeFailure = (error) => {
	return {
		type: GET_WEBSITE_MEDIA_HTML_CODE_FAILURE,
		loading: true,
		copyHTML: [],
		error: error
	}
}

// website-media/html-code Action Creator Start
export const getWebsiteMediaHtmlCode = (params) => {
	return async (dispatch) => {
		dispatch(getWebsiteMediaHtmlCodeRequest())
		try {
			HttpUtility.getuserRequest(WEBSITE_MEDIA_HTML, params).then((response) => {
				const layout = response.data;
				if (response.status === 200) {
					dispatch(getWebsiteMediaHtmlCodeSuccess(layout));
				} else {
					// const messages = 'Some error occured';
					dispatch(getWebsiteMediaHtmlCodeFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(getWebsiteMediaHtmlCodeFailure(error))
		}
	}
}
// website-media/html-code Action Creator End

// website-media/html-code Action End


// chairside-media get cc API Action Creator Start

export const getChairsideMediaCCListRequest = () => {
	return {
		type: GET_CHAIRSIDE_MEDIA_CC_LIST_REQUEST,
		loading: true,
	};
};

export const getChairsideMediaCCListSuccess = (media) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_CC_LIST_SUCCESS,
		chairSideMediaCCList: media,
	};
};

export const getChairsideMediaCCListFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_CC_LIST_FAILURE,
		payload: error,
		loading: true,
	};
};
// chairside-media  get cc API ActionCreator Start
export const getChairsideMediaCCList = (chairSideMediaId) => {
	return async (dispatch) => {
		dispatch(getChairsideMediaCCListRequest());
		try {
			HttpUtility.getuserRequest(`${GET_CHAIRSIDE_MEDIA}/${chairSideMediaId}/cc`).then((response) => {
				if (response.status === 200) {
					const chairSideMediaCCList = response.data;
					dispatch(getChairsideMediaCCListSuccess(chairSideMediaCCList));
				} else {
					dispatch(getChairsideMediaCCListFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(getChairsideMediaCCListFailure(error));
		}
	};
};
// chairside-media  get cc API ActionCreator End

// chairside-media  get cc API ActionCreator Start

export const getChairsideMediaCCRequest = () => {
	return {
		type: GET_CHAIRSIDE_MEDIA_CC_REQUEST,
		loading: true,
	};
};

export const getChairsideMediaCCSuccess = (media) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_CC_SUCCESS,
		chairSideMediaCC: media,
	};
};

export const getChairsideMediaCCFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_CC_FAILURE,
		payload: error,
		loading: true,
	};
};

export const getChairsideMediaCC = (chairSideMediaCCId) => {
	return async (dispatch) => {
		dispatch(getChairsideMediaCCRequest());
		try {
			HttpUtility.getuserRequest(`${GET_CHAIRSIDE_MEDIA}/cc/${chairSideMediaCCId}`).then((response) => {
				if (response.status === 200) {
					const chairSideMediaCC = response.data;
					dispatch(getChairsideMediaCCSuccess(chairSideMediaCC));
				} else {
					dispatch(getChairsideMediaCCFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(getChairsideMediaCCFailure(error));
		}
	};
};
// chairside-media  get cc API ActionCreator End
// chairside-media   get cc API Creator End


// chairside-media  Update cc API ActionCreator Start

export const updateChairsideMediaCCRequest = () => {
	return {
		type: UPDATE_CHAIRSIDE_MEDIA_CC_REQUEST,
	};
};

export const updateChairsideMediaCCSuccess = (media) => {
	return {
		type: UPDATE_CHAIRSIDE_MEDIA_CC_SUCCESS,
		chairSideMediaCC: media,
	};
};

export const updateChairsideMediaCCFailure = (error) => {
	return {
		type: UPDATE_CHAIRSIDE_MEDIA_CC_FAILURE,
		payload: error,
	};
};

export const updateChairsideMediaCC = (params) => {
	return async (dispatch) => {
		dispatch(updateChairsideMediaCCRequest());
		try {
			HttpUtility.patchUserRequest(`${GET_CHAIRSIDE_MEDIA}/cc`, params).then((response) => {
				if (response.status === 200) {
					const chairSideMediaCC = response.data;
					dispatch(updateChairsideMediaCCSuccess(chairSideMediaCC));
				} else {
					dispatch(updateChairsideMediaCCFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(updateChairsideMediaCCFailure(error));
		}
	};
};
// chairside-media Update cc API ActionCreator End
// chairside-media Update cc API Creator End

// chairside-media API Action Creator Start

export const fetchChairsideMediaRequest = () => {
	return {
		type: GET_CHAIRSIDE_MEDIA_REQUEST,
		loading: true,
	};
};

export const fetchChairsideMediaSuccess = (media) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_SUCCESS,
		chairSideMediaState: media,
	};
};

export const postDuplicatesWithChairsideMediaId = (data) => {
	return {
		type:POST_DUPLICATES_WITH_MEDIA_CATEGORIES,
		payload:data
	}
}

export const fetchChairsideMediaFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_FAILURE,
		payload: error,
		loading: true,
	};
};
// chairside-media API ActionCreator Start
export const fetchChairsideMedia = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_CHAIRSIDE_MEDIA_REQUEST });
		try {
			// delete params['id'];
			HttpUtility.getuserRequest(GET_CHAIRSIDE_MEDIA).then(
				(response) => {
					let chairSideMedia = response.data;
					const duplicatesWithDiffCategories =  chairSideMedia.filter((v,i,a)=>a.findIndex(t=>(t.chairside_media_id === v.chairside_media_id))!==i)
					chairSideMedia = chairSideMedia.filter((v,i,a)=>a.findIndex(t=>(t.chairside_media_id === v.chairside_media_id))===i)
					const arrayOfCategories = handleDuplicates(duplicatesWithDiffCategories)
					if (response.status === 200) {
						dispatch(fetchChairsideMediaSuccess(chairSideMedia));
						dispatch(postDuplicatesWithChairsideMediaId(arrayOfCategories))
					} else {
						const messages = 'Error';
						dispatch(fetchChairsideMediaFailure(messages));
					}
				}
			);
		} catch (error) {
			const errMessage = error.message;
			dispatch(fetchChairsideMediaFailure(errMessage));
		}
	};
};

export const setSelectedChairSideMediaSpecializationCategory = (id) => {
	return async (dispatch) => {
		dispatch({
			type: SET_SELECTED_SPECIALIZATION_CATEGORY,
			selectedSpecializatinCatID: id
		})
	}
}
// chairside-media API ActionCreator End
// chairside-media API Creator End

// chairside-media/category-list API ActionCreator Start
export const fetchChairsideMediaCategoryRequest = () => {
	return {
		type: GET_CHAIRSIDE_CATEGORY_LIST_REQUEST,
		loading: true,
	};
};
export const fetchChairsideMediaCategorySuccess = (media) => {
	return {
		type: GET_CHAIRSIDE_CATEGORY_LIST_SUCCESS,
		chairSideCategoryList: media,
	};
};
export const fetchChairsideMediaCategoryFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_CATEGORY_LIST_FAILURE,
		chairSideCategoryList: [],
		payload: error,
		loading: true,
	};
};
// chairside-media/category-list API Async ActionCreator Start
export const fetchChairsideMediaCategoryList = (params) => {
	return async (dispatch) => {
		dispatch(fetchChairsideMediaCategoryRequest());
		try {
			HttpUtility.getuserRequest(GET_CHAIRSIDE_MEDIA_CATEGORY_LIST).then((response) => {
				
				if (response.status === 200) {
					const chairSideMediaCategoryList = response.data;
					dispatch(fetchChairsideMediaCategorySuccess(chairSideMediaCategoryList));
				} else {
					dispatch(fetchChairsideMediaCategoryFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(fetchChairsideMediaCategoryFailure(error));
		}
	};
};

{/*=====================================Chairside video section====================================*/ }

// POST cc-status Chairside Education Action Start
export const ShowCloseCaptionChairsideEducationRequest = (chairside_media_id) => {
	return {
		type: POST_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST,
		chairside_media_id: chairside_media_id,
	}
}
export const ShowCloseCaptionChairsideEducationSuccess = (chairside_media_id) => {
	return {
		type: POST_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS,
		chairside_media_id: chairside_media_id,
	}
}
export const ShowCloseCaptionChairsideEducationFailure = (chairside_media_id) => {
	return {
		type: POST_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE,
		chairside_media_id: chairside_media_id,
		// error:error
	}
}
// POST cc-status Chairside Education Action Creator Start
export const ShowCloseCaptionChairsideEducation = (params) => {
	return async dispatch => {
		dispatch(ShowCloseCaptionChairsideEducationRequest(params.media_id))
		try {
			HttpUtility.userPostRequest(`${GET_CHAIRSIDE_MEDIA}/cc-status`, params).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(ShowCloseCaptionChairsideEducationSuccess(params.media_id));
				} else {
					dispatch(ShowCloseCaptionChairsideEducationFailure(params.media_id));
				}
			});
		} catch (error) {
			dispatch(ShowCloseCaptionChairsideEducationFailure(params.media_id));
		}
	}
}
// POST cc-status Chairside Education Action Creator End
// POST cc-status Chairside Education Action End

// DELETE cc-status Chairside Education Action Start
export const HideCloseCaptionChairsideEducationRequest = (chairside_media_id) => {
	return {
		type: DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_REQUEST,
		loading: true,
		message: null,
		error: null,
		chairside_media_id: chairside_media_id,
	}
}
export const HideCloseCaptionChairsideEducationSuccess = (chairside_media_id) => {
	return {
		type: DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_SUCCESS,
		loading: false,
		// message:message,
		error: null,
		chairside_media_id: chairside_media_id,
	}
}
export const HideCloseCaptionChairsideEducationFailure = (chairside_media_id) => {
	return {
		type: DELETE_CC_STATUS_CHAIRSIDE_EDUCATION_FAILURE,
		loading: true,
		message: null,
		// error:error
		chairside_media_id: chairside_media_id,
	}
}
// DELETE cc-status Chairside Education Action Creator Start
export const HideCloseCaptionChairsideEducation = (chairside_media_id) => {
	return async dispatch => {
		dispatch(HideCloseCaptionChairsideEducationRequest(chairside_media_id))
		try {
			HttpUtility.userdeleteRequest(`${GET_CHAIRSIDE_MEDIA}/${chairside_media_id}/cc-status`).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(HideCloseCaptionChairsideEducationSuccess(chairside_media_id));
				} else {
					dispatch(HideCloseCaptionChairsideEducationFailure(chairside_media_id));
				}
			});
		} catch (error) {
			dispatch(HideCloseCaptionChairsideEducationFailure(chairside_media_id));
		}
	}
}
// DELETE cc-status Chairside Education Action Creator End
// DELETE cc-status Chairside Education Action End


// POST My List Chairside Education Action Start
export const postAddToMyListChairsideEducationRequest = (chairside_media_id) => {
	return {
		type: POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST,
		chairside_media_id: chairside_media_id,
		loading: true,
		message: null,
		error: null
	}
}
export const postAddToMyListChairsideEducationSuccess = (chairside_media_id) => {
	return {
		type: POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS,
		loading: false,
		// message:message,
		chairside_media_id: chairside_media_id,
		error: null
	}
}
export const postAddToMyListChairsideEducationFailure = (chairside_media_id) => {
	return {
		type: POST_ADD_TO_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE,
		loading: true,
		message: null,
		chairside_media_id: chairside_media_id,
		// error:error
	}
}
// POST My List Chairside Education Action Creator Start
export const postAddToMyListChairsideEducation = (params) => {
	return async dispatch => {
		dispatch(postAddToMyListChairsideEducationRequest(params.media_id))
		try {
			HttpUtility.userPostRequest(`${GET_CHAIRSIDE_MEDIA}/mylist`, params).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(postAddToMyListChairsideEducationSuccess(params.media_id));
				} else {
					dispatch(postAddToMyListChairsideEducationFailure(params.media_id));
				}
			});
		} catch (error) {
			dispatch(postAddToMyListChairsideEducationFailure(params.media_id));
		}
	}
}
// POST My List Chairside Education Action Creator End
// POST My List Chairside Education Action End

// DELETE My List Chairside Education Action Start


export const deleteFromMyListChairsideEducationRequest = (chairside_media_id) => {
	return {
		type: DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_REQUEST,
		loading: true,
		message: null,
		error: null,
		chairside_media_id: chairside_media_id,
	}
}
export const deleteFromMyListChairsideEducationSuccess = (chairside_media_id) => {
	return {
		type: DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_SUCCESS,
		loading: false,
		// message:message,
		chairside_media_id: chairside_media_id,
		error: null
	}
}
export const deleteFromMyListChairsideEducationFailure = (chairside_media_id) => {
	return {
		type: DELETE_FROM_MY_LIST_CHAIRSIDE_EDUCATION_FAILURE,
		loading: true,
		message: null,
		error: null,
		chairside_media_id: chairside_media_id,
	}
}
// DELETE My List Chairside Education Action Creator Start
export const deleteFromMyListChairsideEducation = (chairside_media_id) => {
	return async dispatch => {
		dispatch(deleteFromMyListChairsideEducationRequest(chairside_media_id))
		try {
			HttpUtility.userdeleteRequest(`${GET_CHAIRSIDE_MEDIA}/${chairside_media_id}/mylist`).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(deleteFromMyListChairsideEducationSuccess(chairside_media_id));
				} else {
					dispatch(deleteFromMyListChairsideEducationFailure(chairside_media_id));
				}
			});
		} catch (error) {
			dispatch(deleteFromMyListChairsideEducationFailure(chairside_media_id));
		}
	}
}
// DELETE My List Chairside Education Action Creator End
// DELETE My List Chairside Education Action End

{/*=====================================Chairside video section====================================*/ }
// get Video section
export const getVideoSection = ({ chairside_media_id }) => {
	return async (dispatch) => {
		dispatch(getVideoSectionRequest());
		var url = `${GET_CHAIRSIDE_MEDIA}/${chairside_media_id}/video-section`;
		await HttpUtility.getuserRequest(url).then((response) => {
			if (response.status == 200) {
				const responseData = response.data;
				dispatch(getVideoSectionSuccess(responseData));
			} else {
				dispatch(getVideoSectionFailure(response));
			}
		});
	}
};

export const getVideoSectionRequest = () => {
	return {
		type: GET_VIDEO_SECTION_REQUEST,
	};
};
export const getVideoSectionSuccess = (csVideoSection) => {
	return {
		type: GET_VIDEO_SECTION_SUCCESS,
		csVideoSection: csVideoSection,
	};
};
export const getVideoSectionFailure = (error) => {
	return {
		type: GET_VIDEO_SECTION_FAILURE,
		error: error,
	};
};


// get screenshot list
export const getScreenshotList = (search) => {
	return async (dispatch) => {
		dispatch(getScreenshotListRequest());
		var url = `${GET_CHAIRSIDE_MEDIA}/screenshot`;
		await HttpUtility.getuserRequest(url, search).then((response) => {
			if (response.status == 200) {
				const responseData = response.data;
				dispatch(getScreenshotListSuccess(responseData));
			} else {
				dispatch(getScreenshotListFailure(response));
			}
		});
	}
};

export const getScreenshotListRequest = () => {
	return {
		type: GET_SCREENSHOT_LIST_REQUEST,
	};
};
export const getScreenshotListSuccess = (searchSchreenshotList) => {
	return {
		type: GET_SCREENSHOT_LIST_SUCCESS,
		searchSchreenshotList: searchSchreenshotList,
	};
};
export const getScreenshotListFailure = (error) => {
	return {
		type: GET_SCREENSHOT_LIST_FAILURE,
		error: error,
	};
};



{/*=====================================Chairside video end====================================*/ }


// chairside-media/category-list API Async ActionCreator End

// chairside-media/category-list API ActionCreator End

// website-media API ActionCreator Start
export const fetchWebSiteMediaRequest = () => {
	return {
		type: WEBSITE_MEDIA_REQUEST,
		loading: true,
		websiteMediaState: [],
		error: null,
	};
};

export const fetchWebSiteMediaSuccess = (media) => {
	return {
		type: WEBSITE_MEDIA_SUCCESS,
		loading: false,
		websiteMediaState: media,
		error: null,
	};
};

export const fetchWebSiteMediaFailure = (error) => {
	return {
		type: WEBSITE_MEDIA_FAILURE,
		loading: true,
		websiteMediaState: [],
		error: error,
	};
};

// website-media API Async ActionCreator Start
export const fetchWebsiteMedia = () => {
	return async (dispatch) => {
		dispatch(fetchWebSiteMediaRequest());
		try {
			HttpUtility.getuserRequest(WEBSITE_MEDIA).then((response) => {
				if (response.status === 200) {
					const websiteVideos = response.data;
					dispatch(fetchWebSiteMediaSuccess(websiteVideos));
				} else {
					const messages = 'Some error oocured';
					dispatch(fetchWebSiteMediaFailure(messages));
				}
			});
		} catch (error) {
			const errMessage = error.message;
			dispatch(fetchWebSiteMediaFailure(errMessage));
		}
	};
};
// chairside-media/?chairSideMediaId=/language/&languageId= API Start

export const fetchWebSiteMediaLanguageRequest = () => {
	return {
		type: GET_CHAIRSIDE_MEDIA_LANGUAGE_REQUEST,
		loading: true,
	};
};

export const fetchWebSiteMediaLanguageSuccess = (media) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_LANGUAGE_SUCCESS,
		websiteMediaLanguageState: media,
		loading: false,
	};
};

export const fetchWebSiteMediaLanguageFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_MEDIA_LANGUAGE_FAILURE,
		payload: error,
		loading: true,
	};
};
// chairside-media/?chairSideMediaId=/language/&languageId= API Aysnc ActionCreator Start
export const fetchChairsideMediaLanguage = (chairSideMediaId, languageId, params) => {
	return async (dispatch) => {
		dispatch(fetchWebSiteMediaLanguageRequest());
		try {
			HttpUtility.getuserRequest(GET_CHAIRSIDE_MEDIA_LANGUAGE, params).then(
				(response) => {
					const websiteVideos = response.data;
					if (response.status === 200) {
						dispatch(fetchWebSiteMediaLanguageSuccess(websiteVideos));
					} else {
						const messages = 'Error';
						dispatch(fetchWebSiteMediaLanguageFailure(messages));
					}
				}
			);
		} catch (error) {
			const errMessage = error.message;
			dispatch(fetchWebSiteMediaLanguageFailure(errMessage));
		}
	};
};
// chairside-media/?chairSideMediaId=/language/&languageId= API End

// chairside-media/mylist API Start
export const chairSideMediaMyList = (formData) => {
	return async (dispatch) => {
		var params = {
			media_id: formData,
		};

		dispatch({ type: POST_CHAIRSIDE_MEDIA_MYLIST_REQUEST });

		try {
			await HttpUtility.postRequest(POST_CHAIRSIDE_MEDIA_MYLIST, params)
				.then((response) => {
					if (response.status === 200) {
						const successMessage = response.message;
						dispatch({
							type: POST_CHAIRSIDE_MEDIA_MYLIST_SUCCESS,
							successMessage: successMessage,
						});
					}
				})
				.catch((response) => {
					dispatch({
						type: POST_CHAIRSIDE_MEDIA_MYLIST_FAILURE,
						error: response,
					});
				});
		} catch (response) {
			dispatch({ type: POST_CHAIRSIDE_MEDIA_MYLIST_FAILURE, error: response });
		}
	};
};

// chairside-media/mylist API End

// chairside-media/mylist POST API Start
export const webSiteMediaSavePlaylist = (formData, loginInfo) => {
	// console.log("Save Playlist Form Data : ", formData)


	return async (dispatch) => {
		dispatch({ type: WEBSITE_MEDIA_PLAYLIST_POST_REQUEST });

		// try {
		//     await HttpUtility.userPostRequest(  WEBSITE_MEDIA_GET_PLAYLIST_DATA , formData ).then((response)=>{
		//         console.log("Save Playlist Response : ", response)
		//         if(response.status === 200){
		//             const  successMessage =  response.message;
		//             dispatch({
		//                 type: WEBSITE_MEDIA_PLAYLIST_POST_SUCCESS,
		//                 successMessage:successMessage,
		//                 websiteMediaGetPlayListData:response.data,
		//             });
		//         }
		//     })
		//     .catch(response => {
		//         dispatch({type: WEBSITE_MEDIA_PLAYLIST_POST_FAILURE, error: response})
		//     });
		// }catch(response){
		//     dispatch({type: WEBSITE_MEDIA_PLAYLIST_POST_FAILURE, error: response})
		// }
		try {
			axios
				.post(
					WEBSITE_MEDIA_GET_PLAYLIST_DATA,
					{
						data: {
							media: [
								{
									id: 1,
								},
								{
									id: 2,
								},
								{
									id: 3,
								},
							],
						},
					},
					{
						headers: {
							Authorization: 'Bearer ' + loginInfo.token_info.access_token,
						},
					}
				)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => {
					dispatch({
						type: WEBSITE_MEDIA_PLAYLIST_POST_FAILURE,
						error: error.message,
					});
				});
		} catch (response) {
			dispatch({ type: WEBSITE_MEDIA_PLAYLIST_POST_FAILURE, error: response });
		}
	};
};
// chairside-media/mylist POST API End

// website-media/config POST API Start
export const chairSideMediaSavePlayerSettings = (formData) => {
	return async (dispatch) => {
		var params = {
			player_settings: formData,
		};

		dispatch({ type: WEBSITE_MEDIA_CONFIG_POST_REQUEST });
		try {
			await HttpUtility.userPostRequest(WEBSITE_MEDIA_CONFIG, params)
				.then((response) => {
					if (response.status === 200) {
						const successMessage = response.message;
						dispatch({
							type: WEBSITE_MEDIA_CONFIG_POST_SUCCESS,
							successMessage: successMessage,
							websiteMediaConfig: response,
						});
					}
				})
				.catch((response) => {
					dispatch({
						type: WEBSITE_MEDIA_CONFIG_POST_FAILURE,
						error: response.message,
					});
				});
		} catch (response) {
			dispatch({
				type: WEBSITE_MEDIA_CONFIG_POST_FAILURE,
				error: response.message,
			});
		}
	};
};
// website-media/config POST API End
// website-media/playlist/{id} DELETE Start
export const deletePlaylist = (id, loginInfo) => {
	return async (dispatch) => {
		dispatch({ type: WEBSITE_MEDIA_DELETE_PLAYLIST_REQUEST, loading: true });
		try {
			// await HttpUtility.userdeleteRequest(  WEBSITE_MEDIA_DELETE_PLAYLIST , id ).then((response)=>{
			//     if(response.status === 200){
			//         const  successMessage =  response.message;
			//         dispatch({type:WEBSITE_MEDIA_DELETE_PLAYLIST_SUCCESS, successMessage:successMessage})
			//     }
			// })
			let headers = {
				Authorization: 'Bearer ' + loginInfo.token_info.access_token,
			};
			axios
				.delete(consts.APIBASEURL + `${id}`, { headers: headers })
				.then((response) => {
					dispatch({
						type: WEBSITE_MEDIA_DELETE_PLAYLIST_SUCCESS,
						successMessage: response.message,
					});
				})
				.catch((error) => {
					console.log(error.message);
				});
		} catch (err) {
			dispatch({
				type: WEBSITE_MEDIA_DELETE_PLAYLIST_FAILURE,
				error: err.message,
			});
		}
	};
};
// website-media/playlist/{id} DELETE End
// Create Profile
const getHeaders = () => {
	var headers = {};
	headers['Content-Type'] = 'application/json;charset=UTF-8';
	var accessToken = getDeviceCode();
	// console.log(accessToken);
	if (accessToken !== "") {
		headers['Device-Code'] = accessToken;
	}
	return headers;
};

export const setUploadProgress = (percentageProgress) => ({
	type: SET_UPLOAD_PROGRESS,
	progress: percentageProgress,
});

export const successUploadFile = (newProfileInfo) => ({
	type: SUCCESS_UPLOAD_FILE,
	newProfileInfo: newProfileInfo,
});

export const failureUploadFile = (newProfileInfo) => ({
	type: FAILURE_UPLOAD_FILE,
	error: newProfileInfo.response.data,
});

export const CreateNewProfile = (formData) => {
	return async (dispatch) => {
		var headers = getHeaders();
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		axios({
			method: 'post',
			headers: headers,
			data: formData,
			url: CREATE_NEW_PRACTICEUSER,
			cancelToken: source.token,
			onUploadProgress: (progress) => {
				const { loaded, total } = progress;
				const percentageProgress = Math.floor((loaded / total) * 100);
				dispatch(setUploadProgress(percentageProgress));
			},
		})
			.then((response) => {
				const newProfileInfo = response.data.data;
				console.log(newProfileInfo, 'newProfileInfo')
				if (response.status === 200) {
					dispatch(successUploadFile(newProfileInfo));
					dispatch(GetpracticeUser());
					history.push('/profilecreated');
				} else {
					dispatch(failureUploadFile(newProfileInfo));
				}
			})
			.catch((response) => dispatch(failureUploadFile(response)));
	};
};

// Create Profile end

export const setUpdatePasscodeRequest = () => ({
	type: UPDATE_PASSCODE_REQUEST,
	practiceUserUpdatePasscodeInfo: false,
});

export const PracticeUserUpdatePasscode = (params) => {
	return async (dispatch) => {
		dispatch(setUpdatePasscodeRequest());
		try {
			await HttpUtility.userPostRequest(`${PRACTICE_PROFILE_CHANGE_PASSWORD}`, params).then((response) => {
				const practiceUserUpdatePasscodeInfo = response.data;
				if (response.status === 200) {
					dispatch({
						type: UPDATE_PASSCODE_SUCCESS,
						practiceUserUpdatePasscodeInfo: true,
					});
				} else {
					dispatch({ type: UPDATE_PASSCODE_FAIL, error: practiceUserUpdatePasscodeInfo })
				}
			})
		}
		catch (error) {
			dispatch({ type: UPDATE_PASSCODE_FAIL, error: error })
		}
	};
};

export const setUploadProgress_UpdateUserProfileImage = (
	percentageProgress
) => ({
	type: UPDATE_USER_PROFILE_IMAGE_REQUEST,
	progress: percentageProgress,
	UpdateUserProfileImage: false,
});

export const successUploadFile_UpdateUserProfileImage = (UpdateUserProfileImage) => ({
	type: UPDATE_USER_PROFILE_IMAGE_SUCCESS,
	// UpdateUserProfileImage: true,
	practiceUserAccountInfo: UpdateUserProfileImage,
});

export const failureUploadFile_UpdateUserProfileImage = (
	UpdateUserProfileImage
) => ({
	type: UPDATE_USER_PROFILE_IMAGE_FAIL,
	error: UpdateUserProfileImage,
});

export const UpdateUserProfileImage = (id, formData, isSlide, slideImage, isImage, channelId, uploadedImage) => {
	return async (dispatch) => {
		var headers = isSlide || isImage == ISWAITINGROOMCHANNELIMAGE || isImage == ISOPERATORYROOMCHANNELIMAGE || uploadedImage ? getuserHeaders() : getHeaders();
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		let urls =  isSlide ? `${SLIDE_IMAGE_UPLOAD}` : isImage > 0 ? `${isImage == ISWAITINGROOMCHANNELIMAGE ? WAITINGROOM_CHANNEL : OPERATORY_CHANNEL}/${channelId}/image` : `${UPDATE_PRACTICEUSER}/${id}`
		if(uploadedImage){
			urls = SLIDE_IMAGE_UPLOAD;
			// formData.append('isChannel', true);
		}
		
		axios({
			method: 'post',
			headers: headers,
			data: formData,
			url: urls,
			cancelToken: source.token,
			onUploadProgress: (progress) => {
				const { loaded, total } = progress;
				const percentageProgress = Math.floor((loaded / total) * 100);
				dispatch(setUploadProgress_UpdateUserProfileImage(percentageProgress));
			},
		})
			.then((response) => {
				const UpdateUserProfileImage = response.data.data;
				if (response.status === 200) {
					if (isSlide) {
						slideImage(response);
						dispatch(
							successUploadFile_UpdateUserProfileImage(UpdateUserProfileImage)
						);
					}else if(uploadedImage) {
						uploadedImage(UpdateUserProfileImage)
					}else if (isImage){
						console.log(isImage,'isImage')
						dispatch(
							successUploadFile_UpdateUserProfileImage(UpdateUserProfileImage)
						);
					}
					else {
						dispatch(
							successUploadFile_UpdateUserProfileImage(UpdateUserProfileImage)
						);
						dispatch(GetpracticeUser());
						// let params = {
						// 	id: id
						// }
						// dispatch(getPracticeUserAccountInfo(params));
					}
				} else {
					dispatch(
						failureUploadFile_UpdateUserProfileImage(UpdateUserProfileImage)
					);
				}
			})
			.catch((UpdateUserProfileImage) =>
				dispatch(
					failureUploadFile_UpdateUserProfileImage(UpdateUserProfileImage)
				)
			);
	};
};

export const UpdatePracticeUserProfileRequest = (practiceUserProfileInfo) => ({
	type: UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST,
	practiceUserProfileInfo: practiceUserProfileInfo,
});

export const UpdateUserProfileAboutInfo = (params) => {
	return async (dispatch) => {
		// dispatch({ type: UPDATE_USER_PROFILE_ABOUT_INFO_REQUEST });

		try {
			await HttpUtility.postRequest(`${PRACTICEUSER}/${params.id}`, params).then((response) => {
				const practiceUserProfileInfo = response.data;
				if (response.status === 200) {
					dispatch({
						type: UPDATE_USER_PROFILE_ABOUT_INFO_SUCCESS,
						practiceUserProfileInfo: practiceUserProfileInfo,
					});
					// dispatch(GetpracticeUser());
				} else {
					dispatch({
						type: UPDATE_USER_PROFILE_ABOUT_INFO_FAIL,
						error: practiceUserProfileInfo,
					});
				}
			});
		} catch (error) {
			dispatch({
				type: UPDATE_USER_PROFILE_ABOUT_INFO_FAIL,
				error: error,
			});
		}
	};
};

export const UpdateSettingRequest = practiceUserAccountSetting => ({
	type: UPDATE_SYSTEM_LANGUAGE_REQUEST,
	practiceUserAccountSetting: practiceUserAccountSetting
})

export const UpdateAccountSetting = (params) => {
	return async dispatch => {

		// dispatch({type: UPDATE_SYSTEM_LANGUAGE_REQUEST})

		try {
			await HttpUtility.userPostRequest(`${UPDATE_PRACTICEUSER}/account-setting`, params).then((response) => {
				const practiceUserAccountSetting = response.data;
				if (response.status === 200) {
					dispatch({
						type: UPDATE_SYSTEM_LANGUAGE_SUCCESS,
						practiceUserAccountSetting: practiceUserAccountSetting,
					});
					// dispatch(getPracticeUserAccountInfo(practiceUserAccountInfo));
				} else {
					dispatch({ type: UPDATE_SYSTEM_LANGUAGE_FAIL, error: practiceUserAccountSetting })
				}

			})
		}
		catch (error) {
			dispatch({ type: UPDATE_SYSTEM_LANGUAGE_FAIL, error: error })
		}
	};
}

export const UpdateUserProfile = (params) => {
	return async (dispatch) => {
		dispatch({ type: UPDATE_USER_PROFILE_REQUEST });

		try {
			await HttpUtility.userPostRequest(`${PRACTICEUSER}/profile`, params).then(
				(response) => {
					const UpdateUserProfileInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: UPDATE_USER_PROFILE_SUCCESS,
							UpdateUserProfileInfo: UpdateUserProfileInfo,
						});
					} else {
						dispatch({
							type: UPDATE_USER_PROFILE_FAIL,
							error: UpdateUserProfileInfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: UPDATE_USER_PROFILE_FAIL, error: error });
		}
	};
};

export const getPracticeUserAccountInfo = (params) => {
	return async (dispatch) => {
		// dispatch({type: GET_USER_PROFILE_INFO_REQUEST})

		try {
			await HttpUtility.getRequest(`${UPDATE_PRACTICEUSER}/${params.id}`).then(
				(response) => {
					const practiceUserAccountInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_USER_PROFILE_INFO_SUCCESS,
							practiceUserAccountInfo: practiceUserAccountInfo,
						});
					} else {
						dispatch({
							type: GET_USER_PROFILE_INFO_FAIL,
							error: practiceUserAccountInfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_USER_PROFILE_INFO_FAIL, error: error });
		}
	};
};

export const AccountSettingRequest = (practiceUserAccountInfo) => ({
	type: GET_USER_PROFILE_INFO_REQUEST,
	practiceUserAccountInfo: practiceUserAccountInfo,
});

export const UpdateLanguage = (params) => {
	return async (dispatch) => {
		dispatch({ type: UPDATE_SYSTEM_LANGUAGE_REQUEST });

		try {
			await HttpUtility.userPostRequest(
				`${UPDATE_PRACTICEUSER}/account-setting`,
				params
			).then((response) => {
				const practiceUserAccountSetting = response.data;
				if (response.status === 200) {
					dispatch({
						type: UPDATE_SYSTEM_LANGUAGE_SUCCESS,
						practiceUserAccountSetting: practiceUserAccountSetting,
					});
					// dispatch(getPracticeUserAccountInfo(practiceUserAccountInfo));
				} else {
					dispatch({
						type: UPDATE_SYSTEM_LANGUAGE_FAIL,
						error: practiceUserAccountSetting,
					});
				}
			});
		} catch (error) {
			dispatch({ type: UPDATE_SYSTEM_LANGUAGE_FAIL, error: error });
		}
	};
};

export const DeleteUserProfileRequest = () => ({
	type: DELETE_USER_PROFILE_REQUEST,
});


export const DeleteUserProfile = (passcode) => {
	return async (dispatch) => {
		var params = {
			passcode
		};

		dispatch(DeleteUserProfileRequest());
		// dispatch({ type: DELETE_USER_PROFILE_REQUEST });

		try {
			await HttpUtility.userdeleteRequest(
				`${PRACTICEUSER}/profile`,
				params
			).then((response) => {
				const practiceUserdeleteInfo = response.data;
				if (response.status === 200) {
					dispatch({
						type: DELETE_USER_PROFILE_SUCCESS,
						practiceUserdeleteInfo: practiceUserdeleteInfo,
					});
					dispatch(GetpracticeUser());
					// history.push('/profileselection');
				} else {
					dispatch({
						type: DELETE_USER_PROFILE_FAIL,
						error: practiceUserdeleteInfo,
					});
				}
			});
		} catch (error) {
			dispatch({ type: DELETE_USER_PROFILE_FAIL, error: error });
		}
	};
};

export const DeleteAuthorizedUser = (params) => {
	let param ={
		passcode:params.passcode
	}
	return async (dispatch) => {

		dispatch({ type: DELETE_USER_PROFILE_REQUEST });

		try {
			await HttpUtility.userdeleteRequest(`${PRACTICEUSER}/${params.id}/remove`,param).then((response) => {
				const authorizedUserdeleteInfo = response.data;
				if (response.status === 200) {
					dispatch({
						type: DELETE_USER_PROFILE_SUCCESS,
						authorizedUserdeleteInfo: authorizedUserdeleteInfo,
					});
				} else {
					dispatch({
						type: DELETE_USER_PROFILE_FAIL,
						error: authorizedUserdeleteInfo,
					});
				}
			});
		} catch (error) {
			dispatch({ type: DELETE_USER_PROFILE_FAIL, error: error });
		}
	};
};

export const DeleteMultipalUserProfiles = (params) => {
	return async (dispatch) => {
		params = {
			user_id: [params],
		};
		dispatch({ type: DELETE_MULTY_USER_PROFILE_REQUEST });

		try {
			await HttpUtility.userdeleteRequest(`${PRACTICEUSER}/profile/list`, params).then((response) => {
				const practiceMultipalUserdeleteInfo = response.data;
				if (response.status === 200) {
					dispatch({
						type: DELETE_MULTY_USER_PROFILE_SUCCESS,
						practiceMultipalUserdeleteInfo: practiceMultipalUserdeleteInfo,
					});
					dispatch(GetpracticeUser());
					// setTimeout(() => {
					// 	dispatch(resetSuccess());
					// }, 1000);
					// dispatch(resetSuccess())
				} else {
					dispatch({
						type: DELETE_MULTY_USER_PROFILE_FAIL,
						error: practiceMultipalUserdeleteInfo,
					});
				}
			});
		} catch (error) {
			dispatch({ type: DELETE_MULTY_USER_PROFILE_FAIL, error: error });
		}
	};
};

export const resetReferForFreeMonth = (referFreeMonthInfo) => ({
	type: RESET_REFER_FREE_MONTH_REQUEST,
});

export const SendReferForFreeMonth = (params) => {
	return async (dispatch) => {
		params = {
			email: params.email,
			name: params.fullName,
			message: params.message,
		};
		dispatch({ type: POST_REFER_FREE_MONTH_REQUEST });

		try {
			await HttpUtility.userPostRequest(`${PRACTICE}/referral`, params).then(
				(response) => {
					const referFreeMonthInfo = response.data;

					if (response.status === 200) {
						// dispatch(successSendrefer(referFreeMonthInfo))
						dispatch({
							type: POST_REFER_FREE_MONTH_SUCCESS,
							referFreeMonthInfo: referFreeMonthInfo,
							refersend: true,
						});
					} else {
						dispatch({
							type: POST_REFER_FREE_MONTH_FAIL,
							error: referFreeMonthInfo,
							refersend: false,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: POST_REFER_FREE_MONTH_FAIL, error: error });
		}
	};
};

export const GetreferralList = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_REFERRAL_LIST_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/referral`, params).then(
				(response) => {
					const getreferralList = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_REFERRAL_LIST_SUCCESS,
							getreferralList: getreferralList,
						});
					} else {
						dispatch({
							type: GET_REFERRAL_LIST_FAIL,
							error: getreferralList,
							refersend: false,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_REFERRAL_LIST_FAIL, error: error });
		}
	};
};

const LOAD_NOTIFICATION = 'LOAD_NOTIFICATION';
const FILTER_BY_KEYWORD = 'FILTER_BY_KEYWORD';
const FILTER_BY_LETEST = 'FILTER_BY_LETEST';
const FILTER_BY_SHOW_ONLY_NEW = 'FILTER_BY_SHOW_ONLY_NEW';

export const filterByKeyword = (payload) => ({
	type: FILTER_BY_KEYWORD,
	getnotificationlist: payload,
});

export const showOnlyNew = (payload) => ({
	type: FILTER_BY_SHOW_ONLY_NEW,
	getnotificationlist: payload,
});

export const sortByLetest = (payload) => ({
	type: FILTER_BY_LETEST,
	getnotificationlist: payload,
});

export const loadtNotification = (getnotificationlist) => ({
	type: GET_NOTIFICATION_SUCCESS,
	getnotificationlist: getnotificationlist,
});

export const GetNotification = (params) => {
	return async (dispatch) => {

		dispatch({ type: GET_NOTIFICATION_REQUEST });

		try {
			await HttpUtility.getRequest(`${GET_PRACTICE_NOTIFICATION}`, params).then(
				(response) => {
					const getnotificationlist = response.data;
					if (response.status === 200) {
						// console.log(params, 'paramsparamsparams')
						if (params.latest) {
							dispatch(sortByLetest(getnotificationlist));
							history.push('/notification-messages')
						} else if (params.keyword) {
							dispatch(filterByKeyword(getnotificationlist));
						} else if (params.only_new) {
							dispatch(showOnlyNew(getnotificationlist));
						} else {
							dispatch(loadtNotification(getnotificationlist));

						}
					} else {
						dispatch({
							type: GET_NOTIFICATION_FAIL,
							error: getnotificationlist,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_NOTIFICATION_FAIL, error: error });
		}
	};
};

export const GetBillingCard = () => {
	return async (dispatch) => {
		dispatch({ type: GET_BILLING_CARD_INFO_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/billing-card`).then(
				(response) => {
					const getBillingCardInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_BILLING_CARD_INFO_SUCCESS,
							getBillingCardInfo: getBillingCardInfo,
						});
					} else {
						dispatch({
							type: GET_BILLING_CARD_INFO_FAIL,
							error: getBillingCardInfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_BILLING_CARD_INFO_FAIL, error: error });
		}
	};
};

export const UpdateBillingCard = (expireDate, data, isCorporate) => {
	let params = {
		exp_month: parseInt(expireDate.slice(0, 2)),
		exp_year: parseInt(expireDate.slice(2, 6)),
	};

	return async (dispatch) => {
		dispatch({ type: UPDATE_BILLING_CARD_INFO_REQUEST });
		console.log(data, 'expireDate');
		try {
			var url = `${PRACTICE}/billing-card/${data.id}`;
			if(isCorporate){
				url = `${CORPORATE}/${data.practice_id}/card/${data.id}`;
			}
			await HttpUtility.patchuserRequest(url, params).then((response) => {
				
				if (response.status === 200) {
					const updateBillingCardInfo = response.data;
					if(isCorporate){
						// dispatch(getDesignateCard());
						data.exp_month = params.exp_month;
						data.exp_year = params.exp_year;
					}else{
						dispatch(GetBillingCard());
					}
					dispatch({
						type: UPDATE_BILLING_CARD_INFO_SUCCESS,
						updateBillingCardInfo: updateBillingCardInfo,
						data: data,
					});
				} else {
					dispatch({
						type: UPDATE_BILLING_CARD_INFO_FAIL,
						error: response.data,
					});
				}
			});
		} catch (error) {
			dispatch({ type: UPDATE_BILLING_CARD_INFO_FAIL, error: error });
		}
	};
};

export const AddBillingCard = (params, practiceId) => {
	console.log(params, 'params');
	return async (dispatch) => {
		dispatch({ type: ADD_BILLING_CARD_INFO_REQUEST });

		try {
			var url = `${PRACTICE}/billing-card`;
			if(practiceId){
				url = `${CORPORATE}/${practiceId}/card`;
			}
			console.log(url, params);
			await HttpUtility.userPostRequest(url, params).then(async (response) => {
				const addBillingCardInfo = response.data;
				if (response.status === 200) {
					await dispatch({
						type: ADD_BILLING_CARD_INFO_SUCCESS,
						addBillingCardInfo: addBillingCardInfo,
					});
					if(practiceId){
						var params = { page: 1, per_page: 5 }
						dispatch(getDesignateCard(params));
						await dispatch({
							type: SELECTED_CARD,
							selectedCard: response.data[0],
						})
					}else{
						dispatch(GetBillingCard());
					}
				} else {
					dispatch({
						type: ADD_BILLING_CARD_INFO_FAIL,
						error: addBillingCardInfo,
					});
				}
			});
		} catch (error) {
			dispatch({ type: ADD_BILLING_CARD_INFO_FAIL, error: error });
		}
	};
};

export const changeCardStatus = (params, id, practiceId) => {
	console.log(params, 'params');
	return async (dispatch) => {
		dispatch({ type: CHANGE_BILLING_CARD_STATUS_REQUEST });
		try {
			var url = `${PRACTICE}/billing-card/${id}/status`;
			if(practiceId){
				url = `${CORPORATE}/${practiceId}/card/${id}/status`;
			}
			await HttpUtility.patchuserRequest(url, params).then((response) => {
				const cardstatus = response.data;
				if (response.status === 200) {
					dispatch({
						type: CHANGE_BILLING_CARD_STATUS_SUCCESS,
						cardstatus: cardstatus,
					});
					if(practiceId){
						history.goBack();
					}else{
						dispatch(GetBillingCard());
					}
				} else {
					dispatch({
						type: CHANGE_BILLING_CARD_STATUS_FAIL,
						error: cardstatus,
					});
				}
			});
		} catch (error) {
			dispatch({ type: CHANGE_BILLING_CARD_STATUS_FAIL, error: error });
		}
	};
};

export const GetPasscodeRecovery = (id) => {
	return async (dispatch) => {
		dispatch({ type: PASSCODE_RECOVERY_REQUEST });

		try {
			await HttpUtility.getRequest(
				`${PRACTICEUSER}/${id}/passcode-recovery`
			).then((response) => {
				const getPasscodeRecoveryInfo = response.data;
				if (response.status === 200) {
					dispatch({
						type: PASSCODE_RECOVERY_SUCCESS,
						getPasscodeRecoveryInfo: getPasscodeRecoveryInfo,
					});
				} else {
					dispatch({
						type: PASSCODE_RECOVERY_FAIL,
						error: getPasscodeRecoveryInfo,
					});
				}
			});
		} catch (error) {
			dispatch({ type: PASSCODE_RECOVERY_FAIL, error: error });
		}
	};
};

export const GetDomainList = (params) => {
	console.log(params, 'params');
	return async (dispatch) => {

		dispatch({ type: GET_DOMAIN_LIST_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/domain`, params).then(
				(response) => {
					const getDomainList = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_DOMAIN_LIST_SUCCESS,
							getDomainList: getDomainList,
						});
					} else {
						dispatch({ type: GET_DOMAIN_LIST_FAIL, error: getDomainList });
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_DOMAIN_LIST_FAIL, error: error });
		}
	};
};

export const CreateDomainRequest = (createDomaininfo) => {
	return {
		type: CREATE_DOMAIN_REQUEST,
		createDomaininfo: createDomaininfo,
	};
};

export const CreateDomain = (params) => {
	return async (dispatch) => {

		// dispatch({ type: CREATE_DOMAIN_REQUEST });

		try {
			await HttpUtility.userPostRequest(`${PRACTICE}/domain`, params).then(
				(response) => {
					const createDomaininfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: CREATE_DOMAIN_SUCCESS,
							createDomaininfo: createDomaininfo,
						});
						// let data = {
						// 	page: 1,
						// 	per_page: 5,
						// };
						// setTimeout(() => {
						// 	dispatch(resetSuccess());
						// 	dispatch(GetDomainList(data));
						// }, 1000);
					} else {
						dispatch({ type: CREATE_DOMAIN_FAIL, error: createDomaininfo });
					}
				}
			);
		} catch (error) {
			dispatch({ type: CREATE_DOMAIN_FAIL, error: error });
		}
	};
};

export const UpdateDomainRequest = (getDomainList) => {
	return {
		type: UPDATE_DOMAIN_REQUEST,
		getDomainList: getDomainList,
	};
};

export const UpdateDomainSuccess = (getDomainList, index) => {
	return {
		type: UPDATE_DOMAIN_SUCCESS,
		// getDomainList: getDomainList,
		payload: index,
	};
};

export const UpdateDomainFailure = (error) => {
	return {
		type: UPDATE_DOMAIN_FAIL,
		payload: error,
		loading: false,
	};
};

export const UpdateDomain = (params, id, index) => {
	return async (dispatch) => {
		try {
			await HttpUtility.patchuserRequest(
				`${PRACTICE}/domain/${id}`,
				params
			).then((response) => {
				const getDomainList = response.data;

				if (response.status === 200) {
					dispatch(UpdateDomainSuccess(getDomainList, index));
				} else {
					dispatch({ type: UPDATE_DOMAIN_FAIL, error: getDomainList });
				}
			});
		} catch (error) {
			dispatch({ type: UPDATE_DOMAIN_FAIL, error: error });
		}
	};
};

export const RemoveDomainRequest = (getDomainList) => {
	return {
		type: REMOVE_DOMAIN_REQUEST,
		getDomainList: getDomainList,
	};
};

export const RemoveDomainSuccess = (getDomainList, index) => {
	return {
		type: REMOVE_DOMAIN_SUCCESS,
		getDomainList: getDomainList,
		payload: index,
	};
};

export const RemoveDomain = (id, index) => {
	return async (dispatch) => {
		try {
			await HttpUtility.deleteUserRequest(`${PRACTICE}/domain/${id}`).then(
				(response) => {
					const getDomainList = response.data;
					if (response.status === 200) {
						dispatch(RemoveDomainSuccess(getDomainList, index));
						let data = {
							page: 1,
							per_page: 5,
						};
						setTimeout(() => {
							dispatch(GetDomainList(data));
						}, 1000);
					} else {
						dispatch({ type: REMOVE_DOMAIN_FAIL, error: getDomainList });
					}
				}
			);
		} catch (error) {
			dispatch({ type: REMOVE_DOMAIN_FAIL, error: error });
		}
	};
};

export const GetSubscribeInfo = () => {
	return async (dispatch) => {
		dispatch({ type: GET_SUBSCRIBE_INFO_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/subscribe`).then(
				(response) => {
					const getSubscribeinfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_SUBSCRIBE_INFO_SUCCESS,
							getSubscribeinfo: getSubscribeinfo,
						});
					} else {
						dispatch({
							type: GET_SUBSCRIBE_INFO_FAIL,
							error: getSubscribeinfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_SUBSCRIBE_INFO_FAIL, error: error });
		}
	};
};

export const GetSubscribePriceOption = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_SUBSCRIBE_PRICE_OPTION_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/price/option`, params).then(
				(response) => {
					const getSubscribePriceOption = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_SUBSCRIBE_PRICE_OPTION_SUCCESS,
							getSubscribePriceOption: getSubscribePriceOption,
						});
					} else {
						dispatch({
							type: GET_SUBSCRIBE_PRICE_OPTION_FAIL,
							error: response,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_SUBSCRIBE_PRICE_OPTION_FAIL, error: error });
		}
	};
};

export const SaveSubscribeCalcRequest = (getSubscribePriceCalc) => {
	return {
		type: GET_SUBSCRIBE_PRICE_CALC_REQUEST,
		getSubscribePriceCalc: getSubscribePriceCalc,
	};
};

export const SaveSubscribeCalc = (params, isUpgradePlan) => {
	return async (dispatch) => {
		dispatch({ type: GET_SUBSCRIBE_PRICE_CALC_REQUEST });
		try {
			let url = `${PRACTICE}/price/calc/subscription`;
			if(params.is_paid_account === 1 && !params.add_manage_location){
				url = `${PRACTICE}/price/calc/update/subscription`
			}
			await HttpUtility.userPostRequest(url, params).then(
				(response) => {
					
					if (response.status === 200) {
						const getSubscribePriceCalc = response.data;
						dispatch({
							type: GET_SUBSCRIBE_PRICE_CALC_SUCCESS,
							getSubscribePriceCalc: getSubscribePriceCalc,
							isUpgradePlan
						});
					} else {
						dispatch({
							type: GET_SUBSCRIBE_PRICE_CALC_FAIL,
							error: response.data,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_SUBSCRIBE_PRICE_CALC_FAIL, error: error });
		}
	};
};

export const SubscribeNewRequest = (subscribeInfo) => {
	return {
		type: POST_SUBSCRIBE_REQUEST,
		subscribeInfo: subscribeInfo,
	};
};

export const SubscribeNew = (params) => {
	return async (dispatch) => {
		var url;
		// dispatch({ type: POST_SUBSCRIBE_REQUEST });
		if(params.isNewLocation){
			url = `${PRACTICE}/paid-subscription`;
		}else{
			url = `${PRACTICE}/subscribe`;
		}
		
		try {
			await HttpUtility.userPostRequest(url, params).then(
				(response) => {
					const subscribeInfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: POST_SUBSCRIBE_SUCCESS,
							subscribeInfo: subscribeInfo,
						});
						dispatch(GetSubscribeInfo());
						if(params.isNewLocation){
							history.push({pathname:'/subscription-completed', isNewLocation:true})
						}else{
							history.push('/subscription-completed')

						}
					} else {
						dispatch({
							type: POST_SUBSCRIBE_FAIL,
							error: subscribeInfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: POST_SUBSCRIBE_FAIL, error });
		}
	};
};

export const UpgradeDowngradeSubscribe = (params) => {
	return async (dispatch) => {
		var url;
			url = `${PRACTICE}/subscribe`;
		try {
			await HttpUtility.patchUserRequest(url, params).then(
				(response) => {
					const subscribeInfo = response.data;
					console.log(response,'message', subscribeInfo);
					if (response.status === 200) {
						dispatch({
							type: POST_SUBSCRIBE_SUCCESS,
							subscribeInfo: subscribeInfo,
						});
						dispatch(GetSubscribeInfo());
						history.push({pathname:'/subscription-completed', isUpgradePlan:true, message:response.message})
					} else {
						dispatch({
							type: POST_SUBSCRIBE_FAIL,
							error: subscribeInfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: POST_SUBSCRIBE_FAIL, error });
		}
	};
};

export const SetupgradeDowngrade = (param) => {
	return {
		type: SET_UPGRADE_DOWNGRADE,
		isUpgradePlan:param
	};
};

export const AddNewLocationRequest = (newlocationinfo) => {
	return {
		type: POST_NEW_LOCATION_REQUEST,
		newlocationinfo: newlocationinfo,
	};
};

export const AddNewLocation = (params) => {
	return async (dispatch) => {
		try {
			await HttpUtility.userPostRequest(`${PRACTICE}/location`, params).then(
				(response) => {
					const newlocationinfo = response.data;
					if (response.status === 200) {
						dispatch({
							type: POST_NEW_LOCATION_SUCCESS,
							newlocationinfo: newlocationinfo,
						});
					} else {
						dispatch({
							type: POST_NEW_LOCATION_FAIL,
							error: newlocationinfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: POST_NEW_LOCATION_FAIL, error });
		}
	};
};

export const LoginPracticeLocation = (params) => {
	return async (dispatch) => {
		dispatch({ type: POST_LOGIN_PRACTICE_LOCATION_REQUEST });
		try {
			await HttpUtility.userPostRequest(`${PRACTICE}/login-practice-location`, params).then(
				(response) => {
					const loginPracticeLocationinfo = response.data;
					// if(params.is_corporate === 1){
					// 	loginPracticeLocationinfo['isCorporate'] = true;
					// }
					if (response.status === 200) {
						dispatch({
							type: POST_LOGIN_PRACTICE_LOCATION_SUCCESS,
							loginPracticeLocationinfo: loginPracticeLocationinfo,
							practiceProfileInfo: loginPracticeLocationinfo?.practice_profile_info,
							practiceInfo: loginPracticeLocationinfo?.practice_info,
						});
						var token = loginPracticeLocationinfo.token_info.token_type + ' ' + loginPracticeLocationinfo.token_info.access_token
						setToken(`${token}`);
						if(params.is_corporate === 1){
							history.push({
								pathname: '/corporate',
								// isCorporate: true,
							});
						}else{
							history.push('/channels')
						}
						// dispatch(GetpracticeProfile());

						// let param ={
						// 	id:params.practice_id
						// }
						// dispatch(getPracticeUserAccountInfo(param));
					} else {
						dispatch({
							type: POST_LOGIN_PRACTICE_LOCATION_FAIL,
							error: loginPracticeLocationinfo,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: POST_LOGIN_PRACTICE_LOCATION_FAIL, error });
		}
	};
};

export const SubscribeAdditionalDomainRequest = (subscribeAdditionalDomain) => {
	return {
		type: POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUEST,
		subscribeAdditionalDomain: subscribeAdditionalDomain,
	};
};


export const SubscribeAdditionalDomain = (params) => {
	return async (dispatch) => {
		dispatch({ type: POST_SUBSCRIBE_ADDITIONAL_DOMAIN_REQUESTLOADING });

		try {
			await HttpUtility.userPostRequest(
				`${PRACTICE}/subscribe/domain`,
				params
			).then((response) => {
				if (response.status === 200) {
					const subscribeAdditionalDomain = response.data;
					dispatch({
						type: POST_SUBSCRIBE_ADDITIONAL_DOMAIN_SUCCESS,
						subscribeAdditionalDomain: subscribeAdditionalDomain,
					});
					dispatch(GetSubscribeInfo());
				} else {
					dispatch({
						type: POST_SUBSCRIBE_ADDITIONAL_DOMAIN_FAIL,
						error: response.data,
					});
				}
			});
		} catch (error) {
			dispatch({ type: POST_SUBSCRIBE_ADDITIONAL_DOMAIN_FAIL, error: error });
		}
	};
};

export const clearerror = () => {
	return async (dispatch) => {
		dispatch({
			type: CLEAR_ERROR,
		});
	};
};

export const resetSuccess = () => {
	return async (dispatch) => {
		dispatch({
			type: RESET_SUCCESS,
		});
	};
};

export const cleardata = () => {
	return async (dispatch) => {
		dispatch({
			type: CLEAR_DATA,
		});
	};
};

export const clearloading = () => {
	return async (dispatch) => {
		dispatch({
			type: CLEAR_LOADING,
		});
	};
};

// Channel Footer Time Action Start
export const getFooterTimeRequest = () => {
	return {
		type: GET_WAITINGROOM_FOOTER_TIME_REQUEST,
		loading: true,
		error: null,
		liveFooterTime: {},
	};
};
export const getFooterTimeSuccess = (data) => {
	return {
		type: GET_WAITINGROOM_FOOTER_TIME_SUCCESS,
		loading: false,
		error: null,
		liveFooterTime: data,
	};
};
export const getFooterTimeFailure = (error) => {
	return {
		type: GET_WAITINGROOM_FOOTER_TIME_FAILURE,
		loading: true,
		error: error,
		liveFooterTime: {},
	};
};
// Channel Footer Time Action Creator Start
export const getFooterTime = (id, isOperatiryRoom) => {
	return (dispatch) => {
		dispatch(getFooterTimeRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/footer/time`
			if(isOperatiryRoom){
				url = `${OPERATORY_CHANNEL}/${id}/footer/time`
			}
			HttpUtility.getuserRequest(url).then((response) => {
				const data = response.data;
				if (response.status === 200 && response.data != null) {
					dispatch(getFooterTimeSuccess(data));
				} else {
					dispatch(getFooterTimeFailure(data));
				}
			}
			);
		} catch (error) {
			dispatch(getFooterTimeFailure(error));
		}
	};
};
// Channel Footer Time Action Creator End



// SEND_EMBED_CODE_TO_EMAIL_REQUEST
export const sendEmbedCodeToEmail = (params) => {
	return async (dispatch) => {
		dispatch({ type: SEND_EMBED_CODE_TO_EMAIL_REQUEST });
		var url;
		if(params.link_emails){
			url = `${consts.APIBASEURL}practice/send-embed-code-list-to-email`
		}else{
			url = `${consts.APIBASEURL}practice/send-embed-code-to-email`

		}
		try {
			await HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status === 200) {
					const sendcodetoemaildata = response.data;
					dispatch({
						type: SEND_EMBED_CODE_TO_EMAIL_SUCCESS,
						sendcodetoemaildata: sendcodetoemaildata,
					});
				}else{
					dispatch({ type: SEND_EMBED_CODE_TO_EMAIL_FAIL, error: response.data });

				}
			});
		} catch (error) {
			dispatch({ type: SEND_EMBED_CODE_TO_EMAIL_FAIL, error: error });
		}
	};
};
// SEND_EMBED_CODE_TO_EMAIL_REQUEST END

// Channel Footer Time Action End

export const InvoiceList = (params, practiceId) => {
	return async (dispatch) => {
		dispatch({ type: GET_INVOICE_REQUEST });

		try {
			var url = INVOICE;
			if(practiceId){ // isCorporate
				url = `${CORPORATE}/subscription-payment/designate-card/practice/${practiceId}/invoice`;
			}
			await HttpUtility.getuserRequest(url, params).then((response) => {
				const invoiceList = response.data;
				if (response.status === 200) {
					dispatch({
						type: GET_INVOICE_SUCCESS,
						invoiceList: invoiceList,
					});
				}
			});
		} catch (error) {
			dispatch({ type: GET_INVOICE_FAILURE, payload: error });
		}
	};
};

export const InvoiceViewList = (invoice) => {
	return async (dispatch) => {
		console.log(invoice, 'data');
		dispatch({ type: GET_INVOICEVIEW_REQUEST });

		try {
			console.log('invoice.id');
			await HttpUtility.getuserRequest(`${INVOICEVIEW}/${invoice.id}`)
				.then((response) => {
					const invoiceView = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_INVOICEVIEW_SUCCESS,
							invoiceView: invoiceView,
						});
					} else {
						dispatch({ type: GET_INVOICEVIEW_FAILURE, payload: invoiceView });
					}
				})
				.catch((error) => {
					dispatch({ type: GET_INVOICEVIEW_FAILURE, payload: error });
				});
		} catch (error) {
			dispatch({ type: GET_INVOICEVIEW_FAILURE, payload: error });
		}
	};
};

export const GetauthorizedDevice = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_AUTHORIZED_DEVICE_REQUEST });
		try {
			await HttpUtility.getuserRequest(AUTHORIZED_DEVICE, params)
				.then((response) => {
					const authorizedDeviceList = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_AUTHORIZED_DEVICE_SUCCESS,
							authorizedDeviceList: authorizedDeviceList,
						});
					} else {
						dispatch({
							type: GET_AUTHORIZED_DEVICE_FAILURE,
							error: authorizedDeviceList,
						});
					}
				})
				.catch((error) => {
					dispatch({ type: GET_AUTHORIZED_DEVICE_FAILURE, error: error });
				});
		} catch (error) {
			dispatch({ type: GET_AUTHORIZED_DEVICE_FAILURE, error: error });
		}
	};
};

export const UnauthorizedDevice = (id) => {
	return async (dispatch) => {
		dispatch({ type: POST_UNAUTHORIZED_DEVICE_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE_DEVICE}/${id}/unauthorize`)
				.then((response) => {
					const unauthorizedDevice = response.data;
					if (response.status === 200) {
						dispatch({
							type: POST_UNAUTHORIZED_DEVICE_SUCCESS,
							unauthorizedDevice: unauthorizedDevice,
						});
						dispatch(GetauthorizedDevice());
					} else {
						dispatch({ type: POST_UNAUTHORIZED_DEVICE_FAIL, error: response });
					}
				})
				.catch((response) => {
					dispatch({ type: POST_UNAUTHORIZED_DEVICE_FAIL, error: response });
				});
		} catch (response) {
			dispatch({ type: POST_UNAUTHORIZED_DEVICE_FAIL, error: response });
		}
	};
};

export const SubscribeAdditionalDevice = (params) => {
	return async (dispatch) => {
		dispatch({ type: POST_SUBSCRIBE_DEVICE_REQUEST });

		try {
			await HttpUtility.userPostRequest(`${SUBSCRIBE}/device`, params).then(
				(response) => {
					const subscribeAdditionalDevice = response.data;
					if (response.status === 200) {
						dispatch({
							type: POST_SUBSCRIBE_DEVICE_SUCCESS,
							subscribeAdditionalDevice: subscribeAdditionalDevice,
						});
						dispatch(GetSubscribeInfo());
					} else {
						dispatch({
							type: POST_SUBSCRIBE_DEVICE_FAILURE,
							error: subscribeAdditionalDevice,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: POST_SUBSCRIBE_DEVICE_FAILURE, error: error });
		}
	};
};

const list_to_tree = (list, getEmailPreferences) => {
	var map = {},
		node,
		roots = [],
		i;

	// for (i = 0; i < list.length; i += 1) {
	// 	map[list[i].id] = i; // initialize the map
	// 	list[i].children = []; // initialize the children
	// 	list[i].isChecked = false; // initialize the isChecked
	// }

	for (i = 0; i < list.length; i += 1) {
		map[list[i].id] = i; // initialize the map
		list[i].children = []; // initialize the children
		list[i].isChecked = false; // initialize the isChecked
		node = list[i];
		if (node.is_parent !== 0) {
			// if you have dangling branches check that map[node.parentId] exists
			list[map[node.is_parent]].children.push(node);
		} else {
			var index = getEmailPreferences.findIndex(x1 => x1 == list[i].id )
			if(index > -1)
				list[i].isChecked = true;
			roots.push(node);
		}
	}
	return roots;
};

export const getEmailPreferencesListRequest = (getEmailPreferencesList) => {
	return {
		type: GET_EMAIL_PREFERENCES_LIST_REQUEST,
		getEmailPreferencesList: getEmailPreferencesList,
	};
};

export const getEmailPreferencesList = (getEmailPreferences) => {
	return async (dispatch) => {
		try {
			await HttpUtility.getRequest(TYPE_EMAIL_PREFERENCES_lIST).then(
				(response) => {
					var getEmailPreferencesList = response.data;
					getEmailPreferencesList = list_to_tree(getEmailPreferencesList, getEmailPreferences);
					if (response.status === 200) {
						dispatch({
							type: GET_EMAIL_PREFERENCES_LIST_SUCCESS,
							getEmailPreferencesList: getEmailPreferencesList,
						});
					} else {
						dispatch({
							type: GET_EMAIL_PREFERENCES_LIST_FAILURE,
							error: getEmailPreferencesList,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_EMAIL_PREFERENCES_LIST_FAILURE, error: error });
		}
	};
};

export const getEmailPreferences = () => {
	return async (dispatch) => {
		try {
			await HttpUtility.getuserRequest(SELECTED_EMAIL_PREFERENCES).then(
				(response) => {
					const getEmailPreferences = response.data;
					if (response.status === 200) {
						dispatch({
							type: GET_EMAIL_PREFERENCES_SUCCESS,
							getEmailPreferences: getEmailPreferences,
						});
					} else {
						dispatch({
							type: GET_EMAIL_PREFERENCES_FAILURE,
							error: getEmailPreferences,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_EMAIL_PREFERENCES_FAILURE, error: error });
		}
	};
};

export const saveEmailPreferencesRequest = (getEmailPreferences) => {
	return {
		type: POST_EMAIL_PREFERENCES_REQUEST,
		getEmailPreferences: getEmailPreferences,
	};
};

export const saveEmailPreferences = (params) => {
	return async (dispatch) => {
		try {
			await HttpUtility.userPostRequest(
				SELECTED_EMAIL_PREFERENCES,
				params
			).then((response) => {
				const getEmailPreferences = response.data;
				if (response.status === 200) {
					dispatch({
						type: POST_EMAIL_PREFERENCES_SUCCESS,
					});
				} else {
					dispatch({
						type: POST_EMAIL_PREFERENCES_FAILURE,
						error: getEmailPreferences,
					});
				}
			});
		} catch (error) {
			dispatch({ type: POST_EMAIL_PREFERENCES_FAILURE, error: error });
		}
	};
};

export const getDefaultCaptionFontSize = () => {
	return async (dispatch) => {
		dispatch({ type: GET_DEFAULT_CAPTION_SIZE_REQUEST });

		try {
			await HttpUtility.getHelperType(TYPE_DEFAULT_CAPTION_FONT_SIZE)
				.then((response) => {
					const defaultCaptionSize = response.data.close_caption_font_size;
					dispatch({
						type: GET_DEFAULT_CAPTION_SIZE_SUCCESS,
						defaultCaptionSize: defaultCaptionSize,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_DEFAULT_CAPTION_SIZE_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_DEFAULT_CAPTION_SIZE_FAIL, error: error });
		}
	};
};

export const getAccessCode = () => {
	return async (dispatch) => {
		dispatch({ type: GET_ACCESS_CODE_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/get-accesscode`)
				.then((response) => {
					const getAccessCode = response.data;
					dispatch({
						type: GET_ACCESS_CODE_SUCCESS,
						getAccessCode: getAccessCode,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_ACCESS_CODE_FAIL, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_ACCESS_CODE_FAIL, error: error });
		}
	};
};

export const resetAccessCodeRequest = (resetAccessCode) => {
	return {
		type: RESET_ACCESS_CODE_REQUEST,
		resetAccessCode: resetAccessCode,
	};
};

export const resetAccessCode = (params) => {
	return async dispatch => {

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/reset-accesscode`).then((response) => {
				const resetAccessCode = response.data;
				dispatch({
					type: RESET_ACCESS_CODE_SUCCESS,
					resetAccessCode: resetAccessCode,
				});
				// clearAllStorage();
			})
				.catch(response => {
					dispatch({ type: RESET_ACCESS_CODE_FAIL, error: response })
				});
		}
		catch (error) {
			dispatch({ type: RESET_ACCESS_CODE_FAIL, error: error })
		}
	};
}


export const RemoveAdditionalDeviceRequest = (authorizedDeviceList) => {
	return {
		type: REMOVE_AUTHORIZED_DEVICE_REQUEST,
		authorizedDeviceList: authorizedDeviceList,
	};
};

export const RemoveAdditionalDeviceSuccess = (authorizedDeviceList, field) => {
	return {
		type: REMOVE_AUTHORIZED_DEVICE_SUCCESS,
		authorizedDeviceList: authorizedDeviceList,
	};
};

export const RemoveAdditionalDeviceFailure = (error, field) => {
	return {
		type: REMOVE_AUTHORIZED_DEVICE_FAILURE,
		error: error,
	};
};

export const RemoveAdditionalDevice = (params, field) => {
	return async (dispatch) => {
		try {
			await HttpUtility.userdeleteRequest(`${SUBSCRIBE}/device`, params).then(
				(response) => {
					const authorizedDeviceList = response.data;
					if (response.status === 200) {
						dispatch(
							RemoveAdditionalDeviceSuccess(authorizedDeviceList, field)
						);
						dispatch(GetSubscribeInfo());
					} else {
						dispatch(
							RemoveAdditionalDeviceFailure(authorizedDeviceList, field)
						);
					}
				}
			);
		} catch (error) {
			dispatch(RemoveAdditionalDeviceFailure(error, field));
		}
	};
};

// helper/timezone API Action Start
export const fetchHelperTimezoneRequest = () => {
	return {
		type: GET_HELPER_TIMEZONE_REQUEST,
		loading: true,
	};
};

export const fetchHelperTimezoneSuccess = (timezones) => {
	return {
		type: GET_HELPER_TIMEZONE_SUCCESS,
		helperTimezone: timezones,
		loading: false,
	};
};
export const fetchHelperTimezoneFailure = (error) => {
	return {
		type: GET_HELPER_TIMEZONE_FAILURE,
		helperTimezone: [],
		loading: true,
		error: error,
	};
};
// helper/timezone API Action Creator Start
export const fetchHelperTimeline = (params) => {
	return async (dispatch) => {
		dispatch(fetchHelperTimezoneRequest());
		try {
			HttpUtility.getuserRequest(HELPER_TIMEZONE, params).then((response) => {
				if (response.status === 200) {
					const timezones = response.data;
					dispatch(fetchHelperTimezoneSuccess(timezones));
				} else {
					const messages = 'Error';
					dispatch(fetchHelperTimezoneFailure(messages));
				}
			});
		} catch (error) {
			const errMessage = error.message;
			dispatch(fetchHelperTimezoneFailure(errMessage));
		}
	};
};
// helper/timezone API Action Creator End
// helper/timezone API Action End

// waiting-room-media/channel/trivia Action Start
export const fetchWaitingRoomChannelTriviaRequest = () => {
	return {
		type: GET_WAITING_ROOM_CHANNEL_TRIVIA_REQUEST,
		loading: true,
		waitingRoomsChannelTrivia: [],
		error: null,
	};
};
export const fetchWaitingRoomChannelTriviaSuccess = (channels) => {
	return {
		type: GET_WAITING_ROOM_CHANNEL_TRIVIA_SUCCESS,
		loading: false,
		waitingRoomsChannelTrivia: channels,
		error: null,
	};
};
export const fetchWaitingRoomChannelTriviaFailure = (error) => {
	return {
		type: GET_WAITING_ROOM_CHANNEL_TRIVIA_FAILURE,
		loading: true,
		waitingRoomsChannelTrivia: [],
		error: error,
	};
};
// waiting-room-media/channel/trivia Action Creator Start
export const fetchWaitingRoomChannelTrivia = (params) => {
	return (dispatch) => {
		dispatch(fetchWaitingRoomChannelTriviaRequest());
		try {
			HttpUtility.getuserRequest(GET_WAITINGROOM_CHANNEL_TRIVIA,
				// params
			).then(
				(response) => {
					if (response.status === 200) {
						const channels = response.data;
						dispatch(fetchWaitingRoomChannelTriviaSuccess(channels));
					} else {
						dispatch(fetchWaitingRoomChannelTriviaFailure(response));
					}
				}
			);
		} catch (error) {
			const errMessage = error.message;
			dispatch(fetchWaitingRoomChannelTriviaFailure(errMessage));
		}
	};
};
// waiting-room-media/channel/trivia Action Creator End

// waiting-room-media/channel/trivia Action End

// Waiting Room Channel Media Header Action Start
export const postWaitingRoomChannelHeaderRequest = () => {
	return {
		type: POST_WAITINGROOM_MEDIA_HEADER_REQUEST,
		loading: true,
		error: null,
	};
};

export const postWaitingRoomChannelHeaderSuccess = (message) => {
	return {
		type: POST_WAITINGROOM_MEDIA_HEADER_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};

export const postWaitingRoomChannelHeaderFailure = (error) => {
	return {
		type: POST_WAITINGROOM_MEDIA_HEADER_FAILURE,
		loading: true,
		message: '',
		error: error,
	};
};
// Waiting Room Channel Media Header Action Creator Start
export const postWaitingRoomChannelHeader = (params, formData) => {
	let data = {
		practice_name: formData.practice_name,
	};
	return async (dispatch) => {
		dispatch(postWaitingRoomChannelHeaderRequest());
		try {
			axios
				.post(POST_WAITING_ROOM_HEADER, data, {
					headers: {
						Authorization: 'Bearer ' + params.token_info.access_token,
					},
				})
				.then((response) => {
					const message = response.data.message;
					dispatch(postWaitingRoomChannelHeaderSuccess(message));
					alert(message);
				});
		} catch (error) {
			dispatch(postWaitingRoomChannelHeaderFailure('SOME ERROR'));
		}
	};
};
// Waiting Room Channel Media Header Action Creator End

// Waiting Room Channel Media Header Action End

// Waiting Room Channels Action Start
export const getWebsiteRoomMediaChannelListRequest = () => {
	return {
		type: GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_REQUEST,
		loading: true,
		// waitingRoomMediaChannels: [],
		error: null,
	};
};

export const getWebsiteRoomMediaChannelListSuccess = (data) => {
	return {
		type: GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_SUCCESS,
		loading: false,
		waitingRoomMediaChannels: data,
		error: null,
	};
};

export const getWebsiteRoomMediaChannelListFailure = (error) => {
	return {
		type: GET_WEBSITEROOM_MEDIA_CHANNEL_LIST_FAILURE,
		loading: true,
		// waitingRoomMediaChannels: [],
		error: error,
	};
};
// Waiting Room Channels Action Creator Start

export const getWebsiteRoomMediaChannelList = (params, sortedBy, sortedType, isCorporate) => {
	console.log("params, sortedBy, sortedType", params, sortedBy, sortedType, isCorporate)
	return async (dispatch) => {
		dispatch(getWebsiteRoomMediaChannelListRequest());
		try {
			var sort = sortedBy ? sortedBy : 'newest';
			var type = sortedType ? sortedType : 'all';
			var url = `${WAITINGROOM_CHANNEL}?sorted_by=${sort}&sorted_type=${type}`;
			if(isCorporate){
				url = `${CORPORATE_WAITING_ROOM_CHANNEL}?sorted_by=${sort}&need_all=1`;
			}
			HttpUtility.getuserRequest(url).then(
				(response) => {
					if (response.status === 200) {
						const datum = response.data;
						dispatch(getWebsiteRoomMediaChannelListSuccess(datum));
					} else {
						const datum = response ? response.error : {};
						dispatch(getWebsiteRoomMediaChannelListFailure(datum));
					}
				}
			);
		} catch (error) {
			dispatch(getWebsiteRoomMediaChannelListFailure(error));
		}
	};
};
// Waiting Room Channels Action Creator End

// Waiting Room Channels Action End

// Waiting Room Channels Action Start
export const getWaitingRoomMediaChannelRequest = (data) => {
	return {
		type: GET_WAITINGROOM_MEDIA_CHANNEL_REQUEST,
		// loading: true,
		managewaitingRoomMediaChannel: data,
		error: null,
	};
};

export const getWaitingRoomMediaChannelSuccess = (data) => {
	return {
		type: GET_WAITINGROOM_MEDIA_CHANNEL_SUCCESS,
		// loading: false,
		managewaitingRoomMediaChannel: data,
		error: null,
	};
};

export const getWaitingRoomMediaChannelFailure = (error) => {
	return {
		type: GET_WAITINGROOM_MEDIA_CHANNEL_FAILURE,
		// loading: true,
		error: error,
	};
};
// Waiting Room Channels Action Creator Start

export const getWaitingRoomMediaChannel = (id) => {
	return async (dispatch) => {
		dispatch(getWaitingRoomMediaChannelRequest());
		try {
			HttpUtility.getuserRequest(`${WAITINGROOM_CHANNEL}/${id}`).then(
				(response) => {
					if (response.status === 200) {
						const datum = response.data;
						dispatch(getWaitingRoomMediaChannelSuccess(datum));
						// history.push(`/add-new-waiting-room-channel`);
						history.push({
							pathname: '/add-new-waiting-room-channel',
							state: {  // location state
							  isFromMenageChannel: true,
							},
						  });
					} else {
						dispatch(getWaitingRoomMediaChannelFailure(response));
					}
				}
			);
		} catch (error) {
			dispatch(getWaitingRoomMediaChannelFailure(error));
		}
	};
};
// Waiting Room Channels Action Creator End

// Waiting Room Channels Action End




// Waiting Room Channels restore Action Start
export const restoreWaitingRoomMediaChannelRequest = () => {
	return {
		type: RESTORE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST,
		// waitingRoomMediaChannels: [],
		error: null,
	};
};

export const restoreWaitingRoomMediaChannelSuccess = (data) => {
	return {
		type: RESTORE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS,
		waitingRoomMediaChannels: data,
		error: null,
	};
};

export const restoreWaitingRoomMediaChannelFailure = (error) => {
	return {
		type: RESTORE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE,
		// waitingRoomMediaChannels: [],
		error: error,
	};
};
// Waiting Room Channels Action Creator Start

export const restoreWaitingRoomMediaChannel = (id) => {
	return async (dispatch) => {
		dispatch(restoreWaitingRoomMediaChannelRequest());
		try {
			HttpUtility.getuserRequest(`${WAITINGROOM_CHANNEL}/${id}/restore`).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(restoreWaitingRoomMediaChannelSuccess(datum));
						dispatch(getWebsiteRoomMediaChannelList())
					} else {
						const message = response;
						dispatch(restoreWaitingRoomMediaChannelFailure(message));
					}
				}
			);
		} catch (error) {
			dispatch(restoreWaitingRoomMediaChannelFailure(error));
		}
	};
};
// Waiting Room Channels  restore Action Creator End

// Waiting Room Channels  restore Action End


// Waiting Room Channels restore Action Start
export const duplicateWaitingRoomMediaChannelRequest = () => {
	return {
		type: DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_REQUEST,
		// waitingRoomMediaChannels: [],
		error: null,
	};
};

export const duplicateWaitingRoomMediaChannelSuccess = (data) => {
	return {
		type: DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_SUCCESS,
		waitingRoomMediaChannels: data,
		error: null,
	};
};

export const duplicateWaitingRoomMediaChannelFailure = (error) => {
	return {
		type: DUPLICATE_WEBSITEROOM_MEDIA_CHANNEL_FAILURE,
		error: error,
	};
};
// Waiting Room Channels Action Creator Start

export const duplicateWaitingRoomMediaChannel = (id) => {
	return async (dispatch) => {
		dispatch(duplicateWaitingRoomMediaChannelRequest());
		try {
			HttpUtility.getuserRequest(`${WAITINGROOM_CHANNEL}/${id}/duplicate`).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(duplicateWaitingRoomMediaChannelSuccess(datum));
						dispatch(getWebsiteRoomMediaChannelList())
					} else {
						dispatch(duplicateWaitingRoomMediaChannelFailure(datum));
					}
				}
			)
		} catch (error) {
			dispatch(duplicateWaitingRoomMediaChannelFailure(error));
		}
	};
};
// Waiting Room Channels  restore Action Creator End

// Waiting Room Channels  restore Action End


// POST Footer Time in Waiting room Action Start
export const postFooterTimeRequest = () => {
	return {
		type: POST_WAITINGROOM_MEDIA_FOOTER_TIME_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};
export const postFooterTimeSuccess = (message) => {
	return {
		type: POST_WAITINGROOM_MEDIA_FOOTER_TIME_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};
export const postFooterTimeFailure = (error) => {
	return {
		type: POST_WAITINGROOM_MEDIA_FOOTER_TIME_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};
// POST Footer Time in Waiting room Action Creator Start
export const postWeatherTime = (id, params, isOperatiryRoom) => {
	return async (dispatch) => {
		dispatch(postFooterTimeRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/footer/time`
			if(isOperatiryRoom){
				url = `${OPERATORY_CHANNEL}/${id}/footer/time`
			}
			HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status === 200 && response.data != null) {
					const data = response.data;
					dispatch(postFooterTimeSuccess(data));
				} else {
					dispatch(postFooterTimeFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(postFooterTimeFailure(error));
		}
	};
};
// POST Footer Time in Waiting room Action Creator End

// POST Footer Time in Waiting room Action End

// POST Footer Weather in Waiting Room Action Start
export const postFooterWeatherRequest = () => {
	return {
		type: POST_WAITINGROOM_MEDIA_WEATHER_REQUEST,
		message: null,
		error: null,
	};
};

export const postFooterWeatherSuccess = (message) => {
	return {
		type: POST_WAITINGROOM_MEDIA_WEATHER_SUCCESS,
		message: message,
		error: null,
	};
};

export const postFooterWeatherFailure = (error) => {
	return {
		type: POST_WAITINGROOM_MEDIA_WEATHER_FAILURE,
		error: error,
	};
};
// POST Footer Weather in Waiting Room Action Creator Start
export const postFooterWeather = (id, params, isOperatory) => {
	return async (dispatch) => {
		dispatch(postFooterWeatherRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/${GET_WAITINGROOM_CHANNEL_FOOTER_WEATHER}`
			if(isOperatory){
				url = `${OPERATORY_CHANNEL}/${id}/footer/weather`
			}
			await HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status === 200 ) {
					dispatch(postFooterWeatherSuccess(response.data));
				} else {
					dispatch(postFooterWeatherFailure(response));
				}
			});
		} catch (error) {
			dispatch(postFooterWeatherFailure(error));
		}
	};
};
// POST Footer Weather in Waiting Room Action Creator End

// POST Footer Weather in Waiting Room Action End

// POST Footer Custom Messages in Waiting Room Action Start
export const postChannelWiseCustomMessagesRequest = () => {
	return {
		type: POST_CHANNELWISE_CUSTOM_MESSAGES_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};
export const postChannelWiseCustomMessagesSuccess = (message) => {
	return {
		type: POST_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};
export const postChannelWiseCustomMessagesFailure = (error) => {
	return {
		type: POST_CHANNELWISE_CUSTOM_MESSAGES_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};
// POST Footer Custom Messages in Waiting Room Action Creator Start
export const postChannelWiseCustomMessages = (id, params, isOperatiryRoom) => {
	return async (dispatch) => {
		dispatch(postChannelWiseCustomMessagesRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/footer/custom-messages`
			if(isOperatiryRoom){
				url = `${OPERATORY_CHANNEL}/${id}/footer/custom-messages`
			}
			HttpUtility.userPostRequest(url, params).then((response) => {
				const datum = response.data;
				if (response.status === 200) {
					dispatch(postChannelWiseCustomMessagesSuccess(datum));
					dispatch(getCustomMessage(id, isOperatiryRoom))
				} else {
					dispatch(postChannelWiseCustomMessagesFailure(datum));
				}
			}
			)
		} catch (error) {
			dispatch(postChannelWiseCustomMessagesFailure(error));
		}
		// try {
		// 	axios
		// 		.post(POST_CHANNELWISE_CUSTOM_MESSAGES, data, {
		// 			headers: {
		// 				Authorization: 'Bearer ' + params.token_info.access_token,
		// 			},
		// 		})
		// 		.then((response) => {
		// 			const message = response.message;
		// 			dispatch(postChannelWiseCustomMessagesSuccess(message));
		// 		});
		// } catch (error) {
		// 	dispatch(postChannelWiseCustomMessagesFailure(error.message));
		// }
	};
};
// POST Footer Custom Messages in Waiting Room Action Creator End
// POST Footer Custom Messages in Waiting Room Action End

// GET Footer Weather in Waiting Room Action Start
export const getFooterWeatherRequest = () => {
	return {
		type: GET_WAITINGROOM_WEATHER_REQUEST,
		loading: true,
		weatherLocation: {},
		error: null,
	};
};
export const getFooterWeatherSuccess = (data) => {
	return {
		type: GET_WAITINGROOM_WEATHER_SUCCESS,
		loading: false,
		weatherLocation: data,
		error: null,
	};
};
export const getFooterWeatherFailure = (error) => {
	return {
		type: GET_WAITINGROOM_WEATHER_FAILURE,
		loading: true,
		weatherLocation: {},
		error: error,
	};
};
// GET Footer Weather in Waiting Room Action Creator Start
export const getFooterWeather = (id) => {
	return async (dispatch) => {
		dispatch(getFooterWeatherRequest());
		HttpUtility.getuserRequest(`${WAITINGROOM_CHANNEL}/${id}/${GET_WAITINGROOM_CHANNEL_FOOTER_WEATHER}`).then((response) => {
			const channels = response.data;
			if (response.status === 200 && response.data != null) {
				dispatch(getFooterWeatherSuccess(channels));
			} else {
				const message = 'Error';
				dispatch(getFooterWeatherFailure(message));
			}
		}
		);
	};
};
// GET Footer Weather in Waiting Room Action Creator End
// GET Footer Weather in Waiting Room Action End

// DELETE Footer Custom Message in Waiting Room Action Start
export const deleteWaitingRoomCustomMessageRequest = () => {
	return {
		type: DELETE_WAITINGROOM_CUSTOM_MESSAGES_REQUEST,
		buttonLoading: true,
		message: null,
		error: null,
	};
};
export const deleteWaitingRoomCustomMessageSuccess = (message) => {
	return {
		type: DELETE_WAITINGROOM_CUSTOM_MESSAGES_SUCCESS,
		buttonLoading: false,
		message: message,
		error: null,
	};
};
export const deleteWaitingRoomCustomMessageFailure = (error) => {
	return {
		type: DELETE_WAITINGROOM_CUSTOM_MESSAGES_FAILURE,
		buttonLoading: false,
		message: null,
		error: error,
	};
};
// DELETE Footer Custom Message in Waiting Room Action Creator Start
export const deleteWaitingRoomCustomMessage = (id, params, isOperatiryRoom) => {
	return async (dispatch) => {
		dispatch(deleteWaitingRoomCustomMessageRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/footer/custom-messages`
			if(isOperatiryRoom){
				url = `${OPERATORY_CHANNEL}/${id}/footer/custom-messages`
			}
			HttpUtility.deleteUserRequest(url, params).then((response) => {

				if (response.status === 200) {
					const datum = response.data;
					dispatch(deleteWaitingRoomCustomMessageSuccess(datum));
					dispatch(getCustomMessage(id, isOperatiryRoom))
				} else {
					dispatch(deleteWaitingRoomCustomMessageFailure(response));
				}
			}
			)
		} catch (error) {
			dispatch(deleteWaitingRoomCustomMessageFailure(error));
		}

	};
};
// DELETE Footer Custom Message in Waiting Room Action Creator End

// DELETE Footer Custom Message in Waiting Room Action End

// Location Services

export const GetOwnerLocations = (params, isUnAssignLocation) => {
	return async (dispatch) => {
		dispatch({ type: GET_LOCATION_LISTING_REQUEST });
		try {
			await HttpUtility.getuserRequest(`${GET_OWNER_LOCATIONS}`, params).then(
				(response) => {
					const getLocationsList = response.data;
					if (response.status === 200) {
						if(isUnAssignLocation){
							dispatch({
								type: GET_UNASSIGN_LOCATION_LISTING_SUCCESS,
								unassignedLocations: response.data,
							});
						}else{
							dispatch({
								type: GET_LOCATION_LISTING_SUCCESS,
								getLocationsList: getLocationsList,
							});
						}
					} else {
						dispatch({
							type: GET_LOCATION_LISTING_FAILURE,
							error: getLocationsList,
							refersend: false,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_LOCATION_LISTING_FAILURE, error: error });
		}
	};
};

export const GetOwnerLocationsManager = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_LOCATION_MANAGER_LISTING_REQUEST });
		try {
			await HttpUtility.getuserRequest(`${GET_OWNER_LOCATIONS_MANAGER}`,params).then((response) => {
				const getLocationsManagerList = response.data;
				if (response.status === 200) {
					dispatch({
						type: GET_LOCATION_MANAGER_LISTING_SUCCESS,
						getLocationsManagerList: getLocationsManagerList,
					});
				} else {
					dispatch({
						type: GET_LOCATION_MANAGER_LISTING_FAILURE,
						error: getLocationsManagerList,
						refersend: false,
					});
				}
			});
		} catch (error) {
			dispatch({ type: GET_LOCATION_LISTING_FAILURE, error: error });
		}
	};
};
// GET Website Media Layouts in Customization Action Start
// GET_WEBSITE_MEDIA_LAYOUTS_SUCCESS
// GET_WEBSITE_MEDIA_LAYOUTS_FAILURE
export const getWebsiteMediaLayoutsRequest = () => {
	return {
		type: GET_WEBSITE_MEDIA_LAYOUTS_REQUEST,
		error: null,
		websiteMediaLayouts: [],
	};
};

export const getWebsiteMediaLayoutsSuccess = (layouts) => {
	return {
		type: GET_WEBSITE_MEDIA_LAYOUTS_SUCCESS,
		error: null,
		websiteMediaLayouts: layouts,
	};
};

export const getWebsiteMediaLayoutsFailure = (error) => {
	return {
		type: GET_WEBSITE_MEDIA_LAYOUTS_FAILURE,
		error: error,
		websiteMediaLayouts: [],
	};
};
// GET Website Media Layouts in Customization Action Creator Start
export const getWebsiteMediaLayouts = (params) => {
	return async (dispatch) => {
		dispatch(getWebsiteMediaLayoutsRequest());
		try {
			HttpUtility.getuserRequest(GET_WEBSITE_MEDIA_LAYOUTS).then(
				(response) => {
					if (response.status === 200) {
						const layouts = response.data;
						dispatch(getWebsiteMediaLayoutsSuccess(layouts));
					} else {
						const message = response.message;
						dispatch(getWebsiteMediaLayoutsFailure(message));
					}
				}
			);
		} catch (error) {
			dispatch(getWebsiteMediaLayoutsFailure(error.message));
		}
	};
};
// GET Website Media Layouts in Customization Action Creator End

// GET Website Media Layouts in Customization Action End

// GET Website Media Colours in Customization Action Start
export const getWebsiteMediaColoursRequest = () => {
	return {
		type: GET_WEBSITE_MEDIA_COLOURS_REQUEST,
		loading: true,
		// websiteMediaColours: [],
		error: null,
	};
};

export const getWebsiteMediaColoursSuccess = (data) => {
	return {
		type: GET_WEBSITE_MEDIA_COLOURS_SUCCESS,
		loading: false,
		websiteMediaColours: data,
		error: null,
	};
};

export const getWebsiteMediaColoursFailure = (error) => {
	return {
		type: GET_WEBSITE_MEDIA_COLOURS_FAILURE,
		loading: true,
		// websiteMediaColours: [],
		error: error,
	};
};
// GET Website Media Colours in Customization Action Creator Start
export const getWebsiteMediaColours = (params) => {
	return async (dispatch) => {
		dispatch(getWebsiteMediaColoursRequest());
		try {
			HttpUtility.getuserRequest(GET_WEBSITE_MEDIA_COLOURS).then(
				(response) => {
					if (response.status === 200) {
						const colours = response.data;
						dispatch(getWebsiteMediaColoursSuccess(colours));
					} else {
						const message = 'Error';
						dispatch(getWebsiteMediaColoursFailure(message));
					}
				}
			);
		} catch (error) {
			dispatch(getWebsiteMediaColoursFailure(error.message));
		}
	};
};
// GET Website Media Colours in Customization Action Creator End
// GET Website Media Colours in Customization Action End

// GET Helper Newsfeed in Waiting Room Footer Action Start
//     GET_HELPER_NEWSFEED_SUCCESS,
//     GET_HELPER_NEWSFEED_FAILURE,
export const getHelperNewsfeedRequest = () => {
	return {
		type: GET_HELPER_NEWSFEED_REQUEST,
		loading: true,
		helperNewsfeeds: [],
		error: null,
	};
};

export const getHelperNewsfeedSuccess = (data) => {
	return {
		type: GET_HELPER_NEWSFEED_SUCCESS,
		loading: false,
		helperNewsfeeds: data,
		error: null,
	};
};

export const getHelperNewsfeedFailure = (error) => {
	return {
		type: GET_HELPER_NEWSFEED_FAILURE,
		loading: false,
		helperNewsfeeds: [],
		error: error,
	};
};

// GET Helper Newsfeed in Waiting Room Footer Action Creator Start
export const getHelperNewsfeed = (channelId, isOperatiryRoom) => {
	return async (dispatch) => {
		dispatch(getHelperNewsfeedRequest());
		try {
			var url = `${GET_HELPER_NEWSFEED}?channel_id=${channelId}`;
			if(isOperatiryRoom)
				url = `${GET_HELPER_NEWSFEED}?channel_id=${channelId}&type=operatory`
			HttpUtility.getuserRequest(url).then((response) => {
					if (response.status === 200) {
						const data = response.data;
						dispatch(getHelperNewsfeedSuccess(data));
					} else {
						const message = 'Error';
						dispatch(getHelperNewsfeedFailure(message));
					}
				}
			);
		} catch (error) {
			dispatch(getHelperNewsfeedFailure(error.message));
		}
	};
};


// post new feed
export const postNewsfeedRequest = () => {
	return { type: POST_NEWSFEED_REQUEST };
};

export const postNewsfeedSuccess = () => {
	return { type: POST_NEWSFEED_SUCCESS };
};

export const postNewsfeedFailure = (error) => {
	return { type: POST_NEWSFEED_FAILURE, error: error };
};

// post news feed Action Creator Start
export const postNewsfeed = (channelId, selected_newsfeed, isOperatiryRoom) => {
	return async (dispatch) => {
		dispatch(postNewsfeedRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${channelId}/footer/newsfeed`;
			if(isOperatiryRoom)
				url = `${OPERATORY_CHANNEL}/${channelId}/footer/newsfeed`;

			HttpUtility.userPostRequest(url, selected_newsfeed).then((response) => {
					if (response.status === 200) {
						const data = response.data;
						dispatch(postNewsfeedSuccess());
					} else {
						dispatch(postNewsfeedFailure(response.data.errors));
					}
				}
			);
		} catch (error) {
			dispatch(postNewsfeedFailure(error.message));
		}
	};
};

// post news feed Action Creator END

// GET Helper Newsfeed in Waiting Room Footer Action Creator End

// GET Helper Newsfeed in Waiting Room Footer Action End

// GET Display Options in Waiting Room Action Start
export const waitingRoomDisplayOptionsRequest = () => {
	return {
		type: GET_WAITINGROOM_DISPLAY_OPTIONS_REQUEST,
		loading: true,
		displayOptions: {},
		error: null,
	};
};

export const waitingRoomDisplayOptionsSuccess = (data) => {
	return {
		type: GET_WAITINGROOM_DISPLAY_OPTIONS_SUCCESS,
		loading: false,
		displayOptions: data,
		error: null,
	};
};

export const waitingRoomDisplayOptionsFailure = (error) => {
	return {
		type: GET_WAITINGROOM_DISPLAY_OPTIONS_FAILURE,
		loading: true,
		displayOptions: {},
		error: error,
	};
};
// GET Display Options in Waiting Room Action Creator Start
export const waitingRoomDisplayOptions = (id, isOperatiryRoom) => {
	return async (dispatch) => {
		dispatch(waitingRoomDisplayOptionsRequest());
		try {
			var url = ''
			if(isOperatiryRoom){
				url = `${OPERATORY_CHANNEL}/${id}`
			}else{
				url = `${WAITINGROOM_CHANNEL}/${id}`
			}
			HttpUtility.getuserRequest(url).then(
				(response) => {
					if (response.status === 200) {
						const data = response.data;
						dispatch(waitingRoomDisplayOptionsSuccess(data));
					} else {
						dispatch(waitingRoomDisplayOptionsFailure(response));
					}
				}
			);
		} catch (error) {
			waitingRoomDisplayOptionsFailure(error.message);
		}
	};
};
// GET Display Options in Waiting Room Action Creator End

// GET Display Options in Waiting Room Action End

// GET Display Options in Waiting Room Theme list Action Start
export const getwaitingRoomThemeListRequest = (waitingRoomThemeList) => {
	return {
		type: GET_WAITINGROOM_THEME_LIST_REQUEST,
		waitingRoomThemeList: waitingRoomThemeList,
		error: null,
	};
};

export const getwaitingRoomThemeListSuccess = (data) => {
	return {
		type: GET_WAITINGROOM_THEME_LIST_SUCCESS,
		waitingRoomThemeList: data,
		error: null,
	};
};

export const getwaitingRoomThemeListFailure = (error) => {
	return {
		type: GET_WAITINGROOM_THEME_LIST_FAILURE,
		error: error,
	};
};
// GET Display Options in Waiting Room Action Creator Start
export const getwaitingRoomThemeList = () => {
	return async (dispatch) => {
		dispatch(getwaitingRoomThemeListRequest());
		try {
			HttpUtility.getuserRequest(`${WAITINGROOM_CHANNEL_THEME_LIST}`).then(
				(response) => {
					const data = response.data;
					if (response.status === 200) {
						dispatch(getwaitingRoomThemeListSuccess(data));
					} else {
						dispatch(getwaitingRoomThemeListFailure(data));
					}
				}
			);
		} catch (error) {
			getwaitingRoomThemeListFailure(error);
		}
	};
};
// GET Display Options in Waiting Room Action Creator End

// GET Display Options in Waiting Room Theme list Action End


// DELETE Waiting Room Channels Action Start
export const deleteWaitingRoomChannelRequest = () => {
	return {
		type: DELETE_WAITINGROOM_MEDIA_CHANNEL_REQUEST,
		// message: null,
		error: null,
	};
};

export const deleteWaitingRoomChannelSuccess = (id) => {
	return {
		type: DELETE_WAITINGROOM_MEDIA_CHANNEL_SUCCESS,
		id: id,
		error: null,
	};
};

export const deleteWaitingRoomChannelFailure = (error) => {
	return {
		type: DELETE_WAITINGROOM_MEDIA_CHANNEL_FAILURE,
		// message: null,
		error: error,
	};
};

// DELETE Waiting Room Channels Action Creator Start
export const deleteWaitingRoomChannel = (id, params) => {
	return async (dispatch) => {
		dispatch(deleteWaitingRoomChannelRequest());
		try {
			HttpUtility.deleteUserRequest(`${WAITINGROOM_CHANNEL}/${id}`).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(deleteWaitingRoomChannelSuccess(id));
						// dispatch(getWebsiteRoomMediaChannelList())
					} else {
						dispatch(deleteWaitingRoomChannelFailure(datum));
					}
				}
			);
		} catch (error) {
			dispatch(deleteWaitingRoomChannelFailure(error));
		}
	};
};
// DELETE Waiting Room Channels Action Creator End

// DELETE Waiting Room Channels Action End


// add to my list channel
export const myListWaitingOperatoryRoomChannelRequest = () => {
	return {
		type: MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_REQUEST,
		error: null,
	};
};

export const myListWaitingOperatoryRoomChannelSuccess = (id, params, isOperatoryRoom) => {
	return {
		type: MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_SUCCESS,
		id,
		params,
		isOperatoryRoom,
		error: null,
	};
};

export const myListWaitingOperatoryRoomChannelFailure = (error) => {
	return {
		type: MY_LIST_WAITING_OPERATORY_ROOM_CHANNEL_FAILURE,
		error: error,
	};
};

// add to my list channel Start
export const myListWaitingOperatoryRoomChannel = (id, params, isOperatoryRoom) => {
	return async (dispatch) => {
		dispatch(myListWaitingOperatoryRoomChannelRequest());
		try {
			var request;
			var url = `${WAITINGROOM_CHANNEL}/${id}/mylist`;
			if(isOperatoryRoom)
				url = `${OPERATORY_CHANNEL}/${id}/mylist`;
			console.log(params.my_list,'params.my_list')
			if(params.my_list)
				request = HttpUtility.getuserRequest(url);
			else
				request = HttpUtility.deleteUserRequest(url);

				request.then((response) => {
					if (response.status === 200) {
						dispatch(myListWaitingOperatoryRoomChannelSuccess(id, params, isOperatoryRoom));
					} else {
						dispatch(myListWaitingOperatoryRoomChannelFailure(response.data.errors));
					}
				}
			);
		} catch (error) {
			dispatch(myListWaitingOperatoryRoomChannelFailure(error));
		}
	};
};
// add to my list channel End

// PUT Change Waiting Room Channel Rename Action Start
export const updateWaitingRoomChannelNameRequest = (channel) => {
	return {
		type: UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_REQUEST,
		managewaitingRoomMediaChannel: channel,
		error: null,
	};
};
export const updateWaitingRoomChannelBtnLoadingRequest = () => {
	return {
		type: UPDATE_WAITINGROOM_MEDIA_CHANNEL_LOADING_REQUEST,
	};
};


export const updateWaitingRoomChannelCCStatusSuccess = (id, params) => {
	return {
		type: UPDATE_WAITINGROOM_MEDIA_CHANNEL_CC_STATUS_SUCCESS,
		id,
		params,
		error: null,
	};
};
export const updateWaitingRoomChannelNameSuccess = (channel) => {
	return {
		type: UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_SUCCESS,
		managewaitingRoomMediaChannel: channel,
		error: null,
	};
};
export const updateWaitingRoomChannelNameFailure = (error) => {
	return {
		type: UPDATE_WAITINGROOM_MEDIA_CHANNEL_NAME_FAILURE,
		error: error
	};
};
// PUT Change Waiting Room Channel Rename Action Creator Start
export const updateWaitingRoomChannelName = (id, params,isAddChannel) => {
	return async (dispatch) => {
		dispatch(updateWaitingRoomChannelBtnLoadingRequest());
		try {
			return	HttpUtility.userPostRequest(`${WAITINGROOM_CHANNEL}/${id}`, params).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						if(params.cc_status){
							dispatch(updateWaitingRoomChannelCCStatusSuccess(id, params));
						}else{
							dispatch(updateWaitingRoomChannelNameSuccess(datum));
							// dispatch(getWebsiteRoomMediaChannelList())
						}
					} else {
						dispatch(updateWaitingRoomChannelNameFailure(datum));
					}
				}
			);
		} catch (error) {
			dispatch(updateWaitingRoomChannelNameFailure(error));
		}

	};
};
// PUT Change Waiting Room Channel Rename Action Creator End
// PUT Change Waiting Room Channel Rename Action End

// Create New Waiting Room Channel Action Start
export const createNewWaitingRoomChannelRequest = () => {
	return {
		type: CREATE_WAITING_ROOM_CHANNEL_REQUEST,
		loading: true,
		managewaitingRoomMediaChannel: {},
		error: null,
	};
};
export const createNewWaitingRoomChannelSuccess = (managewaitingRoomMediaChannel) => {
	return {
		type: CREATE_WAITING_ROOM_CHANNEL_SUCCESS,
		loading: false,
		managewaitingRoomMediaChannel: managewaitingRoomMediaChannel,
		error: null,
	};
};
export const createNewWaitingRoomChannelFailure = (error) => {
	return {
		type: CREATE_WAITING_ROOM_CHANNEL_FAILURE,
		loading: true,
		managewaitingRoomMediaChannel: {},
		error: error,
	};
};

// Create New Waiting Room Channel Action Creator Start
export const createNewWaitingRoomChannel = (params) => {
	return async (dispatch) => {
		dispatch(createNewWaitingRoomChannelRequest());
		try {
			await HttpUtility.userPostRequest(`${CREATE_NEW_WAITING_ROOM_CHANNEL}`, params).then((response) => {
				if (response.status === 200) {
					const managewaitingRoomMediaChannel = response.data;
					dispatch(createNewWaitingRoomChannelSuccess(managewaitingRoomMediaChannel));
					if(params.is_internal_user){
						// dispatch(getWaitingRoomMediaChannelSuccess(datum));
						history.push("/waiting-room-channel-timeline")
					}else{
						history.push({
							pathname: '/add-new-waiting-room-channel',
							state: {  // location state
							isFromMenageChannel: false,
							},
						});
					}
					// history.push(`/add-new-waiting-room-channel`);
				} else {
					dispatch(createNewWaitingRoomChannelFailure(response.data.errors));
				}
			}
			);
		} catch (error) {
			dispatch(createNewWaitingRoomChannelFailure(error));
		}
		// axios({
		// 	headers: {
		// 		Authorization: 'Bearer ' + params.token_info.access_token,
		// 	},
		// 	method: 'POST',
		// 	url: CREATE_NEW_WAITING_ROOM_CHANNEL,
		// 	data: {
		// 		channel: formData.channel,
		// 		title: formData.title,
		// 		practice_name: formData.practice_name,
		// 		header_status: formData.header_status,
		// 		footer_status: formData.footer_status,
		// 		display_custome_message_and_newsfeed:
		// 			formData.display_custome_message_and_newsfeed,
		// 		display_time_weather: formData.display_time_weather,
		// 	},
		// })
		// 	.then((response) => {
		// 		//DO SOMETHING
		// 		const datum = response.data;
		// 		dispatch(createNewWaitingRoomChannelSuccess(datum));
		// 	})
		// 	.catch((error) => {
		// 		dispatch(createNewWaitingRoomChannelFailure(error.message));
		// 	});
	};
};
// Create New Waiting Room Channel Action Creator End

// Create New Waiting Room Channel Action End

// UPDATE Waiting Room Channel Action Start
export const updateWaitingRoomChannelRequest = () => {
	return {
		type: UPDATE_WAITING_ROOM_CHANNEL_REQUEST,
		loading: true,
		manageChannel: {},
		error: null,
	};
};
export const updateWaitingRoomChannelSuccess = (channel) => {
	return {
		type: UPDATE_WAITING_ROOM_CHANNEL_SUCCESS,
		loading: false,
		manageChannel: channel,
		error: null,
	};
};
export const updateWaitingRoomChannelFailure = (error) => {
	return {
		type: UPDATE_WAITING_ROOM_CHANNEL_FAILURE,
		loading: true,
		manageChannel: {},
		error: error,
	};
};
// UPDATE Waiting Room Channel Action Creator Start
export const updateWaitingRoomChannel = (params, channelId) => {
	return async (dispatch) => {
		dispatch(updateWaitingRoomChannelRequest());
		axios
			.get(
				consts.APIBASEURL + `waiting-room-media/channel/${channelId}`,
				{
					headers: {
						Authorization: 'Bearer ' + params.token_info.access_token,
					},
				}
			)
			.then((response) => {
				const datum = response.data.data;
				dispatch(updateWaitingRoomChannelSuccess(datum));
			})
			.catch((error) => {
				dispatch(updateWaitingRoomChannelFailure(error.message));
			});
	};
};
// UPDATE Waiting Room Channel Action Creator End

// UPDATE Waiting Room Channel Action End

// UPDATE Details Of Waiting Room Channel Action Start
export const postUpdateWaitingRoomChannelRequest = () => {
	return {
		type: POST_UPDATE_WAITING_ROOM_CHANNEL_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};
export const postUpdateWaitingRoomChannelSuccess = (message) => {
	return {
		type: POST_UPDATE_WAITING_ROOM_CHANNEL_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};
export const postUpdateWaitingRoomChannelFailure = (error) => {
	return {
		type: POST_UPDATE_WAITING_ROOM_CHANNEL_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};
// UPDATE Details Of Waiting Room Channel Action Creator Start
export const postUpdateWaitingRoomChannel = (params, formData) => {
	return async (dispatch) => {
		dispatch(postUpdateWaitingRoomChannelRequest());

		axios({
			headers: {
				Authorization: 'Bearer ' + params.token_info.access_token,
			},
			method: 'POST',
			url: consts.APIBASEURL + `waiting-room-media/channel/${formData.id}`,
			data: {
				channel: formData.channel,
				title: formData.title,
				practice_name: formData.practice_name,
				header_status: formData.header_status,
				footer_status: formData.footer_status,
				display_custome_message_and_newsfeed:
					formData.display_custome_message_and_newsfeed,
				display_time_weather: formData.display_time_weather,
			},
		})
			.then((response) => {
				const datum = response.message;
				dispatch(postUpdateWaitingRoomChannelSuccess(datum));
			})
			.catch((error) => {
				dispatch(postUpdateWaitingRoomChannelFailure(error.message));
			});
	};
};
// UPDATE Details Of Waiting Room Channel Action Creator End
// UPDATE Details Of Waiting Room Channel Action End

// Add Location

export const AddOwnerLocations = (params) => {
	return async (dispatch) => {
		dispatch({ type: ADD_LOCATION_REQUEST });
		try {
			await HttpUtility.userPostRequest(`${POST_OWNER_LOCATION}`, params).then((response) => {
				const addLocationResponce = response;
				if (response.status === 200) {
					dispatch({
						type: ADD_LOCATION_SUCCESS,
						addLocationResponce: addLocationResponce,
					});
				} else {
					dispatch({
						type: ADD_LOCATION_FAILURE,
						error: addLocationResponce,
						refersend: false,
					});
				}
			}
			);
		} catch (error) {
			dispatch({ type: ADD_LOCATION_FAILURE, error: error });
		}
	};
};
// PUT Channel wise Custom Messages in Waiting Room Footer Action Start

export const putChannelwiseCustomMessagesRequest = () => {
	return {
		type: PUT_CHANNELWISE_CUSTOM_MESSAGES_REQUEST,
		loading: true,
		putCustomMessages: [],
		error: null,
	};
};
export const putChannelwiseCustomMessagesSuccess = (data) => {
	return {
		type: PUT_CHANNELWISE_CUSTOM_MESSAGES_SUCCESS,
		loading: false,
		putCustomMessages: data,
		error: null,
	};
};
export const putChannelwiseCustomMessagesFailure = (error) => {
	return {
		type: PUT_CHANNELWISE_CUSTOM_MESSAGES_FAILURE,
		loading: true,
		putCustomMessages: [],
		error: error,
	};
};
// PUT Channel wise Custom Messages in Waiting Room Footer Action Creator Start
export const putChannelwiseCustomMessages = (id, params) => {
	return async (dispatch) => {
		dispatch(putChannelwiseCustomMessagesRequest());
		try {
			HttpUtility.putuserRequest(`${WAITINGROOM_CHANNEL}/${id}/footer/custom-messages`, params).then((response) => {
				const datum = response.data;
				if (response.status === 200) {
					dispatch(putChannelwiseCustomMessagesSuccess(datum));
				} else {
					dispatch(putChannelwiseCustomMessagesFailure(datum));
				}
			}
			)
		} catch (error) {
			dispatch(putChannelwiseCustomMessagesFailure(error));
		}
		// try {
		// 	axios
		// 		.put(
		// 			`http://dev.thalamusmedia.com/waiting-room-media/channel/53/footer/custom-messages`,
		// 			{
		// 				headers: {
		// 					Authorization: 'Bearer ' + params.token_info.access_token,
		// 				},
		// 				data: {
		// 					rearrange: [
		// 						{
		// 							order: formData.order,
		// 							id: formData.id,
		// 						},
		// 					],
		// 				},
		// 			}
		// 		)
		// 		.then((response) => {
		// 			if (response.status === 200) {
		// 				const datum = response.data;
		// 				dispatch(putChannelwiseCustomMessagesSuccess(datum));
		// 			} else {
		// 				dispatch(putChannelwiseCustomMessagesFailure('Error occured'));
		// 			}
		// 		});
		// } catch (error) {
		// 	dispatch(putChannelwiseCustomMessagesFailure(error.message));
		// }
	};
};
// PUT Channel wise Custom Messages in Waiting Room Footer Action Creator End
// PUT Channel wise Custom Messages in Waiting Room Footer Action End

// Waiting Room TImeline Education Tab Action Start
export const newWaitingRoomTimelineEducationRequest = () => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_EDUCATION_REQUEST,
		loading: true,
		educationTimeline: [],
		error: null,
	};
};
export const newWaitingRoomTimelineEducationSuccess = (data) => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_EDUCATION_SUCCESS,
		loading: false,
		educationTimeline: data,
		error: null,
	};
};
export const newWaitingRoomTimelineEducationFailure = (error) => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_EDUCATION_FAILURE,
		loading: true,
		educationTimeline: [],
		error: error,
	};
};

// Waiting Room TImeline Education Tab Action Creator Start
export const newWaitingRoomTimelineEducation = (params) => {
	return async (dispatch) => {
		dispatch(newWaitingRoomTimelineEducationRequest());
		try {
			await HttpUtility.getuserRequest(
				NEW_WAITING_ROOM_TIMELINE_EDUCATION,
				// params
			).then((response) => {

				if (response.status === 200) {
					const educationData = response.data;
					dispatch(newWaitingRoomTimelineEducationSuccess(educationData));
				} else {
					dispatch(newWaitingRoomTimelineEducationFailure(response));
				}
			});
		} catch (error) {
			dispatch(newWaitingRoomTimelineEducationFailure(error));
		}
	};
};
// Waiting Room TImeline Education Tab Action Creator End

// Waiting Room TImeline Education Tab Action End

// Waiting Room TImeline Entertainment Tab Action Start

export const newWaitingRoomTimelineEntertainmentRequest = () => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_REQUEST,
		loading: true,
		entertainmentTimeline: [],
		error: null,
	};
};

export const newWaitingRoomTimelineEntertainmentSuccess = (data) => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_SUCCESS,
		loading: false,
		entertainmentTimeline: data,
		error: null,
	};
};

export const newWaitingRoomTimelineEntertainmentFailure = (error) => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT_FAILURE,
		loading: true,
		entertainmentTimeline: [],
		error: error,
	};
};
// Waiting Room TImeline Entertainment Tab Action Creator Start
export const newWaitingRoomTimelineEntertainment = () => {
	return async (dispatch) => {
		dispatch(newWaitingRoomTimelineEntertainmentRequest());
		try {
			await HttpUtility.getuserRequest(
				NEW_WAITING_ROOM_TIMELINE_ENTERTAINMENT,
				// params
			).then((response) => {
				if (response.status === 200) {
					const entertainmentData = response.data;
					dispatch(
						newWaitingRoomTimelineEntertainmentSuccess(entertainmentData)
					);
				} else {
					dispatch(newWaitingRoomTimelineEntertainmentFailure(response));
				}
			});
		} catch (error) {
			dispatch(newWaitingRoomTimelineEntertainmentFailure(error));
		}
	};
};

// Send Manager Invite

export const SendManagerInvite = (params) => {
	return async dispatch => {

		dispatch({ type: SEND_MANAGER_INVITE_REQUEST })
		try {
			await HttpUtility.userPostRequest(`${POST_OWNER_LOCATION}/assign/manager`, params).then((response) => {
				if (response.status === 200) {
					const managerInviteSend = response;
					dispatch({
						type: SEND_MANAGER_INVITE_SUCCESS,
						managerInviteSend: managerInviteSend,
					});

				} else {
					dispatch({ type: SEND_MANAGER_INVITE_FAILURE, error: response.data, refersend: false })
				}
			})
		}
		catch (error) {
			dispatch({ type: SEND_MANAGER_INVITE_FAILURE, error: error })
		}
	};
};
// Waiting Room TImeline Entertainment Tab Action Creator End

// Waiting Room TImeline Entertainment Tab Action End

// POST Waiting Room Footer Display Options Action Start
// POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST
//
// POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST
export const postUpdateWaitingRoomDisplayOptionsRequest = () => {
	return {
		type: POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};

export const postUpdateWaitingRoomDisplayOptionsSuccess = (message) => {
	return {
		type: POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};

export const postUpdateWaitingRoomDisplayOptionsFailure = (error) => {
	return {
		type: POST_UPDATE_WAITING_ROOM_CHANNEL_DISPLAY_OPTIONS_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};
// POST Waiting Room Footer Display Options Action Creator Start
export const postUpdateWaitingRoomDisplayOptions = (id, params, isOperatiryRoom) => {
	return async (dispatch) => {
		dispatch(postUpdateWaitingRoomDisplayOptionsRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}`;
			if(isOperatiryRoom){
				url = `${OPERATORY_CHANNEL}/${id}`;
			}
			HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status == 200) {
					dispatch(postUpdateWaitingRoomDisplayOptionsSuccess(params));
				} else {
					dispatch(postUpdateWaitingRoomDisplayOptionsFailure(response));
				}
			});
		} catch (error) {
			dispatch(postUpdateWaitingRoomDisplayOptionsFailure(error));
		}
	};
};
// POST Waiting Room Footer Display Options Action Creator End

// POST Waiting Room Footer Display Options Action End

// Waiting Room TImeline My Videos Tab Action Start
export const newWaitingRoomTimelineMyVideosRequest = (isReload) => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
		loading: true,
		// myVideosTimeline: [],
		error: null,
		isReload: isReload,
	};
};

export const newWaitingRoomTimelineMyVideosSuccess = (data) => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		loading: false,
		myVideosTimeline: data,
		error: null,
	};
};

export const newWaitingRoomTimelineMyVideosFailure = (error) => {
	return {
		type: NEW_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		loading: false,
		myVideosTimeline: [],
		error: error,
	};
};

// Waiting Room TImeline My Videos Tab Action Creator Start
export const newWaitingRoomTimelineMyVideos = (isReload) => {
	return async (dispatch) => {
		dispatch(newWaitingRoomTimelineMyVideosRequest(isReload));
		try {
			await HttpUtility.getuserRequest(
				NEW_WAITING_ROOM_TIMELINE_MYVIDEOS,
				// params
			).then((response) => {
				if (response.status === 200) {
					const myVideosData = response.data;
					dispatch(newWaitingRoomTimelineMyVideosSuccess(myVideosData));
				} else {
					dispatch(newWaitingRoomTimelineMyVideosFailure(response));
				}
			});
		} catch (error) {
			dispatch(newWaitingRoomTimelineMyVideosFailure(error.message));
		}
	};
};
// Waiting Room TImeline My Videos Tab Action Creator End

// Waiting Room TImeline My Videos Tab Action End

// Remove Video in Waiting Room TImeline My Videos Tab Action Start
export const removeWaitingRoomTimelineMyVideoRequest = () => {
	return {
		type: REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};

export const removeWaitingRoomTimelineMyVideoSuccess = (video) => {
	return {
		type: REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		loading: false,
		video: video,
		error: null,
	};
};

export const removeWaitingRoomTimelineMyVideoFailure = (error) => {
	return {
		type: REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};

// Remove Video in Waiting Room TImeline My Videos Tab Action Creator Start
export const removeWaitingRoomTimelineMyVideo = (params) => {
	return async (dispatch) => {
		dispatch(removeWaitingRoomTimelineMyVideoRequest());
		try {
			var url = `${REMOVE_WAITING_ROOM_TIMELINE_MYVIDEOS}/${params.waiting_room_media_id}`;
			HttpUtility.userdeleteRequest(url).then((response) => {
				if (response.status == 200) {
					dispatch(removeWaitingRoomTimelineMyVideoSuccess(params));
				} else {
					dispatch(removeWaitingRoomTimelineMyVideoSuccess(""));
				}
			});
		} catch (error) {
			dispatch(removeWaitingRoomTimelineMyVideoFailure(error.message));
		}
	};
};
// Remove Video in Waiting Room TImeline My Videos Tab Action Creator End
// Remove Video in Waiting Room TImeline My Videos Tab Action End


// Remove slide in Waiting Room TImeline My slide Tab Action Start
export const removeWaitingRoomTimelineMySlideRequest = () => {
	return {
		type: REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};

export const removeWaitingRoomTimelineMySlideSuccess = (slide) => {
	return {
		type: REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_SUCCESS,
		loading: false,
		slide: slide,
		error: null,
	};
};

export const removeWaitingRoomTimelineMySlideFailure = (error) => {
	return {
		type: REMOVE_WAITING_ROOM_TIMELINE_MYSLIDE_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};

// Remove slide in Waiting Room TImeline My Videos Tab Action Creator
export const removeWaitingRoomTimelineMySlide = (params) => {
	return async (dispatch) => {
		dispatch(removeWaitingRoomTimelineMySlideRequest());
		try {
			var url = `${WAITING_ROOM_CHANNEL_SLIDE}/${params.id}`;
			var param = {
				slide_id: params.id
			}
			HttpUtility.userdeleteRequest(url, param).then((response) => {
				if (response.status == 200) {
					dispatch(removeWaitingRoomTimelineMySlideSuccess(params));
				} else {
					dispatch(removeWaitingRoomTimelineMyVideoFailure(response.message));
				}
			});
		} catch (error) {
			dispatch(removeWaitingRoomTimelineMyVideoFailure(error.message));
		}
	};
};
// Remove slide in Waiting Room TImeline My slide Tab Action end



// Upadate Video in Waiting Room TImeline My Videos Tab Action Creator Start
export const updateWaitingRoomTimelineMyVideoRequest = () => {
	return {
		type: UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};

export const updateWaitingRoomTimelineMyVideoSuccess = (responseData) => {
	return {
		type: UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		loading: false,
		data: responseData,
		error: null,
	};
};

export const updateWaitingRoomTimelineMyVideoFailure = (error) => {
	return {
		type: UPDATE_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		loading: false,
		message: null,
		error: error,
	};
};

export const updateWaitingRoomTimelineMyVideo = (params, updateError) => {
	return async (dispatch) => {
		dispatch(updateWaitingRoomTimelineMyVideoRequest());
		try {
			var url = `${WAITING_ROOM_MEDIA}/${params.waiting_room_media_id}`
			HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status == 200) {
					const responseData = response.data;
					dispatch(updateWaitingRoomTimelineMyVideoSuccess(responseData))
				} else {
					updateError("Name already exists");
					dispatch(updateWaitingRoomTimelineMyVideoFailure(response.message));
				}
			})
		} catch (error) {
			dispatch(updateWaitingRoomTimelineMyVideoFailure(error.message));
		}
	};
};


// Waiting Room TImeline My Videos Tab Action UPLOAD
export const uploadWaitingRoomTimelineMyVideos = (params, goMyVideo) => {
	return async (dispatch) => {
		dispatch(uploadWaitingRoomMyVideosRequest());
		try {
			await HttpUtility.userPostRequest(WAITING_ROOM_MEDIA, params).then((response) => {
				const myVideosData = response.data;
				if (response.status === 200) {
					goMyVideo();
					dispatch(newWaitingRoomTimelineMyVideos());
					dispatch(uploadWaitingRoomMyVideosSuccess(myVideosData, goMyVideo));
				} else {
					dispatch(uploadWaitingRoomMyVideosFailure('Error occured'));
				}
			});
		} catch (error) {
			dispatch(uploadWaitingRoomMyVideosFailure(error.message));
		}
	};
};

// Slides Stock Library List in Waiting Room Timeline Action Start
export const getSlidesStockLibraryWaitingRoomRequest = () => {
	return {
		type: GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_REQUEST,
		loading: true,
		stockLibrary: [],
		error: null,
	};
};

export const getSlidesStockLibraryWaitingRoomSuccess = (data) => {
	return {
		type: GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_SUCCESS,
		loading: false,
		stockLibrary: data,
		error: null,
	};
};

export const getSlidesStockLibraryWaitingRoomFailure = (error) => {
	return {
		type: GET_SLIDES_STOCK_LIBRARY_WAITINGROOM_FAILURE,
		loading: true,
		stockLibrary: [],
		error: error,
	};
};
// Slides Stock Library List in Waiting Room Timeline Action Creator Start
export const getSlidesStockLibraryWaitingRoom = (params) => {
	return async (dispatch) => {
		dispatch(getSlidesStockLibraryWaitingRoomRequest());
		try {
			await HttpUtility.getuserRequest(
				SLIDES_STOCK_LIBRARY_WAITINGROOM,
				params
			).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(getSlidesStockLibraryWaitingRoomSuccess(datum));
				} else {
					dispatch(getSlidesStockLibraryWaitingRoomFailure('Error occured'));
				}
			});
		} catch (error) {
			dispatch(getSlidesStockLibraryWaitingRoomFailure(error.message));
		}
	};
};

// Slides Stock Library List in Waiting Room Timeline Action Creator End

// Slides Stock Library List in Waiting Room Timeline Action End

// Slides Stock Library Search in Waiting Room Timeline Action Start
export const getSlideStockLibrarySearchRequest = () => {
	return {
		type: GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_REQUEST,
		loading: true,
		stockLibrarySearch: [],
		error: null,
	};
};
export const getSlideStockLibrarySearchSuccess = (data) => {
	return {
		type: GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_SUCCESS,
		loading: false,
		stockLibrarySearch: data,
		error: null,
	};
};
export const getSlideStockLibrarySearchFailure = (error) => {
	return {
		type: GET_SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM_FAILURE,
		loading: true,
		stockLibrarySearch: [],
		error: error,
	};
};
// Slides Stock Library Search in Waiting Room Timeline Action Creator Start
export const getSlideStockLibrarySearch = (params) => {
	return async (dispatch) => {
		dispatch(getSlideStockLibrarySearchRequest());
		try {
			await HttpUtility.getuserRequest(
				SLIDES_STOCK_LIBRARY_SEARCH_WAITINGROOM,
				params
			).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(getSlideStockLibrarySearchSuccess(datum));
				} else {
					dispatch(getSlideStockLibrarySearchFailure('Error occured'));
				}
			});
		} catch (error) {
			dispatch(getSlideStockLibrarySearchFailure(error.message));
		}
	};
};
// Slides Stock Library Search in Waiting Room Timeline Action Creator End

// Slides Stock Library Search in Waiting Room Timeline Action End

// Categories for uploading my videos tab in Waiting Room TImeline Action Start
export const getMyVideosCategoryListRequest = () => {
	return {
		type: GET_MYVIDEOS_CATEGORY_LIST_REQUEST,
		loading: true,
		myVideosCategories: [],
		error: null,
	};
};
export const getMyVideosCategoryListSuccess = (data) => {
	return {
		type: GET_MYVIDEOS_CATEGORY_LIST_SUCCESS,
		loading: false,
		myVideosCategories: data,
		error: null,
	};
};
export const getMyVideosCategoryListFailure = (error) => {
	return {
		type: GET_MYVIDEOS_CATEGORY_LIST_FAILURE,
		loading: true,
		myVideosCategories: [],
		error: error,
	};
};
// Categories for uploading my videos tab in Waiting Room TImeline Action Creator Start
export const getMyVideosCategoryList = () => {
	return async (dispatch) => {
		dispatch(getMyVideosCategoryListRequest());
		try {
			// await HttpUtility.getuserRequest(
			// 	GET_MYVIDEOS_CATEGORY_LIST,
			// 	params
			// ).then((response) => {
			// 	if (response.status === 200) {
			// 		const datum = response.data;
			// 		dispatch(getMyVideosCategoryListSuccess(datum));
			// 	} else {
			// 		dispatch(getMyVideosCategoryListFailure('Error occured'));
			// 	}
			// });
			axios.get(GET_MYVIDEOS_CATEGORY_LIST).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(getMyVideosCategoryListSuccess(datum));
				} else {
					dispatch(getMyVideosCategoryListFailure('Error occured'));
				}
			});
		} catch (error) {
			dispatch(getMyVideosCategoryListFailure(error.message));
		}
	};
};
// Categories for uploading my videos tab in Waiting Room TImeline Action Creator End

// Categories for uploading my videos tab in Waiting Room TImeline Action End

// Upload My Videos to Wistia in Waiting Room Timeline Action Start
export const uploadMyVideosWaitingRoomRequest = () => {
	return {
		type: UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};
export const uploadMyVideosWaitingRoomSuccess = (message) => {
	return {
		type: UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};
export const uploadMyVideosWaitingRoomFailure = (error) => {
	return {
		type: UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};

// Upload My Videos to Wistia in Waiting Room Timeline Action Creator Start
export const uploadMyVideosWaitingRoom = (formData) => {
	return async (dispatch) => {
		dispatch(uploadMyVideosWaitingRoomRequest());
		try {
			axios({
				method: 'POST',
				url: UPLOAD_MY_VIDEOS_WAITING_ROOM_TIMELINE,
				data: {
					file: formData.file,
				},
			}).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.message;
					dispatch(uploadMyVideosWaitingRoomSuccess(responseMessage));
				} else {
					dispatch(uploadMyVideosWaitingRoomFailure('Some error occured'));
				}
			});
		} catch (error) {
			dispatch(uploadMyVideosWaitingRoomFailure(error.message));
		}
	};
};
// Upload My Videos to Wistia in Waiting Room Timeline Action Creator End

// Upload My Videos to Wistia in Waiting Room Timeline Action End

// Request Practice video in waiting room all channels Action Start

export const postRequestPracticeVideoRequest = () => {
	return {
		type: POST_REQUEST_PRACTICE_VIDEO_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};
export const postRequestPracticeVideoSuccess = (message) => {
	return {
		type: POST_REQUEST_PRACTICE_VIDEO_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};
export const postRequestPracticeVideoFailure = (error) => {
	return {
		type: POST_REQUEST_PRACTICE_VIDEO_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};
export const postRequestPracticeVideo = (params, page) => {
	return async (dispatch) => {
		dispatch(postRequestPracticeVideoRequest());
		try {
			await HttpUtility.userPostRequest(`${REQUEST_PRACTICE_VIDEO}`, params).then((response) => {
				const responseMessage = response.data;
				if (response.status === 200) {
					dispatch(postRequestPracticeVideoSuccess(responseMessage));
					history.push(page);

				} else {
					dispatch(postRequestPracticeVideoFailure(responseMessage));
				}
			})
		}
		catch (error) {
			dispatch(postRequestPracticeVideoFailure(error));
		}
	};
};
// Request Practice video in waiting room all channels Action Creator Start

// POST Waiting Room Footer Display Options Action End
// Unassign/Revock Manager from Location
export const UnassignManager = (params) => {
	return async dispatch => {

		dispatch({ type: UNASSIGN_MANAGER_REQUEST })
		try {
			await HttpUtility.userPostRequest(`${POST_OWNER_LOCATION}/manager/unassign`, params).then((response) => {
				const unassignManager = response;
				if (response.status === 200) {
					dispatch({
						type: UNASSIGN_MANAGER_SUCCESS,
						unassignManager: unassignManager,
					});

				} else {
					dispatch({ type: UNASSIGN_MANAGER_FAILURE, error: unassignManager, refersend: false })
				}
			})
		}
		catch (error) {
			dispatch({ type: UNASSIGN_MANAGER_FAILURE, error: error })
		}
	};
};
// Request Practice video in waiting room all channels Action Creator End

// Request Practice video in waiting room all channels Action End

// Request Invoice Email Ids,
export const getInvoiceEmails = (practiceId) => {
	return async dispatch => {

		dispatch({ type: GET_INVOICE_EMAIL_REQUEST });
		try {
			var url = `${INVOICE}/email-details`;
			if(practiceId){
				url = `${CORPORATE}/${practiceId}/email-details`;
			}
			await HttpUtility.getuserRequest(url).then((response) => {
				const getInvoiceEmails = response;
				if (response.status === 200) {
					dispatch({
						type: GET_INVOICE_EMAIL_SUCCESS,
						getInvoiceEmails: getInvoiceEmails,
					});

				} else {
					dispatch({ type: GET_INVOICE_EMAIL_FAILURE, error: getInvoiceEmails, refersend: false })
				}
			})
		}
		catch (error) {
			dispatch({ type: GET_INVOICE_EMAIL_FAILURE, error: error })
		}
	};
};


export const UpdateInvoiceEmail = (params, practiceId) => {
	return async dispatch => {
		dispatch({ type: POST_INVOICE_EMAIL_REQUEST })
		try {
			var url = `${INVOICE}/email-details`;
			if(practiceId){
				url = `${CORPORATE}/${practiceId}/email-details`
			}
			await HttpUtility.userPostRequest(url, params).then((response) => {
				const invoiceMailUpdated = response;
				if (response.status === 200) {
					dispatch({
						type: POST_INVOICE_EMAIL_SUCCESS,
						invoiceMailUpdated: invoiceMailUpdated,
					});
				} else {
					dispatch({ type: POST_INVOICE_EMAIL_FAILURE, error: invoiceMailUpdated, refersend: false })
				}
			})
		}
		catch (error) {
			dispatch({ type: POST_INVOICE_EMAIL_FAILURE, error: error })
		}
	};
};
// Waiting Room Channel Slide Action Start
export const waitingRoomChannelSlideRequest = () => {
	return {
		type: WAITING_ROOM_CHANNEL_SLIDE_REQUEST,
		loading: true,
		waitingRoomChannelSlides: [],
		error: null,
	};
};
export const waitingRoomChannelSlideSuccess = (data) => {
	return {
		type: WAITING_ROOM_CHANNEL_SLIDE_SUCCESS,
		loading: false,
		waitingRoomChannelSlides: data,
		error: null,
	};
};
export const waitingRoomChannelSlideFailure = (error) => {
	return {
		type: WAITING_ROOM_CHANNEL_SLIDE_FAILURE,
		loading: true,
		waitingRoomChannelSlides: [],
		error: error,
	};
};
// Waiting Room Channel Slide Action Creator Start
export const waitingRoomChannelSlide = () => {
	return async (dispatch) => {
		dispatch(waitingRoomChannelSlideRequest());
		try {
			await HttpUtility.getuserRequest(WAITING_ROOM_CHANNEL_SLIDE).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(waitingRoomChannelSlideSuccess(datum));
				} else {
					dispatch(waitingRoomChannelSlideFailure('Some error occured'));
				}
			}
			);
		} catch (error) {
			dispatch(waitingRoomChannelSlideFailure(error.message));
		}
	};
};
// Waiting Room Channel Slide Action Creator End

// Waiting Room Channel Slide Action End

// GET Website Media Player Configuration Action Start
export const websiteMediaConfigRequest = () => {
	return {
		type: GET_WEBSITE_MEDIA_CONFIG_REQUEST,
		loading: true,
		// websiteMediaConfigs: {},
		error: null,
	};
};
export const websiteMediaConfigSuccess = (data) => {
	return {
		type: GET_WEBSITE_MEDIA_CONFIG_SUCCESS,
		loading: false,
		websiteMediaConfigs: data,
		error: null,
	};
};
export const websiteMediaConfigFailure = (error) => {
	return {
		type: GET_WEBSITE_MEDIA_CONFIG_FAILURE,
		loading: true,
		// websiteMediaConfigs: {},
		error: error,
	};
};
// GET Website Media Player Configuration Action Creator Start
export const websiteMediaConfig = () => {
	return async (dispatch) => {
		dispatch(websiteMediaConfigRequest());
		try {
			await HttpUtility.getuserRequest(WEBSITE_MEDIA_CONFIG).then(
				(response) => {
					if (response.status === 200) {
						const datum = response.data;
						dispatch(websiteMediaConfigSuccess(datum));
					} else {
						dispatch(websiteMediaConfigFailure(response));
					}
				}
			);
		} catch (error) {
			dispatch(websiteMediaConfigFailure(error));
		}
	};
};
// GET Website Media Player Configuration Action Creator End

// GET Website Media Player Configuration Action End

// POST Website Media Player Configuration Action Start
export const postWebsiteMediaConfigRequest = () => {
	return {
		type: POST_WEBSITE_MEDIA_CONFIG_REQUEST,
		message: null,
		error: null,
	};
};
export const postWebsiteMediaConfigSuccess = (message) => {
	return {
		type: POST_WEBSITE_MEDIA_CONFIG_SUCCESS,
		message: message,
		error: null,
	};
};
export const postWebsiteMediaConfigFailure = (error) => {
	return {
		type: POST_WEBSITE_MEDIA_CONFIG_FAILURE,
		message: null,
		error: error,
	};
};
// POST Website Media Player Configuration Action Cretor Start
export const postWebsiteMediaConfig = (params) => {
	return async (dispatch) => {
		dispatch(postWebsiteMediaConfigRequest());
		try {
			await HttpUtility.userPostRequest(WEBSITE_MEDIA_CONFIG, params).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data;
					dispatch(postWebsiteMediaConfigSuccess(responseMessage));
					dispatch(websiteMediaConfigSuccess(responseMessage));
				} else {
					dispatch(postWebsiteMediaConfigFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(postWebsiteMediaConfigFailure(error));
		}


	};
};
// POST Website Media Player Configuration Action Creator End

// POST Website Media Player Configuration Action End


/* add by milep */
// POST Website Media Player Publish Action Start
export const publishWebsiteMediaRequest = () => {
	return {
		type: PUBLISH_WEBSITE_MEDIA_REQUEST,
		message: null,
		error: null,
	};
};
export const publishWebsiteMediaSuccess = (message) => {
	return {
		type: PUBLISH_WEBSITE_MEDIA_SUCCESS,
		message: message,
		error: null,
	};
};
export const publishWebsiteMediaFailure = (error) => {
	return {
		type: PUBLISH_WEBSITE_MEDIA_FAILURE,
		message: null,
		error: error,
	};
};
// POST Website Media Player Publish Action Cretor Start
export const publishWebsiteMedia = () => {
	return async (dispatch) => {
		dispatch(publishWebsiteMediaRequest());
		try {
			await HttpUtility.getuserRequest(WEBSITE_MEDIA_PUBLISH).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data;
					dispatch(publishWebsiteMediaSuccess(responseMessage));
				} else {
					dispatch(publishWebsiteMediaFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(publishWebsiteMediaFailure(error));
		}
	};
};
//  POST Website Media Player Publish Action  End

//  POST Website Media Player Publish Action  End
/* add by milep  end */


// wista login
// export // Waiting Room TImeline My Videos Tab Action Creator Start
// export const newWaitingRoomTimelineMyVideos = (params) => {
// 	return async (dispatch) => {
// 		dispatch(newWaitingRoomTimelineMyVideosRequest());
// 		try {
// 			await HttpUtility.getuserRequest(
// 				NEW_WAITING_ROOM_TIMELINE_MYVIDEOS,
// 				params
// 			).then((response) => {
// 				const myVideosData = response.data;
// 				if (response.status === 200) {
// 					dispatch(newWaitingRoomTimelineMyVideosSuccess(myVideosData));
// 				} else {
// 					dispatch(newWaitingRoomTimelineMyVideosFailure('Error occured'));
// 				}
// 			});
// 		} catch (error) {
// 			dispatch(newWaitingRoomTimelineMyVideosFailure(error.message));
// 		}
// 	};
// };

// Waiting Room Timeline My Videos upload
export const uploadWaitingRoomMyVideosRequest = () => {
	return {
		type: UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_REQUEST,
		// buttonLoading: true,
		// myVideosTimeline: [],
		error: null,
	};
};

export const uploadWaitingRoomMyVideosSuccess = (data) => {
	return {
		type: UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		// buttonLoading: false,
		// myVideosTimeline: data,
		error: null,
	};
};

export const uploadWaitingRoomMyVideosFailure = (error) => {
	return {
		type: UPLOAD_WAITING_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		// buttonLoading: false,
		// myVideosTimeline: [],
		error: error,
	};
};

//Waiting room timeline slides stock images Action start
// Waiting room timeline slides stock images Creator Start
export const WaitingRoomTimelineSlidesStockImageRequest = () => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_REQUEST,
		templates: null,
	}
};


export const WaitingRoomTimelineSlidesStockImageSuccess = (data) => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_SUCCESS,
		stockImages: data,
	}
};

export const WaitingRoomTimelineSlidesStockImageFailure = (error) => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_STOCK_IMAGES_FAILURE,
		error: error,
	}
};
// Waiting room timeline slides stock images Creator End


export const WaitingRoomTimelineSlidesStockImages = (isOperatoryRoom) => {
	return async (dispatch) => {
		// dispatch(WaitingRoomTimelineSlidesStockImageRequest());
		let url = WAITINGROOMSLIDESSTOCKIMAGE;
		if(isOperatoryRoom){
			url = consts.OPERATORYSLIDESSTOCKIMAGE;
		}
		try {
			await HttpUtility.getuserRequest(url).then((response) => {
				console.log("SLIDEIMAGES", response);
				const responseData = response.data;
				dispatch(WaitingRoomTimelineSlidesStockImageSuccess(responseData));
			});
		} catch (error) {
			dispatch(WaitingRoomTimelineSlidesStockImageFailure(error));
		}
	};
};
//Waiting room timeline slides stock images Action end


// Create waiting room timeline slides  Action start
// Create waiting room timeline slides  Creator Start
export const setWaitingRoomTimelineSlide = (slide) => {
	return {
		type: SET_WAITING_ROOM_TIMELINE_NEW_SLIDE,
		payload: slide,
	}
};
// Create waiting room timeline slides  Creator End

export const setWaitingRoomTimelineNewSlide = (slide) => {
	return async (dispatch) => {
		// dispatch(WaitingRoomTimelineSlidesStockImageRequest());
		try {
			dispatch(setWaitingRoomTimelineSlide(slide));
		} catch (error) {
			// dispatch(WaitingRoomTimelineSlidesStockImageFailure(error));
		}
	}
}

export const SubscribeList = () => {
	return async dispatch => {

		dispatch({ type: GET_SUBSCRIBE_REQUEST })

		try {
			await HttpUtility.getuserRequest(SUBSCRIBE).then((response) => {
				const subscribeList = response.data;
				if (response.status === 200) {
					dispatch({
						type: GET_SUBSCRIBE_SUCCESS,
						subscribeList: subscribeList,
					});
				}
			})
		}
		catch (error) {
			dispatch({ type: GET_SUBSCRIBE_FAILURE, payload: error })
		}
	};
};
// social media
// Waiting Room TImeline Social media
export const addSocialMediaRequest = (providerId) => {
	return {
		type: ADD_SOCIAL_MEDIA_REQUEST,
		providerId: providerId,
		error: null,
	};
};

export const addSocialMediaRequestSuccess = (data, providerId, params) => {
	return {
		type: ADD_SOCIAL_MEDIA_SUCCESS,
		socailMedia: data,
		providerId: providerId,
		params: params,
		error: null,
	};
};

export const addSocialMediaRequestFailure = (error, providerId) => {
	return {
		type: ADD_SOCIAL_MEDIA_FAILURE,
		providerId: providerId,
		error: error,
	};
};

// Waiting Room get social media
export const getSocailMediaRequest = () => {
	return {
		type: GET_SOCIAL_REQUEST,
		error: null,
	};
};

export const getSocailMediaSuccess = (data) => {
	return {
		type: GET_SOCIAL_SUCCESS,
		socailMedia: data,
		error: null,
	};
};

export const getSocailMediaFailure = (error) => {
	return {
		type: GET_SOCIAL_FAILURE,
		error: error,
	};
};

// Waiting Room get socialmedia Start
export const getSocailMedia = () => {
	return async (dispatch) => {
		dispatch(getSocailMediaRequest());
		try {
			var url = `${GET_SOCIAL_MEDIA}`;
			await HttpUtility.getuserRequest(url).then((response) => {
				const socialMedia = response.data;
				if (response.status === 200) {
					dispatch(getSocailMediaSuccess(socialMedia));
				} else {
					dispatch(getSocailMediaFailure(response));
				}
			});
		} catch (error) {
			dispatch(getSocailMediaFailure(error.message));
		}
	};
};
// Waiting Room get socialmedia End

// Waiting Room remove social media
export const removeSocailMediaRequest = (providerId) => {
	return {
		type: REMOVE_SOCIAL_REQUEST,
		providerId: providerId,
		error: null,
	};
};

export const removeSocailMediaSuccess = (providerId) => {
	return {
		type: REMOVE_SOCIAL_SUCCESS,
		providerId: providerId,
		error: null,
	};
};

export const removeSocailMediaFailure = (error, providerId) => {
	return {
		type: REMOVE_SOCIAL_FAILURE,
		providerId: providerId,
		error: error,
	};
};

// Waiting Room get socialmedia Start
export const removeSocailMedia = (providerId) => {
	return async (dispatch) => {
		dispatch(removeSocailMediaRequest(providerId));
		try {
			var url = `${SOCIAL_MEDIA}/${providerId}`;
			await HttpUtility.deleteUserRequest(url).then((response) => {
				if (response.status === 200) {
					const socialMedia = response.data;
					dispatch(removeSocailMediaSuccess(providerId));
				} else {
					dispatch(removeSocailMediaFailure(response, providerId));
				}
			});
		} catch (error) {
			dispatch(removeSocailMediaFailure(error.message, providerId));
		}
	};
};
// Waiting Room get socialmedia End


// Waiting Room TImeline My Videos Tab Action Creator Start
export const addSocialMedia = (params) => {
	return async (dispatch) => {
		dispatch(addSocialMediaRequest(params.provider));
		try {
			var url = `${ADD_SOCIAL_MEDIA}/${params.provider}`;
			await HttpUtility.userPostRequest(url, params).then((response) => {
				const socialMedia = response.data;
				if (response.status === 200) {
					dispatch(addSocialMediaRequestSuccess(socialMedia, params.provider, params));
					// dispatch(getSocailMedia())
				} else {
					dispatch(addSocialMediaRequestFailure(response, params.provider));
				}
			});
		} catch (error) {
			dispatch(addSocialMediaRequestFailure(error.message, params.provider));
		}
	};
};
// Waiting Room TImeline My Videos Tab Action Creator End


// get Waiting Room new TimeLine
export const getTimelineRequest = (isReload) => {
	return {
		type: GET_TIMELINE_REQUEST,
		loading: true,
		error: null,
	};
};

export const getTimelineSuccess = (data) => {
	return {
		type: GET_TIMELINE_SUCCESS,
		loading: false,
		data: data,
		error: null,
	};
};

export const getTimelineFailure = (error) => {
	return {
		type: GET_TIMELINE_FAILURE,
		loading: false,
		error: error,
	};
};

// Waiting Room TImeline My Videos Tab Action Creator Start
export const getTimeline = (channelId, isOperatoryRoom, isChairsidePlayList) => {
	return async (dispatch) => {
		dispatch(getTimelineRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${channelId}/timeline`;
			if(isOperatoryRoom){
				url = `${OPERATORY_CHANNEL}/${channelId}/timeline`;
			}
			if(isChairsidePlayList){
				url = `${consts.CREATE_CHAIRSIDE_PLAYLIST}/${channelId}/timeline`;
			}
			await HttpUtility.getuserRequest(url).then((response) => {
				const channelTimeLine = response.data;
				if (response.status === 200) {
					dispatch(getTimelineSuccess(channelTimeLine));
				} else {
					dispatch(getTimelineFailure(response));
				}
			});
		} catch (error) {
			dispatch(getTimelineFailure(error.message));
		}
	};
};
// Waiting Room TImeline Tab Action Creator End


// Waiting Room new TimeLine
export const postTimelineRequest = (isReload) => {
	return {
		type: POST_TIMELINE_REQUEST,
		error: null,
	};
};

export const postTimelineSuccess = () => {
	return {
		type: POST_TIMELINE_SUCCESS,
		error: null,
	};
};

export const postTimelineFailure = (error) => {
	return {
		type: POST_TIMELINE_FAILURE,
		error: error,
	};
};

// Waiting Room TImeline My Videos Tab Action Creator Start
export const postTimeline = (channelId, params, isOperatoryRoom, isChairsidePlayList) => {
	return async (dispatch) => {
		dispatch(postTimelineRequest());
		try {
			var url=  `${WAITINGROOM_CHANNEL}/${channelId}/timeline`;

			if(isOperatoryRoom){
				url = `${OPERATORY_CHANNEL}/${channelId}/timeline`;
			}

			if(isChairsidePlayList){
				url = `${consts.CREATE_CHAIRSIDE_PLAYLIST}/${channelId}/timeline`;
			}
			await HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status === 200) {
					dispatch(postTimelineSuccess());
				} else {
					dispatch(postTimelineFailure(response));
				}
			});
		} catch (error) {
			dispatch(postTimelineFailure(error.message));
		}
	};
};
// Waiting Room TImeline My Videos Tab Action Creator End


// start by Dixit Solanki ( work releted operatory chanel)

// Create operatory Channel
export const createNewOperatoryChannelRequest = () => {
	return {
		type: CREATE_OPERATORY_CHANNEL_REQUEST,
		loading: true,
		manageOperatoryChannel: {},
		error: null,
	};
};
export const createNewOperatoryChannelSuccess = (manageOperatoryChannel) => {
	return {
		type: CREATE_OPERATORY_CHANNEL_SUCCESS,
		loading: false,
		manageOperatoryChannel: manageOperatoryChannel,
		error: null,
	};
};
export const createNewOperatoryChannelFailure = (error) => {
	return {
		type: CREATE_OPERATORY_CHANNEL_FAILURE,
		loading: true,
		manageOperatoryChannel: {},
		error: error,
	};
};

export const createNewOperatoryChannel = (params) => {
	return async (dispatch) => {
		dispatch(createNewOperatoryChannelRequest());
		try {
			await HttpUtility.userPostRequest(`${CREATE_NEW_OPERATORY_CHANNEL}`, params).then((response) => {
				if (response.status === 200) {
					const manageOperatoryChannel = response.data;
					dispatch(createNewOperatoryChannelSuccess(manageOperatoryChannel));
					// history.push(`/add-new-operatory-channel`);
					if(params.is_internal_user){
						history.push("/operatory-room-timeline")
					}else{
						history.push({
							pathname: `/add-new-operatory-channel`,
							state: {  // location state
								isFromMenageChannel: false,
							},
						});
					}
				} else {
					dispatch(createNewOperatoryChannelFailure(response.data.errors));
				}
			}
			);
		} catch (error) {
			dispatch(createNewOperatoryChannelFailure(error));
		}
	};
};

//Create waiting room timeline slides stock images Action end

//Create Waiting room timeline slides  Action start
// Create Waiting room timeline slides  Creator Start
export const CreatewaitingRoomTimelineSlidesRequest = () => {
	return {
		type: CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_REQUEST,
		templates: null,
	}
};

export const CreatewaitingRoomTimelineSlidesSuccess = (data) => {
	return {
		type: CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_SUCCESS,
		stockImages: data,
	}
};

export const CreatewaitingRoomTimelineSlidesFailure = (error) => {
	return {
		type: CREATE_WAITING_ROOM_TIMELINE_NEW_SLIDE_FAILURE,
		error: error,
	}
};

// Create Waiting room timeline slides  Creator End
export const CreatewaitingRoomTimelineSlides = (slide, selectedSlide) => {
	return async (dispatch) => {
		dispatch(CreatewaitingRoomTimelineSlidesRequest());
		try {
			var url = CREATEWAITINGROOMNEWSLIDE;
			var request;
			
			if(selectedSlide.id){ // update slide..!
				url = `${url}/${selectedSlide.id}`;
				request = HttpUtility.userPostRequest(url, slide);
			}
			else{
				request = HttpUtility.userPostRequest(url, slide);
			}

			request.then((response) => {
				if (response.status === 200) {
					const responseData = response.data;
					dispatch(CreatewaitingRoomTimelineSlidesSuccess(responseData));
					setTimeout(() => { 
						dispatch(waitingRoomChannelSlide());
					}, 1000)
					
				}
				else {
					dispatch(CreatewaitingRoomTimelineSlidesFailure(response));
				}
			});
		} catch (error) {
			dispatch(CreatewaitingRoomTimelineSlidesFailure(error));
		}
	};
};
//Create Waiting room timeline slides stock images Action end

// get operatory channel list
export const getOperatoryMediaChannelList = (params, sortedBy, sortedType, isCorporate) => {
	return async (dispatch) => {
		dispatch(getOperatoryMediaChannelListRequest());
		try {
			var sort = sortedBy != null ? sortedBy : 'newest';
			var type = sortedType ? sortedType : 'all';

			var url = `${OPERATORY_CHANNEL}?sorted_by=${sort}&sorted_type=${type}`;
			if(isCorporate){
				url = `${CORPORATE_OPERATORY_ROOM_CHANNEL}?sorted_by=${sort}&need_all=1`;
			}
			HttpUtility.getuserRequest(url).then((response) => {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(getOperatoryMediaChannelListSuccess(datum));
					} else {
						dispatch(getOperatoryMediaChannelListFailure(datum));
					}
				}
			);
		} catch (error) {
			dispatch(getOperatoryMediaChannelListFailure(error));
		}
	};
};

export const getOperatoryMediaChannelListRequest = () => {
	return {
		type: GET_OPERATORY_MEDIA_CHANNEL_LIST_REQUEST,
		loading: true,
		error: null,
	};
};

export const getOperatoryMediaChannelListSuccess = (data) => {
	return {
		type: GET_OPERATORY_MEDIA_CHANNEL_LIST_SUCCESS,
		loading: false,
		operatoryMediaChannels: data,
		error: null,
	};
};

export const getOperatoryMediaChannelListFailure = (error) => {
	return {
		type: GET_OPERATORY_MEDIA_CHANNEL_LIST_FAILURE,
		loading: true,
		error: error,
	};
};

// delete operatory Channel
export const deleteOperatoryChannelRequest = () => {
	return {
		type: DELETE_OPERATORY_CHANNEL_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};

export const deleteOperatoryChannelSuccess = (message) => {
	return {
		type: DELETE_OPERATORY_CHANNEL_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};

export const deleteOperatoryChannelFailure = (error) => {
	return {
		type: DELETE_OPERATORY_CHANNEL_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};

export const deleteOperatoryChannel = (id, params) => {
	return async (dispatch) => {
		dispatch(deleteOperatoryChannelRequest());
		try {
			HttpUtility.deleteUserRequest(`${OPERATORY_CHANNEL}/${id}`).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(deleteOperatoryChannelSuccess(datum));
						dispatch(getOperatoryMediaChannelList())
					} else {
						dispatch(deleteOperatoryChannelFailure(datum));
					}
				}
			);
		} catch (error) {
			dispatch(deleteOperatoryChannelFailure(error));
		}

	};
};

// duplicate operatory Channel
export const duplicateOperatoryChannelRequest = () => {
	return {
		type: DUPLICATE_OPERATORY_CHANNEL_REQUEST,
		error: null,
	};
};

export const duplicateOperatoryChannelSuccess = (data) => {
	return {
		type: DUPLICATE_OPERATORY_CHANNEL_SUCCESS,
		waitingRoomMediaChannels: data,
		error: null,
	};
};

export const duplicateOperatoryChannelFailure = (error) => {
	return {
		type: DUPLICATE_OPERATORY_CHANNEL_FAILURE,
		error: error,
	};
};


export const duplicateOperatoryChannel = (id) => {
	return async (dispatch) => {
		dispatch(duplicateOperatoryChannelRequest());
		try {
			HttpUtility.getuserRequest(`${OPERATORY_CHANNEL}/${id}/duplicate`).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(duplicateOperatoryChannelSuccess(datum));
						dispatch(getOperatoryMediaChannelList())
					} else {
						dispatch(duplicateOperatoryChannelFailure(datum));
					}
				}
			)
		} catch (error) {
			dispatch(duplicateOperatoryChannelFailure(error));
		}
	};
};

//  get operatory info Channel
export const getOperatoryChannelRequest = (data) => {
	return {
		type: GET_OPERATORY_CHANNEL_REQUEST,
		buttonLoading: true,
		// manageOperatoryChannel: data,
		error: null,
	};
};

export const getOperatoryChannelSuccess = (data) => {
	return {
		type: GET_OPERATORY_CHANNEL_SUCCESS,
		buttonLoading: false,
		manageOperatoryChannel: data,
		error: null,
	};
};

export const getOperatoryChannelFailure = (error) => {
	return {
		type: GET_OPERATORY_CHANNEL_FAILURE,
		buttonLoading: true,
		error: error,
	};
};

export const getOperatoryChannel = (id) => {
	return async (dispatch) => { 
		
		try {
			HttpUtility.getuserRequest(`${OPERATORY_CHANNEL}/${id}`).then(
				(response) => {
					if (response.status === 200) {
						const datum = response.data;
						dispatch(getOperatoryChannelSuccess(datum));
						history.push({
							pathname: `/add-new-operatory-channel`,
							state: {  // location state
								isFromMenageChannel: true, 
								isMangeChannel: true
							},
						});
					} else {
						dispatch(getOperatoryChannelFailure(response.data));
					}
				}
			);
		} catch (error) {
			dispatch(getOperatoryChannelFailure(error));
		}
	};
};


//  restore operatory info Channel
export const restoreOperatoryChannelRequest = () => {
	return {
		type: RESTORE_OPERATORY_CHANNEL_REQUEST,
		error: null,
	};
};

export const restoreOperatoryChannelSuccess = (data) => {
	return {
		type: RESTORE_OPERATORY_CHANNEL_SUCCESS,
		waitingRoomMediaChannels: data,
		error: null,
	};
};

export const restoreOperatoryChannelFailure = (error) => {
	return {
		type: RESTORE_OPERATORY_CHANNEL_FAILURE,
		error: error,
	};
};

export const restoreOperatoryChannel = (id) => {
	return async (dispatch) => {
		dispatch(restoreOperatoryChannelRequest());
		try {
			HttpUtility.getuserRequest(`${OPERATORY_CHANNEL}/${id}/restore`).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						dispatch(restoreOperatoryChannelSuccess(datum));
						dispatch(getOperatoryMediaChannelList())
					} else {
						const message = response;
						dispatch(restoreOperatoryChannelFailure(message));
					}
				}
			);
		} catch (error) {
			dispatch(restoreOperatoryChannelFailure(error));
		}
	};
};

// update  operatory Channel Rename
export const updateOperatoryChannelNameRequest = (channel) => {
	return {
		type: UPDATE_OPERATORY_CHANNEL_NAME_REQUEST,
		manageOperatoryChannel: channel,
		error: null,
	};
};

export const updateOperatoryChannelBtnLoadingRequest = () => {
	return {
		type: UPDATE_OPERATORYGROOM_MEDIA_CHANNEL_LOADING_REQUEST,
	};
};

export const updateOperatoryChannelNameSuccess = (channel) => {
	return {
		type: UPDATE_OPERATORY_CHANNEL_NAME_SUCCESS,
		manageOperatoryChannel: channel,
		error: null,
	};
};
export const updateOperatoryChannelNameFailure = (error) => {
	return {
		type: UPDATE_OPERATORY_CHANNEL_NAME_FAILURE,
		error: error
	};
};

export const updateOperatoryRoomChannelCCStatusSuccess = (id, params) => {
	return {
		type: UPDATE_OPEROTORY_MEDIA_CHANNEL_CC_STATUS_SUCCESS,
		id,
		params,
		error: null,
	};
}

export const updateOperatoryChannelName = (id, params) => {
	return async (dispatch) => {
		try {
			dispatch(updateOperatoryChannelBtnLoadingRequest());
			HttpUtility.userPostRequest(`${OPERATORY_CHANNEL}/${id}`, params).then(
				(response) => {
					const datum = response.data;
					if (response.status === 200) {
						if(params.cc_status){
							dispatch(updateOperatoryRoomChannelCCStatusSuccess(id, params));
						}else{
							dispatch(updateOperatoryChannelNameSuccess(datum));
							dispatch(getOperatoryMediaChannelList())
						}
					} else {
						dispatch(updateOperatoryChannelNameFailure(datum));
					}
				}
			);
		} catch (error) {
			dispatch(updateOperatoryChannelNameFailure(error));
		}

	};
};

// get Theme operatory Channel
export const getOperatoryThemeListRequest = (operatoryThemeList) => {
	return {
		type: GET_OPERATORY_THEME_LIST_REQUEST,
		operatoryThemeList: operatoryThemeList,
		error: null,
	};
};

export const getOperatoryThemeListSuccess = (data) => {
	return {
		type: GET_OPERATORY_THEME_LIST_SUCCESS,
		operatoryThemeList: data,
		error: null,
	};
};

export const getOperatoryThemeListFailure = (error) => {
	return {
		type: GET_OPERATORY_THEME_LIST_FAILURE,
		error: error,
	};
};
export const getOperatoryThemeList = () => {
	return async (dispatch) => {
		dispatch(getOperatoryThemeListRequest());
		try {
			HttpUtility.getuserRequest(`${OPERATORY_CHANNEL_THEME_LIST}`).then(
				(response) => {
					const data = response.data;
					if (response.status === 200) {
						dispatch(getOperatoryThemeListSuccess(data));
					} else {
						dispatch(getOperatoryThemeListFailure(data));
					}
				}
			);
		} catch (error) {
			getOperatoryThemeListFailure(error);
		}
	};
};

// get options operatory Channel
export const operatoryDisplayOptionsRequest = () => {
	return {
		type: GET_OPERATORY_DISPLAY_OPTIONS_REQUEST,
		loading: true,
		displayOptions: {},
		error: null,
	};
};

export const operatoryDisplayOptionsSuccess = (data) => {
	return {
		type: GET_OPERATORY_DISPLAY_OPTIONS_SUCCESS,
		loading: false,
		displayOptions: data,
		error: null,
	};
};

export const operatoryDisplayOptionsFailure = (error) => {
	return {
		type: GET_OPERATORY_DISPLAY_OPTIONS_FAILURE,
		loading: true,
		displayOptions: {},
		error: error,
	};
};
export const operatoryDisplayOptions = (params) => {
	return async (dispatch) => {
		dispatch(operatoryDisplayOptionsRequest());
		try {
			HttpUtility.getuserRequest(GET_OPERATORY_DISPLAY_OPTIONS, params).then(
				(response) => {
					if (response.status === 200) {
						const data = response.data;
						dispatch(operatoryDisplayOptionsSuccess(data));
					} else {
						const message = 'Error';
						dispatch(operatoryDisplayOptionsFailure(message));
					}
				}
			);
		} catch (error) {
			operatoryDisplayOptionsFailure(error.message);
		}
	};
};

// get whether operatory Channel
export const operatoryLiveWeatherDataRequest = () => {
	return {
		type: GET_OPERATORY_LIVE_WEATHER_DATA_REQUEST,
		loading: true,
		liveWeatherData: {},
		error: null,
	};
};
export const operatoryLiveWeatherDataSuccess = (data) => {
	return {
		type: GET_OPERATORY_LIVE_WEATHER_DATA_SUCCESS,
		loading: false,
		liveWeatherData: data,
		error: null,
	};
};
export const operatoryLiveWeatherDataFailure = (error) => {
	return {
		type: GET_OPERATORY_LIVE_WEATHER_DATA_FAILURE,
		loading: true,
		liveWeatherData: {},
		error: error,
	};
};

export const operatoryLiveWeather = (params, formData) => {
	let data = {
		auto_set_location: formData.auto_set_location,
		location: formData.location,
		unit: formData.unit,
	};
	return async (dispatch) => {
		dispatch(operatoryLiveWeatherDataRequest());
		try {
			await HttpUtility.getuserRequest(
				GET_WAITINGROOM_LIVE_WEATHER_DATA,
				params,
				data
			).then((response) => {
				if (response.status === 200 && response.data != null) {
					dispatch(operatoryLiveWeatherDataSuccess(response.data));
				} else {
					var error = 'Some error occured';
					dispatch(operatoryLiveWeatherDataFailure(error));
				}
			});
		} catch (error) {
			var error = error.message;
			dispatch(operatoryLiveWeatherDataFailure(error));
		}
	};
};

// update options operatory Channel
export const postUpdateOperatoryDisplayOptionsRequest = () => {
	return {
		type: POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};

export const postUpdateOperatoryDisplayOptionsSuccess = (message) => {
	return {
		type: POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_SUCCESS,
		loading: false,
		message: message,
		error: null,
	};
};

export const postUpdateOperatoryDisplayOptionsFailure = (error) => {
	return {
		type: POST_UPDATE_OPERATORY_CHANNEL_DISPLAY_OPTIONS_FAILURE,
		loading: true,
		message: null,
		error: error,
	};
};

export const postUpdateOperatoryDisplayOptions = (params, formData) => {
	return async (dispatch) => {
		dispatch(postUpdateOperatoryDisplayOptionsRequest());
		try {
			axios({
				headers: {
					Authorization: 'Bearer ' + params.token_info.access_token,
				},
				method: 'POST',
				url: consts.APIBASEURL + `waiting-room-media/channel/3`,
				data: {
					display_custome_message_and_newsfeed:
						formData.display_custome_message_and_newsfeed,
					display_time_weather: formData.display_time_weather,
				},
			}).then((response) => {
				const responseMessage = response.message;
				dispatch(postUpdateOperatoryDisplayOptionsSuccess(responseMessage));
			});
		} catch (error) {
			dispatch(postUpdateOperatoryDisplayOptionsFailure(error.message));
		}
	};
};
// end by Dixit Solanki ( work releted operatory chanel)
{/*==============================operatory room ========================================*/ }

// Operatory Room get videos
export const getOperatoryVideosRequest = () => {
	return {
		type: GET_OPERATORY_VIDEO_REQUEST,
		error: null,
	};
};

export const getOperatoryVideosSuccess = (enterainmentVideos, mediaType) => {
	console.log("getOperatoryVideosSuccess xxxxxxxxxxx=========>", mediaType);
	if (mediaType === 1) {
		return {
			type: GET_OPERATORY_VIDEO_SUCCESS,
			videos: enterainmentVideos,
			stateName:"operatoryRoomEnterainmentVideos",
			error: null,
		};
	}
	else if (mediaType === 2)
	{
		return {
			type: GET_OPERATORY_VIDEO_SUCCESS,
			videos: enterainmentVideos,
			stateName:"operatoryRoomMyVideos",
			error: null,
		};
		}
};

export const getOperatoryVideosFailure = (error) => {
	return {
		type: GET_OPERATORY_VIDEO_FAILURE,
		error: error,
	};
};

// Waiting Room TImeline My Videos Tab Action Creator Start
export const getOperatoryVideos = (params) => {
	return async (dispatch) => {
		dispatch(getOperatoryVideosRequest());
		try {
			var url = `${OPERATORY_ROOM_VIDEO}/${params.mediaType}`;
			await HttpUtility.getuserRequest(url).then((response) => {
				console.log("RESPONSE -------------->", response);
				// console.log("Params -------------->", params);
				if (response.status === 200) {
					var enterainmentVideos = response.data;
					dispatch(getOperatoryVideosSuccess(enterainmentVideos,params.mediaType));
				} else {
					dispatch(getOperatoryVideosFailure(response));
				}
			});
		} catch (error) {
			dispatch(getOperatoryVideosFailure(error.message));
		}
	};
};

// DELETE Playlist in Website Video Library Action Start
export const deleteWebsiteVideoLibraryPlaylistRequest = () => {
	return {
		type: DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
		loading: true,
		message: null,
		error: null,
	};
};

export const deleteWebsiteVideoLibraryPlaylistSuccess = (deletedPlayListId) => {
	return {
		type: DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
		loading: false,
		deletedPlayListId: deletedPlayListId,
		error: null,
	};
};

export const deleteWebsiteVideoLibraryPlaylistFailure = (error) => {
	return {
		type: DELETE_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,
		loading: true,
		message: null,
		removeplayListData: false,
		error: error,
	};
};
export const deleteWebsiteVideoLibraryPlaylist = (formData) => {
	return async (dispatch) => {
		// dispatch(deleteWebsiteVideoLibraryPlaylistRequest());
		try {
			HttpUtility.deleteUserRequest(`${POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST}/${formData.playListId}`).then((response) => {
				if (response.status === 200) {
					var deletedPlayListId = formData.playListId
					dispatch(deleteWebsiteVideoLibraryPlaylistSuccess(deletedPlayListId));
				} else {
					dispatch(deleteWebsiteVideoLibraryPlaylistFailure('Some error occured'));
				}
			});
		} catch (error) {
			dispatch(deleteWebsiteVideoLibraryPlaylistFailure(error.message));
		}
	};
};

// GET Playlists in Website Video Library Action Start
export const getPlaylistsWebsiteVideoLibraryRequest = () => {
	return {
		type: GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_REQUEST,
		loading: true,
		playlists: [],
		error: null,
	};
};
export const getPlaylistsWebsiteVideoLibrarySuccess = (playlists) => {
	return {
		type: GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_SUCCESS,
		loading: false,
		playlists: playlists,
		error: null,
	};
};
export const getPlaylistsWebsiteVideoLibraryFailure = (error) => {
	return {
		type: GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY_FAILURE,
		loading: true,
		playlists: [],
		error: error,
	};
};
// GET Playlists in Website Video Library Action Creator Start
export const getPlaylistsWebsiteVideoLibrary = (params) => {
	return async (dispatch) => {
		dispatch(getPlaylistsWebsiteVideoLibraryRequest());
		try {
			await HttpUtility.getuserRequest(GET_PLAYLISTS_WEBSITE_VIDEO_LIBRARY, params).then((response) => {
				if (response.status === 200) {
					const datum = response.data;
					dispatch(getPlaylistsWebsiteVideoLibrarySuccess(datum));
				} else {
					dispatch(getPlaylistsWebsiteVideoLibraryFailure('Some error occured'));
				}
			})
				.catch((error) => {
					dispatch(getPlaylistsWebsiteVideoLibraryFailure(error.message));
				});
		} catch (error) {
			dispatch(getPlaylistsWebsiteVideoLibraryFailure(error.message));
		}
	};
};

// POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST
export const getWebsiteVideoLibraryPlaylistRequest = () => {
	return {
		type: GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
		loading: true,
		getPlayListData: {},
		error: null,
	};
};
export const getWebsiteVideoLibraryPlaylistSuccess = (data) => {
	return {
		type: GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
		loading: false,
		getPlayListData: data,
		error: null,
	};
};
export const getWebsiteVideoLibraryPlaylistFailure = (error) => {
	return {
		type: GET_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,
		loading: true,
		getPlayListData: {},
		error: error,
	};
};
// GET Playlist in Website Video Library Action Creator Start
export const getWebsiteVideoLibraryPlaylist = (params, playListId) => {
	return async (dispatch) => {
		dispatch(getWebsiteVideoLibraryPlaylistRequest());
		try {
			axios({
				method: 'GET',
				url: `${POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST}/${playListId}`,
				headers: {
					Authorization: 'Bearer ' + params.token_info.access_token,
				},
			}).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data.data;
					dispatch(getWebsiteVideoLibraryPlaylistSuccess(responseMessage));
				} else {
					dispatch(getWebsiteVideoLibraryPlaylistFailure('Some error occured'));
				}
			}).catch((error) => {
				dispatch(getWebsiteVideoLibraryPlaylistFailure(error.message));
			});
		} catch (error) {
			dispatch(getWebsiteVideoLibraryPlaylistFailure(error.message));
		}
	};
};
// POST Timeline in Website Video Library Action Start

export const postSaveTimelineWebsiteVideoLibraryRequest = () => {
	return {
		type: POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_REQUEST,
		websiteTimeline: {},
		error: null,
	};
};

export const postSaveTimelineWebsiteVideoLibrarySuccess = (timeline) => {
	return {
		type: POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_SUCCESS,
		websiteTimeline: timeline,
		error: null,
	};
};

export const postSaveTimelineWebsiteVideoLibraryFailure = (error) => {
	return {
		type: POST_SAVE_TIMELINE_WEBSITE_VIDEO_LIBRARY_FAILURE,
		websiteTimeline: {},
		error: error,
	};
};
// POST Timeline in Website Video Library Action Creator Start
export const postSaveTimelineWebsiteVideoLibrary = (params, formData) => {
	console.log('FormData in SaveTimeline : ', formData);
	return async (dispatch) => {
		dispatch(postSaveTimelineWebsiteVideoLibraryRequest());
		try {
			axios({
				method: 'POST',
				url: consts.APIBASEURL + 'website-media/saved-timeline',
				headers: {
					Authorization: 'Bearer ' + params.token_info.access_token,
				},
				data: {
					website_media_id: `[${formData}]`,
				},
			}).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data.data;
					dispatch(postSaveTimelineWebsiteVideoLibrarySuccess(responseMessage));
				} else {
					dispatch(
						postSaveTimelineWebsiteVideoLibraryFailure('Some error occured')
					);
				}
			})
				.catch((error) => {
					dispatch(postSaveTimelineWebsiteVideoLibraryFailure(error.message));
				});
		} catch (error) {
			dispatch(postSaveTimelineWebsiteVideoLibraryFailure(error.message));
		}
	};
};

export const postWebsiteVideoLibraryPlaylistRequest = () => {
	return {
		type: POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_REQUEST,
		loading: true,
		playListData: null,
		error: null,
	};
};
export const postWebsiteVideoLibraryPlaylistSuccess = (message) => {
	return {
		type: POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_SUCCESS,
		loading: false,
		playListData: message,
		error: null,
	};
};
export const postWebsiteVideoLibraryPlaylistFailure = (error) => {
	return {
		type: POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST_FAILURE,
		loading: true,
		playListData: null,
		error: error,
	};
};
// POST Playlist in Website Video Library Action Creator Start
export const postWebsiteVideoLibraryPlaylist = (params) => {
	params = {
		media: params
	}
	return async (dispatch) => {
		dispatch(postWebsiteVideoLibraryPlaylistRequest());
		try {
			await HttpUtility.userPostRequest(POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST, params).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data.data;
					dispatch(postWebsiteVideoLibraryPlaylistSuccess(responseMessage));
				} else {
					dispatch(postWebsiteVideoLibraryPlaylistFailure(response));
				}
			});
		} catch (error) {
			dispatch(postWebsiteVideoLibraryPlaylistFailure(error));
		}
		// try {
		// 	axios({
		// 		method: 'POST',
		// 		url: POST_WEBSITE_VIDEO_LIBRARY_PLAYLIST,
		// 		headers: {
		// 			Authorization: 'Bearer ' + params.token_info.access_token,
		// 		},
		// 		data: {
		// 			media: formData,
		// 		},
		// 	}).then((response) => {
		// 		if (response.status === 200) {
		// 			const responseMessage = response.data.data;
		// 			dispatch(postWebsiteVideoLibraryPlaylistSuccess(responseMessage));
		// 		} else {
		// 			dispatch(
		// 				postWebsiteVideoLibraryPlaylistFailure('Some error occured')
		// 			);
		// 		}
		// 	}).catch((error) => {
		// 		dispatch(postWebsiteVideoLibraryPlaylistFailure(error.message));
		// 	});
		// } catch (error) {
		// 	dispatch(postWebsiteVideoLibraryPlaylistFailure(error.message));
		// }
	};
};
// Waiting Room TImeline My Videos Tab Action Creator End

{/*==============================operatory room End========================================*/ }




// Waiting room timeline slides template Action Start
// Waiting room timeline slides template Action Creator Start
export const WaitingRoomTimelineSlidesTemplatesSuccess = (data) => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_SUCCESS,
		templates: data,
	}
};

export const WaitingRoomTimelineSlidesTemplatesFailure = (error) => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_TEMPLATES_FAILURE,
		error: error,
	}
};
// Waiting room timeline slides template Action Creator End


export const WaitingRoomTimelineSlidesTemplates = () => {
	return async (dispatch) => {

		try {
			await HttpUtility.getuserRequest(WAITINGROOMSLIDESHTMLTEMPLATE).then((response) => {
				const responseData = response.data;
				dispatch(WaitingRoomTimelineSlidesTemplatesSuccess(responseData));
			});
		} catch (error) {
			dispatch(WaitingRoomTimelineSlidesTemplatesFailure(error));
		}
	};
};

// ADD CATEGORY
export const fetchSpecializationCategory = (params) => {

	return async dispatch => {
		dispatch({ type: ADD_CATEGORY_SPECIALIZATION, payload: params })
	};
}
//  Waiting room timeline slides template Action Start

export const resetDefaultwebsiteVideo = () => {
	return async (dispatch) => {
		dispatch({ type: CHAIRSIDE_RESET_DEFAULT });
	}
}

{/*==============================operatory room End========================================*/ }

// Chairside Image Editor APIs
export const getCSCustomMessage = () => {
	return async (dispatch) => {
		dispatch({ type: GET_CUSTOM_MESSAGES_REQUEST });
		try {
			await HttpUtility.getuserRequest(GET_CHAIRSIDE_MEDIA + '/config/get-messages')
				.then((response) => {
					const currentMessage = response.data;
					dispatch({
						type: GET_CUSTOM_MESSAGES_SUCCESS,
						currentMessage: currentMessage,
					});
				})
				.catch((response) => {
					dispatch({ type: GET_CUSTOM_MESSAGES_FAILURE, error: response });
				});
		} catch (error) {
			dispatch({ type: GET_CUSTOM_MESSAGES_FAILURE, payload: error });
		}
	};
};

export const resetCSCustomMessage = () => {
	return async (dispatch) => {

		dispatch({ type: RESET_CUSTOM_MESSAGES_REQUEST });

		try {
			await HttpUtility.getuserRequest(GET_CHAIRSIDE_MEDIA + '/config/reset-default')
				.then((response) => {
					const resetMessage = response.data;
					dispatch({
						type: RESET_CUSTOM_MESSAGES_SUCCESS,
						resetMessage: resetMessage,
					});
				})
				.catch((response) => {
					dispatch({ type: RESET_CUSTOM_MESSAGES_FAILURE, error: response });
				});
		} catch (error) {
			dispatch({ type: RESET_CUSTOM_MESSAGES_FAILURE, payload: error });
		}
	};
};

export const updateCSCustomMessage = (data) => {
	return async (dispatch) => {

		dispatch({ type: POST_CUSTOM_MESSAGES_REQUEST });

		try {
			await HttpUtility.userPostRequest(GET_CHAIRSIDE_MEDIA + "/config", data)
				.then((response) => {
					const updateCSCustomMessage = response.data;
					if (response.status === 200) {
						dispatch({
							type: POST_CUSTOM_MESSAGES_SUCCESS,
							updateCSCustomMessage: updateCSCustomMessage,
						});
					} else {
						dispatch({
							type: POST_CUSTOM_MESSAGES_FAILURE,
							error: updateCSCustomMessage,
						});
					}
				})
				.catch((response) => {
					dispatch({ type: POST_CUSTOM_MESSAGES_FAILURE, error: response });
				});
		} catch (error) {
			dispatch({ type: POST_CUSTOM_MESSAGES_FAILURE, error: error });
		}
	};
};

export const resetDefaultsendScreenShot = () => {
	return async (dispatch) => {
		dispatch({ type: SEND_SCREENSHOT__RESET_REQUEST });
	}
}

export const sendScreenShot = (formData) => {
	let hdr = getuserHeaders()
	hdr["Content-Type"] = "multipart/form-data"
	return async (dispatch) => {
		dispatch({ type: SEND_SCREENSHOT_REQUEST });
		axios({
			method: "post",
			headers: hdr,
			url: GET_CHAIRSIDE_MEDIA + "/screenshot",
			data: formData,
		})
			.then(function (response) {
				const sendScreenshot = response.data;
				if (response.status === 200) {
					dispatch({
						type: SEND_SCREENSHOT_SUCCESS,
						sendScreenshot: sendScreenshot,
					});
				} else {
					dispatch({
						type: SEND_SCREENSHOT_FAILURE,
						error: sendScreenshot,
					});
				}
			})
			.catch(function (response) {
				//handle error
				dispatch({ type: SEND_SCREENSHOT_FAILURE, error: response });

			});
	};
};

//  start operatory schedule post

export const saveOperatoryScheduleRequest = () => {
	return {
		type: CREATE_OPERATORY_SCHEDULE_REQUEST,
		loading: true,
		manageOperatorySchedule: {},
		error: null,
	};
};
export const saveOperatoryScheduleSuccess = (manageOperatorySchedule) => {
	return {
		type: CREATE_OPERATORY_SCHEDULE_SUCCESS,
		loading: false,
		manageOperatorySchedule: manageOperatorySchedule,
		error: null,
	};
};
export const saveOperatoryScheduleFailure = (error) => {
	return {
		type: CREATE_OPERATORY_SCHEDULE_FAILURE,
		loading: true,
		manageOperatorySchedule: {},
		error: error,
	};
};

export const postOperatorySchedule = (data) => {
	return async (dispatch) => {
		dispatch(saveOperatoryScheduleRequest());
		try {
			await HttpUtility.userPostRequest(SAVE_OPERATORY_SCHEDULE, data).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data.data;
					dispatch(saveOperatoryScheduleSuccess(responseMessage));
				} else {
					dispatch(saveOperatoryScheduleFailure(response));
				}
			});
		} catch (error) {
			dispatch(saveOperatoryScheduleFailure(error));
		}

	};
};

// ############# DD-11
export const getShareVideoLink = (videoId, params) => {
	return async (dispatch) => {

		dispatch({ type: GET_SHARE_VIDEO_LINK_REQUEST, });

		try {
			await HttpUtility.getuserRequest(`${consts.GET_SHARE_VIDEO_LINK}`, params)
				.then((response) => {
					if (response.status === 200) {
						const shareVideoList = response.data;
						dispatch({
							type: GET_SHARE_VIDEO_LINK_SUCCESS,
							shareVideoList: shareVideoList,
						});
					} else {
						dispatch({
							type: GET_SHARE_VIDEO_LINK_FAILURE,
							error: response.data,
						});
					}
				})
		} catch (error) {
			dispatch({ type: GET_SHARE_VIDEO_LINK_FAILURE, payload: error });
		}
	};
}

export const shareVideoLinkClearData = () => {
	return {
		type: SHARE_VIDEO_LINK_CLEAR_REQUEST,
		shareVideoData: {},
	};
};

export const setShareVideoId = (videoId) => {
	return {
		type: SHARE_VIDEO_LINK_ID_REQUEST,
		shareVideoId: videoId,
	};
};

export const shareVideoLink = (params, videoId) => {
	return async (dispatch) => {

		dispatch({ type: SHARE_VIDEO_LINK_REQUEST, });

		try {
			await HttpUtility.userPostRequest(`${consts.GET_SHARE_VIDEO_LINK}/${videoId}`, params)
				.then((response) => {
					if (response.status === 200) {
						const shareVideoData = response.data;
						dispatch({
							type: SHARE_VIDEO_LINK_SUCCESS,
							shareVideoData: shareVideoData,
						});
					} else {
						dispatch({
							type: SHARE_VIDEO_LINK_FAILURE,
							error: response.data,
						});
					}
				})
		} catch (error) {
			dispatch({ type: SHARE_VIDEO_LINK_FAILURE, payload: error });
		}
	};
}
// ############# DD-11


//  start operatory schedule post

export const saveWaitingRoomScheduleRequest = () => {
	return {
		type: CREATE_WAITING_ROOM_SCHEDULE_REQUEST,
		loading: true,
		waitingRoomSchedule: {},
		error: null,
	};
};
export const savetWaitingRoomScheduleSuccess = (waitingRoomSchedule) => {
	return {
		type: CREATE_WAITING_ROOM_SCHEDULE_SUCCESS,
		loading: false,
		waitingRoomSchedule: waitingRoomSchedule,
		error: null,
	};
};
export const savetWaitingRoomScheduleFailure = (error) => {
	return {
		type: CREATE_WAITING_ROOM_SCHEDULE_FAILURE,
		loading: true,
		waitingRoomSchedule: {},
		error: error,
	};
};

// operatory channels save
export const saveOperatoryRoomScheduleRequest = () => {
	return {
		type: CREATE_OPERATORY_SCHEDULE_REQUEST,
		loading: true,
		waitingRoomSchedule: {},
		error: null,
	};
};
export const savetOperatoryRoomScheduleSuccess = (waitingRoomSchedule) => {
	return {
		type: CREATE_OPERATORY_SCHEDULE_SUCCESS,
		loading: false,
		waitingRoomSchedule: waitingRoomSchedule,
		error: null,
	};
};
export const savetOperatoryRoomScheduleFailure = (error) => {
	return {
		type: CREATE_OPERATORY_SCHEDULE_FAILURE,
		loading: true,
		waitingRoomSchedule: {},
		error: error,
	};
};

export const postWaitingRoomSchedule = (data) => {
	return async (dispatch) => {
		dispatch(saveWaitingRoomScheduleRequest());
		try {
			await HttpUtility.userPostRequest(SAVE_WAITING_ROOM_SCHEDULE, data).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data.data;
					dispatch(savetWaitingRoomScheduleSuccess(responseMessage));
				} else {
					dispatch(savetWaitingRoomScheduleFailure(response));
				}
			});
		} catch (error) {
			dispatch(saveOperatoryScheduleFailure(error));
		}

	};
};
//operatory room schedule
export const postOperatoryRoomSchedule = (data) => {
	console.log('post operator schedule',SAVE_OPERATORY_SCHEDULE)
	return async (dispatch) => {
		dispatch(saveOperatoryRoomScheduleRequest());
		try {
			await HttpUtility.userPostRequest(SAVE_OPERATORY_SCHEDULE, data).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data.data;
					dispatch(savetOperatoryRoomScheduleSuccess(responseMessage));
				} else {
					dispatch(savetOperatoryRoomScheduleFailure(response));
				}
			});
		} catch (error) {
			dispatch(saveOperatoryScheduleFailure(error));
		}

	};
};

// get slide background
// Waiting room timeline slides background images Creator Start
export const WaitingRoomTimelineSlidesBackgroundImageRequest = () => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_REQUEST,
		templates: null,
	}
};


export const WaitingRoomTimelineSlidesBackgroundImageSuccess = (data) => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_SUCCESS,
		backgroundImages: data,
	}
};

export const WaitingRoomTimelineSlidesBackgroundImageFailure = (error) => {
	return {
		type: GET_WAITING_ROOM_TIMELINE_SLIDE_BACKGROUND_IMAGES_FAILURE,
		error: error,
	}
};

export const WaitingRoomTimelineSlidesBackgroundImages = () => {
	return async (dispatch) => {
		// dispatch(WaitingRoomTimelineSlidesBackgroundImageRequest());
		try {
			await HttpUtility.getuserRequest(WAITINGROOMSLIDESBACKGROUNDIMAGE).then((response) => {
				if(response.status == 200){
					console.log("SLIDE BACKGROUND IMAGES", response);
					const responseData = response.data;
					dispatch(WaitingRoomTimelineSlidesBackgroundImageSuccess(responseData));
				}else{
					dispatch(WaitingRoomTimelineSlidesBackgroundImageFailure(response.data.errors));
				}
			});
		} catch (error) {
			dispatch(WaitingRoomTimelineSlidesBackgroundImageFailure(error));
		}
	};
};

// Waiting room timeline slides background images Creator End


/* get waiting Room Schedule */
export const getWaitingRoomScheduleRequest = () => {
	return {
		type: GET_WAITING_ROOM_SCHEDULE_REQUEST,
	};
};
export const getWaitingRoomScheduleSuccess = (waitingRoomSchedule) => {
	return {
		type: GET_WAITING_ROOM_SCHEDULE_SUCCESS,
		waitingRoomSchedule: waitingRoomSchedule,
	};
};
export const getWaitingRoomScheduleFailure = (error) => {
	return {
		type: GET_WAITING_ROOM_SCHEDULE_FAILURE,
		error: error,
	};
};

export const getWaitingRoomSchedule = () => {
	return async (dispatch) => {
		dispatch(getWaitingRoomScheduleRequest());
		try {
			await HttpUtility.getuserRequest(GET_WAITINGROOM_CHANNEL_SCHEDULE).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data;
					dispatch(getWaitingRoomScheduleSuccess(responseMessage));
				} else {
					dispatch(getWaitingRoomScheduleFailure(response));
				}
			});
		} catch (error) {
			dispatch(getWaitingRoomScheduleFailure(error));
		}

	};
};

// operatory schedule
export const getOperatoryRoomSchedule = () => {
	return async (dispatch) => {
		dispatch(getWaitingRoomScheduleRequest());
		try {
			await HttpUtility.getuserRequest(consts.GET_OPERATORY_CHANNEL_SCHEDULE).then((response) => {
				if (response.status === 200) {
					const responseMessage = response.data;
					dispatch(getWaitingRoomScheduleSuccess(responseMessage));
				} else {
					dispatch(getWaitingRoomScheduleFailure(response));
				}
			});
		} catch (error) {
			dispatch(getWaitingRoomScheduleFailure(error));
		}

	};
};
/* get waiting Room Schedule end */



// WaitingRoom Footer Weather Data Action Start
export const waitingRoomFooterWeatherDataRequest = () => {
	return {
		type: GET_WAITINGROOM_FOOTER_WEATHER_DATA_REQUEST,
		error: null,
	};
};
export const waitingRoomFooterWeatherDataSuccess = (data) => {
	return {
		type: GET_WAITINGROOM_FOOTER_WEATHER_DATA_SUCCESS,
		weatherLocation: data,
	};
};
export const waitingRoomFooterWeatherDataFailure = (error) => {
	return {
		type: GET_WAITINGROOM_FOOTER_WEATHER_DATA_FAILURE,
		error: error,
	};
};
// WaitingRoom Footer Weather Data Action Creator Start
export const getwaitingRoomFooterWeather = (id, isOperatoryRoom) => {
	return async (dispatch) => {
		dispatch(waitingRoomFooterWeatherDataRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/${GET_WAITINGROOM_CHANNEL_FOOTER_WEATHER}`
			if(isOperatoryRoom){
				url = `${OPERATORY_CHANNEL}/${id}/footer/weather`
			}
			await HttpUtility.getuserRequest(url).then((response) => {
				if (response.status === 200 && response.data != null) {
					dispatch(waitingRoomFooterWeatherDataSuccess(response.data));
				} else {
					dispatch(waitingRoomFooterWeatherDataFailure(response));
				}
			});
		} catch (error) {
			dispatch(waitingRoomFooterWeatherDataFailure(error));
		}
	};
};
// WaitingRoom Footer Weather Data Action Creator End

// WaitingRoom Footer Weather Data Action Action End


// Operatory Room Timeline My Videos upload
export const uploadOperatoryRoomMyVideosRequest = () => {
	return {
		type: UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
		// buttonLoading: true,
		// myVideosTimeline: [],
		error: null,
	};
};

export const uploadOperatoryRoomMyVideosSuccess = (data) => {
	return {
		type: UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		// buttonLoading: false,
		// myVideosTimeline: data,
		error: null,
	};
};

export const uploadOperatoryRoomMyVideosFailure = (error) => {
	return {
		type: UPLOAD_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		// buttonLoading: false,
		// myVideosTimeline: [],
		error: error,
	};
};



// Operatory Room TImeline My Videos Tab Action UPLOAD
export const uploadOperatoryRoomTimelineMyVideos = (params, goMyVideo) => {
	return async (dispatch) => {
		dispatch(uploadOperatoryRoomMyVideosRequest());
		try {
			await HttpUtility.userPostRequest(OPERATORY_ROOM_MEDIA, params).then((response) => {
				console.log("Params --->", params);
				console.log("https://www.youtube.com/watch?v=ViLEPoIJxPk", response);
				const myVideosData = response.data;
				if (response.status === 200) {
					goMyVideo();
					dispatch(getOperatoryVideos({ mediaType: OPERATORYMYVIDEO }));
					dispatch(uploadOperatoryRoomMyVideosSuccess(myVideosData, goMyVideo));
				} else {
					dispatch(uploadOperatoryRoomMyVideosFailure('Error occured'));
				}
			});
		} catch (error) {
			console.log("uploadOperatoryRoomMyVideosFailure", error);
			dispatch(uploadOperatoryRoomMyVideosFailure(error.message));
		}
	};
};

// Operatory Room TImeline My Videos Tab Action UPLOAD END

// operatory Room TImeline My Videos Tab Action Start
export const newoperatoryRoomTimelineMyVideosRequest = (isReload) => {
	return {
		type: NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
		loading: true,
		myVideosTimeline: [],
		error: null,
		isReload: isReload,
	};
};

export const newoperatoryRoomTimelineMyVideosSuccess = (data) => {
	return {
		type: NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		loading: false,
		myVideosTimeline: data,
		error: null,
	};
};

export const newoperatoryRoomTimelineMyVideosFailure = (error) => {
	return {
		type: NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		loading: true,
		myVideosTimeline: [],
		error: error,
	};
};

// operatory Room TImeline My Videos Tab Action Creator Start
export const newoperatoryRoomTimelineMyVideos = (isReload) => {
	return async (dispatch) => {
		dispatch(newoperatoryRoomTimelineMyVideosRequest(isReload));
		try {
			await HttpUtility.getuserRequest(
				NEW_OPERATORY_ROOM_TIMELINE_MYVIDEOS,
				// params
			).then((response) => {
				const myVideosData = response.data;
				if (response.status === 200) {
					dispatch(newoperatoryRoomTimelineMyVideosSuccess(myVideosData));
				} else {
					dispatch(newoperatoryRoomTimelineMyVideosFailure('Error occured'));
				}
			});
		} catch (error) {
			dispatch(newoperatoryRoomTimelineMyVideosFailure(error.message));
		}
	};
};
// operatory Room TImeline My Videos Tab Action Creator End
// WaitingRoom PUBLISH_WAITINGROOM_TIMELINE Action Start
export const waitingRoomPublishTimelineRequest = () => {
	return {
		type: PUBLISH_WAITINGROOM_TIMELINE_REQUEST,
		error: null,
	};
};
export const waitingRoomPublishTimelineSuccess = (data) => {
	return {
		type: PUBLISH_WAITINGROOM_TIMELINE_SUCCESS,
		waitingRoomPublishTimeline: data,
	};
};
export const waitingRoomPublishTimelineFailure = (error) => {
	return {
		type: PUBLISH_WAITINGROOM_TIMELINE_FAILURE,
		error: error,
	};
};
export const waitingRoomPublishTimelineNull = () => {
	return {
		type: PUBLISH_WAITINGROOM_TIMELINE_NULL,
		waitingRoomPublishTimeline: null,
	};
};
// WaitingRoom PUBLISH_WAITINGROOM_TIMELINE Action Creator Start
export const postwaitingRoomPublishTimeline = (id , isOperatoryRoom) => {
	return async (dispatch) => {
		dispatch(waitingRoomPublishTimelineRequest());
		try {
			var url = `${WAITINGROOM_CHANNEL}/${id}/publish`
			if(isOperatoryRoom){
				url = `${OPERATORY_CHANNEL}/${id}/publish`
			}
			await HttpUtility.getuserRequest(url).then((response) => {
				if (response.status === 200 && response.data != null) {
					dispatch(waitingRoomPublishTimelineSuccess(response.data));
					dispatch(waitingRoomPublishTimelineNull())
				} else {
					dispatch(waitingRoomPublishTimelineFailure(response));
				}
			});
		} catch (error) {
			dispatch(waitingRoomPublishTimelineFailure(error));
		}
	};
};
// WaitingRoom PUBLISH_WAITINGROOM_TIMELINE Creator End

// WaitingRoom PUBLISH_WAITINGROOM_TIMELINE Action End



// Upadate operatory
export const updateOperatoryRoomTimelineMyVideoRequest = () => {
	return {
		type: UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	};
};

export const updateOperatoryRoomTimelineMyVideoSuccess = (responseData, params) => {
	return {
		type: UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		data: responseData,
		params
	};
};

export const updateOperatoryRoomTimelineMyVideoFailure = (error) => {
	return {
		type: UPDATE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		error: error,
	};
};

export const updateOperatoryRoomTimelineMyVideo = (params, updateError) => {
	return async (dispatch) => {
		dispatch(updateOperatoryRoomTimelineMyVideoRequest());
		try {
			var url = `${consts.OPERATORY_ROOM}/${params.operatory_media_id}`
			HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status == 200) {
					const responseData = response.data;
					dispatch(updateOperatoryRoomTimelineMyVideoSuccess(responseData, params))
				} else {
					updateError("Name already exists");
					dispatch(updateOperatoryRoomTimelineMyVideoFailure(response));
				}
			})
		} catch (error) {
			dispatch(updateOperatoryRoomTimelineMyVideoFailure(error));
		}
	};
};



// Remove Video inOperatoryRoom TImeline My Videos Tab Action Start
export const removeOperatoryRoomTimelineMyVideoRequest = () => {
	return {
		type: REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_REQUEST,
	};
};

export const removeOperatoryRoomTimelineMyVideoSuccess = (video) => {
	return {
		type: REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_SUCCESS,
		video: video,
	};
};

export const removeOperatoryRoomTimelineMyVideoFailure = (error) => {
	return {
		type: REMOVE_OPERATORY_ROOM_TIMELINE_MYVIDEOS_FAILURE,
		error: error,
	};
};

// Remove Video in OperatoryRoom TImeline My Videos Tab Action Creator Start
export const removeOperatoryRoomTimelineMyVideo = (params) => {
	return async (dispatch) => {
		dispatch(removeOperatoryRoomTimelineMyVideoRequest());
		try {
			var url = `${consts.OPERATORY_ROOM}/${params.operatory_media_id}`;
			HttpUtility.userdeleteRequest(url).then((response) => {
				if (response.status == 200) {
					dispatch(removeOperatoryRoomTimelineMyVideoSuccess(params));
				} else {
					dispatch(removeOperatoryRoomTimelineMyVideoSuccess(""));
				}
			});
		} catch (error) {
			dispatch(removeOperatoryRoomTimelineMyVideoFailure(error.message));
		}
	};
};
// Remove Video in OperatoryRoom TImeline My Videos Tab Action Creator End
// Remove Video in OperatoryRoom TImeline My Videos Tab Action End



// practice/payment-information Start
export const GetPracticePaymentinfo = () => {
	return async (dispatch) => {
		dispatch({ type: GET_PRACTICE_PAYMENT_INFO_REQUEST });

		try {
			await HttpUtility.getuserRequest(`${PRACTICE}/payment-information`).then(
				(response) => {
					if (response.status === 200) {
						const getPracticePaymentinfo = response.data;
						dispatch({
							type: GET_PRACTICE_PAYMENT_INFO_SUCCESS,
							getPracticePaymentinfo: getPracticePaymentinfo,
						});
					} else {
						dispatch({
							type: GET_PRACTICE_PAYMENT_INFO_FAIL,
							error: response,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: GET_PRACTICE_PAYMENT_INFO_FAIL, error: error });
		}
	};
};
// practice/payment-information End



// PayPracticePayment Start
export const PayPracticePayment = (params) => {
	return async (dispatch) => {
		dispatch({ type: POST_PRACTICE_PAYMENT_INFO_REQUEST });

		try {
			await HttpUtility.userPostRequest(`${PRACTICE}/pay-now`, params).then(
				(response) => {
					if (response.status === 200) {
						const getPracticePaymentinfo = response.data;
						dispatch({
							type: POST_PRACTICE_PAYMENT_INFO_SUCCESS,
							getPracticePaymentinfo: getPracticePaymentinfo,
						});
						dispatch(GetBillingCard());
						history.push('/channels')
					} else {
						dispatch({
							type: POST_PRACTICE_PAYMENT_INFO_FAIL,
							error: response.data,
						});
					}
				}
			);
		} catch (error) {
			dispatch({ type: POST_PRACTICE_PAYMENT_INFO_FAIL, error: error });
		}
	};
};
// PayPracticePayment End

export const getCityNameFromLatLong =async (lat,long) => {
	const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${GOOGLEAPIKEY}`
	try{
		const response = await axios.get(url)
		const { data } = response;
		const {results} = data
		const addressComponent = results[1].formatted_address
		console.log(addressComponent,'addressComponent')
		return {

			address:addressComponent,
			// stateName:addressComponent[2].long_name,
			// countryName:addressComponent[3].long_name
		}
	}catch(err){
		console.log(url,'addressComponent')
		throw err

	}
}

export const GetMediaCategoryImages = (params) => {
	return async (dispatch) => {
		dispatch({ type: GET_MEDIA_CATEGORY_IMAGES_REQUEST });
		try {
			await HttpUtility.getuserRequest(TYPE_MEDIA_CATEGORY_IMAGE,params)
				.then((response) => {
					if (response.status === 200) {
						const getMediaCategoryImages = response.data;
						dispatch({
							type: GET_MEDIA_CATEGORY_IMAGES_SUCCESS,
							getMediaCategoryImages: getMediaCategoryImages,
						});
					} else {
						dispatch({ type: GET_MEDIA_CATEGORY_IMAGES_FAIL, error: response.data });
					}
				})
		} catch (error) {
			dispatch({ type: GET_MEDIA_CATEGORY_IMAGES_FAIL, error: error });
		}
	};
};


export const PostTosComplaint = (params) => {
	return async (dispatch) => {

		dispatch({ type: POST_TOS_COMPLAINT_REQUEST, });

		try {
			await HttpUtility.postRequest(`${TOSCOMPLAINT}` , params)
				.then((response) => {
					if (response.status === 200) {
						const tosComplaint = response.data;
						dispatch({
							type: POST_TOS_COMPLAINT_SUCCESS,
							tosComplaint: tosComplaint,
						});
					} else {
						dispatch({
							type: POST_TOS_COMPLAINT_FAIL,
							error: response.data,
						});
					}
				})
		} catch (error) {
			dispatch({ type: POST_TOS_COMPLAINT_FAIL, payload: error });
		}
	};
}



// chairside-media  recent play videos

export const getRecentPlayVideosRequest = () => {
	return {
		type: GET_CHAIRSIDE_MEDIA__RECENT_PLAY_REQUEST,
	};
};

export const getRecentPlayVideosSuccess = (media) => {
	return {
		type: GET_CHAIRSIDE_MEDIA__RECENT_PLAY_SUCCESS,
		chairSideMediarecentPlay: media,
	};
};

export const getRecentPlayVideosFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_MEDIA__RECENT_PLAY_FAILURE,
		payload: error,
	};
};

export const getRecentPlayVideos = (params) => {
	return async (dispatch) => {
		dispatch(getRecentPlayVideosRequest());
		try {
			HttpUtility.getuserRequest(`${GET_CHAIRSIDE_MEDIA}`, params).then((response) => {
				if (response.status === 200) {
					const chairSideMediarecentPlay = response.data;
					dispatch(getRecentPlayVideosSuccess(chairSideMediarecentPlay));
				} else {
					dispatch(getRecentPlayVideosFailure(response));
				}
			}
			);
		} catch (error) {
			dispatch(getRecentPlayVideosFailure(error));
		}
	};
};
//  chairside-media  recent play videos API ActionCreator End
//  chairside-media  recent play videos API Creator End


// practice/connect/practice-contact-us

export const postPracticeContactUsRequest = () => {
	return {
		type: POST_PRACTICE_CONTACTUS_REQUEST,
	};
};

export const postPracticeContactUsSuccess = (practicecontactus) => {
	return {
		type: POST_PRACTICE_CONTACTUS_SUCCESS,
		practicecontactus: practicecontactus,
	};
};

export const postPracticeContactUsFailure = (error) => {
	return {
		type: POST_PRACTICE_CONTACTUS_FAILURE,
		error: error,
	};
};

export const postPracticeContactUs = (params, resetField) => {
	return async (dispatch) => {
		dispatch(postPracticeContactUsRequest());
		try {
			HttpUtility.userPostRequest(`${PRACTICE_CONTACTUS}`, params).then((response) => {
				if (response.status === 200) {
					const practicecontactus = response.data;
					dispatch(postPracticeContactUsSuccess(practicecontactus));
					resetField(true)
				} else {
					dispatch(postPracticeContactUsFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(postPracticeContactUsFailure(error));
		}
	};
};
//  practice/connect/practice-contact-us API ActionCreator End
//  practice/connect/practice-contact-us API Creator End



// }website-media/reset-to-default

export const resetDefaultWebsiteVideoRequest = () => {
	return {
		type: RESET_DEFAULT_WEBSITE_VIDEO_REQUEST,
	};
};

export const resetDefaultWebsiteVideoSuccess = (media) => {
	return {
		type: RESET_DEFAULT_WEBSITE_VIDEO_SUCCESS,
		websiteMediaState:media
	};
};

export const resetDefaultWebsiteVideoFailure = (error) => {
	return {
		type: RESET_DEFAULT_WEBSITE_VIDEO_FAILURE,
		error: error,
	};
};

export const resetDefaultWebsiteVideoLibery = () => {
	return async (dispatch) => {
		dispatch(resetDefaultWebsiteVideoRequest());
		try {
			HttpUtility.getuserRequest(`${WEBSITE_MEDIA}/reset-to-default`).then((response) => {
				if (response.status === 200) {
					const websiteMediaState = response.data;
					console.log(websiteMediaState,'practicecontactus')
					dispatch(resetDefaultWebsiteVideoSuccess(websiteMediaState));
				} else {
					dispatch(resetDefaultWebsiteVideoFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(resetDefaultWebsiteVideoFailure(error));
		}
	};
};

export const extendTrail = (isFromHeader, histiory) => {
	
	return async (dispatch) => {

		dispatch({ type: EXTEND_TRIAL_REQUEST });

		try {
			var url = `${PRACTICE}/extend-trial`;
			await HttpUtility.postRequest(url).then((response) => {
				const responseData = response.data;
				if (response.status === 200) {
					dispatch({
						type: EXTEND_TRIAL_SUCCESS,
					});
					if(isFromHeader){
						history.push({ pathname: "/trial",  isHeader: true });
					}
				} else {
					dispatch({
						type: EXTEND_TRIAL_FAILURE,
						error: responseData,
					});
				}
			});
		} catch (error) {
			dispatch({ type: EXTEND_TRIAL_FAILURE, error: error });
		}
	};
};
// website-media/reset-to-default API ActionCreator End
//  website-media/reset-to-default API Creator End


// corporate cards

export const getDesignateCardRequest = () => {
	return {
		type: GET_DESIGNATE_CARD_REQUEST,
	};
};

export const getDesignateCardSuccess = (designateCard) => {
	return {
		type: GET_DESIGNATE_CARD_SUCCESS,
		designateCard,
	};
};

export const getDesignateCardsFailure = (error) => {
	return {
		type: GET_DESIGNATE_CARD_FAILURE,
		error: error,
	};
};

export const getDesignateCard = (params) => {
	return async (dispatch) => {
		dispatch(getDesignateCardRequest());
		try {
			HttpUtility.getuserRequest(`${CORPORATE}/subscription-payment`, params).then((response) => {
				if (response.status === 200) {
					const designate = response.data;
					dispatch(getDesignateCardSuccess(designate));
				} else {
					dispatch(getDesignateCardsFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(getDesignateCardsFailure(error));
		}
	};
};

// DesignateCard to multiple
export const getDesignateCardMultipleRequest = () => {
	return {
		type: GET_DESIGNATE_CARD_MULTIPLE_REQUEST,
	};
};

export const getDesignateCardMultipleSuccess = (designateCardMultiple) => {
	return {
		type: GET_DESIGNATE_CARD_MULTIPLE_SUCCESS,
		designateCardMultiple,
	};
};

export const getDesignateCardsMultipleFailure = (error) => {
	return {
		type: GET_DESIGNATE_CARD_MULTIPLE_FAILURE,
		error: error,
	};
};

export const getDesignateCardMultiple = () => {
	return async (dispatch) => {
		dispatch(getDesignateCardMultipleRequest());
		try {
			HttpUtility.getuserRequest(`${CORPORATE}/subscription-payment/designate-card`).then((response) => {
				if (response.status === 200) {
					const designate = response.data;
					var primaryCards = designate.cards.filter((x) => x.is_primary == 1);
					var secondrayCards = designate.cards.filter((x) => x.is_primary == 0);
					designate['primaryCards'] = primaryCards;
					designate['secondrayCards'] = secondrayCards;
					dispatch(getDesignateCardMultipleSuccess(designate));
				} else {
					dispatch(getDesignateCardsMultipleFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(getDesignateCardsMultipleFailure(error));
		}
	};
};

// post multiple cardPOST_CORPORATE_CARD_FAILURE
export const postCorporateMultipleCardRequest = () => {
	return {
		type: POST_CORPORATE_MULTIPLE_CARD_REQUEST,
	};
};

export const posCorporateMultipleCardSuccess = () => {
	return {
		type: POST_CORPORATE_MULTIPLE_CARD_SUCCESS,
	};
};

export const postCorporateMultipleCardFailure = (error) => {
	return {
		type: POST_CORPORATE_MULTIPLE_CARD_FAILURE,
		error: error,
	};
};

export const postCorporateMultipleCard = (params, selectedCard) => {
	return async (dispatch) => {
		dispatch(postCorporateMultipleCardRequest());
		try {
			var url = `${CORPORATE}/subscription-payment/designate-card`;
			HttpUtility.userPostRequest(url, params).then((response) => {
				if (response.status === 200) {
					dispatch(posCorporateMultipleCardSuccess());
					history.push({ pathname: '/payment-card-setup', state: { selectedCard } });
				} else {
					dispatch(postCorporateMultipleCardFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(postCorporateMultipleCardFailure(error));
		}
	};
};

export const onSelecteCard = (params) => {
	return async (dispatch) => {
		dispatch({
			type: SELECTED_CARD,
			selectedCard: params,
		})
	}
}




export const getCorporatHardwareListRequest = () => {
	return {
		type: GET_CORPORAT_HARDWARE_LIST_REQUEST,
	};
};

export const getCorporatHardwareListSuccess = (corporathardwarelist) => {
	return {
		type: GET_CORPORAT_HARDWARE_LIST_SUCCESS,
		corporathardwarelist,
	};
};

export const getCorporatHardwareListFailure = (error) => {
	return {
		type: GET_CORPORAT_HARDWARE_LIST_FAILURE,
		error: error,
	};
};

export const getCorporatHardwareList = (params) => {
	return async (dispatch) => {
		dispatch(getCorporatHardwareListRequest());
		try {
			HttpUtility.getuserRequest(`${CORPORATE}/option`, params).then((response) => {
				if (response.status === 200) {
					const corporathardwarelist = response.data;
					dispatch(getCorporatHardwareListSuccess(corporathardwarelist));
				} else {
					dispatch(getCorporatHardwareListFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(getCorporatHardwareListFailure(error));
		}
	};
};


export const getCorporatPracticesListRequest = () => {
	return {
		type: GET_CORPORAT_PRACTICES_LIST_REQUEST,
	};
};

export const getCorporatPracticesListSuccess = (corporatpracticeslist) => {
	return {
		type: GET_CORPORAT_PRACTICES_LIST_SUCCESS,
		corporatpracticeslist,
	};
};

export const getCorporatPracticesListFailure = (error) => {
	return {
		type: GET_CORPORAT_PRACTICES_LIST_FAILURE,
		error: error,
	};
};

export const getCorporatPracticesList = (params) => {
	return async (dispatch) => {
		dispatch(getCorporatPracticesListRequest());
		try {
			HttpUtility.getuserRequest(`${CORPORATE}/practices?need_all=1&hardware_order=1`, params).then((response) => {
				if (response.status === 200) {
					const corporatpracticeslist = response.data;
					dispatch(getCorporatPracticesListSuccess(corporatpracticeslist));
				} else {
					dispatch(getCorporatPracticesListFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(getCorporatPracticesListFailure(error));
		}
	};
};


export const getCorporatHardwareCalcRequest = () => {
	return {
		type: GET_CORPORAT_HARDWARE_CALC_REQUEST,
	};
};

export const getCorporatHardwareCalcSuccess = (corporathardwarecalc) => {
	return {
		type: GET_CORPORAT_HARDWARE_CALC_SUCCESS,
		corporathardwarecalc,
	};
};

export const getCorporatHardwareCalcFailure = (error) => {
	return {
		type: GET_CORPORAT_HARDWARE_CALC_FAILURE,
		error: error,
	};
};

export const getCorporatHardwareCalc = (params) => {
	return async (dispatch) => {
		dispatch(getCorporatHardwareCalcRequest());
		try {
			HttpUtility.userPostRequest(`${CORPORATE}/hardware/calculation`, params).then((response) => {
				if (response.status === 200) {
					const corporathardwarecalc = response.data;
					dispatch(getCorporatHardwareCalcSuccess(corporathardwarecalc));
				} else {
					dispatch(getCorporatHardwareCalcFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(getCorporatHardwareCalcFailure(error));
		}
	};
};

export const purchaseCorporatHardwareRequest = () => {
	return {
		type: PURCHASE_CORPORAT_HARDWARE_REQUEST,
	};
};

export const purchaseCorporatHardwareSuccess = (purchaseCorporatHardware) => {
	return {
		type: PURCHASE_CORPORAT_HARDWARE_SUCCESS,
		purchaseCorporatHardware,
	};
};

export const purchaseCorporatHardwareFailure = (error) => {
	return {
		type: PURCHASE_CORPORAT_HARDWARE_FAILURE,
		error: error,
	};
};

export const purchaseCorporatHardware = (params) => {
	return async (dispatch) => {
		dispatch(purchaseCorporatHardwareRequest());
		try {
			HttpUtility.userPostRequest(`${CORPORATE}/hardware/purchase`, params).then((response) => {
				if (response.status === 200) {
					const purchaseCorporatHardware = response.data;
					dispatch(purchaseCorporatHardwareSuccess(purchaseCorporatHardware));
					history.push('/purchase-completed')
				} else {
					dispatch(purchaseCorporatHardwareFailure(response.data));
				}
			});
		} catch (error) {
			dispatch(purchaseCorporatHardwareFailure(error));
		}
	};
};

export const showError = (params) => {
	return async (dispatch) => {
		dispatch({
			type: SHOW_ERROR,
			error: params,
		});
	}
}


export const updateHardwareTotal = (params) => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_HARDWARE_TOTAL,
			payload: params,
		});
	}
}

export const sameAsMainPractice = (params) => {
	return async (dispatch) => {

		dispatch({ type: SAME_AS_MAIN_PRACTICE_REQUEST, });

		try {
			await HttpUtility.getuserRequest(`${CORPORATE}/practices?parent_practice=1` , params)
				.then((response) => {
					if (response.status === 200) {
						const mainPracticeAddress = response.data;
						dispatch({
							type: SAME_AS_MAIN_PRACTICE_SUCCESS,
							mainPracticeAddress: mainPracticeAddress,
						});
					} else {
						dispatch({
							type: SAME_AS_MAIN_PRACTICE_FAIL,
							error: response.data,
						});
					}
				})
		} catch (error) {
			dispatch({ type: SAME_AS_MAIN_PRACTICE_FAIL, payload: error });
		}
	};
}


export const setGoogleTvTestDriveOnorOff = (params) => {
	return async (dispatch) => {
		dispatch({
			type: SET_GOOGLE_TV_TEST_DRIVE_ON_REQUEST,
			googleTvTestDrive: params,
		})
	}
}


export const setGoogleTvTestDriveMenuItem = (params) => {
	return async (dispatch) => {
		dispatch({
			type: SET_GOOGLE_TV_TEST_DRIVE_MENU_ITEM,
			googleTvTestDriveMenuItem: params,
			googleTvTestDriveChairsideMenuItem: params,
		})
	}
}

export const setGoogleTvTestDriveCatIsOpen = (params) => {
	return async (dispatch) => {
		dispatch({
			type: SET_GOOGLE_TV_TEST_DRIVE_CAT_IS_OPEN,
			catList:params.videosList,
			catTitle:params.catTitle
		})
	}
}

// Chairside Playlist TImeline Action Start
export const getChairsidePlaylistMediaDataRequest = () => {
	return {
		type: GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_REQUEST,
		loading: true,
		chairsidePlaylistMediaData: [],
		error: null,
	};
};
export const getChairsidePlaylistMediaDataSuccess = (data) => {
	return {
		type: GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_SUCCESS,
		loading: false,
		chairsidePlaylistMediaData: data,
		error: null,
	};
};
export const getChairsidePlaylistMediaDataFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_PLAYLIST_MEDIA_DATA_FAILURE,
		loading: false,
		chairsidePlaylistMediaData: [],
		error: error,
	};
};

// Chairside Playlist TImeline Creator Start
export const getChairsidePlaylistMediaData = (params) => {
	return async (dispatch) => {
		dispatch(getChairsidePlaylistMediaDataRequest());
		try {
			await HttpUtility.getuserRequest(consts.GET_CHAIRSIDE_PLAYLIST_MEDIA,
				// params
			).then((response) => {

				if (response.status === 200) {
					const chairsidePlaylistMediaData = response.data;
					dispatch(getChairsidePlaylistMediaDataSuccess(chairsidePlaylistMediaData));
				} else {
					dispatch(getChairsidePlaylistMediaDataFailure(response));
				}
			});
		} catch (error) {
			dispatch(getChairsidePlaylistMediaDataFailure(error));
		}
	};
};
// Chairside Playlist TImeline Action Creator End

// Chairside Playlist TImeline Action End




// Chairside Playlist TImeline Action Start
export const createChairsidePlaylistRequest = () => {
	return {
		type: CREATE_CHAIRSIDE_PLAYLIST_REQUEST,
		loading: true,
		chairsidePlaylist: [],
		error: null,
	};
};
export const createChairsidePlaylistSuccess = (data) => {
	return {
		type: CREATE_CHAIRSIDE_PLAYLIST_SUCCESS,
		loading: false,
		chairsidePlaylist: data,
		error: null,
	};
};
export const createChairsidePlaylistFailure = (error) => {
	return {
		type: CREATE_CHAIRSIDE_PLAYLIST_FAILURE,
		loading: false,
		chairsidePlaylist: [],
		error: error,
	};
};

export const createChairsidePlaylist = (params) => {
	return async (dispatch) => {
		dispatch(createChairsidePlaylistRequest());
		try {
			await HttpUtility.userPostRequest(consts.CREATE_CHAIRSIDE_PLAYLIST, params).then((response) => {

				if (response.status === 200) {
					const chairsidePlaylist = response.data;
					dispatch(createChairsidePlaylistSuccess(chairsidePlaylist));
					history.push({
						pathname: '/chairside-create-playlist',
						state: {  // location state
							isFromMenageChannel: false,
						},
					});
				} else {
					dispatch(createChairsidePlaylistFailure(response));
				}
			});
		} catch (error) {
			dispatch(createChairsidePlaylistFailure(error));
		}
	};
};

// Chairside Playlist TImeline Action End


export const getChairsidePlayListRequest = () => {
	return {
		type: GET_CHAIRSIDE_PLAYLIST_REQUEST,
		loading: true,
		// chairsidePlaylistsData: [],
		error: null,
	};
};

export const getChairsidePlayListSuccess = (data) => {
	return {
		type: GET_CHAIRSIDE_PLAYLIST_SUCCESS,
		loading: false,
		chairsidePlaylistsData: data,
		error: null,
	};
};

export const getChairsidePlayListFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_PLAYLIST_FAILURE,
		loading: true,
		error: error,
	};
};

export const getChairsidePlayListData = (params, sortedBy, sortedType) => {
	console.log("params, sortedBy, sortedType", params, sortedBy, sortedType)
	return async (dispatch) => {
		dispatch(getChairsidePlayListRequest());
		try {
			var sort = sortedBy ? sortedBy : 'newest';
			var type = sortedType ? sortedType : 'all';
			var url = `${consts.CREATE_CHAIRSIDE_PLAYLIST}?sorted_by=${sort}&sorted_type=${type}`;
			HttpUtility.getuserRequest(url).then(
				(response) => {
					if (response.status === 200) {
						const datum = response.data;
						dispatch(getChairsidePlayListSuccess(datum));
					} else {
						const datum = response ? response.error : {};
						dispatch(getChairsidePlayListFailure(datum));
					}
				}
			);
		} catch (error) {
			dispatch(getChairsidePlayListFailure(error));
		}
	};
};




// Chairside Playlist TImeline Action Start
export const getSingleChairsidePlaylistRequest = () => {
	return {
		type: GET_SINGLE_CHAIRSIDE_PLAYLIST_REQUEST,
		loading: true,
		chairsidePlaylist: [],
		error: null,
	};
};
export const getSingleChairsidePlaylistSuccess = (data) => {
	return {
		type: GET_SINGLE_CHAIRSIDE_PLAYLIST_SUCCESS,
		loading: false,
		chairsidePlaylist: data,
		error: null,
	};
};
export const getSingleChairsidePlaylistFailure = (error) => {
	return {
		type: GET_SINGLE_CHAIRSIDE_PLAYLIST_FAILURE,
		loading: false,
		chairsidePlaylist: [],
		error: error,
	};
};

export const getSingleChairsidePlaylist = (id, isFromMenageChannel) => {
	return async (dispatch) => {
		dispatch(getSingleChairsidePlaylistRequest());
		try {
			await HttpUtility.getuserRequest(`${consts.CREATE_CHAIRSIDE_PLAYLIST}/${id}`).then((response) => {

				if (response.status === 200) {
					const chairsidePlaylist = response.data;
					dispatch(getSingleChairsidePlaylistSuccess(chairsidePlaylist));
					if(isFromMenageChannel){
						history.push({
							pathname: '/chairside-create-playlist',
							state: {  // location state
								isFromMenageChannel: isFromMenageChannel,
							},
						});
					}
					
				} else {
					dispatch(getSingleChairsidePlaylistFailure(response));
				}
			});
		} catch (error) {
			dispatch(getSingleChairsidePlaylistFailure(error));
		}
	};
};

// Chairside Playlist TImeline Action End



// Chairside Playlist TImeline Action Start
export const updateChairsidePlaylistRequest = () => {
	return {
		type: UPDATE_CHAIRSIDE_PLAYLIST_REQUEST,
		loading: true,
		chairsidePlaylist: [],
		error: null,
	};
};
export const updateChairsidePlaylistSuccess = (data) => {
	return {
		type: UPDATE_CHAIRSIDE_PLAYLIST_SUCCESS,
		loading: false,
		chairsidePlaylist: data,
		error: null,
	};
};
export const updateChairsidePlaylistFailure = (error) => {
	return {
		type: UPDATE_CHAIRSIDE_PLAYLIST_FAILURE,
		loading: false,
		chairsidePlaylist: [],
		error: error,
	};
};

export const updateChairsidePlaylist = (id, param) => {
	return async (dispatch) => {
		dispatch(updateChairsidePlaylistRequest());
		try {
			await HttpUtility.userPostRequest(`${consts.CREATE_CHAIRSIDE_PLAYLIST}/${id}`, param).then((response) => {
				if (response.status === 200) {
					const chairsidePlaylist = response.data;
					dispatch(updateChairsidePlaylistSuccess(chairsidePlaylist));
				} else {
					dispatch(updateChairsidePlaylistFailure(response));
				}
			});
		} catch (error) {
			dispatch(updateChairsidePlaylistFailure(error));
		}
	};
};

// Chairside Playlist TImeline Action End



// Chairside Playlist TImeline Action Start
export const getChairsidePlayVideoRequest = () => {
	return {
		type: GET_CHAIRSIDE_PLAY_VIDEO_REQUEST,
		loading: true,
		chairsidePlayVideo: [],
		error: null,
	};
};
export const getChairsidePlayVideoSuccess = (data) => {
	return {
		type: GET_CHAIRSIDE_PLAY_VIDEO_SUCCESS,
		loading: false,
		chairsidePlayVideo: data,
		error: null,
	};
};
export const getChairsidePlayVideoFailure = (error) => {
	return {
		type: GET_CHAIRSIDE_PLAY_VIDEO_FAILURE,
		loading: false,
		chairsidePlayVideo: [],
		error: error,
	};
};

export const getChairsidePlayVideo = (id) => {
	return async (dispatch) => {
		dispatch(getChairsidePlayVideoRequest());
		try {
			await HttpUtility.getuserRequest(`${consts.CREATE_CHAIRSIDE_PLAYLIST}/${id}/play`).then((response) => {

				if (response.status === 200) {
					const chairsidePlayVideo = response.data;
					dispatch(getChairsidePlayVideoSuccess(chairsidePlayVideo));
				} else {
					dispatch(getChairsidePlayVideoFailure(response));
				}
			});
		} catch (error) {
			dispatch(getChairsidePlayVideoFailure(error));
		}
	};
};

// Chairside Playlist TImeline Action End



// Chairside Playlist TImeline Action Start
export const publishChairsidePlaylistRequest = () => {
	return {
		type: PUBLISH_CHAIRSIDE_PLAYLIST_REQUEST,
		buttonLoading3: true,
		error: null,
	};
};
export const publishChairsidePlaylistSuccess = (data) => {
	return {
		type: PUBLISH_CHAIRSIDE_PLAYLIST_SUCCESS,
		publistChairsidePlaylistStatus:data,
		buttonLoading3: false,
		error: null,
	};
};
export const publishChairsidePlaylistFailure = (error) => {
	return {
		type: PUBLISH_CHAIRSIDE_PLAYLIST_FAILURE,
		buttonLoading3: false,
		error: error,
	};
};

export const publishChairsidePlaylistResetMessage = () => {
	return {
		type: PUBLISH_CHAIRSIDE_PLAYLIST_RESET_MESSAGE,
	};
};

export const publishChairsidePlaylist = (id) => {
	return async (dispatch) => {
		dispatch(publishChairsidePlaylistRequest());
		try {
			await HttpUtility.getuserRequest(`${consts.CREATE_CHAIRSIDE_PLAYLIST}/${id}/publish`).then((response) => {

				if (response.status === 200) {
					const chairsidePlayVideo = response.data;
					dispatch(publishChairsidePlaylistSuccess(chairsidePlayVideo));
					setTimeout(() => {
						dispatch(publishChairsidePlaylistResetMessage(chairsidePlayVideo))
					}, 1000);
				} else {
					dispatch(publishChairsidePlaylistFailure(response));
				}
			});
		} catch (error) {
			dispatch(publishChairsidePlaylistFailure(error));
		}
	};
};

// Chairside Playlist TImeline Action End


// Chairside Playlist TImeline Action Start
export const removeChairsidePlaylistRequest = () => {
	return {
		type: REMOVE_CHAIRSIDE_PLAYLIST_REQUEST,
		buttonLoading3: true,
		error: null,
	};
};
export const removeChairsidePlaylistSuccess = (id) => {
	return {
		type: REMOVE_CHAIRSIDE_PLAYLIST_SUCCESS,
		buttonLoading3: false,
		id:id,
		error: null,
	};
};
export const removeChairsidePlaylistFailure = (error) => {
	return {
		type: REMOVE_CHAIRSIDE_PLAYLIST_FAILURE,
		buttonLoading3: false,
		error: error,
	};
};

export const removeChairsidePlaylist = (id) => {
	return async (dispatch) => {
		dispatch(removeChairsidePlaylistRequest());
		try {
			await HttpUtility.userdeleteRequest(`${consts.CREATE_CHAIRSIDE_PLAYLIST}/${id}`).then((response) => {

				if (response.status === 200) {
					dispatch(removeChairsidePlaylistSuccess(id));
				} else {
					dispatch(removeChairsidePlaylistFailure(response));
				}
			});
		} catch (error) {
			dispatch(removeChairsidePlaylistFailure(error));
		}
	};
};

// Chairside Playlist TImeline Action End


// Chairside Playlist TImeline Action Start
export const duplicateChairsidePlaylistRequest = () => {
	return {
		type: DUPLICATE_CHAIRSIDE_PLAYLIST_REQUEST,
		error: null,
	};
};
export const duplicateChairsidePlaylistSuccess = (id) => {
	return {
		type: DUPLICATE_CHAIRSIDE_PLAYLIST_SUCCESS,
		id:id,
		error: null,
	};
};
export const duplicateChairsidePlaylistFailure = (error) => {
	return {
		type: DUPLICATE_CHAIRSIDE_PLAYLIST_FAILURE,
		error: error,
	};
};

export const duplicateChairsidePlaylist = (id) => {
	return async (dispatch) => {
		dispatch(duplicateChairsidePlaylistRequest());
		try {
			await HttpUtility.userPostRequest(`${consts.CREATE_CHAIRSIDE_PLAYLIST}/${id}/replicate`).then((response) => {

				if (response.status === 200) {
					dispatch(duplicateChairsidePlaylistSuccess(id));
					dispatch(getChairsidePlayListData())
				} else {
					dispatch(duplicateChairsidePlaylistFailure(response));
				}
			});
		} catch (error) {
			dispatch(duplicateChairsidePlaylistFailure(error));
		}
	};
};

// Chairside Playlist TImeline Action End


export const setChairsidePlaylistMenuActive = (data) => {
	return {
		type: SET_CHAIRSIDE_PLAYLIST_MENU_ACTIVE,
		isChairsideMenuActiveIndex:data
	};
};


export const setChairsidePlaylistVideoActiveIndex = (data) => {
	return {
		type: SET_CHAIRSIDE_PLAYLIST_VIDEO_INDEX,
		chairsideVideoActiveIndex:data
	};
};


// save default profile
export const saveDefaultProfile = (params) => {
	return async (dispatch) => {
		dispatch(saveDefaultProfileRequest());
		try {
			await HttpUtility.userPostRequest(`${PRACTICEUSER}/autologin`, params).then((response) => {
				if (response.status === 200) {
					dispatch(saveDefaultProfileSuccess(params.status ? params.user_id : null));
				} else {
					dispatch(saveDefaultProfileFailure(response));
				}
			});
		} catch (error) {
			dispatch(saveDefaultProfileFailure(error));
		}
	};
};

export const saveDefaultProfileRequest = () => {
	return {
		type: DEFAULT_PROFILE_REQUEST,
		loadingDefault: true,
	};
};
export const saveDefaultProfileSuccess = (id) => {
	return {
		type: DEFAULT_PROFILE_SUCCESS,
		userId: id,
		loadingDefault: false,
	};
};
export const saveDefaultProfileFailure = (error) => {
	return {
		type: DEFAULT_PROFILE_FAILURE,
		loadingDefault: false,
		error: error,
	};
};
