import React from 'react'
import { Button } from 'reactstrap';
import { history } from '../..';
import styles from './style.module.scss';
import {  useSelector } from 'react-redux';

const WaitingRoom = () => {
  const states = useSelector(state => state.task);

  
  const redirectPage = (isPlan, redirectUrl, planName)=>{
    let plugins = states.avelablePlugin
    let selectedplan = plugins?.find(p => p.code === isPlan);
    if(selectedplan){
      history.push(redirectUrl);
    }else{
      history.push({
        pathname: '/upgrade-plan',
        selectplan: planName
      });
    }
  }
  

  return (
    <div className={`${styles.videoRoomBox} w-100 d-flex flex-column justify-content-center`}>
        <div className={`${styles.videoRoomBox__innerBox} mx-auto text-center mt-auto`}>
        <h2 className='mb-5'>This TV is located in the</h2>
        <h1 className='mb-5'>Waiting Room</h1>
        <p className='mb-5'>Dental educational videos designed to spark a conversation with the dentist, alongside practice special offers, news, weather, among other entertainments.</p>

        <h2 className='mb-5'>WAITING ROOM CHANNEL</h2>

        <p>*This selection can later be changed.</p>
        </div>

        
        <div className='mt-auto text-center mb-5'>
          <Button size='lg' color="default white" onClick={()=> redirectPage("waiting_room" ,'/google-tv-waiting-room', 'Waiting Room TV') } outline className="mb-4">Select</Button>
        </div>
    </div>
  )
}

export default WaitingRoom
