/** @format */

import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '../../components/LoadingButton'
const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const CLOSEBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomTimelineMyVideosCategories = ({ videos, setVideos, onUploadVideoClick, timeLineList, onPublish }) => {
	const [allVideosIndex, setAllVideosIndex] = useState(true);
	const [allVideosCount, setAllVideosCount] = useState(0);
	const [categories, setCategories] = useState([]);
	const [count, setCount] = useState(0);
	const state = useSelector((state) => state.task);

	useEffect(() => {
		const arr = [];
		var totalVideos = 0;
		var videosArray = [];
		state.myVideosTimeline.forEach((element) => {
			arr.push({
				category_id: element.categories_id,
				category_name: element.categories_name,
				selected: false,
				videosLength: element.video.length,
				videos: element.video,
			});
			element.video.forEach((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				videosArray.push({
					...video,
					category_name: element.category_name,
					category_id: element.categories_id,
					selected: false,
					isAdded,
				});
			});

			totalVideos += element.video.length;
		});
		setAllVideosCount(totalVideos);
		setCategories(arr);
		setVideos(videosArray);
		setCategories(arr);
	}, [state.myVideosTimeline ]);
	
	const allVideoListActive = allVideosIndex ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
	// On Categry Click
	const onCategoryClick = (item) => {
		setAllVideosIndex(false);
		setCount(count + 1);
		const videosArray = [];
		categories.forEach((element) => {
			if (element.category_id === item.category_id) {
				element.selected = !item.selected;
			}
		});
		categories.forEach((element) => {
			if (element.selected === true) {
				element.videos.forEach((video) => {
					var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
					videosArray.push({
						...video,
						category_name: element.category_name,
						category_id: element.categories_id,
						selected: false,
						isAdded,
					});
				});
			}
		});

		// select all
		var isAllSelectCat = categories.filter((x) => x.selected);
		setAllVideosIndex(categories.length == isAllSelectCat.length);

		setVideos(videosArray);
	};

	const changeAllVideos = () => {
		setAllVideosIndex(true);
		setCount(count + 1);
		categories.forEach((element) => {
			element.selected = false;
		});
		const videosArray = [];
		categories.forEach((element) => {
			element.videos.forEach((video) => {
				var isAdded = timeLineList.filter(x => x.media_id == video.waiting_room_media_id);
				videosArray.push({
					...video,
					category_name: element.category_name,
					category_id: element.categories_id,
					selected: false,
					isAdded
				});
			});
		});
		setVideos(videosArray);
	};

	return (
		<>
			<div className='videolist__main__screen d-flex flex-column'>
				<div className='videolist__main__lists'>
					<div className='videolist__main__header mb-3'>
						<span className='videolist__main__header__header-title d-block mb-3'>
							Topics
						</span>
					</div>
					<div className='videolist__submain__header'>
						<img
							src={allVideoListActive}
							alt=''
							onClick={() => changeAllVideos()}
							className='videolist__submain__header__checkbox'
						/>
						&nbsp; &nbsp; &nbsp;
						<span className='videolist__submain__header__title'>
							Show all my videos {`(${allVideosCount})`}<br/>
						</span>
					</div>
					<hr className='videolist__main__lists_hr' />
					{categories.length > 0
						? categories.map((item, index) => {
								const active =
									item.selected === true ? CLOSEBOXIMAGE : WHITEBOXIMAGE;
								return (
									<React.Fragment key={index}>
										<div className='videolist__main__lists__videoselection'>
											<img
												src={active}
												alt=''
												onClick={() => onCategoryClick(item)}
												className='videolist__submain__header__checkbox'
											/>
											&nbsp; &nbsp; &nbsp;
											<span className='videolist__submain__header__title'>
												{item.category_name}
												{`(${item.videosLength})`}
											</span>
										</div>
										{/* <br />  */}
									</React.Fragment>
								);
						  })
						: null}
				</div>
				<div className='d-flex justify-content-between mt-auto videolist__main__button'>
					<LoadingButton type="button"  label={"Publish timeline"} className="mr-3" loading={state.buttonLoading3} disabled={state.buttonLoading3} size="md" onClick={() => onPublish()}  color={'primary'}/>
					<Button type="button" size="md" className='ml-3' color='white' onClick={() => onUploadVideoClick()} >
						Upload videos
					</Button>
				</div>
			</div>
		</>
	);
};

export default WaitingRoomTimelineMyVideosCategories;
