import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";


import "./draganddrop.scss";
// const WHITEBOXIMAGE = "assets/img/colorsImg/whitebox.png";
// const RIGHTBOXIMAGE = "assets/img/colorsImg/right.png";

const DragAndDrop = ({ saveToPlaylist, list, showVideos, onTask }) => {
 
  const [selectedVideoLength, setSelectedVideoLength] = useState([]);
  const [count, setCount] = useState(0);
  console.log(selectedVideoLength);
  useEffect(() => {
    showVideos.forEach((element) => {
      element.selected = true;
    });
    setSelectedVideoLength(showVideos);
    saveToPlaylist(showVideos);
  }, [showVideos]);

  //CHANGE  SELECTED VIDEO
  const changeVideoSelected = (video) => {
    setCount(count + 1);
    showVideos.forEach((element) => {
      if (element.id === video.id) {
        element.selected = !video.selected;
      }
    });
    var arr = [];
    showVideos.forEach((element) => {
      if (element.selected === true) {
        arr.push(element);
      }
    });

    setSelectedVideoLength(arr);
    saveToPlaylist(arr);
  };

  //RESET SELECTED VIDEOS
  const resetButton = () => {
    setCount(count + 1);
    showVideos.forEach((element) => {
      element.selected = false;
    });
    var arr = [];
    showVideos.forEach((element) => {
      if (element.selected === true) {
        arr.push(element);
      }
    });

    setSelectedVideoLength(arr);
    saveToPlaylist(arr);
  };
  console.log(resetButton)
  const onDragStart = (ev, id) => {
    console.log("dragstart:", id);
    ev.dataTransfer.setData("id", id);
  };
  const onDragOver = (ev) => {
    ev.preventDefault();
  };

  const onDrop = (ev, cat) => {
    console.log(cat);
    let id = ev.dataTransfer.getData("id");
    console.log(id);

    let tasks = showVideos.filter((task) => {
      if (task.id === id) {
        task.category = cat;
      }
      return task;
    });
    console.log(tasks);
  };

  return (
    <Container style={{ width: "1200px" }}>
      <div  className="dragdrop__main__screen">
        <div
          onDragOver={(e) => onDragOver(e)}
          onDrop={(e) => {
            onDrop(e, "wip");
          }}
        >
          <Row>
            {showVideos.map((video, index) => {
             
              const selectedImage =
                video.selected === true ? "" : "";

              return (
                <Col
                  lg="4"
                  key={index}
                  className="dragdrop__main__columnScreen"
                  onDragStart={(e) => onDragStart(e, video.id)}
                  draggable={true}
                >
                  <div                  
                    className="card" 
                    onClick={() => changeVideoSelected(video)}
                  >
                    <img className="dragdrop__main__selectedImage" src={`${selectedImage}`} alt="" />
                    <video controls>
                      <source src={`${video.video}`} type="video/mp4" />
                    </video>
                    <div className="dragdrop__main__subtitle">
                      <p>{video.title.toUpperCase()}</p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default DragAndDrop;
