import Home from '../components/SidebarMenuItems/Home';
import NewChannels from '../components/SidebarMenuItems/NewChannels';
import MyLists from '../components/SidebarMenuItems/MyLists';
import AllChannelsList from '../components/SidebarMenuItems/AllChannels';
import MyChannelsList from '../components/SidebarMenuItems/MyChannels';
import SearchScreen from '../components/SidebarMenuItems/Search';
import SettingsScreen from '../Settings';

import {ReactComponent as SearchIcon} from '../images/ic_search.svg';
import {ReactComponent as HomeIcon} from '../images/ic_home.svg';
import {ReactComponent as VideoLibraryIcon} from '../images/ic_new_channels.svg';
import {ReactComponent as AddIcon} from '../images/ic_my_list.svg';
import {ReactComponent as ImageIcon} from '../images/ic_cetegory.svg';
import {ReactComponent as AllChannels} from '../images/ic_all_channel.svg';
import {ReactComponent as Mychannels} from '../images/ic_My_channels.svg';
import {ReactComponent as Settings} from '../images/ic_setting.svg';
import {ReactComponent as Videorequest} from '../images/ic_Video_request.svg';
import {ReactComponent as MyProfile} from '../images/ic_my_profile.svg';


export const tabList = [
  {
    name : 'Search',
    icon : <SearchIcon/>
  },
  {
    name : 'Home',
    icon :  <HomeIcon  />
  },
  {
    name : 'New channels',
    icon :  <VideoLibraryIcon  />
  },
  {
    name : 'My List',
    icon :  <AddIcon />
  },
  {
    name : 'All channels',
    icon : <AllChannels />
  },
  {
    name : 'My channels',
    icon : <Mychannels />
  },
  {
    name : 'My profile',
    icon : <MyProfile />
  },
  {
    name : 'Settings',
    icon : <Settings />
  },
  // {
  //   name : 'Video request',
  //   icon : <Videorequest />
  // },
]

export const tabItems = [
  {
    name : 'Search',
    component : <SearchScreen />
  },
  {
    name : 'Home',
    component : <Home/>
  },
  {
    name : 'New channels',
    component :<NewChannels />
  },
  {
    name : 'My List',
    component :<MyLists/>
  },
  {
    name : 'All Channels',
    component :<AllChannelsList />
  },
  {
    name : 'My channels',
    component :<MyChannelsList/>
  },
  
]
