export const TOKEN_KEY = "access_token"
export const DEVICE_CODE = "device_code"
export const SYSTEM_LANGUAGE = "system_language"
export const SYSTEM_VIDEO_QUALITY = "system_video_quality"
export const WAITING_ROOM_VIDEO_QUALITY = "waiting_room_video_quality"

export const setToken = function(token){
    localStorage.setItem(TOKEN_KEY, token);
}

export const setDeviceCode = function(devicecode){
    localStorage.setItem(DEVICE_CODE, devicecode);
}

export const setSystemLanguage = function(system_language){
    localStorage.setItem(SYSTEM_LANGUAGE, system_language);
}

export const setSystemVideoQuality = function(system_video_quality){
    localStorage.setItem(SYSTEM_VIDEO_QUALITY, system_video_quality);
}

export const setWaitingRoomVideoQuality = function(waiting_room_video_quality){
    localStorage.setItem(WAITING_ROOM_VIDEO_QUALITY, waiting_room_video_quality);
}


export const getToken = function(){
    return localStorage.getItem(TOKEN_KEY)
}

export const getDeviceCode = function(){
    return localStorage.getItem(DEVICE_CODE)
}

export const getSystemLanguage = function(){
    return localStorage.getItem(SYSTEM_LANGUAGE)
}

export const getSystemVideoQuality = function(){
    return localStorage.getItem(SYSTEM_VIDEO_QUALITY)
}

export const getWaitingVideoQuality = function(){
    return localStorage.getItem(WAITING_ROOM_VIDEO_QUALITY)
}

export const clearStorage = function(){
    // localStorage.clear();
    localStorage.removeItem('persist:root')
    localStorage.removeItem('access_token')
    window.location = "/profileselection";
}

export const cleartoken = function(){
    // localStorage.clear();
    localStorage.removeItem('access_token')
}

export const clearAllStorage = function(){
    localStorage.clear();
    localStorage.removeItem('persist:root')
}

export const clearRecentPlaylists = function(){
    localStorage.removeItem('recent_playlists')
}