import { createStore, applyMiddleware,compose  } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/index';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
   key: 'root',
   storage: storage,
}

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
   const { logger } = require(`redux-logger`);

   middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, reducers)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store  =  () => {
   let store = createStore(
     persistedReducer,
     composeEnhancers(applyMiddleware(...middlewares))
   )

   let persistor = persistStore(store)
   return { store, persistor }
}

export default store
