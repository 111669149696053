import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import IconsArrowLeft from '../../img/arrow_left.svg'

import './style.scss';
import { Button} from 'reactstrap';
const HomeIndex = (props) => {
  return(
    <div className="main__screen invoice__page  d-flex flex-column h-100 align-items-center justify-content-center">
      <SubHeader className="mb-auto" title="Invoices"/>
      <div className="invoice__inner mb-auto">
        <div className="invoice__inner__header">
          <div className="row">
            <div className="col-7">
              <h4>Invoice history</h4>
            </div>
            <div className="col-5 text-right">
              <div className="year-change">
                <div className="year-change-arrow">
                  <a href="#"><img src={IconsArrowLeft} className="arrow-left"/></a>
                  <a href="#"><img src={IconsArrowLeft} className="arrow-right" /></a>
                </div>
                <h3>2019</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="invoice__inner__body">
          <div className="invoice__empty flex-column d-flex align-items-center justify-content-center">
            <h4>You haven't been issued an invoice yet.</h4>
            <p>Once subscribed, your first issued invoice will show up on a list.</p>
          </div>
        </div>
      </div>
    </div>
  ) 
}
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
