/** @format */

import React, { useState, useEffect } from 'react';
import Profile_icon from '../../../img/play_white.svg';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Collapse, Card, Button } from 'reactstrap';
import Player from '../../../components/videoPlayer/operatoryVideoplayer';
import MenuIcon from '../../../img/menu.png';
import { getOperatoryVideos, removeOperatoryRoomTimelineMyVideo, updateOperatoryRoomTimelineMyVideo } from '../../../store/actions/taskAction';
import LoadingButton from '../../../components/LoadingButton';
import {InputField} from '../../../components/InputField';
import { YOUTUBESERVICEPROVIDER, MYVIDEOSTYPE, formatSecond, TIMELINEMEDIATYPE, OPERATORYMYVIDEO } from '../../../data/comman';
// import './style.scss';
const WHITEBOXIMAGE = 'assets/img/colorsImg/whitebox.png';
const RIGHTBOXIMAGE = 'assets/img/colorsImg/closeicon.png';

const WaitingRoomTimelineMyVideosSelectedVideo = ({ videos, setVideos, timeLineList }) => {
	const state = useSelector((state) => state.task);
	const dispatch = useDispatch();
	const [showVideo, setShowVideo] = useState({
		video: {},
		showVideo: false,
	});
	const [count, setCount] = useState(0);
	const [playerVideo, setPlayerVideo] = useState(null);
	const [editorImg, setEditorImg] = useState({});
	const [isEditor, setIsEditor] = useState(false);
	const [modal, setModal] = useState(false);
	const [updateError, setUpdateError ] = useState("")
	const playSelectedVideo = (video) => {
		setPlayerVideo(video);
		setShowVideo({
			video: video,
			showVideo: true,
		});
	};
	const onClickClose = (close) => {
		setShowVideo({
			video: {},
			showVideo: false,
		});
	};

	const openImgEditor = (img) => {
		if (img) {
			setEditorImg(img)
			setIsEditor(true)
			onClickClose()
		}
    }

	const togglePip = (isPipOn) => {
		setShowVideo({
			...showVideo,
			showVideo: !isPipOn,
		});
	}

	// useEffect(() => {
	// 	const categoryArray = [];
	// 	const videos = [];
	// 	state.operatoryRoomMyVideos.forEach((element) => {
	// 		categoryArray.push({
	// 			category_id: element.categories_id,
	// 			category_name: element.categories_name,
	// 			selected: false,
	// 			videosLength: element.video.length,
	// 		});
	// 		element.video.forEach((video) => {
	// 			var isAdded = timeLineList.filter(x => x.media_id == video.operatory_media_id);
	// 			videos.push({
	// 				...video,
	// 				category_name: element.categories_name,
	// 				category_id: element.categories_id,
	// 				selected: false,
	// 				isAdded,
	// 			});
	// 		});
	// 	});
	// 	setVideos(videos);
	// 	setRemoveVideo({});
    //     setMenuOpen(false);
	// 	setRemoveMenu(false);
	// 	setRenameMenu(false);
	// 	setRename("");
	// }, [timeLineList]);

	const getMyvideos = () =>{
		dispatch(getOperatoryVideos({ mediaType: OPERATORYMYVIDEO }));
	}

	useEffect(()=>{
		setRemoveMenu(false);
		setRemoveVideo({});
        setMenuOpen(false);
		setRenameMenu(false);
		setRename("");


		var videoStatus = videos.findIndex(x => x.status == 99)
		console.log(videoStatus,'videoStatus')
		if(videoStatus != -1){
			const intervalCall = setInterval(() => {
				getMyvideos();
			}, 120000);
			return () => {
			  // clean up
			  clearInterval(intervalCall);
			};
		}

	}, [state.operatoryRoomMyVideos]);

	const [removeVideo, setRemoveVideo] = useState({});
	const [menuOpen, setMenuOpen] = useState(false);
	const [removeMenu, setRemoveMenu] = useState(false);
	const [renameMenu, setRenameMenu] = useState(false);
	const [rename, setRename] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const changeVideoSelected = (video) => {
		setCount(count + 1);
		videos.forEach((element) => {
			if (element.provider_id === video.provider_id) {
				element.selected = !video.selected;
			}
		});
		var arr = [];
		videos.forEach((element) => {
			if (element.selected === true) {
				arr.push(element);
			}
		});
	};
	const format = (seconds) => {
		if (isNaN(seconds)) {
			return '00:00';
		}

		const date = new Date(seconds * 1000);
		const hh = date.getUTCHours();
		const mm = date.getUTCMinutes();
		const ss = date.getUTCSeconds().toString().padStart(2, '0');

		if (hh) {
			return `${hh}:${mm.toString().padStart(2, '0')} :${ss}`;
		}
		return `${mm}:${ss}`;
	};
	// Open Menu
	const openMenu = (video) => {
		setMenuOpen(video.operatory_media_id == removeVideo.operatory_media_id ?  !menuOpen : true);
		setRemoveVideo(video);
		setRenameMenu(false);
		setRemoveMenu(false);
	};

	const removeMyVideo = (video) => {
		setRemoveMenu(true);
	};
	const submitRemoveVideo = (video) => {
		// setRemoveVideo({});
		dispatch(removeOperatoryRoomTimelineMyVideo(video));
	};

	const updateErrorCallback = (error) => {
		setUpdateError(error)
	}

	const submitRenameVideo = (video) => {
		console.log(video, video);
		var param = {
			category_id:video.category_id,
			operatory_media_id: video.operatory_media_id,
			media_language_id: 1,
			about: {
				title: rename,
			}
		}
		dispatch(updateOperatoryRoomTimelineMyVideo(param, updateErrorCallback));
	}

    const cancelButton = (video) => {
        setRemoveVideo({});
        setMenuOpen(false);
		setRemoveMenu(false);
		setRenameMenu(false);
	}
	
	return (
		<div className="w-100">
			<div className="video_list">
			{videos.length > 0
				? videos.map((video, index) => {
				const selectedImage = video.selected === true ? RIGHTBOXIMAGE : WHITEBOXIMAGE;
				return (
					<Col draggable={(video.status == 99 && video.service_provider_id != 2) ? false : true} onDragStart={(event) => { event.dataTransfer.setData("timeline", JSON.stringify({ type: TIMELINEMEDIATYPE, timeLine: video })) }}
						lg='4'
						key={index}
						className={`videoSelected__main__columnFour videoSelected_box ${video.isAdded && video.isAdded.length > 0 ? 'border-4 border-warning' : ''}`}>
								<div
									className={`videoSelected__main__card ${menuOpen ? 'menu-opened' : '' || renameMenu ? 'menu-opened':'' || removeMenu ? "menu-opened" : ''}`}
									onClick={() => changeVideoSelected(video)}>
									{/* <img
										className='videoSelected__main__card__selectedImage'
										src={`${selectedImage}`}
										alt=''
									/> */}
									{video.isAdded && video.isAdded.length > 0 && <h3 className='videoSelected__main__card__selectedImage'>{video.isAdded.length}</h3>}
									<div style={{ height: '100%', width: '100%' }} className="my-video-bg-img">
										<img
											src={video.thumb}
											alt={video.file_name}
											style={{
												height: '100%',
												width: '100%',
												objectFit: 'cover',
											}}
										/>
									</div>
									{video.status == 99 && video.service_provider_id != 2?
										<div className='status_panding'>
											<div className='d-flex flex-column h-100 p-4 text-center align-items-center justify-content-center'>
											<h4>Pending</h4>
											<p style={{color:'#FFF', zIndex:'2'}}>Please wait while processing. It may take up to 15 to 20 minutes. Check back later.</p>
											</div>
										</div>
									:
									<div className='videoSelected__main__card__subTitle'>
										<div className='videoSelected__main__card__video_icon' onClick={() => playSelectedVideo(video)}>
											<img
												src={Profile_icon}
												alt='play_video'
											/>
										</div>
										<p className='videoSelected__main__card__title'>
											{video.title} | {formatSecond(video.duration)}
										</p>
										<p className='videoSelected__main__card__smalltitile'>
											{video.category_name}
										</p>
									</div>
									}
									<div
										className='page10__main__div2__gridbox__iconDiv my-videotoggle-btn'
										style={{ marginTop: '-95px', marginRight: '5px' }}>
										<img src={MenuIcon} onClick={() => openMenu(video)} alt="" />
									</div>
									{menuOpen && removeVideo.operatory_media_id === video.operatory_media_id && (
										<div className="myVideoOptions"> 
											<div>
												<div>
													<h6
														style={{
															color: 'white',
															padding: '10px',
															textTransform: 'uppercase',
														}}>
														{YOUTUBESERVICEPROVIDER == video.service_provider_id ? 'YOUTUBE VIDEO OPTIONS' : 'VIDEO OPTIONS'}{' '}
													</h6>
													<img src={MenuIcon}
														style={{
															width: '10%',
															marginTop: '-28px',
															marginLeft: '304px',
														}}
														className="menu-icon-img"
														onClick={() => cancelButton(video)}
														alt=""
													/>
													<hr />
													<div>
														<span style={{  }} className="myvideo-menu-item" onClick={() => { setRenameMenu(true); setMenuOpen(false);}}>Rename</span>
														<hr />
														<span
															style={{  }} className="myvideo-menu-item"
															onClick={() => {removeMyVideo(video); setMenuOpen(false);} }>
															Remove
														</span>
													</div>
												</div>
											</div>
										</div>
									)}
									{renameMenu && removeVideo.operatory_media_id === video.operatory_media_id && (
										<div className="myVideoOptions"> 
											<div>
												<h6
													style={{
														color: 'white',
														padding: '10px',
														textTransform: 'uppercase',
													}}>
													{YOUTUBESERVICEPROVIDER == video.service_provider_id ? 'YOUTUBE VIDEO OPTIONS' : 'VIDEO OPTIONS'}{' '}
												</h6>
												<img
												className="menu-icon-img"
													src={MenuIcon}
													alt=""
													style={{
														width: '10%',
														marginTop: '-28px',
														marginLeft: '304px',
													}}
												/>
												<hr />
												<div className="remane-popup-details">
													<InputField type="text" placeholder='Enter a name for your video' name="" value={rename} onChange={(e) => { setRename(e.target.value); setUpdateError("")}}/>
													{updateError && <span className='text--danger'>{updateError}</span>}
													<br/><br/>
													<Row style={{  }}>
														<Col lg='6'>
															<LoadingButton type="button" label="RENAME" loading={state.buttonLoading} size="md" disabled={rename == "" || state.buttonLoading} onClick={()=>submitRenameVideo(video)} className={``} color="primary"/>
														</Col>
														<Col lg='6'>
															<Button
																style={{
																	backgroundColor: 'transparent',
																	border: '1px solid white',
																}}
																onClick={()=> cancelButton(video)}
																>
																CANCEL
															</Button>
														</Col>
													</Row>

													{/* <span
														style={{ padding: '10px' }}
														onClick={() => removeMyVideo(video)}>
														Remove
													</span> */}
												</div>
											</div>
										</div>
									)}
									{removeMenu && removeVideo.operatory_media_id === video.operatory_media_id && (
										<div className="myVideoOptions bg_red"> 
											<div>
												<h6
													style={{
														color: 'white',
														padding: '10px',
														textTransform: 'uppercase',
													}}>
													REMOVE{' '}
												</h6>
												<img
												className="menu-icon-img"
													src={MenuIcon}
													alt=""
													style={{
														width: '10%',
														marginTop: '-28px',
														marginLeft: '304px',
													}}
												/>
												<hr />
												<div className="remane-popup-details remove-popup-details">
													<span style={{ fontSize:"14px" }}>Remove video permanently from your library?</span>
													<Row style={{  }}>
														<Col lg='6'>
															<LoadingButton type="button" label="REMOVE" loading={state.buttonLoading4} disabled={state.buttonLoading4} size="md" onClick={()=>submitRemoveVideo(video)} className={``} color="default"/>
														</Col>
														<Col lg='6'>
															<Button
																style={{
																	backgroundColor: 'transparent',
																	border: '1px solid white',
																}}
																onClick={()=> cancelButton(video)}
																>
																CANCEL
															</Button>
														</Col>
													</Row>

													{/* <span
														style={{ padding: '10px' }}
														onClick={() => removeMyVideo(video)}>
														Remove
													</span> */}
												</div>
											</div>
										</div>
									)}
								</div>	
							</Col>
						);
					})
				: null}
				{console.log(showVideo,'showVideo')}
					{showVideo.video ? (
						<div size="xxl" className={`${showVideo.showVideo ? 'bg-block' : 'bg-none'} h-100 d-flex flex-column align-items-strach justify-content-center modal-video`} isOpen={showVideo.video} toggle={toggle}>
							<div className={`${showVideo.showVideo ? 'd-block' : 'd-none'} video-show sdf  mt-5`}> 
								<Player
									video={showVideo.video}
									title={showVideo.video}
									className="w-100 h-100"
									onClickClose={onClickClose}
									togglePip={togglePip}
									isWaitingRoom={true}
									isMyvideo={showVideo?.video?.service_provider_id !== 2 ? true : false}
								/>
								
							</div>
						</div>
					) : ('')}
			</div>
		</div>
	);
};

export default WaitingRoomTimelineMyVideosSelectedVideo;
