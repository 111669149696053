import React, { useState ,useEffect } from 'react';
import DropDown from '../../components/DropDown'
import { Input, Button} from 'reactstrap';
import {
	setWaitingRoomTimelineNewSlide,CreatewaitingRoomTimelineSlides ,waitingRoomChannelSlide
} from '../../store/actions/taskAction';
import { useSelector, useDispatch } from 'react-redux';
import LoadingButton from '../LoadingButton';
import PreviewModal from '../PreviewModal';
import { SLIDEDURATION } from '../../data/comman';
import domtoimage from 'dom-to-image';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';

const WaitingRoomTimelineCategory = ({ onNewSlideClick, getSlideContent, slideTrasitions, onSelectSingleSlide }) => {
    const [slideCategory, setSlideCategory] = useState([]);
    const slideDuration = SLIDEDURATION;//[{ label: '0', value: 0 },{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 }]
    
    const [selectedDuaration,setSelectedDuaration] = useState(onSelectSingleSlide.duration ? slideDuration.find(x => x.value == onSelectSingleSlide.duration) : null);
    const [error, setError] = useState("");
    
    const [selectedCategory,setSelectedCategory] = useState(null);
    const [slideTitle,setslideTitle] = useState(onSelectSingleSlide.title ? onSelectSingleSlide.title : '');
    
    const dispatch = useDispatch();
    let newSlide = useSelector((state) => state.task.newSlide);
    let states = useSelector((state) => state.task);
    const [isSaveSlide, setIsSlide] = useState(false);
    const [createSlideLoader, setCreateSlideLoader] = useState(states.buttonLoading);
    const [createSlideSuccess, setCreateSlideSuccess] = useState(false);
    const [previewSlide, setPreviewSlide] = useState(false);
    const [previewContent, setPreviewContent] = useState("")
    
    useEffect(()=>{
        var categoryList = [];
        states.waitingRoomChannelSlides.forEach((element) => {
            categoryList.push({
                id: element.id,
                name: element.title,
            });
        })
        setSlideCategory(categoryList);
        if(onSelectSingleSlide.cat_id && categoryList.length > 0){
            var selectedCat = categoryList.find(x => x.id == onSelectSingleSlide.cat_id);
            setSelectedCategory(selectedCat)
            dispatch(setWaitingRoomTimelineNewSlide({
                cat_id: selectedCat.id,
                duration: onSelectSingleSlide.duration,
                title: onSelectSingleSlide.title,
            }));
        }
        setCreateSlideSuccess(false)
    }, [states.waitingRoomChannelSlides]);

    useEffect(()=>{
        setCreateSlideLoader(states.buttonLoading)
    },[states.buttonLoading])

    useEffect(()=>{
        isValidate();
    }, [slideTitle, selectedCategory, selectedDuaration])
    
    const handleChange = (options) => {
        setSelectedCategory(options);
        dispatch(setWaitingRoomTimelineNewSlide({ cat_id: options.id }));
        
    }
    const handleDurationChange = (options) => {
        setSelectedDuaration(options)
        dispatch(setWaitingRoomTimelineNewSlide({ duration: options.value }));
    }

    const handleSlideNameChange = (value) => {
        setslideTitle(value);
        dispatch(setWaitingRoomTimelineNewSlide({ title: value,content:"<p>This is sample Slide test to insert</p>" }));
    }

    const onCreateSlideClick = () => {
        console.log("New Slide", newSlide);
        var slideContent = getSlideContent();
        if (slideTitle === '') {
            setError('Slide title is required');
        }
        else if (selectedCategory == null) {
            setError("Slide category is required");
        }
        else if (selectedDuaration == null) {
            setError("Slide duration is required");
        }
        else if (slideContent.trim() == "") {
            setError("Slide  layout is requied to create slide");
        }
        else {
            var images = document.querySelectorAll(".THALAMUS_IMAGE");
            images.forEach((item, index) => {
                document.querySelectorAll('.THALAMUS_IMAGE')[index].setAttribute('src',`${item.src.split("?")["0"]}?time=${moment().unix()}`)
                // document.querySelectorAll('.THALAMUS_IMAGE')[index].setAttribute('crossOrigin','anonymous')
            })

            setCreateSlideLoader(true)
            
            // newSlide.content = slideContent;
            // newSlide.settings = {};
            // newSlide.settings.transition = slideTrasitions;
            
            const formData = new FormData();
            var settings = {
                transition: slideTrasitions
            };
            formData.append('cat_id', newSlide.cat_id);
            formData.append('content', slideContent);
            formData.append('duration', newSlide.duration);
            formData.append('title', newSlide.title);
            formData.append('settings[transition]', slideTrasitions);

            var isBlankSLide = !!document.getElementById("mainEditor")
            var myEditor = document.getElementById("mainEditor");
            if(isBlankSLide){    // 
                var placeholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY7h79y4ABTICmGnXPbMAAAAASUVORK5CYII"
                // domtoimage.toBlob(myEditor, { height: 530, width: 960, cacheBust: true, imagePlaceholder: placeholder }).then(async function (blob) {
                    htmlToImage.toBlob(myEditor, { height: 530, width: 960, cacheBust: true, imagePlaceholder: placeholder }).then(async function (blob) {
                    // console.log("toPng",blob);
                    formData.append('image',blob)
                    await dispatch(CreatewaitingRoomTimelineSlides(formData, onSelectSingleSlide));
                    setCreateSlideLoader(false);
                    setCreateSlideSuccess(true)
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    setCreateSlideLoader(false);
                    setCreateSlideSuccess(false)
                });
            }
        }
        setTimeout(()=>{
            setError("");
        }, 2000)
    }

    const onNewSlide =  () =>{
        dispatch(waitingRoomChannelSlide());
        onNewSlideClick()
    }
    // onNewSlideClick

    const isValidate = () => {
        if (slideTitle === '' || selectedCategory == null || selectedDuaration == null ) {
            setIsSlide(false);
        }else{
            setIsSlide(true);
        }
    }

    const showPreviewModal = () => {
        var content = getSlideContent();
        if(content){
            setPreviewContent(content);
            setPreviewSlide(true);
        }
    }
    
    return (
        <div className='videolist__main__screen my-slide-left-screen d-flex flex-column'>
            <span className='videolist__main__header__header-title mb-3 d-block'>
                Slide Creation
            </span>
            <div className='videolist__main__lists' style={{ overflowY: 'inherit' }}>
                <div className='videolist__main__header'>
                    <span>
                    Use the bars above the canvas to add and edit content to your new slide. Once done, please give it a name, select display time and category.
                    </span>
                </div>
 
                <div className="form-group">
                    <label>Slide name</label>
                    <Input type="text" name="Slide Name" value={slideTitle} onChange={(e) => { handleSlideNameChange(e.target.value); } }/>
                </div>

                <div className="form-group">
                    <label>Display time for individual instances in the timeline</label>
                    <DropDown
                        className='react-select-container w-100'
                        classNamePrefix="react-select"
                        options={slideDuration}
                        onChange={(slideDuration) => { handleDurationChange(slideDuration); }}
                        isSearchable={false}
                        placeholder={'Slide Duration'}
                        value={selectedDuaration}
                    />
                </div>

                <div className="form-group">
                    <label>Category</label>
                    <DropDown
                        className='react-select-container w-100 slide-cat-dropdown'
                        classNamePrefix="react-select"
                        options={slideCategory}
                        onChange={(slideCategory) => { handleChange(slideCategory); }}
                        isSearchable={false}
                        getOptionLabel={x => `${x.name}`}
                        getOptionValue={x => `${x.id} ${x.name}`}
                        placeholder={'Select Category'}
                        value={selectedCategory}
                    />
                </div>

                
              
            </div>
            <div style={{ textAlign: 'center' }} className='w-100 d-flex mt-auto'>
                    
                    <Button
                        className='videolist__main__button__warning btn btn-primary m-0 w-100'
                        color='white'
                        onClick={() => { showPreviewModal() }}>
                        Preview
                    </Button>
                    {/* <Button
                        className='videolist__main__button__warning btn btn-primary btn-md ml-0 mr-0'
                        color='white'
                        onClick={() => onNewSlide()}
                    >
                        View My Slide
                    </Button> */}
            </div>
                    <div style={{ textAlign: 'center', marginTop: '15px' }} className="w-100 create-slide-btn">
                        <LoadingButton className="w-100 create-slider-button" type="button" label={`${onSelectSingleSlide.id ? "Update" : createSlideSuccess ? 'Created' : 'Create'}  Slide`} loading={createSlideLoader} disabled={!isSaveSlide || createSlideLoader} size="md" onClick={onCreateSlideClick} color={createSlideSuccess ? 'green' : 'primary'}/>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '15px' }} className="w-100 create-slide-btn">
                        <Button className='videolist__main__button__warning btn btn-primary btn-md ml-0 mr-0' color="red" onClick={() => onNewSlide()} disabled={createSlideLoader}>Discard</Button> 
                    </div>

                    <PreviewModal html={previewContent} isOpen={previewSlide} toggle={()=> setPreviewSlide(!previewSlide) }/>
        </div>
    )
}
export default WaitingRoomTimelineCategory;