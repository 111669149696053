import React, { useEffect, useState } from "react";
import {Button, Grid} from "@material-ui/core"
import SubHeader from "../../components/Header/subHeader";
import "./style.scss";
// import VideoList from "../../components/showAllvideos/VideoList";
import { videoselectionList } from "../practiseComponent/staticFile";
import SelectionTab from "../../components/selectionTab/SelectionTab";
import DragAndDrop from "../../components/drag&drop/DragAndDrop";
import DropBox from "../../components/drag&drop/DropBox";
// import DragDropSlider from "../../components/drag&drop/DragDropSlider";

const sections = ["education", "entertainment", "my videos", "my slides", "social media feed", "triva"];


const WaitingRoom = () => {
    const [selectedSection, setSelectedSection] = useState(sections[0]);
    const [videoSelectItem,setVideoSelectItem] =useState([]);
    const [playListSelectedItems,setPlayListSelectedItems] =useState([]);
    const [tasks, setTasks] = useState({
        wip : [],
        complete : []
    });
 
 useEffect(()=>{
      var arr = [];
    
   videoselectionList.videoList.forEach(element => {
       element.videos.forEach(video=>{
       arr.push(video)
       }) 
     });
  
     
     setVideoSelectItem(arr);
     setPlayListSelectedItems(arr);
},[]);




    const handleClick = (name) =>{
        setSelectedSection(name);
    }

    //SUBMIT VIDEO LISTS
 const submitVideoLists = (items) =>{
     
    setVideoSelectItem(items);
 }
 //SAVE TO PLAYLIST
 const saveToPlaylist = (playListItems) => {
    setPlayListSelectedItems(playListItems);
} 

    return(
        <div className="waiting_room__main__screen   flex-column h-100">
            <SubHeader className="mb-auto" title="Waiting Room Timeline"/>

            <div className="initial_setup__inner__screen mb-auto">
                <Grid container alignContent="center" justify="center" alignItems="center">
                    <Grid item lg={12}>
                        <div style={{textAlign:"center"}}>
                            {sections.map((name, index)=>{
                                const active = selectedSection === name ? {variant: "contained",color:'black'} : {variant: "outlined",color:'white'}
                                return (
                                    <React.Fragment  key={index}>
                                        <Button  style={{borderColor:'white',color:`${active.color}`,width:"150px"}}  variant={`${active.variant}`} onClick={()=>handleClick(name)}>{name}</Button>&nbsp;&nbsp;
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </Grid>
                    <Grid item lg={5} style={{paddingTop:"20px"}}>
                      <SelectionTab label="Select specializations(s)" list={videoselectionList} submitVideoLists={submitVideoLists}/>
                    </Grid>
                    <Grid item lg={7}>
                    <DragAndDrop saveToPlaylist={saveToPlaylist}   showVideos={videoSelectItem} completeVideo={tasks.complete} onTask={setTasks} />
                    </Grid>
                    <Grid item lg={12}>
                        <DropBox lists={videoSelectItem} />
                    </Grid>
                    <Grid item lg={12}>
                       {/* <DragDropSlider/> */}
                    </Grid>
                </Grid>
            </div>

        </div>
   
    )
}
export default WaitingRoom;