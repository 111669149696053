import React from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';

import BillingProfileImg from '../../img/BillingProfileAvtar.png'

import './style.scss';
import { Button} from 'reactstrap';
const HomeIndex = (props) => {
  return(
    <div className="main__screen update_admin_profile__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title="Access Code"/>
        <div className="update_admin_profile__inner mb-auto">
          <div className="update_admin_profile__inner__header text-center">
            <h4>Verify your identity</h4>
            <p>A verification code sent via SMS text, email, or phone call is required in order to proceed.</p>
          </div>
          <div className="update_admin_profile__inner__body min-height-none">
            <div className="user__box">
              <div className="user__avtar">
                <img className="mx-auto my-auto" alt="" src={BillingProfileImg}/>
                <span>Owner</span>
              </div>
              <div className="user__name">CARLA SMITH</div>

            </div>
          </div>
          <div className="update_admin_profile__inner__footer">
            <p>Send the verification code via</p>
            <div className="row">
              <div className="col-6"><Button size="md" color="primary">Mobile SMS</Button></div>
              <div className="col-6"><Button size="md" color="primary">Email</Button></div>
              <div className="col-12 mt-4"><Button size="md" color="primary">Phone call</Button></div>
            </div>
          </div>
        </div>
    </div>
  ) 
}
const mapStateToProps = (state) => ({

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
