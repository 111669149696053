import React, { useState, useEffect} from 'react';
import SubHeader from '../../components/Header/subHeader';
import './styles.scss';
import upper from '../../img/upper.png';
import middle from '../../img/middle.png';
import lower from '../../img/lower.png';
import clsx from 'clsx';
import { Button, Input } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { postUpdateWaitingRoomChannel, updateWaitingRoomChannel } from '../../store/actions/taskAction';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { history } from '..';
import { useHistory } from 'react-router-dom';
const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#6087fd',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#6087fd',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
});
const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
});
function StyledCheckbox(props) {
    const classes = useStyles();
  
    return (
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        {...props}
      />
    );
  }
  
const ManageChannel = () => {
    const states = useSelector((state) => state.task);
    const history = useHistory();
    const [ manageChannelId, setManageChannelId ] = useState(history.location.state.id);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateWaitingRoomChannel(states.practiceUserLoginInfo, manageChannelId))
    }, [])
    const [state, setState] = useState({
        checkedA: true,
        checkedB: states.manageChannel.header_status == "1" ? true : false,
        checkedC: states.manageChannel.footer_status == "1" ? true : false,
    });
    const [ formData, setFormData ] = useState({      
        "channel":states.manageChannel.title,
        "title":states.manageChannel.title,
        "practice_name":states.manageChannel.practice_name,
        "header_status":states.manageChannel.header_status,
        "footer_status":states.manageChannel.footer_status,
        "display_custome_message_and_newsfeed":states.manageChannel.display_custome_message_and_newsfeed,
        "display_time_weather":states.manageChannel.display_time_weather,
        "enableButton":true
    })
    const [message, setMessage] = useState({
        message: "Save Changes",
        color: "#424242",
        bgColor: "#fff",
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const changeHandler = (event) => {
        setFormData({
            ...formData,
            channel:event.target.value,
            title:event.target.value,
            practice_name:event.target.value,
            enableButton:false
        })
    }
    const submitHandler = (event) => {
        event.preventDefault();
        
        dispatch(postUpdateWaitingRoomChannel(states.practiceUserLoginInfo, {
            "id":states.manageChannel.id,
            "channel":formData.channel,
            "title":formData.channel,
            "practice_name":formData.practice_name,
            "header_status":state.checkedB ? "1" : "0",
            "footer_status":state.checkedC ? "1" : "0",
            "display_custome_message_and_newsfeed":state.checkedC ? "1" : "0",
            "display_time_weather":state.checkedC ? "1" : "0",
            "enableButton":false
        }))
        setMessage({
            message: "Changes saved",
            bgColor: "green",
            color: "black",
        });
        setTimeout(()=>{
            history.push('/waiting-room-channels')
        }, 2000)
        

    }
    return (
        <div className="main__screen channels__page  d-flex flex-column h-100 align-items-center justify-content-center">
            <SubHeader className="mb-auto" title="New Waiting Room Channel"/>
            <div className="Page6__main ">

            <div className="Page6__main__left-slide left-slide">
            <div className=" Page6__main__left-slide__div1">
                
                <div className="Page6__main__left-slide__div1__header">
                    <h6>Name and customize your new channel</h6>
                
                    <br />
                    <label>Waiting room channel's name</label><br />
                    {/* <input type="text" value="April 2020"></input> */}
                    <Input type="text" name="title" value={ formData.title } onChange={changeHandler}/>
                </div>
                
                    
                <div className="Page6__main__left-slide__div1__body">
                
                    <div className="Page6__main__left-slide__div1__body__MainRow row">
                        <div className="Page6__main__left-slide__div1__body__MainRow__col1 col-md-6">
                            <img style={{ width:"100%" , height:"100%"}} src={state.checkedB ? upper : upper}></img>
                        </div>
                        <div className="Page6__main__left-slide__div1__body__MainRow__col2 col-md-6">
                            <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row row">
                                <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row__toggelCol col-md-12"><label>Show header </label>
                                <FormControlLabel
                                    control={<IOSSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />}
                                />
                            </div>
                            <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row__textCol col-md-12"><label>Practice name</label></div>
                            </div>
                    </div>
                    </div>
                    <div className="Page6__main__left-slide__div1__body__MainRow row">
                        <div className="Page6__main__left-slide__div1__body__MainRow__col1 col-md-6">
                            <img style={{ width:"100%" , height:"100%"}} src={middle}></img>
                        </div>
                        <div className="Page6__main__left-slide__div1__body__MainRow__col2 col-md-6">
                            <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row row">
                                
                            <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row__textCol col-md-12"><label>Education</label> <br/><label>Entertainment</label> <br/><label>My videos</label> <br/><label>My slides</label></div>
                            </div>
                    </div>
                    </div>
                    <div className="Page6__main__left-slide__div1__body__MainRow row">
                        <div className="Page6__main__left-slide__div1__body__MainRow__col1 col-md-6">
                            <img style={{ width:"100%" , height:"100%"}} src={states.manageChannel.footer_status == "1" ? state.checkedC  ? lower : lower:!state.checkedC}></img>
                        </div>
                        <div className="Page6__main__left-slide__div1__body__MainRow__col2 col-md-6">
                            <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row row">
                                <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row__toggelCol col-md-12"><label>Show Footer </label>
                                    <FormControlLabel
                                        control={<IOSSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />}
                                    />
                                    
                                    </div>
                            <div className="Page6__main__left-slide__div1__body__MainRow__col2__col2Row__textCol col-md-12"><label>Custom Messages</label>
                            <br/>
                            <label>Newsfeed</label><br/>
                            <label>Time</label><br/>
                            <label>Weather</label><br/>
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="Page6__main__left-slide__div1__body__MainRow row">
                        <div className="Page6__main__left-slide__div1__body__MainRow__checkboxText col-md-12"> <input type="checkbox" id="1" />&nbsp;<label>Show channel in this practice only</label></div>
                        <br/><br/>
                
                        <div className="Page6__main__left-slide__div1__body__MainRow__checkboxText col-md-12"> <input type="checkbox" id="1" />&nbsp;<label>Share channel with all my practices</label></div>
                    </div>
                    
                </div>
                
                
                <div className="Page6__main__left-slide__div1__footer">
                    <div className="Page6__main__left-slide__div1__footer__footerRow row">
                        <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol col-md-12">
                            <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol__row row">
                            <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol__row__upperColbtn col-md-4">
                                <Button type="button" name="" id="" className="btn btn-block btn-white">SCHEDULE</Button>
                            </div>
                            <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol__row__upperColbtn col-md-4">
                                <Button type="button" name="" id="" className="btn btn-block btn-white">IMAGES</Button>
                            </div>
                            <div className="Page6__main__left-slide__div1__footer__footerRow__upperCol__row__upperColbtn col-md-4">
                                <Button type="button" name="" id="" className="btn btn-block btn-white">PREVIEW</Button>
                            </div>
                            </div>
                    </div>
                        <div className="Page6__main__left-slide__div1__footer__footerRow__lowerCol col-md-12">
                        <Button className="btn btn-block btn-white" style={{backgroundColor: `${message.bgColor}`, color: `${message.color}` }} onClick={ submitHandler }>{ message.message }</Button>
                
                        </div>
                    </div>
                </div>
                
                
                </div>
            </div>
            <div className="Page6__main__screen-slide">
               
            <div className="ScreenC__main row " style={{width: "100%",
            height: "100%"
            }}>
            
            <label className="col-md-12 ScreenC__main__labelUpper" >SMITH DENTAL</label>

            

            <div className=" col-md-12 flexFont" id="f1">
                <label>NEWS</label>
                <div className='smaller'> Welcome to Smith Dental!</div>
                <div className='footer'  id="f1"> <p style={{ color:"white" }}>With supporting text below as a natural lead-in to additional content With supporting text below as a natural lead-in to additional.</p></div>
            
            </div>



            

        </div>
            </div>
            <div className="Page6__main__label-text">
                <label>Select a theme</label>
            </div>
            <div className="Page6__main__small-screen">
               
                <div className="Page6__main__small-screen__imgDiv">
                    <input type="checkbox" id="1" />
                </div>

                <div className="Page6__main__small-screen__imgDiv">
                    <input type="checkbox" id="1" />
                </div>

                <div className="Page6__main__small-screen__imgDiv">
                    <input type="checkbox" id="1" />
                </div>

                <div className="Page6__main__small-screen__imgDiv">
                  <input type="checkbox" id="1" />
                </div>
                
                <div className="Page6__main__small-screen__imgDiv">
                    <input type="checkbox" id="1" />
                </div>
            </div>
        </div>
            
        </div>
    )
}

export default ManageChannel;