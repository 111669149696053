import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/update_1.png'

const Updates =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <>
   
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Updates</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc" style={{}}>
                                        <h5>Updating Thalamus</h5>
                                        <div className="help_detail">
                                              <p>
                                              If there is an update to the Thalamus software available, you will be prompted to update as soon as you log in to Thalamus. Once prompted, click “update now” to initiate the update. You will not be able to use Thalamus until the latest version is installed.
                                              </p>
                                              <p>Once the software of Thalamus is updated, you may have to re-input your access code.</p>
                                        </div>
                                  </div>
                                  <div className="help-img">
                                      <img src ={Image1} className="w-100" alt="" style={{}}/>
                                  </div>
                            </div>
                      </div>
                  </>
            
    ) 

}

export default Updates;
