import React, {useState} from 'react';
import Back from './../../../img/help/Chevron.svg'
import './../style.scss';
import { Button } from 'reactstrap';
import Image1 from './../../../img/help/Corporate_1.png'
import Image2 from './../../../img/help/Corporate_2.png'
import Image3 from './../../../img/help/Corporate_3.png'
import Image4 from './../../../img/help/Corporate_4.png'

const Corporate =()=> {

    const [tabIndex, setTabIndex] = useState(0);
    return(
                <> 
                      <div className="help_page_heading"><img src={Back} className="back-wrrow"/><h4>Corporate</h4></div>

                      <div className="help_page_guide">
                            <div className="help_description">
                                  <div className="help_desc" style={{arginTop:"3px"}}>
                                        <h5>Managing/Adding Locations</h5>
                                        <div className="help_detail">
                                              <p>
                                              Once logged in to your corporate account, click ‘Add & Manage Locations’ on the home screen. Here, you will not only be able to add and delete locations, but you will also be able to assign and edit the managers at all of your locations.
                                              </p>
                                        </div>
                                  </div>
                                  <div className="help-img" >
                                      <img src ={Image1}  className="w-100" alt="" style={{marginTop:"4px"}}/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{ marginTop:"-10px"}}>
                                        <h5>Sharing Custom channels between practices</h5>
                                        <div className="help_detail">
                                              <p>From the ‘View and Share Channels’ page on the home screen, you will be able to create and edit your channels, as well as send custom channels to other practices.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{ marginTop:"-10px"}}>
                                      <img src ={Image2} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc">
                                        <h5>Can I use one one more credit card to pay for multiple practices?</h5>
                                        <div className="help_detail">
                                              <p>Yes, as an owner of the account, you will be able to add a primary card and a secondary card. You can assign one, multiple, or all locations to one credit card, or delegate locations between two cards.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{marginTop:"-10px"}}>
                                      <img src ={Image3} className="w-100" alt=""/>
                                  </div>
                            </div>
                            <div className="help_description">
                                  <div className="help_desc" style={{ marginTop:"-9px"}}>
                                        <h5>Can I purchase Google TV devices for multiple locations, and have them sent to each location?</h5>
                                        <div className="help_detail">
                                              <p>Yes, from the home screen, pressing the ‘Purchase Hardware’ button will allow you to purchase multiple Google TVs, and send them to the location desired.</p>
                                        </div>
                                  </div>
                                  <div className="help-img" style={{ marginTop:"-28px"}}>
                                      <img src ={Image4} className="w-100" alt=""/>
                                  </div>
                            </div>
                      </div>
                    
                  </>
            
    ) 

}

export default Corporate;
