import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubHeader from '../../components/Header/subHeader';
import './styles.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getWebsiteMediaHtmlCode } from '../../store/actions/taskAction';
import { history } from '..';
function GetHTMLCode() {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	const [html, setHtml] = useState(state.copyHTML);
	useEffect(() => {
		dispatch(getWebsiteMediaHtmlCode(state.practiceUserLoginInfo));
	}, []);

	useEffect(() => {
		console.log(state.copyHTML ,'copyHTML')
		setHtml(state.copyHTML)
	}, [state.copyHTML]);
	
	const [savePlaylistMessage, setSavePlayListMessage] = useState({
		message: 'COPY CODE',
		color: '#424242',
		bgColor: '#FF9F00',
	});

	const changeColor = () => {
		setHtml(html);

		setSavePlayListMessage({
			message: 'COPIED TO CLIPBOARD',
			bgColor: 'green',
			color: 'black',
		});
	};

	const codeCopied = () => {
		console.log("Copied to clipboard")
	}

	return (
		<>
			<div className='main__screen channels__page  d-flex flex-column h-100 align-items-center justify-content-center'>
				<SubHeader className='' title='Website Player Customization' />
				<div className="html-sec">
					<div className='htmlcode__main'>
						<h4 className='htmlcode__main__heading'>HTML Code</h4>
						<p className='htmlcode__main__line'>
							To have the visual changes you've just made implemented to your
							video player, please replace the following HTML code with the one
							currently in use on your website. Don't forget to clear your
							internet browser cache once it is all done.
						</p>
						<p className='htmlcode__main__message'>Message</p>
						{console.log(html,'html')}
						{/* <iframe
							//srcDoc={html}
							src={state.copyHTML ? state.copyHTML : null}
							className='htmlcode__main__frame'
						/> */}
						<textarea className='htmlcode__main__frame' value={html}>
						</textarea>
						<CopyToClipboard
							text={html ? html : null}
							onCopy={codeCopied}>
							<button
								className='htmlcode__main__button'
								style={{
									backgroundColor: `${savePlaylistMessage.bgColor}`,
									color: `${savePlaylistMessage.color}`,
								}}
								onClick={() => changeColor()}>
								{savePlaylistMessage.message}
							</button>
						</CopyToClipboard>
						<button
							className='htmlcode__main__back'
							onClick={() => history.push('/website-player-customization')}>
							BACK
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default GetHTMLCode;
