import React from 'react';

import './style.scss';
import ic_report_problem from '../../img/ic_report_problem.svg'
import { Button } from 'reactstrap';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { history } from '../../pages/index';
import { connect } from 'react-redux';
import { compose } from 'redux';

class AccountInactiveIndex extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        is_screll: false,
        DeviceCode:null,
        tosinfo:{}
    };
    this.handleClick = this.handleClick.bind(this);
  }
  
  handleClick(){
    if(this.props.practiceUserLoginInfo && this.props.practiceUserLoginInfo.token_info){
      history.push('/paymentInfo');
    }else{
      history.push({
        pathname: '/profileselection',
        frompaymentinfo:true
      });
    }
  } 

  async componentDidMount(){
   
  }

  render (){
    const {t} = this.props
    return(
      <div className="inactive__main__screen error__default__screen d-flex flex-column h-100 align-items-center justify-content-center">
         <SubHeader className="mb-auto" title="Account Inactive"/>
        <div className="inactive__inner__screen mb-auto">
            <div className="d-flex flex-column text-center inactive__inner__text text-center">
                <img className="mx-auto mb-5" alt="" src={ic_report_problem}/>
                <h1>{t('inactive.title')}</h1>
                <p>{t('inactive.info')}</p>
                <Button size="xl" onClick={() => this.handleClick()} className="mx-auto" color="default">{t('btn.paynowUnlock')}</Button>
            </div>
        </div>
        <div className="custom__error d-flex align-items-center justify-content-center"><img className="custom-error-icon" alt="" src={ic_report_problem}/><p className="m-0">{t('errors.accountInactive')}</p> <Button className="ml-5" onClick={() => this.handleClick()} outline size="md" color="default white">{t('btn.payNow')}</Button></div>
      </div>
    ) 
  }
}

const mapStateToProps = (state) => {
  return {
    practiceInfo: state.task.practiceInfo,
    practiceProfileInfo:state.task.practiceProfileInfo,
    practiceUserLoginInfo: state.task.practiceUserLoginInfo,
    practiceUserLogoutInfo:state.task.practiceUserLogoutInfo,
    getOnlyNewnotificationlist:state.task.getOnlyNewnotificationlist,
    UpdateUserProfileImage:state.task.UpdateUserProfileImage,
    resetAccessCode:state.task.resetAccessCode,
    practiceUserAccountInfo:state.task.practiceUserAccountInfo,
    buttonLoading: state.task.buttonLoading,
    isCorporate:state.task.isCorporate
  }
};


export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(AccountInactiveIndex);
// export default withTranslation('common')(AccountInactiveIndex);
