/** @format */

import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Spinner, FormGroup, Label } from 'reactstrap';
import SubHeader from '../../components/Header/subHeader';
import { InputField } from '../../components/InputField';
import LoadingButton from '../../components/LoadingButton';
import IconSharingGrey from '../../img/IconSharingGrey.svg';
import MoveItemIcon from '../../img/MoveItemIcon.svg';
import { deleteOperatoryChannel, duplicateOperatoryChannel, getOperatoryChannel, getOperatoryMediaChannelList, restoreOperatoryChannel, updateOperatoryChannelName,getOperatoryChannelRequest, createNewOperatoryChannel, getOperatoryThemeList } from '../../store/actions/taskAction';
import './styles.scss';
import TemporaryDrawer from './TemporaryDrawer';
import { history } from '..';
import Loading from '../../components/Loading';
import { formatSecond, THALAMUSCHANNEL, PRACTICECHANNEL } from '../../data/comman';
import ImageSkeleton from "../../components/Skeletons/imageLoad"
import SkeletonChannelList from "../../components/Skeletons/channelList"
import {ReactComponent as IconPlas} from '../../img/icon_plus_.svg';
import uuid from 'react-uuid';

const OperatoryChannels = ({ sortedBy, sortedType }) => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.task);
	const [channelId, setChannelId] = useState(null);
	const [channelOptions, setChannelOptions] = useState(false);
	const [renameOption, setRenameOption] = useState(false);
	const [restoreDefault, setRestoreDefault] = useState(false);
	const [removeChannel, setRemoveChannel] = useState(false);
	const [duplicate, setDuplicateChannel] = useState(false);
	const [sharechannel, setShareChannel] = useState(false);
	const [count, setCount] = useState(0);
	const [renameChannelName, setRenameChannelName] = useState('');
	const [channelRemoveId, setChannelRemoveId] = useState(null);
	const [selectedSortedType, setSelectedSortedType] = useState("all")
	const wrapperRefBox = useRef(null);

	useOutsideAlerter(wrapperRefBox);


	const [message, setMessage] = useState({
		message: 'Rename',
		color: 'black',
		bgColor: '#eccc63',
	});
	useEffect(() => {

		dispatch(
			getOperatoryMediaChannelList(
				state.practiceUserLoginInfo,
				sortedBy,
				sortedType,
				state.practiceInfo.is_corporate == 1
			)
		);


	}, [sortedBy, sortedType]);

	useEffect(() => {
		setRenameChannelName('');
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false)
		setRemoveChannel(false)
		setDuplicateChannel(false)
		setShareChannel(false)
	}, [state.operatoryMediaChannels]);

	var hoursCount = 0;


	const handleSortedType = (type) => {
		setSelectedSortedType(type);
		dispatch(
			getOperatoryMediaChannelList(
				state.practiceUserLoginInfo,
				sortedBy,
				type,
			)
		);
	}

	const openMenu = (id) => {
		if (channelId === id) {
			setChannelId(null);
			setCount(0);
			setRenameOption(false);
			setChannelOptions(false);
			console.log(channelId, 'channelId', id, 'id')
		} else {
			setChannelId(id);
			setCount(count + 1);
			setRenameOption(false);
			setRestoreDefault(false);
			setRemoveChannel(false);
			setDuplicateChannel(false);
			setShareChannel(false);
			setChannelOptions(true);
			console.log(channelId, 'channelId', id, 'id')
		}

	};
	const renameChannel = (event) => {
		setMessage({
			message: 'Rename',
		});
		setChannelOptions(false);
		setRenameOption(true);
	};

	const restoreDefaultChannel = (event) => {
		setMessage({
			message: 'Restore',
		});
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(true)
	};

	const duplicateChannel = (event) => {
		setMessage({
			message: 'Duplicate',
		});
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false)
		setDuplicateChannel(true)
	};

	const shareChannel = (event) => {
		setMessage({
			message: 'Share',
		});
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false)
		setDuplicateChannel(false)
		setShareChannel(true)

	};

	const renameHandleChange = (event) => {
		setRenameChannelName(event.target.value);
	};

	const onCancel = () => {
		setChannelOptions(true);
		setRenameOption(false);
		setRestoreDefault(false)
		setRemoveChannel(false)
		setDuplicateChannel(false)
		setShareChannel(false)
	}

	const renameSubmitHandler = (id) => {
		let params = {
			title: renameChannelName
		}
		dispatch(updateOperatoryChannelName(id, params));
		// setRenameChannelName('');
	};

	const shareSubmitHandler = (id) => {
		let params = {
			is_shared: "1"
		}
		dispatch(updateOperatoryChannelName(id, params));
	};

	const restoreSubmitHandler = (id) => {
		dispatch(
			restoreOperatoryChannel(id)
		);
	};

	const duplicateSubmitHandler = (id) => {
		dispatch(
			duplicateOperatoryChannel(id)
		);
	};

	const removeSelectedChannel = (id) => {
		setMessage({
			message: 'Remove',
		});
		setChannelRemoveId(id);
		setRemoveChannel(true)
	};

	const deleteChannelHandler = (id) => {
		dispatch(
			deleteOperatoryChannel(id)
		);
	};
	const manageChannel = (id) => {
		setChannelId(id);
		console.log(id, 'id')
		dispatch(getOperatoryChannelRequest());
		dispatch(
			getOperatoryChannel(id)
		);

	};

	const closeAll = () =>{
		setChannelId(null);
		setCount(0);
		setChannelOptions(false);
		setChannelOptions(false);
		setRenameOption(false);
		setRestoreDefault(false);
		setRemoveChannel(false);
		setDuplicateChannel(false);
		setShareChannel(false);
	}

	function useOutsideAlerter(ref) {
		useEffect(() => {
		  /**
		   * Alert if clicked on outside of element
		   */
		  function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				closeAll()
			}
		  }
		  // Bind the event listener
		  document.addEventListener("mousedown", handleClickOutside);
		  return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		  };
		}, [ref]);
	  }
	
	  
  const newChannel = () => {
    let params = {
      "channel": uuid(),
      "title": uuid(),
      // "practice_name": 'Smith Dental',
      "header_status": "1",
      "footer_status":"1",
      "theme_id": 1,
      "enableButton": false,
      "is_shared":0
    }
    dispatch(createNewOperatoryChannel(params))
    dispatch(getOperatoryThemeList())
  };
return (
	<div className='operator_channel__main__screen d-flex flex-column h-100 align-items-center '>
		{state.loadingNewChannelCreate && <Loading />}
		<>
			{state.practiceInfo.is_corporate == 0 && <SubHeader className='mb-5' title='Operatory Channels' />}
			<TemporaryDrawer label='MENU' />
			<div className="mb-5 pb-5">
			{state.practiceInfo.is_internal_user === 1 ?
				<Button className="mx-3 btn-235 w-100" outline color="default white"  onClick={() => history.push({ pathname: '/create-thalamus-channel', param: {isOperatoryRoom: true } }) }><span className='mr-4'><IconPlas/></span> CREATE NEW CHANNEL</Button>
				:
				<Button className="mx-3 btn-235 w-100" outline color="default white"  onClick={() => newChannel()}><span className='mr-4'><IconPlas/></span> CREATE NEW CHANNEL</Button>
			}
			</div>
			<div className="d-flex flex-column operator_channel__main__lists custom-scroll mb-auto w-100">
				{state.practiceInfo.is_internal_user === 1 &&
					<div className="d-flex mx-auto internal_filter_btn mb-5">
						<Button className={`mx-3 ${(selectedSortedType == "published" || selectedSortedType == "all") && 'active'}`} size="md" outline color="default white" onClick={() => handleSortedType("published")}>Published</Button>
						<Button className={`mx-3 ${selectedSortedType == "scheduled" && 'active'}`} size="md" outline color="default white" onClick={() => handleSortedType("scheduled")}>Scheduled</Button>
						<Button className={`mx-3 ${selectedSortedType == "unpublished" && 'active'}`} size="md" outline color="default white" onClick={() => handleSortedType("unpublished")}>Unpublished</Button>
					</div>
				}
				<Col lg='12'>
					<div className='d-flex flex-wrap row-cols-2  mx-auto' ref={wrapperRefBox}>
						{state.operatoryMediaChannels && !state.isloading ? state.operatoryMediaChannels.map((datum, index) => {
							var thumb = datum.thumb;
							const { created_type } = datum
							const isBlack = created_type === "2" ? "" : "practice"
							return (
								<div className='px-20'>
									<div className={`operator_channel__main__lists__item d-flex ${datum.created_type}`}>
										<div className='operator_channel__main__lists__item__image_box'>

											{/* <img src={thumb} alt='' onClick={() => history.push({ pathname: '/channel-detail', state: { channel: datum, isOperatoryRoom: true } })} /> */}
											<ImageSkeleton data={datum} src={thumb} isOperatoryRoom={true}/>
											{/* <div className={`operator_channel__main__lists__item__play_count d-flex align-items-center  ${datum.total_channel_play > 0 ? 'playing-type' : 'not-playing-type'}`}>
													<span></span>
													{datum.total_channel_play > 0 ?
													   	<> Playing on{' '} {datum.total_channel_play} device </>
														:
														'Not playing'
													   }
													</div> */}
											<div className='operator_channel__main__lists__item__video_count d-flex flex-column align-items-center justify-content-between py-5'>
												{datum.is_shared == 1 ?
													<div className=" btn-link p-0"><img src={IconSharingGrey} alt="" /></div>
													:
													<div></div>
												}
												<div className="text-center">
													<h2>{datum.total_timeline}</h2>
													<label id='overlayLabel' for='overlay'>
														ITEMS
															</label>
												</div>
												<div className=" btn-link p-0"><img src={MoveItemIcon} alt="" /></div>
											</div>
										</div>
										<div className='col operator_channel__main__lists__item__title_box p-0'>
											<ul className='d-flex flex-column h-100'>
												<div className="d-flex align-items-center justify-content-between align-items-center operator-header">
													<h5 className="m-0">
														{datum.title}
													</h5>
													<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => state.buttonLoading ? '' : openMenu(datum.id)} />
												</div>
												<div className="channel-play-list">
													{datum.timeline.slice(0, 3).map((datumTwo, index) => {
														return <div className="py-3 border--bottom sdf">
															<div className="d-flex justify-content-between">
																<div className="channel_title">{datumTwo?.media_data?.name}</div>
																<span className="duration">{formatSecond(datumTwo?.media_data?.duration)}</span>
															</div>
														</div>
													})}
												</div>

												{channelId === datum.id && renameOption && (<>
													<div className="chanal_options channel_detail_box d-flex  flex-column h-100">
														<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
															<h5 className="m-0"> RENAME </h5>
															<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id)} />
														</div>
														<div className="rename_form mt-4 h-100 d-flex flex-column">
															<FormGroup className={state.error && state.error.errors && state.error.errors['title'] ? 'inputerror' : ''}>
																<InputField type="text" isError={state.error && state.error.errors && state.error.errors['title'] && state.error.errors['title'][0]} name='channel' label={state.error && state.error.errors && state.error.errors['title'] ? "Enter new channel's name" : 'Please enter a new name for this channel'} value={renameChannelName} onChange={renameHandleChange} />
															</FormGroup>
															<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																<LoadingButton className="col mr-3" label={message.message} loading={state.buttonLoading} disabled={state.buttonLoading} color="primary" onClick={() => renameSubmitHandler(datum.id)}></LoadingButton>
																<Button className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
															</FormGroup>
														</div>
													</div>
												</>)}

												{channelId === datum.id && channelOptions && (<>
													<div className="chanal_options">
														<div className="d-flex px-3 py-3 justify-content-between align-items-center  border--bottom">
															<h5 className="m-0"> Channel Options </h5>
															<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id)} />
														</div>
														{(datum.created_type !== THALAMUSCHANNEL || state.practiceInfo.is_internal_user == 1) &&
															<div className="chanal_options--item px-3 py-3" onClick={channelId == datum.id ? renameChannel : ''}>
																Rename
																		</div>
														}
														{(datum.created_type !== THALAMUSCHANNEL || state.practiceInfo.is_internal_user == 1) &&
															<div className="chanal_options--item px-3 py-3" onClick={channelId == datum.id ? restoreDefaultChannel : ''}>
																Restore Default
																	</div>}
														{ !datum.parent_id  &&
															<div className="chanal_options--item px-3 py-3" onClick={channelId == datum.id ? duplicateChannel : ''}>
																Duplicate
															</div>
														}
														{datum.created_type !== THALAMUSCHANNEL && !datum.parent_id && <div className="chanal_options--item px-3 py-3" onClick={channelId == datum.id ? shareChannel : ''}>
															Share with linked practices
																	</div>}
														{((state.practiceUserLoginInfo.id == datum.user_id && datum.created_type !== THALAMUSCHANNEL) || (state.practiceUserLoginInfo?.type == 4 && datum.created_type !== THALAMUSCHANNEL) || state.practiceInfo.is_internal_user == 1) &&
															<div className="px-3 py-3 chanal_options--item" onClick={channelId === datum.id ? removeSelectedChannel : ''} >
																Remove {datum.parent_id ? 'duplicate' : ''}
																		</div>
														}
														{/* state.practiceUserLoginInfo.id == datum.user_id */}
													</div>
												</>)}


												{channelId === datum.id && restoreDefault && (<>
													<div className="chanal_options channel_detail_box d-flex  flex-column h-100">
														<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
															<h5 className="m-0">RESTORE DEFAULT </h5>
															<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id)} />
														</div>
														<div className="rename_form h-100 d-flex flex-column">
															<FormGroup className="">
																<Label>The default selection of videos and slides curated by THALAMUS will be restored to this channel’s timeline.</Label>
															</FormGroup>
															<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																<LoadingButton className="col mr-3" label={message.message} loading={state.buttonLoading} disabled={state.buttonLoading} color="primary" onClick={() => restoreSubmitHandler(datum.id)}></LoadingButton>
																<Button className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
															</FormGroup>
														</div>
													</div>
												</>)}

												{channelId === datum.id && duplicate && (<>
													<div className="chanal_options channel_detail_box d-flex  flex-column h-100">
														<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
															<h5 className="m-0">Duplicate</h5>
															<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id)} />
														</div>
														<div className="rename_form h-100 d-flex flex-column">
															<FormGroup className="">
																<Label>The default selection of videos and slides curated by THALAMUS will be restored to this channel’s timeline.</Label>
															</FormGroup>
															<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																<LoadingButton className="col mr-3" label={message.message} loading={state.buttonLoading} disabled={state.buttonLoading} color="primary" onClick={() => duplicateSubmitHandler(datum.id)}></LoadingButton>
																<Button className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
															</FormGroup>
														</div>
													</div>
												</>)}

												{channelId === datum.id && sharechannel && (<>
													<div className="chanal_options channel_detail_box d-flex  flex-column h-100">
														<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
															<h5 className="m-0">SHARE WITH LINKED PRACTICES</h5>
															<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id)} />
														</div>
														<div className="rename_form mt-4 h-100 d-flex flex-column">
															<FormGroup className="">
																<Label className="mw-80">By selecting this option, you’ll be making this  channel available to all associated practices, allowing any user to view the video and slide selection.</Label>
															</FormGroup>
															<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																<LoadingButton className="col mr-3" label={message.message} loading={state.buttonLoading} disabled={state.buttonLoading} color="primary" onClick={() => shareSubmitHandler(datum.id)}></LoadingButton>
																<Button className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
															</FormGroup>
														</div>
													</div>
												</>)}



												{channelId === datum.id && removeChannel && (<>
													<div className="chanal_options channel_detail_box remove_box d-flex  flex-column h-100 bg_red">
														<div className="d-flex chanal_options_header justify-content-between align-items-center border--bottom">
															<h5 className="m-0">REMOVE</h5>
															<MenuIcon style={{ width: '2em', height: '2em', }} onClick={() => openMenu(datum.id)} />
														</div>
														<div className="rename_form mt-4 h-100 d-flex flex-column">
															<FormGroup className="">
																<Label>You’re about to remove this channel, and you won’t have access to this selection any longer. To recriate this channel’s timeline, another channel will need to be created. Would you like to proceed?</Label>
															</FormGroup>
															<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
																<LoadingButton className="col mr-3" label={message.message} loading={state.buttonLoading} disabled={state.buttonLoading} color="default" onClick={() => deleteChannelHandler(datum.id)}></LoadingButton>
																<Button className="col ml-3" color="default white" outline onClick={onCancel}>CANCEL</Button>
															</FormGroup>
														</div>
													</div>
												</>)}
												{/* <h1>{}</h1> */}
												{(datum.created_type !== "thalamus" || state.practiceInfo.is_internal_user == 1) &&
													<div className="mt-auto mb-4">
														{/* <Button color="primary" className="col mt-auto" onClick={() => manageChannel(datum.id) }>
																	Manage Channel
																</Button> */}
															
														<FormGroup className="d-flex justify-content-between  mt-auto channel-option-btn">
															<LoadingButton color="primary" className="col mt-auto" label={"Manage Channel"} loading={channelId === datum.id?state.buttonLoading:null} disabled={channelId === datum.id?state.buttonLoading:null} onClick={() => manageChannel(datum.id)} ></LoadingButton>
														</FormGroup>
													</div>
												}
											</ul>

										</div>
									</div>
								</div>
							);
						}
						)
						: 
						<>
							<SkeletonChannelList />
							<SkeletonChannelList />
							<SkeletonChannelList />
							<SkeletonChannelList />
							<SkeletonChannelList />
							<SkeletonChannelList />
						</>
						}
						{state.operatoryMediaChannels.length == 0 &&
							<div>
								<h2 className='mt-5'>No Channels found</h2>
							</div>
						}
					</div>
				</Col>
			</div>
		</>
	</div>
);
};

export default OperatoryChannels;
