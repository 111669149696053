import React from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import App from './App';
import reportWebVitals from './reportWebVitals';
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import configureStore from "./store/store";
import Index from './pages/practiseComponent/Index';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

let { store, persistor } = configureStore();


Sentry.init({
  dsn: "https://6cea98dfa1d04926a3845e06f7ecaf0e@o988779.ingest.sentry.io/5945720",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  environment:  process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
});
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      fr: {
          common: common_fr
      },
  },
});
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}> 
        <App /> 
      </PersistGate>
    </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
