import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import ProfileImgBox from './ProfileImgBox'
import { history } from '../..';
import {ReactComponent as ArrowLeft} from '../../../img/arrow_left.svg';
import { Button } from 'reactstrap';
import { setGoogleTvTestDriveMenuItem } from '../../../store/actions/taskAction';


const getUserType = (id) =>{
  switch(id){
      case 0: 
          return  ["guest", "Guest"];
      case 1:
          return  ["dentist", "Normal User"];
      case 2:
          return  ['authorized_user', "Authorized User"]; 
      case 3:
          return    ['manager', "Manager"]; 
      case 4:
          return    ['owner', "Owner"]; 
      default:
        return    ['guest', "Guest"];
  }
}


const Profile = () => {
  const state = useSelector(state => state.task);
  const userType = getUserType(state.practiceUserLoginInfo.type )
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGoogleTvTestDriveMenuItem(1));

  }, []); 

  return (
    <div className={`${styles.goolge_tv_profile__main__screen} d-flex flex-column  `}>
        <Button  type="button"  onClick={() => history.goBack()} style={{fontSize:'2rem'}} color="link" className="py-1 m-5 pl-0 d-flex align-items-center mb-5"><ArrowLeft className="mr-4"/><span>Back</span></Button>
        <div className={`${styles.goolge_tv_profile__inner__screen}  py-5`}>
            <div className='px-5'>
              <h2 className='mb-5'>My profile</h2>
            </div>
            <div className='d-flex justify-content-between'>
              <div className={`${styles.goolge_tv_profile__img__section} px-5`}>
                  <ProfileImgBox />
              </div>
              <div className={`${styles.goolge_tv_profile__details} px-5`}>
                <p className='mb-5'>The profiles Owner, Manager, and Authorized User can have their name edited only on Thalamus for Windows, and their profile type is restricted to “Secure”.</p>
              
                <div className={`${styles.goolge_tv_profile__details__list_item} d-flex align-items-center justify-content-between`}>
                    <label className=''>Name</label><div className=''>{`${state.practiceUserLoginInfo.first_name || ''} ${state.practiceUserLoginInfo.last_name || ''}`}</div>
                </div>
                <div className={`${styles.goolge_tv_profile__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Role</label><div className=''>{userType[1]}</div>
                </div>
                <div className={`${styles.goolge_tv_profile__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Profile type</label><div className=''>{state.practiceUserLoginInfo.is_secure == 1 ? 'Secure' : 'Unsecure'}</div>
                </div>
                <div className={`${styles.goolge_tv_profile__details__list_item} d-flex justify-content-between`}>
                    <label className=''>Mobile no.</label><div className=''>Confirmed</div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Profile
