import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getScreenshotList } from '../../../store/actions/taskAction';
import moment from 'moment';
import { DateDDMMMYYY } from '../../../utilities/HttpUtility';
import { Modal , Button} from 'reactstrap';
import {   Close, } from "@material-ui/icons";
import {ReactComponent as PrvIcon} from '../../../img/PrvIconsvg.svg';
import {ReactComponent as NextIcon} from '../../../img/ChevronIcon.svg';

import '../style.scss';

const ShowVideo = () => {
    const [searchResult, setSearchResult] = useState([]);
    const [allScreenshort, setAllScreenshort] = useState([]);
    const [searchString, setSearchString] = useState("");
    const dispatch = useDispatch();
    const state = useSelector((state) => state.task);
    const [imageIndex, setImageIndex] = useState(-1);
    const [showImage, setShowImage] = useState(false);
    const [searchStatus, setSearchStatus] = useState(false);
    // set search result
    useEffect(()=>{
        if(searchString != ""){
            setSearchResult(state.searchSchreenshotList);
        }else{
            setSearchResult(state.searchSchreenshotList.slice(0, 4));
        }
        
    }, [state.searchSchreenshotList]);

    
    useEffect(()=>{
        dispatch(getScreenshotList());
        setSearchStatus(false)
    }, []);

    // fetch screenshot
    useEffect(() => {
        var search = searchString.trim();
        setTimeout(() => {
            if(search != "" && search.length > 2) {
                dispatch(getScreenshotList({ search }));
                setSearchResult(state.searchSchreenshotList);

                if(state.searchSchreenshotList.length === 0 ){
                    setSearchStatus(true)
                }
            } else if(search == ""){
                setSearchResult(state.searchSchreenshotList);
                setSearchStatus(false)
            }
        }, 1000)
    }, [searchString]);

   const showImageModal = (index) => {
        setImageIndex(index);
        setShowImage(true);
   }

   const toggleModal = () => {
        setImageIndex(-1);
        setShowImage(false); 
   }

   const handleClickClose = () => {
        setImageIndex(-1);
        setShowImage(false); 
    };

    const showNextImg = () =>{
      var count =  imageIndex + 1
        setImageIndex(count);
    }

    const showPrvImg = () =>{
        var count =  imageIndex - 1
          setImageIndex(count);
      }
  

    return (
        <div className="screenshot-page">
            <input type="text" className="search_main"  placeholder="Search screenshot by patient's name or email" onChange={(e) => setSearchString(e.target.value)} />
            {searchResult.length > 0 ?
            <>
                <br /><h3 style={{ color: "white", marginTop: "30px", marginLeft: "0px", marginBottom: "11px",fontSize:"27px" }}>Screenshot results</h3>
            </>
           : null
           }
                <div className="screenshot-section">
                    { searchResult.map((screenshot, index) => 
                        <div key={index} className="screens-img" onClick={()=> showImageModal(index)}>
                            <div className="card">
                                <img src={screenshot.attachment} alt={screenshot.email}/>
                                <p className="title">{screenshot.email}</p>
                                <div className="overlay-text">
                                    {moment(screenshot.created_at).format(DateDDMMMYYY)}
                                </div>
                            </div>
                        </div> 
                    )}
                    {
                        searchStatus && 
                        <h2 className='ml-5'>No result found for the {searchString}</h2>
                    }
                </div>
                {imageIndex > -1 &&
                    <Modal size="xxl" className={`h-100 d-flex flex-column align-items-strach justify-content-center mt-0 mb-0 screenshot-image-modal`} isOpen={showImage} toggle={toggleModal}>
                        <Button type="button" disabled={imageIndex == 0 ? true : false} onClick={()=>showPrvImg(searchResult)} color="link" className="screenshot-section__prvbtn navBtn p-0"><PrvIcon/></Button>
                        <Button type="button" disabled={imageIndex == searchResult.length - 1 ? true : false} onClick={()=>showNextImg(searchResult)} color="link" className="screenshot-section__nextbtn navBtn p-0"><NextIcon/></Button>
                        <div className="screenshot-section__header d-flex justify-content-between">
                                <div>
                                    <Button color="link" className="text-uppercase pl-0">{searchResult[imageIndex].email}</Button>|
                                    <Button className="text-uppercase" color="link">{moment(searchResult[imageIndex].created_at).format(DateDDMMMYYY)}</Button>
                                </div>
                                <div className="d-flex align-items-center" onClick={handleClickClose} style={{ color: "white" , marginRight:"-5px" , cursor:"pointer"}}>
                                    <p className="m-0" style={{fontSize:"16px"}}>CLOSE</p>
                                    <Close fontSize="inherit" style={{ fontSize: "20px",  }}/>
                                </div>
                        </div>
                        <div class="screenshot-image-preview">
                                <img src={searchResult[imageIndex].attachment} alt=""/>
                                {/* <div className="screenshot-img-disclaimer">
                                    This image captured from a copyrighted video is provided for informational purposes only. 
                                    <br/>
                                        It cannot be presented or distributed to any other outside sources, being that it is a shared confidential image between patient and doctor
                                </div> */}
                        </div>
                        
                    </Modal>
                }
        </div>
    )
}

export default ShowVideo
