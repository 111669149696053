import React, { useState, useEffect } from 'react';
import SubHeader from  '../../components/Header/subHeader'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux';
import Card from '../../components/CreditCards'
import CardNotPaid from './CardNotPaid'
import { useSelector } from 'react-redux';
import './style.scss';

const HomeIndex = (props) => {
  var [ practiceName, setPracticeName ] = useState("")
  var [ practiceId, setPracticeId ] = useState("")
  const state = useSelector((state) => state.task);
  useEffect(() => {
    if(props && props.match && props.match.params && props.match.params.practiceId){
      var practiceId = props.match.params.practiceId;
      var selectedPractice = state.designateCard.data.find(x => x.id == practiceId) || {};
      setPracticeName(selectedPractice.name);
      setPracticeId(practiceId);
    }
  }, []);

  return(
    <div className="corporate-billing__main__screen corporate-billing__page  d-flex flex-column h-100 align-items-center justify-content-center">
        <SubHeader className="mb-auto" title={practiceName || props.practiceInfo.name} practiceId={practiceId}/>
        {props.practiceInfo.is_paid_account === 1 ?
          <Card props={props}/>
        :
          <CardNotPaid />
        }

        { props.error &&
              Object.keys(props.error.errors).map((sub)=>{
                console.log(sub)
                return <div className="custom__error d-flex align-items-center justify-content-center"><p className="m-0">{props.error.errors[sub][0]}</p> </div>
              })
              
          }
    </div>
  ) 
}
const mapStateToProps = (state) => ({
  loading: state.task.loading,
  error: state.task.error,
  getBillingCardInfo:state.task.getBillingCardInfo,
  practiceInfo:state.task.practiceInfo,
  designateCard: state.taskdesignateCard
});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(HomeIndex);
