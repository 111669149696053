import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { chairSideEducationVideos } from "../staticFile";
import VideoSlider from "../../../components/videoSlider/VideoSlider";
import { fetchChairsideMedia, fetchChairsideMediaCategoryList , getRecentPlayVideos } from "../../../store/actions/taskAction";
import { RECENTPLAY, CHAIRSIDENEWRELEASE, CHAIRSIDERECENTPLAY, CHAIRSIDEMYLIST } from "../../../data/comman";
import { Spinner } from "reactstrap";
import Loading from "../../../components/Loading";
import PlayIcon from '../../../img/play_icon_larg.svg'


const Index = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.task);
    const [videosList, setvideosList] = useState(state.chairSideMediaState);
    const [allVideosList, setallVideosList] = useState(state.chairSideMediaState);
    const [categoriesList, setCategoriesList] = useState(state.chairSideCategoryList);

    const [recentPlay, setRecentPlay] = useState([]);
    const [myList, setMyList] = useState([]);
    // const [loading, setLoading] = useState(true);

    useEffect(()=>{
        // dispatch(fetchChairsideMediaCategoryList(state.practiceUserLoginInfo));
        dispatch(fetchChairsideMedia(state.practiceUserLoginInfo))

        var recentPlay = JSON.parse(localStorage.getItem(RECENTPLAY));
        if(recentPlay != null){
            var recentPlayVideos = []
            recentPlay.map((video)=>{
                var videolist = state.chairSideMediaState.find(x => x.chairside_media_id == video);
                recentPlayVideos.push(videolist)
            })
            setRecentPlay(recentPlayVideos)
        }
    }, [])

    useEffect(()=>{
        setCategoriesList(state.chairSideCategoryList)
    }, [state.chairSideCategoryList])
    
    var recentPlayedDb = localStorage.getItem(RECENTPLAY);
    
    useEffect(()=>{
        if(recentPlayedDb != null){
            var recentPlay = JSON.parse(localStorage.getItem(RECENTPLAY));
            var recentPlayVideos = []
            recentPlay.map((video)=>{
                var videolist = state.chairSideMediaState.find(x => x.chairside_media_id == video);
                recentPlayVideos.push(videolist)
                console.log(video,'video')
            })
            setRecentPlay(recentPlayVideos)
        }
		// if(recentPlayedDb != null){
        //     let params = {
        //         'recent_play_media_id[]': recentPlayedDb
        //     }
        //     dispatch(getRecentPlayVideos(params))
        //     setRecentPlay(state.chairSideMediarecentPlay);
		// }
    }, [recentPlayedDb])

    useEffect(()=>{
        setRecentPlay(state.chairSideMediarecentPlay);
    }, [state.chairSideMediarecentPlay])

    useEffect(()=>{
        var myList = [];
        state.chairSideMediaState.map((video)=>{
            if(video.chairside_media && video.chairside_media.in_mylist == 1){
                myList.push(video)
            }
        })
        setMyList(myList);
        // setLoading(state.chairSideMediaState.length == 0 && state.loading == false)
        setvideosList(state.chairSideMediaState.slice(0, 10) )
        setallVideosList(state.chairSideMediaState)
        if(recentPlayedDb != null){
            var recentPlay = JSON.parse(localStorage.getItem(RECENTPLAY));
            var recentPlayVideos = []
            recentPlay.map((video)=>{
                var videolist = state.chairSideMediaState.find(x => x.chairside_media_id == video);
                if(videolist != null){
                    recentPlayVideos.push(videolist)
                }
            })
            setRecentPlay(recentPlayVideos)
        }

    }, [state.chairSideMediaState])

    const submitColors =(color) =>{
        //console.log(color);
    } 

    return(
        <>
            {/* {loading  && <Loading />} */}
            <div className="home_swipers">
                <VideoSlider title='New Releases'  videos={videosList} submitColors={submitColors} setRecentPlay={setRecentPlay} param={CHAIRSIDENEWRELEASE}/>
                
                {myList.length > 0 ?
                    <VideoSlider title='My List'  videos={myList} submitColors={submitColors} setRecentPlay={setRecentPlay} isMyList={true} param={CHAIRSIDEMYLIST}/>
                :
                <>
                    <p style={{ fontSize: '27px' ,  marginBottom:'0px' , paddingLeft:'14px'}}>My List</p>
                    <div className="placeholder__box d-flex align-items-center justify-content-center flex-column">
                        <div className="text-center">
                            <img className="mb-4" src={PlayIcon} alt=""/>
                            
                            <p style={{fontSize:'14px' , color:'#C9C9C9'}} className="mb-0">Press the + icon when a video is selected<br/> to add here for quick access.</p>
                        </div>
                    </div>
                </>
                }
                
                {recentPlay.length > 0 ?
                    <VideoSlider title='Recently Played'  videos={recentPlay} submitColors={submitColors} setRecentPlay={setRecentPlay} param={CHAIRSIDERECENTPLAY}/>
                :
                <>
                    <p style={{ fontSize: '27px' ,  marginBottom:'0px' , paddingLeft:'14px'}}>Recently Played</p>
                    <div className="placeholder__box d-flex align-items-center justify-content-center flex-column">
                        <div className="text-center">
                            <img className="mb-4" src={PlayIcon} alt=""/>
                            <p style={{fontSize:'14px' , color:'#C9C9C9'}} className="mb-0">Recently played videos will appear here.</p>
                        </div>
                    </div>
                </>
                }
               
                {categoriesList.map((item,index) => { 
                    var videosCat = []
                    allVideosList.map((video,i)=>{
                        if (item.id == video.media_category_id)
                        {
                            videosCat.push(video);
                        }
                    })
                  return (      
                      <>
                       {
                        videosCat.length > 0 ?
                            <VideoSlider title={item.title} videos={videosCat} submitColors={submitColors} setRecentPlay={setRecentPlay} param={CHAIRSIDENEWRELEASE}/>
                        :
                        ''
                       }
                      </>              
                  )
              })}
            </div>
        </>
    )
}

export default Index;