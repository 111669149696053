import React from 'react'

const TransitionsTab = () => {
    return (
        <div style={{ marginLeft:"395px" }}>
            <h5>TransitionsTab</h5>
        </div>
    )
}

export default TransitionsTab
