import React from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';

import './style.scss';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../pages/index';
import { extendTrail } from '../../store/actions/taskAction';
import LoadingButton from '../../components/LoadingButton';
import { extendTrialStatus } from '../../data/comman';
// import Player from '../../components/videoPlayer/player';
import ReactPlayer from 'react-player';

class TrialIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen:false
    };
    // this.nextClick = this.nextClick.bind(this);
  }

  async componentDidMount(){
    // const {practiceInfo} = this.props;
  }

  handleClick(){
    if(this.props.practiceInfo.need_profile_setup === 1){
        history.push('/initialsetup');
    }else if(this.props.practiceInfo.need_owner_setup === 1){
        history.push('/edit-authorized-profile');//// 100>practice / 800 affiliate EXPIRE 900 AF TRIAL
    }else if(this.props.location && this.props.location.isHeader){
      history.goBack();
    }else{
        history.push('/profileselection');
    }
  } 

  onClickSubscribe(){
    // history.push('/Subscribe');
    history.push({
      pathname: '/profileselection',
      fromtrial:true
    });
  }

  onClickExtendTrial(){
    this.props.dispatch(extendTrail());
  }

  toggle(){
    this.setState({isOpen:!this.state.isOpen})
  }

  render (){
    const {practiceInfo , t} = this.props
    return(
      <div className="trial__main__screen d-flex flex-column h-100 align-items-center justify-content-center">
        <div className="trial__inner__screen transparent__form--dark d-flex flex-column align-items-center  justify-content-center">
            <div className={`d-flex flex-column text-center trial__inner__text ${practiceInfo.extend_trial_request_status == extendTrialStatus.pending ? 'trial__extend_screen' : ''}`}>
              {console.log(practiceInfo,'practiceInfo')}
              {practiceInfo.days_left == 0 && ( practiceInfo.extend_trial_request_status == extendTrialStatus.pending) ? //practiceInfo.extend_trial_request_status == null ||
                <div className="mb-4 extend-request" style={{ width: '70rem' }}>
                  <h1 className="mb-4">{t('trial.trialExtend')}</h1>
                  <div>
                      <p style={{marginBottom: '1rem' }}>{t('trial.trialPart1')}</p>
                      <p style={{marginBottom: '1rem' }}>{t('trial.trialPart2')}</p>
                  </div>
                </div>
              :
            (practiceInfo.days_left !== 0 ?
                <div>
                  <h1>{t('trial.title1')}</h1>
                  <h3>{practiceInfo.days_left} {t('trial.subTitle')}</h3>
                  {practiceInfo.days_left <= 3 ?
                    <p>{t('trial.detail.part1')} {practiceInfo.days_left} {t('trial.detail.part2')}</p>
                  :
                    <p className='mb-4' dangerouslySetInnerHTML={{__html: t('trial.detail.part3')}}></p>
                  }
                </div>
              :
                <div>
                  <h1>Trial ended</h1>
                  {/*practiceInfo.status != 800 ? 
                    <p>Your trial period has now ended. We hope that your practice has seen the benefits that patient education can bring and would like to continue as a Thalamus subscriber. If you have any questions or concerns regarding Thalamus, please do not hesitate to <a href="https://dental.thalamusmedia.com/contact-us/" target="_blank" rel="noreferrer">contact us</a>.</p>
                  :*/}
                    <div>
                      <p style={{marginBottom: '1rem' }}>Your Thalamus trial period has now ended. </p>
                      {/*<p style={{marginBottom: '1rem' }}>You will still have full access to the website videos section, where you can customize your layout, theme and video selection.</p> */}
                      <p>If you would like to continue using the rest of Thalamus Patient Education Solution, please extend the trial or subscribe now.</p>
                    </div>
                  {/*}*/}
                </div>
            )}
              {practiceInfo.days_left == 0 && practiceInfo.extend_trial_request_status == null?
                <>
                  <div className="d-flex  justify-content-center">
                      <LoadingButton type="button" label={t('btn.extendTrial')} loading={this.props.buttonLoading} size="md" disabled={this.props.buttonLoading} onClick={()=> this.onClickExtendTrial() } className="mx-3 btn-235" color="primary"/>
                      {practiceInfo.need_owner_setup !== 1 &&
                        <Button className="mx-3 btn-235" size="md" disabled={this.props.buttonLoading} onClick={() => this.onClickSubscribe()} color="primary">{t('btn.subscribe')}</Button>
                      }
                      {/* <Button className="mx-3 btn-235 align-self-center" disabled={this.props.buttonLoading} size="md" onClick={() => this.handleClick()}  outline color="default white">{t('btn.continue')}</Button> */}
                  </div>
                  <Button className="mx-3 btn-235 mt-5 align-self-center" disabled={this.props.buttonLoading} size="md" onClick={() => this.handleClick()}  outline color="default white">{t('btn.continue')}</Button>
                </> :
                (practiceInfo.extend_trial_request_status == null && (practiceInfo.status == 800 && practiceInfo.status == 900)?
                  <div className="d-flex  justify-content-center">
                    <Button className="mx-3 btn-235 mt-5 align-self-center" size="md" onClick={() => this.toggle()} color="primary">{t('btn.whatIsThalamus')}</Button>
                    <Button className="mx-3 btn-235 mt-5 align-self-center" disabled={this.props.buttonLoading} size="md" onClick={() => this.handleClick()}  outline color="default white">{t('btn.continue')}</Button>
                  </div>
                :
                  <div className="d-flex  justify-content-center">
                    {/* <Button className="mx-3 btn-235 mt-5 align-self-center" size="md" disabled={this.props.buttonLoading} onClick={() => this.onClickSubscribe()} color="primary">{t('btn.subscribe')}</Button> */}
                    <Button className="mx-3 btn-235 align-self-center" disabled={this.props.buttonLoading} size="md" onClick={() => this.handleClick()}   color="primary">{t('btn.continue')}</Button>
                  </div>)
              }
              {practiceInfo.days_left !== 0 && practiceInfo.need_owner_setup !== 1 &&
              <div className="pt-xl-5  justify-content-center">
                <p className='mt-xl-5 pt-xl-4 mb-xl-5 mb-4 mt-4 '>You can also subscribe to enjoy the full experience of Thalamus.</p>
                <Button className="btn-235 align-self-center" disabled={this.props.buttonLoading} size="md" onClick={() => this.onClickSubscribe()}  outline color="default white">{t('btn.subscribe')}</Button>
              </div>
              }
            </div>
            
        </div>
        {this.state.isOpen && 
            <div size="xxl" className={`${this.state.isOpen ? 'bg-block' : 'bg-none'} h-100 d-flex flex-column align-items-strach justify-content-center modal-video`} isOpen={this.state.isOpen} toggle={() => this.toggle()}>
              <div className={`${this.state.isOpen ? 'd-block' : 'd-none'} video-show mt-5`}>
             

              </div>
				    </div>
        }
      </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  practiceInfo: state.task.practiceInfo,
  buttonLoading: state.task.buttonLoading
});


// export default connect( mapStateToProps,  mapDispacthToProps )(TrialIndex);
// export default withTranslation('common')(TrialIndex);
export default compose(
  withTranslation('common'),
  connect(mapStateToProps)
)(TrialIndex);