import React from 'react';

import { Button , Label , FormGroup, Form , Spinner} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { history } from '../../../pages/index';
// import ReactCodeInput from 'react-verification-code-input';
import ReactCodeInput from 'react-code-input';
import PhoneInput from 'react-phone-input-2'

import { connect } from "react-redux";
import { compose } from 'redux';
import {GetPhoneVerification,VerifyOtp , cleardata} from "../../../store/actions/taskAction";
import PhoneVerify from '../../../components/phoneVerify';
import './style.scss';

class Security extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        hidden: true,
        practiceInfo:this.props.practiceInfo,
        isVerify:false
     
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

    nextClick(){
        this.props.nextStep();
    }

    prevClick(){
        this.props.prevStep();
    }
        
    // handleClick(){
    //     if(this.props.practiceInfo.need_owner_setup === 0){
    //         history.push('/profilesetup');
    //     }else{
    //         history.push('/profilesetup');
    //     }
    // } 

    onClickVerifyPhone(phone){
        this.props.dispatch(GetPhoneVerification(phone));
        this.setState({isVerify:true})
    }

    onClickVerifyOtp(otp){
        var params = {
            request_secrete: this.props.phoneVerificationInfo.request_secrete,
            otp: otp,
            phone: this.props.phoneVerificationInfo.phone,
            media:'sms'
          }
        this.props.dispatch(VerifyOtp(params));
    }
  
    
    componentDidMount(){
      this.props.handleChange('passcode','')
      if(!this.props.isBack){
          this.props.dispatch(cleardata());
        }
      }

  render (){
    const {t , values , phoneVerificationInfo, isError} = this.props
    return(
        <div className="about__screen transparent__form--white  md d-flex flex-column align-items-stretch mb-auto">
          <Form className="h-100 d-flex flex-column">
            <FormGroup className="mb-20">
                <h4 className="m-0 mb-4">Mobile number / Email address</h4>
                <p className="m-0">Enter your mobile no. / email, and verify it with the 4- digit code we’ll send you.</p>
            </FormGroup>
            
            <PhoneVerify isClear={true} isBack={this.props.isBack}  isError={isError.isPhoneverify} isNewProfile={true} values={values}/>

            <FormGroup className="my-5 py-3 border-bottom-2">
                <div className="border--bottom"></div>
            </FormGroup>
            <fieldset disabled={!phoneVerificationInfo.phoneVerifyed && !this.props.isBack} style={{marginTop:"3px !important"}}>
              <FormGroup className="passcode-section">
                  <h4  className="m-0 mb-4" style={{marginTop:"27px !important"}}>Set your passcode</h4>
                  <p className="m-0">To protect your privacy, please set a 4-digit passcode. It can later be updated in the profile settings section.</p>
              </FormGroup>
              <FormGroup className="mb-20 mt-5">
                  <div className="d-flex justify-content-between">
                      <label>Set passcode</label><Button onClick={this.toggleShow} color="link primary" className="p-0 text-uppercase">{this.state.hidden ? 'SHOW' : 'Hide'}</Button>
                     
                  </div>
                  {isError.passcode && (
                        <span className="text--danger">{isError.passcode}</span>
                      )}
                  <ReactCodeInput  type={this.state.hidden ? 'password' : 'number'} onChange={(options) => this.props.handleChange('passcode',options)}   fields={4} className="mb-2"/>
              </FormGroup>
            </fieldset>
            <div className="mt-auto footer-btn d-flex">
                <Button type="button" size="sm" onClick={ this.nextClick } className="mt-5 mr-3" color="primary">{t('btn.next')}</Button>
                <Button type="button" size="sm" onClick={ this.prevClick } className="mt-5 ml-3" outline color="default white">{t('btn.back')}</Button>
            </div>
            </Form>
        </div>
    ) 
  }
}
const mapStateToProps = (state) => ({
  loadingOwnerVerifyPhone:state.task.loadingOwnerVerifyPhone,
  practiceInfo: state.task.practiceInfo,
  phoneVerificationInfo:state.task.phoneVerificationInfo,

});
export default compose(
  withTranslation('common'),
  connect(mapStateToProps )
)(Security);
