/** @format */

import React, { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './videoslider.scss';
import 'swiper/swiper-bundle.css';
import Player from '../../components/videoPlayer/player';
import EditeImageModal from '../EditImages/EditImageModel'
import { RECENTPLAY, formatSecond } from '../../data/comman';
import { useSelector, useDispatch } from "react-redux";
import { getVideoSection, deleteFromMyListChairsideEducation  , postAddToMyListChairsideEducation , ShowCloseCaptionChairsideEducation , HideCloseCaptionChairsideEducation, setShareVideoId, shareVideoLinkClearData, clearloading} from '../../store/actions/taskAction';
import LoadingButton from '../../components/LoadingButton';
import Profile_icon from '../../img/play_white.svg'
import Check_Sign from '../../img/check-sign.png'
import downArrow from '../../img/down-arrow.svg'
import IconPlas from '../../img/IconPlas.svg'
import Icon_favourites from '../../img/Icon_favourites.svg'
import Video_share_icon from '../../img/Video_share_icon.svg'
import { history } from '../../pages/index';
import VideoSliderImageSkeleton from "../Skeletons/videoSliderImageSkeleton"
import VideoswiperSlideSkeleton from "../../components/Skeletons/videoswiperSlideSkeleton"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const VideoSlider = ({ videos, submitColors, title, setRecentPlay, isRecentPlay }) => {
	const state = useSelector(state => state.task);
	const [nextEnable, setNextEnable] = useState('-disable');
	const [prevEnable, setprevEnable] = useState('-disable');
	const dispatch = useDispatch();
	const [showButton, setShowButton] = useState(false);

	const [modal, setModal] = useState(false);
	const [isEditor, setIsEditor] = useState(false);
	const [editorImg, setEditorImg] = useState({})

	const [showResults, setShowResults] = useState({
		video: {},
		showResults: false,
	});

	const onClickShowResults=(video)=>{
		setShowResults({
			video:video,
			showResults:showResults.video.id == video.id ? !showResults.showResults : true
		})
	}

	useEffect(() => {
		submitColors(videos[0]);
		dispatch(clearloading()) 
	}, []);

	const [showVideo, setShowVideo] = useState({
		video: {},
		showVideo: false,
	});

	const toggle = () => setModal(!modal);

	const changeButton = (color) => {
		submitColors(color);
	};

	const onSliderChange = (e) => {
		const activeNextSlide = e.activeIndex === 0 ? '-disable' : '-enable';
		setNextEnable(activeNextSlide);
	};
	const openVideoPlayer = (video) => {
		// console.log('Video : ', video);
		//fetch selected video 'video section'
		// dispatch(getVideoSection({ chairside_media_id: video.chairside_media_id }));

		var recentPlayLocal = localStorage.getItem(RECENTPLAY);
		if(recentPlayLocal != null){
			var recentPlay = JSON.parse(localStorage.getItem(RECENTPLAY));
			var index = recentPlay.findIndex(x => x == video.chairside_media_id);
			if(index > -1){
				recentPlay.splice(index, 1);
			}
			recentPlay.unshift(video.chairside_media_id);
			localStorage.setItem(RECENTPLAY, JSON.stringify(recentPlay));
		}else{
			var recentPlay = [];
			recentPlay.unshift(video.chairside_media_id)
			localStorage.setItem(RECENTPLAY, JSON.stringify(recentPlay));
		}

		if(recentPlay != null){
            var recentPlayVideos = []
            recentPlay.map((video)=>{
                var videolist = state.chairSideMediaState.find(x => x.chairside_media_id == video);
                recentPlayVideos.push(videolist)
            })
            setRecentPlay(recentPlayVideos)
        }
		// if(!isRecentPlay){
			// setRecentPlay(recentPlay);
		// }

		setShowVideo({
			video: video,
			showVideo: true,
		});
	};
	const onClickClose = (close) => {
		setShowVideo({
			video: {},
			showVideo: false,
		});
	};
	const openImgEditor = (img) => {
		// alert("hello");
		if (img) {
			setEditorImg(img)
			setIsEditor(true)
			// onClickClose()
		}
	}

	const togglePip = (isPipOn) => {
		setShowVideo({
			...showVideo,
			showVideo: !isPipOn,
		});
	}

	// Hover on Plus icon
	const hoverHandler = (video, type) => {
		if (type === 'enter') {
			setShowButton(true);
		} else {
			setShowButton(false);
		}
	};
	// Remove from My List
	const removeFromMyList = (video) => {
		dispatch(deleteFromMyListChairsideEducation(video.chairside_media_id));
	}

    // Add to my list
	const addToMyList = (video) => {
        let params ={
            media_id:video.chairside_media_id
        }
		dispatch(
			postAddToMyListChairsideEducation(params)
		);
	};

    //Show CC
    const showCC =(video)=>{
        let params ={
            media_id:video.chairside_media_id
        }
		dispatch(ShowCloseCaptionChairsideEducation(params));
    }

    //Hide CC
    const hideCC =(video)=>{
		dispatch(HideCloseCaptionChairsideEducation(video.chairside_media_id));
    }

	const CategoryComponent = ({categories}) => {
		const TooltipComponent = () => {
			const [hover,setHover] = React.useState(false)
			return <div><span style={{color:"white"}} onMouseOver={()=>setHover(true)} >{categories[0]} ...</span>
			{hover && <div onMouseOver={()=>setHover(true)} onMouseLeave={()=>setHover(false)} style={{backgroundColor:"white",position:"absolute",padding:"5px 10px",top:"10px",right:"0",zIndex:2}}>
				{categories.map((item,index)=>{
					if(index > 0){
						return <><span key={index} style={{color:"black"}}>{item},{" "}</span><br/></>
					}
				})}
			</div>}
			</div>
		}

		return <div>
			{categories.length > 2   ?  <TooltipComponent/>	: categories.map((item, index)=>{
				return <><span key={index} style={{color:"white"}}>{item},{" "}</span></>
			})}
		</div>
	}

	const onShareVideoClick = async (video) =>{
		dispatch(setShareVideoId(video.chairside_media_id));
        dispatch(shareVideoLinkClearData());
		history.push('/email-video')
	}

	return (
		<div style={{ position:'relative'}}>
			<p style={{ fontSize: '27px' ,  marginBottom:'0px' , paddingLeft:'14px'}}>{title}</p>
			{!isEditor ? <>
			<div style={{ marginTop: '-14px',minHeight:'40px'}}>
			<Swiper
				spaceBetween={0}
				slidesPerView={'auto'}
				navigation
				id={`video-slider${nextEnable}`}
				//onSwiper={(swiper) => console.log(swiper)}
				onSlideChange={(e) => onSliderChange(e)} >
				{videos.length > 0 ? videos.map((video, index) => {
					return (
							<SwiperSlide key={index} data-hash={index} style={{  marginTop:'35px'}}>
							<div className='card' /*onClick={() => openVideoPlayer(video)}*/ onClick={()=>onClickShowResults(video)} style={{ cursor: "pointer" }}>
								<div className='chairsidevideo'>
									<VideoSliderImageSkeleton src={video.chairside_media.thumb} />
									{/* { video.chairside_media ? <img  key={video.id} src={video.chairside_media.feature_image} alt="" /> : ''} */}
								</div>
								<div className="subTitle">
									<div className="video_icon" onClick={() => openVideoPlayer(video)}>
										<img  src={Profile_icon} alt="play_video" />
									</div>
									<p className="smalltitile">{video.chairside_media.media_language.title} | {formatSecond(video.chairside_media.media_language.media.duration)}</p>
									{state.duplicatesWithCategories[video.chairside_media_id] !== undefined ? <CategoryComponent categories={state.duplicatesWithCategories[video.chairside_media_id]}/> : <p className="title">{video.category.title}</p>}
									{video.chairside_media.in_mylist === 1 &&
										<div className="check-sign">
											<img src={Icon_favourites} alt="check-sign"/>
										</div>
									}
									<div className="video_downarrow"><img src={downArrow} alt=""/></div>
								</div>
								{/* <h4 className="video_title" style={{ textTransform: 'uppercase' }}>
								   {video.chairside_media.media_language.title}
								</h4> */}
								{video.chairside_media.media_language.title && 
									<h4 className="video_title" >
										<div
										dangerouslySetInnerHTML={{
										__html : video.chairside_media.media_language.title.replace('ß', "<span className\"match\">ß</span>")
										}} />
									</h4>
								}
							</div>
						</SwiperSlide>
					);
				})
				:
				<div className='swiper-wrapper'>
					<VideoswiperSlideSkeleton  />
				</div>
			}
			</Swiper>
			</div>
						{showResults.showResults &&
							<div className="dropdown-chevron" style={{backgroundImage:`url(${showResults.video.chairside_media.feature_image})`}}>
								<div className="hover_description">
									<div className="hover_description__div">
										 <p className="hover_description__title" >
											<div
											dangerouslySetInnerHTML={{
											__html :  showResults.video.chairside_media.media_language.title.replace('ß', "<span className\"match\">ß</span>")
											}} />
										</p>
										<p className="hover_description__duration">Duration : { formatSecond(showResults.video.chairside_media.media_language.media.duration) }<br/>
										Specialization : { showResults.video.category.title }
										</p>
										{<div className="hover_description__description" dangerouslySetInnerHTML={{__html: showResults.video.chairside_media.media_language.description }} />}
									</div>
								</div>
								<div className="dropdown-button d-flex">
									<Button color="primary" size="md" onClick={() => openVideoPlayer(showResults.video)}>Play video</Button>
									<Button type="button" size="md" onClick={()=>{ history.push({pathname:'/chairside-education-edit-close-caption', state: {video:showResults.video} })}} color="white" >View & Edit captions</Button>
									<div className='text-center' style={{height: '41px'}}>
										<LoadingButton type="button" label="CC" loading={showResults.video.chairside_media.isCCLoading} disabled={showResults.video.chairside_media.isCCLoading} onClick={()=>{ showResults.video.chairside_media.cc_status == 0 ? showCC(showResults.video) : hideCC(showResults.video) }} color="default white cc-btn" className={`${showResults.video.chairside_media.cc_status == 0  ? ' ' : 'active'} `} outline/>
										<div className='cc_status_btn_text'>{showResults.video.chairside_media.cc_status == 0 ? 'off' : 'on'}</div>
									</div>
									<Button className="check-sign"><img src={Check_Sign} alt="check-sign"/></Button>
									{/* {console.log(showResults,'showResultsshowResultsshowResults')} */}
									{showButton === false ? (
										<LoadingButton
											color={`${state.buttonLoading3 ? 'default white px-3 d-flex align-items-center' : ' white' }`}
											outline
											type="button"
											className="p-0 "
											disabled={state.buttonLoading3}
											loading={state.buttonLoading3}
											onMouseEnter={() => hoverHandler(showResults.video, 'enter')}
											onMouseLeave={() => hoverHandler(showResults.video, 'leave')}  style={{height:'30px' , width:'30px'}}>
											<img src={showResults.video.chairside_media.in_mylist === 1 ? Icon_favourites : IconPlas } alt=""/>
										</LoadingButton>
									) : showResults.video.chairside_media.in_mylist === 1 ? (
										<LoadingButton
											color='primary'
											type="button"
											size="md"
											loading={state.buttonLoading3}
											onMouseLeave={() => hoverHandler(showResults.video, 'leave')}
											onClick={() => removeFromMyList(showResults.video)}>
											REMOVE FROM MY LIST
										</LoadingButton>
									) : (
										<LoadingButton
											color='white'
											type="button"
											size="md"
											loading={state.buttonLoading3}
											onMouseLeave={() => hoverHandler(showResults.video, 'leave')}
											onClick={() => addToMyList(showResults.video)}>
											ADD TO MY LIST
										</LoadingButton>
									)}
									{state.practiceInfo.days_left != 0 && 
										<Button color="link" className='p-0' onClick={() => onShareVideoClick(showResults.video)}><img src={Video_share_icon} alt="Video share"/></Button>
									}
								</div>
							</div>
						}

			{showVideo.video.id ? (
				<div size="xxl" className={`${showVideo.showVideo ? 'bg-block' : 'bg-none'} h-100 d-flex flex-column align-items-strach justify-content-center modal-video`} isOpen={showVideo.video} toggle={toggle}>
					<div className={`${showVideo.showVideo ? 'd-block' : 'd-none'} video-show mt-5`}>
						<Player
							video={showVideo.video}
							title={showVideo.video}
							onClickClose={onClickClose}
							className="w-100 h-100"
							togglePip={togglePip}
							openImgEditor={openImgEditor}
						/>
					</div>
				</div>
			) : (
				''
			)}
			</> :
				<EditeImageModal
					open={true}
					closeEditor={() => { setIsEditor(false) }}
					// setOpen={setOpen}
					screenShotImage={editorImg}
				/>
			}
		</div>
	);
};

export default VideoSlider;
